import React, { Fragment } from 'react';
import { DesktopList, MobileList, ListItem, StyledLink } from './helper-components';

type BreadcrumbProps = {
  isPopup?: boolean;
  links?: {
    url?: string;
    text: string;
  }[];
};

const Breadcrumb = ({ links = [], isPopup = false }: BreadcrumbProps) => {
  const mobileItem = links.length > 1 ? links[links.length - 2] : null;
  return (
    <Fragment>
      <DesktopList isPopup={isPopup}>
        {links.map(({ url, text }: any, i: any) => (
          <ListItem key={`${text}-${i}`}>
            {url ? <StyledLink to={url}>{text}</StyledLink> : <span>{text}</span>}
          </ListItem>
        ))}
      </DesktopList>

      {mobileItem && (
        <MobileList isPopup={isPopup}>
          <ListItem isPopup={isPopup}>
            <StyledLink to={mobileItem.url}>{mobileItem.text}</StyledLink>
          </ListItem>
        </MobileList>
      )}
    </Fragment>
  );
};

export default Breadcrumb;
