import styled from 'styled-components';
import colors from '../../constants/colors';
import breakpoints from '../../constants/breakpoints';

export const TabsWrapper = styled.div<{
  width?: string;
}>`
  width: ${({ width }) => width};
`;

export const DesktopTabs = styled.div`
  display: flex;
  align-items: flex-end;
  font-size: 1rem;
  height: 43px;

  @media (max-width: ${breakpoints['portrait-min']}px) {
    display: none;
  }
`;

export const DesktopTabButton = styled.div<{
  selected?: boolean;
  minWidth?: string;
  width?: string;
  borderColor?: string;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  word-break: break-word;

  text-transform: uppercase;
  font-weight: 500;
  line-height: 1.2;
  color: ${({ selected }) => (selected ? colors.darkblue : colors.white)};
  cursor: pointer;

  margin: 0px 0px 0px 3px;
  padding: 0px 8px;
  border-radius: 4px 4px 0px 0px;
  min-width: ${({ minWidth = '165px' }) => minWidth};
  ${({ width }) => (width ? `width: ${width}px;` : '')}
  height: ${({ selected }) => (selected ? '43px' : '40px')};
  background-color: ${({ selected }) => (selected ? colors.white : colors.darkblue)};

  &:hover {
    background-color: ${({ selected }) => (selected ? colors.white : '#004147')};
  }

  &:first-child {
    margin-left: 0px;
  }

  ${({ selected, borderColor }) =>
    selected &&
    borderColor &&
    `
  border: solid 1px ${borderColor};
  height: 44px;
  border-bottom: ${colors.white};
  margin-bottom: -1px;
  padding-bottom: 1px;
  `}

  ${({ selected, borderColor }) =>
    !selected && borderColor && `border: solid 1px ${colors.darkblue};`}
`;

export const TabContent = styled.div<{
  borderColor?: string;
}>`
  ${({ borderColor }) => borderColor && `border: solid 1px ${borderColor};`}
  @media (max-width: ${breakpoints['portrait-min']}px) {
    border: none;
  }
`;

export const MobileTabs = styled.div`
  display: none;
  position: relative;
  width: 100%;
  background-color: ${colors.white};
  font-size: 16px;

  @media (max-width: ${breakpoints['portrait-min']}px) {
    display: flex;
    flex-direction: column;
  }
`;

export const MobileTabButton = styled.div<{
  selected?: boolean;
  expanded?: boolean;
}>`
  display: flex;
  margin: 0 0 1px 0;
  color: ${({ selected }) => (selected ? colors.darkblue : colors.white)};
  height: 50px;
  font-weight: 500;
  background-color: ${({ selected }) => (selected ? colors.white : colors.darkblue)};
  align-items: center;
  padding-left: 20px;
  margin-bottom: 1px;
  text-transform: uppercase;

  ${({ expanded }) => !expanded && `border-bottom: 1px solid ${colors.darkblue}`};
`;

export const MobileTabsContainer = styled.div`
  overflow: hidden;
  transition: max-height 500ms ease;
  background-color: ${colors['header-color']};
`;

export const ExpandArrow = styled.div<{
  expanded?: boolean;
}>`
  display: inline-block;
  position: absolute;
  top: 19px;
  right: 25px;
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 12px solid ${colors.secondary};
  transform: rotate(0deg);
  transition: transform 0.2s linear;

  ${({ expanded }) => expanded && 'transform: rotate(180deg);'};
`;
