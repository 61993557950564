// @ts-nocheck
import { post, getXseptionPath } from '../../utils/api';
export function createXseptionReport(enterpriseId: any, countryCode: any) {
  return post(`${getXseptionPath()}/countries/${countryCode}/enterprises/${enterpriseId}/report`);
}
export function createUBOReport(enterpriseId: any, registrationNumber: any, countryCode: any) {
  return post('/credit-reports/ubo/report', {
    enterpriseId,
    registrationId: registrationNumber,
    countryCode,
  });
}
export const creditReportUri = (id: any) => `/credit-reports/${id}/summary`;
export const xseptionReportUri = (id: any) => `/xseption-report/${id}`;
export const uboReportUri = (id: any) => `/ubo-reports/report/${id}`;
export const companyDataUri = (id: any) => `/company-data/organisation/${id}`;
