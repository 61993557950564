import styled from 'styled-components';
import * as colors from '@graydon/ui-colors';
import { sans } from '../../../constants/fonts';
const BasePageWrapepr = styled.div`
  margin: 0 auto;
  width: 100%;
`;
export const InnerWrapper = styled(BasePageWrapepr)`
  max-width: 1400px;
  padding: 0px 16px;

  @media (max-width: 768px) {
    padding: 0px;
  }
`;
export const GreyPageWrapper = styled(BasePageWrapepr)`
  background-color: #efefef;
  height: 100%;
`;
export const CenteredPageWrapper = styled(BasePageWrapepr)`
  background-color: ${colors.grayLight5};
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  padding-bottom: 16px;
`;
export const PageHeaderBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
  order: 1;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 0px;
  }
`;
export const PageHeader = styled.h1`
  margin: 0px;
  font: 500 2rem ${sans};
  color: ${colors.blueLight3};

  @media (max-width: 768px) {
    margin-bottom: 0.5rem;
    font-size: 1.125rem;
    line-height: 1.39;
  }
`;
export const PageDescription = styled.div<{
  textWidth?: string;
}>`
  width: 100%;
  order: 3;
  color: ${colors.gray};

  & > p {
    font-weight: 300;
    width: ${({ textWidth }) => textWidth || '50%'};
  }

  @media (max-width: 768px) {
    width: 100%;
    order: 2;

    & > p {
      width: 100%;
      margin-top: 0px;
    }
  }
`;
