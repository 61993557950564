// @ts-nocheck
import get from 'lodash/get';
import { getYearsRange, getLastFilledFinancialYear } from '../common';

const getKeyFigureCurrency = (keyFigure: any) =>
  get(keyFigure, 'turnover.currencyCode') ||
  get(keyFigure, 'cash.currencyCode') ||
  get(keyFigure, 'result.currencyCode') ||
  get(keyFigure, 'workingCapital.currencyCode') ||
  get(keyFigure, 'accountsTotal.currencyCode') ||
  get(keyFigure, 'equity.currencyCode') ||
  get(keyFigure, 'cashFlow.currencyCode') ||
  get(keyFigure, 'grossMargin.currencyCode');

const getCurrencyByYear = (keyFigures: any, year: any) => {
  const keyFigure = keyFigures.find((figure: any) => figure.year === year);
  return getKeyFigureCurrency(keyFigure, year);
};

export const extractKeyFiguresData = (data: any) => {
  const keyFigures = get(data, 'financialProfile.keyFigures', []);
  const defaultCurrency = getKeyFigureCurrency(keyFigures && keyFigures[0]) || 'EUR';
  const unit = 1;
  const lastFilledYear = getLastFilledFinancialYear(data);
  const actualYears = getYearsRange(lastFilledYear, 5).map((year) => ({
    year,
    currency: getCurrencyByYear(keyFigures, year) || defaultCurrency,
    unit,
  }));
  const template = {
    years: actualYears,
    items: {},
  };
  // Extract all the uniq codes from dataset.
  const uniqCodes = Array.from(
    keyFigures.reduce((set: any, figure: any) => {
      Object.keys(figure)
        .filter((key) => key !== 'year')
        .forEach((key) => set.add(key));
      return set;
    }, new Set()),
  );
  // Fill default value 'null' for every value of every code.
  uniqCodes.forEach((code) => {
    template.items[code] = actualYears.map(() => null);
  });
  actualYears.forEach(({ year }, i) => {
    const figures = keyFigures.find((x: any) => parseInt(x.year, 10) === year);
    uniqCodes.forEach((code) => {
      template.items[code][i] = get(figures, `${code}.value`, null) || get(figures, code, null);
    });
  });
  return template;
};
