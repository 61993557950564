import get from 'lodash/get';
import { getLast12ActiveMonths } from '../common';
export const extractCreditLimitTrendData = (data: any, date = new Date()) => {
  const latestValidDate = new Date(date.getFullYear(), date.getMonth() - 1, 1);
  const creditLimitTrendData = get(
    data,
    'ratingsProfile.graydonGroupCreditScores.historicGraydonGroupCreditScores.creditAmountAdvised',
    [],
  );
  let maxCreditLimit = 0;
  const creditLimitTrend = getLast12ActiveMonths(latestValidDate).reduce(
    (acc: any, period: any) => {
      const creditAmountForGivenPeriod = creditLimitTrendData.find(
        (graydonData: any) =>
          graydonData.month === period.month && graydonData.year === period.year,
      );
      const creditLimitForGivenPeriod = get(creditAmountForGivenPeriod, 'value', null);

      if (creditLimitForGivenPeriod > maxCreditLimit) {
        maxCreditLimit = creditLimitForGivenPeriod;
      }

      period.creditLimit = creditLimitForGivenPeriod;
      return [period, ...acc];
    },
    [],
  );
  return {
    maxCreditLimit,
    data: creditLimitTrend,
    currencyCode: get(data, 'companyIdentification.countryCode') === 'GB' ? 'GBP' : 'EUR',
  };
};
