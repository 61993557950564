export default {
  'cr.ratings.credit.flag.tooltip.NL\n  \n \n\n\n':
    'De credit flag werkt als een stoplicht, en is kortgezegd een simpele vertaling van het kredietadvies en de Graydon Rating. Groen = kredietverlening levert geen problemen op. Oranje = krediet verlenen gaat gepaard met een verhoogd risico. Rood = Graydon raadt af om aan voorfinanciering te doen. De kredietlimiet geeft aan tot welk bedrag het verantwoord is om met een bedrijf zaken te doen. De kredietlimiet vorm een waardeoordeel op korte termijn.',
  'aa.not-available.message': 'De Advanced Analytics applicatie is niet meer beschikbaar.',
  'aa.not-available.title': 'Applicatie niet beschikbaar',
  'aa.not-available.contact-service': 'Neemt u contact op met ons Customer Service team.',
  'aa.modal.annual-account': 'Jaarrekening',
  'aa.modal.download': 'Koop',
  'aa.modal.download-archive': 'Download',
  'aa.modal.download.error.message': 'Probeer later opnieuw of contacteer Graydon',
  'aa.modal.download.error.title': 'Verzoek kon niet worden verzonden',
  'aa.modal.download.not-available': 'Niet beschikbaar',
  'aa.modal.download.not-found.message':
    'De jaarrekening kan niet opgehaald worden. Gelieve Graydon te contacteren.',
  'aa.modal.download.not-found.title': 'De jaarekening werd niet gevonden',
  'aa.modal.download.success.message':
    'De door u opgevraagde jaarrekening is beschikbaar in de downloadbalk en in het Bedrijfsarchief',
  'aa.modal.download.success.title': 'Het rapport is gedownload',
  'aa.modal.explanation': 'Kies voor welk jaar u de jaarrekening wil kopen en downloaden.',
  'aa.modal.fetch-list.error.message':
    'We kunnen de jaarrekening momenteel niet laden. Probeer het later nog eens.',
  'aa.modal.fetch-list.error.title': 'Er ging iets mis',
  'aa.modal.fetch-list.not-found.message':
    'Er zijn geen originele jaarrekeningen beschikbaar voor deze onderneming.',
  'aa.modal.fetch-list.not-found.title': 'Geen jaarrekeningen beschikbaar',
  'aa.modal.title': 'Download jaarrekeningen',
  'aa.report.errorFetching': 'Fout bij het ophalen van het rapport!',
  'aa.report.noReport':
    'Deze informatie is helaas niet online beschikbaar.<br /><br />Onze afdeling Customer Care helpt u graag verder, via <a href="mailto:{country, select, gb {customerservices@graydon.co.uk} be {support@graydon.be} other {service@graydon.nl}}">{country, select, gb {customerservices@graydon.co.uk} be {support@graydon.be} other {service@graydon.nl}}</a> of {country, select, gb {+44 (020) 8515 1400} be {+32 (03) 280 88 80} other {+31 (020) 567 9501}}.',
  'address.city': 'Stad',
  'address.house.number': 'Huisnr.',
  'address.street.or.po.box': 'Straat of postbus',
  'address.zip.code': 'Postcode',
  'addToLists.link': 'Voeg toe…',
  'addToMonitoring.companyAdded': 'Bedrijf <b>toegevoegd</b> aan lijst',
  'addToMonitoring.companyRemoved': 'Bedrijf <b>verwijderd</b> van lijst',
  'addToMonitoring.database-management': 'Database Management ',
  'addToMonitoring.database-management.desc':
    'Om gegevens van een bedrijf te ontvangen, dient u deze toe te voegen aan een lijst met databasebeheer. U kunt een bedrijf toevoegen aan of verwijderen uit de onderstaande lijsten.',
  'addToMonitoring.done': 'Klaar',
  'addToMonitoring.goToMonitoring': 'Ga naar Monitoring',
  'addToMonitoring.heading': 'Voeg bedrijf toe aan lijst(en)',
  'addToMonitoring.link': 'Voeg toe aan Monitoring',
  'addToMonitoring.lists.goToListManagement': 'Ga naar List Management',
  'addToMonitoring.lists.noneFound': 'Geen lijsten gevonden',
  'addToMonitoring.lists.paragraph': 'Kies één of meerdere lijsten',
  'addToMonitoring.lists.subheading': 'Andere lijsten',
  'addToMonitoring.monitoring': 'Monitoring',
  'addToMonitoring.monitoring.desc':
    'Om een bedrijf te monitoren, dient u dit toe te voegen aan een gemonitorde lijst. U kunt een bedrijf toevoegen aan of verwijderen uit de onderstaande lijsten.',
  'addToMonitoring.noMonitoredLists': 'Er zijn nog geen lijsten die u monitort',
  'addToMonitoring.noMonitoredLists.paragraph':
    'Om een bedrijf te monitoren, dient u eerst een monitoring profiel aan te maken. Koppel daarna een bedrijvenlijst aan het profiel.',
  'addToMonitoring.relatedProfiles': 'Gekoppelde monitoring profiel(en):',
  'addToMonitoring.subheading': 'Monitoring lijsten',
  'app-home.app.name.advanced-analytics': 'Advanced Analytics',
  'app-home.app.name.app-home.app.name.decision-model': 'Decision Model',
  'app-home.app.name.archive': 'Archief',
  'app-home.app.name.data-enrichment': 'Data Enrichment',
  'app-home.app.name.list-management': 'List Management',
  'app-home.app.name.management-dashboard': 'Management Dashboard',
  'app-home.app.name.marketing-information': 'Market Data',
  'app-home.app.name.reports': 'International Business',
  'app-home.app.name.uploads': 'Uploads',
  'app-home.apps.heading': 'Mijn andere producten',
  'app-home.main.title': 'Wat wilt u vandaag doen?',
  'apps.advanced-analytics': 'Advanced Analytics',
  'apps.advanced-analytics.description': 'Bekijk statistieken op maat',
  'apps.business-risk': 'Business Risk',
  'apps.business-risk.description': 'Stop fraude en financiële criminaliteit',
  'apps.business-radar': 'Business Radar',
  'apps.business-radar.description': 'Bekijk nieuwsupdates',
  'apps.company-data': 'Bedrijfsinformatie',
  'apps.consumer-credit-check': 'Consumer Credit Check',
  'apps.consumer-credit-check.description': 'Controleer kredietwaardigheid van personen',
  'apps.contact': 'Customer Care',
  'apps.credit-reports': 'Credit Reports',
  'apps.data-enrichment': 'Data Enrichment',
  'apps.data-enrichment.description': 'Verrijk uw bedrijfsdata',
  'apps.database-management': 'Database Management ',
  'apps.database-management.description': 'Houd uw bedrijfsdata actueel',
  'apps.decision-model': 'Decision Model',
  'apps.decision-model.description': 'Beheer uw beslismodellen en bekijk het archief',
  'apps.dm-v2': 'Decision Model',
  'apps.international-business-archive': 'International Business Archief',
  'apps.international-business-archive.description': 'Online rapporten en nieuwe onderzoeken',
  'apps.international-credit-reports': 'International Business',
  'apps.ledger-analyser': 'Ledger Management',
  'apps.ledger-analyser.description': "Vind risico's in uitstaande betalingen",
  'apps.list-management': 'List Management',
  'apps.list-management.description': 'Beheer voor u interessante bedrijven',
  'apps.management-dashboard': 'Management Dashboard',
  'apps.management-dashboard.description': 'Bekijk uw gebruik van Graydon producten',
  'apps.marketing-information': 'Market Data',
  'apps.marketing-information.description': 'Vind de juiste potentiële klanten',
  'apps.monitoring': 'Monitoring Tool',
  'apps.monitoring.description': 'Bekijk updates van bedrijfsdata',
  'apps.stakeholder-report': 'Stakeholder Search',
  'apps.stakeholder-report.description': 'Vind posities van belanghebbenden',
  'apps.ubo-reports': 'UBO Rapport',
  'apps.xseption-report': 'Network incl. XSeptions',
  'archive.backtoarchive': 'Terug naar archief',
  'archive.backToCreditcheck': 'Terug naar credit check',
  'archive.meta.title': 'Gearchiveerd resultaat',
  'archive.pdf.checked.model': 'Gecontroleerd met model',
  'archive.pdf.note.title': 'Notitie',
  'archive.pdf.order.value': 'Orderwaarde',
  'archive.pdf.reference.title': 'Referentie',
  'archive.pdf.result.checkpoint.be.annualAccountAvailable': 'Jaarrekening beschikbaar?',
  'archive.pdf.result.checkpoint.be.annualAccountAvailable.threshold.label': 'Drempelwaarde',
  'archive.pdf.result.checkpoint.be.multiscore': 'Multiscore',
  'archive.pdf.result.checkpoint.be.multiscore.threshold.label': 'Drempelwaarde',
  'archive.pdf.result.checkpoint.be.paymentsScore': 'Betaalscore',
  'archive.pdf.result.checkpoint.be.paymentsScore.threshold.label': 'Drempelwaarde',
  'archive.pdf.result.checkpoint.companyActive': 'Actief bedrijf',
  'archive.pdf.result.checkpoint.companyActive.threshold.label': 'Drempelwaarde',
  'archive.pdf.result.checkpoint.creditLimit': 'Kredietlimiet',
  'archive.pdf.result.checkpoint.creditLimit.threshold.label': 'Orderwaarde',
  'archive.pdf.result.checkpoint.gb.augurScore': 'Augur score',
  'archive.pdf.result.checkpoint.gb.augurScore.threshold.label': 'Drempelwaarde',
  'archive.pdf.result.checkpoint.gb.ccjCount': 'Aantal vonnissen van het County Court',
  'archive.pdf.result.checkpoint.gb.ccjCount.threshold.label': 'Drempelwaarde',
  'archive.pdf.result.checkpoint.gb.companyAge': 'Leeftijd bedrijf',
  'archive.pdf.result.checkpoint.gb.companyAge.threshold.label': 'Drempelwaarde',
  'archive.pdf.result.checkpoint.gb.companyIncorporated': 'Bedrijf incorporated?',
  'archive.pdf.result.checkpoint.gb.companyIncorporated.threshold.label': 'Drempelwaarde',
  'archive.pdf.result.checkpoint.gb.creditRating': 'Kredietrating UK',
  'archive.pdf.result.checkpoint.gb.creditRating.threshold.label': 'Drempelwaarde',
  'archive.pdf.result.checkpoint.gb.orderValue': 'Orderwaarde',
  'archive.pdf.result.checkpoint.gb.orderValue.threshold.label': 'Drempelwaarde',
  'archive.pdf.result.checkpoint.nl.paymentsScore': 'Betaalscore',
  'archive.pdf.result.checkpoint.nl.paymentsScore.threshold.label': 'Drempelwaarde',
  'archive.pdf.result.checkpoint.nl.probabilityOfDefault': 'Kans op wanbetaling (pd%)',
  'archive.pdf.result.checkpoint.nl.probabilityOfDefault.threshold.label': 'Drempelwaarde',
  'archive.pdf.result.checkpoint.payments': 'Betaalgedrag',
  'archive.pdf.result.checkpoint.payments.threshold.label': 'Drempelwaarde',
  'archive.search.placeholder': 'Bedrijfsnaam of registratienummer',
  'archive.title': 'Archief',
  back: 'Terug',
  'back.to.dashboard': 'Terug naar dashboard',
  'banner.phaseout.internetexplorer.content':
    'Upgrade uw browser naar Mozilla Firefox, Google Chrome of Microsoft Edge voor een optimale gebruikservaring, nu en in de toekomst.',
  'banner.phaseout.internetexplorer.title': 'U gebruikt een verouderde browser: Internet Explorer',
  beforeAfterSelection: 'Voor/na',
  'branding.app': '{app}',
  'branding.subtitle': 'Voor {company}',
  'breadcrumb.company-search': 'Company Data',
  'breadcrumb.create-profile': 'Profiel aanmaken',
  'breadcrumb.credit-report': 'Credit Report',
  'breadcrumb.database-management': 'Database Management ',
  'breadcrumb.database-management.create-profile': 'Maak nieuw profiel aan',
  'breadcrumb.database-management.documentation': 'Documentatie',
  'breadcrumb.database-management.update-profile': 'Pas profiel aan',
  'breadcrumb.database-management.view-profile': 'Profiel tonen',
  'breadcrumb.decision-model': 'Decision Model Report',
  'breadcrumb.edit-profile': 'Profiel aanpassen',
  'breadcrumb.export-profile': 'Export',
  'breadcrumb.home': 'Home',
  'breadcrumb.international-credit-reports': 'International Business',
  'breadcrumb.international-credit-reports.archive': 'Archief',
  'breadcrumb.international-credit-reports.investigative-report': 'Bestel onderzoek',
  'breadcrumb.ledger-management': 'Ledger management',
  'breadcrumb.list-management': 'List management',
  'breadcrumb.list-profile': 'Lijstprofiel',
  'breadcrumb.monitoring-updates': 'Monitoring updates',
  'breadcrumb.my-portfolio': 'Mijn portfolios',
  'breadcrumb.organisation': 'Bedrijfsarchief',
  'breadcrumb.preferences': 'Voorkeuren',
  'breadcrumb.stakeholder-archive': 'Archief',
  'breadcrumb.stakeholder-report': 'Stakeholder Search',
  'breadcrumb.stakeholder-search': 'Stakeholder Search',
  'breadcrumb.ubo-report': 'UBO Rapport',
  'breadcrumb.unmatched-debtors': 'Niet gematchte debiteuren',
  'cd.modal.report-download-error.title': 'Er ging iets mis ...',
  'chart.amount.orders': 'orders',
  'chart.average.order.value': 'Gemiddelde orderwaarde',
  'coc.extracts.usage.download': 'Handelsregister uittreksels verbruik (.xlsx)',
  'coc.modal.buy': 'Koop uittreksel',
  'coc.modal.cached': 'Graydon-archief',
  'coc.modal.date': 'Datum',
  'coc.modal.download.error.message':
    'Probeert u het later nogmaals of neem contact op met Graydon',
  'coc.modal.download.error.title': 'Aanvraag kon niet worden verstuurd',
  'coc.modal.download.not-found.message':
    'Het uittreksel kan niet worden opgehaald. Neemt u contact op met Graydon.',
  'coc.modal.download.not-found.title': 'Uittreksel niet gevonden',
  'coc.modal.download.success.message':
    'Het Handelsregister uittreksel is gedownload en is beschikbaar in de downloadbalk en in het Bedrijfsarchief.',
  'coc.modal.download.success.title': 'Uittreksel is gedownload',
  'coc.modal.explanation':
    'Vraag een nieuw uittreksel aan of vraag een (goedkopere) gecachede versie aan vanuit het archief van Graydon.',
  'coc.modal.fetch-cached.error.message':
    'De lijst van gecachede uittreksels kon niet worden opgehaald. Probeert u het later nogmaals of contact Graydon.',
  'coc.modal.fetch-cached.error.title': 'De gegevens konden niet opgehaald worden',
  'coc.modal.link': 'Link',
  'coc.modal.new': 'Nieuw',
  'coc.modal.title': 'Vraag Handelsregister uittreksel aan',
  'coc.modal.today': 'Vandaag',
  'coc.modal.type': 'Type',
  'common.report-modal.open-in-new-tab': 'Altijd openen in een nieuw tabblad',
  companies: 'Bedrijven',
  company: 'Bedrijf',
  'company.contact.details': 'Contactgegevens',
  'company.email': 'E-mail adres',
  'company.entireAddress': 'Locatie (stad of straatnaam etc.)',
  'company.graydon.id': 'Graydon ID',
  'company.incorporation.date': 'Oprichtingsdatum',
  'company.legal.form': 'Rechtsvorm',
  'company.legal.forms': 'Rechtsvormen',
  'company.legal.forms.all': 'Alle rechtsvormen',
  'company.mobile.number': 'Mobiel/GSM nummer',
  'company.name': 'Bedrijfsnaam',
  'company.phone.number': 'Telefoonnummer',
  'company.register.number': 'Bedrijfsregistratienr.',
  'company.relationship.founder': 'Heeft als oprichter',
  'company.relationship.groupMember': 'Heeft als groepsonderdeel',
  'company.relationship.other': 'Heeft een andere relatie met',
  'company.relationship.parent': 'Moederbedrijf',
  'company.relationship.participation':
    'Heeft een {isMajorityStake, select, true{meerderheids} other{}} deelneming in',
  'company.relationship.shareholder':
    'Heeft als  {isMajorityStake, select, true{meerderheids} other{}} aandeelhouder',
  'company.relationship.ultimateParent': 'Uiteindelijke moederonderneming',
  'company.sectorCode': 'Sector code',
  'company.status': 'Bedrijfsstatus',
  'company.status.BANKRUPTCY': 'Failliet',
  'company.status.DEBT_RESTRUCTURING': 'Schuldsanering',
  'company.status.INACTIVE': 'Inactief',
  'company.status.MERGER': 'Fusie',
  'company.status.OWNER_DECEASED': 'Eigenaar overleden',
  'company.status.SPLIT_UP': 'Opsplitsing',
  'company.telefax.number': 'Fax nr.',
  'company.trade-names': 'Handelsnamen',
  'company.vat.number': 'Btw-nummer',
  'company.website': 'Website',
  'contact.customerService':
    '<a href="mailto:{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}">{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}</a> of op {country, select, gb {+44&nbsp;(0)20&nbsp;8515&nbsp;1400} nl {+31&nbsp;(020)&nbsp;567&nbsp;9501} other {+32&nbsp;(03)&nbsp;280&nbsp;88&nbsp;80}}',
  'contacts.text':
    'Als u problemen ervaart met het platform, vragen of suggesties heeft, of meer wil weten over onze producten, neem dan contact op met ons dedicated Customer Care team.',
  'contacts.title': 'Vragen?',
  'cookie.accept': 'Ik ga akkoord met het plaatsen van cookies',
  'cookie.message':
    'Graydon gebruikt cookies om uw gebruiksgemak van de website te verbeteren en om het gebruik van de website te analyseren.',
  'cookie.more-info': 'Meer informatie',
  'core.filterPlaceholder': 'Zoek',
  'core.tagging.add': 'Maak nieuwe lijst',
  'core.tagging.placeholder': 'Geef de lijst een naam',
  'core.treeSelector.collapseAll': 'Alles samenvoegen',
  'core.treeSelector.deselectResults': 'Deselecteer resultaten',
  'core.treeSelector.expandAll': 'Alles uitklappen',
  'core.treeSelector.expandSelected': 'Selectie uitklappen',
  'core.treeSelector.selectAll': 'Selecteer alles',
  'core.treeSelector.selectNone': 'Deselecteer alles',
  'core.treeSelector.selectResults': 'Selecteer resultaten',
  'country.be': 'België',
  'country.fr': 'Frankrijk',
  'country.gb': 'Het Verenigd Koninkrijk',
  'country.nl': 'Nederland',
  'country.uk': 'Het Verenigd Koninkrijk',
  'cr.action.button.addToList': 'Voeg toe aan lijst',
  'cr.action.button.addToMonitoring': 'Monitoring',
  'cr.action.button.addToMonitoring.back': 'Terug naar Credit Report',
  'cr.action.button.download': 'Download rapport',
  'cr.action.button.download.en-pdf': 'Engelse PDF',
  'cr.action.button.download.fr-pdf': 'Franse PDF',
  'cr.action.button.download.nl-pdf': 'Nederlandse PDF',
  'cr.action.button.request-new-report': 'Credit Report',
  'cr.action.button.share': 'Deel volledig rapport',
  'cr.action.buttons.title': 'Delen & Downloaden',
  'cr.add.company.to.default.list.error.body':
    'Er is een onverwachte fout opgetreden. Vernieuw de pagina of probeer het later opnieuw.',
  'cr.add.company.to.default.list.error.title': 'Er ging iets mis',
  'cr.add.company.to.default.list.success.body':
    'Dit bedrijf is toegevoegd aan de lijst "{listName}". ',
  'cr.add.company.to.default.list.success.title': 'Bedrijf toegevoegd aan een standaard lijst',
  'cr.affiliations.calamities': 'Financiële calamiteiten',
  'cr.affiliations.creditFlag.G': 'Geen bezwaar',
  'cr.affiliations.creditFlag.NA': 'Niet beschikbaar ',
  'cr.affiliations.creditFlag.O': 'Monitoring geadviseerd',
  'cr.affiliations.creditFlag.R': 'Niet geadviseerd ',
  'cr.affiliations.directors.active': 'Huidige bestuurders',
  'cr.affiliations.directors.active-calamity': 'Financiële calamiteit',
  'cr.affiliations.directors.address': 'Adres',
  'cr.affiliations.directors.birthDate': 'Geboortedatum',
  'cr.affiliations.directors.birthPlace': 'Geboorteplaats',
  'cr.affiliations.directors.calamities': 'Financiële calamiteit',
  'cr.affiliations.directors.calamity': 'Financiële calamiteit',
  'cr.affiliations.directors.collapse-calamity': 'Verberg financiële calamiteit',
  'cr.affiliations.directors.companyName': 'Bedrijfsnaam',
  'cr.affiliations.directors.entity.COMPANY': 'Leidinggevend Bedrijf',
  'cr.affiliations.directors.entity.PERSON': 'Leidinggevend Persoon',
  'cr.affiliations.directors.error.no-data.text':
    'Er is momenteel geen informatie beschikbaar over de bedrijfsleiding van dit bedrijf.<br /><br />Heeft u vragen of hulp nodig, contacteer dan ons Customer Service team via <a href="mailto:{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}">{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}</a> of op {country, select, gb {+44 (0)20 8515 1400} nl {+31 (020) 567 9501} other {+32 (03) 280 88 80}}.',
  'cr.affiliations.directors.function': 'Functietitel',
  'cr.affiliations.directors.inactive': 'Voorgaande bestuurders',
  'cr.affiliations.directors.jurisdiction': 'Bevoegdheid',
  'cr.affiliations.directors.name': 'Naam',
  'cr.affiliations.directors.otherFunctions': 'Overige functie(s)',
  'cr.affiliations.directors.otherFunctions.collapse': 'Verberg overige functie(s)',
  'cr.affiliations.directors.period': 'Start- en einddatum',
  'cr.affiliations.directors.presentDay': 'Heden',
  'cr.affiliations.directors.registration-number-be': 'Ondernemingsnummer',
  'cr.affiliations.directors.registration-number-gb': 'Bedrijfsregistratie nummer ',
  'cr.affiliations.directors.registration-number-nl': 'KvK-nummer',
  'cr.affiliations.directors.registrationNumber': 'Registratienummer',
  'cr.affiliations.directors.representedBy': 'Vast vertegenwoordiger rechtspersoon',
  'cr.affiliations.directors.serviceAddress': 'Dienstadres',
  'cr.affiliations.directors.tooltip':
    'Hier vindt u een overzicht van de bedrijfsleiders, hun relaties naar andere bedrijven en hun status.',
  'cr.affiliations.directors.tooltip.BE':
    'Hier vindt u een overzicht van de actieve bedrijfsleiders, met hun functie, hun benoemingstermijn en eventueel hun mandaten in andere bedrijven. Daarnaast krijgt u ook een overzicht van inactieve, voormalige bedrijfsleiders en de datum van ontslag.\nDeze informatie geeft u inzicht in het management van een bedrijf, zodat u weet wie er werkelijk aan de touwtjes trekt. Belangrijk, want er is een onmiskenbare link tussen de bedrijfsleiding en de prestaties van een bedrijf. Zeker in een kmo-land als België, waar kleine tot middelgrote bedrijven meestal slechts 1 tot 3 bedrijfsleiders in hun beheerraad hebben.',
  'cr.affiliations.directors.tooltip.GB':
    'Hier vindt u een overzicht van de actieve en inactieve bestuurders, hun bevoegdheden en de details van eventuele andere mandaten die zij bekleden.',
  'cr.affiliations.directors.tooltip.NL':
    'Hier vindt u een overzicht van de actieve en eventueel inactieve bestuurders, bijbehorende bevoegdheid en overige functies van deze bestuurders.',
  'cr.affiliations.directors.unknown': 'Onbekend',
  'cr.affiliations.gender.F': 'Mvr.',
  'cr.affiliations.gender.M': 'Dhr.',
  'cr.affiliations.group-structure.address': 'Adres',
  'cr.affiliations.group-structure.company-name': 'Bedrijfsnaam',
  'cr.affiliations.group-structure.error.no-data.text':
    'Er is momenteel geen informatie beschikbaar over de groepsstructuur van dit bedrijf.<br /><br />Heeft u vragen of hulp nodig, contacteer dan ons Customer Service team via <a href="mailto:{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}">{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}</a> of op {country, select, gb {+44&nbsp;(0)20&nbsp;8515&nbsp;1400} nl {+31&nbsp;(020)&nbsp;567&nbsp;9501} other {+32&nbsp;(03)&nbsp;280&nbsp;88&nbsp;80}}.',
  'cr.affiliations.group-structure.percentage': 'Percentage aandelen',
  'cr.affiliations.group-structure.period': 'Start- en einddatum',
  'cr.affiliations.group-structure.registration-number-be': 'Ondernemingsnummer',
  'cr.affiliations.group-structure.registration-number-gb': 'Bedrijfsregistratie nummer ',
  'cr.affiliations.group-structure.registration-number-nl': 'KvK-nummer',
  'cr.affiliations.group-structure.tooltip.BE':
    'Hier vindt u een overzicht van de actieve participaties / dochtermaatschappijen, met het percentage van de aandelen dat dit bedrijf in zijn bezit heeft (voor zover dit bekend is). Daarnaast krijgt u ook een overzicht van de dochterondernemingen waar dit bedrijf in het verleden een belang in had.',
  'cr.affiliations.group-structure.tooltip.GB':
    'Hier vindt u, indien van toepassing, een overzicht van de groepsstructuur.',
  'cr.affiliations.group-structure.tooltip.NL':
    'Hier vindt u een overzicht van het concern waarin het bedrijf zich bevind.',
  'cr.affiliations.group-structure.type.DAUGHTER': 'Dochtermaatschappij',
  'cr.affiliations.group-structure.type.MOTHER': 'Moedermaatschappij',
  'cr.affiliations.group-structure.type.PARTICIPANT': 'Participatie',
  'cr.affiliations.group-structure.type.SISTER': 'Zustermaatschappij',
  'cr.affiliations.group-structure.type.ULTIMATE_MOTHER': 'Uiteindelijke moedermaatschappij',
  'cr.affiliations.indicator': 'Indicator',
  'cr.affiliations.indicators': 'Indicatoren ',
  'cr.affiliations.no-insolvency': 'Geen financiële calamiteiten',
  'cr.affiliations.no.data.error.title': 'Deze informatie is niet beschikbaar voor dit bedrijf',
  'cr.affiliations.others.account-number': 'Rekeningnummer',
  'cr.affiliations.others.accountant': 'Accountants',
  'cr.affiliations.others.active-calamity': 'Financiële calamiteit',
  'cr.affiliations.others.address': 'Adres',
  'cr.affiliations.others.bank': 'Banks',
  'cr.affiliations.others.bank-name': 'Naam',
  'cr.affiliations.others.bankers': 'Banken',
  'cr.affiliations.others.collapse-calamity': 'Verberg Financiële calamiteit',
  'cr.affiliations.others.company-name': 'Bedrijfsnaam',
  'cr.affiliations.others.error.no-data.text':
    'Er zijn geen andere partijen voor dit bedrijf vermeld of de informatie is momenteel niet beschikbaar.<br /><br />Heeft u vragen of hulp nodig, contacteer dan ons Customer Service team via <a href="mailto:{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}">{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}</a> of op {country, select, gb {+44&nbsp;(0)20&nbsp;8515&nbsp;1400} nl {+31&nbsp;(020)&nbsp;567&nbsp;9501} other {+32&nbsp;(03)&nbsp;280&nbsp;88&nbsp;80}}.',
  'cr.affiliations.others.liability': 'Aansprakelijkheid',
  'cr.affiliations.others.liable-for': 'Aansprakelijk voor dit bedrijf',
  'cr.affiliations.others.name': 'Naam',
  'cr.affiliations.others.period': 'Start- en einddatum',
  'cr.affiliations.others.registration-number-nl': 'KvK-nummer',
  'cr.affiliations.others.tooltip.BE':
    'Hier vindt u een overzicht van de bankrekeningnummers die bij ons gekend zijn.',
  'cr.affiliations.others.tooltip.GB':
    'Hier vindt u nog meer informatie over de bedrijfsstructuur.',
  'cr.affiliations.others.tooltip.NL':
    'Hier vindt u overige informatie over de bedrijfsstructuur van het bedrijf. ',
  'cr.affiliations.psc.error.no-data.text':
    'Dit bedrijf heeft geen personen met zeggenschap of de informatie is momenteel niet beschikbaar.<br /><br />Heeft u vragen of hulp nodig, contacteer dan ons Customer Service team via <a href="mailto:{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}">{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}</a> of op {country, select, gb {+44&nbsp;(0)20&nbsp;8515&nbsp;1400} nl {+31&nbsp;(020)&nbsp;567&nbsp;9501} other {+32&nbsp;(03)&nbsp;280&nbsp;88&nbsp;80}}.',
  'cr.affiliations.psc.error.no-data.title': 'Geen personen met zeggenschap',
  'cr.affiliations.psc.natureOfControl': 'Aard van de controle',
  'cr.affiliations.psc.protected': 'Beschermde perso(o)n(en) met zeggenschap',
  'cr.affiliations.psc.title': 'Actieve personen met zeggenschap',
  'cr.affiliations.psc.tooltip.GB':
    'Hier vindt u alle actieve personen met zeggenschap in dit bedrijf. Een persoon met zeggenschap heeft meer dan 25% van de aandelen of stemrecht in een bedrijf, heeft het recht om de meerderheid van het bestuur aan te stellen of te ontslaan of heeft op andere wijze aanzienlijke zeggenschap. Vanaf 30 juni 2016 geldt voor bedrijven uit het Verenigd Koninkrijk, behalve voor beursgenoteerde bedrijven of bedrijven met een beperkte aansprakelijkheid, dat zij deze informatie moeten toevoegen aan de jaarlijkse ontvangstbevestiging bij Companies House. Personen met zeggenschap kunnen zakelijke, wettelijke of privépersonen betreffen. Let op, indien er sprake is van een vrijstelling of een beschermde persoon, kunnen details niet getoond worden. Dit vanwege beperkingen in het gebruik of het tonen van persoonsgegegens op basis van de wetgeving in sectie 790ZG toegepast voor dit bedrijf. ',
  'cr.affiliations.shareholders.active': 'Huidige aandeelhouders',
  'cr.affiliations.shareholders.active-calamity': 'Financiële calamiteit',
  'cr.affiliations.shareholders.address': 'Adres',
  'cr.affiliations.shareholders.collapse-calamity': 'Verberg Financiële calamiteit',
  'cr.affiliations.shareholders.company': 'Aandeelhouder',
  'cr.affiliations.shareholders.company-name': 'Bedrijfsnaam',
  'cr.affiliations.shareholders.date-of-birth': 'Geboortedatum',
  'cr.affiliations.shareholders.error.no-data.text':
    'Dit bedrijf heeft een rechtsvorm zonder aandeelhouders of de informatie is momenteel niet beschikbaar.<br /><br />Heeft u vragen of hulp nodig, contacteer dan ons Customer Service team via <a href="mailto:{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}">{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}</a> of op {country, select, gb {+44&nbsp;(0)20&nbsp;8515&nbsp;1400} nl {+31&nbsp;(020)&nbsp;567&nbsp;9501} other {+32&nbsp;(03)&nbsp;280&nbsp;88&nbsp;80}}.',
  'cr.affiliations.shareholders.error.no-data.title':
    'Deze informatie is niet beschikbaar voor dit bedrijf',
  'cr.affiliations.shareholders.inactive': 'Voorgaande aandeelhouders',
  'cr.affiliations.shareholders.percentage': 'Percentage aandelen',
  'cr.affiliations.shareholders.period': 'Start- & einddatum',
  'cr.affiliations.shareholders.period.present': 'Heden',
  'cr.affiliations.shareholders.period.unknown': 'Onbekend',
  'cr.affiliations.shareholders.person': 'Aandeelhouder',
  'cr.affiliations.shareholders.person-name': 'Naam',
  'cr.affiliations.shareholders.place-of-birth': 'Geboorteplaats',
  'cr.affiliations.shareholders.registration-number-be': 'Ondernemingsnummer',
  'cr.affiliations.shareholders.registration-number-gb': 'Bedrijfsregistratie nummer ',
  'cr.affiliations.shareholders.registration-number-nl': 'KvK-nummer',
  'cr.affiliations.shareholders.share': '{share, plural, =1 {aandeel} other {aandelen}}',
  'cr.affiliations.shareholders.shares': 'aandelen',
  'cr.affiliations.shareholders.tooltip.BE':
    'Hier vindt u een overzicht van de actieve aandeelhouders, met het percentage van de aandelen die zij in hun bezit hebben (voor zover dit bekend is). Daarnaast krijgt u ook een overzicht van inactieve, voormalige aandeelhouders.',
  'cr.affiliations.shareholders.tooltip.GB':
    'Hier vindt u een overzicht van huidige en vorige aandeelhouders.',
  'cr.affiliations.shareholders.tooltip.NL':
    'Hier vindt u een overzicht van de actieve en eventueel inactieve aandeelhouders.',
  'cr.affiliations.tab.directors': 'Bestuurders',
  'cr.affiliations.tab.group-structure': 'Groepsstructuur',
  'cr.affiliations.tab.other': 'Overige',
  'cr.affiliations.tab.psc': 'Personen met zeggenschap',
  'cr.affiliations.tab.shareholders': 'Aandeelhouders',
  'cr.annual-report.general-information.accountingPeriod': 'Boekjaar',
  'cr.annual-report.general-information.accountingPeriodEndDate': 'Afsluitdatum boekjaar',
  'cr.annual-report.general-information.accountingStandard': 'Boekhoudnorm',
  'cr.annual-report.general-information.annualMeetingDate': 'Datum algemene jaarvergadering',
  'cr.annual-report.general-information.balancedFlag': 'Jaarrekening sluitend',
  'cr.annual-report.general-information.balancedFlag.false': 'Nee',
  'cr.annual-report.general-information.balancedFlag.true': 'Ja',
  'cr.annual-report.general-information.consolidatedFlag': 'Type',
  'cr.annual-report.general-information.consolidatedFlag.false': 'Vennootschappelijk',
  'cr.annual-report.general-information.consolidatedFlag.true': 'Geconsolideerd',
  'cr.annual-report.general-information.description':
    'Informatie over het boekjaar waarop de gegevens betrekking hebben.',
  'cr.annual-report.general-information.description.BE':
    'Hier vindt u informatie terug over het boekjaar waarop de gegevens uit de jaarrekening betrekking hebben.',
  'cr.annual-report.general-information.description.GB':
    'Hier vindt u een overzicht van de jaarverslagen, weergegeven per boekjaar.',
  'cr.annual-report.general-information.description.NL':
    'Hier vindt u informatie terug over het boekjaar waarop de gegevens uit de jaarrekening betrekking op hebben.',
  'cr.annual-report.general-information.dormant': 'Slapend',
  'cr.annual-report.general-information.dormant.no': 'Nee',
  'cr.annual-report.general-information.dormant.yes': 'Ja',
  'cr.annual-report.general-information.filingDate': 'Gedeponeerd op',
  'cr.annual-report.general-information.model.BE_1': 'Verkort model voor ondernemingen (20)',
  'cr.annual-report.general-information.model.BE_1_19': 'Verkort model voor ondernemingen (20)',
  'cr.annual-report.general-information.model.BE_1_20': 'Verkort model voor ondernemingen (20)',
  'cr.annual-report.general-information.model.BE_10': 'Verkort model voor ondernemingen (23)',
  'cr.annual-report.general-information.model.BE_10_20': 'Verkort model voor ondernemingen (23)',
  'cr.annual-report.general-information.model.BE_11': 'Micromodel voor ondernemingen (73)',
  'cr.annual-report.general-information.model.BE_11_20': 'Micromodel voor ondernemingen (73)',
  'cr.annual-report.general-information.model.BE_12_21':
    'Micromodel voor microverenigingen en stichtingen (701)',
  'cr.annual-report.general-information.model.BE_2': 'Volledig model voor ondernemingen (40)',
  'cr.annual-report.general-information.model.BE_2_19': 'Volledig model voor ondernemingen (40)',
  'cr.annual-report.general-information.model.BE_2_20': 'Volledig model voor ondernemingen (40)',
  'cr.annual-report.general-information.model.BE_4': 'Micromodel voor ondernemingen (70)',
  'cr.annual-report.general-information.model.BE_4_19': 'Micromodel voor ondernemingen (70)',
  'cr.annual-report.general-information.model.BE_4_20': 'Micromodel voor ondernemingen (70)',
  'cr.annual-report.general-information.model.BE_5': 'Verkort model voor verenigingen (201)',
  'cr.annual-report.general-information.model.BE_5_13': 'Verkort model voor verenigingen (201)',
  'cr.annual-report.general-information.model.BE_6': 'Volledig model voor verenigingen (401)',
  'cr.annual-report.general-information.model.BE_6_13': 'Volledig model voor verenigingen (401)',
  'cr.annual-report.general-information.model.BE_8': 'Specifiek model schema B (Natuur10)',
  'cr.annual-report.general-information.model.BE_9': 'Volledig model voor ondernemingen (43)',
  'cr.annual-report.general-information.model.BE_9_20': 'Volledig model voor ondernemingen (43)',
  'cr.annual-report.general-information.modelType': 'Model',
  'cr.annual-report.general-information.preliminaryFlag': 'Voorlopig of definitief',
  'cr.annual-report.general-information.preliminaryFlag.false': 'Definitief',
  'cr.annual-report.general-information.preliminaryFlag.true': 'Voorlopig',
  'cr.annual-report.general-information.title': 'Algemene informatie jaarrekeningen',
  'cr.annual.report.annual-accounts.collapse-all': 'Verberg details',
  'cr.annual.report.annual-accounts.expand-all': 'Toon details',
  'cr.annual.report.balance-sheet.BE_ACTIVA': 'ACTIVA',
  'cr.annual.report.balance-sheet.BE_B_167':
    'Voorzieningen voor terug te betalen subsidies en legaten en voor schenkingen met terugnemingsrecht',
  'cr.annual.report.balance-sheet.BE_B_AST_20': 'OPRICHTINGSKOSTEN (20)',
  'cr.annual.report.balance-sheet.BE_B_AST_2028': 'VASTE ACTIVA (2028)',
  'cr.annual.report.balance-sheet.BE_B_AST_2058': 'TOTAAL VAN DE ACTIVA (2058) ',
  'cr.annual.report.balance-sheet.BE_B_AST_2128': 'VASTE ACTIVA (2128) ',
  'cr.annual.report.balance-sheet.BE_B_AST_2958': 'VLOTTENDE ACTIVA (2958) ',
  'cr.annual.report.balance-sheet.BE_B_AST_C_29': 'Vorderingen op meer dan één jaar (29)',
  'cr.annual.report.balance-sheet.BE_B_AST_C_3': 'Voorraden en bestellingen in uitvoering (3)',
  'cr.annual.report.balance-sheet.BE_B_AST_C_4041': 'Vorderingen op ten hoogste één jaar (4041) ',
  'cr.annual.report.balance-sheet.BE_B_AST_C_4901': 'Overlopende rekeningen (4901) ',
  'cr.annual.report.balance-sheet.BE_B_AST_C_5053': 'Geldbeleggingen (5053)',
  'cr.annual.report.balance-sheet.BE_B_AST_C_5458': 'Liquide middelen (5458) ',
  'cr.annual.report.balance-sheet.BE_B_AST_CI_50': 'Eigen aandelen (50)',
  'cr.annual.report.balance-sheet.BE_B_AST_CI_5153': 'Overige beleggingen (5153)',
  'cr.annual.report.balance-sheet.BE_B_AST_COS_3031': 'Grond- en hulpstoffen (3031)',
  'cr.annual.report.balance-sheet.BE_B_AST_COS_32': 'Goederen in bewerking (32)',
  'cr.annual.report.balance-sheet.BE_B_AST_COS_33': 'Gereed product (33)',
  'cr.annual.report.balance-sheet.BE_B_AST_COS_34': 'Handelsgoederen (34)',
  'cr.annual.report.balance-sheet.BE_B_AST_COS_35': 'Onroerende goederen bestemd voor verkoop (35)',
  'cr.annual.report.balance-sheet.BE_B_AST_COS_36': 'Vooruitbetalingen (36)',
  'cr.annual.report.balance-sheet.BE_B_AST_CRL_290': 'Handelsvorderingen (290)',
  'cr.annual.report.balance-sheet.BE_B_AST_CRL_291': 'Overige vorderingen (291)',
  'cr.annual.report.balance-sheet.BE_B_AST_CRLO_2915':
    'Waarvan niet-rentedragende vorderingen of gekoppeld aan een abnormaal lage rente (2915)',
  'cr.annual.report.balance-sheet.BE_B_AST_CRS_40': 'Handelsvorderingen (40) ',
  'cr.annual.report.balance-sheet.BE_B_AST_CRS_41': 'Overige vorderingen (41) ',
  'cr.annual.report.balance-sheet.BE_B_AST_CRSO_415':
    'waarvan niet-rentedragende vorderingen of gekoppeld aan een abnormaal lage rente (415)',
  'cr.annual.report.balance-sheet.BE_B_AST_CS_3036': 'Voorraden (3036)',
  'cr.annual.report.balance-sheet.BE_B_AST_CS_37': 'Bestellingen in uitvoering (37)',
  'cr.annual.report.balance-sheet.BE_B_AST_F_21': 'Immateriële vaste activa (21) ',
  'cr.annual.report.balance-sheet.BE_B_AST_F_2227': 'Materiële vaste activa (2227) ',
  'cr.annual.report.balance-sheet.BE_B_AST_F_28': 'Financiële vaste activa (28) ',
  'cr.annual.report.balance-sheet.BE_B_AST_FF_2801': 'Verbonden ondernemingen (2801)',
  'cr.annual.report.balance-sheet.BE_B_AST_FF_2823':
    'Ondernemingen waarmee een deelnemingsverhouding bestaat (2823)',
  'cr.annual.report.balance-sheet.BE_B_AST_FF_2848': 'Andere financiële vaste activa (2848) ',
  'cr.annual.report.balance-sheet.BE_B_AST_FFC_280': 'Deelnemingen (280)',
  'cr.annual.report.balance-sheet.BE_B_AST_FFC_281': 'Vorderingen (281)',
  'cr.annual.report.balance-sheet.BE_B_AST_FFO_284': 'Aandelen (284)',
  'cr.annual.report.balance-sheet.BE_B_AST_FFO_2858':
    'Vorderingen en borgtochten in contanten (2858) ',
  'cr.annual.report.balance-sheet.BE_B_AST_FFP_282': 'Deelnemingen (282)',
  'cr.annual.report.balance-sheet.BE_B_AST_FFP_283': 'Vorderingen (283)',
  'cr.annual.report.balance-sheet.BE_B_AST_FT_22': 'Terreinen en gebouwen (22)',
  'cr.annual.report.balance-sheet.BE_B_AST_FT_23': 'Installaties, machines en uitrusting (23) ',
  'cr.annual.report.balance-sheet.BE_B_AST_FT_24': 'Meubilair en rollend materieel (24) ',
  'cr.annual.report.balance-sheet.BE_B_AST_FT_25': 'Leasing en soortgelijke rechten (25)',
  'cr.annual.report.balance-sheet.BE_B_AST_FT_26': 'Overige materiële vaste activa (26) ',
  'cr.annual.report.balance-sheet.BE_B_AST_FT_27': 'Activa in aanbouw en vooruitbetalingen (27)',
  'cr.annual.report.balance-sheet.BE_B_AST_FTI_231':
    'In volle eigendom van de vereniging of stichting (231)',
  'cr.annual.report.balance-sheet.BE_B_AST_FTI_232': 'Overige (232)',
  'cr.annual.report.balance-sheet.BE_B_AST_FTI_241':
    'In volle eigendom van de vereniging of stichting (241)',
  'cr.annual.report.balance-sheet.BE_B_AST_FTI_242': 'Overige (242)',
  'cr.annual.report.balance-sheet.BE_B_AST_FTO_261':
    'In volle eigendom van de vereniging of stichting (261)',
  'cr.annual.report.balance-sheet.BE_B_AST_FTO_262': 'Overige (262)',
  'cr.annual.report.balance-sheet.BE_B_AST_FTT_2291':
    'In volle eigendom van de vereniging of stichting (2291)',
  'cr.annual.report.balance-sheet.BE_B_AST_FTT_2292': 'Overige (2292)',
  'cr.annual.report.balance-sheet.BE_B_LBL_1011': 'Inbreng (1011)',
  'cr.annual.report.balance-sheet.BE_B_LBL_1015': 'EIGEN VERMOGEN (1015) ',
  'cr.annual.report.balance-sheet.BE_B_LBL_1049': 'TOTAAL VAN DE PASSIVA (1049)',
  'cr.annual.report.balance-sheet.BE_B_LBL_110': 'Beschikbaar (110)',
  'cr.annual.report.balance-sheet.BE_B_LBL_111': 'Onbeschikbaar (111)',
  'cr.annual.report.balance-sheet.BE_B_LBL_16': 'VOORZIENINGEN EN UITGESTELDE BELASTINGEN (16) ',
  'cr.annual.report.balance-sheet.BE_B_LBL_1749': 'SCHULDEN (1749) ',
  'cr.annual.report.balance-sheet.BE_B_LBL_D_17': 'Schulden op meer dan één jaar (17)',
  'cr.annual.report.balance-sheet.BE_B_LBL_D_1789': 'Overige schulden (1789)',
  'cr.annual.report.balance-sheet.BE_B_LBL_D_4248': 'Schulden op ten hoogste één jaar (4248) ',
  'cr.annual.report.balance-sheet.BE_B_LBL_D_4923': 'Overlopende rekeningen (4923)',
  'cr.annual.report.balance-sheet.BE_B_LBL_DL_1704': 'Financiële schulden (1704)',
  'cr.annual.report.balance-sheet.BE_B_LBL_DL_175': 'Handelsschulden (175)',
  'cr.annual.report.balance-sheet.BE_B_LBL_DL_176':
    'Ontvangen vooruitbetalingen op bestellingen (176)',
  'cr.annual.report.balance-sheet.BE_B_LBL_DL_179': 'Overige schulden (179)',
  'cr.annual.report.balance-sheet.BE_B_LBL_DLF_170': 'Achtergestelde leningen (170)',
  'cr.annual.report.balance-sheet.BE_B_LBL_DLF_171': 'Niet-achtergestelde obligatieleningen (171)',
  'cr.annual.report.balance-sheet.BE_B_LBL_DLF_172':
    'Leasingschulden en soortgelijke schulden (172)',
  'cr.annual.report.balance-sheet.BE_B_LBL_DLF_1723':
    'Kredietinstellingen, leasingschulden en soortgelijke schulden (1723)',
  'cr.annual.report.balance-sheet.BE_B_LBL_DLF_173': 'Kredietinstellingen (173)',
  'cr.annual.report.balance-sheet.BE_B_LBL_DLF_174': 'Overige leningen (174)',
  'cr.annual.report.balance-sheet.BE_B_LBL_DLF_1740': 'Overige leningen (1740)',
  'cr.annual.report.balance-sheet.BE_B_LBL_DLO_1790': 'Rentedragend (1790)',
  'cr.annual.report.balance-sheet.BE_B_LBL_DLO_1791':
    'Niet-rentedragend of gekoppeld aan een abnormaal lage rente (1791)',
  'cr.annual.report.balance-sheet.BE_B_LBL_DLO_1792': 'Borgtochten ontvangen in contanten (1792)',
  'cr.annual.report.balance-sheet.BE_B_LBL_DLTR_1750': 'Leveranciers (1750)',
  'cr.annual.report.balance-sheet.BE_B_LBL_DLTR_1751': 'Te betalen wissels (1751)',
  'cr.annual.report.balance-sheet.BE_B_LBL_DS_42':
    'Schulden op meer dan één jaar die binnen het jaar vervallen (42)',
  'cr.annual.report.balance-sheet.BE_B_LBL_DS_43': 'Financiële schulden (43) ',
  'cr.annual.report.balance-sheet.BE_B_LBL_DS_44': 'Handelsschulden (44)',
  'cr.annual.report.balance-sheet.BE_B_LBL_DS_45':
    'Schulden met betrekking tot belastingen, bezoldigingen en sociale lasten (45)',
  'cr.annual.report.balance-sheet.BE_B_LBL_DS_46':
    'Ontvangen vooruitbetalingen op bestellingen (46)',
  'cr.annual.report.balance-sheet.BE_B_LBL_DS_4748': 'Overige schulden (4748)',
  'cr.annual.report.balance-sheet.BE_B_LBL_DS_48': 'Diverse schulden (48)',
  'cr.annual.report.balance-sheet.BE_B_LBL_DSF_4308': 'Kredietinstellingen (4308)',
  'cr.annual.report.balance-sheet.BE_B_LBL_DSF_439': 'Overige leningen (439)',
  'cr.annual.report.balance-sheet.BE_B_LBL_DST_4503': 'Belastingen (4503)',
  'cr.annual.report.balance-sheet.BE_B_LBL_DST_4549': 'Bezoldigingen en sociale lasten (4549)',
  'cr.annual.report.balance-sheet.BE_B_LBL_DSTR_4404': 'Leveranciers (4404)',
  'cr.annual.report.balance-sheet.BE_B_LBL_DSTR_441': 'Te betalen wissels (441)',
  'cr.annual.report.balance-sheet.BE_B_LBL_DSV_4808':
    'Vervallen obligaties en coupons, terug te betalen subsidies en borgtochten ontvangen in contanten (4808)',
  'cr.annual.report.balance-sheet.BE_B_LBL_DSV_4890': 'Andere rentedragende schulden (4890)',
  'cr.annual.report.balance-sheet.BE_B_LBL_DSV_4891':
    'Andere schulden, niet-rentedragend of gekoppeld aan een abnormaal lage rente (4891)',
  'cr.annual.report.balance-sheet.BE_B_LBL_E_10': 'Kapitaal (10) ',
  'cr.annual.report.balance-sheet.BE_B_LBL_E_11': 'Uitgiftepremies (11)',
  'cr.annual.report.balance-sheet.BE_B_LBL_E_12': 'Herwaarderingsmeerwaarden (12)',
  'cr.annual.report.balance-sheet.BE_B_LBL_E_13': 'Reserves (13) ',
  'cr.annual.report.balance-sheet.BE_B_LBL_E_14': 'Overgedragen winst (verlies) (14)',
  'cr.annual.report.balance-sheet.BE_B_LBL_E_15': 'Kapitaalsubsidies (15)',
  'cr.annual.report.balance-sheet.BE_B_LBL_E_19':
    'Voorschot aan de vennoten op de verdeling van het netto-actief (19)',
  'cr.annual.report.balance-sheet.BE_B_LBL_EC_100': 'Geplaatst kapitaal (100) ',
  'cr.annual.report.balance-sheet.BE_B_LBL_EC_101': 'Niet-opgevraagd kapitaal\r\n (101)',
  'cr.annual.report.balance-sheet.BE_B_LBL_ER_130': 'Wettelijke reserve (130)',
  'cr.annual.report.balance-sheet.BE_B_LBL_ER_1301': 'Onbeschikbare reserves (1301)',
  'cr.annual.report.balance-sheet.BE_B_LBL_ER_131': 'Onbeschikbare reserves  (131)',
  'cr.annual.report.balance-sheet.BE_B_LBL_ER_132': 'Belastingvrije reserves (132) ',
  'cr.annual.report.balance-sheet.BE_B_LBL_ER_133': 'Beschikbare reserves (133) ',
  'cr.annual.report.balance-sheet.BE_B_LBL_ERU_1310': 'Voor eigen aandelen (1310)',
  'cr.annual.report.balance-sheet.BE_B_LBL_ERU_1311': 'Andere (1311)',
  'cr.annual.report.balance-sheet.BE_B_LBL_ERU_1312': 'Inkoop eigen aandelen (1312)',
  'cr.annual.report.balance-sheet.BE_B_LBL_ERU_1313': 'Financiële steunverlening (1313)',
  'cr.annual.report.balance-sheet.BE_B_LBL_ERU_1319': 'Overige (1319)',
  'cr.annual.report.balance-sheet.BE_B_LBL_P_1605': "Voorzieningen voor risico's en kosten (1605) ",
  'cr.annual.report.balance-sheet.BE_B_LBL_P_168':
    'Voorzieningen voor terug te betalen subsidies en legaten en voor schenkingen met terugnemingsrecht (168)',
  'cr.annual.report.balance-sheet.BE_B_LBL_PR_160':
    'Pensioenen en soortgelijke verplichtingen (160)',
  'cr.annual.report.balance-sheet.BE_B_LBL_PR_161': 'Fiscale lasten (161)',
  'cr.annual.report.balance-sheet.BE_B_LBL_PR_162': 'Grote herstellings- en onderhoudswerken (162)',
  'cr.annual.report.balance-sheet.BE_B_LBL_PR_163': 'Milieuverplichtingen (163)',
  'cr.annual.report.balance-sheet.BE_B_LBL_PR_1635': "Overige risico's en kosten (1635)",
  'cr.annual.report.balance-sheet.BE_B_LBL_PR_1645': "Overige risico's en kosten (1645)",
  'cr.annual.report.balance-sheet.BE_PASSIVA': 'PASSIVA',
  'cr.annual.report.balance-sheet.current-assets': 'Vlottende activa',
  'cr.annual.report.balance-sheet.current-liabilites': 'Kortlopende schulden',
  'cr.annual.report.balance-sheet.fixed-assets': 'Vaste activa',
  'cr.annual.report.balance-sheet.GB_AST_00_00_001': 'Werkkapitaal',
  'cr.annual.report.balance-sheet.GB_AST_00_00_002': 'Aangewend kapitaal',
  'cr.annual.report.balance-sheet.GB_AST_00_00_003': 'Totaal netto activa',
  'cr.annual.report.balance-sheet.GB_AST_00_00_004': 'Netto activa',
  'cr.annual.report.balance-sheet.GB_AST_00_00_005': 'Nettowaarde',
  'cr.annual.report.balance-sheet.GB_AST_01': 'Totaal vaste activa',
  'cr.annual.report.balance-sheet.GB_AST_02': 'Totaal immateriële activa',
  'cr.annual.report.balance-sheet.GB_AST_03': 'Totale materiële activa',
  'cr.annual.report.balance-sheet.GB_AST_03_01': 'Terreinen en gebouwen',
  'cr.annual.report.balance-sheet.GB_AST_03_01_01': 'Eigendommen',
  'cr.annual.report.balance-sheet.GB_AST_03_01_02': 'Erfpacht',
  'cr.annual.report.balance-sheet.GB_AST_03_02': 'Inrichting gebouwen',
  'cr.annual.report.balance-sheet.GB_AST_03_03': 'Installaties en voertuigen',
  'cr.annual.report.balance-sheet.GB_AST_03_04': 'Activa in aanbouw',
  'cr.annual.report.balance-sheet.GB_AST_03_05': 'Andere materiële activa',
  'cr.annual.report.balance-sheet.GB_AST_04': 'Totaal financiële activa',
  'cr.annual.report.balance-sheet.GB_AST_04_01': 'Financiële leases / Huurkoop',
  'cr.annual.report.balance-sheet.GB_AST_04_02': 'Groepsleningen',
  'cr.annual.report.balance-sheet.GB_AST_04_03': 'Leningen bestuurders',
  'cr.annual.report.balance-sheet.GB_AST_04_04': 'Andere leningen',
  'cr.annual.report.balance-sheet.GB_AST_04_05': 'Andere financiële activa',
  'cr.annual.report.balance-sheet.GB_AST_05': 'Andere vaste activa / Niet-courant',
  'cr.annual.report.balance-sheet.GB_AST_05_01': 'Langetermijnhandel',
  'cr.annual.report.balance-sheet.GB_AST_05_02': 'Partner / Vennoot / Filiaal',
  'cr.annual.report.balance-sheet.GB_AST_05_03': 'Activa vastgehouden voor doorverkoop',
  'cr.annual.report.balance-sheet.GB_AST_05_04': 'Andere',
  'cr.annual.report.balance-sheet.GB_AST_06': 'Totaal vlottende activa',
  'cr.annual.report.balance-sheet.GB_AST_07': 'Totaal voorraden',
  'cr.annual.report.balance-sheet.GB_AST_07_01': 'Voorraden / Grondstoffen',
  'cr.annual.report.balance-sheet.GB_AST_07_02': 'Werk in uitvoering',
  'cr.annual.report.balance-sheet.GB_AST_07_03': 'Afgewerkte producten',
  'cr.annual.report.balance-sheet.GB_AST_08': 'Totaal financiële activa',
  'cr.annual.report.balance-sheet.GB_AST_08_01': 'Financiële Leases / Huurkoop',
  'cr.annual.report.balance-sheet.GB_AST_08_02': 'Groepsleningen',
  'cr.annual.report.balance-sheet.GB_AST_08_03': 'Leningen bestuurders',
  'cr.annual.report.balance-sheet.GB_AST_08_04': 'Andere leningen',
  'cr.annual.report.balance-sheet.GB_AST_08_05': 'Andere financiële activa',
  'cr.annual.report.balance-sheet.GB_AST_10': 'Totaal kasgeld',
  'cr.annual.report.balance-sheet.GB_AST_10_01': 'Liquide middelen bij de bank / in kassa',
  'cr.annual.report.balance-sheet.GB_AST_10_02': 'Kasequivalenten',
  'cr.annual.report.balance-sheet.GB_AST_11': 'Totaal debiteuren',
  'cr.annual.report.balance-sheet.GB_AST_11_01': 'Vorderingen / Handelsvorderingen',
  'cr.annual.report.balance-sheet.GB_AST_11_02': 'Partner / Vennoot / Filiaal',
  'cr.annual.report.balance-sheet.GB_AST_11_03': 'Groepsleningen',
  'cr.annual.report.balance-sheet.GB_AST_11_04': 'Leningen bestuurders',
  'cr.annual.report.balance-sheet.GB_AST_11_05': 'Voorfinancieringen / Overlopende rekeningen',
  'cr.annual.report.balance-sheet.GB_AST_11_06': 'Andere debiteuren',
  'cr.annual.report.balance-sheet.GB_AST_13': 'Andere vlottende activa',
  'cr.annual.report.balance-sheet.GB_AST_13_01': 'Activa vastgehouden voor doorverkoop',
  'cr.annual.report.balance-sheet.GB_AST_13_02': 'Andere',
  'cr.annual.report.balance-sheet.GB_AST_14': 'Totaal investeringsactiva',
  'cr.annual.report.balance-sheet.GB_AST_14_01': 'Eigendommen',
  'cr.annual.report.balance-sheet.GB_AST_14_02': 'Partner / Vennoot / Filiaal',
  'cr.annual.report.balance-sheet.GB_AST_14_03': 'Andere investeringen',
  'cr.annual.report.balance-sheet.GB_AST_15': 'Totaal van de activa',
  'cr.annual.report.balance-sheet.GB_LBL_01': 'Totaal eigen vermogen',
  'cr.annual.report.balance-sheet.GB_LBL_01_01': 'Geplaatst kapitaal',
  'cr.annual.report.balance-sheet.GB_LBL_01_01_01': 'Regulier',
  'cr.annual.report.balance-sheet.GB_LBL_01_01_02': 'Voorkeur',
  'cr.annual.report.balance-sheet.GB_LBL_01_01_03': 'Andere',
  'cr.annual.report.balance-sheet.GB_LBL_01_02': 'Rekening uitgiftepremies',
  'cr.annual.report.balance-sheet.GB_LBL_01_03': 'Rente in eigen aandelen',
  'cr.annual.report.balance-sheet.GB_LBL_01_04': 'Ingehouden winst',
  'cr.annual.report.balance-sheet.GB_LBL_01_05': 'Herwaarderingsreserve',
  'cr.annual.report.balance-sheet.GB_LBL_01_06': 'Valuta omrekeningsreserve',
  'cr.annual.report.balance-sheet.GB_LBL_01_07': 'Andere reserves',
  'cr.annual.report.balance-sheet.GB_LBL_01.NET-ASSET': 'Netto activa',
  'cr.annual.report.balance-sheet.GB_LBL_12': 'Totaal voorzieningen',
  'cr.annual.report.balance-sheet.GB_LBL_12_01': 'Uitgestelde belastingen',
  'cr.annual.report.balance-sheet.GB_LBL_12_02': 'Pensioen',
  'cr.annual.report.balance-sheet.GB_LBL_12_03': 'Andere voorzieningen',
  'cr.annual.report.balance-sheet.GB_LBL_13': 'Totaal langlopende schulden',
  'cr.annual.report.balance-sheet.GB_LBL_13_01': 'Totaal financiële verplichtingen',
  'cr.annual.report.balance-sheet.GB_LBL_13_01_01': 'Financiële leases / Huurkoop',
  'cr.annual.report.balance-sheet.GB_LBL_13_01_01_01': 'Financiële leases',
  'cr.annual.report.balance-sheet.GB_LBL_13_01_01_02': 'Huurkoop',
  'cr.annual.report.balance-sheet.GB_LBL_13_01_02': 'Groepsleningen',
  'cr.annual.report.balance-sheet.GB_LBL_13_01_03': 'Leningen bestuurders',
  'cr.annual.report.balance-sheet.GB_LBL_13_01_04': 'Subsidies',
  'cr.annual.report.balance-sheet.GB_LBL_13_01_05': 'Andere langlopende leningen',
  'cr.annual.report.balance-sheet.GB_LBL_13_01_06': 'Andere financiële verplichtingen',
  'cr.annual.report.balance-sheet.GB_LBL_13_02': 'Andere langlopende schulden',
  'cr.annual.report.balance-sheet.GB_LBL_13_02_01': 'Lange termijnhandel',
  'cr.annual.report.balance-sheet.GB_LBL_13_02_02': 'Partner / Vennoot / Filiaal',
  'cr.annual.report.balance-sheet.GB_LBL_13_02_03': 'Groepsleningen',
  'cr.annual.report.balance-sheet.GB_LBL_13_02_04': 'Leningen bestuurders',
  'cr.annual.report.balance-sheet.GB_LBL_13_02_05': 'Passiva vastgehouden voor doorverkoop',
  'cr.annual.report.balance-sheet.GB_LBL_13_02_06': 'Overlopende rekeningen & Uitgesteld inkomen',
  'cr.annual.report.balance-sheet.GB_LBL_13_02_07': 'Preferente aandelen',
  'cr.annual.report.balance-sheet.GB_LBL_13_02_08': 'Andere',
  'cr.annual.report.balance-sheet.GB_LBL_15': 'Totaal kortlopende schulden',
  'cr.annual.report.balance-sheet.GB_LBL_15_01': 'Totaal schuldeisers',
  'cr.annual.report.balance-sheet.GB_LBL_15_01_01': 'Handelsschulden / Handelscrediteuren',
  'cr.annual.report.balance-sheet.GB_LBL_15_01_02': 'Partner / Vennoot / Filiaal',
  'cr.annual.report.balance-sheet.GB_LBL_15_01_03': 'Groepsleningen',
  'cr.annual.report.balance-sheet.GB_LBL_15_01_04': 'Leningen bestuurders',
  'cr.annual.report.balance-sheet.GB_LBL_15_01_05': 'Overlopende rekeningen & Uitgesteld inkomen',
  'cr.annual.report.balance-sheet.GB_LBL_15_01_06': 'Sociale zekerheid / BTW',
  'cr.annual.report.balance-sheet.GB_LBL_15_01_07': 'Te betalen belastingen',
  'cr.annual.report.balance-sheet.GB_LBL_15_01_08': 'Andere schuldeisers',
  'cr.annual.report.balance-sheet.GB_LBL_15_02': 'Totaal financiële verplichtingen',
  'cr.annual.report.balance-sheet.GB_LBL_15_02_01': 'Voorschot in rekening-courant',
  'cr.annual.report.balance-sheet.GB_LBL_15_02_02': 'Financiële leases / Huurkoop',
  'cr.annual.report.balance-sheet.GB_LBL_15_02_02_01': 'Financiële leases / Huurkoop',
  'cr.annual.report.balance-sheet.GB_LBL_15_02_02_02': 'Huurkoop',
  'cr.annual.report.balance-sheet.GB_LBL_15_02_03': 'Groepsleningen',
  'cr.annual.report.balance-sheet.GB_LBL_15_02_04': 'Leningen bestuurders',
  'cr.annual.report.balance-sheet.GB_LBL_15_02_05': 'Subsidies',
  'cr.annual.report.balance-sheet.GB_LBL_15_02_06': 'Andere kortetermijnleningen',
  'cr.annual.report.balance-sheet.GB_LBL_15_02_07': 'Andere financiële verplichtingen',
  'cr.annual.report.balance-sheet.GB_LBL_15_03': 'Andere kortlopende schulden',
  'cr.annual.report.balance-sheet.GB_LBL_15_03_01': 'Passiva vastgehouden voor doorverkoop',
  'cr.annual.report.balance-sheet.GB_LBL_15_03_02': 'Andere',
  'cr.annual.report.balance-sheet.GB_LBL_15_04': 'Dividenden',
  'cr.annual.report.balance-sheet.GB_LBL_20': 'Minoriteitsbelangen',
  'cr.annual.report.balance-sheet.long-term-liabilities': 'Langlopende schulden',
  'cr.annual.report.balance-sheet.NL_ACC_01': 'Balanstotaal',
  'cr.annual.report.balance-sheet.NL_AST_10_00_001': 'Totaal vaste activa',
  'cr.annual.report.balance-sheet.NL_AST_10_00_002': 'Overige vaste activa',
  'cr.annual.report.balance-sheet.NL_AST_10_10_001': 'Immateriële vaste activa',
  'cr.annual.report.balance-sheet.NL_AST_10_10_002':
    'Kosten van oprichting en uitgifte van aandelen',
  'cr.annual.report.balance-sheet.NL_AST_10_10_003': 'Kosten van onderzoek en ontwikkeling',
  'cr.annual.report.balance-sheet.NL_AST_10_10_004':
    'Verwervingskosten, consessies, vergunningen en intellectuele rechten',
  'cr.annual.report.balance-sheet.NL_AST_10_10_005': 'Goodwill',
  'cr.annual.report.balance-sheet.NL_AST_10_10_006': 'Vooruitbetaald op immateriële vaste activa',
  'cr.annual.report.balance-sheet.NL_AST_10_20_001': 'Materiële vaste activa',
  'cr.annual.report.balance-sheet.NL_AST_10_20_002': 'Bedrijfsgebouwen en -terreinen',
  'cr.annual.report.balance-sheet.NL_AST_10_20_003': 'Machines en installaties',
  'cr.annual.report.balance-sheet.NL_AST_10_20_004': 'Andere vaste bedrijfsmiddelen',
  'cr.annual.report.balance-sheet.NL_AST_10_20_005':
    'Vaste bedrijfsmiddelen in uitvoering en vooruitbetaald op MVA',
  'cr.annual.report.balance-sheet.NL_AST_10_20_006': 'Niet aan bedrijfsuitoefening dienstbaar',
  'cr.annual.report.balance-sheet.NL_AST_10_30_001': 'Financiële vaste activa',
  'cr.annual.report.balance-sheet.NL_AST_10_30_002': 'Deelnemingen in groepsmaatschappijen',
  'cr.annual.report.balance-sheet.NL_AST_10_30_003': 'Vorderingen op groepsmaatschappijen',
  'cr.annual.report.balance-sheet.NL_AST_10_30_004': 'Andere deelnemingen',
  'cr.annual.report.balance-sheet.NL_AST_10_30_005': 'Vorderingen op participanten en deelnemingen',
  'cr.annual.report.balance-sheet.NL_AST_10_30_006': 'Overige effecten',
  'cr.annual.report.balance-sheet.NL_AST_20_00_001': 'Totaal vlottende activa',
  'cr.annual.report.balance-sheet.NL_AST_20_00_002': 'Overige vlottende activa',
  'cr.annual.report.balance-sheet.NL_AST_20_10_001': 'Voorraden',
  'cr.annual.report.balance-sheet.NL_AST_20_10_002': 'Grond en -hulpstoffen',
  'cr.annual.report.balance-sheet.NL_AST_20_10_003': 'Onderhanden werk',
  'cr.annual.report.balance-sheet.NL_AST_20_10_004': 'Gereed product en handelsgoederen',
  'cr.annual.report.balance-sheet.NL_AST_20_10_005': 'Vooruitbetaald op voorraad',
  'cr.annual.report.balance-sheet.NL_AST_20_30_001': 'Vorderingen',
  'cr.annual.report.balance-sheet.NL_AST_20_30_002': 'Handelsdebiteuren',
  'cr.annual.report.balance-sheet.NL_AST_20_30_003': 'Groepsmaatschappijen',
  'cr.annual.report.balance-sheet.NL_AST_20_30_004': 'Participanten en deelnemingen',
  'cr.annual.report.balance-sheet.NL_AST_20_30_005': 'Overige vorderingen',
  'cr.annual.report.balance-sheet.NL_AST_20_30_006': 'Van aandeelhouders opgevraagde stortingen',
  'cr.annual.report.balance-sheet.NL_AST_20_30_007': 'Overlopende activa',
  'cr.annual.report.balance-sheet.NL_AST_20_30_008': 'Vorderingen en overlopende activa',
  'cr.annual.report.balance-sheet.NL_AST_20_40_001': 'Effecten',
  'cr.annual.report.balance-sheet.NL_AST_20_40_002':
    'Buiten de groep staande verbonden maatschappijen',
  'cr.annual.report.balance-sheet.NL_AST_20_40_003': 'Overige effecten',
  'cr.annual.report.balance-sheet.NL_AST_20_50_001': 'Liquide middelen',
  'cr.annual.report.balance-sheet.NL_AST_20_50_002': 'Overige liquide middelen',
  'cr.annual.report.balance-sheet.NL_LBL_30_00_000': 'Totaal passiva',
  'cr.annual.report.balance-sheet.NL_LBL_30_10_001': 'Eigen vermogen',
  'cr.annual.report.balance-sheet.NL_LBL_30_10_002': 'Gestort en opgevraagd kapitaal',
  'cr.annual.report.balance-sheet.NL_LBL_30_10_003': 'Agio',
  'cr.annual.report.balance-sheet.NL_LBL_30_10_004': 'Herwaarderingsreserve',
  'cr.annual.report.balance-sheet.NL_LBL_30_10_005': 'Wettelijke reserve',
  'cr.annual.report.balance-sheet.NL_LBL_30_10_006': 'Statutaire reserve',
  'cr.annual.report.balance-sheet.NL_LBL_30_10_007': 'Wettelijke statutaire reserves',
  'cr.annual.report.balance-sheet.NL_LBL_30_10_008': 'Overige reserve',
  'cr.annual.report.balance-sheet.NL_LBL_30_10_009': 'Onverdeelde winst',
  'cr.annual.report.balance-sheet.NL_LBL_30_10_010': 'Resultaat na belasting',
  'cr.annual.report.balance-sheet.NL_LBL_30_10_012': 'Saldo verlies',
  'cr.annual.report.balance-sheet.NL_LBL_30_20_001': 'Aandeel van derden',
  'cr.annual.report.balance-sheet.NL_LBL_30_20_002': 'Overige aandeel derden',
  'cr.annual.report.balance-sheet.NL_LBL_30_25_001': 'Egalisatierekening',
  'cr.annual.report.balance-sheet.NL_LBL_30_25_002': 'Overige egalisatierekening',
  'cr.annual.report.balance-sheet.NL_LBL_30_30_001': 'Voorzieningen',
  'cr.annual.report.balance-sheet.NL_LBL_30_30_002': 'Voor pensioen',
  'cr.annual.report.balance-sheet.NL_LBL_30_30_003': 'Voor belasting',
  'cr.annual.report.balance-sheet.NL_LBL_30_30_004': 'Voor herstructurering / reorganisatie',
  'cr.annual.report.balance-sheet.NL_LBL_30_30_005': 'Voor debiteuren',
  'cr.annual.report.balance-sheet.NL_LBL_30_30_006': 'Voor latente belastingen',
  'cr.annual.report.balance-sheet.NL_LBL_30_30_007': 'Overige',
  'cr.annual.report.balance-sheet.NL_LBL_30_40_001': 'Langlopende schulden',
  'cr.annual.report.balance-sheet.NL_LBL_30_40_002': 'Converteerbare leningen',
  'cr.annual.report.balance-sheet.NL_LBL_30_40_003': 'Andere obligaties en onderhandse leningen',
  'cr.annual.report.balance-sheet.NL_LBL_30_40_004': 'Schulden aan kredietinstellingen',
  'cr.annual.report.balance-sheet.NL_LBL_30_40_005': 'Vooruit ontvangen op bestellingen',
  'cr.annual.report.balance-sheet.NL_LBL_30_40_006':
    'Schulden aan leveranciers en handelskredieten',
  'cr.annual.report.balance-sheet.NL_LBL_30_40_007': 'Te betalen wissels en cheques',
  'cr.annual.report.balance-sheet.NL_LBL_30_40_008': 'Schulden aan groepsmaatschappijen',
  'cr.annual.report.balance-sheet.NL_LBL_30_40_009': 'Schulden aan participanten en deelnemingen',
  'cr.annual.report.balance-sheet.NL_LBL_30_40_010': 'Belasting en premies sociale verzekering',
  'cr.annual.report.balance-sheet.NL_LBL_30_40_011': 'Schulden ter zake van pensioenen',
  'cr.annual.report.balance-sheet.NL_LBL_30_40_012': 'Overige schulden',
  'cr.annual.report.balance-sheet.NL_LBL_30_40_013': 'Overlopende passiva',
  'cr.annual.report.balance-sheet.NL_LBL_30_50_001': 'Kortlopende schulden',
  'cr.annual.report.balance-sheet.NL_LBL_30_50_002': 'Converteerbare obligaties en andere leningen',
  'cr.annual.report.balance-sheet.NL_LBL_30_50_003': 'Andere obligaties en onderhandse leningen',
  'cr.annual.report.balance-sheet.NL_LBL_30_50_004': 'Schulden aan kredietinstellingen',
  'cr.annual.report.balance-sheet.NL_LBL_30_50_005': 'Vooruit ontvangen op bestellingen',
  'cr.annual.report.balance-sheet.NL_LBL_30_50_006':
    'Schulden aan leveranciers en handelskredieten',
  'cr.annual.report.balance-sheet.NL_LBL_30_50_007': 'Te betalen wissels en cheques',
  'cr.annual.report.balance-sheet.NL_LBL_30_50_008': 'Schulden aan groepsmaatschappijen',
  'cr.annual.report.balance-sheet.NL_LBL_30_50_009': 'Schulden aan participanten en deelnemingen',
  'cr.annual.report.balance-sheet.NL_LBL_30_50_010': 'Belasting en premies sociale verzekeringen',
  'cr.annual.report.balance-sheet.NL_LBL_30_50_011': 'Schulden ter zake van pensioenen',
  'cr.annual.report.balance-sheet.NL_LBL_30_50_012': 'Overige schulden',
  'cr.annual.report.balance-sheet.NL_LBL_30_50_013': 'Overlopende passiva',
  'cr.annual.report.balance-sheet.NL_LBL_30_50_014': 'Kortlopende schulden en overlopende passiva',
  'cr.annual.report.balance-sheet.passiva': 'Passiva',
  'cr.annual.report.balance-sheet.provisions': 'Voorzieningen',
  'cr.annual.report.balance-sheet.shareholders-funds': 'Eigen vermogen',
  'cr.annual.report.balance-sheet.sub-header': 'Valuta en eenheid',
  'cr.annual.report.balance-sheet.tooltip.BE':
    'Hier vindt u de balansen terug van de laatste 5 boekjaren met alle details. Klik op de blauwe driehoekjes om onderliggende posten te bekijken en weer te sluiten. De balans is een momentopname van de bezittingen en schulden op de laatste dag van het boekjaar.',
  'cr.annual.report.balance-sheet.tooltip.GB':
    'De balans vindt u hier. De balans is een momentopname van de activa en passiva op de laatste dag van het boekjaar.',
  'cr.annual.report.balance-sheet.tooltip.NL':
    'Hier vindt u de balans. De balans is een momentopname van de bezittingen en schulden op de laatste dag van het boekjaar.',
  'cr.annual.report.balance-sheet.vaste-activa': 'Vaste Activa',
  'cr.annual.report.balance-sheet.vlottende-activa': 'Vlottende activa',
  'cr.annual.report.base-currency': 'Valuta en eenheid',
  'cr.annual.report.download-excel': 'Download jaarrekeningen',
  'cr.annual.report.explanatory-notes-BE_1.BE_T_635':
    'Voorzieningen voor pensioenen en soortgelijke verplichtingen (635)',
  'cr.annual.report.explanatory-notes-BE_1.BE_T_6358':
    "Voorzieningen voor risico's en kosten: toevoegingen (6358)",
  'cr.annual.report.explanatory-notes-BE_1.BE_T_6408': 'Andere bedrijfskosten (6408)',
  'cr.annual.report.explanatory-notes-BE_1.BE_T_9072': 'Vervallen belastingschulden (9072)',
  'cr.annual.report.explanatory-notes-BE_1.BE_T_9076':
    'Vervallen schulden ten aanzien van de Rijksdienst voor Sociale Zekerheid (9076)',
  'cr.annual.report.explanatory-notes-BE_1.BE_T_9150':
    'Door de onderneming geëndosseerde handelseffecten in omloop (9150)',
  'cr.annual.report.explanatory-notes-BE_1.BE_T_9161':
    'Waarborg van onderneming - Hypotheken - Boekwaarde van de bezwaarde activa (9161)',
  'cr.annual.report.explanatory-notes-BE_1.BE_T_91611':
    'Waarborg van onderneming - Hypotheken - Boekwaarde van de bezwaarde activa (91611)',
  'cr.annual.report.explanatory-notes-BE_1.BE_T_91612':
    'Waarborg van derden - Hypotheken - Boekwaarde van de bezwaarde activa (91612)',
  'cr.annual.report.explanatory-notes-BE_1.BE_T_9162':
    'Waarborg van derden - Hypotheken - Boekwaarde van de bezwaarde activa (9162)',
  'cr.annual.report.explanatory-notes-BE_1.BE_T_91621':
    'Waarborg van onderneming - Hypotheken - Bedrag van de inschrijving (91621)',
  'cr.annual.report.explanatory-notes-BE_1.BE_T_91622':
    'Waarborg van derden - Hypotheken - Bedrag van de inschrijving (91622)',
  'cr.annual.report.explanatory-notes-BE_1.BE_T_91631':
    'Waarborg van onderneming - Hypotheken - Gemandateerd inschrijvingsbedrag (91631)',
  'cr.annual.report.explanatory-notes-BE_1.BE_T_91632':
    'Waarborg van derden - Hypotheken - Gemandateerd inschrijvingsbedrag (91632)',
  'cr.annual.report.explanatory-notes-BE_1.BE_T_9171':
    'Waarborg van onderneming - Hypotheken - Bedrag van de inschrijving (9171)',
  'cr.annual.report.explanatory-notes-BE_1.BE_T_91711':
    'Waarborg van onderneming - Pand op het handelsfonds - Bedrag van de inschrijving (91711)',
  'cr.annual.report.explanatory-notes-BE_1.BE_T_91712':
    'Waarborg van derden - Pand op het handelsfonds - Bedrag van de inschrijving (91712)',
  'cr.annual.report.explanatory-notes-BE_1.BE_T_9172':
    'Waarborg van derden - Hypotheken - Bedrag van de inschrijving (9172)',
  'cr.annual.report.explanatory-notes-BE_1.BE_T_91721':
    'Waarborg van onderneming - Pand op het handelsfonds - Gemandateerd inschrijvingsbedrag (91721)',
  'cr.annual.report.explanatory-notes-BE_1.BE_T_91722':
    'Waarborg van derden - Pand op het handelsfonds - Gemandateerd inschrijvingsbedrag (91722)',
  'cr.annual.report.explanatory-notes-BE_1.BE_T_9181':
    'Waarborg van onderneming- Pand op het handelsfonds - Bedrag van de inschrijving (9181)',
  'cr.annual.report.explanatory-notes-BE_1.BE_T_91811':
    'Waarborg van onderneming - Pand op andere activa of onherroepelijke mandaten tot verpanding van andere mandaten - Boekwaarde van de bezwaarde activa (91811)',
  'cr.annual.report.explanatory-notes-BE_1.BE_T_91812':
    'Waarborg van derden - Pand op andere activa of onherroepelijke mandaten tot verpanding van andere mandaten - Boekwaarde van de bezwaarde activa (91812)',
  'cr.annual.report.explanatory-notes-BE_1.BE_T_9182':
    'Waarborg van derden - Pand op het handelsfonds - Bedrag van de inschrijving (9182)',
  'cr.annual.report.explanatory-notes-BE_1.BE_T_91821':
    'Waarborg van onderneming - Pand op andere activa of onherroepelijke mandaten tot verpanding van andere mandaten - Maximumbedrag waarvoor de schuld is gewaarborgd (91821)',
  'cr.annual.report.explanatory-notes-BE_1.BE_T_91822':
    'Waarborg van derden - Pand op andere activa of onherroepelijke mandaten tot verpanding van andere mandaten - Maximumbedrag waarvoor de schuld is gewaarborgd (91822)',
  'cr.annual.report.explanatory-notes-BE_1.BE_T_9191':
    'Waarborg van onderneming - Pand op andere activa - Boekwaarde van de in pand gegeven activa (9191)',
  'cr.annual.report.explanatory-notes-BE_1.BE_T_91911':
    'Waarborg van onderneming - Gestelde of onherroepelijk beloofde zekerheden op de nog door de vennootschap te verwerven activa - Bedrag van de betrokken activa (91911)',
  'cr.annual.report.explanatory-notes-BE_1.BE_T_91912':
    'Waarborg van derden - Gestelde of onherroepelijk beloofde zekerheden op de nog door de vennootschap te verwerven activa - Bedrag van de betrokken activa (91912)',
  'cr.annual.report.explanatory-notes-BE_1.BE_T_9192':
    'Waarborg van derden - Pand op andere activa - Boekwaarde van de in pand gegeven activa (9192)',
  'cr.annual.report.explanatory-notes-BE_1.BE_T_91921':
    'Waarborg van onderneming - Gestelde of onherroepelijk beloofde zekerheden op de nog door de vennootschap te verwerven activa - Maximumbedrag waarvoor de schuld is gewaarborgd (91921)',
  'cr.annual.report.explanatory-notes-BE_1.BE_T_91922':
    'Waarborg van derden - Gestelde of onherroepelijk beloofde zekerheden op de nog door de vennootschap te verwerven activa - Maximumbedrag waarvoor de schuld is gewaarborgd (91922)',
  'cr.annual.report.explanatory-notes-BE_1.BE_T_9201':
    'Waarborg van onderneming - Zekerheden op de nog te verwerven activa - Bedrag van de betrokken activa (9201)',
  'cr.annual.report.explanatory-notes-BE_1.BE_T_92011':
    'Waarborg van onderneming - Voorrecht van de verkoper - Boekwaarde van het verkochte goed (92011)',
  'cr.annual.report.explanatory-notes-BE_1.BE_T_92012':
    'Waarborg van derden - Voorrecht van de verkoper - Boekwaarde van het verkochte goed (92012)',
  'cr.annual.report.explanatory-notes-BE_1.BE_T_9202':
    'Waarborg van derden - Zekerheden op de nog door de onderneming te verwerven activa - Bedrag van de betrokken activa (9202)',
  'cr.annual.report.explanatory-notes-BE_1.BE_T_92021':
    'Waarborg van onderneming - Voorrecht van de verkoper - Bedrag van de niet-betaalde prijs (92021)',
  'cr.annual.report.explanatory-notes-BE_1.BE_T_92022':
    'Waarborg van derden - Voorrecht van de verkoper - Bedrag van de niet-betaalde prijs (92022)',
  'cr.annual.report.explanatory-notes-BE_1.BE_T_9500':
    'Uitstaande vorderingen op deze personen (9500)',
  'cr.annual.report.explanatory-notes-BE_1.BE_T_9501':
    'Waarborgen toegestaan in hun voordeel (9501)',
  'cr.annual.report.explanatory-notes-BE_1.BE_T_9502':
    'Andere betekenisvolle verplichtingen aangegaan in hun voordeel (9502)',
  'cr.annual.report.explanatory-notes-BE_1.business': 'Zakelijke zekerheden',
  'cr.annual.report.explanatory-notes-BE_1.general': 'Algemeen',
  'cr.annual.report.explanatory-notes-BE_10.BE_T_6358':
    "Voorzieningen voor risico's en kosten: toevoegingen (6358)",
  'cr.annual.report.explanatory-notes-BE_10.BE_T_6408': 'Andere bedrijfskosten (6408)',
  'cr.annual.report.explanatory-notes-BE_10.BE_T_9072': 'Vervallen belastingschulden (9072)',
  'cr.annual.report.explanatory-notes-BE_10.BE_T_9076':
    'Vervallen schulden ten aanzien van de Rijksdienst voor Sociale Zekerheid (9076)',
  'cr.annual.report.explanatory-notes-BE_10.BE_T_9150':
    'Door de onderneming geëndosseerde handelseffecten in omloop (9150)',
  'cr.annual.report.explanatory-notes-BE_10.BE_T_91611':
    'Waarborg van onderneming - Hypotheken - Boekwaarde van de bezwaarde activa (91611)',
  'cr.annual.report.explanatory-notes-BE_10.BE_T_91612':
    'Waarborg van derden - Hypotheken - Boekwaarde van de bezwaarde activa (91612)',
  'cr.annual.report.explanatory-notes-BE_10.BE_T_91621':
    'Waarborg van onderneming - Hypotheken - Bedrag van de inschrijving (91621)',
  'cr.annual.report.explanatory-notes-BE_10.BE_T_91622':
    'Waarborg van derden - Hypotheken - Bedrag van de inschrijving (91622)',
  'cr.annual.report.explanatory-notes-BE_10.BE_T_91631':
    'Waarborg van onderneming - Hypotheken - Gemandateerd inschrijvingsbedrag (91631)',
  'cr.annual.report.explanatory-notes-BE_10.BE_T_91632':
    'Waarborg van derden - Hypotheken - Gemandateerd inschrijvingsbedrag (91632)',
  'cr.annual.report.explanatory-notes-BE_10.BE_T_91711':
    'Waarborg van onderneming - Pand op het handelsfonds - Bedrag van de inschrijving (91711)',
  'cr.annual.report.explanatory-notes-BE_10.BE_T_91712':
    'Waarborg van derden - Pand op het handelsfonds - Bedrag van de inschrijving (91712)',
  'cr.annual.report.explanatory-notes-BE_10.BE_T_91721':
    'Waarborg van onderneming - Pand op het handelsfonds - Gemandateerd inschrijvingsbedrag (91721)',
  'cr.annual.report.explanatory-notes-BE_10.BE_T_91722':
    'Waarborg van derden - Pand op het handelsfonds - Gemandateerd inschrijvingsbedrag (91722)',
  'cr.annual.report.explanatory-notes-BE_10.BE_T_91811':
    'Waarborg van onderneming - Pand op andere activa of onherroepelijke mandaten tot verpanding van andere mandaten - Boekwaarde van de bezwaarde activa (91811)',
  'cr.annual.report.explanatory-notes-BE_10.BE_T_91812':
    'Waarborg van derden - Pand op andere activa of onherroepelijke mandaten tot verpanding van andere mandaten - Boekwaarde van de bezwaarde activa (91812)',
  'cr.annual.report.explanatory-notes-BE_10.BE_T_91821':
    'Waarborg van onderneming - Pand op andere activa of onherroepelijke mandaten tot verpanding van andere mandaten - Maximumbedrag waarvoor de schuld is gewaarborgd (91821)',
  'cr.annual.report.explanatory-notes-BE_10.BE_T_91822':
    'Waarborg van derden - Pand op andere activa of onherroepelijke mandaten tot verpanding van andere mandaten - Maximumbedrag waarvoor de schuld is gewaarborgd (91822)',
  'cr.annual.report.explanatory-notes-BE_10.BE_T_91911':
    'Waarborg van onderneming - Gestelde of onherroepelijk beloofde zekerheden op de nog door de vennootschap te verwerven activa - Bedrag van de betrokken activa (91911)',
  'cr.annual.report.explanatory-notes-BE_10.BE_T_91912':
    'Waarborg van derden - Gestelde of onherroepelijk beloofde zekerheden op de nog door de vennootschap te verwerven activa - Bedrag van de betrokken activa (91912)',
  'cr.annual.report.explanatory-notes-BE_10.BE_T_91921':
    'Waarborg van onderneming - Gestelde of onherroepelijk beloofde zekerheden op de nog door de vennootschap te verwerven activa - Maximumbedrag waarvoor de schuld is gewaarborgd (91921)',
  'cr.annual.report.explanatory-notes-BE_10.BE_T_91922':
    'Waarborg van derden - Gestelde of onherroepelijk beloofde zekerheden op de nog door de vennootschap te verwerven activa - Maximumbedrag waarvoor de schuld is gewaarborgd (91922)',
  'cr.annual.report.explanatory-notes-BE_10.BE_T_92011':
    'Waarborg van onderneming - Voorrecht van de verkoper - Boekwaarde van het verkochte goed (92011)',
  'cr.annual.report.explanatory-notes-BE_10.BE_T_92012':
    'Waarborg van derden - Voorrecht van de verkoper - Boekwaarde van het verkochte goed (92012)',
  'cr.annual.report.explanatory-notes-BE_10.BE_T_92021':
    'Waarborg van onderneming - Voorrecht van de verkoper - Bedrag van de niet-betaalde prijs (92021)',
  'cr.annual.report.explanatory-notes-BE_10.BE_T_92022':
    'Waarborg van derden - Voorrecht van de verkoper - Bedrag van de niet-betaalde prijs (92022)',
  'cr.annual.report.explanatory-notes-BE_10.BE_T_9500':
    'Uitstaande vorderingen op deze personen (9500)',
  'cr.annual.report.explanatory-notes-BE_10.BE_T_9501':
    'Waarborgen toegestaan in hun voordeel (9501)',
  'cr.annual.report.explanatory-notes-BE_10.BE_T_9502':
    'Andere betekenisvolle verplichtingen aangegaan in hun voordeel (9502)',
  'cr.annual.report.explanatory-notes-BE_10.business': 'Zakelijke zekerheden',
  'cr.annual.report.explanatory-notes-BE_10.general': 'Algemeen',
  'cr.annual.report.explanatory-notes-BE_11.BE_T_6358':
    "Voorzieningen voor risico's en kosten: toevoegingen (6358)",
  'cr.annual.report.explanatory-notes-BE_11.BE_T_6408': 'Andere bedrijfskosten (6408)',
  'cr.annual.report.explanatory-notes-BE_11.BE_T_9072': 'Vervallen belastingschulden (9072)',
  'cr.annual.report.explanatory-notes-BE_11.BE_T_9076':
    'Vervallen schulden ten aanzien van de Rijksdienst voor Sociale Zekerheid (9076)',
  'cr.annual.report.explanatory-notes-BE_11.BE_T_9150':
    'Door de vereniging of stichting geëndosseerde handelseffecten in omloop (9150)',
  'cr.annual.report.explanatory-notes-BE_11.BE_T_91611':
    'Waarborg van onderneming - Hypotheken - Boekwaarde van de bezwaarde activa (91611)',
  'cr.annual.report.explanatory-notes-BE_11.BE_T_91612':
    'Waarborg van derden - Hypotheken - Boekwaarde van de bezwaarde activa (91612)',
  'cr.annual.report.explanatory-notes-BE_11.BE_T_91621':
    'Waarborg van onderneming - Hypotheken - Bedrag van de inschrijving (91621)',
  'cr.annual.report.explanatory-notes-BE_11.BE_T_91622':
    'Waarborg van derden - Hypotheken - Bedrag van de inschrijving (91622)',
  'cr.annual.report.explanatory-notes-BE_11.BE_T_91631':
    'Waarborg van onderneming - Hypotheken - Gemandateerd inschrijvingsbedrag (91631)',
  'cr.annual.report.explanatory-notes-BE_11.BE_T_91632':
    'Waarborg van derden - Hypotheken - Gemandateerd inschrijvingsbedrag (91632)',
  'cr.annual.report.explanatory-notes-BE_11.BE_T_91711':
    'Waarborg van onderneming - Pand op het handelsfonds - Bedrag van de inschrijving (91711)',
  'cr.annual.report.explanatory-notes-BE_11.BE_T_91712':
    'Waarborg van derden - Pand op het handelsfonds - Bedrag van de inschrijving (91712)',
  'cr.annual.report.explanatory-notes-BE_11.BE_T_91721':
    'Waarborg van onderneming - Pand op het handelsfonds - Gemandateerd inschrijvingsbedrag (91721)',
  'cr.annual.report.explanatory-notes-BE_11.BE_T_91722':
    'Waarborg van derden - Pand op het handelsfonds - Gemandateerd inschrijvingsbedrag (91722)',
  'cr.annual.report.explanatory-notes-BE_11.BE_T_91811':
    'Waarborg van onderneming - Pand op andere activa of onherroepelijke mandaten tot verpanding van andere mandaten - Boekwaarde van de bezwaarde activa (91811)',
  'cr.annual.report.explanatory-notes-BE_11.BE_T_91812':
    'Waarborg van derden - Pand op andere activa of onherroepelijke mandaten tot verpanding van andere mandaten - Boekwaarde van de bezwaarde activa (91812)',
  'cr.annual.report.explanatory-notes-BE_11.BE_T_91821':
    'Waarborg van onderneming - Pand op andere activa of onherroepelijke mandaten tot verpanding van andere mandaten - Maximumbedrag waarvoor de schuld is gewaarborgd (91821)',
  'cr.annual.report.explanatory-notes-BE_11.BE_T_91822':
    'Waarborg van derden - Pand op andere activa of onherroepelijke mandaten tot verpanding van andere mandaten - Maximumbedrag waarvoor de schuld is gewaarborgd (91822)',
  'cr.annual.report.explanatory-notes-BE_11.BE_T_91911':
    'Waarborg van onderneming - Gestelde of onherroepelijk beloofde zekerheden op de nog door de vennootschap te verwerven activa - Bedrag van de betrokken activa (91911)',
  'cr.annual.report.explanatory-notes-BE_11.BE_T_91912':
    'Waarborg van derden - Gestelde of onherroepelijk beloofde zekerheden op de nog door de vennootschap te verwerven activa - Bedrag van de betrokken activa (91912)',
  'cr.annual.report.explanatory-notes-BE_11.BE_T_91921':
    'Waarborg van onderneming - Gestelde of onherroepelijk beloofde zekerheden op de nog door de vennootschap te verwerven activa - Maximumbedrag waarvoor de schuld is gewaarborgd (91921)',
  'cr.annual.report.explanatory-notes-BE_11.BE_T_91922':
    'Waarborg van derden - Gestelde of onherroepelijk beloofde zekerheden op de nog door de vennootschap te verwerven activa - Maximumbedrag waarvoor de schuld is gewaarborgd (91922)',
  'cr.annual.report.explanatory-notes-BE_11.BE_T_92011':
    'Waarborg van onderneming - Voorrecht van de verkoper - Boekwaarde van het verkochte goed (92011)',
  'cr.annual.report.explanatory-notes-BE_11.BE_T_92012':
    'Waarborg van derden - Voorrecht van de verkoper - Boekwaarde van het verkochte goed (92012)',
  'cr.annual.report.explanatory-notes-BE_11.BE_T_92021':
    'Waarborg van onderneming - Voorrecht van de verkoper - Bedrag van de niet-betaalde prijs (92021)',
  'cr.annual.report.explanatory-notes-BE_11.BE_T_92022':
    'Waarborg van derden - Voorrecht van de verkoper - Bedrag van de niet-betaalde prijs (92022)',
  'cr.annual.report.explanatory-notes-BE_11.BE_T_9500':
    'Uitstaande vorderingen op deze personen (9500)',
  'cr.annual.report.explanatory-notes-BE_11.BE_T_9501':
    'Waarborgen toegestaan in hun voordeel (9501)',
  'cr.annual.report.explanatory-notes-BE_11.BE_T_9502':
    'Andere betekenisvolle verplichtingen aangegaan in hun voordeel (9502)',
  'cr.annual.report.explanatory-notes-BE_11.business': 'Zakelijke zekerheden',
  'cr.annual.report.explanatory-notes-BE_11.general': 'Algemeen',
  'cr.annual.report.explanatory-notes-BE_12_21.BE_T_9150':
    'Door de vereniging of stichting geëndosseerde handelseffecten in omloop (9150)',
  'cr.annual.report.explanatory-notes-BE_12_21.BE_T_91611':
    'Boekwaarde van de bezwaarde activa (91611)',
  'cr.annual.report.explanatory-notes-BE_12_21.BE_T_91621': 'Bedrag van de inschrijving (91621)',
  'cr.annual.report.explanatory-notes-BE_12_21.BE_T_91711':
    'Maximumbedrag waarvoor de schuld is gewaarborgd en waarvoor registratie plaatsvindt (91711)',
  'cr.annual.report.explanatory-notes-BE_12_21.BE_T_91721':
    'Voor de onherroepelijke mandaten tot verpanding van het handelsfonds, het bedrag waarvoor de volmachthebber krachtens het mandaat tot registratie mag overgaan (91721)',
  'cr.annual.report.explanatory-notes-BE_12_21.BE_T_91811':
    'Boekwaarde van de bezwaarde activa (91811)',
  'cr.annual.report.explanatory-notes-BE_12_21.BE_T_91821':
    'Maximumbedrag waarvoor de schuld is gewaarborgd (91821)',
  'cr.annual.report.explanatory-notes-BE_12_21.BE_T_91911':
    'Bedrag van de betrokken activa (91911)',
  'cr.annual.report.explanatory-notes-BE_12_21.BE_T_91921':
    'Maximumbedrag waarvoor de schuld is gewaarborgd (91921)',
  'cr.annual.report.explanatory-notes-BE_12_21.BE_T_92011':
    'Boekwaarde van het verkochte goed (92011)',
  'cr.annual.report.explanatory-notes-BE_12_21.BE_T_92021':
    'Bedrag van de niet-betaalde prijs (92021)',
  'cr.annual.report.explanatory-notes-BE_12_21.BE_T_9500':
    'Uitstaande vorderingen op deze personen (9500)',
  'cr.annual.report.explanatory-notes-BE_12_21.BE_T_9501':
    'Waarborgen toegestaan in hun voordeel (9501)',
  'cr.annual.report.explanatory-notes-BE_12_21.BE_T_9502':
    'Andere betekenisvolle verplichtingen aangegaan in hun voordeel (9502)',
  'cr.annual.report.explanatory-notes-BE_12_21.business': 'Zakelijke zekerheden',
  'cr.annual.report.explanatory-notes-BE_12_21.general': 'Algemeen',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_635':
    'Voorzieningen voor pensioenen en soortgelijke verplichtingen (635)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_6358':
    "Voorzieningen voor risico's en kosten: toevoegingen (6358)",
  'cr.annual.report.explanatory-notes-BE_2.BE_T_640':
    'Andere bedrijfskosten: bedrijfsbelastingen en -taksen (640)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_6418': 'Andere bedrijfskosten: andere (6418)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_653':
    'Andere financiële kosten: bedrag van het disconto ten laste van de onderneming bij de verhandeling van vorderingen (653)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_9072': 'Vervallen belastingschulden (9072)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_9076':
    'Vervallen schulden ten aanzien van de Rijksdienst voor Sociale Zekerheid (9076)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_9145':
    'In rekening gebrachte belasting op de toegevoegde waarde: aan de onderneming (aftrekbaar) (9145)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_9146':
    'In rekening gebrachte belasting op de toegevoegde waarde: door de onderneming (9146)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_9150':
    'Door de onderneming geëndosseerde handelseffecten in omloop (9150)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_9161':
    'Waarborg van onderneming - Hypotheken - Boekwaarde van de bezwaarde activa (9161)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_91611':
    'Waarborg van onderneming - Hypotheken - Boekwaarde van de bezwaarde activa (91611)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_91612':
    'Waarborg van derden - Hypotheken - Boekwaarde van de bezwaarde activa (91612)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_9162':
    'Waarborg van derden - Hypotheken - Boekwaarde van de bezwaarde activa (9162)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_91621':
    'Waarborg van onderneming - Hypotheken - Bedrag van de inschrijving (91621)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_91622':
    'Waarborg van derden - Hypotheken - Bedrag van de inschrijving (91622)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_91631':
    'Waarborg van onderneming - Hypotheken - Gemandateerd inschrijvingsbedrag (91631)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_91632':
    'Waarborg van derden - Hypotheken - Gemandateerd inschrijvingsbedrag (91632)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_9171':
    'Waarborg van onderneming - Hypotheken - Bedrag van de inschrijving (9171)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_91711':
    'Waarborg van onderneming - Pand op het handelsfonds - Bedrag van de inschrijving (91711)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_91712':
    'Waarborg van derden - Pand op het handelsfonds - Bedrag van de inschrijving (91712)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_9172':
    'Waarborg van derden - Hypotheken - Bedrag van de inschrijving (9172)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_91721':
    'Waarborg van onderneming - Pand op het handelsfonds - Gemandateerd inschrijvingsbedrag (91721)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_91722':
    'Waarborg van derden - Pand op het handelsfonds - Gemandateerd inschrijvingsbedrag (91722)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_9181':
    'Waarborg van onderneming- Pand op het handelsfonds - Bedrag van de inschrijving (9181)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_91811':
    'Waarborg van onderneming - Pand op andere activa of onherroepelijke mandaten tot verpanding van andere mandaten - Boekwaarde van de bezwaarde activa (91811)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_91812':
    'Waarborg van derden - Pand op andere activa of onherroepelijke mandaten tot verpanding van andere mandaten - Boekwaarde van de bezwaarde activa (91812)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_9182':
    'Waarborg van derden - Pand op het handelsfonds - Bedrag van de inschrijving (9182)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_91821':
    'Waarborg van onderneming - Pand op andere activa of onherroepelijke mandaten tot verpanding van andere mandaten - Maximumbedrag waarvoor de schuld is gewaarborgd (91821)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_91822':
    'Waarborg van derden - Pand op andere activa of onherroepelijke mandaten tot verpanding van andere mandaten - Maximumbedrag waarvoor de schuld is gewaarborgd (91822)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_9191':
    'Waarborg van onderneming - Pand op andere activa - Boekwaarde van de in pand gegeven activa (9191)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_91911':
    'Waarborg van onderneming - Gestelde of onherroepelijk beloofde zekerheden op de nog door de vennootschap te verwerven activa - Bedrag van de betrokken activa (91911)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_91912':
    'Waarborg van derden - Gestelde of onherroepelijk beloofde zekerheden op de nog door de vennootschap te verwerven activa - Bedrag van de betrokken activa (91912)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_9192':
    'Waarborg van derden - Pand op andere activa - Boekwaarde van de in pand gegeven activa (9192)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_91921':
    'Waarborg van onderneming - Gestelde of onherroepelijk beloofde zekerheden op de nog door de vennootschap te verwerven activa - Maximumbedrag waarvoor de schuld is gewaarborgd (91921)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_91922':
    'Waarborg van derden - Gestelde of onherroepelijk beloofde zekerheden op de nog door de vennootschap te verwerven activa - Maximumbedrag waarvoor de schuld is gewaarborgd (91922)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_9201':
    'Waarborg van onderneming - Zekerheden op de nog te verwerven activa - Bedrag van de betrokken activa (9201)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_92011':
    'Waarborg van onderneming - Voorrecht van de verkoper - Boekwaarde van het verkochte goed (92011)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_92012':
    'Waarborg van derden - Voorrecht van de verkoper - Boekwaarde van het verkochte goed (92012)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_9202':
    'Waarborg van derden - Zekerheden op de nog door de onderneming te verwerven activa - Bedrag van de betrokken activa (9202)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_92021':
    'Waarborg van onderneming - Voorrecht van de verkoper - Bedrag van de niet-betaalde prijs (92021)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_92022':
    'Waarborg van derden - Voorrecht van de verkoper - Bedrag van de niet-betaalde prijs (92022)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_9500':
    'Uitstaande vorderingen op deze personen (9500)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_9501':
    'Waarborgen toegestaan in hun voordeel (9501)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_9502':
    'Andere betekenisvolle verplichtingen aangegaan in hun voordeel (9502)',
  'cr.annual.report.explanatory-notes-BE_2.business': 'Zakelijke zekerheden',
  'cr.annual.report.explanatory-notes-BE_2.general': 'Algemeen',
  'cr.annual.report.explanatory-notes-BE_4.BE_T_6358':
    "Voorzieningen voor risico's en kosten: toevoegingen (6358)",
  'cr.annual.report.explanatory-notes-BE_4.BE_T_6408': 'Andere bedrijfskosten (6408)',
  'cr.annual.report.explanatory-notes-BE_4.BE_T_9072': 'Vervallen belastingschulden (9072)',
  'cr.annual.report.explanatory-notes-BE_4.BE_T_9076':
    'Vervallen schulden ten aanzien van de Rijksdienst voor Sociale Zekerheid (9076)',
  'cr.annual.report.explanatory-notes-BE_4.BE_T_9150':
    'Door de onderneming geëndosseerde handelseffecten in omloop (9150)',
  'cr.annual.report.explanatory-notes-BE_4.BE_T_9161':
    'Waarborg van onderneming - Hypotheken - Boekwaarde van de bezwaarde activa (9161)',
  'cr.annual.report.explanatory-notes-BE_4.BE_T_91611':
    'Waarborg van onderneming - Hypotheken - Boekwaarde van de bezwaarde activa (91611)',
  'cr.annual.report.explanatory-notes-BE_4.BE_T_91612':
    'Waarborg van derden - Hypotheken - Boekwaarde van de bezwaarde activa (91612)',
  'cr.annual.report.explanatory-notes-BE_4.BE_T_9162':
    'Waarborg van derden - Hypotheken - Boekwaarde van de bezwaarde activa (9162)',
  'cr.annual.report.explanatory-notes-BE_4.BE_T_91621':
    'Waarborg van onderneming - Hypotheken - Bedrag van de inschrijving (91621)',
  'cr.annual.report.explanatory-notes-BE_4.BE_T_91622':
    'Waarborg van derden - Hypotheken - Bedrag van de inschrijving (91622)',
  'cr.annual.report.explanatory-notes-BE_4.BE_T_91631':
    'Waarborg van onderneming - Hypotheken - Gemandateerd inschrijvingsbedrag (91631)',
  'cr.annual.report.explanatory-notes-BE_4.BE_T_91632':
    'Waarborg van derden - Hypotheken - Gemandateerd inschrijvingsbedrag (91632)',
  'cr.annual.report.explanatory-notes-BE_4.BE_T_9171':
    'Waarborg van onderneming - Hypotheken - Bedrag van de inschrijving (9171)',
  'cr.annual.report.explanatory-notes-BE_4.BE_T_91711':
    'Waarborg van onderneming - Pand op het handelsfonds - Bedrag van de inschrijving (91711)',
  'cr.annual.report.explanatory-notes-BE_4.BE_T_91712':
    'Waarborg van derden - Pand op het handelsfonds - Bedrag van de inschrijving (91712)',
  'cr.annual.report.explanatory-notes-BE_4.BE_T_9172':
    'Waarborg van derden - Hypotheken - Bedrag van de inschrijving (9172)',
  'cr.annual.report.explanatory-notes-BE_4.BE_T_91721':
    'Waarborg van onderneming - Pand op het handelsfonds - Gemandateerd inschrijvingsbedrag (91721)',
  'cr.annual.report.explanatory-notes-BE_4.BE_T_91722':
    'Waarborg van derden - Pand op het handelsfonds - Gemandateerd inschrijvingsbedrag (91722)',
  'cr.annual.report.explanatory-notes-BE_4.BE_T_9181':
    'Waarborg van onderneming- Pand op het handelsfonds - Bedrag van de inschrijving (9181)',
  'cr.annual.report.explanatory-notes-BE_4.BE_T_91811':
    'Waarborg van onderneming - Pand op andere activa of onherroepelijke mandaten tot verpanding van andere mandaten - Boekwaarde van de bezwaarde activa (91811)',
  'cr.annual.report.explanatory-notes-BE_4.BE_T_91812':
    'Waarborg van derden - Pand op andere activa of onherroepelijke mandaten tot verpanding van andere mandaten - Boekwaarde van de bezwaarde activa (91812)',
  'cr.annual.report.explanatory-notes-BE_4.BE_T_9182':
    'Waarborg van derden - Pand op het handelsfonds - Bedrag van de inschrijving (9182)',
  'cr.annual.report.explanatory-notes-BE_4.BE_T_91821':
    'Waarborg van onderneming - Pand op andere activa of onherroepelijke mandaten tot verpanding van andere mandaten - Maximumbedrag waarvoor de schuld is gewaarborgd (91821)',
  'cr.annual.report.explanatory-notes-BE_4.BE_T_91822':
    'Waarborg van derden - Pand op andere activa of onherroepelijke mandaten tot verpanding van andere mandaten - Maximumbedrag waarvoor de schuld is gewaarborgd (91822)',
  'cr.annual.report.explanatory-notes-BE_4.BE_T_9191':
    'Waarborg van onderneming - Pand op andere activa - Boekwaarde van de in pand gegeven activa (9191)',
  'cr.annual.report.explanatory-notes-BE_4.BE_T_91911':
    'Waarborg van onderneming - Gestelde of onherroepelijk beloofde zekerheden op de nog door de vennootschap te verwerven activa - Bedrag van de betrokken activa (91911)',
  'cr.annual.report.explanatory-notes-BE_4.BE_T_91912':
    'Waarborg van derden - Gestelde of onherroepelijk beloofde zekerheden op de nog door de vennootschap te verwerven activa - Bedrag van de betrokken activa (91912)',
  'cr.annual.report.explanatory-notes-BE_4.BE_T_9192':
    'Waarborg van derden - Pand op andere activa - Boekwaarde van de in pand gegeven activa (9192)',
  'cr.annual.report.explanatory-notes-BE_4.BE_T_91921':
    'Waarborg van onderneming - Gestelde of onherroepelijk beloofde zekerheden op de nog door de vennootschap te verwerven activa - Maximumbedrag waarvoor de schuld is gewaarborgd (91921)',
  'cr.annual.report.explanatory-notes-BE_4.BE_T_91922':
    'Waarborg van derden - Gestelde of onherroepelijk beloofde zekerheden op de nog door de vennootschap te verwerven activa - Maximumbedrag waarvoor de schuld is gewaarborgd (91922)',
  'cr.annual.report.explanatory-notes-BE_4.BE_T_9201':
    'Waarborg van onderneming - Zekerheden op de nog te verwerven activa - Bedrag van de betrokken activa (9201)',
  'cr.annual.report.explanatory-notes-BE_4.BE_T_92011':
    'Waarborg van onderneming - Voorrecht van de verkoper - Boekwaarde van het verkochte goed (92011)',
  'cr.annual.report.explanatory-notes-BE_4.BE_T_92012':
    'Waarborg van derden - Voorrecht van de verkoper - Boekwaarde van het verkochte goed (92012)',
  'cr.annual.report.explanatory-notes-BE_4.BE_T_9202':
    'Waarborg van derden - Zekerheden op de nog door de onderneming te verwerven activa - Bedrag van de betrokken activa (9202)',
  'cr.annual.report.explanatory-notes-BE_4.BE_T_92021':
    'Waarborg van onderneming - Voorrecht van de verkoper - Bedrag van de niet-betaalde prijs (92021)',
  'cr.annual.report.explanatory-notes-BE_4.BE_T_92022':
    'Waarborg van derden - Voorrecht van de verkoper - Bedrag van de niet-betaalde prijs (92022)',
  'cr.annual.report.explanatory-notes-BE_4.BE_T_9500':
    'Uitstaande vorderingen op deze personen (9500)',
  'cr.annual.report.explanatory-notes-BE_4.BE_T_9501':
    'Waarborgen toegestaan in hun voordeel (9501)',
  'cr.annual.report.explanatory-notes-BE_4.BE_T_9502':
    'Andere betekenisvolle verplichtingen aangegaan in hun voordeel (9502)',
  'cr.annual.report.explanatory-notes-BE_4.business': 'Zakelijke zekerheden',
  'cr.annual.report.explanatory-notes-BE_4.general': 'Algemeen',
  'cr.annual.report.explanatory-notes-BE_5.BE_T_6358':
    "Voorzieningen voor risico's en kosten: toevoegingen (6358)",
  'cr.annual.report.explanatory-notes-BE_5.BE_T_6408': 'Andere bedrijfskosten (6408)',
  'cr.annual.report.explanatory-notes-BE_5.BE_T_653':
    'Andere financiële kosten: bedrag van het disconto ten laste van de vereniging of stichting bij de verhandeling van vorderingen (653)',
  'cr.annual.report.explanatory-notes-BE_5.BE_T_73':
    'Lidgeld, schenkingen, legaten en subsidies (73)',
  'cr.annual.report.explanatory-notes-BE_5.BE_T_9072': 'Vervallen belastingschulden (9072)',
  'cr.annual.report.explanatory-notes-BE_5.BE_T_9076':
    'Vervallen schulden ten aanzien van de Rijksdienst voor Sociale Zekerheid (9076)',
  'cr.annual.report.explanatory-notes-BE_5.BE_T_9150':
    'Door de vereniging of stichting geëndosseerde handelseffecten in omloop (9150)',
  'cr.annual.report.explanatory-notes-BE_5.BE_T_9161':
    'Waarborg van onderneming - Hypotheken - Boekwaarde van de bezwaarde activa (9161)',
  'cr.annual.report.explanatory-notes-BE_5.BE_T_91611':
    'Boekwaarde van de bezwaarde activa (91611)',
  'cr.annual.report.explanatory-notes-BE_5.BE_T_9162':
    'Waarborg van derden - Hypotheken - Boekwaarde van de bezwaarde activa (9162)',
  'cr.annual.report.explanatory-notes-BE_5.BE_T_91621': 'Bedrag van de inschrijving (91621)',
  'cr.annual.report.explanatory-notes-BE_5.BE_T_9171':
    'Waarborg van onderneming - Hypotheken - Bedrag van de inschrijving (9171)',
  'cr.annual.report.explanatory-notes-BE_5.BE_T_91711':
    'Maximumbedrag waarvoor de schuld is gewaarborgd en waarvoor registratie plaatsvindt (91711)',
  'cr.annual.report.explanatory-notes-BE_5.BE_T_9172':
    'Waarborg van derden - Hypotheken - Bedrag van de inschrijving (9172)',
  'cr.annual.report.explanatory-notes-BE_5.BE_T_91721':
    'Voor de onherroepelijke mandaten tot verpanding van het handelsfonds, het bedrag waarvoor de volmachthebber krachtens het mandaat tot registratie mag overgaan (91721)',
  'cr.annual.report.explanatory-notes-BE_5.BE_T_9181':
    'Waarborg van onderneming- Pand op het handelsfonds - Bedrag van de inschrijving (9181)',
  'cr.annual.report.explanatory-notes-BE_5.BE_T_91811':
    'Boekwaarde van de bezwaarde activa (91811)',
  'cr.annual.report.explanatory-notes-BE_5.BE_T_9182':
    'Waarborg van derden - Pand op het handelsfonds - Bedrag van de inschrijving (9182)',
  'cr.annual.report.explanatory-notes-BE_5.BE_T_91821':
    'Maximumbedrag waarvoor de schuld is gewaarborgd (91821)',
  'cr.annual.report.explanatory-notes-BE_5.BE_T_9191':
    'Waarborg van onderneming - Pand op andere activa - Boekwaarde van de in pand gegeven activa (9191)',
  'cr.annual.report.explanatory-notes-BE_5.BE_T_91911': 'Bedrag van de betrokken activa (91911)',
  'cr.annual.report.explanatory-notes-BE_5.BE_T_9192':
    'Waarborg van derden - Pand op andere activa - Boekwaarde van de in pand gegeven activa (9192)',
  'cr.annual.report.explanatory-notes-BE_5.BE_T_91921':
    'Maximumbedrag waarvoor de schuld is gewaarborgd (91921)',
  'cr.annual.report.explanatory-notes-BE_5.BE_T_9201':
    'Waarborg van onderneming - Zekerheden op de nog te verwerven activa - Bedrag van de betrokken activa (9201)',
  'cr.annual.report.explanatory-notes-BE_5.BE_T_92011': 'Boekwaarde van het verkochte goed (92011)',
  'cr.annual.report.explanatory-notes-BE_5.BE_T_9202':
    'Waarborg van derden - Zekerheden op de nog door de onderneming te verwerven activa - Bedrag van de betrokken activa (9202)',
  'cr.annual.report.explanatory-notes-BE_5.BE_T_92021': 'Bedrag van de niet-betaalde prijs (92021)',
  'cr.annual.report.explanatory-notes-BE_5.BE_T_9500':
    'Uitstaande vorderingen op deze personen (9500)',
  'cr.annual.report.explanatory-notes-BE_5.BE_T_9501':
    'Waarborgen toegestaan in hun voordeel (9501)',
  'cr.annual.report.explanatory-notes-BE_5.BE_T_9502':
    'Andere betekenisvolle verplichtingen aangegaan in hun voordeel (9502)',
  'cr.annual.report.explanatory-notes-BE_5.business': 'Zakelijke zekerheden',
  'cr.annual.report.explanatory-notes-BE_5.general': 'Algemeen',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_635':
    'Voorzieningen voor pensioenen en soortgelijke verplichtingen - toevoegingen (635)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_6358':
    "Voorzieningen voor risico's en kosten: toevoegingen (6358)",
  'cr.annual.report.explanatory-notes-BE_6.BE_T_640': 'Bedrijfsbelastingen en -taksen (640)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_6408': 'Andere bedrijfskosten (6408)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_6418': 'Andere bedrijfskosten: andere (6418)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_653':
    'Andere financiële kosten: bedrag van het disconto ten laste van de vereniging of stichting bij de verhandeling van vorderingen (653)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_730': 'Lidgelden (730)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_7301':
    'Lidgeld, schenkingen, legaten en subsidies: lidgeld (7301)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_731': 'Schenkingen (731)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_732': 'Legaten (732)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_7323':
    'Lidgeld, schenkingen, legaten en subsidies: schenkingen (7323)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_733': 'Subsidies (733)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_7345':
    'Lidgeld, schenkingen, legaten en subsidies: legaten (7345)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_736':
    'Kapitaal- en intrestsubsidies, exploitatiesubsidies en compenserende bedragen ter vermindering van de loonkost (736)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_7368':
    'Kapitaal- en intrestsubsidies, exploitatiesubsidies en compenserende bedragen ter vermindering van de loonkost (7368)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_9072': 'Vervallen belastingschulden (9072)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_9076':
    'Vervallen schulden ten aanzien van de Rijksdienst voor Sociale Zekerheid (9076)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_9145':
    'In rekening gebrachte belasting op de toegevoegde waarde: aan de vereniging of stichting (aftrekbaar) (9145)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_9146':
    'In rekening gebrachte belasting op de toegevoegde waarde: door de vereniging of stichting (9146)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_9150':
    'Door de vereniging of stichting geëndosseerde handelseffecten in omloop (9150)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_9161':
    'Waarborg van onderneming - Hypotheken - Boekwaarde van de bezwaarde activa (9161)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_91611':
    'Boekwaarde van de bezwaarde activa (91611)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_9162':
    'Waarborg van derden - Hypotheken - Boekwaarde van de bezwaarde activa (9162)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_91621': 'Bedrag van de inschrijving (91621)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_9171':
    'Waarborg van onderneming - Hypotheken - Bedrag van de inschrijving (9171)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_91711':
    'Maximumbedrag waarvoor de schuld is gewaarborgd en waarvoor registratie plaatsvindt (91711)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_9172':
    'Waarborg van derden - Hypotheken - Bedrag van de inschrijving (9172)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_91721':
    'Voor de onherroepelijke mandaten tot verpanding van het handelsfonds, het bedrag waarvoor de volmachthebber krachtens het mandaat tot registratie mag overgaan (91721)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_9181':
    'Waarborg van onderneming- Pand op het handelsfonds - Bedrag van de inschrijving (9181)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_91811':
    'Boekwaarde van de bezwaarde activa (91811)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_9182':
    'Waarborg van derden - Pand op het handelsfonds - Bedrag van de inschrijving (9182)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_91821':
    'Maximumbedrag waarvoor de schuld is gewaarborgd (91821)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_9191':
    'Waarborg van onderneming - Pand op andere activa - Boekwaarde van de in pand gegeven activa (9191)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_91911':
    'Boekwaarde van de bezwaarde activa (91911)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_9192':
    'Waarborg van derden - Pand op andere activa - Boekwaarde van de in pand gegeven activa (9192)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_91921':
    'Maximumbedrag waarvoor de schuld is gewaarborgd (91921)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_9201':
    'Waarborg van onderneming - Zekerheden op de nog te verwerven activa - Bedrag van de betrokken activa (9201)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_92011': 'Boekwaarde van het verkochte goed (92011)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_9202':
    'Waarborg van derden - Zekerheden op de nog door de onderneming te verwerven activa - Bedrag van de betrokken activa (9202)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_92021': 'Bedrag van de niet-betaalde prijs (92021)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_9500':
    'Uitstaande vorderingen op deze personen (9500)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_9501':
    'Waarborgen toegestaan in hun voordeel (9501)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_9502':
    'Andere betekenisvolle verplichtingen aangegaan in hun voordeel (9502)',
  'cr.annual.report.explanatory-notes-BE_6.business': 'Zakelijke zekerheden',
  'cr.annual.report.explanatory-notes-BE_6.general': 'Algemeen',
  'cr.annual.report.explanatory-notes-BE_9.BE_T_635':
    'Voorzieningen voor pensioenen en soortgelijke verplichtingen (635)',
  'cr.annual.report.explanatory-notes-BE_9.BE_T_6358':
    "Voorzieningen voor risico's en kosten: toevoegingen (6358)",
  'cr.annual.report.explanatory-notes-BE_9.BE_T_640':
    'Andere bedrijfskosten: bedrijfsbelastingen en -taksen (640)',
  'cr.annual.report.explanatory-notes-BE_9.BE_T_6408': 'Andere bedrijfskosten (6408)',
  'cr.annual.report.explanatory-notes-BE_9.BE_T_6418': 'Andere bedrijfskosten: andere (6418)',
  'cr.annual.report.explanatory-notes-BE_9.BE_T_653':
    'Andere financiële kosten: bedrag van het disconto ten laste van de onderneming bij de verhandeling van vorderingen (653)',
  'cr.annual.report.explanatory-notes-BE_9.BE_T_9072': 'Vervallen belastingschulden (9072)',
  'cr.annual.report.explanatory-notes-BE_9.BE_T_9076':
    'Vervallen schulden ten aanzien van de Rijksdienst voor Sociale Zekerheid (9076)',
  'cr.annual.report.explanatory-notes-BE_9.BE_T_9145':
    'In rekening gebrachte belasting op de toegevoegde waarde: aan de onderneming (aftrekbaar) (9145)',
  'cr.annual.report.explanatory-notes-BE_9.BE_T_9146':
    'In rekening gebrachte belasting op de toegevoegde waarde: door de onderneming (9146)',
  'cr.annual.report.explanatory-notes-BE_9.BE_T_9150':
    'Door de onderneming geëndosseerde handelseffecten in omloop (9150)',
  'cr.annual.report.explanatory-notes-BE_9.BE_T_91611':
    'Waarborg van onderneming - Hypotheken - Boekwaarde van de bezwaarde activa (91611)',
  'cr.annual.report.explanatory-notes-BE_9.BE_T_91612':
    'Waarborg van derden - Hypotheken - Boekwaarde van de bezwaarde activa (91612)',
  'cr.annual.report.explanatory-notes-BE_9.BE_T_91621':
    'Waarborg van onderneming - Hypotheken - Bedrag van de inschrijving (91621)',
  'cr.annual.report.explanatory-notes-BE_9.BE_T_91622':
    'Waarborg van derden - Hypotheken - Bedrag van de inschrijving (91622)',
  'cr.annual.report.explanatory-notes-BE_9.BE_T_91631':
    'Waarborg van onderneming - Hypotheken - Gemandateerd inschrijvingsbedrag (91631)',
  'cr.annual.report.explanatory-notes-BE_9.BE_T_91632':
    'Waarborg van derden - Hypotheken - Gemandateerd inschrijvingsbedrag (91632)',
  'cr.annual.report.explanatory-notes-BE_9.BE_T_91711':
    'Waarborg van onderneming - Pand op het handelsfonds - Bedrag van de inschrijving (91711)',
  'cr.annual.report.explanatory-notes-BE_9.BE_T_91712':
    'Waarborg van derden - Pand op het handelsfonds - Bedrag van de inschrijving (91712)',
  'cr.annual.report.explanatory-notes-BE_9.BE_T_91721':
    'Waarborg van onderneming - Pand op het handelsfonds - Gemandateerd inschrijvingsbedrag (91721)',
  'cr.annual.report.explanatory-notes-BE_9.BE_T_91722':
    'Waarborg van derden - Pand op het handelsfonds - Gemandateerd inschrijvingsbedrag (91722)',
  'cr.annual.report.explanatory-notes-BE_9.BE_T_91811':
    'Waarborg van onderneming - Pand op andere activa of onherroepelijke mandaten tot verpanding van andere mandaten - Boekwaarde van de bezwaarde activa (91811)',
  'cr.annual.report.explanatory-notes-BE_9.BE_T_91812':
    'Waarborg van derden - Pand op andere activa of onherroepelijke mandaten tot verpanding van andere mandaten - Boekwaarde van de bezwaarde activa (91812)',
  'cr.annual.report.explanatory-notes-BE_9.BE_T_91821':
    'Waarborg van onderneming - Pand op andere activa of onherroepelijke mandaten tot verpanding van andere mandaten - Maximumbedrag waarvoor de schuld is gewaarborgd (91821)',
  'cr.annual.report.explanatory-notes-BE_9.BE_T_91822':
    'Waarborg van derden - Pand op andere activa of onherroepelijke mandaten tot verpanding van andere mandaten - Maximumbedrag waarvoor de schuld is gewaarborgd (91822)',
  'cr.annual.report.explanatory-notes-BE_9.BE_T_91911':
    'Waarborg van onderneming - Gestelde of onherroepelijk beloofde zekerheden op de nog door de vennootschap te verwerven activa - Bedrag van de betrokken activa (91911)',
  'cr.annual.report.explanatory-notes-BE_9.BE_T_91912':
    'Waarborg van derden - Gestelde of onherroepelijk beloofde zekerheden op de nog door de vennootschap te verwerven activa - Bedrag van de betrokken activa (91912)',
  'cr.annual.report.explanatory-notes-BE_9.BE_T_91921':
    'Waarborg van onderneming - Gestelde of onherroepelijk beloofde zekerheden op de nog door de vennootschap te verwerven activa - Maximumbedrag waarvoor de schuld is gewaarborgd (91921)',
  'cr.annual.report.explanatory-notes-BE_9.BE_T_91922':
    'Waarborg van derden - Gestelde of onherroepelijk beloofde zekerheden op de nog door de vennootschap te verwerven activa - Maximumbedrag waarvoor de schuld is gewaarborgd (91922)',
  'cr.annual.report.explanatory-notes-BE_9.BE_T_92011':
    'Waarborg van onderneming - Voorrecht van de verkoper - Boekwaarde van het verkochte goed (92011)',
  'cr.annual.report.explanatory-notes-BE_9.BE_T_92012':
    'Waarborg van derden - Voorrecht van de verkoper - Boekwaarde van het verkochte goed (92012)',
  'cr.annual.report.explanatory-notes-BE_9.BE_T_92021':
    'Waarborg van onderneming - Voorrecht van de verkoper - Bedrag van de niet-betaalde prijs (92021)',
  'cr.annual.report.explanatory-notes-BE_9.BE_T_92022':
    'Waarborg van derden - Voorrecht van de verkoper - Bedrag van de niet-betaalde prijs (92022)',
  'cr.annual.report.explanatory-notes-BE_9.BE_T_9500':
    'Uitstaande vorderingen op deze personen (9500)',
  'cr.annual.report.explanatory-notes-BE_9.BE_T_9501':
    'Waarborgen toegestaan in hun voordeel (9501)',
  'cr.annual.report.explanatory-notes-BE_9.BE_T_9502':
    'Andere betekenisvolle verplichtingen aangegaan in hun voordeel (9502)',
  'cr.annual.report.explanatory-notes-BE_9.business': 'Zakelijke zekerheden',
  'cr.annual.report.explanatory-notes-BE_9.general': 'Algemeen',
  'cr.annual.report.explanatory-notes.after-2020':
    'Zakelijke zekerheden die door de vennootschap op haar eigen activa werden gesteld of onherroepelijk beloofd als waarborg voor schulden en verplichtingen van de vennootschap -  Model vanaf 2020',
  'cr.annual.report.explanatory-notes.before-2020':
    'Zakelijke zekerheden die door de onderneming op haar eigen activa werden gesteld of onherroepelijk beloofd als waarborg voor schulden en verplichtingen van de onderneming - Hypotheken - Model voor 2020',
  'cr.annual.report.explanatory-notes.tooltip.BE':
    'In de toelichting verklaart het bedrijf onder meer welke waarderingsregels het gebruikt heeft om tot bepaalde cijfers in de balans en de resultatenrekening te komen. Die waarderingsregels bevatten belangrijke informatie. Het is bijvoorbeeld interessant om na te gaan hoe een onderneming omspringt met afschrijvingen en voorraadwaarderingen. Elke vorm van waardering geeft een totaal ander beeld en heeft financiële implicaties.',
  'cr.annual.report.key-figures.accountsTotal': 'Kapitaal',
  'cr.annual.report.key-figures.accountsTotal-BE': 'Totaal rekening',
  'cr.annual.report.key-figures.acidTestRatio': 'Liquiditeitsratio',
  'cr.annual.report.key-figures.averageDaysClientsCredit-BE':
    'Gemiddeld aantal dagen klantenkrediet',
  'cr.annual.report.key-figures.averageDaysSuppliersCredit-BE':
    'Gemiddeld aantal dagen leverancierskrediet',
  'cr.annual.report.key-figures.balans': 'Balans',
  'cr.annual.report.key-figures.borrowingRatio': 'Leen ratio',
  'cr.annual.report.key-figures.capital': 'Kapitaal',
  'cr.annual.report.key-figures.cash': 'Liquide middelen',
  'cr.annual.report.key-figures.cashFlow': 'Cashflow',
  'cr.annual.report.key-figures.cashFlowRatio': 'Cashflow',
  'cr.annual.report.key-figures.creditorDays': 'Crediteuren dagen',
  'cr.annual.report.key-figures.currentRatio': 'Current ratio',
  'cr.annual.report.key-figures.debtsFallenDuetowardsRSZ-BE':
    'Vervallen schulden ten aanzien van de RSZ',
  'cr.annual.report.key-figures.debtsFallenDuetowardsTax-BE': 'Uitstaande belastingschulden',
  'cr.annual.report.key-figures.equity': 'Totaal eigen vermogen',
  'cr.annual.report.key-figures.equityCapital': 'Eigen vermogen',
  'cr.annual.report.key-figures.financial-ratios': "Financiële ratio's",
  'cr.annual.report.key-figures.generalDebtRate-BE': 'Algemene schuldgraad in %',
  'cr.annual.report.key-figures.grantsAndContributionsAutonomy-BE':
    'Autonomie subsidies en bijdragen %',
  'cr.annual.report.key-figures.grossSalesMargin-BE': 'Bruto verkoopmarge in %',
  'cr.annual.report.key-figures.income-statement': 'Resultatenrekening',
  'cr.annual.report.key-figures.investments-BE': 'Investeringen',
  'cr.annual.report.key-figures.netSalesMargin-BE': 'Netto verkoopmarge in %',
  'cr.annual.report.key-figures.operationResult-BE': 'Bedrijfsresultaat',
  'cr.annual.report.key-figures.preTaxProfitAndLoss': 'Winst/verlies voor belastingen',
  'cr.annual.report.key-figures.preTaxProfitMargin': 'Winst/verlies percentage voor belastingen',
  'cr.annual.report.key-figures.quickRatio': 'Liquiditeitsratio',
  'cr.annual.report.key-figures.quickRatio-BE': 'Quick ratio',
  'cr.annual.report.key-figures.ratios.description':
    "De volgende cijfers worden als ratio's weergegeven",
  'cr.annual.report.key-figures.result': 'Resultaat boekjaar',
  'cr.annual.report.key-figures.result-BE': 'Resultaat over de periode',
  'cr.annual.report.key-figures.returnOfCapital': 'Rendement op kapitaal',
  'cr.annual.report.key-figures.shareholderFunds': 'Aandeelhouders',
  'cr.annual.report.key-figures.solvencyRatio': 'Solvabiliteitsratio',
  'cr.annual.report.key-figures.sub-header': 'Valuta en eenheid',
  'cr.annual.report.key-figures.tangibleNetWorth': 'Vaste activa',
  'cr.annual.report.key-figures.tooltip':
    'Hier vindt u een samenvatting van de belangrijkste kerngetallen van deze jaarrekening. ',
  'cr.annual.report.key-figures.tooltip.BE':
    "Hier vindt u een overzicht van de belangrijkste cijfers uit de 5 recentste jaarrekeningen en een aantal financiële ratio's.",
  'cr.annual.report.key-figures.tooltip.GB':
    'Hier vindt u een samenvatting van de kerncijfers van deze jaarrekening.',
  'cr.annual.report.key-figures.tooltip.NL':
    'Hier vindt u een samenvatting van de belangrijkste kerngetallen van deze jaarrekening.',
  'cr.annual.report.key-figures.totalAssets': 'Totaal van de activa',
  'cr.annual.report.key-figures.turnover': 'Omzet',
  'cr.annual.report.key-figures.workingCapital': 'Werkkapitaal',
  'cr.annual.report.key-figures.workingCapital-BE': 'Netto werkkapitaal',
  'cr.annual.report.key-figures.workingCapitalRatio': 'Werkkapitaalquote',
  'cr.annual.report.profit-and-loss.appropriation': 'Resultaatverwerking',
  'cr.annual.report.profit-and-loss.BE_B_LBL_E_14': 'Over te dragen winst (verlies) (14)',
  'cr.annual.report.profit-and-loss.BE_P_RES_6064': 'Bedrijfskosten (6064)',
  'cr.annual.report.profit-and-loss.BE_P_RES_6066A': 'Bedrijfskosten (6066A)',
  'cr.annual.report.profit-and-loss.BE_P_RES_6566B': 'Financiële kosten (6566B)',
  'cr.annual.report.profit-and-loss.BE_P_RES_66': 'Niet-recurrente kosten (66)',
  'cr.annual.report.profit-and-loss.BE_P_RES_6777': 'Belastingen op het resultaat (6777)',
  'cr.annual.report.profit-and-loss.BE_P_RES_680':
    'Overboeking naar de uitgestelde belastingen (680)',
  'cr.annual.report.profit-and-loss.BE_P_RES_689':
    'Overboeking naar de belastingvrije reserves (689)',
  'cr.annual.report.profit-and-loss.BE_P_RES_7074': 'Bedrijfsopbrengsten (7074)',
  'cr.annual.report.profit-and-loss.BE_P_RES_7076A': 'Bedrijfsopbrengsten (7076A)',
  'cr.annual.report.profit-and-loss.BE_P_RES_7576B': 'Financiële opbrengsten (7576B)',
  'cr.annual.report.profit-and-loss.BE_P_RES_76': 'Niet-recurrente opbrengsten (76)',
  'cr.annual.report.profit-and-loss.BE_P_RES_780':
    'Onttrekking aan de uitgestelde belastingen (780)',
  'cr.annual.report.profit-and-loss.BE_P_RES_789':
    'Onttrekking aan de belastingvrije reserves (789)',
  'cr.annual.report.profit-and-loss.BE_P_RES_9901': 'Bedrijfswinst (Bedrijfsverlies) (9901)',
  'cr.annual.report.profit-and-loss.BE_P_RES_9902':
    'Wint (Verlies) uit de gewone bedrijfsuitoefening (9902)',
  'cr.annual.report.profit-and-loss.BE_P_RES_9903':
    'Winst (Verlies) van het boekjaar vóór belasting (9903)',
  'cr.annual.report.profit-and-loss.BE_P_RES_9904': 'Winst (Verlies) van het boekjaar (9904)',
  'cr.annual.report.profit-and-loss.BE_P_RES_9905':
    'Te bestemmen winst (verlies) van het boekjaar (9905)',
  'cr.annual.report.profit-and-loss.BE_P_RES_C_60': 'Handelsgoederen, grond- en hulpstoffen (60)',
  'cr.annual.report.profit-and-loss.BE_P_RES_C_61': 'Diensten en diverse goederen (61)',
  'cr.annual.report.profit-and-loss.BE_P_RES_C_62':
    'Bezoldigingen, sociale lasten en pensioenen (62)',
  'cr.annual.report.profit-and-loss.BE_P_RES_C_630':
    'Afschrijvingen en waardeverminderingen op oprichtingskosten, op immateriële en materiële vaste activa (630)',
  'cr.annual.report.profit-and-loss.BE_P_RES_C_6314':
    'Waardeverminderingen op voorraden, op bestellingen in uitvoering en op handelsvorderingen: toevoegingen (terugnemingen) (6314)',
  'cr.annual.report.profit-and-loss.BE_P_RES_C_6358':
    "Voorzieningen voor risico's en kosten: toevoegingen (bestedingen en terugnemingen) (6358)",
  'cr.annual.report.profit-and-loss.BE_P_RES_C_6408': 'Andere bedrijfskosten (6408)',
  'cr.annual.report.profit-and-loss.BE_P_RES_C_649':
    'Als herstructureringskosten geactiveerde bedrijfskosten (649)',
  'cr.annual.report.profit-and-loss.BE_P_RES_C_66A': 'Niet-recurrente bedrijfskosten (66A)',
  'cr.annual.report.profit-and-loss.BE_P_RES_CTR_6008': 'Aankopen (6008)',
  'cr.annual.report.profit-and-loss.BE_P_RES_CTR_609': 'Voorraad: afname (toename) (609)',
  'cr.annual.report.profit-and-loss.BE_P_RES_E_660':
    'Niet-recurrente afschrijvingen en waardeverminderingen op oprichtingskosten, op immateriële en materiële vaste activa (660)',
  'cr.annual.report.profit-and-loss.BE_P_RES_E_661':
    'Waardeverminderingen op financiële vaste activa (661)',
  'cr.annual.report.profit-and-loss.BE_P_RES_E_662':
    "Voorzieningen voor uitzonderlijke risico's en kosten: toevoegingen (bestedingen) (662)",
  'cr.annual.report.profit-and-loss.BE_P_RES_E_663':
    'Minderwaarden bij de realisatie van vaste activa (663)',
  'cr.annual.report.profit-and-loss.BE_P_RES_E_6648': 'Andere niet-recurrente kosten (6648)',
  'cr.annual.report.profit-and-loss.BE_P_RES_E_669':
    'Als herstructureringskosten geactiveerde niet-recurrente kosten (669)',
  'cr.annual.report.profit-and-loss.BE_P_RES_E_760':
    'Terugneming van afschrijvingen en van waardeverminderingen op immateriële en materiële vaste activa (760)',
  'cr.annual.report.profit-and-loss.BE_P_RES_E_761':
    'Terugneming van waardeverminderingen op financiële vaste activa (761)',
  'cr.annual.report.profit-and-loss.BE_P_RES_E_762':
    "Terugneming van voorzieningen voor uitzonderlijke risico's en kosten (762)",
  'cr.annual.report.profit-and-loss.BE_P_RES_E_763':
    'Meerwaarden bij de realisatie van vaste activa (763)',
  'cr.annual.report.profit-and-loss.BE_P_RES_E_7649': 'Andere uitzonderlijke opbrengsten (7649)',
  'cr.annual.report.profit-and-loss.BE_P_RES_FC_65': 'Recurrente financiële kosten (65)',
  'cr.annual.report.profit-and-loss.BE_P_RES_FC_65.COMPANY': 'Recurrente financiële kosten (65)',
  'cr.annual.report.profit-and-loss.BE_P_RES_FC_65.NON-PROFIT': 'Financiële kosten (65)',
  'cr.annual.report.profit-and-loss.BE_P_RES_FC_66B': 'Niet-recurrente financiële kosten (66B)',
  'cr.annual.report.profit-and-loss.BE_P_RES_FCR_650': 'Kosten van schulden (650)',
  'cr.annual.report.profit-and-loss.BE_P_RES_FCR_651':
    'Waardeverminderingen op vlottende activa andere dan voorraden, bestellingen in uitvoering en handelsvorderingen: toevoegingen (terugnemingen) (651)',
  'cr.annual.report.profit-and-loss.BE_P_RES_FCR_6529': 'Andere financiële kosten (6529)',
  'cr.annual.report.profit-and-loss.BE_P_RES_FR_75': 'Recurrente financiële opbrengsten (75)',
  'cr.annual.report.profit-and-loss.BE_P_RES_FR_75.COMPANY':
    'Recurrente financiële opbrengsten (75)',
  'cr.annual.report.profit-and-loss.BE_P_RES_FR_75.NON-PROFIT': 'Financiële opbrengsten (75)',
  'cr.annual.report.profit-and-loss.BE_P_RES_FR_76B':
    'Niet-recurrente financiële opbrengsten (76B)',
  'cr.annual.report.profit-and-loss.BE_P_RES_FRR_750':
    'Opbrengsten uit financiële vaste activa (750)',
  'cr.annual.report.profit-and-loss.BE_P_RES_FRR_751': 'Opbrengsten uit vlottende activa (751)',
  'cr.annual.report.profit-and-loss.BE_P_RES_FRR_7529': 'Andere financiële opbrengsten (7529)',
  'cr.annual.report.profit-and-loss.BE_P_RES_FRR_753':
    'Waarvan: kapitaal- en interestsubsidies (753)',
  'cr.annual.report.profit-and-loss.BE_P_RES_R_70': 'Omzet (70)',
  'cr.annual.report.profit-and-loss.BE_P_RES_R_71':
    'Voorraad goederen in bewerking en gereed product en bestellingen in uitvoering: toename (afname) (71)',
  'cr.annual.report.profit-and-loss.BE_P_RES_R_72': 'Geproduceerde vaste activa (72)',
  'cr.annual.report.profit-and-loss.BE_P_RES_R_74': 'Andere bedrijfsopbrengsten (74)',
  'cr.annual.report.profit-and-loss.BE_P_RES_R_76A':
    'Waarvan: niet-recurrente bedrijfsopbrengsten (76A)',
  'cr.annual.report.profit-and-loss.BE_P_RES_R_9900': 'Brutomarge (9900)',
  'cr.annual.report.profit-and-loss.BE_P_RES_RG_6061':
    'Handelsgoederen, grond- en hulpstoffen, diensten en diverse goederen (6061)',
  'cr.annual.report.profit-and-loss.BE_P_RES_RGR_73':
    'Lidgeld, schenkingen, legaten en subsidies (73)',
  'cr.annual.report.profit-and-loss.BE_P_RES_T_6703': 'Belastingen (6703)',
  'cr.annual.report.profit-and-loss.BE_P_RES_T_77':
    'Regularisering van belastingen en terugneming van voorzieningen voor belastingen (77)',
  'cr.annual.report.profit-and-loss.BE_R_6359':
    "Voorzieningen voor risico's en kosten: toevoegingen (bestedingen en terugnemingen) (6359)",
  'cr.annual.report.profit-and-loss.BE_RV_14': 'Over te dragen winst (verlies) (14)',
  'cr.annual.report.profit-and-loss.BE_RV_14P':
    'Overgedragen winst (verlies) van het vorige boekjaar (14P)',
  'cr.annual.report.profit-and-loss.BE_RV_691': 'aan de inbreng (691)',
  'cr.annual.report.profit-and-loss.BE_RV_6912': 'Toevoeging aan het eigen vermogen (691/2)',
  'cr.annual.report.profit-and-loss.BE_RV_692': 'Toevoeging aan de bestemde fondsen (692)',
  'cr.annual.report.profit-and-loss.BE_RV_6920': 'aan de wettelijke reserve (6920)',
  'cr.annual.report.profit-and-loss.BE_RV_6921': 'aan de overige reserves (6921)',
  'cr.annual.report.profit-and-loss.BE_RV_694': 'Vergoeding van de inbreng (694)',
  'cr.annual.report.profit-and-loss.BE_RV_6947': 'Uit te keren winst (6947)',
  'cr.annual.report.profit-and-loss.BE_RV_695': 'Bestuurders of zaakvoerders (695)',
  'cr.annual.report.profit-and-loss.BE_RV_696': 'Werknemers (696)',
  'cr.annual.report.profit-and-loss.BE_RV_697': 'Andere rechthebbenden (697)',
  'cr.annual.report.profit-and-loss.BE_RV_791':
    'Onttrekking aan het eigen vermogen: fondsen, bestemde fondsen en andere reserves (791)',
  'cr.annual.report.profit-and-loss.BE_RV_791.COMPANY':
    'aan het kapitaal en aan de uitgiftepremies (791)',
  'cr.annual.report.profit-and-loss.BE_RV_791.NON-PROFIT':
    'aan de fondsen van de vereniging of stichting (791)',
  'cr.annual.report.profit-and-loss.BE_RV_7912': 'Onttrekking aan het eigen vermogen (791/2)',
  'cr.annual.report.profit-and-loss.BE_RV_792.COMPANY': 'aan de reserves (792)',
  'cr.annual.report.profit-and-loss.BE_RV_792.NON-PROFIT': 'aan de bestemde fondsen (792)',
  'cr.annual.report.profit-and-loss.BE_RV_794': 'Tussenkomst van de vennoten in het verlies (794)',
  'cr.annual.report.profit-and-loss.BE_RV_9905':
    'Te bestemmen winst (verlies) van het boekjaar (9905)',
  'cr.annual.report.profit-and-loss.BE_RV_9906': 'Te bestemmen winst (verlies) (9906)',
  'cr.annual.report.profit-and-loss.bedrijfsopbrengsten-en-bedrijfskosten':
    'Bedrijfsopbrengsten en bedrijfskosten',
  'cr.annual.report.profit-and-loss.GB_RES_21': 'Exploitatiewinst/-verlies',
  'cr.annual.report.profit-and-loss.GB_RES_21_01': 'Exploitatiekosten',
  'cr.annual.report.profit-and-loss.GB_RES_21_02': 'Exploitatie inkomsten',
  'cr.annual.report.profit-and-loss.GB_RES_33': 'Afschrijvingskosten',
  'cr.annual.report.profit-and-loss.GB_RES_21_03': 'Uitzonderlijke elementen',
  'cr.annual.report.profit-and-loss.GB_RES_21_04': 'Winst/verlies op afboekingsdatum',
  'cr.annual.report.profit-and-loss.GB_RES_26': 'Winst/verlies voor belastingen',
  'cr.annual.report.profit-and-loss.GB_RES_26_03': 'Te ontvangen interesten',
  'cr.annual.report.profit-and-loss.GB_RES_26_04': 'Te betalen interesten',
  'cr.annual.report.profit-and-loss.GB_RES_26_10': 'Uitzonderlijke elementen',
  'cr.annual.report.profit-and-loss.GB_RES_34': 'Nettowinst/-verlies',
  'cr.annual.report.profit-and-loss.GB_RES_34_01': 'Belasting',
  'cr.annual.report.profit-and-loss.GB_RES_34_02': 'Minderheidsbelangen',
  'cr.annual.report.profit-and-loss.GB_RES_34_03': 'Buitengewone elementen',
  'cr.annual.report.profit-and-loss.GB_RES_34_04': 'Dividenden',
  'cr.annual.report.profit-and-loss.GB_RES_35': 'Brutowinst',
  'cr.annual.report.profit-and-loss.GB_RES_35_01': 'Omzet',
  'cr.annual.report.profit-and-loss.GB_RES_35_01_01': 'Omzet (GB)',
  'cr.annual.report.profit-and-loss.GB_RES_35_01_02': 'Omzet (Europa)',
  'cr.annual.report.profit-and-loss.GB_RES_35_01_03': 'Omzet (rest van de wereld)',
  'cr.annual.report.profit-and-loss.GB_RES_35_01_04': 'Omzet (export)',
  'cr.annual.report.profit-and-loss.GB_RES_35_02': 'Verkoopkosten',
  'cr.annual.report.profit-and-loss.GB_RES_35_03': 'Totale uitgaven',
  'cr.annual.report.profit-and-loss.GB_RES_35_03_01': 'Uitzonderlijke elementen',
  'cr.annual.report.profit-and-loss.GB_RES_35_03_02': 'Andere directe elementen',
  'cr.annual.report.profit-and-loss.NL_ACC_02': 'Nettoresultaat',
  'cr.annual.report.profit-and-loss.NL_RES_50_10_001': 'Som der bedrijfsopbrengsten',
  'cr.annual.report.profit-and-loss.NL_RES_50_10_002': 'Netto-omzet',
  'cr.annual.report.profit-and-loss.NL_RES_50_10_003':
    'Wijziging in voorraden gereed product en onderhanden werk',
  'cr.annual.report.profit-and-loss.NL_RES_50_10_004': 'Overige bedrijfsopbrengsten',
  'cr.annual.report.profit-and-loss.NL_RES_50_10_005': 'Overige bedrijfsopbrengsten',
  'cr.annual.report.profit-and-loss.NL_RES_50_10_006': 'Totaal opbrengsten',
  'cr.annual.report.profit-and-loss.NL_RES_50_15_001': 'Brutomarge',
  'cr.annual.report.profit-and-loss.NL_RES_50_15_002': 'Overige brutomarge',
  'cr.annual.report.profit-and-loss.NL_RES_50_20_001': 'Som der bedrijfslasten',
  'cr.annual.report.profit-and-loss.NL_RES_50_20_002': 'Kosten van grond- en hulpstoffen',
  'cr.annual.report.profit-and-loss.NL_RES_50_20_003':
    'Kosten uitbesteed werk en andere externe kosten',
  'cr.annual.report.profit-and-loss.NL_RES_50_20_004': 'Lonen en salarissen',
  'cr.annual.report.profit-and-loss.NL_RES_50_20_005': 'Sociale lasten',
  'cr.annual.report.profit-and-loss.NL_RES_50_20_006':
    'Afschrijving op immateriële vaste en materiële vaste activa',
  'cr.annual.report.profit-and-loss.NL_RES_50_20_007':
    'Overige waardeveranderingen van immateriële vaste en materiële vaste activa',
  'cr.annual.report.profit-and-loss.NL_RES_50_20_008':
    'Bijzondere waardevermindering van vlottende activa',
  'cr.annual.report.profit-and-loss.NL_RES_50_20_009': 'Overige bedrijfskosten',
  'cr.annual.report.profit-and-loss.NL_RES_50_20_010': 'Algemene beheerskosten',
  'cr.annual.report.profit-and-loss.NL_RES_50_25_001': 'Bedrijfslasten',
  'cr.annual.report.profit-and-loss.NL_RES_50_25_002': 'Kosten van grond- en hulpstoffen',
  'cr.annual.report.profit-and-loss.NL_RES_50_25_003':
    'Kosten uitbesteed werk en andere externe kosten',
  'cr.annual.report.profit-and-loss.NL_RES_50_25_004': 'Lonen en salarissen',
  'cr.annual.report.profit-and-loss.NL_RES_50_25_005': 'Sociale lasten',
  'cr.annual.report.profit-and-loss.NL_RES_50_25_006':
    'Afschrijving op immateriële vaste en materiële vaste activa',
  'cr.annual.report.profit-and-loss.NL_RES_50_25_007':
    'Overige waardeveranderingen van immateriële vaste en materiële vaste activa',
  'cr.annual.report.profit-and-loss.NL_RES_50_25_008':
    'Bijzondere waardevermindering van vlottende activa',
  'cr.annual.report.profit-and-loss.NL_RES_50_25_009': 'Overige bedrijfslasten',
  'cr.annual.report.profit-and-loss.NL_RES_50_30_001': 'Bedrijfsresultaat',
  'cr.annual.report.profit-and-loss.NL_RES_50_30_002': 'Overige bedrijfsresultaat',
  'cr.annual.report.profit-and-loss.NL_RES_50_40_001': 'Financieel resultaat',
  'cr.annual.report.profit-and-loss.NL_RES_50_40_002': 'Overig financieel resultaat',
  'cr.annual.report.profit-and-loss.NL_RES_50_42_001': 'Som der financiële baten',
  'cr.annual.report.profit-and-loss.NL_RES_50_42_002': 'Uitkering uit deelnemingen',
  'cr.annual.report.profit-and-loss.NL_RES_50_42_003':
    'Opbrengsten uit vorderingen die tot de vaste activa behoren en andere affecten',
  'cr.annual.report.profit-and-loss.NL_RES_50_42_004':
    'Andere rentebaten en soortgelijke opbrengsten',
  'cr.annual.report.profit-and-loss.NL_RES_50_42_005':
    'Waardeveranderingen van vorderingen die tot de vaste activa behoren en andere effecten',
  'cr.annual.report.profit-and-loss.NL_RES_50_44_001': 'Som der financiële lasten',
  'cr.annual.report.profit-and-loss.NL_RES_50_44_002': 'Uitkering uit deelnemingen',
  'cr.annual.report.profit-and-loss.NL_RES_50_44_003':
    'Waardeveranderingen van vorderingen die tot de vaste activa behoren en andere effecten',
  'cr.annual.report.profit-and-loss.NL_RES_50_44_004': 'Rentelasten en soortgelijke kosten',
  'cr.annual.report.profit-and-loss.NL_RES_50_50_001':
    'Resultaat uit gewone bedrijfsuitoefening voor belastingen',
  'cr.annual.report.profit-and-loss.NL_RES_50_50_002':
    'Overig resultaat uit gewone bedrijfsuitoefening voor belastingen',
  'cr.annual.report.profit-and-loss.NL_RES_50_52_001': 'Aandeel in winst/verlies deelneming',
  'cr.annual.report.profit-and-loss.NL_RES_50_52_002': 'Overig aandeel in winst/verlies deelneming',
  'cr.annual.report.profit-and-loss.NL_RES_50_52_003': 'Resultaat uit deelneming na belasting',
  'cr.annual.report.profit-and-loss.NL_RES_50_54_001':
    'Belasting resultaat uit gewone bedrijfsuitoefening',
  'cr.annual.report.profit-and-loss.NL_RES_50_54_002':
    'Overige belasting resultaat uit gewone bedrijfsuitoefening',
  'cr.annual.report.profit-and-loss.NL_RES_50_60_001':
    'Resultaat uit gewone bedrijfsuitoefening na belastingen',
  'cr.annual.report.profit-and-loss.NL_RES_50_60_002':
    'Overig resultaat uit gewone bedrijfsuitoefening na belasting',
  'cr.annual.report.profit-and-loss.NL_RES_50_62_001': 'Som der buitengewone baten',
  'cr.annual.report.profit-and-loss.NL_RES_50_62_002': 'Overige som der buitengewone baten',
  'cr.annual.report.profit-and-loss.NL_RES_50_64_001': 'Som der buitengewone lasten',
  'cr.annual.report.profit-and-loss.NL_RES_50_64_002': 'Overige som der buitengewone lasten',
  'cr.annual.report.profit-and-loss.NL_RES_50_66_001': 'Belasting buitengewoon resultaat',
  'cr.annual.report.profit-and-loss.NL_RES_50_66_002': 'Overige buitengewoon resultaat',
  'cr.annual.report.profit-and-loss.NL_RES_50_70_001': 'Buitengewoon resultaat na belastingen',
  'cr.annual.report.profit-and-loss.NL_RES_50_70_002':
    'Overige buitengewoon resultaat na belastingen',
  'cr.annual.report.profit-and-loss.NL_RES_50_70_003':
    'Saldo buitengewoon resultaat voor belasting',
  'cr.annual.report.profit-and-loss.NL_RES_50_72_001': 'Aandeel van derden',
  'cr.annual.report.profit-and-loss.NL_RES_50_72_002': 'Overig aandeel van derden',
  'cr.annual.report.profit-and-loss.NL_RES_50_80_001': 'Resultaat na belasting',
  'cr.annual.report.profit-and-loss.NL_RES_50_80_002': 'Overige resultaat na belasting',
  'cr.annual.report.profit-and-loss.RESULTATENREKENING': 'RESULTATENREKENING',
  'cr.annual.report.profit-and-loss.subheader': 'Valuta en eenheid',
  'cr.annual.report.profit.loss.subheader': 'Basisvaluta en eenheid',
  'cr.annual.report.profit.loss.tooltip.BE':
    'De resultatenrekening geeft een overzicht van de inkomsten en de uitgaven van de laatste 5 boekjaren.',
  'cr.annual.report.profit.loss.tooltip.GB':
    'De winst- en verliesrekening geeft een overzicht van de baten en de kosten gedurende het boekjaar.',
  'cr.annual.report.profit.loss.tooltip.NL':
    'De winst- en verliesrekening geeft een overzicht van de inkomsten en de uitgaven gedurende het boekjaar.',
  'cr.annual.report.social-balance.BE_SB_1003':
    'Gemiddeld aantal werknemers tijdens het boekjaar (VTE) (1003)',
  'cr.annual.report.social-balance.BE_SB_1013':
    'Aantal daadwerkelijk gepresteerde uren tijdens het boekjaar (1013)',
  'cr.annual.report.social-balance.BE_SB_1023': 'Personeelskosten tijdens het boekjaar (1023)',
  'cr.annual.report.social-balance.BE_SB_1203': 'Mannen (VTE) op afsluitdatum boekjaar (1203)',
  'cr.annual.report.social-balance.BE_SB_1213': 'Vrouwen (VTE) op afsluitdatum boekjaar (1213)',
  'cr.annual.report.social-balance.BE_SB_1303':
    'Directieleden (VTE) op afsluitdatum boekjaar (1303)',
  'cr.annual.report.social-balance.BE_SB_1323': 'Arbeiders (VTE) op afsluitdatum boekjaar (1323)',
  'cr.annual.report.social-balance.BE_SB_1333': 'Andere (VTE) op afsluitdatum boekjaar (1333)',
  'cr.annual.report.social-balance.BE_SB_1343': 'Bedienden (VTE) op afsluitdatum boekjaar (1343)',
  'cr.annual.report.social-balance.BE_SB_1501':
    'Gemiddeld aantal uitzendkrachten tijdens het boekjaar (1501)',
  'cr.annual.report.social-balance.BE_SB_1511':
    'Aantal daadwerkelijk gepresteerde uren van de uitzendkrachten tijdens het boekjaar (1511)',
  'cr.annual.report.social-balance.BE_SB_1521':
    'Kosten van de uitzendkrachten tijdens het boekjaar (1521)',
  'cr.annual.report.social-balance.header-title': 'Sociale balans',
  'cr.annual.report.social-balance.tooltip.BE':
    'Hier vindt u de uitsplitsing van medewerkertypes en medewerkerkosten',
  'cr.annual.report.tab.balance-sheet': 'Balans',
  'cr.annual.report.tab.explanatory-notes': 'Toelichting',
  'cr.annual.report.tab.key-figures': 'Kerncijfers',
  'cr.annual.report.tab.profit-and-loss': 'Winst en verlies',
  'cr.annual.report.tab.social-balance': 'Sociale balans',
  'cr.archive.action.button.addToMonitoring.back': 'Terug naar Bedrijfsarchief',
  'cr.archive.no.archived.reports.body':
    'U heeft nog geen rapporten aangevraagd of de door u aangevraagde rapporten zijn momenteel niet beschikbaar.<br /><br />\nHeeft u vragen of hulp nodig, contacteer dan ons Customer Service team<a href="mailto:{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}">{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}</a> or on {country, select, gb {+44 (0)20 8515 1400} nl {+31 (020) 567 9501} other {+32 (03) 280 88 80}}.',
  'cr.archive.no.archived.reports.title': 'Geen recente acties beschikbaar',
  'cr.branches-table.branch-address': 'Vestigingsadres',
  'cr.branches-table.branch-name': 'Naam vestiging',
  'cr.branches-table.branch-number': 'Graydon vestigingsnummer',
  'cr.branches-table.branch-start-date': 'Start- & einddatum',
  'cr.branches.branch-address': 'Adres vestiging',
  'cr.branches.branch-id': 'Identificatie vestiging',
  'cr.branches.branch-name': 'Naam vestiging',
  'cr.branches.branch-number': 'Vestigingsnummer',
  'cr.branches.period.present': 'Heden',
  'cr.branches.period.unknown': 'Onbekend',
  'cr.branches.start-date': 'Startdatum',
  'cr.branches.total': 'Totaal',
  'cr.company-archive.reference.add': 'Voeg een referentie toe',
  'cr.company-archive.reference.edit': 'Bewerken',
  'cr.company-archive.reference.error.message':
    'We hebben uw referentie niet kunnen bijwerken. Gelieve het opnieuw te proberen.',
  'cr.company-archive.reference.error.title': 'Gelieve het opnieuw te proberen',
  'cr.company-archive.reference.form.add-new-reference': '+ Voeg nog een referentie toe',
  'cr.company-archive.reference.form.add.cancel': 'Annuleren',
  'cr.company-archive.reference.form.add.label': 'Uw referentie',
  'cr.company-archive.reference.form.add.placeholder':
    'Voeg hier uw referentie toe voor dit bedrijf',
  'cr.company-archive.reference.form.add.save': 'Opslaan',
  'cr.company-archive.reference.form.add.title': 'Voeg uw referentie toe',
  'cr.company-archive.reference.form.add.tooltip':
    'Een bedrijfsreferentie toevoegen, kan u helpen om dit bedrijf in de toekomst te identificeren. Uw referentie wordt getoond in alle nieuwe rapporten die u voor dit bedrijf aanvraagt.',
  'cr.company-archive.reference.form.description': 'Geef 1 of meerdere referenties op',
  'cr.company-archive.reference.form.edit.cancel': 'Annuleren',
  'cr.company-archive.reference.form.edit.label': 'Uw referentie',
  'cr.company-archive.reference.form.edit.placeholder':
    'Bewerk hier uw referentie voor dit bedrijf',
  'cr.company-archive.reference.form.edit.save': 'Opslaan',
  'cr.company-archive.reference.form.edit.title': 'Bewerk uw referentie(s)',
  'cr.company-archive.reference.form.edit.tooltip':
    'Uw bijgewerkte referentie wordt getoond in alle nieuwe rapporten die u voor dit bedrijf aanvraagt.',
  'cr.company-archive.reference.form.references': 'Uw referentie(s)',
  'cr.company-archive.reference.label': 'Referentie',
  'cr.company-archive.reference.modal.add.title': 'Voeg uw referentie toe voor dit bedrijf',
  'cr.company-archive.reference.placeHolder':
    'Voeg een referentie toe om dit bedrijf te identificeren',
  'cr.company-archive.reference.success.add.title': 'Uw referentie is toegevoegd',
  'cr.company-archive.reference.success.remove.title': 'Uw referentie is verwijderd',
  'cr.company-archive.reference.tooltip.add.description':
    'Een bedrijfsreferentie toevoegen, kan u helpen om dit bedrijf in de toekomst te identificeren. Uw referentie wordt getoond in alle nieuwe rapporten die u voor dit bedrijf aanvraagt.',
  'cr.company-archive.reference.tooltip.add.title': 'Voeg uw referentie(s) toe',
  'cr.company-archive.reference.tooltip.edit.description':
    'Uw bijgewerkte referentie wordt getoond in alle nieuwe rapporten die u voor dit bedrijf aanvraagt.',
  'cr.company-archive.reference.tooltip.edit.title': 'Bewerk uw referentie(s)',
  'cr.company-box.branch-address': 'Adres',
  'cr.company-box.branches': 'Vestigingen',
  'cr.company-box.branches.link': 'Bekijk vestigingen',
  'cr.company-box.graydon-number': 'Graydon nummer',
  'cr.company-box.registration-number-be': 'Ondernemingsnummer',
  'cr.company-box.registration-number-gb': 'Bedrijfsregistratie nummer ',
  'cr.company-box.registration-number-nl': 'KvK-nummer',
  'cr.company-box.trade-names': 'Handelsnamen',
  'cr.company-box.trade-names.link': 'Bekijk alle handelsnamen',
  'cr.company-box.vat-number': 'BTW nummer',
  'cr.company.active': 'Actief',
  'cr.company.branches.title': 'Vestigingen',
  'cr.company.branches.tooltip':
    'Hier vindt u alle gegevens terug over de vestigingen van het bedrijf.',
  'cr.company.branches.tooltip.BE':
    'In dit gedeelte vindt u alle gegevens terug over de vestigingen van het bedrijf. Elke vestiging heeft een uniek nummer dat, net als het ondernemingsnummer, wordt toegekend door de KBO. ',
  'cr.company.branches.tooltip.GB':
    'Hier vindt u, indien van toepassing, de gegevens van eventuele vestigingen van de onderneming.',
  'cr.company.branches.tooltip.NL':
    'In dit gedeelte vindt u alle gegevens terug over de vestigingen van het bedrijf. ',
  'cr.company.contacts.email': 'E-mailadres',
  'cr.company.contacts.fax': 'Faxnummer',
  'cr.company.contacts.location': 'Locatie op kaart',
  'cr.company.contacts.mobile-number': 'Mobiel/GSM nummer',
  'cr.company.contacts.old-address': 'Oud adres',
  'cr.company.contacts.phone': 'Telefoonnummer',
  'cr.company.contacts.region': 'Gewest',
  'cr.company.contacts.registered-address': 'Geregistreerd adres',
  'cr.company.contacts.title': 'Contactgegevens bedrijf',
  'cr.company.contacts.title.BE':
    'Hier vindt u alle contactgegevens en handelsnamen terug die bij ons gekend zijn. Het geregistreerd adres of de maatschappelijke zetel is het officiële juridische adres van een bedrijf (rechtspersoon) of een vereniging. Er kunnen meerdere vestigingen zijn, maar er is slechts één geregistreerd adres. Meestal wordt die locatie ook beschouwd als het hoofdkantoor.\nVoor eenmanszaken leveren onze officiële bronnen geen maatschappelijke adressen meer uit en is Graydon aangewezen op de aangeleverde vestigingsadressen.\nEr is een verschil tussen de handelsnaam en de maatschappelijke naam van een bedrijf. De handelsnaam is de naam waaronder een onderneming haar handelsactiviteiten uitvoert. Het zijn meestal eenmanszaken die met een handelsnaam werken. De maatschappelijke naam is de officiële juridische benaming van een onderneming en dient om de vennootschap te identificeren. Hij heeft geen publicitair doel zoals dat wel het geval is voor de handelsnaam. Bij rechtspersonen zijn de handelsnaam en de maatschappelijke benaming evenwel vaak dezelfde.',
  'cr.company.contacts.title.GB':
    'Hier vindt u de contactgegevens en handelsnamen van het bedrijf.',
  'cr.company.contacts.title.NL':
    'Hier vindt u alle contactgegevens en handelsnamen die bij ons bekend zijn.',
  'cr.company.contacts.tooltip':
    'Hier vindt u alle contactgegevens en handelsnamen die bij ons bekend zijn.',
  'cr.company.contacts.tooltip.BE':
    'Hier vindt u alle contactgegevens en handelsnamen terug die bij ons gekend zijn. Het geregistreerd adres of de maatschappelijke zetel is het officiële juridische adres van een bedrijf (rechtspersoon) of een vereniging. Er kunnen meerdere vestigingen zijn, maar er is slechts één geregistreerd adres. Meestal wordt die locatie ook beschouwd als het hoofdkantoor.\nVoor eenmanszaken leveren onze officiële bronnen geen maatschappelijke adressen meer uit en is Graydon aangewezen op de aangeleverde vestigingsadressen. \nEr is een verschil tussen de handelsnaam en de maatschappelijke naam van een bedrijf. De handelsnaam is de naam waaronder een onderneming haar handelsactiviteiten uitvoert. Het zijn meestal eenmanszaken die met een handelsnaam werken. De maatschappelijke naam is de officiële juridische benaming van een onderneming en dient om de vennootschap te identificeren. Hij heeft geen publicitair doel zoals dat wel het geval is voor de handelsnaam. Bij rechtspersonen zijn de handelsnaam en de maatschappelijke benaming evenwel vaak dezelfde.',
  'cr.company.contacts.tooltip.GB':
    'Hier vindt u de contactgegevens en handelsnamen van het bedrijf.',
  'cr.company.contacts.tooltip.NL':
    'Hier vindt u alle contactgegevens en handelsnamen die bij ons bekend zijn.',
  'cr.company.contacts.trade.names': 'Handelsnamen',
  'cr.company.contacts.trade.names.link': 'Bekijk alle handelsnamen',
  'cr.company.contacts.trade.names.mobile':
    '{value} van handelsnamen om te bekijken in volle PDF-download',
  'cr.company.contacts.trading-address': 'Handelsadres',
  'cr.company.contacts.website': 'Website',
  'cr.company.details.activities-description': 'Activiteit ({codeType} code)',
  'cr.company.details.activity-group': 'Activiteitengroep',
  'cr.company.details.activity-text': 'Activiteit details',
  'cr.company.details.activity-text.showAll': 'Lees meer details',
  'cr.company.details.actual-amount-liability': 'Actueel bedrag aansprakelijkheid',
  'cr.company.details.capital': 'Kapitaal',
  'cr.company.details.capital.associationFunds': 'Fondsen van de vereniging',
  'cr.company.details.capital.authorisedCapital': 'Maatschappelijk kapitaal',
  'cr.company.details.capital.availableContribution': 'Beschikbare inbreng',
  'cr.company.details.capital.contribution': 'Inbreng',
  'cr.company.details.capital.foundingCapital': 'Oprichtingskapitaal',
  'cr.company.details.capital.issuedCapital': 'Geplaatst kapitaal',
  'cr.company.details.capital.paidUpCapital': 'Gestort kapitaal',
  'cr.company.details.capital.paidUpContribution': 'Gestorte inbreng',
  'cr.company.details.capital.paidUpFoundingCapital': 'Gestort oprichtingskapitaal',
  'cr.company.details.capital.partnershipCapital': 'Partnership',
  'cr.company.details.capital.unavailableContribution': 'Onbeschikbare inbreng',
  'cr.company.details.coc-documents': 'Officiële documenten',
  'cr.company.details.companies-house': 'Kruispuntbank van Ondernemingen',
  'cr.company.details.companies-house.view-company': 'Toon historiek',
  'cr.company.details.company-name': 'Bedrijfsnaam',
  'cr.company.details.consumer-credit-check': 'Consumer credit check',
  'cr.company.details.end-date': 'Einddatum',
  'cr.company.details.estimated-amount-liability': 'Geschat bedrag aansprakelijkheid',
  'cr.company.details.export-indicator': 'Export indicator',
  'cr.company.details.founding-capital': 'Oprichtingskapitaal',
  'cr.company.details.governance-form': 'Bestuursvorm',
  'cr.company.details.graydon-number': 'Graydon nummer',
  'cr.company.details.import-indicator': 'Import indicator',
  'cr.company.details.incorporation-date': 'Oprichtingsdatum',
  'cr.company.details.known-since': 'Bekend sinds',
  'cr.company.details.legal-form': 'Rechtsvorm',
  'cr.company.details.legal-form-since': 'Juridische vorm sinds',
  'cr.company.details.lei-code': 'LEI Code (Legal Entity Identifier)',
  'cr.company.details.no': 'Nee',
  'cr.company.details.number-of-employees': 'Aantal werknemers',
  'cr.company.details.number-of-fte': 'Aantal fulltime werknemers',
  'cr.company.details.old-graydon-number': 'Oud Graydon nummer',
  'cr.company.details.paritair-comite': 'Paritair comité',
  'cr.company.details.previous-company-name': 'Vorige bedrijfsnaam',
  'cr.company.details.recognition': 'Erkenning',
  'cr.company.details.recognition.agriculturalSocietyRecognition': 'Landbouwvennootschap',
  'cr.company.details.recognition.landAgencyRecognition': 'Bosgroepering',
  'cr.company.details.recognition.socialRecognition': 'Sociale erkenning',
  'cr.company.details.registration-id.BE': 'Ondernemingsnummer',
  'cr.company.details.registration-id.GB': 'Bedrijfsregistratie nummer ',
  'cr.company.details.registration-id.NL': 'KvK-nummer',
  'cr.company.details.rsz-number': 'RSZ-nummer',
  'cr.company.details.sector': 'Sector',
  'cr.company.details.size-description': 'Personeelsklasse omvang',
  'cr.company.details.title': 'Bedrijfsgegevens',
  'cr.company.details.tooltip': 'In dit gedeelte vindt u algemene gegevens over het bedrijf terug.',
  'cr.company.details.tooltip.BE':
    'Met de elementen in dit gedeelte krijgt u een algemeen beeld van het profiel van het bedrijf. Het ondernemingsnummer is in België het unieke nummer om een bedrijf te identificeren. Het wordt toegekend door de Kruispuntbank voor Ondernemingen en moet op alle officiële documenten (zoals bv. facturen) vermeld worden. Het RSZ-nummer is een nummer dat de Rijksdienst voor Sociale Zekerheid toekent om werkgevers te identificeren. Elke werkgever moet driemaandelijks een elektronische aangifte aan de RSZ bezorgen met daarop de gegevens van alle werknemers en de berekening van de verschuldigde bedragen. Bedrijven die geen personeel in dienst hebben, hebben geen RSZ-nummer.\nDe activiteitscode die hier getoond wordt, is het resultaat van een cascade. Hierbij wordt eerst gekeken naar de activiteitscode toegekend door de BTW-administratie en vervolgens de Bijlagen van het Belgisch Staasblad, de Kruispuntbank voor Ondernemingen, de Rijksdienst voor Sociale Zekerheid of via enquête.\nDe werkgeverscategorie, het paritair comité, alsook de personeelsklasse (een waarde van 0 tot en met 9), worden aangeleverd door de Rijksdienst voor Sociale Zekerheid. De personeelsklasse hangt samen met het aantal werknemers dat een bedrijf in dienst heeft, waarbij personeelsklasse 9 overeenkomt met meer dan 1.000 werknemers.',
  'cr.company.details.tooltip.GB': 'In deze rubriek vindt u algemene informatie over het bedrijf.',
  'cr.company.details.tooltip.NL':
    'Hier vindt u alle algemene gegevens over het bedrijf terug. Het Graydon nummer is een nieuw en uniform nummer voor een bedrijf die voor Nederland, België en het Verenigd Koninkrijk wordt gebruikt. Het oude Graydon nummer is een lokaal Graydon nummer wat vroeger gehanteerd werd. ',
  'cr.company.details.transunion-application': 'TransUnion application',
  'cr.company.details.vat-number': 'Btw-nummer',
  'cr.company.details.vat-status': 'BTW hoedanigheid',
  'cr.company.details.vat-status.no': 'Nee',
  'cr.company.details.view-documents': 'Ga naar documenten',
  'cr.company.details.werkgeverscategorie': 'Werkgeverscategorie',
  'cr.company.details.yes': 'Ja',
  'cr.company.inactive': 'Inactief',
  'cr.company.unavailable': 'Status niet beschikbaar',
  'cr.error.annualReport.natuur10.contact':
    'Omwille van het specifieke model kunnen de jaarrekeningen voor dit bedrijf niet verwerkt worden. U kunt de originele jaarrekeningen consulteren via de website van de <a href="https://www.nbb.be/nl" rel="noopener noreferrer" target="_blank">Nationale Bank van België</a>. Heeft u hier vragen over, contacteer dan ons Customer Service team via {contactInfo}.',
  'cr.error.annualReport.natuur10.tileHeading': 'Jaarrekeningen',
  'cr.error.annualReport.natuur10.title':
    'Dit bedrijf heeft een uitzonderlijk type jaarrekening neergelegd',
  'cr.error.contacts':
    'Indien dit nog altijd niet werkt, contacteer dan onze Klantendienst op <a href="mailto:{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}">{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}</a> of op {country, select, gb {+44 (020) 8515 1400} nl {+31 (020) 567 9501} other {+32 (03) 280 88 80}}.',
  'cr.error.goback': 'Ga terug naar zoeken',
  'cr.error.insolvencies.no-data.body':
    'Er zijn geen financiële calamiteiten voor dit bedrijf.<br /><br />Heeft u vragen of hulp nodig, contacteer dan ons Customer Service team via <a href="mailto:{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}">{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}</a> of op {country, select, gb {+44&nbsp;(0)20&nbsp;8515&nbsp;1400} nl {+31&nbsp;(020)&nbsp;567&nbsp;9501} other {+32&nbsp;(03)&nbsp;280&nbsp;88&nbsp;80}}.',
  'cr.error.insolvencies.no-data.title': 'Geen financiële calamiteiten voor dit bedrijf',
  'cr.error.no.annual.report.contact':
    'Dit bedrijf heeft de laatste 5 jaar geen jaarrekening gedeponeerd.<br /><br />Heeft u vragen of hulp nodig? Contacteer dan ons Customer Service team via <a href="mailto:{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}">{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}</a> of op {country, select, gb {+44 (020) 8515 1400} nl {+31 (020) 567 9501} other {+32 (03) 280 88 80}}.',
  'cr.error.no.annual.report.no-data-from-liability':
    'Dit bedrijf noch het bedrijf met een aansprakelijheidsverklaring voor dit bedrijf, heeft de laatste 5 jaar een jaarrekening gedeponeerd.<br /><br />Heeft u vragen of hulp nodig? Contacteer dan ons Customer Service team via <a href="mailto:{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}">{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}</a> of op {country, select, gb {+44 (020) 8515 1400} nl {+31 (020) 567 9501} other {+32 (03) 280 88 80}}.',
  'cr.error.no.annual.report.title': 'Geen jaarrekening beschikbaar voor dit bedrijf',
  'cr.error.no.annual.report.title-no-data-from-liability': 'Geen jaarrekening beschikbaar ',
  'cr.error.no.archived.reports.body':
    'U heeft nog geen acties ondernemen voor dit bedrijf of de acties zijn helaas niet beschikbaar.<br /><br />Klopt dit niet, contacteer dan ons Customer Service team via <a href="mailto:{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}">{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}</a> of op {country, select, gb {+44 (0)20 8515 1400} nl {+31 (020) 567 9501} other {+32 (03) 280 88 80}}.',
  'cr.error.no.archived.reports.title': 'Geen recente acties voor dit bedrijf',
  'cr.error.no.trends.body':
    'Heeft u vragen of hulp nodig? Contacteer dan ons Customer Service team via <a href="mailto:{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}">{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}</a> of op {country, select, gb {+44 (020) 8515 1400} nl {+31 (020) 567 9501} other {+32 (03) 280 88 80}}.',
  'cr.error.no.trends.body.condensed':
    'Heeft u vragen of hulp nodig, contacteer dan ons Customer Service team voor meer informatie.',
  'cr.error.no.trends.title': 'Geen trendgrafieken beschikbaar voor dit bedrijf',
  'cr.error.report.expired.text':
    'Onze excuses, de pagina die u koos is niet meer beschikbaar. Vraag een nieuw rapport aan of zoek het bedrijf en download het opgeslagen rapport.',
  'cr.error.report.expired.title': 'Dit rapport is niet meer beschikbaar …',
  'cr.error.report.fetch.list-header': 'Gelieve het volgende te proberen',
  'cr.error.report.fetch.list-item1': 'Zorg ervoor dat u de juiste URL heeft',
  'cr.error.report.fetch.list-item2': 'Pagina vernieuwen',
  'cr.error.report.fetch.list-item3': 'Ga terug naar Home en probeer uw pagina opnieuw te zoeken',
  'cr.error.report.fetch.text':
    'Onze excuses, de pagina die u koos bestaat helaas niet of werkt niet.',
  'cr.error.report.fetch.title': 'Er ging iets mis ...',
  'cr.error.search': 'Er ging iets mis …',
  'cr.error.search.contacts':
    'Gelieve het later opnieuw te proberen of contacteer ons Customer Service team via <a href="mailto:{country, select, gb {customerservices@graydon.co.uk} be {support@graydon.be} other {service@graydon.nl}}">{country, select, gb {customerservices@graydon.co.uk} be {support@graydon.be} other {service@graydon.nl}}</a> of op {country, select, gb {+44&nbsp;(0)20&nbsp;8515&nbsp;1400} be {+32&nbsp;(03)&nbsp;280&nbsp;8880} other {+31&nbsp;(020)&nbsp;567&nbsp;9501}}.',
  'cr.error.search.text':
    'Helaas is deze dienst niet beschikbaar en kan de zoekopdracht niet uitgevoerd worden.',
  'cr.error.tryagain': 'Probeer opnieuw',
  'cr.financial-calamities.curators': 'Met de benoeming van',
  'cr.financial-calamities.description': 'Omschrijving',
  'cr.financial-calamities.preliminary': 'Voorlopig',
  'cr.financial-calamities.preliminary.no': 'Nee',
  'cr.financial-calamities.preliminary.yes': 'Ja',
  'cr.financial-calamities.start-date': 'Datum',
  'cr.financial-calamities.title': 'Financiële calamiteiten',
  'cr.financial-calamities.tooltip':
    'In dit gedeelte vindt u een overzicht van de financiële calamiteiten voor dit bedrijf en alle bijbehorende details. ',
  'cr.financial-calamities.tooltip.BE':
    'In dit deel vindt u een overzicht van de financiële calamiteiten voor dit bedrijf en alle bijbehorende details. ',
  'cr.financial-calamities.tooltip.GB':
    'In dit deel vindt u een overzicht van de financiële calamiteiten voor dit bedrijf en alle bijbehorende details. ',
  'cr.financial-calamities.tooltip.NL':
    'Hier krijgt u meer details over eventuele financiële calamiteiten van dit bedrijf.',
  'cr.financials.liabilities.companyName': 'Bedrijfsnaam',
  'cr.financials.liabilities.data-from-liability':
    'De getoonde financiële informatie is afkomstig van het bedrijf dat een aansprakelijkheidsverklaring (403) heeft gedeponeerd.',
  'cr.financials.liabilities.main':
    '{liableCompany} is hoofdelijk aansprakelijk voor alle schulden die voortkomen uit juridisch bindende transacties die zijn aangegaan door dit bedrijf.',
  'cr.financials.liabilities.startDate': 'Startdatum',
  'cr.financials.liabilities.title': 'Aansprakelijkheid',
  'cr.financials.liabilities.tooltip':
    'Hier vindt u algemene gegevens over de passiva van het bedrijf.',
  'cr.financials.liabilities.tooltip.NL':
    'Hier vindt u algemene gegevens over de passiva van het bedrijf. Aansprakelijkheid is de verplichting om de nadelige gevolgen van een gebeurtenis te dragen.',
  'cr.hearder.reference': 'Referentie',
  'cr.insolvency.curators': 'Met de benoeming van',
  'cr.insolvency.description': 'Omschrijving',
  'cr.insolvency.descriptionDetails': 'Beschrijving en details financiële calamiteit',
  'cr.insolvency.endReason': 'Einde financiële calamiteit',
  'cr.insolvency.eventDate': 'Datum',
  'cr.insolvency.eventDetails': 'Details event',
  'cr.insolvency.forms': 'Datum',
  'cr.insolvency.forms-count': 'Details event',
  'cr.insolvency.forms-hide': 'Verberg formulieren',
  'cr.insolvency.no-description': 'Onbekend',
  'cr.insolvency.no-details': 'Geen details',
  'cr.insolvency.period': 'Start- en einddatum',
  'cr.insolvency.preliminary': 'Voorlopig',
  'cr.insolvency.preliminary.no': 'Nee',
  'cr.insolvency.preliminary.yes': 'Ja',
  'cr.insolvency.present': 'Heden',
  'cr.insolvency.startDate': 'Datum',
  'cr.insolvency.table.date': 'Datum',
  'cr.insolvency.table.form': 'Formulier',
  'cr.modal.report-fetching.header': 'Even geduld, we verzamelen de gegevens voor u',
  'cr.modal.report-fetching.text': 'gegevens aan het verzamelen …',
  'cr.modal.report-download-error.text.paragraph1':
    'Even geduld alstublieft. Het duurt even om uw rapport samen te stellen.\n\nAls er veel verkeer is op de site kan dit soms langer duren. Eenmaal gedownload, vindt u het PDF-rapport terug in uw archief.',
  'cr.modal.report-download-error.text.paragraph2':
    'Vindt u het toch niet terug in uw archief, contacteer dan onze afdeling Customer Care via <a href="mailto:{country, select, gb {customerservices@graydon.co.uk} be {support@graydon.be} other {service@graydon.nl}}">{country, select, gb {customerservices@graydon.co.uk} be {support@graydon.be} other {service@graydon.nl}}</a> of {country, select, gb {+44&nbsp;(0)20&nbsp;8515&nbsp;1400} be {+32&nbsp;(03)&nbsp;280&nbsp;8880} other {+31&nbsp;(020)&nbsp;567&nbsp;9501}}.',
  'cr.modal.report-download-error.title': 'Uw PDF-rapport wordt voorbereid.',
  'cr.modal.report-error.graydon.be': 'Graydon België',
  'cr.modal.report-error.graydon.gb': 'Graydon United Kingdom',
  'cr.modal.report-error.graydon.nl': 'Graydon Nederland',
  'cr.modal.report-error.text': 'Mocht u hulp nodig hebben, neem dan contact op met',
  'cr.modal.report-error.title': 'De gegevens konden niet opgehaald worden',
  'cr.modal.report-creating.header': 'S.V.P.  WACHTEN TERWIJL WE HET RAPPORT GENEREREN',
  'cr.modal.report-creating.text': 'Rapport gemaakt…',
  'cr.modal.request-new-report.company-archive-link': 'Bedrijf bekijken',
  'cr.modal.request-new-report.open-in-new-tab': 'Open in nieuw tabblad',
  'cr.modal.request-new-report.request-report-button': 'Rapport aanvragen',
  'cr.modal.request-new-report.text':
    "Bekijk de belangrijkste bedrijfsgegevens en de geschiedenis van acties op de bedrijfspagina van <a>{companyName}</a> of kies 'Meer' om een rapport voor dit bedrijf aan te vragen of het bedrijf toe te voegen aan Monitoring.",
  'cr.modal.request-new-report.title': 'Vraag een rapport aan',
  'cr.nav.item.1': 'Samenvatting',
  'cr.nav.item.2': 'Bedrijfsprofiel',
  'cr.nav.item.3': 'Ratings',
  'cr.nav.item.4': 'Financials',
  'cr.nav.item.5': 'Betaalgedrag',
  'cr.nav.item.6': 'Bedrijfsstructuur',
  'cr.nav.item.7': 'Uitzonderlijke kenmerken',
  'cr.payment-behavior.no-data-error.contacts':
    'Als er data beschikbaar zou moeten zijn, gelieve dan ons Customer Service team te contacteren via <a href="mailto:{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}">{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}</a> of op {country, select, gb {+44 (020) 8515 1400} nl {+31 (020) 567 9501} other {+32 (03) 280 88 80}}.',
  'cr.payment-behavior.no-data-error.message':
    'Helaas is er voor dit bedrijf nog geen informatie beschikbaar over het betaalgedrag. Doet u zaken met dit bedrijf? Deel uw betalingservaringen met ons en u krijgt een korting op de Graydon-oplossingen. Contacteer ons voor meer informatie.',
  'cr.payment-behavior.no-data-error.title': 'Geen informatie over betaalgedrag beschikbaar',
  'cr.payment-behaviour.analysis-table.amountCategory': 'Totaalbedrag aantal betalingen',
  'cr.payment-behaviour.analysis-table.legal.text':
    'Incidentele afwijkingen in het betaalgedrag kunnen het gevolg zijn van betwistingen of specifieke betaalafspraken tussen leverancier en het bedrijf.',
  'cr.payment-behaviour.analysis-table.numPaymentExperiences': 'Aantal ervaringen',
  'cr.payment-behaviour.analysis-table.pctOver90DBT': 'langer dan 90 dagen',
  'cr.payment-behaviour.analysis-table.pctTo30DBT': '1 t/m 30 dagen',
  'cr.payment-behaviour.analysis-table.pctTo60DBT': '31 t/m 60 dagen',
  'cr.payment-behaviour.analysis-table.pctTo90DBT': '61 t/m 90 dagen',
  'cr.payment-behaviour.analysis-table.pctWithinTerms': 'Binnen termijn',
  'cr.payment-behaviour.analysis-table.quarter': 'Kwartaal',
  'cr.payment-behaviour.analysis-table.suppliers': 'Aantal leveranciers',
  'cr.payment-behaviour.analysis-table.suppliers.text':
    'De betaalgegevens zijn gebaseerd op de ervaringen van {num} {num, plural, =1 {leverancier} other {leveranciers}}.',
  'cr.payment-behaviour.analysis-table.title': 'Analyse betaalgedrag',
  'cr.payment-behaviour.analysis-table.tooltip':
    'Dit overzicht toont in detail het aantal verwerkte betalingservaringen en in welke mate de afgesproken betaaltermijnen worden gerespecteerd of overschreden. Het huidige kwartaal is nog niet afgesloten waardoor de gegevens slechts een eerste indicatie zijn.',
  'cr.payment-behaviour.analysis-table.tooltip.BE':
    'Deze tabel gaat tot twee jaar terug en toont het bedrag aan verwerkte betalingservaringen in de desbetreffende periode. In de tabel vindt u verder een verdeling terug van de betalingen die binnen de afgesproken termijn uitgevoerd zijn en welk percentage na de vervaldag uitgevoerd is. Let op, betalingservaringen kunnen betrekking hebben op over het hoofd geziene rekeningen, betwistingen, ... Het huidige kwartaal is nog niet afgesloten. De gegevens zijn dan ook slechts een eerste indicatie.',
  'cr.payment-behaviour.analysis-table.tooltip.NL':
    'Dit overzicht toont het aantal verwerkte betalingservaringen en in welke mate de afgesproken betaaltermijnen worden gerespecteerd of overschreden.',
  'cr.payment-behaviour.ccj-details-table.amount': 'Bedrag (&pound;)',
  'cr.payment-behaviour.ccj-details-table.case-number': 'Rolnummer',
  'cr.payment-behaviour.ccj-details-table.court': 'Rechtbank',
  'cr.payment-behaviour.ccj-details-table.date': 'Datum',
  'cr.payment-behaviour.ccj-details-table.header': 'Overzicht vonnissen van het County Court',
  'cr.payment-behaviour.ccj-details-table.info':
    'Maximale weergave van de 20 meest recente vonnissen van het County Court van {totalCount} in totaal',
  'cr.payment-behaviour.ccj-details-table.no-data.text':
    'Dit bedrijf heeft geen vonnissen van het County Court of de informatie is niet beschikbaar.<br /><br />Klopt dit niet, contacteer dan ons Customer Service team via <a href="mailto:{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}">{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}</a> of op {country, select, gb {+44&nbsp;(0)20&nbsp;8515&nbsp;1400} nl {+31&nbsp;(020)&nbsp;567&nbsp;9501} other {+32&nbsp;(03)&nbsp;280&nbsp;88&nbsp;80}}.',
  'cr.payment-behaviour.ccj-details-table.no-data.title':
    'Geen vonnissen van het County Court beschikbaar voor dit bedrijf',
  'cr.payment-behaviour.ccj-details-table.satisfied': 'Voldaan',
  'cr.payment-behaviour.ccj-details-table.title': 'Detail vonnissen van het County Court',
  'cr.payment-behaviour.ccj-details-table.tooltip':
    'Hier vindt u de details van de vonnissen van het County Court die lopen tegen het bedrijf.',
  'cr.payment-behaviour.ccj-details-table.tooltip.GB':
    'Hier vindt u een samenvatting van de vonnissen van het County Court die lopen tegen het bedrijf, met het totaal bedrag en het gedeelte dat nog geregeld moet worden. Onder de samenvatting vindt u de details van de 20 meest recente vonnissen van het County Court.',
  'cr.payment-behaviour.ccj-details-table.type': 'Type',
  'cr.payment-behaviour.ccj-summaries-table.col.last18month': 'Laatste 18 maanden',
  'cr.payment-behaviour.ccj-summaries-table.col.last18monthMonthlyAverage':
    'Maandelijks gemiddelde',
  'cr.payment-behaviour.ccj-summaries-table.col.last36month': 'Laatste 36 maanden',
  'cr.payment-behaviour.ccj-summaries-table.col.last36monthMonthlyAverage':
    'Maandelijks gemiddelde',
  'cr.payment-behaviour.ccj-summaries-table.col.last72month': 'Laatste 72 maanden',
  'cr.payment-behaviour.ccj-summaries-table.col.last72monthMonthlyAverage':
    'Maandelijks gemiddelde',
  'cr.payment-behaviour.ccj-summaries-table.header': 'Samenvatting vonnissen van het County Court',
  'cr.payment-behaviour.ccj-summaries-table.row.count': 'Aantal',
  'cr.payment-behaviour.ccj-summaries-table.row.highestAmount': 'Hoogste bedrag',
  'cr.payment-behaviour.ccj-summaries-table.row.totalAmount': 'Totaal bedrag',
  'cr.payment-behaviour.ccj-summaries-table.row.unsettledAmount': 'Openstaand bedrag',
  'cr.payment-behaviour.ccj-summaries-table.sub-header': 'Samenvatting',
  'cr.payment-behaviour.summons-and-judgments.no-data.text':
    'Dit bedrijf heeft geen dagvaardingen of de informatie is momenteel niet beschikbaar.<br /><br />Heeft u vragen of hulp nodig, contacteer dan ons Customer Service team via <a href="mailto:{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}">{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}</a> of op {country, select, gb {+44&nbsp;(0)20&nbsp;8515&nbsp;1400} nl {+31&nbsp;(020)&nbsp;567&nbsp;9501} other {+32&nbsp;(03)&nbsp;280&nbsp;88&nbsp;80}}.',
  'cr.payment-behaviour.summons-and-judgments.no-data.title':
    'Geen dagvaardingen en vonnissen beschikbaar voor dit bedrijf',
  'cr.payment.behaviour.averages.disclaimer':
    'Alle gegevens verwijzen naar de periode na vervaldag.',
  'cr.payment.behaviour.averages.th.1': 'Dit bedrijf',
  'cr.payment.behaviour.averages.th.2': 'Sector',
  'cr.payment.behaviour.averages.title': 'Gemiddeld betalingsbedrag',
  'cr.payment.behaviour.averages.tooltip':
    'In dit gedeelte vindt u meer informatie over de gemiddelde betalingsbedragen die dit bedrijf heeft gemaakt voor openstaande hypotheken in vergelijking met de sector.',
  'cr.payment.behaviour.averages.tooltip.GB':
    'In dit gedeelte vindt u meer informatie over de gemiddelde bedragen die dit bedrijf heeft betaald voor openstaande hypotheken, vergeleken met de sector.',
  'cr.payment.behaviour.averages.tr.1': 'Deze maand',
  'cr.payment.behaviour.averages.tr.2': 'Laatste 3 maanden',
  'cr.payment.behaviour.averages.tr.3': 'Laatste 6 maanden',
  'cr.payment.behaviour.averages.tr.4': 'Laatste 12 maanden',
  'cr.payment.behaviour.graph.noData': 'Niet beschikbaar',
  'cr.payment.behaviour.graph.noData-short': 'na',
  'cr.payment.behaviour.graph.pctOver90DBT': 'langer dan 90 dagen',
  'cr.payment.behaviour.graph.pctOver90DBT-short': '> 90',
  'cr.payment.behaviour.graph.pctTo30DBT': '1 t/m 30 dagen',
  'cr.payment.behaviour.graph.pctTo30DBT-short': '1 t/m 30',
  'cr.payment.behaviour.graph.pctTo60DBT': '31 t/m 60 dagen',
  'cr.payment.behaviour.graph.pctTo60DBT-short': '31 t/m 60',
  'cr.payment.behaviour.graph.pctTo90DBT': '61 t/m 90 dagen',
  'cr.payment.behaviour.graph.pctTo90DBT-short': '61 t/m 90',
  'cr.payment.behaviour.graph.pctWithinTerms': 'binnen termijn',
  'cr.payment.behaviour.graph.pctWithinTerms-short': 'Binnen termijn',
  'cr.payment.behaviour.graph.quarter': 'Kwartaal',
  'cr.payment.behaviour.graph.title': 'Betaalgedrag laatste 4 kwartalen',
  'cr.payment.behaviour.graph.tooltip':
    'Het is leuk als u weet dat een bedrijf een mooie jaarrekening kan voorleggen en prima scoort op vlak van kredietwaardigheid. Maar dat betekent nog niet dat de klant ook uw facturen op tijd zal betalen. Sommige bedrijven zijn slordig op dat vlak of betalen gewoon systematisch te laat. Al kunt u het ook omgekeerd bekijken. Bedrijven met een financieel zwakker plaatje kunnen best goede betalers zijn. Daarom is het betaalgedrag een mooie aanvulling op het kredietadvies. Los van het feit of de klant u wel of niet kan betalen, vertelt het of de klant dat ook zal doen.',
  'cr.payment.behaviour.graph.tooltip.BE':
    'Uit de grafische weergave van het betaalgedrag over de laatste 4 kwartalen - verdeeld in een periode binnen termijn en verschillende segmenten buiten termijn - kunt u snel afleiden of het betaalgedrag in positieve of negatieve zin evolueert.',
  'cr.payment.behaviour.graph.tooltip.NL':
    'Hier ziet u het betaalgedrag van de afgelopen vier kwartalen en in welke mate de afgesproken betaaltermijnen worden gerespecteerd of overschreden.',
  'cr.payment.behaviour.mortgages.dateOfLastFullSatisfaction':
    'Datum laatste hypotheek volledig voldaan',
  'cr.payment.behaviour.mortgages.dateOfLastMortgage': 'Datum van de laatste hypotheek',
  'cr.payment.behaviour.mortgages.dateOfLastPartialSatisfaction': 'Datum gedeeltelijk voldaan',
  'cr.payment.behaviour.mortgages.numberFullySatisfied': 'Aantal voldaan',
  'cr.payment.behaviour.mortgages.numberPartiallySatisfied': 'Aantal gedeeltelijk voldaan',
  'cr.payment.behaviour.mortgages.title': 'Hypotheken en kosten',
  'cr.payment.behaviour.mortgages.tooltip':
    'In deze sectie worden de meest recente hypotheken, kosten en terugbetalingen beschreven. Let op, er is geen wettelijke vereiste om terugbetalingen over hypotheken/kosten bij Companies House in te dienen.',
  'cr.payment.behaviour.mortgages.tooltip.GB':
    'In deze sectie worden de meest recente hypotheken, kosten en terugbetalingen beschreven. Let op, er is geen wettelijke vereiste om terugbetalingen over hypotheken/kosten bij Companies House in te dienen.',
  'cr.payment.behaviour.mortgages.totalNumberOfMortgages':
    'Totaal aan hypotheken / geregistreerde kosten',
  'cr.payment.behaviour.payment.score.trend.disclaimer':
    'Hier vindt u het verloop van de betaalscore over de afgelopen 12 maanden.',
  'cr.payment.behaviour.payment.score.trend.title': 'Trend betaalscore',
  'cr.payment.behaviour.payment.score.trend.tooltip.BE':
    'Hier vindt u het verloop van de betaalscore over de afgelopen 12 maanden.',
  'cr.payment.behaviour.payment.score.trend.tooltip.GB':
    'Hier vindt u het verloop van de betaalscore over de afgelopen 12 maanden.',
  'cr.payment.behaviour.payment.score.trend.tooltip.NL':
    'Hier vindt u het verloop van de betaalscore over de afgelopen 12 maanden.',
  'cr.payment.behaviour.payment.trend.disclaimer':
    'Alle gegevens van {value} ervaringen verwijzen naar de periode na de vervaldag.',
  'cr.payment.behaviour.payment.trend.title': 'Days Beyond Terms Trend',
  'cr.payment.behaviour.payment.trend.tooltip':
    'Deze sectie geeft de betalingstrends van dit bedrijf weer, verwijzend naar de betalingen uitgevoerd na de vervaldag.',
  'cr.payment.behaviour.payment.trend.tooltip.BE':
    'Uit de grafische weergave van het betaalgedrag over de laatste 4 kwartalen - verdeeld in een periode binnen termijn en verschillende segmenten buiten termijn - kunt u snel afleiden of het betaalgedrag in positieve of negatieve zin evolueert.',
  'cr.payment.behaviour.payment.trend.tooltip.GB':
    'Deze sectie geeft de betalingstrends van dit bedrijf weer, verwijzend naar de betalingen uitgevoerd na de vervaldag.',
  'cr.payment.behaviour.payment.trend.tooltip.NL':
    'Hier ziet u het betaalgedrag van de afgelopen vier kwartalen en in welke mate de afgesproken betaaltermijnen worden gerespecteerd of overschreden.',
  'cr.payment.behaviour.registeredCharges.andOthers': 'en anderen.',
  'cr.payment.behaviour.registeredCharges.dateCreated': 'Datum aangemaakt',
  'cr.payment.behaviour.registeredCharges.dateRegistered': 'Datum registratie',
  'cr.payment.behaviour.registeredCharges.dateSatisfied': 'Datum volledig voldaan',
  'cr.payment.behaviour.registeredCharges.description': 'Omschrijving',
  'cr.payment.behaviour.registeredCharges.disclaimer':
    'Maximale weergave van de 20 meest recente hypotheken op een totaal van {totalCount}',
  'cr.payment.behaviour.registeredCharges.lender': 'Hypotheekverstrekker',
  'cr.payment.behaviour.registeredCharges.no-data-text':
    'Dit bedrijf heeft geen hypotheken of de informatie is niet beschikbaar.<br/><br/> Heeft u vragen of hulp nodig, contacteer dan ons Customer Service team via <a href="mailto:{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}">{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}</a> of op {country, select, gb {+44&nbsp;(0)20&nbsp;8515&nbsp;1400} nl {+31&nbsp;(020)&nbsp;567&nbsp;9501} other {+32&nbsp;(03)&nbsp;280&nbsp;88&nbsp;80}}.',
  'cr.payment.behaviour.registeredCharges.no-data-title': 'Dit bedrijf heeft geen hypotheken.',
  'cr.payment.behaviour.registeredCharges.summary.current': 'Huidige waarde',
  'cr.payment.behaviour.registeredCharges.summary.title': 'Samenvatting hypotheken en kosten',
  'cr.payment.behaviour.registeredCharges.summary.totals': 'Totaal',
  'cr.payment.behaviour.registeredCharges.title': 'Hypotheken en kosten',
  'cr.payment.behaviour.summons-and-judgments.date': 'Datum',
  'cr.payment.behaviour.summons-and-judgments.description': 'Omschrijving',
  'cr.payment.behaviour.summons-and-judgments.info':
    'Maximale weergave van de 20 meest recente dagvaardingen en vonnissen van {totalCount} in totaal',
  'cr.payment.behaviour.summons-and-judgments.InsuranceAndSelfEmployed':
    'Dagvaardingen Sociaal verzekeringsfonds voor zelfstandigen',
  'cr.payment.behaviour.summons-and-judgments.RSZ': 'RSZ',
  'cr.payment.behaviour.summons-and-judgments.SocialSecurityFund':
    'Dagvaardingen Fonds voor Bestaanszekerheid',
  'cr.payment.behaviour.summons-and-judgments.title': 'Dagvaardingen',
  'cr.payment.behaviour.summons-and-judgments.tooltip':
    'De uitgebrachte dagvaardingen kunnen het gevolg zijn van over het hoofd geziene rekeningen, betwistingen, ...',
  'cr.payment.behaviour.summons-and-judgments.tooltip.BE':
    'In dit gedeelte vindt u een overzicht van de dagvaardingen door de Rijksdienst voor Sociale Zekerheid, het Sociaal Verzekeringfonds voor Zelfstandigen of het Fonds voor Bestaanszekerheid, inclusief de datum van de zitdag. Let op, de uitgebrachte dagvaardingen kunnen het gevolg zijn van over het hoofd geziene rekeningen, betwistingen, etc. Daarom krijgt elke onderneming ook een brief met de vraag om ons meer informatie te bezorgen over de betreffende dagvaarding(en).\n',
  'cr.payment.behaviour.summons-and-judgments.tooltip.NL':
    'De uitgebrachte dagvaardingen kunnen het gevolg zijn van over het hoofd geziene rekeningen, betwistingen, ...',
  'cr.payment.behaviour.unsecuredCreditors.amount': 'Bedrag',
  'cr.payment.behaviour.unsecuredCreditors.companyName': 'Bedrijfsnaam',
  'cr.payment.behaviour.unsecuredCreditors.disclaimer':
    'Maximale weergave van de 20 grootste vorderingen op een totaal van {totalCount}',
  'cr.payment.behaviour.unsecuredCreditors.no-data-text':
    'Dit bedrijf heeft geen ongedekte schuldeisers of de informatie is niet beschikbaar.<br/><br/> Heeft u vragen of hulp nodig, contacteer dan ons Customer Service team via <a href="mailto:{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}">{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}</a> of op {country, select, gb {+44&nbsp;(0)20&nbsp;8515&nbsp;1400} nl {+31&nbsp;(020)&nbsp;567&nbsp;9501} other {+32&nbsp;(03)&nbsp;280&nbsp;88&nbsp;80}}.',
  'cr.payment.behaviour.unsecuredCreditors.no-data-title':
    'Geen ongedekte schuldeisers beschikbaar voor dit bedrijf.',
  'cr.payment.behaviour.unsecuredCreditors.statementDate': 'Datum van aangifte',
  'cr.payment.behaviour.unsecuredCreditors.summary': 'Samenvatting ongedekte schuldeisers',
  'cr.payment.behaviour.unsecuredCreditors.tabTitle': 'Ongedekte schuldeisers',
  'cr.payment.behaviour.unsecuredCreditors.tooltip':
    'Deze lijst omvat de ongedekte schuldeisers zoals bekend op de datum van aangifte.',
  'cr.payment.behaviour.unsecuredCreditors.totalNumber': 'Totaal aantal ongedekte schuldeisers',
  'cr.payment.behaviour.unsecuredCreditors.totalValue': 'Totale waarde ongedekte schulden',
  'cr.payment.behaviour.unsecuredCreditors.unknown': 'Onbekend',
  'cr.pdf-archive.available.actions': 'Kies uit een van deze acties voor dit bedrijf',
  'cr.pdf-archive.download.button.title': 'Download PDF',
  'cr.pdf-archive.download.button.title.en': 'Engelse PDF',
  'cr.pdf-archive.download.button.title.fr': 'Franse PDF',
  'cr.pdf-archive.download.button.title.nl': 'Nederlandse PDF',
  'cr.pdf-archive.item.title': 'Credit report',
  'cr.pdf-archive.item.button.no-avail': 'Download is niet meer beschikbaar',
  'cr.pdf-archive.max.reports.message':
    'We tonen de 50 meeste recente acties.<br /><br />Heeft u vragen hierover? Neem dan contact op met Customer Service team via <a href="mailto:{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}">{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}</a> of op {country, select, gb {+44 (020) 8515 1400} nl {+31 (020) 567 9501} other {+32 (03) 280 88 80}}.',
  'cr.pdf-archive.previous.actions': 'Eerdere aanvragen',
  'cr.pdf-archive.title': 'Acties voor dit bedrijf',
  'cr.preferences': 'Voorkeuren',
  'cr.preferences.cancel-button': 'Annuleren',
  'cr.preferences.default-list.no-list-message.part.one':
    'Om deze optie te gebruiken moet u eerst een of meerdere lijsten aanmaken. ',
  'cr.preferences.default-list.no-list-message.part.two': 'Dit kan in de List Management app.',
  'cr.preferences.default.list-preference.description':
    'Als u deze optie activeert, zal elk bedrijf waarvan u een kredietrapport opvraagt aan de standaard lijst worden toegevoegd. U kunt deze lijst gebruiken voor monitoring, data verrijking of om te exporteren.\n\nKiest u liever zelf welk bedrijf u toevoegt? Laat deze functie dan gedeactiveerd. ',
  'cr.preferences.default.list-select.list.title': 'Selecteer een lijst',
  'cr.preferences.default.list-title': 'Bekijk het rapport en voeg het bedrijf toe aan de lijst',
  'cr.preferences.default.list-toggle.label': 'Voeg toe aan standaard lijst',
  'cr.preferences.default.list-toggle.title': 'Activeer standaard lijst optie',
  'cr.preferences.default.list-tooltip':
    'U kunt hier instellen dat een bedrijf standaard aan een lijst wordt toegevoegd, zodra u een kredietrapport aanvraagt. ',
  'cr.preferences.default.list.select.list-monitoring.info':
    'Deze lijst bevat <b>{numberOfProfilesAttachedToSelectedList}</b> monitoring profielen.',
  'cr.preferences.default.list.select.list-monitoring.info.tooltip.body':
    'Als een lijst gekoppeld is aan een monitoring profiel ontvangt u updates wanneer er data wijzigt bij het bedrijf. U kunt een lijst koppelen aan een monitoring profiel in de Monitoring App.',
  'cr.preferences.default.list.select.list-monitoring.info.tooltip.title': 'Monitoring profiles',
  'cr.preferences.save-button': 'Bewaar voorkeuren',
  'cr.preferences.title': 'Voorkeuren',
  'cr.preferences.tosti.error.body':
    'Er is een onverwachte fout opgetreden. Vernieuw de pagina of probeer het later opnieuw.',
  'cr.preferences.tosti.error.title': 'Er ging iets mis',
  'cr.preferences.tosti.success.body':
    'Bij elke nieuwe aanvraag van een kredietrapport zal het bedrijf aan de geselecteerde lijst toegevoegd worden.',
  'cr.preferences.tosti.success.title': 'Uw voorkeuren zijn opgeslagen',
  'cr.ratings.credit-rating.0': 'Financiële sterkte minder dan £1',
  'cr.ratings.credit-rating.A': 'Algemeen risiconiveau uitstekend',
  'cr.ratings.credit-rating.B': 'Algemeen risiconiveau goed',
  'cr.ratings.credit-rating.C': 'Algemeen risiconiveau redelijk',
  'cr.ratings.credit-rating.D': 'Algemeen risiconiveau zwak',
  'cr.ratings.credit-rating.description':
    'Deze beoordeling is gebaseerd op specifieke informatie uit de sector en toont de financiële sterkte en kredietwaardigheid aan.',
  'cr.ratings.credit-rating.N': 'Recent opgerichte onderneming',
  'cr.ratings.credit-rating.na': 'Niet beschikbaar',
  'cr.ratings.credit-rating.NA': 'Niet beschikbaar',
  'cr.ratings.credit-rating.NIL': 'Geen neergelegde jaarrekeningen of negatieve informatie',
  'cr.ratings.credit-rating.P': 'Ouderlijke link',
  'cr.ratings.credit-rating.S': 'Speciale rating',
  'cr.ratings.credit-rating.SN': 'Speciale rating',
  'cr.ratings.credit-rating.title': 'Kredietrating',
  'cr.ratings.credit-rating.tooltip':
    'De Credit Rating is een alfanumerieke rating, gelezen in combinatie met een kredietrichtlijn. De rating is gebaseerd op alle informatie en gegevens in onze database en vat onze algemene opinie samen over het onderwerp van uw onderzoek.',
  'cr.ratings.credit-rating.tooltip.BE':
    'De Graydon Rating toont de mate van kredietrisico aan. De AAA-rating is de hoogste rating en wijst op het laagst mogelijke risico. De C-rating is de zwakste rating en wijst op een zeer hoog rsicio. Bedrijven met een rating D verkeren in een staat van faillissement. NR betekent dat er geen rating vastgelegd kon worden omdat het bedrijf passief is. De kans op faillissement geeft aan hoe groot het risico is dat het bedrijf ernstige financiële problemen ondervindt en failliet gaat. Hoe lager het percentage hoe lager het risico.',
  'cr.ratings.credit-rating.tooltip.GB':
    'Let op: Om gebruik te maken van het nieuwste risicomodel verwijzen wij u naar de Graydon Rating of Augur Score. De Credit Rating wordt eind september 2021 stopgezet. Neem voor meer informatie contact op met uw accountmanager.\n\nDe alfanumerieke Graydon Credit Rating is gebaseerd op alle data en informatie waarover Graydon beschikt. Hij vat het algemene beeld en de aanbevelingen samen die Graydon afleidt uit recente gedragingen en de financiële prestaties van het bedrijf (A - Uitstekend; B - Goed; C - Redelijk; D - Slechts).',
  'cr.ratings.credit-rating.tooltip.NL':
    'De Graydon Rating toont de mate van kredietrisico, waarbij AAA-rating de hoogst mogelijke rating is en C de laagste. D geeft aan dat het bedrijf in staat van faillissement verkeert. NR geeft aan dat geen rating vastgelegd kon worden, omdat het bedrijf passief is. De kans op faillissement geeft inzicht in het risico dat het bedrijf ernstige financiële problemen ondervindt. Hoe hoger het percentage hoe groter het risico.',
  'cr.ratings.credit-rating.X': 'Financiële moeilijkheden of sluiting',
  'cr.ratings.credit.flag.description': 'De geadviseerde kredietlimiet voor dit bedrijf.',
  'cr.ratings.credit.flag.information.G': 'Geen bezwaar tegen kredietfaciliteiten',
  'cr.ratings.credit.flag.information.O':
    'Kredietfaciliteit kan verleend worden maar moet in de gaten gehouden worden.',
  'cr.ratings.credit.flag.information.R':
    'Verlening van een kredietfaciliteit wordt niet geadviseerd.',
  'cr.ratings.credit.flag.information.text':
    'Deze beoordeling is gebaseerd op de kredietlimiet en de Graydon Rating.',
  'cr.ratings.credit.flag.limit.tooltip':
    '**Credit flag**\n\nDe credit flag werkt als een stoplicht, en is kortgezegd een simpele vertaling van het kredietadvies en de Graydon Rating. **[Groen](#green)** = kredietverlening levert geen problemen op. **[Oranje](#orange)** = krediet verlenen gaat gepaard met een verhoogd risico. **[Rood](#red)** = Graydon raadt af om aan voorfinanciering te doen.\n\n\n**Credit limit**\n\nDe kredietlimiet geeft aan tot welk bedrag het verantwoord is om met een bedrijf zaken te doen. De kredietlimiet vorm een waardeoordeel op korte termijn.',
  'cr.ratings.credit.flag.maximum-limit': 'Maximale kredietlimiet',
  'cr.ratings.credit.flag.monthly-credit-guide': 'Maandelijkse kredietrichtlijn',
  'cr.ratings.credit.flag.title': 'Kredietvlag & kredietlimiet',
  'cr.ratings.credit.flag.tooltip':
    'De kredietlimiet geeft aan hoeveel cash een onderneming op korte termijn kan vrijmaken om openstaande schulden af te lossen.',
  'cr.ratings.credit.flag.tooltip.BE':
    'De credit flag geeft op een eenvoudige manier weer in hoeverre het veilig is om krediet te verlenen aan een bedrijf. Groen = krediet verlenen levert geen problemen op. Oranje = krediet verlenen gaat gepaard met een verhoogd risico. Rood = Graydon vindt het niet verantwoord om krediet te verlenen. \nDe kredietlimiet geeft de maximale hoeveelheid cash aan die een onderneming op korte termijn kan vrijmaken om openstaande schulden af te lossen.',
  'cr.ratings.credit.flag.tooltip.GB':
    'De credit flag fungeert als verkeerslicht. Groen = krediet verlenen levert geen problemen op. Oranje = krediet verlenen gaat gepaard met een verhoogd risico. Rood = Graydon vindt het niet verantwoord om krediet te verlenen. De kredietlimiet geeft aan tot welk bedrag u zaken kunt doen met een bedrijf. De kredietlimiet is een waardeoordeel op korte termijn.',
  'cr.ratings.credit.limit.trend.title': 'Trend kredietlimiet',
  'cr.ratings.credit.limit.trend.tooltip':
    'Hier vindt u het verloop van de kredietlimiet over de afgelopen 12 maanden.',
  'cr.ratings.delphi.index.1': 'Delphi index 1, verhoogd risico',
  'cr.ratings.delphi.index.2': 'Delphi index 2, verhoogd risico',
  'cr.ratings.delphi.index.3': 'Delphi index 3, verhoogd risico',
  'cr.ratings.delphi.index.4': 'Delphi index 4, gemiddeld risico',
  'cr.ratings.delphi.index.5': 'Delphi index 5, gemiddeld risico',
  'cr.ratings.delphi.index.6': 'Delphi index 6, gemiddeld risico',
  'cr.ratings.delphi.index.7': 'Delphi index 7, laag risico',
  'cr.ratings.delphi.index.8': 'Delphi index 8, laag risico',
  'cr.ratings.delphi.index.9': 'Delphi index 9, laag risico',
  'cr.ratings.delphi.index.personal.credit.information': 'Persoonlijke kredietindicatie',
  'cr.ratings.delphi.index.tooltip':
    '**Credit flag**\n\nDe credit flag werkt als een stoplicht, en is kortgezegd een simpele vertaling van het kredietadvies en de Graydon Rating. **[Groen](#green)** = kredietverlening levert geen problemen op. **[Oranje](#orange)** = krediet verlenen gaat gepaard met een verhoogd risico. **[Rood](#red)** = Graydon raadt af om aan voorfinanciering te doen.\n\n**Credit limit**\n\nDe kredietlimiet geeft aan tot welk bedrag het verantwoord is om met een bedrijf zaken te doen. De kredietlimiet vorm een waardeoordeel op korte termijn.\n\n**Persoonlijke kredietindicatie**\n\nDe Delphi index is een persoonlijke kredietindicatie voor een natuurlijke persoon, rekening houdend met negatieve registraties bij bijvoorbeeld banken, deurwaarders of incassobureaus en rekening houdend met omgevingsfactoren en informatie met betrekking tot het onroerend goed.\nHoe hoger de Delphi index. hoe lager het risico niveau. De Delphi index is samengesteld door Experian.',
  'cr.ratings.discontinuation-score.1': 'Zeer hoog',
  'cr.ratings.discontinuation-score.2': 'Hoog',
  'cr.ratings.discontinuation-score.3': 'Neutraal',
  'cr.ratings.discontinuation-score.4': 'Laag',
  'cr.ratings.discontinuation-score.5': 'Zeer laag',
  'cr.ratings.discontinuation-score.description':
    'Hoe waarschijnlijk is het dat dit bedrijf de komende 12 maanden zal ophouden te bestaan.',
  'cr.ratings.discontinuation-score.NA': 'Niet beschikbaar ',
  'cr.ratings.discontinuation-score.NR': 'Niet beoordeeld',
  'cr.ratings.discontinuation-score.title': 'Kans op opheffing',
  'cr.ratings.discontinuation-score.tooltip':
    'De opheffingsscore voorspelt hoe waarschijnlijk het is dat een bedrijf in de komende 12 maanden wordt opgeheven. De score kijkt niet alleen naar de kans op een faillissement, maar ook naar de kans op ontbinding, fusie, overname en stopzetting.',
  'cr.ratings.discontinuation-score.tooltip.BE':
    'De kans op opheffing voorspelt hoe waarschijnlijk het is dat een bedrijf in de komende 12 maanden ophoudt te bestaan. De score kijkt hierbij niet naar de kans op een faillissement of WCO (Wet op de continuïteit van de onderneming), maar naar de kans op ontbinding, fusie, overname en stopzetting.',
  'cr.ratings.discontinuation-score.tooltip.NL':
    'De kans op opheffing voorspelt hoe waarschijnlijk het is dat een bedrijf ophoudt te bestaan in de komende 12 maanden.',
  'cr.ratings.graydon.rating.trend.title': 'Trend Graydon rating',
  'cr.ratings.graydon.rating.trend.tooltip':
    'Hier vindt u het verloop van de Graydon rating over de afgelopen 12 maanden.',
  'cr.ratings.growth-score.1': 'Sterke krimp',
  'cr.ratings.growth-score.2': 'Krimp',
  'cr.ratings.growth-score.3': 'Neutraal',
  'cr.ratings.growth-score.4': 'Groei',
  'cr.ratings.growth-score.5': 'Sterke groei',
  'cr.ratings.growth-score.99': 'Niet te voorspellen',
  'cr.ratings.growth-score.description':
    'Hoe waarschijnlijk is het dat de totale activa van dit bedrijf in de komende 12 maanden zal groeien.',
  'cr.ratings.growth-score.NA': 'Niet beschikbaar ',
  'cr.ratings.growth-score.NR': 'Niet beoordeeld',
  'cr.ratings.growth-score.title': 'Groeiverwachting',
  'cr.ratings.growth-score.tooltip':
    'De groeiscore geeft een indicatie over de potentiële groeikansen van een bedrijf in normale economische omstandigheden. Ze bekijkt in welke mate een onderneming haar eigen groei kan financieren, zonder onmiddellijk beroep te doen op extern kapitaal.\nLet op, gezien de coronacrisis kunnen we niet langer spreken van normale economische omstandigheden, zodat de groeiverwachting zou kunnen afwijken van de realiteit.',
  'cr.ratings.growth-score.tooltip.BE':
    "De groeiverwachting geeft een indicatie over de potentiële groeikansen van een bedrijf voor de komende 12 maanden in normale economische omstandigheden. Het bekijkt in welke mate een onderneming haar eigen groei kan financieren, zonder onmiddellijk beroep te doen op extern kapitaal. De groeiverwachting is alleen beschikbaar voor bedrijven die een jaarrekening publiceren. De indicatie 'niet te voorspellen' betekent dat de groeiverwachting volatiel of sterk wisselend is.\nLet op, gezien de coronacrisis kunnen we niet langer spreken van normale economische omstandigheden, zodat de groeiverwachting zou kunnen afwijken van de realiteit.",
  'cr.ratings.growth-score.tooltip.GB':
    "Dit is een gerichte prognose van de verwachte groei van de totale activa van de onderneming in de komende 12 maanden in normale economische omstandigheden. De indicatie 'niet te voorspellen' betekent dat de groeiverwachting volatiel of sterk wisselend is.\nLet op, gezien de coronacrisis kunnen we niet langer spreken van normale economische omstandigheden, zodat de groeiverwachting zou kunnen afwijken van de realiteit.",
  'cr.ratings.growth-score.tooltip.NL':
    "De groeiverwachting maakt een gerichte prognose over de verwachte groei van de totale activa van bedrijven en vestigingen in de komende twaalf maanden in normale economische omstandigheden. De indicatie 'niet te voorspellen' betekent dat de groeiverwachting volatiel of sterk wisselend is.\nLet op, gezien de coronacrisis kunnen we niet langer spreken van normale economische omstandigheden, zodat de groeiverwachting zou kunnen afwijken van de realiteit",
  'cr.ratings.no.delphi.index': 'Geen Delphi index beschikbaar',
  'cr.ratings.payment-score.description':
    'In welke mate betaalt dit bedrijf haar facturen op tijd.',
  'cr.ratings.payment-score.lower-bound': 'De betaalvoorwaarden worden niet gerespecteerd',
  'cr.ratings.payment-score.NR': 'Niet beoordeeld',
  'cr.ratings.payment-score.range-sector-1': 'De betaalvoorwaarden worden niet gerespecteerd',
  'cr.ratings.payment-score.range-sector-2': 'Onder het gemiddelde',
  'cr.ratings.payment-score.range-sector-3': 'Gemiddeld',
  'cr.ratings.payment-score.range-sector-4': 'Goed betaalgedrag',
  'cr.ratings.payment-score.title': 'Betaalscore',
  'cr.ratings.payment-score.tooltip':
    'Het kredietadvies vertelt u of uw zakenrelatie u op korte termijn kan betalen. De betaalscore en het betalingsgedrag vertellen u of uw zakenrelatie dat ook effectief zal doen.',
  'cr.ratings.payment-score.tooltip.BE':
    'De betaalscore vertelt u hoe correct uw klant de afgesproken betaaltermijn respecteert.\nLet op het verschil met de kredietlimiet, die aangeeft of uw klant op korte termijn de capaciteit heeft om zijn leveranciers te betalen. De kredietmimiet vertelt of uw klant kan betalen. De betaalscore geeft aan of de klant dat ook effectief zal doen. Hoe hoger de betaalscore (op een schaal van 0 tot 10), hoe beter het betaalgedrag.\nDe betaalscore vloeit voort uit een uitwisselingsproject waarbij duizenden leveranciers miljoenen betalingservaringen met elkaar delen. Discreet en anoniem. Alle deelnemers signaleren op die manier zwakke betalers, maar belonen ook goed betalende klanten. Deelnemen aan het uitwisselingsproject Data Exchange Pooling van Graydon kost u niets. Integendeel, u toont zich collegiaal en u krijgt in ruil voor uw aanlevering een commerciële tegemoetkoming. Neem contact op met ons voor meer informatie.',
  'cr.ratings.payment-score.tooltip.GB':
    'De betaalscore geeft aan in welke mate een organisatie haar facturen tijdig betaalt. Hier geldt de volgende regel: hoe hoger de betaalscore, hoe correcter het bedrijf haar facturen betaalt.',
  'cr.ratings.payment-score.tooltip.NL':
    'De betaalscore geeft aan tot op welke hoogte een organisatie haar facturen op tijd betaalt. Hier geldt: hoe hoger de betaalscore, hoe beter het bedrijf haar facturen op tijd betaalt.',
  'cr.ratings.payment-score.upper-bound': 'Goed betaalgedrag',
  'cr.ratings.rating.augur-rating': '(Augur score: { augurRating })',
  'cr.ratings.rating.description': 'Percentage kans op faillissement',
  'cr.ratings.rating.information.A': 'Lage kans op wanbetaling',
  'cr.ratings.rating.information.AA': 'Lage kans op wanbetaling',
  'cr.ratings.rating.information.AAA': 'Kans op wanbetaling is zeer laag',
  'cr.ratings.rating.information.B': 'Neutrale kans op wanbetaling',
  'cr.ratings.rating.information.BB': 'Neutrale kans op wanbetaling',
  'cr.ratings.rating.information.BBB': 'Neutrale kans op wanbetaling',
  'cr.ratings.rating.information.C': 'Kans op wanbetaling is zeer hoog',
  'cr.ratings.rating.information.CC': 'Kans op wanbetaling is hoger dan het gemiddelde',
  'cr.ratings.rating.information.CCC': 'Kans op wanbetaling is hoger dan het gemiddelde',
  'cr.ratings.rating.information.D': 'De onderneming is failliet',
  'cr.ratings.rating.information.NA': 'De kans op wanbetaling kan niet bepaald worden.',
  'cr.ratings.rating.information.NR': 'Er kan geen rating vastgesteld worden',
  'cr.ratings.rating.information.text':
    'Deze beoordeling is gebaseerd op specifieke informatie uit de sector, beschikbare jaarrekeningen en betaalgedrag.',
  'cr.ratings.rating.multiscore': '(Multiscore: { multiscore })',
  'cr.ratings.rating.placeholder': 'Kans dat een bedrijf binnen 12 maanden failliet gaat.',
  'cr.ratings.rating.title': 'Multiscore & percentage kans op faillissement',
  'cr.ratings.rating.tooltip':
    'Krijg hier meer inzicht in de kans op wanbetaling en de Graydon Rating',
  'cr.ratings.rating.tooltip.BE':
    'De Graydon Rating toont de mate van kredietrisico aan. De AAA-rating is de hoogste rating en wijst op het laagst mogelijke risico. De C-rating is de zwakste rating en wijst op een zeer hoog rsicio. Bedrijven met een rating D verkeren in een staat van faillissement. NR betekent dat er geen rating vastgelegd kon worden omdat het bedrijf passief is. De kans op faillissement geeft aan hoe groot het risico is dat het bedrijf ernstige financiële problemen ondervindt en failliet gaat. Hoe lager het percentage hoe lager het risico.',
  'cr.ratings.rating.tooltip.GB':
    'De Graydon Rating toont de mate van kredietrisico aan. De AAA-rating is de hoogste rating en wijst op het laagst mogelijke risico. De C-rating is de zwakste rating en wijst op een zeer hoog rsicio. Bedrijven met een rating D verkeren in een staat van faillissement. NR betekent dat er geen rating vastgelegd kon worden, omdat het bedrijf passief is. De kans op faillissement geeft aan hoe groot het risico is dat het bedrijf ernstige financiële problemen ondervindt en failliet gaat. Hoe lager het percentage hoe lager het risico. De indicatie NAN - not a number (geen nummer) - betekent dat er geen Augur Score beschikbaar is.',
  'cr.ratings.rating.tooltip.NL':
    'De Graydon Rating toont de mate van kredietrisico, waarbij AAA-rating de hoogst mogelijke rating is en C de laagste. D geeft aan dat het bedrijf in staat van faillissement verkeert. NR geeft aan dat geen rating vastgelegd kon worden, omdat het bedrijf passief is. De kans op faillissement geeft inzicht in het risico dat het bedrijf ernstige financiële problemen ondervindt. Hoe hoger het percentage hoe groter het risico.',
  'cr.ratings.trends.tile.title': 'Trends',
  'cr.search-gb.fresh.investigation.disclaimer':
    '\nRapporten van bedrijven met en zonder rechtspersoonlijkheid kunnen aangevraagd worden via de button Nieuw Onderzoek bovenaan deze pagina.',
  'cr.search.annual-account': 'Jaarrekening',
  'cr.search.coc-extract': 'Handelsregister uittreksel',
  'cr.search.credit-report': 'Credit Report',
  'cr.search.gdm-check': 'Decision Model check',
  'cr.search.international-credit-report': 'Internationaal kredietrapport',
  'cr.search.more': 'meer',
  'cr.search.results.no.data.link-text': 'vraag dan een Nieuw Onderzoek aan.',
  'cr.search.results.no.data.main-text':
    'Als u op zoek bent naar een niet officieel geregistreerd bedrijf, ',
  'cr.search.view-company-archive': 'Bedrijf bekijken',
  'cr.search.view-raw-data': 'Bekijk data',
  'cr.search.viewReport': 'Bekijk rapport',
  'cr.search.xseptions.report': 'Network incl. XSeptions',
  'cr.specifics.events.category': 'Categorie',
  'cr.specifics.events.date': 'Datum',
  'cr.specifics.events.description': 'Historiekgegevens over het bedrijf.',
  'cr.specifics.events.description.BE':
    'Hier vindt u een overzicht van alle akten die gepubliceerd zijn in de Bijlagen van het Belgisch Staatsblad, inclusief een korte omschrijving van de inhoud.',
  'cr.specifics.events.description.GB':
    'Hier is de lijst en een beschrijving van de gebeurtenissen die zich hebben voorgedaan met betrekking tot dit bedrijf.',
  'cr.specifics.events.description.history.BE':
    'Hier vindt u een overzicht van alle akten die veranderingen van bedragen, bedrijfsnamen, addressen e.d. bevatten en die gepubliceerd zijn in de Bijlagen van het Belgisch Staatsblad, inclusief een korte omschrijving van de inhoud.',
  'cr.specifics.events.disclaimer': 'Maximale weergave van de 50 meest recente historiek',
  'cr.specifics.events.disclaimer.BE':
    'Maximale weergave van de 50 meest recente historiek- en andere gebeurtenissen samen',
  'cr.specifics.events.event-description': 'Omschrijving gebeurtenis - Details',
  'cr.specifics.events.event-description.BE': 'Omschrijving gebeurtenis',
  'cr.specifics.events.event-description.hideDetails': 'Verberg details event',
  'cr.specifics.events.event-description.showAll': 'Lees meer',
  'cr.specifics.events.eventReference': 'BBS aktenummer',
  'cr.specifics.events.no-data.text':
    'Dit bedrijf kende geen gebeurtenissen of de informatie is niet beschikbaar.<br /><br />Heeft u hier vragen over, contacteer dan ons Customer Service team op <a href="mailto:{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}">{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}</a> of op {country, select, gb {+44&nbsp;(0)20&nbsp;8515&nbsp;1400} nl {+31&nbsp;(020)&nbsp;567&nbsp;9501} other {+32&nbsp;(03)&nbsp;280&nbsp;88&nbsp;80}}.',
  'cr.specifics.events.no-data.title': 'Geen gebeurtenissen beschikbaar voor dit bedrijf',
  'cr.specifics.events.source-description': 'Bron',
  'cr.specifics.financial-insolvencies.title': 'Financiële calamiteiten {extra}',
  'cr.specifics.financial-insolvencies.tooltip':
    'Hier krijgt u meer details over de financiële calamiteiten van dit bedrijf.',
  'cr.specifics.financial-insolvencies.tooltip.BE':
    'In dit stuk vindt u een overzicht van de financiële calamiteiten voor dit bedrijf en alle bijbehorende details. ',
  'cr.specifics.financial-insolvencies.tooltip.GB':
    'Hier vindt u de details van eventuele financiële calamiteiten waarbij het bedrijf betrokken is.',
  'cr.specifics.financial-insolvencies.tooltip.NL':
    'Hier krijgt u meer details over eventuele financiële calamiteiten van dit bedrijf.',
  'cr.specifics.tab.events': 'Gebeurtenissen {extra}',
  'cr.specifics.tab.events.BE': 'Historiek {extra}',
  'cr.specifics.tab.xseptions.title': 'XSeptions {extra}',
  'cr.specifics.tab.xseptions.title.BE': 'XSeptions {extra}',
  'cr.specifics.tab.xseptions.title.GB': 'XSeptions {extra}',
  'cr.specifics.tab.xseptions.title.NL': 'XSeptions {extra}',
  'cr.specifics.xseptions.category': 'Categorie',
  'cr.specifics.xseptions.date': 'Datum',
  'cr.specifics.xseptions.description': 'Omschrijving',
  'cr.specifics.xseptions.description.BE':
    'XSeptions screent eerste en tweedegraads relaties (bedrijven en/of bestuurders) op opmerkelijke situaties. Denk hierbij aan vermelding op sanctielijsten, ondoorzichtige bedrijfsstructuren of recente faillissementen van de bestuurder.',
  'cr.specifics.xseptions.description.GB':
    'Hier zijn details van XSeptions voor dit bedrijf. Merk op dat een XSeption alleen wordt geregistreerd wanneer ongebruikelijke gedragspatronen of financiële resultaten zijn geïdentificeerd.',
  'cr.specifics.xseptions.description.NL':
    'XSeptions screent eerste en tweedegraads relaties (bedrijven en/of bestuurders) op opmerkelijke situaties. Denk hierbij aan ondoorzichtige bedrijfsstructuren of recente faillissementen van de bestuurder.',
  'cr.specifics.xseptions.no-data.text':
    'Dit bedrijf heeft geen XSeptions of de informatie is momenteel niet beschikbaar.<br /><br />Heeft u vragen of hulp nodig, contacteer dan ons Customer Service team via <a href="mailto:{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}">{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}</a> of op {country, select, gb {+44&nbsp;(0)20&nbsp;8515&nbsp;1400} nl {+31&nbsp;(020)&nbsp;567&nbsp;9501} other {+32&nbsp;(03)&nbsp;280&nbsp;88&nbsp;80}}.',
  'cr.specifics.xseptions.no-data.text.condensed':
    'Dit bedrijf heeft geen XSeptions of de informatie is momenteel niet beschikbaar.',
  'cr.specifics.xseptions.no-data.title': 'Geen XSeptions beschikbaar voor dit bedrijf',
  'cr.specifics.xseptions.start-date': 'Startdatum',
  'cr.specifics.xseptions.uk-description.GB_1':
    'Betrokkene heeft zijn geregistreerd adres {value1} keer gewijzigd in de periode van {date1} tot {date2}.',
  'cr.specifics.xseptions.uk-description.GB_2':
    'Betrokkene heeft twee sets rekeningen ingediend met {value1} dagen verschil. De rekeningen zijn ingediend op {date1} en vervolgens op {date2}.',
  'cr.specifics.xseptions.uk-description.GB_3':
    'De rekeningen van {date1} van betrokkene lijken identiek te zijn aan die van het bedrijfsregistratienummer {text} voor {date2}.',
  'cr.specifics.xseptions.uk-description.GB_4':
    'Betrokkene heeft zijn eerste set rekeningen op {date2} ingediend na te zijn opgenomen op {date1}',
  'cr.specifics.xseptions.uk-description.GB_5':
    '{value1} bestuurders hebben op dezelfde dag {date1} ontslag genomen.',
  'cr.specifics.xseptions.uk-description.GB_6':
    'Er is informatie van potentieel kritisch belang over betrokkene verzameld.',
  'cr.specifics.xseptions.uk-description.GB_7':
    'Betrokkene heeft zijn laatste rekeningen heel snel na de einddatum van het jaar ingediend. Het laatste financiële jaareinde {date2} is ingediend op {date1}.',
  'cr.specifics.xseptions.uk-description.GB_8':
    'Betrokkene benoemde een bestuurder op {date1}. die werd geassocieerd met {value1} bedrijf/bedrijven die onlangs een insolventie-actie hebben gekend.',
  'cr.specifics.xseptions.uk-description.GB_9': 'Twee jaar geen jaarrekening gedeponeerd',
  'cr.summary.company.details.activitiesDescription': 'Activiteiten ({codeType} code)',
  'cr.summary.company.details.email': 'E-mailadres',
  'cr.summary.company.details.graydonNumber': 'Graydon nummer',
  'cr.summary.company.details.incorporationDate': 'Oprichtingsdatum',
  'cr.summary.company.details.legalForm': 'Rechtsvorm',
  'cr.summary.company.details.numberOfBranches': 'Aantal vestigingen',
  'cr.summary.company.details.phoneNumber': 'Telefoonnummer',
  'cr.summary.company.details.registrationId': 'Registratie ID',
  'cr.summary.company.details.registrationId.BE': 'Ondernemingsnummer',
  'cr.summary.company.details.registrationId.GB': 'Bedrijfsregistratie nummer ',
  'cr.summary.company.details.registrationId.NL': 'KvK-nummer',
  'cr.summary.company.details.title': 'Bedrijfsgegevens',
  'cr.summary.company.details.tooltip.BE':
    'Hier vindt u kort de belangrijkste basisgegevens voor het bedrijf. Open het overzicht voor meer details.',
  'cr.summary.company.details.tooltip.GB':
    'Hier vindt u in het kort een overzicht van de basisgegevens van het bedrijf dat u heeft aangevraagd.',
  'cr.summary.company.details.tooltip.NL':
    'Hier vindt u alle basisinformatie over het door u opgevraagde bedrijf.',
  'cr.summary.company.details.tradingAddress': 'Handelsadres',
  'cr.summary.company.details.vatNumber': 'Btw-nummer',
  'cr.summary.company.details.website': 'Website',
  'cr.summary.company.structure.title': 'Bedrijfsstructuur',
  'cr.summary.company.structure.tooltip.BE':
    'In dit gedeelte vindt u meer informatie over de bedrijfsstructuur, met onder andere de (ultieme) moedermaatschappij. Open het overzicht om de bedrijfsleiders, de aandeelhouders of de participaties te consulteren.',
  'cr.summary.company.structure.tooltip.GB':
    'Hier vindt u meer informatie over de bedrijfsstructuur, met bijvoorbeeld de moedermaatschappij.',
  'cr.summary.company.structure.tooltip.NL':
    'Hier vindt u meer informatie over de bedrijfsstructuur van het door uw opgevraagde bedrijf. U ziet hier bijvoorbeeld het moederbedrijf.',
  'cr.summary.key.figures.title': 'Kerncijfers',
  'cr.summary.key.figures.tooltip': 'Informatie met betrekking tot de kerncijfers …',
  'cr.summary.key.figures.tooltip.BE':
    "Hier krijgt u in het kort een overzicht van de belangrijkste cijfers uit de meest recente jaarrekening en een aantal financiële ratio's. Open het overzicht voor meer kerncijfers. Of om de balans en de resultatenrekening van de laatste 5 boekjaren te consulteren.",
  'cr.summary.key.figures.tooltip.GB':
    "Hier vindt u de kerncijfers van de onderneming, zoals werkkapitaal, eigen vermogen en de financiële ratio's.",
  'cr.summary.key.figures.tooltip.NL':
    'Hier vindt u kerncijfers van het bedrijf, zoals het werkkapitaal en eigen vermogen. Financiële ratio’s, zoals de current ratio.',
  'cr.summary.link.company.profile': 'Overzicht bedrijfsprofiel >',
  'cr.summary.link.company.structure': 'Overzicht bedrijfsstructuur >',
  'cr.summary.link.financials': 'Overzicht financials >',
  'cr.summary.link.payment-behaviour': 'Overzicht betaalgedrag >',
  'cr.summary.link.ratings': 'Overzicht ratings >',
  'cr.summary.link.xseptions': 'Overzicht uitzonderlijke kenmerken >',
  'cr.summary.link.xseptions.date-unknown': 'Datum onbekend',
  'cr.summary.news': 'Nieuws',
  'cr.summary.news.no-data.text':
    'Er is momenteel geen nieuws beschikbaar voor dit bedrijf.<br /><br />Heeft u vragen of hulp nodig, contacteer dan ons Customer Service team via <a href="mailto:{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}">{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}</a> of op {country, select, gb {+44 (0)20 8515 1400} nl {+31 (020) 567 9501} other {+32 (03) 280 88 80}}.',
  'cr.summary.news.no-data.title': 'Geen nieuws beschikbaar voor dit bedrijf',
  'cr.summary.news.tooltip':
    'Hier vindt u de 3 meest recente artikels over het bedrijf, inclusief sentiment (positief > 0 < negatief) en categorie. Klik op het artikel om het te openen in een nieuw tabblad in uw browser. Deze informatie is afkomstig van Business Radar.',
  'cr.summary.payment-score-ccj-summons.information.ccjs':
    'Totaal aantal vonnissen van het County Court voor dit bedrijf.',
  'cr.summary.payment-score-ccj-summons.information.dbts':
    'Gemiddelde betalingen die de afgelopen maand na de vervaldag zijn uitgevoerd',
  'cr.summary.payment-score-ccj-summons.information.summons':
    'Totaal aantal dagvaardingen en vonnissen voor dit bedrijf.',
  'cr.summary.payment-score-ccj-summons.title.BE': 'Betaalscore, dagvaardingen & vonnissen',
  'cr.summary.payment-score-ccj-summons.title.GB':
    'Betaalgedrag, dagen na vervaldag & vonnissen van het County Court',
  'cr.summary.payment-score-ccj-summons.title.NL': 'Betaalscore',
  'cr.summary.payment-score-ccj-summons.tooltip.BE':
    'De betaalscore vertelt u hoe groot de kans is dat uw klant de afgesproken betaaltermijn zal respecteren. Hoe hoger de betaalscore (op een schaal van 0 tot 10), hoe beter het betaalgedrag. Het aantal dagvaardingen geeft aan hoe vaak een bedrijf gedagvaard werd door de Rijksdienst voor Sociale Zekerheid, het Sociaal Verzekeringfonds voor Zelfstandigen of het Fonds voor Bestaanszekerheid. Open het overzicht voor meer details.',
  'cr.summary.payment-score-ccj-summons.tooltip.GB':
    'De betaalscore geeft aan in welke mate een organisatie haar facturen tijdig betaalt. Hier geldt de volgende regel: hoe hoger de betaalscore, hoe correcter het bedrijf haar facturen betaalt.',
  'cr.summary.payment-score-ccj-summons.tooltip.NL':
    'De betaalscore geeft aan tot op welke hoogte een organisatie haar facturen op tijd betaalt. Hier geldt: hoe hoger de betaalscore, hoe beter het bedrijf haar facturen op tijd betaalt.',
  'cr.summary.xseptions.title': 'Uitzonderlijke kenmerken',
  'cr.summary.xseptions.tooltip.BE':
    'Onder Xseptions vindt u een aantal opvallende gebeurtenissen die zich afspeelden (bv. een brand, sociale onlusten, financiële problemen, ...) of zullen plaatsvinden (bv. aankonding investeringen, fusievoorstel, aankondiging verplaatisng maatschappelijke zetel, ...) binnen het bedrijf. Open het overzicht voor meer details.',
  'cr.summary.xseptions.tooltip.GB':
    'XSeptions screent relaties in eerste en tweede graad (bedrijven en/of bestuurders) op kritische gebeurtenissen. Dit omvat de vermelding op sanctielijsten, ondoorzichtige bedrijfsstructuren, recente faillissementen of ontslag van bestuurders.',
  'cr.summary.xseptions.tooltip.NL':
    'XSeptions screent eerste en tweedegraads relaties (bedrijven en/of bestuurders) op opmerkelijke situaties. Denk hierbij aan vermelding op sanctielijsten, ondoorzichtige bedrijfsstructuren of recente faillissementen van de bestuurder.',
  'cr.test': 'test',
  'cr.toast.message.edit-preferences': 'Wijzig voorkeuren',
  'cr.unsecuredClaims.amount': 'Bedrag',
  'cr.unsecuredClaims.companyName': 'Bedrijfsnaam',
  'cr.unsecuredClaims.disclaimer':
    'Maximale weergave van de {maximum} grootste vorderingen op een totaal van {totalCount}',
  'cr.unsecuredClaims.error.no-data.text':
    'Dit bedrijf heeft geen ongedekte schulden of de informatie is momenteel niet beschikbaar.<br /><br />Heeft u vragen of hulp nodig, contacteer dan ons Customer Service team via <a href="mailto:{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}">{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}</a> of op {country, select, gb {+44&nbsp;(0)20&nbsp;8515&nbsp;1400} nl {+31&nbsp;(020)&nbsp;567&nbsp;9501} other {+32&nbsp;(03)&nbsp;280&nbsp;88&nbsp;80}}.',
  'cr.unsecuredClaims.error.no-data.title': 'Geen ongedekte schulden',
  'cr.unsecuredClaims.statementDate': 'Datum van aangifte',
  'cr.unsecuredClaims.summary': 'Totale waarde van ongedekte schulden',
  'cr.unsecuredClaims.title': 'Ongedekte schulden',
  'cr.unsecuredClaims.tooltip':
    'Deze lijst omvat de ongedekte vorderingen zoals bekend op de datum van aangifte.',
  'cr.unsecuredClaims.unknown': 'Onbekend',
  'cr.value.not.available': 'N.A.',
  'credit.check.accountsDate': 'Afsluitdatum boekjaar',
  'credit.check.accountsFileDate': 'Deponeringsdatum',
  'credit.check.accountsPeriod': 'Boekjaar',
  'credit.check.accumulatedDepreciation': 'Gecumuleerde afschrijving',
  'credit.check.advancedPayments': 'Vooruitbetaalde bedragen',
  'credit.check.advancedPaymentsOnStock': 'Vooruitbetalingen op voorraad',
  'credit.check.assets': 'Activa',
  'credit.check.cashBankSecurities': 'Liquide middelen',
  'credit.check.commonRating': 'Algemene Rating',
  'credit.check.commonRiskClass': 'Algemene Rating',
  'credit.check.company.previous.title': 'Eerdere check(s) voor dit bedrijf',
  'credit.check.companyIncorporated': 'Bedrijf Incorporated',
  'credit.check.companyIncorporated.no': 'Nee',
  'credit.check.companyIncorporated.yes': 'Ja',
  'credit.check.creditorDays': 'Crediteur',
  'credit.check.creditRecommendation': 'Kredietlimiet',
  'credit.check.currentAssets': 'Vlottende activa',
  'credit.check.currentLiabilities': 'Kortlopende schulden',
  'credit.check.debtorDays': 'Betalingstermijn',
  'credit.check.details.showMore': '{num} meer…',
  'credit.check.download.pdf': 'Download PDF',
  'credit.check.dueToBanks': 'Schulden aan banken',
  'credit.check.equityCapital': 'Eigen vermogen',
  'credit.check.erc': 'Algemene Rating',
  'credit.check.finishedGoods': 'Afgewerkte producten',
  'credit.check.fixedAssets': 'Vaste activa',
  'credit.check.graydonUkAugurScore': 'Augur score',
  'credit.check.graydonUkCreditRating': 'Kredietrating',
  'credit.check.graydonUkOddsOfFinancialStress': 'Kans financiële stress',
  'credit.check.inventories': 'Voorraad',
  'credit.check.liabilities': 'Passiva',
  'credit.check.longTermDeferredTaxes': 'Lange termijn uitgestelde belastingen',
  'credit.check.longTermLiabilities': 'Langlopende schulden',
  'credit.check.longTermLiabilitiesToAssociatedCompanies':
    'Lange termijn verplichtingen aan geassocieerde vennootschappen',
  'credit.check.longTermLoans': 'Lange termijn leningen',
  'credit.check.multiscore': 'Multiscore',
  'credit.check.note.notValid':
    'Dit veld moet een minimum van 1 karakter hebben en een maximum van 140 karakters',
  'credit.check.note.placeholder': 'Maak notitie (optioneel)',
  'credit.check.numberOfEmployees': 'Aantal werknemers',
  'credit.check.other': 'Overige',
  'credit.check.otherCurrentAssets': 'Overige vlottende activa',
  'credit.check.otherLongTermLiabilities': 'Overige langlopende schulden',
  'credit.check.otherReserves': 'Overige reserves',
  'credit.check.otherShortTermLiabilities': 'Overige kortlopende schulden',
  'credit.check.paymentscore': 'Payment score',
  'credit.check.paymentsRating': 'Betaalgedrag',
  'credit.check.pdPercentage': 'Percentage kans op faillissement',
  'credit.check.pdRating': 'Graydon Rating',
  'credit.check.profitAndLossAccount': 'Winst- en verliesrekening',
  'credit.check.provisions': 'Voorzieningen',
  'credit.check.rawMaterialsAndSupplies': 'Grond- en hulpstoffen',
  'credit.check.reference.label': 'Reference number',
  'credit.check.reference.placeholder': 'Voeg referentie toe (optioneel)',
  'credit.check.refId.notValid': 'Hier zijn maar 30 karakters toegestaan',
  'credit.check.reserveForPensions': 'Gereserveerd voor pensioenen',
  'credit.check.reserves': 'Reserves',
  'credit.check.revaluationReserves': 'Herwaardering reserves',
  'credit.check.save.and.close': 'Klaar',
  'credit.check.saved': 'Uitkomst opgeslagen',
  'credit.check.shareholdersFunds': 'Aandeelhouders',
  'credit.check.shortTermLiabilities': 'Kortlopende schulden',
  'credit.check.startupReservesExpenses': 'Opstart reserveringsuitgaven',
  'credit.check.surplusEquity': 'Overschot eigen vermogen',
  'credit.check.totalAssets': 'Totaal activa',
  'credit.check.totalCurrentAssets': 'Totaal vlottende activa',
  'credit.check.totalEquity': 'Totaal eigen vermogen',
  'credit.check.totalFinancialAssets': 'Totaal financiële activa',
  'credit.check.totalFixedAssets': 'Totaal vaste activa',
  'credit.check.totalIntangibleAssets': 'Totaal immateriële activa',
  'credit.check.totalLiabilities': 'Totaal passiva',
  'credit.check.totalLongTermLiabilities': 'Totaal langlopende schulden',
  'credit.check.totalReceivables': 'Totaal vorderingen',
  'credit.check.totalShortTermLiabilities': 'Totaal kortlopende schulden',
  'credit.check.totalTangibleAssets': 'Totaal materiële activa',
  'credit.check.tradeCreditors': 'Handelscrediteuren',
  'credit.check.unknown.date': 'Onbekend',
  'credit.check.workingCapital': 'Werkkapitaal',
  'credit.check.workInProgress': 'Werk in uitvoering',
  'creditcheck.no.active.models': 'Geen actieve modellen',
  'cta.choose.default': 'Selecteer ons geadviseerd model',
  'cta.choose.this': 'Personaliseer uw beslissingsmodel',
  'cta.logout': 'Uitloggen',
  'cta.next.step': 'Volgende stap',
  'cta.save': 'Opslaan',
  'cta.save.and.close': 'Opslaan en sluiten',
  'cta.save.and.test': 'Opslaan en testen',
  'cta.search': 'Zoeken',
  'cta.select': 'Selecteer',
  'dashboard.clear.filters': 'Wis filters',
  'dashboard.company-list.tile.common-rating': 'Algemene rating',
  'dashboard.company-list.tile.report-requested': 'Rapport aangevraagd',
  'dashboard.filter.common-rating.title': 'Algemene Rating',
  'dashboard.filter.common-rating.tooltip':
    'Dit is een Europese norm om het kredietrisico van een bedrijf te bepalen en wordt uitgedrukt als een waarde op een schaal van 1 t/m 5. Hoe hoger de waarde, hoe lager het risico. Waarde ‘9’ betekent financiële moeilijkheden. Bij een waarde van ‘8’ is het niet mogelijk om een risico te bepalen.',
  'dashboard.filter.common-rating.value-1': 'Zeer hoog',
  'dashboard.filter.common-rating.value-2': 'Hoog',
  'dashboard.filter.common-rating.value-3': 'Gemiddeld',
  'dashboard.filter.common-rating.value-4': 'Laag',
  'dashboard.filter.common-rating.value-5': 'Zeer laag',
  'dashboard.filter.common-rating.value-8': 'Betalingsproblemen',
  'dashboard.filter.common-rating.value-9': 'Niet beschikbaar',
  'dashboard.filter.group.companysizes.title': 'Bedrijfsgrootte',
  'dashboard.filter.group.countries_per_package.deluxe.title': "International Deluxe' -pakket",
  'dashboard.filter.group.countries_per_package.essential.title':
    "International Essential' -pakket",
  'dashboard.filter.group.countries_per_package.premium.title': "International Premium' -pakket",
  'dashboard.filter.group.countries_per_package.title': 'Landen per pakket',
  'dashboard.filter.group.countries.title': 'Landen',
  'dashboard.filter.group.decisionmodels.title': 'Beslismodellen',
  'dashboard.filter.group.industry.title': 'Branches',
  'dashboard.filter.group.sectors.title': 'Sectoren',
  'dashboard.filter.time-range.last-month': 'Huidige maand',
  'dashboard.filter.time-range.last-six-months': '6 laatste maanden',
  'dashboard.filter.time-range.last-three-months': '3 laatste maanden',
  'dashboard.filter.time-range.last-twelve-months': '12 laatste maanden',
  'dashboard.filter.time-range.title': 'Assortiment datums',
  'dashboard.heatmap.companies-list.header':
    'Alle controles gedurende {timerange} voor {country}. Weergave {numOfItems} van {totalNumOfItems} {totalNumOfItems, plural, one{item} other{items}}.',
  'dashboard.heatmap.legend.addon-package': 'Aanvullend pakket',
  'dashboard.heatmap.legend.least-reports': 'Minste rapporten',
  'dashboard.heatmap.legend.most-reports': 'Meeste rapporten',
  'dashboard.heatmap.legend.unavailable': 'Niet beschikbaar',
  'dashboard.multiselect.companysizes.0': '0 werknemers',
  'dashboard.multiselect.companysizes.1': '1 - 4 werknemers',
  'dashboard.multiselect.companysizes.2': '5 - 9 werknemers',
  'dashboard.multiselect.companysizes.3': '10 - 19 werknemers',
  'dashboard.multiselect.companysizes.4': '20 - 49 werknemers',
  'dashboard.multiselect.companysizes.5': '50 - 99 werknemers',
  'dashboard.multiselect.companysizes.6': '100 - 199 werknemers',
  'dashboard.multiselect.companysizes.7': '200 - 499 werknemers',
  'dashboard.multiselect.companysizes.8': '500 - 999 werknemers',
  'dashboard.multiselect.companysizes.9': '>1000 werknemers',
  'dashboard.multiselect.companysizes.all': 'Alle bedrijfsgroottes',
  'dashboard.multiselect.companysizes.n-sizes': '{n} bedrijfsgroottes geselecteerd',
  'dashboard.multiselect.companysizes.X': 'Onbekend',
  'dashboard.tile.list.footer': 'Alle items zien?',
  'dashboard.tile.list.header':
    '{outcome, select, all{Alle} accept{Geaccepteerd} refer{Doorverwezen} deny{Afgewezen}} orders voor {month}.',
  'dashboard.tile.list.header.close': 'Sluiten',
  'dashboard.tile.list.numOfItems':
    '{numOfItems} van {totalNumOfItems} {totalNumOfItems, plural, one{item} other{items}} weergegeven.',
  'dashboard.title': 'Management Dashboard',
  'dashboard.widget.label.accept': 'Accepteren',
  'dashboard.widget.label.be': 'België',
  'dashboard.widget.label.deluxe': 'Deluxe',
  'dashboard.widget.label.deny': 'Doorverwijzen',
  'dashboard.widget.label.essential': 'Essential',
  'dashboard.widget.label.gb': 'Verenigd Koninkrijk',
  'dashboard.widget.label.nl': 'Nederland',
  'dashboard.widget.label.premium': 'Premium',
  'dashboard.widget.label.refer': 'Afwijzen',
  'dashboard.widget.title.acceptance-grade': 'Acceptatiegraad',
  'dashboard.widget.title.average-order-value': 'Gemiddelde orderwaarde',
  'dashboard.widget.title.consumption': 'International Business - Opgevraagde rapporten per land',
  'dashboard.widget.title.consumption-heatmap':
    'International Business - Opgevraagde rapporten per land',
  'dashboard.widget.title.credit-checks': 'Credit checks',
  'dashboard.widget.title.total-orders': 'Totaal aantal checks',
  'dashboard.with.filters': 'Gefilterde selectie',
  Datablocks: 'De wijzigingen werden opgeslagen',
  'dbm.alreadyScheduled': 'reeds geprogrammeerd',
  'dbm.backHome': 'Terug naar de homepage',
  'dbm.bigDownload.button': 'Laten we iets anders doen',
  'dbm.bigDownload.emailTo':
    'Als uw verrijkte bestand klaar is om te downloaden, sturen wij een email naar:',
  'dbm.bigDownload.heading': 'Bijna tijd om uw targets te bereiken ...',
  'dbm.bigDownload.smallText':
    'Heeft u binnen 2 uur nog geen e-mail ontvangen? Neem contact op met de klantenservice',
  'dbm.chooseAList': 'Kies een lijst',
  'dbm.create-profile.companies': 'Bedrijven',
  'dbm.create-profile.company-options.description':
    'Let op: U kunt alleen kiezen voor Lijst, Selectie of Alle van een land bij de aanmaak van een nieuw profiel. Daarna kunt u dit niet meer wijzigen.',
  'dbm.create-profile.company-options.description-no-merketdata':
    'Let op: U kunt alleen kiezen voor Lijst of Alle van een land bij de aanmaak van een nieuw profiel. Daarna kunt u dit niet meer wijzigen.',
  'dbm.create-profile.company-options.event': 'Alles van het land',
  'dbm.create-profile.company-options.event-description':
    'Vind ondernemingen op basis van gebeurtenissen, bv. faillissementen',
  'dbm.create-profile.company-options.from': 'Bedrijven uit',
  'dbm.create-profile.company-options.list': 'Lijst',
  'dbm.create-profile.company-options.list-description': 'Voeg zelf bedrijven toe',
  'dbm.create-profile.company-options.selection': 'Market Data-selectie',
  'dbm.create-profile.company-options.selection-description':
    'Vind bedrijven op basis van criteria',
  'dbm.create-profile.company-options.tooltip':
    'U kunt data ontvangen uit een zelf samengestelde lijst van bedrijven (Lijst optie), uit bedrijven die voldoen aan een reeks criteria (Selectie optie) of van alle bedrijven van een land waar een specifieke gebeurtenis optreedt zoals een faillissement (Land optie).',
  'dbm.create-profile.company-options.tooltip-no-marketdata':
    'U kunt data ontvangen uit een zelf samengestelde lijst van bedrijven (Lijst optie) of van alle bedrijven van een land waar een specifieke gebeurtenis optreedt zoals een faillissement (Land optie).',
  'dbm.create-profile.country': 'Land',
  'dbm.create-profile.country.tooltip':
    'Let op: u kunt de land-keuze niet meer wijzigen nadat u het profiel heeft opgeslagen. Wilt u data uit verschillende landen ontvangen? Maak dan een profiel per land aan.',
  'dbm.create-profile.data-block-group.code_descriptions': 'Code omschrijvingen',
  'dbm.create-profile.data-block-group.company_profile': 'Bedrijfsprofiel',
  'dbm.create-profile.data-block-group.events': 'Uitzonderlijke kenmerken',
  'dbm.create-profile.data-block-group.finance': 'Financiële gegevens',
  'dbm.create-profile.data-block-group.network': 'Bedrijfsstructuur',
  'dbm.create-profile.data-block-group.ratings': 'Ratings',
  'dbm.create-profile.data-block-group.untitled': 'Niet benoemd',
  'dbm.create-profile.data-blocks': 'Kies datablokken',
  'dbm.create-profile.data-blocks-version': 'Versie',
  'dbm.create-profile.data-blocks.DBM_ACTIVITY': 'Activiteiten',
  'dbm.create-profile.data-blocks.DBM_ACTIVITY.tooltip':
    'Dit datablok bevat alle activiteit informatie van een bedrijf. Voor meer detail bekijk de documentatie.',
  'dbm.create-profile.data-blocks.DBM_ADDRESS': 'Adressen',
  'dbm.create-profile.data-blocks.DBM_ADDRESS.tooltip':
    'Dit datablok bevat alle adres informatie van een bedrijf. Voor meer detail bekijk de documentatie.',
  'dbm.create-profile.data-blocks.DBM_ANNUAL_ACCOUNT': 'Jaarrekeningen',
  'dbm.create-profile.data-blocks.DBM_ANNUAL_ACCOUNT.tooltip': '-',
  'dbm.create-profile.data-blocks.DBM_ANNUAL_REPORT': 'Jaarverslag',
  'dbm.create-profile.data-blocks.DBM_ANNUAL_REPORT.tooltip': '-',
  'dbm.create-profile.data-blocks.DBM_BAG': 'BAG',
  'dbm.create-profile.data-blocks.DBM_BAG.tooltip':
    'BAG (Basisregistratie Adressen en Gebouwen) bevat gemeentelijke basisgegevens van alle adressen en gebouwen in een gemeente.',
  'dbm.create-profile.data-blocks.DBM_BANKER': 'Bankier',
  'dbm.create-profile.data-blocks.DBM_BANKER.tooltip': 'Bankier',
  'dbm.create-profile.data-blocks.DBM_BASIC': 'Basis',
  'dbm.create-profile.data-blocks.DBM_BASIC.tooltip':
    'Dit datablok bevat basis informatie van een bedrijf. Voor meer detail bekijk de documentatie.',
  'dbm.create-profile.data-blocks.DBM_BRANCH': 'Vestigingen',
  'dbm.create-profile.data-blocks.DBM_BRANCH.tooltip':
    'Dit datablok bevat de vestigingsinformatie van een bedrijf. Voor meer detail bekijk de documentatie.',
  'dbm.create-profile.data-blocks.DBM_CCJ_SUMMARY': 'CCJ Samenvatting',
  'dbm.create-profile.data-blocks.DBM_CCJ_SUMMARY.tooltip': '-',
  'dbm.create-profile.data-blocks.DBM_CODE_DESCRIPTIONS': 'Code omschrijvingen',
  'dbm.create-profile.data-blocks.DBM_CODE_DESCRIPTIONS.tooltip':
    'Speciaal datablok met alle codes, waarden en omschrijvingen (NL, EN, FR) voor de tabellen in de verschillende datablokken. (bv Country code: NL -> Nederland, SIZE_CLASS: 4 -> 5 tot 9)',
  'dbm.create-profile.data-blocks.DBM_CONTACT_ITEM': 'Contactinformatie',
  'dbm.create-profile.data-blocks.DBM_CONTACT_ITEM.tooltip':
    'Dit datablok bevat contact informatie van een bedrijf. Voor meer detail bekijk de documentatie.',
  'dbm.create-profile.data-blocks.DBM_CONTINUATION': 'Continuation',
  'dbm.create-profile.data-blocks.DBM_CONTINUATION.tooltip': '-',
  'dbm.create-profile.data-blocks.DBM_CREDIT_RATING': 'Credit rating',
  'dbm.create-profile.data-blocks.DBM_CREDIT_RATING.tooltip':
    'Dit datablok bevat score en rating informatie van een bedrijf. Voor meer detail bekijk de documentatie.',
  'dbm.create-profile.data-blocks.DBM_CREDIT_SCORES': 'Kredietscores',
  'dbm.create-profile.data-blocks.DBM_CREDIT_SCORES.tooltip':
    'Dit datablok bevat score en rating informatie van een bedrijf. Voor meer detail bekijk de documentatie.',
  'dbm.create-profile.data-blocks.DBM_EMPLOYEE': 'Werknemers',
  'dbm.create-profile.data-blocks.DBM_EMPLOYEE.tooltip':
    'Dit datablok bevat werknemer informatie van een bedrijf. Voor meer detail bekijk de documentatie.',
  'dbm.create-profile.data-blocks.DBM_EVENTS': 'Historiek en publicaties {extra}',
  'dbm.create-profile.data-blocks.DBM_EVENTS.tooltip': '-',
  'dbm.create-profile.data-blocks.DBM_FINANCE_SUMMARY': 'Financiële samenvatting',
  'dbm.create-profile.data-blocks.DBM_FINANCE_SUMMARY.tooltip':
    'Dit datablok bevat een financiële samenvatting van een bedrijf. Voor meer detail bekijk de documentatie.',
  'dbm.create-profile.data-blocks.DBM_INSOLVENCY': 'Insolventie',
  'dbm.create-profile.data-blocks.DBM_INSOLVENCY_POSITION': 'Personen gerelateerd aan insolventie',
  'dbm.documentation.block-description.DBM_INSOLVENCY_POSITION-NL': '-',
  'dbm.documentation.block-description.DBM_INSOLVENCY_POSITION-BE': '-',
  'dbm.documentation.block-description.DBM_INSOLVENCY_POSITION-GB': '-',
  'dbm.create-profile.data-blocks.DBM_INSOLVENCY.tooltip': '-',
  'dbm.create-profile.data-blocks.DBM_KEY_FIGURE': 'Kerncijfers',
  'dbm.create-profile.data-blocks.DBM_KEY_FIGURE.tooltip': 'Kerncijfers',
  'dbm.create-profile.data-blocks.DBM_LIABILITY_STATEMENT': 'Aansprakelijkheidsverklaring',
  'dbm.create-profile.data-blocks.DBM_LIABILITY_STATEMENT.tooltip': '-',
  'dbm.create-profile.data-blocks.DBM_OPPORTUNITY': 'Kansen / opportuniteiten',
  'dbm.create-profile.data-blocks.DBM_OPPORTUNITY.tooltip': 'Kansen / opportuniteiten',
  'dbm.create-profile.data-blocks.DBM_ORGANIZATION_NAME': 'Organisatienamen',
  'dbm.create-profile.data-blocks.DBM_ORGANIZATION_NAME.tooltip':
    'Dit datablok bevat alle organsatie namen van een bedrijf. Voor meer detail bekijk de documentatie.',
  'dbm.create-profile.data-blocks.DBM_PARTICIPATION': 'Participaties',
  'dbm.create-profile.data-blocks.DBM_PARTICIPATION.tooltip':
    'Dit datablok bevat alle informatie over aandelen van een bedrijf. Voor meer detail bekijk de documentatie.',
  'dbm.create-profile.data-blocks.DBM_PAYMENT_ANALYSIS': 'Betalingsanalyse',
  'dbm.create-profile.data-blocks.DBM_PAYMENT_ANALYSIS.tooltip': '-',
  'dbm.create-profile.data-blocks.DBM_POSITION': 'Bedrijfsleiders',
  'dbm.create-profile.data-blocks.DBM_POSITION_CIS': 'Functies CIS',
  'dbm.create-profile.data-blocks.DBM_POSITION_CIS.tooltip':
    'Informatie over functies, afkomstig van Stichting Centraal Informatie Systeem',
  'dbm.create-profile.data-blocks.DBM_POSITION.tooltip':
    'Dit datablok bevat alle directeur en management informatie van een bedrijf. Voor meer detail bekijk de documentatie.',
  'dbm.create-profile.data-blocks.DBM_SHAREHOLDER': 'Aandeelhouders',
  'dbm.create-profile.data-blocks.DBM_SHAREHOLDER.tooltip':
    'Dit datablok bevat alle aandelhouder informatie van een bedrijf. Voor meer detail bekijk de documentatie.',
  'dbm.create-profile.data-blocks.DBM_SOCIAL_BALANCE': 'Sociale balans',
  'dbm.create-profile.data-blocks.DBM_SOCIAL_BALANCE.tooltip': '-',
  'dbm.create-profile.data-blocks.DBM_STRUCTURE': 'Structuur',
  'dbm.create-profile.data-blocks.DBM_STRUCTURE.tooltip':
    'Dit datablok bevat alle gerelateerde bedrijfsinformatie van een bedrijf. Voor meer detail bekijk de documentatie.',
  'dbm.create-profile.data-blocks.DBM_SUMMONS': 'Dagvaardingen',
  'dbm.create-profile.data-blocks.DBM_SUMMONS.tooltip': '-',
  'dbm.create-profile.data-blocks.DBM_UBO': 'UBO',
  'dbm.create-profile.data-blocks.DBM_UBO_CIS': 'UBO CIS',
  'dbm.create-profile.data-blocks.DBM_UBO_CIS.tooltip':
    'Informatie over Uiteindelijk belanghebbenden (Ultimate Beneficial Owners) in de groepsstructuur van een bedrijf, afkomstig van Stichting Centraal Informatie Systeem',
  'dbm.create-profile.data-blocks.DBM_UBO.tooltip':
    'Uiteindelijk belanghebbenden (Ultimate Beneficial Owners) in de groepsstructuur van een bedrijf',
  'dbm.create-profile.data-blocks.DBM_XSEPTION': 'XSeption',
  'dbm.create-profile.data-blocks.DBM_XSEPTION.tooltip': '-',
  'dbm.create-profile.delimiter': 'Scheidingsteken in CSV bestand',
  'dbm.create-profile.description': 'Omschrijving',
  'dbm.create-profile.duplicate.message': 'U heeft deze naam al voor een ander profiel gebruikt',
  'dbm.create-profile.duplicate.title': 'Benaming gekopieerd',
  'dbm.create-profile.error.message': 'Er was een fout bij het aanmaken van het profiel',
  'dbm.create-profile.error.title': 'Fout bij het aanmaken van het profiel',
  'dbm.create-profile.layout': 'Bestandslayout',
  'dbm.create-profile.layout.columnNames': 'Voeg kolomnamen in',
  'dbm.create-profile.layout.headerAndFooter': 'Voeg header en footer in',
  'dbm.create-profile.layout.tooltip':
    'De header van het bestand bevat metadata zoals profielnaam, datum en tijd en nummer van aanvrager. De footer bevat het aantal organisaties in het bestand. Kolomnamen verklaren welke data in welke bestandskolom staat. Zie de documentatie voor een voorbeeld.',
  'dbm.create-profile.list.countryNote':
    'Let op: slechts {organizationCountOfCountry} {organizationCountOfCountry, plural, =1 {company} other {companies}} van {organizationCount} bedrijven in de lijst zijn gevestigd in {countryCode} en worden gebruikt in dit profiel.',
  'dbm.create-profile.list.countryNote.empty':
    'Geen enkele van de {organizationCount} bedrijven in de lijst zijn uit {countryCode}, dus geen enkele zal gebruikt worden in dit profiel.',
  'dbm.create-profile.name': 'Profielnaam',
  'dbm.create-profile.no-data-block':
    'Er zijn geen datablokken ingeschakeld voor dit land. [Neem contact op met Graydon](/contact).',
  'dbm.create-profile.receiving-updates': 'Levering van bestand',
  'dbm.create-profile.save-profile': 'Profiel bewaren',
  'dbm.create-profile.select-company': 'Algemeen',
  'dbm.create-profile.start-date': 'Vanaf',
  'dbm.create-profile.success.message': 'Het profiel is met success aangemaakt.',
  'dbm.create-profile.success.title': 'Profiel met succes aangemaakt',
  'dbm.create-profile.title': 'Maak profiel aan',
  'dbm.create-profile.type': 'Volledig of alleen wijzigingen',
  'dbm.create-profile.type.delta': 'Ontvang alleen gewijzigde bedrijven',
  'dbm.create-profile.type.full': 'Ontvang telkens volledige dataset',
  'dbm.create-profile.type.full_then_delta': 'Volledige dataset eerst, daarna alleen wijzigingen',
  'dbm.dataBlock.fields.activity': 'Activiteit',
  'dbm.dataBlock.fields.companyName': 'Bedrijfsnaam & Handelsnaam',
  'dbm.dataBlock.fields.contactDetails': 'Contactgegevens',
  'dbm.dataBlock.fields.DMU': 'Contactpersoon (DMU)',
  'dbm.dataBlock.fields.employeeCount': 'Aantal medewerkers',
  'dbm.dataBlock.fields.establishmentType': 'Vestigingstype',
  'dbm.dataBlock.fields.graydonId': 'Graydon identificatienummers',
  'dbm.dataBlock.fields.incorporationDate': 'Oprichtingsdatum',
  'dbm.dataBlock.fields.legalForm': 'Rechtsvorm',
  'dbm.dataBlock.fields.sector': 'Sector',
  'dbm.dataBlock.marketinginformation': 'Marketing Informatie',
  'dbm.documentation.block-description.DBM_ACTIVITY-BE': 'Activiteiten',
  'dbm.documentation.block-description.DBM_ACTIVITY-GB': '-',
  'dbm.documentation.block-description.DBM_ACTIVITY-NL': '-',
  'dbm.documentation.block-description.DBM_ADDRESS-BE': 'Adressen',
  'dbm.documentation.block-description.DBM_ADDRESS-GB': '-',
  'dbm.documentation.block-description.DBM_ADDRESS-NL': '-',
  'dbm.documentation.block-description.DBM_ANNUAL_ACCOUNT-BE': 'Jaarrekeningen',
  'dbm.documentation.block-description.DBM_ANNUAL_ACCOUNT-GB': '-',
  'dbm.documentation.block-description.DBM_ANNUAL_ACCOUNT-NL': '-',
  'dbm.documentation.block-description.DBM_ANNUAL_REPORT-BE': 'Jaarrekeningen publicaties index',
  'dbm.documentation.block-description.DBM_ANNUAL_REPORT-GB': '-',
  'dbm.documentation.block-description.DBM_ANNUAL_REPORT-NL': '-',
  'dbm.documentation.block-description.DBM_BAG-NL':
    'BAG (Basisregistratie Adressen en Gebouwen) bevat gemeentelijke basisgegevens van alle adressen en gebouwen in een gemeente.',
  'dbm.documentation.block-description.DBM_BANKER-BE': 'Bankiers',
  'dbm.documentation.block-description.DBM_BASIC-BE': 'Basisinformatie',
  'dbm.documentation.block-description.DBM_BASIC-GB': '-',
  'dbm.documentation.block-description.DBM_BASIC-NL': '-',
  'dbm.documentation.block-description.DBM_BRANCH-BE': 'Vestigingen',
  'dbm.documentation.block-description.DBM_BRANCH-NL': '-',
  'dbm.documentation.block-description.DBM_CCJ_SUMMARY-BE': '-',
  'dbm.documentation.block-description.DBM_CCJ_SUMMARY-GB': '-',
  'dbm.documentation.block-description.DBM_CCJ_SUMMARY-NL': '-',
  'dbm.documentation.block-description.DBM_CODE_DESCRIPTIONS-BE':
    'Speciaal datablok met alle codes, waarden en omschrijvingen (NL, EN, FR) voor de tabellen in de verschillende datablokken',
  'dbm.documentation.block-description.DBM_CODE_DESCRIPTIONS-GB':
    'Speciaal datablok met alle codes, waarden en omschrijvingen (NL, EN, FR) voor de tabellen in de verschillende datablokken',
  'dbm.documentation.block-description.DBM_CODE_DESCRIPTIONS-NL':
    'Speciaal datablok met alle codes, waarden en omschrijvingen (NL, EN, FR) voor de tabellen in de verschillende datablokken',
  'dbm.documentation.block-description.DBM_CONTACT_ITEM-BE': 'Contactinformatie',
  'dbm.documentation.block-description.DBM_CONTACT_ITEM-GB': '-',
  'dbm.documentation.block-description.DBM_CONTACT_ITEM-NL': '-',
  'dbm.documentation.block-description.DBM_CONTINUATION-BE': '-',
  'dbm.documentation.block-description.DBM_CONTINUATION-GB': '-',
  'dbm.documentation.block-description.DBM_CONTINUATION-NL': '-',
  'dbm.documentation.block-description.DBM_CREDIT_SCORES-BE': 'Kredietscores',
  'dbm.documentation.block-description.DBM_CREDIT_SCORES-GB': '-',
  'dbm.documentation.block-description.DBM_CREDIT_SCORES-NL': '-',
  'dbm.documentation.block-description.DBM_EMPLOYEE-GB': '-',
  'dbm.documentation.block-description.DBM_EMPLOYEE-NL': '-',
  'dbm.documentation.block-description.DBM_EVENTS-BE': 'Gebeurtenissen en publicaties',
  'dbm.documentation.block-description.DBM_EVENTS-GB': 'Gebeurtenissen',
  'dbm.documentation.block-description.DBM_EVENTS-NL': '-',
  'dbm.documentation.block-description.DBM_FINANCE_SUMMARY-BE': 'Financiële samenvatting',
  'dbm.documentation.block-description.DBM_FINANCE_SUMMARY-GB': '-',
  'dbm.documentation.block-description.DBM_FINANCE_SUMMARY-NL': '-',
  'dbm.documentation.block-description.DBM_INSOLVENCY-BE':
    'Insolventies - faillissementen en juridische reorganisaties',
  'dbm.documentation.block-description.DBM_INSOLVENCY-GB':
    'Insolventies - faillissementen en juridische reorganisaties',
  'dbm.documentation.block-description.DBM_INSOLVENCY-NL':
    'Insolventies - faillissementen en juridische reorganisaties',
  'dbm.documentation.block-description.DBM_KEY_FIGURE-BE': 'Kerncijfers',
  'dbm.documentation.block-description.DBM_KEY_FIGURE-GB': 'Kerncijfers',
  'dbm.documentation.block-description.DBM_KEY_FIGURE-NL': 'Kerncijfers',
  'dbm.documentation.block-description.DBM_LIABILITY_STATEMENT-NL': '-',
  'dbm.documentation.block-description.DBM_OPPORTUNITY-BE': 'Kansscores / opportuniteitscores',
  'dbm.documentation.block-description.DBM_OPPORTUNITY-GB': 'Kansscores / opportuniteitscores',
  'dbm.documentation.block-description.DBM_OPPORTUNITY-NL': 'Kansscores / opportuniteitscores',
  'dbm.documentation.block-description.DBM_ORGANIZATION_NAME-BE': 'Benamingen',
  'dbm.documentation.block-description.DBM_ORGANIZATION_NAME-GB': '-',
  'dbm.documentation.block-description.DBM_ORGANIZATION_NAME-NL': '-',
  'dbm.documentation.block-description.DBM_PARTICIPATION-BE': 'Participaties',
  'dbm.documentation.block-description.DBM_PARTICIPATION-GB': 'Participaties',
  'dbm.documentation.block-description.DBM_PARTICIPATION-NL': 'Participaties',
  'dbm.documentation.block-description.DBM_PAYMENT_ANALYSIS-BE': 'Betalingsanalyse',
  'dbm.documentation.block-description.DBM_PAYMENT_ANALYSIS-NL': 'Betalingsanalyse',
  'dbm.documentation.block-description.DBM_POSITION_CIS-BE': '-',
  'dbm.documentation.block-description.DBM_POSITION_CIS-GB': '-',
  'dbm.documentation.block-description.DBM_POSITION_CIS-NL': '-',
  'dbm.documentation.block-description.DBM_POSITION-BE': 'Bedrijfsleiders',
  'dbm.documentation.block-description.DBM_POSITION-NL': '-',
  'dbm.documentation.block-description.DBM_POSITION-GB': '-',
  'dbm.documentation.block-description.DBM_SHAREHOLDER-BE': 'Aandeelhouders',
  'dbm.documentation.block-description.DBM_SHAREHOLDER-GB': 'Aandeelhouders',
  'dbm.documentation.block-description.DBM_SHAREHOLDER-NL': 'Aandeelhouders',
  'dbm.documentation.block-description.DBM_SOCIAL_BALANCE-BE': 'Sociale balans',
  'dbm.documentation.block-description.DBM_SOCIAL_BALANCE-GB': '-',
  'dbm.documentation.block-description.DBM_SOCIAL_BALANCE-NL': '-',
  'dbm.documentation.block-description.DBM_STRUCTURE-NL': '-',
  'dbm.documentation.block-description.DBM_STRUCTURE-GB': '-',
  'dbm.documentation.block-description.DBM_SUMMONS-BE': 'Dagvaardingen',
  'dbm.documentation.block-description.DBM_SUMMONS-GB': '-',
  'dbm.documentation.block-description.DBM_SUMMONS-NL': '-',
  'dbm.documentation.block-description.DBM_UBO_CIS-BE': '-',
  'dbm.documentation.block-description.DBM_UBO_CIS-GB': '-',
  'dbm.documentation.block-description.DBM_UBO_CIS-NL': '-',
  'dbm.documentation.block-description.DBM_UBO-BE': '-',
  'dbm.documentation.block-description.DBM_UBO-NL': '-',
  'dbm.documentation.block-description.DBM_XSEPTION-BE': 'XSeptions',
  'dbm.documentation.block-description.DBM_XSEPTION-GB': 'XSeptions',
  'dbm.documentation.block-description.DBM_XSEPTION-NL': 'XSeptions',
  'dbm.documentation.collapse-all': 'Alles inklappen',
  'dbm.documentation.delete-profile': 'Verwijder profiel',
  'dbm.documentation.delete-profile.error':
    'Het profiel kan niet worden verwijderd omdat de data op dit moment wordt verzameld. Probeer het later nogmaals.',
  'dbm.documentation.delete-profile.success': 'Profiel is verwijderd',
  'dbm.documentation.description':
    'Hieronder wordt de metadata van beschikbare datablokken beschreven. Als u delen van de documentatie wilt downloaden of printen, open de datablokken van uw voorkeur.\n Algemene documentatie over het gebruik van de API vindt u op [graydon.io](https://graydon.io) en in het [meegeleverde technische document](https://connect.graydon-global.com/rs/934-AQC-614/images/Database-Management-Technical-documentation.pdf). ',
  'dbm.documentation.description.ABBREVIATIONS_LEGAL_FORM': 'Afkorting rechtsvorm',
  'dbm.documentation.description.ACC_PERIOD_END_DATE': 'Datum einde boekjaar van de jaarrekening ',
  'dbm.documentation.description.ACC_PERIOD_START_DATE': 'Startdatum jaarrekening',
  'dbm.documentation.description.ACCOUNTANT_NAME': 'Organisatienaam van de accountant  ',
  'dbm.documentation.description.ACCOUNTING_PERIOD': 'Jaarrekening periode start-einddatum',
  'dbm.documentation.description.ACTIVITY_CLASSIFICATION_CODE':
    'Een classificatie van specifieke activiteitscode',
  'dbm.documentation.description.ACTIVITY_CODE':
    'Een standaardcode om de activiteiten van de onderneming te identificeren (primair)',
  'dbm.documentation.description.ACTIVITY_CODE_1':
    'Een standaardcode om de activiteiten van de onderneming te identificeren (secundair)',
  'dbm.documentation.description.ACTIVITY_CODE_2':
    'Een standaardcode om de activiteiten van de onderneming te identificeren (secundair)',
  'dbm.documentation.description.ACTIVITY_CODE_3':
    'Een standaardcode om de activiteiten van de onderneming te identificeren (secundair)',
  'dbm.documentation.description.ACTIVITY_CODE_4':
    'Een standaardcode om de activiteiten van de onderneming te identificeren (secundair)',
  'dbm.documentation.description.ACTIVITY_CODE_5':
    'Een standaardcode om de activiteiten van de onderneming te identificeren (secundair)',
  'dbm.documentation.description.ACTIVITY_DESCRIPTION':
    'Omschrijving van de activiteiten van de onderneming.',
  'dbm.documentation.description.ACTIVITY_GROUP_CODE':
    'Broncode voor de activiteit: RSZ, BTW, KBO of Graydon',
  'dbm.documentation.description.ACTIVITY_SEQUENCE_NUMBER': 'Volgnummer activiteiten',
  'dbm.documentation.description.ACTIVITY_START_DATE': 'Datum',
  'dbm.documentation.description.ACTIVITY_TYPE_CODE':
    'Code bepaalt het type van de activiteit : primair, secundair (nevenactiviteit)',
  'dbm.documentation.description.ADDRESS_END_DATE':
    'Datum geeft aan tot wanneer het adres in gebruik was door het bedrijf',
  'dbm.documentation.description.ADDRESS_LINE_1': 'adreslijn 1',
  'dbm.documentation.description.ADDRESS_LINE_1_RECEIVER_1':
    'Adreslijn 1, adres van de curator of bewindvoerder',
  'dbm.documentation.description.ADDRESS_LINE_1_RECEIVER_10': '-',
  'dbm.documentation.description.ADDRESS_LINE_1_RECEIVER_2':
    'Adreslijn 1, adres van de curator of bewindvoerder',
  'dbm.documentation.description.ADDRESS_LINE_1_RECEIVER_3':
    'Adreslijn 1, adres van de curator of bewindvoerder',
  'dbm.documentation.description.ADDRESS_LINE_1_RECEIVER_4':
    'Adreslijn 1, adres van de curator of bewindvoerder',
  'dbm.documentation.description.ADDRESS_LINE_1_RECEIVER_5':
    'Adreslijn 1, adres van de curator of bewindvoerder',
  'dbm.documentation.description.ADDRESS_LINE_1_RECEIVER_6': '-',
  'dbm.documentation.description.ADDRESS_LINE_1_RECEIVER_7': '-',
  'dbm.documentation.description.ADDRESS_LINE_1_RECEIVER_8': '-',
  'dbm.documentation.description.ADDRESS_LINE_1_RECEIVER_9': '-',
  'dbm.documentation.description.ADDRESS_LINE_2': 'adreslijn 2',
  'dbm.documentation.description.ADDRESS_LINE_2_RECEIVER_1':
    'Adreslijn 2, postcode van de curator of bewindvoerder',
  'dbm.documentation.description.ADDRESS_LINE_2_RECEIVER_10': '-',
  'dbm.documentation.description.ADDRESS_LINE_2_RECEIVER_2':
    'Adreslijn 2, postcode van de curator of bewindvoerder',
  'dbm.documentation.description.ADDRESS_LINE_2_RECEIVER_3':
    'Adreslijn 2, postcode van de curator of bewindvoerder',
  'dbm.documentation.description.ADDRESS_LINE_2_RECEIVER_4':
    'Adreslijn 2, postcode van de curator of bewindvoerder',
  'dbm.documentation.description.ADDRESS_LINE_2_RECEIVER_5':
    'Adreslijn 2, postcode van de curator of bewindvoerder',
  'dbm.documentation.description.ADDRESS_LINE_2_RECEIVER_6': '-',
  'dbm.documentation.description.ADDRESS_LINE_2_RECEIVER_7': '-',
  'dbm.documentation.description.ADDRESS_LINE_2_RECEIVER_8': '-',
  'dbm.documentation.description.ADDRESS_LINE_2_RECEIVER_9': '-',
  'dbm.documentation.description.ADDRESS_LINE_3': 'adreslijn 3',
  'dbm.documentation.description.ADDRESS_LINE_3_RECEIVER_1':
    'Adreslijn 3, woonplaats van de curator of bewindvoerder ',
  'dbm.documentation.description.ADDRESS_LINE_3_RECEIVER_10': '-',
  'dbm.documentation.description.ADDRESS_LINE_3_RECEIVER_2':
    'Adreslijn 3, woonplaats van de curator of bewindvoerder ',
  'dbm.documentation.description.ADDRESS_LINE_3_RECEIVER_3':
    'Adreslijn 3, woonplaats van de curator of bewindvoerder ',
  'dbm.documentation.description.ADDRESS_LINE_3_RECEIVER_4': '-',
  'dbm.documentation.description.ADDRESS_LINE_3_RECEIVER_5': '-',
  'dbm.documentation.description.ADDRESS_LINE_3_RECEIVER_6': '-',
  'dbm.documentation.description.ADDRESS_LINE_3_RECEIVER_7': '-',
  'dbm.documentation.description.ADDRESS_LINE_3_RECEIVER_8': '-',
  'dbm.documentation.description.ADDRESS_LINE_3_RECEIVER_9': '-',
  'dbm.documentation.description.ADDRESS_LINE_4': 'adreslijn 4',
  'dbm.documentation.description.ADDRESS_LINE_4_RECEIVER_1': '-',
  'dbm.documentation.description.ADDRESS_LINE_4_RECEIVER_10': '-',
  'dbm.documentation.description.ADDRESS_LINE_4_RECEIVER_2': '-',
  'dbm.documentation.description.ADDRESS_LINE_4_RECEIVER_3': '-',
  'dbm.documentation.description.ADDRESS_LINE_4_RECEIVER_4': '-',
  'dbm.documentation.description.ADDRESS_LINE_4_RECEIVER_5': '-',
  'dbm.documentation.description.ADDRESS_LINE_4_RECEIVER_6': '-',
  'dbm.documentation.description.ADDRESS_LINE_4_RECEIVER_7': '-',
  'dbm.documentation.description.ADDRESS_LINE_4_RECEIVER_8': '-',
  'dbm.documentation.description.ADDRESS_LINE_4_RECEIVER_9': '-',
  'dbm.documentation.description.ADDRESS_LINE_5': 'adreslijn 5',
  'dbm.documentation.description.ADDRESS_LINE_5_RECEIVER_1': '-',
  'dbm.documentation.description.ADDRESS_LINE_5_RECEIVER_10': '-',
  'dbm.documentation.description.ADDRESS_LINE_5_RECEIVER_2': '-',
  'dbm.documentation.description.ADDRESS_LINE_5_RECEIVER_3': '-',
  'dbm.documentation.description.ADDRESS_LINE_5_RECEIVER_4': '-',
  'dbm.documentation.description.ADDRESS_LINE_5_RECEIVER_5': '-',
  'dbm.documentation.description.ADDRESS_LINE_5_RECEIVER_6': '-',
  'dbm.documentation.description.ADDRESS_LINE_5_RECEIVER_7': '-',
  'dbm.documentation.description.ADDRESS_LINE_5_RECEIVER_8': '-',
  'dbm.documentation.description.ADDRESS_LINE_5_RECEIVER_9': '-',
  'dbm.documentation.description.ADDRESS_LINES': 'Indicator dat de adreslijnen zijn gebruikt',
  'dbm.documentation.description.ADDRESS_ROLE_CODE':
    'Code type adres (Bezoek adres / handels adres, vorig adres etc) ',
  'dbm.documentation.description.ADDRESS_START_DATE':
    'Datum geeft aan sinds wanneer het adres in gebruik is door het bedrijf',
  'dbm.documentation.description.ADDRESS_TYPE_CODE': 'Postbus of fysiek adres',
  'dbm.documentation.description.AMOUNT_CATEGORY_CODE': 'Bedrag categorie code',
  'dbm.documentation.description.AMOUNT_CCJ_LAST_12_MONTHS': '-',
  'dbm.documentation.description.AMOUNT_CCJ_LAST_24_MONTHS': '-',
  'dbm.documentation.description.AMOUNT_CCJ_LAST_36_MONTHS': '-',
  'dbm.documentation.description.AMOUNT_CCJ_LAST_48_MONTHS': '-',
  'dbm.documentation.description.AMOUNT_CCJ_LAST_60_MONTHS': '-',
  'dbm.documentation.description.AMOUNT_CCJ_LAST_72_MONTHS': '-',
  'dbm.documentation.description.AMOUNT_UNIT_CODE': 'Schaalcode van de bedragen',
  'dbm.documentation.description.ANNUAL_ACCOUNT_ITEM_CODE':
    'Rubriekscode van de jaarrekening post ',
  'dbm.documentation.description.ANNUAL_ACCOUNT_ITEM_VALUE': 'Waarde van de jaarrekening post',
  'dbm.documentation.description.ANNUAL_ACCOUNT_TYPE_CODE':
    'Type code onderdeel van de Jaarrekening ',
  'dbm.documentation.description.ANNUAL_REPORT_FILING_DATE':
    'De datum waarop de jaarrekening is gedeponeerd bij de Kamer van Koophandel ',
  'dbm.documentation.description.ANNUAL_REPORT_TYPE_CODE': 'Type code jaarrekening model ',
  'dbm.documentation.description.AREA': 'Gebouw in vierkante meters',
  'dbm.documentation.description.ASSOCIATION_FUNDS': 'Fondsen van de vereniging / stichting',
  'dbm.documentation.description.AUTHORISED_CAPITAL': "Maatschappelijk kapitaal (in Euro's)",
  'dbm.documentation.description.AVAILABLE_CONTRIBUTION': 'Beschikbare inbreng',
  'dbm.documentation.description.BAG_STATUS_CODE': 'Object status code',
  'dbm.documentation.description.BAG_TYPE_CODE': 'BAG type code',
  'dbm.documentation.description.BALANCE_SHEET_TOTAL': 'Balanstotaal van het bedrijf',
  'dbm.documentation.description.BANK_CODE': 'bank code, identificeert de bank',
  'dbm.documentation.description.BANK_NAME': 'Naam van de bank',
  'dbm.documentation.description.BE_BUSINESS_UNIT_NUMBER':
    'Elke vestigingseenheid krijgt een uniek identificatienummer toegewezen dat verschilt van het ondernemingsnummer. Het nummer van de vestigingseenheid bestaat uit 10 cijfers. Het eerste cijfer is een cijfer van 2 tot 8. Het nummer is overdraagbaar van de ene entiteit naar de andere.',
  'dbm.documentation.description.BE_ENTERPRISE_NUMBER': 'Ondernemingsnummer',
  'dbm.documentation.description.BE_LEGAL_FORM_CODE': 'Code rechtsvorm toegekend door Graydon',
  'dbm.documentation.description.BE_MULTISCORE':
    'De multiscore bundelt alle relevante informatie waarover Graydon beschikt in één score om een onderneming op middellange termijn te beoordelen naar financiële gezondheid, betalingsmoraal en overlevingskansen',
  'dbm.documentation.description.BOARD_TYPE_CODE': 'Bestuurstype van de onderneming',
  'dbm.documentation.description.BUILDING': '-',
  'dbm.documentation.description.CALAMITY': '-',
  'dbm.documentation.description.CALAMITY_START_DATE': '-',
  'dbm.documentation.description.CASH': 'Cash van de onderneming',
  'dbm.documentation.description.CCJ_FLAG': '-',
  'dbm.documentation.description.CITY': 'Woonplaats',
  'dbm.documentation.description.CODE_VALUE': 'Waarde code',
  'dbm.documentation.description.CONSOLIDATED_FLAG':
    'Flag of de jaarrekening geconsolideerd is (Y/N)',
  'dbm.documentation.description.CONSTRUCTION_YEAR': 'Bouwjaar',
  'dbm.documentation.description.CONTACT_ITEM_ROLE_CODE': 'Contact onderwerp functie code',
  'dbm.documentation.description.CONTACT_ITEM_TYPE_CODE':
    'Type code contact informatie (e-mail, url, telefoon, ...)',
  'dbm.documentation.description.CONTACT_ITEM_VALUE':
    'contactwaarde van de verschillende types, voorbeeld www.rikaint.com of info@graydon.nl of 0205679999',
  'dbm.documentation.description.CONTINUATION_START_DATE': 'Startdatum voortzetting',
  'dbm.documentation.description.CONTINUATION_TYPE_CODE': 'Type code voortzetting',
  'dbm.documentation.description.CONTRIBUTION': 'Inbreng',
  'dbm.documentation.description.COUNTRY_CODE': 'ISO alpha-2 landcode',
  'dbm.documentation.description.COURT': 'Rechtbank',
  'dbm.documentation.description.CREDIT_ADVICE':
    'Het maximum door Graydon geadviseerd kredietbedrag voor alle leveranciers van de onderneming in euro.',
  'dbm.documentation.description.CREDIT_FLAG':
    'Credit Flag, de risico-indicatie van zakendoen met de onderneming (rood – oranje – groen)',
  'dbm.documentation.description.CURRENCY_CODE':
    'Code van de valuta waarin de bedragen in de jaarrekening worden weergegeven \r\nISO 4217',
  'dbm.documentation.description.CURRENT_RATIO': 'Current ratio',
  'dbm.documentation.description.DATE_LAST_CCJ': '-',
  'dbm.documentation.description.DIRECT_PERCENTAGE': 'Rechtstreeks percentage',
  'dbm.documentation.description.DISCONTINUATION_PROBABILITY':
    'Dit is het voorspelde percentage kans op opheffing van het bedrijf. Bereik: [0.0, 100.0]. Hoe hoger het percentage, hoe groter de kans op opheffing.',
  'dbm.documentation.description.DISCONTINUATION_SCORE':
    'De kans op opheffing van de onderneming weergegeven in een score van 1 tot en met 9. ',
  'dbm.documentation.description.DISSOLUTION_DATE': 'De datum van de ontbinding van een filiaal',
  'dbm.documentation.description.DO_NOT_MAIL_FLAG':
    'Indicator die aangeeft dat een onderneming geen direct mail wenst te ontvangen (Y/N)',
  'dbm.documentation.description.DOMAIN_CODE': 'Domeincode',
  'dbm.documentation.description.EMAIL': 'E-mail',
  'dbm.documentation.description.EMPLOYEE_COUNT_ANNUAL_ACCOUNT':
    'Aantal werknemers volgens jaarrekening',
  'dbm.documentation.description.EMPLOYEE_COUNT_FULL_TIME':
    'Het totaal aantal fulltime medewerkers in de onderneming',
  'dbm.documentation.description.EMPLOYEE_COUNT_TOTAL':
    'Het totaal aantal medewerkers in de onderneming',
  'dbm.documentation.description.EN_DESCRIPTION': 'Engelse omschrijving',
  'dbm.documentation.description.END_DATE': 'Einddatum',
  'dbm.documentation.description.END_REASON_CODE':
    'Type code van de soort opheffing van het insolventietype ',
  'dbm.documentation.description.ENTERPRISE_ID':
    'Unieke identificatie in de Graydon database om een onderneming te identificeren',
  'dbm.documentation.description.ERC':
    'Standaard score voor Europese leveranciers (European Risk Classification ERC)',
  'dbm.documentation.description.EVENT_REFERENCE': 'Referentie',
  'dbm.documentation.description.EVENTS_TYPE_CODE': 'Gebeurtenis type code',
  'dbm.documentation.description.EXCUSABILITY_FLAG': 'Verschoonbaarheid',
  'dbm.documentation.description.EXPORT_FLAG': 'Aanduiding voor exportactiviteit',
  'dbm.documentation.description.FIGURE_VALUE': 'Kerncijfer waarde',
  'dbm.documentation.description.FILED_FOR_ENTERPRISE_ID':
    'Graydon Enterprise ID van de organisatie waarvoor de aansprakelijkheidsverklaring is afgegeven. ',
  'dbm.documentation.description.FILED_FOR_NAME':
    'Naam van de organisatie waarvoor de aansprakelijkheidsverklaring is afgeven ',
  'dbm.documentation.description.FILED_FOR_NL_KVK_NUMBER':
    '8 cijferig Kamer van Koophandel nummer van de organisatie waarvoor de aansprakelijkheidsverklaring is afgegeven.',
  'dbm.documentation.description.FILING_DATE': 'De datum van deponering',
  'dbm.documentation.description.FIXED_CAPITAL': 'Vast kapitaal',
  'dbm.documentation.description.FOUND_AT_CALAMITY': '-',
  'dbm.documentation.description.FOUND_AT_CALAMITY_START_DATE': '-',
  'dbm.documentation.description.FOUND_AT_ENTERPRISE_ID': '-',
  'dbm.documentation.description.FOUND_AT_ORG_LEVEL': '-',
  'dbm.documentation.description.FOUNDATION_DATE': 'Oprichtingsdatum van het bedrijf',
  'dbm.documentation.description.FOUNDER_FLAG':
    'Bij waarde Y zijn de aandeelhouder en het kapitaal van bij de oprichting',
  'dbm.documentation.description.FR_DESCRIPTION': 'Franse omschrijving',
  'dbm.documentation.description.FREE_TEXT': 'Vrije tekst',
  'dbm.documentation.description.GAZETTE_NUMBER': 'BBS aktenummer',
  'dbm.documentation.description.GB_AUGUR_SCORE': 'Augur score',
  'dbm.documentation.description.GB_CREDIT_RATING': '-',
  'dbm.documentation.description.GB_CRO_REGISTRATION_NUMBER': '-',
  'dbm.documentation.description.GB_LEGAL_FORM_CODE': '-',
  'dbm.documentation.description.GB_MAXIMUM_CREDIT_GUIDE': '-',
  'dbm.documentation.description.GB_MONTHLY_CREDIT_GUIDE': '-',
  'dbm.documentation.description.GDB_ACCOUNTING_PERIOD': '-',
  'dbm.documentation.description.GENDER_CODE': 'Geslachtscode van de persoon',
  'dbm.documentation.description.GENDER_CODE_RECEIVER_1': 'Tye code geslacht ',
  'dbm.documentation.description.GENDER_CODE_RECEIVER_2': 'Tye code geslacht ',
  'dbm.documentation.description.GENDER_CODE_RECEIVER_3': 'Tye code geslacht ',
  'dbm.documentation.description.GOV_GAZETTE_DATE': 'BBS-datum',
  'dbm.documentation.description.GOV_GAZETTE_NUMBER': 'BBS aktenummer',
  'dbm.documentation.description.GOVERNMENT_GAZETTE_CODE': 'BBS-code',
  'dbm.documentation.description.GRAYDON_RATING':
    'De kans op wanbetaling van een onderneming weergegeven in een  score (AAA – D)',
  'dbm.documentation.description.GROSS_MARGIN': 'Brutomarge',
  'dbm.documentation.description.GROSS_SALES_MARGIN': 'Brutoverkoopmarge',
  'dbm.documentation.description.GROUP_EMPLOYEE_COUNT_FULL_TIME':
    'Totaal aantal full-time medewerkers binnen het concern',
  'dbm.documentation.description.GROUP_EMPLOYEE_COUNT_TOTAL':
    'Totaal aantal werknemers op groepsniveau',
  'dbm.documentation.description.GROWTH_SCORE':
    'Dit is de voorspelde groeiscore van de onderneming.\nEr zijn 7 categorieën:\n \n 1: Sterke krimp\n 2: Krimp\n 3: Neutraal\n 4: Groei\n 5: Sterke groei\n 99: Volatiel \n NR: Not Rated - niet gescoord',
  'dbm.documentation.description.GROWTH_SEGMENT':
    'Het segment geeft het niveau van beschikbare gegevens aan om de groeiscore te modelleren.\n Ondernemingen worden gegroepeerd op basis van de beschikbare gegevens als volgt:\n \n A: het hoogste niveau van beschikbare gegevens.\n B: het tweede hoogste niveau van beschikbare gegevens.\n C: het derde hoogste niveau van beschikbare gegevens.\n D: het laagste niveau van beschikbare gegevens.',
  'dbm.documentation.description.HELD_BY_ORG_ENTERPRISE_ID': 'verouderde vertaalsleutel',
  'dbm.documentation.description.HIGHEST_AMOUNT_CCJ_LAST_12_MONTHS': '-',
  'dbm.documentation.description.HIGHEST_AMOUNT_CCJ_LAST_24_MONTHS': '-',
  'dbm.documentation.description.HIGHEST_AMOUNT_CCJ_LAST_36_MONTHS': '-',
  'dbm.documentation.description.HIGHEST_AMOUNT_CCJ_LAST_48_MONTHS': '-',
  'dbm.documentation.description.HIGHEST_AMOUNT_CCJ_LAST_60_MONTHS': '-',
  'dbm.documentation.description.HIGHEST_AMOUNT_CCJ_LAST_72_MONTHS': '-',
  'dbm.documentation.description.HOUSE_NUMBER': 'Huisnummer dat bij een adres behoort',
  'dbm.documentation.description.HOUSE_NUMBER_SUFFIX':
    'Huisnummer toevoeging dat bij een adres behoort',
  'dbm.documentation.description.IBAN': 'BE70645108445025',
  'dbm.documentation.description.IMPORT_FLAG': 'Import indicator (Y/N)',
  'dbm.documentation.description.INFO_CODE': 'Info code',
  'dbm.documentation.description.INSOLVENCY_DETAIL_CODE': '-',
  'dbm.documentation.description.INSOLVENCY_FORM_NAME': 'Formulier naam',
  'dbm.documentation.description.INSOLVENCY_FREE_TEXT': 'Vrije tekst',
  'dbm.documentation.description.INSOLVENCY_JUDGMENT_TYPE_CODE': 'Soort uitspraak',
  'dbm.documentation.description.INSOLVENCY_TYPE_CODE':
    'Type code dat het soort insolventie aangeeft. ',
  'dbm.documentation.description.INTEREST_SIZE_DESCRIPTION': '-',
  'dbm.documentation.description.INTEREST_TYPE_DESCRIPTION': '-',
  'dbm.documentation.description.IS_ACTIVE_STATUS_CODE':
    'Is de onderneming actief of inactief (Y/N)\n',
  'dbm.documentation.description.IS_DISSOLVED_STATUS_CODE': 'Opheffingsstatus (Y/N)',
  'dbm.documentation.description.IS_ECONOMICALLY_ACTIVE_STATUS_CODE':
    'Economisch actief volgens KVK (Y/N)',
  'dbm.documentation.description.IS_INSOLVENT_STATUS_CODE': 'Insolventiestatus (Y/N)',
  'dbm.documentation.description.IS_SOHO':
    'Het bedrijf kan gekenmerkt worden als een small office / home office ',
  'dbm.documentation.description.IS_ZZP':
    'Het bedrijf kan gekenmerkt worden als zelfstandige zonder personeel (ZZP)',
  'dbm.documentation.description.ISIC_ACTIVITY_CODE': 'ISIC 2-cijferige activiteit van SBI',
  'dbm.documentation.description.ISSUE_DATE': 'Publicatiedatum',
  'dbm.documentation.description.ISSUED_CAPITAL': "Geplaatst kapitaal (in Euro's)",
  'dbm.documentation.description.JUDGMENT_DATE': 'Uitspraak datum',
  'dbm.documentation.description.KEY_FIGURE_YEAR': 'Het boekjaar van de jaarrekening ',
  'dbm.documentation.description.LAST_NL_KVK_SOURCE_UPDATE_DATE': '-',
  'dbm.documentation.description.LATITUDE': 'GPS breedtegraad in decimale graden',
  'dbm.documentation.description.LEGAL_FORM_START_DATE': 'Startdatum van een rechtsvorm',
  'dbm.documentation.description.LEI_NUMBER':
    'De Legal Entity Identifier (LEI) is een alfanumerieke code van 20 tekens die is gebaseerd op de ISO 17442-norm, ontwikkeld door de International Organization of Standardization (ISO). Deze maakt verbinding met belangrijke referentie-informatie die een unieke identificatie mogelijk maakt van juridische entiteiten die deelnemen aan financiële transacties.',
  'dbm.documentation.description.SEARCH_STATUS_DESCRIPTION': '-',
  'dbm.documentation.description.POSITION_MANDATE_AUTHORITY_DESCRIPTION': '-',
  'dbm.documentation.description.SEARCH_ROLE_DESCRIPTION': '-',
  'dbm.documentation.description.POSITION_TYPE_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_2_SEARCH_STATUS_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_2_POSITION_MANDATE_AUTHORITY_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_2_SEARCH_ROLE_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_2_POSITION_TYPE_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_2_POSITION_START_DATE': '-',
  'dbm.documentation.description.LEVEL_2_POSITION_END_DATE': '-',
  'dbm.documentation.description.LEVEL_3_SEARCH_STATUS_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_3_POSITION_MANDATE_AUTHORITY_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_3_SEARCH_ROLE_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_3_POSITION_TYPE_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_3_POSITION_START_DATE': '-',
  'dbm.documentation.description.LEVEL_3_POSITION_END_DATE': '-',
  'dbm.documentation.description.LEVEL_4_SEARCH_STATUS_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_4_POSITION_MANDATE_AUTHORITY_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_4_SEARCH_ROLE_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_4_POSITION_TYPE_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_4_POSITION_START_DATE': '-',
  'dbm.documentation.description.LEVEL_4_POSITION_END_DATE': '-',
  'dbm.documentation.description.LEVEL_5_SEARCH_STATUS_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_5_POSITION_MANDATE_AUTHORITY_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_5_SEARCH_ROLE_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_5_POSITION_TYPE_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_5_POSITION_START_DATE': '-',
  'dbm.documentation.description.LEVEL_5_POSITION_END_DATE': '-',
  'dbm.documentation.description.LEVEL_6_SEARCH_STATUS_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_6_POSITION_MANDATE_AUTHORITY_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_6_SEARCH_ROLE_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_6_POSITION_TYPE_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_6_POSITION_START_DATE': '-',
  'dbm.documentation.description.LEVEL_6_POSITION_END_DATE': '-',
  'dbm.documentation.description.LEVEL_7_SEARCH_STATUS_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_7_POSITION_MANDATE_AUTHORITY_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_7_SEARCH_ROLE_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_7_POSITION_TYPE_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_7_POSITION_START_DATE': '-',
  'dbm.documentation.description.LEVEL_7_POSITION_END_DATE': '-',
  'dbm.documentation.description.LEVEL_8_SEARCH_STATUS_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_8_POSITION_MANDATE_AUTHORITY_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_8_SEARCH_ROLE_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_8_POSITION_TYPE_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_8_POSITION_START_DATE': '-',
  'dbm.documentation.description.LEVEL_8_POSITION_END_DATE': '-',
  'dbm.documentation.description.LEVEL_9_SEARCH_STATUS_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_9_POSITION_MANDATE_AUTHORITY_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_9_SEARCH_ROLE_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_9_POSITION_TYPE_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_9_POSITION_START_DATE': '-',
  'dbm.documentation.description.LEVEL_9_POSITION_END_DATE': '-',
  'dbm.documentation.description.LEVEL_10_SEARCH_STATUS_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_10_POSITION_MANDATE_AUTHORITY_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_10_SEARCH_ROLE_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_10_POSITION_TYPE_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_10_POSITION_START_DATE': '-',
  'dbm.documentation.description.LEVEL_10_POSITION_END_DATE': '-',
  'dbm.documentation.description.LEVEL_11_SEARCH_STATUS_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_11_POSITION_MANDATE_AUTHORITY_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_11_SEARCH_ROLE_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_11_POSITION_TYPE_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_11_POSITION_START_DATE': '-',
  'dbm.documentation.description.LEVEL_11_POSITION_END_DATE': '-',
  'dbm.documentation.description.LEVEL_12_SEARCH_STATUS_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_12_POSITION_MANDATE_AUTHORITY_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_12_SEARCH_ROLE_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_12_POSITION_TYPE_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_12_POSITION_START_DATE': '-',
  'dbm.documentation.description.LEVEL_12_POSITION_END_DATE': '-',
  'dbm.documentation.description.LEVEL_1_ORGANIZATION_NAME': '-',
  'dbm.documentation.description.LEVEL_1_COUNTRY_CODE': '-',
  'dbm.documentation.description.LEVEL_1_COUNTRY': '-',
  'dbm.documentation.description.LEVEL_2_ORGANIZATION_NAME': '-',
  'dbm.documentation.description.LEVEL_2_COUNTRY_CODE': '-',
  'dbm.documentation.description.LEVEL_2_COUNTRY': '-',
  'dbm.documentation.description.LEVEL_3_ORGANIZATION_NAME': '-',
  'dbm.documentation.description.LEVEL_3_COUNTRY_CODE': '-',
  'dbm.documentation.description.LEVEL_3_COUNTRY': '-',
  'dbm.documentation.description.LEVEL_4_ORGANIZATION_NAME': '-',
  'dbm.documentation.description.LEVEL_4_COUNTRY_CODE': '-',
  'dbm.documentation.description.LEVEL_4_COUNTRY': '-',
  'dbm.documentation.description.LEVEL_5_ORGANIZATION_NAME': '-',
  'dbm.documentation.description.LEVEL_5_COUNTRY_CODE': '-',
  'dbm.documentation.description.LEVEL_5_COUNTRY': '-',
  'dbm.documentation.description.LEVEL_6_ORGANIZATION_NAME': '-',
  'dbm.documentation.description.LEVEL_6_COUNTRY_CODE': '-',
  'dbm.documentation.description.LEVEL_6_COUNTRY': '-',
  'dbm.documentation.description.LEVEL_7_ORGANIZATION_NAME': '-',
  'dbm.documentation.description.LEVEL_7_COUNTRY_CODE': '-',
  'dbm.documentation.description.LEVEL_7_COUNTRY': '-',
  'dbm.documentation.description.LEVEL_8_ORGANIZATION_NAME': '-',
  'dbm.documentation.description.LEVEL_8_COUNTRY_CODE': '-',
  'dbm.documentation.description.LEVEL_8_COUNTRY': '-',
  'dbm.documentation.description.LEVEL_9_ORGANIZATION_NAME': '-',
  'dbm.documentation.description.LEVEL_9_COUNTRY_CODE': '-',
  'dbm.documentation.description.LEVEL_9_COUNTRY': '-',
  'dbm.documentation.description.LEVEL_10_ORGANIZATION_NAME': '-',
  'dbm.documentation.description.LEVEL_10_COUNTRY_CODE': '-',
  'dbm.documentation.description.LEVEL_10_COUNTRY': '-',
  'dbm.documentation.description.LEVEL_11_ORGANIZATION_NAME': '-',
  'dbm.documentation.description.LEVEL_11_COUNTRY_CODE': '-',
  'dbm.documentation.description.LEVEL_11_COUNTRY': '-',
  'dbm.documentation.description.LEVEL_12_ORGANIZATION_NAME': '-',
  'dbm.documentation.description.LEVEL_12_COUNTRY_CODE': '-',
  'dbm.documentation.description.LEVEL_12_COUNTRY': '-',
  'dbm.documentation.description.LEVEL_1_CALAMITY': '-',
  'dbm.documentation.description.LEVEL_1_CALAMITY_START_DATE': '-',
  'dbm.documentation.description.LEVEL_2_CALAMITY': '-',
  'dbm.documentation.description.LEVEL_2_CALAMITY_START_DATE': '-',
  'dbm.documentation.description.LEVEL_3_CALAMITY': '-',
  'dbm.documentation.description.LEVEL_3_CALAMITY_START_DATE': '-',
  'dbm.documentation.description.LEVEL_4_CALAMITY': '-',
  'dbm.documentation.description.LEVEL_4_CALAMITY_START_DATE': '-',
  'dbm.documentation.description.LEVEL_5_CALAMITY': '-',
  'dbm.documentation.description.LEVEL_5_CALAMITY_START_DATE': '-',
  'dbm.documentation.description.LEVEL_6_CALAMITY': '-',
  'dbm.documentation.description.LEVEL_6_CALAMITY_START_DATE': '-',
  'dbm.documentation.description.LEVEL_7_CALAMITY': '-',
  'dbm.documentation.description.LEVEL_7_CALAMITY_START_DATE': '-',
  'dbm.documentation.description.LEVEL_8_CALAMITY': '-',
  'dbm.documentation.description.LEVEL_8_CALAMITY_START_DATE': '-',
  'dbm.documentation.description.LEVEL_9_CALAMITY': '-',
  'dbm.documentation.description.LEVEL_9_CALAMITY_START_DATE': '-',
  'dbm.documentation.description.LEVEL_10_CALAMITY': '-',
  'dbm.documentation.description.LEVEL_10_CALAMITY_START_DATE': '-',
  'dbm.documentation.description.LEVEL_11_CALAMITY': '-',
  'dbm.documentation.description.LEVEL_11_CALAMITY_START_DATE': '-',
  'dbm.documentation.description.LEVEL_12_CALAMITY': '-',
  'dbm.documentation.description.LEVEL_12_CALAMITY_START_DATE': '-',
  'dbm.documentation.description.LEVEL_1_ENTERPRISE_ID': '-',
  'dbm.documentation.description.LEVEL_1_NL_KVK_NUMBER':
    'Uniek 8-cijferig identificatie nummer uitgegeven door de Kamer van Koophandel ',
  'dbm.documentation.description.LEVEL_1_SEARCH_ROLE_CODE': '-',
  'dbm.documentation.description.LEVEL_1_SEARCH_STATUS_CODE': '-',
  'dbm.documentation.description.LEVEL_10_DIRECT_PERCENTAGE': '-',
  'dbm.documentation.description.LEVEL_10_ENTERPRISE_ID': '-',
  'dbm.documentation.description.LEVEL_10_INDIRECT_PERCENTAGE_SUBJECT': '-',
  'dbm.documentation.description.LEVEL_10_MANDATE_AUTHORITY_CODE': '-',
  'dbm.documentation.description.LEVEL_10_NL_KVK_NUMBER':
    'Uniek 8-cijferig identificatie nummer uitgegeven door de Kamer van Koophandel ',
  'dbm.documentation.description.LEVEL_10_ORG_ADDRESS_LINE_1': '-',
  'dbm.documentation.description.LEVEL_10_ORG_ADDRESS_LINE_2': '-',
  'dbm.documentation.description.LEVEL_10_ORG_ADDRESS_LINE_3': '-',
  'dbm.documentation.description.LEVEL_10_ORG_ADDRESS_LINE_4': '-',
  'dbm.documentation.description.LEVEL_10_ORG_ADDRESS_LINE_5': '-',
  'dbm.documentation.description.LEVEL_10_ORG_NAME': '-',
  'dbm.documentation.description.LEVEL_10_POSITION_TITLE_CODE': '-',
  'dbm.documentation.description.LEVEL_10_POSITION_TYPE_CODE': '-',
  'dbm.documentation.description.LEVEL_10_ROLE_TYPE_CODE': '-',
  'dbm.documentation.description.LEVEL_10_SEARCH_ROLE_CODE': '-',
  'dbm.documentation.description.LEVEL_10_SEARCH_STATUS_CODE': '-',
  'dbm.documentation.description.LEVEL_10_START_DATE': '-',
  'dbm.documentation.description.LEVEL_11_DIRECT_PERCENTAGE': 'Rechtstreeks percentage',
  'dbm.documentation.description.LEVEL_11_ENTERPRISE_ID': '-',
  'dbm.documentation.description.LEVEL_11_INDIRECT_PERCENTAGE_SUBJECT': '-',
  'dbm.documentation.description.LEVEL_11_MANDATE_AUTHORITY_CODE': '-',
  'dbm.documentation.description.LEVEL_11_NL_KVK_NUMBER':
    'Uniek 8-cijferig identificatie nummer uitgegeven door de Kamer van Koophandel ',
  'dbm.documentation.description.LEVEL_11_ORG_ADDRESS_LINE_1': '-',
  'dbm.documentation.description.LEVEL_11_ORG_ADDRESS_LINE_2': '-',
  'dbm.documentation.description.LEVEL_11_ORG_ADDRESS_LINE_3': '-',
  'dbm.documentation.description.LEVEL_11_ORG_ADDRESS_LINE_4': '-',
  'dbm.documentation.description.LEVEL_11_ORG_ADDRESS_LINE_5': '-',
  'dbm.documentation.description.LEVEL_11_ORG_NAME': '-',
  'dbm.documentation.description.LEVEL_11_POSITION_TITLE_CODE': '-',
  'dbm.documentation.description.LEVEL_11_POSITION_TYPE_CODE': '-',
  'dbm.documentation.description.LEVEL_11_ROLE_TYPE_CODE': '-',
  'dbm.documentation.description.LEVEL_11_SEARCH_ROLE_CODE': '-',
  'dbm.documentation.description.LEVEL_11_SEARCH_STATUS_CODE': '-',
  'dbm.documentation.description.LEVEL_11_START_DATE': '-',
  'dbm.documentation.description.LEVEL_12_DIRECT_PERCENTAGE': 'Rechtstreeks percentage',
  'dbm.documentation.description.LEVEL_12_ENTERPRISE_ID': '-',
  'dbm.documentation.description.LEVEL_12_INDIRECT_PERCENTAGE_SUBJECT': '-',
  'dbm.documentation.description.LEVEL_12_MANDATE_AUTHORITY_CODE': '-',
  'dbm.documentation.description.LEVEL_12_NL_KVK_NUMBER':
    'Uniek 8-cijferig identificatie nummer uitgegeven door de Kamer van Koophandel ',
  'dbm.documentation.description.LEVEL_12_ORG_ADDRESS_LINE_1': '-',
  'dbm.documentation.description.LEVEL_12_ORG_ADDRESS_LINE_2': '-',
  'dbm.documentation.description.LEVEL_12_ORG_ADDRESS_LINE_3': '-',
  'dbm.documentation.description.LEVEL_12_ORG_ADDRESS_LINE_4': '-',
  'dbm.documentation.description.LEVEL_12_ORG_ADDRESS_LINE_5': '-',
  'dbm.documentation.description.LEVEL_12_ORG_NAME': '-',
  'dbm.documentation.description.LEVEL_12_POSITION_TITLE_CODE': '-',
  'dbm.documentation.description.LEVEL_12_POSITION_TYPE_CODE': '-',
  'dbm.documentation.description.LEVEL_12_ROLE_TYPE_CODE': '-',
  'dbm.documentation.description.LEVEL_12_SEARCH_ROLE_CODE': '-',
  'dbm.documentation.description.LEVEL_12_SEARCH_STATUS_CODE': '-',
  'dbm.documentation.description.LEVEL_12_START_DATE': '-',
  'dbm.documentation.description.LEVEL_2_DIRECT_PERCENTAGE': 'Rechtstreeks percentage',
  'dbm.documentation.description.LEVEL_2_ENTERPRISE_ID': '-',
  'dbm.documentation.description.LEVEL_2_INDIRECT_PERCENTAGE_SUBJECT': '-',
  'dbm.documentation.description.LEVEL_2_MANDATE_AUTHORITY_CODE': '-',
  'dbm.documentation.description.LEVEL_2_NL_KVK_NUMBER':
    'Uniek 8-cijferig identificatie nummer uitgegeven door de Kamer van Koophandel ',
  'dbm.documentation.description.LEVEL_2_ORG_ADDRESS_LINE_1': '-',
  'dbm.documentation.description.LEVEL_2_ORG_ADDRESS_LINE_2': '-',
  'dbm.documentation.description.LEVEL_2_ORG_ADDRESS_LINE_3': '-',
  'dbm.documentation.description.LEVEL_2_ORG_ADDRESS_LINE_4': '-',
  'dbm.documentation.description.LEVEL_2_ORG_ADDRESS_LINE_5': '-',
  'dbm.documentation.description.LEVEL_2_ORG_NAME': '-',
  'dbm.documentation.description.LEVEL_2_POSITION_TITLE_CODE': '-',
  'dbm.documentation.description.LEVEL_2_POSITION_TYPE_CODE': '-',
  'dbm.documentation.description.LEVEL_2_ROLE_TYPE_CODE': '-',
  'dbm.documentation.description.LEVEL_2_SEARCH_ROLE_CODE': '-',
  'dbm.documentation.description.LEVEL_2_SEARCH_STATUS_CODE': '-',
  'dbm.documentation.description.LEVEL_2_START_DATE': '-',
  'dbm.documentation.description.LEVEL_3_DIRECT_PERCENTAGE': 'Rechtstreeks percentage',
  'dbm.documentation.description.LEVEL_3_ENTERPRISE_ID': '-',
  'dbm.documentation.description.LEVEL_3_INDIRECT_PERCENTAGE_SUBJECT': '-',
  'dbm.documentation.description.LEVEL_3_MANDATE_AUTHORITY_CODE': '-',
  'dbm.documentation.description.LEVEL_3_NL_KVK_NUMBER':
    'Uniek 8-cijferig identificatie nummer uitgegeven door de Kamer van Koophandel ',
  'dbm.documentation.description.LEVEL_3_ORG_ADDRESS_LINE_1': '-',
  'dbm.documentation.description.LEVEL_3_ORG_ADDRESS_LINE_2': '-',
  'dbm.documentation.description.LEVEL_3_ORG_ADDRESS_LINE_3': '-',
  'dbm.documentation.description.LEVEL_3_ORG_ADDRESS_LINE_4': '-',
  'dbm.documentation.description.LEVEL_3_ORG_ADDRESS_LINE_5': '-',
  'dbm.documentation.description.LEVEL_3_ORG_NAME': '-',
  'dbm.documentation.description.LEVEL_3_POSITION_TITLE_CODE': '-',
  'dbm.documentation.description.LEVEL_3_POSITION_TYPE_CODE': '-',
  'dbm.documentation.description.LEVEL_3_ROLE_TYPE_CODE': '-',
  'dbm.documentation.description.LEVEL_3_SEARCH_ROLE_CODE': '-',
  'dbm.documentation.description.LEVEL_3_SEARCH_STATUS_CODE': '-',
  'dbm.documentation.description.LEVEL_3_START_DATE': '-',
  'dbm.documentation.description.LEVEL_4_DIRECT_PERCENTAGE': '-',
  'dbm.documentation.description.LEVEL_4_ENTERPRISE_ID': '-',
  'dbm.documentation.description.LEVEL_4_INDIRECT_PERCENTAGE_SUBJECT': '-',
  'dbm.documentation.description.LEVEL_4_MANDATE_AUTHORITY_CODE': '-',
  'dbm.documentation.description.LEVEL_4_NL_KVK_NUMBER':
    'Uniek 8-cijferig identificatie nummer uitgegeven door de Kamer van Koophandel ',
  'dbm.documentation.description.LEVEL_4_ORG_ADDRESS_LINE_1': '-',
  'dbm.documentation.description.LEVEL_4_ORG_ADDRESS_LINE_2': '-',
  'dbm.documentation.description.LEVEL_4_ORG_ADDRESS_LINE_3': '-',
  'dbm.documentation.description.LEVEL_4_ORG_ADDRESS_LINE_4': '-',
  'dbm.documentation.description.LEVEL_4_ORG_ADDRESS_LINE_5': '-',
  'dbm.documentation.description.LEVEL_4_ORG_NAME': '-',
  'dbm.documentation.description.LEVEL_4_POSITION_TITLE_CODE': '-',
  'dbm.documentation.description.LEVEL_4_POSITION_TYPE_CODE': '-',
  'dbm.documentation.description.LEVEL_4_ROLE_TYPE_CODE': '-',
  'dbm.documentation.description.LEVEL_4_SEARCH_ROLE_CODE': '-',
  'dbm.documentation.description.LEVEL_4_SEARCH_STATUS_CODE': '-',
  'dbm.documentation.description.LEVEL_4_START_DATE': '-',
  'dbm.documentation.description.LEVEL_5_DIRECT_PERCENTAGE': '-',
  'dbm.documentation.description.LEVEL_5_ENTERPRISE_ID': '-',
  'dbm.documentation.description.LEVEL_5_INDIRECT_PERCENTAGE_SUBJECT': '-',
  'dbm.documentation.description.LEVEL_5_MANDATE_AUTHORITY_CODE': '-',
  'dbm.documentation.description.LEVEL_5_NL_KVK_NUMBER':
    'Uniek 8-cijferig identificatie nummer uitgegeven door de Kamer van Koophandel ',
  'dbm.documentation.description.LEVEL_5_ORG_ADDRESS_LINE_1': '-',
  'dbm.documentation.description.LEVEL_5_ORG_ADDRESS_LINE_2': '-',
  'dbm.documentation.description.LEVEL_5_ORG_ADDRESS_LINE_3': '-',
  'dbm.documentation.description.LEVEL_5_ORG_ADDRESS_LINE_4': '-',
  'dbm.documentation.description.LEVEL_5_ORG_ADDRESS_LINE_5': '-',
  'dbm.documentation.description.LEVEL_5_ORG_NAME': '-',
  'dbm.documentation.description.LEVEL_5_POSITION_TITLE_CODE': '-',
  'dbm.documentation.description.LEVEL_5_POSITION_TYPE_CODE': '-',
  'dbm.documentation.description.LEVEL_5_ROLE_TYPE_CODE': '-',
  'dbm.documentation.description.LEVEL_5_SEARCH_ROLE_CODE': '-',
  'dbm.documentation.description.LEVEL_5_SEARCH_STATUS_CODE': '-',
  'dbm.documentation.description.LEVEL_5_START_DATE': '-',
  'dbm.documentation.description.LEVEL_6_DIRECT_PERCENTAGE': '-',
  'dbm.documentation.description.LEVEL_6_ENTERPRISE_ID': '-',
  'dbm.documentation.description.LEVEL_6_INDIRECT_PERCENTAGE_SUBJECT': '-',
  'dbm.documentation.description.LEVEL_6_MANDATE_AUTHORITY_CODE': '-',
  'dbm.documentation.description.LEVEL_6_NL_KVK_NUMBER':
    'Uniek 8-cijferig identificatie nummer uitgegeven door de Kamer van Koophandel ',
  'dbm.documentation.description.LEVEL_6_ORG_ADDRESS_LINE_1': '-',
  'dbm.documentation.description.LEVEL_6_ORG_ADDRESS_LINE_2': '-',
  'dbm.documentation.description.LEVEL_6_ORG_ADDRESS_LINE_3': '-',
  'dbm.documentation.description.LEVEL_6_ORG_ADDRESS_LINE_4': '-',
  'dbm.documentation.description.LEVEL_6_ORG_ADDRESS_LINE_5': '-',
  'dbm.documentation.description.LEVEL_6_ORG_NAME': '-',
  'dbm.documentation.description.LEVEL_6_POSITION_TITLE_CODE': '-',
  'dbm.documentation.description.LEVEL_6_POSITION_TYPE_CODE': '-',
  'dbm.documentation.description.LEVEL_6_ROLE_TYPE_CODE': '-',
  'dbm.documentation.description.LEVEL_6_SEARCH_ROLE_CODE': '-',
  'dbm.documentation.description.LEVEL_6_SEARCH_STATUS_CODE': '-',
  'dbm.documentation.description.LEVEL_6_START_DATE': '-',
  'dbm.documentation.description.LEVEL_7_DIRECT_PERCENTAGE': 'Rechtstreeks percentage',
  'dbm.documentation.description.LEVEL_7_ENTERPRISE_ID': '-',
  'dbm.documentation.description.LEVEL_7_INDIRECT_PERCENTAGE_SUBJECT': '-',
  'dbm.documentation.description.LEVEL_7_MANDATE_AUTHORITY_CODE': '-',
  'dbm.documentation.description.LEVEL_7_NL_KVK_NUMBER':
    'Uniek 8-cijferig identificatie nummer uitgegeven door de Kamer van Koophandel ',
  'dbm.documentation.description.LEVEL_7_ORG_ADDRESS_LINE_1': '-',
  'dbm.documentation.description.LEVEL_7_ORG_ADDRESS_LINE_2': '-',
  'dbm.documentation.description.LEVEL_7_ORG_ADDRESS_LINE_3': '-',
  'dbm.documentation.description.LEVEL_7_ORG_ADDRESS_LINE_4': '-',
  'dbm.documentation.description.LEVEL_7_ORG_ADDRESS_LINE_5': '-',
  'dbm.documentation.description.LEVEL_7_ORG_NAME': '-',
  'dbm.documentation.description.LEVEL_7_POSITION_TITLE_CODE': '-',
  'dbm.documentation.description.LEVEL_7_POSITION_TYPE_CODE': '-',
  'dbm.documentation.description.LEVEL_7_ROLE_TYPE_CODE': '-',
  'dbm.documentation.description.LEVEL_7_SEARCH_ROLE_CODE': '-',
  'dbm.documentation.description.LEVEL_7_SEARCH_STATUS_CODE': '-',
  'dbm.documentation.description.LEVEL_7_START_DATE': '-',
  'dbm.documentation.description.LEVEL_8_DIRECT_PERCENTAGE': 'Rechtstreeks percentage',
  'dbm.documentation.description.LEVEL_8_ENTERPRISE_ID': '-',
  'dbm.documentation.description.LEVEL_8_INDIRECT_PERCENTAGE_SUBJECT': '-',
  'dbm.documentation.description.LEVEL_8_MANDATE_AUTHORITY_CODE': '-',
  'dbm.documentation.description.LEVEL_8_NL_KVK_NUMBER':
    'Uniek 8-cijferig identificatie nummer uitgegeven door de Kamer van Koophandel ',
  'dbm.documentation.description.LEVEL_8_ORG_ADDRESS_LINE_1': '-',
  'dbm.documentation.description.LEVEL_8_ORG_ADDRESS_LINE_2': '-',
  'dbm.documentation.description.LEVEL_8_ORG_ADDRESS_LINE_3': '-',
  'dbm.documentation.description.LEVEL_8_ORG_ADDRESS_LINE_4': '-',
  'dbm.documentation.description.LEVEL_8_ORG_ADDRESS_LINE_5': '-',
  'dbm.documentation.description.LEVEL_8_ORG_NAME': '-',
  'dbm.documentation.description.LEVEL_8_POSITION_TITLE_CODE': '-',
  'dbm.documentation.description.LEVEL_8_POSITION_TYPE_CODE': '-',
  'dbm.documentation.description.LEVEL_8_ROLE_TYPE_CODE': '-',
  'dbm.documentation.description.LEVEL_8_SEARCH_ROLE_CODE': '-',
  'dbm.documentation.description.LEVEL_8_SEARCH_STATUS_CODE': '-',
  'dbm.documentation.description.LEVEL_8_START_DATE': '-',
  'dbm.documentation.description.LEVEL_9_DIRECT_PERCENTAGE': 'Rechtstreeks percentage',
  'dbm.documentation.description.LEVEL_9_ENTERPRISE_ID': '-',
  'dbm.documentation.description.LEVEL_9_INDIRECT_PERCENTAGE_SUBJECT': '-',
  'dbm.documentation.description.LEVEL_9_MANDATE_AUTHORITY_CODE': '-',
  'dbm.documentation.description.LEVEL_9_NL_KVK_NUMBER':
    'Uniek 8-cijferig identificatie nummer uitgegeven door de Kamer van Koophandel ',
  'dbm.documentation.description.LEVEL_9_ORG_ADDRESS_LINE_1': '-',
  'dbm.documentation.description.LEVEL_9_ORG_ADDRESS_LINE_2': '-',
  'dbm.documentation.description.LEVEL_9_ORG_ADDRESS_LINE_3': '-',
  'dbm.documentation.description.LEVEL_9_ORG_ADDRESS_LINE_4': '-',
  'dbm.documentation.description.LEVEL_9_ORG_ADDRESS_LINE_5': '-',
  'dbm.documentation.description.LEVEL_9_ORG_NAME': '-',
  'dbm.documentation.description.LEVEL_9_POSITION_TITLE_CODE': '-',
  'dbm.documentation.description.LEVEL_9_POSITION_TYPE_CODE': '-',
  'dbm.documentation.description.LEVEL_9_ROLE_TYPE_CODE': '-',
  'dbm.documentation.description.LEVEL_9_SEARCH_ROLE_CODE': '-',
  'dbm.documentation.description.LEVEL_9_SEARCH_STATUS_CODE': '-',
  'dbm.documentation.description.LEVEL_9_START_DATE': '-',
  'dbm.documentation.description.LIST_MGT_ORG_ID': '-',
  'dbm.documentation.description.LONGITUDE': 'GPS lengtegraad in decimale graden',
  'dbm.documentation.description.MEETING_DATE': 'Datum algemene vergadering',
  'dbm.documentation.description.MOBILE': 'Mobiel nummer/gsm',
  'dbm.documentation.description.NACE_ACTIVITY_CODE':
    'Branchecode omschrijving via NACE indeling (1e 4 cijfers van de SBI indeling)',
  'dbm.documentation.description.NAME': 'Naam van de organisatie',
  'dbm.documentation.description.NET_RESULT': 'Netto resultaat',
  'dbm.documentation.description.NL_DESCRIPTION': 'Nederlandse omschrijving',
  'dbm.documentation.description.NL_KVK_BRANCH_NUMBER':
    'Voor iedere vestiging wordt door de Kamer van Koophandel een uniek 12-cijferig Kamer van Koophandel vestgingsnummer uitgegeven.',
  'dbm.documentation.description.NL_KVK_NUMBER':
    'Unieke identificatie - officieel 8-cijferig geregistreerd id van de Kamer van Koophandel',
  'dbm.documentation.description.NL_KVK_NUMBER_12':
    'Officieel 8-cijferig KvK nummer aangevuld met het 4-cijferig KvK subdossiernummer ',
  'dbm.documentation.description.NL_LEGAL_FORM_CODE':
    'Huidige rechtsvorm van de organisatie. De rechtsvorm wordt geleverd in een code. Domeincode in code-beschrijving:\nNL_LEGAL_FORM_CODE\n',
  'dbm.documentation.description.NL_RSIN':
    'Elke niet natuurlijk persoon (samenwerkingsverband of rechtspersoon) krijgt in het handelsregister een door de KvK uit te geven Rechtspersonen Samenwerkingsverbanden Informatie Nummer (het RSIN). Het RSIN is een 9-cijferig nummer en kan voorloopnullen bevatten. \r\n',
  'dbm.documentation.description.NOMINAL_VALUE': 'Nominale waarde',
  'dbm.documentation.description.NOTICE_EVENT_DATE': 'Begindatum en einddatum boekjaar ',
  'dbm.documentation.description.NUM_BRANCH_OFFICES': 'Aantal vestigingen van de onderneming',
  'dbm.documentation.description.NUM_CCJ_LAST_12_MONTHS': '-',
  'dbm.documentation.description.NUM_CCJ_LAST_24_MONTHS': '-',
  'dbm.documentation.description.NUM_CCJ_LAST_36_MONTHS': '-',
  'dbm.documentation.description.NUM_CCJ_LAST_48_MONTHS': '-',
  'dbm.documentation.description.NUM_CCJ_LAST_60_MONTHS': '-',
  'dbm.documentation.description.NUM_CCJ_LAST_72_MONTHS': '-',
  'dbm.documentation.description.NUM_EMPLOYEES_SIZE_CLASS_CODE': '-',
  'dbm.documentation.description.NUM_OF_SUPPLIERS': 'Aantal leveranciers',
  'dbm.documentation.description.NUM_PAYMENT_EXPERIENCES': 'Aantal betalingservaringen',
  'dbm.documentation.description.NUM_SHARES':
    'Het aantal aandelen dat een aandeelhouder bezit in het gevraagde bedrijf',
  'dbm.documentation.description.ORGANIZATION_ID':
    'Unieke identificatie in de Graydon database om een organisatie te identificeren (branche, departement of root organisatie)',
  'dbm.documentation.description.ORGANIZATION_NAME': 'Naam',
  'dbm.documentation.description.ORGANIZATION_NAME_TYPE_CODE': 'Organisatienaam type code',
  'dbm.documentation.description.ORGANIZATION_TYPE_CODE':
    'Type organisatie , ROOT_ORG of BRANCH_OFFICE',
  'dbm.documentation.description.ORIGIN_CODE': 'Broncode',
  'dbm.documentation.description.OTHER_ADDR_LINE_1': 'Adreslijn 1 (voortzetting)',
  'dbm.documentation.description.OTHER_ADDR_LINE_2': 'Adreslijn 2 (voortzetting)',
  'dbm.documentation.description.OTHER_ADDR_LINE_3': 'Adreslijn 3 (voortzetting)',
  'dbm.documentation.description.OTHER_ADDR_LINE_4': 'Adreslijn 4 (voortzetting)',
  'dbm.documentation.description.OTHER_ADDR_LINE_5': 'Adreslijn 5 (voortzetting)',
  'dbm.documentation.description.OTHER_BE_ENTERPRISE_NUMBER': 'Ander ondernemingsnummer',
  'dbm.documentation.description.OTHER_ENTERPRISE_ID': 'Graydon Enterprise ID van de organisatie ',
  'dbm.documentation.description.OTHER_GB_CRO_REGISTRATION_NUMBER': '-',
  'dbm.documentation.description.OTHER_NAME': 'Naam van de organisatie (voortzetting)',
  'dbm.documentation.description.OTHER_NL_KVK_NUMBER':
    '8 cijferig Kamer van Koophandel nummer van de organisatie',
  'dbm.documentation.description.OTHER_ORG_ADDRESS_LINE_1': 'Adreslijn 1 gerelateerde onderneming ',
  'dbm.documentation.description.OTHER_ORG_ADDRESS_LINE_2': 'Adreslijn 2 gerelateerde onderneming ',
  'dbm.documentation.description.OTHER_ORG_ADDRESS_LINE_3': 'Adreslijn 3 gerelateerde onderneming ',
  'dbm.documentation.description.OTHER_ORG_ADDRESS_LINE_4': 'Adreslijn 4 gerelateerde onderneming ',
  'dbm.documentation.description.OTHER_ORG_ADDRESS_LINE_5': 'Adreslijn 5 gerelateerde onderneming ',
  'dbm.documentation.description.OTHER_ORG_NAME': 'Naam gerelateerde onderneming',
  'dbm.documentation.description.PAID_UP_CAPITAL': "Gestort kapitaal (in Euro's)",
  'dbm.documentation.description.PAID_UP_CONTRIBUTION': 'Gestorte inbreng',
  'dbm.documentation.description.PAID_UP_FIXED_CAPITAL': 'Gestort vast kapitaal',
  'dbm.documentation.description.PAID_UP_VARIABLE_CAPITAL': 'Gestort variabel kapitaal',
  'dbm.documentation.description.PART_ADDR_LINE_1':
    'Adresregel 1 van het adres van de participatie. Buitenlands adres.',
  'dbm.documentation.description.PART_ADDR_LINE_2':
    'Adresregel 2 van het adres van de participatie. Buitenlands adres.',
  'dbm.documentation.description.PART_ADDR_LINE_3':
    'Adresregel 3 van het adres van de participatie. Buitenlands adres.',
  'dbm.documentation.description.PART_ADDR_LINE_4':
    'Adresregel 4 van het adres van de participatie. Buitenlands adres.',
  'dbm.documentation.description.PART_ADDR_LINE_5':
    'Adresregel 5 van het adres van de participatie. Buitenlands adres.',
  'dbm.documentation.description.PART_BE_ENTERPRISE_NUMBER': 'KBO-ondernemingsnummer',
  'dbm.documentation.description.PART_ENTERPRISE_ID':
    'Graydon uniek organisatie ID van de onderneming waarin wordt deelgenomen',
  'dbm.documentation.description.PART_NAME': 'Naam van de participatie',
  'dbm.documentation.description.PART_NL_KVK_NUMBER':
    'Unieke identificatie - officieel 8-cijferig geregistreerd id van de Kamer van Koophandel van de onderneming waarin wordt deelgenomen ',
  'dbm.documentation.description.PARTNERSHIP_CAPITAL': "Deelnemingskapitaal (in Euro's)",
  'dbm.documentation.description.PAYMENT_SCORE':
    'De betaalscore geeft het betaalgedrag van het bedrijf weer',
  'dbm.documentation.description.PCT_OVER_90_DBT':
    'Percentage betalingen langer dan 90 dagen na vervaldatum ',
  'dbm.documentation.description.PCT_TO_30_DBT':
    'Percentage betalingen 1 tot 30 dagen na vervaldatum',
  'dbm.documentation.description.PCT_TO_60_DBT':
    'Percentage betalingen 31 tot 60 dagen na vervaldatum',
  'dbm.documentation.description.PCT_TO_90_DBT':
    'Percentage betalingen 61 tot 90 dagen na vervaldatum',
  'dbm.documentation.description.PCT_WITHIN_TERMS': 'Percentage betalingen binnen termijn',
  'dbm.documentation.description.PD_PERCENTAGE': 'De kans op wanbetaling als percentage',
  'dbm.documentation.description.PERSON_BIRTH_CITY': 'Geboorteplaats van de persoon.',
  'dbm.documentation.description.PERSON_BIRTH_COUNTRY_CODE':
    'De ISO-code van het land waar de persoon geboren is',
  'dbm.documentation.description.PERSON_BIRTH_DATE': 'Geboortedatum van de persoon',
  'dbm.documentation.description.PERSON_BIRTH_NDATE':
    'Niet opgemaakte geboortedatum van een persoon in de bedrijfsleiding. Deze datum is niet gecontroleerd en komt rechtstreeks van de bron.',
  'dbm.documentation.description.PERSON_CITY': 'Woonplaats',
  'dbm.documentation.description.PERSON_COUNTRY_CODE':
    'ISO alpha-2 landcode van oorsprong (oorsprong van officiële bron)',
  'dbm.documentation.description.PERSON_DEATH_DATE': 'Datum van overlijden van de persoon',
  'dbm.documentation.description.PERSON_DEATH_NDATE':
    'Datum van overlijden van de persoon (niet opgemaakte datum)',
  'dbm.documentation.description.PERSON_DETAILS_PROTECTED': '-',
  'dbm.documentation.description.PERSON_FIRST_NAMES': 'Voornaam van de persoon',
  'dbm.documentation.description.PERSON_FULL_NAME': 'Volledige naam van de persoon ',
  'dbm.documentation.description.PERSON_GENDER_CODE': 'Geslachtscode van de persoon (M, F, X)',
  'dbm.documentation.description.PERSON_HOUSE_NUMBER': 'Huisnummer dat bij een adres behoort',
  'dbm.documentation.description.PERSON_HOUSE_NUMBER_SUFFIX':
    'Huisnummer toevoeging dat bij een adres behoort',
  'dbm.documentation.description.PERSON_ID': 'Extern persoons id aangemaakt door Graydon',
  'dbm.documentation.description.PERSON_INITIALS': 'Initialen van de persoon',
  'dbm.documentation.description.PERSON_POSTCODE': 'Postcode',
  'dbm.documentation.description.PERSON_STREET': 'Straatnaam',
  'dbm.documentation.description.PERSON_STREET_CODE': 'Belgische straatcode',
  'dbm.documentation.description.PERSON_SURNAME':
    'Familienaam of het laatste deel van de naam van de persoon',
  'dbm.documentation.description.PERSON_SURNAME_PREFIX':
    'Prefix dat tussen de voor- en de achternaam komt',
  'dbm.documentation.description.PERSON_TITLES': 'Titel van de persoon ',
  'dbm.documentation.description.PO_BOX_CITY': 'Woonplaats',
  'dbm.documentation.description.PO_BOX_END_DATE': 'Eind datum postbus adres',
  'dbm.documentation.description.PO_BOX_NUMBER': 'Postbusnummer',
  'dbm.documentation.description.PO_BOX_POSTCODE': 'Postcode postbus',
  'dbm.documentation.description.PO_BOX_START_DATE': 'Start datum postbus adres',
  'dbm.documentation.description.POSITION_END_DATE': 'Einddatum van het mandaat',
  'dbm.documentation.description.POSITION_HOLDER_ADDR_LINE_1':
    'Adreslijn 1 van de onderneming die de positie bekleed ',
  'dbm.documentation.description.POSITION_HOLDER_ADDR_LINE_2':
    'Adreslijn 2 van de onderneming die de positie bekleed ',
  'dbm.documentation.description.POSITION_HOLDER_ADDR_LINE_3':
    'Adreslijn 3 van de onderneming die de positie bekleed ',
  'dbm.documentation.description.POSITION_HOLDER_ADDR_LINE_4':
    'Adreslijn 4 van de onderneming die de positie bekleed ',
  'dbm.documentation.description.POSITION_HOLDER_ADDR_LINE_5':
    'Adreslijn 5 van de onderneming die de positie bekleed ',
  'dbm.documentation.description.POSITION_HOLDER_BE_ENTERPRISE_NUMBER':
    'Graydon id van de onderneming die de positie bekleed ',
  'dbm.documentation.description.POSITION_HOLDER_ENTERPRISE_ID':
    'Unieke identificatie in de Graydon database om een organisatie te identificeren. Enterprise ID van de onderneming die de positie bekleed',
  'dbm.documentation.description.POSITION_HOLDER_NAME':
    'Naam van de onderneming die de positie bekleed ',
  'dbm.documentation.description.POSITION_HOLDER_NL_KVK_NUMBER':
    '8 cijferig Kamer van Koophandel nummer van de onderneming die de positie bekleed',
  'dbm.documentation.description.POSITION_MANDATE_AUTHORITY_CODE':
    'Volmacht/ bevoegdheid code (gestandaardiseerd) ',
  'dbm.documentation.description.POSITION_MANDATE_TEXT':
    'Volmacht  / bevoegdheid beschreven zoals op het uittreksel ',
  'dbm.documentation.description.POSITION_START_DATE': 'Startdatum van het mandaat',
  'dbm.documentation.description.POSITION_TITLE':
    'Vrije tekst beschrijving van de managementpositie',
  'dbm.documentation.description.POSITION_TITLE_CODE': 'Functiecode',
  'dbm.documentation.description.POSITION_TYPE_CODE': 'Het type positie die een entiteit heeft',
  'dbm.documentation.description.POSTCODE': 'Postcode',
  'dbm.documentation.description.PREFERRED_LANGUAGE_CODE':
    'Voorkeurtaal, alleen België, ISO-639-1 code',
  'dbm.documentation.description.PRELIMINARY_FLAG':
    'Flag of de type insolventie de status voorlopig heeft (Y/N) ',
  'dbm.documentation.description.PRIMARY_ACTIVITY_CODE_1':
    'Een standaardcode om de activiteiten van de onderneming te identificeren (primair)',
  'dbm.documentation.description.QUARTER': 'Kwartaal 1, 2 , 3 of 4',
  'dbm.documentation.description.QUICK_RATIO': 'Quick ratio van het bedrijf',
  'dbm.documentation.description.REASON_CODE': 'Rede code',
  'dbm.documentation.description.RECEIVER_1': 'Curator / Bewindvoerder',
  'dbm.documentation.description.RECEIVER_1_END_DATE': 'Einddatum curator',
  'dbm.documentation.description.RECEIVER_1_TYPE_CODE':
    'Type code van de functie die bekleed wordt tijdens de insolventieprocedure. (Curator, Bewindvoerder, etc) ',
  'dbm.documentation.description.RECEIVER_10': 'Curator / Gerechtsmandataris',
  'dbm.documentation.description.RECEIVER_2': 'Curator / Bewindvoerder',
  'dbm.documentation.description.RECEIVER_2_END_DATE': 'Einddatum curator',
  'dbm.documentation.description.RECEIVER_2_TYPE_CODE':
    'Type code van de functie die bekleed wordt tijdens de insolventieprocedure. (Curator, Bewindvoerder, etc) ',
  'dbm.documentation.description.RECEIVER_3': 'Curator / Gerechtsmandataris',
  'dbm.documentation.description.RECEIVER_3_END_DATE': 'Einddatum curator',
  'dbm.documentation.description.RECEIVER_3_TYPE_CODE':
    'Type code van de functie die bekleed wordt tijdens de insolventieprocedure. (Curator, Bewindvoerder, etc) ',
  'dbm.documentation.description.RECEIVER_4': 'Curator / Gerechtsmandataris',
  'dbm.documentation.description.RECEIVER_4_TYPE_CODE':
    'Type code van de functie die bekleed wordt tijdens de insolventieprocedure. (Curator, Bewindvoerder, etc) ',
  'dbm.documentation.description.RECEIVER_5': 'Curator / Gerechtsmandataris',
  'dbm.documentation.description.RECEIVER_5_TYPE_CODE':
    'Type code van de functie die bekleed wordt tijdens de insolventieprocedure. (Curator, Bewindvoerder, etc) ',
  'dbm.documentation.description.RECEIVER_6': 'Curator / Gerechtsmandataris',
  'dbm.documentation.description.RECEIVER_7': 'Curator / Gerechtsmandataris',
  'dbm.documentation.description.RECEIVER_8': 'Curator / Gerechtsmandataris',
  'dbm.documentation.description.RECEIVER_9': 'Curator / Gerechtsmandataris',
  'dbm.documentation.description.RECIEVER': 'Curator / Gerechtsmandataris',
  'dbm.documentation.description.RECIEVER_TYPE_CODE':
    'Type code van de functie die bekleed wordt tijdens de insolventieprocedure wordt ingenomen. (Curator, Bewindvoerder, etc) ',
  'dbm.documentation.description.REFERENCE': 'Uw referentie',
  'dbm.documentation.description.REGION':
    'Bedrijfsregistratie regiocode (Vlaanderen, Brussel, Wallonië)',
  'dbm.documentation.description.RELATION_TYPE_CODE':
    'Type code relatie \r\nMoeder of uiteindelijke moeder',
  'dbm.documentation.description.REPRESENTATIVE_PERSON_BIRTH_CITY':
    'Geboorteplaats van de persoon.',
  'dbm.documentation.description.REPRESENTATIVE_PERSON_BIRTH_COUNTRY_CODE':
    'De ISO-code van het land waar de persoon geboren is',
  'dbm.documentation.description.REPRESENTATIVE_PERSON_BIRTH_DATE': 'Geboortedatum van de persoon',
  'dbm.documentation.description.REPRESENTATIVE_PERSON_BIRTH_NDATE':
    'Niet opgemaakte geboortedatum van een persoon in de bedrijfsleiding. Deze datum is niet gecontroleerd en komt rechtstreeks van de bron.',
  'dbm.documentation.description.REPRESENTATIVE_PERSON_CITY': 'Woonplaats',
  'dbm.documentation.description.REPRESENTATIVE_PERSON_COUNTRY_CODE':
    'ISO alpha-2 landcode van oorsprong (oorsprong van officiële bron)',
  'dbm.documentation.description.REPRESENTATIVE_PERSON_DEATH_DATE': 'Datum van overlijden',
  'dbm.documentation.description.REPRESENTATIVE_PERSON_DEATH_NDATE':
    'Datum van overlijden - niet opgemaakt',
  'dbm.documentation.description.REPRESENTATIVE_PERSON_FIRST_NAMES': 'Voornaam van de persoon',
  'dbm.documentation.description.REPRESENTATIVE_PERSON_FULL_NAME':
    'De volledige naam van de persoon',
  'dbm.documentation.description.REPRESENTATIVE_PERSON_GENDER_CODE':
    'Geslachtscode van de persoon (M, F, X)',
  'dbm.documentation.description.REPRESENTATIVE_PERSON_HOUSE_NUMBER':
    'Huisnummer dat bij een adres behoort',
  'dbm.documentation.description.REPRESENTATIVE_PERSON_HOUSE_NUMBER_SUFFIX':
    'Huisnummer toevoeging dat bij een adres behoort',
  'dbm.documentation.description.REPRESENTATIVE_PERSON_ID':
    'Graydon persoons id vertegenwoordigers',
  'dbm.documentation.description.REPRESENTATIVE_PERSON_INITIALS': 'Initialen van de persoon',
  'dbm.documentation.description.REPRESENTATIVE_PERSON_POSTCODE': 'Postcode',
  'dbm.documentation.description.REPRESENTATIVE_PERSON_STREET': 'Straatnaam',
  'dbm.documentation.description.REPRESENTATIVE_PERSON_STREET_CODE': 'Belgische straatcode',
  'dbm.documentation.description.REPRESENTATIVE_PERSON_SURNAME':
    'Familienaam van de vertegenwoordiger',
  'dbm.documentation.description.REPRESENTATIVE_PERSON_SURNAME_PREFIX':
    'Prefix dat tussen de voor- en de achternaam komt',
  'dbm.documentation.description.REPRESENTATIVE_PERSON_TITLES': 'Aanspreek titel van de persoon',
  'dbm.documentation.description.RESULT_SEARCH_STATUS_CODE': '-',
  'dbm.documentation.description.ROW_TYPE':
    'Speciaal veld dat aangeeft welk type data er in de rij staat. Zie Technische documentatie.',
  'dbm.documentation.description.RSZ_NUMBER': 'RSZ nummer',
  'dbm.documentation.description.SBI_ACTIVITY_CODE':
    'Standaard branche code gebaseerd op de SBI2008 indeling dat de activiteiten van de onderneming weergeeft.  ',
  'dbm.documentation.description.SEARCH_ROLE_CODE': '-',
  'dbm.documentation.description.SECONDARY_ACTIVITY_CODE_1':
    'Een standaardcode om de activiteiten van de onderneming te identificeren (secundair)',
  'dbm.documentation.description.SECONDARY_ACTIVITY_CODE_2':
    'Een standaardcode om de activiteiten van de onderneming te identificeren (secundair)',
  'dbm.documentation.description.SECONDARY_ACTIVITY_CODE_3':
    'Een standaardcode om de activiteiten van de onderneming te identificeren (secundair)',
  'dbm.documentation.description.SECTOR_CODE': 'Industriecode, sectorcode',
  'dbm.documentation.description.SEQUENCE_NUMBER': '-',
  'dbm.documentation.description.SHARE_PERCENTAGE':
    'Aandelenpercentage in het bezit van een entiteit',
  'dbm.documentation.description.SHARE_TYPE_CODE': 'type aandeel',
  'dbm.documentation.description.SHAREHOLDER_ADDR_LINE_1': 'Adreslijn 1 van de aandeelhouder',
  'dbm.documentation.description.SHAREHOLDER_ADDR_LINE_2': 'Adreslijn 2 van de aandeelhouder',
  'dbm.documentation.description.SHAREHOLDER_ADDR_LINE_3': 'Adreslijn 3 van de aandeelhouder',
  'dbm.documentation.description.SHAREHOLDER_ADDR_LINE_4': 'Adreslijn 4 van de aandeelhouder',
  'dbm.documentation.description.SHAREHOLDER_ADDR_LINE_5': 'Adreslijn 5 van de aandeelhouder',
  'dbm.documentation.description.SHAREHOLDER_FUNDS': 'De aandeelhoudersfondsen',
  'dbm.documentation.description.SHAREHOLDER_NAME': 'Naam van de aandeelhouder',
  'dbm.documentation.description.SHL_BE_ENTERPRISE_NUMBER': 'KBO-ondernemingsnummer',
  'dbm.documentation.description.SHL_ENTERPRISE_ID': 'Enterprise ID van de aandeelhouder',
  'dbm.documentation.description.SHL_NL_KVK_NUMBER':
    'Unieke identificatie - officieel 8-cijferig geregistreerd id van de Kamer van Koophandel van de aandeelhouder',
  'dbm.documentation.description.SHL_PERSON_ID': 'Graydon persoons id',
  'dbm.documentation.description.SIZE_CLASS_BE_RSZ_NUM_EMPLOYEES': 'RSZ personeelsklasse',
  'dbm.documentation.description.SIZE_CLASS_EMPLOYEE_COUNT_FULL_TIME':
    'Totaal aantal medewerkers naar klasse werkzame personen (klasse 1 t/m 10) ',
  'dbm.documentation.description.SIZE_CLASS_EMPLOYEE_COUNT_TOTAL': 'Totaal werknemersklasse',
  'dbm.documentation.description.SIZE_CLASS_GROUP_EMPLOYEE_COUNT_FULL_TIME':
    'Totaal aantal full-time medewerkers binnen het concern naar klasse werkzame personen (klasse 1 t/m 10) ',
  'dbm.documentation.description.SIZE_CLASS_GROUP_EMPLOYEE_COUNT_TOTAL':
    'Totaal aantal medewerkers binnen het concern naar klasse werkzame personen (klasse 1 t/m 10)',
  'dbm.documentation.description.SIZE_CLASS_NUM_EMPLOYEES': 'Personeelsklasse',
  'dbm.documentation.description.SOURCE': 'Bron kan NBB of Bijlage Belgisch staatblad zijn',
  'dbm.documentation.description.SOURCE_REF': 'Bron referentiecode',
  'dbm.documentation.description.START_DATE': 'Startdatum',
  'dbm.documentation.description.STREET': 'Straatnaam',
  'dbm.documentation.description.STREET_CODE': 'Belgische straatcode',
  'dbm.documentation.description.SUM_TOTAL_PERCENTAGE_SUBJECT_ALL': '-',
  'dbm.documentation.description.SUMMONS': 'Dagvaardingen',
  'dbm.documentation.description.TEL_AREA_CODE': 'Zonenummer',
  'dbm.documentation.description.TEL_COUNTRY_CODE': 'Landcode telefoonnummer',
  'dbm.documentation.description.TEL_SUBSCRIBER_NUMBER': 'Abonneenummer van een bedrijf',
  'dbm.documentation.description.TELEPHONE': 'Telefoonnummer',
  'dbm.documentation.description.TELEPHONE_RECEIVER_1':
    'Telefoonnummer van de curator of bewindvoerder ',
  'dbm.documentation.description.TELEPHONE_RECEIVER_2':
    'Telefoonnummer van de curator of bewindvoerder ',
  'dbm.documentation.description.TELEPHONE_RECEIVER_3':
    'Telefoonnummer van de curator of bewindvoerder ',
  'dbm.documentation.description.TITLES': 'Aanspreek titel van de persoon',
  'dbm.documentation.description.TITLES_RECEIVER_1':
    'Aanspreektitel van de curator of bewindvoerder',
  'dbm.documentation.description.TITLES_RECEIVER_2':
    'Aanspreektitel van de curator of bewindvoerder',
  'dbm.documentation.description.TITLES_RECEIVER_3':
    'Aanspreektitel van de curator of bewindvoerder',
  'dbm.documentation.description.TOTAL_EQUITY': 'Eigen vermogen van het bedrijf',
  'dbm.documentation.description.TOTAL_PERCENTAGE_SUBJECT': '-',
  'dbm.documentation.description.TRADE_NAME_1': 'first trade name',
  'dbm.documentation.description.TRADE_NAME_2': 'second trade name',
  'dbm.documentation.description.TRADE_NAME_3': '3th trade name',
  'dbm.documentation.description.TURNOVER': 'Omzet van het bedrijf',
  'dbm.documentation.description.TYPE_CODE': 'Key figure item type code',
  'dbm.documentation.description.UBO_SOURCE': '-',
  'dbm.documentation.description.UBO_SOURCE_KVK_DATE': '-',
  'dbm.documentation.description.UNAVAILABLE_CONTRIBUTION': 'Onbeschikbare inbreng',
  'dbm.documentation.description.UPDATED_ON': 'Datum waarop de code tabel is bjigewerkt. ',
  'dbm.documentation.description.URL': 'URL',
  'dbm.documentation.description.USE_CLASS_CODE_1': 'Use class code 1',
  'dbm.documentation.description.USE_CLASS_CODE_2': 'Use class code 2',
  'dbm.documentation.description.USE_CLASS_CODE_3': 'Use class code 3',
  'dbm.documentation.description.USE_CLASS_CODE_4': 'Use class code 4',
  'dbm.documentation.description.USE_CLASS_CODE_5': 'Use class code 5',
  'dbm.documentation.description.VARIABLE_CAPITAL': 'Variabel kapitaal',
  'dbm.documentation.description.VAT_LIABILITY': 'Is een bedrijf BTW-plichting of niet?',
  'dbm.documentation.description.VAT_NUMBER': 'Uniek BTW nummer',
  'dbm.documentation.description.VOTING_DATE': 'De datum van de stemming ',
  'dbm.documentation.description.WORKING_CAPITAL': 'Werkkapitaal van het bedrijf',
  'dbm.documentation.description.XSEPTION_CATEGORY_CODE': 'XSeption categorie code',
  'dbm.documentation.description.XSEPTION_NDATE_1':
    'Boekjaar van de jaarrekening waarop de Xseption van toepassing is',
  'dbm.documentation.description.XSEPTION_NDATE_2':
    'Boekjaar van de jaarrekening waarop de Xseption van toepassing is',
  'dbm.documentation.description.XSEPTION_TEXT': 'XSeptions',
  'dbm.documentation.description.XSEPTION_TYPE_CODE': 'XSeption type code',
  'dbm.documentation.description.XSEPTION_VALUE_1': 'XSeption waarde veld 1',
  'dbm.documentation.description.XSEPTION_VALUE_2': 'XSeption waarde veld 2',
  'dbm.documentation.description.YEAR': 'Numeriek jaar',
  'dbm.documentation.description.NL_KVK_NUMBER_CONTINUATION': '-',
  'dbm.documentation.description.NL_LEGAL_FORM_DESCRIPTION': '-',
  'dbm.documentation.description.COUNTRY': '-',
  'dbm.documentation.description.FOUND_AT_NL_KVK_NUMBER': '-',
  'dbm.documentation.description.FOUND_AT_NL_LEGAL_FORM_DESCRIPTION': '-',
  'dbm.documentation.description.PERSON_BIRTH_COUNTRY': '-',
  'dbm.documentation.description.RESULT_SEARCH_STATUS_DESCRIPTION': '-',
  'dbm.documentation.description.INTEREST_TYPE_CODE': '-',
  'dbm.documentation.description.INTEREST_SIZE_CODE': '-',
  'dbm.documentation.description.REGISTRATION_DATE': '-',
  'dbm.documentation.domain-code.description':
    'Sommige velden bevatten een code in plaats van een leesbare waarde. De mogelijk codes en hun omschrijvingen (in het Nederlands, Engels en Frans) kunnen in het DBM_CODE_DESCRIPTIONS datablok gevonden worden, zie hierboven. Download dit datablok voor de meest recente versies van de omschrijvingen van elke code.',
  'dbm.documentation.domain-code.description.title': 'Domeincode in code omschrijving:',
  'dbm.documentation.domain-code.link': 'Info over code omschrijvingen',
  'dbm.documentation.domain-code.title': '{country} Code omschrijvingen',
  'dbm.documentation.download-example': 'Download/Bekijk voorbeeld van dit datablok (.csv)',
  'dbm.documentation.example.ABBREVIATIONS_LEGAL_FORM': 'NV',
  'dbm.documentation.example.ACC_PERIOD_END_DATE': '2021-01-01',
  'dbm.documentation.example.ACC_PERIOD_START_DATE': '2012-01-01',
  'dbm.documentation.example.ACCOUNTANT_NAME': 'KPMG',
  'dbm.documentation.example.ACCOUNTING_PERIOD': '20120101-20121231',
  'dbm.documentation.example.ACTIVITY_CLASSIFICATION_CODE':
    'NL_SBI2008, BE_NACEBEL2008, GB_SIC2007',
  'dbm.documentation.example.ACTIVITY_CODE': '8513',
  'dbm.documentation.example.ACTIVITY_CODE_1': '8513',
  'dbm.documentation.example.ACTIVITY_CODE_2': '8513',
  'dbm.documentation.example.ACTIVITY_CODE_3': '8513',
  'dbm.documentation.example.ACTIVITY_CODE_4': '8513',
  'dbm.documentation.example.ACTIVITY_CODE_5': '8513',
  'dbm.documentation.example.ACTIVITY_DESCRIPTION': 'Transportbedrijf',
  'dbm.documentation.example.ACTIVITY_GROUP_CODE': 'CODE: BE_4',
  'dbm.documentation.example.ACTIVITY_SEQUENCE_NUMBER': '1',
  'dbm.documentation.example.ACTIVITY_START_DATE': '2021-01-01',
  'dbm.documentation.example.ACTIVITY_TYPE_CODE': '1',
  'dbm.documentation.example.ADDRESS_END_DATE': '2021-01-10',
  'dbm.documentation.example.ADDRESS_LINE_1': 'SOUTH RANGE 6 HIGH CARLBURY',
  'dbm.documentation.example.ADDRESS_LINE_1_RECEIVER_1': 'POSTBUS 1300',
  'dbm.documentation.example.ADDRESS_LINE_1_RECEIVER_10': '-',
  'dbm.documentation.example.ADDRESS_LINE_1_RECEIVER_2': 'POSTBUS 1300',
  'dbm.documentation.example.ADDRESS_LINE_1_RECEIVER_3': 'POSTBUS 1300',
  'dbm.documentation.example.ADDRESS_LINE_1_RECEIVER_4': 'POSTBUS 1300',
  'dbm.documentation.example.ADDRESS_LINE_1_RECEIVER_5': 'POSTBUS 1300',
  'dbm.documentation.example.ADDRESS_LINE_1_RECEIVER_6': '-',
  'dbm.documentation.example.ADDRESS_LINE_1_RECEIVER_7': '-',
  'dbm.documentation.example.ADDRESS_LINE_1_RECEIVER_8': '-',
  'dbm.documentation.example.ADDRESS_LINE_1_RECEIVER_9': '-',
  'dbm.documentation.example.ADDRESS_LINE_2': 'PIERCEBRIDGE',
  'dbm.documentation.example.ADDRESS_LINE_2_RECEIVER_1': '3600KJ',
  'dbm.documentation.example.ADDRESS_LINE_2_RECEIVER_10': '-',
  'dbm.documentation.example.ADDRESS_LINE_2_RECEIVER_2': '3600KJ',
  'dbm.documentation.example.ADDRESS_LINE_2_RECEIVER_3': '3600KJ',
  'dbm.documentation.example.ADDRESS_LINE_2_RECEIVER_4': '3600KJ',
  'dbm.documentation.example.ADDRESS_LINE_2_RECEIVER_5': '3600KJ',
  'dbm.documentation.example.ADDRESS_LINE_2_RECEIVER_6': '-',
  'dbm.documentation.example.ADDRESS_LINE_2_RECEIVER_7': '-',
  'dbm.documentation.example.ADDRESS_LINE_2_RECEIVER_8': '-',
  'dbm.documentation.example.ADDRESS_LINE_2_RECEIVER_9': '-',
  'dbm.documentation.example.ADDRESS_LINE_3': 'DARLINGTON',
  'dbm.documentation.example.ADDRESS_LINE_3_RECEIVER_1': 'AMSTERDAM',
  'dbm.documentation.example.ADDRESS_LINE_3_RECEIVER_10': '-',
  'dbm.documentation.example.ADDRESS_LINE_3_RECEIVER_2': 'AMSTERDAM',
  'dbm.documentation.example.ADDRESS_LINE_3_RECEIVER_3': 'AMSTERDAM',
  'dbm.documentation.example.ADDRESS_LINE_3_RECEIVER_4': '-',
  'dbm.documentation.example.ADDRESS_LINE_3_RECEIVER_5': '-',
  'dbm.documentation.example.ADDRESS_LINE_3_RECEIVER_6': '-',
  'dbm.documentation.example.ADDRESS_LINE_3_RECEIVER_7': '-',
  'dbm.documentation.example.ADDRESS_LINE_3_RECEIVER_8': '-',
  'dbm.documentation.example.ADDRESS_LINE_3_RECEIVER_9': '-',
  'dbm.documentation.example.ADDRESS_LINE_4': 'CO DURHAM',
  'dbm.documentation.example.ADDRESS_LINE_4_RECEIVER_1': '-',
  'dbm.documentation.example.ADDRESS_LINE_4_RECEIVER_10': '-',
  'dbm.documentation.example.ADDRESS_LINE_4_RECEIVER_2': '-',
  'dbm.documentation.example.ADDRESS_LINE_4_RECEIVER_3': '-',
  'dbm.documentation.example.ADDRESS_LINE_4_RECEIVER_4': '-',
  'dbm.documentation.example.ADDRESS_LINE_4_RECEIVER_5': '-',
  'dbm.documentation.example.ADDRESS_LINE_4_RECEIVER_6': '-',
  'dbm.documentation.example.ADDRESS_LINE_4_RECEIVER_7': '-',
  'dbm.documentation.example.ADDRESS_LINE_4_RECEIVER_8': '-',
  'dbm.documentation.example.ADDRESS_LINE_4_RECEIVER_9': '-',
  'dbm.documentation.example.ADDRESS_LINE_5': 'UNITED KINGDOM',
  'dbm.documentation.example.ADDRESS_LINE_5_RECEIVER_1': '-',
  'dbm.documentation.example.ADDRESS_LINE_5_RECEIVER_10': '-',
  'dbm.documentation.example.ADDRESS_LINE_5_RECEIVER_2': '-',
  'dbm.documentation.example.ADDRESS_LINE_5_RECEIVER_3': '-',
  'dbm.documentation.example.ADDRESS_LINE_5_RECEIVER_4': '-',
  'dbm.documentation.example.ADDRESS_LINE_5_RECEIVER_5': '-',
  'dbm.documentation.example.ADDRESS_LINE_5_RECEIVER_6': '-',
  'dbm.documentation.example.ADDRESS_LINE_5_RECEIVER_7': '-',
  'dbm.documentation.example.ADDRESS_LINE_5_RECEIVER_8': '-',
  'dbm.documentation.example.ADDRESS_LINE_5_RECEIVER_9': '-',
  'dbm.documentation.example.ADDRESS_LINES': 'TRUE',
  'dbm.documentation.example.ADDRESS_ROLE_CODE': '1',
  'dbm.documentation.example.ADDRESS_START_DATE': '2021-01-10',
  'dbm.documentation.example.ADDRESS_TYPE_CODE': 'PHYSICAL',
  'dbm.documentation.example.AMOUNT_CATEGORY_CODE': 'BE_A',
  'dbm.documentation.example.AMOUNT_CCJ_LAST_12_MONTHS': '-',
  'dbm.documentation.example.AMOUNT_CCJ_LAST_24_MONTHS': '-',
  'dbm.documentation.example.AMOUNT_CCJ_LAST_36_MONTHS': '-',
  'dbm.documentation.example.AMOUNT_CCJ_LAST_48_MONTHS': '-',
  'dbm.documentation.example.AMOUNT_CCJ_LAST_60_MONTHS': '-',
  'dbm.documentation.example.AMOUNT_CCJ_LAST_72_MONTHS': '-',
  'dbm.documentation.example.AMOUNT_UNIT_CODE': '1',
  'dbm.documentation.example.ANNUAL_ACCOUNT_ITEM_CODE': 'NL_AST_20_00_002',
  'dbm.documentation.example.ANNUAL_ACCOUNT_ITEM_VALUE': '53786',
  'dbm.documentation.example.ANNUAL_ACCOUNT_TYPE_CODE': '40',
  'dbm.documentation.example.ANNUAL_REPORT_FILING_DATE': '2021-01-01',
  'dbm.documentation.example.ANNUAL_REPORT_TYPE_CODE': 'NL',
  'dbm.documentation.example.AREA': '5972',
  'dbm.documentation.example.ASSOCIATION_FUNDS': '125000',
  'dbm.documentation.example.AUTHORISED_CAPITAL': '500000',
  'dbm.documentation.example.AVAILABLE_CONTRIBUTION': '125000',
  'dbm.documentation.example.BAG_STATUS_CODE': '2',
  'dbm.documentation.example.BAG_TYPE_CODE': '1',
  'dbm.documentation.example.BALANCE_SHEET_TOTAL': '87000',
  'dbm.documentation.example.BANK_CODE': 'JVBA',
  'dbm.documentation.example.BANK_NAME': 'BANK J VAN BREDA',
  'dbm.documentation.example.BE_BUSINESS_UNIT_NUMBER': '2019802096',
  'dbm.documentation.example.BE_ENTERPRISE_NUMBER': '8123456',
  'dbm.documentation.example.BE_LEGAL_FORM_CODE': '12',
  'dbm.documentation.example.BE_MULTISCORE': '59',
  'dbm.documentation.example.BOARD_TYPE_CODE': '-',
  'dbm.documentation.example.BUILDING': '-',
  'dbm.documentation.example.CALAMITY': '-',
  'dbm.documentation.example.CALAMITY_START_DATE': '-',
  'dbm.documentation.example.CASH': '1',
  'dbm.documentation.example.CCJ_FLAG': '-',
  'dbm.documentation.example.CITY': 'Amsterdam',
  'dbm.documentation.example.CODE_VALUE': 'J (=Ja), NL (=Nederland)',
  'dbm.documentation.example.CONSOLIDATED_FLAG': 'Y',
  'dbm.documentation.example.CONSTRUCTION_YEAR': '1997',
  'dbm.documentation.example.CONTACT_ITEM_ROLE_CODE': '-',
  'dbm.documentation.example.CONTACT_ITEM_TYPE_CODE': 'E-mail, URL, telefoon, mobiel/gsm',
  'dbm.documentation.example.CONTACT_ITEM_VALUE': 'info@graydon.nl',
  'dbm.documentation.example.CONTINUATION_START_DATE': '2021-01-01',
  'dbm.documentation.example.CONTINUATION_TYPE_CODE': '7',
  'dbm.documentation.example.CONTRIBUTION': '125000',
  'dbm.documentation.example.COUNTRY_CODE': 'NL',
  'dbm.documentation.example.COURT': 'Antwerpen',
  'dbm.documentation.example.CREDIT_ADVICE': '2000',
  'dbm.documentation.example.CREDIT_FLAG': 'G',
  'dbm.documentation.example.CURRENCY_CODE': 'EUR',
  'dbm.documentation.example.CURRENT_RATIO': '-',
  'dbm.documentation.example.DATE_LAST_CCJ': '-',
  'dbm.documentation.example.DIRECT_PERCENTAGE': '-',
  'dbm.documentation.example.DISCONTINUATION_PROBABILITY': '9.87',
  'dbm.documentation.example.DISCONTINUATION_SCORE': '3',
  'dbm.documentation.example.DISSOLUTION_DATE': '01/01/2021',
  'dbm.documentation.example.DO_NOT_MAIL_FLAG': 'Y',
  'dbm.documentation.example.DOMAIN_CODE': 'ORGANIZATION_STATUS_CODE, COUNTRY_CODE',
  'dbm.documentation.example.EMAIL': '-',
  'dbm.documentation.example.EMPLOYEE_COUNT_ANNUAL_ACCOUNT': '150',
  'dbm.documentation.example.EMPLOYEE_COUNT_FULL_TIME': '6',
  'dbm.documentation.example.EMPLOYEE_COUNT_TOTAL': '10',
  'dbm.documentation.example.EN_DESCRIPTION': 'Yes (voor code Y)',
  'dbm.documentation.example.END_DATE': '2021-01-01',
  'dbm.documentation.example.END_REASON_CODE': 'NL_FAL_7',
  'dbm.documentation.example.ENTERPRISE_ID': '1234567',
  'dbm.documentation.example.ERC': '3',
  'dbm.documentation.example.EVENT_REFERENCE': '-',
  'dbm.documentation.example.EVENTS_TYPE_CODE': '-',
  'dbm.documentation.example.EXCUSABILITY_FLAG': '-',
  'dbm.documentation.example.EXPORT_FLAG': 'N',
  'dbm.documentation.example.FOUND_AT_CALAMITY': '-',
  'dbm.documentation.example.FOUND_AT_CALAMITY_START_DATE': '-',
  'dbm.documentation.example.FIGURE_VALUE': '88762',
  'dbm.documentation.example.FILED_FOR_ENTERPRISE_ID': '1234567',
  'dbm.documentation.example.FILED_FOR_NAME': 'Graydon Nederland B.V.',
  'dbm.documentation.example.FILED_FOR_NL_KVK_NUMBER': '81234560',
  'dbm.documentation.example.FILING_DATE': '2021-01-01',
  'dbm.documentation.example.FIXED_CAPITAL': '125000',
  'dbm.documentation.example.FOUND_AT_ENTERPRISE_ID': '-',
  'dbm.documentation.example.FOUND_AT_ORG_LEVEL': '-',
  'dbm.documentation.example.FOUNDATION_DATE': '2021-01-01',
  'dbm.documentation.example.FOUNDER_FLAG': 'Y',
  'dbm.documentation.example.FR_DESCRIPTION': 'Oui (voor code Y)',
  'dbm.documentation.example.FREE_TEXT': 'Dit is vrije tekst',
  'dbm.documentation.example.GAZETTE_NUMBER': '-',
  'dbm.documentation.example.GOV_GAZETTE_DATE': '-',
  'dbm.documentation.example.GB_AUGUR_SCORE': '-',
  'dbm.documentation.example.GB_CREDIT_RATING': '-',
  'dbm.documentation.example.GB_CRO_REGISTRATION_NUMBER': '-',
  'dbm.documentation.example.GB_LEGAL_FORM_CODE': '-',
  'dbm.documentation.example.GB_MAXIMUM_CREDIT_GUIDE': '-',
  'dbm.documentation.example.GB_MONTHLY_CREDIT_GUIDE': '-',
  'dbm.documentation.example.GDB_ACCOUNTING_PERIOD': '-',
  'dbm.documentation.example.GENDER_CODE': 'M, F, X',
  'dbm.documentation.example.GENDER_CODE_RECEIVER_1': 'M',
  'dbm.documentation.example.GENDER_CODE_RECEIVER_2': 'M',
  'dbm.documentation.example.GENDER_CODE_RECEIVER_3': 'M',
  'dbm.documentation.example.GOV_GAZETTE_NUMBER': '-',
  'dbm.documentation.example.GOVERNMENT_GAZETTE_CODE': '-',
  'dbm.documentation.example.GRAYDON_RATING': 'AA',
  'dbm.documentation.example.GROSS_MARGIN': '1',
  'dbm.documentation.example.GROSS_SALES_MARGIN': '1',
  'dbm.documentation.example.GROUP_EMPLOYEE_COUNT_FULL_TIME': '9000',
  'dbm.documentation.example.GROUP_EMPLOYEE_COUNT_TOTAL': '10000',
  'dbm.documentation.example.GROWTH_SCORE': '2',
  'dbm.documentation.example.GROWTH_SEGMENT': 'A',
  'dbm.documentation.example.HELD_BY_ORG_ENTERPRISE_ID': 'verouderde vertaalsleutel',
  'dbm.documentation.example.HIGHEST_AMOUNT_CCJ_LAST_12_MONTHS': '-',
  'dbm.documentation.example.HIGHEST_AMOUNT_CCJ_LAST_24_MONTHS': '-',
  'dbm.documentation.example.HIGHEST_AMOUNT_CCJ_LAST_36_MONTHS': '-',
  'dbm.documentation.example.HIGHEST_AMOUNT_CCJ_LAST_48_MONTHS': '-',
  'dbm.documentation.example.HIGHEST_AMOUNT_CCJ_LAST_60_MONTHS': '-',
  'dbm.documentation.example.HIGHEST_AMOUNT_CCJ_LAST_72_MONTHS': '-',
  'dbm.documentation.example.HOUSE_NUMBER': '250',
  'dbm.documentation.example.HOUSE_NUMBER_SUFFIX': '-A',
  'dbm.documentation.example.IBAN': 'Bankrekening of IBAN nummer',
  'dbm.documentation.example.IMPORT_FLAG': 'Y',
  'dbm.documentation.example.INFO_CODE': '-',
  'dbm.documentation.example.INSOLVENCY_DETAIL_CODE': '-',
  'dbm.documentation.example.INSOLVENCY_FORM_NAME': '-',
  'dbm.documentation.example.INSOLVENCY_FREE_TEXT': 'Dit is vrije tekst',
  'dbm.documentation.example.INSOLVENCY_JUDGMENT_TYPE_CODE': '-',
  'dbm.documentation.example.INSOLVENCY_TYPE_CODE': 'NL_1',
  'dbm.documentation.example.INTEREST_SIZE_DESCRIPTION': '-',
  'dbm.documentation.example.INTEREST_TYPE_DESCRIPTION': '-',
  'dbm.documentation.example.IS_ACTIVE_STATUS_CODE': 'Y',
  'dbm.documentation.example.IS_DISSOLVED_STATUS_CODE': 'N',
  'dbm.documentation.example.IS_ECONOMICALLY_ACTIVE_STATUS_CODE': 'N',
  'dbm.documentation.example.IS_INSOLVENT_STATUS_CODE': 'Y',
  'dbm.documentation.example.IS_SOHO': 'Y',
  'dbm.documentation.example.IS_ZZP': 'Y',
  'dbm.documentation.example.ISIC_ACTIVITY_CODE': '15',
  'dbm.documentation.example.ISSUE_DATE': '-',
  'dbm.documentation.example.ISSUED_CAPITAL': '50000',
  'dbm.documentation.example.JUDGMENT_DATE': '2021-01-01',
  'dbm.documentation.example.KEY_FIGURE_YEAR': '2018',
  'dbm.documentation.example.LAST_NL_KVK_SOURCE_UPDATE_DATE': '-',
  'dbm.documentation.example.LATITUDE': '51.30338',
  'dbm.documentation.example.LEGAL_FORM_START_DATE': '2021-01-01',
  'dbm.documentation.example.LEI_NUMBER': '72450056A9WLXUPSI574',
  'dbm.documentation.example.SEARCH_STATUS_DESCRIPTION': '-',
  'dbm.documentation.example.POSITION_MANDATE_AUTHORITY_DESCRIPTION': '-',
  'dbm.documentation.example.SEARCH_ROLE_DESCRIPTION': '-',
  'dbm.documentation.example.POSITION_TYPE_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_2_SEARCH_STATUS_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_2_POSITION_MANDATE_AUTHORITY_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_2_SEARCH_ROLE_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_2_POSITION_TYPE_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_2_POSITION_START_DATE': '-',
  'dbm.documentation.example.LEVEL_2_POSITION_END_DATE': '-',
  'dbm.documentation.example.LEVEL_3_SEARCH_STATUS_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_3_POSITION_MANDATE_AUTHORITY_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_3_SEARCH_ROLE_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_3_POSITION_TYPE_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_3_POSITION_START_DATE': '-',
  'dbm.documentation.example.LEVEL_3_POSITION_END_DATE': '-',
  'dbm.documentation.example.LEVEL_4_SEARCH_STATUS_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_4_POSITION_MANDATE_AUTHORITY_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_4_SEARCH_ROLE_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_4_POSITION_TYPE_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_4_POSITION_START_DATE': '-',
  'dbm.documentation.example.LEVEL_4_POSITION_END_DATE': '-',
  'dbm.documentation.example.LEVEL_5_SEARCH_STATUS_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_5_POSITION_MANDATE_AUTHORITY_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_5_SEARCH_ROLE_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_5_POSITION_TYPE_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_5_POSITION_START_DATE': '-',
  'dbm.documentation.example.LEVEL_5_POSITION_END_DATE': '-',
  'dbm.documentation.example.LEVEL_6_SEARCH_STATUS_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_6_POSITION_MANDATE_AUTHORITY_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_6_SEARCH_ROLE_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_6_POSITION_TYPE_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_6_POSITION_START_DATE': '-',
  'dbm.documentation.example.LEVEL_6_POSITION_END_DATE': '-',
  'dbm.documentation.example.LEVEL_7_SEARCH_STATUS_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_7_POSITION_MANDATE_AUTHORITY_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_7_SEARCH_ROLE_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_7_POSITION_TYPE_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_7_POSITION_START_DATE': '-',
  'dbm.documentation.example.LEVEL_7_POSITION_END_DATE': '-',
  'dbm.documentation.example.LEVEL_8_SEARCH_STATUS_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_8_POSITION_MANDATE_AUTHORITY_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_8_SEARCH_ROLE_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_8_POSITION_TYPE_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_8_POSITION_START_DATE': '-',
  'dbm.documentation.example.LEVEL_8_POSITION_END_DATE': '-',
  'dbm.documentation.example.LEVEL_9_SEARCH_STATUS_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_9_POSITION_MANDATE_AUTHORITY_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_9_SEARCH_ROLE_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_9_POSITION_TYPE_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_9_POSITION_START_DATE': '-',
  'dbm.documentation.example.LEVEL_9_POSITION_END_DATE': '-',
  'dbm.documentation.example.LEVEL_10_SEARCH_STATUS_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_10_POSITION_MANDATE_AUTHORITY_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_10_SEARCH_ROLE_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_10_POSITION_TYPE_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_10_POSITION_START_DATE': '-',
  'dbm.documentation.example.LEVEL_10_POSITION_END_DATE': '-',
  'dbm.documentation.example.LEVEL_11_SEARCH_STATUS_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_11_POSITION_MANDATE_AUTHORITY_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_11_SEARCH_ROLE_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_11_POSITION_TYPE_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_11_POSITION_START_DATE': '-',
  'dbm.documentation.example.LEVEL_11_POSITION_END_DATE': '-',
  'dbm.documentation.example.LEVEL_12_SEARCH_STATUS_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_12_POSITION_MANDATE_AUTHORITY_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_12_SEARCH_ROLE_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_12_POSITION_TYPE_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_12_POSITION_START_DATE': '-',
  'dbm.documentation.example.LEVEL_12_POSITION_END_DATE': '-',
  'dbm.documentation.example.LEVEL_1_ORGANIZATION_NAME': '-',
  'dbm.documentation.example.LEVEL_1_COUNTRY_CODE': '-',
  'dbm.documentation.example.LEVEL_1_COUNTRY': '-',
  'dbm.documentation.example.LEVEL_2_ORGANIZATION_NAME': '-',
  'dbm.documentation.example.LEVEL_2_COUNTRY_CODE': '-',
  'dbm.documentation.example.LEVEL_2_COUNTRY': '-',
  'dbm.documentation.example.LEVEL_3_ORGANIZATION_NAME': '-',
  'dbm.documentation.example.LEVEL_3_COUNTRY_CODE': '-',
  'dbm.documentation.example.LEVEL_3_COUNTRY': '-',
  'dbm.documentation.example.LEVEL_4_ORGANIZATION_NAME': '-',
  'dbm.documentation.example.LEVEL_4_COUNTRY_CODE': '-',
  'dbm.documentation.example.LEVEL_4_COUNTRY': '-',
  'dbm.documentation.example.LEVEL_5_ORGANIZATION_NAME': '-',
  'dbm.documentation.example.LEVEL_5_COUNTRY_CODE': '-',
  'dbm.documentation.example.LEVEL_5_COUNTRY': '-',
  'dbm.documentation.example.LEVEL_6_ORGANIZATION_NAME': '-',
  'dbm.documentation.example.LEVEL_6_COUNTRY_CODE': '-',
  'dbm.documentation.example.LEVEL_6_COUNTRY': '-',
  'dbm.documentation.example.LEVEL_7_ORGANIZATION_NAME': '-',
  'dbm.documentation.example.LEVEL_7_COUNTRY_CODE': '-',
  'dbm.documentation.example.LEVEL_7_COUNTRY': '-',
  'dbm.documentation.example.LEVEL_8_ORGANIZATION_NAME': '-',
  'dbm.documentation.example.LEVEL_8_COUNTRY_CODE': '-',
  'dbm.documentation.example.LEVEL_8_COUNTRY': '-',
  'dbm.documentation.example.LEVEL_9_ORGANIZATION_NAME': '-',
  'dbm.documentation.example.LEVEL_9_COUNTRY_CODE': '-',
  'dbm.documentation.example.LEVEL_9_COUNTRY': '-',
  'dbm.documentation.example.LEVEL_10_ORGANIZATION_NAME': '-',
  'dbm.documentation.example.LEVEL_10_COUNTRY_CODE': '-',
  'dbm.documentation.example.LEVEL_10_COUNTRY': '-',
  'dbm.documentation.example.LEVEL_11_ORGANIZATION_NAME': '-',
  'dbm.documentation.example.LEVEL_11_COUNTRY_CODE': '-',
  'dbm.documentation.example.LEVEL_11_COUNTRY': '-',
  'dbm.documentation.example.LEVEL_12_ORGANIZATION_NAME': '-',
  'dbm.documentation.example.LEVEL_12_COUNTRY_CODE': '-',
  'dbm.documentation.example.LEVEL_12_COUNTRY': '-',
  'dbm.documentation.example.LEVEL_1_CALAMITY': '-',
  'dbm.documentation.example.LEVEL_1_CALAMITY_START_DATE': '-',
  'dbm.documentation.example.LEVEL_2_CALAMITY': '-',
  'dbm.documentation.example.LEVEL_2_CALAMITY_START_DATE': '-',
  'dbm.documentation.example.LEVEL_3_CALAMITY': '-',
  'dbm.documentation.example.LEVEL_3_CALAMITY_START_DATE': '-',
  'dbm.documentation.example.LEVEL_4_CALAMITY': '-',
  'dbm.documentation.example.LEVEL_4_CALAMITY_START_DATE': '-',
  'dbm.documentation.example.LEVEL_5_CALAMITY': '-',
  'dbm.documentation.example.LEVEL_5_CALAMITY_START_DATE': '-',
  'dbm.documentation.example.LEVEL_6_CALAMITY': '-',
  'dbm.documentation.example.LEVEL_6_CALAMITY_START_DATE': '-',
  'dbm.documentation.example.LEVEL_7_CALAMITY': '-',
  'dbm.documentation.example.LEVEL_7_CALAMITY_START_DATE': '-',
  'dbm.documentation.example.LEVEL_8_CALAMITY': '-',
  'dbm.documentation.example.LEVEL_8_CALAMITY_START_DATE': '-',
  'dbm.documentation.example.LEVEL_9_CALAMITY': '-',
  'dbm.documentation.example.LEVEL_9_CALAMITY_START_DATE': '-',
  'dbm.documentation.example.LEVEL_10_CALAMITY': '-',
  'dbm.documentation.example.LEVEL_10_CALAMITY_START_DATE': '-',
  'dbm.documentation.example.LEVEL_11_CALAMITY': '-',
  'dbm.documentation.example.LEVEL_11_CALAMITY_START_DATE': '-',
  'dbm.documentation.example.LEVEL_12_CALAMITY': '-',
  'dbm.documentation.example.LEVEL_12_CALAMITY_START_DATE': '-',
  'dbm.documentation.example.LEVEL_1_ENTERPRISE_ID': '-',
  'dbm.documentation.example.LEVEL_1_NL_KVK_NUMBER': '81234560',
  'dbm.documentation.example.LEVEL_1_SEARCH_ROLE_CODE': '-',
  'dbm.documentation.example.LEVEL_1_SEARCH_STATUS_CODE': '-',
  'dbm.documentation.example.LEVEL_10_DIRECT_PERCENTAGE': '-',
  'dbm.documentation.example.LEVEL_10_ENTERPRISE_ID': '-',
  'dbm.documentation.example.LEVEL_10_INDIRECT_PERCENTAGE_SUBJECT': '-',
  'dbm.documentation.example.LEVEL_10_MANDATE_AUTHORITY_CODE': '-',
  'dbm.documentation.example.LEVEL_10_NL_KVK_NUMBER': '81234560',
  'dbm.documentation.example.LEVEL_10_ORG_ADDRESS_LINE_1': '-',
  'dbm.documentation.example.LEVEL_10_ORG_ADDRESS_LINE_2': '-',
  'dbm.documentation.example.LEVEL_10_ORG_ADDRESS_LINE_3': '-',
  'dbm.documentation.example.LEVEL_10_ORG_ADDRESS_LINE_4': '-',
  'dbm.documentation.example.LEVEL_10_ORG_ADDRESS_LINE_5': '-',
  'dbm.documentation.example.LEVEL_10_ORG_NAME': '-',
  'dbm.documentation.example.LEVEL_10_POSITION_TITLE_CODE': '-',
  'dbm.documentation.example.LEVEL_10_POSITION_TYPE_CODE': '-',
  'dbm.documentation.example.LEVEL_10_ROLE_TYPE_CODE': '-',
  'dbm.documentation.example.LEVEL_10_SEARCH_ROLE_CODE': '-',
  'dbm.documentation.example.LEVEL_10_SEARCH_STATUS_CODE': '-',
  'dbm.documentation.example.LEVEL_10_START_DATE': '2021-01-01',
  'dbm.documentation.example.LEVEL_11_DIRECT_PERCENTAGE': '-',
  'dbm.documentation.example.LEVEL_11_ENTERPRISE_ID': '-',
  'dbm.documentation.example.LEVEL_11_INDIRECT_PERCENTAGE_SUBJECT': '-',
  'dbm.documentation.example.LEVEL_11_MANDATE_AUTHORITY_CODE': '-',
  'dbm.documentation.example.LEVEL_11_NL_KVK_NUMBER': '81234560',
  'dbm.documentation.example.LEVEL_11_ORG_ADDRESS_LINE_1': '-',
  'dbm.documentation.example.LEVEL_11_ORG_ADDRESS_LINE_2': '-',
  'dbm.documentation.example.LEVEL_11_ORG_ADDRESS_LINE_3': '-',
  'dbm.documentation.example.LEVEL_11_ORG_ADDRESS_LINE_4': '-',
  'dbm.documentation.example.LEVEL_11_ORG_ADDRESS_LINE_5': '-',
  'dbm.documentation.example.LEVEL_11_ORG_NAME': '-',
  'dbm.documentation.example.LEVEL_11_POSITION_TITLE_CODE': '-',
  'dbm.documentation.example.LEVEL_11_POSITION_TYPE_CODE': '-',
  'dbm.documentation.example.LEVEL_11_ROLE_TYPE_CODE': '-',
  'dbm.documentation.example.LEVEL_11_SEARCH_ROLE_CODE': '-',
  'dbm.documentation.example.LEVEL_11_SEARCH_STATUS_CODE': '-',
  'dbm.documentation.example.LEVEL_11_START_DATE': '2021-01-01',
  'dbm.documentation.example.LEVEL_12_DIRECT_PERCENTAGE': '-',
  'dbm.documentation.example.LEVEL_12_ENTERPRISE_ID': '-',
  'dbm.documentation.example.LEVEL_12_INDIRECT_PERCENTAGE_SUBJECT': '-',
  'dbm.documentation.example.LEVEL_12_MANDATE_AUTHORITY_CODE': '-',
  'dbm.documentation.example.LEVEL_12_NL_KVK_NUMBER': '81234560',
  'dbm.documentation.example.LEVEL_12_ORG_ADDRESS_LINE_1': '-',
  'dbm.documentation.example.LEVEL_12_ORG_ADDRESS_LINE_2': '-',
  'dbm.documentation.example.LEVEL_12_ORG_ADDRESS_LINE_3': '-',
  'dbm.documentation.example.LEVEL_12_ORG_ADDRESS_LINE_4': '-',
  'dbm.documentation.example.LEVEL_12_ORG_ADDRESS_LINE_5': '-',
  'dbm.documentation.example.LEVEL_12_ORG_NAME': '-',
  'dbm.documentation.example.LEVEL_12_POSITION_TITLE_CODE': '-',
  'dbm.documentation.example.LEVEL_12_POSITION_TYPE_CODE': '-',
  'dbm.documentation.example.LEVEL_12_ROLE_TYPE_CODE': '-',
  'dbm.documentation.example.LEVEL_12_SEARCH_ROLE_CODE': '-',
  'dbm.documentation.example.LEVEL_12_SEARCH_STATUS_CODE': '-',
  'dbm.documentation.example.LEVEL_12_START_DATE': '2021-01-01',
  'dbm.documentation.example.LEVEL_2_DIRECT_PERCENTAGE': '-',
  'dbm.documentation.example.LEVEL_2_ENTERPRISE_ID': '-',
  'dbm.documentation.example.LEVEL_2_INDIRECT_PERCENTAGE_SUBJECT': '-',
  'dbm.documentation.example.LEVEL_2_MANDATE_AUTHORITY_CODE': '-',
  'dbm.documentation.example.LEVEL_2_NL_KVK_NUMBER': '81234560',
  'dbm.documentation.example.LEVEL_2_ORG_ADDRESS_LINE_1': '-',
  'dbm.documentation.example.LEVEL_2_ORG_ADDRESS_LINE_2': '-',
  'dbm.documentation.example.LEVEL_2_ORG_ADDRESS_LINE_3': '-',
  'dbm.documentation.example.LEVEL_2_ORG_ADDRESS_LINE_4': '-',
  'dbm.documentation.example.LEVEL_2_ORG_ADDRESS_LINE_5': '-',
  'dbm.documentation.example.LEVEL_2_ORG_NAME': '-',
  'dbm.documentation.example.LEVEL_2_POSITION_TITLE_CODE': '-',
  'dbm.documentation.example.LEVEL_2_POSITION_TYPE_CODE': '-',
  'dbm.documentation.example.LEVEL_2_ROLE_TYPE_CODE': '-',
  'dbm.documentation.example.LEVEL_2_SEARCH_ROLE_CODE': '-',
  'dbm.documentation.example.LEVEL_2_SEARCH_STATUS_CODE': '-',
  'dbm.documentation.example.LEVEL_2_START_DATE': '2021-01-01',
  'dbm.documentation.example.LEVEL_3_DIRECT_PERCENTAGE': '-',
  'dbm.documentation.example.LEVEL_3_ENTERPRISE_ID': '-',
  'dbm.documentation.example.LEVEL_3_INDIRECT_PERCENTAGE_SUBJECT': '-',
  'dbm.documentation.example.LEVEL_3_MANDATE_AUTHORITY_CODE': '-',
  'dbm.documentation.example.LEVEL_3_NL_KVK_NUMBER': '81234560',
  'dbm.documentation.example.LEVEL_3_ORG_ADDRESS_LINE_1': '-',
  'dbm.documentation.example.LEVEL_3_ORG_ADDRESS_LINE_2': '-',
  'dbm.documentation.example.LEVEL_3_ORG_ADDRESS_LINE_3': '-',
  'dbm.documentation.example.LEVEL_3_ORG_ADDRESS_LINE_4': '-',
  'dbm.documentation.example.LEVEL_3_ORG_ADDRESS_LINE_5': '-',
  'dbm.documentation.example.LEVEL_3_ORG_NAME': '-',
  'dbm.documentation.example.LEVEL_3_POSITION_TITLE_CODE': '-',
  'dbm.documentation.example.LEVEL_3_POSITION_TYPE_CODE': '-',
  'dbm.documentation.example.LEVEL_3_ROLE_TYPE_CODE': '-',
  'dbm.documentation.example.LEVEL_3_SEARCH_ROLE_CODE': '-',
  'dbm.documentation.example.LEVEL_3_SEARCH_STATUS_CODE': '-',
  'dbm.documentation.example.LEVEL_3_START_DATE': '2021-01-01',
  'dbm.documentation.example.LEVEL_4_DIRECT_PERCENTAGE': 'Rechtstreeks percentage',
  'dbm.documentation.example.LEVEL_4_ENTERPRISE_ID': '-',
  'dbm.documentation.example.LEVEL_4_INDIRECT_PERCENTAGE_SUBJECT': '-',
  'dbm.documentation.example.LEVEL_4_MANDATE_AUTHORITY_CODE': '-',
  'dbm.documentation.example.LEVEL_4_NL_KVK_NUMBER': '81234560',
  'dbm.documentation.example.LEVEL_4_ORG_ADDRESS_LINE_1': '-',
  'dbm.documentation.example.LEVEL_4_ORG_ADDRESS_LINE_2': '-',
  'dbm.documentation.example.LEVEL_4_ORG_ADDRESS_LINE_3': '-',
  'dbm.documentation.example.LEVEL_4_ORG_ADDRESS_LINE_4': '-',
  'dbm.documentation.example.LEVEL_4_ORG_ADDRESS_LINE_5': '-',
  'dbm.documentation.example.LEVEL_4_ORG_NAME': '-',
  'dbm.documentation.example.LEVEL_4_POSITION_TITLE_CODE': '-',
  'dbm.documentation.example.LEVEL_4_POSITION_TYPE_CODE': '-',
  'dbm.documentation.example.LEVEL_4_ROLE_TYPE_CODE': '-',
  'dbm.documentation.example.LEVEL_4_SEARCH_ROLE_CODE': '-',
  'dbm.documentation.example.LEVEL_4_SEARCH_STATUS_CODE': '-',
  'dbm.documentation.example.LEVEL_4_START_DATE': '2021-01-01',
  'dbm.documentation.example.LEVEL_5_DIRECT_PERCENTAGE': '-',
  'dbm.documentation.example.LEVEL_5_ENTERPRISE_ID': '-',
  'dbm.documentation.example.LEVEL_5_INDIRECT_PERCENTAGE_SUBJECT': '-',
  'dbm.documentation.example.LEVEL_5_MANDATE_AUTHORITY_CODE': '-',
  'dbm.documentation.example.LEVEL_5_NL_KVK_NUMBER': '81234560',
  'dbm.documentation.example.LEVEL_5_ORG_ADDRESS_LINE_1': '-',
  'dbm.documentation.example.LEVEL_5_ORG_ADDRESS_LINE_2': '-',
  'dbm.documentation.example.LEVEL_5_ORG_ADDRESS_LINE_3': '-',
  'dbm.documentation.example.LEVEL_5_ORG_ADDRESS_LINE_4': '-',
  'dbm.documentation.example.LEVEL_5_ORG_ADDRESS_LINE_5': '-',
  'dbm.documentation.example.LEVEL_5_ORG_NAME': '-',
  'dbm.documentation.example.LEVEL_5_POSITION_TITLE_CODE': '-',
  'dbm.documentation.example.LEVEL_5_POSITION_TYPE_CODE': '-',
  'dbm.documentation.example.LEVEL_5_ROLE_TYPE_CODE': '-',
  'dbm.documentation.example.LEVEL_5_SEARCH_ROLE_CODE': '-',
  'dbm.documentation.example.LEVEL_5_SEARCH_STATUS_CODE': '-',
  'dbm.documentation.example.LEVEL_5_START_DATE': '2021-01-01',
  'dbm.documentation.example.LEVEL_6_DIRECT_PERCENTAGE': '-',
  'dbm.documentation.example.LEVEL_6_ENTERPRISE_ID': '-',
  'dbm.documentation.example.LEVEL_6_INDIRECT_PERCENTAGE_SUBJECT': '-',
  'dbm.documentation.example.LEVEL_6_MANDATE_AUTHORITY_CODE': '-',
  'dbm.documentation.example.LEVEL_6_NL_KVK_NUMBER': '81234560',
  'dbm.documentation.example.LEVEL_6_ORG_ADDRESS_LINE_1': '-',
  'dbm.documentation.example.LEVEL_6_ORG_ADDRESS_LINE_2': '-',
  'dbm.documentation.example.LEVEL_6_ORG_ADDRESS_LINE_3': '-',
  'dbm.documentation.example.LEVEL_6_ORG_ADDRESS_LINE_4': '-',
  'dbm.documentation.example.LEVEL_6_ORG_ADDRESS_LINE_5': '-',
  'dbm.documentation.example.LEVEL_6_ORG_NAME': '-',
  'dbm.documentation.example.LEVEL_6_POSITION_TITLE_CODE': '-',
  'dbm.documentation.example.LEVEL_6_POSITION_TYPE_CODE': '-',
  'dbm.documentation.example.LEVEL_6_ROLE_TYPE_CODE': '-',
  'dbm.documentation.example.LEVEL_6_SEARCH_ROLE_CODE': '-',
  'dbm.documentation.example.LEVEL_6_SEARCH_STATUS_CODE': '-',
  'dbm.documentation.example.LEVEL_6_START_DATE': '2021-01-01',
  'dbm.documentation.example.LEVEL_7_DIRECT_PERCENTAGE': '-',
  'dbm.documentation.example.LEVEL_7_ENTERPRISE_ID': '-',
  'dbm.documentation.example.LEVEL_7_INDIRECT_PERCENTAGE_SUBJECT': '-',
  'dbm.documentation.example.LEVEL_7_MANDATE_AUTHORITY_CODE': '-',
  'dbm.documentation.example.LEVEL_7_NL_KVK_NUMBER': '81234560',
  'dbm.documentation.example.LEVEL_7_ORG_ADDRESS_LINE_1': '-',
  'dbm.documentation.example.LEVEL_7_ORG_ADDRESS_LINE_2': '-',
  'dbm.documentation.example.LEVEL_7_ORG_ADDRESS_LINE_3': '-',
  'dbm.documentation.example.LEVEL_7_ORG_ADDRESS_LINE_4': '-',
  'dbm.documentation.example.LEVEL_7_ORG_ADDRESS_LINE_5': '-',
  'dbm.documentation.example.LEVEL_7_ORG_NAME': '-',
  'dbm.documentation.example.LEVEL_7_POSITION_TITLE_CODE': '-',
  'dbm.documentation.example.LEVEL_7_POSITION_TYPE_CODE': '-',
  'dbm.documentation.example.LEVEL_7_ROLE_TYPE_CODE': '-',
  'dbm.documentation.example.LEVEL_7_SEARCH_ROLE_CODE': '-',
  'dbm.documentation.example.LEVEL_7_SEARCH_STATUS_CODE': '-',
  'dbm.documentation.example.LEVEL_7_START_DATE': '2021-01-01',
  'dbm.documentation.example.LEVEL_8_DIRECT_PERCENTAGE': '-',
  'dbm.documentation.example.LEVEL_8_ENTERPRISE_ID': '-',
  'dbm.documentation.example.LEVEL_8_INDIRECT_PERCENTAGE_SUBJECT': '-',
  'dbm.documentation.example.LEVEL_8_MANDATE_AUTHORITY_CODE': '-',
  'dbm.documentation.example.LEVEL_8_NL_KVK_NUMBER': '81234560',
  'dbm.documentation.example.LEVEL_8_ORG_ADDRESS_LINE_1': '-',
  'dbm.documentation.example.LEVEL_8_ORG_ADDRESS_LINE_2': '-',
  'dbm.documentation.example.LEVEL_8_ORG_ADDRESS_LINE_3': '-',
  'dbm.documentation.example.LEVEL_8_ORG_ADDRESS_LINE_4': '-',
  'dbm.documentation.example.LEVEL_8_ORG_ADDRESS_LINE_5': '-',
  'dbm.documentation.example.LEVEL_8_ORG_NAME': '-',
  'dbm.documentation.example.LEVEL_8_POSITION_TITLE_CODE': '-',
  'dbm.documentation.example.LEVEL_8_POSITION_TYPE_CODE': '-',
  'dbm.documentation.example.LEVEL_8_ROLE_TYPE_CODE': '-',
  'dbm.documentation.example.LEVEL_8_SEARCH_ROLE_CODE': '-',
  'dbm.documentation.example.LEVEL_8_SEARCH_STATUS_CODE': '-',
  'dbm.documentation.example.LEVEL_8_START_DATE': '2021-01-01',
  'dbm.documentation.example.LEVEL_9_DIRECT_PERCENTAGE': '-',
  'dbm.documentation.example.LEVEL_9_ENTERPRISE_ID': '-',
  'dbm.documentation.example.LEVEL_9_INDIRECT_PERCENTAGE_SUBJECT': '-',
  'dbm.documentation.example.LEVEL_9_MANDATE_AUTHORITY_CODE': '-',
  'dbm.documentation.example.LEVEL_9_NL_KVK_NUMBER': '81234560',
  'dbm.documentation.example.LEVEL_9_ORG_ADDRESS_LINE_1': '-',
  'dbm.documentation.example.LEVEL_9_ORG_ADDRESS_LINE_2': '-',
  'dbm.documentation.example.LEVEL_9_ORG_ADDRESS_LINE_3': '-',
  'dbm.documentation.example.LEVEL_9_ORG_ADDRESS_LINE_4': '-',
  'dbm.documentation.example.LEVEL_9_ORG_ADDRESS_LINE_5': '-',
  'dbm.documentation.example.LEVEL_9_ORG_NAME': '-',
  'dbm.documentation.example.LEVEL_9_POSITION_TITLE_CODE': '-',
  'dbm.documentation.example.LEVEL_9_POSITION_TYPE_CODE': '-',
  'dbm.documentation.example.LEVEL_9_ROLE_TYPE_CODE': '-',
  'dbm.documentation.example.LEVEL_9_SEARCH_ROLE_CODE': '-',
  'dbm.documentation.example.LEVEL_9_SEARCH_STATUS_CODE': '-',
  'dbm.documentation.example.LEVEL_9_START_DATE': '2021-01-01',
  'dbm.documentation.example.LIST_MGT_ORG_ID': '-',
  'dbm.documentation.example.LONGITUDE': '5.37337',
  'dbm.documentation.example.MEETING_DATE': '-',
  'dbm.documentation.example.MOBILE': '-',
  'dbm.documentation.example.NACE_ACTIVITY_CODE': '1511',
  'dbm.documentation.example.NAME': 'GRAYDON',
  'dbm.documentation.example.NET_RESULT': '15000',
  'dbm.documentation.example.NL_DESCRIPTION': 'Ja (voor code Y)',
  'dbm.documentation.example.NL_KVK_BRANCH_NUMBER': '987123456789',
  'dbm.documentation.example.NL_KVK_NUMBER': '8123456',
  'dbm.documentation.example.NL_KVK_NUMBER_12': '81234560000',
  'dbm.documentation.example.NL_LEGAL_FORM_CODE': '4',
  'dbm.documentation.example.NL_RSIN': '009031315',
  'dbm.documentation.example.NOMINAL_VALUE': '-',
  'dbm.documentation.example.NOTICE_EVENT_DATE': '-',
  'dbm.documentation.example.NUM_BRANCH_OFFICES': '2',
  'dbm.documentation.example.NUM_CCJ_LAST_12_MONTHS': '-',
  'dbm.documentation.example.NUM_CCJ_LAST_24_MONTHS': '-',
  'dbm.documentation.example.NUM_CCJ_LAST_36_MONTHS': '-',
  'dbm.documentation.example.NUM_CCJ_LAST_48_MONTHS': '-',
  'dbm.documentation.example.NUM_CCJ_LAST_60_MONTHS': '-',
  'dbm.documentation.example.NUM_CCJ_LAST_72_MONTHS': '-',
  'dbm.documentation.example.NUM_EMPLOYEES_SIZE_CLASS_CODE': '-',
  'dbm.documentation.example.NUM_OF_SUPPLIERS': '8',
  'dbm.documentation.example.NUM_PAYMENT_EXPERIENCES': '47',
  'dbm.documentation.example.NUM_SHARES': '-',
  'dbm.documentation.example.ORGANIZATION_ID': '18268053',
  'dbm.documentation.example.ORGANIZATION_NAME': 'Bouwbedrijf Bos',
  'dbm.documentation.example.ORGANIZATION_NAME_TYPE_CODE': 'PRINCIPAL_NAME, SHORT_NAME, enz.',
  'dbm.documentation.example.ORGANIZATION_TYPE_CODE': 'ROOT_ORG',
  'dbm.documentation.example.ORIGIN_CODE': '-',
  'dbm.documentation.example.OTHER_ADDR_LINE_1': 'POSTBUS 1300',
  'dbm.documentation.example.OTHER_ADDR_LINE_2': '3600KJ',
  'dbm.documentation.example.OTHER_ADDR_LINE_3': 'AMSTERDAM',
  'dbm.documentation.example.OTHER_ADDR_LINE_4': 'ZUID OOST',
  'dbm.documentation.example.OTHER_ADDR_LINE_5': '-',
  'dbm.documentation.example.OTHER_BE_ENTERPRISE_NUMBER': '406952018',
  'dbm.documentation.example.OTHER_ENTERPRISE_ID': '1234567',
  'dbm.documentation.example.OTHER_GB_CRO_REGISTRATION_NUMBER': '-',
  'dbm.documentation.example.OTHER_NAME': 'Graydon',
  'dbm.documentation.example.OTHER_NL_KVK_NUMBER': '33189409',
  'dbm.documentation.example.OTHER_ORG_ADDRESS_LINE_1': 'Berg Arrarat 1',
  'dbm.documentation.example.OTHER_ORG_ADDRESS_LINE_2': '-',
  'dbm.documentation.example.OTHER_ORG_ADDRESS_LINE_3': '-',
  'dbm.documentation.example.OTHER_ORG_ADDRESS_LINE_4': 'Willemstad',
  'dbm.documentation.example.OTHER_ORG_ADDRESS_LINE_5': 'Curaçao',
  'dbm.documentation.example.OTHER_ORG_NAME': 'HRO Curacao N.V.',
  'dbm.documentation.example.PAID_UP_CAPITAL': '50000',
  'dbm.documentation.example.PAID_UP_CONTRIBUTION': '125000',
  'dbm.documentation.example.PAID_UP_FIXED_CAPITAL': '125000',
  'dbm.documentation.example.PAID_UP_VARIABLE_CAPITAL': '125000',
  'dbm.documentation.example.PART_ADDR_LINE_1': 'Lopez de Hoyos 15',
  'dbm.documentation.example.PART_ADDR_LINE_2': '3 dcha',
  'dbm.documentation.example.PART_ADDR_LINE_3': '28006',
  'dbm.documentation.example.PART_ADDR_LINE_4': 'Madrid',
  'dbm.documentation.example.PART_ADDR_LINE_5': 'Spain',
  'dbm.documentation.example.PART_BE_ENTERPRISE_NUMBER': '8123456',
  'dbm.documentation.example.PART_ENTERPRISE_ID': '1234567',
  'dbm.documentation.example.PART_NAME': 'Graydon Nederland B.V.',
  'dbm.documentation.example.PART_NL_KVK_NUMBER': '33080348',
  'dbm.documentation.example.PARTNERSHIP_CAPITAL': '1000',
  'dbm.documentation.example.PAYMENT_SCORE': '6.8',
  'dbm.documentation.example.PCT_OVER_90_DBT': '10.2',
  'dbm.documentation.example.PCT_TO_30_DBT': '36.9',
  'dbm.documentation.example.PCT_TO_60_DBT': '38.6',
  'dbm.documentation.example.PCT_TO_90_DBT': '2.4',
  'dbm.documentation.example.PCT_WITHIN_TERMS': '12.1',
  'dbm.documentation.example.PD_PERCENTAGE': '0.22',
  'dbm.documentation.example.PERSON_BIRTH_CITY': 'Amsterdam',
  'dbm.documentation.example.PERSON_BIRTH_COUNTRY_CODE': 'BE',
  'dbm.documentation.example.PERSON_BIRTH_DATE': '1980-01-01',
  'dbm.documentation.example.PERSON_BIRTH_NDATE': '2018-01-01',
  'dbm.documentation.example.PERSON_CITY': 'ANTWERPEN',
  'dbm.documentation.example.PERSON_COUNTRY_CODE': 'BE',
  'dbm.documentation.example.PERSON_DEATH_DATE': 'Algemeen datum type',
  'dbm.documentation.example.PERSON_DEATH_NDATE': '20180101',
  'dbm.documentation.example.PERSON_DETAILS_PROTECTED': '-',
  'dbm.documentation.example.PERSON_FIRST_NAMES': 'Fred Johannes',
  'dbm.documentation.example.PERSON_FULL_NAME': 'Fred van Amerongen',
  'dbm.documentation.example.PERSON_GENDER_CODE': 'F',
  'dbm.documentation.example.PERSON_HOUSE_NUMBER': '84',
  'dbm.documentation.example.PERSON_HOUSE_NUMBER_SUFFIX': '-A',
  'dbm.documentation.example.PERSON_ID': '1000008993',
  'dbm.documentation.example.PERSON_INITIALS': 'AAJ',
  'dbm.documentation.example.PERSON_POSTCODE': '2600',
  'dbm.documentation.example.PERSON_STREET': 'UITBREIDINGSTRAAT',
  'dbm.documentation.example.PERSON_STREET_CODE': '3180',
  'dbm.documentation.example.PERSON_SURNAME': 'Amerongen',
  'dbm.documentation.example.PERSON_SURNAME_PREFIX': 'van',
  'dbm.documentation.example.PERSON_TITLES': 'Drs',
  'dbm.documentation.example.PO_BOX_CITY': 'Amsterdam',
  'dbm.documentation.example.PO_BOX_END_DATE': '2021-01-01',
  'dbm.documentation.example.PO_BOX_NUMBER': '461',
  'dbm.documentation.example.PO_BOX_POSTCODE': '1101BV',
  'dbm.documentation.example.PO_BOX_START_DATE': '2021-01-01',
  'dbm.documentation.example.POSITION_END_DATE': '2021-01-01',
  'dbm.documentation.example.POSITION_HOLDER_ADDR_LINE_1': 'Plaza real 11',
  'dbm.documentation.example.POSITION_HOLDER_ADDR_LINE_2': '28000',
  'dbm.documentation.example.POSITION_HOLDER_ADDR_LINE_3': 'Madrid',
  'dbm.documentation.example.POSITION_HOLDER_ADDR_LINE_4': 'Spain',
  'dbm.documentation.example.POSITION_HOLDER_ADDR_LINE_5': '-',
  'dbm.documentation.example.POSITION_HOLDER_BE_ENTERPRISE_NUMBER': '730124',
  'dbm.documentation.example.POSITION_HOLDER_ENTERPRISE_ID': '1234567',
  'dbm.documentation.example.POSITION_HOLDER_NAME': 'Graydon Nederland B.V.',
  'dbm.documentation.example.POSITION_HOLDER_NL_KVK_NUMBER': '81234560',
  'dbm.documentation.example.POSITION_MANDATE_AUTHORITY_CODE': '2',
  'dbm.documentation.example.POSITION_MANDATE_TEXT':
    'Gezamenlijk bevoegd (met andere bestuurder(s), zie statuten) ',
  'dbm.documentation.example.POSITION_START_DATE': '2021-01-01',
  'dbm.documentation.example.POSITION_TITLE': 'Directeur',
  'dbm.documentation.example.POSITION_TITLE_CODE': '-',
  'dbm.documentation.example.POSITION_TYPE_CODE': 'NL_4',
  'dbm.documentation.example.POSTCODE': '1101BV, 3000, EN3 5BT',
  'dbm.documentation.example.PREFERRED_LANGUAGE_CODE': 'FR, NL, DE',
  'dbm.documentation.example.PRELIMINARY_FLAG': 'N',
  'dbm.documentation.example.PRIMARY_ACTIVITY_CODE_1': '4722',
  'dbm.documentation.example.QUARTER': '2',
  'dbm.documentation.example.QUICK_RATIO': '1',
  'dbm.documentation.example.REASON_CODE': '-',
  'dbm.documentation.example.RECEIVER_1': 'AAJ van Amerongen',
  'dbm.documentation.example.RECEIVER_1_END_DATE': '2021-01-01',
  'dbm.documentation.example.RECEIVER_1_TYPE_CODE': 'NL_1',
  'dbm.documentation.example.RECEIVER_10': '-',
  'dbm.documentation.example.RECEIVER_2': 'AAJ van Amerongen',
  'dbm.documentation.example.RECEIVER_2_END_DATE': '2021-01-01',
  'dbm.documentation.example.RECEIVER_2_TYPE_CODE': 'NL_1',
  'dbm.documentation.example.RECEIVER_3': '-',
  'dbm.documentation.example.RECEIVER_3_END_DATE': '2021-01-01',
  'dbm.documentation.example.RECEIVER_3_TYPE_CODE': 'NL_1',
  'dbm.documentation.example.RECEIVER_4': '-',
  'dbm.documentation.example.RECEIVER_4_TYPE_CODE': 'NL_1',
  'dbm.documentation.example.RECEIVER_5': '-',
  'dbm.documentation.example.RECEIVER_5_TYPE_CODE': 'NL_1',
  'dbm.documentation.example.RECEIVER_6': '-',
  'dbm.documentation.example.RECEIVER_7': '-',
  'dbm.documentation.example.RECEIVER_8': '-',
  'dbm.documentation.example.RECEIVER_9': '-',
  'dbm.documentation.example.RECIEVER': '-',
  'dbm.documentation.example.RECIEVER_TYPE_CODE': 'NL_1',
  'dbm.documentation.example.REFERENCE': 'XYZ',
  'dbm.documentation.example.REGION': 'BE_5_1, BE_5_2, BE_5_3',
  'dbm.documentation.example.RELATION_TYPE_CODE': '1',
  'dbm.documentation.example.REPRESENTATIVE_PERSON_BIRTH_CITY': 'Amsterdam',
  'dbm.documentation.example.REPRESENTATIVE_PERSON_BIRTH_COUNTRY_CODE': 'BE',
  'dbm.documentation.example.REPRESENTATIVE_PERSON_BIRTH_DATE': 'Algemeen datum type',
  'dbm.documentation.example.REPRESENTATIVE_PERSON_BIRTH_NDATE': '20180101',
  'dbm.documentation.example.REPRESENTATIVE_PERSON_CITY': 'ANTWERPEN',
  'dbm.documentation.example.REPRESENTATIVE_PERSON_COUNTRY_CODE': 'BE',
  'dbm.documentation.example.REPRESENTATIVE_PERSON_DEATH_DATE': 'Algemeen datum type',
  'dbm.documentation.example.REPRESENTATIVE_PERSON_DEATH_NDATE': '2021-01-01',
  'dbm.documentation.example.REPRESENTATIVE_PERSON_FIRST_NAMES': 'Fred Johannes',
  'dbm.documentation.example.REPRESENTATIVE_PERSON_FULL_NAME': 'van Amerongen, Fred Johannes',
  'dbm.documentation.example.REPRESENTATIVE_PERSON_GENDER_CODE': 'F',
  'dbm.documentation.example.REPRESENTATIVE_PERSON_HOUSE_NUMBER': '84',
  'dbm.documentation.example.REPRESENTATIVE_PERSON_HOUSE_NUMBER_SUFFIX': '-A',
  'dbm.documentation.example.REPRESENTATIVE_PERSON_ID': '1000008993',
  'dbm.documentation.example.REPRESENTATIVE_PERSON_INITIALS': 'AAJ',
  'dbm.documentation.example.REPRESENTATIVE_PERSON_POSTCODE': '2600',
  'dbm.documentation.example.REPRESENTATIVE_PERSON_STREET': 'UITBREIDINGSTRAAT',
  'dbm.documentation.example.REPRESENTATIVE_PERSON_STREET_CODE': '3180',
  'dbm.documentation.example.REPRESENTATIVE_PERSON_SURNAME': 'Amerongen',
  'dbm.documentation.example.REPRESENTATIVE_PERSON_SURNAME_PREFIX': 'van',
  'dbm.documentation.example.REPRESENTATIVE_PERSON_TITLES': 'Dr.',
  'dbm.documentation.example.RESULT_SEARCH_STATUS_CODE': '-',
  'dbm.documentation.example.ROW_TYPE': 'D (=data), R (=verwijdering)',
  'dbm.documentation.example.RSZ_NUMBER': '88995656',
  'dbm.documentation.example.SBI_ACTIVITY_CODE': '151101',
  'dbm.documentation.example.SEARCH_ROLE_CODE': '-',
  'dbm.documentation.example.SECONDARY_ACTIVITY_CODE_1': '8513',
  'dbm.documentation.example.SECONDARY_ACTIVITY_CODE_2': '8514',
  'dbm.documentation.example.SECONDARY_ACTIVITY_CODE_3': '8515',
  'dbm.documentation.example.SECTOR_CODE': 'A',
  'dbm.documentation.example.SEQUENCE_NUMBER': '-',
  'dbm.documentation.example.SHARE_PERCENTAGE': '100',
  'dbm.documentation.example.SHARE_TYPE_CODE': 'BE_30',
  'dbm.documentation.example.SHAREHOLDER_ADDR_LINE_1': 'Lopez de Hoyos 15',
  'dbm.documentation.example.SHAREHOLDER_ADDR_LINE_2': '3 dcha',
  'dbm.documentation.example.SHAREHOLDER_ADDR_LINE_3': '28006 Madrid',
  'dbm.documentation.example.SHAREHOLDER_ADDR_LINE_4': 'Spain',
  'dbm.documentation.example.SHAREHOLDER_ADDR_LINE_5': '-',
  'dbm.documentation.example.SHAREHOLDER_FUNDS': '123456',
  'dbm.documentation.example.SHAREHOLDER_NAME': 'Graydon Holding N.V.',
  'dbm.documentation.example.SHL_BE_ENTERPRISE_NUMBER': '8123456',
  'dbm.documentation.example.SHL_ENTERPRISE_ID': '302459',
  'dbm.documentation.example.SHL_NL_KVK_NUMBER': '33189409',
  'dbm.documentation.example.SHL_PERSON_ID': '1000008993',
  'dbm.documentation.example.SIZE_CLASS_BE_RSZ_NUM_EMPLOYEES': '2',
  'dbm.documentation.example.SIZE_CLASS_EMPLOYEE_COUNT_FULL_TIME': '3',
  'dbm.documentation.example.SIZE_CLASS_EMPLOYEE_COUNT_TOTAL': '2',
  'dbm.documentation.example.SIZE_CLASS_GROUP_EMPLOYEE_COUNT_FULL_TIME': '11',
  'dbm.documentation.example.SIZE_CLASS_GROUP_EMPLOYEE_COUNT_TOTAL': '12',
  'dbm.documentation.example.SIZE_CLASS_NUM_EMPLOYEES': '1',
  'dbm.documentation.example.SOURCE': 'BE_01',
  'dbm.documentation.example.SOURCE_REF': '20090123/12271',
  'dbm.documentation.example.START_DATE': '2021-01-01',
  'dbm.documentation.example.STREET': 'Hullenbergweg',
  'dbm.documentation.example.STREET_CODE': '3180',
  'dbm.documentation.example.SUM_TOTAL_PERCENTAGE_SUBJECT_ALL': '-',
  'dbm.documentation.example.SUMMONS': '-',
  'dbm.documentation.example.TEL_AREA_CODE': '020',
  'dbm.documentation.example.TEL_COUNTRY_CODE': '0031',
  'dbm.documentation.example.TEL_SUBSCRIBER_NUMBER': '5679999',
  'dbm.documentation.example.TELEPHONE': '-',
  'dbm.documentation.example.TELEPHONE_RECEIVER_1': '010-4400501',
  'dbm.documentation.example.TELEPHONE_RECEIVER_2': '010-4400501',
  'dbm.documentation.example.TELEPHONE_RECEIVER_3': '010-4400501',
  'dbm.documentation.example.TITLES': 'Dr.',
  'dbm.documentation.example.TITLES_RECEIVER_1': 'DRS.',
  'dbm.documentation.example.TITLES_RECEIVER_2': 'DRS.',
  'dbm.documentation.example.TITLES_RECEIVER_3': 'DRS.',
  'dbm.documentation.example.TOTAL_EQUITY': '600000',
  'dbm.documentation.example.TOTAL_PERCENTAGE_SUBJECT': 'Totaal percentage',
  'dbm.documentation.example.TRADE_NAME_1': 'aero',
  'dbm.documentation.example.TRADE_NAME_2': 'aero plus',
  'dbm.documentation.example.TRADE_NAME_3': 'aero float',
  'dbm.documentation.example.TURNOVER': '1000000000',
  'dbm.documentation.example.TYPE_CODE': '1',
  'dbm.documentation.example.UBO_SOURCE': '-',
  'dbm.documentation.example.UBO_SOURCE_KVK_DATE': '-',
  'dbm.documentation.example.UNAVAILABLE_CONTRIBUTION': '125000',
  'dbm.documentation.example.UPDATED_ON': '2021-01-01',
  'dbm.documentation.example.URL': '-',
  'dbm.documentation.example.USE_CLASS_CODE_1': '6',
  'dbm.documentation.example.USE_CLASS_CODE_2': '10',
  'dbm.documentation.example.USE_CLASS_CODE_3': '11',
  'dbm.documentation.example.USE_CLASS_CODE_4': '6',
  'dbm.documentation.example.USE_CLASS_CODE_5': '9',
  'dbm.documentation.example.VARIABLE_CAPITAL': '125000',
  'dbm.documentation.example.VAT_LIABILITY': 'Y, N, null',
  'dbm.documentation.example.VAT_NUMBER': 'NL123456789B01',
  'dbm.documentation.example.VOTING_DATE': '2021-01-01',
  'dbm.documentation.example.WORKING_CAPITAL': '25000',
  'dbm.documentation.example.XSEPTION_CATEGORY_CODE': 'A',
  'dbm.documentation.example.XSEPTION_NDATE_1': '2018',
  'dbm.documentation.example.XSEPTION_NDATE_2': '2019',
  'dbm.documentation.example.XSEPTION_TEXT': '-',
  'dbm.documentation.example.XSEPTION_TYPE_CODE': 'NL_84',
  'dbm.documentation.example.XSEPTION_VALUE_1': '-',
  'dbm.documentation.example.XSEPTION_VALUE_2': '-',
  'dbm.documentation.example.YEAR': '2018',
  'dbm.documentation.example.NL_KVK_NUMBER_CONTINUATION': '-',
  'dbm.documentation.example.NL_LEGAL_FORM_DESCRIPTION': '-',
  'dbm.documentation.example.COUNTRY': '-',
  'dbm.documentation.example.FOUND_AT_NL_KVK_NUMBER': '-',
  'dbm.documentation.example.FOUND_AT_NL_LEGAL_FORM_DESCRIPTION': '-',
  'dbm.documentation.example.PERSON_BIRTH_COUNTRY': '-',
  'dbm.documentation.example.RESULT_SEARCH_STATUS_DESCRIPTION': '-',
  'dbm.documentation.example.INTEREST_TYPE_CODE': '-',
  'dbm.documentation.example.INTEREST_SIZE_CODE': '-',
  'dbm.documentation.example.REGISTRATION_DATE': '-',
  'dbm.documentation.expand-all': 'Alles uitklappen',
  'dbm.documentation.field.description': 'Omschrijving',
  'dbm.documentation.field.example': 'voorbeeld',
  'dbm.documentation.field.length': 'lengte',
  'dbm.documentation.field.level': 'niveau',
  'dbm.documentation.field.name': 'naam',
  'dbm.documentation.field.nullable': 'ongeldig',
  'dbm.documentation.field.ROW_TYPE': 'ROW_TYPE',
  'dbm.documentation.field.type': 'type',
  'dbm.documentation.footer': '-',
  'dbm.documentation.go-back': 'Ga terug',
  'dbm.documentation.print': 'Print documentatie',
  'dbm.documentation.show-documentation': 'Bekijk documentatie',
  'dbm.documentation.title': 'Datablokken documentatie',
  'dbm.enrichedScheduleSet.paragraph': 'De eerste download wordt binnenkort verzonden naar {email}',
  'dbm.enrichedScheduleSet.paragraph.later':
    'De eerste download wordt op {date} verzonden naar {email}',
  'dbm.executions.download-file': 'Download bestand',
  'dbm.executions.downloading-file': 'Downloaden',
  'dbm.executions.endDate': 'Einddatum',
  'dbm.executions.error': 'Fout bij de ophaling van de leveringen',
  'dbm.executions.error.retry': 'Probeer opnieuw',
  'dbm.executions.file-size': 'Bestandsgrootte',
  'dbm.executions.report': 'Bestand',
  'dbm.executions.sequentialNumber': 'Volgnummer',
  'dbm.executions.startDate': 'Startdatum',
  'dbm.executions.status': 'Status',
  'dbm.executions.status.done': 'Afgerond',
  'dbm.executions.status.failed': 'Mislukt',
  'dbm.executions.status.scheduled': 'Ingepland',
  'dbm.executions.status.updating_data': 'Gegevens bijwerken',
  'dbm.executions.status.updating_list': 'Lijst bijwerken',
  'dbm.executions.title': 'Eerdere leveringen',
  'dbm.executions.type': 'Type',
  'dbm.executions.type.delta': 'Veranderingen',
  'dbm.executions.type.full': 'Volledig databestand',
  'dbm.homepage.disabled': 'Uitgeschakeld, geen leveringen gepland',
  'dbm.homepage.noListsAvailable': 'U heeft nog geen lijst met bedrijven',
  'dbm.homepage.scheduleButton': 'Laten we beginnen',
  'dbm.homepage.scheduled.empty.heading': 'U ontvangt nog geen permanente updates!',
  'dbm.homepage.scheduled.empty.text': 'Dit betekent dat uw database niet up-to-date is',
  'dbm.homepage.scheduled.heading': 'Permanente verrijkingen van uw database',
  'dbm.homepage.schedules.addAnother': 'Voeg een nieuwe lijst toe aan uw continue updates',
  'dbm.homepage.schedules.interval': 'Elke {num, plural, =1 {day} other {{num} days}}',
  'dbm.homepage.single.file': 'Verrijk uw bestand',
  'dbm.homepage.single.heading': 'Eenmalige verrijking',
  'dbm.homepage.single.list': 'Verrijk en download een lijst',
  'dbm.homepage.single.text':
    'Hebt u nu de gegevens nodig? Verrijk dan eenmalig uw bestand of lijst.',
  'dbm.homepage.startEnrichmentButton': 'Begin met de verrijking',
  'dbm.homepage.title': 'Dataverrijking',
  'dbm.important.stuff': 'prima',
  'dbm.importCompaniesLink': 'Importeer bedrijven',
  'dbm.importCompaniesLink.right': '(exits Databasemanagement)',
  'dbm.profile-overview.add-new-profile': 'Voeg nieuw profiel toe',
  'dbm.profile-overview.no-creation-access':
    'U heeft geen toelating om een ​​profiel aan te maken. Neem contact op met de databasemanager en vraag om een ​​profiel aan te maken.',
  'dbm.profile-overview.no-profile': 'U heeft nog geen profiel aangemaakt voor databasemanagement.',
  'dbm.profile-overview.no-profile.restricted':
    'U heeft geen profiel aangemaakt voor databasemanagement.',
  'dbm.profile-overview.note': 'Let op',
  'dbm.profile-overview.title': 'Profieloverzicht',
  'dbm.profileOverview.prevDeliveries.hide': 'Verberg eerdere leveringen',
  'dbm.profileOverview.prevDeliveries.view': 'Bekijk eerdere leveringen',
  'dbm.scheduleEnrichmentForm.heading': 'Houd uw database up-to-date',
  'dbm.scheduleEnrichmentForm.schedule.daysStarting': 'dagen, vanaf',
  'dbm.scheduleEnrichmentForm.schedule.every': 'Elke',
  'dbm.scheduleEnrichmentForm.schedule.heading': 'U ontvangt een e-mail met nieuwe gegevens',
  'dbm.scheduleEnrichmentForm.schedule.info': 'U krijgt de updates op {email}',
  'dbm.scheduleEnrichmentForm.selectData.explanation':
    'Hoe meer gegevens u kiest, hoe waardevoller uw database.',
  'dbm.scheduleEnrichmentForm.selectData.heading':
    'Welke gegevens moeten we toevoegen aan deze bedrijven?',
  'dbm.scheduleEnrichmentForm.selectList.heading': 'Welke bedrijven staan in uw database?',
  'dbm.scheduleEnrichmentForm.start': 'Start',
  'dbm.scheduleList.deleteSchedule': 'Permanente verrijking verwijderen',
  'dbm.scheduleList.deleteSchedule.message':
    'Weet u zeker dat u de permanente verrijking wilt verwijderen van "{tagName}"?',
  'dbm.scheduleList.deleteSchedule.message2': 'U kunt dit later altijd opnieuw invoeren.',
  'dbm.update-profile.dbm-disabled-for-this-country':
    'Let op: Database Management is uitgeschakeld voor dit land. Databestanden zullen **niet** geleverd worden.',
  'dbm.update-profile.error.message': 'Er is een fout opgetreden bij de wijziging van het profiel',
  'dbm.update-profile.error.title': 'Fout bij de wijziging van het profiel',
  'dbm.update-profile.success.message': 'Het profiel werd succesvol bijgewerkt',
  'dbm.update-profile.success.message.new-blocks-added':
    '**Let op:** U heeft een nieuw datablok toegevoegd. De eerstvolgende levering zal alle data voor het nieuw toegevoegde datablok bevatten.',
  'dbm.update-profile.success.title': 'Profiel succesvol bijgewerkt',
  'dbm.update-profile.title': 'Pas profiel aan',
  'dbm.view-profile.profile-id': 'Profiel ID (voor API gebruik)',
  'dbm.view-profile.profile-id.tooltip':
    'Gebruik dit ID om data van het juiste database management profiel te vinden.',
  'dbm.view-profile.send-single-delivery': 'Bereid nu eenmalige levering voor',
  'dbm.view-profile.send-single-delivery.desc': 'gebaseerd op dit profiel',
  'dbm.view-profile.single-delivery': 'Eenmalige levering',
  'dbm.view-profile.single-delivery.failed': 'Extra levering kan niet worden voorbereid',
  'dbm.view-profile.single-delivery.failed.desc':
    'Ofwel is er geen bedrijvenlijst geselecteerd in dit profiel of de data kan niet opgehaald worden. Selecteer een bedrijvenlijst of probeer het later opnieuw.',
  'dbm.view-profile.single-delivery.success': 'Extra levering wordt voorbereid',
  'dbm.view-profile.single-delivery.success.desc':
    "Zodra de data is opgehaald, zal het databestand geleverd worden. Bekijk het overzicht 'Eerdere leveringen' in dit profiel om het bestand te downloaden.",
  'dbm.view-profile.single-delivery.tooltip':
    'Gebruik deze link om te starten met een manuele opvraging van extra data gebaseerd op de bewaarde profielinstellingen. Zodra de data is opgehaald, zal het bestand beschikbaar zijn in de lijst met eerdere leveringen.',
  'dbm.view-profile.title': 'Profiel tonen',
  'de.dataOverview.continue': 'Ga verder',
  'de.dataOverview.heading': 'Laten we eerst de data kiezen die u wenst',
  'de.downloadUpdatedFile': 'Download verrijkt bestand',
  'de.homePage.continue': 'Start verrijking',
  'de.homePage.heading': 'Dataverrijking',
  'de.homePage.step1.heading': '1. Kies de data die u wenst',
  'de.homePage.step1.text': 'Dit zal aan uw bestand toegevoegd worden in nieuwe kolommen',
  'de.homePage.step2.heading': '2. Importeer uw bestand',
  'de.homePage.step2.li1': 'Upload uw bestand',
  'de.homePage.step2.li2': 'Breng uw velden in kaart',
  'de.homePage.step2.li3': 'Koppel met Graydon data',
  'de.homePage.step3.heading': '3. Download verrijkt bestand',
  'de.homePage.step3.text': 'We veranderen nooit uw originele data',
  'de.listEnrichment.downloadButton': 'Download verrijkte list',
  'dm.acceptance.label': ' ',
  'dm.acceptance.legend': ' ',
  'dm.accordion.contact': 'Algemene bedrijfsgegevens',
  'dm.accordion.financials': 'Jaarrekeningen',
  'dm.accordion.ginsights': 'Graydon Insights',
  'dm.accordion.management': 'Management',
  'dm.accordion.relation': 'Bedrijfsstructuur',
  'dm.balanced.lede':
    'Met een flexibeler acceptatiebeleid kunt u nog altijd groei realiseren, winst verhogen en het risico op afschrijvingen beperken.',
  'dm.balanced.title': 'Gebalanceerd',
  'dm.createModel.header': 'Aan de slag',
  'dm.createModel.instruction':
    'Welkom bij de set-up. Voordat u klanten kunt accepteren, maakt u eerst een beslismodel aan. Geen nood, het is in drie stappen klaar. Kies een land, kies een model, personaliseer het of sla het meteen op.',
  'dm.createModel.subHeader': 'Laten we beginnen met een land te kiezen',
  'dm.credit.check.error':
    'Deze informatie is helaas niet online beschikbaar.<br /><br />Onze afdeling Customer Care helpt u graag verder, via <a href="mailto:{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}">{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}</a> of {country, select, gb {+44 (020) 8515 1400} nl {+31 (020) 567 9501} other {+32 (03) 280 88 80}}.',
  'dm.defensive.lede':
    'Dit model is geschikt wanneer u weinig risico wilt lopen en afschrijvingen tot een minimum wilt beperken.',
  'dm.defensive.title': 'Defensief',
  'dm.delete.confirm.desc':
    'U staat op het punt een beslismodel te verwijderen. Dit is onomkeerbaar.',
  'dm.delete.confirm.no': 'Nee ',
  'dm.delete.confirm.question': 'Bent u zeker?',
  'dm.delete.confirm.title': 'Wacht eens even!',
  'dm.delete.confirm.yes': 'Ja',
  'dm.editor.accept': 'Accepteren',
  'dm.editor.annualAccountAvailable': 'Jaarrekening?',
  'dm.editor.annualAccountAvailable.desc':
    "Hier kunt u de condities voor het Checkpoint Beschikbaarheid Jaarrekening instellen. U kunt dit lezen als 'Als, Dan statements'. 'Als' aan de conditie wordt voldaan, 'Dan' wordt de bijbehorende vervolgactie genomen.",
  'dm.editor.annualAccountAvailable.title': 'Checkpoint: is de jaarrekening beschikbaar?',
  'dm.editor.augurScore': 'Augur score',
  'dm.editor.augurScore-1': 'augur 1',
  'dm.editor.augurScore-2': 'augur 2',
  'dm.editor.augurScore-3': 'augur 3',
  'dm.editor.augurScore.desc':
    "Hier kunt u de condities voor het Checkpoint Augur Score instellen. U kunt dit lezen als 'Als, Dan statements'. 'Als' aan de conditie wordt voldaan, 'Dan' wordt de bijbehorende vervolgactie genomen.",
  'dm.editor.augurScore.title': 'Checkpoint: Augur score',
  'dm.editor.be.creditLimit.desc':
    "Hier kunt u de condities voor het Checkpoint Graydon kredietlimiet instellen. U kunt dit lezen als 'Als, Dan statements'. 'Als' aan de conditie wordt voldaan, 'Dan' wordt de bijbehorende vervolgactie genomen. De 'Orderwaarde' wordt per check ingevuld.",
  'dm.editor.be.multiscore.threshold.invalid': 'De waarde moet tussen 0 en 100 liggen',
  'dm.editor.ccj.description': ' ',
  'dm.editor.ccjCount': 'Aantal vonnissen van het County Court',
  'dm.editor.ccjCount.desc':
    "Hier kunt u de condities voor het Checkpoint Aantal vonnissen van het County Court instellen. U kunt dit lezen als 'Als, Dan statements'. 'Als' aan de conditie wordt voldaan, 'Dan' wordt de bijbehorende vervolgactie genomen.",
  'dm.editor.ccjCount.title': 'Checkpoint: Aantal vonnissen van het County Court',
  'dm.editor.companyActive': 'Bedrijf actief?',
  'dm.editor.companyActive.desc':
    "Hier kunt u de condities voor het Checkpoint Bedrijf actief instellen. U kunt dit lezen als 'Als, Dan statements'. 'Als' aan de conditie wordt voldaan,'Dan' wordt de bijbehorende vervolgactie genomen.",
  'dm.editor.companyActive.title': 'Checkpoint: is het bedrijf actief? ',
  'dm.editor.companyAge': 'Leeftijd bedrijf',
  'dm.editor.companyAge.desc':
    "Hier kunt u de condities voor het Checkpoint Leeftijd bedrijf instellen. U kunt dit lezen als 'Als, Dan statements'. 'Als' aan de conditie wordt voldaan, 'Dan' wordt de bijbehorende vervolgactie genomen.",
  'dm.editor.companyAge.months': 'maanden',
  'dm.editor.companyAge.title': 'Checkpoint: Leeftijd bedrijf',
  'dm.editor.companyIncorporated': 'Company Incorporated',
  'dm.editor.companyIncorporated.desc':
    "Hier kunt u de condities voor het Checkpoint Bedrijf Incorporated instellen. U kunt dit lezen als 'Als, Dan statements'. 'Als' aan de conditie wordt voldaan, 'Dan' wordt de bijbehorende vervolgactie genomen.",
  'dm.editor.companyIncorporated.title': 'Checkpoint: Bedrijf Incorporated?',
  'dm.editor.companyName': 'Bedrijfsnaam',
  'dm.editor.companyRegisterNumber': 'Registratienummer',
  'dm.editor.credit.limit': 'Orderwaarde',
  'dm.editor.credit.limit.zero': 'Kredietlimiet = {currency} 0',
  'dm.editor.creditLimit': 'Kredietlimiet',
  'dm.editor.creditLimit.desc':
    "Hier kunt u de condities voor het Checkpoint Graydon kredietlimiet instellen. U kunt dit lezen als 'Als, Dan statements'. 'Als' aan de conditie wordt voldaan, 'Dan' wordt de bijbehorende vervolgactie genomen. De 'Orderwaarde' wordt per check ingevuld.",
  'dm.editor.creditLimit.threshold.invalid': 'De waarde moet tussen 0 en 12 liggen',
  'dm.editor.creditLimit.title': 'Checkpoint: Graydon kredietlimiet',
  'dm.editor.creditRating': 'Kredietrating ',
  'dm.editor.creditRating.desc':
    "Hier kunt u de condities voor het Checkpoint Kredietrating instellen. U kunt dit lezen als 'Als, Dan statements'. 'Als' aan de conditie wordt voldaan, 'Dan' wordt de bijbehorende vervolgactie genomen.",
  'dm.editor.creditRating.outcome.above': 'Kredietrating',
  'dm.editor.creditRating.outcome.below': 'Kredietrating',
  'dm.editor.creditRating.title': 'Checkpoint: Kredietrating',
  'dm.editor.deny': 'Afwijzen',
  'dm.editor.error': 'De waarde moet tussen {minValue} en {maxValue} liggen.',
  'dm.editor.esc.to.close': 'Sluiten zonder opslaan',
  'dm.editor.help.annualAccountAvailable.condition':
    'De beschikbaarheid van een jaarrekening heeft invloed op de Multiscore en de Kredietlimiet.',
  'dm.editor.help.annualAccountAvailable.decision':
    'Kies de gewenste vervolgactie. Voeg een omschrijving toe om de uitkomst te personaliseren. Deze omschrijving komt terug in check resultaten.',
  'dm.editor.help.augurScore.condition':
    'De Augur Score is een indicator van financiële gezondheid. Hoe hoger de score, hoe lager het risico. Zorg ervoor dat de ingevulde waardes op elkaar aansluiten.',
  'dm.editor.help.augurScore.decision':
    'Kies de gewenste vervolgactie. Voeg een omschrijving toe om de uitkomst te personaliseren. Deze omschrijving komt terug in check resultaten.',
  'dm.editor.help.ccjCount.condition':
    'CCJs of vonnissen van het County Court zijn juridische beslissingen van Engelse rechtbanken. Stel het maximum toegestane aantal in. Vul dezelfde waarde in bij beide condities.',
  'dm.editor.help.ccjCount.decision':
    'Kies de gewenste vervolgactie. Voeg een omschrijving toe om de uitkomst te personaliseren. Deze omschrijving komt terug in check resultaten.',
  'dm.editor.help.companyActive.condition':
    "De start van elk model is een check of het bedrijf actief is. Bedrijven die opgeheven of failliet zijn worden aangeduid als 'Inactief'.",
  'dm.editor.help.companyActive.decision':
    'Kies de gewenste vervolgactie. Voeg een omschrijving toe om de uitkomst te personaliseren. Deze omschrijving komt terug in check resultaten.',
  'dm.editor.help.companyAge.condition':
    'Kies de leeftijd van het bedrijf in aantal maanden. Zorg ervoor dat de waardes in beide condities matchen.',
  'dm.editor.help.companyAge.decision':
    'Kies de gewenste vervolgactie. Voeg een omschrijving toe om de uitkomst te personaliseren. Deze omschrijving komt terug in check resultaten.',
  'dm.editor.help.companyIncorporated.condition':
    'Een bedrijf is Incorporated (Y) indien sprake is van een rechtspersoon; Unincorporated (N) als geen sprake is van een rechtspersoon.',
  'dm.editor.help.companyIncorporated.decision':
    'Kies de gewenste vervolgactie. Voeg een omschrijving toe om de uitkomst te personaliseren. Deze omschrijving komt terug in check resultaten.',
  'dm.editor.help.creditLimit.condition':
    'De orderwaarde wordt vergeleken met de Graydon kredietlimiet. U kunt een multiplier gebruiken om de Graydon kredietlimiet aan te passen.',
  'dm.editor.help.creditLimit.decision':
    'Kies de gewenste vervolgactie. Voeg een omschrijving toe om de uitkomst te personaliseren. Deze omschrijving komt terug in check resultaten.',
  'dm.editor.help.creditRating': 'Lorem ipsum dolor sit amet',
  'dm.editor.help.creditRating.condition':
    "De Kredietrating is 'X' bij een verhoogd risico op het bedrijf. Als de Rating anders dan 'X' is, raden we aan om door te gaan naar de Augur Score.",
  'dm.editor.help.creditRating.decision':
    'Kies de gewenste vervolgactie. Voeg een omschrijving toe om de uitkomst te personaliseren. Deze omschrijving komt terug in check resultaten.',
  'dm.editor.help.multiscore.condition':
    'De Multiscore beoordeelt kansen en risico’s voor een onderneming. Hoe hoger de score, hoe groter het potentieel en hoe lager het risico. Zorg ervoor dat de waardes in beide condities overeenkomen.',
  'dm.editor.help.multiscore.decision':
    'Kies de gewenste vervolgactie. Voeg een omschrijving toe om de uitkomst te personaliseren. Deze omschrijving komt terug in check resultaten.',
  'dm.editor.help.orderValue.condition':
    'Als een orderwaarde boven de hier ingestelde waarde ligt, kunt u de check doorverwijzen. Vul dezelfde orderwaarde in bij beide condities.',
  'dm.editor.help.orderValue.decision':
    'Kies de gewenste vervolgactie. Voeg een omschrijving toe om de uitkomst te personaliseren. Deze omschrijving komt terug in check resultaten.',
  'dm.editor.help.payments.condition':
    "Het betaalgedrag wordt uitgedrukt als 'Goed' en 'Slecht'. 'Goed': er zijn positieve ervaringen; 'Slecht': er zijn negatieve ervaringen.",
  'dm.editor.help.payments.decision':
    'Kies de gewenste vervolgactie. Voeg een omschrijving toe om de uitkomst te personaliseren. Deze omschrijving komt terug in check resultaten.',
  'dm.editor.help.paymentsScore.condition':
    "Het betaalgedrag wordt uitgedrukt in een cijfer tussen '1' en '10', waarbij '10' het hoogst haalbare is.",
  'dm.editor.help.paymentsScore.decision':
    'Kies de gewenste vervolgactie. Voeg een omschrijving toe om de uitkomst te personaliseren. Deze omschrijving komt terug in check resultaten.',
  'dm.editor.help.probabilityOfDefault.condition':
    'De kans op wanbetaling is een percentage. Hoe hoger het PD%, hoe hoger de kans op wanbetaling. Bij ontbrekende waardes staat er Not Rated .',
  'dm.editor.help.probabilityOfDefault.decision':
    'Kies de gewenste vervolgactie. Voeg een omschrijving toe om de uitkomst te personaliseren. Deze omschrijving komt terug in check resultaten.',
  'dm.editor.help.tree.view':
    "Deze pagina toont u het beslismodel in een boomstructuur. Als u een check uitvoert, komt het bedrijf er vanaf de linkerkant in. De blauwe vierkantjes zijn checkpoints, waarbij de data van het bedrijf wordt vergeleken met de ingestelde condities, zodat er een vervolgactie in werking treedt. De witte vierkantjes geven de condities weer. Het bedrijf kan doorgaan naar een volgend checkpoint, of eindigen in een uitkomst. De uitkomsten worden getoond als groene vierkantjes en bestaan uit 3 categorieën: Accepteren, Doorverwijzen en Afwijzen. Klik op een checkpoint om de condities en vervolgacties van die check aan te passen. Als u klaar bent, klik dan rechtsboven op 'Opslaan en sluiten' om het model te gebruiken.",
  'dm.editor.isCompanyIncorporated': 'Company Incorporated?',
  'dm.editor.multiscore': 'Multiscore',
  'dm.editor.multiscore.desc':
    "Hier kunt u de condities voor het Checkpoint Multiscore instellen. U kunt dit lezen als 'Als, Dan statements'. 'Als' aan de conditie wordt voldaan,'Dan' wordt de bijbehorende vervolgactie genomen.",
  'dm.editor.multiscore.title': 'Checkpoint: Multiscore',
  'dm.editor.nameEditor.err': 'Dit is geen valide naam',
  'dm.editor.nameEditor.placeholder': 'Geef uw model een naam',
  'dm.editor.next.checkpoint': 'Ga naar volgende checkpoint',
  'dm.editor.nl.creditLimit.desc':
    "Hier kunt u de condities voor het Checkpoint Betaalgedrag instellen. U kunt dit lezen als 'Als, Dan statements'. 'Als' aan de conditie wordt voldaan, 'Dan' wordt de bijbehorende vervolgactie genomen",
  'dm.editor.nl.paymentsScore.desc':
    "Hier kunt u de condities voor het Checkpoint Betaalgedrag instellen. U kunt dit lezen als 'Als, Dan statements'. 'Als' aan de conditie wordt voldaan, 'Dan' wordt de bijbehorende vervolgactie genomen",
  'dm.editor.nl.probabilityOfDefault.threshold.invalid': 'De waarde moet tussen 0.1 en 20 liggen',
  'dm.editor.operator.nr': 'Niet beoordeeld',
  'dm.editor.orderValue': 'orderwaarde',
  'dm.editor.orderValue.desc':
    "Hier kunt u de condities voor het Checkpoint Orderwaarde instellen. U kunt dit lezen als 'Als, Dan statements'. 'Als' aan de conditie wordt voldaan, 'Dan' wordt de bijbehorende vervolgactie genomen.",
  'dm.editor.orderValue.k': ' ',
  'dm.editor.orderValue.title': 'Orderwaarde',
  'dm.editor.outcome.description': 'Uitkomst omschrijving',
  'dm.editor.outcomes': 'Ga naar een uitkomst',
  'dm.editor.payments': 'Betaalgedrag',
  'dm.editor.payments.desc':
    "Hier kunt u de condities voor het Checkpoint Betaalgedrag instellen. U kunt dit lezen als 'Als, Dan statements'. 'Als' aan de conditie wordt voldaan, 'Dan' wordt de bijbehorende vervolgactie genomen",
  'dm.editor.payments.title': 'Checkpoint: Betaalgedrag',
  'dm.editor.paymentsScore': 'Betaalgedrag',
  'dm.editor.paymentsScore.desc': 'Pas de drempel aan voor betaalscore.',
  'dm.editor.paymentsScore.title': 'Betaalscore',
  'dm.editor.pd.nr': 'Kans op wanbetaling (PD)= “Not Rated”',
  'dm.editor.probabilityOfDefault': 'Kans op wanbetaling (PD)',
  'dm.editor.probabilityOfDefault.desc':
    "Hier kunt u de condities voor het Checkpoint Kans op wanbetaling instellen. U kunt dit lezen als 'Als, Dan statements'. 'Als' aan de conditie wordt voldaan,'Dan' wordt de bijbehorende vervolgactie genomen.",
  'dm.editor.probabilityOfDefault.desc.nl':
    "Heeft u hulp nodig met het instellen van een PD-percentage? Bekijk in de <a href='https://connect.graydon-global.com/rs/934-AQC-614/images/NL PD Sheet.pdf' target='_blank' rel='noopener noreferrer'>sector data tabel (pdf)</a> hoeveel bedrijven u zult accepteren per sector op basis van het PD-percentage. Let op: Dit is een momentopname en slechts een richtlijn.",
  'dm.editor.probabilityOfDefault.title': 'Checkpoint: Kans op wanbetaling (PD%)',
  'dm.editor.refer': 'Doorverwijzen',
  'dm.editor.setup': 'Decision model set up',
  'dm.editor.totalStaffEmployed': 'Totaal aantal werknemers',
  'dm.firstrun.cta': 'Maak een beslismodel',
  'dm.firstrun.lede':
    'Welkom bij de set-up. Voordat u klanten kan accepteren, maakt u eerst een beslismodel aan. Geen zorgen, het is in drie stappen klaar.',
  'dm.firstrun.next.steps': ' ',
  'dm.firstrun.step1': 'Stap 1. Kies een land;',
  'dm.firstrun.step2': 'Stap 2. Kies een model;',
  'dm.firstrun.step3': 'Stap 3. Personaliseer het model.',
  'dm.firstrun.title': 'Aan de slag',
  'dm.label.client.acceptance': 'Klantacceptatie',
  'dm.label.debt.prevention': 'Afschrijvingen voorkomen',
  'dm.management.action.activate': 'activeer',
  'dm.management.action.deactivate': 'deactiveer',
  'dm.management.action.delete': 'verwijder',
  'dm.management.action.edit': 'wijzig',
  'dm.management.create': 'Maak een nieuw model',
  'dm.management.title': 'Model management',
  'dm.multiselect.all-models': 'Alle beslismodellen',
  'dm.multiselect.n-models': '{n} beslismodellen geselecteerd',
  'dm.offensive.lede':
    'Streeft u groei na en bent u bereid meer risico te nemen? Dan is dit uw model.',
  'dm.offensive.title': 'Offensief',
  'dm.pod.tolerance.accept': '<b>accepteren</b>',
  'dm.pod.tolerance.deny': '<b>afwijzen</b>',
  'dm.pod.tolerance.if': 'Als <b>kans op wanbetaling</b>',
  'dm.pod.tolerance.label': 'Jaarlijks wil ik niet meer van mijn omzet afschrijven dan…',
  'dm.run.backtosearch': 'Terug naar zoekresultaten',
  'dm.run.choosemodel': 'Kies bestaand beslismodel',
  'dm.run.ordervalue': 'Vul hier de orderwaarde in',
  'dm.run.result.bucket-accept': 'Accepteren',
  'dm.run.result.bucket-deny': 'Afwijzen',
  'dm.run.result.bucket-refer': 'Doorverwijzen',
  'dm.run.result.bucket-undefined': 'Nog niet gedefinieerd',
  'dm.run.result.checkpoint.be.annualAccountAvailable': 'Jaarrekening beschikbaar?',
  'dm.run.result.checkpoint.be.annualAccountAvailable.threshold.label': 'Drempelwaarde',
  'dm.run.result.checkpoint.be.multiscore': 'Multiscore',
  'dm.run.result.checkpoint.be.multiscore.threshold.label': 'Drempelwaarde',
  'dm.run.result.checkpoint.be.paymentsScore': 'Betaalscore',
  'dm.run.result.checkpoint.be.paymentsScore.threshold.label': 'Drempelwaarde',
  'dm.run.result.checkpoint.companyActive': 'Actief bedrijf?',
  'dm.run.result.checkpoint.companyActive.threshold.label': 'Drempelwaarde',
  'dm.run.result.checkpoint.creditLimit': 'Kredietlimiet',
  'dm.run.result.checkpoint.creditLimit.threshold.label': 'Orderwaarde',
  'dm.run.result.checkpoint.failed.description': 'Resultaat kon niet worden bepaald',
  'dm.run.result.checkpoint.failed.label': 'Resultaat',
  'dm.run.result.checkpoint.gb.augurScore': 'Augur Score',
  'dm.run.result.checkpoint.gb.augurScore.threshold.label': 'Drempelwaarde',
  'dm.run.result.checkpoint.gb.ccjCount': 'Aantal vonnissen van het County Court',
  'dm.run.result.checkpoint.gb.ccjCount.threshold.label': 'Drempelwaarde',
  'dm.run.result.checkpoint.gb.companyAge': 'Ouderdom bedrijf',
  'dm.run.result.checkpoint.gb.companyAge.threshold.label': 'Drempelwaarde',
  'dm.run.result.checkpoint.gb.companyIncorporated': 'Bedrijf incorporated?',
  'dm.run.result.checkpoint.gb.companyIncorporated.threshold.label': 'Drempelwaarde',
  'dm.run.result.checkpoint.gb.creditRating': 'Kredietrating',
  'dm.run.result.checkpoint.gb.creditRating.threshold.label': 'Drempelwaarde',
  'dm.run.result.checkpoint.gb.orderValue': 'Orderwaarde',
  'dm.run.result.checkpoint.gb.orderValue.threshold.label': 'Drempelwaarde',
  'dm.run.result.checkpoint.nl.paymentsScore': 'Betaalscore',
  'dm.run.result.checkpoint.nl.paymentsScore.threshold.label': 'Drempelwaarde',
  'dm.run.result.checkpoint.nl.probabilityOfDefault': 'Percentage kans op Faillissement',
  'dm.run.result.checkpoint.nl.probabilityOfDefault.threshold.label': 'Drempelwaarde',
  'dm.run.result.checkpoint.payments': 'Betaalgedrag',
  'dm.run.result.checkpoint.payments.threshold.label': 'Drempelwaarde',
  'dm.run.result.checkpoint.value.bad': 'Slecht',
  'dm.run.result.checkpoint.value.good': 'Goed',
  'dm.run.result.checkpoint.value.n': 'Nee',
  'dm.run.result.checkpoint.value.na': 'Niet beschikbaar',
  'dm.run.result.checkpoint.value.no': 'Nee',
  'dm.run.result.checkpoint.value.notX': 'Ongelijk aan X',
  'dm.run.result.checkpoint.value.X': 'X',
  'dm.run.result.checkpoint.value.y': 'Ja',
  'dm.run.result.checkpoint.value.yes': 'Ja',
  'dm.run.result.failed': 'Het resultaat kan niet worden getoond',
  'dm.run.runcheck': 'Voer check uit',
  'dm.setup.step1': 'Selecteer een land',
  'dm.setup.step2': "Wat is uw 'Risk Appetite'?",
  'dm.setup.step3': 'Personaliseer het model',
  'dm.setup.step4': 'Klaar!',
  'dm.templates.lede':
    'We hebben deze voorgedefinieerde modellen voor u geselecteerd. Kies er één en personaliseer op basis van uw eigen wensen in de volgende stap.',
  'dm.templates.title': "Wat is uw 'Risk Appetite'?",
  'dm.tooltip.acceptance.legend': ' ',
  'dm.tooltip.acceptance.value': ' ',
  'dm.tooltip.client.acceptance':
    'Hoe meer blokken gevuld zijn in dit model, hoe meer u gericht bent op groei en klanten accepteren. ',
  'dm.tooltip.debt.prevention':
    'Hoe meer blokken gevuld zijn in dit model, hoe meer u gericht bent op afschrijvingen voorkomen.',
  'dm.tooltip.tolerance.accept':
    'Wanneer de kans op wanbetaling van een bedrijf lager ligt dan de ingestelde waarde, dan kunt u de klant accepteren.',
  'dm.tooltip.tolerance.deny':
    'Wanneer de kans op wanbetaling van een bedrijf hoger ligt dan de ingestelde waarde, dan kunt u de klant niet accepteren.',
  'dm.tooltip.tolerance.threshold':
    'Stel hier het jaarlijks maximaal toelaatbaar afschrijvingspercentage in. Wanneer u een bedrijf checkt, wordt deze waarde vergeleken met de kans op wanbetaling.',
  'dm.userBar.cancel': 'Annuleer en sluiten',
  'download.as.csv': 'Download als CSV',
  'error.contact-support': 'Bel met onze support lijn',
  'error.inactive.description':
    'Bel ons op onderstaand nummer en we helpen u snel aan de slag te gaan.',
  'error.inactive.title': 'Uw account is momenteel niet actief',
  'error.login': 'Inloggen',
  'error.retry-login': 'Probeer opnieuw in te loggen',
  'error.something-wrong': 'Er ging iets mis',
  'error.unknown.description':
    "Inloggen is helaas niet gelukt. Het kan zijn dat uw account (nog) niet actief is. Ook kan het zijn dat uw 'consumer ID' niet goed is ingevoerd. Dat zegt wellicht niet direct iets, maar als u deze melding doorgeeft aan onze Service Desk, kunnen zij u snel verder helpen.",
  'error.unknown.title': 'Er gaat iets niet helemaal goed',
  'filter.pane.button.tooltip': 'Klik om de filters te verbergen of tonen',
  'filter.pane.header': 'Filters',
  'filters.countries.be': 'BE',
  'filters.countries.gb': 'UK',
  'filters.countries.nl': 'NL',
  'filters.countries.xx': 'Onbekend',
  'filters.sectors.a': 'Landbouw, bosbouw en visserij',
  'filters.sectors.a.short': 'Landbouw, bosbouw en visserij',
  'filters.sectors.b': 'Winning van delfstoffen',
  'filters.sectors.b.short': 'Winning van delfstoffen',
  'filters.sectors.c': 'Industrie',
  'filters.sectors.c.short': 'Industrie',
  'filters.sectors.d': 'Productie en distributie van elektriciteit, gas, stoom en gekoelde lucht',
  'filters.sectors.d.short':
    'Productie en distributie van elektriciteit, gas, stoom en gekoelde lucht',
  'filters.sectors.e': 'Distributie van water; afval- en afvalwaterbeheer en sanering',
  'filters.sectors.e.short': 'Distributie van water; afval- en afvalwaterbeheer en sanering',
  'filters.sectors.f': 'Bouwnijverheid',
  'filters.sectors.f.short': 'Bouwnijverheid',
  'filters.sectors.g': 'Groot- en detailhandel; reparatie van voertuigen',
  'filters.sectors.g.short': 'Groot- en detailhandel; reparatie van voertuigen',
  'filters.sectors.h': 'Vervoer en opslag',
  'filters.sectors.h.short': 'Vervoer en opslag',
  'filters.sectors.i': 'Verschaffen van accommodatie en maaltijden',
  'filters.sectors.i.short': 'Verschaffen van accommodatie en maaltijden',
  'filters.sectors.j': 'Informatie en communicatie',
  'filters.sectors.j.short': 'Informatie en communicatie',
  'filters.sectors.k': 'Financiële activiteiten en verzekeringen',
  'filters.sectors.k.short': 'Financiële activiteiten en verzekeringen',
  'filters.sectors.l': 'Exploitatie van en handel in onroerend goed',
  'filters.sectors.l.short': 'Exploitatie van en handel in onroerend goed',
  'filters.sectors.m': 'Vrije beroepen en wetenschappelijke en technische activiteiten',
  'filters.sectors.m.short': 'Vrije beroepen en wetenschappelijke en technische activiteiten',
  'filters.sectors.n': 'Administratieve en ondersteunende diensten',
  'filters.sectors.n.short': 'Administratieve en ondersteunende diensten',
  'filters.sectors.o': 'Openbaar bestuur en defensie; verplichte sociale verzekeringen',
  'filters.sectors.o.short': 'Openbaar bestuur en defensie; verplichte sociale verzekeringen',
  'filters.sectors.p': 'Onderwijs',
  'filters.sectors.p.short': 'Onderwijs',
  'filters.sectors.q': 'Menselijke gezondheidszorg en maatschappelijke dienstverlening',
  'filters.sectors.q.short': 'Menselijke gezondheidszorg en maatschappelijke dienstverlening',
  'filters.sectors.r': 'Kunst, amusement en recreatie',
  'filters.sectors.r.short': 'Kunst, amusement en recreatie',
  'filters.sectors.s': 'Overige diensten',
  'filters.sectors.s.short': 'Overige diensten',
  'filters.sectors.t': 'Huishoudens als werkgever',
  'filters.sectors.t.short': 'Huishoudens als werkgever',
  'filters.sectors.u': 'Extraterritoriale organisaties en lichamen',
  'filters.sectors.u.short': 'Extraterritoriale organisaties en lichamen',
  'filters.sectors.x': 'Overige',
  'filters.sectors.x.short': 'Overige',
  'footer.cookies': 'Cookies',
  'footer.copyright': '© Graydon. All rights reserved',
  'footer.gdpr': 'AVG',
  'footer.terms.and.conditions': 'Algemene Voorwaarden',
  'form.validation.max-length': 'Dit veld mag maximum {max} karakters bevatten',
  'form.validation.required': 'Verplicht veld',
  'gdm.check-modal.archive-description': 'Alleen de laatste 10 checks worden getoond',
  'gdm.check-modal.archive-error': 'Fout bij het laden van voorgaande check(s)',
  'gdm.check-modal.archive-header': 'Eerdere check(s) voor dit bedrijf',
  'gdm.check-modal.archive-no-result': 'Geen voorgaande checks',
  'gdm.check-modal.select': 'Kies een decision model',
  'gdm.check-modal.title': 'Check met decision model',
  'gdm.check-modal.view-check': 'Bekijk check',
  'gdm.check-result.checkpoint.current-value': 'Huidige waarde',
  'gdm.check-result.checkpoint.indicator': 'Indicator',
  'gdm.check-result.checkpoint.order-value': 'Drempelwaarde',
  'gdm.check-result.checkpoint.title.accept': 'Accepteren',
  'gdm.check-result.checkpoint.title.deny': 'Afwijzen',
  'gdm.check-result.checkpoint.title.refer': 'Doorverwijzen',
  'gdm.check-result.checkpoint.tooltip': 'Hier vindt u de belangrijkste kenmerken van deze check. ',
  'gdm.check-result.company-details.tooltip':
    'Hier vindt u de basisinformatie over het door u opgevraagde bedrijf.',
  'gdm.check-result.company-relations.tooltip':
    'Hier vindt u de verbindingen van het door u opgevraagde bedrijf.',
  'gdm.check-result.contact-details.tooltip':
    'Hier vindt u de contactgegevens van het door u opgevraagde bedrijf. ',
  'gdm.check-result.directors.tooltip':
    'Hier vindt u een overzicht van de actieve en eventueel inactieve bestuurders, bijbehorende bevoegdheid en overige functies van deze bestuurders.',
  'gdm.check-result.graydon-insights.tooltip':
    'Hier vindt u een aantal belangrijke kenmerken van dit bedrijf. ',
  'gdm.check-result.summary.date': 'Datum',
  'gdm.check-result.summary.decision-model': 'Decision Model',
  'gdm.check-result.summary.order-value': 'Orderwaarde',
  'gdm.report.orderValue.customized': 'Aangepast',
  'gdm.report.orderValue.recommended': 'Aanbevolen',
  'general.contactGraydon': 'Contact Graydon',
  'general.customerCare': 'Customer Care',
  'general.fetch.error': 'Fout bij het ophalen van de data, probeer het later opnieuw',
  'general.na': 'N/A',
  'general.noRating': 'NR',
  'general.not.available': 'Niet beschikbaar',
  'general.sort': 'Sorteer',
  'general.sort.ascending': 'Oplopend',
  'general.sort.descending': 'Aflopend',
  'general.specify-search': 'Niet gevonden wat u zoekt? Verfijn uw zoekopdracht.',
  'general.to.the.attention.of': 't.a.v.',
  'get.support': ' ',
  heading: 'UBO Rapport',
  'home.heading': 'Welkom',
  'home.heading.subtext': 'Ga direct aan de slag en maak een beslismodel of zoek een bedrijf.',
  'icr-form-field-required': 'Verplicht',
  'icr.archive-page.description':
    'Archief van alle aanvragen die gebeurd zijn van uw account aan het Graydon-systeem.',
  'icr.archive-page.header': 'Archief',
  'icr.archive-page.tab.pending': 'In behandeling',
  'icr.archive-page.tab.received': 'Ontvangen',
  'icr.archive.load.more.reports': 'Laad {pageSize} rapporten meer',
  'icr.archive.orders.client-note': 'Uw referentie',
  'icr.archive.orders.company-name': 'Bedrijfsnaam',
  'icr.archive.orders.completed.tooltip':
    'Een kopie van al uw rapporten die u eerder aangevraagd en gedownload heeft, vindt u terug in deze sectie.',
  'icr.archive.orders.country': 'Land',
  'icr.archive.orders.dateCreated': 'Datum aangemaakt',
  'icr.archive.orders.documentName': 'Rapportnaam',
  'icr.archive.orders.due-date': 'Leveringsdatum',
  'icr.archive.orders.genericError.description':
    'Het aangevraagde rapport kon niet worden geleverd. Gelieve een [Nieuw Onderzoek](/apps/international-credit-reports/order-investigated-report) aan te vragen.',
  'icr.archive.orders.genericError.title': 'Aanvraag mislukt\n',
  'icr.archive.orders.hideReports': 'Verberg {count} rapporten',
  'icr.archive.orders.no-result': 'Geen resultaten gevonden',
  'icr.archive.orders.order-reference': 'Bestelling #',
  'icr.archive.orders.ordered-date': 'Datum besteld',
  'icr.archive.orders.pending.tooltip':
    'Hier vindt u een overzicht van alle rapporten die u besteld heeft en nog afgeleverd moeten worden.',
  'icr.archive.orders.report': 'Rapport',
  'icr.archive.orders.search.placeholder':
    'Zoek op de naam van een bedrijf, land, ordernummer, rapport of uw referentie. ',
  'icr.archive.orders.showReports': 'Bekijk {count} rapporten',
  'icr.archive.orders.type': 'Type',
  'icr.archive.report.download.toast.body.error':
    'Het rapport kan niet worden gedownload. Probeer het later nogmaals.',
  'icr.archive.report.download.toast.body.link-to-archive': 'Ga naar het Archief',
  'icr.archive.report.download.toast.body.not-available':
    'Probeer het rapport opnieuw te downloaden',
  'icr.archive.report.download.toast.body.success':
    'Your report is also stored in the International Business Archive you can access it anytime there.',
  'icr.archive.report.download.toast.title.error': 'Kan niet worden gedownload',
  'icr.archive.report.download.toast.title.not-available': 'Rapport momenteel niet beschikbaar',
  'icr.archive.report.download.toast.title.success': 'Report downloaded',
  'icr.archive.request-report.toast.link': 'Ga naar het archief',
  'icr.archive.request-report.toast.text':
    'Het rapport is binnenkort beschikbaar. Volg de status in uw International Business-archief',
  'icr.archive.request-report.toast.text.error':
    'Probeert u het later nogmaals of neem [contact](/apps/contact) op met Graydon.',
  'icr.archive.request-report.toast.title': 'Uw rapport wordt samengesteld',
  'icr.archive.request-report.toast.title.error': 'Aanvraag kon niet worden verstuurd',
  'icr.country-select.disabled.hover':
    'Dit land zit niet in uw pakket. Contacteer ons om dit aan te passen.',
  'icr.country-select.domestic': 'Lokaal',
  'icr.country-select.placeholder': 'Selecteer of typ een land',
  'icr.country-select.report-type.database': 'Online rapport',
  'icr.country-select.report-type.domestic-database': 'Lokaal online rapport',
  'icr.country-select.report-type.investigation': 'Nieuw Onderzoek',
  'icr.country.AD': 'Andorra',
  'icr.country.AE': 'Verenigde Arabische Emiraten',
  'icr.country.AF': 'Afghanistan',
  'icr.country.AG': 'Antigua en Barbuda',
  'icr.country.AI': 'Anguilla',
  'icr.country.AL': 'Albanië',
  'icr.country.AM': 'Armenië',
  'icr.country.AN': 'Nederlandse Antillen',
  'icr.country.AO': 'Angola',
  'icr.country.AQ': 'Antarctica',
  'icr.country.AR': 'Argentinië',
  'icr.country.AS': 'Amerikaans-Samoa',
  'icr.country.AT': 'Oostenrijk',
  'icr.country.AU': 'Australië',
  'icr.country.AW': 'Aruba',
  'icr.country.AX': 'Aland Islands',
  'icr.country.AZ': 'Azerbeidzjan',
  'icr.country.BA': 'Bosnië-Herzegovina',
  'icr.country.BB': 'Barbados',
  'icr.country.BD': 'Bangladesh',
  'icr.country.BE': 'België',
  'icr.country.BF': 'Burkina Faso',
  'icr.country.BG': 'Bulgarije',
  'icr.country.BH': 'Bahrein',
  'icr.country.BI': 'Burundi',
  'icr.country.BJ': 'Benin',
  'icr.country.BL': 'Sint Bartholomeus',
  'icr.country.BM': 'Bermuda',
  'icr.country.BN': 'Brunei Darussalam',
  'icr.country.BO': 'Bolivia',
  'icr.country.BQ': 'Bonaire, Sint Eustatius and Saba',
  'icr.country.BR': 'Brazilië',
  'icr.country.BS': "Bahama's",
  'icr.country.BT': 'Bhutan',
  'icr.country.BV': 'Bouvet Island',
  'icr.country.BW': 'Botswana',
  'icr.country.BY': 'Wit-Rusland',
  'icr.country.BZ': 'Belize',
  'icr.country.CA': 'Canada',
  'icr.country.CC': 'Cocos (Keeling) Islands',
  'icr.country.CD': 'Congo, Democratische Republiek',
  'icr.country.CF': 'Central African Republic',
  'icr.country.CG': 'Congo',
  'icr.country.CH': 'Zwitserland',
  'icr.country.CI': 'Ivoorkust',
  'icr.country.CK': 'Cook Eilanden',
  'icr.country.CL': 'Chili',
  'icr.country.CM': 'Kameroen',
  'icr.country.CN': 'China',
  'icr.country.CO': 'Colombia',
  'icr.country.CR': 'Costa Rica',
  'icr.country.CU': 'Cuba',
  'icr.country.CV': 'Kaapverdië',
  'icr.country.CW': 'Curaçao',
  'icr.country.CX': 'Christmaseiland',
  'icr.country.CY': 'Cyprus',
  'icr.country.CZ': 'Tsjechische Republiek',
  'icr.country.DE': 'Duitsland',
  'icr.country.DJ': 'Djibouti',
  'icr.country.DK': 'Denemarken',
  'icr.country.DM': 'Dominica',
  'icr.country.DO': 'Dominicaanse Republiek',
  'icr.country.DZ': 'Algerije',
  'icr.country.EC': 'Ecuador',
  'icr.country.EE': 'Estland',
  'icr.country.EG': 'Egypte',
  'icr.country.EH': 'Westelijke Sahara',
  'icr.country.ER': 'Eritrea',
  'icr.country.ES': 'Spanje',
  'icr.country.ET': 'Ethiopië',
  'icr.country.FI': 'Finland',
  'icr.country.FJ': 'Fiji',
  'icr.country.FK': 'Falkland Eilanden',
  'icr.country.FM': 'Micronesië, Federale Staten van',
  'icr.country.FO': 'Faeröer',
  'icr.country.FR': 'Frankrijk',
  'icr.country.GA': 'Gabon',
  'icr.country.GB': 'Verenigd Koninkrijk',
  'icr.country.GD': 'Grenada',
  'icr.country.GE': 'Georgië',
  'icr.country.GF': 'Frans-Guyana',
  'icr.country.GG': 'Guernsey',
  'icr.country.GH': 'Ghana',
  'icr.country.GI': 'Gibraltar',
  'icr.country.GL': 'Groenland',
  'icr.country.GM': 'Gambia',
  'icr.country.GN': 'Guinee',
  'icr.country.GP': 'Guadeloupe',
  'icr.country.GQ': 'Equatoriaal-Guinea',
  'icr.country.GR': 'Griekenland',
  'icr.country.GS': 'South Georgia and the South Sandwich Islands',
  'icr.country.GT': 'Guatemala',
  'icr.country.GU': 'Guam',
  'icr.country.GW': 'Guinea-Bissau',
  'icr.country.GY': 'Guyana',
  'icr.country.HK': 'Hong Kong',
  'icr.country.HM': 'Heard and Mcdonald Islands',
  'icr.country.HN': 'Honduras',
  'icr.country.HR': 'Kroatië',
  'icr.country.HT': 'Haïti',
  'icr.country.HU': 'Hongarije',
  'icr.country.ID': 'Indonesië',
  'icr.country.IE': 'Ierland',
  'icr.country.IL': 'Israël',
  'icr.country.IM': 'Isle of Man',
  'icr.country.IN': 'India',
  'icr.country.IO': 'Brits-Indisch oceaan gebied',
  'icr.country.IQ': 'Irak',
  'icr.country.IR': 'Iran',
  'icr.country.IS': 'Ijsland',
  'icr.country.IT': 'Italië',
  'icr.country.JE': 'Jersey',
  'icr.country.JM': 'Jamaica',
  'icr.country.JO': 'Jordanië',
  'icr.country.JP': 'Japan',
  'icr.country.KE': 'Kenia',
  'icr.country.KG': 'Kirgizië',
  'icr.country.KH': 'Cambodja',
  'icr.country.KI': 'Kiribati',
  'icr.country.KM': 'Comoren',
  'icr.country.KN': 'Saint Kitts en Nevis',
  'icr.country.KP': 'Korea (North)',
  'icr.country.KR': 'Korea (South)',
  'icr.country.KW': 'Koeweit',
  'icr.country.KY': 'Kaaiman Eilanden',
  'icr.country.KZ': 'Kazakhstan',
  'icr.country.LA': 'Lao Democratische Volksrepubliek',
  'icr.country.LB': 'Libanon',
  'icr.country.LC': 'Saint Lucia',
  'icr.country.LI': 'Liechtenstein',
  'icr.country.LK': 'Sri Lanka',
  'icr.country.LR': 'Liberia',
  'icr.country.LS': 'Lesotho',
  'icr.country.LT': 'Litouwen',
  'icr.country.LU': 'Luxemburg',
  'icr.country.LV': 'Letland',
  'icr.country.LY': 'Libië',
  'icr.country.MA': 'Marokko',
  'icr.country.MC': 'Monaco',
  'icr.country.MD': 'Moldavië',
  'icr.country.ME': 'Montenegro',
  'icr.country.MF': 'Saint-Martin (French part)',
  'icr.country.MG': 'Madagascar',
  'icr.country.MH': 'Marshall eilanden',
  'icr.country.MK': 'Noord-Macedonië',
  'icr.country.ML': 'Mali',
  'icr.country.MM': 'Myanmar',
  'icr.country.MN': 'Mongolië',
  'icr.country.MO': 'Macau',
  'icr.country.MP': 'Noordelijke Mariana eilanden',
  'icr.country.MQ': 'Martinique',
  'icr.country.MR': 'Mauritanië',
  'icr.country.MS': 'Montserrat',
  'icr.country.MT': 'Malta',
  'icr.country.MU': 'Mauritius',
  'icr.country.MV': 'Malediven',
  'icr.country.MW': 'Malawi',
  'icr.country.MX': 'Mexico',
  'icr.country.MY': 'Maleisië',
  'icr.country.MZ': 'Mozambique',
  'icr.country.NA': 'Namibië',
  'icr.country.NC': 'Nieuw-Caledonië',
  'icr.country.NE': 'Niger',
  'icr.country.NF': 'Norfolk Island',
  'icr.country.NG': 'Nigeria',
  'icr.country.NI': 'Nicaragua',
  'icr.country.NL': 'Nederland',
  'icr.country.NO': 'Noorwegen',
  'icr.country.NP': 'Nepal',
  'icr.country.NR': 'Nauru',
  'icr.country.NU': 'Niue',
  'icr.country.NZ': 'Nieuw Zeeland',
  'icr.country.OM': 'Oman',
  'icr.country.PA': 'Panama',
  'icr.country.PE': 'Peru',
  'icr.country.PF': 'Frans-Polynesië',
  'icr.country.PG': 'Papoea-Nieuw-Guinea',
  'icr.country.PH': 'Filipijnen',
  'icr.country.PK': 'Pakistan',
  'icr.country.PL': 'Polen',
  'icr.country.PM': 'Saint Pierre and Miquelon',
  'icr.country.PN': 'Pitcairn',
  'icr.country.PR': 'Puerto Rico',
  'icr.country.PS': 'Palestina, staat',
  'icr.country.PT': 'Portugal',
  'icr.country.PW': 'Palau',
  'icr.country.PY': 'Paraguay',
  'icr.country.QA': 'Katar',
  'icr.country.RE': 'Reunion',
  'icr.country.RO': 'Roemenië',
  'icr.country.RS': 'Servië',
  'icr.country.RU': 'Russische Federatie',
  'icr.country.RW': 'Rwanda',
  'icr.country.SA': 'Saoedi-Arabië',
  'icr.country.SB': 'Solomon eilanden',
  'icr.country.SC': 'Seychellen',
  'icr.country.SD': 'Sudan',
  'icr.country.SE': 'Zweden',
  'icr.country.SG': 'Singapore',
  'icr.country.SH': 'Saint Helena',
  'icr.country.SI': 'Slovenië',
  'icr.country.SJ': 'Svalbard and Jan Mayen Islands',
  'icr.country.SK': 'Slowakije',
  'icr.country.SL': 'Sierra Leone',
  'icr.country.SM': 'San Marino',
  'icr.country.SN': 'Senegal',
  'icr.country.SO': 'Somalië',
  'icr.country.SR': 'Suriname',
  'icr.country.SS': 'Zuid Soedan',
  'icr.country.ST': 'Sao Tome and Principe',
  'icr.country.SV': 'El Salvador',
  'icr.country.SY': 'Syrië',
  'icr.country.SZ': 'Swaziland',
  'icr.country.TC': 'Turks- en Caicoseilanden',
  'icr.country.TD': 'Tsjaad',
  'icr.country.TF': 'Franse zuidelijke gebieden',
  'icr.country.TG': 'Togo',
  'icr.country.TH': 'Thailand',
  'icr.country.TJ': 'Tadzjikistan',
  'icr.country.TK': 'Tokelau',
  'icr.country.TL': 'Timor-Leste',
  'icr.country.TM': 'Turkmenistan',
  'icr.country.TN': 'Tunesië',
  'icr.country.TO': 'Tonga',
  'icr.country.TR': 'Turkije',
  'icr.country.TT': 'Trinidad en Tobago',
  'icr.country.TV': 'Tuvalu',
  'icr.country.TW': 'Taiwan',
  'icr.country.TZ': 'Tanzania',
  'icr.country.UA': 'Oekraïne',
  'icr.country.UG': 'Oeganda',
  'icr.country.UM': 'Kleine afgelegen eilanden van de Verenigde Staten',
  'icr.country.US': 'Verenigde Staten',
  'icr.country.UY': 'Uruguay',
  'icr.country.UZ': 'Oezbekistan',
  'icr.country.VA': 'Holy See (Vatican City State)',
  'icr.country.VC': 'Saint Vincent en de Grenadines',
  'icr.country.VE': 'Venezuela',
  'icr.country.VG': 'Britse Maagdeneilanden',
  'icr.country.VI': 'Amerikaanse Maagdeneilanden',
  'icr.country.VN': 'Vietnam',
  'icr.country.VU': 'Vanuatu',
  'icr.country.WF': 'Wallis and Futuna Islands',
  'icr.country.WS': 'Samoa',
  'icr.country.YE': 'Jemen',
  'icr.country.YT': 'Mayotte',
  'icr.country.ZA': 'Zuid-Afrika',
  'icr.country.ZM': 'Zambia',
  'icr.country.ZW': 'Zimbabwe',
  'icr.coutnry.GQ': 'Equatoriaal-Guinea',
  'icr.error.search': 'Er ging iets mis',
  'icr.error.search.contacts':
    'Gelieve het later opnieuw te proberen of contacteer ons Customer Service team via <a href="mailto:{country, select, gb {customerservices@graydon.co.uk} be {support@graydon.be} other {service@graydon.nl}}">{country, select, gb {customerservices@graydon.co.uk} be {support@graydon.be} other {service@graydon.nl}}</a> of op {country, select, gb {+44&nbsp;(0)20&nbsp;8515&nbsp;1400} be {+32&nbsp;(03)&nbsp;280&nbsp;8880} other {+31&nbsp;(020)&nbsp;567&nbsp;9501}}.',
  'icr.error.search.text':
    'Helaas is de service niet beschikbaar en kan de zoekopdracht niet uitgevoerd worden.',
  'icr.investgated.report.additionalEmails.label': 'Verstuur extra notificatie aan',
  'icr.investgated.report.additionalEmails.placeholder': 'email@your-domain.com',
  'icr.investgated.report.additionalEmails.recipients.none':
    'Een melding wordt alleen gestuurd naar het e-mailadres van het account',
  'icr.investgated.report.additionalEmails.tooltip':
    'Een melding wordt sowieso gestuurd naar het e-mailadres van het account zodra het gevraagde rapport beschikbaar is. Geef extra e-mailadressen op om extra meldingen te sturen.',
  'icr.investgated.report.address.label': 'Adres',
  'icr.investgated.report.address.placeholder':
    'Vul het volledige adres in, inclusief woonplaats, straat en huisnummer, gebouw, enz.',
  'icr.investgated.report.address.tooltip':
    'Vul het volledige adres in, inclusief woonplaats, straatnaam, huisnummer, gebouw, enz.',
  'icr.investgated.report.address.validation.message':
    'Gelieve een geldig en gedetailleerd adres in te vullen',
  'icr.investgated.report.building.label': 'Gebouw',
  'icr.investgated.report.building.placeholder': 'Voeg gebouwnummer in',
  'icr.investgated.report.building.tooltip': 'Voeg gebouwnummer in',
  'icr.investgated.report.building.validation.message': 'Voer een geldig gebouwnummer in',
  'icr.investgated.report.city.label': 'Plaats',
  'icr.investgated.report.city.placeholder': 'Voer plaatsnaam in',
  'icr.investgated.report.city.validation.message': 'Voer een geldige plaatsnaam in',
  'icr.investgated.report.clientEmail.label': 'E-mail',
  'icr.investgated.report.clientEmail.placeholder': 'Voer email adres in',
  'icr.investgated.report.clientEmail.tooltip': 'Voer email adres in',
  'icr.investgated.report.clintIdentification.sap-id': 'SAP ID',
  'icr.investgated.report.clintIdentification.validate': 'Valideren',
  'icr.investgated.report.comments.label': 'Opmerkingen',
  'icr.investgated.report.comments.maxLengthMessage': 'Resterende karakters:',
  'icr.investgated.report.comments.tooltip':
    '\nGeef hier eventuele aanvullende informatie en/of details mee die onze teams kunnen helpen of opmerkingen/vragen aangaande het bedrijfsrapport dat u aanvraagt.',
  'icr.investgated.report.companyName.label': 'Naam van het bedrijf',
  'icr.investgated.report.companyName.placeholder': 'Vul de naam van het bedrijf in',
  'icr.investgated.report.companyName.tooltip':
    'Vul de naam in van het bedrijf waarvoor u een rapport wilt aanvragen.',
  'icr.investgated.report.companyNameLocal.label': 'Naam van het bedrijf in de lokale taal',
  'icr.investgated.report.companyNameLocal.placeholder':
    'Vul naam in de locale taal in (indien verschillend van hierboven)',
  'icr.investgated.report.companyNameLocal.tooltip':
    'Vul de naam van het bedrijf in de lokale taal in (indien verschillend van de naam hierboven of in een ander alfabet).',
  'icr.investgated.report.companyRegNumber.label': 'Registratienummer',
  'icr.investgated.report.companyRegNumber.placeholder': 'Vul registratienummer van het bedrijf in',
  'icr.investgated.report.companyRegNumber.tooltip':
    'Vul het registratienummer van het bedrijf in.',
  'icr.investgated.report.companyRegNumber.validation.message':
    'Voer een geldig registratienummer in',
  'icr.investgated.report.consumer-name.label': 'Bedrijfsnaam van de klant',
  'icr.investgated.report.country.label': 'Land',
  'icr.investgated.report.country.validation.message': 'Gelieve een land te selecteren',
  'icr.investgated.report.creditRecommendation.label': 'Aanbevolen krediet',
  'icr.investgated.report.creditRecommendation.placeholder':
    'Voer het kredietbedrag in dat u wilt aanbieden (indien van toepassing)',
  'icr.investgated.report.currency.label': 'Munteenheid',
  'icr.investgated.report.currency.tooltip':
    'Selecteer in het keuzemenu de munteenheid voor het aanbevolen krediet',
  'icr.investgated.report.discloseEnquirerName.label':
    'Ja, ik geef Graydon toelating om de naam van de aanvrager vrij te geven als onderdeel van dit onderzoek',
  'icr.investgated.report.discloseEnquirerName.tooltip':
    'Vink dit vakje aan als u akkoord bent om uw naam te verbinden aan dit onderzoek.',
  'icr.investgated.report.email.label': 'E-mailadres',
  'icr.investgated.report.enquirerName.label': 'Naam van de aanvrager',
  'icr.investgated.report.form.info.2':
    'Vul alle andere informatie in die ons kunnen helpen om het juiste bedrijf te identificeren.',
  'icr.investgated.report.form.section.header.0': 'Klant identificatie',
  'icr.investgated.report.form.section.header.1': 'Vereiste informatie over het bedrijf',
  'icr.investgated.report.form.section.header.2': 'Aanvullende informatie over het bedrijf',
  'icr.investgated.report.form.section.header.3': 'Informatie over het rapport',
  'icr.investgated.report.includeEnquirerName.false': 'Nee',
  'icr.investgated.report.includeEnquirerName.label': 'Naam van de aanvrager toevoegen',
  'icr.investgated.report.includeEnquirerName.true': 'Ja',
  'icr.investgated.report.max-length.validation': 'Dit veld mag maximaal {max} karakters bevatten',
  'icr.investgated.report.name.label': 'Volledige naam contactpersoon',
  'icr.investgated.report.otherIdentifiers.addAnother': 'Voeg een ander identificatienummer toe',
  'icr.investgated.report.otherIdentifiers.tooltip':
    'Als u nog andere unieke identificatienummers heeft, voeg ze dan hieronder toe.\nLet op, u dient in het vak aan de rechterkant aan te geven welk type identificatienummer u toevoegt.',
  'icr.investgated.report.otherIdentifiers.validation':
    'Voer een geldig nummer én omschrijving in of verwijder de aanvullende velden',
  'icr.investgated.report.otherIdentifyingDescription.label':
    'Omschrijving van identificatienummer',
  'icr.investgated.report.otherIdentifyingDescription.label-short': 'Omschrijving',
  'icr.investgated.report.otherIdentifyingNumber.label': 'Ander uniek identificatienummer',
  'icr.investgated.report.otherIdentifyingNumber.label-short':
    'Voer een extra identificatienummer in, indien beschikbaar',
  'icr.investgated.report.otherInfoOrComments.label':
    'Voeg eventuele andere opmerkingen toe in dit vak',
  'icr.investgated.report.postalCode.label': 'Postcode',
  'icr.investgated.report.postalCode.placeholder': 'Voer postcode in',
  'icr.investgated.report.postalCode.validation.message': 'Voer een geldige postcode in',
  'icr.investgated.report.range.validation':
    'Dit veld moet een minimum van {min} karakter hebben en een maximum van {max} karakters',
  'icr.investgated.report.reference.label': 'Referentie',
  'icr.investgated.report.reference.placeholder':
    'Voer uw referentie in voor dit rapport (bv. bestelnummer of klantnummer)',
  'icr.investgated.report.reference.tooltip':
    'Vul uw persoonlijke referentie in voor deze aanvraag (naam van de klant, dossiernummer, enz.).',
  'icr.investgated.report.reference.validation.message':
    'Voer een geldig referentienummer in (max. 50 tekens)',
  'icr.investgated.report.sap-id.label': 'SAP ID',
  'icr.investgated.report.speedOfService.label': 'Snelheid van de dienstverlening',
  'icr.investgated.report.speedOfService.tooltip':
    'Selecteer de snelheid van de dienstverlening uit de onderstaande opties. Let op, de vermelde snelheid van de dienstverlening is afhankelijk van het land dat u hebt gekozen. Raadpleeg uw overeenkomst met Graydon voor meer informatie over de prijs van elke leveringssnelheid.',
  'icr.investgated.report.speedOfService.validation.message':
    'Er ging iets mis. Gelieve het opnieuw te proberen.',
  'icr.investgated.report.speedOfService.validationMessage':
    'U dient de gewenst snelheid van de dienstverlening te kiezen',
  'icr.investgated.report.street.label': 'Straat, huisnummer, toevoeging',
  'icr.investgated.report.street.placeholder': 'Voer straatnaam in',
  'icr.investgated.report.street.tooltip': 'Voer straatnaam in',
  'icr.investgated.report.street.validation.message': 'Voer een geldige straatnaam in',
  'icr.investgated.report.submitting.message-bottom': 'Aanvraag versturen',
  'icr.investgated.report.submitting.message-top':
    'Even geduld terwijl we uw aanvraag naar ons onderzoeksteam versturen',
  'icr.investgated.report.telephone.addNumber': 'Voeg een ander telefoonnummer toe',
  'icr.investgated.report.telephone.label': 'Vul een telefoonnummer in ',
  'icr.investgated.report.telephone.placeholder': 'Vul een telefoonnummer in ',
  'icr.investgated.report.vatNumber.label': 'BTW-nummer',
  'icr.investgated.report.vatNumber.placeholder': 'Vul BTW-nummer in',
  'icr.investgated.report.vatNumber.tooltip': 'Vul het btw-nummer van het bedrijf in.',
  'icr.investigated.report.button.next-step': 'Volgende stap',
  'icr.investigated.report.button.send-request': 'Bestelling bevestigen',
  'icr.investigated.report.clientEmail.empty.message': 'Voer een emailadres in voor validatie',
  'icr.investigated.report.clientEmail.validation.message': 'Ongeldig of onbekend e-mailadres',
  'icr.investigated.report.companyName.validation.message':
    'Gelieve een geldige bedrijfsnaam in te vullen',
  'icr.investigated.report.form.deliveryTime': 'Levering {daysFrom} tot {daysTo} werkdagen',
  'icr.investigated.report.form.deliveryTime-short': '({daysFrom} tot {daysTo} werkdagen)',
  'icr.investigated.report.form.info.1':
    'Vul de volgende gegevens in over het bedrijf dat u wilt laten onderzoeken. Deze informatie helpt ons om het bedrijf te identificeren en de juiste informatie voor u op te halen.',
  'icr.investigated.report.form.previousStep': 'Vorige stap',
  'icr.investigated.report.page.title': 'Een Nieuw Onderzoek aanvragen',
  'icr.investigated.report.step.0.name': 'Klant identificatie',
  'icr.investigated.report.step.0.title': 'Stap 0',
  'icr.investigated.report.step.1.name': 'Informatie over het bedrijf',
  'icr.investigated.report.step.1.title': 'Stap 1',
  'icr.investigated.report.step.2.name': 'Details bestelling',
  'icr.investigated.report.step.2.title': 'Stap 2',
  'icr.investigated.report.step.3.name': 'Bestel onderzoek',
  'icr.investigated.report.step.3.title': 'Stap 3',
  'icr.investigated.report.step.title': 'Stap {step}',
  'icr.link.download-old-report': 'Download oud report',
  'icr.order.investigated.report.priceList':
    'Bekijk [hier](https://graydon.showpad.com/share/ekV3Y2Zo4zbTQWaibeYTW) de tarieven voor onze internationale rapporten',
  'icr.order.investigated.report.toast.body.error':
    'Helaas is er een fout opgetreden en kunnen we uw bestelling niet voltooien. Probeer uw aanvraag opnieuw te verzenden',
  'icr.order.investigated.report.toast.body.success':
    'Uw aanvraag is verstuurd. U kunt de status van uw bestelling bekijken in het archief.',
  'icr.order.investigated.report.toast.title.error': 'Er ging iets mis',
  'icr.order.investigated.report.toast.title.success': 'Aanvraag verstuurd!',
  'icr.report.database.immediate': 'Online rapport - Immediate',
  'icr.report.database.immediate-with-update': 'Online rapport - Immediate with update',
  'icr.report.investigated': 'Nieuw Onderzoek',
  'icr.search-page.archive-btn': 'Archief',
  'icr.search-page.choose-country': 'Kies een land',
  'icr.search-page.choose-country.tooltip':
    'Selecteer in welk land u wilt zoeken. Via het uitklapmenu ziet u meteen of een rapport onmiddellijk beschikbaar is in de database. Is dat niet het geval, dan kunt u via de knop rechts bovenaan een Nieuw Onderzoek aanvragen (3 tot 5 werkdagen).',
  'icr.search-page.choose-state': 'Selecteer een staat',
  'icr.search-page.choose-state.tooltip': 'Selecteer of typ een staat in',
  'icr.search-page.client-session.description':
    'Op dit moment voert u acties uit in de naam van “**{firstName} {lastName}**” en SAP ID “**{consumerId}**”',
  'icr.search-page.client-session.modal.description':
    'U gaat de sessie afsluiten waar u acties uitvoert in de naam van "**{firstName} {lastName}**" met SAP ID "**{consumerId}**". ',
  'icr.search-page.client-session.modal.title': 'Sluit sessie af',
  'icr.search-page.client-session.start': 'Start een klantsessie',
  'icr.search-page.client-session.stop': 'Sluit de klantsessie af',
  'icr.search-page.client-session.stop-session': 'Sluit sessie af',
  'icr.search-page.client-session.title': 'Klantsessie',
  'icr.search-page.client-session.tooltip':
    'Een administrator kan acties uitvoeren in naam van een klant, zoals een nieuwe onderzoek of een rapport aanvragen of het archief bekijken. Aangevraagde rapporten zijn beschikbaar in het archief van de klant.',
  'icr.search-page.client-validation.description':
    'Voor het e-mailadres in van de klant in om acties uit te voeren vanuit zijn/haar account.',
  'icr.search-page.client-validation.email-empty.message': 'Voer een emailadres in voor validatie',
  'icr.search-page.client-validation.email-validation.message': 'Ongeldig of onbekend e-mailadres',
  'icr.search-page.client-validation.email.label': 'E-mail',
  'icr.search-page.client-validation.email.text': 'Voer email adres in',
  'icr.search-page.client-validation.sap-id': 'SAP ID',
  'icr.search-page.client-validation.sapid-empty.message': 'Voer een SAP ID in voor validatie',
  'icr.search-page.client-validation.sapid-validation.message': 'Ongeldig of onbekend SAP ID',
  'icr.search-page.client-validation.sapid.label': 'SAP ID',
  'icr.search-page.client-validation.sapid.text': 'Voer SAP ID in',
  'icr.search-page.client-validation.start-session': 'Start session',
  'icr.search-page.client-validation.title': 'Klant identificatie',
  'icr.search-page.client-validation.validate': 'Valideren',
  'icr.search-page.description':
    'Landen hebben aanbevolen zoekmethodes. Voor een optimale ervaring hebben we voor elk land de meest gebruikte voor u voorgeselecteerd. U kunt op elk moment overschakelen naar alternatieve methodes afhankelijk van de informatie die u ter beschikking heeft.',
  'icr.search-page.domestic-country.link': 'Ga naar Company Data',
  'icr.search-page.domestic-country.message':
    '{country} maakt deel uit van uw lokaal abonnement. Ga naar Company Data om een bedrijf te zoeken.',
  'icr.search-page.fresh-investigation-btn': 'Nieuw Onderzoek',
  'icr.search-page.fresh-investigation-button.no-paygo':
    'Nieuwe onderzoeken maken geen deel uit van uw internationaal pakket. Contacteer ons voor meer informatie of voor een upgrade van uw pakket.',
  'icr.search-page.header': 'Zoek naar een bedrijf',
  'icr.search-page.non-domestic-country.message.BE':
    'Belgische rapporten zijjn beschikbaar via Company Data.',
  'icr.search-page.non-domestic-country.message.GB':
    'Britse rapporten zijn beschikbaar via Company Data.',
  'icr.search-page.non-domestic-country.message.NL':
    'Nederlandse rapporten zijn beschikbaar via Company Data.',
  'icr.search-page.service-level-investigation.domestic.message':
    'Dit land is op dit moment niet in onze database beschikbaar.<br><br>\nHeeft u vragen of hulp nodig, contacteer dan ons Customer Service team<a href="mailto:{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}">{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}</a> or on {country, select, gb {+44 (0)20 8515 1400} nl {+31 (020) 567 9501} other {+32 (03) 280 88 80}}.',
  'icr.search-page.service-level-investigation.link': 'Vraag een Nieuw Onderzoek aan',
  'icr.search-page.service-level-investigation.message':
    '\nDit land zit nog niet in onze database. Om informatie over een specifiek bedrijf in dit land te ontvangen, dient u ons formulier voor een Nieuw Onderzoek in te vullen.',
  'icr.search-page.service-level-investigation.no-paygo':
    'Een rapport voor dit land is alleen te verkrijgen via een nieuw onderzoek. Contacteer ons voor meer informatie of voor een upgrade van uw pakket.',
  'icr.search-page.switch-to-client': 'Switch to client account',
  'icr.search-page.switch-to-personal': 'Switch to personal account',
  'icr.search-results.button.check-availability': 'Rapport aanvragen',
  'icr.search-results.button.view-report': 'Bekijk rapport',
  'icr.search-results.latest-report-date': 'Date of latest report',
  'icr.search-results.modal.add-client-note': 'Voeg een referentie toe',
  'icr.search-results.modal.address': 'Adres',
  'icr.search-results.modal.availability-error':
    'Dit rapport is niet online beschikbaar. Gelieve contact op te nemen met uw accountmanager om uw pakket aan te passen, zodat u een nieuw onderzoek kunt aanvragen.',
  'icr.search-results.modal.availability-error-fresh':
    "Dit rapport is niet online beschikbaar. U kunt een nieuw onderzoek aanvragen via de knop 'NIEUW ONDERZOEK'.",
  'icr.search-results.modal.availability-immediate':
    "U kunt een up-to-date online rapport aanvragen via de knop ‘RAPPORT AANVRAGEN'.",
  'icr.search-results.modal.availability-immediate-fresh':
    'Voor veel bedrijven is een rapport direct beschikbaar. U kunt ook een nieuw onderzoek aanvragen. In dat geval leveren we een actueel rapport voor een bedrijf.',
  'icr.search-results.modal.availability-immediate-update':
    'Onze leverancier heeft aangegeven dat dit rapport niet up-to-date is. U kunt het nu opvragen en we sturen u een geactualiseerde versie van zodra die beschikbaar is.',
  'icr.search-results.modal.availability-immediate-update-fresh':
    "Onze leverancier heeft aangegeven dat dit rapport niet up-to-date is. U kunt het nu opvragen en we sturen u een geactualiseerde versie van zodra die beschikbaar is.\nOf, afhankelijk van de urgentie, kunt u een nieuw onderzoek aanvragen via de knop 'NIEUW ONDERZOEK'.",
  'icr.search-results.modal.availability.outdated-report-body':
    'De geactualiseerde versie van dit rapport is binnenkort beschikbaar. Volg de status in uw Archief.',
  'icr.search-results.modal.availability.outdated-report-title':
    'Uw geactualiseerd rapport wordt samengesteld',
  'icr.search-results.modal.clientNote.label': 'Voeg een referentie toe',
  'icr.search-results.modal.clientNote.placeholder': 'Voeg een referentie toe aan uw rapport',
  'icr.search-results.modal.clientNote.tooltip':
    'Hier kunt u een referentie aan uw rapport toevoegen. Deze referentie is zichtbaar in uw archief.',
  'icr.search-results.modal.downloading.heading': 'Even geduld terwijl we de dowload voorbereiden',
  'icr.search-results.modal.downloading.step0': 'de aanvraag versturen',
  'icr.search-results.modal.downloading.step1': 'de data verzamelen',
  'icr.search-results.modal.downloading.step2': 'de informatie hergroeperen',
  'icr.search-results.modal.downloading.step3': 'het rapport samenstellen',
  'icr.search-results.modal.downloading.step4': 'het rapport bewaren',
  'icr.search-results.modal.fresh-investigation-button': 'Nieuw Onderzoek',
  'icr.search-results.modal.identifier': 'Identificatie',
  'icr.search-results.modal.not-available':
    'Voor dit bedrijf is geen rapport direct beschikbaar. U kunt een nieuw onderzoek aanvragen. In dat geval leveren we een actueel rapport voor het bedrijf.',
  'icr.search-results.modal.request-report-button': 'Rapport aanvragen',
  'icr.search-results.modal.tooltip':
    'Voor veel bedrijven is een rapport direct beschikbaar. U kunt ook een nieuw onderzoek aanvragen. In dat geval leveren we een actueel rapport voor een bedrijf.',
  'icr.search.advanced.city.placeholder': 'Woonplaats',
  'icr.search.advanced.post-code.placeholder': 'Postcode',
  'icr.search.advanced.toggle-link': 'Uitgebreid zoeken',
  'icr.search.company.searchButton': 'Zoek bedrijf',
  'icr.search.search-bar.placeholder': 'Vul informatie over het bedrijf in',
  'icr.search.search-method.all': 'Gecombineerd',
  'icr.search.search-method.identifier': 'Identificatie',
  'icr.search.search-method.KEYWORD': 'Trefwoord',
  'icr.search.search-method.keyword': 'Trefwoord',
  'icr.search.search-method.NAME': 'Naam',
  'icr.search.search-method.name': 'Naam',
  'icr.search.search-method.NAME_AND_CITY': 'Gecombineerd',
  'icr.search.search-method.REGISTRATION_ID': 'Identificatie',
  'icr.search.search-methods-label': 'Zoekmethode',
  'icr.state-select.placeholder': 'Selecteer een staat',
  importCompanies: 'Importeer bedrijven',
  'importCompanies.steps.addToList': 'Stap 4/4: Voeg bedrijven toe aan lijst(en)',
  'importCompanies.steps.downloadEnrichedFile': 'Stap 4/4: Download verrijkt bestand',
  'importCompanies.steps.mapping': "Stap 2/4: Koppel uw kolommen aan Graydon's velden",
  'importCompanies.steps.matchingInProgress': 'Stap 3/4: Matching is bezig',
  'importCompanies.steps.upload': 'Step 1/4: Upload',
  'info.404.cannot-be-found':
    'De pagina die u zoekt, kunnen we niet vinden. Controleer of u de juiste url heeft ingetypt.',
  'info.404.does-not-exist':
    'Dit is niet de pagina waar u naar op zoek bent. Of misschien wel, maar hij bestaat niet. Maar, waar bent u dan nu...',
  'info.404.go-back': 'Terug',
  'info.404.go-home': 'Breng me terug',
  'info.404.go-to-graydon-insights': 'Ga terug naar de Graydon Insights homepagina.',
  'info.404.oops': 'Oeps',
  'info.404.page-not-found': 'Pagina niet gevonden',
  'info.associated.no-data': 'Geen data',
  'info.no-access.title': 'Geen toegang',
  'info.no-data': 'Helaas is er geen data beschikbaar',
  'int-reports.country-search-in': 'Zoek in',
  'int-reports.country.al': 'Albanië',
  'int-reports.country.at': 'Oostenrijk',
  'int-reports.country.ba': 'Bosnië en Herzegovina',
  'int-reports.country.be': 'België',
  'int-reports.country.bg': 'Bulgarije',
  'int-reports.country.ch': 'Zwitserland',
  'int-reports.country.cz': 'Tsjechië',
  'int-reports.country.de': 'Duitsland',
  'int-reports.country.ee': 'Estland',
  'int-reports.country.es': 'Spanje',
  'int-reports.country.fr': 'Frankrijk',
  'int-reports.country.gb': 'Verenigd Koninkrijk',
  'int-reports.country.hr': 'Kroatië',
  'int-reports.country.hu': 'Hongarije',
  'int-reports.country.ie': 'Ierland',
  'int-reports.country.it': 'Italië',
  'int-reports.country.kv': 'Kosovo',
  'int-reports.country.lt': 'Litouwen',
  'int-reports.country.lu': 'Luxemburg',
  'int-reports.country.lv': 'Letland',
  'int-reports.country.md': 'Moldavië',
  'int-reports.country.me': 'Montenegro',
  'int-reports.country.mk': 'Macedonië',
  'int-reports.country.nl': 'Nederland',
  'int-reports.country.pl': 'Polen',
  'int-reports.country.pt': 'Portugal',
  'int-reports.country.ro': 'Roemenië',
  'int-reports.country.rs': 'Servië',
  'int-reports.country.ru': 'Rusland',
  'int-reports.country.se': 'Zweden',
  'int-reports.country.si': 'Slovenië',
  'int-reports.country.sk': 'Slovakije',
  'int-reports.country.ua': 'Oekraïne',
  'int-reports.country.us': 'Verenigde Staten',
  'intl-report.autocomplete.no-results': 'Geen overeenkomsten voor "{keyword}"',
  'intl-report.category.otherFields': 'Overige velden',
  'intl-report.category.recommendedFields': 'Aanbevolen velden',
  'intl-report.category.requiredFields': 'Verplichte velden',
  'intl-report.check.for.archived.download': 'Download dit rapport',
  'intl-report.check.for.archived.no-reports': 'Geen rapporten gevonden in archief',
  'intl-report.company.active': 'Actief',
  'intl-report.company.al.amountAdvisedCaption':
    'Het geadviseerde bedrag is per 60 dagen; alle leveranciers, in de veronderstelling dat de debiteur 5 schuldeisers heeft. Het kredietplafond in dit land bedraagt €2,500,000',
  'intl-report.company.amountAdvisedCaption': 'De maximale kredietlimiet voor dit bedrijf',
  'intl-report.company.at.amountAdvisedCaption':
    'Het geadviseerde bedrag is een maandelijkse kredietlimiet per leverancier. Het kredietplafond in dit land bedraagt € 1,000,000',
  'intl-report.company.ba.amountAdvisedCaption':
    'Het geadviseerde bedrag is per 60 dagen; alle leveranciers, in de veronderstelling dat de debiteur 5 schuldeisers heeft. Het kredietplafond in dit land bedraagt €2,500,000',
  'intl-report.company.be.amountAdvisedCaption':
    'Het geadviseerde bedrag is een maximaal kredietrisico over 12 maanden, alle leveranciers. Er is geen kredietplafond in dit land.',
  'intl-report.company.bg.amountAdvisedCaption':
    'Het geadviseerde bedrag is per 60 dagen; alle leveranciers, in de veronderstelling dat de debiteur 5 schuldeisers heeft. Het kredietplafond in dit land bedraagt €2,500,000',
  'intl-report.company.ch.amountAdvisedCaption':
    'Het geadviseerde bedrag is een maximaal kredietrisico, per leverancier, op een willekeurig tijdstip. Het kredietplafond in dit land bedraagt CHF 1,000.000.',
  'intl-report.company.check': 'Bekijk rapport voor dit bedrijf',
  'intl-report.company.commonRating': 'Algemene Rating',
  'intl-report.company.commonRiskClassCaption': 'Het risico van niet-betaling voor dit bedrijf',
  'intl-report.company.commonRiskClassDescription':
    'Dit is een Europese norm om het kredietrisico van een bedrijf te bepalen en wordt uitgedrukt als een waarde op een schaal van 1 t/m 5. Hoe hoger de waarde, hoe lager het risico. Waarde ‘9’ betekent financiële moeilijkheden. Bij een waarde van ‘8’ is het niet mogelijk om een risico te bepalen.',
  'intl-report.company.creditRatings': 'Kredietratings',
  'intl-report.company.creditRatings.title': 'Kredietrating',
  'intl-report.company.cz.amountAdvisedCaption':
    'Het geadviseerde bedrag is een maximaal kredietrisico, per leverancier, over 12 maanden. Als er geen financiële gegevens beschikbaar zijn bedraagt het kredietplafond in dit land CZK 39 mil. Indien er rekeningen beschikbaar zijn, is er geen kredietlimiet.',
  'intl-report.company.de.amountAdvisedCaption':
    'Het geadviseerde bedrag is een kredietadvies per transactie. Er is geen kredietplafond in dit land.',
  'intl-report.company.ee.amountAdvisedCaption':
    'Het geadviseerde bedrag is per 60 dagen; alle leveranciers, in de veronderstelling dat de debiteur 5 schuldeisers heeft. Het kredietplafond in dit land bedraagt €2,500,000',
  'intl-report.company.error': 'Fout bij het ophalen van de data, probeer het later opnieuw',
  'intl-report.company.error.extended':
    'Deze informatie is helaas niet online beschikbaar.<br /><br />Onze afdeling Customer Care helpt u graag verder, via <a href="mailto:{country, select, gb {customerservices@graydon.co.uk} be {support@graydon.be} other {service@graydon.nl}}">{country, select, gb {customerservices@graydon.co.uk} be {support@graydon.be} other {service@graydon.nl}}</a> of {country, select, gb {+44 (020) 8515 1400} be {+32 (03) 280 88 80} other {+31 (020) 567 9501}}.',
  'intl-report.company.es.amountAdvisedCaption':
    'Het geadviseerde bedrag is een maximaal kredietrisico op een willekeurig tijdstip. Het kredietplafond in dit land bedraagt € 6,000,000.',
  'intl-report.company.fr.amountAdvisedCaption':
    'Het geadviseerde bedrag is een kredietadvies per transactie. Het kredietplafond in dit land bedraagt € 300,000.',
  'intl-report.company.gb.amountAdvisedCaption':
    'Het geadviseerde bedrag is een maandelijkse kredietlimiet, per leverancier. Het kredietplafond in dit land bedraagt £ 1,125,000.',
  'intl-report.company.hr.amountAdvisedCaption':
    'Het geadviseerde bedrag is per 60 dagen; alle leveranciers, in de veronderstelling dat de debiteur 5 schuldeisers heeft. Het kredietplafond in dit land bedraagt €2,500,000',
  'intl-report.company.hu.amountAdvisedCaption':
    'Het geadviseerde bedrag is per 60 dagen; alle leveranciers, in de veronderstelling dat de debiteur 5 schuldeisers heeft. Het kredietplafond in dit land bedraagt €2,500,000',
  'intl-report.company.ie.amountAdvisedCaption':
    'Het geadviseerde bedrag is een maximaal kredietrisico, per leverancier, op een willekeurig tijdstip. Het kredietplafond in dit land bedraagt € 7,500,000.',
  'intl-report.company.inDefault': 'Standaardwaarde',
  'intl-report.company.inDefaultOrInsolvent': 'Standaardwaarde of insolvent',
  'intl-report.company.insolvent': 'Insolvent',
  'intl-report.company.it.amountAdvisedCaption':
    'Het geadviseerde bedrag is een kredietadvies per transactie. Het kredietplafond in dit land bedraagt € 1,000,000.',
  'intl-report.company.localRating': 'Lokale beoordeling',
  'intl-report.company.localRatingCaption': 'De kans op wanbetaling voor dit bedrijf',
  'intl-report.company.lt.amountAdvisedCaption':
    'Het geadviseerde bedrag is per 60 dagen; alle leveranciers, in de veronderstelling dat de debiteur 5 schuldeisers heeft. Het kredietplafond in dit land bedraagt €2,500,000',
  'intl-report.company.lu.amountAdvisedCaption':
    'Het geadviseerde bedrag is een kredietadvies per transactie. Er is geen kredietplafond in dit land.',
  'intl-report.company.lv.amountAdvisedCaption':
    'Het geadviseerde bedrag is per 60 dagen; alle leveranciers, in de veronderstelling dat de debiteur 5 schuldeisers heeft. Het kredietplafond in dit land bedraagt €2,500,000',
  'intl-report.company.maxCreditLimit': 'Maximaal Kredietlimiet',
  'intl-report.company.maxCreditLimitDescription':
    'Dit is het hoogste aanbevolen bedrag dat toegekend wordt conform de Graydon korte termijn kredietrating. Dit bedrag kan variëren per regio van 500 euro tot 6 miljoen euro.',
  'intl-report.company.maxCreditLimitNotAvailable': 'Niet beschikbaar',
  'intl-report.company.md,amountAdvisedCaption':
    'Het geadviseerde bedrag is per 60 dagen; alle leveranciers, in de veronderstelling dat de debiteur 5 schuldeisers heeft. Het kredietplafond in dit land bedraagt €2,500,000',
  'intl-report.company.me.amountAdvisedCaption':
    'Het geadviseerde bedrag is per 60 dagen; alle leveranciers, in de veronderstelling dat de debiteur 5 schuldeisers heeft. Het kredietplafond in dit land bedraagt €2,500,000',
  'intl-report.company.mk.amountAdvisedCaption':
    'Het geadviseerde bedrag is per 60 dagen; alle leveranciers, in de veronderstelling dat de debiteur 5 schuldeisers heeft. Het kredietplafond in dit land bedraagt €2,500,000',
  'intl-report.company.nl.amountAdvisedCaption':
    'Het geadviseerde bedrag is een maximaal kredietrisico per leverancier over 12 maanden. Het kredietplafond in dit land bedraagt € 5,000,000.',
  'intl-report.company.noRating': 'Geen waardering beschikbaar',
  'intl-report.company.paymentBehaviour': 'Betalingsgedrag',
  'intl-report.company.paymentBehaviourCaption':
    'Incidenten met protesten, wanbetalingen en andere kwalificaties van betalingsgedrag',
  'intl-report.company.paymentBehaviourDescription':
    'Informatie over het betalingsgedrag vertelt u hoe een bedrijf op dit moment betaalt. Incidenten met protesten, wanbetalingen en andere kwalificaties van betalingsgedrag geven inzicht in de evolutie van de betalingen, de cashpositie en de betalingsintenties.',
  'intl-report.company.paymentBehaviourUnknown': 'Niet beschikbaar',
  'intl-report.company.pl.amountAdvisedCaption':
    'Het geadviseerde bedrag is een maximaal kredietrisico over 12 maanden, alle leveranciers. Er is geen kredietplafond in dit land.',
  'intl-report.company.protestsAndNonPayments':
    '{protestsAndNonPayments} Protesten en/of Wanbetalingen geregistreerd',
  'intl-report.company.providerRiskClassDescription':
    'Deze standaard varieert en wordt gebruikt om het kredietrisico van een bedrijf vast te stellen per land. Het risico wordt uitgedrukt op verschillende schalen. Gelieve Graydon te contacteren voor meer inlichtingen over deze rating.',
  'intl-report.company.pt.amountAdvisedCaption':
    'Het geadviseerde bedrag is een maximaal kredietrisico op een willekeurig tijdstip. Het kredietplafond in dit land bedraagt € 6,000,000.',
  'intl-report.company.rating.no-description': 'Geen beschrijving',
  'intl-report.company.riskClasses': 'Risicoklassen',
  'intl-report.company.ro.amountAdvisedCaption':
    'Het geadviseerde bedrag is per 60 dagen; alle leveranciers, in de veronderstelling dat de debiteur 5 schuldeisers heeft. Het kredietplafond in dit land bedraagt €2,500,000',
  'intl-report.company.rs.amountAdvisedCaption':
    'Het geadviseerde bedrag is per 60 dagen; alle leveranciers, in de veronderstelling dat de debiteur 5 schuldeisers heeft. Het kredietplafond in dit land bedraagt €2,500,000',
  'intl-report.company.ru.amountAdvisedCaption':
    'Het geadviseerde bedrag is per 60 dagen; alle leveranciers, in de veronderstelling dat de debiteur 5 schuldeisers heeft. Het kredietplafond in dit land bedraagt €2,500,000',
  'intl-report.company.se.amountAdvisedCaption':
    'Het geadviseerde bedrag is een maandelijkse kredietlimiet, alle leveranciers. Het kredietplafond in dit land bedraagt SEK 10 mil.',
  'intl-report.company.sectorCode': 'Sector code',
  'intl-report.company.si.amountAdvisedCaption':
    'Het geadviseerde bedrag is per 60 dagen; alle leveranciers, in de veronderstelling dat de debiteur 5 schuldeisers heeft. Het kredietplafond in dit land bedraagt €2,500,000',
  'intl-report.company.sk.amountAdvisedCaption':
    'Het geadviseerde bedrag is per 60 dagen; alle leveranciers, in de veronderstelling dat de debiteur 5 schuldeisers heeft. Het kredietplafond in dit land bedraagt €2,500,000',
  'intl-report.company.ua.amountAdvisedCaption':
    'Het geadviseerde bedrag is per 60 dagen; alle leveranciers, in de veronderstelling dat de debiteur 5 schuldeisers heeft. Het kredietplafond in dit land bedraagt €2,500,000',
  'intl-report.company.us.amountAdvisedCaption':
    'Het geadviseerde bedrag is een maximaal kredietrisico, per leverancier, op een willekeurig tijdstip. Het kredietplafond in dit land bedraagt $ 1,000,000.',
  'intl-report.date': 'Datum',
  'intl-report.download.pdf.full-report': 'Download volledig rapport',
  'intl-report.download.pdf.summary': 'Download samenvatting',
  'intl-report.financialSummary.profitLoss': 'Winst Verlies',
  'intl-report.financialSummary.revenue': 'Omzet',
  'intl-report.financialSummary.summaryDate': 'Financiële gegevens uit {data}',
  'intl-report.financialSummary.totalEquity': 'Totaal eigen vermogen',
  'intl-report.financialSummary.workingCapital': 'Werkkapitaal',
  'intl-report.managementPositions.authorization': 'Volmacht',
  'intl-report.managementPositions.birthDate': 'Geboortedatum',
  'intl-report.managementPositions.companyName': 'Naam onderneming',
  'intl-report.managementPositions.firstName': 'Voornaam',
  'intl-report.managementPositions.fullName': 'Volledige naam',
  'intl-report.managementPositions.initials': 'Initialen',
  'intl-report.managementPositions.lastName': 'Achternaam',
  'intl-report.managementPositions.management': 'Bedrijfsleiding',
  'intl-report.managementPositions.middleName': 'Tweede naam',
  'intl-report.managementPositions.prefix': 'Prefix',
  'intl-report.managementPositions.startingDate': 'Benoemingsdatum',
  'intl-report.managementPositions.title': 'Titel',
  'intl-report.pdf.balance': 'Balans',
  'intl-report.pdf.employee-data': 'Personeelsgegevens',
  'intl-report.pdf.liabilityDeclarations': 'Aansprakelijkheidsverklaringen',
  'intl-report.pdf.unknown.value': 'Onbekend',
  'intl-report.protestsAndNonPayments.amount': 'Bedrag',
  'intl-report.protestsAndNonPayments.creditor': 'Crediteur',
  'intl-report.protestsAndNonPayments.date': 'Datum',
  'intl-report.protestsAndNonPayments.description': 'Omschrijving',
  'intl-report.protestsAndNonPayments.noEventsRegisterdForThisType':
    'Geen incidenten geregistreerd voor dit type',
  'intl-report.protestsAndNonPayments.noEventsRegistered':
    'Geen protesten en/of wanbetalingen geregistreerd',
  'intl-report.protestsAndNonPayments.title': 'Betalingen: Protesten & Wanbetalingen',
  'intl-report.protestsAndNonPayments.type': 'Type',
  'intl-report.search-hint.adress': 'Gebruik alleen straatnaam',
  'intl-report.search-hint.builder': 'Alleen cijfers',
  'intl-report.search-hint.city': 'Gebruik volledige naam',
  'intl-report.search-hint.companyId': 'Het unieke nummer in de Graydon database',
  'intl-report.search-hint.companyRegisterNumber':
    'In de meeste gevallen zal dit het registratienummer bij de Kamer van Koophandel zijn',
  'intl-report.search-hint.name': 'Gebruik volledige naam',
  'intl-report.search-hint.postCode': 'Gebruik de volledige postcode',
  'intl-report.search-hint.registeredName': 'Gebruik volledige naam',
  'intl-report.search-hint.state': 'Gebruik de volledige provincienaam',
  'intl-report.search-hint.street': 'Gebruik alleen straatnaam',
  'intl-report.search-hint.tradingName': 'De naam waaronder een bedrijf handelt',
  'intl-report.search-hint.vat': 'Laat FR en de eerste 2 cijfers weg',
  'intl-report.search-hint.vatNumber': 'Laat FR en de eerste 2 cijfers weg',
  'intl-report.search-key.adress': 'Adres',
  'intl-report.search-key.builder': 'Builder',
  'intl-report.search-key.city': 'Stad',
  'intl-report.search-key.companyId': 'ID van het bedrijf',
  'intl-report.search-key.companyRegisterNumber': 'Registratienummer',
  'intl-report.search-key.postCode': 'Postcode',
  'intl-report.search-key.registeredName': 'Naam',
  'intl-report.search-key.state': 'Provincie',
  'intl-report.search-key.street': 'Straat',
  'intl-report.search-key.tradingName': 'Handelsnaam',
  'intl-report.search-key.vat': 'BTW',
  'intl-report.search-key.vatNumber': 'BTW',
  'intl-report.search.hide-other-fields': 'Verberg andere velden',
  'intl-report.search.number.results':
    '{num, plural, =1 {1 item} =50 {50+ items} other {{num} items}} gevonden',
  'intl-report.search.recommended': 'Zoek op één of meerdere aanbevolen tags',
  'intl-report.search.show-other-fields': 'Toon andere velden',
  'intl-report.search.state.ak': 'Alaska',
  'intl-report.search.state.al': 'Alabama',
  'intl-report.search.state.ar': 'Arkansas',
  'intl-report.search.state.az': 'Arizona',
  'intl-report.search.state.ca': 'Californië',
  'intl-report.search.state.co': 'Colorado',
  'intl-report.search.state.ct': 'Connecticut',
  'intl-report.search.state.de': 'Delaware',
  'intl-report.search.state.fl': 'Florida',
  'intl-report.search.state.ga': 'Georgia',
  'intl-report.search.state.hi': 'Hawaii',
  'intl-report.search.state.ia': 'Iowa',
  'intl-report.search.state.id': 'Idaho',
  'intl-report.search.state.il': 'Illinois',
  'intl-report.search.state.in': 'Indiana',
  'intl-report.search.state.ks': 'Kansas',
  'intl-report.search.state.ky': 'Kentucky',
  'intl-report.search.state.la': 'Louisiana',
  'intl-report.search.state.ma': 'Massachusetts',
  'intl-report.search.state.md': 'Maryland',
  'intl-report.search.state.me': 'Maine',
  'intl-report.search.state.mi': 'Michigan',
  'intl-report.search.state.mn': 'Minnesota',
  'intl-report.search.state.mo': 'Missouri',
  'intl-report.search.state.ms': 'Mississippi',
  'intl-report.search.state.mt': 'Montana',
  'intl-report.search.state.nc': 'North Carolina',
  'intl-report.search.state.nd': 'North Dakota',
  'intl-report.search.state.ne': 'Nebraska',
  'intl-report.search.state.nh': 'New Hampshire',
  'intl-report.search.state.nj': 'New Jersey',
  'intl-report.search.state.nm': 'New Mexico',
  'intl-report.search.state.nv': 'Nevada',
  'intl-report.search.state.ny': 'New York',
  'intl-report.search.state.oh': 'Ohio',
  'intl-report.search.state.ok': 'Oklahoma',
  'intl-report.search.state.or': 'Oregon',
  'intl-report.search.state.pa': 'Pennsylvania',
  'intl-report.search.state.ri': 'Rhode Island',
  'intl-report.search.state.sc': 'South Carolina',
  'intl-report.search.state.sd': 'South Dakota',
  'intl-report.search.state.tn': 'Tennessee',
  'intl-report.search.state.tx': 'Texas',
  'intl-report.search.state.ut': 'Utah',
  'intl-report.search.state.va': 'Virginia',
  'intl-report.search.state.vt': 'Vermont',
  'intl-report.search.state.wa': 'Washington',
  'intl-report.search.state.wi': 'Wisconsin',
  'intl-report.search.state.wv': 'West Virginia',
  'intl-report.search.state.wy': 'Wyoming',
  'intl-report.search.title': 'Zoek',
  'intl-reports.consumption.downloads':
    '{downloads, plural, =0 {# rapporten} one {# rapporten} other {# rapporten}}',
  'intl-reports.consumption.heatmap': 'Rapporten per land',
  'intl-reports.consumption.heatmap.month': 'voor de huidige maand',
  'intl-reports.pdf.from': 'van',
  'intl-reports.pdf.liabilityDeclaration.providedBy': 'Geleverd door',
  'intl-reports.pdf.liabilityDeclaration.providedFor': 'Geleverd voor',
  'intl-reports.pdf.number-of-employees': 'Aantal werknemers',
  'intl-reports.pdf.untill': 'tot',
  'la.agedDebt': 'Aantal dagen buiten termijn',
  'la.agedDebt.DAYS_0_30': '0-30 dagen',
  'la.agedDebt.DAYS_31_60': '31-60 dagen',
  'la.agedDebt.DAYS_61_90': '61-90 dagen',
  'la.agedDebt.DAYS_90_PLUS': '90+ dagen',
  'la.agedDebt.WITHIN_TERMS': 'Binnen termijn',
  'la.agedDebtDistribution': 'Aantal dagen buiten termijn',
  'la.agedDebtDistribution.hint':
    'Hier ziet u het totaal uitstaand bedrag per categorie volgens het aantal dagen buiten termijn. Klik op een segment in de grafiek of op de legende om debiteuren te filteren.',
  'la.amountMatched': 'U bekijkt {percentage} van het aantal debiteuren met openstaande bedragen',
  'la.augurScore': 'Augur score',
  'la.backHome': 'Terug naar Ledger Management',
  'la.balanceOutstanding': 'Uitstaand saldo',
  'la.companiesInLedger': 'Bedrijvenoverzicht',
  'la.companiesInLedger.agedDebt': 'Aantal dagen buiten termijn',
  'la.companiesInLedger.agedDebt.hint':
    'In dit overzicht vindt u uw klanten terug met hun openstaande bedragen, verdeeld volgens verchillende periodes. Gebruik de pijltjes onder elke periode om uw klanten te rangschikken of kies één of meerdere segmenten in bovenstaande risicomatrix om uw selectie te verfijnen.',
  'la.companiesInLedger.difference': 'Verschil',
  'la.companiesInLedger.download': 'Download',
  'la.companiesInLedger.graydonData': 'Graydon data',
  'la.companiesInLedger.hint':
    '<b>Graydon Rating</b><br/>\nDe kans dat een bedrijf binnen nu en 12 maanden niet aan zijn betalingsverplichtingen kan voldoen.<br/>\n<br/>\n<b>Kans op opheffing</b><br/>\nDe kans op opheffing voorspelt hoe waarschijnlijk het bedrijf binnen 12 maanden wordt opgeheven.<br/>\n<br/>\n<b>Groeiverwachting</b><br/>\nDe kans op groei brengt de verwachte groei van een bedrijf in de komende 12 maanden in kaart.',
  'la.companiesInLedger.id': 'Graydon ID',
  'la.companiesInLedger.name': 'Naam',
  'la.companySearch.hint':
    'Ingeval van onverwachte resultaten, kan het sleutelwoord matchen met de details van de debiteur. Open de details van de onderneming om de details van de debiteur te zien.',
  'la.companySizeFilter.from': 'Van',
  'la.companySizeFilter.heading': 'Bedrijfsgrootte',
  'la.companySizeFilter.includeUnknown': 'Bedrijven met onbekende omvang toevoegen',
  'la.companySizeFilter.to': 'Tot',
  'la.companyTable.limitNote':
    'Let op: uw (gefilterde) bedrijvenoverzicht bevat {totalAmount} klanten. We tonen alleen de eerste {shownAmount}.',
  'la.creditFlag': 'Kredietvlag',
  'la.creditFlag.G': 'Groen: Geen bezwaar tegen kredietfaciliteiten',
  'la.creditFlag.NA': 'Informatie niet beschikbaar',
  'la.creditFlag.O':
    'Oranje: Kredietfaciliteit kan verleend worden maar moet in de gaten gehouden worden.',
  'la.creditFlag.R': 'Rood: Verlening van een kredietfaciliteit wordt niet geadviseerd.',
  'la.creditLimit': 'Kredietlimiet',
  'la.debtor.customerID': 'Referentie debiteur',
  'la.debtor.customerID.multiple': 'Meerdere',
  'la.debtor.customerName': 'Naam debiteur',
  'la.debtor.freeFields': 'Aanvullende debiteurinformatie',
  'la.discontinuation': 'Kans op opheffing',
  'la.discontinuationScore.1': 'Zeer hoog',
  'la.discontinuationScore.2': 'Hoog',
  'la.discontinuationScore.3': 'Hoog',
  'la.discontinuationScore.4': 'Neutraal',
  'la.discontinuationScore.5': 'Neutraal',
  'la.discontinuationScore.6': 'Neutraal',
  'la.discontinuationScore.7': 'Laag',
  'la.discontinuationScore.8': 'Laag',
  'la.discontinuationScore.9': 'Zeer laag',
  'la.discontinuationScore.NR': 'Niet beoordeeld',
  'la.filterBar.showCompanies': 'Toon bedrijven',
  'la.filteredSelection': 'Gefilterde selectie',
  'la.filteredSelection.removeAll': 'Verwijder alle filters',
  'la.filters': 'Filters',
  'la.freeField': 'Vrij veld {n}',
  'la.freeField.placeholder': 'Kies filter {n}',
  'la.graphHasNegativeValues': 'Wegens negatieve bedragen kan de grafiek niet weergegeven worden.',
  'la.graydonRating': 'Graydon Rating',
  'la.graydonRating.HIGH': 'CCC/CC/C',
  'la.graydonRating.hint':
    'Hier ziet u de Graydon Rating. Klik op een segment in in de grafiek of op de legende om debiteuren te filteren op rating.',
  'la.graydonRating.INSOLVENT': 'D - Failliet',
  'la.graydonRating.LOW': 'AAA/AA/A',
  'la.graydonRating.MEDIUM': 'BBB/BB/B',
  'la.graydonRating.NR': 'Niet beoordeeld',
  'la.growthScore': 'Groeiverwachting',
  'la.growthScore.1': 'Sterke krimp',
  'la.growthScore.2': 'Krimp',
  'la.growthScore.3': 'Neutraal',
  'la.growthScore.4': 'Groei',
  'la.growthScore.5': 'Sterke groei',
  'la.growthScore.99': 'Niet te voorspellen',
  'la.growthScore.NR': 'Niet beoordeeld',
  'la.hideFilters': 'Verberg filters',
  'la.multiscore': 'Multiscore',
  'la.myCustomers': 'Mijn klanten',
  'la.nrInvoices': 'Aantal facturen',
  'la.paymentScore': 'Betaalscore',
  'la.paymentScore.1': 'De betaalvoorwaarden worden niet gerespecteerd',
  'la.paymentScore.2': 'Onder het gemiddelde',
  'la.paymentScore.3': 'Gemiddeld',
  'la.paymentScore.4': 'Goed betaalgedrag',
  'la.paymentScore.null': 'Onbekend',
  'la.pdRating.A': 'Lage kans op wanbetaling',
  'la.pdRating.AA': 'Lage kans op wanbetaling',
  'la.pdRating.AAA': 'Kans op wanbetaling is zeer laag',
  'la.pdRating.B': 'Neutrale kans op wanbetaling',
  'la.pdRating.BB': 'Neutrale kans op wanbetaling',
  'la.pdRating.BBB': 'Neutrale kans op wanbetaling',
  'la.pdRating.C': 'Kans op wanbetaling is zeer hoog',
  'la.pdRating.CC': 'Kans op wanbetaling is hoger dan het gemiddelde',
  'la.pdRating.CCC': 'Kans op wanbetaling is hoger dan het gemiddelde',
  'la.pdRating.D': 'De onderneming is failliet',
  'la.pdRating.NR': 'Er kan geen rating vastgesteld worden',
  'la.preferences': 'Voorkeuren',
  'la.preferences.cancel': 'Annuleren',
  'la.preferences.companyTable.description':
    'Pas de zichtbaarheid van één of meerdere kolommen aan in de tabel.',
  'la.preferences.save': 'Bewaar voorkeuren',
  'la.riskDistribution': 'Risicodistributie',
  'la.riskDistribution.high': 'Hoog',
  'la.riskDistribution.hint':
    'De risicoverdeling is een combinatie van de Graydon Rating en aantal dagen buiten termijn.<br><br>Tip: Klik op een segment in de grafiek of op de legende om debiteuren te filteren op risiconiveau.',
  'la.riskDistribution.low': 'Laag',
  'la.riskDistribution.medium': 'Gemiddeld',
  'la.riskDistribution.nr': 'Niet beoordeeld',
  'la.riskMatrix': 'Risicomatrix',
  'la.riskMatrix.hint':
    "De risicomatrix toont uitstaande bedragen en het aantal bedrijven met uitstaande bedragen. De bedragen zijn uitgesplitst in 'aantal dagen buiten betalingstermijn' en de 'Graydon Rating' van het bedrijf.<br/><br/>Het risiconiveau is een combinatie van aantal dagen buiten termijn en Graydon Rating. Dit niveau wordt aangegeven met een kleur (groen=laag, oranje=gemiddeld, rood=hoog). Klik op het icoon instellingen boven de matrix om deze niveaus zelf in te stellen.",
  'la.riskMatrixPreferences.explanation':
    'Klik op de cellen in de matrix om het risiconiveau te definiëren voor elke combinatie van Graydon Rating en betaling',
  'la.riskMatrixPreferences.heading': 'Bepaal risiconiveaus',
  'la.sector': 'Sector',
  'la.sector.placeholder': 'Kies een sector',
  'la.showFilters': 'Toon filters',
  'la.showUnmatched': 'Toon niet gematchte debiteuren',
  'la.totals.averageDaysOverdue': 'gemiddeld aantal dagen buiten termijn',
  'la.totals.lastUpdated': 'Voor het laatst geïmporteerd',
  'la.totals.matchCount': 'debiteuren met openstaande bedragen',
  'la.totals.totalOpen': 'totaal openstaand bedrag',
  'la.totals.totalOverdue': 'buiten termijn',
  'la.totals.totalWithinTerms': 'binnen de termijnen',
  'la.unmatch.button': 'Ontkoppel debiteur(en) van relatie',
  'la.unmatch.heading': 'Ontkoppel debiteur(en) van relatie',
  'la.unmatch.text':
    'Indien u <b>‘{organizationName}’</b> niet herkent als klant, dan kan het zijn dat deze verkeerd gematched is. Gelieve deze debiteur los te koppelen van <b>‘{organizationName}’</b>.',
  'la.unmatchDone.heading': 'Ontkoppelde debiteur(en) van relatie.',
  'la.unmatchDone.text':
    'Om debiteuren correct te kunnen matchen<br/>\n<br/>\n• Controleer en update data van uw debiteuren (naam, ID, adres etc.) in uw ledger systeem<br/>\n• Voeg bij voorkeur het bedrijfsregistratienummer (KvK, CRO, Ondernemingsnummer) van uw debiteur toe in uw ledger systeem<br/>\n• Wacht tot na de volgende import (meestal de dag nadien) en controleer of de matching correct verlopen is.',
  'la.unmatchedDebtors': 'Niet gematchte debiteuren',
  'la.unmatchedDebtors.limitNote':
    '<b>Let op:</b> We tonen de <b>top 200 niet gematchte debiteuren</b> met de grootste openstaande bedragen.',
  'la.unmatchedDebtors.text':
    '{unmatchedCount} debiteuren geïmporteerd in ledger konden niet gematcht worden met Graydon data. Om debiteuren correct te kunnen matchen:<br/>\n<br/>\n• Controleer en update data van uw debiteuren (naam, ID, adres etc.) in uw ledger systeem<br/>\n• Voeg bij voorkeur het bedrijfsregistratienummer (KvK, CRO, Ondernemingsnummer) van uw debiteur toe in uw ledger systeem<br/>\n• Wacht tot na de volgende import (meestal de dag nadien) en controleer of de matching correct verlopen is.',
  'la.unmatchLink': 'Ik herken deze klant niet',
  'la.updatePreferencesPopup.error': 'Voorkeuren konden niet bewaard worden',
  'la.updatePreferencesPopup.success': 'Voorkeuren opgeslagen',
  lists: 'Lijsten',
  'lm.company.has-dbm': 'Database management',
  'lm.company.monitored': 'Gemonitord',
  'lm.deletePopup.cancel': 'Nee, annuleren',
  'lm.deletePopup.confirm': 'Ja, verwijderen',
  'lm.listCompanies.actions': 'Acties',
  'lm.listCompanies.back': 'Terug naar lijstenoverzicht',
  'lm.listCompanies.consumerReferenceId': 'Uw referentie',
  'lm.listCompanies.country': 'Land',
  'lm.listCompanies.dateAdded': 'Datum toegevoegd',
  'lm.listCompanies.name': 'Naam',
  'lm.listCompanies.nrCompaniesSelected': '{amount} bedrijven geslecteerd',
  'lm.listCompanies.organizationId': 'Graydon ID',
  'lm.listCompanies.registrationNumber': 'Bedrijfsregistratie nummer ',
  'lm.listCompanies.removeCompanies': 'Bedrijven verwijderen',
  'lm.listCompanies.removeCompanies.message':
    'Bent u zeker dat u {amount} bedrijven uit {listName} wilt verwijderen?',
  'lm.listCompanies.removeCompany': 'Bedrijf verwijderen',
  'lm.listCompanies.removeCompany.message':
    'Bent u zeker dat u "{companyName}" uit {listName} wilt verwijderen?',
  'lm.listCompanies.removeSuccess': 'Succesvol verwijderd',
  'lm.listCompanies.search.placeholder': 'Zoek een bedrijf in de lijst',
  'lm.listOverview.createList': 'Maak nieuwe lijst',
  'lm.listOverview.createPopup.heading': 'Maak een nieuwe lijst',
  'lm.listOverview.createPopup.label': 'Lijstnaam',
  'lm.listOverview.createPopup.submit': 'Opslaan',
  'lm.listOverview.deleteList': 'Verwijder lijst',
  'lm.listOverview.deletePopup.message': 'Weet u zeker dat u "{name}" wilt verwijderen?',
  'lm.listOverview.import.button': 'Importeer bedrijven',
  'lm.listOverview.noCompaniesMessage':
    'Er zitten nog geen bedrijven in deze lijst. Importeer bedrijven om deze lijst te gebruiken of zoek bedrijf en voeg deze toe via Credit Reports.',
  'lm.listOverview.noListsMessage':
    'Er zijn nog geen lijsten om te tonen. Importeer eerst bedrijven.',
  'lm.listOverview.renameList': 'Lijst hernoemen',
  'lm.listOverview.renamePopup.label': 'Lijstnaam',
  'lm.listOverview.renamePopup.submit': 'Opslaan',
  'lm.listOverview.clearReferences': 'Verwijder alle referenties',
  'lm.listOverview.clearReferences.confirm':
    'Weet u zeker dat u alle referenties wil verwijderen voor "{Listname}"?',
  'lm.listOverview.clearReferences.yes': 'Ja, verwijderen',
  'lm.listOverview.clearReferences.no': 'Nee, annuleren',
  'lm.listOverview.clearReferences.success': 'Referenties zijn met succes verwijderd',
  'lm.listOverview.title': 'Lijstenoverzicht',
  'lm.numCompanies': '{num} {num, plural, =1 {bedrijf} other {bedrijven}}',
  'lm.showMoreLists': 'Toon meer lijsten',
  'lm.tags.createError.nameAlreadyExists': 'Lijstnaam bestaat al',
  'lm.tags.createSuccess': 'Lijst is aangemaakt',
  'lm.tags.deleteSuccess': 'Met succes verwijderd',
  'lm.tags.renameSuccess': 'Naam succesvol veranderd',
  'lm.upload-csv.delimiter': 'Scheidingsteken',
  'loading.content': 'Content wordt geladen',
  'matching.organizationId': 'Graydon nummer',
  'matching.addressCity': 'Woonplaats',
  'matching.addressHouseNumber': 'Huisnummer',
  'matching.addressHouseNumberSuffix': 'Huisnummer toevoeging',
  'matching.addressLine1': 'Adreslijn 1',
  'matching.addressLine2': 'Adreslijn 2',
  'matching.addressLine3': 'Adreslijn 3',
  'matching.addressLine4': 'Adreslijn 4',
  'matching.addressLine5': 'Adreslijn 5',
  'matching.addressPostalCode': 'Postcode',
  'matching.addressStreet': 'Straat',
  'matching.addressStreetHouseNumberSuffix': 'Huisnummer + toevoeging',
  'matching.addressStreetHouseNumberSuffixPostcalCodeCity': 'Volledig adres',
  'matching.async.backButton': 'Ga naar de homepage',
  'matching.async.disclaimer':
    'Afhankelijk van de grootte van uw bestand zou dit tot één uur in beslag kunnen nemen. U kunt ondertussen deze pagina verlaten en iets anders doen.',
  'matching.async.matchingDone': 'Wanneer matching is afgelopen, zenden wij de resultaten naar:',
  'matching.async.nextSteps': 'Volgende stappen',
  'matching.async.nextSteps.step1': 'Zodra matching klaar is, sturen we de match-resultaten naar',
  'matching.async.nextSteps.step1.note':
    'Let op: deze e-mail zou in uw spam-folder terecht kunnen komen, dus controleer a.u.b. deze folder ook',
  'matching.async.nextSteps.step2': 'Klik de link in de e-mail',
  'matching.async.nextSteps.step3': 'Voeg de gematchte bedrijven toe aan 1 of meer lijsten',
  'matching.async.title': 'Matching is bezig ...',
  'matching.be': 'België',
  'matching.beBusinessUnitNumber': 'Vestigingsnummer',
  'matching.beEnterpriseNumber': 'Ondernemingsnummer',
  'matching.connectingWithGraydonDatabase': 'Koppel uw bestandkolommen aan Graydons data',
  'matching.croRegistrationNumberOrGbYCompanyId': 'Bedrijfsregistratie nummer ',
  'matching.example': 'Voorbeeld uit uw bestand',
  'matching.gb': 'Het Verenigd Koninkrijk',
  'matching.graydonFieldsFor': 'Graydon data',
  'matching.graydonOrganisationId': 'Graydon nummer',
  'matching.introText.be':
    'Bepaal welke gegevens-kolommen in uw bestand u wilt koppelen aan data van Graydon. De data kan beter worden gekoppeld als u (tenminste) het vestigingsnummer of de bedrijfsnaam en het volledige adres koppelt. Hoe meer u kunt koppelen, des te beter.',
  'matching.introText.gb':
    'Bepaal welke gegevens-kolommen in uw bestand u wilt koppelen aan data van Graydon. De data kan beter worden gekoppeld als u (tenminste) het bedrijfsregistratienummer of de bedrijfsnaam en het volledige adres koppelt. Hoe meer u kunt koppelen, des te beter.',
  'matching.introText.nl':
    'Bepaal welke gegevens-kolommen in uw bestand u wilt koppelen aan data van Graydon. De data kan beter worden gekoppeld als u (tenminste) het KvK-nummer of de bedrijfsnaam en het volledige adres koppelt. Hoe meer u kunt koppelen, des te beter.',
  'matching.kvkBranchNumber': 'KvK vestigingsnummer',
  'matching.kvkNumber': 'KvK-nummer',
  'matching.labelFromYourList': 'Uw bestandskolommen',
  'matching.nl': 'Nederland',
  'matching.nlYCompanyId': 'Oud Graydon-nummer (NL)',
  'matching.orgName': 'Bedrijfsnaam',
  'matching.reference': 'Referentie (klantnummer)',
  'matching.results.accessViaPortfolio': 'U kunt deze bedrijven terugvinden in uw portfolio',
  'matching.results.addCompaniesToList': 'Voeg bedrijven toe aan een lijst',
  'matching.results.download': 'Download',
  'matching.results.download.point1': 'gematchte bedrijven',
  'matching.results.download.point2': "bedrijven aangemerkt als 'Niet gematcht'",
  'matching.results.download.text': 'Download match-resultaten met daarin:',
  'matching.results.downloadButton': 'Download matchresultaten',
  'matching.results.heading': 'Match-resultaten',
  'matching.results.listItemOne': "Gebruik rijen met 'Not matched'",
  'matching.results.listItemThree': 'Update verkeerde data',
  'matching.results.listItemTwo': 'Voeg ontbrekende data toe',
  'matching.results.matchResults': 'Match resultaat',
  'matching.results.more': 'meer',
  'matching.results.noMatchesMessage': 'Update uw bestand of ga terug en verander de koppeling',
  'matching.results.pleaseChooseGroups': 'Voeg bedrijven toe aan lijst(en)',
  'matching.results.pleaseChooseGroups.text': 'Kies een of meer lijst(en) of',
  'matching.results.pleaseChooseGroups.text.link': 'maak een nieuwe lijst',
  'matching.results.successfullyMatched': 'bedrijven zijn succesvol gematcht',
  'matching.results.update': 'Update',
  'matching.results.update.point1': 'Voeg ontbrekende data toe',
  'matching.results.update.point2': 'Update verkeerde data',
  'matching.results.update.text':
    'Update het bestand en importeer deze opnieuw om meer bedrijven te matchen:',
  'matching.results.updateYourFile': 'Bekijk niet gematchte bedrijven en match meer',
  'matching.results.uploadAgain': 'Opnieuw uploaden',
  'matching.results.uploadAgainButton': 'Upload geüpdatete lijst',
  'matching.results.wereNotMatched': 'bedrijven zijn niet gematcht',
  'matching.review.addMissingData': 'Graag de ontbrekende gegevens zelf toevoegen',
  'matching.review.companies': 'de bedrijven zijn gematcht en aan uw portfolio toegevoegd',
  'matching.review.companyMoreData': 'de bedrijven hebben meer gegevens nodig',
  'matching.review.continue': 'Ga verder zonder niet gematchte gegevens',
  'matching.review.hideTips': 'verberg tips',
  'matching.review.reviewImport': 'Controleer import',
  'matching.review.showTips': 'Toon tips',
  'matching.review.submitButton': 'Download niet gematchte gegevens',
  'matching.review.theWordHow': 'Hoe?',
  'matching.review.theWordOf': 'van',
  'matching.review.tip.downloadNonMatched': 'Download niet gematchte bedrijven',
  'matching.review.tip.review':
    'Controleer het bestand. Voeg zoveel mogelijk ontbrekende gegevens toe. We hebben het volgende nodig:',
  'matching.review.tip.uploadAndMatch': 'Upload het geüpdate bestand en match opnieuw.',
  'matching.review.tip1': 'tip',
  'matching.review.tip2': 'tip',
  'matching.review.tip3': 'tip',
  'matching.selectColumn': 'Geen label geselecteerd',
  'matching.overwriteReference':
    'Overschrijf bestaande referenties met de referenties in deze import',
  'matching.overwriteReference.warning':
    'Het overschrijven van referenties is niet ongedaan te maken. Het heeft ook effect op andere gebruikers.',
  'matching.submitButton': 'Match gegevens',
  'matching.vatNumber': 'BTW-nummer',
  'matching.yMsKey': 'MSkey',
  'matchng.results.continueButton': 'Sla op en ga verder',
  'mdb.cm.download-invoice-data': 'Credit Management factuurdata (.xlsx)',
  'mdb.cm.download-usage-data': 'Credit Management verbruikdata (.xlsx)',
  'mdb.creditManagement.amountRequested.advanced': 'Bedrijven gecontroleerd',
  'mdb.creditManagement.amountRequested.essential': 'Credit Reports opgevraagd',
  'mdb.creditManagement.amountRequested.premium': 'Bedrijven gecontroleerd',
  'mdb.creditManagement.country.be': 'België',
  'mdb.creditManagement.country.nl': 'Nederland',
  'mdb.creditManagement.country.uk': 'Het Verenigd Koninkrijk',
  'mdb.creditManagement.country.gb': 'Het Verenigd Koninkrijk',
  'mdb.creditManagement.description.advanced':
    'Het aantal gecontroleerde bedrijven tegenover het totaal aantal bedrijven dat tijdens de contractperiode beschikbaar is in uw pakket: **{dateRange}**. Monitoring, Credit Reports en Ledger Management inbegrepen.',
  'mdb.creditManagement.description.essential':
    'Het aantal opgevraagde credit reports tegenover het totaal aantal credit reports dat tijdens de contractperiode beschikbaar is in uw pakket: **{dateRange}**.',
  'mdb.creditManagement.description.premium':
    'Het aantal gecontroleerde bedrijven tegenover het totaal aantal bedrijven dat tijdens de contractperiode beschikbaar is in uw pakket: **{dateRange}**. Monitoring, Credit Reports en Ledger Management inbegrepen.',
  'mdb.creditManagement.lastUpdate': 'Laatste update:',
  'mdb.creditManagement.tab': 'Credit Management',
  'mdb.creditManagement.tileHeading.advanced': 'Aantal gecontroleerde bedrijven',
  'mdb.creditManagement.tileHeading.essential': 'Aantal opgevraagde Credit Reports',
  'mdb.creditManagement.tileHeading.premium': 'Aantal gecontroleerde bedrijven',
  'mdb.creditManagement.tooltip': 'De Credit Management tellers worden één maal per dag geüpdatet.',
  'mdb.download-data-enrichment': 'Marketing Informatie verrijkingen (.xlsx)',
  'mdb.download-market-data': 'Market Data verbruikdata (.xlsx)',
  'mdb.icr.download-old-usage-data':
    'Oude internationale kredietrapporten verbruikdata (tot 9-11-2021) (.xlsx)',
  'mdb.icr.download-usage-data': 'Internationale kredietrapporten verbruikdata (.xlsx)',
  'mdb.icr.tab': 'Internationale kredietrapporten',
  'mdb.market-data.tab': 'Market Data',
  'mdb.marketData.amountRequested': 'Gedownloade of verrijkte bedrijven',
  'mdb.marketData.description':
    'Het aantal bedrijven dat is gedownload via Market Data of verrijkt via Data Enrichment vs. het totale aantal bedrijven in uw packet gedurende de contractduur: **{dateRange}**.',
  'mdb.marketData.tileHeading': 'Het aantal gedownloade of verrijkte bedrijven',
  'mdb.marketData.tooltip':
    'De Market Data tellers worden een keer per dag bijgewerkt. De teller geeft aan hoeveel records u nog kunt downloaden.',
  'mdb.no.reference': 'Geen referentie',
  'mdb.usage.extractCached.amountRequested':
    'Handelsregister uittreksels aangevraagd uit het Graydon archief',
  'mdb.usage.extractCached.description':
    'Het aantal Handelsregister uitreksels uit het Graydon archief tegenover het totaal aantal uittreksels dat tijdens de contractperiode beschikbaar is in uw pakket: **{dateRange}**.',
  'mdb.usage.extractFresh.amountRequested': 'Nieuwe Handelsregister uittreksels aangevraagd',
  'mdb.usage.extractFresh.description':
    'Het aantal nieuwe Handelsregister uitreksels tegenover het totaal aantal uittreksels dat tijdens de contractperiode beschikbaar is in uw pakket: **{dateRange}**.',
  'mdn.usage.companyExtract.tooltip':
    'De Handelsregister uittreksel tellers worden éénmaal per dag geüpdatet.',
  'mdn.usage.extractCached.heading':
    'Aantal Handelsregister uittreksels van bedrijven uit het Graydon archief {country, select, gb {Verenigd Koninkrijk} be {België} other {Nederland}}',
  'mdn.usage.extractFresh.heading':
    'Aantal nieuwe Handelsregister uittreksels van bedrijven {country, select, gb {Verenigd Koninkrijk} be {België} other {Nederland}}',
  'menu.hide': 'Verberg navigatie',
  'menu.show': 'Toon navigatie',
  'mi.activities.confirm': 'Bevestig activiteiten',
  'mi.activities.group-code.1': 'BTW',
  'mi.activities.group-code.BE_1': 'KBO-EDRL',
  'mi.activities.group-code.BE_1_2_5': 'KBO',
  'mi.activities.group-code.BE_2': 'KBO-POR',
  'mi.activities.group-code.BE_3': 'RSZPPO',
  'mi.activities.group-code.BE_3_4': 'RSZ',
  'mi.activities.group-code.BE_4': 'RSZ',
  'mi.activities.group-code.BE_5': 'KBO-SCHOLEN',
  'mi.activities.group-code.BE_6': 'Madison',
  'mi.activities.group-code.EMPTY': 'BBS en andere',
  'mi.activities.source': 'Bron',
  'mi.activities.source.all-sources': 'Alle bronnen',
  'mi.activitySearchPlaceholder': 'Zoek op trefwoord of activiteitscode',
  'mi.bigDownload.button': 'Laten we iets anders doen',
  'mi.bigDownload.emailTo': 'Als uw campagne klaar is om te downloaden, sturen we een e-mail naar:',
  'mi.bigDownload.heading': 'Bijna tijd om uw targets te bereiken ...',
  'mi.bigDownload.smallText':
    'Heeft u binnen 2 uur nog geen e-mail ontvangen? Neem contact op met de klantenservice',
  'mi.companies': 'bedrijven',
  'mi.configurator.activities': 'Activiteiten',
  'mi.configurator.addArea': 'Voeg gebied toe',
  'mi.configurator.addCity': 'Voeg plaatsnaam toe',
  'mi.configurator.addCity.belgium': 'Voeg plaatsnaam toe',
  'mi.configurator.addFoundingYear': 'Voeg jaar toe',
  'mi.configurator.addFoundingYearRange': 'Voeg jaarreeks toe',
  'mi.configurator.addZipCode': 'Voeg postcode toe',
  'mi.configurator.addZipCodeRange': 'Voeg postcodereeks toe',
  'mi.configurator.amountSelected': '{num} geselecteerd',
  'mi.configurator.annualAccount.equity_capital': 'Eigen vermogen',
  'mi.configurator.annualAccount.net_result': 'Resultaat boekjaar',
  'mi.configurator.annualAccount.tooltip':
    'Kies een of meer kerncijfers en bepaal de drempelwaarde(n)',
  'mi.configurator.annualAccount.total_assets': 'Totaal van de activa',
  'mi.configurator.annualAccount.turnover': 'Omzet',
  'mi.configurator.annualAccount.working_capital': 'Werkkapitaal',
  'mi.configurator.annualAccountHeading': 'Jaarrekeningen',
  'mi.configurator.area.enterToSearch': 'om te zoeken',
  'mi.configurator.area.heading': 'Gebied',
  'mi.configurator.area.locationNotFound': 'Locatie niet gevonden',
  'mi.configurator.area.tooltip':
    "Vul een locatie in, druk op 'Enter', en maak de straal van het gebied groter of kleiner door de cirkel op de kaart te verslepen",
  'mi.configurator.chooseActivities': 'Kies activiteiten',
  'mi.configurator.cityFilterHeading': 'Plaatsnaam',
  'mi.configurator.cityFilterHeading.belgium': 'Plaatsnaam',
  'mi.configurator.clearAllFilters': 'Reset selectie',
  'mi.configurator.clearSelection': 'Wis filter',
  'mi.configurator.companySizesHeading': 'Aantal werknemers',
  'mi.configurator.companyTypesHeading': 'Type vestiging',
  'mi.configurator.edited': 'Bewerkt',
  'mi.configurator.filters': 'Filters',
  'mi.configurator.foundingYearHeading': 'Jaar van oprichting',
  'mi.configurator.foundingYearInputPlaceholder': 'Jaar',
  'mi.configurator.header.be': 'Uw selectie in België',
  'mi.configurator.header.gb': 'Uw selectie in het Verenigd Koninkrijk',
  'mi.configurator.header.nl': 'Uw selectie in Nederland',
  'mi.configurator.heading': 'Mijn campagne',
  'mi.configurator.legalFormsHeading': 'Rechtsvorm',
  'mi.configurator.noProspectsActivities':
    'Geen prospects in selectie. Wijzig één of meerdere filters om activiteiten te zien.',
  'mi.configurator.provincesHeading': 'Provincies',
  'mi.configurator.provincesHeading.uk': "Regio's",
  'mi.configurator.rangeButton': 'Bereik',
  'mi.configurator.rangeSelector.and': 'en',
  'mi.configurator.rangeSelector.between': 'Tussen',
  'mi.configurator.rangeSelector.higher-than': 'Hoger dan',
  'mi.configurator.rangeSelector.lower-than': 'Lager dan',
  'mi.configurator.removeFoundingYear': 'Verwijder',
  'mi.configurator.removeZipCode': 'Verwijder',
  'mi.configurator.resetFiltering': 'Filters resetten',
  'mi.configurator.save': 'Opslaan',
  'mi.configurator.saveActivities': 'Ga verder met selectie',
  'mi.configurator.saveAs': 'Opslaan als',
  'mi.configurator.savedFiltersHeading': 'Mijn campagnes',
  'mi.configurator.saveFilters': 'Sla campagne op',
  'mi.configurator.saveFilters.inputPlaceholder':
    'vb.: Bakkerijen in Brussel, grote ondernemingen in Amsterdam',
  'mi.configurator.saveFilters.text':
    'Om later verder te gaan, of een template te creëren om nieuwe campagnes op te baseren',
  'mi.configurator.search-key.name': 'Naam',
  'mi.configurator.selectCountryHeading': 'Start met uw campagne',
  'mi.configurator.selectCountryParagraph':
    'Selecteer een land om uw campagne te starten. Vervolgens kunt u in dit land een prospectselectie maken.',
  'mi.configurator.sessionExpired.button': 'Log opnieuw in',
  'mi.configurator.sessionExpired.text':
    'Geen paniek, uw werk is niet verloren. Log opnieuw in om verder te gaan.',
  'mi.configurator.sessionExpired.title': 'Om veiligheidsredenen is uw sessie verlopen',
  'mi.configurator.showLess': 'Minder',
  'mi.configurator.showMore': 'Toon alles',
  'mi.configurator.socialBalance.heading': 'Sociale balans',
  'mi.configurator.socialBalance.label':
    '{label, select, full_time_employees_size {Aantal fulltime werknemers} board_members_size {Aantal directieleden} office_staff_size {Aantal bedienden} other {Onbekende categorie}}',
  'mi.configurator.socialBalance.tooltip':
    'Kies een of meer aantallen van medewerkertype en bepaal de drempelwaarde(n).',
  'mi.configurator.textSearch.invalidQuery': 'Ongeldige zoekopdracht',
  'mi.configurator.textSearch.placeholder': 'Bijvoorbeeld: Webshop AND (Winkelmand OR Winkelwagen)',
  'mi.configurator.textSearchHeading': 'Doorzoek bedrijfswebsites en bedrijfsdata',
  'mi.configurator.unknown': 'Onbekend',
  'mi.configurator.zipCode': 'Postcode',
  'mi.configurator.zipCodeHeading': 'Postcode',
  'mi.configurator.zipCodeInputPlaceholder': 'Postcode',
  'mi.dateRangeSelection': 'Periode',
  'mi.discontinuationScore.1': 'Laag',
  'mi.discontinuationScore.2': 'Neutraal',
  'mi.discontinuationScore.3': 'Hoog',
  'mi.discontinuationScore.heading': 'Kans op opheffing',
  'mi.discontinuationScore.NR': 'Niet beoordeeld',
  'mi.download': 'Download',
  'mi.download.noResults.heading': 'Er zijn geen prospects meer',
  'mi.download.noResults.message':
    'U heeft waarschijnlijk te veel bedrijven uitgesloten. U kunt minder bedrijven uitsluiten of uw filters aanpassen.',
  'mi.downloadAsCsv': 'Download als CSV',
  'mi.downloadAsExcel': 'Download als Excel',
  'mi.downloadForm.amount.right': 'Totaal: {maxAmount}',
  'mi.downloadForm.amountScheduled.right': 'bedrijven per levering',
  'mi.downloadForm.back': 'Terug naar campagne',
  'mi.downloadForm.batchSize': 'Maximale batch grootte',
  'mi.downloadForm.batchSize.nCompanies': 'bedrijven',
  'mi.downloadForm.batchSize.xFirst': 'eerst',
  'mi.downloadForm.campaignName': 'Naam campagne',
  'mi.downloadForm.cleanUp.heading': 'Ruim uw download op',
  'mi.downloadForm.cleanUp.paragraph':
    'Dubbele ondernemingen verwijderen en ondernemingen uitsluiten, zoals in uw eerdere downloads.',
  'mi.downloadForm.deduplicate': 'Ontdubbelen',
  'mi.downloadForm.deduplicate.heading': 'Ontdubbel uw selectie',
  'mi.downloadForm.deduplicate.onAddress': 'Op adres',
  'mi.downloadForm.deduplicate.onEmail': 'Op e-mailadres',
  'mi.downloadForm.deduplicate.onPhone': 'Op telefoonnummer',
  'mi.downloadForm.downloadLocale': 'Bestandstaal',
  'mi.downloadForm.downloadSettings.heading': 'Download instellingen',
  'mi.downloadForm.downloadType.once': 'Eenmalige download',
  'mi.downloadForm.downloadType.once.paragraph': 'Nuttig voor kleine eenmalige campagnes',
  'mi.downloadForm.downloadType.scheduled': 'Geplande herhaalde download',
  'mi.downloadForm.downloadType.scheduled.paragraph':
    'Verspreid een groot aantal prospects geleidelijk. Dit garandeert dat u telkens recente data ontvangt.',
  'mi.downloadForm.excelTooBig\n':
    'Helaas ondersteunt Excel geen 1 miljoen records of meer. Kies CSV of maak uw selectie kleiner.',
  'mi.downloadForm.exclude': 'Uitsluiten',
  'mi.downloadForm.excludeDownloads': 'Vorige downloads uitsluiten',
  'mi.downloadForm.excludeDownloads.selectAll': 'Alle vorige downloads',
  'mi.downloadForm.excludeDownloads.title': 'Vorige downloads kiezen',
  'mi.downloadForm.excludeGroups.continue': 'Ga verder',
  'mi.downloadForm.excludeGroups.heading': 'Sluit één lijst of lijsten uit van uw campagne',
  'mi.downloadForm.excludeGroups.showMore': 'Kies één of meerdere lijsten',
  'mi.downloadForm.fileType': 'Bestandstype',
  'mi.downloadForm.fileType.csv': 'Comma Separated Values (.csv)',
  'mi.downloadForm.fileType.excel': 'Excel (.xlsx)',
  'mi.downloadForm.fileType.heading': 'Bestandstype',
  'mi.downloadForm.first.batch': 'Eerste levering',
  'mi.downloadForm.firstBatch': 'U zult de eerste levering downloaden van ',
  'mi.downloadForm.gdpr.conditionsText':
    'Op de persoonsgegevens en scores in de download zijn de Algemene Verordening Gegevensbescherming (AVG) en de Telecommunicatiewet van toepassing. Voor meer informatie bekijkt u onze Algemene Voorwaarden op <a href="https://www.graydon.nl/algemene-voorwaarden">www.graydon.nl/algemene-voorwaarden</a> of <a href="https://graydon.be/algemene-voorwaarden">www.graydon.be/algemene-voorwaarden</a>',
  'mi.downloadForm.gdpr.dmus': 'Persoonsgegevens van contactpersonen (indien aanwezig)',
  'mi.downloadForm.gdpr.dmus.tooltip':
    'Het is niet toegestaan om bedrijven/personen met een non-mailing indicator die geen relatie van u zijn via post of deur-tot-deur verkoop te benaderen.',
  'mi.downloadForm.gdpr.heading': 'Wilt u extra data ontvangen?',
  'mi.downloadForm.gdpr.scores': 'Scores (indien aanwezig)',
  'mi.downloadForm.gdpr.scores.tooltip':
    'Het gaat hier om kans op wanbetaling of financieel risico, kans op opheffing & groeiverwachting.',
  'mi.downloadForm.heading': 'Uw campagne downloaden',
  'mi.downloadForm.maxNumberOfProspects': 'Aantal',
  'mi.downloadForm.name': 'Naam van de campagne',
  'mi.downloadForm.nextBatch': 'U zult uw prospects ontvangen op',
  'mi.downloadForm.on': 'op',
  'mi.downloadForm.order.oldest': 'oudste',
  'mi.downloadForm.order.withHighestProbOfDefault': 'met de hoogste kans op wanbetaling',
  'mi.downloadForm.order.withLeastEmployees': 'met de minste werknemers',
  'mi.downloadForm.order.withLowestProbOfDefault': 'met de minste kans op wanbetaling',
  'mi.downloadForm.order.withMostEmployees': 'met de meeste werknemers',
  'mi.downloadForm.order.youngest': 'jongste',
  'mi.downloadForm.recipient': 'Ontvanger',
  'mi.downloadForm.repeat': 'Herhaal elke',
  'mi.downloadForm.repeat.left': 'om de',
  'mi.downloadForm.repeat.right': 'dagen',
  'mi.downloadForm.repeat.subtext': 'Volgende levering op',
  'mi.downloadForm.scheduledInfoLine2':
    'Let op: Bij een geplande download wordt er automatisch ontdubbeld op voorgaande downloads en ontvangt u organisaties maar één keer. ',
  'mi.downloadForm.tags.heading': 'Kies een lijst om de bedrijven aan toe te voegen',
  'mi.downloadForm.tags.paragraph': 'Lijsten zijn in te zien in List Management',
  'mi.downloadForm.today': 'vandaag',
  'mi.downloadForm.validation.filterName': 'Geef de campagne een naam',
  'mi.downloadForm.validation.nrCompanies': 'Voer een getal in groter dan 0',
  'mi.downloadForm.validation.prospectLimitExceeded':
    'U kunt maximaal {value} prospects per keer downloaden',
  'mi.downloadForm.validation.repeatInterval': 'Voer een getal in groter dan 0',
  'mi.downloadForm.validation.selectList': 'Kies één of meerdere lijsten',
  'mi.downloadStarted.heading': 'Download gestart',
  'mi.downloadStarted.homeButton': 'Ga naar de homepage',
  'mi.errorPage.fetchErrorDescription':
    'Fout bij het ophalen van de data, probeer het later opnieuw',
  'mi.errorPage.heading': 'Error',
  'mi.errorPage.invalidDownload': 'Link is verlopen. Download opnieuw',
  'mi.errorPage.retry': 'Probeer opnieuw',
  'mi.foundingYear.after': 'Na',
  'mi.foundingYear.before': 'Voor',
  'mi.foundingYear.lastNMonths': 'Laatste {n} maanden',
  'mi.growthScore.1': 'Groei',
  'mi.growthScore.2': 'Neutraal',
  'mi.growthScore.3': 'Krimp',
  'mi.growthScore.heading': 'Groeiverwachting',
  'mi.growthScore.NR': 'Niet beoordeeld',
  'mi.hasTurnover.false': 'Nee',
  'mi.hasTurnover.heading': 'Bedrijven met omzet',
  'mi.hasTurnover.true': 'Ja',
  'mi.howToContact.has_email': 'E-mail beschikbaar',
  'mi.howToContact.has_telephone_number': 'Telefoonnummer beschikbaar',
  'mi.howToContact.heading': 'Contactgegevens',
  'mi.isActiveStatusCode': '{key, select, Y {Ja} N {Nee} other {Onbekend}}',
  'mi.isActiveStatusCode.heading': 'Actieve bedrijven',
  'mi.isActiveStatusCode.tooltip':
    'Actieve bedrijven zijn bedrijven die als actief geregistreerd staan bij de Kamer van Koophandel. Deze bedrijven zijn niet opgeheven en niet failliet.',
  'mi.isEconomicallyActive.heading': 'Economisch actieve bedrijven',
  'mi.isEconomicallyActive.N': 'Nee',
  'mi.isEconomicallyActive.N/A': 'Onbekend',
  'mi.isEconomicallyActive.tooltip':
    'Economisch actieve bedrijven zijn bedrijven waarvan Graydon vast heeft gesteld dat zij zeer waarschijnlijk actief deelnemen aan het economisch verkeer. Graydon heeft dit bepaald aan de hand van gedeponeerde jaarrekeningen, betaalgegevens van het bedrijf, of informatie uit andere bronnen.',
  'mi.isEconomicallyActive.Y': 'Ja',
  'mi.noActivitiesFound':
    'Geen activiteiten gevonden. Controleer uw spelling, of probeer een ander trefwoord.',
  'mi.nonMailIndicator.heading': 'KVK Non-mailing-indicator (NMI)',
  'mi.nonMailIndicator.noNMI':
    'Alleen bedrijven zonder NMI (wel te benaderen via post of huis-aan-huis bezoek)',
  'mi.nrProspects': '{num} prospects',
  'mi.paymentScore.average': 'Gemiddeld',
  'mi.paymentScore.heading': 'Betaalscore',
  'mi.paymentScore.high': 'Hoog',
  'mi.paymentScore.low': 'Laag',
  'mi.postcodeRange': 'Postcodereeks',
  'mi.preferredLanguages.FR': 'Frans',
  'mi.preferredLanguages.heading': 'Voertaal van de organisatie',
  'mi.preferredLanguages.NL': 'Nederlands',
  'mi.preparingDownload.heading': 'Download aan het voorbereiden, gelieve te wachten...',
  'mi.preparingDownloadCsv': 'CSV-download in voorbereiding',
  'mi.preparingDownloadExcel': 'Excel-download in voorbereiding',
  'mi.prospectPreview.activityUnknown': 'Activiteit onbekend',
  'mi.prospectPreview.backButton': 'Pas campagne aan',
  'mi.prospectPreview.heading': 'Voorbeeld uit uw campagne',
  'mi.prospectPreview.legalFormUnknown': 'Rechtsvorm onbekend',
  'mi.prospectPreview.unknownCompanyName': 'Naam onbekend',
  'mi.prospectPreviewButton': 'Voorbeeld',
  'mi.ratingCode.1': 'Hoog',
  'mi.ratingCode.2': 'Gemiddeld',
  'mi.ratingCode.3': 'Laag',
  'mi.ratingCode.heading': 'Financieel risico',
  'mi.ratingCode.heading.nl': 'Kans op wanbetaling',
  'mi.savedFilters.delete': 'Verwijder',
  'mi.savedFilters.deletePopup.cancel': 'Nee, annuleren',
  'mi.savedFilters.deletePopup.confirm': 'Ja, verwijderen',
  'mi.savedFilters.deletePopup.message': 'Weet u zeker dat u "{name}" wilt verwijderen?',
  'mi.savedFilters.deleteSuccess': 'Met succes verwijderd',
  'mi.savedFilters.editSchedule': 'bewerken',
  'mi.savedFilters.noSavedFilters': 'U heeft op dit moment geen opgeslagen campagnes.',
  'mi.savedFilters.saveSuccess': 'Campagne is opgeslagen',
  'mi.savedFilters.scheduled': 'Gepland',
  'mi.savedFilters.scheduledText':
    'Geplande download om de {nrDays} dagen, volgende levering {nextDate}',
  'mi.savedFilters.show.all': 'Alle',
  'mi.savedFilters.show.saved': 'Bewaard',
  'mi.savedFilters.show.scheduled': 'Gepland',
  'mi.saveFilter.nameAlreadyExists': 'Campagne met dezelfde naam bestaat reeds',
  'mi.scheduleSet.heading': 'De geplande download is ingesteld',
  'mi.scheduleSet.paragraph':
    'De eerste levering met prospects wordt zo snel mogelijk verzonden naar {email}',
  'mi.scheduleSet.paragraph.later':
    'De eerste levering met prospects wordt op {date} verzonden naar {email}',
  'mi.scheduleUpdated.heading': 'De geplande download is geactualiseerd',
  'mi.sectors.heading': 'Sector',
  'mi.singlePostcode': 'Enkele postcode',
  'mi.singleYear': 'Enkel jaar',
  'mi.textSearchFilter.tooltip_1':
    '- Gebruik <b>AND</b> tussen twee trefwoorden als beide woorden aanwezig moeten zijn.',
  'mi.textSearchFilter.tooltip_2':
    '- Gebruik <b>OR</b> tussen twee trefwoorden als één van de woorden aanwezig moet zijn.',
  'mi.textSearchFilter.tooltip_3':
    '- Gebruik <b>NOT</b> vóór een trefwoord als je organisaties met dit trefwoord wilt uitsluiten.',
  'mi.textSearchFilter.tooltip_4':
    '- Plaats trefwoorden <b>&quot;tussen aanhalingstekens&quot;</b> om te zoeken op een exacte match van deze woorden.',
  'mi.textSearchFilter.tooltip_5':
    '- Plaats trefwoorden <b>tussen haakjes</b> om de woorden te groeperen. Bijvoorbeeld: (bakkerij OR bakker) AND winkel.',
  'mi.totalSelectedProspects': 'Prospects in selectie',
  'mi.unknownArea': 'Onbekende locatie',
  'mi.whoToContact.has_financial_director': 'Financiële zaken',
  'mi.whoToContact.has_fleet_management_director': 'Wagenpark',
  'mi.whoToContact.has_hrm_director': 'Personeelszaken',
  'mi.whoToContact.has_it_director': 'IT/Automatisering',
  'mi.whoToContact.has_managing_director': 'Bedrijfsleiding/ directie',
  'mi.whoToContact.has_managing_director.be':
    'Bedrijfsleiding: Cascade: afgevaardigd bestuurder - bestuurder - zaakvoerder - uitbater – voorzitter',
  'mi.whoToContact.has_marketing_director': 'Marketing',
  'mi.whoToContact.has_operations_director': 'Operations',
  'mi.whoToContact.has_purchase_director': 'Inkoop',
  'mi.whoToContact.has_sales_director': 'Verkoop',
  'mi.whoToContact.has_technical_director': 'Technische dienst',
  'mi.whoToContact.heading': 'Contactpersoon beschikbaar',
  'mi.yearRange': 'Jaarreeks',
  'mi.yearSelection': 'Jaar',
  'mon.backToUpdateOverview': 'Terug naar update-overzicht',
  'mon.ccjFlag.false': 'Geen geregistreerd',
  'mon.ccjFlag.true': 'Vonnis(sen) geregistreerd',
  'mon.configure.address': 'Adres van het bedrijf',
  'mon.configure.address_1': 'Adres van het bedrijf',
  'mon.configure.address_1.hint':
    'Monitor wijzigingen in het bedrijfsadres. U krijgt een bericht wanneer er een wijziging is gedetecteerd. De bijgewerkte informatie vindt u in het kredietrapport.',
  'mon.configure.address.changed': 'Nieuw adres geregistreerd',
  'mon.configure.address.hint':
    'Monitor wijzigingen in het bedrijfsadres. U krijgt een bericht wanneer er een wijziging is gedetecteerd. De bijgewerkte informatie vindt u in het kredietrapport.',
  'mon.configure.annualReport': 'Jaarrekening',
  'mon.configure.annualReport.hint':
    'Monitor of er een nieuwe jaarrekening beschikbaar is. U krijgt een update wanneer er een wijziging is gedetecteerd. U krijgt het nieuwe en voorgaande jaartal te zien.',
  'mon.configure.annualReport.newReport': 'Nieuwe jaarrekening gedeponeerd',
  'mon.configure.annualReportGB': 'Rekening beschikbaar (bij Graydon)',
  'mon.configure.annualReportGB.hint':
    'Monitor of er een nieuwe jaarrekening beschikbaar is bij Graydon. U \nkrijgt een update wanneer er een wijziging is gedetecteerd. U krijgt het\n nieuwe en voorgaande jaartal te zien.',
  'mon.configure.annualReports': 'Meest recente jaarrekening gedeponeerd bij Companies House op',
  'mon.configure.annualReports.title': 'Jaarrekeningen',
  'mon.configure.beMultiscore': 'Multiscore',
  'mon.configure.beMultiscore.hint':
    'Monitor de Multiscore. De multiscore beoordeelt kansen en risico’s voor een onderneming op middellange termijn en wordt uitgedrukt in een score van 1-100.',
  'mon.configure.ccjFlag': 'Vonnissen van het County Court',
  'mon.configure.ccjFlag.hint':
    'Monitor of er een vonnis van het County Court (CCJ) beschikbaar is. U krijgt een bericht wanneer er een CCJ is gedetecteerd en vice versa. De bijgewerkte informatie vindt u in het kredietrapport.',
  'mon.configure.companyName': 'Bedrijfsnaam',
  'mon.configure.companyName.hint':
    'Monitor de bedrijfsnaam. U krijgt een update wanneer er een wijziging is gedetecteerd. U krijgt de oude en nieuwe naam te zien.',
  'mon.configure.country': 'Land',
  'mon.configure.country.hint':
    'Kies een land. Als een bedrijvenlijst die u monitort bedrijven uit verschillende landen bevat, monitort u alleen de bedrijven uit het gekozen land.<br><br>Als u bedrijven uit verschillende landen wilt monitoren, dan moet u verschillende monitoringprofielen instellen.',
  'mon.configure.courtJudgmentDetails': 'Vonnissen',
  'mon.configure.courtJudgmentDetails.hint':
    'Monitor of er een vonnis van het County Court (CCJ) beschikbaar is. U krijgt een bericht wanneer er een CCJ is gedetecteerd en vice versa. De bijgewerkte informatie vindt u in het kredietrapport.',
  'mon.configure.creditAmountAdvised': 'Kredietlimiet',
  'mon.configure.creditAmountAdvised.changeMoreThanPercent': '{n}% verandering',
  'mon.configure.creditAmountAdvised.hint': 'Monitor de maximaal geadviseerde kredietlimiet',
  'mon.configure.dataType': 'Type data',
  'mon.configure.events': 'Gebeurtenissen',
  'mon.configure.eventsPosition': 'Bestuurders',
  'mon.configure.eventsPosition.hint':
    'Monitor wijzigingen in de bestuurders van een bedrijf. U krijgt een bericht wanneer er een wijziging is gedetecteerd. De bijgewerkte informatie vindt u in het kredietrapport.',
  'mon.configure.financialCalamities': 'Financiële calamiteit',
  'mon.configure.financialCalamities.hint':
    'Monitor de financiële calamiteiten. Graydon adviseert om dit item altijd te monitoren.',
  'mon.configure.financialCalamitiesDetails': 'Financiële calamiteiten details',
  'mon.configure.financialCalamitiesDetails.hint':
    'Monitor gedetailleerde financiële calamiteiten. Graydon adviseert om dit item altijd te monitoren.',
  'mon.configure.general': 'Algemeen',
  'mon.configure.graydonRating': 'Graydon Rating',
  'mon.configure.graydonRating.changeMoreThan':
    '{n} {n, plural, =1 {rating categorie} other {rating categorien}}',
  'mon.configure.graydonRating.hint':
    'Monitor de Graydon Rating. De Graydon Rating toont de mate van kredietrisico aan, uitgedrukt in een score op een schaal van AAA (minimaal risico) tot C (maximaal risico). D staat voor een faillissement. NR betekent dat er geen score bepaald kon worden.',
  'mon.configure.graydonRatingPercentage': 'Kans op faillissement',
  'mon.configure.graydonRatingPercentage.hint':
    'Monitor de kans op faillissement. De kans dat het bedrijf in ernstige financiële nood verkeert, uitgedrukt in een percentage.',
  'mon.configure.heading': 'Monitoring profile ‘{profileName}’',
  'mon.configure.latestAnnualReportFilingDate': 'Meest recente jaarrekening gedeponeerd',
  'mon.configure.latestAnnualReportFilingDate.hint':
    'Monitor of er een nieuwe jaarrekening gedeponeerd is bij Companies House.',
  'mon.configure.legalForm': 'Rechtsvorm',
  'mon.configure.legalForm_1': 'Rechtsvorm',
  'mon.configure.legalForm_1.hint':
    'Monitor wijzigingen in de rechtsvorm van een bedrijf. U krijgt een bericht wanneer er een wijziging is gedetecteerd. De bijgewerkte informatie vindt u in het kredietrapport.',
  'mon.configure.legalForm.changed': 'Nieuwe rechtsvorm geregistreerd',
  'mon.configure.legalForm.hint':
    'Monitor wijzigingen in de rechtsvorm van een bedrijf. U krijgt een bericht wanneer er een wijziging is gedetecteerd. De bijgewerkte informatie vindt u in het kredietrapport.',
  'mon.configure.liabilityStatement': 'Aansprakelijkheids&shy;verklaring',
  'mon.configure.liabilityStatement.hint':
    'Monitor de veranderingen in aansprakelijkheidsverklaring. U krijgt een \nupdate wanneer er een wijziging is gedetecteerd. U krijgt het oude en \nnieuwe aansprakelijke bedrijf te zien.',
  'mon.configure.liabilityStatement.updateLabel': 'Aansprakelijk bedrijf',
  'mon.configure.list.countryNote':
    'Let op: slechts {organizationCountOfCountry} {organizationCountOfCountry, plural, =1 {company} other {companies}} van {organizationCount} bedrijven in de lijst zijn gevestigd in {countryCode} en worden gemonitord.',
  'mon.configure.list.countryNote.empty':
    'Let op: Geen van de {organizationCount} bedrijven in de lijst is gevestigd in {countryCode}, dus u monitort geen bedrijf in dit profiel.',
  'mon.configure.list.createEmptyList': 'Maak nieuwe lijst',
  'mon.configure.list.custom.value': 'Aangepaste waarde: {value}',
  'mon.configure.list.custom.value.error': 'Voer een waarde tussen 0 en 600 in.',
  'mon.configure.list.custom.value.placeholder': 'Kies of voer een waarde in',
  'mon.configure.list.download': 'Download de lijst',
  'mon.configure.list.goToListManagement': 'Ga naar List Management',
  'mon.configure.list.heading': 'Bedrijven',
  'mon.configure.list.headingText': 'Kies een lijst of',
  'mon.configure.list.label': 'Monitor bedrijven in lijst',
  'mon.configure.list.link': 'Bekijk in List Management',
  'mon.configure.list.link.newTab': '(opent in nieuw tabblad)',
  'mon.configure.list.none': 'Geen',
  'mon.configure.management': 'Bestuurders',
  'mon.configure.management.changed': 'Wijziging van bestuurders',
  'mon.configure.management.hint':
    'Monitor wijzigingen in de bestuurders van een bedrijf. U krijgt een bericht wanneer er een wijziging is gedetecteerd. De bijgewerkte informatie vindt u in het kredietrapport.',
  'mon.configure.notification.email.label': 'Stuur meldingen naar',
  'mon.configure.notification.frequency': 'Frequentie',
  'mon.configure.notification.frequency.DAILY': 'Dagelijks',
  'mon.configure.notification.frequency.EVERY_FOUR_WEEKS': 'Elke vier weken',
  'mon.configure.notification.frequency.EVERY_MONTH': 'Elke maand',
  'mon.configure.notification.frequency.EVERY_SIX_MONTHS': 'Elke zes maanden',
  'mon.configure.notification.frequency.EVERY_THREE_MONTHS': 'Elke drie maanden',
  'mon.configure.notification.frequency.EVERY_TWO_MONTHS': 'Elke twee maanden',
  'mon.configure.notification.frequency.EVERY_TWO_WEEKS': 'Elke twee weken',
  'mon.configure.notification.frequency.EVERY_YEAR': 'Jaarlijks',
  'mon.configure.notification.frequency.note':
    'Let op: U kunt de frequentie alleen instellen bij de aanmaak van een nieuw profiel. Daarna kan het niet meer gewijzigd worden.',
  'mon.configure.notification.frequency.WEEKLY': 'Wekelijks',
  'mon.configure.notification.heading': 'Melding',
  'mon.configure.operator.ANY_CHANGE': 'Elke wijziging',
  'mon.configure.operator.CHANGE_MORE_THAN': 'Elke wijziging groter dan',
  'mon.configure.operator.CHANGE_MORE_THAN_PERCENT': 'Elke wijziging groter dan',
  'mon.configure.operator.HIGHER_OR_LOWER_THAN': 'Wordt hoger of lager dan',
  'mon.configure.operator.HIGHER_THAN': 'Wordt hoger dan',
  'mon.configure.operator.LOWER_THAN': 'Wordt lager dan',
  'mon.configure.operator.xseptions.ANY_CHANGE': 'Alle XSeptions',
  'mon.configure.operator.xseptions.ONLY_HIGH_AND_MEDIUM_PRIORITY_XSEPTIONS':
    'Alleen hoge en gemiddelde prioriteit',
  'mon.configure.operator.xseptions.ONLY_HIGH_PRIORITY_XSEPTIONS': 'Alleen hoge prioriteit',
  'mon.configure.paymentScore': 'Betaalscore',
  'mon.configure.paymentScore.hint':
    'Monitor de betaalscore. De betaalscore geeft aan in welke mate een organisatie haar facturen betaalt volgens de afspraken en wordt uitgedrukt in een score van 1 tot 10.',
  'mon.configure.phoneNumber': 'Telefoonnummer',
  'mon.configure.phoneNumber.changed': 'Nieuw telefoonnummer geregistreerd',
  'mon.configure.phoneNumber.hint':
    'Monitor wijzigingen in het telefoonnummer van een bedrijf. U krijgt een bericht wanneer er een wijziging is gedetecteerd. De bijgewerkte informatie vindt u in het kredietrapport.',
  'mon.configure.profileName': 'Profielnaam',
  'mon.configure.recipients': 'Stuur meldingen naar',
  'mon.configure.recipients.add': 'Ander e-mailadres toevoegen',
  'mon.configure.recipients.none':
    'Zonder bekend e-mailadres kunnen updates niet verstuurd worden.',
  'mon.configure.save': 'Bewaren en monitoring starten',
  'mon.configure.selection.goToMarketData': 'Ga naar Market Data',
  'mon.configure.selection.link': 'Bekijk selectie in Market Data',
  'mon.configure.sendAlerts': 'Stuur meldingen',
  'mon.configure.sendAlerts.ALWAYS': 'Altijd',
  'mon.configure.sendAlerts.NEVER': 'Nooit',
  'mon.configure.sendAlerts.ONLY_ON_UPDATE': 'Alleen als er updates zijn',
  'mon.configure.shareHolders': 'Aandeelhouders',
  'mon.configure.shareHolders.changed': 'Wijziging van aandeelhouder(s)',
  'mon.configure.shareHolders.hint':
    'Monitor wijzigingen in de aandeehouders van een bedrijf. U krijgt een bericht wanneer er een wijziging is gedetecteerd. De bijgewerkte informatie vindt u in het kredietrapport.',
  'mon.configure.shareholdersDetails': 'Aandeelhouders',
  'mon.configure.shareholdersDetails.changed': 'Wijziging van aandeelhouder(s)',
  'mon.configure.summons': 'Dagvaardingen',
  'mon.configure.summons.hint':
    'Monitor dagvaardingen in detail. U krijgt een update wanneer er een wijziging is gedetecteerd. U krijgt de details van de dagvaardingen te zien.',
  'mon.configure.threshold': 'Drempelwaarde',
  'mon.configure.totalSummons': 'Dagvaardingen',
  'mon.configure.totalSummons.hint':
    'Monitor het aantal dagvaardingen. U krijgt een update wanneer er een wijziging is gedetecteerd. U krijgt het nieuwe en oude aantal dagvaardingen te zien.',
  'mon.configure.ukAugurRating': 'Augur score',
  'mon.configure.ukAugurRating.hint':
    'Monitor de Augur score. De Augur Score is een indicator van financiële gezondheid, uitgedrukt in een score van 0-600. Hoe hoger de score, hoe lager het risico.',
  'mon.configure.ultimateParent': 'Uiteindelijke moedermaatschappij',
  'mon.configure.ultimateParent.hint':
    'Monitor wijzigingen in de uiteindelijke moedermaatschappij van een bedrijf. U krijgt een bericht wanneer er een wijziging is gedetecteerd. De bijgewerkte informatie vindt u in het kredietrapport.',
  'mon.configure.validation.empty': 'Dit veld mag niet leeg zijn',
  'mon.configure.validation.maximum': 'Waarde kan niet hoger zijn dan {max}',
  'mon.configure.validation.minimum': 'Waarde kan niet lager zijn dan {min}',
  'mon.configure.validation.nameAlreadyExists': 'Naam bestaat al',
  'mon.configure.xseptions': 'XSeptions',
  'mon.configure.xseptions.hint':
    'Monitor opmerkelijke situaties binnen een bedrijf (XSeptions). U krijgt een bericht wanneer er een wijziging is gedetecteerd. U kunt kiezen alleen een bericht te krijgen over XSeptions met een specifieke prioriteit zoals bepaald in uw voorkeuren.',
  'mon.configure.xseptions.link.prio-preferences': 'View XSeptions priority preferences',
  'mon.configure.ubo': 'UBO',
  'mon.configure.ubo.hint':
    'Monitor wijzigingen op de UBO (Uiteindelijke belanghebbende) van het bedrijf. U krijgt een bericht wanneer er een wijziging is gedetecteerd. De bijgewerkte informatie vindt u in het UBO rapport.',
  'mon.createListPopup.success': 'Lijst is aangemaakt',
  'mon.createListPopup.success.text':
    'De aangemaakte lijst is gekoppeld aan het monitoring profiel.',
  'mon.createProfile.back': 'Terug naar monitoring-profiel',
  'mon.createProfile.backToEvents': 'Toon update-overzicht',
  'mon.createProfile.backToProfile': 'Toon profielinstellingen',
  'mon.createProfile.heading': 'Maak monitoring-profiel',
  'mon.createProfileLink': 'Nieuw profiel',
  'mon.createProfilePopup.error': 'Profiel kan niet bewaard worden',
  'mon.createProfilePopup.error.nameAlreadyExists': 'Profielnaam bestaat al',
  'mon.createProfilePopup.note':
    'Let op: Updates die overeenkomen met de nieuwe profielinstellingen zijn vanaf morgen of volgende week zichtbaar, afhankelijk van de frequentie die u koos voor meldingen.',
  'mon.createProfilePopup.success': 'Profiel opgeslagen',
  'mon.exportProfiles.button': 'Exporteer profielen',
  'mon.exportProfiles.popup.heading': 'Exporteer meerdere monitoringprofielen',
  'mon.exportProfiles.popup.body.parargraph.1':
    'Werknemers van Graydon kunnen deze geautomatiseerde tool gebruiken om meerdere monitoringprofielen van een klant te exporteren om deze vervolgens te importeren in de Monitoring applicatie van Creditsafe. Upload een CSV-bestand in het gespecificeerde formaat en kies Exporteren.',
  'mon.exportProfiles.popup.body.parargraph.2':
    'Voor meer informatie kunt u contact opnemen met de IT-afdeling van Creditsafe via de servicedesk:',
  'mon.exportProfiles.popup.body.email': 'service@graydon.nl',
  'mon.exportProfiles.popup.link.upload': 'Upload CSV file',
  'mon.exportProfiles.popup.files.selected': 'Geselecteerd bestand',
  'mon.exportProfiles.popup.files.rejected': 'Bestand geweigerd',
  'mon.exportProfiles.popup.link.reset': 'Reset',
  'mon.exportProfiles.popup.button.download': 'Exporteer',
  'mon.exportProfiles.popup.error.400': 'Probleem met de aanvraag',
  'mon.exportProfiles.popup.error.401': 'U bent niet gemachtigd',
  'mon.exportProfiles.popup.error.403': 'U bent niet gemachtigd',
  'mon.exportProfiles.popup.error.404': 'Het uploaden van het bestand is mislukt',
  'mon.exportProfiles.popup.error.500': 'Verwerking van bestand is mislukt',
  'mon.exportProfiles.popup.error.unknown': 'Onbekende fout opgetreden',
  'mon.exportProfiles.popup.processing': 'Uw bestand wordt verwerkt...',
  'mon.exportProfiles.popup.success': 'Exportbestand gedownload',
  'mon.customDownload.button': 'Download geschiedenis',
  'mon.customDownload.button.mobile': 'Geschiedenis',
  'mon.customDownload.heading': 'Aangepaste periode',
  'mon.customDownload.placeholder': 'Kies week',
  'mon.customDownload.text':
    'Selecteer een aangepaste periode om een collectie updates te downloaded',
  'mon.customDownload.title': 'Download geschiedenis',
  'mon.defaultProfileName': 'Mijn profiel',
  'mon.deleteProfileLink': 'Verwijder profiel',
  'mon.deleteProfilePopup.cancel': 'Annuleren',
  'mon.deleteProfilePopup.confirm': 'Verwijder',
  'mon.deleteProfilePopup.paragraph':
    'Weet u zeker dat u dit profiel wilt verwijderen? Als u een profiel verwijdert, dan zullen alle bewaarde meldingen voor dit profiel niet meer beschikbaar zijn.',
  'mon.deleteProfilePopup.success': 'Profiel is verwijderd',
  'mon.deleteProfilePopup.toUpdateOverview': 'Terug naar update-overzicht',
  'mon.emailInvalid': 'Gelieve een geldig e-mailadres in te vullen',
  'mon.eventsPage.courtJudgmentDetails.amount': 'Voor £{amount}',
  'mon.eventsPage.courtJudgmentDetails.cancellation': 'Afwijzing',
  'mon.eventsPage.courtJudgmentDetails.caseNumber': 'Dossiernr.: {caseNumber}',
  'mon.eventsPage.courtJudgmentDetails.judgement': 'Uitspraak',
  'mon.eventsPage.courtJudgmentDetails.location': 'Geregistreerd te {courtName}',
  'mon.eventsPage.courtJudgmentDetails.satisfaction': 'Voldoening',
  'mon.eventsPage.download': 'Download',
  'mon.eventsPage.editProfile': 'Profiel aanpassen',
  'mon.eventsPage.governmentGazette': 'zie Bijlage bij het Belgisch Staatsblad',
  'mon.eventsPage.groupByCompany': 'Groepeer per bedrijf',
  'mon.eventsPage.heading': 'Updates in ‘{profileName}’',
  'mon.eventsPage.list': 'Lijst',
  'mon.eventsPage.noUpdates.emptyList':
    'Er zijn geen updates omdat de lijst die u monitort nog geen bedrijven bevat.',
  'mon.eventsPage.noUpdates.general': 'Er zijn geen updates voor de gekozen periode.',
  'mon.eventsPage.noUpdates.noList':
    'Er zijn geen updates omdat er binnen dit profiel nog geen lijst geselecteerd is.',
  'mon.eventsPage.noUpdates.noList.link': 'Profiel aanpassen',
  'mon.eventsPage.noUpdates.noList.link.after': 'en koppel een lijst aan dit profiel.',
  'mon.eventsPage.numUpdates': '{numUpdates} {numUpdates, plural, =1 {update} other {updates}}',
  'mon.eventsPage.numUpdates.in': 'in',
  'mon.eventsPage.oldValue': '(was: {oldValue})',
  'mon.eventsPage.profileTile.hint':
    'U kunt verschillende monitoringprofielen aanmaken. Voor elk profiel kiest u een lijst van bedrijven en bepaalt u de elementen die u wilt monitoren.',
  'mon.eventsPage.since': 'Sinds',
  'mon.eventsPage.updates': 'Updates',
  'mon.eventsPage.updates.hint':
    'U ziet updates die overeenkomen met uw profielinstellingen en die hebben plaatsgevonden in de opgegeven periode. Ziet u geen updates, dan kunt u uw profielinstellingen aanpassen.',
  'mon.export.title.graydonInsights': 'Graydon Insights',
  'mon.export.title.creditSafe': 'Creditsafe',
  'mon.export.migrate.button': 'How to migrate to Creditsafe?',
  'mon.export.download.button': 'Download profile settings',
  'mon.export.operator.ANY_CHANGE': 'Any change',
  'mon.export.operator.CHANGE_MORE_THAN': 'Reduce by',
  'mon.export.operator.CHANGE_MORE_THAN_PERCENT': 'Reduce by',
  'mon.export.operator.HIGHER_OR_LOWER_THAN': 'Less then',
  'mon.export.operator.ONLY_HIGH_AND_MEDIUM_PRIORITY_XSEPTIONS': 'Any change',
  'mon.export.operator.ONLY_HIGH_PRIORITY_XSEPTIONS': 'Any change',
  'mon.export.configure.general': 'Portfolios / Edit portfolio',
  'mon.export.configure.events': 'Notification rules',
  'mon.export.configure.notification.heading': 'Portfolios / Edit portfolio',
  'mon.export.configure.profileName': 'Portfolio name',
  'mon.export.configure.list.label': 'Selected companies',
  'mon.export.configure.list.value': '[Import companies from file]',
  'mon.export.configure.recipients': 'Emails',
  'mon.export.configure.financialCalamitiesDetails': 'Bankruptcy data',
  'mon.export.configure.creditAmountAdvised': 'Limit',
  'mon.export.configure.creditAmountAdvised.extra':
    'Credit Limit drops below internal (customer) credit limit',
  'mon.export.configure.graydonRating': 'Creditscore',
  'mon.export.configure.graydonRating.higherLower': 'International Score & Return Band',
  'mon.export.configure.xseptions': 'Xseptions Addition',
  'mon.export.configure.xseptions.extra': 'Xseptions Removal',
  'mon.export.configure.annualReport': 'New accounts filed',
  'mon.export.configure.beMultiscore': 'Creditscore',
  'mon.export.configure.beMultiscore.higherLower': 'International Score & Return Band',
  'mon.export.configure.paymentScore': 'Payment experience left',
  'mon.export.configure.ultimateParent': 'Ultimate Holding Company',
  'mon.export.configure.eventsPosition': 'Director(s)',
  'mon.export.configure.management': 'Director(s)',
  'mon.export.configure.shareholdersDetails': 'Share capital change',
  'mon.export.configure.liabilityStatement': 'Declaration of Liability (403)',
  'mon.export.configure.companyName': 'Company name',
  'mon.export.configure.address_1': 'Address',
  'mon.export.configure.phoneNumber': 'Telephone Number',
  'mon.financialCalamities.yes': 'Ja',
  'mon.onboarding.noupdates': 'Er zijn nog geen updates.',
  'mon.onboarding.start': 'Start monitoring',
  'mon.onboarding.step1': 'Maak een monitoring-profiel aan',
  'mon.onboarding.step2': 'Maak nieuwe lijst',
  'mon.onboarding.step3': 'Voeg als volgt bedrijven toe aan de lijst:',
  'mon.onboarding.step3-import-link': 'importeer een lijst met bedrijven',
  'mon.onboarding.step3-import-then': 'via List Management.',
  'mon.onboarding.step3-search-link': 'zoek een bedrijf',
  'mon.onboarding.step3-search-then': 'en voeg deze toe via Credit Reports of',
  'mon.onboarding.to-start-take-steps': 'Neem de volgende stappen om te starten met monitoring:',
  'mon.onboarding.unauthorized':
    'U heeft geen toestemming om een monitoring-profiel aan te maken. Vraag iemand met beheerrechten om eerst een profiel aan te maken.',
  'mon.period': 'Periode',
  'mon.period.lastWeek': 'Vorige week ({date})',
  'mon.period.yesterday': 'Gisteren ({date})',
  'mon.portfolioAnalyser': 'Mijn portfolio',
  'mon.portfolioAnalyser.company.reference': 'Uw referentie',
  'mon.portfolioAnalyser.company.registrationId': 'Officieel registratienummer',
  'mon.portfolioAnalyser.company.registrationId.be': 'Ondernemingsnummer',
  'mon.portfolioAnalyser.company.registrationId.gb': 'Bedrijfsregistratie-nummer',
  'mon.portfolioAnalyser.company.registrationId.nl': 'KvK-nummer',
  'mon.portfolioAnalyser.companyList': 'Bedrijvenlijst',
  'mon.portfolioAnalyser.companyList.hint':
    'Kies een lijst en bekijk bedrijfsgegevens van de bedrijven in die lijst',
  'mon.portfolioAnalyser.companyOverview': 'Bedrijfsinformatie',
  'mon.portfolioAnalyser.companyOverview.hint':
    '<b>Graydon Rating</b><br/>\r\nDe kans dat een bedrijf zijn betalingsafspraken de volgende 12 maanden niet zal nakomen.<br/>\r\n<br/>\r\n<b>Multiscore</b><br/>\r\nParameter om het kredietrisico verbonden aan een bedrijf uit te drukken in een score van 1 tot 100.<br/>\r\n<br/>\r\n<b>Augur score</b><br/>\r\nParameter gebaseerd op statistische analyse van een kredietbestand om het risico of de kredietwaardigheid van een bedrijf weer te geven. Empirisch afgeleide score die varieert van 0 tot 600.<br/>',
  'mon.portfolioAnalyser.download': 'Download',
  'mon.collapseReferences': 'Referenties op één rij weergeven',
  'mon.portfolioAnalyser.graydonRatingOverview': 'Graydon Rating',
  'mon.portfolioAnalyser.graydonRatingOverview.AAA_AA_A': 'AAA/AA/A',
  'mon.portfolioAnalyser.graydonRatingOverview.BBB_BB_B': 'BBB/BB/B',
  'mon.portfolioAnalyser.graydonRatingOverview.CCC_CC_C': 'CCC/CC/C',
  'mon.portfolioAnalyser.graydonRatingOverview.D': 'D - Failliet',
  'mon.portfolioAnalyser.graydonRatingOverview.hint':
    'Dit is een uitsplitsing van de Graydon Rating voor de bedrijven in deze lijst. ',
  'mon.portfolioAnalyser.graydonRatingOverview.NON_MONITORED': 'Niet gemonitord',
  'mon.portfolioAnalyser.graydonRatingOverview.NR': 'Niet beoordeeld',
  'mon.portfolioAnalyser.monitoringMessage':
    'Graydon data is alleen beschikbaar voor bedrijven die in een monitoring lijst staan',
  'mon.portfolioAnalyser.monitoringMessage.link': 'Monitoring instellen',
  'mon.portfolioAnalyser.table.limitNote':
    'Let op: uw (gefilterde) bedrijvenoverzicht bevat {totalAmount} bedrijven. We tonen alleen de eerste {shownAmount}.',
  'mon.profile.noList': 'Geen lijst',
  'mon.profileLabel': 'Profiel',
  'mon.showPortfolioAnalyser': 'Portfolio status',
  'mon.updateProfilePopup.error': 'Profielaanpassingen konden niet bewaard worden',
  'mon.updateProfilePopup.note':
    'Let op: Updates die overeenkomen met de nieuwe profielinstellingen zijn vanaf morgen of volgende week zichtbaar, afhankelijk van de frequentie die u koos voor meldingen.',
  'mon.updateProfilePopup.showUpdateOverview': 'Toon update-overzicht',
  'mon.updateProfilePopup.success': 'Profielaanpassingen zijn bewaard',
  'nav.dm.archive': 'Archief',
  'nav.dm.create': 'Maak een beslismodel',
  'nav.dm.modelmanagement': 'Model management',
  'nav.dm.view': ' ',
  'nav.home': 'Home',
  'nav.mdb.dashboard': 'Management Dashboard',
  'nav.search': 'Zoek een bedrijf',
  'notifications.title': 'Notificaties',
  'one-search.company': 'Bedrijf',
  'one-search.company.form.description':
    'Selecteer een land en voer bedrijfsnaam of andere onderscheidende gegevens in.',
  'one-search.company.form.error': 'Error in fetching country list. Please try again later',
  'one-search.company.form.title': 'Zoek bedrijfsgegevens',
  'one-search.company.search-bar.placeholder': 'Voer bedrijfsinformatie in',
  'one-search.person': 'Persoon',
  'pdf.annualAccount.accountStatus': 'Rekeningstatus',
  'pdf.annualAccount.accountStatus.filed': 'Gedeponeerd',
  'pdf.annualAccount.accountStatus.unknown': 'Onbekend',
  'pdf.annualAccount.baseCurrency': 'Basiskoers',
  'pdf.annualAccount.baseUnit': 'Basiseenheid',
  'pdf.annualAccount.creditRatios': "Belangrijkste kredietratio's",
  'pdf.annualAccount.dateOfClosure': 'Einde van de looptijd',
  'pdf.annualReport.natuur10.text':
    'Omwille van het specifieke model kunnen de jaarrekeningen voor dit bedrijf niet verwerkt worden. U kunt de originele jaarrekeningen consulteren via de website van de <a href="https://www.nbb.be/nl" rel="noopener noreferrer" target="_blank">Nationale Bank van België</a>. Heeft u hier vragen over, contacteer dan ons Customer Service team.',
  'pdf.annualReport.natuur10.title':
    'Dit bedrijf heeft een uitzonderlijk type jaarrekening neergelegd',
  'pdf.annualReport.noReport.text':
    'Dit bedrijf heeft de laatste 5 jaar geen jaarrekening gedeponeerd.',
  'pdf.annualReport.noReport.title': 'Geen financiële calamiteiten voor dit bedrijf',
  'pdf.paymentBehaviour.noData.text':
    'Helaas hebben we niet genoeg informatie beschikbaar voor een juiste analyse. ',
  'pdf.annualReport.noReportLiability.text':
    'Dit bedrijf noch het bedrijf met een aansprakelijheidsverklaring voor dit bedrijf, heeft de laatste 5 jaar een jaarrekening gedeponeerd.',
  'pdf.annualReport.noReportLiability.title': 'Geen jaarrekening beschikbaar ',
  'pdf.branches.limit-message':
    '20+ vestigingen, de volledige lijst vindt u terug in het digitaal rapport',
  'pdf.copyright.statement':
    'Copyright {yearIssued} {customerCountry, select, gb {Graydon UK Limited} be {Graydon Belgium N.V.} nl {Graydon Nederland B.V.} other {Graydon UK Limited}}',
  'pdf.footer.text':
    'Dit {productName} is bestemd voor het exclusieve gebruik van: {companyName} en de contractueel goedgekeurde gebruikers – {userName}.',
  'pdf.footer.ubo': 'UBO Report - {companyName} - {requestorName}',
  'pdf.generic-no-data.text':
    'Heeft u vragen of hulp nodig, contacteer dan ons Customer Service team voor meer informatie.',
  'pdf.generic-no-data.title': 'Deze informatie is niet beschikbaar voor dit bedrijf',
  'pdf.legal.contact':
    'Neem gerust contact op met de Graydon klantenservice via <b>{country, select, gb {+44&nbsp;(0)20&nbsp;8515&nbsp;1400} nl {+31&nbsp;(020)&nbsp;567&nbsp;9501} be {+32&nbsp;(03)&nbsp;280&nbsp;88&nbsp;80} other {+44&nbsp;(0)20&nbsp;8515&nbsp;1400}}</b> or <b><a href="mailto:{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} be {support@graydon.be} other {customerservices@graydon.co.uk}}">{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} be {support@graydon.be} other {customerservices@graydon.co.uk}}</a></b>',
  'pdf.legal.contact.heading': 'Vragen?',
  'pdf.legal.copyright': 'Copyright: {graydonName}',
  'pdf.legal.disclaimer':
    'Op dit Graydon Credit Report zijn de algemene voorwaarden van Graydon van toepassing. De inhoud van dit Graydon Credit Report is vertrouwelijk en mag niet aan derden worden verstrekt (in het geheel of gedeeltelijk). U mag deze inhoud uitsluitend gebruiken voor uw eigen interne bedrijfsmatige gebruik. ',
  'pdf.legal.graydon.BE': 'Graydon Belgium N.V.',
  'pdf.legal.graydon.GB': 'Graydon UK Ltd.',
  'pdf.legal.graydon.NL': 'Graydon Nederland B.V.',
  'pdf.legal.report.requested.by': 'Rapport aangevraagd door',
  'pdf.legal.statement':
    'Op dit Graydon {productName} zijn de algemene voorwaarden van {customerCountry, select, gb {Graydon UK Limited} be {Graydon Belgium N.V.} nl {Graydon Nederland B.V.} other {Graydon UK Limited}} van toepassing. De inhoud van dit Graydon {productName} is vertrouwelijk en mag niet aan derden worden verstrekt (in het geheel of gedeeltelijk). U mag deze inhoud uitsluitend gebruiken voor uw eigen interne bedrijfsmatige gebruik',
  'pdf.section.h1.company-profile': 'Bedrijfsprofiel',
  'pdf.section.h1.company-structure': 'Bedrijfsstructuur',
  'pdf.section.h1.exceptional-events': 'Uitzonderlijke kenmerken',
  'pdf.section.h1.financials': 'Financials',
  'pdf.section.h1.payment.behaviour': 'Betaalgedrag',
  'pdf.section.h1.ratings': 'Ratings',
  'pdf.section.h1.summary': 'Samenvatting',
  'pdf.section.h2.balance-sheet': 'Balans',
  'pdf.section.h2.branches': 'Vestigingen',
  'pdf.section.h2.company-contacts': 'Contactgegevens',
  'pdf.section.h2.company-details': 'Bedrijfsgegevens',
  'pdf.section.h2.credit.flag': 'Kredietvlag',
  'pdf.section.h2.credit.flag.maximum.advised':
    'Maximaal geadviseerde kredietlimiet over 12 maanden',
  'pdf.section.h2.credit.limit': 'Kredietlimiet',
  'pdf.section.h2.credit.limit.trend': 'Kredietlimiet Trend',
  'pdf.section.h2.credit.rating': 'Kredietrating',
  'pdf.section.h2.credit.rating.graydon.percentage': 'Graydon Percentage',
  'pdf.section.h2.credit.rating.graydon.rating': 'Graydon Rating',
  'pdf.section.h2.directors': 'Bestuurders',
  'pdf.section.h2.discontinuation.score': 'Kans op opheffing',
  'pdf.section.h2.events': 'Gebeurtenissen',
  'pdf.section.h2.events.BE': 'Historiek',
  'pdf.section.h2.financial-insolvencies': 'Financiële calamiteiten',
  'pdf.section.h2.general-information': 'Algemene informatie jaarrekeningen',
  'pdf.section.h2.group-structure': 'Groepsstructuur',
  'pdf.section.h2.growth.score': 'Groeiscore',
  'pdf.section.h2.key-figures': 'Kerncijfers',
  'pdf.section.h2.liability': 'Aansprakelijkheid',
  'pdf.section.h2.order-data': 'Order data',
  'pdf.section.h2.others': 'Overige',
  'pdf.section.h2.payment.analysis.table': 'Analyse betaalgedrag',
  'pdf.section.h2.payment.ccj.details': 'Detail vonnissen van het County Court',
  'pdf.section.h2.payment.mortgages': 'Hypotheken',
  'pdf.section.h2.payment.payment.averages': 'Gemiddelde betalingen',
  'pdf.section.h2.payment.score': 'Betaalscore',
  'pdf.section.h2.payment.score.trend': 'Trend betaalscore',
  'pdf.section.h2.payment.summons.and.judgements': 'Dagvaardingen',
  'pdf.section.h2.payment.terms': 'Betaalgedrag laatste 4 kwartalen',
  'pdf.section.h2.payment.trend': 'Days Beyond Terms Trend',
  'pdf.section.h2.profit-and-loss': 'Winst en verlies',
  'pdf.section.h2.rating.trend': 'Graydon Rating trend',
  'pdf.section.h2.shareholders': 'Aandeelhouders',
  'pdf.section.h2.summary.company-data': 'Bedrijfsgegevens',
  'pdf.section.h2.summary.company-structure': 'Bedrijfsstructuur',
  'pdf.section.h2.summary.insolvency': 'Financiële calamiteit',
  'pdf.section.h2.summary.key-figures': 'Kerncijfers',
  'pdf.section.h2.summary.payment-score-ccj-summons.BE': 'Betaalscore, dagvaardingen & vonnissen',
  'pdf.section.h2.summary.payment-score-ccj-summons.GB':
    'Betaalgedrag & vonnissen van het County Court',
  'pdf.section.h2.summary.payment-score-ccj-summons.NL': 'Betaalscore',
  'pdf.section.h2.tradenames': 'Handelsnamen',
  'pdf.section.h2.xseptions.BE': 'XSeptions',
  'pdf.section.h2.xseptions.GB': 'XSeptions',
  'pdf.section.h2.xseptions.NL': 'XSeptions',
  'pdf.section.h3.order-comment': 'Comments',
  'pdf.section.h3.order-number': 'Order number',
  'pdf.section.h3.order-reference': 'Order reference',
  'pdf.support.statement':
    '**Heeft u vragen of hulp nodig, neem contact op met onze Customer Service**.<br />Neem contact op met onze Customer Service team via **{country, select, gb {+44 (0)20 8515 1400} be {+32 (03) 280 88 80} nl {+31 (0)20 567 9501} other {+44 (0)20 8515 1400}}** or at <a href="mailto:{country, select, gb {customerservices@graydon.co.uk} be {support@graydon.be} nl {service@graydon.nl} other {customerservices@graydon.co.uk}}">**{country, select, gb {customerservices@graydon.co.uk} be {support@graydon.be} nl {service@graydon.nl} other {customerservices@graydon.co.uk}}**</a>.',
  'pdf.tradenames.limit-message':
    '20+ handelsnamen, de volledige lijst vindt u terug in het digitaal rapport',
  'pdf.tradenames.title': 'Handelsnamen',
  'person.birthdate': 'Geboortedatum',
  'person.gender.FEMALE.title': 'Mevr.',
  'person.gender.MALE.title': 'Mr.',
  'placeSearch.placeholder': 'Geef een locatie in',
  'position.authorization': 'Autorisatie',
  'position.startdate': 'Startdatum',
  'position.title': 'Titel',
  radius: 'straal',
  rangeTo: 't/m',
  'relation.company-link.warning':
    'OPGELET: Door op de link van het gerelateerde bedrijf te klikken, vraagt u een nieuw Highlight-rapport op. Deze actie wordt in rekening gebracht op uw account.',
  'search-results.item.address': 'Adres',
  'search-results.item.Buyers_Number': 'Kopersnummer',
  'search-results.item.Chamber_Of_Commerce': 'Kamer van Koophandel',
  'search-results.item.Company_Registration_Number': 'Bedrijfsregistratie nummer ',
  'search-results.item.contact.email': 'Gevonden in e-mail adres: {value}',
  'search-results.item.contact.telephoneNumber': 'Gevonden in telefoonnummer: {value}',
  'search-results.item.Fiscal_Code': 'Fiscale Code',
  'search-results.item.gbYCompanyId': 'Gevonden in Graydon nummer: {value}',
  'search-results.item.id': 'Graydon nummer',
  'search-results.item.Internal': 'Company number',
  'search-results.item.National_Government_Business_Identification_Number':
    'National Government Business Identification Number',
  'search-results.item.National1': 'National1',
  'search-results.item.National2': 'National2',
  'search-results.item.NIT': 'NIT',
  'search-results.item.previousCompanyName': 'Gevonden in vorige bedrijfsnaam: {value}',
  'search-results.item.previousRegisteredAddress': 'Gevonden in vorig adres: {value}',
  'search-results.item.registrationNumber.be': 'Ondernemingsnummer',
  'search-results.item.registrationNumber.gb': 'Bedrijfsregistratie nummer',
  'search-results.item.registrationNumber.nl': 'KvK-nummer',
  'search-results.item.RIF': 'RIF',
  'search-results.item.RUC_Number': 'RUC nummer',
  'search-results.item.SIREN_Number': 'SIREN Nummer',
  'search-results.item.SIRET_Number': 'SIRET Nummer',
  'search-results.item.tradeName': 'Gevonden in handelsbenaming: {value}',
  'search-results.item.tradeNames': 'Gevonden in handelsbenaming: {value}',
  'search-results.item.tradingAddress': 'Gevonden in handelsadres: {value}',
  'search-results.item.TSR': 'TSR',
  'search-results.item.type.BRANCH_OFFICE': 'V',
  'search-results.item.type.ROOT_ORG': 'HV',
  'search-results.item.type.tooltip.BRANCH_OFFICE': 'Vestiging',
  'search-results.item.type.tooltip.ROOT_ORG': 'Hoofdvestiging',
  'search-results.item.Unique_Company_Number': 'Uniek bedrijfsnummer',
  'search-results.item.Unknown': 'Onbekend',
  'search-results.item.VAT': 'Ondernemingsnummer',
  'search-results.item.vatNumber': 'Gevonden in BTW-nummer: {value}',
  'search-results.item.Web_Address': 'Webadres',
  'search.advanced.clear': 'Leegmaken',
  'search.advanced.close': 'Sluiten',
  'search.advanced.heading': 'Uitgebreid zoeken',
  'search.advanced.open': 'Uitgebreid zoeken',
  'search.advanced.subheading': 'U kunt meerdere velden gebruiken',
  'search.autocomplete.all': 'toon alle {total} resultaten',
  'search.autocomplete.less': 'Typ verder of…',
  'search.autocomplete.max': 'Toon de eerste {total} resultaten',
  'search.companies.found': '{total} {total, plural, =1 {bedrijf} other {bedrijven}} gevonden',
  'search.error': 'Oeps! We konden uw zoekopdracht niet uitvoeren. Probeer het opnieuw.',
  'search.heading': 'Zoek een bedrijf',
  'search.heading.subtext': 'Controleer of het bedrijf voldoet aan de acceptatievoorwaarden',
  'search.in': 'Kies land',
  'search.name.obsolete': 'Gevonden onder oude bedrijfsnaam {name}',
  'search.no.results': 'Geen resultaten gevonden',
  'search.no.results.check-spelling': 'Controleer de spelling',
  'search.no.results.check-the-following':
    'Geen resultaten gevonden. Controleer het volgende indien van toepassing',
  'search.no.results.for': 'Geen resultaten gevonden voor “{query}”',
  'search.no.results.identifier': 'Controleer de ingevoerde identificatie (bv. BTW-nummer)',
  'search.no.results.refine-search': 'Verfijn uw zoekopdracht met extra informatie',
  'search.no.results.refine-search.extended':
    'Verfijn uw zoekopdracht met extra informatie in de uitgebreide zoekfunctie',
  'search.placeholder':
    'Voer bedrijfsnaam, keyword, KVK-Nummer of BTW-Nummer (inclusief het voorvoegsel GB en zonder spaties tussen de cijfers) in.',
  'search.placeholder.be': 'Voer bedrijfsnaam, keyword, ondernemingsnummer of BTW-Nummer in',
  'search.placeholder.gb':
    'Voer bedrijfsnaam, keyword, bedrijfsregistratie nummer of BTW-Nummer in',
  'search.placeholder.long': 'Voer bedrijfsnaam, keyword, KVK-Nummer of BTW-Nummer in',
  'search.placeholder.nl': 'Voer bedrijfsnaam, keyword, KVK-Nummer of BTW-Nummer in',
  'search.results.found': '{total} {total, plural, =1 {result} other {results}} gevonden.',
  'search.tooltip.country': 'Zoek bedrijven in {country}',
  'search.tradingName': 'Gevonden als handelsnaam {name}',
  'session.expired.close': 'OK',
  'session.expired.description':
    'Uw sessie is verlopen. Als u op de OK knop klikt komt u op de inlog pagina terecht.',
  'share.check-result': 'Deel dit resultaat',
  'share.creditcheck.body':
    'Hallo, ik heb deze check uitgevoerd {url}. Kunt u er even naar kijken?',
  'share.creditcheck.subject': 'Er is een credit check met u gedeeld',
  'shr.archive.subtitle':
    'Archiefweergave voor alle aanvragen van uw account aan het Graydon-systeem.',
  'shr.archive.table.address': 'Adres',
  'shr.archive.table.country': 'Land',
  'shr.archive.table.date': 'Datum besteld',
  'shr.archive.table.download': 'Download rapport',
  'shr.archive.table.download.en': 'Engelse PDF',
  'shr.archive.table.download.fr': 'Franse PDF',
  'shr.archive.table.download.nl': 'Nederlandse PDF',
  'shr.archive.table.person-name': 'Naam van de persoon',
  'shr.archive.table.report': 'Rapport',
  'shr.archive.title': 'Archief',
  'shr.pdf.footer':
    '{personName} - Dit Graydon Stakeholder Report is voor het exclusieve gebruik van: {requestorCustomer} - {requestorName}',
  'shr.report.archive.tooltip':
    'Hier vindt u alle eerder geconsulteerde stakeholder rapporten terug, gesorteerd volgens datum van aanvraag. U kunt de rapporten opnieuw downloaden in één van de drie beschikbare talen. ',
  'shr.report.company-overview.insolvency.active': 'Actieve financiële calamiteit',
  'shr.report.company-overview.insolvency.inactive': 'Geen bezwaar',
  'shr.report.company-overview.row.city': 'Stad',
  'shr.report.company-overview.row.company': 'Bedrijf',
  'shr.report.company-overview.row.company-address': 'Adres van het bedrijf',
  'shr.report.company-overview.row.company-number': 'Bedrijfsnummer',
  'shr.report.company-overview.row.date': 'Begin- en einddatum',
  'shr.report.company-overview.row.insolvency': 'Financiële calamiteit',
  'shr.report.company-overview.row.position': 'Positie',
  'shr.report.company-overview.title': 'Bedrijvenoverzicht ({positions} posities)',
  'shr.report.company-overview.tooltip':
    'In dit rapport ziet u alle actieve en inactieve posities van de belanghebbenden. De posities zijn gesorteerd op datum. U kunt een rapport op de verschillende organisaties consulteren als dat binnen uw Graydon contract geactiveerd is.',
  'shr.search.add-birth-date': 'Voeg geboortedatum toe',
  'shr.search.date-invalid': 'Voer een correcte datum in',
  'shr.search.date-month-and-year': 'Voer een maand en jaar in',
  'shr.search.dateOfBirth.day': 'Dag',
  'shr.search.dateOfBirth.month': 'Maand*',
  'shr.search.dateOfBirth.year': 'Jaar*',
  'shr.search.input.placeholder': 'Vul voor- en/of achternaam in van de persoon die u zoekt.',
  'shr.search.result.address': 'Adres',
  'shr.search.result.birthDate': 'Geboortedatum',
  'shr.search.result.personId': 'Persoons-id',
  'shr.search.result.position': '**{role}** bij **{company}**',
  'shr.search.result.request-report': 'Vraag een rapport aan',
  'shr.search.result.total': '{total} resultaten gevonden',
  'shr.search.subtitle':
    'Gelieve de naam toe te voegen van de belanghebbende die u zoekt.Gebruik indien nodig de geboortedatum.',
  'shr.search.title': 'Zoeken naar informatie belanghebbende',
  'ubo.report.bulkComplianceCheck.complete.body':
    'Zie compliance check gegevens verder op de pagina',
  'ubo.report.bulkComplianceCheck.complete.heading': 'Rapport is bijgewerkt',
  'ubo.report.bulkComplianceCheck.loading.body':
    'Een nieuw rapport inclusief all compliance check gegevens wordt gegenereerd. Je vindt het rapport in het [Company Archive](/company-archive/organisation/{enterpriseId}) zodra het beschikbaar is.\n',
  'ubo.report.bulkComplianceCheck.loading.heading': 'Rapport wordt gegenereerd',
  'ubo.report.compliance.check.modal.cancel': 'Annuleren',
  'ubo.report.compliance.check.modal.description':
    'Voeg de roepnaam toe (indien bekend) voor betere compliance check resultaten. ',
  'ubo.report.compliance.check.modal.input-label': 'Roepnaam (optioneel)',
  'ubo.report.compliance.check.modal.name': 'Naam',
  'ubo.report.compliance.check.modal.submit': 'Compliance check',
  'ubo.report.compliance.check.modal.title': 'Compliance check van persoon',
  'ubo.report.complianceCheck.request.bulk': 'Vraag alle compliance checks aan',
  'ubo.report.complianceCheck.request.bulk.all': 'Controleer alle UBO’s en bestuurders',
  'ubo.report.complianceCheck.request.bulk.companies': 'Controleer alle bedrijven',
  'ubo.report.complianceCheck.request.bulk.ubo': 'Controleer alle UBO’s',
  'ubo.report.complianceCheck.request.info':
    'U kunt een compliance check aanvragen voor alle personen of alle bedrijven tegelijkertijd of per individuele UBO, bestuurder of bedrijf.',
  'ubo.report.complianceCheck.usage.report.download': 'Compliance check verbruikdata (.xlsx)',
  'ubo.report.directors.title': 'Bestuurders (alleen personen)',
  'ubo.report.downloadPdf': 'Download rapport',
  'ubo.report.downloadPdf.en-GB': 'Engelse PDF',
  'ubo.report.downloadPdf.fr': 'Franse PDF',
  'ubo.report.downloadPdf.nl-NL': 'Nederlandse PDF',
  'ubo.report.heading': 'UBO Rapport',
  'ubo.report.requestedDate': 'Datum',
  'ubo.report.requester': 'Aanvrager van rapport',
  'ubo.report.summary.explanation':
    'Het UBO-overzicht toont alle uiteindelijk begunstigden in de groepsstructuur van het aangevraagde bedrijf. Elke tak in de structuur is een pad naar de hoogste entiteit. Aanvullend worden alle bestuurders van het aangevraagde bedrijf getoond.',
  'ubo.report.summary.heading': 'UBO samenvatting',
  'ubo.report.summary.hint':
    'De uitkomsten die per pad kunnen voorkomen zijn:\n* Er is een __UBO gevonden__ in dit pad. In dit geval wordt de gevonden UBO getoond en het pad daarnaartoe.\n* Er is in dit pad __geen UBO gevonden__. In dit geval wordt de hoogst gevonden bestuurder getoond en het pad er naar toe.\n* Er is in dit pad __onvoldoende data__ om een UBO te bepalen. In dit geval kan geen persoon worden gevonden en wordt alleen het pad naar deze uitkomst getoond.\n* Het pad is __ondoorzichtig / complex__. Indien eenzelfde entiteit meerdere keren voorkomt in het pad en er dus een kringverwijzing ontstaat dan wordt deze uitkomst gegeven. Het pad wordt getoond. Nader onderzoek is aan te raden.',
  'ubo.report.summary.numberOf.companies':
    '{numberOfCompanies, plural, =0 {geen bedrijven} =1 {# uniek bedrijf} other {# unieke bedrijven}}',
  'ubo.report.summary.numberOf.directors':
    '{numberOfDirectors, plural, =0 {geen bestuurders} =1 {# bestuurder} other {# bestuurders}}',
  'ubo.report.summary.numberOf.paths':
    '{numberOfSearchPaths, plural, =0 {geen paden} =1 {1 pad} other {{numberOfSearchPaths} paden}}',
  'ubo.report.summary.numberOf.ubos':
    '{numberOfUbos, plural, =0 {geen UBO’s} =1 {# (mogelijke) UBO} other {# (mogelijke) UBO’s}}',
  'ubo.report.summary.results': 'Uitkomst',
  'ubo.report.ubo.search.result.compliance.hint.company':
    'Een compliance check voor een bedrijf bestaat altijd uit 3 controles: \n* __Sanctielijst__: De entiteit komt voor op een of meerdere officiële sanctielijsten die door overheden wereldwijd worden onderhouden. \n* __Negatieve media__: Kranten in meer dan 15 talen worden gemonitord op informatie betreffende onwettige activiteiten waaronder witwassen, fraude, drugshandel, terrorisme en corruptie.\n* __Staatsbedrijf__: een bedrijf waarop de overheid of de staat significante invloed heeft door een volledig, grotendeels of significant minderheids-eigenaarsschap.\n\nIn basis zijn er drie mogelijke uitkomsten:\n* __NO MATCH__: Het betreffende bedrijf komt op geen enkele manier naar voren bij het uitvoeren van de Compliance Check.\n* __EXACT MATCH__: Het betreffende bedrijf komt naar voren bij een of meerdere onderdelen van de Compliance Check. Zowel de achternaam, het land als het geboortejaar van de persoon komen overeen met de gegevens op een van de registraties.\n* __POSSIBLE MATCH__: Het betreffende bedrijf komt naar voren bij een of meerdere onderdelen van de Compliance Check. De achternaam en het land komen overeen maar het geboortejaar van de persoon kan niet worden gematched met de gegevens op een van de registraties.\n',
  'ubo.report.ubo.search.result.compliance.hint.person':
    'Een compliance check voor een persoon bestaat altijd uit 3 controles: \n* __Politically Exposed Person (PEP)__: De term heeft betrekking op een huidig of voormalig senior politiek persoon, inclusief de directe familie en naaste verwanten. \n* __Sanctielijst__: De entiteit komt voor op een of meerdere officiële sanctielijsten die door overheden wereldwijd worden onderhouden. \n* __Negatieve media__: Kranten in meer dan 15 talen worden gemonitord op informatie betreffende onwettige activiteiten waaronder witwassen, fraude, drugshandel, terrorisme en corruptie.\n\nIn basis zijn er drie mogelijke uitkomsten:\n* __NO MATCH__: De betreffende persoon komt op geen enkele manier naar voren bij het uitvoeren van de Compliance Check.\n* __EXACT MATCH__: De betreffende persoon komt naar voren bij een of meerdere onderdelen van de Compliance Check. Zowel de achternaam, het land als het geboortejaar van de persoon komen overeen met de gegevens op een van de registraties.\n* __POSSIBLE MATCH__: De betreffende persoon komt naar voren bij een of meerdere onderdelen van de Compliance Check. De achternaam en het land komen overeen maar het geboortejaar van de persoon kan niet worden gematched met de gegevens op een van de registraties.\n',
  'ubo.report.ubo.search.result.hint':
    '# Niveau\nHet hoogste niveau in het betreffende pad. Elke stap die binnen de concernstructuur wordt genomen telt als een niveau.\n\n# Resultaattype\nDe rol van persoon die is gevonden bij de hoogste entiteit in het betreffende pad.\n\n# Uiteindelijk aandeel in aangevraagd bedrijf\nHet uiteindelijk aandeel (% aandelen) dat de betreffende persoon heeft in het aangevraagde bedrijf.\n\n# Functietitel en autorisatie\nFunctietitel en bevoegdheid van de gevonden persoon bij de hoogst gevonden entiteit in dit pad\n',
  'ubo.report.ubo.search.result.path.hint':
    '# NCCT/FATF controle\nNon-Cooperative Countries and Territories, afgekort: NCCT. Een door de Financial Action Task Force (FATF) gepubliceerde lijst van landen en gebieden die niet meewerken op het gebied van het voorkomen van witwassen.\n\n# Uiteindelijk aandeel in aangevraagd bedrijf\nHet uiteindelijk aandeel (% aandelen) dat het bedrijf op dat niveau heeft in het aangevraagde bedrijf.',
  'ubo.report.uboSearch.result': 'UBO zoekpad',
  'ubo.report.uboSearch.result.authorisation': 'Authorisatie',
  'ubo.report.uboSearch.result.collapsePath': 'Verberg pad',
  'ubo.report.uboSearch.result.compliance.button.text': 'Compliance check',
  'ubo.report.uboSearch.result.compliance.checkType': 'Check type',
  'ubo.report.uboSearch.result.compliance.date': 'Compliance check uitgevoerd op',
  'ubo.report.uboSearch.result.compliance.dateofbirth': 'Geboortedatum',
  'ubo.report.uboSearch.result.compliance.details': 'Details',
  'ubo.report.uboSearch.result.compliance.explanation':
    'Een compliance check voor een persoon bestaat altijd uit 3 controles: een controle of de persoon prominent actief is in de politiek (Politically Exposed Person-controle), een controle of de persoon voorkomt op een sanctielijst en een controle of er negatieve berichtgeving is rondom de persoon (Controle negatieve media)',
  'ubo.report.uboSearch.result.compliance.explanation.company':
    'Een compliance check voor een bedrijf bestaat altijd uit 3 controles: een controle of een bedrijf voorkomt op een sanctielijst, een controle of er negatieve berichtgeving is rondom het bedrijf (Controle negatieve media) en een controle of het een staatsbedrijf is (State-Owned Enterprise).',
  'ubo.report.uboSearch.result.compliance.firstname': 'Voornaam',
  'ubo.report.uboSearch.result.compliance.gender': 'Geslacht',
  'ubo.report.uboSearch.result.compliance.gender.f': 'Vrouw',
  'ubo.report.uboSearch.result.compliance.gender.m': 'Man',
  'ubo.report.uboSearch.result.compliance.heading':
    'Compliance check {checkType, select, person {(persoon)} company {(bedrijf)} other {} }',
  'ubo.report.uboSearch.result.compliance.key': 'Zoekwoord',
  'ubo.report.uboSearch.result.compliance.lastname': 'Achternaam',
  'ubo.report.uboSearch.result.compliance.links': 'Links',
  'ubo.report.uboSearch.result.compliance.media': 'Controle negatieve media',
  'ubo.report.uboSearch.result.compliance.noresult': 'Geen informatie gevonden',
  'ubo.report.uboSearch.result.compliance.pep': 'PEP-controle',
  'ubo.report.uboSearch.result.compliance.politicallevel': 'Politiek niveau',
  'ubo.report.uboSearch.result.compliance.position': 'Positie',
  'ubo.report.uboSearch.result.compliance.reasonListed': 'Reden vermelding',
  'ubo.report.uboSearch.result.compliance.result':
    '{status, select, NO_MATCH {Geen informatie gevonden} other {Informatie gevonden}}',
  'ubo.report.uboSearch.result.compliance.sanction': 'Controle sanctielijst',
  'ubo.report.uboSearch.result.compliance.soe': 'Controle staatsbedrijf',
  'ubo.report.uboSearch.result.compliance.source': 'Bronvermelding',
  'ubo.report.uboSearch.result.compliance.status': 'Status matching',
  'ubo.report.uboSearch.result.compliance.subcategory': 'Subcategorie',
  'ubo.report.uboSearch.result.compliance.toast.body':
    'De gegevens voor de compliance check van deze persoon konden niet opgehaald worden',
  'ubo.report.uboSearch.result.compliance.toast.title': 'Geen gegevens opgehaald',
  'ubo.report.uboSearch.result.dateOfBirth': 'Geboortedatum',
  'ubo.report.uboSearch.result.expandPath': 'Toon pad',
  'ubo.report.uboSearch.result.jobTitle': 'Functietitel',
  'ubo.report.uboSearch.result.level': 'op niveau {level}',
  'ubo.report.uboSearch.result.name': 'Naam',
  'ubo.report.uboSearch.result.path': 'Pad',
  'ubo.report.uboSearch.result.path.companyName': 'Bedrijfsnaam',
  'ubo.report.uboSearch.result.path.country': 'Land',
  'ubo.report.uboSearch.result.path.directShares': 'Direct aandeel in onderliggend bedrijf',
  'ubo.report.uboSearch.result.path.level': 'Niveau',
  'ubo.report.uboSearch.result.path.NCCT_FATF_CountryCheck': 'NCCT/FATF Controle',
  'ubo.report.uboSearch.result.path.NCCT_FATF_CountryCheck.false':
    'Dit land staat niet op de NCCT lijst die onderhouden wordt door de FATF',
  'ubo.report.uboSearch.result.path.NCCT_FATF_CountryCheck.true':
    'Dit land staat op de NCCT lijst die onderhouden wordt door de FATF',
  'ubo.report.uboSearch.result.path.ultimateShares': 'Uiteindelijk aandeel in aangevraagd bedrijf',
  'ubo.report.uboSearch.result.path.unknownRelationship': 'Onbekende relatie',
  'ubo.report.uboSearch.result.placeOfBirth': 'Geboorteplaats',
  'ubo.report.uboSearch.result.status.NO-DATA': 'Onvoldoende data beschikbaar om UBO te bepalen',
  'ubo.report.uboSearch.result.status.NO-UBO': 'Geen UBO gevonden',
  'ubo.report.uboSearch.result.status.NO-UBO_BANKRUPTCY': 'Faillissement',
  'ubo.report.uboSearch.result.status.NO-UBO_COMPLEX': 'Complex',
  'ubo.report.uboSearch.result.status.NO-UBO_DISSOLUTION': 'Ontbinding',
  'ubo.report.uboSearch.result.status.NO-UBO_DO_INQUIRY':
    'Geen UBO gevonden, aanvullend onderzoek geadviseerd',
  'ubo.report.uboSearch.result.status.NO-UBO_FOREIGN_COMPANY': 'Buitenlands bedrijf',
  'ubo.report.uboSearch.result.status.NO-UBO_MORATORIUM': 'Moratorium',
  'ubo.report.uboSearch.result.status.POSSIBLE-UBO': 'Mogelijke UBO gevonden',
  'ubo.report.uboSearch.result.status.POSSIBLE-UBO-SEARCH-RESULT':
    'Er werd een mogelijke UBO gevonden en die is te vinden in het zoekresultaat',
  'ubo.report.uboSearch.result.status.UBO': 'UBO gevonden',
  'ubo.report.uboSearch.result.status.UBO-SEARCH-RESULT':
    'Er werd een definitieve UBO gevonden en die is te vinden in het zoekresultaat',
  'ubo.report.uboSearch.result.type': 'Resultaattype',
  'ubo.report.uboSearch.result.type.AUTHORIZED PROXY': 'Geautoriseerde volmacht',
  'ubo.report.uboSearch.result.type.PROXY': 'Volmacht',
  'ubo.report.uboSearch.result.type.MANAGER': 'Bedrijfsleider',
  'ubo.report.uboSearch.result.type.SHAREHOLDER': 'Aandeelhouder',
  'ubo.report.uboSearch.result.type.UBO': 'UBO',
  'ubo.report.uboSearch.result.type.PSEUDO UBO': 'Pseudo UBO',
  'ubo.report.uboSearch.result.type.STATUTORY MANAGER': 'Statutaire bedrijfsleider',
  'ubo.report.usage.report.download': 'UBO verbruikdata (.xlsx)',
  'ubo.request.foreign.company.error.message': 'Probeert u het later nogmaals of',
  'ubo.request.foreign.company.error.message.link': 'contact Graydon',
  'ubo.request.foreign.company.error.title': 'Aanvraag kon niet worden verstuurd',
  'ubo.request.foreign.company.modal.button.text': 'Vraag onderzoek aan',
  'ubo.request.foreign.company.modal.description':
    'Wenst u een UBO-onderzoek en een compliance check uit te voeren voor dit bedrijf (35 EUR)? U ontvangt de resultaten binnen enkele dagen per e-mail.',
  'ubo.request.foreign.company.success.message':
    'U ontvangt de resultaten per mail binnen enkele dagen.',
  'ubo.request.foreign.company.success.title': 'Aanvraag is verstuurd',
  'ubo.request.foreign.company.title': 'Vraag bedrijfsonderzoek aan',
  'ubo.requestUboReport': 'UBO Rapport',
  'um.authorization-title': 'Unauthorized access',

  'um.cancel': 'Cancel',
  'um.createListPopup.success': 'Lijst is aangemaakt',
  'um.createListPopup.success.text': 'De lijst is aangemaakt en geselecteerd',
  'um.no-authorization': 'You are unauthorized to access this page',
  'unit.months': '{value} maand(en)',
  'unknown.address': 'Adres onbekend',
  'uploading.downloadExample': 'Zie voorbeeld-bedrijfsbestand:',
  'uploading.dropzone.browse': 'browse',
  'uploading.dropzone.chooseAdifferentFile': 'Of kies een ander bestand',
  'uploading.dropzone.disclaimer':
    'Uw gegevens zijn veilig. Graydon zal deze nooit met derden delen.',
  'uploading.dropzone.dropFile': 'Sleep uw bestand hierheen om te uploaden',
  'uploading.dropzone.selectNew': 'Kies een ander bestand',
  'uploading.dropzone.submitButton': 'Upload lijst',
  'uploading.dropzone.tip': 'Het maximale bestandsformaat is 1 GB. Bestanden moeten .xlsx zijn',
  'uploading.dropzone.title': 'Sleep uw bestand hierheen of',
  'uploading.dropzone.warning1': 'Oeps! Dit bestandstype wordt niet ondersteund. Gebruik .xlsx',
  'uploading.dropzone.warning2':
    'Oeps! Het bestand is te groot. Het maximale bestandsformaat is 1 GB.',
  'uploading.error.button': 'Probeer het opnieuw',
  'uploading.error.message':
    'Dit is onze fout. Als dit blijft gebeuren, neem dan contact op met de klantenservice',
  'uploading.errorPage.heading': 'Er ging iets mis ...',
  'uploading.header': 'Importeer bedrijven uit',
  'uploading.loadingPage.heading': 'Uw gegevens worden voorbereid.',
  'uploading.point0': 'Het bestand moet de indeling .xlsx of .csv hebben',
  'uploading.point1': 'Headers zijn aanwezig in de eerste rij',
  'uploading.point2':
    'Uw gegevens worden beter gematcht als deze (tenminste) de volgende gegevens bevatten:',
  'uploading.point2a.be': 'Ondernemingsnummers',
  'uploading.point2a.gb': 'Bedrijfsregistratie nummers',
  'uploading.point2a.nl': 'KVK-nummers',
  'uploading.point2b': 'Bedrijfsnamen en volledig adressen',
  'uploading.point3':
    'Indien u de bedrijfsdata weer in uw CRM wilt importeren, is een referentie belangrijk',
  'uploading.step1': 'Kies een land',
  'uploading.subHeader': 'Controleer het bestand dat u uploadt op de volgende punten:',
  'user.bar.language': 'Taal',
  'user.greeting': 'Hi {user}',
  'user.management': 'Gebruikersbeheer',
  'user.roles.delivery_managers': 'Beheerder',
  'user.roles.delivery_users': 'Gebruiker',
  'widgets.acceptance.grade.title': 'Acceptatiegraad',
  'widgets.area.acceptance.grade.title': 'Acceptatiegraad',
  'widgets.avg.order.value.title': 'Gemiddelde orderwaarde',
  'widgets.consumption.title': 'International Business verbruik',
  'widgets.total.orders.title': 'Totaal aantal checks',
  'xs.affiliations.directors.birthDate': 'Geboortedatum',
  'xs.affiliations.directors.birthPlace': 'Geboorteplaats',
  'xs.company-details.more-xseptions': '+ {count} meer XSeption(s)',
  'xs.company-details.title': 'Opgevraagd bedrijf',
  'xs.company-relations.description':
    'XSeptions screent eerste en tweedegraads relaties (bedrijven en/of personen) op opmerkelijke situaties. Denk hierbij aan vermelding op sanctielijsten, ondoorzichtige bedrijfsstructuren of recente faillissementen van de bestuurder.',
  'xs.company-relations.no-xseptions': 'Geen XSeptions gevonden',
  'xs.company-relations.person.address': 'Adres',
  'xs.company-relations.person.name': 'Naam',
  'xs.company-relations.present': 'Heden',
  'xs.company-relations.relation.dates': 'Start- en einddatum',
  'xs.company-relations.relation.level.1': '1e graad',
  'xs.company-relations.relation.level.2': '2e graad',
  'xs.company-relations.title': 'Gevonden relaties',
  'xs.company-relations.unknown': 'Onbekend',
  'xs.company-relations.unknown-company': 'Onbekend bedrijf',
  'xs.company-relations.unknown-person': 'Onbekende persoon',
  'xs.filters.title': 'Filters',
  'xs.filters.toggle.label': 'Toon lijstweergave',
  'xs.filters.tooltip':
    'Dit netwerk toont het opgevraagde bedrijf en de 1e en 2e graads gerelateerde bedrijven (zowel actieve en inactieve) en personen.',
  'xs.graph.hover.xseptions.title': 'XSeption(s)',
  'xs.graph.loading.display': 'Toon nu',
  'xs.graph.loading.message':
    'Netwerk wordt weergegeven.\n\nWacht alstublieft om de weergave te versnellen.',
  'xs.pdf.footer': 'Network incl. XSeptions - {companyName} - {requestorName}',
  'xs.person.details.title': 'Persoonsgegevens',
  'xs.preferences.priority.level.active': 'Activeer prioriteitsniveaus voor XSeptions',
  'xs.preferences.priority.level.cancel': 'Annuleren',
  'xs.preferences.priority.level.high': 'Hoog',
  'xs.preferences.priority.level.intro':
    'Activeer en bepaal de XSeptions-prioriteitsniveaus per land hieronder. Klik Bewaar om de voorkeuren op te slaan.',
  'xs.preferences.priority.level.low': 'Laag',
  'xs.preferences.priority.level.medium': 'Gemiddeld',
  'xs.preferences.priority.level.save': 'Bewaar XSeptions-voorkeuren',
  'xs.preferences.priority.level.title': 'Prioriteitsniveaus voor XSeptions',
  'xs.preferences.priority.level.tooltip':
    'Sommige XSeption types zijn mogelijk relevanter of minder relevant voor uw situatie. Als u XSeption-prioriteitsniveaus activeert wordt de prioriteit per XSeption aangegeven in het XSeption report, zodat u kunt focussen op relevante uitzonderlijke kenmerken.',
  'xs.preferences.success.link': 'Ga naar Zoek een bedrijf',
  'xs.preferences.success.message': 'De prioriteitsvoorkeuren voor XSeptions zijn bijgewerkt.',
  'xs.relation-type.ESC':
    '{entityA} **heeft als entiteit met aanzienlijke zeggenschap** {entityB} {dateRange}',
  'xs.relation-type.ESC.past':
    '{entityA} **had als entiteit met aanzienlijke zeggenschap** {entityB} {dateRange}',
  'xs.relation-type.HASSHL':
    '{entityA} **heeft als aandeelhouder {shareDetails}** {entityB} {dateRange}',
  'xs.relation-type.HASSHL.past':
    '{entityA} **had als aandeelhouder {shareDetails}** {entityB} {dateRange}',
  'xs.relation-type.ISSHL':
    '{entityA} **is aandeelhouder {shareDetails} van** {entityB} {dateRange}',
  'xs.relation-type.ISSHL.past':
    '{entityA} **was aandeelhouder {shareDetails} van** {entityB} {dateRange}',
  'xs.relation-type.LBSBY':
    '{entityA} **heeft een aansprakelijkheidsverklaring ontvangen van** {entityB} {dateRange}',
  'xs.relation-type.LBSBY.past':
    '{entityA} **heeft een aansprakelijkheidsverklaring ontvangen van** {entityB} {dateRange}',
  'xs.relation-type.LBSFOR':
    '{entityA} **heeft een aansprakelijkheidsverklaring afgegeven voor** {entityB} {dateRange}',
  'xs.relation-type.LBSFOR.past':
    '{entityA} **heeft een aansprakelijkheidsverklaring afgegeven voor** {entityB} {dateRange}',
  'xs.relation-type.MANBY': '{entityA} **heeft als {position}** {entityB} {dateRange}',
  'xs.relation-type.MANBY.past': '{entityA} **had als {position}** {entityB} {dateRange}',
  'xs.relation-type.MANFOR': '{entityA} **is {position} van** {entityB} {dateRange}',
  'xs.relation-type.MANFOR.past': '{entityA} **was {position} van** {entityB} {dateRange}',
  'xs.relation-type.PAR': '{entityA} **heeft als moedermaatschappij** {entityB} {dateRange}',
  'xs.relation-type.PAR.past': '{entityA} **had als moedermaatschappij** {entityB} {dateRange}',
  'xs.relation-type.SUB': '{entityA} **heeft als dochtermaatschappij** {entityB} {dateRange}',
  'xs.relation-type.SUB.past': '{entityA} **had als dochtermaatschappij** {entityB} {dateRange}',
  'xs.relation-type.UP':
    '{entityA} **heeft als uiteindelijke moedermaatschappij** {entityB} {dateRange}',
  'xs.relation-type.UP.past':
    '{entityA} **had als uiteindelijke moedermaatschappij** {entityB} {dateRange}',
  'xs.report.download-pdf': 'Download rapport',
  'xs.report.download-pdf.en-GB': 'Engelse PDF',
  'xs.report.download-pdf.fr': 'Franse PDF',
  'xs.report.download-pdf.nl-NL': 'Nederlandse PDF',
  'xs.report.download-xls.en-GB': 'Engelse Excel',
  'xs.report.download-xls.fr': 'Franse Excel',
  'xs.report.download-xls.nl-NL': 'Nederlandse Excel',
  'xs.report.download.content.nodeType.organisation': 'Bedrijf',
  'xs.report.download.content.nodeType.person': 'Persoon',
  'xs.report.download.headers.address': 'Adres',
  'xs.report.download.headers.dateOfBirth': 'Geboortedatum',
  'xs.report.download.headers.dissolvedDate': 'Opheffingsdatum',
  'xs.report.download.headers.endDate': 'Einddatum',
  'xs.report.download.headers.graydonNumber': 'Graydon-nummer',
  'xs.report.download.headers.graydonNumOfRelated': 'Graydon-nummer van relatie',
  'xs.report.download.headers.name': 'Naam',
  'xs.report.download.headers.nodeType': 'Bedrijf of persoon',
  'xs.report.download.headers.placeOfBirth': 'Geboorteplaats',
  'xs.report.download.headers.registrationId': 'Officieel registratienummer',
  'xs.report.download.headers.relationOf': 'Relatie van',
  'xs.report.download.headers.relationType': 'Relatietype',
  'xs.report.download.headers.startDate': 'Startdatum',
  'xs.report.heading': 'Network incl. XSeptions',
  'xs.report.usage.download': 'Network incl. XSeptions verbruikdata (.xlsx)',
  'xs.xseptions.risk-priority': 'Prioriteit',
  'xs.xseptions.risk-priority.H': 'Hoog',
  'xs.xseptions.risk-priority.L': 'Laag',
  'xs.xseptions.risk-priority.M': 'Gemiddeld',
  'xs.xseptions.risk-priority.NA': 'N/A',
  'general.help-center.title': 'Graydon Help Centre',
  'general.help-center.desc':
    "Leer hoe u meer uit Graydon Insights haalt en laat u inspireren! We bundelen hier onze tips & tricks, instructievideo's en meer.",
  'general.help-center.link': 'Bezoek het Help Centre',
  'dbm.create-profile.deliver-complete-columns-removal': 'Opmaak van verwijderde data',
  'dbm.create-profile.deliver-complete-columns-removal.tooltip':
    'Kies de opmaak van verwijderde data in het data-bestand. Lege kolommen zullen worden toegevoegd voor overbodige velden om hetzelfde formaat te gebruiken als bij andere data.\n',
  'dbm.create-profile.deliver-complete-columns-removal.label.short':
    'Gebruik korte opmaak voor verwijderingen',
  'dbm.create-profile.deliver-complete-columns-removal.label.complete':
    'Gebruik dezelfde opmaak voor verwijderingen als voor andere data',
  'mi.isIncludeInIndexFlag': 'Alleen bedrijven met complete en unieke gegevens',
  'mi.isIncludeInIndexFlag.heading': 'Bedrijven-selectie opschonen',
  'mi.isIncludeInIndexFlag.tooltip':
    'Bedrijven die aan de volgende criteria voldoen worden standaard uit de selectie verwijderd:\n\n1. De bedrijfsnaam ontbreekt\n2. De provincie van het bedrijf ontbreekt\n3. De vestiging heeft hetzelfde adres als de hoofdvestiging\n4. Het officiële registratienummer bevindt zich buiten Nederland\n5. Het bedrijf is inactief\n\nVink dit criterium uit om meer bedrijven te selecteren.',
  'dbm.create-profile.data-blocks.DBM_ADDRESS_OLD': 'Adressen Historie',
  'dbm.create-profile.data-blocks.DBM_ADDRESS_OLD.tooltip': '-',
  'dbm.create-profile.data-blocks.DBM_ORGANIZATION_NAME_OLD': 'Organisatienamen Historie',
  'dbm.create-profile.data-blocks.DBM_ORGANIZATION_NAME_OLD.tooltip': '-',
  'dbm.documentation.block-description.DBM_ADDRESS_OLD-NL': '-',
  'dbm.documentation.block-description.DBM_ORGANIZATION_NAME_OLD-NL': '-',
};
