import styled from 'styled-components';
import * as colors from '@graydon/ui-colors';
import { sans } from '../../constants/fonts';

export const Button = styled.button<{
  primary?: boolean;
  secondary?: boolean;
  disabled?: boolean;
  dropdown?: boolean;
  iconButton?: boolean;
  withText?: boolean;
  marginRight?: string;
  minWidth?: string;
  width?: string;
  onClick?: any;
}>`
  height: 44px;
  width: ${({ width = '100%' }) => width};
  line-height: 15px;
  text-transform: uppercase;
  font: 14px ${sans};
  font-weight: 300;
  border: none;
  outline: none;
  border-radius: 0.25em;
  text-decoration: none;
  text-align: center;
  transition: background-color 0.2s ease-out;
  white-space: nowrap;

  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  padding: ${({ dropdown }) => (dropdown && '0 35px 0 15px;') || '0 15px;'};
  ${({ iconButton, withText }) =>
    iconButton
      ? `
    padding: 0;
    min-width: ${({ minWidth = '44px' }) => minWidth};
    max-width: ${withText ? 'initial' : '44px'};
    ${withText ? 'padding: 0 5px 0 15px;' : ''}
    display: flex;
    align-items: center;
    justify-content: center;
    `
      : ''}
  color: ${({ disabled }) => (disabled ? colors.grayLight2 : colors.white)};
  background-color: ${({ primary, disabled }) => {
    if (disabled) return colors.grayLight1;
    return primary ? colors.orange : colors.blue;
  }};

  &:hover {
    background-color: ${({ primary, disabled }) => {
      if (disabled) return colors.grayLight1;
      return primary ? colors.orangeDark : colors.blueDark;
    }};
  }

  &:focus {
    background-color: ${({ primary }) => (primary ? colors.orangeDark : colors.blueDark)};
  }

  &:not(:last-child) {
    margin-right: ${({ marginRight = '10px' }) => marginRight};
  }
`;

export default Button;
