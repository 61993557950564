// @ts-nocheck
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { injectI18n } from 'src/i18n';
import compose from 'lodash/fp/compose';
import getOr from 'lodash/fp/getOr';
import __ from 'lodash/fp/placeholder';
import set from 'lodash/fp/set';
import React from 'react';
import Input from '../../../../components/Input';
import Select from '../../../../components/Select';
import { I18nProp } from 'src/i18n';
import { ClassNameProp, DateObject, DateProp } from '../../types';
import { Wrapper } from './helper-components';
import { getMonthsForLocale } from './utils';
type RestProp = {
  onClose: () => any;
  onChange: (date: DateObject) => any;
};
type Prop = I18nProp & DateProp & ClassNameProp & RestProp;

function DateSelector({ date, i18n, className, onClose, onChange }: Prop) {
  const months = getMonthsForLocale(i18n.locale);

  const setDate = (part: any) => compose(onChange, set(part, __, date), getOr(0, 'target.value'));

  return (
    <Wrapper className={className}>
      <Input
        min="0"
        max="31"
        width="75px"
        type="number"
        value={date.day}
        placeholder={i18n.text('shr.search.dateOfBirth.day')}
        onChange={setDate('day')}
      />

      <Select width="130px" value={date.month} onChange={setDate('month')}>
        <option key="" value={0}>
          {i18n.text('shr.search.dateOfBirth.month')}
        </option>

        {months.map((name, i) => (
          <option key={name} value={i + 1}>
            {name}
          </option>
        ))}
      </Select>

      <Input
        width="90px"
        type="number"
        value={date.year}
        placeholder={i18n.text('shr.search.dateOfBirth.year')}
        onChange={setDate('year')}
      />

      <IconButton aria-label="close" size="small" onClick={onClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </Wrapper>
  );
}

export default injectI18n(DateSelector);
