// @ts-nocheck
import prop from 'lodash/get';
import { hasDomestic, hasAccessToInternationalSearch } from '../../keycloak';
export const extractCountries = ({ countries }: any) => {
  const isAvailable = (code: string) => hasAccessToInternationalSearch(code) || hasDomestic(code);

  return countries.reduce(
    (
      acc: any,
      {
        name,
        code,
        preferredSearchMethod,
        searchMethods,
        serviceLevel,
        available,
        availablePaygo,
        states,
      }: any,
    ) =>
      isAvailable(code)
        ? {
            ...acc,
            [code]: {
              name,
              preferredMethod: preferredSearchMethod || prop(searchMethods, '0.method', null),
              searchMethods,
              serviceLevel,
              available: available || ['NL', 'BE', 'GB'].includes(code.toUpperCase()),
              availablePaygo: !!availablePaygo,
              states,
            },
          }
        : acc,
    {},
  );
};
