import { useEffect, useState } from 'react';
import { getCountryList } from './api';
import { useSessionStorage } from '../../hooks/useLocalStorage';
export function useCountryList() {
  const [countryList, setCountryList] = useState();
  const [error, setError] = useState(null);
  const [clientSession] = useSessionStorage('client-session');
  useEffect(() => {
    getCountryList(clientSession && clientSession.consumerId)
      .then(setCountryList)
      .catch(setError);
  }, [clientSession]);
  return [countryList, error];
}
