import { green } from '@graydon/ui-colors';
import Settings from '@material-ui/icons/Settings';
import React from 'react';
import styled from 'styled-components';
import { I18n } from 'src/i18n';
import { RouterLink } from '../Link';
const PreferencesLinkWrapper = styled(RouterLink)`
  display: flex;
  margin-left: 10px;
  align-self: center;
`;
const SettingsIcon = styled(Settings)`
  width: 20px;
  height: 20px;
  color: ${({ color = green }) => color};
`;

type PreferencesLinkProps = {
  i18n: I18n;
  to: string;
  title?: string;
  color?: string;
};

const PreferencesLink: React.FC<PreferencesLinkProps> = ({ i18n, to, title, color }) => (
  <PreferencesLinkWrapper to={to}>
    {/* @ts-ignore */}
    <SettingsIcon color={color} alt={i18n.text('la.preferences')} title={title} />
  </PreferencesLinkWrapper>
);

export default PreferencesLink;
