export const initialState = {
  hasValidDate: true,
  hasRequiredFields: true,
};
export function validateDate(date: any) {
  if (date == null) {
    return initialState;
  }

  if (!date.month || !date.year || date.month === '0') {
    return {
      hasRequiredFields: false,
      hasValidDate: false,
    };
  }

  // Only validate the full date if day is present
  // A date without a day is valid and does not need this check
  if (date.day) {
    const numericDate = {
      year: Number.parseInt(date.year, 10),
      month: Number.parseInt(date.month, 10) - 1,
      day: Number.parseInt(date.day, 10),
    };
    const d = new Date();
    d.setFullYear(numericDate.year);
    d.setMonth(numericDate.month);
    d.setDate(numericDate.day);

    if (
      !(d instanceof Date) ||
      Number.isNaN(d) ||
      d.getFullYear() !== numericDate.year ||
      d.getMonth() !== numericDate.month ||
      d.getDate() !== numericDate.day
    ) {
      return {
        hasRequiredFields: true,
        hasValidDate: false,
      };
    }
  }

  return initialState;
}
