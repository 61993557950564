import AccountCircle from '@material-ui/icons/AccountCircle';
import AccountTree from '@material-ui/icons/AccountTree';
import BubbleChart from '@material-ui/icons/BubbleChart';
import Business from '@material-ui/icons/Business';
import Inbox from '@material-ui/icons/Inbox';
import PlaylistAdd from '@material-ui/icons/PlaylistAdd';
import get from 'lodash/fp/get';
import React, { ReactNode } from 'react';
import { I18n } from 'src/i18n';
import {
  HasAccessToDecisionModel,
  hasAccessToAnnualAccount,
  hasAccessToCoCExtract,
  hasAccessToCreditReports,
  hasAccessToInternationalCreditReportOnDomestic,
  hasAccessToUboReports,
  hasAccessToXseptionReport,
  hasCreditReportCountry,
  hasInternationalCreditReportCountryOnDomestic,
} from '../../keycloak';
import annualAccountIcon from '../../media/icons/annual-account.svg';
import appIcons from '../../media/icons/appIcons.svg';
import { createCreditReport } from '../../store/credit-reports';
import { sendEvent } from '../../utils/analytics';
import { Icon, SvgIcon } from './helper-components';
import { CompanyData } from './index';
import {
  companyDataUri,
  createUBOReport,
  createXseptionReport,
  creditReportUri,
  uboReportUri,
  xseptionReportUri,
} from './reports';

export type ReportType =
  | 'credit-report'
  | 'international-credit-report'
  | 'coc-extract'
  | 'ubo-report'
  | 'xseption-report'
  | 'add-to-monitoring'
  | 'company-view'
  | 'gdm-check'
  | 'annual-account';

export type OptionType = {
  type: ReportType;
  access: boolean;
  label: string;
  icon: ReactNode;
  action: () => Promise<any>;
};

export const reportOptions = (
  { enterpriseId, registrationNumber, countryCode, cocNumber, companyName }: CompanyData,
  i18n: I18n,
  context: string,
  app: string,
): OptionType[] => [
  {
    type: 'credit-report',
    access: countryCode ? hasCreditReportCountry(countryCode) : hasAccessToCreditReports(),
    label: i18n.text('cr.search.credit-report'),
    icon: (
      <SvgIcon>
        <use xlinkHref={`${appIcons}#company-data`} />
      </SvgIcon>
    ),
    action: () => {
      sendEvent(app, `report-menu-${context}: Request Credit Report`);
      return createCreditReport(enterpriseId).then(get('id')).then(creditReportUri);
    },
  },
  {
    type: 'international-credit-report',
    access: countryCode
      ? hasInternationalCreditReportCountryOnDomestic(countryCode)
      : hasAccessToInternationalCreditReportOnDomestic(),
    label: i18n.text('cr.search.international-credit-report'),
    icon: (
      <SvgIcon>
        <use xlinkHref={`${appIcons}#database-immediate`} />
      </SvgIcon>
    ),
    action: () => {
      sendEvent(app, `report-menu-${context}: Open International Credit Report Modal`);
      return Promise.resolve();
    },
  },
  {
    type: 'coc-extract',
    access: hasAccessToCoCExtract(countryCode),
    label: i18n.text('cr.search.coc-extract'),
    icon: <Business fontSize="small" />,
    action: () => {
      sendEvent(app, `report-menu-${context}: Open CoC Modal`);
      return Promise.resolve({
        enterpriseId,
        countryCode,
        cocNumber,
      });
    },
  },
  {
    type: 'ubo-report',
    // @ts-ignore
    access: hasAccessToUboReports(countryCode) && registrationNumber,
    label: i18n.text('ubo.requestUboReport'),
    icon: <AccountCircle fontSize="small" />,
    action: () => {
      sendEvent(app, `report-menu-${context}: Request UBO report`);
      return createUBOReport(enterpriseId, registrationNumber, countryCode)
        .then(get('reportId'))
        .then(uboReportUri);
    },
  },
  {
    type: 'annual-account',
    access: hasAccessToAnnualAccount(countryCode),
    label: i18n.text('cr.search.annual-account'),
    icon: <Icon src={annualAccountIcon} />,
    action: () => {
      sendEvent(app, `report-menu-${context}: Open Annual Account Modal`);
      return Promise.resolve({
        enterpriseId,
      });
    },
  },
  {
    type: 'xseption-report',
    access: Boolean(countryCode) && hasAccessToXseptionReport(countryCode),
    label: i18n.text('cr.search.xseptions.report'),
    icon: <BubbleChart fontSize="small" />,
    action: () => {
      sendEvent(app, `report-menu-${context}: Request Xseption report`);
      return createXseptionReport(enterpriseId, countryCode)
        .then(get('reportId'))
        .then(xseptionReportUri);
    },
  },
  {
    type: 'add-to-monitoring',
    access: true,
    label: i18n.text('cr.action.button.addToList'),
    icon: <PlaylistAdd fontSize="small" />,
    action: () => {
      sendEvent(app, `report-menu-${context}: Add to list`);
      return Promise.resolve({
        enterpriseId,
        countryCode,
      });
    },
  },
  {
    type: 'gdm-check',
    access: Boolean(enterpriseId) && HasAccessToDecisionModel(),
    label: i18n.text('cr.search.gdm-check'),
    icon: <AccountTree fontSize="small" />,
    action: () => {
      sendEvent(app, `report-menu-${context}: decision model check`);
      return Promise.resolve({
        companyName,
        enterpriseId,
        countryCode,
      });
    },
  },
  {
    type: 'company-view',
    access: true,
    label: i18n.text('cr.search.view-company-archive'),
    icon: <Inbox fontSize="small" />,
    action: () => {
      sendEvent(app, `report-menu-${context}: Company archive`);
      return Promise.resolve(companyDataUri(enterpriseId));
    },
  },
];
