// @ts-nocheck
import { injectI18n } from 'src/i18n';
import React from 'react';
import { StatelessFunctionalComponent } from 'react';
import styles from './styles';
import CustomOption from '../../../../design-system/components-inspection/RichSelect/CustomOption';
import DropDownSvg from '../../../../media/icons/select-arrow-down.svg';
import { I18nProp } from 'src/i18n';
import { noop } from '../../../../utils/FP-helpers';
import { MenuIndicator, Select } from './helper-components';
type Props = {
  statesList: Array<{
    code: string;
    name: string;
  }>;
  selectedCountryState: string;
  onChange: (code: string) => any;
};

function StateSelect({
  i18n,
  statesList,
  onChange = noop,
  selectedCountryState,
}: Props & I18nProp) {
  function onSelectState(data: any) {
    const { value: state } = data;

    if (onChange) {
      onChange(state);
    }
  }

  const options = statesList.map(({ code, name }) => ({
    label: `${name} - ${code}`,
    value: code,
  }));
  const value = options.find((option) => option.value === selectedCountryState);
  return (
    <Select
      className="state-select"
      options={options}
      placeholder={i18n.text('icr.state-select.placeholder')}
      onChange={onSelectState}
      components={{
        Option: CustomOption,
        DropdownIndicator: () => <MenuIndicator src={DropDownSvg} alt="down" />,
      }}
      styles={styles}
      value={value}
    />
  );
}

export default injectI18n(StateSelect) as StatelessFunctionalComponent<Props>;
