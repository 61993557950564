import React from 'react';
import ExternalUboReportModal from '../../../../components/ExternalUboReportModal';
import CompanyMonitoringLists from '../../../../components/CompanyMonitoringLists';
import { sendEvent } from '../../../../utils/analytics';
import DecisionModel from '../../../../components/DecisionModel';

type AppModalsProps = {
  modal?: {
    type: 'GDM_MODAL' | 'UBO_REPORT' | 'MONITORING_LISTS';
    enterpriseId: number;
    countryCode: string;
    registrationNumber: string;
    organisationTagUuids: any;
    setOrganisationTagUuids: any;
    eventFrom: string;
  };
  setModal: (modal: any) => void;
};

const AppModals = ({ modal, setModal }: AppModalsProps) => {
  if (!modal) return null;

  if (modal.type === 'GDM_MODAL') {
    return <DecisionModel onClose={() => setModal(null)} company={modal} />;
  }

  if (modal.type === 'UBO_REPORT') {
    return (
      <ExternalUboReportModal
        onClose={() => setModal(null)}
        enterpriseId={modal.enterpriseId}
        registrationId={modal.registrationNumber}
        countryCode={modal.countryCode}
      />
    );
  }

  if (modal.type === 'MONITORING_LISTS') {
    return (
      <CompanyMonitoringLists
        enterpriseId={modal.enterpriseId}
        countryCode={modal.countryCode}
        onClose={(from: any) => {
          setModal(null);
          sendEvent('CD', `${modal.eventFrom}: Close "Add to list" (${from})`);
        }}
        selectedTagUuids={modal.organisationTagUuids}
        onChangeSelectedTagUuids={(value: any) => {
          if (modal.setOrganisationTagUuids) modal.setOrganisationTagUuids(value);
          sendEvent('CD', `${modal.eventFrom}: Add to list popup: click list checkbox`);
        }}
      />
    );
  }

  return null;
};

export default AppModals;
