// @ts-nocheck
import get from 'lodash/get';
import set from 'lodash/set';
import camelCase from 'lodash/camelCase';
import { getYearsRange, getLastFilledFinancialYear } from '../common';
import { PROFIT_AND_LOSS } from '../../constants';
const TYPE_CODE_TOTAL_BALANCE_SHEET = 'NL_ACC_01';
const TYPE_CODE_TOTAL_LIABILITIES = 'NL_LBL_30_00_000';
const amountUnitMap = {
  '1': 1,
  '2': 10,
  '3': 100,
  '4': 1000,
  '5': 10000,
  '6': 100000,
  '7': 1000000,
};
export const parseAccountingPeriod = (annualAccount: any) =>
  parseInt(get(annualAccount, 'annualReportHeader.accountingPeriod'), 10);
export const parseModel = (annualAccount: any) =>
  get(annualAccount, 'annualReportHeader.typeCode.value', null);
export const getUniqCodesReducer =
  (pathPart: any, years: any) => (uniqTypeCodes: any, annualAccount: any) => {
    const year = parseAccountingPeriod(annualAccount);

    if (years.includes(year)) {
      get(annualAccount, `${pathPart}.typeCodes`, []).forEach(({ typeCode: { value } }: any) =>
        uniqTypeCodes.add(value),
      );
    }

    return uniqTypeCodes;
  };
export const getModels = (years: any, annualAccounts: any) => {
  const accounts = annualAccounts.map((account: any) => ({
    year: parseAccountingPeriod(account),
    model: parseModel(account),
  }));
  return years.map((year: any) => {
    const account = accounts.find((acc: any) => acc.year === year);
    return account ? account.model : null;
  });
};
export function getAnnualReportPath(annualReportType: any) {
  return annualReportType === PROFIT_AND_LOSS
    ? 'profitAndLossStatement'
    : camelCase(annualReportType);
}
export const getAnnualReportUnit = (annualAccount: any, annualReportType: any) => {
  const pathPart = getAnnualReportPath(annualReportType);
  const amountUnitCode =
    get(annualAccount, `${pathPart}.amountUnitCode.value`) ||
    get(annualAccount, 'annualReportHeader.amountUnitCode.value');
  return amountUnitMap[amountUnitCode];
};
export const getYears = (years: any, annualAccounts: any, annualReportType: any) =>
  years.map((year: any) => {
    const annualAccount = annualAccounts.find(
      (x: any) => parseInt(x.annualReportHeader.accountingPeriod, 10) === year,
    );
    const currency = get(annualAccount, 'annualReportHeader.currencyCode');
    const unit = getAnnualReportUnit(annualAccount, annualReportType);
    return {
      year,
      currency,
      unit,
    };
  });
export const extractAnnualAccountsData = (annualReportType: any, data: any) => {
  const lastFilledYear = getLastFilledFinancialYear(data);
  const actualYears = getYearsRange(lastFilledYear, 5);
  const annualAccounts = get(data, 'annualReportProfile.annualReports', []);
  const template = {
    years: getYears(actualYears, annualAccounts, annualReportType),
    models: getModels(actualYears, annualAccounts),
    items: {},
  };
  const pathPart = getAnnualReportPath(annualReportType);
  // Extract all the uniq codes from dataset.
  const uniqCodes = Array.from(
    annualAccounts.reduce(getUniqCodesReducer(pathPart, actualYears), new Set()),
  );
  // Fill default value 'null' for every value of every code.
  uniqCodes.forEach((code) => {
    template.items[code] = actualYears.map(() => null);
  });

  // Hotfix for missing total liabilities from graydon
  // Remove when gdi fixed the issue
  if (
    !template.items[TYPE_CODE_TOTAL_LIABILITIES] &&
    template.items[TYPE_CODE_TOTAL_BALANCE_SHEET]
  ) {
    template.items[TYPE_CODE_TOTAL_LIABILITIES] = actualYears.map(() => null);
  }

  actualYears.forEach((year, i) => {
    const annualAccount = annualAccounts.find(
      (x: any) => parseInt(x.annualReportHeader.accountingPeriod, 10) === year,
    );

    if (annualAccount) {
      const items = get(annualAccount, `${pathPart}.typeCodes`, []);
      uniqCodes.forEach((tableCode) => {
        const item = items.find(({ typeCode: { value } }: any) => value === tableCode);

        if (item) {
          template.items[tableCode][i] = item.value;
        }
      });

      // Hotfix for missing total liabilities from graydon
      // Remove when gdi fixed the issue
      if (
        !get(template.items, `${TYPE_CODE_TOTAL_LIABILITIES}.${i}`) &&
        get(template.items, `${TYPE_CODE_TOTAL_BALANCE_SHEET}.${i}`)
      ) {
        set(
          template.items,
          `${TYPE_CODE_TOTAL_LIABILITIES}.${i}`,
          get(template.items, `${TYPE_CODE_TOTAL_BALANCE_SHEET}.${i}`),
        );
      }
    }
  });
  return template;
};
