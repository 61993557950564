import styled from 'styled-components';
import icon from './cross.svg';

export function createCrossIcon(color) {
  return styled.span`
    &::before {
      content: '';
      background-image: url(icon);
      background-size: 100%;
      background-position: center 0.35em;
      background-repeat: no-repeat;
      margin-right: 0.25em;
      width: 0.65em;
      height: 1em;
      display: inline-block;
    }
  `;
}

export default createCrossIcon('#000');
