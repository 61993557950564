import styled from 'styled-components';
import colors from '../../constants/colors';
import { sans } from '../../constants/fonts';

const TextBlock = styled.p<{
  uppercase?: boolean;
}>`
  color: ${colors.secondaryDark};
  font-family: ${sans};
  font-weight: 100;
  font-size: 16px;
  text-transform: ${({ uppercase }) => (uppercase ? 'uppercase' : 'none')};
`;

export default TextBlock;
