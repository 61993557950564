import get from 'lodash/get';
import { parseDate } from '../common';
export const extractEventsData = (data: any) =>
  get(data, 'operationsProfile.events', []).map((event: any, i: any) => {
    const descriptionTypeCode = get(event, 'type.value');
    const categoryTypeCode = get(event, 'category.value');
    const sourceTypeCode = get(event, 'source.value');
    const eventReference = get(event, 'eventReference');
    const gdiTranslationKeyDescription =
      descriptionTypeCode && `exceptionalEvents.events.description.typeCode-${descriptionTypeCode}`;
    const gdiTranslationKeyCategory =
      categoryTypeCode && `exceptionalEvents.events.category.typeCode-${categoryTypeCode}`;
    const gdiTranslationKeySource =
      sourceTypeCode && `exceptionslEvents.events.source.typeCode-${sourceTypeCode}`;
    return {
      key: i,
      date: parseDate(get(event, 'date')),
      text: get(event, 'text'),
      sourceTypeCode: get(event, 'source.value'),
      gdiTranslationKeyDescription,
      gdiTranslationKeyCategory,
      gdiTranslationKeySource,
      eventReference,
      dateObject: get(event, 'date'),
    };
  });
