import get from 'lodash/get';
import { parseDate, extractRegisteredAddress } from '../common';
import { extractInsolvencies } from '../extractFinancialInsolvenciesData';
import { ENTITY_TYPE_PERSON, ENTITY_TYPE_COMPANY } from '../../constants';
import { getFormattedAddress } from '../../../../../utils/getFormattedAddress';
export const shareholdersComparator = (a: any, b: any) => {
  // Active shareholders (no end date)
  if (!a.endDate && b.endDate) return -1;
  if (a.endDate && !b.endDate) return 1;

  // If both active
  if (!a.endDate && !b.endDate) {
    // Compare percentage
    if (a.percentage > b.percentage) return -1;
    if (a.percentage < b.percentage) return 1;
    // If percentage is same
    // Compare start dates
    // Filled start date leads before unknown start date
    if (a.startDate && !b.startDate) return -1;
    if (!a.startDate && b.startDate) return 1;

    if (a.startDate && b.startDate) {
      if (a.startDate.getTime() > b.startDate.getTime()) return -1;
      if (a.startDate.getTime() < b.startDate.getTime()) return 1;
    }

    // If start date is same
    // Compare name
    const aname = a.name || a.surname;
    const bname = b.name || b.surname;
    if (aname < bname) return -1;
    if (aname > bname) return 1;
    // If everything equals, do not sort.
    return 0;
  }

  // Inactive shareholders (both has end date)
  // Latest end date is leading
  if (a.endDate.getTime() > b.endDate.getTime()) return -1;
  if (a.endDate.getTime() < b.endDate.getTime()) return 1;
  // If end date is same
  // Compare percentage
  if (a.percentage > b.percentage) return -1;
  if (a.percentage < b.percentage) return 1;
  // If percentage is same
  // Compare name
  const aname = a.name || a.surname;
  const bname = b.name || b.surname;
  if (aname < bname) return -1;
  if (aname > bname) return 1;
  // If everything equals, do not sort.
  return 0;
};

const getBirthPlace = (person: any) => {
  const placeOfBirth = get(person, 'placeOfBirth');
  const countryOfBirth = get(person, 'countryOfBirth.description');
  return [placeOfBirth, countryOfBirth].filter((x) => x).join(', ');
};

const getAdditionalInfo = (country: any, type: any, item: any) => {
  if (type === ENTITY_TYPE_PERSON) {
    const { person = {} } = item;
    const { initials, titles, firstName, surNamePrefix, surName: surname, address } = person;
    const fullName =
      get(person, 'fullName') ||
      (country === 'GB'
        ? [titles, firstName, surname].filter((x) => x).join(' ')
        : [initials, surNamePrefix, surname].filter((x) => x).join(' '));
    const personAddress =
      country === 'BE'
        ? {
            address: getFormattedAddress(country, address),
          }
        : {};
    return {
      birthPlace: getBirthPlace(person),
      birthDate: parseDate(get(person, 'dateOfBirth')),
      gender: get(person, 'gender'),
      fullName,
      surname,
      ...personAddress,
    };
  }

  if (type === ENTITY_TYPE_COMPANY) {
    const companyProfile = get(item, 'company.companyProfile');
    const creditFlag = get(
      item,
      'company.ratingsProfile.graydonGroupCreditScores.creditFlag.value',
      'NA',
    );
    const companyIdentification = get(item, 'company.companyIdentification');
    const address = extractRegisteredAddress(get(companyProfile, 'addresses'));
    const addressCountry = get(address, 'country.value');
    const mappedInsolvencies = extractInsolvencies(
      country,
      get(companyProfile, 'financialCalamities', []),
    );
    return {
      name: get(companyProfile, 'companyName'),
      enterpriseId: get(companyIdentification, 'enterpriseId'),
      registrationNumber: get(companyIdentification, 'registrationId'),
      graydonCompanyId: get(companyIdentification, 'graydonCompanyId'),
      address: getFormattedAddress(addressCountry, address),
      isActive: get(companyProfile, 'companyStatus.isActive'),
      insolvency: mappedInsolvencies[0],
      creditFlag,
      countryCode: get(companyIdentification, 'countryCode'),
    };
  }

  return {};
};

export const extractShareholdersData = (data: any) => {
  const shareholders = get(data, 'affiliationsProfile.shareholders', []);
  const country = get(data, 'companyIdentification.countryCode', '');
  const result = shareholders
    .reduce((acc: any, item: any) => {
      const type = get(item, 'entity');
      const shares = get(item, 'shares', []);
      const sharesTypes = get(item, 'sharestypes', {});
      const additionaInfo = getAdditionalInfo(country, type, item);
      // In case if company doesn't has any shares, we still
      // need to show these row but with all empty values
      const items =
        shares.length === 0
          ? {
              type,
              ...additionaInfo,
            }
          : shares.map(({ startDate, endDate, percentage, numberOfShares }: any) => ({
              type,
              percentage,
              numberOfShares,
              startDate: parseDate(startDate),
              endDate: parseDate(endDate),
              sharesTypes: get(sharesTypes, 'descriptions', {}),
              ...additionaInfo,
            }));
      return acc.concat(items);
    }, [])
    .sort(shareholdersComparator);
  return {
    country,
    shareHolders: result,
  };
};
