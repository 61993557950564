export default {
  'cr.ratings.credit.flag.tooltip.NL\n  \n \n\n\n':
    "Le drapeau de crédit fonctionne comme un feu de signalisation et représente, en bref, une simple traduction de l’avis de crédit et de la Notation Graydon. Vert = l’octroi d’un crédit ne pose aucun problème. Orange = l'octroi d'un crédit s’accompagne d’un risque accru. Rouge = Graydon déconseille tout préfinancement. La limite de crédit indique le montant jusqu'auquel vous pouvez faire affaire avec une entreprise. La limite de crédit est un jugement de valeur à court terme.",
  'aa.not-available.message': 'L’application Advanced Analytics n’est plus disponible.',
  'aa.not-available.title': 'Application non disponible',
  'aa.not-available.contact-service':
    'N’hésitez pas à contacter notre équipe de service clientèle.',
  'aa.modal.annual-account': 'Comptes annules',
  'aa.modal.download': 'Acheter',
  'aa.modal.download-archive': 'Télécharger',
  'aa.modal.download.error.message': 'Veuillez réessayer plus tard ou contacter Graydon',
  'aa.modal.download.error.title': "La demande n'a pas pu être envoyée",
  'aa.modal.download.not-available': 'Pas disponible',
  'aa.modal.download.not-found.message':
    'Les comptes annuels ne peuvent pas être récupérés. Veuillez contacter Graydon.',
  'aa.modal.download.not-found.title': 'Le compte annuel est introuvable',
  'aa.modal.download.success.message':
    "Le compte annuel que vous avez demandé est disponible dans la barre de téléchargement et dans l'archive entreprise",
  'aa.modal.download.success.title': 'Le rapport a été téléchargé',
  'aa.modal.explanation':
    "Choisissez l'année pour laquelle vous souhaitez acheter et téléchargez les comptes annuels",
  'aa.modal.fetch-list.error.message':
    'Impossible de charger les comptes annuels pour le moment. Veuillez réessayer plus tard',
  'aa.modal.fetch-list.error.title': 'Un problème est survenu',
  'aa.modal.fetch-list.not-found.message':
    "Aucun compte annuel original n'est disponible pour cette société.",
  'aa.modal.fetch-list.not-found.title': 'Aucun compte annuel disponible',
  'aa.modal.title': 'Telecharger les comptes annuels',
  'aa.report.errorFetching': 'Erreur dans le téléchargement du rapport disponible !',
  'aa.report.noReport':
    'Cette information n’est actuellement pas disponible en ligne.<br /><br />Pour toute aide complémentaire, vous pouvez contacter notre équipe de Support Clients par <a href="mailto:{country, select, gb {customerservices@graydon.co.uk} be {support@graydon.be} other {service@graydon.nl}}">{country, select, gb {customerservices@graydon.co.uk} be {support@graydon.be} other {service@graydon.nl}}</a> ou par téléphone au {country, select, gb {+44 (020) 8515 1400} be {+32 (03) 280 88 80} other {+31 (020) 567 9501}}.',
  'address.city': 'Localité',
  'address.house.number': 'Numéro',
  'address.street.or.po.box': 'Rue ou boîte postale',
  'address.zip.code': 'Code postal',
  'addToLists.link': 'Ajouter au...',
  'addToMonitoring.companyAdded': 'Company <b>added</b> to list',
  'addToMonitoring.companyRemoved': 'Company <b>removed</b> from list',
  'addToMonitoring.database-management': 'Database Management ',
  'addToMonitoring.database-management.desc':
    "Pour recevoir des données d'une société, celles-ci doivent être ajoutées à une liste de gestion de base de données. Une société peut être ajoutée ou supprimée des listes ci-dessous.",
  'addToMonitoring.done': 'Prêt',
  'addToMonitoring.goToMonitoring': "Aller à la section 'Monitoring'",
  'addToMonitoring.heading': 'Ajouter la société à la (aux) liste(s)',
  'addToMonitoring.link': 'Ajouter au Monitoring',
  'addToMonitoring.lists.goToListManagement': 'Aller à Gestion des listes',
  'addToMonitoring.lists.noneFound': 'Aucunes listes trouvées',
  'addToMonitoring.lists.paragraph': 'Choisissez une ou plusieurs listes',
  'addToMonitoring.lists.subheading': 'Autres listes',
  'addToMonitoring.monitoring': 'Monitoring',
  'addToMonitoring.monitoring.desc':
    'Pour surveiller une société, elle doit être ajoutée à une liste surveillée. Une société peut être ajoutée ou supprimée des listes suivantes.',
  'addToMonitoring.noMonitoredLists': "Aucune liste de surveillance n'est répertoriée",
  'addToMonitoring.noMonitoredLists.paragraph':
    "Pour commencer à surveiller une société, vous devez d'abord créer un profil de surveillance et associer une liste de sociétés au profil.",
  'addToMonitoring.relatedProfiles': 'profil(s) de surveillance connexe(s) :',
  'addToMonitoring.subheading': 'Listes du Monitoring',
  'app-home.app.name.advanced-analytics': 'Advanced Analytics',
  'app-home.app.name.app-home.app.name.decision-model': 'Modèle de décision',
  'app-home.app.name.archive': 'Archive',
  'app-home.app.name.data-enrichment': 'Data Enrichment',
  'app-home.app.name.list-management': 'Gestion des listes',
  'app-home.app.name.management-dashboard': 'Management Dashboard',
  'app-home.app.name.marketing-information': 'Market Data',
  'app-home.app.name.reports': 'International Business',
  'app-home.app.name.uploads': 'Uploads',
  'app-home.apps.heading': 'Mes autres produits',
  'app-home.main.title': "Que voulez-vous faire aujourd'hui?",
  'apps.advanced-analytics': 'Advanced Analytics',
  'apps.advanced-analytics.description': "Vérifiez les données d'analyse personnalisées",
  'apps.business-risk': 'Risque Commercial',
  'apps.business-risk.description': 'Arrêter la fraude et la criminalité financière',
  'apps.business-radar': 'Radar Business',
  'apps.business-radar.description': "Vérifier les nouvelles de l'entreprise",
  'apps.company-data': "Données de l'entreprise",
  'apps.consumer-credit-check': 'Consumer Credit Check',
  'apps.consumer-credit-check.description': "Vérifier la solvabilité d'une personne",
  'apps.contact': 'Customer Care',
  'apps.credit-reports': 'Rapports de crédit',
  'apps.data-enrichment': 'Data Enrichment',
  'apps.data-enrichment.description': 'Enrichissez les données de l’entreprise',
  'apps.database-management': 'Database Management ',
  'apps.database-management.description': "Gardez les données de l'entreprise à jour",
  'apps.decision-model': 'Modèle de décision',
  'apps.decision-model.description':
    "Définissez vos critères d'acceptation et consultez vos archives",
  'apps.dm-v2': 'Modèle de décision',
  'apps.international-business-archive': 'Archive International Business',
  'apps.international-business-archive.description':
    'Rapports en ligne et nouvelles investigations',
  'apps.international-credit-reports': 'International Business',
  'apps.ledger-analyser': 'Ledger Management',
  'apps.ledger-analyser.description': 'Trouver des risques dans votre grand livre',
  'apps.list-management': 'Gestion des listes',
  'apps.list-management.description': "Gérez vos entreprises d'intérêt",
  'apps.management-dashboard': 'Management Dashboard',
  'apps.management-dashboard.description': "Vérifier l'utilisation des applications Graydon",
  'apps.marketing-information': 'Market Data',
  'apps.marketing-information.description': 'Trouver les bons clients potentiels',
  'apps.monitoring': 'Monitoring Tool',
  'apps.monitoring.description': "Vérifier les mises à jour de l'entreprise",
  'apps.stakeholder-report': 'Stakeholder Search',
  'apps.stakeholder-report.description': 'Trouver les positions des parties prenantes',
  'apps.ubo-reports': "Rapport d'UBO",
  'apps.xseption-report': 'Network incl. XSeptions',
  'archive.backtoarchive': 'Retour à l’archive',
  'archive.backToCreditcheck': 'Retour au contrôle de solvabilité',
  'archive.meta.title': 'Résultats archivés',
  'archive.pdf.checked.model': 'Contrôlé avec modèle',
  'archive.pdf.note.title': 'Notes',
  'archive.pdf.order.value': 'Valeur de commande',
  'archive.pdf.reference.title': 'Référence',
  'archive.pdf.result.checkpoint.be.annualAccountAvailable': 'Compte annuel disponible?',
  'archive.pdf.result.checkpoint.be.annualAccountAvailable.threshold.label': 'Valeur-limite',
  'archive.pdf.result.checkpoint.be.multiscore': 'Multiscore',
  'archive.pdf.result.checkpoint.be.multiscore.threshold.label': 'Valeur-limite',
  'archive.pdf.result.checkpoint.be.paymentsScore': 'Score de paiement',
  'archive.pdf.result.checkpoint.be.paymentsScore.threshold.label': 'Valeur-limite',
  'archive.pdf.result.checkpoint.companyActive': 'Société active',
  'archive.pdf.result.checkpoint.companyActive.threshold.label': 'Valeur-limite',
  'archive.pdf.result.checkpoint.creditLimit': 'Limite de crédit',
  'archive.pdf.result.checkpoint.creditLimit.threshold.label': 'Valeur de la commande',
  'archive.pdf.result.checkpoint.gb.augurScore': 'Augur score',
  'archive.pdf.result.checkpoint.gb.augurScore.threshold.label': 'Valeur-limite',
  'archive.pdf.result.checkpoint.gb.ccjCount': 'Nombre de CCJs',
  'archive.pdf.result.checkpoint.gb.ccjCount.threshold.label': 'Valeur-limite',
  'archive.pdf.result.checkpoint.gb.companyAge': "Âge de l'entreprise",
  'archive.pdf.result.checkpoint.gb.companyAge.threshold.label': 'Valeur-limite',
  'archive.pdf.result.checkpoint.gb.companyIncorporated': 'Company incorporated?',
  'archive.pdf.result.checkpoint.gb.companyIncorporated.threshold.label': 'Valeur-limite',
  'archive.pdf.result.checkpoint.gb.creditRating': 'Notation de crédit ',
  'archive.pdf.result.checkpoint.gb.creditRating.threshold.label': 'Valeur-limite',
  'archive.pdf.result.checkpoint.gb.orderValue': 'Valeur de la commande',
  'archive.pdf.result.checkpoint.gb.orderValue.threshold.label': 'Valeur-limite',
  'archive.pdf.result.checkpoint.nl.paymentsScore': 'Score de paiement',
  'archive.pdf.result.checkpoint.nl.paymentsScore.threshold.label': 'Valeur-limite',
  'archive.pdf.result.checkpoint.nl.probabilityOfDefault': 'Risque de defaut de paiement (% pd)',
  'archive.pdf.result.checkpoint.nl.probabilityOfDefault.threshold.label': 'Valeur-limite',
  'archive.pdf.result.checkpoint.payments': 'Comportement de paiement',
  'archive.pdf.result.checkpoint.payments.threshold.label': 'Valeur-limite',
  'archive.search.placeholder': "Nom de la société ou numéro d'enregistrement",
  'archive.title': 'Archives',
  back: 'Retour',
  'back.to.dashboard': 'Retour au tableau de bord',
  'banner.phaseout.internetexplorer.content':
    'Pour une expérience optimale, veuillez mettre à jour votre navigateur en optant pour Mozilla Firefox, Google Chrome ou Microsoft Edge.',
  'banner.phaseout.internetexplorer.title':
    'Vous utilisez un navigateur Web obsolète: Internet Explorer\n',
  beforeAfterSelection: 'Avant/après',
  'branding.app': '{app}',
  'branding.subtitle': 'Pour {company}',
  'breadcrumb.company-search': "Données de l'entreprise",
  'breadcrumb.create-profile': 'Créer un profil',
  'breadcrumb.credit-report': 'Rapport de crédit',
  'breadcrumb.database-management': 'Database Management ',
  'breadcrumb.database-management.create-profile': 'Ajouter un nouveau profil',
  'breadcrumb.database-management.documentation': 'Documentation',
  'breadcrumb.database-management.update-profile': 'Modifier le profil',
  'breadcrumb.database-management.view-profile': 'Voir le profile',
  'breadcrumb.decision-model': 'Modèle de décision',
  'breadcrumb.edit-profile': 'Modifier le profil',
  'breadcrumb.export-profile': 'Export',
  'breadcrumb.home': 'Accueil',
  'breadcrumb.international-credit-reports': 'International Business',
  'breadcrumb.international-credit-reports.archive': 'Archives',
  'breadcrumb.international-credit-reports.investigative-report':
    'Passez la commande pour une investigation',
  'breadcrumb.ledger-management': 'Ledger management',
  'breadcrumb.list-management': 'Gestion des listes',
  'breadcrumb.list-profile': 'Profil de la liste',
  'breadcrumb.monitoring-updates': 'Mises à jour surveillance',
  'breadcrumb.my-portfolio': 'Mes portefeuilles',
  'breadcrumb.organisation': "Archive de l'entreprise",
  'breadcrumb.preferences': 'Préférences',
  'breadcrumb.stakeholder-archive': 'Archive',
  'breadcrumb.stakeholder-report': 'Stakeholder Search',
  'breadcrumb.stakeholder-search': 'Stakeholder Search',
  'breadcrumb.ubo-report': 'Rapport UBO',
  'breadcrumb.unmatched-debtors': 'Débiteurs non liés',
  'cd.modal.report-download-error.title': "Une erreur s'est produite ...",
  'chart.amount.orders': 'commandes',
  'chart.average.order.value': 'Valeur moyenne de commande',
  'coc.extracts.usage.download': 'Utilisation des extraits du registre de commerce (.xlsx)',
  'coc.modal.buy': "Acheter l'extrait",
  'coc.modal.cached': 'Archive Graydon',
  'coc.modal.date': 'Date',
  'coc.modal.download.error.message': 'Veuillez réessayer plus tard ou contacter Graydon.',
  'coc.modal.download.error.title': 'Impossible de envoyer la demande',
  'coc.modal.download.not-found.message':
    "Impossible de récupérer l'extrait. Veuillez contacter Graydon.",
  'coc.modal.download.not-found.title': "L'extrait pas trouvé",
  'coc.modal.download.success.message':
    'The extrait du registre de commerce that you requested is available from the download bar and from Company Archive.',
  'coc.modal.download.success.title': "L'extrait a été téléchargé",
  'coc.modal.explanation':
    'Demandez un nouvel extrait ou demandez une version en cache (moins chère) à partir des archives Graydon.',
  'coc.modal.fetch-cached.error.message':
    'Impossible de récupérer la liste des extraits en cache. Veuillez réessayer plus tard ou contacter Graydon.',
  'coc.modal.fetch-cached.error.title': 'Impossible de récupérer les données',
  'coc.modal.link': 'Lien',
  'coc.modal.new': 'Nouveau',
  'coc.modal.title': 'Demander un extrait du registre de commerce',
  'coc.modal.today': "Aujourd'hui",
  'coc.modal.type': 'Type',
  'common.report-modal.open-in-new-tab': 'Toujours ouvrir dans un nouvel onglet',
  companies: 'Sociétés',
  company: 'Société',
  'company.contact.details': 'Données de contact',
  'company.email': 'Adresse e-mail',
  'company.entireAddress': 'Lieu (ville ou nom de rue etc.)',
  'company.graydon.id': 'Graydon ID',
  'company.incorporation.date': 'Date de constitution',
  'company.legal.form': 'Forme juridique',
  'company.legal.forms': 'Formes juridiques',
  'company.legal.forms.all': 'Toutes les formes juridiques',
  'company.mobile.number': 'Numéro de portable',
  'company.name': 'Nom de la société',
  'company.phone.number': 'Numéro de téléphone',
  'company.register.number': "Numéro d'enregistrement",
  'company.relationship.founder': 'A pour fondateur:',
  'company.relationship.groupMember': 'A comme membre du groupe',
  'company.relationship.other': 'A une autre relation avec',
  'company.relationship.parent': 'Société mère',
  'company.relationship.participation':
    "Dispose d'une {isMajorityStake, select, true{majorité} other{}} participation à",
  'company.relationship.shareholder':
    "Dispose de {isMajorityStake, select, true{majorité} other{}} en tant qu'actionnaire",
  'company.relationship.ultimateParent': 'Principale société mère',
  'company.sectorCode': 'Code secteur',
  'company.status': "Statut de l'entreprise",
  'company.status.BANKRUPTCY': 'Faillite',
  'company.status.DEBT_RESTRUCTURING': 'Assainissement de la dette',
  'company.status.INACTIVE': 'Inactif',
  'company.status.MERGER': 'Fusion',
  'company.status.OWNER_DECEASED': 'Propriétaire décédé(e)',
  'company.status.SPLIT_UP': 'Scission',
  'company.telefax.number': '№ de fax',
  'company.trade-names': 'Dénominations commerciales',
  'company.vat.number': 'Numéro de TVA',
  'company.website': 'Site web / internet',
  'contact.customerService':
    '<a href="mailto:{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}">{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}</a> ou par téléphone au {country, select, gb {+44&nbsp;(0)20&nbsp;8515&nbsp;1400} nl {+31&nbsp;(020)&nbsp;567&nbsp;9501} other {+32&nbsp;(03)&nbsp;280&nbsp;88&nbsp;80}}',
  'contacts.text':
    'Si vous rencontrez des problèmes avec la plateforme, si vous avez des questions ou des suggestions à nous faire, ou si vous souhaitez des informations complémentaires à propos de nos produits, n’hésitez pas à contacter notre service dédié au support de nos clients.',
  'contacts.title': 'Des questions?',
  'cookie.accept': "Je suis d'accord avec le placement des cookies",
  'cookie.message':
    "En poursuivant votre navigation sur ce site, vous acceptez que des cookies soient utilisés afin d'améliorer votre expérience d'utilisateur et de vous offrir des contenus personnalisés.",
  'cookie.more-info': 'En savoir plus',
  'core.filterPlaceholder': 'Rechercher',
  'core.tagging.add': 'Créer une nouvelle liste',
  'core.tagging.placeholder': 'Entrer un nom de liste',
  'core.treeSelector.collapseAll': 'Réduire tout',
  'core.treeSelector.deselectResults': 'Déselectionner les résultats',
  'core.treeSelector.expandAll': 'Afficher tout',
  'core.treeSelector.expandSelected': 'Étendre la sélection',
  'core.treeSelector.selectAll': 'Sélectionner tout',
  'core.treeSelector.selectNone': 'Déselectionner tout',
  'core.treeSelector.selectResults': 'Sélectionner les résultats',
  'country.be': 'Belgique',
  'country.fr': 'France',
  'country.gb': 'Royaume-Uni',
  'country.nl': 'Pays-Bas',
  'country.uk': 'Royaume-Uni',
  'cr.action.button.addToList': 'Ajouter à la liste',
  'cr.action.button.addToMonitoring': 'Monitoring',
  'cr.action.button.addToMonitoring.back': 'Retour au Rapport de Crédit',
  'cr.action.button.download': 'Télécharger le rapport',
  'cr.action.button.download.en-pdf': 'PDF en anglais',
  'cr.action.button.download.fr-pdf': 'PDF en français',
  'cr.action.button.download.nl-pdf': 'PDF en néerlandais',
  'cr.action.button.request-new-report': 'Rapport de crédit',
  'cr.action.button.share': 'Partager le rapport complet',
  'cr.action.buttons.title': 'Partager & Télécharger',
  'cr.add.company.to.default.list.error.body':
    'Il semble y avoir eu une erreur inattendue. Veuillez rafraîchir la page ou réessayer plus tard.',
  'cr.add.company.to.default.list.error.title': "Une erreur s'est produite",
  'cr.add.company.to.default.list.success.body':
    'Nous avons ajouté cette société à la liste "{listName}".',
  'cr.add.company.to.default.list.success.title': 'Société ajoutée à la liste par défaut',
  'cr.affiliations.calamities': 'Calamités financières',
  'cr.affiliations.creditFlag.G': 'Pas d’objection',
  'cr.affiliations.creditFlag.NA': 'Indisponible',
  'cr.affiliations.creditFlag.O': 'Surveillance conseillée',
  'cr.affiliations.creditFlag.R': 'Déconseillé',
  'cr.affiliations.directors.active': 'Directeurs/-trices actuels,-les',
  'cr.affiliations.directors.active-calamity': 'Calamité financière',
  'cr.affiliations.directors.address': 'Adresse',
  'cr.affiliations.directors.birthDate': 'Date de naissance',
  'cr.affiliations.directors.birthPlace': 'Lieu de naissance',
  'cr.affiliations.directors.calamities': 'Calamité financière',
  'cr.affiliations.directors.calamity': 'Calamité financière',
  'cr.affiliations.directors.collapse-calamity': 'Chachez la calamité financière',
  'cr.affiliations.directors.companyName': 'Nom de la société',
  'cr.affiliations.directors.entity.COMPANY': 'Société de gestion',
  'cr.affiliations.directors.entity.PERSON': 'Gestionnaire principal',
  'cr.affiliations.directors.error.no-data.text':
    "Aucune information n'est disponible à propos du conseil d'administration de cette société.<br /><br />Si vous avez des questions ou avez besoin d’aide, veuillez contacter notre équipe du Service Clients à l'adresse suivante : <a href=\"mailto:{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}\">{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}</a> ou par téléphone au {country, select, gb {+44&nbsp;(0)20&nbsp;8515&nbsp;1400} nl {+31&nbsp;(020)&nbsp;567&nbsp;9501} other {+32&nbsp;(03)&nbsp;280&nbsp;88&}}.",
  'cr.affiliations.directors.function': 'Profession',
  'cr.affiliations.directors.inactive': 'Directeurs/-trices précédents,-tes',
  'cr.affiliations.directors.jurisdiction': 'Autorité',
  'cr.affiliations.directors.name': 'Nom',
  'cr.affiliations.directors.otherFunctions': 'Autre(s) fonction(s)',
  'cr.affiliations.directors.otherFunctions.collapse': 'Fermer autre(s) fonction(s)',
  'cr.affiliations.directors.period': 'Date de début et de fin',
  'cr.affiliations.directors.presentDay': 'Présent',
  'cr.affiliations.directors.registration-number-be': "Numéro d'entreprise",
  'cr.affiliations.directors.registration-number-gb': "Numéro d'entreprise",
  'cr.affiliations.directors.registration-number-nl': "Numéro d'identification (KvK)",
  'cr.affiliations.directors.registrationNumber': "Numéro d'enregistrement",
  'cr.affiliations.directors.representedBy': 'Représentant permanent de la personne morale',
  'cr.affiliations.directors.serviceAddress': 'Adresse du service',
  'cr.affiliations.directors.tooltip':
    "Vous trouverez ici un aperçu des directeurs de la société ainsi que leurs liens avec d'autres entreprises et leurs statuts.",
  'cr.affiliations.directors.tooltip.BE':
    "Vous trouverez ici une vue d'ensemble des dirigeants actifs, avec leur fonction, leur mandat et éventuellement leurs mandats dans d'autres sociétés. Vous aurez également un aperçu des anciens dirigeants inactifs et de la date de leur licenciement. \nCette information vous donne un aperçu de la gestion d'une entreprise, de sorte que vous sachiez qui tire réellement les ficelles. Ce qui est important, car il existe un lien indéniable entre la gestion et la performance d’une entreprise. D’autant plus dans un pays de PME tel que la Belgique, où les petites et moyennes entreprises ne comptent généralement qu'1 à 3 dirigeants dans leur conseil d'administration.",
  'cr.affiliations.directors.tooltip.GB':
    "Vous trouverez ici un aperçu des administrateurs actifs et inactifs, de l'autorité qui leur est associée et des autres mandats qu'ils exercent.",
  'cr.affiliations.directors.tooltip.NL':
    'Vous trouverez ici un aperçu des administrateurs actifs et éventuellement inactifs, des compétences associées et des autres fonctions de ces administrateurs.',
  'cr.affiliations.directors.unknown': 'Inconnu',
  'cr.affiliations.gender.F': 'Mme.',
  'cr.affiliations.gender.M': 'M.',
  'cr.affiliations.group-structure.address': 'Adresse',
  'cr.affiliations.group-structure.company-name': 'Nom de la société',
  'cr.affiliations.group-structure.error.no-data.text':
    'Aucune information n\'est actuellement disponible à propos de la structure de groupe de cette société.<br /><br />Si vous avez des questions ou avez besoin d’aide, veuillez contacter notre équipe du Service Clients à l\'adresse suivante :  <a href="mailto:{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}">{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}</a> ou par téléphone au {country, select, gb {+44&nbsp;(0)20&nbsp;8515&nbsp;1400} nl {+31&nbsp;(020)&nbsp;567&nbsp;9501} other {+32&nbsp;(03)&nbsp;280&nbsp;88&nbsp;80}}.',
  'cr.affiliations.group-structure.percentage': "Pourcentage d'actions",
  'cr.affiliations.group-structure.period': 'Date de début et de fin',
  'cr.affiliations.group-structure.registration-number-be': "Numéro d'entreprise",
  'cr.affiliations.group-structure.registration-number-gb': "Numéro d'entreprise",
  'cr.affiliations.group-structure.registration-number-nl': "Numéro d'identification (KvK)",
  'cr.affiliations.group-structure.tooltip.BE':
    "Vous trouverez ici un aperçu des participations actives / filiales, avec le pourcentage d'actions que cette société détient (s’il est connu). Vous aurez également un aperçu des filiales dans lesquelles cette société avait un intérêt dans le passé.",
  'cr.affiliations.group-structure.tooltip.GB':
    "Vous trouverez ici une vue d'ensemble de la structure de la société du groupe, le cas échéant.",
  'cr.affiliations.group-structure.tooltip.NL':
    "Vous trouverez ici un aperçu du groupe duquel fait partie l'entreprise.",
  'cr.affiliations.group-structure.type.DAUGHTER': 'Filiale',
  'cr.affiliations.group-structure.type.MOTHER': 'Maison-mère',
  'cr.affiliations.group-structure.type.PARTICIPANT': 'Participation',
  'cr.affiliations.group-structure.type.SISTER': 'Société sœur',
  'cr.affiliations.group-structure.type.ULTIMATE_MOTHER': 'Société mère ultime',
  'cr.affiliations.indicator': 'Indicateur',
  'cr.affiliations.indicators': 'Indicateurs',
  'cr.affiliations.no-insolvency': 'Pas de cas d’insolvabilité',
  'cr.affiliations.no.data.error.title':
    "Cette information n'est pas disponible pour cette société",
  'cr.affiliations.others.account-number': 'Numéro de compte',
  'cr.affiliations.others.accountant': 'Comptables',
  'cr.affiliations.others.active-calamity': 'Calamité financière',
  'cr.affiliations.others.address': 'Adresse',
  'cr.affiliations.others.bank': 'Banques',
  'cr.affiliations.others.bank-name': 'Nom',
  'cr.affiliations.others.bankers': 'Banques',
  'cr.affiliations.others.collapse-calamity': 'Chachez la calamité financière',
  'cr.affiliations.others.company-name': 'Nom de la société',
  'cr.affiliations.others.error.no-data.text':
    "Aucune autre partie n'est répertoriée pour cette société ou aucune donnée n'est actuellement disponible.<br /><br />Si vous avez des questions ou avez besoin d’aide, veuillez contacter notre équipe du Service Clients à l'adresse suivante : <a href=\"mailto:{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}\">{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}</a> ou par téléphone au {country, select, gb {+44&nbsp;(0)20&nbsp;8515&nbsp;1400} nl {+31&nbsp;(020)&nbsp;567&nbsp;9501} other {+32&nbsp;(03)&nbsp;280&nbsp;88&nbsp;80}}.",
  'cr.affiliations.others.liability': 'Responsabilité',
  'cr.affiliations.others.liable-for': 'Responsable pour cette société',
  'cr.affiliations.others.name': 'Nom',
  'cr.affiliations.others.period': 'Date de début et de fin',
  'cr.affiliations.others.registration-number-nl': "Numéro d'identification (KvK)",
  'cr.affiliations.others.tooltip.BE':
    'Vous trouverez ici un aperçu des numéros de compte bancaire dont nous disposons.',
  'cr.affiliations.others.tooltip.GB':
    "Vous trouverez ici d'autres informations sur la structure de l'entreprise.",
  'cr.affiliations.others.tooltip.NL':
    "Vous trouverez ici d'autres informations sur la structure de l'entreprise.",
  'cr.affiliations.psc.error.no-data.text':
    "Cette société n'a enregistré aucune personne ayant un contrôle important ou les informations ne sont actuellement pas disponibles. <br /> <br /> Si vous avez des questions ou avez besoin d'aide, veuillez contacter notre équipe du Service Clients à l'adresse suivante : <a href=\"mailto:{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}\"> {country, select, gb {services clients @ graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}} </a> ou par téléphone au {country, select, gb {+44&nbsp;(0)20&nbsp;8515&nbsp;1400} nl {+31&nbsp;(020)&nbsp;567&nbsp;9501} other {+32&nbsp;(03)&nbsp;280&nbsp;88&nbsp;80}}.",
  'cr.affiliations.psc.error.no-data.title': 'Aucune personne ayant un contrôle important',
  'cr.affiliations.psc.natureOfControl': 'Nature du contrôle',
  'cr.affiliations.psc.protected': 'Personne(s) protégée(s) ayant un contrôle important\n',
  'cr.affiliations.psc.title': 'Personne(s) active(s) ayant un contrôle important',
  'cr.affiliations.psc.tooltip.GB':
    "Vous trouverez ici toutes les personnes actives ayant un contrôle important pour cette société. Une personne ayant un contrôle important est une personne qui détient plus de 25% des actions ou des droits de vote dans une société, qui a le droit de nommer ou destituer la majorité du conseil d'administration ou d'exercer autrement une influence ou un contrôle important. Depuis le 30 juin 2016, les sociétés britanniques (à l'exception des sociétés cotées) et les sociétés à responsabilité limitée (LLP) doivent déclarer ces informations lors de la remise de leur déclaration de confirmation annuelle à Companies House. Les personnes détenant un contrôle important peuvent inclure des entités corporatives, des personnes morales et des particuliers. Veuillez noter qu'en cas d'exemption ou de personne super sécurisée, les détails ne sont pas affichés en raison de restrictions sur l'utilisation ou la divulgation des détails de cet individu en vertu de l'article 790ZG de la réglementation en relation avec cette société.\n",
  'cr.affiliations.shareholders.active': 'Actionnaires actuels',
  'cr.affiliations.shareholders.active-calamity': 'Calamité financière',
  'cr.affiliations.shareholders.address': 'Adresse',
  'cr.affiliations.shareholders.collapse-calamity': 'Chachez la calamité financière',
  'cr.affiliations.shareholders.company': "Société d'actionnaire",
  'cr.affiliations.shareholders.company-name': 'Nom de la société',
  'cr.affiliations.shareholders.date-of-birth': 'Date de naissance',
  'cr.affiliations.shareholders.error.no-data.text':
    'Cette société a une forme juridique sans actionnaires ou aucune information n\'est actuellement disponible.<br /><br />Si vous avez des questions ou avez besoin d’aide, veuillez contacter notre équipe du Service Clients à l\'adresse suivante : <a href="mailto:{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}">{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}</a> ou par téléphone au {country, select, gb {+44&nbsp;(0)20&nbsp;8515&nbsp;1400} nl {+31&nbsp;(020)&nbsp;567&nbsp;9501} other {+32&nbsp;(03)&nbsp;280&nbsp;88&nbsp;80}}.',
  'cr.affiliations.shareholders.error.no-data.title':
    "Cette information n'est pas disponible pour cette société",
  'cr.affiliations.shareholders.inactive': 'Actionnaires précédents',
  'cr.affiliations.shareholders.percentage': "Pourcentage d'actions",
  'cr.affiliations.shareholders.period': 'Date de début & de fin',
  'cr.affiliations.shareholders.period.present': 'Présent',
  'cr.affiliations.shareholders.period.unknown': 'Inconnu',
  'cr.affiliations.shareholders.person': 'Actionnaire',
  'cr.affiliations.shareholders.person-name': 'Nom',
  'cr.affiliations.shareholders.place-of-birth': 'Lieu de naissance',
  'cr.affiliations.shareholders.registration-number-be': "Numéro d'entreprise",
  'cr.affiliations.shareholders.registration-number-gb': "Numéro d'entreprise",
  'cr.affiliations.shareholders.registration-number-nl': "Numéro d'identification (KvK)",
  'cr.affiliations.shareholders.share': '{share, plural, =1 {action} other {actions}}',
  'cr.affiliations.shareholders.shares': 'actions',
  'cr.affiliations.shareholders.tooltip.BE':
    'Vous trouverez ici un aperçu des actionnaires actifs, avec le pourcentage des actions qu’ils détiennent (s’il est connu). Vous aurez également un aperçu des anciens actionnaires inactifs.',
  'cr.affiliations.shareholders.tooltip.GB':
    'Vous trouverez ici un aperçu des actionnaires actuels et passés.',
  'cr.affiliations.shareholders.tooltip.NL':
    'Vous trouverez ici un aperçu des actionnaires actifs et éventuellement inactifs.',
  'cr.affiliations.tab.directors': 'Administrateurs',
  'cr.affiliations.tab.group-structure': 'Structure du groupe',
  'cr.affiliations.tab.other': 'Autres',
  'cr.affiliations.tab.psc': 'Personne(s) ayant un contrôle important',
  'cr.affiliations.tab.shareholders': 'Actionnaires',
  'cr.annual-report.general-information.accountingPeriod': 'Exercice financier',
  'cr.annual-report.general-information.accountingPeriodEndDate': 'Date de clôture des comptes',
  'cr.annual-report.general-information.accountingStandard': 'Norme comptable',
  'cr.annual-report.general-information.annualMeetingDate': "La date de l'assemblée générale",
  'cr.annual-report.general-information.balancedFlag': 'Rapport annuel équilibré',
  'cr.annual-report.general-information.balancedFlag.false': 'Non',
  'cr.annual-report.general-information.balancedFlag.true': 'Oui',
  'cr.annual-report.general-information.consolidatedFlag': 'Type',
  'cr.annual-report.general-information.consolidatedFlag.false': 'Bilan annuel',
  'cr.annual-report.general-information.consolidatedFlag.true': 'Consolidé',
  'cr.annual-report.general-information.description':
    'Aperçu des rapports annuels listés par année fiscale ',
  'cr.annual-report.general-information.description.BE':
    "Vous trouverez ici des informations sur l'exercice financier auquel les données des comptes annuels se rapportent.",
  'cr.annual-report.general-information.description.GB':
    'Vous trouverez ici un aperçu des rapports annuels, affichés par exercice.',
  'cr.annual-report.general-information.description.NL':
    "Vous trouverez ici des informations sur l'exercice financier auquel les données des comptes annuels se rapportent.",
  'cr.annual-report.general-information.dormant': 'Dormant',
  'cr.annual-report.general-information.dormant.no': 'Non',
  'cr.annual-report.general-information.dormant.yes': 'Oui',
  'cr.annual-report.general-information.filingDate': 'Date de dépôt des comptes',
  'cr.annual-report.general-information.model.BE_1': 'Modèle abrégé pour entreprises (20)',
  'cr.annual-report.general-information.model.BE_1_19': 'Modèle abrégé pour entreprises (20)',
  'cr.annual-report.general-information.model.BE_1_20': 'Modèle abrégé pour entreprises (20)',
  'cr.annual-report.general-information.model.BE_10': 'Modèle abrégé pour entreprises (23)',
  'cr.annual-report.general-information.model.BE_10_20': 'Modèle abrégé pour entreprises (23)',
  'cr.annual-report.general-information.model.BE_11': 'Modèle micro pour entreprises (73)',
  'cr.annual-report.general-information.model.BE_11_20': 'Modèle micro pour entreprises (73)',
  'cr.annual-report.general-information.model.BE_12_21':
    'Modèle micro pour micro-associations et microfondations (701)',
  'cr.annual-report.general-information.model.BE_2': 'Modèle complet pour entreprises (40)',
  'cr.annual-report.general-information.model.BE_2_19': 'Modèle complet pour entreprises (40)',
  'cr.annual-report.general-information.model.BE_2_20': 'Modèle complet pour entreprises (40)',
  'cr.annual-report.general-information.model.BE_4': 'Modèle micro pour entreprises (70)',
  'cr.annual-report.general-information.model.BE_4_19': 'Modèle micro pour entreprises (70)',
  'cr.annual-report.general-information.model.BE_4_20': 'Modèle micro pour entreprises (70)',
  'cr.annual-report.general-information.model.BE_5': 'Modèle abrégé pour associations (201)',
  'cr.annual-report.general-information.model.BE_5_13': 'Modèle abrégé pour associations (201)',
  'cr.annual-report.general-information.model.BE_6': 'Modèle complet pour associations (401)',
  'cr.annual-report.general-information.model.BE_6_13': 'Modèle complet pour associations (401)',
  'cr.annual-report.general-information.model.BE_8': 'Modèle spécifique schéma B (Nature 10)',
  'cr.annual-report.general-information.model.BE_9': 'Modèle complet pour entreprises (43)',
  'cr.annual-report.general-information.model.BE_9_20': 'Modèle complet pour entreprises (43)',
  'cr.annual-report.general-information.modelType': 'Modèle',
  'cr.annual-report.general-information.preliminaryFlag': 'Préliminaire ou définitif',
  'cr.annual-report.general-information.preliminaryFlag.false': 'Définitif',
  'cr.annual-report.general-information.preliminaryFlag.true': 'Préliminaire',
  'cr.annual-report.general-information.title': 'Aperçu des rapports annuels',
  'cr.annual.report.annual-accounts.collapse-all': 'Cacher tout',
  'cr.annual.report.annual-accounts.expand-all': 'Dérouler tout',
  'cr.annual.report.balance-sheet.BE_ACTIVA': 'ACTIF',
  'cr.annual.report.balance-sheet.BE_B_167':
    'Provisions pour subsides et legs à rembourser et pour dons avec droit de reprise',
  'cr.annual.report.balance-sheet.BE_B_AST_20': 'FRAIS D’ÉTABLISSEMENT (20)',
  'cr.annual.report.balance-sheet.BE_B_AST_2028': 'ACTIFS IMMOBILISÉS (2028)',
  'cr.annual.report.balance-sheet.BE_B_AST_2058': "TOTAL DE L'ACTIF (2058) ",
  'cr.annual.report.balance-sheet.BE_B_AST_2128': 'ACTIFS IMMOBILISÉS (2128) ',
  'cr.annual.report.balance-sheet.BE_B_AST_2958': 'ACTIFS CIRCULANTS (2958) ',
  'cr.annual.report.balance-sheet.BE_B_AST_C_29': 'Créances à plus d’un an (29)',
  'cr.annual.report.balance-sheet.BE_B_AST_C_3': 'Stocks et commandes en cours d’exécution (3)',
  'cr.annual.report.balance-sheet.BE_B_AST_C_4041': 'Créances à un an au plus (4041) ',
  'cr.annual.report.balance-sheet.BE_B_AST_C_4901': 'Comptes de régularisation (4901) ',
  'cr.annual.report.balance-sheet.BE_B_AST_C_5053': 'Placements de trésorerie (5053)',
  'cr.annual.report.balance-sheet.BE_B_AST_C_5458': 'Valeurs disponibles (5458) ',
  'cr.annual.report.balance-sheet.BE_B_AST_CI_50': 'Actions propres (50)',
  'cr.annual.report.balance-sheet.BE_B_AST_CI_5153': 'Autres placements (5153)',
  'cr.annual.report.balance-sheet.BE_B_AST_COS_3031': 'Approvisionnements (3031)',
  'cr.annual.report.balance-sheet.BE_B_AST_COS_32': 'En-cours de fabrication (32)',
  'cr.annual.report.balance-sheet.BE_B_AST_COS_33': 'Produits finis (33)',
  'cr.annual.report.balance-sheet.BE_B_AST_COS_34': 'Marchandises (34)',
  'cr.annual.report.balance-sheet.BE_B_AST_COS_35': 'Immeubles destinés à la vente (35)',
  'cr.annual.report.balance-sheet.BE_B_AST_COS_36': 'Acomptes versés (36)',
  'cr.annual.report.balance-sheet.BE_B_AST_CRL_290': 'Créances commerciales (290)',
  'cr.annual.report.balance-sheet.BE_B_AST_CRL_291': 'Autres créances (291)',
  'cr.annual.report.balance-sheet.BE_B_AST_CRLO_2915':
    'Dont créances non productives d’intérêts ou liées à des taux d’intérêt anormalement bas (2915)',
  'cr.annual.report.balance-sheet.BE_B_AST_CRS_40': 'Créances commerciales (40) ',
  'cr.annual.report.balance-sheet.BE_B_AST_CRS_41': 'Autres créances (41) ',
  'cr.annual.report.balance-sheet.BE_B_AST_CRSO_415':
    'Dont créances non productives d’intérêts ou liées à des taux d’intérêt anormalement bas (415)',
  'cr.annual.report.balance-sheet.BE_B_AST_CS_3036': 'Stocks (3036)',
  'cr.annual.report.balance-sheet.BE_B_AST_CS_37': 'Commandes en cours d’exécution (37)',
  'cr.annual.report.balance-sheet.BE_B_AST_F_21': 'Immobilisations incorporelles (21) ',
  'cr.annual.report.balance-sheet.BE_B_AST_F_2227': 'Immobilisations corporelles (2227) ',
  'cr.annual.report.balance-sheet.BE_B_AST_F_28': 'Immobilisations financières (28) ',
  'cr.annual.report.balance-sheet.BE_B_AST_FF_2801': 'Entreprises liées (2801)',
  'cr.annual.report.balance-sheet.BE_B_AST_FF_2823':
    'Entreprises avec lesquelles il existe un lien de participation (2823)',
  'cr.annual.report.balance-sheet.BE_B_AST_FF_2848': 'Autres immobilisations financières (2848) ',
  'cr.annual.report.balance-sheet.BE_B_AST_FFC_280': 'Participations (280)',
  'cr.annual.report.balance-sheet.BE_B_AST_FFC_281': 'Créances (281)',
  'cr.annual.report.balance-sheet.BE_B_AST_FFO_284': 'Actions et parts (284)',
  'cr.annual.report.balance-sheet.BE_B_AST_FFO_2858':
    'Créances et cautionnements en numéraire (2858) ',
  'cr.annual.report.balance-sheet.BE_B_AST_FFP_282': 'Participations (282)',
  'cr.annual.report.balance-sheet.BE_B_AST_FFP_283': 'Créances (283)',
  'cr.annual.report.balance-sheet.BE_B_AST_FT_22': 'Terrains et constructions (22)',
  'cr.annual.report.balance-sheet.BE_B_AST_FT_23': 'Installations, machines et outillage (23) ',
  'cr.annual.report.balance-sheet.BE_B_AST_FT_24': 'Mobilier et matériel roulant (24) ',
  'cr.annual.report.balance-sheet.BE_B_AST_FT_25': 'Location-financement et droits similaires (25)',
  'cr.annual.report.balance-sheet.BE_B_AST_FT_26': 'Autres immobilisations corporelles (26) ',
  'cr.annual.report.balance-sheet.BE_B_AST_FT_27':
    'Immobilisations en cours et acomptes versés (27)',
  'cr.annual.report.balance-sheet.BE_B_AST_FTI_231':
    "Appartenant à l'association ou à la fondation en pleine propriété (231)",
  'cr.annual.report.balance-sheet.BE_B_AST_FTI_232': 'Autres (232)',
  'cr.annual.report.balance-sheet.BE_B_AST_FTI_241':
    "Appartenant à l'association ou à la fondation en pleine propriété (241)",
  'cr.annual.report.balance-sheet.BE_B_AST_FTI_242': 'Autres (242)',
  'cr.annual.report.balance-sheet.BE_B_AST_FTO_261':
    "Appartenant à l'association ou à la fondation en pleine propriété (261)",
  'cr.annual.report.balance-sheet.BE_B_AST_FTO_262': 'Autres (262)',
  'cr.annual.report.balance-sheet.BE_B_AST_FTT_2291':
    "Appartenant à l'association ou à la fondation en pleine propriété (2291)",
  'cr.annual.report.balance-sheet.BE_B_AST_FTT_2292': 'Autres (2292)',
  'cr.annual.report.balance-sheet.BE_B_LBL_1011': 'Apport (1011)',
  'cr.annual.report.balance-sheet.BE_B_LBL_1015': 'FONDS PROPRES (1015) ',
  'cr.annual.report.balance-sheet.BE_B_LBL_1049': 'TOTAL DU PASSIF (1049)',
  'cr.annual.report.balance-sheet.BE_B_LBL_110': 'Disponible (110)',
  'cr.annual.report.balance-sheet.BE_B_LBL_111': 'Indisponible (111)',
  'cr.annual.report.balance-sheet.BE_B_LBL_16': 'PROVISIONS ET IMPÔTS DIFFÉRÉS (16) ',
  'cr.annual.report.balance-sheet.BE_B_LBL_1749': 'DETTES (1749) ',
  'cr.annual.report.balance-sheet.BE_B_LBL_D_17': 'Dettes à plus d’un an (17)',
  'cr.annual.report.balance-sheet.BE_B_LBL_D_1789': 'Autres dettes (1789)',
  'cr.annual.report.balance-sheet.BE_B_LBL_D_4248': 'Dettes à un an au plus (4248) ',
  'cr.annual.report.balance-sheet.BE_B_LBL_D_4923': 'Comptes de régularisation (4923)',
  'cr.annual.report.balance-sheet.BE_B_LBL_DL_1704': 'Dettes financières (1704)',
  'cr.annual.report.balance-sheet.BE_B_LBL_DL_175': 'Dettes commerciales (175)',
  'cr.annual.report.balance-sheet.BE_B_LBL_DL_176': 'Acomptes reçus sur commandes (176)',
  'cr.annual.report.balance-sheet.BE_B_LBL_DL_179': 'Autres dettes (179)',
  'cr.annual.report.balance-sheet.BE_B_LBL_DLF_170': 'Emprunts subordonnés (170)',
  'cr.annual.report.balance-sheet.BE_B_LBL_DLF_171': 'Emprunts obligataires non subordonnés (171)',
  'cr.annual.report.balance-sheet.BE_B_LBL_DLF_172':
    'Dettes de location-financement et dettes assimilées (172)',
  'cr.annual.report.balance-sheet.BE_B_LBL_DLF_1723':
    'Etablissements de crédit, dettes de location-financement et dettes assimilées (1723)',
  'cr.annual.report.balance-sheet.BE_B_LBL_DLF_173': 'Établissements de crédit (173)',
  'cr.annual.report.balance-sheet.BE_B_LBL_DLF_174': 'Autres emprunts (174)',
  'cr.annual.report.balance-sheet.BE_B_LBL_DLF_1740': 'Autres emprunts (1740)',
  'cr.annual.report.balance-sheet.BE_B_LBL_DLO_1790': 'Portant intérêt (1790)',
  'cr.annual.report.balance-sheet.BE_B_LBL_DLO_1791':
    "Non productives d'intérêts ou assorties d'un intérêt anormalement faible (1791)",
  'cr.annual.report.balance-sheet.BE_B_LBL_DLO_1792': 'Cautionnements reçus en numéraire (1792)',
  'cr.annual.report.balance-sheet.BE_B_LBL_DLTR_1750': 'Fournisseurs (1750)',
  'cr.annual.report.balance-sheet.BE_B_LBL_DLTR_1751': 'Effets à payer (1751)',
  'cr.annual.report.balance-sheet.BE_B_LBL_DS_42':
    'Dettes à plus d’un an échéant dans l’année (42)',
  'cr.annual.report.balance-sheet.BE_B_LBL_DS_43': 'Dettes financières (43) ',
  'cr.annual.report.balance-sheet.BE_B_LBL_DS_44': 'Dettes commerciales (44)',
  'cr.annual.report.balance-sheet.BE_B_LBL_DS_45': 'Dettes fiscales, salariales et sociales (45)',
  'cr.annual.report.balance-sheet.BE_B_LBL_DS_46': 'Acomptes reçus sur commandes (46)',
  'cr.annual.report.balance-sheet.BE_B_LBL_DS_4748': 'Autres dettes (4748)',
  'cr.annual.report.balance-sheet.BE_B_LBL_DS_48': 'Dettes diverses (48)',
  'cr.annual.report.balance-sheet.BE_B_LBL_DSF_4308': 'Établissements de crédit (4308)',
  'cr.annual.report.balance-sheet.BE_B_LBL_DSF_439': 'Autres emprunts (439)',
  'cr.annual.report.balance-sheet.BE_B_LBL_DST_4503': 'Impôts (4503)',
  'cr.annual.report.balance-sheet.BE_B_LBL_DST_4549': 'Rémunérations et charges sociales (4549)',
  'cr.annual.report.balance-sheet.BE_B_LBL_DSTR_4404': 'Fournisseurs (4404)',
  'cr.annual.report.balance-sheet.BE_B_LBL_DSTR_441': 'Effets à payer (441)',
  'cr.annual.report.balance-sheet.BE_B_LBL_DSV_4808':
    'Obligations et coupons échus, subsides à\nrembourser et cautionnements reçus en numéraire (4808)',
  'cr.annual.report.balance-sheet.BE_B_LBL_DSV_4890': "Autres dettes productives d'intérêts (4890)",
  'cr.annual.report.balance-sheet.BE_B_LBL_DSV_4891':
    "Autres dettes non productives d'intérêts ou assorties d'un intérêt anormalement faible (4891)",
  'cr.annual.report.balance-sheet.BE_B_LBL_E_10': 'Capital (10) ',
  'cr.annual.report.balance-sheet.BE_B_LBL_E_11': 'Primes d’émission (11)',
  'cr.annual.report.balance-sheet.BE_B_LBL_E_12': 'Plus-values de réévaluation (12)',
  'cr.annual.report.balance-sheet.BE_B_LBL_E_13': 'Réserves (13) ',
  'cr.annual.report.balance-sheet.BE_B_LBL_E_14': 'Bénéfice (Perte) reporté(e) (14)',
  'cr.annual.report.balance-sheet.BE_B_LBL_E_15': 'Subsides en capital (15)',
  'cr.annual.report.balance-sheet.BE_B_LBL_E_19':
    'Avance aux associés sur répartition de l’actif net5 (19)',
  'cr.annual.report.balance-sheet.BE_B_LBL_EC_100': 'Capital souscrit (100) ',
  'cr.annual.report.balance-sheet.BE_B_LBL_EC_101': 'Capital non appelé\r\n (101)',
  'cr.annual.report.balance-sheet.BE_B_LBL_ER_130': 'Réserve légale (130)',
  'cr.annual.report.balance-sheet.BE_B_LBL_ER_1301': 'Réserves indisponibles (1301)',
  'cr.annual.report.balance-sheet.BE_B_LBL_ER_131': 'Réserves indisponibles  (131)',
  'cr.annual.report.balance-sheet.BE_B_LBL_ER_132': 'Réserves immunisées (132) ',
  'cr.annual.report.balance-sheet.BE_B_LBL_ER_133': 'Réserves disponibles (133) ',
  'cr.annual.report.balance-sheet.BE_B_LBL_ERU_1310': 'Pour actions propres (1310)',
  'cr.annual.report.balance-sheet.BE_B_LBL_ERU_1311': 'Autres (1311)',
  'cr.annual.report.balance-sheet.BE_B_LBL_ERU_1312': 'Acquisition d’actions propres (1312)',
  'cr.annual.report.balance-sheet.BE_B_LBL_ERU_1313': 'Soutien financier (1313)',
  'cr.annual.report.balance-sheet.BE_B_LBL_ERU_1319': 'Autres (1319)',
  'cr.annual.report.balance-sheet.BE_B_LBL_P_1605': 'Provisions pour risques et charges (1605) ',
  'cr.annual.report.balance-sheet.BE_B_LBL_P_168':
    'Provisions pour subsides et legs à rembourser et pour dons avec droit de reprise (168)',
  'cr.annual.report.balance-sheet.BE_B_LBL_PR_160': 'Pensions et obligations similaires (160)',
  'cr.annual.report.balance-sheet.BE_B_LBL_PR_161': 'Charges fiscales (161)',
  'cr.annual.report.balance-sheet.BE_B_LBL_PR_162': 'Grosses réparations et gros entretien (162)',
  'cr.annual.report.balance-sheet.BE_B_LBL_PR_163': 'Obligations environnementales (163)',
  'cr.annual.report.balance-sheet.BE_B_LBL_PR_1635': 'Autres risques et charges (1635)',
  'cr.annual.report.balance-sheet.BE_B_LBL_PR_1645': 'Autres risques et charges (1645)',
  'cr.annual.report.balance-sheet.BE_PASSIVA': 'PASSIF',
  'cr.annual.report.balance-sheet.current-assets': 'Actifs circulants',
  'cr.annual.report.balance-sheet.current-liabilites': 'Passifs courants',
  'cr.annual.report.balance-sheet.fixed-assets': 'Actifs immobilisés',
  'cr.annual.report.balance-sheet.GB_AST_00_00_001': 'Fonds de roulement',
  'cr.annual.report.balance-sheet.GB_AST_00_00_002': 'Capital employé',
  'cr.annual.report.balance-sheet.GB_AST_00_00_003': 'Total actifs nets',
  'cr.annual.report.balance-sheet.GB_AST_00_00_004': 'Actifs nets',
  'cr.annual.report.balance-sheet.GB_AST_00_00_005': 'Valeur nette',
  'cr.annual.report.balance-sheet.GB_AST_01': 'Total actifs immobilisés',
  'cr.annual.report.balance-sheet.GB_AST_02': 'Total actifs incorporels',
  'cr.annual.report.balance-sheet.GB_AST_03': 'Total actifs corporels',
  'cr.annual.report.balance-sheet.GB_AST_03_01': 'Terrains et immeubles',
  'cr.annual.report.balance-sheet.GB_AST_03_01_01': 'Propriété',
  'cr.annual.report.balance-sheet.GB_AST_03_01_02': 'En location',
  'cr.annual.report.balance-sheet.GB_AST_03_02': 'Installations corporelles',
  'cr.annual.report.balance-sheet.GB_AST_03_03': 'Usine et véhicules',
  'cr.annual.report.balance-sheet.GB_AST_03_04': 'Actifs en cours de construction',
  'cr.annual.report.balance-sheet.GB_AST_03_05': 'Autres actifs corporels',
  'cr.annual.report.balance-sheet.GB_AST_04': 'Total actifs financiers',
  'cr.annual.report.balance-sheet.GB_AST_04_01': 'Location-financements / Vente à tempérament',
  'cr.annual.report.balance-sheet.GB_AST_04_02': 'Prêts de groupe',
  'cr.annual.report.balance-sheet.GB_AST_04_03': 'Prêts administrateurs',
  'cr.annual.report.balance-sheet.GB_AST_04_04': 'Autres prêts',
  'cr.annual.report.balance-sheet.GB_AST_04_05': 'Autres actifs financiers',
  'cr.annual.report.balance-sheet.GB_AST_05': 'Autres actifs immobilisés / non courants',
  'cr.annual.report.balance-sheet.GB_AST_05_01': 'Commerce à long terme',
  'cr.annual.report.balance-sheet.GB_AST_05_02': 'Partenaire / Associé / Filiale',
  'cr.annual.report.balance-sheet.GB_AST_05_03': 'Actifs détenus pour la revente',
  'cr.annual.report.balance-sheet.GB_AST_05_04': 'Autres',
  'cr.annual.report.balance-sheet.GB_AST_06': 'Autres actifs circulants',
  'cr.annual.report.balance-sheet.GB_AST_07': 'Total stocks',
  'cr.annual.report.balance-sheet.GB_AST_07_01': 'Stocks / Matières premières',
  'cr.annual.report.balance-sheet.GB_AST_07_02': 'Travaux en cours',
  'cr.annual.report.balance-sheet.GB_AST_07_03': 'Produits finis',
  'cr.annual.report.balance-sheet.GB_AST_08': 'Total actifs financiers',
  'cr.annual.report.balance-sheet.GB_AST_08_01': 'Location-financements / Vente à tempérament',
  'cr.annual.report.balance-sheet.GB_AST_08_02': 'Prêts de groupe',
  'cr.annual.report.balance-sheet.GB_AST_08_03': 'Prêts administrateurs',
  'cr.annual.report.balance-sheet.GB_AST_08_04': 'Autres prêts',
  'cr.annual.report.balance-sheet.GB_AST_08_05': 'Autres actifs financiers',
  'cr.annual.report.balance-sheet.GB_AST_10': 'Total trésorerie',
  'cr.annual.report.balance-sheet.GB_AST_10_01': 'Trésorerie en banque / en caisse',
  'cr.annual.report.balance-sheet.GB_AST_10_02': 'Équivalents de trésorerie',
  'cr.annual.report.balance-sheet.GB_AST_11': 'Total débiteurs',
  'cr.annual.report.balance-sheet.GB_AST_11_01': 'Comptes débiteurs / Créances commerciales',
  'cr.annual.report.balance-sheet.GB_AST_11_02': 'Partenaire / Associé / Filiale',
  'cr.annual.report.balance-sheet.GB_AST_11_03': 'Prêts de groupe',
  'cr.annual.report.balance-sheet.GB_AST_11_04': 'Prêts administrateurs',
  'cr.annual.report.balance-sheet.GB_AST_11_05': 'Pre-paiement / Comptes de régularisation',
  'cr.annual.report.balance-sheet.GB_AST_11_06': 'Autres créanciers',
  'cr.annual.report.balance-sheet.GB_AST_13': 'Autres actifs circulants',
  'cr.annual.report.balance-sheet.GB_AST_13_01': 'Actifs détenus pour la revente',
  'cr.annual.report.balance-sheet.GB_AST_13_02': 'Autres',
  'cr.annual.report.balance-sheet.GB_AST_14': "Total actifs d'investissement",
  'cr.annual.report.balance-sheet.GB_AST_14_01': 'Propriété',
  'cr.annual.report.balance-sheet.GB_AST_14_02': 'Partenaire / Associé / Filiale',
  'cr.annual.report.balance-sheet.GB_AST_14_03': 'Autres investissements',
  'cr.annual.report.balance-sheet.GB_AST_15': "Total de l'actif",
  'cr.annual.report.balance-sheet.GB_LBL_01': 'Total des fonds propres',
  'cr.annual.report.balance-sheet.GB_LBL_01_01': 'Capital souscrit',
  'cr.annual.report.balance-sheet.GB_LBL_01_01_01': 'Ordinaire',
  'cr.annual.report.balance-sheet.GB_LBL_01_01_02': 'Préférence',
  'cr.annual.report.balance-sheet.GB_LBL_01_01_03': 'Autres',
  'cr.annual.report.balance-sheet.GB_LBL_01_02': "Primes d'émission",
  'cr.annual.report.balance-sheet.GB_LBL_01_03': 'Intérêt en actions propres',
  'cr.annual.report.balance-sheet.GB_LBL_01_04': 'Bénéfices non distribués',
  'cr.annual.report.balance-sheet.GB_LBL_01_05': 'Réserve de réévaluation',
  'cr.annual.report.balance-sheet.GB_LBL_01_06': 'Réserve de conversion de devises',
  'cr.annual.report.balance-sheet.GB_LBL_01_07': 'Autres réserves',
  'cr.annual.report.balance-sheet.GB_LBL_01.NET-ASSET': 'Actifs nets',
  'cr.annual.report.balance-sheet.GB_LBL_12': 'Total provisions',
  'cr.annual.report.balance-sheet.GB_LBL_12_01': 'Impôts différés',
  'cr.annual.report.balance-sheet.GB_LBL_12_02': 'Pension de retraite',
  'cr.annual.report.balance-sheet.GB_LBL_12_03': 'Autres réserves',
  'cr.annual.report.balance-sheet.GB_LBL_13': 'Total passifs à long terme',
  'cr.annual.report.balance-sheet.GB_LBL_13_01': 'Total passifs financiers',
  'cr.annual.report.balance-sheet.GB_LBL_13_01_01': 'Location-financements / Vente à tempérament',
  'cr.annual.report.balance-sheet.GB_LBL_13_01_01_01': 'Location-financements',
  'cr.annual.report.balance-sheet.GB_LBL_13_01_01_02': 'Vente à tempérament',
  'cr.annual.report.balance-sheet.GB_LBL_13_01_02': 'Prêts de groupe',
  'cr.annual.report.balance-sheet.GB_LBL_13_01_03': 'Prêts administrateurs',
  'cr.annual.report.balance-sheet.GB_LBL_13_01_04': 'Subventions',
  'cr.annual.report.balance-sheet.GB_LBL_13_01_05': 'Autres prêts à long terme',
  'cr.annual.report.balance-sheet.GB_LBL_13_01_06': 'Autres passifs financiers',
  'cr.annual.report.balance-sheet.GB_LBL_13_02': 'Autres passifs à long terme',
  'cr.annual.report.balance-sheet.GB_LBL_13_02_01': 'Commerce à long terme',
  'cr.annual.report.balance-sheet.GB_LBL_13_02_02': 'Partenaire / Associé / Filiale',
  'cr.annual.report.balance-sheet.GB_LBL_13_02_03': 'Prêts de groupe',
  'cr.annual.report.balance-sheet.GB_LBL_13_02_04': 'Prêts administrateurs',
  'cr.annual.report.balance-sheet.GB_LBL_13_02_05': 'Passifs détenus pour la revente',
  'cr.annual.report.balance-sheet.GB_LBL_13_02_06': 'Comptes de régularisation & Revenu reporté',
  'cr.annual.report.balance-sheet.GB_LBL_13_02_07': 'Actions privilégiées',
  'cr.annual.report.balance-sheet.GB_LBL_13_02_08': 'Autres',
  'cr.annual.report.balance-sheet.GB_LBL_15': 'Total passifs courants',
  'cr.annual.report.balance-sheet.GB_LBL_15_01': 'Total créanciers',
  'cr.annual.report.balance-sheet.GB_LBL_15_01_01': 'Créances / Créanciers',
  'cr.annual.report.balance-sheet.GB_LBL_15_01_02': 'Partenaire / Associé / Filiale',
  'cr.annual.report.balance-sheet.GB_LBL_15_01_03': 'Prêts de groupe',
  'cr.annual.report.balance-sheet.GB_LBL_15_01_04': 'Prêts administrateurs',
  'cr.annual.report.balance-sheet.GB_LBL_15_01_05': 'Comptes de régularisation & Revenu reporté',
  'cr.annual.report.balance-sheet.GB_LBL_15_01_06': 'Sécurité sociale / TVA',
  'cr.annual.report.balance-sheet.GB_LBL_15_01_07': 'Impôts à payer',
  'cr.annual.report.balance-sheet.GB_LBL_15_01_08': 'Autres créanciers',
  'cr.annual.report.balance-sheet.GB_LBL_15_02': 'Total passifs financiers',
  'cr.annual.report.balance-sheet.GB_LBL_15_02_01': 'Découvert bancaire',
  'cr.annual.report.balance-sheet.GB_LBL_15_02_02': 'Location-financements / Vente à tempérament',
  'cr.annual.report.balance-sheet.GB_LBL_15_02_02_01':
    'Location-financements / Vente à tempérament',
  'cr.annual.report.balance-sheet.GB_LBL_15_02_02_02': 'Vente à tempérament',
  'cr.annual.report.balance-sheet.GB_LBL_15_02_03': 'Prêts de groupe',
  'cr.annual.report.balance-sheet.GB_LBL_15_02_04': 'Prêts administrateurs',
  'cr.annual.report.balance-sheet.GB_LBL_15_02_05': 'Subventions',
  'cr.annual.report.balance-sheet.GB_LBL_15_02_06': 'Autres prêts à court terme',
  'cr.annual.report.balance-sheet.GB_LBL_15_02_07': 'Autres passifs financiers',
  'cr.annual.report.balance-sheet.GB_LBL_15_03': 'Autres passifs courants',
  'cr.annual.report.balance-sheet.GB_LBL_15_03_01': 'Passifs détenus pour la revente',
  'cr.annual.report.balance-sheet.GB_LBL_15_03_02': 'Autres',
  'cr.annual.report.balance-sheet.GB_LBL_15_04': 'Dividendes',
  'cr.annual.report.balance-sheet.GB_LBL_20': 'Participations minoritaires',
  'cr.annual.report.balance-sheet.long-term-liabilities': 'Passifs à long terme',
  'cr.annual.report.balance-sheet.NL_ACC_01': 'Total bilan',
  'cr.annual.report.balance-sheet.NL_AST_10_00_001': 'Total actifs immobilisés',
  'cr.annual.report.balance-sheet.NL_AST_10_00_002': 'Autres actifs immobilisés',
  'cr.annual.report.balance-sheet.NL_AST_10_10_001': 'Immobilisations incorporelles',
  'cr.annual.report.balance-sheet.NL_AST_10_10_002': 'Frais d’établissement et émission d’actions',
  'cr.annual.report.balance-sheet.NL_AST_10_10_003': 'Frais de recherche et développement',
  'cr.annual.report.balance-sheet.NL_AST_10_10_004':
    'Frais d’acquisition, concessions, licences et droits intellectuels',
  'cr.annual.report.balance-sheet.NL_AST_10_10_005': 'Goodwill',
  'cr.annual.report.balance-sheet.NL_AST_10_10_006': 'Acompte sur immobilisations incorporelles',
  'cr.annual.report.balance-sheet.NL_AST_10_20_001': 'Immobilisations corporelles',
  'cr.annual.report.balance-sheet.NL_AST_10_20_002': 'Bâtiments et terrains d’entreprise',
  'cr.annual.report.balance-sheet.NL_AST_10_20_003': 'Machines et installations',
  'cr.annual.report.balance-sheet.NL_AST_10_20_004': 'Autres immobilisations corporelles',
  'cr.annual.report.balance-sheet.NL_AST_10_20_005':
    'Immobilisations corporelles en exécution et acompte sur immobilisations corporelles',
  'cr.annual.report.balance-sheet.NL_AST_10_20_006': 'Actifs immobilisés non courants',
  'cr.annual.report.balance-sheet.NL_AST_10_30_001': 'Immobilisations financières',
  'cr.annual.report.balance-sheet.NL_AST_10_30_002': 'Participations dans des sociétés du groupe',
  'cr.annual.report.balance-sheet.NL_AST_10_30_003': 'Créances sur sociétés du groupe',
  'cr.annual.report.balance-sheet.NL_AST_10_30_004': 'Autres participations',
  'cr.annual.report.balance-sheet.NL_AST_10_30_005': 'Créances sur participants et participations',
  'cr.annual.report.balance-sheet.NL_AST_10_30_006': 'Autres titres',
  'cr.annual.report.balance-sheet.NL_AST_20_00_001': 'Total actifs circulants',
  'cr.annual.report.balance-sheet.NL_AST_20_00_002': 'Autres actifs circulants',
  'cr.annual.report.balance-sheet.NL_AST_20_10_001': 'Stocks',
  'cr.annual.report.balance-sheet.NL_AST_20_10_002': 'Approvisionnements',
  'cr.annual.report.balance-sheet.NL_AST_20_10_003': 'En-cours de fabrication',
  'cr.annual.report.balance-sheet.NL_AST_20_10_004': 'Produits finis et marchandises',
  'cr.annual.report.balance-sheet.NL_AST_20_10_005': 'Acompte sur stock',
  'cr.annual.report.balance-sheet.NL_AST_20_30_001': 'Créances',
  'cr.annual.report.balance-sheet.NL_AST_20_30_002': 'Débiteurs commerciaux',
  'cr.annual.report.balance-sheet.NL_AST_20_30_003': 'Sociétés du groupe',
  'cr.annual.report.balance-sheet.NL_AST_20_30_004': 'Participants et participations',
  'cr.annual.report.balance-sheet.NL_AST_20_30_005': 'Autres créances',
  'cr.annual.report.balance-sheet.NL_AST_20_30_006': 'Versements demandés aux actionnaires',
  'cr.annual.report.balance-sheet.NL_AST_20_30_007': 'Produits à recevoir',
  'cr.annual.report.balance-sheet.NL_AST_20_30_008': 'Créances et produits à recevoir',
  'cr.annual.report.balance-sheet.NL_AST_20_40_001': 'Titres',
  'cr.annual.report.balance-sheet.NL_AST_20_40_002': 'Sociétés liées en dehors du groupe',
  'cr.annual.report.balance-sheet.NL_AST_20_40_003': 'Autres titres',
  'cr.annual.report.balance-sheet.NL_AST_20_50_001': 'Valeurs disponibles',
  'cr.annual.report.balance-sheet.NL_AST_20_50_002': 'Autres valeurs disponibles',
  'cr.annual.report.balance-sheet.NL_LBL_30_00_000': 'Total passif',
  'cr.annual.report.balance-sheet.NL_LBL_30_10_001': 'Fonds propres',
  'cr.annual.report.balance-sheet.NL_LBL_30_10_002': 'Capital libéré et appelé',
  'cr.annual.report.balance-sheet.NL_LBL_30_10_003': 'Agios',
  'cr.annual.report.balance-sheet.NL_LBL_30_10_004': 'Réserve de revalorisation',
  'cr.annual.report.balance-sheet.NL_LBL_30_10_005': 'Réserve légale',
  'cr.annual.report.balance-sheet.NL_LBL_30_10_006': 'Réserve statutaire',
  'cr.annual.report.balance-sheet.NL_LBL_30_10_007': 'Réserves statutaires légales',
  'cr.annual.report.balance-sheet.NL_LBL_30_10_008': 'Autres réserves',
  'cr.annual.report.balance-sheet.NL_LBL_30_10_009': 'Bénéfice non distribué',
  'cr.annual.report.balance-sheet.NL_LBL_30_10_010': 'Résultat après impôts',
  'cr.annual.report.balance-sheet.NL_LBL_30_10_012': 'Equilibre des pertes',
  'cr.annual.report.balance-sheet.NL_LBL_30_20_001': 'Part des tiers',
  'cr.annual.report.balance-sheet.NL_LBL_30_20_002': 'Autres parts des tiers',
  'cr.annual.report.balance-sheet.NL_LBL_30_25_001': 'Compte d’égalisation',
  'cr.annual.report.balance-sheet.NL_LBL_30_25_002': 'Autres comptes d’égalisation',
  'cr.annual.report.balance-sheet.NL_LBL_30_30_001': 'Provisions',
  'cr.annual.report.balance-sheet.NL_LBL_30_30_002': 'Pour les pensions de retraite',
  'cr.annual.report.balance-sheet.NL_LBL_30_30_003': 'Pour impôts',
  'cr.annual.report.balance-sheet.NL_LBL_30_30_004': 'Pour restructuration / réorganisation',
  'cr.annual.report.balance-sheet.NL_LBL_30_30_005': 'Pour débiteurs',
  'cr.annual.report.balance-sheet.NL_LBL_30_30_006': 'Pour impôts latents',
  'cr.annual.report.balance-sheet.NL_LBL_30_30_007': 'Autres',
  'cr.annual.report.balance-sheet.NL_LBL_30_40_001': 'Dettes à long terme',
  'cr.annual.report.balance-sheet.NL_LBL_30_40_002': 'Emprunts convertibles',
  'cr.annual.report.balance-sheet.NL_LBL_30_40_003': 'Autres obligations et placements privés',
  'cr.annual.report.balance-sheet.NL_LBL_30_40_004': 'Dettes aux établissements de crédit',
  'cr.annual.report.balance-sheet.NL_LBL_30_40_005': 'Acomptes reçus sur commandes',
  'cr.annual.report.balance-sheet.NL_LBL_30_40_006':
    'Dettes aux fournisseurs et crédits commerciaux',
  'cr.annual.report.balance-sheet.NL_LBL_30_40_007': 'Titres et chèques à payer',
  'cr.annual.report.balance-sheet.NL_LBL_30_40_008': 'Dettes à des sociétés du groupe',
  'cr.annual.report.balance-sheet.NL_LBL_30_40_009': 'Dettes aux participants et participations',
  'cr.annual.report.balance-sheet.NL_LBL_30_40_010': 'Impôts et primes assurances sociales',
  'cr.annual.report.balance-sheet.NL_LBL_30_40_011': 'Dettes pour les pensions',
  'cr.annual.report.balance-sheet.NL_LBL_30_40_012': 'Autres dettes',
  'cr.annual.report.balance-sheet.NL_LBL_30_40_013': 'Charges à payer',
  'cr.annual.report.balance-sheet.NL_LBL_30_50_001': 'Dettes à court terme',
  'cr.annual.report.balance-sheet.NL_LBL_30_50_002': 'Obligations convertibles et autres emprunts',
  'cr.annual.report.balance-sheet.NL_LBL_30_50_003': 'Autres obligations et placements privés',
  'cr.annual.report.balance-sheet.NL_LBL_30_50_004': 'Dettes aux établissements de crédit',
  'cr.annual.report.balance-sheet.NL_LBL_30_50_005': 'Acomptes reçus sur commandes',
  'cr.annual.report.balance-sheet.NL_LBL_30_50_006':
    'Dettes aux fournisseurs et crédits commerciaux',
  'cr.annual.report.balance-sheet.NL_LBL_30_50_007': 'Titres et chèques à payer',
  'cr.annual.report.balance-sheet.NL_LBL_30_50_008': 'Dettes à des sociétés du groupe',
  'cr.annual.report.balance-sheet.NL_LBL_30_50_009': 'Dettes aux participants et participations',
  'cr.annual.report.balance-sheet.NL_LBL_30_50_010': 'Impôts et primes assurances sociales',
  'cr.annual.report.balance-sheet.NL_LBL_30_50_011': 'Dettes pour les pensions de retraite',
  'cr.annual.report.balance-sheet.NL_LBL_30_50_012': 'Autres dettes',
  'cr.annual.report.balance-sheet.NL_LBL_30_50_013': 'Charges à payer',
  'cr.annual.report.balance-sheet.NL_LBL_30_50_014': 'Dettes à court terme et charges à payer',
  'cr.annual.report.balance-sheet.passiva': 'Passif',
  'cr.annual.report.balance-sheet.provisions': 'Provisions',
  'cr.annual.report.balance-sheet.shareholders-funds': 'Fonds propres',
  'cr.annual.report.balance-sheet.sub-header': 'Devise de base et unité',
  'cr.annual.report.balance-sheet.tooltip.BE':
    "Vous trouverez ici les bilans des 5 derniers exercices avec tous les détails. Cliquez sur les triangles bleus pour afficher et fermer les postes sous-jacents. Le bilan est un instantané des actifs et des passifs au dernier jour de l'exercice.",
  'cr.annual.report.balance-sheet.tooltip.GB':
    "Vous trouverez le solde ici. Le bilan est un instantané de l'actif et du passif au dernier jour de l'exercice.",
  'cr.annual.report.balance-sheet.tooltip.NL':
    "Vous trouverez le bilan ici. Le bilan est un instantané des actifs et des passifs au dernier jour de l'exercice.",
  'cr.annual.report.balance-sheet.vaste-activa': 'Actifs immobilisés',
  'cr.annual.report.balance-sheet.vlottende-activa': 'Actifs circulants',
  'cr.annual.report.base-currency': 'Devise de base et unité',
  'cr.annual.report.download-excel': 'Télécharger les comptes annuels',
  'cr.annual.report.explanatory-notes-BE_1.BE_T_635':
    'Provisions pour pensions et obligations similaires (635)',
  'cr.annual.report.explanatory-notes-BE_1.BE_T_6358':
    'Provisions pour risques et charges: dotations (6358)',
  'cr.annual.report.explanatory-notes-BE_1.BE_T_6408': "Autres charges d'exploitation (6408)",
  'cr.annual.report.explanatory-notes-BE_1.BE_T_9072': 'Dettes fiscales échues (9072)',
  'cr.annual.report.explanatory-notes-BE_1.BE_T_9076':
    "Dettes échues envers l'Office National de Sécurité Sociale (9076)",
  'cr.annual.report.explanatory-notes-BE_1.BE_T_9150':
    "Effets de commerce en circulation endossés par l'entreprise (9150)",
  'cr.annual.report.explanatory-notes-BE_1.BE_T_9161':
    "Garanties de l'entreprise - Hypothèques - Valeur comptable des immeubles grevés (9161)",
  'cr.annual.report.explanatory-notes-BE_1.BE_T_91611':
    "Garanties de l'entreprise - Hypothèques - Valeur comptable des immeubles grevés (91611)",
  'cr.annual.report.explanatory-notes-BE_1.BE_T_91612':
    'Garanties de tiers - Hypothèques - Valeur comptable des immeubles grevés (91612)',
  'cr.annual.report.explanatory-notes-BE_1.BE_T_9162':
    'Garanties de tiers - Hypothèques - Valeur comptable des immeubles grevés (9162)',
  'cr.annual.report.explanatory-notes-BE_1.BE_T_91621':
    "Garanties de l'entreprise - Hypothèques - Montant de l'inscription (91621)",
  'cr.annual.report.explanatory-notes-BE_1.BE_T_91622':
    "Garanties de tiers - Hypothèques - Montant de l'inscription (91622)",
  'cr.annual.report.explanatory-notes-BE_1.BE_T_91631':
    "Garanties de l'entreprise - Hypothèques - Montant de l'inscription autorisé (91631)",
  'cr.annual.report.explanatory-notes-BE_1.BE_T_91632':
    "Garanties de triers - Hypothèques - Montant de l'inscription autorisé (91632)",
  'cr.annual.report.explanatory-notes-BE_1.BE_T_9171':
    "Garanties de l'entreprise - Hypothèques - Montant de l'inscription (9171)",
  'cr.annual.report.explanatory-notes-BE_1.BE_T_91711':
    "Garanties de l'entreprise - Gages sur fonds de commerce - Montant de l'inscription (91711)",
  'cr.annual.report.explanatory-notes-BE_1.BE_T_91712':
    "Garanties de tiers - Gages sur fonds de commerce - Montant de l'inscription (91712)",
  'cr.annual.report.explanatory-notes-BE_1.BE_T_9172':
    "Garanties de tiers - Hypothèques - Montant de l'inscription (9172)",
  'cr.annual.report.explanatory-notes-BE_1.BE_T_91721':
    "Garanties de l'entreprise - Gages sur fonds de commerce - Montant de l'inscription autorisé (91721)",
  'cr.annual.report.explanatory-notes-BE_1.BE_T_91722':
    "Garanties de tiers - Gages sur fonds de commerce - Montant de l'inscription autorisé (91722)",
  'cr.annual.report.explanatory-notes-BE_1.BE_T_9181':
    "Garanties de l'entreprise - Gages sur fonds de commerce - Montant de l'inscription (9181)",
  'cr.annual.report.explanatory-notes-BE_1.BE_T_91811':
    "Garanties de l'entreprise - Gages sur d'autres actifs - Valeur comptable des actifs gagés (91811)",
  'cr.annual.report.explanatory-notes-BE_1.BE_T_91812':
    "Garanties de tiers - Gages sur d'autres actifs - Valeur comptable des actifs gagés (91812)",
  'cr.annual.report.explanatory-notes-BE_1.BE_T_9182':
    "Garanties de tiers - Gages sur fonds de commerce - Montant de l'inscription (9182)",
  'cr.annual.report.explanatory-notes-BE_1.BE_T_91821':
    "Garanties de l'entreprise - Gages sur d'autres actifs - Montant maximum (91821)",
  'cr.annual.report.explanatory-notes-BE_1.BE_T_91822':
    "Garanties de tiers - Gages sur d'autres actifs - Montant maximum (91822)",
  'cr.annual.report.explanatory-notes-BE_1.BE_T_9191':
    "Garanties de l'entreprise - Gages sur d'autres actifs - Valeur comptable des actifs gagés (9191)",
  'cr.annual.report.explanatory-notes-BE_1.BE_T_91911':
    "Garanties de l'entreprise - Sûretés constituées sur actifs futurs - Montant des actifs en cause (91911)",
  'cr.annual.report.explanatory-notes-BE_1.BE_T_91912':
    'Garanties de tiers - Sûretés constituées sur actifs futurs - Montant des actifs en cause (91912)',
  'cr.annual.report.explanatory-notes-BE_1.BE_T_9192':
    "Garanties de tiers - Gages sur d'autres actifs - Valeur comptable des actifs gagés (9192)",
  'cr.annual.report.explanatory-notes-BE_1.BE_T_91921':
    "Garanties de l'entreprise - Sûretés constituées sur actifs futurs - Montant maximum (91921)",
  'cr.annual.report.explanatory-notes-BE_1.BE_T_91922':
    'Garanties de tiers - Sûretés constituées sur actifs futurs - Montant maximum (91922)',
  'cr.annual.report.explanatory-notes-BE_1.BE_T_9201':
    "Garanties de l'entreprise - Sûretés constituées sur actifs futurs - Montant des actifs en cause (9201)",
  'cr.annual.report.explanatory-notes-BE_1.BE_T_92011':
    "Garanties de l'entreprise - Privilege du vendeur - Valeur comptable du bien vendu (92011)",
  'cr.annual.report.explanatory-notes-BE_1.BE_T_92012':
    'Garanties de tiers - Privilege du vendeur - Valeur comptable du bien vendu (92012)',
  'cr.annual.report.explanatory-notes-BE_1.BE_T_9202':
    'Garanties de tiers - Sûretés constituées sur actifs futurs - Montant des actifs en cause (9202)',
  'cr.annual.report.explanatory-notes-BE_1.BE_T_92021':
    "Garanties de l'entreprise - Privilege du vendeur - Montant du prix non payé (92021)",
  'cr.annual.report.explanatory-notes-BE_1.BE_T_92022':
    'Garanties de tiers - Privilege du vendeur - Montant du prix non payé (92022)',
  'cr.annual.report.explanatory-notes-BE_1.BE_T_9500':
    'Créances sur les personnes précitées (9500)',
  'cr.annual.report.explanatory-notes-BE_1.BE_T_9501':
    'Garanties constituées en leur faveur (9501)',
  'cr.annual.report.explanatory-notes-BE_1.BE_T_9502':
    'Autres engagements significatifs souscrits en leur faveur (9502)',
  'cr.annual.report.explanatory-notes-BE_1.business': 'Garanties réelles',
  'cr.annual.report.explanatory-notes-BE_1.general': 'Général',
  'cr.annual.report.explanatory-notes-BE_10.BE_T_6358':
    'Provisions pour risques et charges: dotations (6358)',
  'cr.annual.report.explanatory-notes-BE_10.BE_T_6408': "Autres charges d'exploitation (6408)",
  'cr.annual.report.explanatory-notes-BE_10.BE_T_9072': 'Dettes fiscales échues (9072)',
  'cr.annual.report.explanatory-notes-BE_10.BE_T_9076':
    "Dettes échues envers l'Office National de Sécurité Sociale (9076)",
  'cr.annual.report.explanatory-notes-BE_10.BE_T_9150':
    "Effets de commerce en circulation endossés par l'entreprise (9150)",
  'cr.annual.report.explanatory-notes-BE_10.BE_T_91611':
    "Garanties de l'entreprise - Hypothèques - Valeur comptable des immeubles grevés (91611)",
  'cr.annual.report.explanatory-notes-BE_10.BE_T_91612':
    'Garanties de tiers - Hypothèques - Valeur comptable des immeubles grevés (91612)',
  'cr.annual.report.explanatory-notes-BE_10.BE_T_91621':
    "Garanties de l'entreprise - Hypothèques - Montant de l'inscription (91621)",
  'cr.annual.report.explanatory-notes-BE_10.BE_T_91622':
    "Garanties de tiers - Hypothèques - Montant de l'inscription (91622)",
  'cr.annual.report.explanatory-notes-BE_10.BE_T_91631':
    "Garanties de l'entreprise - Hypothèques - Montant de l'inscription autorisé (91631)",
  'cr.annual.report.explanatory-notes-BE_10.BE_T_91632':
    "Garanties de triers - Hypothèques - Montant de l'inscription autorisé (91632)",
  'cr.annual.report.explanatory-notes-BE_10.BE_T_91711':
    "Garanties de l'entreprise - Gages sur fonds de commerce - Montant de l'inscription (91711)",
  'cr.annual.report.explanatory-notes-BE_10.BE_T_91712':
    "Garanties de tiers - Gages sur fonds de commerce - Montant de l'inscription (91712)",
  'cr.annual.report.explanatory-notes-BE_10.BE_T_91721':
    "Garanties de l'entreprise - Gages sur fonds de commerce - Montant de l'inscription autorisé (91721)",
  'cr.annual.report.explanatory-notes-BE_10.BE_T_91722':
    "Garanties de tiers - Gages sur fonds de commerce - Montant de l'inscription autorisé (91722)",
  'cr.annual.report.explanatory-notes-BE_10.BE_T_91811':
    "Garanties de l'entreprise - Gages sur d'autres actifs - Valeur comptable des actifs gagés (91811)",
  'cr.annual.report.explanatory-notes-BE_10.BE_T_91812':
    "Garanties de tiers - Gages sur d'autres actifs - Valeur comptable des actifs gagés (91812)",
  'cr.annual.report.explanatory-notes-BE_10.BE_T_91821':
    "Garanties de l'entreprise - Gages sur d'autres actifs - Montant maximum (91821)",
  'cr.annual.report.explanatory-notes-BE_10.BE_T_91822':
    "Garanties de tiers - Gages sur d'autres actifs - Montant maximum (91822)",
  'cr.annual.report.explanatory-notes-BE_10.BE_T_91911':
    "Garanties de l'entreprise - Sûretés constituées sur actifs futurs - Montant des actifs en cause (91911)",
  'cr.annual.report.explanatory-notes-BE_10.BE_T_91912':
    'Garanties de tiers - Sûretés constituées sur actifs futurs - Montant des actifs en cause (91912)',
  'cr.annual.report.explanatory-notes-BE_10.BE_T_91921':
    "Garanties de l'entreprise - Sûretés constituées sur actifs futurs - Montant maximum (91921)",
  'cr.annual.report.explanatory-notes-BE_10.BE_T_91922':
    'Garanties de tiers - Sûretés constituées sur actifs futurs - Montant maximum (91922)',
  'cr.annual.report.explanatory-notes-BE_10.BE_T_92011':
    "Garanties de l'entreprise - Privilege du vendeur - Valeur comptable du bien vendu (92011)",
  'cr.annual.report.explanatory-notes-BE_10.BE_T_92012':
    'Garanties de tiers - Privilege du vendeur - Valeur comptable du bien vendu (92012)',
  'cr.annual.report.explanatory-notes-BE_10.BE_T_92021':
    "Garanties de l'entreprise - Privilege du vendeur - Montant du prix non payé (92021)",
  'cr.annual.report.explanatory-notes-BE_10.BE_T_92022':
    'Garanties de tiers - Privilege du vendeur - Montant du prix non payé (92022)',
  'cr.annual.report.explanatory-notes-BE_10.BE_T_9500':
    'Créances sur les personnes précitées (9500)',
  'cr.annual.report.explanatory-notes-BE_10.BE_T_9501':
    'Garanties constituées en leur faveur (9501)',
  'cr.annual.report.explanatory-notes-BE_10.BE_T_9502':
    'Autres engagements significatifs souscrits en leur faveur (9502)',
  'cr.annual.report.explanatory-notes-BE_10.business': 'Garanties réelles',
  'cr.annual.report.explanatory-notes-BE_10.general': 'Général',
  'cr.annual.report.explanatory-notes-BE_11.BE_T_6358':
    'Provisions pour risques et charges: dotations (6358)',
  'cr.annual.report.explanatory-notes-BE_11.BE_T_6408': "Autres charges d'exploitation (6408)",
  'cr.annual.report.explanatory-notes-BE_11.BE_T_9072': 'Dettes fiscales échues (9072)',
  'cr.annual.report.explanatory-notes-BE_11.BE_T_9076':
    "Dettes échues envers l'Office National de Sécurité Sociale (9076)",
  'cr.annual.report.explanatory-notes-BE_11.BE_T_9150':
    'Effets de commerce en circulation endossés par la société (9150)',
  'cr.annual.report.explanatory-notes-BE_11.BE_T_91611':
    "Garanties de l'entreprise - Hypothèques - Valeur comptable des immeubles grevés (91611)",
  'cr.annual.report.explanatory-notes-BE_11.BE_T_91612':
    'Garanties de tiers - Hypothèques - Valeur comptable des immeubles grevés (91612)',
  'cr.annual.report.explanatory-notes-BE_11.BE_T_91621':
    "Garanties de l'entreprise - Hypothèques - Montant de l'inscription (91621)",
  'cr.annual.report.explanatory-notes-BE_11.BE_T_91622':
    "Garanties de tiers - Hypothèques - Montant de l'inscription (91622)",
  'cr.annual.report.explanatory-notes-BE_11.BE_T_91631':
    "Garanties de l'entreprise - Hypothèques - Montant de l'inscription autorisé (91631)",
  'cr.annual.report.explanatory-notes-BE_11.BE_T_91632':
    "Garanties de triers - Hypothèques - Montant de l'inscription autorisé (91632)",
  'cr.annual.report.explanatory-notes-BE_11.BE_T_91711':
    "Garanties de l'entreprise - Gages sur fonds de commerce - Montant de l'inscription (91711)",
  'cr.annual.report.explanatory-notes-BE_11.BE_T_91712':
    "Garanties de tiers - Gages sur fonds de commerce - Montant de l'inscription (91712)",
  'cr.annual.report.explanatory-notes-BE_11.BE_T_91721':
    "Garanties de l'entreprise - Gages sur fonds de commerce - Montant de l'inscription autorisé (91721)",
  'cr.annual.report.explanatory-notes-BE_11.BE_T_91722':
    "Garanties de tiers - Gages sur fonds de commerce - Montant de l'inscription autorisé (91722)",
  'cr.annual.report.explanatory-notes-BE_11.BE_T_91811':
    "Garanties de l'entreprise - Gages sur d'autres actifs - Valeur comptable des actifs gagés (91811)",
  'cr.annual.report.explanatory-notes-BE_11.BE_T_91812':
    "Garanties de tiers - Gages sur d'autres actifs - Valeur comptable des actifs gagés (91812)",
  'cr.annual.report.explanatory-notes-BE_11.BE_T_91821':
    "Garanties de l'entreprise - Gages sur d'autres actifs - Montant maximum (91821)",
  'cr.annual.report.explanatory-notes-BE_11.BE_T_91822':
    "Garanties de tiers - Gages sur d'autres actifs - Montant maximum (91822)",
  'cr.annual.report.explanatory-notes-BE_11.BE_T_91911':
    "Garanties de l'entreprise - Sûretés constituées sur actifs futurs - Montant des actifs en cause (91911)",
  'cr.annual.report.explanatory-notes-BE_11.BE_T_91912':
    'Garanties de tiers - Sûretés constituées sur actifs futurs - Montant des actifs en cause (91912)',
  'cr.annual.report.explanatory-notes-BE_11.BE_T_91921':
    "Garanties de l'entreprise - Sûretés constituées sur actifs futurs - Montant maximum (91921)",
  'cr.annual.report.explanatory-notes-BE_11.BE_T_91922':
    'Garanties de tiers - Sûretés constituées sur actifs futurs - Montant maximum (91922)',
  'cr.annual.report.explanatory-notes-BE_11.BE_T_92011':
    "Garanties de l'entreprise - Privilege du vendeur - Valeur comptable du bien vendu (92011)",
  'cr.annual.report.explanatory-notes-BE_11.BE_T_92012':
    'Garanties de tiers - Privilege du vendeur - Valeur comptable du bien vendu (92012)',
  'cr.annual.report.explanatory-notes-BE_11.BE_T_92021':
    "Garanties de l'entreprise - Privilege du vendeur - Montant du prix non payé (92021)",
  'cr.annual.report.explanatory-notes-BE_11.BE_T_92022':
    'Garanties de tiers - Privilege du vendeur - Montant du prix non payé (92022)',
  'cr.annual.report.explanatory-notes-BE_11.BE_T_9500':
    'Créances sur les personnes précitées (9500)',
  'cr.annual.report.explanatory-notes-BE_11.BE_T_9501':
    'Garanties constituées en leur faveur (9501)',
  'cr.annual.report.explanatory-notes-BE_11.BE_T_9502':
    'Autres engagements significatifs souscrits en leur faveur (9502)',
  'cr.annual.report.explanatory-notes-BE_11.business': 'Garanties réelles',
  'cr.annual.report.explanatory-notes-BE_11.general': 'Général',
  'cr.annual.report.explanatory-notes-BE_12_21.BE_T_9150':
    "Effets de commerce en circulation endossés par de l'association ou fondation (9150)",
  'cr.annual.report.explanatory-notes-BE_12_21.BE_T_91611':
    'Valeur comptable des immeubles grevés (91611)',
  'cr.annual.report.explanatory-notes-BE_12_21.BE_T_91621': "Montant de l'inscription (91621)",
  'cr.annual.report.explanatory-notes-BE_12_21.BE_T_91711':
    "Le montant maximum à concurrence duquel la dette est garantie et qui fait l’objet de l'enregistrement (91711)",
  'cr.annual.report.explanatory-notes-BE_12_21.BE_T_91721':
    'Pour les mandats irrévocables de mise en gage du fonds de commerce, le montant pour lequel le mandataire est autorisé à procéder à l’enregistrement en vertu du mandat (91721)',
  'cr.annual.report.explanatory-notes-BE_12_21.BE_T_91811':
    'La valeur comptable des actifs grevés (91811)',
  'cr.annual.report.explanatory-notes-BE_12_21.BE_T_91821':
    'Le montant maximum à concurrence duquel la dette est garantie (91821)',
  'cr.annual.report.explanatory-notes-BE_12_21.BE_T_91911':
    'Le montant des actifs en cause (91911)',
  'cr.annual.report.explanatory-notes-BE_12_21.BE_T_91921':
    'Le montant maximum à concurrence duquel la dette est garantie (91921)',
  'cr.annual.report.explanatory-notes-BE_12_21.BE_T_92011':
    'La valeur comptable du bien vendu (92011)',
  'cr.annual.report.explanatory-notes-BE_12_21.BE_T_92021': 'Le montant du prix non payé (92021)',
  'cr.annual.report.explanatory-notes-BE_12_21.BE_T_9500':
    'Créances sur les personnes précitées (9500)',
  'cr.annual.report.explanatory-notes-BE_12_21.BE_T_9501':
    'Garanties constituées en leur faveur (9501)',
  'cr.annual.report.explanatory-notes-BE_12_21.BE_T_9502':
    'Autres engagements significatifs souscrits en leur faveur (9502)',
  'cr.annual.report.explanatory-notes-BE_12_21.business': 'Garanties réelles',
  'cr.annual.report.explanatory-notes-BE_12_21.general': 'Général',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_635':
    'Provisions pour pensions et obligations similaires (635)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_6358':
    'Provisions pour risques et charges: dotations (6358)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_640':
    'Autres charges d’exploitation: impôts et taxes relatifs à l’exploitation (640)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_6418':
    'Autres charges d’exploitation : autres (6418)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_653':
    "Autres charges financières: montant de l'escompte à charge de l'entreprise sur la négociation de créances (653)",
  'cr.annual.report.explanatory-notes-BE_2.BE_T_9072': 'Dettes fiscales échues (9072)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_9076':
    "Dettes échues envers l'Office National de Sécurité Sociale (9076)",
  'cr.annual.report.explanatory-notes-BE_2.BE_T_9145':
    "Taxes sur la valeur ajoutée, portées en compte à l'entreprise (déductibles) (9145)",
  'cr.annual.report.explanatory-notes-BE_2.BE_T_9146':
    'Taxes sur la valeur ajoutée, portées en compte par l’entreprise (9146)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_9150':
    "Effets de commerce en circulation endossés par l'entreprise (9150)",
  'cr.annual.report.explanatory-notes-BE_2.BE_T_9161':
    "Garanties de l'entreprise - Hypothèques - Valeur comptable des immeubles grevés (9161)",
  'cr.annual.report.explanatory-notes-BE_2.BE_T_91611':
    "Garanties de l'entreprise - Hypothèques - Valeur comptable des immeubles grevés (91611)",
  'cr.annual.report.explanatory-notes-BE_2.BE_T_91612':
    'Garanties de tiers - Hypothèques - Valeur comptable des immeubles grevés (91612)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_9162':
    'Garanties de tiers - Hypothèques - Valeur comptable des immeubles grevés (9162)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_91621':
    "Garanties de l'entreprise - Hypothèques - Montant de l'inscription (91621)",
  'cr.annual.report.explanatory-notes-BE_2.BE_T_91622':
    "Garanties de tiers - Hypothèques - Montant de l'inscription (91622)",
  'cr.annual.report.explanatory-notes-BE_2.BE_T_91631':
    "Garanties de l'entreprise - Hypothèques - Montant de l'inscription autorisé (91631)",
  'cr.annual.report.explanatory-notes-BE_2.BE_T_91632':
    "Garanties de triers - Hypothèques - Montant de l'inscription autorisé (91632)",
  'cr.annual.report.explanatory-notes-BE_2.BE_T_9171':
    "Garanties de l'entreprise - Hypothèques - Montant de l'inscription (9171)",
  'cr.annual.report.explanatory-notes-BE_2.BE_T_91711':
    "Garanties de l'entreprise - Gages sur fonds de commerce - Montant de l'inscription (91711)",
  'cr.annual.report.explanatory-notes-BE_2.BE_T_91712':
    "Garanties de tiers - Gages sur fonds de commerce - Montant de l'inscription (91712)",
  'cr.annual.report.explanatory-notes-BE_2.BE_T_9172':
    "Garanties de tiers - Hypothèques - Montant de l'inscription (9172)",
  'cr.annual.report.explanatory-notes-BE_2.BE_T_91721':
    "Garanties de l'entreprise - Gages sur fonds de commerce - Montant de l'inscription autorisé (91721)",
  'cr.annual.report.explanatory-notes-BE_2.BE_T_91722':
    "Garanties de tiers - Gages sur fonds de commerce - Montant de l'inscription autorisé (91722)",
  'cr.annual.report.explanatory-notes-BE_2.BE_T_9181':
    "Garanties de l'entreprise - Gages sur fonds de commerce - Montant de l'inscription (9181)",
  'cr.annual.report.explanatory-notes-BE_2.BE_T_91811':
    "Garanties de l'entreprise - Gages sur d'autres actifs - Valeur comptable des actifs gagés (91811)",
  'cr.annual.report.explanatory-notes-BE_2.BE_T_91812':
    "Garanties de tiers - Gages sur d'autres actifs - Valeur comptable des actifs gagés (91812)",
  'cr.annual.report.explanatory-notes-BE_2.BE_T_9182':
    "Garanties de tiers - Gages sur fonds de commerce - Montant de l'inscription (9182)",
  'cr.annual.report.explanatory-notes-BE_2.BE_T_91821':
    "Garanties de l'entreprise - Gages sur d'autres actifs - Montant maximum (91821)",
  'cr.annual.report.explanatory-notes-BE_2.BE_T_91822':
    "Garanties de tiers - Gages sur d'autres actifs - Montant maximum (91822)",
  'cr.annual.report.explanatory-notes-BE_2.BE_T_9191':
    "Garanties de l'entreprise - Gages sur d'autres actifs - Valeur comptable des actifs gagés (9191)",
  'cr.annual.report.explanatory-notes-BE_2.BE_T_91911':
    "Garanties de l'entreprise - Sûretés constituées sur actifs futurs - Montant des actifs en cause (91911)",
  'cr.annual.report.explanatory-notes-BE_2.BE_T_91912':
    'Garanties de tiers - Sûretés constituées sur actifs futurs - Montant des actifs en cause (91912)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_9192':
    "Garanties de tiers - Gages sur d'autres actifs - Valeur comptable des actifs gagés (9192)",
  'cr.annual.report.explanatory-notes-BE_2.BE_T_91921':
    "Garanties de l'entreprise - Sûretés constituées sur actifs futurs - Montant maximum (91921)",
  'cr.annual.report.explanatory-notes-BE_2.BE_T_91922':
    'Garanties de tiers - Sûretés constituées sur actifs futurs - Montant maximum (91922)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_9201':
    "Garanties de l'entreprise - Sûretés constituées sur actifs futurs - Montant des actifs en cause (9201)",
  'cr.annual.report.explanatory-notes-BE_2.BE_T_92011':
    "Garanties de l'entreprise - Privilege du vendeur - Valeur comptable du bien vendu (92011)",
  'cr.annual.report.explanatory-notes-BE_2.BE_T_92012':
    'Garanties de tiers - Privilege du vendeur - Valeur comptable du bien vendu (92012)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_9202':
    'Garanties de tiers - Sûretés constituées sur actifs futurs - Montant des actifs en cause (9202)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_92021':
    "Garanties de l'entreprise - Privilege du vendeur - Montant du prix non payé (92021)",
  'cr.annual.report.explanatory-notes-BE_2.BE_T_92022':
    'Garanties de tiers - Privilege du vendeur - Montant du prix non payé (92022)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_9500':
    'Créances sur les personnes précitées (9500)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_9501':
    'Garanties constituées en leur faveur (9501)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_9502':
    'Autres engagements significatifs souscrits en leur faveur (9502)',
  'cr.annual.report.explanatory-notes-BE_2.business': 'Garanties réelles',
  'cr.annual.report.explanatory-notes-BE_2.general': 'Général',
  'cr.annual.report.explanatory-notes-BE_4.BE_T_6358':
    'Provisions pour risques et charges: dotations (6358)',
  'cr.annual.report.explanatory-notes-BE_4.BE_T_6408': "Autres charges d'exploitation (6408)",
  'cr.annual.report.explanatory-notes-BE_4.BE_T_9072': 'Dettes fiscales échues (9072)',
  'cr.annual.report.explanatory-notes-BE_4.BE_T_9076':
    "Dettes échues envers l'Office National de Sécurité Sociale (9076)",
  'cr.annual.report.explanatory-notes-BE_4.BE_T_9150':
    "Effets de commerce en circulation endossés par l'entreprise (9150)",
  'cr.annual.report.explanatory-notes-BE_4.BE_T_9161':
    "Garanties de l'entreprise - Hypothèques - Valeur comptable des immeubles grevés (9161)",
  'cr.annual.report.explanatory-notes-BE_4.BE_T_91611':
    "Garanties de l'entreprise - Hypothèques - Valeur comptable des immeubles grevés (91611)",
  'cr.annual.report.explanatory-notes-BE_4.BE_T_91612':
    'Garanties de tiers - Hypothèques - Valeur comptable des immeubles grevés (91612)',
  'cr.annual.report.explanatory-notes-BE_4.BE_T_9162':
    'Garanties de tiers - Hypothèques - Valeur comptable des immeubles grevés (9162)',
  'cr.annual.report.explanatory-notes-BE_4.BE_T_91621':
    "Garanties de l'entreprise - Hypothèques - Montant de l'inscription (91621)",
  'cr.annual.report.explanatory-notes-BE_4.BE_T_91622':
    "Garanties de tiers - Hypothèques - Montant de l'inscription (91622)",
  'cr.annual.report.explanatory-notes-BE_4.BE_T_91631':
    "Garanties de l'entreprise - Hypothèques - Montant de l'inscription autorisé (91631)",
  'cr.annual.report.explanatory-notes-BE_4.BE_T_91632':
    "Garanties de triers - Hypothèques - Montant de l'inscription autorisé (91632)",
  'cr.annual.report.explanatory-notes-BE_4.BE_T_9171':
    "Garanties de l'entreprise - Hypothèques - Montant de l'inscription (9171)",
  'cr.annual.report.explanatory-notes-BE_4.BE_T_91711':
    "Garanties de l'entreprise - Gages sur fonds de commerce - Montant de l'inscription (91711)",
  'cr.annual.report.explanatory-notes-BE_4.BE_T_91712':
    "Garanties de tiers - Gages sur fonds de commerce - Montant de l'inscription (91712)",
  'cr.annual.report.explanatory-notes-BE_4.BE_T_9172':
    "Garanties de tiers - Hypothèques - Montant de l'inscription (9172)",
  'cr.annual.report.explanatory-notes-BE_4.BE_T_91721':
    "Garanties de l'entreprise - Gages sur fonds de commerce - Montant de l'inscription autorisé (91721)",
  'cr.annual.report.explanatory-notes-BE_4.BE_T_91722':
    "Garanties de tiers - Gages sur fonds de commerce - Montant de l'inscription autorisé (91722)",
  'cr.annual.report.explanatory-notes-BE_4.BE_T_9181':
    "Garanties de l'entreprise - Gages sur fonds de commerce - Montant de l'inscription (9181)",
  'cr.annual.report.explanatory-notes-BE_4.BE_T_91811':
    "Garanties de l'entreprise - Gages sur d'autres actifs - Valeur comptable des actifs gagés (91811)",
  'cr.annual.report.explanatory-notes-BE_4.BE_T_91812':
    "Garanties de tiers - Gages sur d'autres actifs - Valeur comptable des actifs gagés (91812)",
  'cr.annual.report.explanatory-notes-BE_4.BE_T_9182':
    "Garanties de tiers - Gages sur fonds de commerce - Montant de l'inscription (9182)",
  'cr.annual.report.explanatory-notes-BE_4.BE_T_91821':
    "Garanties de l'entreprise - Gages sur d'autres actifs - Montant maximum (91821)",
  'cr.annual.report.explanatory-notes-BE_4.BE_T_91822':
    "Garanties de tiers - Gages sur d'autres actifs - Montant maximum (91822)",
  'cr.annual.report.explanatory-notes-BE_4.BE_T_9191':
    "Garanties de l'entreprise - Gages sur d'autres actifs - Valeur comptable des actifs gagés (9191)",
  'cr.annual.report.explanatory-notes-BE_4.BE_T_91911':
    "Garanties de l'entreprise - Sûretés constituées sur actifs futurs - Montant des actifs en cause (91911)",
  'cr.annual.report.explanatory-notes-BE_4.BE_T_91912':
    'Garanties de tiers - Sûretés constituées sur actifs futurs - Montant des actifs en cause (91912)',
  'cr.annual.report.explanatory-notes-BE_4.BE_T_9192':
    "Garanties de tiers - Gages sur d'autres actifs - Valeur comptable des actifs gagés (9192)",
  'cr.annual.report.explanatory-notes-BE_4.BE_T_91921':
    "Garanties de l'entreprise - Sûretés constituées sur actifs futurs - Montant maximum (91921)",
  'cr.annual.report.explanatory-notes-BE_4.BE_T_91922':
    'Garanties de tiers - Sûretés constituées sur actifs futurs - Montant maximum (91922)',
  'cr.annual.report.explanatory-notes-BE_4.BE_T_9201':
    "Garanties de l'entreprise - Sûretés constituées sur actifs futurs - Montant des actifs en cause (9201)",
  'cr.annual.report.explanatory-notes-BE_4.BE_T_92011':
    "Garanties de l'entreprise - Privilege du vendeur - Valeur comptable du bien vendu (92011)",
  'cr.annual.report.explanatory-notes-BE_4.BE_T_92012':
    'Garanties de tiers - Privilege du vendeur - Valeur comptable du bien vendu (92012)',
  'cr.annual.report.explanatory-notes-BE_4.BE_T_9202':
    'Garanties de tiers - Sûretés constituées sur actifs futurs - Montant des actifs en cause (9202)',
  'cr.annual.report.explanatory-notes-BE_4.BE_T_92021':
    "Garanties de l'entreprise - Privilege du vendeur - Montant du prix non payé (92021)",
  'cr.annual.report.explanatory-notes-BE_4.BE_T_92022':
    'Garanties de tiers - Privilege du vendeur - Montant du prix non payé (92022)',
  'cr.annual.report.explanatory-notes-BE_4.BE_T_9500':
    'Créances sur les personnes précitées (9500)',
  'cr.annual.report.explanatory-notes-BE_4.BE_T_9501':
    'Garanties constituées en leur faveur (9501)',
  'cr.annual.report.explanatory-notes-BE_4.BE_T_9502':
    'Autres engagements significatifs souscrits en leur faveur (9502)',
  'cr.annual.report.explanatory-notes-BE_4.business': 'Garanties réelles',
  'cr.annual.report.explanatory-notes-BE_4.general': 'Général',
  'cr.annual.report.explanatory-notes-BE_5.BE_T_6358':
    'Provisions pour risques et charges: dotations (6358)',
  'cr.annual.report.explanatory-notes-BE_5.BE_T_6408': "Autres charges d'exploitation (6408)",
  'cr.annual.report.explanatory-notes-BE_5.BE_T_653':
    "Autres charges financières: montant de l'escompte à charge de l'association ou de la fondation sur la négociation de créances (653)",
  'cr.annual.report.explanatory-notes-BE_5.BE_T_73': 'Cotisations, dons, legs et subsides (73)',
  'cr.annual.report.explanatory-notes-BE_5.BE_T_9072': 'Dettes fiscales échues (9072)',
  'cr.annual.report.explanatory-notes-BE_5.BE_T_9076':
    "Dettes échues envers l'Office National de Sécurité Sociale (9076)",
  'cr.annual.report.explanatory-notes-BE_5.BE_T_9150':
    "Effets de commerce en circulation endossés par l'association ou la fondation (9150)",
  'cr.annual.report.explanatory-notes-BE_5.BE_T_9161':
    "Garanties de l'entreprise - Hypothèques - Valeur comptable des immeubles grevés (9161)",
  'cr.annual.report.explanatory-notes-BE_5.BE_T_91611':
    'Valeur comptable des immeubles grevés (91611)',
  'cr.annual.report.explanatory-notes-BE_5.BE_T_9162':
    'Garanties de tiers - Hypothèques - Valeur comptable des immeubles grevés (9162)',
  'cr.annual.report.explanatory-notes-BE_5.BE_T_91621': "Montant de l'inscription (91621)",
  'cr.annual.report.explanatory-notes-BE_5.BE_T_9171':
    "Garanties de l'entreprise - Hypothèques - Montant de l'inscription (9171)",
  'cr.annual.report.explanatory-notes-BE_5.BE_T_91711':
    "Le montant maximum à concurrence duquel la dette est garantie et qui fait l’objet de l'enregistrement (91711)",
  'cr.annual.report.explanatory-notes-BE_5.BE_T_9172':
    "Garanties de tiers - Hypothèques - Montant de l'inscription (9172)",
  'cr.annual.report.explanatory-notes-BE_5.BE_T_91721':
    'Pour les mandats irrévocables de mise en gage du fonds de commerce, le montant pour lequel le mandataire est autorisé à procéder à l’enregistrement en vertu du mandat (91721)',
  'cr.annual.report.explanatory-notes-BE_5.BE_T_9181':
    "Garanties de l'entreprise - Gages sur fonds de commerce - Montant de l'inscription (9181)",
  'cr.annual.report.explanatory-notes-BE_5.BE_T_91811':
    'La valeur comptable des actifs grevés (91811)',
  'cr.annual.report.explanatory-notes-BE_5.BE_T_9182':
    "Garanties de tiers - Gages sur fonds de commerce - Montant de l'inscription (9182)",
  'cr.annual.report.explanatory-notes-BE_5.BE_T_91821':
    'Le montant maximum à concurrence duquel la dette est garantie (91821)',
  'cr.annual.report.explanatory-notes-BE_5.BE_T_9191':
    "Garanties de l'entreprise - Gages sur d'autres actifs - Valeur comptable des actifs gagés (9191)",
  'cr.annual.report.explanatory-notes-BE_5.BE_T_91911': 'Le montant des actifs en cause (91911)',
  'cr.annual.report.explanatory-notes-BE_5.BE_T_9192':
    "Garanties de tiers - Gages sur d'autres actifs - Valeur comptable des actifs gagés (9192)",
  'cr.annual.report.explanatory-notes-BE_5.BE_T_91921':
    'Le montant maximum à concurrence duquel la dette est garantie (91921)',
  'cr.annual.report.explanatory-notes-BE_5.BE_T_9201':
    "Garanties de l'entreprise - Sûretés constituées sur actifs futurs - Montant des actifs en cause (9201)",
  'cr.annual.report.explanatory-notes-BE_5.BE_T_92011': 'La valeur comptable du bien vendu (92011)',
  'cr.annual.report.explanatory-notes-BE_5.BE_T_9202':
    'Garanties de tiers - Sûretés constituées sur actifs futurs - Montant des actifs en cause (9202)',
  'cr.annual.report.explanatory-notes-BE_5.BE_T_92021': 'Le montant du prix non payé (92021)',
  'cr.annual.report.explanatory-notes-BE_5.BE_T_9500':
    'Créances sur les personnes précitées (9500)',
  'cr.annual.report.explanatory-notes-BE_5.BE_T_9501':
    'Garanties constituées en leur faveur (9501)',
  'cr.annual.report.explanatory-notes-BE_5.BE_T_9502':
    'Autres engagements significatifs souscrits en leur faveur (9502)',
  'cr.annual.report.explanatory-notes-BE_5.business': 'Garanties réelles',
  'cr.annual.report.explanatory-notes-BE_5.general': 'Général',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_635':
    'Provisions pour pensions et obligations similaires - dotations (635)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_6358':
    'Provisions pour risques et charges: dotations (6358)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_640':
    "Impôts et taxes relatifs à l'exploitation (640)",
  'cr.annual.report.explanatory-notes-BE_6.BE_T_6408': "Autres charges d'exploitation (6408)",
  'cr.annual.report.explanatory-notes-BE_6.BE_T_6418':
    'Autres charges d’exploitation : autres (6418)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_653':
    "Autres charges financières: montant de l'escompte à charge de l'association ou de la fondation sur la négociation de créances (653)",
  'cr.annual.report.explanatory-notes-BE_6.BE_T_730': 'Cotisations (730)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_7301':
    'Cotisations, dons, legs et subsides: cotisations (7301)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_731': 'Dons (731)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_732': 'Legs (732)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_7323':
    'Cotisations, dons, legs et subsides: dons (7323)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_733': 'Subsides (733)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_7345':
    'Cotisations, dons, legs et subsides: legs (7345)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_736':
    "Subsides en capital et en intérêts, subsides d'exploitation et montants compensatoires destinés à réduire le coût salaria (736)",
  'cr.annual.report.explanatory-notes-BE_6.BE_T_7368':
    "Subsides en capital et en intérêts, subsides d'exploitation et montants compensatoires destinés à réduire le coût salarial (7368)",
  'cr.annual.report.explanatory-notes-BE_6.BE_T_9072': 'Dettes fiscales échues (9072)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_9076':
    "Dettes échues envers l'Office National de Sécurité Sociale (9076)",
  'cr.annual.report.explanatory-notes-BE_6.BE_T_9145':
    "Taxes sur la valeur ajoutée, portées en compte à l'association ou à la fondation (déductibles) (9145)",
  'cr.annual.report.explanatory-notes-BE_6.BE_T_9146':
    "Taxes sur la valeur ajoutée, portées en compte par l'association ou la fondation (9146)",
  'cr.annual.report.explanatory-notes-BE_6.BE_T_9150':
    "Effets de commerce en circulation endossés par l'association ou la fondation (9150)",
  'cr.annual.report.explanatory-notes-BE_6.BE_T_9161':
    "Garanties de l'entreprise - Hypothèques - Valeur comptable des immeubles grevés (9161)",
  'cr.annual.report.explanatory-notes-BE_6.BE_T_91611':
    'Valeur comptable des immeubles grevés (91611)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_9162':
    'Garanties de tiers - Hypothèques - Valeur comptable des immeubles grevés (9162)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_91621': "Montant de l'inscription (91621)",
  'cr.annual.report.explanatory-notes-BE_6.BE_T_9171':
    "Garanties de l'entreprise - Hypothèques - Montant de l'inscription (9171)",
  'cr.annual.report.explanatory-notes-BE_6.BE_T_91711':
    "Le montant maximum à concurrence duquel la dette est garantie et qui fait l’objet de l'enregistrement (91711)",
  'cr.annual.report.explanatory-notes-BE_6.BE_T_9172':
    "Garanties de tiers - Hypothèques - Montant de l'inscription (9172)",
  'cr.annual.report.explanatory-notes-BE_6.BE_T_91721':
    'Pour les mandats irrévocables de mise en gage du fonds de commerce, le montant pour lequel le mandataire est autorisé à procéder à l’enregistrement en vertu du mandat (91721)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_9181':
    "Garanties de l'entreprise - Gages sur fonds de commerce - Montant de l'inscription (9181)",
  'cr.annual.report.explanatory-notes-BE_6.BE_T_91811':
    'La valeur comptable des actifs grevés (91811)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_9182':
    "Garanties de tiers - Gages sur fonds de commerce - Montant de l'inscription (9182)",
  'cr.annual.report.explanatory-notes-BE_6.BE_T_91821':
    'Le montant maximum à concurrence duquel la dette est garantie (91821)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_9191':
    "Garanties de l'entreprise - Gages sur d'autres actifs - Valeur comptable des actifs gagés (9191)",
  'cr.annual.report.explanatory-notes-BE_6.BE_T_91911': 'Le montant des actifs en cause (91911)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_9192':
    "Garanties de tiers - Gages sur d'autres actifs - Valeur comptable des actifs gagés (9192)",
  'cr.annual.report.explanatory-notes-BE_6.BE_T_91921':
    'Le montant maximum à concurrence duquel la dette est garantie (91921)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_9201':
    "Garanties de l'entreprise - Sûretés constituées sur actifs futurs - Montant des actifs en cause (9201)",
  'cr.annual.report.explanatory-notes-BE_6.BE_T_92011': 'La valeur comptable du bien vendu (92011)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_9202':
    'Garanties de tiers - Sûretés constituées sur actifs futurs - Montant des actifs en cause (9202)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_92021': 'Le montant du prix non payé (92021)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_9500':
    'Créances sur les personnes précitées (9500)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_9501':
    'Garanties constituées en leur faveur (9501)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_9502':
    'Autres engagements significatifs souscrits en leur faveur (9502)',
  'cr.annual.report.explanatory-notes-BE_6.business': 'Garanties réelles',
  'cr.annual.report.explanatory-notes-BE_6.general': 'Général',
  'cr.annual.report.explanatory-notes-BE_9.BE_T_635':
    'Provisions pour pensions et obligations similaires (635)',
  'cr.annual.report.explanatory-notes-BE_9.BE_T_6358':
    'Provisions pour risques et charges: dotations (6358)',
  'cr.annual.report.explanatory-notes-BE_9.BE_T_640':
    'Autres charges d’exploitation: impôts et taxes relatifs à l’exploitation (640)',
  'cr.annual.report.explanatory-notes-BE_9.BE_T_6408': "Autres charges d'exploitation (6408)",
  'cr.annual.report.explanatory-notes-BE_9.BE_T_6418':
    'Autres charges d’exploitation : autres (6418)',
  'cr.annual.report.explanatory-notes-BE_9.BE_T_653':
    "Autres charges financières: montant de l'escompte à charge de l'entreprise sur la négociation de créances (653)",
  'cr.annual.report.explanatory-notes-BE_9.BE_T_9072': 'Dettes fiscales échues (9072)',
  'cr.annual.report.explanatory-notes-BE_9.BE_T_9076':
    "Dettes échues envers l'Office National de Sécurité Sociale (9076)",
  'cr.annual.report.explanatory-notes-BE_9.BE_T_9145':
    "Taxes sur la valeur ajoutée, portées en compte à l'entreprise (déductibles) (9145)",
  'cr.annual.report.explanatory-notes-BE_9.BE_T_9146':
    'Taxes sur la valeur ajoutée, portées en compte par l’entreprise (9146)',
  'cr.annual.report.explanatory-notes-BE_9.BE_T_9150':
    "Effets de commerce en circulation endossés par l'entreprise (9150)",
  'cr.annual.report.explanatory-notes-BE_9.BE_T_91611':
    "Garanties de l'entreprise - Hypothèques - Valeur comptable des immeubles grevés (91611)",
  'cr.annual.report.explanatory-notes-BE_9.BE_T_91612':
    'Garanties de tiers - Hypothèques - Valeur comptable des immeubles grevés (91612)',
  'cr.annual.report.explanatory-notes-BE_9.BE_T_91621':
    "Garanties de l'entreprise - Hypothèques - Montant de l'inscription (91621)",
  'cr.annual.report.explanatory-notes-BE_9.BE_T_91622':
    "Garanties de tiers - Hypothèques - Montant de l'inscription (91622)",
  'cr.annual.report.explanatory-notes-BE_9.BE_T_91631':
    "Garanties de l'entreprise - Hypothèques - Montant de l'inscription autorisé (91631)",
  'cr.annual.report.explanatory-notes-BE_9.BE_T_91632':
    "Garanties de triers - Hypothèques - Montant de l'inscription autorisé (91632)",
  'cr.annual.report.explanatory-notes-BE_9.BE_T_91711':
    "Garanties de l'entreprise - Gages sur fonds de commerce - Montant de l'inscription (91711)",
  'cr.annual.report.explanatory-notes-BE_9.BE_T_91712':
    "Garanties de tiers - Gages sur fonds de commerce - Montant de l'inscription (91712)",
  'cr.annual.report.explanatory-notes-BE_9.BE_T_91721':
    "Garanties de l'entreprise - Gages sur fonds de commerce - Montant de l'inscription autorisé (91721)",
  'cr.annual.report.explanatory-notes-BE_9.BE_T_91722':
    "Garanties de tiers - Gages sur fonds de commerce - Montant de l'inscription autorisé (91722)",
  'cr.annual.report.explanatory-notes-BE_9.BE_T_91811':
    "Garanties de l'entreprise - Gages sur d'autres actifs - Valeur comptable des actifs gagés (91811)",
  'cr.annual.report.explanatory-notes-BE_9.BE_T_91812':
    "Garanties de tiers - Gages sur d'autres actifs - Valeur comptable des actifs gagés (91812)",
  'cr.annual.report.explanatory-notes-BE_9.BE_T_91821':
    "Garanties de l'entreprise - Gages sur d'autres actifs - Montant maximum (91821)",
  'cr.annual.report.explanatory-notes-BE_9.BE_T_91822':
    "Garanties de tiers - Gages sur d'autres actifs - Montant maximum (91822)",
  'cr.annual.report.explanatory-notes-BE_9.BE_T_91911':
    "Garanties de l'entreprise - Sûretés constituées sur actifs futurs - Montant des actifs en cause (91911)",
  'cr.annual.report.explanatory-notes-BE_9.BE_T_91912':
    'Garanties de tiers - Sûretés constituées sur actifs futurs - Montant des actifs en cause (91912)',
  'cr.annual.report.explanatory-notes-BE_9.BE_T_91921':
    "Garanties de l'entreprise - Sûretés constituées sur actifs futurs - Montant maximum (91921)",
  'cr.annual.report.explanatory-notes-BE_9.BE_T_91922':
    'Garanties de tiers - Sûretés constituées sur actifs futurs - Montant maximum (91922)',
  'cr.annual.report.explanatory-notes-BE_9.BE_T_92011':
    "Garanties de l'entreprise - Privilege du vendeur - Valeur comptable du bien vendu (92011)",
  'cr.annual.report.explanatory-notes-BE_9.BE_T_92012':
    'Garanties de tiers - Privilege du vendeur - Valeur comptable du bien vendu (92012)',
  'cr.annual.report.explanatory-notes-BE_9.BE_T_92021':
    "Garanties de l'entreprise - Privilege du vendeur - Montant du prix non payé (92021)",
  'cr.annual.report.explanatory-notes-BE_9.BE_T_92022':
    'Garanties de tiers - Privilege du vendeur - Montant du prix non payé (92022)',
  'cr.annual.report.explanatory-notes-BE_9.BE_T_9500':
    'Créances sur les personnes précitées (9500)',
  'cr.annual.report.explanatory-notes-BE_9.BE_T_9501':
    'Garanties constituées en leur faveur (9501)',
  'cr.annual.report.explanatory-notes-BE_9.BE_T_9502':
    'Autres engagements significatifs souscrits en leur faveur (9502)',
  'cr.annual.report.explanatory-notes-BE_9.business': 'Garanties réelles',
  'cr.annual.report.explanatory-notes-BE_9.general': 'Général',
  'cr.annual.report.explanatory-notes.after-2020':
    'Garanties réelles constituées ou irrévocablement promises par la société sur ses actifs propres pour sûreté de dettes et engagements de la société -  Modèle dès 2020',
  'cr.annual.report.explanatory-notes.before-2020':
    "Garanties réelles constituées ou irrévocablement promises par l'entreprise sur ses actifs\npropres pour sûreté de dettes et engagements de l'entreprise - Hypothèques - Modéle avant 2020",
  'cr.annual.report.explanatory-notes.tooltip.BE':
    'Vous trouverez en annexe les explications quant aux règles d’évaluation utilisées pour obtenir certains chiffres du bilan et du compte de résultats. Ces règles d’évaluation comportent des informations importantes. Il est, par exemple, intéressant de déterminer comment une entreprise gère les amortissements et les évaluations de stock. Chaque forme d’évaluation donne une image totalement différente et a des implications financières.',
  'cr.annual.report.key-figures.accountsTotal': 'Capital',
  'cr.annual.report.key-figures.accountsTotal-BE': 'Total du compte',
  'cr.annual.report.key-figures.acidTestRatio': 'Ratio de liquidité',
  'cr.annual.report.key-figures.averageDaysClientsCredit-BE':
    'Nombre moyen de jours d’encaissement clients',
  'cr.annual.report.key-figures.averageDaysSuppliersCredit-BE':
    'Nombre moyen de jours de crédit fournisseur',
  'cr.annual.report.key-figures.balans': 'Bilan comptable',
  'cr.annual.report.key-figures.borrowingRatio': "Rapport d'emprunt",
  'cr.annual.report.key-figures.capital': 'Capital',
  'cr.annual.report.key-figures.cash': 'Liquidités',
  'cr.annual.report.key-figures.cashFlow': 'Flux de trésorerie',
  'cr.annual.report.key-figures.cashFlowRatio': 'Flux de trésorerie',
  'cr.annual.report.key-figures.creditorDays': 'Créanciers jours',
  'cr.annual.report.key-figures.currentRatio': 'Ratio courant',
  'cr.annual.report.key-figures.debtsFallenDuetowardsRSZ-BE': 'Dettes envers l’ONSS',
  'cr.annual.report.key-figures.debtsFallenDuetowardsTax-BE': 'Dettes fiscales impayées',
  'cr.annual.report.key-figures.equity': 'Total des fonds propres',
  'cr.annual.report.key-figures.equityCapital': 'Fonds propres',
  'cr.annual.report.key-figures.financial-ratios': 'Ratios financiers',
  'cr.annual.report.key-figures.generalDebtRate-BE': 'Taux d’endettement general en %',
  'cr.annual.report.key-figures.grantsAndContributionsAutonomy-BE':
    'Subventions et contributions d’autonomie en %',
  'cr.annual.report.key-figures.grossSalesMargin-BE': 'Marge commerciale brute en %',
  'cr.annual.report.key-figures.income-statement': 'Compte de résultats',
  'cr.annual.report.key-figures.investments-BE': 'Investissements',
  'cr.annual.report.key-figures.netSalesMargin-BE': 'Marge commerciale nette en %',
  'cr.annual.report.key-figures.operationResult-BE': 'Résultat opérationnel',
  'cr.annual.report.key-figures.preTaxProfitAndLoss': 'Pré - Impôt Bénéfice / perte',
  'cr.annual.report.key-figures.preTaxProfitMargin': 'Avant impôt Marge bénéficiaire%',
  'cr.annual.report.key-figures.quickRatio': 'Ratio de liquidité',
  'cr.annual.report.key-figures.quickRatio-BE': 'Ratio de liquidité',
  'cr.annual.report.key-figures.ratios.description':
    'Les chiffres suivants sont présentés sous forme de ratios',
  'cr.annual.report.key-figures.result': "Résultat de l'exercice annuel",
  'cr.annual.report.key-figures.result-BE': "Résultat de l'exercice annuel",
  'cr.annual.report.key-figures.returnOfCapital': 'Remboursement de capital%',
  'cr.annual.report.key-figures.shareholderFunds': 'Fonds des actionnaires',
  'cr.annual.report.key-figures.solvencyRatio': 'Ratio de solvabilité',
  'cr.annual.report.key-figures.sub-header': 'Devise de base et unité',
  'cr.annual.report.key-figures.tangibleNetWorth': 'Valeur corporelle nette',
  'cr.annual.report.key-figures.tooltip':
    'Ci-contre, un résumé des chiffres les plus importants des bilans comptables',
  'cr.annual.report.key-figures.tooltip.BE':
    'Vous trouverez ici un aperçu des chiffres les plus importants des 5 derniers comptes annuels ainsi que plusieurs ratios financiers.',
  'cr.annual.report.key-figures.tooltip.GB':
    'Vous trouverez ici un résumé des chiffres clés de ces états financiers.',
  'cr.annual.report.key-figures.tooltip.NL':
    'Vous trouverez ici un résumé des chiffres clés les plus importants de ces comptes annuels.',
  'cr.annual.report.key-figures.totalAssets': "Total de l'actif",
  'cr.annual.report.key-figures.turnover': "Chiffre d'affaires",
  'cr.annual.report.key-figures.workingCapital': 'Fonds de roulement',
  'cr.annual.report.key-figures.workingCapital-BE': 'Fonds de roulement net',
  'cr.annual.report.key-figures.workingCapitalRatio': 'Ratio du fonds de roulement',
  'cr.annual.report.profit-and-loss.appropriation': 'Affectations et Prélèvements',
  'cr.annual.report.profit-and-loss.BE_B_LBL_E_14': 'Bénéfice (Perte) à reporter (14)',
  'cr.annual.report.profit-and-loss.BE_P_RES_6064': "Charges d'exploitation (6064)",
  'cr.annual.report.profit-and-loss.BE_P_RES_6066A': 'Coût des ventes et des prestations (6066A)',
  'cr.annual.report.profit-and-loss.BE_P_RES_6566B': 'Charges financières (6566B)',
  'cr.annual.report.profit-and-loss.BE_P_RES_66': 'Charges non récurrentes (66)',
  'cr.annual.report.profit-and-loss.BE_P_RES_6777': 'Impôts sur le résultat (6777)',
  'cr.annual.report.profit-and-loss.BE_P_RES_680': 'Transfert aux impôts différés (680)',
  'cr.annual.report.profit-and-loss.BE_P_RES_689': 'Transfert aux réserves immunisées (689)',
  'cr.annual.report.profit-and-loss.BE_P_RES_7074': "Résultat d'exploitation (7074)",
  'cr.annual.report.profit-and-loss.BE_P_RES_7076A': 'Ventes et prestations (7076A)',
  'cr.annual.report.profit-and-loss.BE_P_RES_7576B': 'Produits financiers (7576B)',
  'cr.annual.report.profit-and-loss.BE_P_RES_76': 'Produits non récurrents (76)',
  'cr.annual.report.profit-and-loss.BE_P_RES_780': 'Prélèvements sur les impôts différés (780)',
  'cr.annual.report.profit-and-loss.BE_P_RES_789': 'Prélèvements sur les réserves immunisées (789)',
  'cr.annual.report.profit-and-loss.BE_P_RES_9901': "Bénéfice (Perte) d'exploitation (9901)",
  'cr.annual.report.profit-and-loss.BE_P_RES_9902':
    'Bénéfice (Perte) des opérations courantes (9902)',
  'cr.annual.report.profit-and-loss.BE_P_RES_9903':
    'Bénéfice (Perte) de l’exercice avant impôts (9903)',
  'cr.annual.report.profit-and-loss.BE_P_RES_9904': 'Bénéfice (Perte) de l’exercice (9904)',
  'cr.annual.report.profit-and-loss.BE_P_RES_9905':
    'Bénéfice (Perte) de l’exercice à affecter (+)/(-) (9905)',
  'cr.annual.report.profit-and-loss.BE_P_RES_C_60': 'Approvisionnements et marchandises (60)',
  'cr.annual.report.profit-and-loss.BE_P_RES_C_61': 'Services et biens divers (61)',
  'cr.annual.report.profit-and-loss.BE_P_RES_C_62':
    'Rémunérations, charges sociales et pensions (62)',
  'cr.annual.report.profit-and-loss.BE_P_RES_C_630':
    "Amortissements et réductions de valeur sur frais d'établissement, sur immobilisations incorporelles et corporelles (630)",
  'cr.annual.report.profit-and-loss.BE_P_RES_C_6314':
    "Réductions de valeur sur stocks, sur commandes en cours d'exécution et sur créances commerciales: dotations (reprises) . (6314)",
  'cr.annual.report.profit-and-loss.BE_P_RES_C_6358':
    'Provisions pour risques et charges: dotations (utilisations et reprises) (6358)',
  'cr.annual.report.profit-and-loss.BE_P_RES_C_6408': "Autres charges d'exploitation (6408)",
  'cr.annual.report.profit-and-loss.BE_P_RES_C_649':
    "Frais d'exploitation portées à l'actif au titre de frais d'exploitation (649)",
  'cr.annual.report.profit-and-loss.BE_P_RES_C_66A': "Charges d'exploitation non récurrents (66A)",
  'cr.annual.report.profit-and-loss.BE_P_RES_CTR_6008': 'Achats (6008)',
  'cr.annual.report.profit-and-loss.BE_P_RES_CTR_609': 'Stocks: réduction (augmentation) (609)',
  'cr.annual.report.profit-and-loss.BE_P_RES_E_660':
    "Amortissements et réductions de valeur non récurrents sur frais d'établissement, sur immobilisations incorporelles et corporelles (660)",
  'cr.annual.report.profit-and-loss.BE_P_RES_E_661':
    'Réductions de valeur sur immobilisations financières (661)',
  'cr.annual.report.profit-and-loss.BE_P_RES_E_662':
    'Provisions pour risques et charges exceptionnels: dotations (utilisations) (662)',
  'cr.annual.report.profit-and-loss.BE_P_RES_E_663':
    'Moins-values sur réalisation d’immobilisations (663)',
  'cr.annual.report.profit-and-loss.BE_P_RES_E_6648': 'Autres charges non récurrentes (6648)',
  'cr.annual.report.profit-and-loss.BE_P_RES_E_669':
    "Charges non récurrentes portées à l'actif au titre de frais de restructuration (669)",
  'cr.annual.report.profit-and-loss.BE_P_RES_E_760':
    "Reprises d'amortissements et de réductions de valeur sur\nimmobilisations incorporelles et corporelles (760)",
  'cr.annual.report.profit-and-loss.BE_P_RES_E_761':
    'Reprises de réductions de valeur sur immobilisations\nfinancières (761)',
  'cr.annual.report.profit-and-loss.BE_P_RES_E_762':
    'Reprise de provisions pour risques et charges exceptionnels (762)',
  'cr.annual.report.profit-and-loss.BE_P_RES_E_763':
    'Plus-values sur réalisation d’immobilisations (763)',
  'cr.annual.report.profit-and-loss.BE_P_RES_E_7649': 'Autres produits non récurrents (7649)',
  'cr.annual.report.profit-and-loss.BE_P_RES_FC_65': 'Charges financières récurrentes (65)',
  'cr.annual.report.profit-and-loss.BE_P_RES_FC_65.COMPANY': 'Charges financières récurrentes (65)',
  'cr.annual.report.profit-and-loss.BE_P_RES_FC_65.NON-PROFIT': 'Charges financières (65)',
  'cr.annual.report.profit-and-loss.BE_P_RES_FC_66B': 'Charges financières non récurrentes (66B)',
  'cr.annual.report.profit-and-loss.BE_P_RES_FCR_650': 'Charges des dettes (650)',
  'cr.annual.report.profit-and-loss.BE_P_RES_FCR_651':
    'Réductions de valeur sur actifs circulants autres que stocks, commandes en cours et créances commerciales: dotations (reprises) (651)',
  'cr.annual.report.profit-and-loss.BE_P_RES_FCR_6529': 'Autres charges financières (6529)',
  'cr.annual.report.profit-and-loss.BE_P_RES_FR_75': 'Produits financiers récurrents (75)',
  'cr.annual.report.profit-and-loss.BE_P_RES_FR_75.COMPANY': 'Produits financiers récurrents (75)',
  'cr.annual.report.profit-and-loss.BE_P_RES_FR_75.NON-PROFIT': 'Produits financiers (75)',
  'cr.annual.report.profit-and-loss.BE_P_RES_FR_76B': 'Produits financiers non récurrents (76B)',
  'cr.annual.report.profit-and-loss.BE_P_RES_FRR_750':
    'Produits des immobilisations financières (750)',
  'cr.annual.report.profit-and-loss.BE_P_RES_FRR_751': 'Produits des actifs circulants (751)',
  'cr.annual.report.profit-and-loss.BE_P_RES_FRR_7529': 'Autres produits financiers (7529)',
  'cr.annual.report.profit-and-loss.BE_P_RES_FRR_753':
    'Dont: subsides en capital et en intérêts (753)',
  'cr.annual.report.profit-and-loss.BE_P_RES_R_70': "Chiffre d'affaires (70)",
  'cr.annual.report.profit-and-loss.BE_P_RES_R_71':
    "En-cours de fabrication, produits finis et commandes en cours d'exécution: augmentation (réduction) (71)",
  'cr.annual.report.profit-and-loss.BE_P_RES_R_72': 'Production immobilisée (72)',
  'cr.annual.report.profit-and-loss.BE_P_RES_R_74': "Autres produits d'exploitation (74)",
  'cr.annual.report.profit-and-loss.BE_P_RES_R_76A':
    'Dont: produits d’exploitation non récurrents (76A)',
  'cr.annual.report.profit-and-loss.BE_P_RES_R_9900': 'Marge brute (9900)',
  'cr.annual.report.profit-and-loss.BE_P_RES_RG_6061':
    'Approvisionnements et marchandises, services et biens divers (6061)',
  'cr.annual.report.profit-and-loss.BE_P_RES_RGR_73': 'Cotisations, dons, legs et subsides (73)',
  'cr.annual.report.profit-and-loss.BE_P_RES_T_6703': 'Impôts (6703)',
  'cr.annual.report.profit-and-loss.BE_P_RES_T_77':
    'Régularisations d’impôts et reprises de provisions fiscales (77)',
  'cr.annual.report.profit-and-loss.BE_R_6359':
    'Provisions pour risques et charges: dotations (utilisations et reprises) (6359)',
  'cr.annual.report.profit-and-loss.BE_RV_14': 'Bénéfice (Perte) à reporter (14)',
  'cr.annual.report.profit-and-loss.BE_RV_14P':
    "Bénéfice (Perte) reporté(e) de l'exercice précédent (14P)",
  'cr.annual.report.profit-and-loss.BE_RV_691': 'à l’apport (691)',
  'cr.annual.report.profit-and-loss.BE_RV_6912': 'Affectations aux capitaux propres (691/2)',
  'cr.annual.report.profit-and-loss.BE_RV_692': 'Affectations aux capitaux propres (692)',
  'cr.annual.report.profit-and-loss.BE_RV_6920': 'à la réserve légale (6920)',
  'cr.annual.report.profit-and-loss.BE_RV_6921': 'aux autres réserves (6921)',
  'cr.annual.report.profit-and-loss.BE_RV_694': 'Rémunération du capital (694)',
  'cr.annual.report.profit-and-loss.BE_RV_6947': 'Bénéfice à distribuer (6947)',
  'cr.annual.report.profit-and-loss.BE_RV_695': 'Administrateurs ou gérants (695)',
  'cr.annual.report.profit-and-loss.BE_RV_696': 'Employés (696)',
  'cr.annual.report.profit-and-loss.BE_RV_697': 'Autres allocataires (697)',
  'cr.annual.report.profit-and-loss.BE_RV_791':
    'Prélèvement sur les capitaux propres: fonds, fonds affectés et autres réserves (791)',
  'cr.annual.report.profit-and-loss.BE_RV_791.COMPANY':
    "sur le capital et les primes d'émission (791)",
  'cr.annual.report.profit-and-loss.BE_RV_791.NON-PROFIT':
    "sur les fonds de l'association ou de la fondation (791)",
  'cr.annual.report.profit-and-loss.BE_RV_7912': 'Prélèvements sur les capitaux propres (791/2)',
  'cr.annual.report.profit-and-loss.BE_RV_792.COMPANY': 'sur les réserves (792)',
  'cr.annual.report.profit-and-loss.BE_RV_792.NON-PROFIT': 'sur les fonds affectés (792)',
  'cr.annual.report.profit-and-loss.BE_RV_794': 'Intervention d’associés dans la perte (794)',
  'cr.annual.report.profit-and-loss.BE_RV_9905': "Bénéfice (Perte) de l'exercice à affecter (9905)",
  'cr.annual.report.profit-and-loss.BE_RV_9906': 'Bénéfice (Perte) à affecter (+)/(-) (9906)',
  'cr.annual.report.profit-and-loss.bedrijfsopbrengsten-en-bedrijfskosten':
    "Résultat d'exploitationn et charges d'exploitation",
  'cr.annual.report.profit-and-loss.GB_RES_21': 'Pertes/profits opérationnel-le-s',
  'cr.annual.report.profit-and-loss.GB_RES_21_01': 'Frais de fonctionnement',
  'cr.annual.report.profit-and-loss.GB_RES_21_02': "Résultat d'exploitation",
  'cr.annual.report.profit-and-loss.GB_RES_33': "Charges d'amortissement",
  'cr.annual.report.profit-and-loss.GB_RES_21_03': 'Éléments exceptionnels',
  'cr.annual.report.profit-and-loss.GB_RES_21_04': 'Pertes/Profits sur cession',
  'cr.annual.report.profit-and-loss.GB_RES_26': 'Pertes/profits avant impôt',
  'cr.annual.report.profit-and-loss.GB_RES_26_03': 'Intérêts à recevoir',
  'cr.annual.report.profit-and-loss.GB_RES_26_04': 'Intérêts à payer',
  'cr.annual.report.profit-and-loss.GB_RES_26_10': 'Éléments exceptionnels',
  'cr.annual.report.profit-and-loss.GB_RES_34': 'Pertes/Profits nets',
  'cr.annual.report.profit-and-loss.GB_RES_34_01': 'Imposition',
  'cr.annual.report.profit-and-loss.GB_RES_34_02': 'Participations minoritaires',
  'cr.annual.report.profit-and-loss.GB_RES_34_03': 'Éléments extraordinaires',
  'cr.annual.report.profit-and-loss.GB_RES_34_04': 'Dividendes',
  'cr.annual.report.profit-and-loss.GB_RES_35': 'Profit brut',
  'cr.annual.report.profit-and-loss.GB_RES_35_01': "Chiffre d'affaires",
  'cr.annual.report.profit-and-loss.GB_RES_35_01_01': "Chiffre d'affaires (UK)",
  'cr.annual.report.profit-and-loss.GB_RES_35_01_02': "Chiffre d'affaires (Europe)",
  'cr.annual.report.profit-and-loss.GB_RES_35_01_03': "Chiffre d'affaires (reste du monde)",
  'cr.annual.report.profit-and-loss.GB_RES_35_01_04': "Chiffre d'affaires (exportations)",
  'cr.annual.report.profit-and-loss.GB_RES_35_02': 'Coût des ventes',
  'cr.annual.report.profit-and-loss.GB_RES_35_03': 'Charges totales',
  'cr.annual.report.profit-and-loss.GB_RES_35_03_01': 'Éléments exceptionnels',
  'cr.annual.report.profit-and-loss.GB_RES_35_03_02': 'Autres éléments directs',
  'cr.annual.report.profit-and-loss.NL_ACC_02': 'Bénéfice net',
  'cr.annual.report.profit-and-loss.NL_RES_50_10_001': 'Somme des produits d’exploitation',
  'cr.annual.report.profit-and-loss.NL_RES_50_10_002': 'Chiffre d’affaires net',
  'cr.annual.report.profit-and-loss.NL_RES_50_10_003':
    'Modifications des stocks de produits finis et en-cours de fabrication',
  'cr.annual.report.profit-and-loss.NL_RES_50_10_004': 'Produits activés entreprise propre',
  'cr.annual.report.profit-and-loss.NL_RES_50_10_005': 'Autres revenus d’exploitation',
  'cr.annual.report.profit-and-loss.NL_RES_50_10_006': 'Revenu total',
  'cr.annual.report.profit-and-loss.NL_RES_50_15_001': 'Marge brute',
  'cr.annual.report.profit-and-loss.NL_RES_50_15_002': 'Autres marges brutes',
  'cr.annual.report.profit-and-loss.NL_RES_50_20_001': 'Sommes des charges d’exploitation',
  'cr.annual.report.profit-and-loss.NL_RES_50_20_002': "Coûts d'approvisionnement",
  'cr.annual.report.profit-and-loss.NL_RES_50_20_003':
    'Coûts des travaux sous-traités et autres coûts externes',
  'cr.annual.report.profit-and-loss.NL_RES_50_20_004': 'Rémunérations et salaires',
  'cr.annual.report.profit-and-loss.NL_RES_50_20_005': 'Charges sociales',
  'cr.annual.report.profit-and-loss.NL_RES_50_20_006':
    'Amortissements sur immobilisations incorporelles et immobilisations corporelles',
  'cr.annual.report.profit-and-loss.NL_RES_50_20_007':
    'Autres changements de valeur des immobilisations incorporelles et des immobilisations corporelles',
  'cr.annual.report.profit-and-loss.NL_RES_50_20_008': 'Dépréciation des actifs circulants',
  'cr.annual.report.profit-and-loss.NL_RES_50_20_009': 'Autres charges d’exploitation',
  'cr.annual.report.profit-and-loss.NL_RES_50_20_010': 'Coûts généraux de gestion',
  'cr.annual.report.profit-and-loss.NL_RES_50_25_001': 'Charges d’exploitation',
  'cr.annual.report.profit-and-loss.NL_RES_50_25_002': "Coûts d'approvisionnement",
  'cr.annual.report.profit-and-loss.NL_RES_50_25_003':
    'Coût de la sous-traitance et autres coûts externes',
  'cr.annual.report.profit-and-loss.NL_RES_50_25_004': 'Rémunérations et salaires',
  'cr.annual.report.profit-and-loss.NL_RES_50_25_005': 'Charges sociales',
  'cr.annual.report.profit-and-loss.NL_RES_50_25_006':
    'Amortissements sur immobilisations incorporelles et immobilisations corporelles',
  'cr.annual.report.profit-and-loss.NL_RES_50_25_007':
    'Autres changements de valeur des immobilisations incorporelles et des immobilisations corporelles',
  'cr.annual.report.profit-and-loss.NL_RES_50_25_008':
    'Dépréciation exceptionnelle des actifs circulants',
  'cr.annual.report.profit-and-loss.NL_RES_50_25_009': 'Autres charges d’exploitation',
  'cr.annual.report.profit-and-loss.NL_RES_50_30_001': 'Résultat d’exploitation',
  'cr.annual.report.profit-and-loss.NL_RES_50_30_002': 'Autre résultat d’exploitation',
  'cr.annual.report.profit-and-loss.NL_RES_50_40_001': 'Résultat financier',
  'cr.annual.report.profit-and-loss.NL_RES_50_40_002': 'Autre résultat financier',
  'cr.annual.report.profit-and-loss.NL_RES_50_42_001': 'Somme des avantages financiers',
  'cr.annual.report.profit-and-loss.NL_RES_50_42_002': 'Paiement des participations',
  'cr.annual.report.profit-and-loss.NL_RES_50_42_003':
    'Revenu provenant des actifs immobilisés et autres actifs',
  'cr.annual.report.profit-and-loss.NL_RES_50_42_004':
    'Autres revenus provenant des intérêts et produits similaires',
  'cr.annual.report.profit-and-loss.NL_RES_50_42_005':
    'Variation de la valeur des créances faisant partie des actifs immobilisés et autres actifs',
  'cr.annual.report.profit-and-loss.NL_RES_50_44_001': 'Somme des charges financières',
  'cr.annual.report.profit-and-loss.NL_RES_50_44_002': 'Paiement des participations',
  'cr.annual.report.profit-and-loss.NL_RES_50_44_003':
    'Variation de la valeur des créances faisant partie des actifs immobilisés et autres actifs',
  'cr.annual.report.profit-and-loss.NL_RES_50_44_004': 'Intérêts et charges similaires',
  'cr.annual.report.profit-and-loss.NL_RES_50_50_001': 'Résultat courant avant impôts',
  'cr.annual.report.profit-and-loss.NL_RES_50_50_002': 'Autres résultats courants avant impôts',
  'cr.annual.report.profit-and-loss.NL_RES_50_52_001': 'Part des bénéfices/pertes de participation',
  'cr.annual.report.profit-and-loss.NL_RES_50_52_002':
    'Autres parts des bénéfices/pertes de participation',
  'cr.annual.report.profit-and-loss.NL_RES_50_52_003': 'Résultats de la participation après impôt',
  'cr.annual.report.profit-and-loss.NL_RES_50_54_001':
    'Impôt sur le résultat fiscal des activités ordinaires',
  'cr.annual.report.profit-and-loss.NL_RES_50_54_002':
    'Autres impôts sur le résultat fiscal des activités ordinaires',
  'cr.annual.report.profit-and-loss.NL_RES_50_60_001':
    'Résultat des activités ordinaires après impôts',
  'cr.annual.report.profit-and-loss.NL_RES_50_60_002':
    'Autres résultat des activités ordinaires après impôts',
  'cr.annual.report.profit-and-loss.NL_RES_50_62_001': 'Somme des revenus exceptionnels',
  'cr.annual.report.profit-and-loss.NL_RES_50_62_002': 'Autres sommes des revenus exceptionnels',
  'cr.annual.report.profit-and-loss.NL_RES_50_64_001': 'Somme des charges exceptionnelles',
  'cr.annual.report.profit-and-loss.NL_RES_50_64_002': 'Autres sommes des charges exceptionnelles',
  'cr.annual.report.profit-and-loss.NL_RES_50_66_001': 'Impôt sur le résultat exceptionnel',
  'cr.annual.report.profit-and-loss.NL_RES_50_66_002': 'Autres résultats exceptionnels',
  'cr.annual.report.profit-and-loss.NL_RES_50_70_001': 'Résultat exceptionnel après impôts',
  'cr.annual.report.profit-and-loss.NL_RES_50_70_002': 'Résultat exceptionnel après impôts',
  'cr.annual.report.profit-and-loss.NL_RES_50_70_003': 'Solde résultat exceptionnel avant impôts',
  'cr.annual.report.profit-and-loss.NL_RES_50_72_001': 'Part de tiers',
  'cr.annual.report.profit-and-loss.NL_RES_50_72_002': 'Autres parts de tiers',
  'cr.annual.report.profit-and-loss.NL_RES_50_80_001': 'Bénéfices après impôts',
  'cr.annual.report.profit-and-loss.NL_RES_50_80_002': 'Autres bénéfices après impôts',
  'cr.annual.report.profit-and-loss.RESULTATENREKENING': 'RÉSULTATS DES COMPTES',
  'cr.annual.report.profit-and-loss.subheader': 'Devise de base et unité',
  'cr.annual.report.profit.loss.subheader': 'Devise de base et unité',
  'cr.annual.report.profit.loss.tooltip.BE':
    'Le compte de résultat donne un aperçu des recettes et des dépenses des 5 derniers exercices.',
  'cr.annual.report.profit.loss.tooltip.GB':
    "Le compte de résultat donne un aperçu des recettes et des dépenses de l'exercice.",
  'cr.annual.report.profit.loss.tooltip.NL':
    'Le compte de pertes et de profits donne un aperçu des recettes et des dépenses au cours de l’exercice.',
  'cr.annual.report.social-balance.BE_SB_1003':
    "Nombre moyen d’employés au cours de l'exercice (ETP) (1003)",
  'cr.annual.report.social-balance.BE_SB_1013':
    'Actuelnombre d’heures effectuées au cours de l’exercice annuel (1013)',
  'cr.annual.report.social-balance.BE_SB_1023': 'Coût du personnel durant l’exercice annuel (1023)',
  'cr.annual.report.social-balance.BE_SB_1203':
    "Hommes (ETP) à la date de clôture de l'exercice (1203)",
  'cr.annual.report.social-balance.BE_SB_1213':
    "Femmes (ETP) à la date de clôture de l'exercice (1213)",
  'cr.annual.report.social-balance.BE_SB_1303':
    'Management/Responsables (ETP) à la date de clôture de l’exercice (1303)',
  'cr.annual.report.social-balance.BE_SB_1323':
    "Employés (ETP) à la date de clôture de l'exercice (1323)",
  'cr.annual.report.social-balance.BE_SB_1333':
    "Autres (ETP) à la date de clôture de l'exercice (1333)",
  'cr.annual.report.social-balance.BE_SB_1343':
    "Personnel de bureau (ETP) à la date de clôture de l'exercice (1343)",
  'cr.annual.report.social-balance.BE_SB_1501':
    "Nombre moyen d’intérimaires au cours de l'exercice (1501)",
  'cr.annual.report.social-balance.BE_SB_1511':
    "Nombre réel d'heures effectuées par des intérimaires au cours de l'exercice (1511)",
  'cr.annual.report.social-balance.BE_SB_1521':
    "Frais d'agence intérimaire au cours de l'exercice (1521)",
  'cr.annual.report.social-balance.header-title': 'Bilan social',
  'cr.annual.report.social-balance.tooltip.BE':
    "Vous trouverez ici la répartition des types d'employés et des coûts de personnel",
  'cr.annual.report.tab.balance-sheet': 'Comptes annuels',
  'cr.annual.report.tab.explanatory-notes': 'Annexe',
  'cr.annual.report.tab.key-figures': 'Chiffres clés',
  'cr.annual.report.tab.profit-and-loss': 'Pertes et profits',
  'cr.annual.report.tab.social-balance': 'Bilan social',
  'cr.archive.action.button.addToMonitoring.back': 'Retour aux Archives de la Société',
  'cr.archive.no.archived.reports.body':
    'Vous n’avez encore requis aucun rapport, ou bien si vous en avez demandés, ils ne sont pas encore disponibles.<br /><br />\nSi vous avez besoin des questions ou besoin d’aide, veuillez contacter notre équipe du Service Clients à l’adresse suivante:  <a href="mailto:{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}">{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}</a> or on {country, select, gb {+44 (0)20 8515 1400} nl {+31 (020) 567 9501} other {+32 (03) 280 88 80}}.',
  'cr.archive.no.archived.reports.title': "Aucune action récente n'est pour l'instant disponible.",
  'cr.branches-table.branch-address': 'Addresse de la filiale',
  'cr.branches-table.branch-name': 'Nom de la filiale ',
  'cr.branches-table.branch-number': 'Numéro de la filiale Graydon',
  'cr.branches-table.branch-start-date': 'Date de début & de fin',
  'cr.branches.branch-address': 'Adresse de la filiale',
  'cr.branches.branch-id': 'Identification filiale',
  'cr.branches.branch-name': 'Nom de la filiale',
  'cr.branches.branch-number': 'Numéro de la filiale',
  'cr.branches.period.present': 'Présent',
  'cr.branches.period.unknown': 'Inconnu',
  'cr.branches.start-date': 'Date de début',
  'cr.branches.total': 'Total',
  'cr.company-archive.reference.add': 'Ajoutez une référence',
  'cr.company-archive.reference.edit': 'Modifier',
  'cr.company-archive.reference.error.message':
    'Nous n’avons pas pu mettre à jour votre référence, merci de réessayer.',
  'cr.company-archive.reference.error.title': 'Merci de réessayer',
  'cr.company-archive.reference.form.add-new-reference': '+ Ajoutez une autre référence',
  'cr.company-archive.reference.form.add.cancel': 'Annuler',
  'cr.company-archive.reference.form.add.label': 'Votre référence',
  'cr.company-archive.reference.form.add.placeholder':
    'Notez ici votre référence pour cette société',
  'cr.company-archive.reference.form.add.save': 'Sauvegarder',
  'cr.company-archive.reference.form.add.title': 'Ajoutez votre référence',
  'cr.company-archive.reference.form.add.tooltip':
    'Ajouter votre propre référence pour cette société vous aidera à l’identifier à l’avenir. Votre référence sera visible sur tous les futurs nouveaux rapports dont vous ferez la demande pour cette société.',
  'cr.company-archive.reference.form.description': 'Spécifiez 1 ou plusieurs références',
  'cr.company-archive.reference.form.edit.cancel': 'Annuler',
  'cr.company-archive.reference.form.edit.label': 'Votre référence',
  'cr.company-archive.reference.form.edit.placeholder': 'Modifiez votre référence ici',
  'cr.company-archive.reference.form.edit.save': 'Sauvegarder',
  'cr.company-archive.reference.form.edit.title': 'Modifier votre référence',
  'cr.company-archive.reference.form.edit.tooltip':
    'Votre référence mise à jour sera visible sur tous les futurs nouveaux rapports dont vous ferez la demande pour cette société .',
  'cr.company-archive.reference.form.references': 'Votre référence / Vos références',
  'cr.company-archive.reference.label': 'Référence',
  'cr.company-archive.reference.modal.add.title': 'Ajoutez votre référence pour cette société',
  'cr.company-archive.reference.placeHolder': 'Ajoutez une référence pour identifier une société',
  'cr.company-archive.reference.success.add.title': 'Votre référence a été ajoutée',
  'cr.company-archive.reference.success.remove.title': 'Votre référence a été supprimée',
  'cr.company-archive.reference.tooltip.add.description':
    'Ajouter votre propre référence pour cette société vous aidera à l’identifier à l’avenir. Votre référence sera visible sur tous les futurs nouveaux rapports dont vous ferez la demande pour cette société.',
  'cr.company-archive.reference.tooltip.add.title': 'Ajoutez votre référence',
  'cr.company-archive.reference.tooltip.edit.description':
    'Votre référence mise à jour sera visible sur tous les futurs nouveaux rapports dont vous ferez la demande pour cette société .',
  'cr.company-archive.reference.tooltip.edit.title': 'Modifier votre référence',
  'cr.company-box.branch-address': 'Adresse',
  'cr.company-box.branches': 'Filiales',
  'cr.company-box.branches.link': 'Voir les filiales',
  'cr.company-box.graydon-number': 'Numéro Graydon',
  'cr.company-box.registration-number-be': "Numéro d'entreprise",
  'cr.company-box.registration-number-gb': "Numéro d'entreprise",
  'cr.company-box.registration-number-nl': "Numéro d'identification (KvK)",
  'cr.company-box.trade-names': 'Dénominations commerciales',
  'cr.company-box.trade-names.link': 'Voir toutes les dénominations commerciales',
  'cr.company-box.vat-number': 'Numéro de TVA',
  'cr.company.active': 'Actif',
  'cr.company.branches.title': 'Filiales',
  'cr.company.branches.tooltip':
    'Vous trouverez ici toutes les informations à propos des filiales de la société',
  'cr.company.branches.tooltip.BE':
    'Dans cette section, vous trouverez toutes les informations sur les filiales de l’entreprise. Chaque filiale a un numéro unique qui, comme le numéro d’entreprise, est attribué par la BCE.',
  'cr.company.branches.tooltip.GB':
    "Vous trouverez ici les coordonnées des filiales de l'entreprise, le cas échéant.",
  'cr.company.branches.tooltip.NL':
    'Dans cette section, vous trouverez toutes les informations sur les filiales de l’entreprise.',
  'cr.company.contacts.email': 'Adresse e-mail',
  'cr.company.contacts.fax': 'Numéro de fax',
  'cr.company.contacts.location': 'Localisation sur une carte',
  'cr.company.contacts.mobile-number': 'Numéro de portable',
  'cr.company.contacts.old-address': 'Ancienne adresse',
  'cr.company.contacts.phone': 'Numéro de téléphone',
  'cr.company.contacts.region': 'Région',
  'cr.company.contacts.registered-address': 'Siège social',
  'cr.company.contacts.title': "Contacts de l'entreprise",
  'cr.company.contacts.title.BE':
    "Vous retrouverez ici toutes les coordonnées et les noms commerciaux dont nous disposons. L’adresse enregistrée ou le siège social est l’adresse légale officielle d’une société (personne morale) ou d’une association. Il peut y avoir plusieurs lieux d’établissement, mais il n'y a qu'une seule adresse enregistrée. La plupart du temps, cette adresse est également considérée comme le siège social.\nPour les entreprises individuelles, nos sources officielles ne fournissent plus les adresses enregistrées et Graydon doit alors se fier aux adresses des filiales.\nIl existe une différence entre le nom commercial et la dénomination sociale d'une entreprise. Le nom commercial est le nom sous lequel une entreprise exerce ses activités commerciales. Ce sont généralement les entreprises individuelles qui travaillent avec un nom commercial. La dénomination sociale est l’appellation légale officielle d'une entreprise et sert à l'identifier. Elle n'a aucun but publicitaire, contrairement au nom commercial. Pour les personnes morales, toutefois, le nom commercial et la dénomination sociale sont souvent les mêmes.",
  'cr.company.contacts.title.GB':
    "Vous trouverez ici les coordonnées et les noms commerciaux de l'entreprise.",
  'cr.company.contacts.title.NL':
    'Vous trouverez ici toutes les coordonnées et les noms commerciaux dont nous disposons.',
  'cr.company.contacts.tooltip':
    'Vous trouverez ici les contacts et dénominations commerciales de la société',
  'cr.company.contacts.tooltip.BE':
    "Vous retrouverez ici toutes les coordonnées et les noms commerciaux dont nous disposons. L’adresse enregistrée ou le siège social est l’adresse légale officielle d’une société (personne morale) ou d’une association. Il peut y avoir plusieurs lieux d’établissement, mais il n'y a qu'une seule adresse enregistrée. La plupart du temps, cette adresse est également considérée comme le siège social.\nPour les entreprises individuelles, nos sources officielles ne fournissent plus les adresses enregistrées et Graydon doit alors se fier aux adresses des filiales.\nIl existe une différence entre le nom commercial et la dénomination sociale d'une entreprise. Le nom commercial est le nom sous lequel une entreprise exerce ses activités commerciales. Ce sont généralement les entreprises individuelles qui travaillent avec un nom commercial. La dénomination sociale est l’appellation légale officielle d'une entreprise et sert à l'identifier. Elle n'a aucun but publicitaire, contrairement au nom commercial. Pour les personnes morales, toutefois, le nom commercial et la dénomination sociale sont souvent les mêmes.",
  'cr.company.contacts.tooltip.GB':
    "Vous trouverez ici les coordonnées et les noms commerciaux de l'entreprise.",
  'cr.company.contacts.tooltip.NL':
    'Vous trouverez ici toutes les coordonnées et les noms commerciaux dont nous disposons.',
  'cr.company.contacts.trade.names': 'Dénomination commerciale',
  'cr.company.contacts.trade.names.link': 'Voir tous les noms commerciaux',
  'cr.company.contacts.trade.names.mobile':
    '{value} des noms commerciaux à visualiser en téléchargement PDF complet',
  'cr.company.contacts.trading-address': "Siège d'exploitation",
  'cr.company.contacts.website': 'Site web',
  'cr.company.details.activities-description': 'Activité ({codeType} code)',
  'cr.company.details.activity-group': "Groupe d'activité",
  'cr.company.details.activity-text': "Détails de l'activité",
  'cr.company.details.activity-text.showAll': 'Lire la suite details',
  'cr.company.details.actual-amount-liability': 'Limite de responsabilité actuelle',
  'cr.company.details.capital': 'Capital',
  'cr.company.details.capital.associationFunds': 'Fonds associatifs',
  'cr.company.details.capital.authorisedCapital': 'Capital social',
  'cr.company.details.capital.availableContribution': 'Apport disponible (hors capital)',
  'cr.company.details.capital.contribution': 'Apport de contribution',
  'cr.company.details.capital.foundingCapital': 'Capital de départ',
  'cr.company.details.capital.issuedCapital': 'Capital émis',
  'cr.company.details.capital.paidUpCapital': 'Capital libéré',
  'cr.company.details.capital.paidUpContribution': 'Libération de l’apport',
  'cr.company.details.capital.paidUpFoundingCapital': 'Libération partielle du capital',
  'cr.company.details.capital.partnershipCapital': 'Partenariat',
  'cr.company.details.capital.unavailableContribution': 'Apport indisponible (hors capital)',
  'cr.company.details.coc-documents': 'Documents officiels',
  'cr.company.details.companies-house': 'Banque-Carrefour des Entreprises',
  'cr.company.details.companies-house.view-company': "Voir l'historique des bilans annuels",
  'cr.company.details.company-name': 'Nom de la société',
  'cr.company.details.consumer-credit-check': 'Consumer credit check',
  'cr.company.details.end-date': 'Date de fin',
  'cr.company.details.estimated-amount-liability': 'Limite de responsabilité estimée',
  'cr.company.details.export-indicator': 'Indicateur export',
  'cr.company.details.founding-capital': 'Capital de fondation',
  'cr.company.details.governance-form': 'Forme de gestion',
  'cr.company.details.graydon-number': 'Numéro Graydon',
  'cr.company.details.import-indicator': 'Indicateur import',
  'cr.company.details.incorporation-date': 'Date de constitution',
  'cr.company.details.known-since': 'Connu depuis',
  'cr.company.details.legal-form': 'Forme juridique',
  'cr.company.details.legal-form-since': 'Forme juridique depuis',
  'cr.company.details.lei-code': 'Code LEI (Legal Entity Identifier)',
  'cr.company.details.no': 'Non',
  'cr.company.details.number-of-employees': 'Nombre de collaborateurs',
  'cr.company.details.number-of-fte': 'Nombre d’employés à plein temps',
  'cr.company.details.old-graydon-number': 'Ancien numéro Graydon',
  'cr.company.details.paritair-comite': 'Comité paritaire',
  'cr.company.details.previous-company-name': "Nom précédent de l'entreprise",
  'cr.company.details.recognition': 'Agrément',
  'cr.company.details.recognition.agriculturalSocietyRecognition': 'Société agricole',
  'cr.company.details.recognition.landAgencyRecognition': 'Groupement forestier',
  'cr.company.details.recognition.socialRecognition': 'Agrément social',
  'cr.company.details.registration-id.BE': "Numéro d'entreprise",
  'cr.company.details.registration-id.GB': "Numéro d'entreprise",
  'cr.company.details.registration-id.NL': "Numéro d'identification (KvK)",
  'cr.company.details.rsz-number': 'Numéro ONSS',
  'cr.company.details.sector': 'Secteur',
  'cr.company.details.size-description': 'Taille de la société',
  'cr.company.details.title': 'Détails de la société',
  'cr.company.details.tooltip':
    'Dans cette partie, vous trouverez les information générales sur la société',
  'cr.company.details.tooltip.BE':
    "Grâce aux éléments de cette section, vous aurez un aperçu global du profil de l’entreprise. Le numéro d'entreprise en Belgique est le numéro unique permettant d'identifier une entreprise. Il est attribué par la Banque Carrefour des Entreprises et doit être indiqué sur tous les documents officiels (tels que les factures). Le numéro de sécurité sociale est un numéro attribué par l’Office national de la sécurité sociale pour identifier les employeurs. Chaque employeur doit soumettre trimestriellement une déclaration électronique à l'Office national de la sécurité sociale, reprenant les coordonnées de tous les employés et le calcul des montants dus. Les entreprises qui n'emploient pas de personnel n'ont pas de numéro de sécurité sociale.\nLe code d'activité indiqué ici est le résultat d'une cascade. Cela implique tout d'abord le fait de consulter le code d'activité attribué par l'administration de la TVA, puis les Annexes du Moniteur Belge, la Banque Carrefour des Entreprises, l'Office national de la sécurité sociale ou une enquête.\nLa catégorie d’employeur, le comité paritaire, ainsi que la classe de personnel (une valeur de 0 à 9) sont communiqués par l'Office national de la sécurité sociale. La classe de personnel est liée au nombre d'employés employés par une entreprise, la catégorie de personnel 9 correspondant à plus de 1 000 employés.",
  'cr.company.details.tooltip.GB':
    "Dans cette section, vous trouverez des informations générales sur l'entreprise.",
  'cr.company.details.tooltip.NL':
    "Vous trouverez ici toutes les informations générales sur l’entreprise. Le numéro Graydon est un nouveau numéro uniforme pour une entreprise utilisé aux Pays-Bas, en Belgique et au Royaume-Uni. L'ancien numéro Graydon est un numéro Graydon local utilisé auparavant.",
  'cr.company.details.transunion-application': 'TransUnion application',
  'cr.company.details.vat-number': 'Numéro de TVA',
  'cr.company.details.vat-status': 'Assujetti(e) à la TVA',
  'cr.company.details.vat-status.no': 'Non',
  'cr.company.details.view-documents': 'Afficher les documents',
  'cr.company.details.werkgeverscategorie': "Catégorie de l'organisation patronale",
  'cr.company.details.yes': 'Oui',
  'cr.company.inactive': 'Inactif',
  'cr.company.unavailable': 'Statut non disponible',
  'cr.error.annualReport.natuur10.contact':
    'En raison du modèle spécifique, le bilan annuel de cette société ne peut être traité. Vous pouvez consulté le bilan original depuis le site internet de la <a href="https://www.nbb.be/fr" rel="noopener noreferrer" target="_blank">Banque Nationale de Belgique</a>.Si vous avez des questions ou avez besoin d’aide, veuillez contacter notre équipe du Service Clients à l\'adresse suivante {contactInfo}.',
  'cr.error.annualReport.natuur10.tileHeading': 'Comptes annuels',
  'cr.error.annualReport.natuur10.title':
    'Cette société a déposé un type de bilan annuel exceptionnel',
  'cr.error.contacts':
    'Si cela ne fonctionne toujours pas, veuillez contacter notre équipe Customer Service en écrivant à <a href="mailto:{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}">{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}</a> ou au {country, select, gb {+44 (020) 8515 1400} nl {+31 (020) 567 9501} other {+32 (03) 280 88 80}}.',
  'cr.error.goback': 'Retour à la recherche',
  'cr.error.insolvencies.no-data.body':
    'Aucune calamité financière n’est listée pour cette société.<br /><br />Si vous avez des questions ou avez besoin d’aide, veuillez contacter notre équipe du Service Clients à l\'adresse suivante : <a href="mailto:{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}">{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}</a> ou par téléphone au {country, select, gb {+44&nbsp;(0)20&nbsp;8515&nbsp;1400} nl {+31&nbsp;(020)&nbsp;567&nbsp;9501} other {+32&nbsp;(03)&nbsp;280&nbsp;88&nbsp;80}}.',
  'cr.error.insolvencies.no-data.title': 'Aucune calamités financières pour cette société',
  'cr.error.no.annual.report.contact':
    'Cette société n\'a déposé aucun bilan comptable au cours de ces cinq dernières années.<br /><br />Merci de contacter notre service clients au <a href="mailto:{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}">{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}</a> or on {country, select, gb {+44 (020) 8515 1400} nl {+31 (020) 567 9501} other {+32 (03) 280 88 80}} pour toutes erreurs ou modifications.',
  'cr.error.no.annual.report.no-data-from-liability':
    "Ni cette société, ni la société assujettie à l'impôt n'ont déposé de bilan comptable au cours de ces cinq dernières années.<br /><br />Si vous avez des questions ou avez besoin d'aide, merci de contacter notre service clients au <a href=\"mailto:{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}\">{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}</a> or on {country, select, gb {+44 (020) 8515 1400} nl {+31 (020) 567 9501} other {+32 (03) 280 88 80}} pour toutes erreurs ou modifications.",
  'cr.error.no.annual.report.title':
    "Aucun bilan annuel n'est actuellement disponible pour cette société",
  'cr.error.no.annual.report.title-no-data-from-liability': 'Aucun bilan annuel disponible',
  'cr.error.no.archived.reports.body':
    'Vous n\'avez exécuté encore aucune recherche pour cette société ou bien ces recherches sont actuellement indisponibles.<br /><br />Si vous avez des questions ou avez besoin d’aide, veuillez contacter notre équipe du Service Clients à l\'adresse suivante : <a href="mailto:{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}">{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}</a> ou par téléphone au {country, select, gb {+44 (0)20 8515 1400} nl {+31 (020) 567 9501} other {+32 (03) 280 88 80}}.',
  'cr.error.no.archived.reports.title':
    "Aucune action récente n'est pour l'instant disponible pour cette société",
  'cr.error.no.trends.body':
    'Si vous avez des questions ou avez besoin d\'aide, merci de contacter notre service clients au <a href="mailto:{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}">{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}</a> or on {country, select, gb {+44 (020) 8515 1400} nl {+31 (020) 567 9501} other {+32 (03) 280 88 80}}.',
  'cr.error.no.trends.body.condensed':
    'Si vous avez des questions ou avez besoin d’aide, veuillez contacter notre équipe du Service Clients.',
  'cr.error.no.trends.title': 'Aucune information n’est actuellement disponible',
  'cr.error.report.expired.text':
    "Nous sommes désolés, la page de rapport que vous avez tenté d'ouvrir a expiré. Veuillez lancer une nouvelle demande de rapport ou rechercher la société et télécharger le rapport archivé.",
  'cr.error.report.expired.title': 'Ce rapport a expiré...',
  'cr.error.report.fetch.list-header': 'Veuillez essayer ce qui suit',
  'cr.error.report.fetch.list-item1': 'Assurez-vous de bien avoir la bonne URL',
  'cr.error.report.fetch.list-item2': 'Rafraîchissez la page',
  'cr.error.report.fetch.list-item3': "Retournez à l'accueil et recherchez à nouveau votre page",
  'cr.error.report.fetch.text':
    "Nous sommes désolés, la page que vous avez tenté d'ouvrir n'existe plus ou est inopérante.",
  'cr.error.report.fetch.title': "Une erreur s'est produite ...",
  'cr.error.search': "Une erreur s'est produite ...",
  'cr.error.search.contacts':
    'Merci de réessayer plus tard, ou bien contactez notre équipe du Service Clients à l\'adresse suivante : <a href="mailto:{country, select, gb {customerservices@graydon.co.uk} be {support@graydon.be} other {service@graydon.nl}}">{country, select, gb {customerservices@graydon.co.uk} be {support@graydon.be} other {service@graydon.nl}}</a> ou bien par téléphone au {country, select, gb {+44&nbsp;(0)20&nbsp;8515&nbsp;1400} be {+32&nbsp;(03)&nbsp;280&nbsp;8880} other {+31&nbsp;(020)&nbsp;567&nbsp;9501}}.',
  'cr.error.search.text':
    'Le service est malheureusement indisponible et la recherche ne peut être effectuée.',
  'cr.error.tryagain': 'Veuillez réessayer',
  'cr.financial-calamities.curators': 'Avec la nomination de',
  'cr.financial-calamities.description': 'Description',
  'cr.financial-calamities.preliminary': 'Préliminaire',
  'cr.financial-calamities.preliminary.no': 'Non',
  'cr.financial-calamities.preliminary.yes': 'Oui',
  'cr.financial-calamities.start-date': 'Date',
  'cr.financial-calamities.title': 'Calamités financières',
  'cr.financial-calamities.tooltip':
    'Dans cette section, vous trouverez un aperçu des incidents financiers pour cette entreprise et tous les détails connexes.',
  'cr.financial-calamities.tooltip.BE':
    'Dans cette section, vous trouverez un aperçu des incidents financiers pour cette entreprise et tous les détails connexes.',
  'cr.financial-calamities.tooltip.GB':
    'Dans cette section, vous trouverez un aperçu des incidents financiers pour cette entreprise et tous les détails connexes.',
  'cr.financial-calamities.tooltip.NL':
    'Vous trouverez ici plus de détails sur les éventuelles calamités financières de cette entreprise.',
  'cr.financials.liabilities.companyName': 'Nom de la société',
  'cr.financials.liabilities.data-from-liability':
    'Les informations financières présentées ci-dessous correspondent à la société à responsabilité limitée qui a déposé une déclaration 403.',
  'cr.financials.liabilities.main':
    '{liableCompany} a assumé la responsabilité conjointe et solidaire pour toutes les dettes découlant des transactions juridiquement contraignantes conclues par la société',
  'cr.financials.liabilities.startDate': 'Date de début',
  'cr.financials.liabilities.title': 'Passif',
  'cr.financials.liabilities.tooltip':
    "Plus d'informations sur le passif de la compagnie listée disponibles ici.",
  'cr.financials.liabilities.tooltip.NL':
    "Vous trouverez ici des informations générales sur les passifs de l’entreprise. La responsabilité est l'obligation de supporter les conséquences néfastes d'un événement.",
  'cr.hearder.reference': 'Référence',
  'cr.insolvency.curators': 'Avec la nomination de',
  'cr.insolvency.description': 'Description',
  'cr.insolvency.descriptionDetails': "Description et détails de l'insolvabilité",
  'cr.insolvency.endReason': "Fin d'insolvabilité",
  'cr.insolvency.eventDate': 'Date',
  'cr.insolvency.eventDetails': "Détails de l'événement",
  'cr.insolvency.forms': 'Date',
  'cr.insolvency.forms-count': "Détails de l'événement",
  'cr.insolvency.forms-hide': 'Cachez le(s) formulaire(s)',
  'cr.insolvency.no-description': 'Inconnu',
  'cr.insolvency.no-details': 'Pas de détails',
  'cr.insolvency.period': 'Date de début et de fin',
  'cr.insolvency.preliminary': 'Préliminaire',
  'cr.insolvency.preliminary.no': 'Non',
  'cr.insolvency.preliminary.yes': 'Oui',
  'cr.insolvency.present': 'Présent',
  'cr.insolvency.startDate': 'Date',
  'cr.insolvency.table.date': 'Date',
  'cr.insolvency.table.form': 'Formulaire',
  'cr.modal.report-fetching.header': 'Veuillez patienter pendant que nous rassemblons les données',
  'cr.modal.report-fetching.text': 'nous collectons les données...',
  'cr.modal.report-download-error.text.paragraph1':
    'Merci de bien vouloir patienter pendant que nous générons le PDF.\n\nIl suffit généralement d’un instant pour générer un PDF mais s’il y a beaucoup de trafic sur le site, cela peut prendre un peu plus de temps. Lorsqu’il est généré, le PDF est disponible depuis la section Archives de votre société.',
  'cr.modal.report-download-error.text.paragraph2':
    'Si le PDF ne figure pas dans les archives de votre société, veuillez contacter notre équipe de Support Clients par <a href="mailto:{country, select, gb {customerservices@graydon.co.uk} be {support@graydon.be} other {service@graydon.nl}}">{country, select, gb {customerservices@graydon.co.uk} be {support@graydon.be} other {service@graydon.nl}}</a> ou par téléphone au {country, select, gb {+44&nbsp;(0)20&nbsp;8515&nbsp;1400} be {+32&nbsp;(03)&nbsp;280&nbsp;8880} other {+31&nbsp;(020)&nbsp;567&nbsp;9501}}.',
  'cr.modal.report-download-error.title':
    'Votre rapport au format PDF est en train d’être préparé.',
  'cr.modal.report-error.graydon.be': 'Graydon Belgique',
  'cr.modal.report-error.graydon.gb': 'Graydon Royaume-Uni',
  'cr.modal.report-error.graydon.nl': 'Graydon Pays-Bas',
  'cr.modal.report-error.text': "Veuillez contacter Graydon pour obtenir de l'aide",
  'cr.modal.report-error.title': 'Impossible de récupérer les données',
  'cr.modal.report-creating.header': 'VEUILLEZ PATIENTER PENDANT QUE NOUS GÉNÉRONS LE RAPPORT',
  'cr.modal.report-creating.text': 'Téléchargement rapport...',
  'cr.modal.request-new-report.company-archive-link': "Voir l'entreprise",
  'cr.modal.request-new-report.open-in-new-tab': 'Ouvre dans un nouvel onglet',
  'cr.modal.request-new-report.request-report-button': 'Demander rapport',
  'cr.modal.request-new-report.text':
    "Voir les details de la société et l'histoire des demandes a la page de la société <a>{companyName}</a> ou choisir 'Plus' pour demander un report ou pour ajouter la société au Monitoring.\n",
  'cr.modal.request-new-report.title': 'Demander un rapport',
  'cr.nav.item.1': 'Résumé',
  'cr.nav.item.2': "Profile de l'entreprise",
  'cr.nav.item.3': 'Ratings',
  'cr.nav.item.4': 'Finances',
  'cr.nav.item.5': 'Comportement de paiement',
  'cr.nav.item.6': "Structure de l'entreprise",
  'cr.nav.item.7': 'Facteurs exceptionnels',
  'cr.payment-behavior.no-data-error.contacts':
    'Si des informations devraient figurer ici, merci de contacter notre équipe de Service Clients à l\'adresse suivante : <a href="mailto:{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}">{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}</a> ou par téléphone au {country, select, gb {+44 (020) 8515 1400} nl {+31 (020) 567 9501} other {+32 (03) 280 88 80}}.',
  'cr.payment-behavior.no-data-error.message':
    "Malheureusement, nous ne disposons pas d'informations sur le comportement de paiement de cette entreprise. Faites-vous affaire avec cette entreprise ? Partagez vos expériences de paiement avec nous et vous recevrez un rabais sur les solutions Graydon. Contactez-nous pour en savoir plus.",
  'cr.payment-behavior.no-data-error.title': 'Aucun information de paiement disponible',
  'cr.payment-behaviour.analysis-table.amountCategory': 'Montant total des paiements',
  'cr.payment-behaviour.analysis-table.legal.text':
    "Les écarts accidentels dans le comportement de paiement peuvent résulter de litiges ou d'accords de paiement spécifiques entre le fournisseur et l'entreprise.",
  'cr.payment-behaviour.analysis-table.numPaymentExperiences': 'Expériences de paiement',
  'cr.payment-behaviour.analysis-table.pctOver90DBT': 'plus de 90 jours',
  'cr.payment-behaviour.analysis-table.pctTo30DBT': 'entre 1 et 30 jours',
  'cr.payment-behaviour.analysis-table.pctTo60DBT': 'entre 31 et 60 jours',
  'cr.payment-behaviour.analysis-table.pctTo90DBT': 'entre 61 et 90 jours',
  'cr.payment-behaviour.analysis-table.pctWithinTerms': 'Dans les délais',
  'cr.payment-behaviour.analysis-table.quarter': 'Trimestre',
  'cr.payment-behaviour.analysis-table.suppliers': 'Nombre de fournisseurs',
  'cr.payment-behaviour.analysis-table.suppliers.text':
    "L'information de paiement est basée sur les expérience de {num} {num, plural, =1 {fournisseur} other {fournisseurs}}.",
  'cr.payment-behaviour.analysis-table.title': 'Analyse du comportement de paiement',
  'cr.payment-behaviour.analysis-table.tooltip':
    "Cette synthèse montre le détail des expériences de paiements traitées et dans quelle mesure les délais convenus sont respectés ou dépassés. Ceci n'est qu'une indication pour le trimestre en cours.",
  'cr.payment-behaviour.analysis-table.tooltip.BE':
    "Ce tableau couvre une période allant jusqu’à deux ans en arrière et indique le nombre d'expériences de paiement traitées au cours de la période concernée. Dans le tableau, vous trouverez également une ventilation des paiements effectués dans les délais convenus et le pourcentage effectué après la date d'échéance. Veuillez noter que les expériences de paiement peuvent concerner des comptes oubliés, des litiges... Le trimestre en cours n'est pas encore clôturé. Les données ne constituent donc qu'une première indication.",
  'cr.payment-behaviour.analysis-table.tooltip.NL':
    "Cet aperçu montre le nombre d'expériences de paiement traitées et dans quelle mesure les délais de paiement convenus sont respectés ou dépassés.",
  'cr.payment-behaviour.ccj-details-table.amount': 'Montant (&pound;)',
  'cr.payment-behaviour.ccj-details-table.case-number': 'Numéro de dossier',
  'cr.payment-behaviour.ccj-details-table.court': 'Cour',
  'cr.payment-behaviour.ccj-details-table.date': 'Date',
  'cr.payment-behaviour.ccj-details-table.header': 'Synthèse des injonctions',
  'cr.payment-behaviour.ccj-details-table.info':
    'Affichage maximum des 20 plus récentes injonctions sur un total de {totalCount}',
  'cr.payment-behaviour.ccj-details-table.no-data.text':
    "Il n'y a pas d'injonctions ou bien aucune information n'est actuellement disponible.<br /><br />Si vous avez des questions ou avez besoin d’aide, veuillez contacter notre équipe du Service Clients à l'adresse suivante : <a href=\"mailto:{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}\">{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}</a> ou par téléphone au {country, select, gb {+44&nbsp;(0)20&nbsp;8515&nbsp;1400} nl {+31&nbsp;(020)&nbsp;567&nbsp;9501} other {+32&nbsp;(03)&nbsp;280&nbsp;88&nbsp;80}}.",
  'cr.payment-behaviour.ccj-details-table.no-data.title': "Pas d'injonctions pour cette société",
  'cr.payment-behaviour.ccj-details-table.satisfied': "Levée (d'injonctions)",
  'cr.payment-behaviour.ccj-details-table.title': 'Détails des injonctions',
  'cr.payment-behaviour.ccj-details-table.tooltip':
    "Vous trouverez ici le détail des injonctions de paiement prononcées à l'encontre de cette société.",
  'cr.payment-behaviour.ccj-details-table.tooltip.GB':
    "Vous trouverez ici un résumé des injonctions de paiement prononcées à l'encontre de cette société, avec le montant total ainsi que le montant qui reste à régler. Sous le résumé vous trouverez le détail des 20 plus récentes injonctions de paiement.",
  'cr.payment-behaviour.ccj-details-table.type': 'Type',
  'cr.payment-behaviour.ccj-summaries-table.col.last18month': '18 Derniers mois',
  'cr.payment-behaviour.ccj-summaries-table.col.last18monthMonthlyAverage': 'Moyenne mensuelle',
  'cr.payment-behaviour.ccj-summaries-table.col.last36month': '36 Derniers mois',
  'cr.payment-behaviour.ccj-summaries-table.col.last36monthMonthlyAverage': 'Moyenne mensuelle',
  'cr.payment-behaviour.ccj-summaries-table.col.last72month': '72 Derniers mois',
  'cr.payment-behaviour.ccj-summaries-table.col.last72monthMonthlyAverage': 'Moyenne mensuelle',
  'cr.payment-behaviour.ccj-summaries-table.header': 'Résumé des injonctions',
  'cr.payment-behaviour.ccj-summaries-table.row.count': 'Nombre',
  'cr.payment-behaviour.ccj-summaries-table.row.highestAmount': 'Montant le plus élevé ',
  'cr.payment-behaviour.ccj-summaries-table.row.totalAmount': 'Montant total',
  'cr.payment-behaviour.ccj-summaries-table.row.unsettledAmount': 'Montant non régié',
  'cr.payment-behaviour.ccj-summaries-table.sub-header': 'Résumé',
  'cr.payment-behaviour.summons-and-judgments.no-data.text':
    "Cette société n'a pas de citation à comparaître ou de jugement de liquidation judiciaire ou bien aucun information n'est actuellement disponible.<br /><br />Si vous avez des questions ou avez besoin d’aide, veuillez contacter notre équipe du Service Clients à l'adresse suivante : <a href=\"mailto:{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}\">{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}</a> ou par téléphone au {country, select, gb {+44&nbsp;(0)20&nbsp;8515&nbsp;1400} nl {+31&nbsp;(020)&nbsp;567&nbsp;9501} other {+32&nbsp;(03)&nbsp;280&nbsp;88&nbsp;80}}.",
  'cr.payment-behaviour.summons-and-judgments.no-data.title':
    "Pas d'assignations ou de jugements disponible pour cette société",
  'cr.payment.behaviour.averages.disclaimer':
    'Tous ces chiffres font référence aux nombres de jours au-delà des délais.',
  'cr.payment.behaviour.averages.th.1': 'La société',
  'cr.payment.behaviour.averages.th.2': "Secteur d'industrie",
  'cr.payment.behaviour.averages.title': 'Montant moyen des paiements',
  'cr.payment.behaviour.averages.tooltip':
    "Cette section vous donne des détails à propos des montants moyens des paiements faits par cette société concernant les hypothèques en cours et compare avec le secteur d'industrie.",
  'cr.payment.behaviour.averages.tooltip.GB':
    "Cette section vous donne des détails à propos des montants moyens des paiements faits par cette société concernant les hypothèques en cours et compare avec le secteur d'industrie.",
  'cr.payment.behaviour.averages.tr.1': 'le mois dernier',
  'cr.payment.behaviour.averages.tr.2': 'dans les 3 derniers mois',
  'cr.payment.behaviour.averages.tr.3': 'dans les 6 derniers mois',
  'cr.payment.behaviour.averages.tr.4': 'dans les 12 derniers mois',
  'cr.payment.behaviour.graph.noData': 'Indisponible',
  'cr.payment.behaviour.graph.noData-short': 'na',
  'cr.payment.behaviour.graph.pctOver90DBT': 'plus de 90 jours',
  'cr.payment.behaviour.graph.pctOver90DBT-short': '> 90',
  'cr.payment.behaviour.graph.pctTo30DBT': 'entre 1 et 30 jours',
  'cr.payment.behaviour.graph.pctTo30DBT-short': 'entre 1 et 30',
  'cr.payment.behaviour.graph.pctTo60DBT': 'entre 31 et 60 jours',
  'cr.payment.behaviour.graph.pctTo60DBT-short': 'entre 31 et 60',
  'cr.payment.behaviour.graph.pctTo90DBT': 'entre 61 et 90 jours',
  'cr.payment.behaviour.graph.pctTo90DBT-short': 'entre 61 et 90',
  'cr.payment.behaviour.graph.pctWithinTerms': 'dans les délais',
  'cr.payment.behaviour.graph.pctWithinTerms-short': 'Selon les conditions',
  'cr.payment.behaviour.graph.quarter': 'Trimestre',
  'cr.payment.behaviour.graph.title': 'Comportement de paiement pour les 4 derniers trimestres',
  'cr.payment.behaviour.graph.tooltip':
    "Ce graphic indique si une société paye dans les délais. Veuillez noter qu'il ne s'agit pas d'une indication de solvabilité, mais juste du comportement de paiement de cette société.",
  'cr.payment.behaviour.graph.tooltip.BE':
    'La représentation graphique du comportement de paiement au cours des 4 derniers trimestres - divisée en une période dans les délais et différents segments en dehors du délai - permet de déduire rapidement si le comportement de paiement évolue dans un sens positif ou négatif.',
  'cr.payment.behaviour.graph.tooltip.NL':
    'Ici, vous pouvez voir le comportement de paiement au cours des quatre derniers trimestres et dans quelle mesure les délais de paiement convenus sont respectés ou dépassés.',
  'cr.payment.behaviour.mortgages.dateOfLastFullSatisfaction':
    "Date de la dernière (main)levée d'hypothèque",
  'cr.payment.behaviour.mortgages.dateOfLastMortgage': 'Date de la dernière hypothèque',
  'cr.payment.behaviour.mortgages.dateOfLastPartialSatisfaction':
    "Date de l'hypothèques partiellement levées",
  'cr.payment.behaviour.mortgages.numberFullySatisfied': "Nombre de (main)levées d'hypothèques",
  'cr.payment.behaviour.mortgages.numberPartiallySatisfied':
    "Nombre d'hypothèques partiellement levées",
  'cr.payment.behaviour.mortgages.title': 'Hypothèques et coûts',
  'cr.payment.behaviour.mortgages.tooltip':
    "Cette section détaille les plus récentes hypothèques, charges et mainlevées. Veuillez noter qu'il n'y a aucune obligation légale de soumettre le détail des (main)levées d'hypothèques ou de charges auprès de Companies House.",
  'cr.payment.behaviour.mortgages.tooltip.GB':
    "Cette section détaille les plus récentes hypothèques, charges et satisfactions. Veuillez noter qu'il n'y a pas d'obligation légale de déposer les détails de satisfaction des hypothèques/charges à la Companies House.",
  'cr.payment.behaviour.mortgages.totalNumberOfMortgages':
    'Total des charges et hypothèques enregistrées',
  'cr.payment.behaviour.payment.score.trend.disclaimer':
    'Cette section donne une indication de la tendance suivie par le Score de Paiement de cette société pour les 12 derniers mois.',
  'cr.payment.behaviour.payment.score.trend.title': 'Evolution du Score de Paiement',
  'cr.payment.behaviour.payment.score.trend.tooltip.BE':
    'Cette section donne une indication de la tendance suivie par le Score de Paiement de cette société pour les 12 derniers mois.',
  'cr.payment.behaviour.payment.score.trend.tooltip.GB':
    'Cette section donne une indication de la tendance suivie par le Score de Paiement de cette société pour les 12 derniers mois.',
  'cr.payment.behaviour.payment.score.trend.tooltip.NL':
    'Cette section donne une indication de la tendance suivie par le Score de Paiement de cette société pour les 12 derniers mois.',
  'cr.payment.behaviour.payment.trend.disclaimer':
    'Tous ces chiffres se réfèrent aux jours de retard pour {value} expériences de paiements en retard.',
  'cr.payment.behaviour.payment.trend.title': 'Days Beyond Terms Trend',
  'cr.payment.behaviour.payment.trend.tooltip':
    'Cette section vous indique les tendances de paiement de cette société en se référant à leurs paiements effectués au-delà des délais.',
  'cr.payment.behaviour.payment.trend.tooltip.BE':
    'La représentation graphique du comportement de paiement au cours des 4 derniers trimestres - divisée en une période dans les délais et différents segments en dehors du délai - permet de déduire rapidement si le comportement de paiement évolue dans un sens positif ou négatif.',
  'cr.payment.behaviour.payment.trend.tooltip.GB':
    'Cette section vous indique les tendances de paiement de cette société en se référant à leurs paiements effectués au-delà des délais.',
  'cr.payment.behaviour.payment.trend.tooltip.NL':
    'Ici, vous pouvez voir le comportement de paiement au cours des quatre derniers trimestres et dans quelle mesure les délais de paiement convenus sont respectés ou dépassés.',
  'cr.payment.behaviour.registeredCharges.andOthers': 'et autres.',
  'cr.payment.behaviour.registeredCharges.dateCreated': 'Date de création',
  'cr.payment.behaviour.registeredCharges.dateRegistered': 'Date enregistrée',
  'cr.payment.behaviour.registeredCharges.dateSatisfied': 'Date de la (main)levée complétée',
  'cr.payment.behaviour.registeredCharges.description': 'Description de l’ hypothèque',
  'cr.payment.behaviour.registeredCharges.disclaimer':
    'Affiche au maximum 20 des hypothèques et coûts les plus récents',
  'cr.payment.behaviour.registeredCharges.lender': 'Prêteur',
  'cr.payment.behaviour.registeredCharges.no-data-text':
    'Cette société n’a pas d’hypothèques ou bien aucune information n\'est disponible.<br/><br/>Si vous avez des questions ou avez besoin d’aide, veuillez contacter notre équipe du Service Clients à l\'adresse suivante : <a href="mailto:{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}">{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}</a> ou par téléphone au {country, select, gb {+44&nbsp;(0)20&nbsp;8515&nbsp;1400} nl {+31&nbsp;(020)&nbsp;567&nbsp;9501} other {+32&nbsp;(03)&nbsp;280&nbsp;88&nbsp;80}}.',
  'cr.payment.behaviour.registeredCharges.no-data-title': 'Cette société n’a pas d’hypothèques.',
  'cr.payment.behaviour.registeredCharges.summary.current': 'Valeur actuelle',
  'cr.payment.behaviour.registeredCharges.summary.title': 'Résumé des hypothèques et des coûts',
  'cr.payment.behaviour.registeredCharges.summary.totals': 'Totaux',
  'cr.payment.behaviour.registeredCharges.title': 'Hypothèques et coûts',
  'cr.payment.behaviour.summons-and-judgments.date': 'Date',
  'cr.payment.behaviour.summons-and-judgments.description': 'Description',
  'cr.payment.behaviour.summons-and-judgments.info':
    'Affichage maximum des 20 plus récentes assignations et jugements pour un montant total de {totalCount}',
  'cr.payment.behaviour.summons-and-judgments.InsuranceAndSelfEmployed':
    "Assignation(s) Caisse d'Assurance Sociale pour Indépendants",
  'cr.payment.behaviour.summons-and-judgments.RSZ': 'ONSS',
  'cr.payment.behaviour.summons-and-judgments.SocialSecurityFund':
    "Assignation(s) Fonds de Sécurité d'Emploi",
  'cr.payment.behaviour.summons-and-judgments.title': 'Assignations',
  'cr.payment.behaviour.summons-and-judgments.tooltip':
    "Une assignation de paiement peut résulter d'éléments comptables non pris en compte, de litiges, etc...",
  'cr.payment.behaviour.summons-and-judgments.tooltip.BE':
    "Dans cette section, vous trouverez un aperçu des citations à comparaître de l'Office national de la sécurité sociale, de la Caisse d'assurance sociale pour travailleurs indépendants ou du Fonds de sécurité d’existence, avec la date du jour de la séance. Veuillez noter que la convocation peut être le résultat de comptes oubliés, de litiges, etc. C’est pourquoi chaque entreprise reçoit également une lettre leur demandant de nous fournir plus d’informations sur les citations en question.\n",
  'cr.payment.behaviour.summons-and-judgments.tooltip.NL':
    'Veuillez noter que la convocation peut être le résultat de comptes oubliés, de litiges, ...',
  'cr.payment.behaviour.unsecuredCreditors.amount': 'Montant',
  'cr.payment.behaviour.unsecuredCreditors.companyName': 'Nom de la société',
  'cr.payment.behaviour.unsecuredCreditors.disclaimer':
    'Affichage des 20 réclamations les plus élevées sur un total de {totalCount}',
  'cr.payment.behaviour.unsecuredCreditors.no-data-text':
    'Cette société n’a listé aucun créancier chirographaire ou bien aucune information n\'est actuellement archivée.<br/><br/>Si vous avez des questions ou avez besoin d’aide, veuillez contacter notre équipe du Service Clients à l\'adresse suivante : <a href="mailto:{country, select, gb\n{customerservices@graydon.co.uk} nl {service@graydon.nl} other\n{support@graydon.be}}">{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}</a> ou par téléphone au {country, select, gb {+44&nbsp;(0)20&nbsp;8515&nbsp;1400} nl {+31&nbsp;(020)&nbsp;567&nbsp;9501} other {+32&nbsp;(03)&nbsp;280&nbsp;88&nbsp;80}}.',
  'cr.payment.behaviour.unsecuredCreditors.no-data-title':
    'Aucun créanciers chirographaires listé pour cette société.',
  'cr.payment.behaviour.unsecuredCreditors.statementDate': 'Date du relevé',
  'cr.payment.behaviour.unsecuredCreditors.summary': 'Résumé des créanciers chirographaires',
  'cr.payment.behaviour.unsecuredCreditors.tabTitle': 'Créanciers chirographaires',
  'cr.payment.behaviour.unsecuredCreditors.tooltip':
    'Cette liste inclut les créanciers chirographaires tels qu’indiqués à la date du relevé.',
  'cr.payment.behaviour.unsecuredCreditors.totalNumber':
    'Nombre total de créanciers chirographaires',
  'cr.payment.behaviour.unsecuredCreditors.totalValue': 'Valeur totale de la dette sans garantie',
  'cr.payment.behaviour.unsecuredCreditors.unknown': 'Inconnu',
  'cr.pdf-archive.available.actions': "Effectuer l'une de ces actions pour cette entreprise ",
  'cr.pdf-archive.download.button.title': 'Téléchargez le PDF',
  'cr.pdf-archive.download.button.title.en': 'PDF en anglais',
  'cr.pdf-archive.download.button.title.fr': 'PDF en français',
  'cr.pdf-archive.download.button.title.nl': 'PDF en néerlandais',
  'cr.pdf-archive.item.title': 'Rapport de crédit',
  'cr.pdf-archive.item.button.no-avail': "Le téléchargement n'est plus disponible",
  'cr.pdf-archive.max.reports.message':
    'Seules les 50 dernières actions sont affichées.<br /><br />Si vous avez des questions, veuillez contacter notre équipe du Service Clients à l\'adresse suivante : <a href="mailto:{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}">{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}</a> ou par téléphone au {country, select, gb {+44 (020) 8515 1400} nl {+31 (020) 567 9501} other {+32 (03) 280 88 80}}.',
  'cr.pdf-archive.previous.actions': 'Demandes précédentes',
  'cr.pdf-archive.title': 'Actions pour cette entreprise',
  'cr.preferences': 'Préférences',
  'cr.preferences.cancel-button': 'Annuler',
  'cr.preferences.default-list.no-list-message.part.one':
    'Pour pouvoir utiliser cette option, vous devez avoir créé au moins une liste. ',
  'cr.preferences.default-list.no-list-message.part.two':
    "Vous pouvez configurer des listes dans l'application de gestion de liste.",
  'cr.preferences.default.list-preference.description':
    'En activant cette option, chaque rapport de crédit dont vous ferez la demande pour une société sera automatiquement ajouté à la liste par défaut. Vous pouvez utiliser une liste pour surveiller une société, pour enrichir des données, ou pour des exports de données.\n\nSi vous préférez choisir, à chaque fois, la liste à laquelle ajouter la société à surveiller, il vous suffit de laisser cette option désactivée.',
  'cr.preferences.default.list-select.list.title': 'Sélectionner une liste',
  'cr.preferences.default.list-title': 'Voir le rapport et ajouter la société à la liste.',
  'cr.preferences.default.list-toggle.label': 'Ajouter société à la list par défaut',
  'cr.preferences.default.list-toggle.title': "Activer l'option de list par défaut",
  'cr.preferences.default.list-tooltip':
    'Selon vos paramètres sélectionnés, la société pour laquelle vous avez demandé un rapport de crédit sera automatiquement ajoutée à la liste par défaut.',
  'cr.preferences.default.list.select.list-monitoring.info':
    'Cette liste a <b>{numberOfProfilesAttachedToSelectedList}</b> profils de surveillance.',
  'cr.preferences.default.list.select.list-monitoring.info.tooltip.body':
    "Si une liste est connectée à un profil de surveillance, vous recevrez des mises à jour lorsque les données changent dans l'entreprise. Vous pouvez connecter une liste à un profil de surveillance dans l'application de surveillance.",
  'cr.preferences.default.list.select.list-monitoring.info.tooltip.title':
    'Profils de surveillance',
  'cr.preferences.save-button': 'Sauvegarder les préférences',
  'cr.preferences.title': 'Préférences',
  'cr.preferences.tosti.error.body':
    'Il semble y avoir eu une erreur inattendue. Veuillez rafraîchir la page ou réessayer plus tard.',
  'cr.preferences.tosti.error.title': "Une erreur s'est produite",
  'cr.preferences.tosti.success.body':
    'Chaque nouvelle requête d’un rapport de crédit pour une société sera ajouté à la liste sélectionnée.',
  'cr.preferences.tosti.success.title': 'Vos préférences ont bien été mises à jour',
  'cr.ratings.credit-rating.0': 'Solidité financière de moins de £1',
  'cr.ratings.credit-rating.A': 'Niveau de risque global Excellent',
  'cr.ratings.credit-rating.B': 'Niveau de risque global Bon',
  'cr.ratings.credit-rating.C': 'Niveau de risque global Passable',
  'cr.ratings.credit-rating.D': 'Niveau de risque global Faible',
  'cr.ratings.credit-rating.description':
    'Cette notation indique la solvabilité en se basant sur la solidité financière ainsi que des informations sectorielles spécifiques.',
  'cr.ratings.credit-rating.N': 'Société récemment constituée',
  'cr.ratings.credit-rating.na': 'Indisponible',
  'cr.ratings.credit-rating.NA': 'Indisponible',
  'cr.ratings.credit-rating.NIL': 'Aucun comptes annuels déposés ou informations négatives',
  'cr.ratings.credit-rating.P': 'Lien parental',
  'cr.ratings.credit-rating.S': 'Notation spéciale',
  'cr.ratings.credit-rating.SN': 'Notation spéciale',
  'cr.ratings.credit-rating.title': 'Notation de crédit',
  'cr.ratings.credit-rating.tooltip':
    'Le Credit Rating est une notation alphanumérique à interpréter en combinaison avec une politique de crédit. Cette notation se base sur toutes les informations et données de notre base de données et résume notre opinion générale sur le sujet de votre recherche.',
  'cr.ratings.credit-rating.tooltip.BE':
    "La Notation Graydon indique le degré de risque de crédit. La note AAA est la note la plus élevée et indique le risque le plus faible possible. La note C est la note la plus faible et indique un risque très élevé. Les entreprises avec une note D sont en état de faillite. NR signifie qu'aucune note n'a pu être définie car l'entreprise est passive. Le risque de faillite indique l’importance du risque que l’entreprise connaisse de graves problèmes financiers et fasse faillite. Plus le pourcentage est bas, plus le risque est faible.",
  'cr.ratings.credit-rating.tooltip.GB':
    "Remarque : Pour bénéficier du dernier modèle de risque, veuillez vous référer à la Notation Graydon ou au Augur Score. La Notation de crédit sera supprimée à la fin de septembre 2021. Veuillez contacter votre gestionnaire de compte pour plus d'informations.\n\nLa Notation de crédit Graydon est une notation alphanumérique qui se base sur toutes les informations et données de notre base de données. Elle résume notre opinion générale et le guide de crédit basé sur le comportement et la performance financière récente du sujet de votre recherche (A - Excellent; B - Bien; C - Moyen; D - Mauvais).",
  'cr.ratings.credit-rating.tooltip.NL':
    "La Notation Graydon indique le degré de risque de crédit, la note AAA étant la note la plus élevée possible et la note C la plus basse. D indique que l'entreprise est en état de faillite. NR indique qu'aucune note n'a pu être définie car l'entreprise est passive. Le risque de faillite donne une idée du risque que l’entreprise connaisse de graves problèmes financiers. Plus le pourcentage est élevé, plus le risque est grand.",
  'cr.ratings.credit-rating.X': 'Redressement judiciaire ou liquidation',
  'cr.ratings.credit.flag.description': 'La limite de crédit conseillée pour cette société.',
  'cr.ratings.credit.flag.information.G': "Pas d'objection à l'ouverture d'un crédit",
  'cr.ratings.credit.flag.information.O': 'Le crédit peut être ouvert mais devrait être surveillé',
  'cr.ratings.credit.flag.information.R': "Il n'est pas conseillé d'ouvrir un crédit",
  'cr.ratings.credit.flag.information.text':
    'Cette estimation se fonde sur la limite de crédit et la Notation Graydon.',
  'cr.ratings.credit.flag.limit.tooltip':
    "**Credit flag**\n\nLe drapeau de crédit fonctionne comme un feu de signalisation et représente, en bref, une simple traduction de l’avis de crédit et de la Notation Graydon. **[Vert](#green)** = l’octroi d’un crédit ne pose aucun problème. **[Orange](#orange)** = l'octroi d'un crédit s’accompagne d’un risque accru. **[Rouge](#red)** = Graydon déconseille tout préfinancement.\n\n\n**Credit limit**\n\nLa limite de crédit indique le montant jusqu'auquel vous pouvez faire affaire avec une entreprise. La limite de crédit est un jugement de valeur à court terme.",
  'cr.ratings.credit.flag.maximum-limit': 'La limite maximale de crédit',
  'cr.ratings.credit.flag.monthly-credit-guide': 'Guide de crédit mensuel',
  'cr.ratings.credit.flag.title': 'Drapeau de crédit & limite de crédit',
  'cr.ratings.credit.flag.tooltip':
    "La limite de crédit indique le montant qu'une entreprise peut libérer à court terme pour rembourser ses dettes impayées.",
  'cr.ratings.credit.flag.tooltip.BE':
    "Le drapeau de crédit indique de manière simple dans quelle mesure il est prudent d'octroyer un crédit à une entreprise. Vert = l’octroi d’un crédit ne pose aucun problème. Orange = l'octroi d'un crédit s’accompagne d’un risque accru. Rouge = Graydon déconseille tout octroi de crédit. \nLa limite de crédit indique le montant maximum de liquidités qu'une entreprise peut dégager à court terme pour rembourser ses dettes.",
  'cr.ratings.credit.flag.tooltip.GB':
    "Le drapeau de crédit agit comme des feux de circulation. Vert = l'octroi de crédit est peu susceptible de causer des problèmes. Orange = l'octroi de crédit est associé à un risque accru. Rouge = Graydon déconseille l'octroi de crédit. La limite de crédit indique le montant jusqu'à concurrence duquel vous pouvez faire affaire avec une entreprise. La limite de crédit est un jugement de valeur à court terme.",
  'cr.ratings.credit.limit.trend.title': 'Tendance de la Limite de Crédit',
  'cr.ratings.credit.limit.trend.tooltip':
    'Cette section présente les tendances de la limite de crédit pour cette société durant les 12 derniers mois.',
  'cr.ratings.delphi.index.1': 'Delphi index 1, risque accru',
  'cr.ratings.delphi.index.2': 'Delphi index 2, risque accru',
  'cr.ratings.delphi.index.3': 'Delphi index 3, risque accru',
  'cr.ratings.delphi.index.4': 'Delphi index 4, risque moyen',
  'cr.ratings.delphi.index.5': 'Delphi index 5, risque moyen',
  'cr.ratings.delphi.index.6': 'Delphi index 6, risque moyen',
  'cr.ratings.delphi.index.7': 'Delphi index 7, risque faible',
  'cr.ratings.delphi.index.8': 'Delphi index 8, risque faible',
  'cr.ratings.delphi.index.9': 'Delphi index 9, risque faible',
  'cr.ratings.delphi.index.personal.credit.information': 'Indication de crédit personnel',
  'cr.ratings.delphi.index.tooltip':
    "**Credit flag**\n\nLe drapeau de crédit fonctionne comme un feu de signalisation et représente, en bref, une simple traduction de l’avis de crédit et de la Notation Graydon. **[Vert](#green)** = l’octroi d’un crédit ne pose aucun problème. **[Orange](#orange)** = l'octroi d'un crédit s’accompagne d’un risque accru. **[Rouge](#red)** = Graydon déconseille tout préfinancement.\n\n**Credit limit**\n\nLa limite de crédit indique le montant jusqu'auquel vous pouvez faire affaire avec une entreprise. La limite de crédit est un jugement de valeur à court terme.\n\n\n**Indication de crédit personnel**\n\nL’indice Delphi est une indication de crédit personnel pour une personne physique, prenant en compte les enregistrements négatifs auprès d’huissiers de justice, d’agences de recouvrement de créances, etc…\nPlus l'indice Delphi est élevé, plus le niveau de risque est faible. L'index Delphi est établi par Experian.",
  'cr.ratings.discontinuation-score.1': 'Très élevée',
  'cr.ratings.discontinuation-score.2': 'Élevée',
  'cr.ratings.discontinuation-score.3': 'Neutre',
  'cr.ratings.discontinuation-score.4': 'Basse',
  'cr.ratings.discontinuation-score.5': 'Très basse',
  'cr.ratings.discontinuation-score.description':
    "La probabilité qu'une société cesse ses activités dans les 12 prochains mois",
  'cr.ratings.discontinuation-score.NA': 'Indisponible',
  'cr.ratings.discontinuation-score.NR': 'Pas notée',
  'cr.ratings.discontinuation-score.title': 'Probabilité de discontinuité',
  'cr.ratings.discontinuation-score.tooltip':
    "La probabilité de discontinuité prédit l'éventualité qu'a une entreprise de cesser ses activité dans les 12 prochains mois (et prend également en considération la probabilité de liquidation, reprise et fusion).",
  'cr.ratings.discontinuation-score.tooltip.BE':
    "La probabilité de discontinuité prédit la probabilité qu'une entreprise cesse d'exister au cours des 12 prochains mois. Le score ne prend pas en compte à cet égard le risque de faillite ou la LCE (loi relative à la continuité des entreprises), mais le risque de dissolution, de fusion, de reprise et de cessation d’activité.",
  'cr.ratings.discontinuation-score.tooltip.NL':
    "La probabilité de discontinuité prédit la probabilité qu'une entreprise cesse d'exister au cours des 12 prochains mois.",
  'cr.ratings.graydon.rating.trend.title': 'Tendance de la Notation Graydon',
  'cr.ratings.graydon.rating.trend.tooltip':
    'Cette section montre l’évolution de la Notation Graydon pour cette société au cours des 12 derniers mois. ',
  'cr.ratings.growth-score.1': 'Déclin profond',
  'cr.ratings.growth-score.2': 'Déclin',
  'cr.ratings.growth-score.3': 'Neutre',
  'cr.ratings.growth-score.4': 'Croissance',
  'cr.ratings.growth-score.5': 'Croissance élevée',
  'cr.ratings.growth-score.99': 'Imprévisible',
  'cr.ratings.growth-score.description':
    "La possibilité qu'a une société d'augmenter ses actif totaux au cours des 12 prochains mois.",
  'cr.ratings.growth-score.NA': 'Indisponible',
  'cr.ratings.growth-score.NR': 'Pas notée',
  'cr.ratings.growth-score.title': 'Probabilité de croissance',
  'cr.ratings.growth-score.tooltip':
    "Le score de croissance indique la croissance organique potentielle d'une entreprise (sans recours immédiat à des capitaux externes) dans des conditions économiques normales.\nVeuillez noter que compte tenu de la crise du coronavirus, on ne peut plus parler de conditions économiques normales, de sorte que les prévisions de croissance pourraient s’écarter de la réalité",
  'cr.ratings.growth-score.tooltip.BE':
    "La prévision de croissance donne une indication sur les opportunités de croissance potentielles d'une entreprise pour les 12 prochains mois dans des conditions économiques normales. Elle détermine dans quelle mesure une entreprise peut financer sa propre croissance, sans recours immédiat à des capitaux externes. La prévision de croissance n’est disponible que pour les entreprises qui publient des comptes annuels. L’indication ‘imprévisible’ veut dire que la prévision de croissance est volatile ou trop variable.\nVeuillez noter que compte tenu de la crise du coronavirus, on ne peut plus parler de conditions économiques normales, de sorte que les prévisions de croissance pourraient s’écarter de la réalité.\n",
  'cr.ratings.growth-score.tooltip.GB':
    "Il s'agit d'une prévision ciblée de la croissance prévue de l'actif total de la société au cours des 12 prochains mois dans des conditions économiques normales. L’indication ‘imprévisible’ veut dire que la prévision de croissance est volatile ou trop variable.\nVeuillez noter que compte tenu de la crise du coronavirus, on ne peut plus parler de conditions économiques normales, de sorte que les prévisions de croissance pourraient s’écarter de la réalité.",
  'cr.ratings.growth-score.tooltip.NL':
    'La prévision de croissance donne un pronostic ciblé de la croissance attendue du total des actifs d’entreprises et de filiales au cours des douze prochains mois dans des conditions économiques normales. L’indication ‘imprévisible’ veut dire que la prévision de croissance est volatile ou trop variable.\nVeuillez noter que compte tenu de la crise du coronavirus, on ne peut plus parler de conditions économiques normales, de sorte que les prévisions de croissance pourraient s’écarter de la réalité.',
  'cr.ratings.no.delphi.index': 'Aucun indice Delphi récupéré ',
  'cr.ratings.payment-score.description':
    'Dans quelle mesure cette société honore ses factures dans les temps.  ',
  'cr.ratings.payment-score.lower-bound': 'Les délais de paiement ne sont pas respectés',
  'cr.ratings.payment-score.NR': 'Pas notée',
  'cr.ratings.payment-score.range-sector-1': 'Les délais de paiement ne sont pas respectés',
  'cr.ratings.payment-score.range-sector-2': 'Inférieur à la moyenne',
  'cr.ratings.payment-score.range-sector-3': 'La moyenne',
  'cr.ratings.payment-score.range-sector-4': 'Bon comportement de paiement',
  'cr.ratings.payment-score.title': 'Score de paiement',
  'cr.ratings.payment-score.tooltip':
    "Le score de paiement et le comportement de paiement vous indiquent si votre relation d'affaires pourra vous payer à court terme.",
  'cr.ratings.payment-score.tooltip.BE':
    "Le score de paiement vous indique dans quelle mesure le client a tendance à respecter les délais de paiement convenus.\nNotez la différence avec la limite de crédit, qui indique si votre client a la capacité de payer ses fournisseurs à court terme. La limite de crédit indique si votre client peut payer. Le score de paiement indique si le client le fera réellement. Plus le score de paiement est élevé (sur une échelle de 0 à 10), meilleur est son comportement de paiement.\nLe score de paiement est le résultat d'un projet d'échange dans le cadre duquel des milliers de fournisseurs partagent des millions d'expériences de paiement entre eux. Discret et anonyme. De cette façon, tous les participants signalent les mauvais payeurs, mais récompensent aussi les clients qui paient bien. Participer au projet d'échange Data Exchange Pooling de Graydon ne vous coûte rien. Au contraire, vous vous montrez solidaire et vous recevez une ristourne commerciale en échange de votre contribution. Contactez-nous pour en savoir plus.",
  'cr.ratings.payment-score.tooltip.GB':
    "Le score de paiement indique dans quelle mesure une organisation paie ses factures à temps. La règle suivante s'applique ici : plus le score de paiement est élevé, plus l'entreprise paie ses factures à temps.",
  'cr.ratings.payment-score.tooltip.NL':
    "Le score de paiement indique dans quelle mesure une organisation paie ses factures à temps. Ce qui suit est d’application : plus le score de paiement est élevé, plus l'entreprise paie ses factures à temps.",
  'cr.ratings.payment-score.upper-bound': 'Bon comportement de paiement',
  'cr.ratings.rating.augur-rating': '(Augur score: { augurRating })',
  'cr.ratings.rating.description': 'Pourcentage probabilité de défaillance',
  'cr.ratings.rating.information.A': 'Risque de défaut de paiement faible',
  'cr.ratings.rating.information.AA': 'Risque de défaut de paiement faible',
  'cr.ratings.rating.information.AAA': 'Risque de défaut de paiement très faible',
  'cr.ratings.rating.information.B': 'Risque de défaut de paiement neutre',
  'cr.ratings.rating.information.BB': 'Risque de défaut de paiement neutre',
  'cr.ratings.rating.information.BBB': 'Risque de défaut de paiement neutre',
  'cr.ratings.rating.information.C': 'Risque de défaut de paiement très élevé',
  'cr.ratings.rating.information.CC': 'Risque de défaut de paiement supérieur à la moyenne',
  'cr.ratings.rating.information.CCC': 'Risque de défaut de paiement supérieur à la moyenne',
  'cr.ratings.rating.information.D': 'La société est en faillite',
  'cr.ratings.rating.information.NA': 'Le risque de défaut de paiement ne peut pas être déterminé.',
  'cr.ratings.rating.information.NR': "La société n'est pas notée",
  'cr.ratings.rating.information.text':
    'Cette notation se fonde sur des informations sectorielles, les comptes annuels disponibles et le comportement de paiement.',
  'cr.ratings.rating.multiscore': '(Multiscore: { multiscore })',
  'cr.ratings.rating.placeholder': 'La probabilité de défaillance dans les 12 prochains mois.',
  'cr.ratings.rating.title': 'Multiscore & pourcentage probabilité de défaillance',
  'cr.ratings.rating.tooltip':
    'Cliquez ici pour en savoir plus quant à la probabilité de défaut de paiement et la Notation Graydon',
  'cr.ratings.rating.tooltip.BE':
    "La Notation Graydon indique le degré de risque de crédit. La note AAA est la note la plus élevée et indique le risque le plus faible possible. La note C est la note la plus faible et indique un risque très élevé. Les entreprises avec une note D sont en état de faillite. NR signifie qu'aucune note n'a pu être définie car l'entreprise est passive. Le risque de faillite indique l’importance du risque que l’entreprise connaisse de graves problèmes financiers et fasse faillite. Plus le pourcentage est bas, plus le risque est faible.",
  'cr.ratings.rating.tooltip.GB':
    "La Notation Graydon indique le degré de risque de crédit, la notation AAA étant la plus élevée possible et la notation C, la plus basse. D indique que le client est dans une situation de faillite. NR indique qu'aucune notation n'a pu être attribuée parce que l'entreprise est passive. Le risque de faillite donne une idée du risque que l'entreprise connaisse de graves problèmes financiers. Plus le pourcentage est élevé, plus le risque est élevé. L’indication NAN – not a number (pas un nombre) – veut dire que le score Augur n’est pas disponible.",
  'cr.ratings.rating.tooltip.NL':
    "La Notation Graydon indique le degré de risque de crédit, la note AAA étant la note la plus élevée possible et la note C la plus basse. D indique que l'entreprise est en état de faillite. NR indique qu'aucune note n'a pu être définie car l'entreprise est passive. Le risque de faillite donne une idée du risque que l’entreprise connaisse de graves problèmes financiers. Plus le pourcentage est élevé, plus le risque est grand.",
  'cr.ratings.trends.tile.title': 'Evolution du Scores',
  'cr.search-gb.fresh.investigation.disclaimer':
    "Les enquêtes sur des entreprises constituées et non constituées en société peuvent être demandées via le bouton 'Nouvelle enquête' en haut de cette page.\n",
  'cr.search.annual-account': 'Comptes annuels',
  'cr.search.coc-extract': 'Extrait du registre de commerce',
  'cr.search.credit-report': 'Rapport de crédit',
  'cr.search.gdm-check': 'Vérification avec le modèle de décision',
  'cr.search.international-credit-report': 'Rapport international de crédit',
  'cr.search.more': 'plus',
  'cr.search.results.no.data.link-text': 'veuillez demander une nouvelle investigation.',
  'cr.search.results.no.data.main-text':
    'Si vous recherchez une entreprise non constituée en société,',
  'cr.search.view-company-archive': "Voir l'entreprise",
  'cr.search.view-raw-data': 'Afficher données',
  'cr.search.viewReport': 'Consulter rapport',
  'cr.search.xseptions.report': 'Network incl. XSeptions',
  'cr.specifics.events.category': 'Catégorie',
  'cr.specifics.events.date': 'Date',
  'cr.specifics.events.description':
    'Vous trouverez ici une liste et la description des événements qui se sont produits pour cette société.',
  'cr.specifics.events.description.BE':
    'Vous trouverez ici un aperçu de tous les actes publiés dans les Annexes du Moniteur belge, y compris une brève description du contenu.',
  'cr.specifics.events.description.GB':
    'Voici la liste et la description des événements qui se sont produits concernant cette société.',
  'cr.specifics.events.description.history.BE':
    "Vous trouverez ici un aperçu de tous les actes qui contiennent des changements de montants, de noms de sociétés, d'adresses etc. et qui sont publiés dans les Annexes du Moniteur belge, y compris une brève description du contenu.",
  'cr.specifics.events.disclaimer': 'Affichage maximum des 50 plus récentes événements',
  'cr.specifics.events.disclaimer.BE':
    'Affichage maximum des 50 plus récentes événements historique et autres ensemble',
  'cr.specifics.events.event-description': "Description de l'événement - Détails",
  'cr.specifics.events.event-description.BE': "Description de l'événement",
  'cr.specifics.events.event-description.hideDetails': "Masquer détails d'événement ",
  'cr.specifics.events.event-description.showAll': 'Lire la suite',
  'cr.specifics.events.eventReference': 'Numéro de statut AMB',
  'cr.specifics.events.no-data.text':
    "Il n'y a aucun événement pour cette société ou bien aucune information n'est disponible.<br /><br />Si vous avez des questions ou avez besoin d'aide, veuillez contacter notre équipe du Service Clients à l'adresse suivante : <a href=\"mailto:{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}\">{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}</a> ou par téléphone au {country, select, gb {+44&nbsp;(0)20&nbsp;8515&nbsp;1400} nl {+31&nbsp;(020)&nbsp;567&nbsp;9501} other {+32&nbsp;(03)&nbsp;280&nbsp;88&nbsp;80}}.",
  'cr.specifics.events.no-data.title': 'Aucun événement disponible pour cette société',
  'cr.specifics.events.source-description': 'Source',
  'cr.specifics.financial-insolvencies.title': 'Insolvabilités {extra}',
  'cr.specifics.financial-insolvencies.tooltip':
    'Plus de détails à propos des calamités financières disponibles ici.',
  'cr.specifics.financial-insolvencies.tooltip.BE':
    'Dans cette section, vous trouverez un aperçu des incidents financiers pour cette entreprise et tous les détails connexes.',
  'cr.specifics.financial-insolvencies.tooltip.GB':
    "Vous trouverez ici les détails de tous les dossiers d'insolvabilité concernant la société.",
  'cr.specifics.financial-insolvencies.tooltip.NL':
    'Vous trouverez ici plus de détails sur les éventuelles calamités financières de cette entreprise.',
  'cr.specifics.tab.events': 'Événements {extra}',
  'cr.specifics.tab.events.BE': 'Historique {extra}',
  'cr.specifics.tab.xseptions.title': 'XSeptions {extra}',
  'cr.specifics.tab.xseptions.title.BE': 'XSeptions {extra}',
  'cr.specifics.tab.xseptions.title.GB': 'XSeptions {extra}',
  'cr.specifics.tab.xseptions.title.NL': 'XSeptions {extra}',
  'cr.specifics.xseptions.category': 'Catégorie',
  'cr.specifics.xseptions.date': 'Date',
  'cr.specifics.xseptions.description': 'Description',
  'cr.specifics.xseptions.description.BE':
    'XSeptions analyse les relations de premier et de second degré (entreprises et/ou dirigeants) pour déceler des situations sortant de l’ordinaire. Cela peut inclure une mention sur des listes de sanctions, des structures d’entreprises opaques ou des faillites récentes du dirigeant.',
  'cr.specifics.xseptions.description.GB':
    "Voici les détails de XSeptions pour cette compagnie. Veuillez noter qu'une XSeption n'est enregistrée que lorsque des comportements ou des résultats financiers inhabituels ont été identifiés.",
  'cr.specifics.xseptions.description.NL':
    'XSeptions analyse les relations de premier et de second degré (entreprises et/ou dirigeants) pour déceler des situations sortant de l’ordinaire. Cela peut incluredes structures d’entreprises opaques ou des faillites récentes du dirigeant.',
  'cr.specifics.xseptions.no-data.text':
    "Il n'y a aucune XSeption pour cette société ou bien il n'y a aucune information n’est actuellement disponible.<br /><br />Si vous avez des questions ou avez besoin d’aide, veuillez contacter notre équipe du Service Clients à l'adresse suivante : <a href=\"mailto:{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}\">{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}</a> ou par téléphone au {country, select, gb {+44&nbsp;(0)20&nbsp;8515&nbsp;1400} nl {+31&nbsp;(020)&nbsp;567&nbsp;9501} other {+32&nbsp;(03)&nbsp;280&nbsp;88&nbsp;80}}.",
  'cr.specifics.xseptions.no-data.text.condensed':
    "Il n'y a aucune XSeption pour cette société ou bien il n'y a aucune information n’est actuellement disponible.",
  'cr.specifics.xseptions.no-data.title': 'Aucune XSeption disponible pour cette société',
  'cr.specifics.xseptions.start-date': 'Date de début',
  'cr.specifics.xseptions.uk-description.GB_1':
    'La personne concernée a modifié l’adresse enregistrée {value1} fois au cours de la période du {date1} au {date2}.',
  'cr.specifics.xseptions.uk-description.GB_2':
    'La personne concernée a soumis deux séries de comptes à {value1} jours d’intervalle. Les comptes ont été enregistrés le {date1} puis le {date2}.',
  'cr.specifics.xseptions.uk-description.GB_3':
    'Les comptes des personnes concernées enregistrés le {date1} semblent identiques à ceux soumis par la société {text} sous le numéro d’enregistrement, pour le {date2}.',
  'cr.specifics.xseptions.uk-description.GB_4':
    'La personne concernée a enregistré ses premiers comptes le {date2} après avoir été admise le {date1}.',
  'cr.specifics.xseptions.uk-description.GB_5':
    '{value1} les administrateurs ont démissionné de leur fonction le même jour {date1}.',
  'cr.specifics.xseptions.uk-description.GB_6':
    'Des renseignements commerciaux d’une importance critique potentielle ont été collectés sur la Personne concernée.',
  'cr.specifics.xseptions.uk-description.GB_7':
    'La personne concernée a enregistré ses derniers comptes très peu de temps après la date de clôture de l’exercice. La fin du dernier exercice financier du {date2} a été enregistrée le {date1}.',
  'cr.specifics.xseptions.uk-description.GB_8':
    'Le sujet a nommé un administrateur le {date1}, lequel était associé à une/{value1} sociétés ayant récemment fait l’objet d’une procédure en insolvabilité.',
  'cr.specifics.xseptions.uk-description.GB_9':
    'Aucune publication des comptes annuels pendant 2 années consécutives',
  'cr.summary.company.details.activitiesDescription': 'Activités ({codeType} code)',
  'cr.summary.company.details.email': 'Adresse e-mail',
  'cr.summary.company.details.graydonNumber': 'Numéro Graydon',
  'cr.summary.company.details.incorporationDate': 'Date de constitution',
  'cr.summary.company.details.legalForm': 'Forme juridique',
  'cr.summary.company.details.numberOfBranches': 'Nombre de filiales',
  'cr.summary.company.details.phoneNumber': 'Numéro de téléphone',
  'cr.summary.company.details.registrationId': "Identification d'enregistrement",
  'cr.summary.company.details.registrationId.BE': "Numéro d'entreprise",
  'cr.summary.company.details.registrationId.GB': "Numéro d'entreprise",
  'cr.summary.company.details.registrationId.NL': "Numéro d'identification (KvK)",
  'cr.summary.company.details.title': 'Détails de la société',
  'cr.summary.company.details.tooltip.BE':
    "Vous trouverez ici les informations de base les plus importantes pour l'entreprise. Ouvrez l’aperçu pour plus de détails.",
  'cr.summary.company.details.tooltip.GB':
    "Vous trouverez ici un aperçu de l'entreprise que vous avez demandée.",
  'cr.summary.company.details.tooltip.NL':
    "Vous trouverez ici toutes les informations de base sur l'entreprise faisant l’objet de votre recherche.",
  'cr.summary.company.details.tradingAddress': "Siège d'exploitation",
  'cr.summary.company.details.vatNumber': 'Numéro de TVA',
  'cr.summary.company.details.website': 'Site web',
  'cr.summary.company.structure.title': "Structure de l'entreprise",
  'cr.summary.company.structure.tooltip.BE':
    "Dans cette section, vous trouverez plus d'informations sur la structure de l’entreprise, y compris la société mère (ultime). Ouvrez l'aperçu pour consulter les dirigeants, les actionnaires ou les participations.",
  'cr.summary.company.structure.tooltip.GB':
    "Vous trouverez ici plus d'informations sur la structure de l'entreprise. Par exemple, toute société mère.",
  'cr.summary.company.structure.tooltip.NL':
    'Vous trouverez ici plus d’informations sur la structure de l’entreprise faisant l’objet de votre recherche. Vous pourrez par exemple y voir l’entreprise mère.',
  'cr.summary.key.figures.title': 'Chiffres clés',
  'cr.summary.key.figures.tooltip': 'Quelques chiffres clés relatifs aux informations ...',
  'cr.summary.key.figures.tooltip.BE':
    'Vous obtiendrez ici un bref aperçu des chiffres les plus importants des derniers comptes annuels ainsi que plusieurs ratios financiers. Ouvrez l’aperçu pour plus de de chiffres clés. Ou pour consulter le bilan et le compte de résultat des 5 derniers exercices.',
  'cr.summary.key.figures.tooltip.GB':
    "Vous trouverez ici les chiffres clés de l'entreprise, tels que le fonds de roulement et les fonds propres, ainsi que les ratios financiers.",
  'cr.summary.key.figures.tooltip.NL':
    'Vous trouverez ici les chiffres clés de l’entreprise, tels que le fonds de roulement et les fonds propres. Les ratios financiers, tels que le ratio actuel.',
  'cr.summary.link.company.profile': 'Aperçu du profil de l’entreprise >',
  'cr.summary.link.company.structure': 'Aperçu de la structure de l’entreprise >',
  'cr.summary.link.financials': 'Aperçu des finances >',
  'cr.summary.link.payment-behaviour': 'Aperçu du comportement de paiement >',
  'cr.summary.link.ratings': 'Aperçu des notations >',
  'cr.summary.link.xseptions': "Vue d'ensemble des événements exceptionnels >",
  'cr.summary.link.xseptions.date-unknown': 'Date inconnue',
  'cr.summary.news': 'Actualités',
  'cr.summary.news.no-data.text':
    'Il n\'y a actuellement aucune actualité pour cette entreprise.<br /><br />Si vous avez des questions ou avez besoin d’aide, veuillez contacter notre équipe du Service Clients à l\'adresse suivante:  <a href="mailto:{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}">{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}</a> ou par téléphone au {country, select, gb {+44 (0)20 8515 1400} nl {+31 (020) 567 9501} other {+32 (03) 280 88 80}}.',
  'cr.summary.news.no-data.title': 'Aucune actualité n’est disponible pour cette société.',
  'cr.summary.news.tooltip':
    "Vous trouverez ici les 3 articles d'actualités les plus récents sur l'entreprise, y compris le sentiment (positif > 0 < négatif) et la catégorie. Cliquez sur un article pour l'ouvrir dans un nouvel onglet de votre navigateur. Ces informations proviennent de Business Radar.",
  'cr.summary.payment-score-ccj-summons.information.ccjs':
    'Montant total des injonctions pour cette société\n',
  'cr.summary.payment-score-ccj-summons.information.dbts':
    'Moyenne des paiements effectués après les délais durant le mois dernier',
  'cr.summary.payment-score-ccj-summons.information.summons':
    'Nombre total des assignations et jugements.',
  'cr.summary.payment-score-ccj-summons.title.BE': 'Score de paiement, assignations & jugements',
  'cr.summary.payment-score-ccj-summons.title.GB': 'Comportement de paiement & injonctions',
  'cr.summary.payment-score-ccj-summons.title.NL': 'Score de paiement',
  'cr.summary.payment-score-ccj-summons.tooltip.BE':
    "Le score de paiement vous indique dans quelle mesure le client aura tendance à respecter les délais de paiement convenus. Plus le score de paiement est élevé (sur une échelle de 0 à 10), meilleur est son comportement de paiement. Le nombre de citations à comparaître indique le nombre de fois qu’une entreprise a été convoquée par l'Office national de la sécurité sociale, la Caisse d'assurance sociale pour travailleurs indépendants ou le Fonds de sécurité d'existence. Ouvrez l’aperçu pour plus de détails.",
  'cr.summary.payment-score-ccj-summons.tooltip.GB':
    "Le score de paiement indique dans quelle mesure une organisation paie ses factures à temps. La règle suivante s'applique ici : plus le score de paiement est élevé, plus l'entreprise paie ses factures à temps.",
  'cr.summary.payment-score-ccj-summons.tooltip.NL':
    "Le score de paiement indique dans quelle mesure une organisation paie ses factures à temps. Ce qui suit est d’application : plus le score de paiement est élevé, plus l'entreprise paie ses factures à temps.",
  'cr.summary.xseptions.title': 'Facteurs exceptionnels',
  'cr.summary.xseptions.tooltip.BE':
    "Sous Xseptions, vous trouverez un certain nombre d'événements marquants qui ont eu lieu (par exemple un incendie, des troubles sociaux, des problèmes financiers...) ou auront lieu (par exemple une annonce d'investissements, une proposition de fusion, une annonce de changement d’adresse du siège social...) au sein de l’entreprise. Ouvrez l’aperçu pour plus de détails.",
  'cr.summary.xseptions.tooltip.GB':
    "XSeptions analyse d’abord les événements critiques dans les relations au premier et au second degré (entreprises et/ou dirigeants). Cela comprend l'adhésion à des listes de sanctions, des structures d'entreprise opaques ou des faillites ou démissions récentes des directeurs.",
  'cr.summary.xseptions.tooltip.NL':
    'XSeptions analyse les relations de premier et de second degré (entreprises et/ou dirigeants) pour déceler des situations sortant de l’ordinaire. Cela peut inclure une mention sur des listes de sanctions, des structures d’entreprises opaques ou des faillites récentes du dirigeant.',
  'cr.test': 'test',
  'cr.toast.message.edit-preferences': 'Modifier les préférences',
  'cr.unsecuredClaims.amount': 'Montant',
  'cr.unsecuredClaims.companyName': 'Nom de la société',
  'cr.unsecuredClaims.disclaimer':
    'Affichage maximum des {maximum} réclamations les plus importantes sur un total de {totalCount}',
  'cr.unsecuredClaims.error.no-data.text':
    "Cette société n'a aucun réclamations sans garanties ou cette information n'est pas disponible. <br /> <br /> Si vous avez des questions ou avez besoin d'aide, veuillez contacter notre équipe du Service Clients à l'adresse suivante : <a href=\"mailto:{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}\"> {country, select, gb {services clients @ graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}} </a> ou par téléphone au {country, select, gb {+44&nbsp;(0)20&nbsp;8515&nbsp;1400} nl {+31&nbsp;(020)&nbsp;567&nbsp;9501} other {+32&nbsp;(03)&nbsp;280&nbsp;88&nbsp;80}}.",
  'cr.unsecuredClaims.error.no-data.title': 'Aucune réclamations sans garanties',
  'cr.unsecuredClaims.statementDate': 'Date du relevé',
  'cr.unsecuredClaims.summary': 'Montant total des réclamations sans garanties',
  'cr.unsecuredClaims.title': 'Réclamations sans garanties',
  'cr.unsecuredClaims.tooltip':
    'Cette liste inclut toutes les réclamations sans garanties telles que déclarées à la date du relevé',
  'cr.unsecuredClaims.unknown': 'Inconnu',
  'cr.value.not.available': 'N.A.',
  'credit.check.accountsDate': 'Date de clôture des comptes',
  'credit.check.accountsFileDate': 'Date de dépôt des comptes',
  'credit.check.accountsPeriod': 'Exercice financier',
  'credit.check.accumulatedDepreciation': 'Amortissement cumulé',
  'credit.check.advancedPayments': 'Paiements anticipés',
  'credit.check.advancedPaymentsOnStock': 'Acomptes sur stock',
  'credit.check.assets': 'Actif',
  'credit.check.cashBankSecurities': 'Valeurs disponibles',
  'credit.check.commonRating': 'Notation générale',
  'credit.check.commonRiskClass': 'Notation générale',
  'credit.check.company.previous.title': 'Contrôles précédents pour cette société',
  'credit.check.companyIncorporated': 'Company Incorporated',
  'credit.check.companyIncorporated.no': 'Non',
  'credit.check.companyIncorporated.yes': 'Oui',
  'credit.check.creditorDays': 'Créditeur',
  'credit.check.creditRecommendation': 'Limite de crédit',
  'credit.check.currentAssets': 'Actifs circulants',
  'credit.check.currentLiabilities': 'Dettes à court terme',
  'credit.check.debtorDays': 'Termes de paiement',
  'credit.check.details.showMore': '{num} plus…',
  'credit.check.download.pdf': 'Télécharger PDF',
  'credit.check.dueToBanks': 'Dettes aux banques',
  'credit.check.equityCapital': 'Fonds propres',
  'credit.check.erc': 'Notation générale',
  'credit.check.finishedGoods': 'Produits finis',
  'credit.check.fixedAssets': 'Immobilisations',
  'credit.check.graydonUkAugurScore': 'Augur score',
  'credit.check.graydonUkCreditRating': 'Notation de crédit',
  'credit.check.graydonUkOddsOfFinancialStress': 'Risques de stress financier',
  'credit.check.inventories': 'Inventaire(s)',
  'credit.check.liabilities': 'Passif',
  'credit.check.longTermDeferredTaxes': 'Impôts reportés à long terme',
  'credit.check.longTermLiabilities': 'Dettes à long terme',
  'credit.check.longTermLiabilitiesToAssociatedCompanies':
    'Obligations à long terme vis-à-vis de sociétés associées',
  'credit.check.longTermLoans': 'Prêts à long terme',
  'credit.check.multiscore': 'Multiscore',
  'credit.check.note.notValid':
    'Ce champ nécessite un minimum de 1 caractère et un maximum de 140 caractères',
  'credit.check.note.placeholder': 'Prenez des notes (facultatif)',
  'credit.check.numberOfEmployees': "Nombre d'employés",
  'credit.check.other': 'Autres',
  'credit.check.otherCurrentAssets': 'Autres actifs circulants',
  'credit.check.otherLongTermLiabilities': 'Autres dettes à long terme',
  'credit.check.otherReserves': 'Autres réserves',
  'credit.check.otherShortTermLiabilities': 'Autres dettes à court terme',
  'credit.check.paymentscore': 'Payment score',
  'credit.check.paymentsRating': 'Comportement de paiement',
  'credit.check.pdPercentage': 'Pourcentage probabilité de défaillance',
  'credit.check.pdRating': 'Notation Graydon',
  'credit.check.profitAndLossAccount': 'Compte de résultat',
  'credit.check.provisions': 'Provisions',
  'credit.check.rawMaterialsAndSupplies': 'Matières premières et provisions',
  'credit.check.reference.label': 'Reference number',
  'credit.check.reference.placeholder': 'Ajoutez une référence (optionnel)',
  'credit.check.refId.notValid': 'Seuls 30 caractères autorisés',
  'credit.check.reserveForPensions': 'Réservé pour les retraites',
  'credit.check.reserves': 'Réserves',
  'credit.check.revaluationReserves': 'Réserves de réévaluation',
  'credit.check.save.and.close': 'Fini',
  'credit.check.saved': 'Vérifiez les résultats enregistrés',
  'credit.check.shareholdersFunds': 'Actionnaires',
  'credit.check.shortTermLiabilities': 'Dettes à court terme',
  'credit.check.startupReservesExpenses': 'Frais de réserve de démarrage',
  'credit.check.surplusEquity': 'Surplus des fonds propres',
  'credit.check.totalAssets': "Total de l'actif",
  'credit.check.totalCurrentAssets': 'Total des actifs circulants',
  'credit.check.totalEquity': 'Total des fonds propres',
  'credit.check.totalFinancialAssets': 'Total des actifs financiers',
  'credit.check.totalFixedAssets': 'Total des actifs immobilisés',
  'credit.check.totalIntangibleAssets': 'Total des actifs incorporels',
  'credit.check.totalLiabilities': 'Total du passif',
  'credit.check.totalLongTermLiabilities': 'Total des dettes à long terme',
  'credit.check.totalReceivables': 'Total des créances',
  'credit.check.totalShortTermLiabilities': 'Total des dettes à court terme',
  'credit.check.totalTangibleAssets': 'Total des actifs corporels',
  'credit.check.tradeCreditors': 'Créances commerciales',
  'credit.check.unknown.date': 'Inconnu',
  'credit.check.workingCapital': 'Fonds de roulement',
  'credit.check.workInProgress': 'Travail en cours',
  'creditcheck.no.active.models': 'Pas de modèles actifs',
  'cta.choose.default': 'Sélectionnez notre modèle standard',
  'cta.choose.this': 'Personnalisez votre modèle de décision',
  'cta.logout': 'Déconnecter',
  'cta.next.step': 'Etape suivante',
  'cta.save': 'Enregistrer / Sauvegarder',
  'cta.save.and.close': 'Enregistrer et fermer',
  'cta.save.and.test': 'Enregistrer / Sauvegarder et tester',
  'cta.search': 'Rechercher',
  'cta.select': 'Sélectionner',
  'dashboard.clear.filters': 'Supprimer les filtres',
  'dashboard.company-list.tile.common-rating': 'Classement standard',
  'dashboard.company-list.tile.report-requested': 'Rapport demandé',
  'dashboard.filter.common-rating.title': 'Notation générale',
  'dashboard.filter.common-rating.tooltip':
    'Il s’agit d’une norme européenne pour déterminer le risque de crédit d’une entreprise, exprimé en valeur sur une échelle de 1 à 5. Plus la valeur est élevée, plus le risque est faible. La valeur ‘9’ indique une calamité financière. Avec la valeur ‘8’, il n’est pas possible d’évaluer un risque éventuel.',
  'dashboard.filter.common-rating.value-1': 'Très élevé',
  'dashboard.filter.common-rating.value-2': 'Élevé  ',
  'dashboard.filter.common-rating.value-3': 'Moyen ',
  'dashboard.filter.common-rating.value-4': 'Faible',
  'dashboard.filter.common-rating.value-5': 'Très faible',
  'dashboard.filter.common-rating.value-8': 'Par défaut',
  'dashboard.filter.common-rating.value-9': 'Indisponible',
  'dashboard.filter.group.companysizes.title': "Taille de l'entreprise",
  'dashboard.filter.group.countries_per_package.deluxe.title': "Package 'International Deluxe'",
  'dashboard.filter.group.countries_per_package.essential.title':
    "Package 'International Essential'",
  'dashboard.filter.group.countries_per_package.premium.title': "Package 'International Premium'",
  'dashboard.filter.group.countries_per_package.title': 'Pays par package',
  'dashboard.filter.group.countries.title': 'Pays',
  'dashboard.filter.group.decisionmodels.title': 'Modèles de décision',
  'dashboard.filter.group.industry.title': 'Secteurs',
  'dashboard.filter.group.sectors.title': 'Secteurs',
  'dashboard.filter.time-range.last-month': 'Mois en cours',
  'dashboard.filter.time-range.last-six-months': '6 derniers mois',
  'dashboard.filter.time-range.last-three-months': '3 derniers mois',
  'dashboard.filter.time-range.last-twelve-months': '12 derniers mois',
  'dashboard.filter.time-range.title': 'Plage de dates',
  'dashboard.heatmap.companies-list.header':
    'Tous les contrôles pendant {timerange} sur {country}. Résultat {numOfItems} sur {totalNumOfItems} {totalNumOfItems, plural, one{item} other{items}}.',
  'dashboard.heatmap.legend.addon-package': 'Options suppl.',
  'dashboard.heatmap.legend.least-reports': 'Moins de rapports',
  'dashboard.heatmap.legend.most-reports': 'Plus de rapports',
  'dashboard.heatmap.legend.unavailable': 'Indisponible',
  'dashboard.multiselect.companysizes.0': '0 employé',
  'dashboard.multiselect.companysizes.1': '1 - 4 employés',
  'dashboard.multiselect.companysizes.2': '5 - 9 employés',
  'dashboard.multiselect.companysizes.3': '10 - 19 employés',
  'dashboard.multiselect.companysizes.4': '20 - 49 employés',
  'dashboard.multiselect.companysizes.5': '50 - 99 employés',
  'dashboard.multiselect.companysizes.6': '100 - 199 employés',
  'dashboard.multiselect.companysizes.7': '200 - 499 employés',
  'dashboard.multiselect.companysizes.8': '500 - 999 employés',
  'dashboard.multiselect.companysizes.9': '>1000 employés',
  'dashboard.multiselect.companysizes.all': "Toutes les tailles d'entreprises",
  'dashboard.multiselect.companysizes.n-sizes': '{n} tailles des entreprises selectionnées',
  'dashboard.multiselect.companysizes.X': 'Inconnu',
  'dashboard.tile.list.footer': 'Voir la liste complète?',
  'dashboard.tile.list.header':
    '{outcome, select, all{Tous} accept{Accepté} refer{Référé} deny{Refusé}} ordres de {month}.',
  'dashboard.tile.list.header.close': 'Fermer',
  'dashboard.tile.list.numOfItems':
    'Montrer {numOfItems} de {totalNumOfItems} {totalNumOfItems, plural, one{item} other{items}}.',
  'dashboard.title': 'Gestion du tableau de bord',
  'dashboard.widget.label.accept': 'Accepter',
  'dashboard.widget.label.be': 'Belgique',
  'dashboard.widget.label.deluxe': 'Deluxe',
  'dashboard.widget.label.deny': 'Référer',
  'dashboard.widget.label.essential': 'Essential',
  'dashboard.widget.label.gb': 'Royaume-Uni',
  'dashboard.widget.label.nl': 'Pays-Bas',
  'dashboard.widget.label.premium': 'Premium',
  'dashboard.widget.label.refer': 'Refuser',
  'dashboard.widget.title.acceptance-grade': 'Degré d’acceptation',
  'dashboard.widget.title.average-order-value': 'Valeur moyenne de commande',
  'dashboard.widget.title.consumption': 'International Business - Rapports demandés par pays',
  'dashboard.widget.title.consumption-heatmap':
    'International Business - Rapports demandés par pays',
  'dashboard.widget.title.credit-checks': 'Credit checks',
  'dashboard.widget.title.total-orders': 'Nombre total de contrôles',
  'dashboard.with.filters': 'Sélection filtrée',
  Datablocks: 'Les changements ont été enregistrés',
  'dbm.alreadyScheduled': 'déjà programmé',
  'dbm.backHome': "Retour à la page d'accueil",
  'dbm.bigDownload.button': 'Faisons autre chose',
  'dbm.bigDownload.emailTo':
    'Quand votre fichier enrichi sera prêt à être téléchargé, nous vous enverrons un e-mail à :',
  'dbm.bigDownload.heading': "Il est presque temps d'atteindre vos objectifs…",
  'dbm.bigDownload.smallText':
    "Vous n'avez pas reçu un e-mail dans les 2 heures ? Contactez le service clients.",
  'dbm.chooseAList': 'Choisissez une liste',
  'dbm.create-profile.companies': 'Les entreprises',
  'dbm.create-profile.company-options.description':
    "Remarque / Veuillez noter : vous ne pouvez choisir uniquement que Liste, Sélection ou Tout d'un pays lors de la création du profil. Après cela, vous ne pouvez plus changer cela.",
  'dbm.create-profile.company-options.description-no-merketdata':
    "Remarque / Veuillez noter : vous ne pouvez choisir uniquement que Liste ou Tout d'un pays lors de la création du profil. Après cela, vous ne pouvez plus changer cela.",
  'dbm.create-profile.company-options.event': 'De tout le pays',
  'dbm.create-profile.company-options.event-description':
    "Trouver des entreprises en fonction d'événements, par exemple des faillites",
  'dbm.create-profile.company-options.from': 'Les entreprises de',
  'dbm.create-profile.company-options.list': 'Lister',
  'dbm.create-profile.company-options.list-description': 'Ajoutez vous-même des entreprises',
  'dbm.create-profile.company-options.selection': 'Sélection des données de marché',
  'dbm.create-profile.company-options.selection-description':
    'Trouver des entreprises selon les critères',
  'dbm.create-profile.company-options.tooltip':
    "Vous pouvez recevoir des données d'une liste d'entreprises auto-compilée (option Liste), ou d'entreprises qui répondent à un ensemble de critères (option Sélection) ou bien de toutes les entreprises d'un pays où un événement spécifique se produit, telle qu'une faillite (option Pays).",
  'dbm.create-profile.company-options.tooltip-no-marketdata':
    "Vous pouvez recevoir des données d'une liste d'entreprises auto-compilée (option Liste) ou bien de toutes les entreprises d'un pays où un événement spécifique se produit, telle qu'une faillite (option Pays).",
  'dbm.create-profile.country': 'Pays',
  'dbm.create-profile.country.tooltip':
    "Remarque : vous ne pouvez plus modifier la sélection du pays après avoir sauvegarder le profil. Si vous souhaitez recevoir des données d'entreprises de différents pays, vous devrez créer un profil par pays",
  'dbm.create-profile.data-block-group.code_descriptions': 'Description des codes',
  'dbm.create-profile.data-block-group.company_profile': "Profil de l'entreprise",
  'dbm.create-profile.data-block-group.events': 'Caractéristiques exceptionnelles',
  'dbm.create-profile.data-block-group.finance': 'Les données financières',
  'dbm.create-profile.data-block-group.network': "Structure de l'entreprise",
  'dbm.create-profile.data-block-group.ratings': 'Notations',
  'dbm.create-profile.data-block-group.untitled': 'Pas libellé',
  'dbm.create-profile.data-blocks': 'Choisissez des blocs de données',
  'dbm.create-profile.data-blocks-version': 'Version',
  'dbm.create-profile.data-blocks.DBM_ACTIVITY': 'Activités',
  'dbm.create-profile.data-blocks.DBM_ACTIVITY.tooltip': 'Activités',
  'dbm.create-profile.data-blocks.DBM_ADDRESS': 'Adresses',
  'dbm.create-profile.data-blocks.DBM_ADDRESS.tooltip': 'Adresses …',
  'dbm.create-profile.data-blocks.DBM_ANNUAL_ACCOUNT': 'Comptes annuels',
  'dbm.create-profile.data-blocks.DBM_ANNUAL_ACCOUNT.tooltip': '-',
  'dbm.create-profile.data-blocks.DBM_ANNUAL_REPORT': 'Rapport annuel',
  'dbm.create-profile.data-blocks.DBM_ANNUAL_REPORT.tooltip': '-',
  'dbm.create-profile.data-blocks.DBM_BAG': 'BAG',
  'dbm.create-profile.data-blocks.DBM_BAG.tooltip':
    "BAG (Basic Registration Addresses and Buildings) contient les données municipales de base de toutes les adresses et bâtiments d'une commune.",
  'dbm.create-profile.data-blocks.DBM_BANKER': 'Banquier',
  'dbm.create-profile.data-blocks.DBM_BANKER.tooltip': 'Banquier',
  'dbm.create-profile.data-blocks.DBM_BASIC': 'De base',
  'dbm.create-profile.data-blocks.DBM_BASIC.tooltip': 'Informations de base',
  'dbm.create-profile.data-blocks.DBM_BRANCH': 'Filiales',
  'dbm.create-profile.data-blocks.DBM_BRANCH.tooltip': 'Filiales',
  'dbm.create-profile.data-blocks.DBM_CCJ_SUMMARY': 'Résumé des CCJ',
  'dbm.create-profile.data-blocks.DBM_CCJ_SUMMARY.tooltip': '-',
  'dbm.create-profile.data-blocks.DBM_CODE_DESCRIPTIONS': 'Description des codes',
  'dbm.create-profile.data-blocks.DBM_CODE_DESCRIPTIONS.tooltip':
    'Bloc de données spécial avec les codes, valeurs et descriptions (EN, FR, NL) pour tous les tableaux utilisés dans les différents blocs de données. (p.e. Country code: NL -> Nederland, SIZE_CLASS: 4 -> 5 tot 9)',
  'dbm.create-profile.data-blocks.DBM_CONTACT_ITEM': 'Information de contact',
  'dbm.create-profile.data-blocks.DBM_CONTACT_ITEM.tooltip': 'Informations de contact',
  'dbm.create-profile.data-blocks.DBM_CONTINUATION': 'Continuation',
  'dbm.create-profile.data-blocks.DBM_CONTINUATION.tooltip': '-',
  'dbm.create-profile.data-blocks.DBM_CREDIT_RATING': 'Notation de crédit',
  'dbm.create-profile.data-blocks.DBM_CREDIT_RATING.tooltip': 'Notation de crédit …',
  'dbm.create-profile.data-blocks.DBM_CREDIT_SCORES': 'Les cotes de crédit',
  'dbm.create-profile.data-blocks.DBM_CREDIT_SCORES.tooltip':
    "Ce bloc de données contient entre autres: la limite de crédit, l'indicateur de crédit, la notation Graydon, le pourcentage de risque de faillite, le score de paiement, le multiscore, le risque de fermeture",
  'dbm.create-profile.data-blocks.DBM_EMPLOYEE': 'Employés',
  'dbm.create-profile.data-blocks.DBM_EMPLOYEE.tooltip': 'Employés',
  'dbm.create-profile.data-blocks.DBM_EVENTS': 'Événements et publications {extra}',
  'dbm.create-profile.data-blocks.DBM_EVENTS.tooltip': '-',
  'dbm.create-profile.data-blocks.DBM_FINANCE_SUMMARY': 'Résumé financier',
  'dbm.create-profile.data-blocks.DBM_FINANCE_SUMMARY.tooltip': 'Résumé financier',
  'dbm.create-profile.data-blocks.DBM_INSOLVENCY': 'Insolvabilité',
  'dbm.create-profile.data-blocks.DBM_INSOLVENCY_POSITION': "Personnes liées a l'insolvabilité",
  'dbm.documentation.block-description.DBM_INSOLVENCY_POSITION-NL': '-',
  'dbm.documentation.block-description.DBM_INSOLVENCY_POSITION-BE': '-',
  'dbm.documentation.block-description.DBM_INSOLVENCY_POSITION-GB': '-',
  'dbm.create-profile.data-blocks.DBM_INSOLVENCY.tooltip': '-',
  'dbm.create-profile.data-blocks.DBM_KEY_FIGURE': 'Chiffres clés',
  'dbm.create-profile.data-blocks.DBM_KEY_FIGURE.tooltip': 'Chiffres clés',
  'dbm.create-profile.data-blocks.DBM_LIABILITY_STATEMENT': 'Déclaration de responsabilité',
  'dbm.create-profile.data-blocks.DBM_LIABILITY_STATEMENT.tooltip': '-',
  'dbm.create-profile.data-blocks.DBM_OPPORTUNITY': 'Possibilités / Opportunités',
  'dbm.create-profile.data-blocks.DBM_OPPORTUNITY.tooltip': 'Possibilités / Opportunités',
  'dbm.create-profile.data-blocks.DBM_ORGANIZATION_NAME': "Noms de l'organisation",
  'dbm.create-profile.data-blocks.DBM_ORGANIZATION_NAME.tooltip': "Noms de l'organisation",
  'dbm.create-profile.data-blocks.DBM_PARTICIPATION': 'Participations',
  'dbm.create-profile.data-blocks.DBM_PARTICIPATION.tooltip': 'Participations …',
  'dbm.create-profile.data-blocks.DBM_PAYMENT_ANALYSIS': 'Analyse des paiements',
  'dbm.create-profile.data-blocks.DBM_PAYMENT_ANALYSIS.tooltip': '-',
  'dbm.create-profile.data-blocks.DBM_POSITION': "Les chef(fe)s d'entreprise",
  'dbm.create-profile.data-blocks.DBM_POSITION_CIS': 'Postes CIS',
  'dbm.create-profile.data-blocks.DBM_POSITION_CIS.tooltip':
    'Information des postes extrait de la base de données de la Fondation Centraal Informatie Systeem',
  'dbm.create-profile.data-blocks.DBM_POSITION.tooltip': "Les chef(fe)s d'entreprise",
  'dbm.create-profile.data-blocks.DBM_SHAREHOLDER': 'Actionnaires',
  'dbm.create-profile.data-blocks.DBM_SHAREHOLDER.tooltip':
    'Ce bloc de données contient des données sur les actionnaires (particuliers et entreprises)',
  'dbm.create-profile.data-blocks.DBM_SOCIAL_BALANCE': 'Bilan social',
  'dbm.create-profile.data-blocks.DBM_SOCIAL_BALANCE.tooltip': '-',
  'dbm.create-profile.data-blocks.DBM_STRUCTURE': 'Structure',
  'dbm.create-profile.data-blocks.DBM_STRUCTURE.tooltip': 'Structure',
  'dbm.create-profile.data-blocks.DBM_SUMMONS': 'Assignations à comparaître',
  'dbm.create-profile.data-blocks.DBM_SUMMONS.tooltip': '-',
  'dbm.create-profile.data-blocks.DBM_UBO': 'UBO',
  'dbm.create-profile.data-blocks.DBM_UBO_CIS': 'UBO CIS',
  'dbm.create-profile.data-blocks.DBM_UBO_CIS.tooltip':
    "Information des bénéficiaires finaux dans la structure de groupe de l'entreprise extrait de la base de données de la Fondation Centraal Informatie Systeem",
  'dbm.create-profile.data-blocks.DBM_UBO.tooltip':
    "Les bénéficiaires finaux dans la structure de groupe de l'entreprise",
  'dbm.create-profile.data-blocks.DBM_XSEPTION': 'XSeption',
  'dbm.create-profile.data-blocks.DBM_XSEPTION.tooltip': '-',
  'dbm.create-profile.delimiter': 'Séparateur pour fichier CSV',
  'dbm.create-profile.description': 'Description',
  'dbm.create-profile.duplicate.message': 'Vous utilisez déjà ce nom pour un autre profil',
  'dbm.create-profile.duplicate.title': 'Nom dupliqué',
  'dbm.create-profile.error.message': "Une erreur s'est produite lors de la création du profil",
  'dbm.create-profile.error.title': 'Erreur en création du profil',
  'dbm.create-profile.layout': 'Format du fichier',
  'dbm.create-profile.layout.columnNames': 'Inclure les noms des colonnes',
  'dbm.create-profile.layout.headerAndFooter': "Inclure l'en-tête",
  'dbm.create-profile.layout.tooltip':
    "L'en-tête du fichier de données contient des métadonnées telles que le nom du profil, la date et l'heure, l'identifiant du demandeur. Le pied de page contient le nombre d'organisations dans le fichier. Les noms des colonnes indiquent les données contenues dans chaque colonne du fichier de données. Voir la documentation pour un exemple.",
  'dbm.create-profile.list.countryNote':
    'Veuillez noter que seules {organizationCountOfCountry} sur les {organizationCountOfCountry, plural, =1 {company} other {companies}} de {organizationCount} sont dans la liste de {countryCode} et seront utilisées pour ce profile.',
  'dbm.create-profile.list.countryNote.empty':
    'Aucune des {organizationCount} société dans la liste font partie de/du {countryCode}, donc aucune ne sera utilisée pour ce profile',
  'dbm.create-profile.name': 'Nom du profil',
  'dbm.create-profile.no-data-block':
    "Aucun bloc de données n'est activé pour ce pays. [N’hésitez pas à contacter Graydon](/contact).",
  'dbm.create-profile.receiving-updates': 'Livraison de fichiers',
  'dbm.create-profile.save-profile': 'Enregistrer le profil',
  'dbm.create-profile.select-company': 'Général',
  'dbm.create-profile.start-date': 'À partir de (du)',
  'dbm.create-profile.success.message': 'Le profil a été créé avec succès.',
  'dbm.create-profile.success.title': 'Profil créé avec succès',
  'dbm.create-profile.title': 'Créer un nouveau profil',
  'dbm.create-profile.type': 'Téléchargement complet ou uniquement les changements',
  'dbm.create-profile.type.delta': 'Recevoir uniquement les entreprises modifiées',
  'dbm.create-profile.type.full': 'Recevez un ensemble de données complètes à chaque fois',
  'dbm.create-profile.type.full_then_delta':
    "Ensemble de données complètes d'abord, puis uniquement les modifications",
  'dbm.dataBlock.fields.activity': 'Activité',
  'dbm.dataBlock.fields.companyName': "Nom de l'entreprise & dénomination commerciale",
  'dbm.dataBlock.fields.contactDetails': 'Coordonnées',
  'dbm.dataBlock.fields.DMU': 'Personne à contacter (DMU)',
  'dbm.dataBlock.fields.employeeCount': "Nombre d'employés",
  'dbm.dataBlock.fields.establishmentType': "Type d'établissement",
  'dbm.dataBlock.fields.graydonId': "Numéros d'indentification Graydon",
  'dbm.dataBlock.fields.incorporationDate': 'Date de constitution',
  'dbm.dataBlock.fields.legalForm': 'Forme juridique',
  'dbm.dataBlock.fields.sector': 'Secteur',
  'dbm.dataBlock.marketinginformation': 'Informations marketing',
  'dbm.documentation.block-description.DBM_ACTIVITY-BE': 'Activités',
  'dbm.documentation.block-description.DBM_ACTIVITY-GB': '-',
  'dbm.documentation.block-description.DBM_ACTIVITY-NL': '-',
  'dbm.documentation.block-description.DBM_ADDRESS-BE': 'Adresses',
  'dbm.documentation.block-description.DBM_ADDRESS-GB': '-',
  'dbm.documentation.block-description.DBM_ADDRESS-NL': '-',
  'dbm.documentation.block-description.DBM_ANNUAL_ACCOUNT-BE': 'Comptes annuels',
  'dbm.documentation.block-description.DBM_ANNUAL_ACCOUNT-GB': '-',
  'dbm.documentation.block-description.DBM_ANNUAL_ACCOUNT-NL': '-',
  'dbm.documentation.block-description.DBM_ANNUAL_REPORT-BE': 'Index publications comptes annuels',
  'dbm.documentation.block-description.DBM_ANNUAL_REPORT-GB': '-',
  'dbm.documentation.block-description.DBM_ANNUAL_REPORT-NL': '-',
  'dbm.documentation.block-description.DBM_BAG-NL':
    "BAG (Basic Registration Addresses and Buildings) contient les données municipales de base de toutes les adresses et bâtiments d'une commune.",
  'dbm.documentation.block-description.DBM_BANKER-BE': 'Banquiers',
  'dbm.documentation.block-description.DBM_BASIC-BE': 'Informations de base',
  'dbm.documentation.block-description.DBM_BASIC-GB': '-',
  'dbm.documentation.block-description.DBM_BASIC-NL': '-',
  'dbm.documentation.block-description.DBM_BRANCH-BE': 'Filiales',
  'dbm.documentation.block-description.DBM_BRANCH-NL': '-',
  'dbm.documentation.block-description.DBM_CCJ_SUMMARY-BE': '-',
  'dbm.documentation.block-description.DBM_CCJ_SUMMARY-GB': '-',
  'dbm.documentation.block-description.DBM_CCJ_SUMMARY-NL': '-',
  'dbm.documentation.block-description.DBM_CODE_DESCRIPTIONS-BE':
    'Bloc spécial de données avec les codes, valeurs et descriptions (EN, FR, NL) pour les tableaux utilisé dans les différents blocs de données',
  'dbm.documentation.block-description.DBM_CODE_DESCRIPTIONS-GB':
    'Bloc spécial de données avec les codes, valeurs et descriptions (EN, FR, NL) pour les tableaux utilisé dans les différents blocs de données',
  'dbm.documentation.block-description.DBM_CODE_DESCRIPTIONS-NL':
    'Bloc spécial de données avec les codes, valeurs et descriptions (EN, FR, NL) pour les tableaux utilisé dans les différents blocs de données',
  'dbm.documentation.block-description.DBM_CONTACT_ITEM-BE': 'Informations de contact',
  'dbm.documentation.block-description.DBM_CONTACT_ITEM-GB': '-',
  'dbm.documentation.block-description.DBM_CONTACT_ITEM-NL': '-',
  'dbm.documentation.block-description.DBM_CONTINUATION-BE': '-',
  'dbm.documentation.block-description.DBM_CONTINUATION-GB': '-',
  'dbm.documentation.block-description.DBM_CONTINUATION-NL': '-',
  'dbm.documentation.block-description.DBM_CREDIT_SCORES-BE': 'Scores de crédit',
  'dbm.documentation.block-description.DBM_CREDIT_SCORES-GB': '-',
  'dbm.documentation.block-description.DBM_CREDIT_SCORES-NL': '-',
  'dbm.documentation.block-description.DBM_EMPLOYEE-GB': '-',
  'dbm.documentation.block-description.DBM_EMPLOYEE-NL': '-',
  'dbm.documentation.block-description.DBM_EVENTS-BE': 'Événements et publications',
  'dbm.documentation.block-description.DBM_EVENTS-GB': 'Événements',
  'dbm.documentation.block-description.DBM_EVENTS-NL': '-',
  'dbm.documentation.block-description.DBM_FINANCE_SUMMARY-BE': 'Résumé financier',
  'dbm.documentation.block-description.DBM_FINANCE_SUMMARY-GB': '-',
  'dbm.documentation.block-description.DBM_FINANCE_SUMMARY-NL': '-',
  'dbm.documentation.block-description.DBM_INSOLVENCY-BE':
    'Insolvabilités - faillites et réorganisations judiciaires',
  'dbm.documentation.block-description.DBM_INSOLVENCY-GB':
    'Insolvabilités - faillites et réorganisations judiciaires',
  'dbm.documentation.block-description.DBM_INSOLVENCY-NL':
    'Insolvabilités - faillites et réorganisations judiciaires',
  'dbm.documentation.block-description.DBM_KEY_FIGURE-BE': 'Chiffres clés',
  'dbm.documentation.block-description.DBM_KEY_FIGURE-GB': 'Chiffres clés',
  'dbm.documentation.block-description.DBM_KEY_FIGURE-NL': 'Chiffres clés',
  'dbm.documentation.block-description.DBM_LIABILITY_STATEMENT-NL': '-',
  'dbm.documentation.block-description.DBM_OPPORTUNITY-BE':
    "Scores de probabilité / Score d'opportunité",
  'dbm.documentation.block-description.DBM_OPPORTUNITY-GB':
    "Scores de probabilité / Score d'opportunité",
  'dbm.documentation.block-description.DBM_OPPORTUNITY-NL':
    "Scores de probabilité / Score d'opportunité",
  'dbm.documentation.block-description.DBM_ORGANIZATION_NAME-BE': 'Noms',
  'dbm.documentation.block-description.DBM_ORGANIZATION_NAME-GB': '-',
  'dbm.documentation.block-description.DBM_ORGANIZATION_NAME-NL': '-',
  'dbm.documentation.block-description.DBM_PARTICIPATION-BE': 'Participations',
  'dbm.documentation.block-description.DBM_PARTICIPATION-GB': 'Participations',
  'dbm.documentation.block-description.DBM_PARTICIPATION-NL': 'Participations',
  'dbm.documentation.block-description.DBM_PAYMENT_ANALYSIS-BE': 'Analyse des paiements',
  'dbm.documentation.block-description.DBM_PAYMENT_ANALYSIS-NL': 'Analyse des paiements',
  'dbm.documentation.block-description.DBM_POSITION_CIS-BE': '-',
  'dbm.documentation.block-description.DBM_POSITION_CIS-GB': '-',
  'dbm.documentation.block-description.DBM_POSITION_CIS-NL': '-',
  'dbm.documentation.block-description.DBM_POSITION-BE': "Les chef(fe)s d'entreprise",
  'dbm.documentation.block-description.DBM_POSITION-NL': '-',
  'dbm.documentation.block-description.DBM_POSITION-GB': '-',
  'dbm.documentation.block-description.DBM_SHAREHOLDER-BE': 'Actionnaires',
  'dbm.documentation.block-description.DBM_SHAREHOLDER-GB': 'Actionnaires',
  'dbm.documentation.block-description.DBM_SHAREHOLDER-NL': 'Actionnaires',
  'dbm.documentation.block-description.DBM_SOCIAL_BALANCE-BE': 'Bilan social',
  'dbm.documentation.block-description.DBM_SOCIAL_BALANCE-GB': '-',
  'dbm.documentation.block-description.DBM_SOCIAL_BALANCE-NL': '-',
  'dbm.documentation.block-description.DBM_STRUCTURE-NL': '-',
  'dbm.documentation.block-description.DBM_STRUCTURE-GB': '-',
  'dbm.documentation.block-description.DBM_SUMMONS-BE': 'Assignations à comparaître',
  'dbm.documentation.block-description.DBM_SUMMONS-GB': '-',
  'dbm.documentation.block-description.DBM_SUMMONS-NL': '-',
  'dbm.documentation.block-description.DBM_UBO_CIS-BE': '-',
  'dbm.documentation.block-description.DBM_UBO_CIS-GB': '-',
  'dbm.documentation.block-description.DBM_UBO_CIS-NL': '-',
  'dbm.documentation.block-description.DBM_UBO-BE': '-',
  'dbm.documentation.block-description.DBM_UBO-NL': '-',
  'dbm.documentation.block-description.DBM_XSEPTION-BE': 'XSeptions',
  'dbm.documentation.block-description.DBM_XSEPTION-GB': 'XSeptions',
  'dbm.documentation.block-description.DBM_XSEPTION-NL': 'XSeptions',
  'dbm.documentation.collapse-all': 'Réduire le menu',
  'dbm.documentation.delete-profile': 'Supprimer profil',
  'dbm.documentation.delete-profile.error':
    'Le profil ne peut pas être supprimer, parce que les données a été collecter en ce moment. Merci de réessayer plus tard.',
  'dbm.documentation.delete-profile.success': 'Le profil a été supprimé',
  'dbm.documentation.description':
    "Les métadonnées du bloc de données disponible sont décrites ci-dessous. Si vous souhaitez télécharger ou imprimer des parties de la documentation, ouvrez vos blocs de données préférés.\n La documentation générale sur l'utilisation de l'API est disponible sur [graydon.io](https://graydon.io) et dans le [document technique fourni](https://connect.graydon-global.com/rs/934-AQC-614/images/Database-Management-Technical-documentation.pdf).",
  'dbm.documentation.description.ABBREVIATIONS_LEGAL_FORM': 'Abréviation de la forme juridique',
  'dbm.documentation.description.ACC_PERIOD_END_DATE': "Date de fin d'exercice",
  'dbm.documentation.description.ACC_PERIOD_START_DATE':
    'Date de début de la période comptes annuels',
  'dbm.documentation.description.ACCOUNTANT_NAME': 'Nom du/des comptable(s)',
  'dbm.documentation.description.ACCOUNTING_PERIOD':
    'Date de début et de fin de la période comptes annuels',
  'dbm.documentation.description.ACTIVITY_CLASSIFICATION_CODE':
    "Une classification de code d'activité spécifique",
  'dbm.documentation.description.ACTIVITY_CODE':
    "Un code standard pour identifier les activités de l'entreprise (primaire)",
  'dbm.documentation.description.ACTIVITY_CODE_1':
    "Un code standard pour identifier les activités de l'entreprise (primaire)",
  'dbm.documentation.description.ACTIVITY_CODE_2':
    "Un code standard pour identifier les activités de l'entreprise (primaire)",
  'dbm.documentation.description.ACTIVITY_CODE_3':
    "Un code standard pour identifier les activités de l'entreprise (primaire)",
  'dbm.documentation.description.ACTIVITY_CODE_4':
    "Un code standard pour identifier les activités de l'entreprise (primaire)",
  'dbm.documentation.description.ACTIVITY_CODE_5':
    "Un code standard pour identifier les activités de l'entreprise (primaire)",
  'dbm.documentation.description.ACTIVITY_DESCRIPTION': "Description de l'activité",
  'dbm.documentation.description.ACTIVITY_GROUP_CODE':
    "Code de source de l'activité : ONNS, TVA, BCE ou Graydon",
  'dbm.documentation.description.ACTIVITY_SEQUENCE_NUMBER': "Numéro de séquence de l'activité",
  'dbm.documentation.description.ACTIVITY_START_DATE': 'Date',
  'dbm.documentation.description.ACTIVITY_TYPE_CODE':
    "Le code détermine le type d'activité : primaire, secondaire (activité annexe)",
  'dbm.documentation.description.ADDRESS_END_DATE':
    "La date indique jusqu'à quand l'adresse était utilisée par l'entreprise",
  'dbm.documentation.description.ADDRESS_LINE_1': "Champ d'adresse 1",
  'dbm.documentation.description.ADDRESS_LINE_1_RECEIVER_1': '-',
  'dbm.documentation.description.ADDRESS_LINE_1_RECEIVER_10': '-',
  'dbm.documentation.description.ADDRESS_LINE_1_RECEIVER_2': '-',
  'dbm.documentation.description.ADDRESS_LINE_1_RECEIVER_3': '-',
  'dbm.documentation.description.ADDRESS_LINE_1_RECEIVER_4': '-',
  'dbm.documentation.description.ADDRESS_LINE_1_RECEIVER_5': '-',
  'dbm.documentation.description.ADDRESS_LINE_1_RECEIVER_6': '-',
  'dbm.documentation.description.ADDRESS_LINE_1_RECEIVER_7': '-',
  'dbm.documentation.description.ADDRESS_LINE_1_RECEIVER_8': '-',
  'dbm.documentation.description.ADDRESS_LINE_1_RECEIVER_9': '-',
  'dbm.documentation.description.ADDRESS_LINE_2': "Champ d'adresse 2",
  'dbm.documentation.description.ADDRESS_LINE_2_RECEIVER_1': '-',
  'dbm.documentation.description.ADDRESS_LINE_2_RECEIVER_10': '-',
  'dbm.documentation.description.ADDRESS_LINE_2_RECEIVER_2': '-',
  'dbm.documentation.description.ADDRESS_LINE_2_RECEIVER_3': '-',
  'dbm.documentation.description.ADDRESS_LINE_2_RECEIVER_4': '-',
  'dbm.documentation.description.ADDRESS_LINE_2_RECEIVER_5': '-',
  'dbm.documentation.description.ADDRESS_LINE_2_RECEIVER_6': '-',
  'dbm.documentation.description.ADDRESS_LINE_2_RECEIVER_7': '-',
  'dbm.documentation.description.ADDRESS_LINE_2_RECEIVER_8': '-',
  'dbm.documentation.description.ADDRESS_LINE_2_RECEIVER_9': '-',
  'dbm.documentation.description.ADDRESS_LINE_3': "Champ d'adresse 3",
  'dbm.documentation.description.ADDRESS_LINE_3_RECEIVER_1': '-',
  'dbm.documentation.description.ADDRESS_LINE_3_RECEIVER_10': '-',
  'dbm.documentation.description.ADDRESS_LINE_3_RECEIVER_2': '-',
  'dbm.documentation.description.ADDRESS_LINE_3_RECEIVER_3': '-',
  'dbm.documentation.description.ADDRESS_LINE_3_RECEIVER_4': '-',
  'dbm.documentation.description.ADDRESS_LINE_3_RECEIVER_5': '-',
  'dbm.documentation.description.ADDRESS_LINE_3_RECEIVER_6': '-',
  'dbm.documentation.description.ADDRESS_LINE_3_RECEIVER_7': '-',
  'dbm.documentation.description.ADDRESS_LINE_3_RECEIVER_8': '-',
  'dbm.documentation.description.ADDRESS_LINE_3_RECEIVER_9': '-',
  'dbm.documentation.description.ADDRESS_LINE_4': "Champ d'adresse 4",
  'dbm.documentation.description.ADDRESS_LINE_4_RECEIVER_1': '-',
  'dbm.documentation.description.ADDRESS_LINE_4_RECEIVER_10': '-',
  'dbm.documentation.description.ADDRESS_LINE_4_RECEIVER_2': '-',
  'dbm.documentation.description.ADDRESS_LINE_4_RECEIVER_3': '-',
  'dbm.documentation.description.ADDRESS_LINE_4_RECEIVER_4': '-',
  'dbm.documentation.description.ADDRESS_LINE_4_RECEIVER_5': '-',
  'dbm.documentation.description.ADDRESS_LINE_4_RECEIVER_6': '-',
  'dbm.documentation.description.ADDRESS_LINE_4_RECEIVER_7': '-',
  'dbm.documentation.description.ADDRESS_LINE_4_RECEIVER_8': '-',
  'dbm.documentation.description.ADDRESS_LINE_4_RECEIVER_9': '-',
  'dbm.documentation.description.ADDRESS_LINE_5': "Champ d'adresse 5",
  'dbm.documentation.description.ADDRESS_LINE_5_RECEIVER_1': '-',
  'dbm.documentation.description.ADDRESS_LINE_5_RECEIVER_10': '-',
  'dbm.documentation.description.ADDRESS_LINE_5_RECEIVER_2': '-',
  'dbm.documentation.description.ADDRESS_LINE_5_RECEIVER_3': '-',
  'dbm.documentation.description.ADDRESS_LINE_5_RECEIVER_4': '-',
  'dbm.documentation.description.ADDRESS_LINE_5_RECEIVER_5': '-',
  'dbm.documentation.description.ADDRESS_LINE_5_RECEIVER_6': '-',
  'dbm.documentation.description.ADDRESS_LINE_5_RECEIVER_7': '-',
  'dbm.documentation.description.ADDRESS_LINE_5_RECEIVER_8': '-',
  'dbm.documentation.description.ADDRESS_LINE_5_RECEIVER_9': '-',
  'dbm.documentation.description.ADDRESS_LINES':
    "Indication que les champs d'adresse dans ce bloc d'adresse sont remplies",
  'dbm.documentation.description.ADDRESS_ROLE_CODE':
    "Type d'adresse, siège social, adresse commerciale, adresse postale, adresse de service, etc.",
  'dbm.documentation.description.ADDRESS_START_DATE':
    "La date indique depuis quand l'adresse est utilisée par l'entreprise",
  'dbm.documentation.description.ADDRESS_TYPE_CODE': 'Boîte postale ou adresse physique',
  'dbm.documentation.description.AMOUNT_CATEGORY_CODE': 'Code de catégorie du montant',
  'dbm.documentation.description.AMOUNT_CCJ_LAST_12_MONTHS': '-',
  'dbm.documentation.description.AMOUNT_CCJ_LAST_24_MONTHS': '-',
  'dbm.documentation.description.AMOUNT_CCJ_LAST_36_MONTHS': '-',
  'dbm.documentation.description.AMOUNT_CCJ_LAST_48_MONTHS': '-',
  'dbm.documentation.description.AMOUNT_CCJ_LAST_60_MONTHS': '-',
  'dbm.documentation.description.AMOUNT_CCJ_LAST_72_MONTHS': '-',
  'dbm.documentation.description.AMOUNT_UNIT_CODE': 'Montant unitaire',
  'dbm.documentation.description.ANNUAL_ACCOUNT_ITEM_CODE':
    'Code de Graydon de la rubrique des comptes annuels',
  'dbm.documentation.description.ANNUAL_ACCOUNT_ITEM_VALUE':
    'Valeur de la rubrique des comptes annuels',
  'dbm.documentation.description.ANNUAL_ACCOUNT_TYPE_CODE': 'Type de modèle comptes annuels',
  'dbm.documentation.description.ANNUAL_REPORT_FILING_DATE': 'Date de dépôt des comptes annuels',
  'dbm.documentation.description.ANNUAL_REPORT_TYPE_CODE': 'Type de modèle des comptes annuels',
  'dbm.documentation.description.AREA': 'Bâtiment en mètres carrés',
  'dbm.documentation.description.ASSOCIATION_FUNDS': "Fonds de l'association / la fondation",
  'dbm.documentation.description.AUTHORISED_CAPITAL': 'Capital social',
  'dbm.documentation.description.AVAILABLE_CONTRIBUTION': 'Apport disponible',
  'dbm.documentation.description.BAG_STATUS_CODE': "Code d'état de l'objet",
  'dbm.documentation.description.BAG_TYPE_CODE': 'Code du type BAG',
  'dbm.documentation.description.BALANCE_SHEET_TOTAL': "Bilan de l'entreprise",
  'dbm.documentation.description.BANK_CODE': 'Code bancaire, identifie la banque',
  'dbm.documentation.description.BANK_NAME': 'Nom de la banque',
  'dbm.documentation.description.BE_BUSINESS_UNIT_NUMBER':
    "Chaque succursale se voit attribuer un numéro d'identification unique, qui diffère du numéro d'entreprise. Le numéro d'unité d'établissement se compose de 10 chiffres. Le premier chiffre est un chiffre de 2 à 8. Ce nombre est transférable d'une entité à une autre",
  'dbm.documentation.description.BE_ENTERPRISE_NUMBER': "Numéro d'entreprise",
  'dbm.documentation.description.BE_LEGAL_FORM_CODE': 'Forme juridique selon Graydon',
  'dbm.documentation.description.BE_MULTISCORE':
    'Le multiscore combine toutes les informations pertinentes dont dispose Graydon en un seul score pour évaluer une entreprise à moyen terme et en termes de santé financière, de paiement rapide et de chances de survie.',
  'dbm.documentation.description.BOARD_TYPE_CODE': "Type de gestion de l'entreprise",
  'dbm.documentation.description.BUILDING': '-',
  'dbm.documentation.description.CALAMITY': '-',
  'dbm.documentation.description.CALAMITY_START_DATE': '-',
  'dbm.documentation.description.CASH': "La trésorerie de l'entreprise",
  'dbm.documentation.description.CCJ_FLAG': '-',
  'dbm.documentation.description.CITY': 'Nom de la commune',
  'dbm.documentation.description.CODE_VALUE': 'Code valeur',
  'dbm.documentation.description.CONSOLIDATED_FLAG': '-',
  'dbm.documentation.description.CONSTRUCTION_YEAR': 'Année de construction',
  'dbm.documentation.description.CONTACT_ITEM_ROLE_CODE': 'Code de fonction du sujet du contact',
  'dbm.documentation.description.CONTACT_ITEM_TYPE_CODE':
    "Code valeur des informations du contact (comme l'e-mail, l'url, le téléphone, ...)",
  'dbm.documentation.description.CONTACT_ITEM_VALUE':
    'valeur de contact des différents types, exemple www.rikaint.com ou info@graydon.nl ou 0205679999',
  'dbm.documentation.description.CONTINUATION_START_DATE': '-',
  'dbm.documentation.description.CONTINUATION_TYPE_CODE': '-',
  'dbm.documentation.description.CONTRIBUTION': 'Apport',
  'dbm.documentation.description.COUNTRY_CODE': 'ISO alpha-2 code du pays',
  'dbm.documentation.description.COURT': 'Tribunal',
  'dbm.documentation.description.CREDIT_ADVICE':
    "Le montant maximum du crédit conseillé par Graydon pour tous les fournisseurs de l'entreprise, en euros.",
  'dbm.documentation.description.CREDIT_FLAG':
    "Indication du risque de faire des affaires avec l'entreprise demandée.",
  'dbm.documentation.description.CURRENCY_CODE': 'Code de devise, ISO 4217',
  'dbm.documentation.description.CURRENT_RATIO': 'Ratio actuel',
  'dbm.documentation.description.DATE_LAST_CCJ': '-',
  'dbm.documentation.description.DIRECT_PERCENTAGE': 'Pourcentage direct',
  'dbm.documentation.description.DISCONTINUATION_PROBABILITY':
    "Il s'agit du taux de discontinuité prévu pour l'entreprise (en pourcentage). Valeurs: [0.0, 100.0]. Plus le pourcentage est élevé, plus le risque de discontinuité est grand.",
  'dbm.documentation.description.DISCONTINUATION_SCORE':
    "II s'agit du score de discontinuité prévu pour l'entreprise. Il varie de 1 à 9. Le risque diminue de 1 à 9.",
  'dbm.documentation.description.DISSOLUTION_DATE': "Date de dissolution d'une succursale",
  'dbm.documentation.description.DO_NOT_MAIL_FLAG':
    "Indicateur qu'une entreprise ne souhaite pas recevoir de publipostage",
  'dbm.documentation.description.DOMAIN_CODE': 'Code de domaine',
  'dbm.documentation.description.EMAIL': 'E-mail',
  'dbm.documentation.description.EMPLOYEE_COUNT_ANNUAL_ACCOUNT':
    "Nombre d'employés selon les comptes annuels",
  'dbm.documentation.description.EMPLOYEE_COUNT_FULL_TIME':
    "Le nombre d'employés à temps plein dans l'entreprise",
  'dbm.documentation.description.EMPLOYEE_COUNT_TOTAL':
    "Le nombre total d'employés dans l'entreprise",
  'dbm.documentation.description.EN_DESCRIPTION': 'Description en anglais',
  'dbm.documentation.description.END_DATE': 'Date de fin',
  'dbm.documentation.description.END_REASON_CODE': 'Code de motif de fin',
  'dbm.documentation.description.ENTERPRISE_ID':
    'Identification unique dans la base de données Graydon pour identifier une entreprise',
  'dbm.documentation.description.ERC': 'Score standard pour les fournisseurs européens (ERC)',
  'dbm.documentation.description.EVENT_REFERENCE': 'Référence',
  'dbm.documentation.description.EVENTS_TYPE_CODE': "Code de type d'événement",
  'dbm.documentation.description.EXCUSABILITY_FLAG': 'Excusabilité',
  'dbm.documentation.description.EXPORT_FLAG':
    "Indication d'activité dans l'export, export Oui 34469 pour NL et 627650 Non",
  'dbm.documentation.description.FIGURE_VALUE': 'Valeur du chiffre clé',
  'dbm.documentation.description.FILED_FOR_ENTERPRISE_ID': '-',
  'dbm.documentation.description.FILED_FOR_NAME': 'Mettre pour le nom',
  'dbm.documentation.description.FILED_FOR_NL_KVK_NUMBER': '-',
  'dbm.documentation.description.FILING_DATE': 'La date de dépôt ',
  'dbm.documentation.description.FIXED_CAPITAL': 'Capital fixe',
  'dbm.documentation.description.FOUND_AT_CALAMITY': '-',
  'dbm.documentation.description.FOUND_AT_CALAMITY_START_DATE': '-',
  'dbm.documentation.description.FOUND_AT_ENTERPRISE_ID': '-',
  'dbm.documentation.description.FOUND_AT_ORG_LEVEL': '-',
  'dbm.documentation.description.FOUNDATION_DATE': "Date de création de l'entreprise, ISO 8601",
  'dbm.documentation.description.FOUNDER_FLAG':
    "La valeur Y représente l'actionnaire et le capital à partir du moment de la constitution",
  'dbm.documentation.description.FR_DESCRIPTION': 'Description en français',
  'dbm.documentation.description.FREE_TEXT': 'Texte libre',
  'dbm.documentation.description.GAZETTE_NUMBER': 'Numéro de statut AMB',
  'dbm.documentation.description.GB_AUGUR_SCORE': 'Le score Augur',
  'dbm.documentation.description.GB_CREDIT_RATING': '-',
  'dbm.documentation.description.GB_CRO_REGISTRATION_NUMBER': '-',
  'dbm.documentation.description.GB_LEGAL_FORM_CODE': '-',
  'dbm.documentation.description.GB_MAXIMUM_CREDIT_GUIDE': '-',
  'dbm.documentation.description.GB_MONTHLY_CREDIT_GUIDE': '-',
  'dbm.documentation.description.GDB_ACCOUNTING_PERIOD': '-',
  'dbm.documentation.description.GENDER_CODE': "Code du sexe de l'individu",
  'dbm.documentation.description.GENDER_CODE_RECEIVER_1': 'M , F or NULL',
  'dbm.documentation.description.GENDER_CODE_RECEIVER_2': 'M , F or NULL',
  'dbm.documentation.description.GENDER_CODE_RECEIVER_3': 'M , F or NULL',
  'dbm.documentation.description.GOV_GAZETTE_DATE': 'Date AMB',
  'dbm.documentation.description.GOV_GAZETTE_NUMBER': 'Numéro de statut AMB',
  'dbm.documentation.description.GOVERNMENT_GAZETTE_CODE': 'Code AMB',
  'dbm.documentation.description.GRAYDON_RATING':
    "La notation Graydon indique la probabilité de défaut d'une entreprise",
  'dbm.documentation.description.GROSS_MARGIN': 'Marge brute',
  'dbm.documentation.description.GROSS_SALES_MARGIN': 'Marge brute des ventes',
  'dbm.documentation.description.GROUP_EMPLOYEE_COUNT_FULL_TIME':
    "Nombre total d'employés à temps plein au niveau du groupe",
  'dbm.documentation.description.GROUP_EMPLOYEE_COUNT_TOTAL':
    "Nombre total d'employés au niveau du groupe",
  'dbm.documentation.description.GROWTH_SCORE':
    "C'est le score de croissance prévu de l'entreprise.\nIl existe 7 catégories :\n \n 1: Forte déclin\n 2: Déclin\n 3: Neutre.\n 4: Croissance\n 5: Forte croissance\n 99: Volatile\n NR: Non noté",
  'dbm.documentation.description.GROWTH_SEGMENT':
    "Ce segment indique le niveau des données d'entrée disponibles pour modéliser le score de croissance. \n Les entreprises sont regroupées en fonction de la disponibilité des données, comme suit: \n \n A: le plus haut niveau de données disponibles. \n B: le deuxième niveau le plus élevé de données disponibles. \n C: le troisième niveau le plus élevé de données disponibles. \n D: le niveau le plus bas de données disponibles",
  'dbm.documentation.description.HELD_BY_ORG_ENTERPRISE_ID': 'clé de traduction obsolète',
  'dbm.documentation.description.HIGHEST_AMOUNT_CCJ_LAST_12_MONTHS': '-',
  'dbm.documentation.description.HIGHEST_AMOUNT_CCJ_LAST_24_MONTHS': '-',
  'dbm.documentation.description.HIGHEST_AMOUNT_CCJ_LAST_36_MONTHS': '-',
  'dbm.documentation.description.HIGHEST_AMOUNT_CCJ_LAST_48_MONTHS': '-',
  'dbm.documentation.description.HIGHEST_AMOUNT_CCJ_LAST_60_MONTHS': '-',
  'dbm.documentation.description.HIGHEST_AMOUNT_CCJ_LAST_72_MONTHS': '-',
  'dbm.documentation.description.HOUSE_NUMBER': "Numéro de maison faisant partie d'une adresse",
  'dbm.documentation.description.HOUSE_NUMBER_SUFFIX':
    "Ajout de numéro de maison faisant partie d'une adresse",
  'dbm.documentation.description.IBAN': 'BE70645108445025',
  'dbm.documentation.description.IMPORT_FLAG':
    "Indication pour l'activité d'importation, importation oui = 50696 pour NL et non = 611699",
  'dbm.documentation.description.INFO_CODE': 'Code info',
  'dbm.documentation.description.INSOLVENCY_DETAIL_CODE': '-',
  'dbm.documentation.description.INSOLVENCY_FORM_NAME': 'Nom de forme',
  'dbm.documentation.description.INSOLVENCY_FREE_TEXT': 'Texte libre',
  'dbm.documentation.description.INSOLVENCY_JUDGMENT_TYPE_CODE': 'Type de jugement',
  'dbm.documentation.description.INSOLVENCY_TYPE_CODE': "Code du type d'insolvabilité",
  'dbm.documentation.description.INTEREST_SIZE_DESCRIPTION': '-',
  'dbm.documentation.description.INTEREST_TYPE_DESCRIPTION': '-',
  'dbm.documentation.description.IS_ACTIVE_STATUS_CODE':
    "Etat d'activité pour une entreprise - active ou non active",
  'dbm.documentation.description.IS_DISSOLVED_STATUS_CODE': "Etat de dissolution d'une entreprise",
  'dbm.documentation.description.IS_ECONOMICALLY_ACTIVE_STATUS_CODE':
    'Economiquement actif selon la Chambre de Commerce',
  'dbm.documentation.description.IS_INSOLVENT_STATUS_CODE': "Etat d'insolvabilité de l'entreprise",
  'dbm.documentation.description.IS_SOHO': 'Petit bureau / bureau à domicile',
  'dbm.documentation.description.IS_ZZP': 'Indicateur indépendant',
  'dbm.documentation.description.ISIC_ACTIVITY_CODE': 'Activité à 2 chiffres de la CITI de SBI',
  'dbm.documentation.description.ISSUE_DATE': 'Date de publication',
  'dbm.documentation.description.ISSUED_CAPITAL': 'Capital émis',
  'dbm.documentation.description.JUDGMENT_DATE': 'Date de jugement',
  'dbm.documentation.description.KEY_FIGURE_YEAR': 'Année des chiffres clés les plus récents',
  'dbm.documentation.description.LAST_NL_KVK_SOURCE_UPDATE_DATE': '-',
  'dbm.documentation.description.LATITUDE': 'Latitude GPS en degrés décimaux',
  'dbm.documentation.description.LEGAL_FORM_START_DATE': "Date de début d'une forme juridique",
  'dbm.documentation.description.LEI_NUMBER':
    "L'identifiant de l'entité légale (LEI) est un code alphanumérique de 20 caractères basé sur la norme ISO 17442 développée par l'Organisation internationale de normalisation (ISO). Il se connecte à des informations de référence clés qui permettent une identification unique des entités juridiques participant à des transactions financières.",
  'dbm.documentation.description.SEARCH_STATUS_DESCRIPTION': '-',
  'dbm.documentation.description.POSITION_MANDATE_AUTHORITY_DESCRIPTION': '-',
  'dbm.documentation.description.SEARCH_ROLE_DESCRIPTION': '-',
  'dbm.documentation.description.POSITION_TYPE_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_2_SEARCH_STATUS_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_2_POSITION_MANDATE_AUTHORITY_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_2_SEARCH_ROLE_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_2_POSITION_TYPE_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_2_POSITION_START_DATE': '-',
  'dbm.documentation.description.LEVEL_2_POSITION_END_DATE': '-',
  'dbm.documentation.description.LEVEL_3_SEARCH_STATUS_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_3_POSITION_MANDATE_AUTHORITY_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_3_SEARCH_ROLE_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_3_POSITION_TYPE_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_3_POSITION_START_DATE': '-',
  'dbm.documentation.description.LEVEL_3_POSITION_END_DATE': '-',
  'dbm.documentation.description.LEVEL_4_SEARCH_STATUS_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_4_POSITION_MANDATE_AUTHORITY_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_4_SEARCH_ROLE_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_4_POSITION_TYPE_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_4_POSITION_START_DATE': '-',
  'dbm.documentation.description.LEVEL_4_POSITION_END_DATE': '-',
  'dbm.documentation.description.LEVEL_5_SEARCH_STATUS_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_5_POSITION_MANDATE_AUTHORITY_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_5_SEARCH_ROLE_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_5_POSITION_TYPE_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_5_POSITION_START_DATE': '-',
  'dbm.documentation.description.LEVEL_5_POSITION_END_DATE': '-',
  'dbm.documentation.description.LEVEL_6_SEARCH_STATUS_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_6_POSITION_MANDATE_AUTHORITY_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_6_SEARCH_ROLE_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_6_POSITION_TYPE_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_6_POSITION_START_DATE': '-',
  'dbm.documentation.description.LEVEL_6_POSITION_END_DATE': '-',
  'dbm.documentation.description.LEVEL_7_SEARCH_STATUS_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_7_POSITION_MANDATE_AUTHORITY_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_7_SEARCH_ROLE_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_7_POSITION_TYPE_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_7_POSITION_START_DATE': '-',
  'dbm.documentation.description.LEVEL_7_POSITION_END_DATE': '-',
  'dbm.documentation.description.LEVEL_8_SEARCH_STATUS_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_8_POSITION_MANDATE_AUTHORITY_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_8_SEARCH_ROLE_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_8_POSITION_TYPE_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_8_POSITION_START_DATE': '-',
  'dbm.documentation.description.LEVEL_8_POSITION_END_DATE': '-',
  'dbm.documentation.description.LEVEL_9_SEARCH_STATUS_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_9_POSITION_MANDATE_AUTHORITY_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_9_SEARCH_ROLE_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_9_POSITION_TYPE_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_9_POSITION_START_DATE': '-',
  'dbm.documentation.description.LEVEL_9_POSITION_END_DATE': '-',
  'dbm.documentation.description.LEVEL_10_SEARCH_STATUS_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_10_POSITION_MANDATE_AUTHORITY_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_10_SEARCH_ROLE_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_10_POSITION_TYPE_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_10_POSITION_START_DATE': '-',
  'dbm.documentation.description.LEVEL_10_POSITION_END_DATE': '-',
  'dbm.documentation.description.LEVEL_11_SEARCH_STATUS_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_11_POSITION_MANDATE_AUTHORITY_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_11_SEARCH_ROLE_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_11_POSITION_TYPE_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_11_POSITION_START_DATE': '-',
  'dbm.documentation.description.LEVEL_11_POSITION_END_DATE': '-',
  'dbm.documentation.description.LEVEL_12_SEARCH_STATUS_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_12_POSITION_MANDATE_AUTHORITY_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_12_SEARCH_ROLE_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_12_POSITION_TYPE_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_12_POSITION_START_DATE': '-',
  'dbm.documentation.description.LEVEL_12_POSITION_END_DATE': '-',
  'dbm.documentation.description.LEVEL_1_ORGANIZATION_NAME': '-',
  'dbm.documentation.description.LEVEL_1_COUNTRY_CODE': '-',
  'dbm.documentation.description.LEVEL_1_COUNTRY': '-',
  'dbm.documentation.description.LEVEL_2_ORGANIZATION_NAME': '-',
  'dbm.documentation.description.LEVEL_2_COUNTRY_CODE': '-',
  'dbm.documentation.description.LEVEL_2_COUNTRY': '-',
  'dbm.documentation.description.LEVEL_3_ORGANIZATION_NAME': '-',
  'dbm.documentation.description.LEVEL_3_COUNTRY_CODE': '-',
  'dbm.documentation.description.LEVEL_3_COUNTRY': '-',
  'dbm.documentation.description.LEVEL_4_ORGANIZATION_NAME': '-',
  'dbm.documentation.description.LEVEL_4_COUNTRY_CODE': '-',
  'dbm.documentation.description.LEVEL_4_COUNTRY': '-',
  'dbm.documentation.description.LEVEL_5_ORGANIZATION_NAME': '-',
  'dbm.documentation.description.LEVEL_5_COUNTRY_CODE': '-',
  'dbm.documentation.description.LEVEL_5_COUNTRY': '-',
  'dbm.documentation.description.LEVEL_6_ORGANIZATION_NAME': '-',
  'dbm.documentation.description.LEVEL_6_COUNTRY_CODE': '-',
  'dbm.documentation.description.LEVEL_6_COUNTRY': '-',
  'dbm.documentation.description.LEVEL_7_ORGANIZATION_NAME': '-',
  'dbm.documentation.description.LEVEL_7_COUNTRY_CODE': '-',
  'dbm.documentation.description.LEVEL_7_COUNTRY': '-',
  'dbm.documentation.description.LEVEL_8_ORGANIZATION_NAME': '-',
  'dbm.documentation.description.LEVEL_8_COUNTRY_CODE': '-',
  'dbm.documentation.description.LEVEL_8_COUNTRY': '-',
  'dbm.documentation.description.LEVEL_9_ORGANIZATION_NAME': '-',
  'dbm.documentation.description.LEVEL_9_COUNTRY_CODE': '-',
  'dbm.documentation.description.LEVEL_9_COUNTRY': '-',
  'dbm.documentation.description.LEVEL_10_ORGANIZATION_NAME': '-',
  'dbm.documentation.description.LEVEL_10_COUNTRY_CODE': '-',
  'dbm.documentation.description.LEVEL_10_COUNTRY': '-',
  'dbm.documentation.description.LEVEL_11_ORGANIZATION_NAME': '-',
  'dbm.documentation.description.LEVEL_11_COUNTRY_CODE': '-',
  'dbm.documentation.description.LEVEL_11_COUNTRY': '-',
  'dbm.documentation.description.LEVEL_12_ORGANIZATION_NAME': '-',
  'dbm.documentation.description.LEVEL_12_COUNTRY_CODE': '-',
  'dbm.documentation.description.LEVEL_12_COUNTRY': '-',
  'dbm.documentation.description.LEVEL_1_CALAMITY': '-',
  'dbm.documentation.description.LEVEL_1_CALAMITY_START_DATE': '-',
  'dbm.documentation.description.LEVEL_2_CALAMITY': '-',
  'dbm.documentation.description.LEVEL_2_CALAMITY_START_DATE': '-',
  'dbm.documentation.description.LEVEL_3_CALAMITY': '-',
  'dbm.documentation.description.LEVEL_3_CALAMITY_START_DATE': '-',
  'dbm.documentation.description.LEVEL_4_CALAMITY': '-',
  'dbm.documentation.description.LEVEL_4_CALAMITY_START_DATE': '-',
  'dbm.documentation.description.LEVEL_5_CALAMITY': '-',
  'dbm.documentation.description.LEVEL_5_CALAMITY_START_DATE': '-',
  'dbm.documentation.description.LEVEL_6_CALAMITY': '-',
  'dbm.documentation.description.LEVEL_6_CALAMITY_START_DATE': '-',
  'dbm.documentation.description.LEVEL_7_CALAMITY': '-',
  'dbm.documentation.description.LEVEL_7_CALAMITY_START_DATE': '-',
  'dbm.documentation.description.LEVEL_8_CALAMITY': '-',
  'dbm.documentation.description.LEVEL_8_CALAMITY_START_DATE': '-',
  'dbm.documentation.description.LEVEL_9_CALAMITY': '-',
  'dbm.documentation.description.LEVEL_9_CALAMITY_START_DATE': '-',
  'dbm.documentation.description.LEVEL_10_CALAMITY': '-',
  'dbm.documentation.description.LEVEL_10_CALAMITY_START_DATE': '-',
  'dbm.documentation.description.LEVEL_11_CALAMITY': '-',
  'dbm.documentation.description.LEVEL_11_CALAMITY_START_DATE': '-',
  'dbm.documentation.description.LEVEL_12_CALAMITY': '-',
  'dbm.documentation.description.LEVEL_12_CALAMITY_START_DATE': '-',
  'dbm.documentation.description.LEVEL_1_ENTERPRISE_ID': '-',
  'dbm.documentation.description.LEVEL_1_NL_KVK_NUMBER': '-',
  'dbm.documentation.description.LEVEL_1_SEARCH_ROLE_CODE': '-',
  'dbm.documentation.description.LEVEL_1_SEARCH_STATUS_CODE': '-',
  'dbm.documentation.description.LEVEL_10_DIRECT_PERCENTAGE': '-',
  'dbm.documentation.description.LEVEL_10_ENTERPRISE_ID': '-',
  'dbm.documentation.description.LEVEL_10_INDIRECT_PERCENTAGE_SUBJECT': '-',
  'dbm.documentation.description.LEVEL_10_MANDATE_AUTHORITY_CODE': '-',
  'dbm.documentation.description.LEVEL_10_NL_KVK_NUMBER': '-',
  'dbm.documentation.description.LEVEL_10_ORG_ADDRESS_LINE_1': '-',
  'dbm.documentation.description.LEVEL_10_ORG_ADDRESS_LINE_2': '-',
  'dbm.documentation.description.LEVEL_10_ORG_ADDRESS_LINE_3': '-',
  'dbm.documentation.description.LEVEL_10_ORG_ADDRESS_LINE_4': '-',
  'dbm.documentation.description.LEVEL_10_ORG_ADDRESS_LINE_5': '-',
  'dbm.documentation.description.LEVEL_10_ORG_NAME': '-',
  'dbm.documentation.description.LEVEL_10_POSITION_TITLE_CODE': '-',
  'dbm.documentation.description.LEVEL_10_POSITION_TYPE_CODE': '-',
  'dbm.documentation.description.LEVEL_10_ROLE_TYPE_CODE': '-',
  'dbm.documentation.description.LEVEL_10_SEARCH_ROLE_CODE': '-',
  'dbm.documentation.description.LEVEL_10_SEARCH_STATUS_CODE': '-',
  'dbm.documentation.description.LEVEL_10_START_DATE': '-',
  'dbm.documentation.description.LEVEL_11_DIRECT_PERCENTAGE': 'Pourcentage direct',
  'dbm.documentation.description.LEVEL_11_ENTERPRISE_ID': '-',
  'dbm.documentation.description.LEVEL_11_INDIRECT_PERCENTAGE_SUBJECT': '-',
  'dbm.documentation.description.LEVEL_11_MANDATE_AUTHORITY_CODE': '-',
  'dbm.documentation.description.LEVEL_11_NL_KVK_NUMBER': '-',
  'dbm.documentation.description.LEVEL_11_ORG_ADDRESS_LINE_1': '-',
  'dbm.documentation.description.LEVEL_11_ORG_ADDRESS_LINE_2': '-',
  'dbm.documentation.description.LEVEL_11_ORG_ADDRESS_LINE_3': '-',
  'dbm.documentation.description.LEVEL_11_ORG_ADDRESS_LINE_4': '-',
  'dbm.documentation.description.LEVEL_11_ORG_ADDRESS_LINE_5': '-',
  'dbm.documentation.description.LEVEL_11_ORG_NAME': '-',
  'dbm.documentation.description.LEVEL_11_POSITION_TITLE_CODE': '-',
  'dbm.documentation.description.LEVEL_11_POSITION_TYPE_CODE': '-',
  'dbm.documentation.description.LEVEL_11_ROLE_TYPE_CODE': '-',
  'dbm.documentation.description.LEVEL_11_SEARCH_ROLE_CODE': '-',
  'dbm.documentation.description.LEVEL_11_SEARCH_STATUS_CODE': '-',
  'dbm.documentation.description.LEVEL_11_START_DATE': '-',
  'dbm.documentation.description.LEVEL_12_DIRECT_PERCENTAGE': 'Pourcentage direct',
  'dbm.documentation.description.LEVEL_12_ENTERPRISE_ID': '-',
  'dbm.documentation.description.LEVEL_12_INDIRECT_PERCENTAGE_SUBJECT': '-',
  'dbm.documentation.description.LEVEL_12_MANDATE_AUTHORITY_CODE': '-',
  'dbm.documentation.description.LEVEL_12_NL_KVK_NUMBER': '-',
  'dbm.documentation.description.LEVEL_12_ORG_ADDRESS_LINE_1': '-',
  'dbm.documentation.description.LEVEL_12_ORG_ADDRESS_LINE_2': '-',
  'dbm.documentation.description.LEVEL_12_ORG_ADDRESS_LINE_3': '-',
  'dbm.documentation.description.LEVEL_12_ORG_ADDRESS_LINE_4': '-',
  'dbm.documentation.description.LEVEL_12_ORG_ADDRESS_LINE_5': '-',
  'dbm.documentation.description.LEVEL_12_ORG_NAME': '-',
  'dbm.documentation.description.LEVEL_12_POSITION_TITLE_CODE': '-',
  'dbm.documentation.description.LEVEL_12_POSITION_TYPE_CODE': '-',
  'dbm.documentation.description.LEVEL_12_ROLE_TYPE_CODE': '-',
  'dbm.documentation.description.LEVEL_12_SEARCH_ROLE_CODE': '-',
  'dbm.documentation.description.LEVEL_12_SEARCH_STATUS_CODE': '-',
  'dbm.documentation.description.LEVEL_12_START_DATE': '-',
  'dbm.documentation.description.LEVEL_2_DIRECT_PERCENTAGE': 'Pourcentage direct',
  'dbm.documentation.description.LEVEL_2_ENTERPRISE_ID': '-',
  'dbm.documentation.description.LEVEL_2_INDIRECT_PERCENTAGE_SUBJECT': '-',
  'dbm.documentation.description.LEVEL_2_MANDATE_AUTHORITY_CODE': '-',
  'dbm.documentation.description.LEVEL_2_NL_KVK_NUMBER': '-',
  'dbm.documentation.description.LEVEL_2_ORG_ADDRESS_LINE_1': '-',
  'dbm.documentation.description.LEVEL_2_ORG_ADDRESS_LINE_2': '-',
  'dbm.documentation.description.LEVEL_2_ORG_ADDRESS_LINE_3': '-',
  'dbm.documentation.description.LEVEL_2_ORG_ADDRESS_LINE_4': '-',
  'dbm.documentation.description.LEVEL_2_ORG_ADDRESS_LINE_5': '-',
  'dbm.documentation.description.LEVEL_2_ORG_NAME': '-',
  'dbm.documentation.description.LEVEL_2_POSITION_TITLE_CODE': '-',
  'dbm.documentation.description.LEVEL_2_POSITION_TYPE_CODE': '-',
  'dbm.documentation.description.LEVEL_2_ROLE_TYPE_CODE': '-',
  'dbm.documentation.description.LEVEL_2_SEARCH_ROLE_CODE': '-',
  'dbm.documentation.description.LEVEL_2_SEARCH_STATUS_CODE': '-',
  'dbm.documentation.description.LEVEL_2_START_DATE': '-',
  'dbm.documentation.description.LEVEL_3_DIRECT_PERCENTAGE': 'Pourcentage direct',
  'dbm.documentation.description.LEVEL_3_ENTERPRISE_ID': '-',
  'dbm.documentation.description.LEVEL_3_INDIRECT_PERCENTAGE_SUBJECT': '-',
  'dbm.documentation.description.LEVEL_3_MANDATE_AUTHORITY_CODE': '-',
  'dbm.documentation.description.LEVEL_3_NL_KVK_NUMBER': '-',
  'dbm.documentation.description.LEVEL_3_ORG_ADDRESS_LINE_1': '-',
  'dbm.documentation.description.LEVEL_3_ORG_ADDRESS_LINE_2': '-',
  'dbm.documentation.description.LEVEL_3_ORG_ADDRESS_LINE_3': '-',
  'dbm.documentation.description.LEVEL_3_ORG_ADDRESS_LINE_4': '-',
  'dbm.documentation.description.LEVEL_3_ORG_ADDRESS_LINE_5': '-',
  'dbm.documentation.description.LEVEL_3_ORG_NAME': '-',
  'dbm.documentation.description.LEVEL_3_POSITION_TITLE_CODE': '-',
  'dbm.documentation.description.LEVEL_3_POSITION_TYPE_CODE': '-',
  'dbm.documentation.description.LEVEL_3_ROLE_TYPE_CODE': '-',
  'dbm.documentation.description.LEVEL_3_SEARCH_ROLE_CODE': '-',
  'dbm.documentation.description.LEVEL_3_SEARCH_STATUS_CODE': '-',
  'dbm.documentation.description.LEVEL_3_START_DATE': '-',
  'dbm.documentation.description.LEVEL_4_DIRECT_PERCENTAGE': '-',
  'dbm.documentation.description.LEVEL_4_ENTERPRISE_ID': '-',
  'dbm.documentation.description.LEVEL_4_INDIRECT_PERCENTAGE_SUBJECT': '-',
  'dbm.documentation.description.LEVEL_4_MANDATE_AUTHORITY_CODE': '-',
  'dbm.documentation.description.LEVEL_4_NL_KVK_NUMBER': '-',
  'dbm.documentation.description.LEVEL_4_ORG_ADDRESS_LINE_1': '-',
  'dbm.documentation.description.LEVEL_4_ORG_ADDRESS_LINE_2': '-',
  'dbm.documentation.description.LEVEL_4_ORG_ADDRESS_LINE_3': '-',
  'dbm.documentation.description.LEVEL_4_ORG_ADDRESS_LINE_4': '-',
  'dbm.documentation.description.LEVEL_4_ORG_ADDRESS_LINE_5': '-',
  'dbm.documentation.description.LEVEL_4_ORG_NAME': '-',
  'dbm.documentation.description.LEVEL_4_POSITION_TITLE_CODE': '-',
  'dbm.documentation.description.LEVEL_4_POSITION_TYPE_CODE': '-',
  'dbm.documentation.description.LEVEL_4_ROLE_TYPE_CODE': '-',
  'dbm.documentation.description.LEVEL_4_SEARCH_ROLE_CODE': '-',
  'dbm.documentation.description.LEVEL_4_SEARCH_STATUS_CODE': '-',
  'dbm.documentation.description.LEVEL_4_START_DATE': '-',
  'dbm.documentation.description.LEVEL_5_DIRECT_PERCENTAGE': '-',
  'dbm.documentation.description.LEVEL_5_ENTERPRISE_ID': '-',
  'dbm.documentation.description.LEVEL_5_INDIRECT_PERCENTAGE_SUBJECT': '-',
  'dbm.documentation.description.LEVEL_5_MANDATE_AUTHORITY_CODE': '-',
  'dbm.documentation.description.LEVEL_5_NL_KVK_NUMBER': '-',
  'dbm.documentation.description.LEVEL_5_ORG_ADDRESS_LINE_1': '-',
  'dbm.documentation.description.LEVEL_5_ORG_ADDRESS_LINE_2': '-',
  'dbm.documentation.description.LEVEL_5_ORG_ADDRESS_LINE_3': '-',
  'dbm.documentation.description.LEVEL_5_ORG_ADDRESS_LINE_4': '-',
  'dbm.documentation.description.LEVEL_5_ORG_ADDRESS_LINE_5': '-',
  'dbm.documentation.description.LEVEL_5_ORG_NAME': '-',
  'dbm.documentation.description.LEVEL_5_POSITION_TITLE_CODE': '-',
  'dbm.documentation.description.LEVEL_5_POSITION_TYPE_CODE': '-',
  'dbm.documentation.description.LEVEL_5_ROLE_TYPE_CODE': '-',
  'dbm.documentation.description.LEVEL_5_SEARCH_ROLE_CODE': '-',
  'dbm.documentation.description.LEVEL_5_SEARCH_STATUS_CODE': '-',
  'dbm.documentation.description.LEVEL_5_START_DATE': '-',
  'dbm.documentation.description.LEVEL_6_DIRECT_PERCENTAGE': '-',
  'dbm.documentation.description.LEVEL_6_ENTERPRISE_ID': '-',
  'dbm.documentation.description.LEVEL_6_INDIRECT_PERCENTAGE_SUBJECT': '-',
  'dbm.documentation.description.LEVEL_6_MANDATE_AUTHORITY_CODE': '-',
  'dbm.documentation.description.LEVEL_6_NL_KVK_NUMBER': '-',
  'dbm.documentation.description.LEVEL_6_ORG_ADDRESS_LINE_1': '-',
  'dbm.documentation.description.LEVEL_6_ORG_ADDRESS_LINE_2': '-',
  'dbm.documentation.description.LEVEL_6_ORG_ADDRESS_LINE_3': '-',
  'dbm.documentation.description.LEVEL_6_ORG_ADDRESS_LINE_4': '-',
  'dbm.documentation.description.LEVEL_6_ORG_ADDRESS_LINE_5': '-',
  'dbm.documentation.description.LEVEL_6_ORG_NAME': '-',
  'dbm.documentation.description.LEVEL_6_POSITION_TITLE_CODE': '-',
  'dbm.documentation.description.LEVEL_6_POSITION_TYPE_CODE': '-',
  'dbm.documentation.description.LEVEL_6_ROLE_TYPE_CODE': '-',
  'dbm.documentation.description.LEVEL_6_SEARCH_ROLE_CODE': '-',
  'dbm.documentation.description.LEVEL_6_SEARCH_STATUS_CODE': '-',
  'dbm.documentation.description.LEVEL_6_START_DATE': '-',
  'dbm.documentation.description.LEVEL_7_DIRECT_PERCENTAGE': 'Pourcentage direct',
  'dbm.documentation.description.LEVEL_7_ENTERPRISE_ID': '-',
  'dbm.documentation.description.LEVEL_7_INDIRECT_PERCENTAGE_SUBJECT': '-',
  'dbm.documentation.description.LEVEL_7_MANDATE_AUTHORITY_CODE': '-',
  'dbm.documentation.description.LEVEL_7_NL_KVK_NUMBER': '-',
  'dbm.documentation.description.LEVEL_7_ORG_ADDRESS_LINE_1': '-',
  'dbm.documentation.description.LEVEL_7_ORG_ADDRESS_LINE_2': '-',
  'dbm.documentation.description.LEVEL_7_ORG_ADDRESS_LINE_3': '-',
  'dbm.documentation.description.LEVEL_7_ORG_ADDRESS_LINE_4': '-',
  'dbm.documentation.description.LEVEL_7_ORG_ADDRESS_LINE_5': '-',
  'dbm.documentation.description.LEVEL_7_ORG_NAME': '-',
  'dbm.documentation.description.LEVEL_7_POSITION_TITLE_CODE': '-',
  'dbm.documentation.description.LEVEL_7_POSITION_TYPE_CODE': '-',
  'dbm.documentation.description.LEVEL_7_ROLE_TYPE_CODE': '-',
  'dbm.documentation.description.LEVEL_7_SEARCH_ROLE_CODE': '-',
  'dbm.documentation.description.LEVEL_7_SEARCH_STATUS_CODE': '-',
  'dbm.documentation.description.LEVEL_7_START_DATE': '-',
  'dbm.documentation.description.LEVEL_8_DIRECT_PERCENTAGE': 'Pourcentage direct',
  'dbm.documentation.description.LEVEL_8_ENTERPRISE_ID': '-',
  'dbm.documentation.description.LEVEL_8_INDIRECT_PERCENTAGE_SUBJECT': '-',
  'dbm.documentation.description.LEVEL_8_MANDATE_AUTHORITY_CODE': '-',
  'dbm.documentation.description.LEVEL_8_NL_KVK_NUMBER': '-',
  'dbm.documentation.description.LEVEL_8_ORG_ADDRESS_LINE_1': '-',
  'dbm.documentation.description.LEVEL_8_ORG_ADDRESS_LINE_2': '-',
  'dbm.documentation.description.LEVEL_8_ORG_ADDRESS_LINE_3': '-',
  'dbm.documentation.description.LEVEL_8_ORG_ADDRESS_LINE_4': '-',
  'dbm.documentation.description.LEVEL_8_ORG_ADDRESS_LINE_5': '-',
  'dbm.documentation.description.LEVEL_8_ORG_NAME': '-',
  'dbm.documentation.description.LEVEL_8_POSITION_TITLE_CODE': '-',
  'dbm.documentation.description.LEVEL_8_POSITION_TYPE_CODE': '-',
  'dbm.documentation.description.LEVEL_8_ROLE_TYPE_CODE': '-',
  'dbm.documentation.description.LEVEL_8_SEARCH_ROLE_CODE': '-',
  'dbm.documentation.description.LEVEL_8_SEARCH_STATUS_CODE': '-',
  'dbm.documentation.description.LEVEL_8_START_DATE': '-',
  'dbm.documentation.description.LEVEL_9_DIRECT_PERCENTAGE': 'Pourcentage direct',
  'dbm.documentation.description.LEVEL_9_ENTERPRISE_ID': '-',
  'dbm.documentation.description.LEVEL_9_INDIRECT_PERCENTAGE_SUBJECT': '-',
  'dbm.documentation.description.LEVEL_9_MANDATE_AUTHORITY_CODE': '-',
  'dbm.documentation.description.LEVEL_9_NL_KVK_NUMBER': '-',
  'dbm.documentation.description.LEVEL_9_ORG_ADDRESS_LINE_1': '-',
  'dbm.documentation.description.LEVEL_9_ORG_ADDRESS_LINE_2': '-',
  'dbm.documentation.description.LEVEL_9_ORG_ADDRESS_LINE_3': '-',
  'dbm.documentation.description.LEVEL_9_ORG_ADDRESS_LINE_4': '-',
  'dbm.documentation.description.LEVEL_9_ORG_ADDRESS_LINE_5': '-',
  'dbm.documentation.description.LEVEL_9_ORG_NAME': '-',
  'dbm.documentation.description.LEVEL_9_POSITION_TITLE_CODE': '-',
  'dbm.documentation.description.LEVEL_9_POSITION_TYPE_CODE': '-',
  'dbm.documentation.description.LEVEL_9_ROLE_TYPE_CODE': '-',
  'dbm.documentation.description.LEVEL_9_SEARCH_ROLE_CODE': '-',
  'dbm.documentation.description.LEVEL_9_SEARCH_STATUS_CODE': '-',
  'dbm.documentation.description.LEVEL_9_START_DATE': '-',
  'dbm.documentation.description.LIST_MGT_ORG_ID': '-',
  'dbm.documentation.description.LONGITUDE': 'Longitude GPS en degrés décimaux',
  'dbm.documentation.description.MEETING_DATE': "Date de l'assemblée générale",
  'dbm.documentation.description.MOBILE': 'Numéro de portable',
  'dbm.documentation.description.NACE_ACTIVITY_CODE':
    'Code Nace v2 pour l’activité de l’identification SBI',
  'dbm.documentation.description.NAME': "Nom de l'organisation",
  'dbm.documentation.description.NET_RESULT': 'Résultat net',
  'dbm.documentation.description.NL_DESCRIPTION': 'Description en néerlandais',
  'dbm.documentation.description.NL_KVK_BRANCH_NUMBER':
    "Chaque succursale d'une société ou d'une personne morale inscrite au registre du commerce possède un numéro d'emplacement unique de 12 chiffres.",
  'dbm.documentation.description.NL_KVK_NUMBER':
    'Identifiant unique - Identifiant officiel à 8 chiffres enregistré par la Chambre de Commerce',
  'dbm.documentation.description.NL_KVK_NUMBER_12':
    'unofficial 12 figure registered id from the Chamber of Commerce',
  'dbm.documentation.description.NL_LEGAL_FORM_CODE':
    "La forme juridique d'une société. Tapez le code tel qu'il est disponible dans la base de données Graydon",
  'dbm.documentation.description.NL_RSIN':
    "Lors de l'enregistrement auprès de la Chambre de Commerce, toutes les personnes morales et associations, telles que les sociétés privées, les associations, les fondations, les sociétés en nom collectif et les sociétés de personnes (mais pas les entreprises individuelles), reçoivent un numéro de Chambre de Commerce ainsi qu'un numéro d'identification pour les personnes morales et les associations (RSIN : Numéro d'information sur les entités juridiques et les sociétés de personnes). Ce RSIN est utilisé pour échanger des données avec d'autres organisations gouvernementales, telles que les autorités fiscales.",
  'dbm.documentation.description.NOMINAL_VALUE': 'Valeur nominale',
  'dbm.documentation.description.NOTICE_EVENT_DATE': "Date de l'événement",
  'dbm.documentation.description.NUM_BRANCH_OFFICES': "Nombre de filiales de l'entreprise",
  'dbm.documentation.description.NUM_CCJ_LAST_12_MONTHS': '-',
  'dbm.documentation.description.NUM_CCJ_LAST_24_MONTHS': '-',
  'dbm.documentation.description.NUM_CCJ_LAST_36_MONTHS': '-',
  'dbm.documentation.description.NUM_CCJ_LAST_48_MONTHS': '-',
  'dbm.documentation.description.NUM_CCJ_LAST_60_MONTHS': '-',
  'dbm.documentation.description.NUM_CCJ_LAST_72_MONTHS': '-',
  'dbm.documentation.description.NUM_EMPLOYEES_SIZE_CLASS_CODE': '-',
  'dbm.documentation.description.NUM_OF_SUPPLIERS': 'Nombre de fournisseurs',
  'dbm.documentation.description.NUM_PAYMENT_EXPERIENCES': "Nombre d'experiences de paiements",
  'dbm.documentation.description.NUM_SHARES':
    "Le nombre d'actions qu'un actionnaire détient dans la société demandée",
  'dbm.documentation.description.ORGANIZATION_ID':
    'Identification unique dans la base de données Graydon pour identifier une organisation (succursale, service ou organisation racine)',
  'dbm.documentation.description.ORGANIZATION_NAME': 'Nom',
  'dbm.documentation.description.ORGANIZATION_NAME_TYPE_CODE': "Code du type d'organisation",
  'dbm.documentation.description.ORGANIZATION_TYPE_CODE':
    "Type d'organisation , ROOT_ORG or BRANCH_OFFICE",
  'dbm.documentation.description.ORIGIN_CODE': 'Code source',
  'dbm.documentation.description.OTHER_ADDR_LINE_1': '-',
  'dbm.documentation.description.OTHER_ADDR_LINE_2': '-',
  'dbm.documentation.description.OTHER_ADDR_LINE_3': '-',
  'dbm.documentation.description.OTHER_ADDR_LINE_4': '-',
  'dbm.documentation.description.OTHER_ADDR_LINE_5': '-',
  'dbm.documentation.description.OTHER_BE_ENTERPRISE_NUMBER': "Autre numéro d'entreprise",
  'dbm.documentation.description.OTHER_ENTERPRISE_ID':
    "Organisation GDB YUID d'une autre organisation en relation externe.",
  'dbm.documentation.description.OTHER_GB_CRO_REGISTRATION_NUMBER': '-',
  'dbm.documentation.description.OTHER_NAME': '-',
  'dbm.documentation.description.OTHER_NL_KVK_NUMBER':
    'Identifiant unique - Identifiant officiel à 8 chiffres enregistré par la Chambre de commerce',
  'dbm.documentation.description.OTHER_ORG_ADDRESS_LINE_1':
    "Champ d'adresse 1 de l'adresse de l'autre organisation dans la relation externe.",
  'dbm.documentation.description.OTHER_ORG_ADDRESS_LINE_2':
    "Champ d'adresse 2 de l'adresse de l'autre organisation dans la relation externe.",
  'dbm.documentation.description.OTHER_ORG_ADDRESS_LINE_3':
    "Champ d'adresse 3 de l'adresse de l'autre organisation dans la relation externe.",
  'dbm.documentation.description.OTHER_ORG_ADDRESS_LINE_4':
    "Champ d'adresse 4 de l'adresse de l'autre organisation dans la relation externe.",
  'dbm.documentation.description.OTHER_ORG_ADDRESS_LINE_5':
    "Champ d'adresse 5 de l'adresse de l'autre organisation dans la relation externe.",
  'dbm.documentation.description.OTHER_ORG_NAME':
    "Nom d'une autre organisation en relation externe",
  'dbm.documentation.description.PAID_UP_CAPITAL': 'Capital versé',
  'dbm.documentation.description.PAID_UP_CONTRIBUTION': 'Contribution versé',
  'dbm.documentation.description.PAID_UP_FIXED_CAPITAL': 'Capital fixe libéré',
  'dbm.documentation.description.PAID_UP_VARIABLE_CAPITAL': 'Capital variable libéré',
  'dbm.documentation.description.PART_ADDR_LINE_1': "Champ d'adresse 1",
  'dbm.documentation.description.PART_ADDR_LINE_2': "Champ d'adresse 2",
  'dbm.documentation.description.PART_ADDR_LINE_3': "Champ d'adresse 3",
  'dbm.documentation.description.PART_ADDR_LINE_4': "Champ d'adresse 4",
  'dbm.documentation.description.PART_ADDR_LINE_5': "Champ d'adresse 5",
  'dbm.documentation.description.PART_BE_ENTERPRISE_NUMBER': "Numéro d'entreprise BCE",
  'dbm.documentation.description.PART_ENTERPRISE_ID':
    'Identifiant unique dans la base de données Graydon pour identifier une entreprise',
  'dbm.documentation.description.PART_NAME': 'Nom de la participation',
  'dbm.documentation.description.PART_NL_KVK_NUMBER':
    'Identifiant unique - Identifiant officiel à 8 chiffres enregistré par la Chambre de commerce',
  'dbm.documentation.description.PARTNERSHIP_CAPITAL': 'Capital participant',
  'dbm.documentation.description.PAYMENT_SCORE':
    "Le score de paiement reflète le comportement de paiement de l'entreprise",
  'dbm.documentation.description.PCT_OVER_90_DBT': 'Pourcentage supérieur à 90',
  'dbm.documentation.description.PCT_TO_30_DBT': 'Pourcentage de 1 à 30',
  'dbm.documentation.description.PCT_TO_60_DBT': 'Pourcentage de 31 à 60',
  'dbm.documentation.description.PCT_TO_90_DBT': 'Pourcentage de 61 à 90',
  'dbm.documentation.description.PCT_WITHIN_TERMS': 'Paiements à terme',
  'dbm.documentation.description.PD_PERCENTAGE': 'La probabilité de défaut en pourcentage',
  'dbm.documentation.description.PERSON_BIRTH_CITY': 'Lieu de naissance de la personne',
  'dbm.documentation.description.PERSON_BIRTH_COUNTRY_CODE':
    'Le code ISO du pays de naissance de la personne',
  'dbm.documentation.description.PERSON_BIRTH_DATE': 'Date de naissance de la personne',
  'dbm.documentation.description.PERSON_BIRTH_NDATE':
    "Date de naissance non formatée d'une personne à la direction de l'entreprise. Cette date n'a pas été vérifiée et provient directement de la source.",
  'dbm.documentation.description.PERSON_CITY': 'Nom de la commune',
  'dbm.documentation.description.PERSON_COUNTRY_CODE':
    "Code pays d'origine ISO alpha-2 (origine de la source officielle)",
  'dbm.documentation.description.PERSON_DEATH_DATE': 'Date de décès de la personne',
  'dbm.documentation.description.PERSON_DEATH_NDATE':
    'Date de décès de la personne (date non formatée)',
  'dbm.documentation.description.PERSON_DETAILS_PROTECTED': '-',
  'dbm.documentation.description.PERSON_FIRST_NAMES': 'Prénom',
  'dbm.documentation.description.PERSON_FULL_NAME': "Le nom complet d'une personne dans une chaîne",
  'dbm.documentation.description.PERSON_GENDER_CODE': 'Code du sexe de la personne (M, F, X)',
  'dbm.documentation.description.PERSON_HOUSE_NUMBER':
    "Numéro de maison faisant partie d'une adresse",
  'dbm.documentation.description.PERSON_HOUSE_NUMBER_SUFFIX':
    "Ajout de numéro de maison faisant partie d'une adresse",
  'dbm.documentation.description.PERSON_ID': 'ID de personne externe créé par Graydon',
  'dbm.documentation.description.PERSON_INITIALS': 'Initiales de la personne',
  'dbm.documentation.description.PERSON_POSTCODE': 'Code postal',
  'dbm.documentation.description.PERSON_STREET': 'Nom de la rue',
  'dbm.documentation.description.PERSON_STREET_CODE': 'Code des rues Belges',
  'dbm.documentation.description.PERSON_SURNAME':
    'Nom de famille ou dernière partie du nom de la personne',
  'dbm.documentation.description.PERSON_SURNAME_PREFIX':
    'Préfixe qui vient entre le prénom et le nom',
  'dbm.documentation.description.PERSON_TITLES': 'Titre de civilité',
  'dbm.documentation.description.PO_BOX_CITY': 'Nom de la commune',
  'dbm.documentation.description.PO_BOX_END_DATE':
    "La date indique jusqu'à quand l'adresse était utilisée par l'entreprise",
  'dbm.documentation.description.PO_BOX_NUMBER': "Numéro de boîte postale de l'organisation",
  'dbm.documentation.description.PO_BOX_POSTCODE': 'Code postal',
  'dbm.documentation.description.PO_BOX_START_DATE':
    "La date indique depuis quand l'adresse est utilisée par l'entreprise",
  'dbm.documentation.description.POSITION_END_DATE': 'Date de fin du mandat',
  'dbm.documentation.description.POSITION_HOLDER_ADDR_LINE_1': "Champ d'adresse 1",
  'dbm.documentation.description.POSITION_HOLDER_ADDR_LINE_2': "Champ d'adresse 2",
  'dbm.documentation.description.POSITION_HOLDER_ADDR_LINE_3': "Champ d'adresse 3",
  'dbm.documentation.description.POSITION_HOLDER_ADDR_LINE_4': "Champ d'adresse 4",
  'dbm.documentation.description.POSITION_HOLDER_ADDR_LINE_5': "Champ d'adresse 5",
  'dbm.documentation.description.POSITION_HOLDER_BE_ENTERPRISE_NUMBER': "Numéro d'entreprise",
  'dbm.documentation.description.POSITION_HOLDER_ENTERPRISE_ID':
    'Identification unique dans la base de données Graydon pour identifier une entreprise',
  'dbm.documentation.description.POSITION_HOLDER_NAME': 'Nom',
  'dbm.documentation.description.POSITION_HOLDER_NL_KVK_NUMBER': '-',
  'dbm.documentation.description.POSITION_MANDATE_AUTHORITY_CODE': 'Code de procuration',
  'dbm.documentation.description.POSITION_MANDATE_TEXT': 'Texte de procuration',
  'dbm.documentation.description.POSITION_START_DATE': 'Date de début du mandat',
  'dbm.documentation.description.POSITION_TITLE':
    'Description, en texte libre, du poste de direction',
  'dbm.documentation.description.POSITION_TITLE_CODE': 'Code de function',
  'dbm.documentation.description.POSITION_TYPE_CODE': 'Le type de poste occupé par une entité',
  'dbm.documentation.description.POSTCODE': 'Code postal',
  'dbm.documentation.description.PREFERRED_LANGUAGE_CODE':
    'Langue préférée, code ISO-639-1 (Belgique uniquement)',
  'dbm.documentation.description.PRELIMINARY_FLAG': 'Drapeau provisoire',
  'dbm.documentation.description.PRIMARY_ACTIVITY_CODE_1':
    "Un code standard pour identifier les activités de l'entreprise (primaire)",
  'dbm.documentation.description.QUARTER': 'Trimestre 1, 2 , 3 or 4',
  'dbm.documentation.description.QUICK_RATIO': "Ratio rapide de l'entreprise",
  'dbm.documentation.description.REASON_CODE': 'Code de raison',
  'dbm.documentation.description.RECEIVER_1': 'Curateur / Mandataire de justice',
  'dbm.documentation.description.RECEIVER_1_END_DATE': 'end date curator',
  'dbm.documentation.description.RECEIVER_1_TYPE_CODE': '-',
  'dbm.documentation.description.RECEIVER_10': 'Curateur / Mandataire de justice',
  'dbm.documentation.description.RECEIVER_2': 'Curateur / Mandataire de justice',
  'dbm.documentation.description.RECEIVER_2_END_DATE': 'end date curator',
  'dbm.documentation.description.RECEIVER_2_TYPE_CODE': '-',
  'dbm.documentation.description.RECEIVER_3': 'Curateur / Mandataire de justice',
  'dbm.documentation.description.RECEIVER_3_END_DATE': 'end date curator',
  'dbm.documentation.description.RECEIVER_3_TYPE_CODE': '-',
  'dbm.documentation.description.RECEIVER_4': 'Curateur / Mandataire de justice',
  'dbm.documentation.description.RECEIVER_4_TYPE_CODE': '-',
  'dbm.documentation.description.RECEIVER_5': 'Curateur / Mandataire de justice',
  'dbm.documentation.description.RECEIVER_5_TYPE_CODE': '-',
  'dbm.documentation.description.RECEIVER_6': 'Curateur / Mandataire de justice',
  'dbm.documentation.description.RECEIVER_7': 'Curateur / Mandataire de justice',
  'dbm.documentation.description.RECEIVER_8': 'Curateur / Mandataire de justice',
  'dbm.documentation.description.RECEIVER_9': 'Curateur / Mandataire de justice',
  'dbm.documentation.description.RECIEVER': 'Curateur / Mandataire de justice',
  'dbm.documentation.description.RECIEVER_TYPE_CODE':
    "Code de fonction dans les procédures d'insolvabilité",
  'dbm.documentation.description.REFERENCE': 'Votre référence',
  'dbm.documentation.description.REGION':
    "Code régional d''enregistrement de la société (Région wallonne, flamande, bruxelloise)",
  'dbm.documentation.description.RELATION_TYPE_CODE': 'Code type de relation',
  'dbm.documentation.description.REPRESENTATIVE_PERSON_BIRTH_CITY':
    'Lieu de naissance de la personne',
  'dbm.documentation.description.REPRESENTATIVE_PERSON_BIRTH_COUNTRY_CODE':
    'Code ISO du pays où la personne est née',
  'dbm.documentation.description.REPRESENTATIVE_PERSON_BIRTH_DATE':
    'Date de naissance de la personne',
  'dbm.documentation.description.REPRESENTATIVE_PERSON_BIRTH_NDATE':
    "Date de naissance non formatée d'une personne à la direction de l'entreprise. Cette date n'a pas été vérifiée et provient directement de la source.",
  'dbm.documentation.description.REPRESENTATIVE_PERSON_CITY': 'Nom de la commune',
  'dbm.documentation.description.REPRESENTATIVE_PERSON_COUNTRY_CODE':
    "Code pays d'origine ISO alpha-2 (origine de la source officielle)",
  'dbm.documentation.description.REPRESENTATIVE_PERSON_DEATH_DATE': 'Date de décès',
  'dbm.documentation.description.REPRESENTATIVE_PERSON_DEATH_NDATE': 'Date de décès - non formaté',
  'dbm.documentation.description.REPRESENTATIVE_PERSON_FIRST_NAMES':
    'Prénom du/de la représentant(e)',
  'dbm.documentation.description.REPRESENTATIVE_PERSON_FULL_NAME': 'Le nom complet de la personne',
  'dbm.documentation.description.REPRESENTATIVE_PERSON_GENDER_CODE':
    'Code relatif au sexe de la personne (M, F, X)',
  'dbm.documentation.description.REPRESENTATIVE_PERSON_HOUSE_NUMBER':
    "Numéro de maison faisant partie d'une adresse",
  'dbm.documentation.description.REPRESENTATIVE_PERSON_HOUSE_NUMBER_SUFFIX':
    "Ajout de numéro de maison faisant partie d'une adresse",
  'dbm.documentation.description.REPRESENTATIVE_PERSON_ID':
    'Identification du représentant Graydon',
  'dbm.documentation.description.REPRESENTATIVE_PERSON_INITIALS': "Initiales d'une personne",
  'dbm.documentation.description.REPRESENTATIVE_PERSON_POSTCODE': 'Code postal',
  'dbm.documentation.description.REPRESENTATIVE_PERSON_STREET': 'Nom de la rue',
  'dbm.documentation.description.REPRESENTATIVE_PERSON_STREET_CODE': 'Code des rues Belges',
  'dbm.documentation.description.REPRESENTATIVE_PERSON_SURNAME': 'Nom de famille du représentant',
  'dbm.documentation.description.REPRESENTATIVE_PERSON_SURNAME_PREFIX':
    'Le préfixe entre le prénom et le nom de famille',
  'dbm.documentation.description.REPRESENTATIVE_PERSON_TITLES': 'Titre de civilité',
  'dbm.documentation.description.RESULT_SEARCH_STATUS_CODE': '-',
  'dbm.documentation.description.ROW_TYPE':
    "Champ spécial qui indique quel type de données se trouve dans la file d'attente. Voir la documentation technique.",
  'dbm.documentation.description.RSZ_NUMBER': 'Numéro ONNS',
  'dbm.documentation.description.SBI_ACTIVITY_CODE':
    "Un code standard pour identifier les activités de l'entreprise, basé sur sbi2008",
  'dbm.documentation.description.SEARCH_ROLE_CODE': '-',
  'dbm.documentation.description.SECONDARY_ACTIVITY_CODE_1':
    "Un code standard pour identifier les activités de l'entreprise (primaire)",
  'dbm.documentation.description.SECONDARY_ACTIVITY_CODE_2':
    "Un code standard pour identifier les activités de l'entreprise (primaire)",
  'dbm.documentation.description.SECONDARY_ACTIVITY_CODE_3':
    "Un code standard pour identifier les activités de l'entreprise (primaire)",
  'dbm.documentation.description.SECTOR_CODE': "Code pour identifier l'industrie, code du secteur",
  'dbm.documentation.description.SEQUENCE_NUMBER': '-',
  'dbm.documentation.description.SHARE_PERCENTAGE': "Pourcentage d'actions détenues par une entité",
  'dbm.documentation.description.SHARE_TYPE_CODE': "type d'action",
  'dbm.documentation.description.SHAREHOLDER_ADDR_LINE_1': "Champ d'adresse 1",
  'dbm.documentation.description.SHAREHOLDER_ADDR_LINE_2': "Champ d'adresse 2",
  'dbm.documentation.description.SHAREHOLDER_ADDR_LINE_3': "Champ d'adresse 3",
  'dbm.documentation.description.SHAREHOLDER_ADDR_LINE_4': "Champ d'adresse 4",
  'dbm.documentation.description.SHAREHOLDER_ADDR_LINE_5':
    "Ligne 5 du champs de l'adresse de l'actionnaire. Adresse étrangère",
  'dbm.documentation.description.SHAREHOLDER_FUNDS': 'Les fonds des actionnaires',
  'dbm.documentation.description.SHAREHOLDER_NAME': "Nom de l'actionnaire",
  'dbm.documentation.description.SHL_BE_ENTERPRISE_NUMBER': "Numéro d'entreprise BCE",
  'dbm.documentation.description.SHL_ENTERPRISE_ID': "Numéro d'entreprise",
  'dbm.documentation.description.SHL_NL_KVK_NUMBER': '-',
  'dbm.documentation.description.SHL_PERSON_ID': 'Identifiant Graydon de la personne',
  'dbm.documentation.description.SIZE_CLASS_BE_RSZ_NUM_EMPLOYEES':
    "Classe du nombre d'employés ONNS",
  'dbm.documentation.description.SIZE_CLASS_EMPLOYEE_COUNT_FULL_TIME': "Catégorie d'employeur",
  'dbm.documentation.description.SIZE_CLASS_EMPLOYEE_COUNT_TOTAL':
    "Classe du nombre total d'employés totaal",
  'dbm.documentation.description.SIZE_CLASS_GROUP_EMPLOYEE_COUNT_FULL_TIME':
    "Classe du nombre d'employés à temps plein",
  'dbm.documentation.description.SIZE_CLASS_GROUP_EMPLOYEE_COUNT_TOTAL':
    "Classe du nombre total d'employés",
  'dbm.documentation.description.SIZE_CLASS_NUM_EMPLOYEES': "Classe du numbre d'employés",
  'dbm.documentation.description.SOURCE':
    'La source peut être la BNB ou AMB annexe du Moniteur belge',
  'dbm.documentation.description.SOURCE_REF': 'Code de la référence de la source',
  'dbm.documentation.description.START_DATE': 'Date de début',
  'dbm.documentation.description.STREET': 'Nom de la rue',
  'dbm.documentation.description.STREET_CODE': 'Code des rues Belges',
  'dbm.documentation.description.SUM_TOTAL_PERCENTAGE_SUBJECT_ALL': '-',
  'dbm.documentation.description.SUMMONS': 'Assignations à comparaître',
  'dbm.documentation.description.TEL_AREA_CODE': 'Numéro de zone',
  'dbm.documentation.description.TEL_COUNTRY_CODE': 'Indicatif téléphonique du pays',
  'dbm.documentation.description.TEL_SUBSCRIBER_NUMBER': "Numéro d'abonné de l'entreprise",
  'dbm.documentation.description.TELEPHONE': 'Numéro de téléphone',
  'dbm.documentation.description.TELEPHONE_RECEIVER_1': '-',
  'dbm.documentation.description.TELEPHONE_RECEIVER_2': '-',
  'dbm.documentation.description.TELEPHONE_RECEIVER_3': '-',
  'dbm.documentation.description.TITLES': 'Titre de civilité',
  'dbm.documentation.description.TITLES_RECEIVER_1': 'title of the receiver',
  'dbm.documentation.description.TITLES_RECEIVER_2': 'title of the receiver',
  'dbm.documentation.description.TITLES_RECEIVER_3': 'title of the receiver',
  'dbm.documentation.description.TOTAL_EQUITY': "Fonds propre de l'entreprise",
  'dbm.documentation.description.TOTAL_PERCENTAGE_SUBJECT': '-',
  'dbm.documentation.description.TRADE_NAME_1': 'first trade name',
  'dbm.documentation.description.TRADE_NAME_2': 'second trade name',
  'dbm.documentation.description.TRADE_NAME_3': '3th trade name',
  'dbm.documentation.description.TURNOVER': "Chiffre d'affaires de l'entreprise",
  'dbm.documentation.description.TYPE_CODE': 'Code type',
  'dbm.documentation.description.UBO_SOURCE': '-',
  'dbm.documentation.description.UBO_SOURCE_KVK_DATE': '-',
  'dbm.documentation.description.UNAVAILABLE_CONTRIBUTION': 'Apport indisponible',
  'dbm.documentation.description.UPDATED_ON': 'Mis à jour le',
  'dbm.documentation.description.URL': 'URL',
  'dbm.documentation.description.USE_CLASS_CODE_1': "Classe d'utilisation 1",
  'dbm.documentation.description.USE_CLASS_CODE_2': "Classe d'utilisation 2",
  'dbm.documentation.description.USE_CLASS_CODE_3': "Classe d'utilisation 3",
  'dbm.documentation.description.USE_CLASS_CODE_4': "Classe d'utilisation 4",
  'dbm.documentation.description.USE_CLASS_CODE_5': "Classe d'utilisation 5",
  'dbm.documentation.description.VARIABLE_CAPITAL': 'Capital variable',
  'dbm.documentation.description.VAT_LIABILITY':
    'Une entreprise est-elle assujettie à la TVA ou non ?',
  'dbm.documentation.description.VAT_NUMBER': 'Numéro de TVA unique',
  'dbm.documentation.description.VOTING_DATE': 'Date du vote',
  'dbm.documentation.description.WORKING_CAPITAL': "Fonds de roulement de l'entreprise",
  'dbm.documentation.description.XSEPTION_CATEGORY_CODE': "Code de catégorie de l'XSeption",
  'dbm.documentation.description.XSEPTION_NDATE_1': 'Date numérique 1',
  'dbm.documentation.description.XSEPTION_NDATE_2': 'Date numérique 2',
  'dbm.documentation.description.XSEPTION_TEXT': 'XSeptions',
  'dbm.documentation.description.XSEPTION_TYPE_CODE': "Code du type d'Xseption",
  'dbm.documentation.description.XSEPTION_VALUE_1': 'XSeption champ valeur 1',
  'dbm.documentation.description.XSEPTION_VALUE_2': 'XSeption champ valeur 2',
  'dbm.documentation.description.YEAR': 'Année calendaire',
  'dbm.documentation.description.NL_KVK_NUMBER_CONTINUATION': '-',
  'dbm.documentation.description.NL_LEGAL_FORM_DESCRIPTION': '-',
  'dbm.documentation.description.COUNTRY': '-',
  'dbm.documentation.description.FOUND_AT_NL_KVK_NUMBER': '-',
  'dbm.documentation.description.FOUND_AT_NL_LEGAL_FORM_DESCRIPTION': '-',
  'dbm.documentation.description.PERSON_BIRTH_COUNTRY': '-',
  'dbm.documentation.description.RESULT_SEARCH_STATUS_DESCRIPTION': '-',
  'dbm.documentation.description.INTEREST_TYPE_CODE': '-',
  'dbm.documentation.description.INTEREST_SIZE_CODE': '-',
  'dbm.documentation.description.REGISTRATION_DATE': '-',
  'dbm.documentation.domain-code.description':
    "Certains champs contiennent un code au lieu d'une valeur lisible par l'homme. Les codes possibles et leurs descriptions (en néerlandais, anglais et français) se trouvent dans le bloc de données DBM_CODE_DESCRIPTIONS, voir ci-dessus. Téléchargez ce bloc de données pour les versions les plus récentes des descriptions de chaque code.",
  'dbm.documentation.domain-code.description.title':
    'Code de domaine dans la description du code :',
  'dbm.documentation.domain-code.link': 'Informations sur les descriptions de code',
  'dbm.documentation.domain-code.title': '{country} Descriptions des codes',
  'dbm.documentation.download-example':
    'Télécharger / voir un exemple de cette version de bloc de données (.csv)',
  'dbm.documentation.example.ABBREVIATIONS_LEGAL_FORM': 'SA',
  'dbm.documentation.example.ACC_PERIOD_END_DATE': '2021-01-01',
  'dbm.documentation.example.ACC_PERIOD_START_DATE': '2012-01-01',
  'dbm.documentation.example.ACCOUNTANT_NAME': 'KPMG',
  'dbm.documentation.example.ACCOUNTING_PERIOD': '20120101-20121231',
  'dbm.documentation.example.ACTIVITY_CLASSIFICATION_CODE':
    'NL_SBI2008, BE_NACEBEL2008, GB_SIC2007',
  'dbm.documentation.example.ACTIVITY_CODE': '8513',
  'dbm.documentation.example.ACTIVITY_CODE_1': '8513',
  'dbm.documentation.example.ACTIVITY_CODE_2': '8513',
  'dbm.documentation.example.ACTIVITY_CODE_3': '8513',
  'dbm.documentation.example.ACTIVITY_CODE_4': '8513',
  'dbm.documentation.example.ACTIVITY_CODE_5': '8513',
  'dbm.documentation.example.ACTIVITY_DESCRIPTION': '-',
  'dbm.documentation.example.ACTIVITY_GROUP_CODE': 'CODE: BE_4',
  'dbm.documentation.example.ACTIVITY_SEQUENCE_NUMBER': '1',
  'dbm.documentation.example.ACTIVITY_START_DATE': '2021-01-01',
  'dbm.documentation.example.ACTIVITY_TYPE_CODE': '1',
  'dbm.documentation.example.ADDRESS_END_DATE': '2021-01-10',
  'dbm.documentation.example.ADDRESS_LINE_1': 'SOUTH RANGE 6 HIGH CARLBURY',
  'dbm.documentation.example.ADDRESS_LINE_1_RECEIVER_1': '-',
  'dbm.documentation.example.ADDRESS_LINE_1_RECEIVER_10': '-',
  'dbm.documentation.example.ADDRESS_LINE_1_RECEIVER_2': '-',
  'dbm.documentation.example.ADDRESS_LINE_1_RECEIVER_3': '-',
  'dbm.documentation.example.ADDRESS_LINE_1_RECEIVER_4': '-',
  'dbm.documentation.example.ADDRESS_LINE_1_RECEIVER_5': '-',
  'dbm.documentation.example.ADDRESS_LINE_1_RECEIVER_6': '-',
  'dbm.documentation.example.ADDRESS_LINE_1_RECEIVER_7': '-',
  'dbm.documentation.example.ADDRESS_LINE_1_RECEIVER_8': '-',
  'dbm.documentation.example.ADDRESS_LINE_1_RECEIVER_9': '-',
  'dbm.documentation.example.ADDRESS_LINE_2': 'PIERCEBRIDGE',
  'dbm.documentation.example.ADDRESS_LINE_2_RECEIVER_1': '3600KJ',
  'dbm.documentation.example.ADDRESS_LINE_2_RECEIVER_10': '-',
  'dbm.documentation.example.ADDRESS_LINE_2_RECEIVER_2': '-',
  'dbm.documentation.example.ADDRESS_LINE_2_RECEIVER_3': '3600KJ',
  'dbm.documentation.example.ADDRESS_LINE_2_RECEIVER_4': '3600KJ',
  'dbm.documentation.example.ADDRESS_LINE_2_RECEIVER_5': '3600KJ',
  'dbm.documentation.example.ADDRESS_LINE_2_RECEIVER_6': '-',
  'dbm.documentation.example.ADDRESS_LINE_2_RECEIVER_7': '-',
  'dbm.documentation.example.ADDRESS_LINE_2_RECEIVER_8': '-',
  'dbm.documentation.example.ADDRESS_LINE_2_RECEIVER_9': '-',
  'dbm.documentation.example.ADDRESS_LINE_3': 'DARLINGTON',
  'dbm.documentation.example.ADDRESS_LINE_3_RECEIVER_1': 'AMSTERDAM',
  'dbm.documentation.example.ADDRESS_LINE_3_RECEIVER_10': '-',
  'dbm.documentation.example.ADDRESS_LINE_3_RECEIVER_2': 'AMSTERDAM',
  'dbm.documentation.example.ADDRESS_LINE_3_RECEIVER_3': 'AMSTERDAM',
  'dbm.documentation.example.ADDRESS_LINE_3_RECEIVER_4': '-',
  'dbm.documentation.example.ADDRESS_LINE_3_RECEIVER_5': '-',
  'dbm.documentation.example.ADDRESS_LINE_3_RECEIVER_6': '-',
  'dbm.documentation.example.ADDRESS_LINE_3_RECEIVER_7': '-',
  'dbm.documentation.example.ADDRESS_LINE_3_RECEIVER_8': '-',
  'dbm.documentation.example.ADDRESS_LINE_3_RECEIVER_9': '-',
  'dbm.documentation.example.ADDRESS_LINE_4': 'CO DURHAM',
  'dbm.documentation.example.ADDRESS_LINE_4_RECEIVER_1': '-',
  'dbm.documentation.example.ADDRESS_LINE_4_RECEIVER_10': '-',
  'dbm.documentation.example.ADDRESS_LINE_4_RECEIVER_2': '-',
  'dbm.documentation.example.ADDRESS_LINE_4_RECEIVER_3': '-',
  'dbm.documentation.example.ADDRESS_LINE_4_RECEIVER_4': '-',
  'dbm.documentation.example.ADDRESS_LINE_4_RECEIVER_5': '-',
  'dbm.documentation.example.ADDRESS_LINE_4_RECEIVER_6': '-',
  'dbm.documentation.example.ADDRESS_LINE_4_RECEIVER_7': '-',
  'dbm.documentation.example.ADDRESS_LINE_4_RECEIVER_8': '-',
  'dbm.documentation.example.ADDRESS_LINE_4_RECEIVER_9': '-',
  'dbm.documentation.example.ADDRESS_LINE_5': 'UNITED KINGDOM',
  'dbm.documentation.example.ADDRESS_LINE_5_RECEIVER_1': '-',
  'dbm.documentation.example.ADDRESS_LINE_5_RECEIVER_10': '-',
  'dbm.documentation.example.ADDRESS_LINE_5_RECEIVER_2': '-',
  'dbm.documentation.example.ADDRESS_LINE_5_RECEIVER_3': '-',
  'dbm.documentation.example.ADDRESS_LINE_5_RECEIVER_4': '-',
  'dbm.documentation.example.ADDRESS_LINE_5_RECEIVER_5': '-',
  'dbm.documentation.example.ADDRESS_LINE_5_RECEIVER_6': '-',
  'dbm.documentation.example.ADDRESS_LINE_5_RECEIVER_7': '-',
  'dbm.documentation.example.ADDRESS_LINE_5_RECEIVER_8': '-',
  'dbm.documentation.example.ADDRESS_LINE_5_RECEIVER_9': '-',
  'dbm.documentation.example.ADDRESS_LINES': 'TRUE',
  'dbm.documentation.example.ADDRESS_ROLE_CODE': '1',
  'dbm.documentation.example.ADDRESS_START_DATE': '2021-01-10',
  'dbm.documentation.example.ADDRESS_TYPE_CODE': 'PHYSICAL',
  'dbm.documentation.example.AMOUNT_CATEGORY_CODE': 'BE_A',
  'dbm.documentation.example.AMOUNT_CCJ_LAST_12_MONTHS': '-',
  'dbm.documentation.example.AMOUNT_CCJ_LAST_24_MONTHS': '-',
  'dbm.documentation.example.AMOUNT_CCJ_LAST_36_MONTHS': '-',
  'dbm.documentation.example.AMOUNT_CCJ_LAST_48_MONTHS': '-',
  'dbm.documentation.example.AMOUNT_CCJ_LAST_60_MONTHS': '-',
  'dbm.documentation.example.AMOUNT_CCJ_LAST_72_MONTHS': '-',
  'dbm.documentation.example.AMOUNT_UNIT_CODE': '1',
  'dbm.documentation.example.ANNUAL_ACCOUNT_ITEM_CODE': 'NL_AST_20_00_002',
  'dbm.documentation.example.ANNUAL_ACCOUNT_ITEM_VALUE': '53786',
  'dbm.documentation.example.ANNUAL_ACCOUNT_TYPE_CODE': '40',
  'dbm.documentation.example.ANNUAL_REPORT_FILING_DATE': '2021-01-01',
  'dbm.documentation.example.ANNUAL_REPORT_TYPE_CODE': 'NL',
  'dbm.documentation.example.AREA': '5972',
  'dbm.documentation.example.ASSOCIATION_FUNDS': '125000',
  'dbm.documentation.example.AUTHORISED_CAPITAL': '500000',
  'dbm.documentation.example.AVAILABLE_CONTRIBUTION': '125000',
  'dbm.documentation.example.BAG_STATUS_CODE': '2',
  'dbm.documentation.example.BAG_TYPE_CODE': '1',
  'dbm.documentation.example.BALANCE_SHEET_TOTAL': '87000',
  'dbm.documentation.example.BANK_CODE': 'JVBA',
  'dbm.documentation.example.BANK_NAME': 'BANK J VAN BREDA',
  'dbm.documentation.example.BE_BUSINESS_UNIT_NUMBER': '2019802096',
  'dbm.documentation.example.BE_ENTERPRISE_NUMBER': '8123456',
  'dbm.documentation.example.BE_LEGAL_FORM_CODE': '12',
  'dbm.documentation.example.BE_MULTISCORE': '59',
  'dbm.documentation.example.BOARD_TYPE_CODE': '-',
  'dbm.documentation.example.BUILDING': '-',
  'dbm.documentation.example.CALAMITY': '-',
  'dbm.documentation.example.CALAMITY_START_DATE': '-',
  'dbm.documentation.example.CASH': '1',
  'dbm.documentation.example.CCJ_FLAG': '-',
  'dbm.documentation.example.CITY': 'Amsterdam',
  'dbm.documentation.example.CODE_VALUE': 'Y (=Oui), NL (=Pays-Bas)',
  'dbm.documentation.example.CONSOLIDATED_FLAG': 'Y',
  'dbm.documentation.example.CONSTRUCTION_YEAR': '1997',
  'dbm.documentation.example.CONTACT_ITEM_ROLE_CODE': '-',
  'dbm.documentation.example.CONTACT_ITEM_TYPE_CODE': 'E-mail, URL, téléphone, portable',
  'dbm.documentation.example.CONTACT_ITEM_VALUE': 'info@graydon.nl',
  'dbm.documentation.example.CONTINUATION_START_DATE': '2021-01-01',
  'dbm.documentation.example.CONTINUATION_TYPE_CODE': '-',
  'dbm.documentation.example.CONTRIBUTION': '125000',
  'dbm.documentation.example.COUNTRY_CODE': 'NL',
  'dbm.documentation.example.COURT': 'Namur',
  'dbm.documentation.example.CREDIT_ADVICE': '2000',
  'dbm.documentation.example.CREDIT_FLAG': 'G',
  'dbm.documentation.example.CURRENCY_CODE': 'EUR',
  'dbm.documentation.example.CURRENT_RATIO': '-',
  'dbm.documentation.example.DATE_LAST_CCJ': '-',
  'dbm.documentation.example.DIRECT_PERCENTAGE': '-',
  'dbm.documentation.example.DISCONTINUATION_PROBABILITY': '9.87',
  'dbm.documentation.example.DISCONTINUATION_SCORE': '3',
  'dbm.documentation.example.DISSOLUTION_DATE': '01/01/2021',
  'dbm.documentation.example.DO_NOT_MAIL_FLAG': 'Y',
  'dbm.documentation.example.DOMAIN_CODE': 'ORGANIZATION_STATUS_CODE, COUNTRY_CODE',
  'dbm.documentation.example.EMAIL': '-',
  'dbm.documentation.example.EMPLOYEE_COUNT_ANNUAL_ACCOUNT': '150',
  'dbm.documentation.example.EMPLOYEE_COUNT_FULL_TIME': '6',
  'dbm.documentation.example.EMPLOYEE_COUNT_TOTAL': '10',
  'dbm.documentation.example.EN_DESCRIPTION': 'Yes (for code Y)',
  'dbm.documentation.example.END_DATE': '2021-01-01',
  'dbm.documentation.example.END_REASON_CODE': 'NL_FAL_7',
  'dbm.documentation.example.ENTERPRISE_ID': '1234567',
  'dbm.documentation.example.ERC': '3',
  'dbm.documentation.example.EVENT_REFERENCE': '-',
  'dbm.documentation.example.EVENTS_TYPE_CODE': '-',
  'dbm.documentation.example.EXCUSABILITY_FLAG': '-',
  'dbm.documentation.example.EXPORT_FLAG': 'N',
  'dbm.documentation.example.FOUND_AT_CALAMITY': '-',
  'dbm.documentation.example.FOUND_AT_CALAMITY_START_DATE': '-',
  'dbm.documentation.example.FIGURE_VALUE': '88762',
  'dbm.documentation.example.FILED_FOR_ENTERPRISE_ID': '1234567',
  'dbm.documentation.example.FILED_FOR_NAME': 'Graydon Nederland B.V.',
  'dbm.documentation.example.FILED_FOR_NL_KVK_NUMBER': '81234560',
  'dbm.documentation.example.FILING_DATE': '2021-01-01',
  'dbm.documentation.example.FIXED_CAPITAL': '125000',
  'dbm.documentation.example.FOUND_AT_ENTERPRISE_ID': '-',
  'dbm.documentation.example.FOUND_AT_ORG_LEVEL': '-',
  'dbm.documentation.example.FOUNDATION_DATE': '2021-01-01',
  'dbm.documentation.example.FOUNDER_FLAG': 'Y',
  'dbm.documentation.example.FR_DESCRIPTION': 'Oui (pour le code Y)',
  'dbm.documentation.example.FREE_TEXT': '-',
  'dbm.documentation.example.GAZETTE_NUMBER': '-',
  'dbm.documentation.example.GOV_GAZETTE_DATE': '-',
  'dbm.documentation.example.GB_AUGUR_SCORE': '-',
  'dbm.documentation.example.GB_CREDIT_RATING': '-',
  'dbm.documentation.example.GB_CRO_REGISTRATION_NUMBER': '-',
  'dbm.documentation.example.GB_LEGAL_FORM_CODE': '-',
  'dbm.documentation.example.GB_MAXIMUM_CREDIT_GUIDE': '-',
  'dbm.documentation.example.GB_MONTHLY_CREDIT_GUIDE': '-',
  'dbm.documentation.example.GDB_ACCOUNTING_PERIOD': '-',
  'dbm.documentation.example.GENDER_CODE': 'M, F, X',
  'dbm.documentation.example.GENDER_CODE_RECEIVER_1': 'M',
  'dbm.documentation.example.GENDER_CODE_RECEIVER_2': 'M',
  'dbm.documentation.example.GENDER_CODE_RECEIVER_3': 'M',
  'dbm.documentation.example.GOV_GAZETTE_NUMBER': '-',
  'dbm.documentation.example.GOVERNMENT_GAZETTE_CODE': '-',
  'dbm.documentation.example.GRAYDON_RATING': 'AA',
  'dbm.documentation.example.GROSS_MARGIN': '1',
  'dbm.documentation.example.GROSS_SALES_MARGIN': '1',
  'dbm.documentation.example.GROUP_EMPLOYEE_COUNT_FULL_TIME': '9000',
  'dbm.documentation.example.GROUP_EMPLOYEE_COUNT_TOTAL': '10000',
  'dbm.documentation.example.GROWTH_SCORE': '2',
  'dbm.documentation.example.GROWTH_SEGMENT': 'A',
  'dbm.documentation.example.HELD_BY_ORG_ENTERPRISE_ID': 'clé de traduction obsolète',
  'dbm.documentation.example.HIGHEST_AMOUNT_CCJ_LAST_12_MONTHS': '-',
  'dbm.documentation.example.HIGHEST_AMOUNT_CCJ_LAST_24_MONTHS': '-',
  'dbm.documentation.example.HIGHEST_AMOUNT_CCJ_LAST_36_MONTHS': '-',
  'dbm.documentation.example.HIGHEST_AMOUNT_CCJ_LAST_48_MONTHS': '-',
  'dbm.documentation.example.HIGHEST_AMOUNT_CCJ_LAST_60_MONTHS': '-',
  'dbm.documentation.example.HIGHEST_AMOUNT_CCJ_LAST_72_MONTHS': '-',
  'dbm.documentation.example.HOUSE_NUMBER': '250',
  'dbm.documentation.example.HOUSE_NUMBER_SUFFIX': '-A',
  'dbm.documentation.example.IBAN': 'Numéro de compte bancaire ou IBAN',
  'dbm.documentation.example.IMPORT_FLAG': 'Y',
  'dbm.documentation.example.INFO_CODE': '-',
  'dbm.documentation.example.INSOLVENCY_DETAIL_CODE': '-',
  'dbm.documentation.example.INSOLVENCY_FORM_NAME': '-',
  'dbm.documentation.example.INSOLVENCY_FREE_TEXT': '-',
  'dbm.documentation.example.INSOLVENCY_JUDGMENT_TYPE_CODE': '-',
  'dbm.documentation.example.INSOLVENCY_TYPE_CODE': 'NL_1',
  'dbm.documentation.example.INTEREST_SIZE_DESCRIPTION': '-',
  'dbm.documentation.example.INTEREST_TYPE_DESCRIPTION': '-',
  'dbm.documentation.example.IS_ACTIVE_STATUS_CODE': 'Y',
  'dbm.documentation.example.IS_DISSOLVED_STATUS_CODE': 'N',
  'dbm.documentation.example.IS_ECONOMICALLY_ACTIVE_STATUS_CODE': 'N',
  'dbm.documentation.example.IS_INSOLVENT_STATUS_CODE': 'Y',
  'dbm.documentation.example.IS_SOHO': 'Y',
  'dbm.documentation.example.IS_ZZP': 'Y',
  'dbm.documentation.example.ISIC_ACTIVITY_CODE': '15',
  'dbm.documentation.example.ISSUE_DATE': '-',
  'dbm.documentation.example.ISSUED_CAPITAL': '50000',
  'dbm.documentation.example.JUDGMENT_DATE': '2021-01-01',
  'dbm.documentation.example.KEY_FIGURE_YEAR': '2018',
  'dbm.documentation.example.LAST_NL_KVK_SOURCE_UPDATE_DATE': '-',
  'dbm.documentation.example.LATITUDE': '51.30338',
  'dbm.documentation.example.LEGAL_FORM_START_DATE': '2021-01-01',
  'dbm.documentation.example.LEI_NUMBER': '72450056A9WLXUPSI574',
  'dbm.documentation.example.SEARCH_STATUS_DESCRIPTION': '-',
  'dbm.documentation.example.POSITION_MANDATE_AUTHORITY_DESCRIPTION': '-',
  'dbm.documentation.example.SEARCH_ROLE_DESCRIPTION': '-',
  'dbm.documentation.example.POSITION_TYPE_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_2_SEARCH_STATUS_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_2_POSITION_MANDATE_AUTHORITY_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_2_SEARCH_ROLE_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_2_POSITION_TYPE_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_2_POSITION_START_DATE': '-',
  'dbm.documentation.example.LEVEL_2_POSITION_END_DATE': '-',
  'dbm.documentation.example.LEVEL_3_SEARCH_STATUS_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_3_POSITION_MANDATE_AUTHORITY_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_3_SEARCH_ROLE_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_3_POSITION_TYPE_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_3_POSITION_START_DATE': '-',
  'dbm.documentation.example.LEVEL_3_POSITION_END_DATE': '-',
  'dbm.documentation.example.LEVEL_4_SEARCH_STATUS_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_4_POSITION_MANDATE_AUTHORITY_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_4_SEARCH_ROLE_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_4_POSITION_TYPE_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_4_POSITION_START_DATE': '-',
  'dbm.documentation.example.LEVEL_4_POSITION_END_DATE': '-',
  'dbm.documentation.example.LEVEL_5_SEARCH_STATUS_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_5_POSITION_MANDATE_AUTHORITY_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_5_SEARCH_ROLE_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_5_POSITION_TYPE_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_5_POSITION_START_DATE': '-',
  'dbm.documentation.example.LEVEL_5_POSITION_END_DATE': '-',
  'dbm.documentation.example.LEVEL_6_SEARCH_STATUS_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_6_POSITION_MANDATE_AUTHORITY_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_6_SEARCH_ROLE_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_6_POSITION_TYPE_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_6_POSITION_START_DATE': '-',
  'dbm.documentation.example.LEVEL_6_POSITION_END_DATE': '-',
  'dbm.documentation.example.LEVEL_7_SEARCH_STATUS_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_7_POSITION_MANDATE_AUTHORITY_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_7_SEARCH_ROLE_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_7_POSITION_TYPE_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_7_POSITION_START_DATE': '-',
  'dbm.documentation.example.LEVEL_7_POSITION_END_DATE': '-',
  'dbm.documentation.example.LEVEL_8_SEARCH_STATUS_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_8_POSITION_MANDATE_AUTHORITY_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_8_SEARCH_ROLE_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_8_POSITION_TYPE_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_8_POSITION_START_DATE': '-',
  'dbm.documentation.example.LEVEL_8_POSITION_END_DATE': '-',
  'dbm.documentation.example.LEVEL_9_SEARCH_STATUS_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_9_POSITION_MANDATE_AUTHORITY_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_9_SEARCH_ROLE_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_9_POSITION_TYPE_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_9_POSITION_START_DATE': '-',
  'dbm.documentation.example.LEVEL_9_POSITION_END_DATE': '-',
  'dbm.documentation.example.LEVEL_10_SEARCH_STATUS_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_10_POSITION_MANDATE_AUTHORITY_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_10_SEARCH_ROLE_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_10_POSITION_TYPE_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_10_POSITION_START_DATE': '-',
  'dbm.documentation.example.LEVEL_10_POSITION_END_DATE': '-',
  'dbm.documentation.example.LEVEL_11_SEARCH_STATUS_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_11_POSITION_MANDATE_AUTHORITY_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_11_SEARCH_ROLE_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_11_POSITION_TYPE_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_11_POSITION_START_DATE': '-',
  'dbm.documentation.example.LEVEL_11_POSITION_END_DATE': '-',
  'dbm.documentation.example.LEVEL_12_SEARCH_STATUS_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_12_POSITION_MANDATE_AUTHORITY_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_12_SEARCH_ROLE_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_12_POSITION_TYPE_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_12_POSITION_START_DATE': '-',
  'dbm.documentation.example.LEVEL_12_POSITION_END_DATE': '-',
  'dbm.documentation.example.LEVEL_1_ORGANIZATION_NAME': '-',
  'dbm.documentation.example.LEVEL_1_COUNTRY_CODE': '-',
  'dbm.documentation.example.LEVEL_1_COUNTRY': '-',
  'dbm.documentation.example.LEVEL_2_ORGANIZATION_NAME': '-',
  'dbm.documentation.example.LEVEL_2_COUNTRY_CODE': '-',
  'dbm.documentation.example.LEVEL_2_COUNTRY': '-',
  'dbm.documentation.example.LEVEL_3_ORGANIZATION_NAME': '-',
  'dbm.documentation.example.LEVEL_3_COUNTRY_CODE': '-',
  'dbm.documentation.example.LEVEL_3_COUNTRY': '-',
  'dbm.documentation.example.LEVEL_4_ORGANIZATION_NAME': '-',
  'dbm.documentation.example.LEVEL_4_COUNTRY_CODE': '-',
  'dbm.documentation.example.LEVEL_4_COUNTRY': '-',
  'dbm.documentation.example.LEVEL_5_ORGANIZATION_NAME': '-',
  'dbm.documentation.example.LEVEL_5_COUNTRY_CODE': '-',
  'dbm.documentation.example.LEVEL_5_COUNTRY': '-',
  'dbm.documentation.example.LEVEL_6_ORGANIZATION_NAME': '-',
  'dbm.documentation.example.LEVEL_6_COUNTRY_CODE': '-',
  'dbm.documentation.example.LEVEL_6_COUNTRY': '-',
  'dbm.documentation.example.LEVEL_7_ORGANIZATION_NAME': '-',
  'dbm.documentation.example.LEVEL_7_COUNTRY_CODE': '-',
  'dbm.documentation.example.LEVEL_7_COUNTRY': '-',
  'dbm.documentation.example.LEVEL_8_ORGANIZATION_NAME': '-',
  'dbm.documentation.example.LEVEL_8_COUNTRY_CODE': '-',
  'dbm.documentation.example.LEVEL_8_COUNTRY': '-',
  'dbm.documentation.example.LEVEL_9_ORGANIZATION_NAME': '-',
  'dbm.documentation.example.LEVEL_9_COUNTRY_CODE': '-',
  'dbm.documentation.example.LEVEL_9_COUNTRY': '-',
  'dbm.documentation.example.LEVEL_10_ORGANIZATION_NAME': '-',
  'dbm.documentation.example.LEVEL_10_COUNTRY_CODE': '-',
  'dbm.documentation.example.LEVEL_10_COUNTRY': '-',
  'dbm.documentation.example.LEVEL_11_ORGANIZATION_NAME': '-',
  'dbm.documentation.example.LEVEL_11_COUNTRY_CODE': '-',
  'dbm.documentation.example.LEVEL_11_COUNTRY': '-',
  'dbm.documentation.example.LEVEL_12_ORGANIZATION_NAME': '-',
  'dbm.documentation.example.LEVEL_12_COUNTRY_CODE': '-',
  'dbm.documentation.example.LEVEL_12_COUNTRY': '-',
  'dbm.documentation.example.LEVEL_1_CALAMITY': '-',
  'dbm.documentation.example.LEVEL_1_CALAMITY_START_DATE': '-',
  'dbm.documentation.example.LEVEL_2_CALAMITY': '-',
  'dbm.documentation.example.LEVEL_2_CALAMITY_START_DATE': '-',
  'dbm.documentation.example.LEVEL_3_CALAMITY': '-',
  'dbm.documentation.example.LEVEL_3_CALAMITY_START_DATE': '-',
  'dbm.documentation.example.LEVEL_4_CALAMITY': '-',
  'dbm.documentation.example.LEVEL_4_CALAMITY_START_DATE': '-',
  'dbm.documentation.example.LEVEL_5_CALAMITY': '-',
  'dbm.documentation.example.LEVEL_5_CALAMITY_START_DATE': '-',
  'dbm.documentation.example.LEVEL_6_CALAMITY': '-',
  'dbm.documentation.example.LEVEL_6_CALAMITY_START_DATE': '-',
  'dbm.documentation.example.LEVEL_7_CALAMITY': '-',
  'dbm.documentation.example.LEVEL_7_CALAMITY_START_DATE': '-',
  'dbm.documentation.example.LEVEL_8_CALAMITY': '-',
  'dbm.documentation.example.LEVEL_8_CALAMITY_START_DATE': '-',
  'dbm.documentation.example.LEVEL_9_CALAMITY': '-',
  'dbm.documentation.example.LEVEL_9_CALAMITY_START_DATE': '-',
  'dbm.documentation.example.LEVEL_10_CALAMITY': '-',
  'dbm.documentation.example.LEVEL_10_CALAMITY_START_DATE': '-',
  'dbm.documentation.example.LEVEL_11_CALAMITY': '-',
  'dbm.documentation.example.LEVEL_11_CALAMITY_START_DATE': '-',
  'dbm.documentation.example.LEVEL_12_CALAMITY': '-',
  'dbm.documentation.example.LEVEL_12_CALAMITY_START_DATE': '-',
  'dbm.documentation.example.LEVEL_1_ENTERPRISE_ID': '-',
  'dbm.documentation.example.LEVEL_1_NL_KVK_NUMBER': '81234560',
  'dbm.documentation.example.LEVEL_1_SEARCH_ROLE_CODE': '-',
  'dbm.documentation.example.LEVEL_1_SEARCH_STATUS_CODE': '-',
  'dbm.documentation.example.LEVEL_10_DIRECT_PERCENTAGE': '-',
  'dbm.documentation.example.LEVEL_10_ENTERPRISE_ID': '-',
  'dbm.documentation.example.LEVEL_10_INDIRECT_PERCENTAGE_SUBJECT': '-',
  'dbm.documentation.example.LEVEL_10_MANDATE_AUTHORITY_CODE': '-',
  'dbm.documentation.example.LEVEL_10_NL_KVK_NUMBER': '81234560',
  'dbm.documentation.example.LEVEL_10_ORG_ADDRESS_LINE_1': '-',
  'dbm.documentation.example.LEVEL_10_ORG_ADDRESS_LINE_2': '-',
  'dbm.documentation.example.LEVEL_10_ORG_ADDRESS_LINE_3': '-',
  'dbm.documentation.example.LEVEL_10_ORG_ADDRESS_LINE_4': '-',
  'dbm.documentation.example.LEVEL_10_ORG_ADDRESS_LINE_5': '-',
  'dbm.documentation.example.LEVEL_10_ORG_NAME': '-',
  'dbm.documentation.example.LEVEL_10_POSITION_TITLE_CODE': '-',
  'dbm.documentation.example.LEVEL_10_POSITION_TYPE_CODE': '-',
  'dbm.documentation.example.LEVEL_10_ROLE_TYPE_CODE': '-',
  'dbm.documentation.example.LEVEL_10_SEARCH_ROLE_CODE': '-',
  'dbm.documentation.example.LEVEL_10_SEARCH_STATUS_CODE': '-',
  'dbm.documentation.example.LEVEL_10_START_DATE': '2021-01-01',
  'dbm.documentation.example.LEVEL_11_DIRECT_PERCENTAGE': '-',
  'dbm.documentation.example.LEVEL_11_ENTERPRISE_ID': '-',
  'dbm.documentation.example.LEVEL_11_INDIRECT_PERCENTAGE_SUBJECT': '-',
  'dbm.documentation.example.LEVEL_11_MANDATE_AUTHORITY_CODE': '-',
  'dbm.documentation.example.LEVEL_11_NL_KVK_NUMBER': '81234560',
  'dbm.documentation.example.LEVEL_11_ORG_ADDRESS_LINE_1': '-',
  'dbm.documentation.example.LEVEL_11_ORG_ADDRESS_LINE_2': '-',
  'dbm.documentation.example.LEVEL_11_ORG_ADDRESS_LINE_3': '-',
  'dbm.documentation.example.LEVEL_11_ORG_ADDRESS_LINE_4': '-',
  'dbm.documentation.example.LEVEL_11_ORG_ADDRESS_LINE_5': '-',
  'dbm.documentation.example.LEVEL_11_ORG_NAME': '-',
  'dbm.documentation.example.LEVEL_11_POSITION_TITLE_CODE': '-',
  'dbm.documentation.example.LEVEL_11_POSITION_TYPE_CODE': '-',
  'dbm.documentation.example.LEVEL_11_ROLE_TYPE_CODE': '-',
  'dbm.documentation.example.LEVEL_11_SEARCH_ROLE_CODE': '-',
  'dbm.documentation.example.LEVEL_11_SEARCH_STATUS_CODE': '-',
  'dbm.documentation.example.LEVEL_11_START_DATE': '2021-01-01',
  'dbm.documentation.example.LEVEL_12_DIRECT_PERCENTAGE': '-',
  'dbm.documentation.example.LEVEL_12_ENTERPRISE_ID': '-',
  'dbm.documentation.example.LEVEL_12_INDIRECT_PERCENTAGE_SUBJECT': '-',
  'dbm.documentation.example.LEVEL_12_MANDATE_AUTHORITY_CODE': '-',
  'dbm.documentation.example.LEVEL_12_NL_KVK_NUMBER': '81234560',
  'dbm.documentation.example.LEVEL_12_ORG_ADDRESS_LINE_1': '-',
  'dbm.documentation.example.LEVEL_12_ORG_ADDRESS_LINE_2': '-',
  'dbm.documentation.example.LEVEL_12_ORG_ADDRESS_LINE_3': '-',
  'dbm.documentation.example.LEVEL_12_ORG_ADDRESS_LINE_4': '-',
  'dbm.documentation.example.LEVEL_12_ORG_ADDRESS_LINE_5': '-',
  'dbm.documentation.example.LEVEL_12_ORG_NAME': '-',
  'dbm.documentation.example.LEVEL_12_POSITION_TITLE_CODE': '-',
  'dbm.documentation.example.LEVEL_12_POSITION_TYPE_CODE': '-',
  'dbm.documentation.example.LEVEL_12_ROLE_TYPE_CODE': '-',
  'dbm.documentation.example.LEVEL_12_SEARCH_ROLE_CODE': '-',
  'dbm.documentation.example.LEVEL_12_SEARCH_STATUS_CODE': '-',
  'dbm.documentation.example.LEVEL_12_START_DATE': '2021-01-01',
  'dbm.documentation.example.LEVEL_2_DIRECT_PERCENTAGE': '-',
  'dbm.documentation.example.LEVEL_2_ENTERPRISE_ID': '-',
  'dbm.documentation.example.LEVEL_2_INDIRECT_PERCENTAGE_SUBJECT': '-',
  'dbm.documentation.example.LEVEL_2_MANDATE_AUTHORITY_CODE': '-',
  'dbm.documentation.example.LEVEL_2_NL_KVK_NUMBER': '81234560',
  'dbm.documentation.example.LEVEL_2_ORG_ADDRESS_LINE_1': '-',
  'dbm.documentation.example.LEVEL_2_ORG_ADDRESS_LINE_2': '-',
  'dbm.documentation.example.LEVEL_2_ORG_ADDRESS_LINE_3': '-',
  'dbm.documentation.example.LEVEL_2_ORG_ADDRESS_LINE_4': '-',
  'dbm.documentation.example.LEVEL_2_ORG_ADDRESS_LINE_5': '-',
  'dbm.documentation.example.LEVEL_2_ORG_NAME': '-',
  'dbm.documentation.example.LEVEL_2_POSITION_TITLE_CODE': '-',
  'dbm.documentation.example.LEVEL_2_POSITION_TYPE_CODE': '-',
  'dbm.documentation.example.LEVEL_2_ROLE_TYPE_CODE': '-',
  'dbm.documentation.example.LEVEL_2_SEARCH_ROLE_CODE': '-',
  'dbm.documentation.example.LEVEL_2_SEARCH_STATUS_CODE': '-',
  'dbm.documentation.example.LEVEL_2_START_DATE': '2021-01-01',
  'dbm.documentation.example.LEVEL_3_DIRECT_PERCENTAGE': '-',
  'dbm.documentation.example.LEVEL_3_ENTERPRISE_ID': '-',
  'dbm.documentation.example.LEVEL_3_INDIRECT_PERCENTAGE_SUBJECT': '-',
  'dbm.documentation.example.LEVEL_3_MANDATE_AUTHORITY_CODE': '-',
  'dbm.documentation.example.LEVEL_3_NL_KVK_NUMBER': '81234560',
  'dbm.documentation.example.LEVEL_3_ORG_ADDRESS_LINE_1': '-',
  'dbm.documentation.example.LEVEL_3_ORG_ADDRESS_LINE_2': '-',
  'dbm.documentation.example.LEVEL_3_ORG_ADDRESS_LINE_3': '-',
  'dbm.documentation.example.LEVEL_3_ORG_ADDRESS_LINE_4': '-',
  'dbm.documentation.example.LEVEL_3_ORG_ADDRESS_LINE_5': '-',
  'dbm.documentation.example.LEVEL_3_ORG_NAME': '-',
  'dbm.documentation.example.LEVEL_3_POSITION_TITLE_CODE': '-',
  'dbm.documentation.example.LEVEL_3_POSITION_TYPE_CODE': '-',
  'dbm.documentation.example.LEVEL_3_ROLE_TYPE_CODE': '-',
  'dbm.documentation.example.LEVEL_3_SEARCH_ROLE_CODE': '-',
  'dbm.documentation.example.LEVEL_3_SEARCH_STATUS_CODE': '-',
  'dbm.documentation.example.LEVEL_3_START_DATE': '2021-01-01',
  'dbm.documentation.example.LEVEL_4_DIRECT_PERCENTAGE': 'Pourcentage direct',
  'dbm.documentation.example.LEVEL_4_ENTERPRISE_ID': '-',
  'dbm.documentation.example.LEVEL_4_INDIRECT_PERCENTAGE_SUBJECT': '-',
  'dbm.documentation.example.LEVEL_4_MANDATE_AUTHORITY_CODE': '-',
  'dbm.documentation.example.LEVEL_4_NL_KVK_NUMBER': '81234560',
  'dbm.documentation.example.LEVEL_4_ORG_ADDRESS_LINE_1': '-',
  'dbm.documentation.example.LEVEL_4_ORG_ADDRESS_LINE_2': '-',
  'dbm.documentation.example.LEVEL_4_ORG_ADDRESS_LINE_3': '-',
  'dbm.documentation.example.LEVEL_4_ORG_ADDRESS_LINE_4': '-',
  'dbm.documentation.example.LEVEL_4_ORG_ADDRESS_LINE_5': '-',
  'dbm.documentation.example.LEVEL_4_ORG_NAME': '-',
  'dbm.documentation.example.LEVEL_4_POSITION_TITLE_CODE': '-',
  'dbm.documentation.example.LEVEL_4_POSITION_TYPE_CODE': '-',
  'dbm.documentation.example.LEVEL_4_ROLE_TYPE_CODE': '-',
  'dbm.documentation.example.LEVEL_4_SEARCH_ROLE_CODE': '-',
  'dbm.documentation.example.LEVEL_4_SEARCH_STATUS_CODE': '-',
  'dbm.documentation.example.LEVEL_4_START_DATE': '2021-01-01',
  'dbm.documentation.example.LEVEL_5_DIRECT_PERCENTAGE': '-',
  'dbm.documentation.example.LEVEL_5_ENTERPRISE_ID': '-',
  'dbm.documentation.example.LEVEL_5_INDIRECT_PERCENTAGE_SUBJECT': '-',
  'dbm.documentation.example.LEVEL_5_MANDATE_AUTHORITY_CODE': '-',
  'dbm.documentation.example.LEVEL_5_NL_KVK_NUMBER': '81234560',
  'dbm.documentation.example.LEVEL_5_ORG_ADDRESS_LINE_1': '-',
  'dbm.documentation.example.LEVEL_5_ORG_ADDRESS_LINE_2': '-',
  'dbm.documentation.example.LEVEL_5_ORG_ADDRESS_LINE_3': '-',
  'dbm.documentation.example.LEVEL_5_ORG_ADDRESS_LINE_4': '-',
  'dbm.documentation.example.LEVEL_5_ORG_ADDRESS_LINE_5': '-',
  'dbm.documentation.example.LEVEL_5_ORG_NAME': '-',
  'dbm.documentation.example.LEVEL_5_POSITION_TITLE_CODE': '-',
  'dbm.documentation.example.LEVEL_5_POSITION_TYPE_CODE': '-',
  'dbm.documentation.example.LEVEL_5_ROLE_TYPE_CODE': '-',
  'dbm.documentation.example.LEVEL_5_SEARCH_ROLE_CODE': '-',
  'dbm.documentation.example.LEVEL_5_SEARCH_STATUS_CODE': '-',
  'dbm.documentation.example.LEVEL_5_START_DATE': '2021-01-01',
  'dbm.documentation.example.LEVEL_6_DIRECT_PERCENTAGE': '-',
  'dbm.documentation.example.LEVEL_6_ENTERPRISE_ID': '-',
  'dbm.documentation.example.LEVEL_6_INDIRECT_PERCENTAGE_SUBJECT': '-',
  'dbm.documentation.example.LEVEL_6_MANDATE_AUTHORITY_CODE': '-',
  'dbm.documentation.example.LEVEL_6_NL_KVK_NUMBER': '81234560',
  'dbm.documentation.example.LEVEL_6_ORG_ADDRESS_LINE_1': '-',
  'dbm.documentation.example.LEVEL_6_ORG_ADDRESS_LINE_2': '-',
  'dbm.documentation.example.LEVEL_6_ORG_ADDRESS_LINE_3': '-',
  'dbm.documentation.example.LEVEL_6_ORG_ADDRESS_LINE_4': '-',
  'dbm.documentation.example.LEVEL_6_ORG_ADDRESS_LINE_5': '-',
  'dbm.documentation.example.LEVEL_6_ORG_NAME': '-',
  'dbm.documentation.example.LEVEL_6_POSITION_TITLE_CODE': '-',
  'dbm.documentation.example.LEVEL_6_POSITION_TYPE_CODE': '-',
  'dbm.documentation.example.LEVEL_6_ROLE_TYPE_CODE': '-',
  'dbm.documentation.example.LEVEL_6_SEARCH_ROLE_CODE': '-',
  'dbm.documentation.example.LEVEL_6_SEARCH_STATUS_CODE': '-',
  'dbm.documentation.example.LEVEL_6_START_DATE': '2021-01-01',
  'dbm.documentation.example.LEVEL_7_DIRECT_PERCENTAGE': '-',
  'dbm.documentation.example.LEVEL_7_ENTERPRISE_ID': '-',
  'dbm.documentation.example.LEVEL_7_INDIRECT_PERCENTAGE_SUBJECT': '-',
  'dbm.documentation.example.LEVEL_7_MANDATE_AUTHORITY_CODE': '-',
  'dbm.documentation.example.LEVEL_7_NL_KVK_NUMBER': '81234560',
  'dbm.documentation.example.LEVEL_7_ORG_ADDRESS_LINE_1': '-',
  'dbm.documentation.example.LEVEL_7_ORG_ADDRESS_LINE_2': '-',
  'dbm.documentation.example.LEVEL_7_ORG_ADDRESS_LINE_3': '-',
  'dbm.documentation.example.LEVEL_7_ORG_ADDRESS_LINE_4': '-',
  'dbm.documentation.example.LEVEL_7_ORG_ADDRESS_LINE_5': '-',
  'dbm.documentation.example.LEVEL_7_ORG_NAME': '-',
  'dbm.documentation.example.LEVEL_7_POSITION_TITLE_CODE': '-',
  'dbm.documentation.example.LEVEL_7_POSITION_TYPE_CODE': '-',
  'dbm.documentation.example.LEVEL_7_ROLE_TYPE_CODE': '-',
  'dbm.documentation.example.LEVEL_7_SEARCH_ROLE_CODE': '-',
  'dbm.documentation.example.LEVEL_7_SEARCH_STATUS_CODE': '-',
  'dbm.documentation.example.LEVEL_7_START_DATE': '2021-01-01',
  'dbm.documentation.example.LEVEL_8_DIRECT_PERCENTAGE': '-',
  'dbm.documentation.example.LEVEL_8_ENTERPRISE_ID': '-',
  'dbm.documentation.example.LEVEL_8_INDIRECT_PERCENTAGE_SUBJECT': '-',
  'dbm.documentation.example.LEVEL_8_MANDATE_AUTHORITY_CODE': '-',
  'dbm.documentation.example.LEVEL_8_NL_KVK_NUMBER': '81234560',
  'dbm.documentation.example.LEVEL_8_ORG_ADDRESS_LINE_1': '-',
  'dbm.documentation.example.LEVEL_8_ORG_ADDRESS_LINE_2': '-',
  'dbm.documentation.example.LEVEL_8_ORG_ADDRESS_LINE_3': '-',
  'dbm.documentation.example.LEVEL_8_ORG_ADDRESS_LINE_4': '-',
  'dbm.documentation.example.LEVEL_8_ORG_ADDRESS_LINE_5': '-',
  'dbm.documentation.example.LEVEL_8_ORG_NAME': '-',
  'dbm.documentation.example.LEVEL_8_POSITION_TITLE_CODE': '-',
  'dbm.documentation.example.LEVEL_8_POSITION_TYPE_CODE': '-',
  'dbm.documentation.example.LEVEL_8_ROLE_TYPE_CODE': '-',
  'dbm.documentation.example.LEVEL_8_SEARCH_ROLE_CODE': '-',
  'dbm.documentation.example.LEVEL_8_SEARCH_STATUS_CODE': '-',
  'dbm.documentation.example.LEVEL_8_START_DATE': '2021-01-01',
  'dbm.documentation.example.LEVEL_9_DIRECT_PERCENTAGE': '-',
  'dbm.documentation.example.LEVEL_9_ENTERPRISE_ID': '-',
  'dbm.documentation.example.LEVEL_9_INDIRECT_PERCENTAGE_SUBJECT': '-',
  'dbm.documentation.example.LEVEL_9_MANDATE_AUTHORITY_CODE': '-',
  'dbm.documentation.example.LEVEL_9_NL_KVK_NUMBER': '81234560',
  'dbm.documentation.example.LEVEL_9_ORG_ADDRESS_LINE_1': '-',
  'dbm.documentation.example.LEVEL_9_ORG_ADDRESS_LINE_2': '-',
  'dbm.documentation.example.LEVEL_9_ORG_ADDRESS_LINE_3': '-',
  'dbm.documentation.example.LEVEL_9_ORG_ADDRESS_LINE_4': '-',
  'dbm.documentation.example.LEVEL_9_ORG_ADDRESS_LINE_5': '-',
  'dbm.documentation.example.LEVEL_9_ORG_NAME': '-',
  'dbm.documentation.example.LEVEL_9_POSITION_TITLE_CODE': '-',
  'dbm.documentation.example.LEVEL_9_POSITION_TYPE_CODE': '-',
  'dbm.documentation.example.LEVEL_9_ROLE_TYPE_CODE': '-',
  'dbm.documentation.example.LEVEL_9_SEARCH_ROLE_CODE': '-',
  'dbm.documentation.example.LEVEL_9_SEARCH_STATUS_CODE': '-',
  'dbm.documentation.example.LEVEL_9_START_DATE': '2021-01-01',
  'dbm.documentation.example.LIST_MGT_ORG_ID': '-',
  'dbm.documentation.example.LONGITUDE': '5.37337',
  'dbm.documentation.example.MEETING_DATE': '-',
  'dbm.documentation.example.MOBILE': '-',
  'dbm.documentation.example.NACE_ACTIVITY_CODE': '1511',
  'dbm.documentation.example.NAME': 'GRAYDON',
  'dbm.documentation.example.NET_RESULT': '15000',
  'dbm.documentation.example.NL_DESCRIPTION': 'Ja (for code Y)',
  'dbm.documentation.example.NL_KVK_BRANCH_NUMBER': '987123456789',
  'dbm.documentation.example.NL_KVK_NUMBER': '8123456',
  'dbm.documentation.example.NL_KVK_NUMBER_12': '81234560000',
  'dbm.documentation.example.NL_LEGAL_FORM_CODE': '4',
  'dbm.documentation.example.NL_RSIN': '009031315',
  'dbm.documentation.example.NOMINAL_VALUE': '-',
  'dbm.documentation.example.NOTICE_EVENT_DATE': '-',
  'dbm.documentation.example.NUM_BRANCH_OFFICES': '2',
  'dbm.documentation.example.NUM_CCJ_LAST_12_MONTHS': '-',
  'dbm.documentation.example.NUM_CCJ_LAST_24_MONTHS': '-',
  'dbm.documentation.example.NUM_CCJ_LAST_36_MONTHS': '-',
  'dbm.documentation.example.NUM_CCJ_LAST_48_MONTHS': '-',
  'dbm.documentation.example.NUM_CCJ_LAST_60_MONTHS': '-',
  'dbm.documentation.example.NUM_CCJ_LAST_72_MONTHS': '-',
  'dbm.documentation.example.NUM_EMPLOYEES_SIZE_CLASS_CODE': '-',
  'dbm.documentation.example.NUM_OF_SUPPLIERS': '8',
  'dbm.documentation.example.NUM_PAYMENT_EXPERIENCES': '47',
  'dbm.documentation.example.NUM_SHARES': '-',
  'dbm.documentation.example.ORGANIZATION_ID': '18268053',
  'dbm.documentation.example.ORGANIZATION_NAME': 'Bouwbedrijf Bos',
  'dbm.documentation.example.ORGANIZATION_NAME_TYPE_CODE': 'PRINCIPAL_NAME, SHORT_NAME, etc',
  'dbm.documentation.example.ORGANIZATION_TYPE_CODE': 'ROOT_ORG',
  'dbm.documentation.example.ORIGIN_CODE': '-',
  'dbm.documentation.example.OTHER_ADDR_LINE_1': '-',
  'dbm.documentation.example.OTHER_ADDR_LINE_2': '-',
  'dbm.documentation.example.OTHER_ADDR_LINE_3': '-',
  'dbm.documentation.example.OTHER_ADDR_LINE_4': '-',
  'dbm.documentation.example.OTHER_ADDR_LINE_5': '-',
  'dbm.documentation.example.OTHER_BE_ENTERPRISE_NUMBER': '406952018',
  'dbm.documentation.example.OTHER_ENTERPRISE_ID': '1234567',
  'dbm.documentation.example.OTHER_GB_CRO_REGISTRATION_NUMBER': '-',
  'dbm.documentation.example.OTHER_NAME': '-',
  'dbm.documentation.example.OTHER_NL_KVK_NUMBER': '33189409',
  'dbm.documentation.example.OTHER_ORG_ADDRESS_LINE_1': 'Berg Arrarat 1',
  'dbm.documentation.example.OTHER_ORG_ADDRESS_LINE_2': '-',
  'dbm.documentation.example.OTHER_ORG_ADDRESS_LINE_3': '-',
  'dbm.documentation.example.OTHER_ORG_ADDRESS_LINE_4': 'Willemstad',
  'dbm.documentation.example.OTHER_ORG_ADDRESS_LINE_5': 'Curaçao',
  'dbm.documentation.example.OTHER_ORG_NAME': 'HRO Curacao N.V.',
  'dbm.documentation.example.PAID_UP_CAPITAL': '50000',
  'dbm.documentation.example.PAID_UP_CONTRIBUTION': '125000',
  'dbm.documentation.example.PAID_UP_FIXED_CAPITAL': '125000',
  'dbm.documentation.example.PAID_UP_VARIABLE_CAPITAL': '125000',
  'dbm.documentation.example.PART_ADDR_LINE_1': 'Lopez de Hoyos 15',
  'dbm.documentation.example.PART_ADDR_LINE_2': '3 dcha',
  'dbm.documentation.example.PART_ADDR_LINE_3': '28006',
  'dbm.documentation.example.PART_ADDR_LINE_4': 'Madrid',
  'dbm.documentation.example.PART_ADDR_LINE_5': 'Spain',
  'dbm.documentation.example.PART_BE_ENTERPRISE_NUMBER': '8123456',
  'dbm.documentation.example.PART_ENTERPRISE_ID': '1234567',
  'dbm.documentation.example.PART_NAME': 'Graydon Nederland B.V.',
  'dbm.documentation.example.PART_NL_KVK_NUMBER': '33080348',
  'dbm.documentation.example.PARTNERSHIP_CAPITAL': '1000',
  'dbm.documentation.example.PAYMENT_SCORE': '6.8',
  'dbm.documentation.example.PCT_OVER_90_DBT': '10.2',
  'dbm.documentation.example.PCT_TO_30_DBT': '36.9',
  'dbm.documentation.example.PCT_TO_60_DBT': '38.6',
  'dbm.documentation.example.PCT_TO_90_DBT': '2.4',
  'dbm.documentation.example.PCT_WITHIN_TERMS': '12.1',
  'dbm.documentation.example.PD_PERCENTAGE': '0.22',
  'dbm.documentation.example.PERSON_BIRTH_CITY': 'Amsterdam',
  'dbm.documentation.example.PERSON_BIRTH_COUNTRY_CODE': 'BE',
  'dbm.documentation.example.PERSON_BIRTH_DATE': '1980-01-01',
  'dbm.documentation.example.PERSON_BIRTH_NDATE': '2018-01-01',
  'dbm.documentation.example.PERSON_CITY': 'ANTWERPEN',
  'dbm.documentation.example.PERSON_COUNTRY_CODE': 'BE',
  'dbm.documentation.example.PERSON_DEATH_DATE': 'Type de date générale',
  'dbm.documentation.example.PERSON_DEATH_NDATE': '20180101',
  'dbm.documentation.example.PERSON_DETAILS_PROTECTED': '-',
  'dbm.documentation.example.PERSON_FIRST_NAMES': 'Fred Johannes',
  'dbm.documentation.example.PERSON_FULL_NAME': 'Fred van Amerongen',
  'dbm.documentation.example.PERSON_GENDER_CODE': 'F',
  'dbm.documentation.example.PERSON_HOUSE_NUMBER': '84',
  'dbm.documentation.example.PERSON_HOUSE_NUMBER_SUFFIX': '-A',
  'dbm.documentation.example.PERSON_ID': '1000008993',
  'dbm.documentation.example.PERSON_INITIALS': 'AAJ',
  'dbm.documentation.example.PERSON_POSTCODE': '2600',
  'dbm.documentation.example.PERSON_STREET': 'UITBREIDINGSTRAAT',
  'dbm.documentation.example.PERSON_STREET_CODE': '3180',
  'dbm.documentation.example.PERSON_SURNAME': 'Amerongen',
  'dbm.documentation.example.PERSON_SURNAME_PREFIX': 'van',
  'dbm.documentation.example.PERSON_TITLES': '-',
  'dbm.documentation.example.PO_BOX_CITY': 'Amsterdam',
  'dbm.documentation.example.PO_BOX_END_DATE': '2021-01-01',
  'dbm.documentation.example.PO_BOX_NUMBER': '461',
  'dbm.documentation.example.PO_BOX_POSTCODE': '1101BV',
  'dbm.documentation.example.PO_BOX_START_DATE': '2021-01-01',
  'dbm.documentation.example.POSITION_END_DATE': '2021-01-01',
  'dbm.documentation.example.POSITION_HOLDER_ADDR_LINE_1': 'Plaza real 11',
  'dbm.documentation.example.POSITION_HOLDER_ADDR_LINE_2': '28000',
  'dbm.documentation.example.POSITION_HOLDER_ADDR_LINE_3': 'Madrid',
  'dbm.documentation.example.POSITION_HOLDER_ADDR_LINE_4': 'Spain',
  'dbm.documentation.example.POSITION_HOLDER_ADDR_LINE_5': '-',
  'dbm.documentation.example.POSITION_HOLDER_BE_ENTERPRISE_NUMBER': '730124',
  'dbm.documentation.example.POSITION_HOLDER_ENTERPRISE_ID': '1234567',
  'dbm.documentation.example.POSITION_HOLDER_NAME': 'Graydon Nederland B.V.',
  'dbm.documentation.example.POSITION_HOLDER_NL_KVK_NUMBER': '81234560',
  'dbm.documentation.example.POSITION_MANDATE_AUTHORITY_CODE': '2',
  'dbm.documentation.example.POSITION_MANDATE_TEXT': '-',
  'dbm.documentation.example.POSITION_START_DATE': '2021-01-01',
  'dbm.documentation.example.POSITION_TITLE': '-',
  'dbm.documentation.example.POSITION_TITLE_CODE': '-',
  'dbm.documentation.example.POSITION_TYPE_CODE': 'NL_4',
  'dbm.documentation.example.POSTCODE': '1101BV, 3000, EN3 5BT',
  'dbm.documentation.example.PREFERRED_LANGUAGE_CODE': 'FR, NL, DE',
  'dbm.documentation.example.PRELIMINARY_FLAG': 'N',
  'dbm.documentation.example.PRIMARY_ACTIVITY_CODE_1': '4722',
  'dbm.documentation.example.QUARTER': '2',
  'dbm.documentation.example.QUICK_RATIO': '1',
  'dbm.documentation.example.REASON_CODE': '-',
  'dbm.documentation.example.RECEIVER_1': 'AAJ van Amerongen',
  'dbm.documentation.example.RECEIVER_1_END_DATE': '2021-01-01',
  'dbm.documentation.example.RECEIVER_1_TYPE_CODE': 'NL_1',
  'dbm.documentation.example.RECEIVER_10': '-',
  'dbm.documentation.example.RECEIVER_2': 'AAJ van Amerongen',
  'dbm.documentation.example.RECEIVER_2_END_DATE': '2021-01-01',
  'dbm.documentation.example.RECEIVER_2_TYPE_CODE': 'NL_1',
  'dbm.documentation.example.RECEIVER_3': '-',
  'dbm.documentation.example.RECEIVER_3_END_DATE': '2021-01-01',
  'dbm.documentation.example.RECEIVER_3_TYPE_CODE': 'NL_1',
  'dbm.documentation.example.RECEIVER_4': '-',
  'dbm.documentation.example.RECEIVER_4_TYPE_CODE': 'NL_1',
  'dbm.documentation.example.RECEIVER_5': '-',
  'dbm.documentation.example.RECEIVER_5_TYPE_CODE': 'NL_1',
  'dbm.documentation.example.RECEIVER_6': '-',
  'dbm.documentation.example.RECEIVER_7': '-',
  'dbm.documentation.example.RECEIVER_8': '-',
  'dbm.documentation.example.RECEIVER_9': '-',
  'dbm.documentation.example.RECIEVER': '-',
  'dbm.documentation.example.RECIEVER_TYPE_CODE': 'NL_1',
  'dbm.documentation.example.REFERENCE': 'XYZ',
  'dbm.documentation.example.REGION': 'BE_5_1, BE_5_2, BE_5_3',
  'dbm.documentation.example.RELATION_TYPE_CODE': '1',
  'dbm.documentation.example.REPRESENTATIVE_PERSON_BIRTH_CITY': 'Amsterdam',
  'dbm.documentation.example.REPRESENTATIVE_PERSON_BIRTH_COUNTRY_CODE': 'BE',
  'dbm.documentation.example.REPRESENTATIVE_PERSON_BIRTH_DATE': 'Type de date générale',
  'dbm.documentation.example.REPRESENTATIVE_PERSON_BIRTH_NDATE': '20180101',
  'dbm.documentation.example.REPRESENTATIVE_PERSON_CITY': 'ANTWERPEN',
  'dbm.documentation.example.REPRESENTATIVE_PERSON_COUNTRY_CODE': 'BE',
  'dbm.documentation.example.REPRESENTATIVE_PERSON_DEATH_DATE': 'Type de date générale',
  'dbm.documentation.example.REPRESENTATIVE_PERSON_DEATH_NDATE': '2021-01-01',
  'dbm.documentation.example.REPRESENTATIVE_PERSON_FIRST_NAMES': 'Fred Johannes',
  'dbm.documentation.example.REPRESENTATIVE_PERSON_FULL_NAME': 'van Amerongen, Fred Johannes',
  'dbm.documentation.example.REPRESENTATIVE_PERSON_GENDER_CODE': 'F',
  'dbm.documentation.example.REPRESENTATIVE_PERSON_HOUSE_NUMBER': '84',
  'dbm.documentation.example.REPRESENTATIVE_PERSON_HOUSE_NUMBER_SUFFIX': '-A',
  'dbm.documentation.example.REPRESENTATIVE_PERSON_ID': '1000008993',
  'dbm.documentation.example.REPRESENTATIVE_PERSON_INITIALS': 'AAJ',
  'dbm.documentation.example.REPRESENTATIVE_PERSON_POSTCODE': '2600',
  'dbm.documentation.example.REPRESENTATIVE_PERSON_STREET': 'UITBREIDINGSTRAAT',
  'dbm.documentation.example.REPRESENTATIVE_PERSON_STREET_CODE': '3180',
  'dbm.documentation.example.REPRESENTATIVE_PERSON_SURNAME': 'Amerongen',
  'dbm.documentation.example.REPRESENTATIVE_PERSON_SURNAME_PREFIX': 'van',
  'dbm.documentation.example.REPRESENTATIVE_PERSON_TITLES': 'Dr',
  'dbm.documentation.example.RESULT_SEARCH_STATUS_CODE': '-',
  'dbm.documentation.example.ROW_TYPE': 'D (=Données), R (=Supprimer)',
  'dbm.documentation.example.RSZ_NUMBER': '88995656',
  'dbm.documentation.example.SBI_ACTIVITY_CODE': '151101',
  'dbm.documentation.example.SEARCH_ROLE_CODE': '-',
  'dbm.documentation.example.SECONDARY_ACTIVITY_CODE_1': '8513',
  'dbm.documentation.example.SECONDARY_ACTIVITY_CODE_2': '8514',
  'dbm.documentation.example.SECONDARY_ACTIVITY_CODE_3': '8515',
  'dbm.documentation.example.SECTOR_CODE': 'A',
  'dbm.documentation.example.SEQUENCE_NUMBER': '-',
  'dbm.documentation.example.SHARE_PERCENTAGE': '100',
  'dbm.documentation.example.SHARE_TYPE_CODE': 'BE_30',
  'dbm.documentation.example.SHAREHOLDER_ADDR_LINE_1': 'Lopez de Hoyos 15',
  'dbm.documentation.example.SHAREHOLDER_ADDR_LINE_2': '3 dcha',
  'dbm.documentation.example.SHAREHOLDER_ADDR_LINE_3': '28006 Madrid',
  'dbm.documentation.example.SHAREHOLDER_ADDR_LINE_4': 'Spain',
  'dbm.documentation.example.SHAREHOLDER_ADDR_LINE_5': '-',
  'dbm.documentation.example.SHAREHOLDER_FUNDS': '123456',
  'dbm.documentation.example.SHAREHOLDER_NAME': 'Graydon Holding N.V.',
  'dbm.documentation.example.SHL_BE_ENTERPRISE_NUMBER': '8123456',
  'dbm.documentation.example.SHL_ENTERPRISE_ID': '302459',
  'dbm.documentation.example.SHL_NL_KVK_NUMBER': '33189409',
  'dbm.documentation.example.SHL_PERSON_ID': '1000008993',
  'dbm.documentation.example.SIZE_CLASS_BE_RSZ_NUM_EMPLOYEES': '2',
  'dbm.documentation.example.SIZE_CLASS_EMPLOYEE_COUNT_FULL_TIME': '3',
  'dbm.documentation.example.SIZE_CLASS_EMPLOYEE_COUNT_TOTAL': '2',
  'dbm.documentation.example.SIZE_CLASS_GROUP_EMPLOYEE_COUNT_FULL_TIME': '11',
  'dbm.documentation.example.SIZE_CLASS_GROUP_EMPLOYEE_COUNT_TOTAL': '12',
  'dbm.documentation.example.SIZE_CLASS_NUM_EMPLOYEES': '1',
  'dbm.documentation.example.SOURCE': 'BE_01',
  'dbm.documentation.example.SOURCE_REF': '20090123/12271',
  'dbm.documentation.example.START_DATE': '2021-01-01',
  'dbm.documentation.example.STREET': 'Hullenbergweg',
  'dbm.documentation.example.STREET_CODE': '3180',
  'dbm.documentation.example.SUM_TOTAL_PERCENTAGE_SUBJECT_ALL': '-',
  'dbm.documentation.example.SUMMONS': '-',
  'dbm.documentation.example.TEL_AREA_CODE': '020',
  'dbm.documentation.example.TEL_COUNTRY_CODE': '0031',
  'dbm.documentation.example.TEL_SUBSCRIBER_NUMBER': '5679999',
  'dbm.documentation.example.TELEPHONE': '-',
  'dbm.documentation.example.TELEPHONE_RECEIVER_1': '010-4400502',
  'dbm.documentation.example.TELEPHONE_RECEIVER_2': '010-4400500',
  'dbm.documentation.example.TELEPHONE_RECEIVER_3': '010-4400500',
  'dbm.documentation.example.TITLES': 'Dr.',
  'dbm.documentation.example.TITLES_RECEIVER_1': 'DRS.',
  'dbm.documentation.example.TITLES_RECEIVER_2': 'DRS.',
  'dbm.documentation.example.TITLES_RECEIVER_3': 'DRS.',
  'dbm.documentation.example.TOTAL_EQUITY': '1',
  'dbm.documentation.example.TOTAL_PERCENTAGE_SUBJECT': 'Pourcentage total',
  'dbm.documentation.example.TRADE_NAME_1': 'aero',
  'dbm.documentation.example.TRADE_NAME_2': 'aero plus',
  'dbm.documentation.example.TRADE_NAME_3': 'aero float',
  'dbm.documentation.example.TURNOVER': '1000000000',
  'dbm.documentation.example.TYPE_CODE': '1',
  'dbm.documentation.example.UBO_SOURCE': '-',
  'dbm.documentation.example.UBO_SOURCE_KVK_DATE': '-',
  'dbm.documentation.example.UNAVAILABLE_CONTRIBUTION': '125000',
  'dbm.documentation.example.UPDATED_ON': '2021-01-01',
  'dbm.documentation.example.URL': '-',
  'dbm.documentation.example.USE_CLASS_CODE_1': '6',
  'dbm.documentation.example.USE_CLASS_CODE_2': '10',
  'dbm.documentation.example.USE_CLASS_CODE_3': '11',
  'dbm.documentation.example.USE_CLASS_CODE_4': '6',
  'dbm.documentation.example.USE_CLASS_CODE_5': '9',
  'dbm.documentation.example.VARIABLE_CAPITAL': '125000',
  'dbm.documentation.example.VAT_LIABILITY': 'Y, N, null',
  'dbm.documentation.example.VAT_NUMBER': 'NL123456789B01',
  'dbm.documentation.example.VOTING_DATE': '2021-01-01',
  'dbm.documentation.example.WORKING_CAPITAL': '25000',
  'dbm.documentation.example.XSEPTION_CATEGORY_CODE': 'A',
  'dbm.documentation.example.XSEPTION_NDATE_1': '2018',
  'dbm.documentation.example.XSEPTION_NDATE_2': '2019',
  'dbm.documentation.example.XSEPTION_TEXT': '-',
  'dbm.documentation.example.XSEPTION_TYPE_CODE': 'NL_84',
  'dbm.documentation.example.XSEPTION_VALUE_1': '-',
  'dbm.documentation.example.XSEPTION_VALUE_2': '-',
  'dbm.documentation.example.YEAR': '2018',

  'dbm.documentation.example.NL_KVK_NUMBER_CONTINUATION': '-',
  'dbm.documentation.example.NL_LEGAL_FORM_DESCRIPTION': '-',
  'dbm.documentation.example.COUNTRY': '-',
  'dbm.documentation.example.FOUND_AT_NL_KVK_NUMBER': '-',
  'dbm.documentation.example.FOUND_AT_NL_LEGAL_FORM_DESCRIPTION': '-',
  'dbm.documentation.example.PERSON_BIRTH_COUNTRY': '-',
  'dbm.documentation.example.RESULT_SEARCH_STATUS_DESCRIPTION': '-',
  'dbm.documentation.example.INTEREST_TYPE_CODE': '-',
  'dbm.documentation.example.INTEREST_SIZE_CODE': '-',
  'dbm.documentation.example.REGISTRATION_DATE': '-',
  'dbm.documentation.expand-all': 'Développer le menu',
  'dbm.documentation.field.description': 'description',
  'dbm.documentation.field.example': 'exemple',
  'dbm.documentation.field.length': 'longeur',
  'dbm.documentation.field.level': 'niveau',
  'dbm.documentation.field.name': 'nom',
  'dbm.documentation.field.nullable': 'invalide',
  'dbm.documentation.field.ROW_TYPE': 'ROW_TYPE',
  'dbm.documentation.field.type': 'type',
  'dbm.documentation.footer': '-',
  'dbm.documentation.go-back': 'Retourner',
  'dbm.documentation.print': 'Imprimer la documentation',
  'dbm.documentation.show-documentation': 'Afficher la documentation',
  'dbm.documentation.title': 'Documentation des blocs de données',
  'dbm.enrichedScheduleSet.paragraph': 'Le premier téléchargement sera envoyé sous peu à {email}',
  'dbm.enrichedScheduleSet.paragraph.later':
    'Le premier téléchargement sera envoyé à {email} le {date}',
  'dbm.executions.download-file': 'Télécharger un fichier',
  'dbm.executions.downloading-file': 'Télécharger',
  'dbm.executions.endDate': 'Date de fin',
  'dbm.executions.error': 'Erreur lors de la prise en charge des livraisons',
  'dbm.executions.error.retry': 'Réessayer',
  'dbm.executions.file-size': 'Taille du fichier',
  'dbm.executions.report': 'Fichier',
  'dbm.executions.sequentialNumber': 'Numéro de série',
  'dbm.executions.startDate': 'Date de début',
  'dbm.executions.status': 'Statut',
  'dbm.executions.status.done': 'Terminé',
  'dbm.executions.status.failed': 'Manqué',
  'dbm.executions.status.scheduled': 'Plannifié',
  'dbm.executions.status.updating_data': 'Mettre à jour les données',
  'dbm.executions.status.updating_list': 'Mettre à jour la liste',
  'dbm.executions.title': 'Livraisons précédentes',
  'dbm.executions.type': 'Type',
  'dbm.executions.type.delta': 'Modifications',
  'dbm.executions.type.full': 'Ficher complet de données',
  'dbm.homepage.disabled': 'Fonction désactivée, aucune livraison n’est prévue',
  'dbm.homepage.noListsAvailable': "Vous n'avez pas encore de liste d'entreprises.",
  'dbm.homepage.scheduleButton': 'Commençons',
  'dbm.homepage.scheduled.empty.heading': 'Vous ne recevez pas encore de mises à jour continues !',
  'dbm.homepage.scheduled.empty.text': "Cela signifie que votre base de données n'est pas à jour",
  'dbm.homepage.scheduled.heading': 'Des enrichissements permanents de votre base de données',
  'dbm.homepage.schedules.addAnother': 'Ajoutez une autre liste à vos mises à jour continues',
  'dbm.homepage.schedules.interval': 'Tous les {num, plural, =1 {day} other {{num} days}}',
  'dbm.homepage.single.file': 'Enrichissez votre fichier',
  'dbm.homepage.single.heading': 'Enrichissement unique',
  'dbm.homepage.single.list': 'Enrichissez et téléchargez une liste',
  'dbm.homepage.single.text':
    'Vous avez besoin des données maintenant ? Enrichissez votre fichier ou votre liste une seule fois.',
  'dbm.homepage.startEnrichmentButton': "Débuter l'enrichissement",
  'dbm.homepage.title': 'Enrichissement données',
  'dbm.important.stuff': 'génial',
  'dbm.importCompaniesLink': 'Importer des entreprises',
  'dbm.importCompaniesLink.right': '(Quitter la Gestion de base de données)',
  'dbm.profile-overview.add-new-profile': 'Ajouter un nouveau profil',
  'dbm.profile-overview.no-creation-access':
    "Vous n'êtes pas autorisé à créer un profil. Veuillez contacter le gestionnaire de la base de données et demandez à créer un profil.",
  'dbm.profile-overview.no-profile':
    "Vous n'avez pas encore créé de profil de gestion de base de données.",
  'dbm.profile-overview.no-profile.restricted':
    "Vous n'avez pas créé de profil de gestion de base de données.",
  'dbm.profile-overview.note': 'Veuillez noter',
  'dbm.profile-overview.title': 'Aperçu des profils',
  'dbm.profileOverview.prevDeliveries.hide': 'Cacher livraisons précédentes',
  'dbm.profileOverview.prevDeliveries.view': 'Voir livraisons précédentes',
  'dbm.scheduleEnrichmentForm.heading': 'Gardez votre base de données à jour',
  'dbm.scheduleEnrichmentForm.schedule.daysStarting': 'jour, à partir de (du)',
  'dbm.scheduleEnrichmentForm.schedule.every': 'Chaque',
  'dbm.scheduleEnrichmentForm.schedule.heading':
    'Vous allez recevoir un e-mail avec de nouvelles données',
  'dbm.scheduleEnrichmentForm.schedule.info': 'Vous allez recevoir les mises à jour sur {email}',
  'dbm.scheduleEnrichmentForm.selectData.explanation':
    'Plus vous sélectionnez de données, plus votre base de données prend de la valeur.',
  'dbm.scheduleEnrichmentForm.selectData.heading':
    'Quelles données doit-on ajouter à ces entreprises ?',
  'dbm.scheduleEnrichmentForm.selectList.heading':
    'Quelles entreprises sont dans votre base de données ?',
  'dbm.scheduleEnrichmentForm.start': 'Démarrer',
  'dbm.scheduleList.deleteSchedule': "Supprimer l'enrichissement continu",
  'dbm.scheduleList.deleteSchedule.message':
    'Êtes-vous sûr de vouloir supprimer l\'enrichissement continu de "{tagName}"?',
  'dbm.scheduleList.deleteSchedule.message2': "Vous pouvez toujours l'ajouter plus tard.",
  'dbm.update-profile.dbm-disabled-for-this-country':
    'Nota Bene / Remarque / Veuillez noter : La gestion de la base de données est désactivée pour ce pays. Les fichiers de données ne seront **pas** fournis.',
  'dbm.update-profile.error.message': "Une erreur s'est produite lors de la modification du profil",
  'dbm.update-profile.error.title': 'Erreur lors de la modification du profil',
  'dbm.update-profile.success.message': 'Le profil a été mis à jour avec succès',
  'dbm.update-profile.success.message.new-blocks-added':
    '** Nota Bene / Remarque / Veuillez noter : ** Vous avez ajouté un nouveau bloc de données. La prochaine livraison contiendra toutes les données du bloc de données nouvellement ajouté',
  'dbm.update-profile.success.title': 'Mise à jour du profil réussie',
  'dbm.update-profile.title': 'Modifier le profil',
  'dbm.view-profile.profile-id': "ID du profil (pour l'utilisation de l'API)",
  'dbm.view-profile.profile-id.tooltip':
    'Utilisez cette ID pour rechercher les données du bon profil de gestion de base de données.',
  'dbm.view-profile.send-single-delivery': 'Préparer la livraison unique maintenant',
  'dbm.view-profile.send-single-delivery.desc': 'sur la base de ce profil',
  'dbm.view-profile.single-delivery': 'Livraison en une seule fois',
  'dbm.view-profile.single-delivery.failed':
    'La livraison supplémentaire ne peut pas être préparée',
  'dbm.view-profile.single-delivery.failed.desc':
    "Soit aucune liste d'entreprise n'a été sélectionnée dans ce profil, soit les données ne peuvent pas être récupérées. Veuillez sélectionner une liste d'entreprises ou réessayer plus tard.",
  'dbm.view-profile.single-delivery.success':
    'une livraison supplémentaire est en cours de préparation',
  'dbm.view-profile.single-delivery.success.desc':
    "Une fois les données récupérées, le fichier de données sera livré. Consultez l'aperçu « Livraisons passées » pour ce profil afin de télécharger le fichier.",
  'dbm.view-profile.single-delivery.tooltip':
    'Utilisez ce lien pour lancer une récupération manuelle des données supplémentaires selon les paramètres de profil enregistrés. Une fois les données récupérées, le fichier sera disponible dans la liste des livraisons passées.',
  'dbm.view-profile.title': 'Voir le profile',
  'de.dataOverview.continue': 'Continuez',
  'de.dataOverview.heading': 'Commençons par choisir les données que vous voulez',
  'de.downloadUpdatedFile': 'Téléchargez le fichier actualisé',
  'de.homePage.continue': 'Démarrer enrichissement',
  'de.homePage.heading': 'Enrichissement données',
  'de.homePage.step1.heading': '1. Choisissez les données que vous voulez',
  'de.homePage.step1.text': 'Ceci sera ajouté à votre fichier dans de nouvelles colonnes',
  'de.homePage.step2.heading': '2. Importer le fichier de votre entreprise',
  'de.homePage.step2.li1': 'Télécharger votre fichier',
  'de.homePage.step2.li2': 'Comparer vos champs',
  'de.homePage.step2.li3': 'Comparer avec les données Graydon',
  'de.homePage.step3.heading': '3. Télécharger fichier actualisé',
  'de.homePage.step3.text': 'Nous ne modifions jamais vos données originales',
  'de.listEnrichment.downloadButton': 'Download enriched list',
  'dm.acceptance.label': ' ',
  'dm.acceptance.legend': ' ',
  'dm.accordion.contact': "Données générales de l'entreprise",
  'dm.accordion.financials': 'Comptes annuels',
  'dm.accordion.ginsights': 'Graydon Insights',
  'dm.accordion.management': 'La Direction',
  'dm.accordion.relation': "Structure de l'entreprise",
  'dm.balanced.lede':
    "Avec une politique d'acceptation un peu plus flexible, vous pouvez toujours réaliser une croissance, augmenter votre bénéfice et limiter le risque d'amortissement.",
  'dm.balanced.title': 'Equilibré',
  'dm.createModel.header': 'C’est parti !',
  'dm.createModel.instruction':
    "Bienvenue dans la configuration. Avant de pouvoir accepter des clients, vous devez créer un modèle de décision. Mais pas de panique : la création d’un modèle de décision s'effectue en trois étapes faciles. Choisissez un pays, sélectionnez un modèle et personnalisez-le ou enregistrez-le immédiatement.",
  'dm.createModel.subHeader': 'Commençons par choisir un pays',
  'dm.credit.check.error':
    'Cette information n’est actuellement pas disponible en ligne.<br /><br />Pour toute aide complémentaire, vous pouvez contacter notre équipe de Support Clients par <a href="mailto:{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}">{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}</a> ou par téléphone au {country, select, gb {+44 (020) 8515 1400} nl {+31 (020) 567 9501} other {+32 (03) 280 88 80}}.',
  'dm.defensive.lede':
    'Ce modèle convient si vous voulez courir peu de risques et limiter les amortissements au minimum.',
  'dm.defensive.title': 'Défensif',
  'dm.delete.confirm.desc':
    'Vous êtes sur le point de supprimer un modèle de décision. Cette opération est irréversible.',
  'dm.delete.confirm.no': 'Non',
  'dm.delete.confirm.question': 'Vous êtes sûr?',
  'dm.delete.confirm.title': 'Attendez un moment !',
  'dm.delete.confirm.yes': 'Oui',
  'dm.editor.accept': 'Accepter',
  'dm.editor.annualAccountAvailable': 'Comptes annuels ?',
  'dm.editor.annualAccountAvailable.desc':
    "Vous pouvez configurer les conditions pour le Checkpoint Disponibilité des comptes annuels ici. Vous pouvez le lire sous forme d'affirmation 'si, alors'. 'Si' la condition est remplie, 'Alors' l’action consécutive correspondante est réalisée.",
  'dm.editor.annualAccountAvailable.title':
    'Checkpoint : Les comptes annuels sont-ils disponibles ?',
  'dm.editor.augurScore': 'Augur score',
  'dm.editor.augurScore-1': 'augur 1',
  'dm.editor.augurScore-2': 'augur 2',
  'dm.editor.augurScore-3': 'augur 3',
  'dm.editor.augurScore.desc':
    "Vous pouvez configurer les conditions pour le Checkpoint Augur Score ici. Vous pouvez le lire sous forme d'affirmation 'si, alors'. 'Si' la condition est remplie, 'Alors' l’action consécutive correspondante est réalisée.",
  'dm.editor.augurScore.title': 'Checkpoint: Augur score',
  'dm.editor.be.creditLimit.desc':
    "Vous pouvez configurer les conditions pour le Checkpoint Limite de crédit Graydon ici. Vous pouvez le lire sous forme d'affirmation 'si, alors'. 'Si' la condition est remplie, 'Alors' l’action consécutive correspondante est réalisée. La 'Valeur de commande' est complétée par check.",
  'dm.editor.be.multiscore.threshold.invalid': 'La valeur doit se situer entre 0 et 100',
  'dm.editor.ccj.description': ' ',
  'dm.editor.ccjCount': "Nombre d'injonctions",
  'dm.editor.ccjCount.desc':
    "Vous pouvez configurer les conditions pour le Checkpoint Nombre de CCJ ici. Vous pouvez le lire sous forme de 'affirmation si, alors'. 'Si' la condition est remplie, 'Alors' l’action consécutive correspondante est réalisée.",
  'dm.editor.ccjCount.title': 'Checkpoint: Nombre de CCJs',
  'dm.editor.companyActive': 'Société active ?',
  'dm.editor.companyActive.desc':
    "Vous pouvez configurer les conditions pour le Checkpoint Entreprise active ici. Vous pouvez le lire sous forme d'affirmation 'si ..., alors'. 'Si' la condition est remplie, 'Alors' l’action consécutive correspondante est réalisée.",
  'dm.editor.companyActive.title': 'Checkpoint : la société est-elle active ? ',
  'dm.editor.companyAge': 'Âge de l’entreprise',
  'dm.editor.companyAge.desc':
    "Vous pouvez configurer les conditions pour le Checkpoint Âge entreprise ici. Vous pouvez le lire sous forme de 'affirmation si, alors'. 'Si' la condition est remplie, 'Alors' l’action consécutive correspondante est réalisée.",
  'dm.editor.companyAge.months': 'mois',
  'dm.editor.companyAge.title': "Checkpoint: âge de l'entreprise",
  'dm.editor.companyIncorporated': 'Company Incorporated',
  'dm.editor.companyIncorporated.desc':
    "Vous pouvez configurer les conditions pour le Checkpoint Entreprise Incorporated ici. Vous pouvez le lire sous forme de 'affirmation si, alors'. 'Si' la condition est remplie, 'Alors' l’action consécutive correspondante est réalisée.",
  'dm.editor.companyIncorporated.title': 'Checkpoint: Company Incorporated?',
  'dm.editor.companyName': 'Nom de la société',
  'dm.editor.companyRegisterNumber': "Numéro d'entreprise",
  'dm.editor.credit.limit': 'Valeur de commande',
  'dm.editor.credit.limit.zero': 'Limite de crédit = {currency} 0',
  'dm.editor.creditLimit': 'Limite de crédit',
  'dm.editor.creditLimit.desc':
    "Vous pouvez configurer les conditions pour le Checkpoint Limite de crédit Graydon ici. Vous pouvez le lire sous forme d'affirmation 'si, alors'. 'Si' la condition est remplie, 'Alors' l’action consécutive correspondante est réalisée. La 'Valeur de commande' est complétée par check.",
  'dm.editor.creditLimit.threshold.invalid': 'La valeur doit se situer entre 0 et 12',
  'dm.editor.creditLimit.title': 'Checkpoint : Limite de crédit Graydon',
  'dm.editor.creditRating': 'Notation de crédit',
  'dm.editor.creditRating.desc':
    "Vous pouvez configurer les conditions pour le Checkpoint Notation de crédit ici. Vous pouvez le lire sous forme de 'affirmation si, alors'. 'Si' la condition est remplie, 'Alors' l’action consécutive correspondante est réalisée.",
  'dm.editor.creditRating.outcome.above': 'Notation de crédit',
  'dm.editor.creditRating.outcome.below': 'Notation de crédit',
  'dm.editor.creditRating.title': 'Checkpoint: Notation de crédit',
  'dm.editor.deny': 'Refuser',
  'dm.editor.error': 'La valeur doit se situer entre {minValue} et {maxValue}.',
  'dm.editor.esc.to.close': 'Fermer sans enregistrer',
  'dm.editor.help.annualAccountAvailable.condition':
    'La disponibilité de comptes annuels influence le Multiscore et la Limite de crédit.',
  'dm.editor.help.annualAccountAvailable.decision':
    'Sélectionnez l’action suivante souhaitée. Ajoutez une description pour personnaliser le résultat. Cette description sera reprise dans les résultats du check.',
  'dm.editor.help.augurScore.condition':
    "L'Augur Score est un indicateur de la santé financière. Plus le score est élevé, plus le risque est faible. Faites en sorte que les valeurs indiquées correspondent.",
  'dm.editor.help.augurScore.decision':
    'Sélectionnez l’action suivante souhaitée. Ajoutez une description pour personnaliser le résultat. Cette description sera reprise dans les résultats du check.',
  'dm.editor.help.ccjCount.condition':
    'Les CCJ sont des décisions juridiques des tribunaux anglais. Configurez le nombre maximal autorisé. Complétez la même valeur pour les deux conditions.',
  'dm.editor.help.ccjCount.decision':
    'Sélectionnez l’action suivante souhaitée. Ajoutez une description pour personnaliser le résultat. Cette description sera reprise dans les résultats du check.',
  'dm.editor.help.companyActive.condition':
    "Le départ de chaque modèle est un check pour vérifier si l’entreprise est active. Les entreprises liquidées ou en faillite sont mentionnées comme 'Inactives'.",
  'dm.editor.help.companyActive.decision':
    'Sélectionnez l’action suivante souhaitée. Ajoutez une description pour personnaliser le résultat. Cette description sera reprise dans les résultats du check.',
  'dm.editor.help.companyAge.condition':
    'Sélectionnez l’âge de l’entreprise en nombre de mois. Faites en sorte que les valeurs dans les deux conditions correspondent.',
  'dm.editor.help.companyAge.decision':
    'Sélectionnez l’action suivante souhaitée. Ajoutez une description pour personnaliser le résultat. Cette description sera reprise dans les résultats du check.',
  'dm.editor.help.companyIncorporated.condition':
    'Une entreprise est Incorporated (Y) s’il s’agit d’une personne morale ; Unincorporated (N) s’il ne s’agit pas d’une personne morale.',
  'dm.editor.help.companyIncorporated.decision':
    'Sélectionnez l’action suivante souhaitée. Ajoutez une description pour personnaliser le résultat. Cette description sera reprise dans les résultats du check.',
  'dm.editor.help.creditLimit.condition':
    'La valeur de commande est comparée à la limite de crédit Graydon. Vous pouvez utiliser un multiplicateur pour adapter la limite de crédit Graydon.',
  'dm.editor.help.creditLimit.decision':
    'Sélectionnez l’action souhaitée. Ajoutez une description pour personnaliser le résultat. Cette description sera reprise dans les résultats du contrôle.',
  'dm.editor.help.creditRating': 'Lorem ipsum dolor sit amet',
  'dm.editor.help.creditRating.condition':
    "La Notation de crédit est 'X' dans le cas d’un risque accru sur l’entreprise. Si le Rating est différent de 'X', nous vous recommandons de passer à l'Augur Score.",
  'dm.editor.help.creditRating.decision':
    'Sélectionnez l’action suivante souhaitée. Ajoutez une description pour personnaliser le résultat. Cette description sera reprise dans  les résultats du check.',
  'dm.editor.help.multiscore.condition':
    'Le Multiscore évalue les opportunités et les risques pour une entreprise. Plus le score est élevé, plus le potentiel est grand et plus le risque est faible. Faites en sorte que les valeurs dans les deux conditions correspondent.',
  'dm.editor.help.multiscore.decision':
    'Sélectionnez l’action suivante souhaitée. Ajoutez une description pour personnaliser le résultat. Cette description sera reprise dans les résultats du check.',
  'dm.editor.help.orderValue.condition':
    'Si la valeur de commande est supérieure à la valeur configurée, vous pouvez référer le check. Complétez la même valeur de commande pour les deux conditions.',
  'dm.editor.help.orderValue.decision':
    'Sélectionnez l’action suivante souhaitée. Ajoutez une description pour personnaliser le résultat. Cette description sera reprise dans les résultats du check.',
  'dm.editor.help.payments.condition':
    "Le montant du paiement est exprimé sous forme de 'Good' et 'Bad'. 'Good' : il y a des expériences positives ; 'Bad' : il y a des expériences négatives.",
  'dm.editor.help.payments.decision':
    'Sélectionnez l’action suivante souhaitée. Ajoutez une description pour personnaliser le résultat. Cette description sera reprise dans  les résultats du check.',
  'dm.editor.help.paymentsScore.condition':
    "Le montant du paiement est exprimé sous forme de 'Good' et 'Bad'. 'Good' : il y a des expériences positives ; 'Bad' : il y a des expériences négatives.",
  'dm.editor.help.paymentsScore.decision':
    'Sélectionnez l’action suivante souhaitée. Ajoutez une description pour personnaliser le résultat. Cette description sera reprise dans  les résultats du check.',
  'dm.editor.help.probabilityOfDefault.condition':
    'Le risque de défaut de paiement est un pourcentage. Plus le %PD est élevé, plus le risque de défaut de paiement est grand. Not Rated fait partie des valeurs manquantes.',
  'dm.editor.help.probabilityOfDefault.decision':
    'Sélectionnez l’action suivante souhaitée. Ajoutez une description pour personnaliser le résultat. Cette description sera reprise dans les résultats du check.',
  'dm.editor.help.tree.view':
    "Cette page montre le modèle de décision dans une structure arborescente. Lorsque vous effectuez un check, l’entreprise arrive par le côté gauche. Les carrés bleus sont des checkpoints, où les données de l’entreprise sont contrôlées en regard des conditions paramétrées, et une action consécutive est réalisée. Les carrés blancs indiquent les conditions. L’entreprise peut passer à un checkpoint suivant, ou terminer dans un résultat. Les résultats sont montrés sous forme de carrés verts et comptent 3 catégories  : Accepter, Référer et Refuser. Cliquez sur un checkpoint pour adapter les conditions et les actions consécutives de ce check. Lorsque vous êtes prêt, cliquez dans le coin supérieur droit sur 'Sauvegarder et fermer’ pour activer le modèle et l’utiliser.",
  'dm.editor.isCompanyIncorporated': 'Company Incorporated?',
  'dm.editor.multiscore': 'Multiscore',
  'dm.editor.multiscore.desc':
    "Vous pouvez configurer les conditions pour le Checkpoint Multiscore ici. Vous pouvez le lire sous forme d'affirmation 'si, alors'. 'Si' la condition est remplie, 'Alors' l’action consécutive correspondante est réalisée.",
  'dm.editor.multiscore.title': 'Checkpoint: Multiscore',
  'dm.editor.nameEditor.err': "Ceci n'est pas un nom valide",
  'dm.editor.nameEditor.placeholder': 'Nommez votre modèle',
  'dm.editor.next.checkpoint': 'Allez au checkpoint suivant',
  'dm.editor.nl.creditLimit.desc':
    "Vous pouvez configurer les conditions pour le Checkpoint Comportement de paiement ici. Vous pouvez le lire sous forme d'affirmation 'si, alors'. 'Si' la condition est remplie, 'Alors' l’action consécutive correspondante est réalisée.",
  'dm.editor.nl.paymentsScore.desc':
    "Vous pouvez configurer les conditions pour le Checkpoint Comportement de paiement ici. Vous pouvez le lire sous forme d'affirmation 'si, alors'. 'Si' la condition est remplie, 'Alors' l’action consécutive correspondante est réalisée.",
  'dm.editor.nl.probabilityOfDefault.threshold.invalid': 'La valeur doit se situer entre 0.1 et 20',
  'dm.editor.operator.nr': 'Pas notée',
  'dm.editor.orderValue': 'valeur de la commande',
  'dm.editor.orderValue.desc':
    "Vous pouvez configurer les conditions pour le Checkpoint Valeur de commande ici. Vous pouvez le lire sous forme de 'affirmation si, alors'. 'Si' la condition est remplie, 'Alors' l’action consécutive correspondante est réalisée.",
  'dm.editor.orderValue.k': ' ',
  'dm.editor.orderValue.title': 'Valeur de la commande',
  'dm.editor.outcome.description': 'Description du résultat',
  'dm.editor.outcomes': 'Allez au résultat',
  'dm.editor.payments': 'Comportement de paiement',
  'dm.editor.payments.desc':
    "Vous pouvez configurer les conditions pour le Checkpoint Comportement de paiement ici. Vous pouvez le lire sous forme d'affirmation 'si, alors'. 'Si' la condition est remplie, 'Alors' l’action consécutive correspondante est réalisée.",
  'dm.editor.payments.title': 'Checkpoint : Comportement de paiement ',
  'dm.editor.paymentsScore': 'Comportement de paiement',
  'dm.editor.paymentsScore.desc': 'Adaptez la limite pour le score de paiement.',
  'dm.editor.paymentsScore.title': 'Score de paiement',
  'dm.editor.pd.nr': 'Risque de défaut de paiement (PD) = “Not Rated”',
  'dm.editor.probabilityOfDefault': 'Risque de défaut de paiement (PD)',
  'dm.editor.probabilityOfDefault.desc':
    "Vous pouvez configurer les conditions pour le Checkpoint Risque de défaut de paiement ici. Vous pouvez le lire sous forme d'affirmation 'si, alors'. 'Si' la condition est remplie, 'Alors' l’action consécutive correspondante est réalisée.",
  'dm.editor.probabilityOfDefault.desc.nl':
    "Avez-vous besoin d’aide pour l’instauration d’un risque de défaut de paiement (PD%) ? Vous pouvez voir dans le <a href='https://connect.graydon-global.com/rs/934-AQC-614/images/FR PD Sheet.pdf' target='_blank' rel='noopener noreferrer'>tableau de données par secteurs d’industrie</a>, le nombre de compagnies, que vous pouvez approuver sur la base du pourcentage de défaut de paiement. NB : ceci n’est qu’un extrait qui ne peut servir que de ligne conductrice.",
  'dm.editor.probabilityOfDefault.title': 'Checkpoint : Risque de défaut de paiement (PD%)',
  'dm.editor.refer': 'Référer',
  'dm.editor.setup': 'Decision model set up',
  'dm.editor.totalStaffEmployed': "Nombre total d'employés",
  'dm.firstrun.cta': 'Créez un modèle de décision',
  'dm.firstrun.lede':
    "Bienvenue dans la phase de préparation. Avant de pouvoir accepter des clients, vous devez d'abord créer un modèle de décision. Pas de souci, ce sera fait en trois étapes simples.",
  'dm.firstrun.next.steps': ' ',
  'dm.firstrun.step1': 'Etape 1. Sélectionnez un pays;',
  'dm.firstrun.step2': 'Etape 2. Sélectionnez un modèle;',
  'dm.firstrun.step3': 'Etape 3. Personnalisez le modèle.',
  'dm.firstrun.title': 'Commencez',
  'dm.label.client.acceptance': 'Acceptation des clients',
  'dm.label.debt.prevention': 'Prévention des créances',
  'dm.management.action.activate': 'activer',
  'dm.management.action.deactivate': 'désactiver',
  'dm.management.action.delete': 'effacer',
  'dm.management.action.edit': 'modifier',
  'dm.management.create': 'Créer un nouveau modèle',
  'dm.management.title': 'Modèle de gestion',
  'dm.multiselect.all-models': 'Tous les modèles de décision',
  'dm.multiselect.n-models': '{n} modèles de décision selectionnés',
  'dm.offensive.lede':
    'Vous visez la croissance et êtes prêt à prendre davantage de risque ? Alors ce modèle est pour vous.',
  'dm.offensive.title': 'Offensif',
  'dm.pod.tolerance.accept': '<b>accepter</b>',
  'dm.pod.tolerance.deny': '<b>refuser</b>',
  'dm.pod.tolerance.if': 'Si <b>risque de défaut de paiement</b>',
  'dm.pod.tolerance.label':
    "Je ne veux pas passer en pertes et profits plus de … sur mon chiffre d'affaires annuel.",
  'dm.run.backtosearch': 'Retour aux résultats de recherche',
  'dm.run.choosemodel': 'Sélectionnez un modèle de décision existant',
  'dm.run.ordervalue': 'Complétez ici la valeur de la commande',
  'dm.run.result.bucket-accept': 'Accepter',
  'dm.run.result.bucket-deny': 'Refuser',
  'dm.run.result.bucket-refer': 'Référer',
  'dm.run.result.bucket-undefined': 'Pas encore défini(e)',
  'dm.run.result.checkpoint.be.annualAccountAvailable': 'Comptes annuels disponibles ?',
  'dm.run.result.checkpoint.be.annualAccountAvailable.threshold.label': 'Valeur limite',
  'dm.run.result.checkpoint.be.multiscore': 'Multiscore',
  'dm.run.result.checkpoint.be.multiscore.threshold.label': 'Valeur limite',
  'dm.run.result.checkpoint.be.paymentsScore': 'Score de paiement',
  'dm.run.result.checkpoint.be.paymentsScore.threshold.label': 'Valeur limite',
  'dm.run.result.checkpoint.companyActive': 'Société active ?',
  'dm.run.result.checkpoint.companyActive.threshold.label': 'Valeur limite',
  'dm.run.result.checkpoint.creditLimit': 'Limite de crédit',
  'dm.run.result.checkpoint.creditLimit.threshold.label': 'Valeur de la commande',
  'dm.run.result.checkpoint.failed.description': "Le résultat n'a pas pu être déterminé",
  'dm.run.result.checkpoint.failed.label': 'Résultat',
  'dm.run.result.checkpoint.gb.augurScore': 'Augur Score',
  'dm.run.result.checkpoint.gb.augurScore.threshold.label': 'Valeur limite',
  'dm.run.result.checkpoint.gb.ccjCount': 'Nombre de jugements par le tribunal d’instance',
  'dm.run.result.checkpoint.gb.ccjCount.threshold.label': 'Valeur limite',
  'dm.run.result.checkpoint.gb.companyAge': "Âge de l'entreprise",
  'dm.run.result.checkpoint.gb.companyAge.threshold.label': 'Valeur limite',
  'dm.run.result.checkpoint.gb.companyIncorporated': 'Société à responsabilité limitée ?',
  'dm.run.result.checkpoint.gb.companyIncorporated.threshold.label': 'Valeur limite',
  'dm.run.result.checkpoint.gb.creditRating': 'Notation de crédit',
  'dm.run.result.checkpoint.gb.creditRating.threshold.label': 'Valeur limite',
  'dm.run.result.checkpoint.gb.orderValue': 'Valeur de la commande',
  'dm.run.result.checkpoint.gb.orderValue.threshold.label': 'Valeur limite',
  'dm.run.result.checkpoint.nl.paymentsScore': 'Score de paiement',
  'dm.run.result.checkpoint.nl.paymentsScore.threshold.label': 'Valeur limite',
  'dm.run.result.checkpoint.nl.probabilityOfDefault': 'Pourcentage de probabilité de défaillance ',
  'dm.run.result.checkpoint.nl.probabilityOfDefault.threshold.label': 'Valeur limite',
  'dm.run.result.checkpoint.payments': 'Comportement de paiement',
  'dm.run.result.checkpoint.payments.threshold.label': 'Valeur limite',
  'dm.run.result.checkpoint.value.bad': 'Mauvais',
  'dm.run.result.checkpoint.value.good': 'Bon',
  'dm.run.result.checkpoint.value.n': 'Non',
  'dm.run.result.checkpoint.value.na': 'Indisponible',
  'dm.run.result.checkpoint.value.no': 'Non',
  'dm.run.result.checkpoint.value.notX': 'Pas égal à X',
  'dm.run.result.checkpoint.value.X': 'X',
  'dm.run.result.checkpoint.value.y': 'Oui',
  'dm.run.result.checkpoint.value.yes': 'Oui',
  'dm.run.result.failed': 'Le résultat ne peut pas être montré',
  'dm.run.runcheck': 'Effectuez le contrôle',
  'dm.setup.step1': 'Sélectionnez un pays',
  'dm.setup.step2': 'Quel est votre appétit pour le risque ?',
  'dm.setup.step3': 'Personnalisez le modèle',
  'dm.setup.step4': 'Terminé !',
  'dm.templates.lede':
    "Nous avons sélectionné pour vous ces modèles prédéfinis. Sélectionnez-en un et personnalisez-le sur la base de vos propres souhaits à l'étape suivante.",
  'dm.templates.title': 'Quel est votre appétit pour le risque ?',
  'dm.tooltip.acceptance.legend': ' ',
  'dm.tooltip.acceptance.value': ' ',
  'dm.tooltip.client.acceptance':
    "Plus il y a de blocs complétés, plus vous êtes axés sur la croissance et l'acceptation des clients.",
  'dm.tooltip.debt.prevention':
    'Plus il y a de blocs complétés dans ce modèle, plus vous êtes axés sur la prévention des créances.',
  'dm.tooltip.tolerance.accept':
    "Lorsque le risque de défaut de paiement d'une entreprise est inférieur à la valeur indiquée, vous pouvez accepter le client.",
  'dm.tooltip.tolerance.deny':
    "Lorsque le risque de défaut de paiement d'une entreprise est supérieur à la valeur indiquée, vous ne pouvez pas accepter le client.",
  'dm.tooltip.tolerance.threshold':
    'Indiquez ici le pourcentage de créances maximal admissible par an. Lorsque vous contrôlez une entreprise, cette valeur est comparée au risque de défaut de paiement.',
  'dm.userBar.cancel': 'Annuler et fermer',
  'download.as.csv': 'Télécharger sous forme de CSV',
  'error.contact-support': 'Contactez le service clientèle',
  'error.inactive.description':
    'Donnez-nous un appel au numéro ci-dessous, et nous allons vous aider rapidement.',
  'error.inactive.title': 'Votre compte est inactif actuellement',
  'error.login': 'Login',
  'error.retry-login': "S'il vous plaît essayez de vous connecter à nouveau",
  'error.something-wrong': "Une erreur s'est produite",
  'error.unknown.description':
    "La connexion a échoué. Il se pourrait que votre compte ne soit pas actif. Il se pourrait aussi que votre 'numéro ID' ne soit pas correct. Cela ne vous dit rien? Passez ce message à notre Desk Service, ils vous aideront rapidement.",
  'error.unknown.title': 'Il y a quelque chose qui ne va pas',
  'filter.pane.button.tooltip': 'Cliquez pour masquer ou afficher les filtres',
  'filter.pane.header': 'Filtres',
  'filters.countries.be': 'BE',
  'filters.countries.gb': 'UK',
  'filters.countries.nl': 'NL',
  'filters.countries.xx': 'Inconnu',
  'filters.sectors.a': 'Agriculture, sylviculture et pêche',
  'filters.sectors.a.short': 'Agriculture, sylviculture et pêche',
  'filters.sectors.b': 'Industries extractives',
  'filters.sectors.b.short': 'Industries extractives',
  'filters.sectors.c': 'Industrie manufacturière',
  'filters.sectors.c.short': 'Industrie manufacturière',
  'filters.sectors.d':
    "Production et distribution d'électricité, de gaz, de vapeur et d'air conditionné",
  'filters.sectors.d.short':
    "Production et distribution d'électricité, de gaz, de vapeur et d'air conditionné",
  'filters.sectors.e':
    "Production et distribution d'eau; assainissement, gestion des déchets et dépollution",
  'filters.sectors.e.short':
    "Production et distribution d'eau; assainissement, gestion des déchets et dépollution",
  'filters.sectors.f': 'Construction',
  'filters.sectors.f.short': 'Construction',
  'filters.sectors.g': 'Commerce de gros et de détail; réparation de véhicules',
  'filters.sectors.g.short': 'Commerce de gros et de détail; réparation de véhicules',
  'filters.sectors.h': 'Transports et entreposage',
  'filters.sectors.h.short': 'Transports et entreposage',
  'filters.sectors.i': 'Hébergement et restauration',
  'filters.sectors.i.short': 'Hébergement et restauration',
  'filters.sectors.j': 'Information et communication',
  'filters.sectors.j.short': 'Information et communication',
  'filters.sectors.k': "Activités financières et d'assurance",
  'filters.sectors.k.short': "Activités financières et d'assurance",
  'filters.sectors.l': 'Activités immobilières',
  'filters.sectors.l.short': 'Activités immobilières',
  'filters.sectors.m': 'Activités spécialisées, scientifiques et techniques',
  'filters.sectors.m.short': 'Activités spécialisées, scientifiques et techniques',
  'filters.sectors.n': 'Activités de services administratifs et de soutien',
  'filters.sectors.n.short': 'Activités de services administratifs et de soutien',
  'filters.sectors.o': 'Administration publique et défense; sécurité sociale obligatoire',
  'filters.sectors.o.short': 'Administration publique et défense; sécurité sociale obligatoire',
  'filters.sectors.p': 'Enseignement',
  'filters.sectors.p.short': 'Enseignement',
  'filters.sectors.q': 'Santé humaine et action sociale',
  'filters.sectors.q.short': 'Santé humaine et action sociale',
  'filters.sectors.r': 'Arts, spectacles et activités récréatives',
  'filters.sectors.r.short': 'Arts, spectacles et activités récréatives',
  'filters.sectors.s': 'Autres activités de services',
  'filters.sectors.s.short': 'Autres activités de services',
  'filters.sectors.t': "Activités des ménages en tant qu'employeurs",
  'filters.sectors.t.short': "Activités des ménages en tant qu'employeurs",
  'filters.sectors.u': 'Activités des organismes extraterritoriaux',
  'filters.sectors.u.short': 'Activités des organismes extraterritoriaux',
  'filters.sectors.x': 'Autres',
  'filters.sectors.x.short': 'Autres',
  'footer.cookies': 'Cookies',
  'footer.copyright': '© Graydon. All rights reserved',
  'footer.gdpr': 'RGPD',
  'footer.terms.and.conditions': 'Conditions Générales',
  'form.validation.max-length': 'Ce champ peut contenir un maximum de {max} caractères',
  'form.validation.required': 'Champ obligatoire',
  'gdm.check-modal.archive-description': 'Seules les 10 dernières vérifications sont affichées',
  'gdm.check-modal.archive-error': 'Erreur lors du chargement des vérifications précédentes',
  'gdm.check-modal.archive-header': 'Contrôles précédents pour cette société',
  'gdm.check-modal.archive-no-result': 'Aucune vérification préalable',
  'gdm.check-modal.select': 'Select a decision model',
  'gdm.check-modal.title': 'Vérifier avec le modèle de décision',
  'gdm.check-modal.view-check': 'Voir le chèque',
  'gdm.check-result.checkpoint.current-value': 'Valeur actuelle',
  'gdm.check-result.checkpoint.indicator': 'Indicateur',
  'gdm.check-result.checkpoint.order-value': 'Valeur du seuil',
  'gdm.check-result.checkpoint.title.accept': 'Accepter',
  'gdm.check-result.checkpoint.title.deny': 'Refuser',
  'gdm.check-result.checkpoint.title.refer': 'Référer',
  'gdm.check-result.checkpoint.tooltip':
    'Vous trouverez ici les détails les plus important à propos de la société que vous avez demandée.',
  'gdm.check-result.company-details.tooltip':
    "Vous trouverez ici toutes les informations de base sur l'entreprise faisant l’objet de votre recherche.",
  'gdm.check-result.company-relations.tooltip':
    'Vous trouverez ici la liste des relations  pour la société que vous avez demandée',
  'gdm.check-result.contact-details.tooltip':
    'Vous trouverez ici les coordonnées de la société que vous avez demandée.',
  'gdm.check-result.directors.tooltip':
    'Vous trouverez ici un aperçu des administrateurs actifs et éventuellement ceux inactifs, de l’autorité qui leur est allouée et des autres fonctions de ces administrateurs.',
  'gdm.check-result.graydon-insights.tooltip':
    'Vous trouverez ici des détails importants à propos de la société que vous avez demandée.',
  'gdm.check-result.summary.date': 'Date',
  'gdm.check-result.summary.decision-model': 'Modèle de décision',
  'gdm.check-result.summary.order-value': 'Montant de la commande',
  'gdm.report.orderValue.customized': 'Personnalisée',
  'gdm.report.orderValue.recommended': 'Conseillée',
  'general.contactGraydon': 'Contact Graydon',
  'general.customerCare': 'Customer Care',
  'general.fetch.error': 'Erreur lors du chargement des données, veuillez réessayer plus tard',
  'general.na': 'N/A',
  'general.noRating': 'NR',
  'general.not.available': 'Indisponible',
  'general.sort': 'Sort',
  'general.sort.ascending': 'Ascending',
  'general.sort.descending': 'Descending',
  'general.specify-search':
    "Vous ne trouvez pas ce que vous cherchez ? Merci d'affiner votre recherche.",
  'general.to.the.attention.of': 'à l’attention',
  'get.support': ' ',
  heading: 'Rapport UBO',
  'home.heading': 'Bienvenue',
  'home.heading.subtext':
    'Commençons par créer un modèle de décision ou par rechercher une entreprise.',
  'icr-form-field-required': 'Obligatoire',
  'icr.archive-page.description':
    'Archives de toutes les demandes effectuées depuis votre compte vers le système Graydon.',
  'icr.archive-page.header': 'Archives',
  'icr.archive-page.tab.pending': 'En attente',
  'icr.archive-page.tab.received': 'Reçu',
  'icr.archive.load.more.reports': 'Télécharger plus de rapports {pageSize}',
  'icr.archive.orders.client-note': 'Votre référence',
  'icr.archive.orders.company-name': 'Nom de la société',
  'icr.archive.orders.completed.tooltip':
    'Vous trouverez dans cette section tous les rapports précédemment commandés et livrés.',
  'icr.archive.orders.country': 'Pays',
  'icr.archive.orders.dateCreated': 'Date de création',
  'icr.archive.orders.documentName': 'Nom de la rapport',
  'icr.archive.orders.due-date': "Date d'échéance",
  'icr.archive.orders.genericError.description':
    "Le rapport commandé n'a pas pu être livré. Veuillez commander une [Nouvelle Investigation](/apps/international-credit-reports/order-investigated-report).",
  'icr.archive.orders.genericError.title': 'La demande a échoué\n',
  'icr.archive.orders.hideReports': 'Masquer les {count} rapports',
  'icr.archive.orders.no-result': 'Aucun résultat trouvé',
  'icr.archive.orders.order-reference': 'Numéro de commande',
  'icr.archive.orders.ordered-date': 'Date de la commande',
  'icr.archive.orders.pending.tooltip':
    'Vous trouverez ici les rapports dont vous avez fait la demande et qui sont en attente de livraison.',
  'icr.archive.orders.report': 'Rapport',
  'icr.archive.orders.search.placeholder':
    'Rechercher par nom de société, pays, numéro de commande, rapport, ou votre référence. ',
  'icr.archive.orders.showReports': 'Voir les {count} rapports',
  'icr.archive.orders.type': 'Type',
  'icr.archive.report.download.toast.body.error':
    'Le rapport ne peut pas être téléchargé. Merci de réessayer plus tard.',
  'icr.archive.report.download.toast.body.link-to-archive': 'Allez dans la partie Archive',
  'icr.archive.report.download.toast.body.not-available':
    'Veuillez de nouveau essayer de télécharger le rapport',
  'icr.archive.report.download.toast.body.success':
    'Your report is also stored in the International Business Archive you can access it anytime there.',
  'icr.archive.report.download.toast.title.error': 'Impossible de télécharger',
  'icr.archive.report.download.toast.title.not-available':
    "Le rapport n'est pas disponible pour le moment",
  'icr.archive.report.download.toast.title.success': 'Report downloaded',
  'icr.archive.request-report.toast.link': 'Allez dans les archives',
  'icr.archive.request-report.toast.text':
    'Le rapport sera bientôt disponible. Suivez les progrès dans la section Archives International Business',
  'icr.archive.request-report.toast.text.error':
    'Veuillez réessayer plus tard ou [contacter](/apps/contact) Graydon.',
  'icr.archive.request-report.toast.title': 'Votre rapport est en train d’être produit',
  'icr.archive.request-report.toast.title.error': 'Impossible de envoyer la demande',
  'icr.country-select.disabled.hover':
    "Ce pays n'est pas inclus dans votre forfait. Merci de bien vouloir nous contacter pour une mise à niveau.",
  'icr.country-select.domestic': 'Locale',
  'icr.country-select.placeholder': 'Choisissez votre pays',
  'icr.country-select.report-type.database': 'Rapport en ligne',
  'icr.country-select.report-type.domestic-database': 'Rapport en ligne locale',
  'icr.country-select.report-type.investigation': 'Nouvelle Investigation',
  'icr.country.AD': 'Andorre',
  'icr.country.AE': 'Émirats arabes unis',
  'icr.country.AF': 'Afghanistan',
  'icr.country.AG': 'Antigua-et-Barbuda',
  'icr.country.AI': 'Anguilla',
  'icr.country.AL': 'Albanie',
  'icr.country.AM': 'Arménie',
  'icr.country.AN': 'Antilles néerlandaises',
  'icr.country.AO': 'Angola',
  'icr.country.AQ': 'Antarctica',
  'icr.country.AR': 'Argentine',
  'icr.country.AS': 'Samoa américaines',
  'icr.country.AT': 'Autriche',
  'icr.country.AU': 'Australie',
  'icr.country.AW': 'Aruba',
  'icr.country.AX': 'Aland Islands',
  'icr.country.AZ': 'Azerbaïdjan',
  'icr.country.BA': 'Bosnie-Herzégovine',
  'icr.country.BB': 'Barbade',
  'icr.country.BD': 'Bangladesh',
  'icr.country.BE': 'Belgique',
  'icr.country.BF': 'Burkina Faso',
  'icr.country.BG': 'Bulgarie',
  'icr.country.BH': 'Bahreïn',
  'icr.country.BI': 'Burundi',
  'icr.country.BJ': 'Bénin',
  'icr.country.BL': 'Saint Barthélemy',
  'icr.country.BM': 'Bermudes',
  'icr.country.BN': 'Brunei Darussalam',
  'icr.country.BO': 'Bolivie',
  'icr.country.BQ': 'Bonaire, Saint-Eustache et Saba',
  'icr.country.BR': 'Brésil',
  'icr.country.BS': 'Bahamas',
  'icr.country.BT': 'Bhoutan',
  'icr.country.BV': 'Bouvet Island',
  'icr.country.BW': 'Botswana',
  'icr.country.BY': 'Bélarus',
  'icr.country.BZ': 'Belize',
  'icr.country.CA': 'Canada',
  'icr.country.CC': 'Cocos (Keeling) Islands',
  'icr.country.CD': 'Congo, République démocratique du',
  'icr.country.CF': 'Centraal Afrikaanse Republiek',
  'icr.country.CG': 'Congo',
  'icr.country.CH': 'Suisse',
  'icr.country.CI': "Côte d'Ivoire",
  'icr.country.CK': 'Îles Cook',
  'icr.country.CL': 'Chili',
  'icr.country.CM': 'Cameroun',
  'icr.country.CN': 'Chine',
  'icr.country.CO': 'Colombie',
  'icr.country.CR': 'Costa Rica',
  'icr.country.CU': 'Cuba',
  'icr.country.CV': 'Cap-Vert',
  'icr.country.CW': 'Curaçao',
  'icr.country.CX': 'Île Christmas',
  'icr.country.CY': 'Chypre',
  'icr.country.CZ': 'République tchèque',
  'icr.country.DE': 'Allemagne',
  'icr.country.DJ': 'Djibouti',
  'icr.country.DK': 'Danemark',
  'icr.country.DM': 'Dominique',
  'icr.country.DO': 'République Dominicaine',
  'icr.country.DZ': 'Algérie',
  'icr.country.EC': 'Équateur',
  'icr.country.EE': 'Estonie',
  'icr.country.EG': 'Égypte',
  'icr.country.EH': 'Sahara occidental',
  'icr.country.ER': 'Érythrée',
  'icr.country.ES': 'Espagne',
  'icr.country.ET': 'Éthiopie',
  'icr.country.FI': 'Finlande',
  'icr.country.FJ': 'Fidji',
  'icr.country.FK': 'Îles Malouines',
  'icr.country.FM': 'Micronésie, États fédérés de',
  'icr.country.FO': 'Îles Féroé',
  'icr.country.FR': 'France',
  'icr.country.GA': 'Gabon',
  'icr.country.GB': 'Royaume-Uni',
  'icr.country.GD': 'Grenade',
  'icr.country.GE': 'Géorgie',
  'icr.country.GF': 'Guyane Française',
  'icr.country.GG': 'Guernesey',
  'icr.country.GH': 'Ghana',
  'icr.country.GI': 'Gibraltar',
  'icr.country.GL': 'Groenland',
  'icr.country.GM': 'Gambie',
  'icr.country.GN': 'Guinée',
  'icr.country.GP': 'Guadeloupe',
  'icr.country.GQ': 'Guinée équatoriale',
  'icr.country.GR': 'Grèce',
  'icr.country.GS': 'South Georgia and the South Sandwich Islands',
  'icr.country.GT': 'Guatemala',
  'icr.country.GU': 'Guam',
  'icr.country.GW': 'Guinée-Bissau',
  'icr.country.GY': 'Guyana',
  'icr.country.HK': 'Hong Kong',
  'icr.country.HM': 'Heard and Mcdonald Islands',
  'icr.country.HN': 'Honduras',
  'icr.country.HR': 'Croatie',
  'icr.country.HT': 'Haïti',
  'icr.country.HU': 'Hongrie',
  'icr.country.ID': 'Indonésie',
  'icr.country.IE': 'Irlande',
  'icr.country.IL': 'Israël',
  'icr.country.IM': 'Île de Man',
  'icr.country.IN': 'Inde',
  'icr.country.IO': "Territoire britannique de l'océan Indien",
  'icr.country.IQ': 'Irak',
  'icr.country.IR': 'Iran',
  'icr.country.IS': 'Islande',
  'icr.country.IT': 'Italie',
  'icr.country.JE': 'Jersey',
  'icr.country.JM': 'Jamaïque',
  'icr.country.JO': 'Jordanie',
  'icr.country.JP': 'Japon',
  'icr.country.KE': 'Kenya',
  'icr.country.KG': 'Kirghizistan',
  'icr.country.KH': 'Cambodge',
  'icr.country.KI': 'Kiribati',
  'icr.country.KM': 'Comores',
  'icr.country.KN': 'Saint-Kitts-et-Nevis',
  'icr.country.KP': 'Korea (North)',
  'icr.country.KR': 'Korea (South)',
  'icr.country.KW': 'Koweït',
  'icr.country.KY': 'Îles Caïmans',
  'icr.country.KZ': 'Kazakhstan',
  'icr.country.LA': 'Laos',
  'icr.country.LB': 'Liban',
  'icr.country.LC': 'Sainte-Lucie',
  'icr.country.LI': 'Liechtenstein',
  'icr.country.LK': 'Sri Lanka',
  'icr.country.LR': 'Libéria',
  'icr.country.LS': 'Lesotho',
  'icr.country.LT': 'Lituanie',
  'icr.country.LU': 'Luxembourg',
  'icr.country.LV': 'Lettonie',
  'icr.country.LY': 'Libye',
  'icr.country.MA': 'Maroc',
  'icr.country.MC': 'Monaco',
  'icr.country.MD': 'Moldavie',
  'icr.country.ME': 'Monténégro',
  'icr.country.MF': 'Saint-Martin (French part)',
  'icr.country.MG': 'Madagascar',
  'icr.country.MH': 'Îles Marshall',
  'icr.country.MK': 'Macédoine du Nord',
  'icr.country.ML': 'Mali',
  'icr.country.MM': 'Myanmar',
  'icr.country.MN': 'Mongolie',
  'icr.country.MO': 'Macau',
  'icr.country.MP': 'Mariannes du Nord',
  'icr.country.MQ': 'Martinique',
  'icr.country.MR': 'Mauritanie',
  'icr.country.MS': 'Montserrat',
  'icr.country.MT': 'Malte',
  'icr.country.MU': 'Maurice',
  'icr.country.MV': 'Maldives',
  'icr.country.MW': 'Malawi',
  'icr.country.MX': 'Mexique',
  'icr.country.MY': 'Malaisie',
  'icr.country.MZ': 'Mozambique',
  'icr.country.NA': 'Namibie',
  'icr.country.NC': 'Nouvelle-Calédonie',
  'icr.country.NE': 'Niger',
  'icr.country.NF': 'Norfolk Island',
  'icr.country.NG': 'Nigeria',
  'icr.country.NI': 'Nicaragua',
  'icr.country.NL': 'Pays-Bas',
  'icr.country.NO': 'Norvège',
  'icr.country.NP': 'Népal',
  'icr.country.NR': 'Nauru',
  'icr.country.NU': 'Niue',
  'icr.country.NZ': 'Nouvelle-Zélande',
  'icr.country.OM': 'Oman',
  'icr.country.PA': 'Panama',
  'icr.country.PE': 'Pérou',
  'icr.country.PF': 'Polynésie française',
  'icr.country.PG': 'Papouasie-Nouvelle-Guinée',
  'icr.country.PH': 'Philippines',
  'icr.country.PK': 'Pakistan',
  'icr.country.PL': 'Pologne',
  'icr.country.PM': 'Saint Pierre and Miquelon',
  'icr.country.PN': 'Pitcairn',
  'icr.country.PR': 'Puerto Rico',
  'icr.country.PS': 'Palestine',
  'icr.country.PT': 'Portugal',
  'icr.country.PW': 'Palau',
  'icr.country.PY': 'Paraguay',
  'icr.country.QA': 'Qatar',
  'icr.country.RE': 'Réunion',
  'icr.country.RO': 'Roumanie',
  'icr.country.RS': 'Serbie',
  'icr.country.RU': 'Russie',
  'icr.country.RW': 'Rwanda',
  'icr.country.SA': 'Arabie saoudite',
  'icr.country.SB': 'Îles Salomon',
  'icr.country.SC': 'Seychelles',
  'icr.country.SD': 'Soudan',
  'icr.country.SE': 'Suède',
  'icr.country.SG': 'Singapour',
  'icr.country.SH': 'Saint Helena',
  'icr.country.SI': 'Slovénie',
  'icr.country.SJ': 'Svalbard and Jan Mayen Islands',
  'icr.country.SK': 'Slovaquie',
  'icr.country.SL': 'Sierra Leone',
  'icr.country.SM': 'San Marino',
  'icr.country.SN': 'Sénégal',
  'icr.country.SO': 'Somalie',
  'icr.country.SR': 'Suriname',
  'icr.country.SS': 'Soudan du Sud',
  'icr.country.ST': 'Sao Tome and Principe',
  'icr.country.SV': 'El Salvador',
  'icr.country.SY': 'Syrie',
  'icr.country.SZ': 'Eswatini',
  'icr.country.TC': 'Îles Turks et Caicos',
  'icr.country.TD': 'Tchad',
  'icr.country.TF': 'Terres australes françaises',
  'icr.country.TG': 'Togo',
  'icr.country.TH': 'Thaïlande',
  'icr.country.TJ': 'Tadjikistan',
  'icr.country.TK': 'Tokelau',
  'icr.country.TL': 'Timor-Leste',
  'icr.country.TM': 'Turkménistan',
  'icr.country.TN': 'Tunisie',
  'icr.country.TO': 'Tonga',
  'icr.country.TR': 'Turquie',
  'icr.country.TT': 'Trinité-et-Tobago',
  'icr.country.TV': 'Tuvalu',
  'icr.country.TW': 'Taïwan',
  'icr.country.TZ': 'Tanzanie',
  'icr.country.UA': 'Ukraine',
  'icr.country.UG': 'Ouganda',
  'icr.country.UM': 'Îles mineures éloignées des États-Unis',
  'icr.country.US': 'États-Unis',
  'icr.country.UY': 'Uruguay',
  'icr.country.UZ': 'Ouzbékistan',
  'icr.country.VA': 'Holy See (Vatican City State)',
  'icr.country.VC': 'Saint-Vincent-et-les-Grenadines',
  'icr.country.VE': 'Venezuela',
  'icr.country.VG': 'Îles Vierges britanniques',
  'icr.country.VI': 'Îles Vierges américaines',
  'icr.country.VN': 'Vietnam',
  'icr.country.VU': 'Vanuatu',
  'icr.country.WF': 'Wallis and Futuna Islands',
  'icr.country.WS': 'Samoa',
  'icr.country.YE': 'Yémen',
  'icr.country.YT': 'Mayotte',
  'icr.country.ZA': 'Afrique du Sud',
  'icr.country.ZM': 'Zambie',
  'icr.country.ZW': 'Zimbabwe',
  'icr.coutnry.GQ': 'Guinée équatoriale',
  'icr.error.search': "Une erreur s'est produite",
  'icr.error.search.contacts':
    'Merci de réessayer plus tard, ou bien contactez notre équipe du Service Clients à l\'adresse suivante : <a href="mailto:{country, select, gb {customerservices@graydon.co.uk} be {support@graydon.be} other {service@graydon.nl}}">{country, select, gb {customerservices@graydon.co.uk} be {support@graydon.be} other {service@graydon.nl}}</a> ou bien par téléphone au {country, select, gb {+44&nbsp;(0)20&nbsp;8515&nbsp;1400} be {+32&nbsp;(03)&nbsp;280&nbsp;8880} other {+31&nbsp;(020)&nbsp;567&nbsp;9501}}.',
  'icr.error.search.text':
    'Le service est malheureusement indisponible et la recherche ne peut être effectuée.',
  'icr.investgated.report.additionalEmails.label': 'Envoyer une notification supplémentaire à',
  'icr.investgated.report.additionalEmails.placeholder': 'email@your-domain.com',
  'icr.investgated.report.additionalEmails.recipients.none':
    "Une notification sera seulement envoyée à l'adresse e-mail du compte",
  'icr.investgated.report.additionalEmails.tooltip':
    "Une notification sera quand même envoyée à l'adresse e-mail du compte dès que le rapport demandé est disponible. Fournissez des adresses e-mail supplémentaires pour envoyer des notifications supplémentaires.",
  'icr.investgated.report.address.label': 'Adresse',
  'icr.investgated.report.address.placeholder':
    "Veuillez entrer l'adresse complète incluant la ville, le code postal, le numéro et le nom de la rue, l'immeuble, etc.",
  'icr.investgated.report.address.tooltip':
    "Indiquez ici l'adresse complète incluant la ville, le numéro et la rue, l'immeuble, etc.",
  'icr.investgated.report.address.validation.message':
    'Veuillez entrer une adresse détaillée et valide',
  'icr.investgated.report.building.label': 'bâtiment',
  'icr.investgated.report.building.placeholder': 'Entrez le numéro du bâtiment, etc.',
  'icr.investgated.report.building.tooltip': 'Entrez le numéro du bâtiment, etc.',
  'icr.investgated.report.building.validation.message': 'Entrez un numéro de bâtiment valide',
  'icr.investgated.report.city.label': 'Ville',
  'icr.investgated.report.city.placeholder': 'Entrez le nom de la ville',
  'icr.investgated.report.city.validation.message': 'Entrez un nom de ville valide',
  'icr.investgated.report.clientEmail.label': 'Email',
  'icr.investgated.report.clientEmail.placeholder': "Entrer l'adresse e-mail",
  'icr.investgated.report.clientEmail.tooltip': "Entrer l'adresse e-mail",
  'icr.investgated.report.clintIdentification.sap-id': 'SAP ID',
  'icr.investgated.report.clintIdentification.validate': 'Valider',
  'icr.investgated.report.comments.label': 'Remarques',
  'icr.investgated.report.comments.maxLengthMessage': 'Caractères restants',
  'icr.investgated.report.comments.tooltip':
    'Veuillez indiquer ici toute autre information et/ou détails qui pourraient aider notre équipes ou vos commentaires/questions concernant le rapport dont vous faites la demande.',
  'icr.investgated.report.companyName.label': 'Nom de la société',
  'icr.investgated.report.companyName.placeholder': 'Entrez le nom de la société',
  'icr.investgated.report.companyName.tooltip':
    'Notez ici le nom de la société pour laquelle pour vous demandez un rapport.',
  'icr.investgated.report.companyNameLocal.label': 'Nom de la société dans la langue locale',
  'icr.investgated.report.companyNameLocal.placeholder':
    'Entrez le nom dans la langue locale (si différent de la mention ci-dessus)',
  'icr.investgated.report.companyNameLocal.tooltip':
    'Indiquez ici le nom de la société dans la langue locale (si différent de celui indiqué ci-dessus ou dans un autre alphabet).',
  'icr.investgated.report.companyRegNumber.label': "Numéro d'entreprise",
  'icr.investgated.report.companyRegNumber.placeholder': "Entrez le numéro d'immatriculation",
  'icr.investgated.report.companyRegNumber.tooltip':
    "Notez ici le numéro d'immatriculation de la société.",
  'icr.investgated.report.companyRegNumber.validation.message':
    "Entrez un numéro d'enregistrement valide",
  'icr.investgated.report.consumer-name.label': 'Nom de la société cliente',
  'icr.investgated.report.country.label': 'Pays',
  'icr.investgated.report.country.validation.message': 'Merci de sélectionner un pays',
  'icr.investgated.report.creditRecommendation.label': 'Crédit conseillé',
  'icr.investgated.report.creditRecommendation.placeholder':
    'Indiquez le montant de crédit que vous souhaitez verifier (le cas échéant)',
  'icr.investgated.report.currency.label': 'Devise',
  'icr.investgated.report.currency.tooltip':
    'Dans le menu déroulant, veuillez sélectionner la devise pour le crédit conseillé requis.',
  'icr.investgated.report.discloseEnquirerName.label':
    "Oui, j'autorise Graydon à citer le nom du demandeur dans le cadre de cette enquête",
  'icr.investgated.report.discloseEnquirerName.tooltip':
    "Si vous acceptez de divulguer votre nom dans le cadre de l'investigation, merci de cocher cette case.",
  'icr.investgated.report.email.label': 'Adresse e-mail',
  'icr.investgated.report.enquirerName.label': 'Nom du requérant',
  'icr.investgated.report.form.info.2':
    "Merci d'indiquer toute autre information qui peut nous aider à identifier correctement la société",
  'icr.investgated.report.form.section.header.0': 'Identification du client',
  'icr.investgated.report.form.section.header.1': 'Information requise pour la société',
  'icr.investgated.report.form.section.header.2': 'Information complémentaire sur la société',
  'icr.investgated.report.form.section.header.3': 'Information sur le rapport',
  'icr.investgated.report.includeEnquirerName.false': 'Non',
  'icr.investgated.report.includeEnquirerName.label': 'Inclure le nom du demandeur',
  'icr.investgated.report.includeEnquirerName.true': 'Oui',
  'icr.investgated.report.max-length.validation':
    'Ce champ peut contenir un maximum de {max} caractères',
  'icr.investgated.report.name.label': 'Nom complet du contact',
  'icr.investgated.report.otherIdentifiers.addAnother': "Ajoutez un autre numéro d'identification",
  'icr.investgated.report.otherIdentifiers.tooltip':
    "Si vous disposez d'autres numéros d'identification, vous pouvez les ajouter ci dessous.\nMerci de préciser dans le cadre de droite de quel type d'identifiant il s'agit.",
  'icr.investgated.report.otherIdentifiers.validation':
    'Veuillez entrer un numéro valide et une description ou supprimer les champs supplémentaires',
  'icr.investgated.report.otherIdentifyingDescription.label':
    "Description du numéro d'identification",
  'icr.investgated.report.otherIdentifyingDescription.label-short': 'Description',
  'icr.investgated.report.otherIdentifyingNumber.label': "Autre numéro d'identification unique",
  'icr.investgated.report.otherIdentifyingNumber.label-short':
    "Si disponible, merci d'indiquer un numéro d'identification supplémentaire",
  'icr.investgated.report.otherInfoOrComments.label':
    'Indiquez ici toutes autres informations utiles',
  'icr.investgated.report.postalCode.label': 'Code postal',
  'icr.investgated.report.postalCode.placeholder': 'Entrez le code postal',
  'icr.investgated.report.postalCode.validation.message': 'Entrez un code postal valide',
  'icr.investgated.report.range.validation':
    'Ce champ nécessite un minimum de {min} caractère et un maximum de {max} caractères',
  'icr.investgated.report.reference.label': 'Référence',
  'icr.investgated.report.reference.placeholder':
    'Notez ici la référence à indiquer pour ce rapport (ex: votre numéro de commande ou numéro de client)',
  'icr.investgated.report.reference.tooltip':
    'Indiquez ici votre propre référence pour cette demande (nom du client, nom du fichier, etc.)',
  'icr.investgated.report.reference.validation.message':
    'Entrez un numéro de référence valide (max 50 caractères)',
  'icr.investgated.report.sap-id.label': 'Identifiant SAP',
  'icr.investgated.report.speedOfService.label': 'Rapidité du service',
  'icr.investgated.report.speedOfService.tooltip':
    'Merci de sélectionner la rapidité de service parmi les options ci-dessous. \nVeuillez noter que la rapidité de service dépendra du pays que vous aurez sélectionné. Pour plus de détails, merci de vous référer à votre contrat Graydon.',
  'icr.investgated.report.speedOfService.validation.message':
    "Une erreur s'est produite. Merci de réessayer.",
  'icr.investgated.report.speedOfService.validationMessage':
    'Merci de sélectionner la rapidité de service requise.',
  'icr.investgated.report.street.label': 'Rue, numéro de maison, suffix',
  'icr.investgated.report.street.placeholder': 'Entrez le nom de la rue',
  'icr.investgated.report.street.tooltip': 'Entrez le nom de la rue',
  'icr.investgated.report.street.validation.message': "S'il vous plaît entrer un nom de rue valide",
  'icr.investgated.report.submitting.message-bottom': 'Requête en cours de traitement',
  'icr.investgated.report.submitting.message-top':
    'Veuillez patientez pendant que nous transmettons votre requête à notre équipe d’investigations',
  'icr.investgated.report.telephone.addNumber': 'Ajoutez un autre numéro de téléphone',
  'icr.investgated.report.telephone.label': 'Entrer un numéro de téléphone',
  'icr.investgated.report.telephone.placeholder': 'Entrer un numéro de téléphone',
  'icr.investgated.report.vatNumber.label': 'Numéro de TVA',
  'icr.investgated.report.vatNumber.placeholder': 'Entrez le numéro de TVA',
  'icr.investgated.report.vatNumber.tooltip': 'Indiquez le numéro de TVA pour cette société.',
  'icr.investigated.report.button.next-step': 'Étape suivante',
  'icr.investigated.report.button.send-request': 'Validez votre commande',
  'icr.investigated.report.clientEmail.empty.message':
    'Entrez l’adresse e-mail ici pour la vérifier',
  'icr.investigated.report.clientEmail.validation.message': 'Adresse e-mail invalide ou inconnue',
  'icr.investigated.report.companyName.validation.message':
    'Veuillez entrer un nom de société valide',
  'icr.investigated.report.form.deliveryTime': 'Livraison en {daysFrom} à {daysTo} jours ouvrables',
  'icr.investigated.report.form.deliveryTime-short': 'de ({daysFrom} à {daysTo} jours ouvrables)',
  'icr.investigated.report.form.info.1':
    'Veuillez remplir les informations suivantes à propos de la société sur laquelle vous voudriez que nous enquêtions. Ces informations nous aideront à identifier la société et à vous fournir des résultats corrects.',
  'icr.investigated.report.form.previousStep': 'Etape précédente',
  'icr.investigated.report.page.title': 'Commander une Nouvelle Investigation',
  'icr.investigated.report.step.0.name': 'Identification du client',
  'icr.investigated.report.step.0.title': 'Étape 0',
  'icr.investigated.report.step.1.name': 'Détails de la société',
  'icr.investigated.report.step.1.title': 'Étape 1',
  'icr.investigated.report.step.2.name': 'Détails de la commande',
  'icr.investigated.report.step.2.title': 'Étape 2',
  'icr.investigated.report.step.3.name': 'Passez la commande pour une investigation',
  'icr.investigated.report.step.3.title': 'Étape 3',
  'icr.investigated.report.step.title': 'Étape {step}',
  'icr.link.download-old-report': "Télécharger l'ancien rapport",
  'icr.order.investigated.report.priceList':
    'Vérifiez [ici](https://graydon.showpad.com/share/X3yhO90D06zihnTPXSKGx) les tarifs pour nos rapports internationaux',
  'icr.order.investigated.report.toast.body.error':
    "Malheureusement, une erreur s'est produite et nous ne pouvons compléter votre commande. Merci de bien vouloir renvoyer votre demande.",
  'icr.order.investigated.report.toast.body.success':
    "Votre demande a été envoyée. Vous pouvez consulter l'état de votre commande dans les archives.",
  'icr.order.investigated.report.toast.title.error': "Une erreur s'est produite",
  'icr.order.investigated.report.toast.title.success': 'Demande envoyée!',
  'icr.report.database.immediate': 'Rapport en ligne - Immediate',
  'icr.report.database.immediate-with-update': 'Rapport en ligne - Immediate with update',
  'icr.report.investigated': 'Nouvelle Investigation',
  'icr.search-page.archive-btn': 'Archives',
  'icr.search-page.choose-country': 'Choisissez un pays',
  'icr.search-page.choose-country.tooltip':
    'Selectionnez le pays pour lequel vous souhaitez lancer une recherche. Via le menu déroulant, vous verrrez si un rapport est immédiatement disponible dans la base de données. Sinon, vous pourrez demander une nouvelle investigation (livraison habituellement sous 3 à 5 jours ouvrés) via le bouton en haut à droite.',
  'icr.search-page.choose-state': 'Choisissez un état',
  'icr.search-page.choose-state.tooltip': 'Choisissez ou tapez un état',
  'icr.search-page.client-session.description':
    'Currently you are performing actions in the name of “**{firstName} {lastName}**” and SAP ID “**{consumerId}**”',
  'icr.search-page.client-session.modal.description':
    'You are about to terminate the session of "**{firstName} {lastName}**" with SAP ID "**{consumerId}**". ',
  'icr.search-page.client-session.modal.title': 'Stop session',
  'icr.search-page.client-session.start': 'Démarrer une session client',
  'icr.search-page.client-session.stop': 'Arrêter une session client',
  'icr.search-page.client-session.stop-session': 'Stop session',
  'icr.search-page.client-session.title': 'Session client ',
  'icr.search-page.client-session.tooltip':
    "An admin user can perform actions on behalf of a customer, like requesting a fresh investigation or a report. Requested reports will be available in the customer's archive.",
  'icr.search-page.client-validation.description':
    "Saisissez l'e-mail du client à valider afin d'effectuer une action depuis son compte.",
  'icr.search-page.client-validation.email-empty.message':
    'Entrez l’adresse e-mail ici pour la vérifier',
  'icr.search-page.client-validation.email-validation.message':
    'Adresse e-mail invalide ou inconnue',
  'icr.search-page.client-validation.email.label': 'E-mail',
  'icr.search-page.client-validation.email.text': "Entrer l'adresse e-mail",
  'icr.search-page.client-validation.sap-id': 'SAP ID',
  'icr.search-page.client-validation.sapid-empty.message': 'Entrez l’SAP ID ici pour la vérifier',
  'icr.search-page.client-validation.sapid-validation.message':
    'Adresse SAP ID invalide ou inconnue',
  'icr.search-page.client-validation.sapid.label': 'SAP ID',
  'icr.search-page.client-validation.sapid.text': "Entrer l'SAP ID",
  'icr.search-page.client-validation.start-session': 'Démarrez la session',
  'icr.search-page.client-validation.title': 'Identification du client',
  'icr.search-page.client-validation.validate': 'Validez',
  'icr.search-page.description':
    "Chaque pays a ces propres méthodes de recherche. Pour une meilleure expérience lors de vos recherches, nous avons pré-sélectionné les meilleures d'entre-elles pour chaque pays. Vous pouvez néanmoins changer la méthode de recherche selon les informations dont vous disposez.",
  'icr.search-page.domestic-country.link': "Allez dans la section Données de l'entreprise",
  'icr.search-page.domestic-country.message':
    '{country} fait partie de votre souscription locale. Pour lancer une recherche allez simplement dans la section Rapports de Crédit.',
  'icr.search-page.fresh-investigation-btn': 'Nouvelle Investigation',
  'icr.search-page.fresh-investigation-button.no-paygo':
    'Les nouvelles investigations ne font parties de votre forfait. Merci de contacter votre chargé(e) de clientèle pour plus de détails ou pour mettre à jour votre souscription.',
  'icr.search-page.header': 'Rechercher une société',
  'icr.search-page.non-domestic-country.message.BE':
    "Les rapports belges sont disponibles via la section 'Company Data'.",
  'icr.search-page.non-domestic-country.message.GB':
    "Les rapports britanniques sont disponibles via la section 'Company Data'.",
  'icr.search-page.non-domestic-country.message.NL':
    "Les rapports néerlandais sont disponibles via la section 'Company Data'",
  'icr.search-page.service-level-investigation.domestic.message':
    'Ce pays ne figure pas actuellement dans notre base de données.<br><br>\nSi vous avez des questions ou avez besoin d’aide, veuillez contacter notre équipe du Service Clients à l\'adresse suivante: <a href="mailto:{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}">{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}</a> ou par téléphone au {country, select, gb {+44&nbsp;(0)20&nbsp;8515&nbsp;1400} nl {+31&nbsp;(020)&nbsp;567&nbsp;9501} other {+32&nbsp;(03)&nbsp;280&nbsp;88&nbsp;80}}.',
  'icr.search-page.service-level-investigation.link': 'Commandez une nouvelle investigation',
  'icr.search-page.service-level-investigation.message':
    'Ce pays ne figure pas actuellement dans notre base de données. Vous pouvez néanmoins demander des informations sur une entreprise spécifique de ce pays, simplement en remplissant le formulaire Nouvelle investigation.',
  'icr.search-page.service-level-investigation.no-paygo':
    'Les rapports pour ce pays ne sont disponibles que sous la forme d’une nouvelle investigation. Merci de contacter votre chargé(e) de clientèle pour plus de détails ou pour mettre à jour votre souscription.',
  'icr.search-page.switch-to-client': 'Switch to client account',
  'icr.search-page.switch-to-personal': 'Switch to personal account',
  'icr.search-results.button.check-availability': 'Demandez un rapport',
  'icr.search-results.button.view-report': 'Consulter le rapport',
  'icr.search-results.latest-report-date': 'Date of latest report',
  'icr.search-results.modal.add-client-note': 'Ajoutez une référence',
  'icr.search-results.modal.address': 'Adresse',
  'icr.search-results.modal.availability-error':
    'Ce rapport n’est pas disponible en ligne. Veuillez contacter votre chargé(e) de clientèle afin d’actualiser votre souscription pour pouvoir demander une nouvelle investigation.',
  'icr.search-results.modal.availability-error-fresh':
    'Ce rapport n’est pas disponible en ligne. Vous pouvez demander une nouvelle investigation en cliquant sur le bouton ‘NOUVELLE INVESTIGATION’',
  'icr.search-results.modal.availability-immediate':
    'Vous pouvez demander un rapport en ligne mis à jour en cliquant sur le bouton ‘DEMANDER UN RAPPORT’.',
  'icr.search-results.modal.availability-immediate-fresh':
    'Pour de nombreuses entreprises, un rapport est disponible immédiatement. Vous pouvez également demander une nouvelle investigation et nous rechercherons et fournirons un rapport pour une entreprise.',
  'icr.search-results.modal.availability-immediate-update':
    'Le fournisseur indique que ce rapport n’est plus à jour. Vous pouvez néanmoins le demander maintenant et nous vous ferons parvenir la mise à jour lorsqu‘elle sera disponible.',
  'icr.search-results.modal.availability-immediate-update-fresh':
    "Le fournisseur indique que ce rapport n’est plus à jour. Vous pouvez néanmoins le demander maintenant et nous vous ferons parvenir la mise à jour lorsqu‘elle sera disponible.\nCependant, en fonction de l'urgence, vous pouvez demander une nouvelle investigation en cliquant sur le bouton ‘NOUVELLE INVESTIGATION’.",
  'icr.search-results.modal.availability.outdated-report-body':
    'La mise à jour de ce rapport sera bientôt disponible. Suivez les progrès dans la section Archives.',
  'icr.search-results.modal.availability.outdated-report-title':
    'Votre rapport mis à jour est en train d’être produit.',
  'icr.search-results.modal.clientNote.label': 'Ajoutez une référence',
  'icr.search-results.modal.clientNote.placeholder': 'Ajouter une référence à votre rapport',
  'icr.search-results.modal.clientNote.tooltip':
    'Vous pouvez ajouter ici une note servant de référence pour votre rapport. Cette référence sera visible dans votre archive.',
  'icr.search-results.modal.downloading.heading':
    'Merci de bien vouloir patienter pendant que nous prepare le download',
  'icr.search-results.modal.downloading.step0': 'envoyons la demande',
  'icr.search-results.modal.downloading.step1': 'rassemblons les données',
  'icr.search-results.modal.downloading.step2': 'regroupons les informations',
  'icr.search-results.modal.downloading.step3': 'préparons le rapport',
  'icr.search-results.modal.downloading.step4': 'sauvegardons le rapport',
  'icr.search-results.modal.fresh-investigation-button': 'Nouvelle Investigation',
  'icr.search-results.modal.identifier': 'Identifiant',
  'icr.search-results.modal.not-available':
    "Pour cette entreprises, un rapport n'est pas disponible immédiatement. Veuillez demander une nouvelle investigation et nous rechercherons et fournirons un rapport pour l'entreprise.",
  'icr.search-results.modal.request-report-button': 'Demandez un rapport',
  'icr.search-results.modal.tooltip':
    'Pour de nombreuses entreprises, un rapport est disponible immédiatement. Vous pouvez également demander une nouvelle investigation et nous rechercherons et fournirons un rapport pour une entreprise.',
  'icr.search.advanced.city.placeholder': 'Ville',
  'icr.search.advanced.post-code.placeholder': 'Code postal',
  'icr.search.advanced.toggle-link': 'Recherche avancée',
  'icr.search.company.searchButton': 'Recherchez une entreprise',
  'icr.search.search-bar.placeholder': 'Entrez les informations relatives à la société',
  'icr.search.search-method.all': 'Combinée',
  'icr.search.search-method.identifier': 'Identifiant',
  'icr.search.search-method.KEYWORD': 'Mot-clef',
  'icr.search.search-method.keyword': 'Mot-clef',
  'icr.search.search-method.NAME': 'Nom',
  'icr.search.search-method.name': 'Nom',
  'icr.search.search-method.NAME_AND_CITY': 'Combinée',
  'icr.search.search-method.REGISTRATION_ID': 'Identifiant',
  'icr.search.search-methods-label': 'Méthode de recherche',
  'icr.state-select.placeholder': 'Choisissez un état',
  importCompanies: 'Importer entreprises',
  'importCompanies.steps.addToList': 'Étape 4/4: Add companies to list(s)',
  'importCompanies.steps.downloadEnrichedFile': 'Étape 4/4: Télécharger fichier actualisé',
  'importCompanies.steps.mapping': 'Étape 2/4: Associez vos colonnes aux champs Graydon',
  'importCompanies.steps.matchingInProgress': 'Étape 3/4: Matching en cours',
  'importCompanies.steps.upload': 'Étape 1/4: Télécharger',
  'info.404.cannot-be-found':
    'La page que vous recherchez est introuvable. Veuillez vérifier que vous avez tapé correctement l’adresse URL.',
  'info.404.does-not-exist':
    "Ceci n'est pas la page recherchée ou cette page n'existe pas. Mais alors, que faut-il faire?",
  'info.404.go-back': 'Retour',
  'info.404.go-home': 'Retour à la page initiale',
  'info.404.go-to-graydon-insights':
    'Veuillez SVP vous rendre sur la page d’accueil de Graydon Insights.',
  'info.404.oops': 'Oops',
  'info.404.page-not-found': 'La page est introuvable.',
  'info.associated.no-data': 'Pas de données disponibles',
  'info.no-access.title': "Pas d'accès",
  'info.no-data': 'Pas de données disponibles',
  'int-reports.country-search-in': 'Recherchez dans',
  'int-reports.country.al': 'Albanie',
  'int-reports.country.at': 'Autriche',
  'int-reports.country.ba': 'Bosnie Herzegovine',
  'int-reports.country.be': 'Belgique',
  'int-reports.country.bg': 'Bulgarie',
  'int-reports.country.ch': 'Suisse',
  'int-reports.country.cz': 'République Tchèque',
  'int-reports.country.de': 'Allemagne',
  'int-reports.country.ee': 'Estonie',
  'int-reports.country.es': 'Espagne',
  'int-reports.country.fr': 'France',
  'int-reports.country.gb': 'Royaume uni',
  'int-reports.country.hr': 'Croatie',
  'int-reports.country.hu': 'Hongrie',
  'int-reports.country.ie': 'Irlande',
  'int-reports.country.it': 'Italie',
  'int-reports.country.kv': 'Kosovo',
  'int-reports.country.lt': 'Lithuanie',
  'int-reports.country.lu': 'Luxembourg',
  'int-reports.country.lv': 'Letonie',
  'int-reports.country.md': 'Moldavie',
  'int-reports.country.me': 'Montenegro',
  'int-reports.country.mk': 'Macedoine',
  'int-reports.country.nl': 'Pays-Bas',
  'int-reports.country.pl': 'Pologne',
  'int-reports.country.pt': 'Portugal',
  'int-reports.country.ro': 'Roumanie',
  'int-reports.country.rs': 'Serbie',
  'int-reports.country.ru': 'Russie',
  'int-reports.country.se': 'Suède',
  'int-reports.country.si': 'Slovénie',
  'int-reports.country.sk': 'Slovaquie',
  'int-reports.country.ua': 'Ukraine',
  'int-reports.country.us': "Etas-Unis d'Amérique",
  'intl-report.autocomplete.no-results': 'Pas de correspondance pour "{keyword}"',
  'intl-report.category.otherFields': 'Autres champs',
  'intl-report.category.recommendedFields': 'Champs recommandés',
  'intl-report.category.requiredFields': 'Champs obligatoires',
  'intl-report.check.for.archived.download': 'Téléchargez ce rapport',
  'intl-report.check.for.archived.no-reports': 'Aucun rapport archivé trouvé',
  'intl-report.company.active': 'Actif',
  'intl-report.company.al.amountAdvisedCaption':
    'Le montant recommandé est à 60 jours, tous les fournisseurs, partant du principe que le débiteur a 5 créditeurs. La limite de crédit dans ce pays est de 2.500.000 €.',
  'intl-report.company.amountAdvisedCaption': 'La limite de crédit maximale de cette entreprise',
  'intl-report.company.at.amountAdvisedCaption':
    'Le montant recommandé est une limite de crédit mensuelle par fournisseur. La limite de crédit dans ce pays est de 1.000.000 €.',
  'intl-report.company.ba.amountAdvisedCaption':
    'Le montant recommandé est à 60 jours, tous les fournisseurs, partant du principe que le débiteur a 5 créditeurs. La limite de crédit dans ce pays est de 2.500.000 €.',
  'intl-report.company.be.amountAdvisedCaption':
    'Le montant recommandé est une exposition maximale du crédit sur 12 mois, tous les fournisseurs. Il n’y a pas de limite de crédit dans ce pays.',
  'intl-report.company.bg.amountAdvisedCaption':
    'Le montant recommandé est à 60 jours, tous les fournisseurs, partant du principe que le débiteur a 5 créditeurs. La limite de crédit dans ce pays est de 2.500.000 €.',
  'intl-report.company.ch.amountAdvisedCaption':
    'Le montant recommandé est une exposition maximale du crédit par fournisseur à tout moment. La limite de crédit dans ce pays est de 1.000.000 CHF.',
  'intl-report.company.check': 'Voir le rapport pour cette entreprise',
  'intl-report.company.commonRating': 'Notation générale',
  'intl-report.company.commonRiskClassCaption': 'Le risque de non-paiement de cette entreprise',
  'intl-report.company.commonRiskClassDescription':
    'Il s’agit d’une norme européenne pour déterminer le risque de crédit d’une entreprise, exprimé en valeur sur une échelle de 1 à 5. Plus la valeur est élevée, plus le risque est faible. La valeur ‘9’ indique une calamité financière. Avec la valeur ‘8’, il n’est pas possible d’évaluer un risque éventuel.',
  'intl-report.company.creditRatings': 'Notations de crédit',
  'intl-report.company.creditRatings.title': 'Notation de crédit',
  'intl-report.company.cz.amountAdvisedCaption':
    'Le montant recommandé est une exposition maximale du crédit par fournisseur sur 12 mois. Si aucune donnée financière n’est disponible, la limite de crédit dans ce pays est de 39 millions CZK. Si des comptes sont disponibles, il n’y a pas de limite.',
  'intl-report.company.de.amountAdvisedCaption':
    'Le montant recommandé est une recommandation de crédit par transaction. Il n’y a pas de limite de crédit dans ce pays.',
  'intl-report.company.ee.amountAdvisedCaption':
    'Le montant recommandé est à 60 jours, tous les fournisseurs, partant du principe que le débiteur a 5 créditeurs. La limite de crédit dans ce pays est de 2.500.000 €.',
  'intl-report.company.error':
    'Erreur lors du chargement des données, veuillez réessayer plus tard',
  'intl-report.company.error.extended':
    'Cette information n’est actuellement pas disponible en ligne.<br /><br />Pour toute aide complémentaire, vous pouvez contacter notre équipe de Support Clients par <a href="mailto:{country, select, gb {customerservices@graydon.co.uk} be {support@graydon.be} other {service@graydon.nl}}">{country, select, gb {customerservices@graydon.co.uk} be {support@graydon.be} other {service@graydon.nl}}</a> ou par téléphone au {country, select, gb {+44 (020) 8515 1400} be {+32 (03) 280 88 80} other {+31 (020) 567 9501}}.',
  'intl-report.company.es.amountAdvisedCaption':
    'Le montant recommandé est une exposition maximale du crédit à tout moment. La limite de crédit dans ce pays est de 6.000.000 €.',
  'intl-report.company.fr.amountAdvisedCaption':
    'Le montant recommandé est une recommandation de crédit par transaction. La limite de crédit dans ce pays est de 300.000 €.',
  'intl-report.company.gb.amountAdvisedCaption':
    'Le montant recommandé est une limite de crédit mensuelle par fournisseur. La limite de crédit dans ce pays est de 1.125.000 £.',
  'intl-report.company.hr.amountAdvisedCaption':
    'Le montant recommandé est à 60 jours, tous les fournisseurs, partant du principe que le débiteur a 5 créditeurs. La limite de crédit dans ce pays est de 2.500.000 €.',
  'intl-report.company.hu.amountAdvisedCaption':
    'Le montant recommandé est à 60 jours, tous les fournisseurs, partant du principe que le débiteur a 5 créditeurs. La limite de crédit dans ce pays est de 2.500.000 €.',
  'intl-report.company.ie.amountAdvisedCaption':
    'Le montant recommandé est une exposition maximale du crédit par fournisseur à tout moment. La limite de crédit dans ce pays est de 7.500.000 €.',
  'intl-report.company.inDefault': 'Valeur par défaut',
  'intl-report.company.inDefaultOrInsolvent': 'En défaut ou insolvable',
  'intl-report.company.insolvent': 'Insolvable',
  'intl-report.company.it.amountAdvisedCaption':
    'Le montant recommandé est une recommandation de crédit par transaction. La limite de crédit dans ce pays est de 1.000.000 €.',
  'intl-report.company.localRating': 'Notation locale',
  'intl-report.company.localRatingCaption':
    'La probabilité de défaut de paiement de cette entreprise',
  'intl-report.company.lt.amountAdvisedCaption':
    'Le montant recommandé est à 60 jours, tous les fournisseurs, partant du principe que le débiteur a 5 créditeurs. La limite de crédit dans ce pays est de 2.500.000 €.',
  'intl-report.company.lu.amountAdvisedCaption':
    'Le montant recommandé est une recommandation de crédit par transaction. Il n’y a pas de limite de crédit dans ce pays.',
  'intl-report.company.lv.amountAdvisedCaption':
    'Le montant recommandé est à 60 jours, tous les fournisseurs, partant du principe que le débiteur a 5 créditeurs. La limite de crédit dans ce pays est de 2.500.000 €.',
  'intl-report.company.maxCreditLimit': 'Limite de crédit maximale',
  'intl-report.company.maxCreditLimitDescription':
    "C’est le montant le plus élevé attribué, conformément à la notation de crédit à court terme de Graydon. Le montant peut peuvent varier d’une région à l’autre de 500 euro à 6 millions d'euro.",
  'intl-report.company.maxCreditLimitNotAvailable': 'Indisponible',
  'intl-report.company.md,amountAdvisedCaption':
    'Le montant recommandé est à 60 jours, tous les fournisseurs, partant du principe que le débiteur a 5 créditeurs. La limite de crédit dans ce pays est de 2.500.000 €.',
  'intl-report.company.me.amountAdvisedCaption':
    'Le montant recommandé est à 60 jours, tous les fournisseurs, partant du principe que le débiteur a 5 créditeurs. La limite de crédit dans ce pays est de 2.500.000 €.',
  'intl-report.company.mk.amountAdvisedCaption':
    'Le montant recommandé est à 60 jours, tous les fournisseurs, partant du principe que le débiteur a 5 créditeurs. La limite de crédit dans ce pays est de 2.500.000 €.',
  'intl-report.company.nl.amountAdvisedCaption':
    'Le montant recommandé est une exposition maximale du crédit sur 12 mois par fournisseur. La limite de crédit dans ce pays est de 5.000.000 €.',
  'intl-report.company.noRating': 'Pas de notation disponible',
  'intl-report.company.paymentBehaviour': 'Comportement de paiement',
  'intl-report.company.paymentBehaviourCaption':
    'Cas de protêts, défauts de paiement et autres qualifications du comportement de paiement',
  'intl-report.company.paymentBehaviourDescription':
    'La discipline de paiement vous raconte comment une entreprise paie ses factures en ce moment. Des incidents de protestations, des cas de non-paiements, ou d’autres qualifications du comportement de paiement sont des indicateurs cruciaux au niveau de l’évolution des paiements, de la position de cash et des intentions de paiement.',
  'intl-report.company.paymentBehaviourUnknown': 'Indisponible',
  'intl-report.company.pl.amountAdvisedCaption':
    'Le montant recommandé est une exposition maximale du crédit sur 12 mois, tous les fournisseurs. Il n’y a pas de limite de crédit dans ce pays.',
  'intl-report.company.protestsAndNonPayments':
    '{ protestsAndNonPayments } Les protêts et/ou non-paiements ont été enregistrés',
  'intl-report.company.providerRiskClassDescription':
    'Ce standard variable détermine le risque de crédit d’une entreprise par pays. Ce risque se traduit dans différentes échelles. Contactez Graydon pour de plus amples informations sur cette notation.',
  'intl-report.company.pt.amountAdvisedCaption':
    'Le montant recommandé est une exposition maximale du crédit à tout moment. La limite de crédit dans ce pays est de 6.000.000 €.',
  'intl-report.company.rating.no-description': 'Pas de description',
  'intl-report.company.riskClasses': 'Classes de risque',
  'intl-report.company.ro.amountAdvisedCaption':
    'Le montant recommandé est à 60 jours, tous les fournisseurs, partant du principe que le débiteur a 5 créditeurs. La limite de crédit dans ce pays est de 2.500.000 €.',
  'intl-report.company.rs.amountAdvisedCaption':
    'Le montant recommandé est à 60 jours, tous les fournisseurs, partant du principe que le débiteur a 5 créditeurs. La limite de crédit dans ce pays est de 2.500.000 €.',
  'intl-report.company.ru.amountAdvisedCaption':
    'Le montant recommandé est à 60 jours, tous les fournisseurs, partant du principe que le débiteur a 5 créditeurs. La limite de crédit dans ce pays est de 2.500.000 €.',
  'intl-report.company.se.amountAdvisedCaption':
    'Le montant recommandé est une limite de crédit mensuelle, tous fournisseurs. La limite de crédit dans ce pays est de 10 millions SEK',
  'intl-report.company.sectorCode': 'Code secteur',
  'intl-report.company.si.amountAdvisedCaption':
    'Le montant recommandé est à 60 jours, tous les fournisseurs, partant du principe que le débiteur a 5 créditeurs. La limite de crédit dans ce pays est de 2.500.000 €.',
  'intl-report.company.sk.amountAdvisedCaption':
    'Le montant recommandé est à 60 jours, tous les fournisseurs, partant du principe que le débiteur a 5 créditeurs. La limite de crédit dans ce pays est de 2.500.000 €.',
  'intl-report.company.ua.amountAdvisedCaption':
    'Le montant recommandé est à 60 jours, tous les fournisseurs, partant du principe que le débiteur a 5 créditeurs. La limite de crédit dans ce pays est de 2.500.000 €.',
  'intl-report.company.us.amountAdvisedCaption':
    'Le montant recommandé est une exposition maximale du crédit par fournisseur à tout moment. La limite de crédit dans ce pays est de 1.000.000 $.',
  'intl-report.date': 'Date',
  'intl-report.download.pdf.full-report': 'Télécharger le rapport complet',
  'intl-report.download.pdf.summary': 'Télécharger le sommaire',
  'intl-report.financialSummary.profitLoss': 'Bénéfice Perte',
  'intl-report.financialSummary.revenue': "Chiffre d'affaires",
  'intl-report.financialSummary.summaryDate': 'Données financières de {data} ',
  'intl-report.financialSummary.totalEquity': 'Total des fonds propres',
  'intl-report.financialSummary.workingCapital': 'Fonds de roulement',
  'intl-report.managementPositions.authorization': 'Autorisation',
  'intl-report.managementPositions.birthDate': 'Date de naissance',
  'intl-report.managementPositions.companyName': 'Nom de la société',
  'intl-report.managementPositions.firstName': 'Prénom',
  'intl-report.managementPositions.fullName': 'Nom complet',
  'intl-report.managementPositions.initials': 'Initiales',
  'intl-report.managementPositions.lastName': 'Nom de famille',
  'intl-report.managementPositions.management': "Conseil d'administration",
  'intl-report.managementPositions.middleName': 'Second prénom',
  'intl-report.managementPositions.prefix': 'Préfixe',
  'intl-report.managementPositions.startingDate': 'Date de nomination',
  'intl-report.managementPositions.title': 'Titre',
  'intl-report.pdf.balance': 'Bilan',
  'intl-report.pdf.employee-data': 'Données du personnel',
  'intl-report.pdf.liabilityDeclarations': 'Déclarations de responsabilité',
  'intl-report.pdf.unknown.value': 'Inconnu',
  'intl-report.protestsAndNonPayments.amount': 'Montant',
  'intl-report.protestsAndNonPayments.creditor': 'Créditeur',
  'intl-report.protestsAndNonPayments.date': 'Date',
  'intl-report.protestsAndNonPayments.description': 'Description',
  'intl-report.protestsAndNonPayments.noEventsRegisterdForThisType':
    "Pas d'événements enregistrés pour ce type",
  'intl-report.protestsAndNonPayments.noEventsRegistered':
    'Aucun protêt et/ou non-paiement enregistré',
  'intl-report.protestsAndNonPayments.title': 'Paiements: Protêts & Non-paiements',
  'intl-report.protestsAndNonPayments.type': 'Type',
  'intl-report.search-hint.adress': 'Utilisez uniquement le nom de la rue',
  'intl-report.search-hint.builder': 'Uniquement les chiffres',
  'intl-report.search-hint.city': 'Utilisez le nom complet',
  'intl-report.search-hint.companyId': 'Le numéro unique dans la base de données Graydon',
  'intl-report.search-hint.companyRegisterNumber':
    "Dans la plupart des cas il s'agira du numéro de registre de commerce",
  'intl-report.search-hint.name': 'Utilisez le nom complet',
  'intl-report.search-hint.postCode': 'utilisez le code postal entier',
  'intl-report.search-hint.registeredName': 'Utilisez le nom complet',
  'intl-report.search-hint.state': 'utilisez le nom complet de la province',
  'intl-report.search-hint.street': 'Utilisez uniquement le nom de la rue',
  'intl-report.search-hint.tradingName': 'Le nom commercial de la société',
  'intl-report.search-hint.vat': 'Supprimez FR ainsi que les 2 premiers chiffres',
  'intl-report.search-hint.vatNumber': 'Supprimez FR ainsi que les 2 premiers chiffres',
  'intl-report.search-key.adress': 'Adresse',
  'intl-report.search-key.builder': 'Builder',
  'intl-report.search-key.city': 'Ville',
  'intl-report.search-key.companyId': "Le numéro d'identification de la société",
  'intl-report.search-key.companyRegisterNumber': "Numéro d'entreprise",
  'intl-report.search-key.postCode': 'Code postal',
  'intl-report.search-key.registeredName': 'Nom',
  'intl-report.search-key.state': 'Province',
  'intl-report.search-key.street': 'Rue',
  'intl-report.search-key.tradingName': 'Dénomination commerciale',
  'intl-report.search-key.vat': 'TVA',
  'intl-report.search-key.vatNumber': 'TVA',
  'intl-report.search.hide-other-fields': 'Masquer autres champs',
  'intl-report.search.number.results':
    '{num, plural, =1 {1 item} =50 {50+ items} autres {{num} items}} trouvés',
  'intl-report.search.recommended': "Recherchez à l'aide de 1 ou plusieurs tags recommandés",
  'intl-report.search.show-other-fields': 'Montrer autres champs',
  'intl-report.search.state.ak': 'Alaska',
  'intl-report.search.state.al': 'Alabama',
  'intl-report.search.state.ar': 'Arkansas',
  'intl-report.search.state.az': 'Arizona',
  'intl-report.search.state.ca': 'Californie',
  'intl-report.search.state.co': 'Colorado',
  'intl-report.search.state.ct': 'Connecticut',
  'intl-report.search.state.de': 'Delaware',
  'intl-report.search.state.fl': 'Floride',
  'intl-report.search.state.ga': 'Géorgie',
  'intl-report.search.state.hi': 'Hawaii',
  'intl-report.search.state.ia': 'Iowa',
  'intl-report.search.state.id': 'Idaho',
  'intl-report.search.state.il': 'Illinois',
  'intl-report.search.state.in': 'Indiana',
  'intl-report.search.state.ks': 'Kansas',
  'intl-report.search.state.ky': 'Kentucky',
  'intl-report.search.state.la': 'Louisiane',
  'intl-report.search.state.ma': 'Massachusetts',
  'intl-report.search.state.md': 'Maryland',
  'intl-report.search.state.me': 'Maine',
  'intl-report.search.state.mi': 'Michigan',
  'intl-report.search.state.mn': 'Minnesota',
  'intl-report.search.state.mo': 'Missouri',
  'intl-report.search.state.ms': 'Mississippi',
  'intl-report.search.state.mt': 'Montana',
  'intl-report.search.state.nc': 'Caroline du Nord',
  'intl-report.search.state.nd': 'Dakota du Nord',
  'intl-report.search.state.ne': 'Nebraska',
  'intl-report.search.state.nh': 'New Hampshire',
  'intl-report.search.state.nj': 'New Jersey',
  'intl-report.search.state.nm': 'Nouveau Mexique',
  'intl-report.search.state.nv': 'Nevada',
  'intl-report.search.state.ny': 'New York',
  'intl-report.search.state.oh': 'Ohio',
  'intl-report.search.state.ok': 'Oklahoma',
  'intl-report.search.state.or': 'Oregon',
  'intl-report.search.state.pa': 'Pennsylvanie',
  'intl-report.search.state.ri': 'Rhode Island',
  'intl-report.search.state.sc': 'Caroline du Sud',
  'intl-report.search.state.sd': 'Dakota du Sud',
  'intl-report.search.state.tn': 'Tennessee',
  'intl-report.search.state.tx': 'Texas',
  'intl-report.search.state.ut': 'Utah',
  'intl-report.search.state.va': 'Virginie',
  'intl-report.search.state.vt': 'Vermont',
  'intl-report.search.state.wa': 'Washington',
  'intl-report.search.state.wi': 'Wisconsin',
  'intl-report.search.state.wv': 'Virginie Occidentale',
  'intl-report.search.state.wy': 'Wyoming',
  'intl-report.search.title': 'Recherche',
  'intl-reports.consumption.downloads':
    '{downloads, plural, =0 {# rapports} one {# rapport} other {# rapports}}',
  'intl-reports.consumption.heatmap': 'Rapports par pays',
  'intl-reports.consumption.heatmap.month': 'pour le mois en cours',
  'intl-reports.pdf.from': 'de',
  'intl-reports.pdf.liabilityDeclaration.providedBy': 'Livrez par',
  'intl-reports.pdf.liabilityDeclaration.providedFor': 'Livrez pour',
  'intl-reports.pdf.number-of-employees': "Nombre d'employés",
  'intl-reports.pdf.untill': "jusqu'à",
  'la.agedDebt': 'Dettes anciennes',
  'la.agedDebt.DAYS_0_30': '0-30 jours',
  'la.agedDebt.DAYS_31_60': '31-60 jours',
  'la.agedDebt.DAYS_61_90': '61-90 jours',
  'la.agedDebt.DAYS_90_PLUS': '90+ jours',
  'la.agedDebt.WITHIN_TERMS': 'Dans les conditions',
  'la.agedDebtDistribution': 'Distribution dettes anciennes',
  'la.agedDebtDistribution.hint':
    "L'encours apparaît ici par tranche d'âge de la dette. Cliquez sur un segment dans le graphique ou dans la légende pour filtrer les débiteurs.",
  'la.amountMatched': 'Afficher {percentage} de tous les débiteurs ayant un solde impayé',
  'la.augurScore': 'Augur score',
  'la.backHome': 'Retour à Ledger Management (Gestion du grand livre)',
  'la.balanceOutstanding': 'Solde en cours',
  'la.companiesInLedger': "Liste d'entreprises",
  'la.companiesInLedger.agedDebt': 'Répartition de la dette ancienne',
  'la.companiesInLedger.agedDebt.hint':
    'Cet aperçu présente vos clients avec leurs dettes impayées, réparties sur plusieurs périodes. Utilisez les flèches sous chaque période pour classer vos clients ou choisissez un ou plusieurs segments dans la matrice de risque ci-dessus pour affiner votre sélection.',
  'la.companiesInLedger.difference': 'Différence',
  'la.companiesInLedger.download': 'Télécharger',
  'la.companiesInLedger.graydonData': 'Données Graydon',
  'la.companiesInLedger.hint':
    '<b>Notation Graydon</b><br/>\nLe risque qu’une entreprise ne puisse pas remplir ses obligations de paiement pendant ces 12 prochains mois à partir de maintenant.<br/>\n<br/>\n<b>Probabilité de discontinuité</b><br/>\nLe risque de liquidation prédit la probabilité que l’entreprise soit liquidée dans les 12 prochains mois.<br/>\n<br/>\n<b>Probabilité de croissance</b><br/>\nLa probabilité de croissance indique la croissance prévue les 12 prochains mois pour l’entreprise.',
  'la.companiesInLedger.id': 'Graydon ID',
  'la.companiesInLedger.name': 'Nom',
  'la.companySearch.hint':
    'En cas de résultats inattendus, le mot clé peut correspondre à des détails du débiteur. Ouvrez les détails de l’entreprise pour voir davantage de détails concernant le débiteur.',
  'la.companySizeFilter.from': 'De',
  'la.companySizeFilter.heading': "Taille de l'entreprise",
  'la.companySizeFilter.includeUnknown': 'Inclure des entreprises de taille inconnue',
  'la.companySizeFilter.to': 'Pour',
  'la.companyTable.limitNote':
    "N.B.: Votre liste d'entreprises (filtrée) contient {totalAmount} clients. Nous ne montrons que les {shownAmount} premiers.",
  'la.creditFlag': 'Drapeau de crédit',
  'la.creditFlag.G': "Vert: Pas d'objection à l'ouverture d'un crédit",
  'la.creditFlag.NA': 'Information indisponible',
  'la.creditFlag.O': 'Orange: Le crédit peut être ouvert mais devrait être surveillé',
  'la.creditFlag.R': "Rouge: Il n'est pas conseillé d'ouvrir un crédit",
  'la.creditLimit': 'Limite de crédit',
  'la.debtor.customerID': 'Référence débiteur',
  'la.debtor.customerID.multiple': 'Plusieurs',
  'la.debtor.customerName': 'Nom débiteur',
  'la.debtor.freeFields': 'Données complémentaires débiteur',
  'la.discontinuation': 'Probabilité de discontinuité',
  'la.discontinuationScore.1': 'Très élevé',
  'la.discontinuationScore.2': 'Élevé',
  'la.discontinuationScore.3': 'Élevé',
  'la.discontinuationScore.4': 'Neutre',
  'la.discontinuationScore.5': 'Neutre',
  'la.discontinuationScore.6': 'Neutre',
  'la.discontinuationScore.7': 'Basse',
  'la.discontinuationScore.8': 'Basse',
  'la.discontinuationScore.9': 'Très basse',
  'la.discontinuationScore.NR': 'Pas notée',
  'la.filterBar.showCompanies': 'Afficher les sociétés',
  'la.filteredSelection': 'Sélection filtrée',
  'la.filteredSelection.removeAll': 'Enlever tous les filtres',
  'la.filters': 'Filtres',
  'la.freeField': 'Champ libre {n}',
  'la.freeField.placeholder': 'Choisissez filtre {n}',
  'la.graphHasNegativeValues':
    'En raison de montants négatifs, ce graphique ne peut pas être affiché.',
  'la.graydonRating': 'Notation Graydon',
  'la.graydonRating.HIGH': 'CCC/CC/C',
  'la.graydonRating.hint':
    'Ici apparaît la Notation Graydon. Cliquez sur un segment dans le graphique ou dans la légende pour filtrer les débiteurs en fonction de leur notation.',
  'la.graydonRating.INSOLVENT': 'D - Failliete',
  'la.graydonRating.LOW': 'AAA/AA/A',
  'la.graydonRating.MEDIUM': 'BBB/BB/B',
  'la.graydonRating.NR': 'Pas notée',
  'la.growthScore': 'Probabilité de croissance',
  'la.growthScore.1': 'Déclin profond',
  'la.growthScore.2': 'Déclin',
  'la.growthScore.3': 'Neutre',
  'la.growthScore.4': 'Croissance',
  'la.growthScore.5': 'Croissance élevée',
  'la.growthScore.99': 'Imprévisible',
  'la.growthScore.NR': 'Pas notée',
  'la.hideFilters': 'Masquer filtres',
  'la.multiscore': 'Multiscore',
  'la.myCustomers': 'Mes clients',
  'la.nrInvoices': 'Nombre de factures',
  'la.paymentScore': 'Score de paiement',
  'la.paymentScore.1': 'Les délais de paiement ne sont pas respectés',
  'la.paymentScore.2': 'Inférieur à la moyenne',
  'la.paymentScore.3': 'Moyenne',
  'la.paymentScore.4': 'Bon comportement de paiement',
  'la.paymentScore.null': 'Inconnu',
  'la.pdRating.A': 'Risque de défaillance faible',
  'la.pdRating.AA': 'Risque de défaillance faible',
  'la.pdRating.AAA': 'Risque de défaillance très faible',
  'la.pdRating.B': 'Risque de défaillance neutre',
  'la.pdRating.BB': 'Risque de défaillance neutre',
  'la.pdRating.BBB': 'Risque de défaillance neutre',
  'la.pdRating.C': 'Risque de défaillance très élevé',
  'la.pdRating.CC': 'Risque de défaillance supérieur à la moyenne',
  'la.pdRating.CCC': 'Risque de défaillance supérieur à la moyenne',
  'la.pdRating.D': 'La société est en défaut de paiement',
  'la.pdRating.NR': "La société n'est pas notée",
  'la.preferences': 'Préférences',
  'la.preferences.cancel': 'Annuler',
  'la.preferences.companyTable.description':
    'Modifier la visibilité de (certaines) colonnes du tableau de la société.',
  'la.preferences.save': 'Enregistrer les préférences',
  'la.riskDistribution': 'Distribution risques',
  'la.riskDistribution.high': 'Elevé',
  'la.riskDistribution.hint':
    'La distribution des risques est une combinaison de la Notation de Graydon et du nombre de jours de dépassement du délai.<br><br>Astuce  : Cliquez sur un segment du graphique ou de la légende pour filtrer les débiteurs par niveau de risque.',
  'la.riskDistribution.low': 'Faible',
  'la.riskDistribution.medium': 'Moyen',
  'la.riskDistribution.nr': 'Pas notée',
  'la.riskMatrix': 'Matrice de risque',
  'la.riskMatrix.hint':
    "La matrice des risques indique les encours et le nombre d'entreprises avec des encours. Les montants sont ventilés en tranches d’ancienneté de la dette et en Notation Graydon de la société. <br/><br/>Le niveau de risque est une combinaison de l’ancienneté de la dette et de la Notation Graydon. Ce niveau est indiqué par une couleur (vert = bas, orange = moyen, rouge = élevé). Cliquez sur l'icône Paramètres au-dessus de la matrice pour modifier ces niveaux.",
  'la.riskMatrixPreferences.explanation':
    'Cliquez sur les cellules de la matrice pour définir le niveau de risque pour chaque combinaison de la Notation Graydon et du paiement.',
  'la.riskMatrixPreferences.heading': 'Définir les niveaux de risque',
  'la.sector': 'Secteur',
  'la.sector.placeholder': 'Choisissez un secteur',
  'la.showFilters': 'Montrer filtres',
  'la.showUnmatched': 'Afficher les débiteurs non appariés',
  'la.totals.averageDaysOverdue': 'moyenne jours échus',
  'la.totals.lastUpdated': 'Dernier importé',
  'la.totals.matchCount': 'Créanciers avec montant impayé',
  'la.totals.totalOpen': 'total impayé',
  'la.totals.totalOverdue': 'échu',
  'la.totals.totalWithinTerms': 'dans les délais',
  'la.unmatch.button': 'Débiteur(s) non apparié(s) du client',
  'la.unmatch.heading': 'Débiteur(s) non apparié(s) du client',
  'la.unmatch.text':
    "Si vous ne reconnaissez pas <b>'{organizationName}'</b> en tant que client, il se peut que nous n'ayons pas correctement apparié le ou les débiteurs à ce client. Veuillez détacher tous les débiteurs de <b>'{organizationName}'</b>.",
  'la.unmatchDone.heading': 'Débiteur(s) non apparié(s) au client',
  'la.unmatchDone.text':
    "Afin d'apparier correctement les débiteurs, veuillez <br/>.\n• Vérifier et mettre à jour les données du débiteur (nom, identifiant, adresse, etc.) dans votre système de grand livre <br>.\n• Veuillez de préférence ajouter le numéro d'enregistrement de la société (CdC, CRO, Numéro d’entreprise) à vos données de débiteur dans votre système de grand livre. <br>\n• Attendez jusqu’à l'importation suivante (généralement le lendemain).",
  'la.unmatchedDebtors': 'Débiteurs non appariés',
  'la.unmatchedDebtors.limitNote':
    "Note:</b>Note:</b> Nous n'affichons que les <b>200 premiers débiteurs non appariés </b> dont l'encours est le plus élevé.",
  'la.unmatchedDebtors.text':
    "Les débiteurs non appariés dans votre grand livre importé n'ont pas pu être appariés avec les données de Graydon. Afin d'apparier correctement les débiteurs, s'il vous plaît:<br/>\n<br/>\n• Vérifier et mettre à jour les données du débiteur (nom, identifiant, adresse, etc.) dans votre système de grand livre<br/>\n• Il est préférable d’ajouter le numéro d'enregistrement de la société (CdC, CRO, Numéro d’entreprise) à vos données de débiteur dans votre système de grand livre. <br/>\n• Attendez après l'importation suivante (généralement le lendemain).",
  'la.unmatchLink': 'Je ne reconnais pas ce client',
  'la.updatePreferencesPopup.error': "Les préférences n'ont pas pu être sauvegardées",
  'la.updatePreferencesPopup.success': 'Les préférences ont été sauvegardées',
  lists: 'Listes',
  'lm.company.has-dbm': 'Database management',
  'lm.company.monitored': 'Surveillé',
  'lm.deletePopup.cancel': 'Non, annuler',
  'lm.deletePopup.confirm': 'Oui, supprimer',
  'lm.listCompanies.actions': 'Actions',
  'lm.listCompanies.back': 'Retour au relevé de la liste',
  'lm.listCompanies.consumerReferenceId': 'Votre référence',
  'lm.listCompanies.country': 'Pays',
  'lm.listCompanies.dateAdded': 'Date ajoutée',
  'lm.listCompanies.name': 'Nom',
  'lm.listCompanies.nrCompaniesSelected': '{amount} entreprises sélectionnées',
  'lm.listCompanies.organizationId': 'Graydon ID',
  'lm.listCompanies.registrationNumber': "Numéro d'entreprise",
  'lm.listCompanies.removeCompanies': 'Supprimer entreprises',
  'lm.listCompanies.removeCompanies.message':
    'Êtes-vous sûr de vouloir supprimer {amount} entreprises de la {listeName} ?',
  'lm.listCompanies.removeCompany': 'Supprimer entreprise',
  'lm.listCompanies.removeCompany.message':
    'Êtes-vous sûr de vouloir supprimer "{companyName}" de {listName} ?',
  'lm.listCompanies.removeSuccess': 'Suppression effectuée avec succès',
  'lm.listCompanies.search.placeholder': 'Rechercher une société dans la liste',
  'lm.listOverview.createList': 'Créer nouvelle liste',
  'lm.listOverview.createPopup.heading': 'Créer une nouvelle liste',
  'lm.listOverview.createPopup.label': 'Nom de la liste',
  'lm.listOverview.createPopup.submit': 'Enregistrer',
  'lm.listOverview.deleteList': 'Supprimer liste',
  'lm.listOverview.deletePopup.message': 'Êtes-vous sûr de vouloir supprimer "{name}"?',
  'lm.listOverview.import.button': 'Importer entreprises',
  'lm.listOverview.noCompaniesMessage':
    "Il n'y a pas encore d'entreprises dans cette liste. Veuillez importer ces entreprises pour utiliser cette liste.",
  'lm.listOverview.noListsMessage':
    "Pas de liste à afficher, veuillez importer des entreprises d'abord.",
  'lm.listOverview.renameList': 'Renommer liste',
  'lm.listOverview.renamePopup.label': 'Nom liste',
  'lm.listOverview.renamePopup.submit': 'Enregistrer',
  'lm.listOverview.clearReferences': 'Supprimer tout les références',
  'lm.listOverview.clearReferences.confirm':
    'Êtes-vous sûr de vouloir supprimer tout les références de "{Listname}"?',
  'lm.listOverview.clearReferences.yes': 'Oui, supprimer',
  'lm.listOverview.clearReferences.no': 'Non, annuler',
  'lm.listOverview.clearReferences.success': 'Références supprimé avec succès',
  'lm.listOverview.title': 'Relevé liste',
  'lm.numCompanies': '{num} {num, plural, =1 {entreprise} other {entreprises}}',
  'lm.showMoreLists': 'Show more lists',
  'lm.tags.createError.nameAlreadyExists': 'Nom de la liste existe déjà',
  'lm.tags.createSuccess': 'La liste a été créée',
  'lm.tags.deleteSuccess': 'Supprimé avec succès',
  'lm.tags.renameSuccess': 'Renommé avec succès',
  'lm.upload-csv.delimiter': 'Séparateur',
  'loading.content': 'Chargement du contenu',
  'matching.organizationId': 'Numéro Graydon',
  'matching.addressCity': 'Ville',
  'matching.addressHouseNumber': 'Numéro de maison',
  'matching.addressHouseNumberSuffix': 'Suffixe numéro de maison',
  'matching.addressLine1': "Ligne d'adresse 1",
  'matching.addressLine2': "Ligne d'adresse 2",
  'matching.addressLine3': "Ligne d'adresse 3",
  'matching.addressLine4': "Ligne d'adresse 4",
  'matching.addressLine5': "Ligne d'adresse 5",
  'matching.addressPostalCode': 'Code postal',
  'matching.addressStreet': 'Rue',
  'matching.addressStreetHouseNumberSuffix': 'Numéro de maison + suffix',
  'matching.addressStreetHouseNumberSuffixPostcalCodeCity': 'Addresse complète',
  'matching.async.backButton': "Aller à la page d'accueil",
  'matching.async.disclaimer':
    'Selon la taille de votre fichier, cela pourrait prendre une heure. Entre-temps, vous pouvez quitter cette page et faire autre chose.',
  'matching.async.matchingDone':
    'Une fois le matching terminé, nous enverrons les résultats du matching à:',
  'matching.async.nextSteps': 'Etapes suivantes',
  'matching.async.nextSteps.step1':
    "Lorsque la correspondance est terminée, nous enverrons les résultats correspondants à l'adresse",
  'matching.async.nextSteps.step1.note':
    'Remarque: Cet e-mail peut se retrouver dans votre spambox',
  'matching.async.nextSteps.step2': "Cliquer sur le lien dans l'e-mail",
  'matching.async.nextSteps.step3': 'Ajouter des entreprises liées à une ou plusieurs listes',
  'matching.async.title': 'Matching en cours...',
  'matching.be': 'Belgique',
  'matching.beBusinessUnitNumber': "Numéro d'unité d'entreprise",
  'matching.beEnterpriseNumber': "Numéro d'entreprise",
  'matching.connectingWithGraydonDatabase': 'Associez vos colonnes aux données Graydon',
  'matching.croRegistrationNumberOrGbYCompanyId': "Numéro d'entreprise",
  'matching.example': 'Example de votre fichier',
  'matching.gb': 'Royaume-Uni',
  'matching.graydonFieldsFor': 'Données Graydon',
  'matching.graydonOrganisationId': 'Numéro Graydon',
  'matching.introText.be':
    'Définissez les colonnes de données de votre fichier à associer aux les données Graydon.',
  'matching.introText.gb':
    'Définissez les colonnes de données de votre fichier à associer aux les données Graydon.',
  'matching.introText.nl':
    'Définissez les colonnes de données de votre fichier à associer aux les données Graydon. ',
  'matching.kvkBranchNumber': "Chambre de Commerce numéro d'établissement",
  'matching.kvkNumber': 'Numéro chambre de commerce',
  'matching.labelFromYourList': 'Vos colonnes',
  'matching.nl': 'Pays-Bas',
  'matching.nlYCompanyId': 'Ancien numéro Graydon (NL)',
  'matching.orgName': 'Nom de la société',
  'matching.reference': 'Référence (identification client)',
  'matching.results.accessViaPortfolio':
    'Vous pouvez accéder aux entreprises via votre Portefeuille.',
  'matching.results.addCompaniesToList': 'Ajouter vos entreprises à une liste',
  'matching.results.download': 'Télécharger',
  'matching.results.download.point1': 'des entreprises liées',
  'matching.results.download.point2': "des entreprises désigné comme 'Pas été liées'",
  'matching.results.download.text': 'Télécharger les résultats de match avec:',
  'matching.results.downloadButton': 'Télécharger résultats de match',
  'matching.results.heading': 'Résultats de match',
  'matching.results.listItemOne': "Utilisez les rangs portant la mention 'Not matched'",
  'matching.results.listItemThree': 'Actualiser données erronées',
  'matching.results.listItemTwo': 'Ajouter les données manquantes',
  'matching.results.matchResults': 'Résultat de match',
  'matching.results.more': 'plus',
  'matching.results.noMatchesMessage':
    'Veuillez actualiser votre fichier ou retourner en arrière et modifier la correspondance',
  'matching.results.pleaseChooseGroups': 'Ajouter des entreprises à une ou plusieurs listes',
  'matching.results.pleaseChooseGroups.text': 'Choississez une ou plusieurs listes ou',
  'matching.results.pleaseChooseGroups.text.link': 'créer une nouvelle liste',
  'matching.results.successfullyMatched': 'entreprises ont été liées avec succès',
  'matching.results.update': 'Actualiser',
  'matching.results.update.point1': 'Ajouter les données manquantes',
  'matching.results.update.point2': 'Actualiser données erronées',
  'matching.results.update.text':
    "Actualiser le fichier et importer encore pour lier plus d'entreprises:",
  'matching.results.updateYourFile':
    "Voir entreprises que n'ont pas été liées et augmenter les liaisons",
  'matching.results.uploadAgain': 'Télécharger à nouveau',
  'matching.results.uploadAgainButton': 'Télécharger liste actualisée',
  'matching.results.wereNotMatched': "entreprises n'ont pas été liées",
  'matching.review.addMissingData': 'Veuillez ajouter vous-même les données manquantes',
  'matching.review.companies':
    'les entreprises ont été mises en correspondance et ajoutées à votre portefeuille',
  'matching.review.companyMoreData': 'les entreprises nécessitent davantage de données',
  'matching.review.continue': 'Poursuivre sans données sans correspondance ',
  'matching.review.hideTips': 'masquer conseils',
  'matching.review.reviewImport': 'Contrôler import',
  'matching.review.showTips': 'Montrer les conseils',
  'matching.review.submitButton': 'Télécharger les données sans correspondance',
  'matching.review.theWordHow': 'Comment ?',
  'matching.review.theWordOf': 'de',
  'matching.review.tip.downloadNonMatched': 'Télécharger les entreprises sans correspondance',
  'matching.review.tip.review':
    'Contrôlez le fichier. Ajoutez autant de données manquantes que vous le pouvez. Nous avons besoin de :',
  'matching.review.tip.uploadAndMatch':
    'Téléchargez le fichier actualisé et effectuez une nouvelle recherche de correspondance.',
  'matching.review.tip1': 'conseil',
  'matching.review.tip2': 'conseil',
  'matching.review.tip3': 'conseil',
  'matching.selectColumn': 'Pas de label sélectionné',
  'matching.overwriteReference':
    "Remplacer les références d'entreprise existantes par les références de cette importation",
  'matching.overwriteReference.warning':
    'L’écrasement des références ne peut pas être annulé. Cela affectera également les autres utilisateurs.',
  'matching.submitButton': 'Mise en correspondance des données',
  'matching.vatNumber': 'Numéro de TVA',
  'matching.yMsKey': 'MSkey',
  'matchng.results.continueButton': 'Sauvegardez et poursuivez',
  'mdb.cm.download-invoice-data': 'Données de facturation de gestion de crédit (.xlsx)',
  'mdb.cm.download-usage-data': "Données d'utilisation de gestion de crédit (.xlsx)",
  'mdb.creditManagement.amountRequested.advanced': 'Organisations vérifiées',
  'mdb.creditManagement.amountRequested.essential': 'Rapports de crédit demandés',
  'mdb.creditManagement.amountRequested.premium': 'Organisations vérifiées',
  'mdb.creditManagement.country.be': 'Belgique',
  'mdb.creditManagement.country.nl': 'Pays-Bas',
  'mdb.creditManagement.country.uk': 'Royaume-Uni',
  'mdb.creditManagement.country.gb': 'Royaume-Uni',
  'mdb.creditManagement.description.advanced':
    "Le nombre d'entreprises qui ont été vérifiées par rapport au nombre total d'entreprises qui peuvent être vérifiées selon votre forfait pour la durée de votre contrat : **{dateRange}**. Ceci inclut le Monitoring Tool, les Rapports de Crédit et le Ledger Management.",
  'mdb.creditManagement.description.essential':
    'Le nombre de rapports de crédit demandés par rapport au nombre total de rapports de crédit inclus dans votre forfait pour la durée de votre contrat : **{dateRange}**.',
  'mdb.creditManagement.description.premium':
    "Le nombre d'entreprises qui ont été vérifiées par rapport au nombre total d'entreprises qui peuvent être vérifiées selon votre forfait pour la durée de votre contrat : **{dateRange}**. Ceci inclut le Monitoring Tool, les Rapports de Crédit et le Ledger Management.",
  'mdb.creditManagement.lastUpdate': 'Dernière mise à jour:',
  'mdb.creditManagement.tab': 'Credit Management',
  'mdb.creditManagement.tileHeading.advanced': "Nombre d'organisations vérifiées",
  'mdb.creditManagement.tileHeading.essential': 'Nombre de rapports de crédit demandés',
  'mdb.creditManagement.tileHeading.premium': "Nombre d'organisations vérifiées",
  'mdb.creditManagement.tooltip':
    'Les compteurs de Credit Management seront mis à jour une fois par jour.',
  'mdb.download-data-enrichment':
    "Données d'utilisation de Marketing Information Enrichments (.xlsx)",
  'mdb.download-market-data': "Données d'utilisation de Market Data (.xlsx)",
  'mdb.icr.download-old-usage-data':
    "Anciennes données d'utilisation des rapports internationaux de crédit (avant 9-11-2021)",
  'mdb.icr.download-usage-data': "Données d'utilisation des rapports internationaux de crédit",
  'mdb.icr.tab': 'Rapports internationaux de crédit',
  'mdb.market-data.tab': 'Market Data',
  'mdb.marketData.amountRequested': 'Organisations téléchargées ou enrichies',
  'mdb.marketData.description':
    "Le nombre d'organisations qui ont été téléchargées via Market Data ou enrichies via Data Enrichment versus le nombre total d'organisations incluses dans votre forfait pour la durée de votre contrat : **{dateRange}**.",
  'mdb.marketData.tileHeading': "Nombre d'organisations téléchargées ou enrichies",
  'mdb.marketData.tooltip':
    "Les compteur Market Data seront mis à jour une fois par jour. Le compteur indique le nombre d'adresses que vous pouvez encore télécharger.\n",
  'mdb.no.reference': 'Pas de référence',
  'mdb.usage.extractCached.amountRequested':
    'Extraits du registre de commerce demandés aux archives de Graydon',
  'mdb.usage.extractCached.description':
    "Le nombre d'extraits du registre de commerce d'archives de Graydon par rapport au nombre total d'extraits disponibles pendant la durée du contrat dans votre forfait: **{dateRange}**.",
  'mdb.usage.extractFresh.amountRequested': 'Nouveaux extraits du registre de commerce demandés',
  'mdb.usage.extractFresh.description':
    "Le nombre de nouveaux extraits du registre de commerce par rapport au nombre total d'extraits disponibles pendant la durée du contrat dans votre forfait: **{dateRange}**.",
  'mdn.usage.companyExtract.tooltip':
    'Les compteurs de extraits du registre de commerce seront mis à jour une fois par jour.',
  'mdn.usage.extractCached.heading':
    "Nombre d'extraits du registre de commerce de l'archives Graydon {country, select, gb {Royaume-Uni} be {Belgique} other {Pays-Bas}}",
  'mdn.usage.extractFresh.heading':
    'Nombre de nouveaux extraits du registre de commerce {country, select, gb {Royaume-Uni} be {Belgique} other {Pays-Bas}}',
  'menu.hide': 'Masquer le menu de navigation',
  'menu.show': 'Montrer le menu de navigation',
  'mi.activities.confirm': 'Confirmer activités',
  'mi.activities.group-code.1': 'T.V.A.',
  'mi.activities.group-code.BE_1': 'BCE-EDRL',
  'mi.activities.group-code.BE_1_2_5': 'BCE',
  'mi.activities.group-code.BE_2': 'BCE-POR',
  'mi.activities.group-code.BE_3': 'ONSSAPL',
  'mi.activities.group-code.BE_3_4': 'ONSS',
  'mi.activities.group-code.BE_4': 'ONSS',
  'mi.activities.group-code.BE_5': 'BCE-ECOLES',
  'mi.activities.group-code.BE_6': 'Madison',
  'mi.activities.group-code.EMPTY': 'AMB et autres',
  'mi.activities.source': 'Source',
  'mi.activities.source.all-sources': 'Toutes les sources',
  'mi.activitySearchPlaceholder': "Recherche par mots clés ou code d'activité",
  'mi.bigDownload.button': 'Faisons autre chose',
  'mi.bigDownload.emailTo':
    "Lorsque votre campagne sera prête à télécharger, nous vous enverrons un e-mail à l'adresse :",
  'mi.bigDownload.heading': 'Il est presque temps de réaliser vos objectifs…',
  'mi.bigDownload.smallText':
    "Vous n'avez pas reçu un e-mail dans les 2 heures? Contactez le service clients",
  'mi.companies': 'entreprises',
  'mi.configurator.activities': 'Activités',
  'mi.configurator.addArea': 'Ajouter zone',
  'mi.configurator.addCity': 'Ajouter commune',
  'mi.configurator.addCity.belgium': 'Ajouter commune',
  'mi.configurator.addFoundingYear': 'Ajoutez année',
  'mi.configurator.addFoundingYearRange': "Ajouter série d'années",
  'mi.configurator.addZipCode': 'Ajouter code postal',
  'mi.configurator.addZipCodeRange': 'Ajouter série de codes postaux',
  'mi.configurator.amountSelected': '{num} sélectionné(s)',
  'mi.configurator.annualAccount.equity_capital': 'Equity capital',
  'mi.configurator.annualAccount.net_result': 'Net result',
  'mi.configurator.annualAccount.tooltip':
    'Choisissez un ou plusieurs indicateurs clés et définissez la ou les valeurs seuils.',
  'mi.configurator.annualAccount.total_assets': 'Total assets',
  'mi.configurator.annualAccount.turnover': 'Turnover',
  'mi.configurator.annualAccount.working_capital': 'Working capital',
  'mi.configurator.annualAccountHeading': 'Comptes annuels',
  'mi.configurator.area.enterToSearch': 'pour rechercher',
  'mi.configurator.area.heading': 'Zone',
  'mi.configurator.area.locationNotFound': 'Lieu non trouvé',
  'mi.configurator.area.tooltip':
    'Entrez un lieu et augmentez ou réduisez le rayon de la zone en faisant glisser le cercle sur la carte',
  'mi.configurator.chooseActivities': 'Choisir activités',
  'mi.configurator.cityFilterHeading': 'Commune',
  'mi.configurator.cityFilterHeading.belgium': 'Commune',
  'mi.configurator.clearAllFilters': 'Réinitialiser sélection',
  'mi.configurator.clearSelection': 'Supprimer filtre',
  'mi.configurator.companySizesHeading': 'Nombre de collaborateurs',
  'mi.configurator.companyTypesHeading': "Type d'établissement",
  'mi.configurator.edited': 'Modifié',
  'mi.configurator.filters': 'Filtres',
  'mi.configurator.foundingYearHeading': 'Année de création',
  'mi.configurator.foundingYearInputPlaceholder': 'Année',
  'mi.configurator.header.be': 'Votre sélection en Belgique',
  'mi.configurator.header.gb': 'Votre sélection au Royaume-Uni',
  'mi.configurator.header.nl': 'Votre sélection aux Pays-Bas',
  'mi.configurator.heading': 'Ma campagne',
  'mi.configurator.legalFormsHeading': 'Forme juridique',
  'mi.configurator.noProspectsActivities':
    'Pas de prospects dans la sélection. Changez un ou plusieurs filtres pour voir les activités',
  'mi.configurator.provincesHeading': 'Provinces',
  'mi.configurator.provincesHeading.uk': 'Régions',
  'mi.configurator.rangeButton': 'Fourchette',
  'mi.configurator.rangeSelector.and': 'et',
  'mi.configurator.rangeSelector.between': 'Entre',
  'mi.configurator.rangeSelector.higher-than': 'Supérieur à',
  'mi.configurator.rangeSelector.lower-than': 'Inférieure à',
  'mi.configurator.removeFoundingYear': 'Supprimez',
  'mi.configurator.removeZipCode': 'Supprimer',
  'mi.configurator.resetFiltering': 'Réinitialiser le filtrage',
  'mi.configurator.save': 'Sauvegarder',
  'mi.configurator.saveActivities': 'Continuer avec la sélection',
  'mi.configurator.saveAs': 'Sauvegarder sous',
  'mi.configurator.savedFiltersHeading': 'Mes campagnes',
  'mi.configurator.saveFilters': 'Sauvegarder le campagne',
  'mi.configurator.saveFilters.inputPlaceholder':
    'par ex. boulangers à Bruxelles, grandes sociétés à Anvers',
  'mi.configurator.saveFilters.text':
    "Pour poursuivre ultérieurement ou créer un template permettant d'effectuer de nouvelles campagnes",
  'mi.configurator.search-key.name': 'Nom',
  'mi.configurator.selectCountryHeading': 'Démarrez votre campagne',
  'mi.configurator.selectCountryParagraph':
    "Pour lancer une nouvelle campagne, vous devez d'abord choisir un pays. Ensuite vous pouvez effectuer une sélection de prospects dans ce pays.",
  'mi.configurator.sessionExpired.button': 'se connecter de nouveau',
  'mi.configurator.sessionExpired.text':
    "Pas de panique, votre travail n'est pas perdu. Reconnectez-vous et poursuivez.",
  'mi.configurator.sessionExpired.title': 'Votre session a expiré, pour des raisons de sécurité',
  'mi.configurator.showLess': 'Moins',
  'mi.configurator.showMore': 'Montrer tout',
  'mi.configurator.socialBalance.heading': 'Bilan social',
  'mi.configurator.socialBalance.label':
    '{label, select, full_time_employees_size {Nombre d’employés à plein temps} board_members_size {Nombre management/responsables} office_staff_size {Nombre personnel de bureau} other {Catégorie inconnue}}',
  'mi.configurator.socialBalance.tooltip':
    "Choisissez un ou plusieurs numéros de type d'employé et définissez la ou les valeurs seuils.",
  'mi.configurator.textSearch.invalidQuery': 'Recherche non valable',
  'mi.configurator.textSearch.placeholder': 'Exemple : Webshop AND (Panier OU Caddie)',
  'mi.configurator.textSearchHeading': 'Chercher les sites web des entreprises',
  'mi.configurator.unknown': 'Inconnu',
  'mi.configurator.zipCode': 'Code postal',
  'mi.configurator.zipCodeHeading': 'Code postal',
  'mi.configurator.zipCodeInputPlaceholder': 'Code postal',
  'mi.dateRangeSelection': 'Fourchette dates',
  'mi.discontinuationScore.1': 'Faible',
  'mi.discontinuationScore.2': 'Moyenne',
  'mi.discontinuationScore.3': 'Elevée',
  'mi.discontinuationScore.heading': 'Probabilité de discontinuité',
  'mi.discontinuationScore.NR': 'Pas notée',
  'mi.download': 'Télécharger',
  'mi.download.noResults.heading': "Il n'y a plus de prospects",
  'mi.download.noResults.message':
    "Vous avez probablement exclu trop d'entreprises. Vous devez exclure moins d'entreprises, ou adapter vos filtres.",
  'mi.downloadAsCsv': 'Télécharger en CSV',
  'mi.downloadAsExcel': 'Télécharger en Excel',
  'mi.downloadForm.amount.right': 'Total: {maxAmount}',
  'mi.downloadForm.amountScheduled.right': 'entreprises par lot',
  'mi.downloadForm.back': 'Retour à la campagne',
  'mi.downloadForm.batchSize': 'Taille maximale du batch',
  'mi.downloadForm.batchSize.nCompanies': 'sociétés',
  'mi.downloadForm.batchSize.xFirst': 'premier',
  'mi.downloadForm.campaignName': 'Nom campagne',
  'mi.downloadForm.cleanUp.heading': 'Nettoyer votre téléchargement',
  'mi.downloadForm.cleanUp.paragraph':
    'Supprimer les doublons et exclure certaines sociétés, par exemple celles qui étaient dans vos téléchargements précédents.',
  'mi.downloadForm.deduplicate': 'Supprimer les doublons',
  'mi.downloadForm.deduplicate.heading': 'Dédoublez votre sélection',
  'mi.downloadForm.deduplicate.onAddress': 'Sur adresse',
  'mi.downloadForm.deduplicate.onEmail': 'Sur adresse e-mail',
  'mi.downloadForm.deduplicate.onPhone': 'Sur numéro de téléphone',
  'mi.downloadForm.downloadLocale': 'Langue du fichier',
  'mi.downloadForm.downloadSettings.heading': 'Paramètres de téléchargement',
  'mi.downloadForm.downloadType.once': 'Télécharger une fois',
  'mi.downloadForm.downloadType.once.paragraph':
    'Utile pour les campagnes ponctuelles de moindre envergure.',
  'mi.downloadForm.downloadType.scheduled': 'Téléchargement répétitif programmé',
  'mi.downloadForm.downloadType.scheduled.paragraph':
    'Répartir un grand nombre de prospects dans le temps. Cela garantira en tout temps des données actualisées.',
  'mi.downloadForm.excelTooBig\n':
    "Malheureusement, Excel ne supporte pas 1 million d'enregistrements ou plus. Veuillez sélectionner CSV ou réduisez votre sélection.",
  'mi.downloadForm.exclude': 'Exclure',
  'mi.downloadForm.excludeDownloads': 'Exclure des téléchargements précédents',
  'mi.downloadForm.excludeDownloads.selectAll': 'Tous les téléchargements précédents',
  'mi.downloadForm.excludeDownloads.title': 'Sélectionner des téléchargements précédents',
  'mi.downloadForm.excludeGroups.continue': 'Continuer',
  'mi.downloadForm.excludeGroups.heading': 'Exclure liste(s) de votre campagne',
  'mi.downloadForm.excludeGroups.showMore': 'Choisissez une ou plusieurs listes',
  'mi.downloadForm.fileType': 'Type de fichier',
  'mi.downloadForm.fileType.csv': 'Comma Separated Values (.csv)',
  'mi.downloadForm.fileType.excel': 'Excel (.xlsx)',
  'mi.downloadForm.fileType.heading': 'Type fichier',
  'mi.downloadForm.first.batch': 'Premier lot',
  'mi.downloadForm.firstBatch': 'Vous allez télécharger un premier lot de',
  'mi.downloadForm.gdpr.conditionsText':
    'Les données à caractère personnel et les scores sont soumis à la fois au Règlement général sur la protection des données et à la Loi néerlandaise sur les télécommunications. Pour de plus amples informations, veuillez consulter les Conditions générales de Graydon, disponibles à l’adresse : <a href="https://graydon.be/fr/conditions-generales">https://graydon.be/fr/conditions-generales</a>',
  'mi.downloadForm.gdpr.dmus': 'Coordonnées d’une personne de contact (le cas échéant)',
  'mi.downloadForm.gdpr.dmus.tooltip':
    "Il n'est pas permis de contacter par courrier ou en porte-à-porte les sociétés/personnes qui ne figurent pas parmi vos relations et qui ont indiqué ne pas souhaiter recevoir de mailings.",
  'mi.downloadForm.gdpr.heading': 'Souhaitez-vous recevoir des données supplémentaires ?',
  'mi.downloadForm.gdpr.scores': 'Scores (le cas échéant)',
  'mi.downloadForm.gdpr.scores.tooltip':
    "Il s'agit ici du risque de défaut de paiement, de la probabilite de discontinuité et de la probabilité de croissance.",
  'mi.downloadForm.heading': 'Téléchargement de votre campagne',
  'mi.downloadForm.maxNumberOfProspects': 'Nombre',
  'mi.downloadForm.name': 'Nom du campagne',
  'mi.downloadForm.nextBatch': 'Vous allez recevoir vos prospects le',
  'mi.downloadForm.on': 'sur',
  'mi.downloadForm.order.oldest': 'le plus âgé',
  'mi.downloadForm.order.withHighestProbOfDefault':
    'avec la probabilité de défaut de paiement la plus élevée',
  'mi.downloadForm.order.withLeastEmployees': "avec le moins d'employés",
  'mi.downloadForm.order.withLowestProbOfDefault':
    'avec la probabilité de défaut de paiement la plus faible',
  'mi.downloadForm.order.withMostEmployees': "avec le plus d'employés",
  'mi.downloadForm.order.youngest': 'le plus jeune',
  'mi.downloadForm.recipient': 'Destinataire',
  'mi.downloadForm.repeat': 'Répéter chaque',
  'mi.downloadForm.repeat.left': 'chaque',
  'mi.downloadForm.repeat.right': 'jour',
  'mi.downloadForm.repeat.subtext': 'Lot suivant sur',
  'mi.downloadForm.scheduledInfoLine2':
    "Attention : \r\navec un téléchargement planifié, la déduplication est automatiquement effectuée sur les téléchargements précédents et vous ne recevrez les organisations qu'une seule fois",
  'mi.downloadForm.tags.heading': 'Choisissez une liste à laquelle ajouter les sociétés',
  'mi.downloadForm.tags.paragraph': 'Lists can be found in List Management',
  'mi.downloadForm.today': "aujourd'hui",
  'mi.downloadForm.validation.filterName': 'Veuillez choisir un nom pour la campagne',
  'mi.downloadForm.validation.nrCompanies': 'Veuillez choisir un nombre supérieur à 0',
  'mi.downloadForm.validation.prospectLimitExceeded':
    'Vous ne pouvez pas télécharger plus de {value} prospects à la fois',
  'mi.downloadForm.validation.repeatInterval': 'Veuillez choisir un nombre supérieur à 0',
  'mi.downloadForm.validation.selectList': 'Choisissez une ou plusieurs listes',
  'mi.downloadStarted.heading': 'Téléchargement débuté',
  'mi.downloadStarted.homeButton': "Aller à la page d'accueil",
  'mi.errorPage.fetchErrorDescription':
    'Erreur lors du chargement des données, veuillez réessayer plus tard',
  'mi.errorPage.heading': 'Erreur',
  'mi.errorPage.invalidDownload': 'Le lien a expiré Veuillez répéter le téléchargement.',
  'mi.errorPage.retry': 'Réessayer',
  'mi.foundingYear.after': 'après',
  'mi.foundingYear.before': 'Avant',
  'mi.foundingYear.lastNMonths': 'Derniers {n} mois',
  'mi.growthScore.1': 'Croissance',
  'mi.growthScore.2': 'Neutre',
  'mi.growthScore.3': 'Régression',
  'mi.growthScore.heading': 'Probabilité de croissance',
  'mi.growthScore.NR': 'Pas notée',
  'mi.hasTurnover.false': 'Non',
  'mi.hasTurnover.heading': "Entreprises avec chiffre d'affaires",
  'mi.hasTurnover.true': 'Oui',
  'mi.howToContact.has_email': 'E-mail disponible',
  'mi.howToContact.has_telephone_number': 'Numéro de téléphone disponible',
  'mi.howToContact.heading': 'Coordonnées',
  'mi.isActiveStatusCode': '{key, select, Y {Oui} N {Non} other {Inconnu}}',
  'mi.isActiveStatusCode.heading': 'Entreprises actives',
  'mi.isActiveStatusCode.tooltip':
    "Les entreprises actives sont des entreprises enregistrées comme actives à la Chambre de Commerce. Ces entreprises ne sont pas en faillite et n'ont pas été dissoutes.",
  'mi.isEconomicallyActive.heading': 'Entreprises économiquement actives',
  'mi.isEconomicallyActive.N': 'Non',
  'mi.isEconomicallyActive.N/A': 'Inconnu',
  'mi.isEconomicallyActive.tooltip':
    "Les entreprises économiquement actives sont des entreprises dont Graydon a déterminé qu'elles prennent très probablement une part active dans la vie économique. Graydon a établi cela, par exemple, sur la base de comptes annuels déposés, d'informations de paiement collectées ou d'informations provenant d'autres sources.",
  'mi.isEconomicallyActive.Y': 'Oui',
  'mi.noActivitiesFound':
    "Pas d'activités trouvées. Veuillez vérifier l'orthographe ou essayez de chercher avec un autre mot-clé.",
  'mi.nonMailIndicator.heading': 'CCI Non-mailing-indicator (NMI)',
  'mi.nonMailIndicator.noNMI': 'Seulement les sociétés sans NMI (joignables par courrier)',
  'mi.nrProspects': '{num} prospects',
  'mi.paymentScore.average': 'Moyen',
  'mi.paymentScore.heading': 'Score de paiement',
  'mi.paymentScore.high': 'Elevé',
  'mi.paymentScore.low': 'Faible',
  'mi.postcodeRange': 'Série de codes postaux',
  'mi.preferredLanguages.FR': 'Français',
  'mi.preferredLanguages.heading': "Langue opérationnelle de l'organisation",
  'mi.preferredLanguages.NL': 'Néerlandais',
  'mi.preparingDownload.heading': 'Téléchargement en préparation, veuillez attendre…',
  'mi.preparingDownloadCsv': 'Téléchargement CSV en préparation',
  'mi.preparingDownloadExcel': 'Téléchargement Excel en préparation',
  'mi.prospectPreview.activityUnknown': 'Activité inconnue',
  'mi.prospectPreview.backButton': 'Changez la campagne',
  'mi.prospectPreview.heading': 'Exemple de votre campagne',
  'mi.prospectPreview.legalFormUnknown': 'Forme juridique inconnue',
  'mi.prospectPreview.unknownCompanyName': 'Nom inconnu',
  'mi.prospectPreviewButton': 'Exemple',
  'mi.ratingCode.1': 'Élevé',
  'mi.ratingCode.2': 'Moyen',
  'mi.ratingCode.3': 'Faible',
  'mi.ratingCode.heading': 'Risque financier',
  'mi.ratingCode.heading.nl': 'Risque de défaut de paiement',
  'mi.savedFilters.delete': 'Supprimer',
  'mi.savedFilters.deletePopup.cancel': 'Non, annuler',
  'mi.savedFilters.deletePopup.confirm': 'Oui, supprimer',
  'mi.savedFilters.deletePopup.message': 'Êtes-vous sûr de vouloir supprimer "{name}"?',
  'mi.savedFilters.deleteSuccess': 'Supprimé avec succès',
  'mi.savedFilters.editSchedule': 'modifier',
  'mi.savedFilters.noSavedFilters': "Pour l'instant, vous n'avez aucun campagnes sauvegardé.",
  'mi.savedFilters.saveSuccess': 'Campagne enregistrée',
  'mi.savedFilters.scheduled': 'programmé',
  'mi.savedFilters.scheduledText':
    'Téléchargement programmé tous les {nrDays} jours, prochaine série {nextDate}',
  'mi.savedFilters.show.all': 'Tous',
  'mi.savedFilters.show.saved': 'Sauvegardé',
  'mi.savedFilters.show.scheduled': 'Programmé',
  'mi.saveFilter.nameAlreadyExists': 'Une campagne du même nom existe déjà',
  'mi.scheduleSet.heading': 'Le téléchargement prévu a été configuré',
  'mi.scheduleSet.paragraph': 'Le premier lot de prospects sera envoyé à {email} sous peu',
  'mi.scheduleSet.paragraph.later': 'Le premier lot de prospects sera envoyé à {email} le {date}',
  'mi.scheduleUpdated.heading': 'Le téléchargement prévu a été actualisé',
  'mi.sectors.heading': 'Secteur',
  'mi.singlePostcode': 'Un code postal',
  'mi.singleYear': 'Une année',
  'mi.textSearchFilter.tooltip_1':
    '- Utilisez <b>AND</b> entre deux mots-clés lorsque les deux mots-clés doivent être présents.',
  'mi.textSearchFilter.tooltip_2':
    "- Utilisez <b>OR</b>  entre deux mots-clés lorsque l'un ou l'autre des deux mots-clés doit être présent.",
  'mi.textSearchFilter.tooltip_3':
    '- Utilisez <b>NOT</b> devant un mot-clé pour exclure les organisations ayant ce mot-clé sur leur site web.',
  'mi.textSearchFilter.tooltip_4':
    '- Placez les mots clés <b>&quot;entre parenthèses&quot;</b> pour rechercher une correspondance exacte de ces mots.',
  'mi.textSearchFilter.tooltip_5':
    '- Placez les mots clés <b>entre parenthèses</b> pour grouper les mots. Exemple: (boulangerie OR boulanger) AND magasin.',
  'mi.totalSelectedProspects': 'Prospects dans la sélection',
  'mi.unknownArea': 'Situation inconnue',
  'mi.whoToContact.has_financial_director': 'Affaires financères',
  'mi.whoToContact.has_fleet_management_director': 'Flotte automobille',
  'mi.whoToContact.has_hrm_director': 'Ressources humaines',
  'mi.whoToContact.has_it_director': 'IT/Automatisation',
  'mi.whoToContact.has_managing_director': 'Affaires générales',
  'mi.whoToContact.has_managing_director.be':
    'La direction: Cascade: administrateur délégué - administrateur - gérant - exploitant – président',
  'mi.whoToContact.has_marketing_director': 'Marketing',
  'mi.whoToContact.has_operations_director': 'Opérations',
  'mi.whoToContact.has_purchase_director': "Service d'achat",
  'mi.whoToContact.has_sales_director': 'Service des ventes',
  'mi.whoToContact.has_technical_director': 'Service technique',
  'mi.whoToContact.heading': 'Personne de contact disponible',
  'mi.yearRange': "Série d'années",
  'mi.yearSelection': 'Année',
  'mon.backToUpdateOverview': "Retour à l'aperçu des mises à jour",
  'mon.ccjFlag.false': 'Aucune prononcée',
  'mon.ccjFlag.true': 'Injonction(s) prononcée(s)',
  'mon.configure.address': 'Adresse de la société',
  'mon.configure.address_1': 'Adresse de la société',
  'mon.configure.address_1.hint':
    "Surveillez les changements d'adresse d'une société. Recevez une alerte lorsqu'il y a une mise à jour. Les informations mises à jour sont disponibles dans le rapport de crédit.",
  'mon.configure.address.changed': 'Nouvelle adresse enregistrée',
  'mon.configure.address.hint':
    "Surveillez les changements d'adresse d'une société. Recevez une alerte lorsqu'il y a une mise à jour. Les informations mises à jour sont disponibles dans le rapport de crédit.",
  'mon.configure.annualReport': 'Bilan annuel',
  'mon.configure.annualReport.hint': 'Surveillez si un nouveau bilan annuel est disponible.',
  'mon.configure.annualReport.newReport': 'Nouveau bilan annuel déposé',
  'mon.configure.annualReportGB': 'Bilan disponible (à Graydon)',
  'mon.configure.annualReportGB.hint':
    'Surveillez si un nouveau bilan annuel est disponible dans la base de données de Graydon.',
  'mon.configure.annualReports': 'Dernier bilan annuel déposé a Companies House le',
  'mon.configure.annualReports.title': 'Bilans annuels',
  'mon.configure.beMultiscore': 'Multiscore',
  'mon.configure.beMultiscore.hint':
    "Surveillez le Multiscore. Mesure du niveau de risque de crédit associé à l'entreprise. S'exprime par un score compris entre 1 et 100.",
  'mon.configure.ccjFlag': 'Injonctions de paiement',
  'mon.configure.ccjFlag.hint':
    "Surveillez si une injonction de paiement a été ordonnée contre une société. Recevez une alerte si de nouvelles injonctions sont imposées. Pour plus d'informations, vous pouvew demandee un nouveau rapport de crédit.",
  'mon.configure.companyName': 'Nom de la société',
  'mon.configure.companyName.hint':
    "Surveillez les changements de nom d'une société. Recevez une alerte s'il y a des modifications. Vous serez informé de l'ancien et du nouveau nom.",
  'mon.configure.country': 'Pays',
  'mon.configure.country.hint':
    'Choisissez un pays. Si une liste de sociétés que vous surveillez est composée de sociétés de plusieurs pays, vous ne surveillerez que les sociétés du pays choisi. <br><br>Si vous souhaitez surveiller des sociétés de plusieurs pays, vous devez définir un profil de surveillance pour chaque pays.',
  'mon.configure.courtJudgmentDetails': 'Injonctions',
  'mon.configure.courtJudgmentDetails.hint':
    "Surveillez si une injonction de paiement a été ordonnée contre une société. Recevez une alerte si de nouvelles injonctions sont imposées. Pour plus d'informations, vous pouvew demandee un nouveau rapport de crédit.",
  'mon.configure.creditAmountAdvised': 'Limite de crédit',
  'mon.configure.creditAmountAdvised.changeMoreThanPercent': '{n}% changement',
  'mon.configure.creditAmountAdvised.hint': 'Surveillez la limite de crédit maximale conseillée',
  'mon.configure.dataType': 'Type de données',
  'mon.configure.events': 'Événements',
  'mon.configure.eventsPosition': 'Administrateurs',
  'mon.configure.eventsPosition.hint':
    "Surveillez les changements de chefs d'entreprise. Recevez une alerte lorsqu'il y a une mise à jour. Les informations mises à jour sont disponibles dans le rapport de crédit.",
  'mon.configure.financialCalamities': 'Calamité financière',
  'mon.configure.financialCalamities.hint':
    "Surveillez les événements liés à l'insolvabilité. Il est recommandé de toujours surveiller cet élément.",
  'mon.configure.financialCalamitiesDetails': 'Calamité financière avec détails',
  'mon.configure.financialCalamitiesDetails.hint':
    "Surveillez les événements liés à l'insolvabilité avec des détails. Il est recommandé de toujours surveiller cet élément.",
  'mon.configure.general': 'Général',
  'mon.configure.graydonRating': 'Notation Graydon',
  'mon.configure.graydonRating.changeMoreThan':
    '{n} {n, plural, =1 {rating category} other {rating categories}}',
  'mon.configure.graydonRating.hint':
    "Surveillez la Notation Graydon. Indication du niveau de risque de crédit associé à l'entreprise, exprimé par un score compris entre AAA (risque minimum) et C (risque maximum). D indique une faillite, NR indique un niveau non coté.",
  'mon.configure.graydonRatingPercentage': "Pourcentage de probabilité d'échec",
  'mon.configure.graydonRatingPercentage.hint':
    "Surveillez le pourcentage de probabilité d'échec. Probabilité qu'une entreprise connaisse de graves difficultés financières (c.-à-d. des événements d'insolvabilité), exprimée en pourcentage.",
  'mon.configure.heading': 'Profil de surveillance ‘{profileName}’',
  'mon.configure.latestAnnualReportFilingDate': 'Dernier bilan annuel déposé',
  'mon.configure.latestAnnualReportFilingDate.hint':
    'Surveillez si un nouveau bilan annuel a été déposé à la Companies House',
  'mon.configure.legalForm': 'Forme juridique',
  'mon.configure.legalForm_1': 'Forme juridique',
  'mon.configure.legalForm_1.hint':
    "Surveillez les changements de forme juridique d'une société. Recevez une alerte lorsqu'il y a une mise à jour. Les informations mises à jour sont disponibles dans le rapport de crédit.",
  'mon.configure.legalForm.changed': 'Nouvelle forme juridique enregistrée\n',
  'mon.configure.legalForm.hint':
    "Surveillez les changements de forme juridique d'une société. Recevez une alerte lorsqu'il y a une mise à jour. Les informations mises à jour sont disponibles dans le rapport de crédit.",
  'mon.configure.liabilityStatement': 'Déclaration de responsabilité',
  'mon.configure.liabilityStatement.hint':
    "Surveillez les changements de déclaration de responsabilité. Recevez une\n alerte s'il y a des modifications. Vous serez informé de l'ancienne et \nde la nouvelle entreprise responsable.",
  'mon.configure.liabilityStatement.updateLabel': 'Entreprise responsable',
  'mon.configure.list.countryNote':
    'Note : seules {organizationCountOfCountry} {organizationCountOfCountry, plural, =1 {company} other {companies}} de {organizationCount} dans la liste sont de {countryCode} et seront surveillées.',
  'mon.configure.list.countryNote.empty':
    'Note : aucune des sociétés {organizationCount} de la liste ne provient de {countryCode}, donc aucune ne sera surveillée par ce profil.',
  'mon.configure.list.createEmptyList': 'Créer nouvelle liste',
  'mon.configure.list.custom.value': 'Valeur personnalisée: {value}',
  'mon.configure.list.custom.value.error': 'Saisissez une valeur comprise entre 0 et 600.',
  'mon.configure.list.custom.value.placeholder': 'Choisissez ou entrez une valeur',
  'mon.configure.list.download': 'Téléchargez la liste',
  'mon.configure.list.goToListManagement': 'Aller à Gestion des listes',
  'mon.configure.list.heading': 'Entreprises',
  'mon.configure.list.headingText': 'Choisissez une liste ou',
  'mon.configure.list.label': 'Surveiller les entreprises de la liste',
  'mon.configure.list.link': 'Voir dans la Gestion des listes',
  'mon.configure.list.link.newTab': '(s’ouvre dans un nouvel onglet)',
  'mon.configure.list.none': 'Aucun',
  'mon.configure.management': 'Administrateurs',
  'mon.configure.management.changed': 'Modification des administrateurs',
  'mon.configure.management.hint':
    "Surveillez les changements de chefs d'entreprise. Recevez une alerte lorsqu'il y a une mise à jour. Les informations mises à jour sont disponibles dans le rapport de crédit.",
  'mon.configure.notification.email.label': 'Envoyer des alertes à',
  'mon.configure.notification.frequency': 'Fréquence',
  'mon.configure.notification.frequency.DAILY': 'Quotidien',
  'mon.configure.notification.frequency.EVERY_FOUR_WEEKS': 'Toutes les quatre semaines',
  'mon.configure.notification.frequency.EVERY_MONTH': 'Chaque mois',
  'mon.configure.notification.frequency.EVERY_SIX_MONTHS': 'Tous les six mois',
  'mon.configure.notification.frequency.EVERY_THREE_MONTHS': 'Tous les trois mois',
  'mon.configure.notification.frequency.EVERY_TWO_MONTHS': 'Tous les deux mois',
  'mon.configure.notification.frequency.EVERY_TWO_WEEKS': 'Toutes les deux semaines',
  'mon.configure.notification.frequency.EVERY_YEAR': 'Annuelle',
  'mon.configure.notification.frequency.note':
    "Note: Vous ne pouvez modifier la fréquence qu'au moment de la création. Cela ne peut pas être changé.",
  'mon.configure.notification.frequency.WEEKLY': 'Hebdomadaire',
  'mon.configure.notification.heading': 'Notification',
  'mon.configure.operator.ANY_CHANGE': 'Tout changement',
  'mon.configure.operator.CHANGE_MORE_THAN': 'Tout changement de plus de',
  'mon.configure.operator.CHANGE_MORE_THAN_PERCENT': 'Tout changement de plus de',
  'mon.configure.operator.HIGHER_OR_LOWER_THAN': 'Devient supérieur ou inférieur à',
  'mon.configure.operator.HIGHER_THAN': 'Devient supérieur à',
  'mon.configure.operator.LOWER_THAN': 'Devient inférieur à',
  'mon.configure.operator.xseptions.ANY_CHANGE': 'Toutes XSeptions',
  'mon.configure.operator.xseptions.ONLY_HIGH_AND_MEDIUM_PRIORITY_XSEPTIONS':
    'Seulement priorité élevée et moyenne',
  'mon.configure.operator.xseptions.ONLY_HIGH_PRIORITY_XSEPTIONS': 'Seulement priorité élevée',
  'mon.configure.paymentScore': 'Score de paiement',
  'mon.configure.paymentScore.hint':
    "Surveillez le score de paiement. Métrique qui indique dans quelle mesure une société respecte ses obligations de paiement. S'exprime par un score compris entre 1 et 10.",
  'mon.configure.phoneNumber': 'Numéro de téléphone',
  'mon.configure.phoneNumber.changed': 'Nouveau numéro de téléphone enregistré',
  'mon.configure.phoneNumber.hint':
    "Surveillez les changements de numéros de téléphone d'une société. Recevez une alerte lorsqu'il y a une mise à jour. Les informations mises à jour sont disponibles dans le rapport de crédit.",
  'mon.configure.profileName': 'Nom du profil',
  'mon.configure.recipients': 'Envoyer des alertes à',
  'mon.configure.recipients.add': 'Ajouter un autre',
  'mon.configure.recipients.none': 'Les mises à jour ne seront pas envoyées par courriel',
  'mon.configure.save': 'Enregistrer et démarrer la surveillance',
  'mon.configure.selection.goToMarketData': 'Aller à Market Data',
  'mon.configure.selection.link': 'Afficher la sélection en Market Data',
  'mon.configure.sendAlerts': 'Envoyer des alertes',
  'mon.configure.sendAlerts.ALWAYS': 'Toujours',
  'mon.configure.sendAlerts.NEVER': 'Non',
  'mon.configure.sendAlerts.ONLY_ON_UPDATE': 'Seulement quand il y a des mises à jour',
  'mon.configure.shareHolders': 'Actionnaires',
  'mon.configure.shareHolders.changed': 'Modification des actionnaires',
  'mon.configure.shareHolders.hint':
    "Surveillez les changements d'actionnaires d'une société. Recevez une alerte lorsqu'il y a une mise à jour. Les informations mises à jour sont disponibles dans le rapport de crédit.",
  'mon.configure.shareholdersDetails': 'Actionnaires',
  'mon.configure.shareholdersDetails.changed': 'Modification des actionnaires',
  'mon.configure.summons': 'Assignations',
  'mon.configure.summons.hint':
    "Surveillez des assignations avec des détails. Recevez une alerte lorsqu'il y a une mise à jour. Vous pourrez voir les détails d'assignations.",
  'mon.configure.threshold': 'Seuil',
  'mon.configure.totalSummons': 'Citations à comparaître',
  'mon.configure.totalSummons.hint':
    "Surveillez le nombre d'assignations. Recevez une alerte lorsqu'il y a une mise à jour. Vous pourrez voir le précédent nombre d'assignations ainsi que le nouveau nombre total d'assignations.",
  'mon.configure.ukAugurRating': 'Augur score',
  'mon.configure.ukAugurRating.hint':
    "Surveillez l'Augur Score. Mesure basée sur l'analyse statistique d'un dossier de crédit, pour représenter le risque ou la solvabilité d'une société. Le score est dérivé empiriquement, et varie de 0 à 600.",
  'mon.configure.ultimateParent': 'Société mère ultime',
  'mon.configure.ultimateParent.hint':
    "Surveillez les changements de la société mère ultime. Recevez une alerte lorsqu'il y a une mise à jour. Les informations mises à jour sont disponibles dans le rapport de crédit.",
  'mon.configure.validation.empty': 'Ce champ ne peut pas être vide',
  'mon.configure.validation.maximum': 'La valeur ne peut pas être supérieure à {max}.',
  'mon.configure.validation.minimum': 'La valeur ne peut pas être inférieure à {min}.',
  'mon.configure.validation.nameAlreadyExists': 'Le nom existe déjà',
  'mon.configure.xseptions': 'XSeptions',
  'mon.configure.xseptions.hint':
    'Surveillez des situations sortant de l’ordinaire dans une société (XSeptions). Recevez une alerte lorsqu’il y a une mise à jour. Vous pouvez choisir de n’être informé que des XSeptions avec une priorité spécifique telle que définie dans vos préférences.',
  'mon.configure.xseptions.link.prio-preferences': 'View XSeptions priority preferences',
  'mon.configure.ubo': 'UBO',
  'mon.configure.ubo.hint':
    "Surveillez les modifications possibles concernant l'UBO (Ultimate Beneficial Owner ou Bénéficiaire Ultime) d'une entreprise. Vous serez averti(e) lorsqu'il y aura un changement. Vous pouvez trouver les informations mises à jour dans le rapport UBO.",
  'mon.createListPopup.success': 'La liste a été créée',
  'mon.createListPopup.success.text':
    "La liste d'entreprises que vous avez créée est jointe au profil de surveillance.",
  'mon.createProfile.back': 'Retour au profil',
  'mon.createProfile.backToEvents': "Afficher la vue d'ensemble des mises à jour",
  'mon.createProfile.backToProfile': 'Voir les paramètres de profil',
  'mon.createProfile.heading': 'Créer un profil de surveillance',
  'mon.createProfileLink': 'Nouveau profil',
  'mon.createProfilePopup.error': 'Le profil ne peut pas être sauvegardé',
  'mon.createProfilePopup.error.nameAlreadyExists': 'Le nom du profil existe déjà',
  'mon.createProfilePopup.note':
    'Les mises à jour correspondant aux nouveaux paramètres de surveillance seront visibles à partir de demain ou de la semaine prochaine, en fonction de la fréquence de notification choisie.',
  'mon.createProfilePopup.success': 'Le profil a été sauvegardé',
  'mon.exportProfiles.button': 'Télécharger des profils',
  'mon.exportProfiles.popup.heading': 'Exporter plusieurs profils de surveillance',
  'mon.exportProfiles.popup.body.parargraph.1':
    "Les employés de Graydon peuvent utiliser cet outil automatisé pour exporter plusieurs profils de surveillance d'un client afin de les importer dans l'application de surveillance de Creditsafe. Téléchargez un fichier CSV dans le format spécifié et choisissez Exporter.",
  'mon.exportProfiles.popup.body.parargraph.2':
    "Pour plus d'informations, veuillez contacter le service informatique de Creditsafe via le service de support:",
  'mon.exportProfiles.popup.body.email': 'service@graydon.nl',
  'mon.exportProfiles.popup.link.upload': 'Téléchargez le fichier CSV',
  'mon.exportProfiles.popup.link.reset': 'Reset',
  'mon.exportProfiles.popup.files.selected': 'Fichier sélectionné',
  'mon.exportProfiles.popup.files.rejected': 'Fichier rejeté',
  'mon.exportProfiles.popup.button.download': 'Exporter',
  'mon.exportProfiles.popup.error.400': 'Problème avec la requête',
  'mon.exportProfiles.popup.error.401': "ous n'êtes pas autorisé",
  'mon.exportProfiles.popup.error.403': "ous n'êtes pas autorisé",
  'mon.exportProfiles.popup.error.404': 'Échec du téléchargement du fichier',
  'mon.exportProfiles.popup.error.500': 'Le traitement du fichier a échoué',
  'mon.exportProfiles.popup.error.unknown': 'Erreur inconnue',
  'mon.exportProfiles.popup.processing': 'Votre fichier est en cours de traitement...',
  'mon.exportProfiles.popup.success': 'Fichier exporté téléchargé',
  'mon.customDownload.button': 'Download history',
  'mon.customDownload.button.mobile': 'Historique',
  'mon.customDownload.heading': 'Custom period',
  'mon.customDownload.placeholder': 'Choisir la semaine',
  'mon.customDownload.text': 'Select a custom period in order to download a collection of updates',
  'mon.customDownload.title': 'Télécharger histoire',
  'mon.defaultProfileName': 'Mon profil',
  'mon.deleteProfileLink': 'Supprimer profil',
  'mon.deleteProfilePopup.cancel': 'Annuler',
  'mon.deleteProfilePopup.confirm': 'Supprimer',
  'mon.deleteProfilePopup.paragraph':
    'Êtes-vous sûr de vouloir supprimer ce profil ? Lorsque vous supprimez un profil, tous les événements de surveillance stockés pour ce profil ne seront plus accessibles.',
  'mon.deleteProfilePopup.success': 'Le profil a été supprimé',
  'mon.deleteProfilePopup.toUpdateOverview': "Retour à l'aperçu des mises à jour",
  'mon.emailInvalid': 'Veuillez entrer une adresse électronique valide',
  'mon.eventsPage.courtJudgmentDetails.amount': 'Á £{amount}',
  'mon.eventsPage.courtJudgmentDetails.cancellation': 'Annulation',
  'mon.eventsPage.courtJudgmentDetails.caseNumber': 'Dossier no.: {caseNumber}',
  'mon.eventsPage.courtJudgmentDetails.judgement': 'Jugement',
  'mon.eventsPage.courtJudgmentDetails.location': 'Enregistré dans {courtName}',
  'mon.eventsPage.courtJudgmentDetails.satisfaction': 'Réparation',
  'mon.eventsPage.download': 'Télécharger',
  'mon.eventsPage.editProfile': 'Modifier le profil',
  'mon.eventsPage.governmentGazette': 'voir Annexe au Moniteur Belge',
  'mon.eventsPage.groupByCompany': 'Groupe par société',
  'mon.eventsPage.heading': "Mises à jour dans '{profileName}'.",
  'mon.eventsPage.list': 'Liste',
  'mon.eventsPage.noUpdates.emptyList':
    "Il n'y a pas de mises à jour, car la liste des sociétés que vous surveillez ne contient pas encore de sociétés.",
  'mon.eventsPage.noUpdates.general': "Il n'y a pas de mises à jour pour la période choisie.",
  'mon.eventsPage.noUpdates.noList':
    "Il n'y a pas de mise à jour, car il n'y a pas de liste d'entreprises liées à ce profil de surveillance.",
  'mon.eventsPage.noUpdates.noList.link': 'Modifier le profil',
  'mon.eventsPage.noUpdates.noList.link.after': 'et relier une liste au profil.',
  'mon.eventsPage.numUpdates': '{numUpdates} {numUpdates, plural, =1 {update} other {updates}}',
  'mon.eventsPage.numUpdates.in': 'dans',
  'mon.eventsPage.oldValue': '(était : {oldValue})',
  'mon.eventsPage.profileTile.hint':
    'Vous pouvez configurer plusieurs profils de surveillance. Pour chaque profil, vous pouvez sélectionner une liste des sociétés que vous souhaitez suivre et définir les types d’événements que vous souhaitez suivre.',
  'mon.eventsPage.since': 'Depuis',
  'mon.eventsPage.updates': 'Mises à jour',
  'mon.eventsPage.updates.hint':
    'Vous verrez les mises à jour qui correspondent à vos paramètres de profil de surveillance et qui ont eu lieu dans la période choisie. Si vous ne voyez aucune mise à jour, vous pouvez modifier les paramètres de votre profil.',
  'mon.export.title.graydonInsights': 'Graydon Insights',
  'mon.export.title.creditSafe': 'Creditsafe',
  'mon.export.migrate.button': 'How to migrate to Creditsafe?',
  'mon.export.download.button': 'Download profile settings',
  'mon.export.operator.ANY_CHANGE': 'Any change',
  'mon.export.operator.CHANGE_MORE_THAN': 'Reduce by',
  'mon.export.operator.CHANGE_MORE_THAN_PERCENT': 'Reduce by',
  'mon.export.operator.HIGHER_OR_LOWER_THAN': 'Less then',
  'mon.export.operator.ONLY_HIGH_AND_MEDIUM_PRIORITY_XSEPTIONS': 'Any change',
  'mon.export.operator.ONLY_HIGH_PRIORITY_XSEPTIONS': 'Any change',
  'mon.export.configure.general': 'Portfolios / Edit portfolio',
  'mon.export.configure.events': 'Notification rules',
  'mon.export.configure.notification.heading': 'Portfolios / Edit portfolio',
  'mon.export.configure.profileName': 'Portfolio name',
  'mon.export.configure.list.label': 'Selected companies',
  'mon.export.configure.list.value': '[Import companies from file]',
  'mon.export.configure.recipients': 'Emails',
  'mon.export.configure.financialCalamitiesDetails': 'Bankruptcy data',
  'mon.export.configure.creditAmountAdvised': 'Limit',
  'mon.export.configure.creditAmountAdvised.extra':
    'Credit Limit drops below internal (customer) credit limit',
  'mon.export.configure.graydonRating': 'Creditscore',
  'mon.export.configure.graydonRating.higherLower': 'International Score & Return Band',
  'mon.export.configure.xseptions': 'Xseptions Addition',
  'mon.export.configure.xseptions.extra': 'Xseptions Removal',
  'mon.export.configure.annualReport': 'New accounts filed',
  'mon.export.configure.beMultiscore': 'Creditscore',
  'mon.export.configure.beMultiscore.higherLower': 'International Score & Return Band',
  'mon.export.configure.paymentScore': 'Payment experience left',
  'mon.export.configure.ultimateParent': 'Ultimate Holding Company',
  'mon.export.configure.eventsPosition': 'Director(s)',
  'mon.export.configure.management': 'Director(s)',
  'mon.export.configure.shareholdersDetails': 'Share capital change',
  'mon.export.configure.liabilityStatement': 'Declaration of Liability (403)',
  'mon.export.configure.companyName': 'Company name',
  'mon.export.configure.address_1': 'Address',
  'mon.export.configure.phoneNumber': 'Telephone Number',
  'mon.financialCalamities.yes': 'Oui',
  'mon.onboarding.noupdates': "Il n'y a pas encore de mises à jour.",
  'mon.onboarding.start': 'Démarrer la surveillance',
  'mon.onboarding.step1': 'Créer un profil de surveillance',
  'mon.onboarding.step2': 'Créer une nouvelle liste',
  'mon.onboarding.step3': 'Ajoutez des entreprises à la liste comme suit :',
  'mon.onboarding.step3-import-link': "importer une liste d'entreprises",
  'mon.onboarding.step3-import-then': 'via Gestion des listes',
  'mon.onboarding.step3-search-link': 'chercher une entreprise',
  'mon.onboarding.step3-search-then': "et l'ajouter par l'intermédiaire de Rapports de crédit ou",
  'mon.onboarding.to-start-take-steps':
    'Pour commencer à surveiller les entreprises, suivez les étapes suivantes :',
  'mon.onboarding.unauthorized':
    "Vous n'avez pas la permission de créer un profil de surveillance. Demandez d'abord à une personne ayant les droits d'administrateur de créer un profil.",
  'mon.period': 'Période',
  'mon.period.lastWeek': 'La semaine dernière ({date})',
  'mon.period.yesterday': 'Hier ({date})',
  'mon.portfolioAnalyser': 'Mon portefeuille',
  'mon.portfolioAnalyser.company.reference': 'Votre référence',
  'mon.portfolioAnalyser.company.registrationId': "Numéro officiel d'entreprise",
  'mon.portfolioAnalyser.company.registrationId.be': "Numéro d'entreprise",
  'mon.portfolioAnalyser.company.registrationId.gb': "Numéro d'entreprise",
  'mon.portfolioAnalyser.company.registrationId.nl': "Numéro d'identification (KvK)",
  'mon.portfolioAnalyser.companyList': 'Liste des entreprises',
  'mon.portfolioAnalyser.companyList.hint':
    'Choisissez une liste et accédez aux données des sociétés incluses dans cette liste.',
  'mon.portfolioAnalyser.companyOverview': "Données de l'entreprise",
  'mon.portfolioAnalyser.companyOverview.hint':
    '<b>Graydon Rating</b><br/>\r\nLa probabilité qu’une société  soit en défaut de paiement dans les 12 prochains mois.<br/>\r\n<br/>\r\n<b>Multiscore</b><br/>\r\nMétrique quant au niveau de risques de crédit associés à une société. S’exprime par un score allant de 1 à 100.<br/>\r\n<br/>\r\n<b>Augur score</b><br/>\r\nScore empirique (allant de 0 à 600) basé sur des information clé à propos d’une société et jaugeant les risques de credit.<br/>',
  'mon.portfolioAnalyser.download': 'Télécharger',
  'mon.collapseReferences': 'Réduire les références à une ligne',
  'mon.portfolioAnalyser.graydonRatingOverview': 'Notation Graydon',
  'mon.portfolioAnalyser.graydonRatingOverview.AAA_AA_A': 'AAA/AA/A',
  'mon.portfolioAnalyser.graydonRatingOverview.BBB_BB_B': 'BBB/BB/B',
  'mon.portfolioAnalyser.graydonRatingOverview.CCC_CC_C': 'CCC/CC/C',
  'mon.portfolioAnalyser.graydonRatingOverview.D': 'D - Failliete',
  'mon.portfolioAnalyser.graydonRatingOverview.hint':
    'Voici la composition du Graydon Rating pour les sociétés incluses dans cette liste.',
  'mon.portfolioAnalyser.graydonRatingOverview.NON_MONITORED': 'Non surveillé',
  'mon.portfolioAnalyser.graydonRatingOverview.NR': 'Pas notée',
  'mon.portfolioAnalyser.monitoringMessage':
    'Les données Graydon ne sont disponibles que pour les sociétés incluses dans une liste de contrôle / de monitoring.',
  'mon.portfolioAnalyser.monitoringMessage.link': 'Configurez le contrôle / le monitoring',
  'mon.portfolioAnalyser.table.limitNote':
    "N.B.: Votre liste d'entreprises (filtrée) contient {totalAmount} entreprises. Nous ne montrons que les {shownAmount} premiers.",
  'mon.profile.noList': 'Pas de liste',
  'mon.profileLabel': 'Profil',
  'mon.showPortfolioAnalyser': 'Portefeuille actuelle',
  'mon.updateProfilePopup.error': "Les changements de profil n'ont pas pu être sauvegardés",
  'mon.updateProfilePopup.note':
    'Les mises à jour correspondant aux nouveaux paramètres de surveillance seront visibles à partir de demain ou de la semaine prochaine, en fonction de la fréquence de notification choisie.',
  'mon.updateProfilePopup.showUpdateOverview': "Afficher la vue d'ensemble des mises à jour",
  'mon.updateProfilePopup.success': 'Les changements de profil ont été sauvegardés',
  'nav.dm.archive': 'Archives',
  'nav.dm.create': 'Créer un modèle de décision',
  'nav.dm.modelmanagement': 'Modèle de gestion',
  'nav.dm.view': ' ',
  'nav.home': 'Accueil',
  'nav.mdb.dashboard': 'Gestion du tableau de bord',
  'nav.search': 'Rechercher une société',
  'notifications.title': 'Notifications',
  'one-search.company': 'Société',
  'one-search.company.form.description':
    'Selectionnez un pays et saisissez le nom de la société ou des autres données distinctives.',
  'one-search.company.form.error': 'Error in fetching country list. Please try again later',
  'one-search.company.form.title': "Recherchez des données de l'entreprise",
  'one-search.company.search-bar.placeholder': 'Saisissez les données de la société',
  'one-search.person': 'Personne',
  'pdf.annualAccount.accountStatus': 'Statut compte',
  'pdf.annualAccount.accountStatus.filed': 'Déposé',
  'pdf.annualAccount.accountStatus.unknown': 'Inconnu',
  'pdf.annualAccount.baseCurrency': 'Cours de base',
  'pdf.annualAccount.baseUnit': 'Unité de base',
  'pdf.annualAccount.creditRatios': 'Ratios de crédit importants',
  'pdf.annualAccount.dateOfClosure': 'Fin de la durée',
  'pdf.annualReport.natuur10.text':
    'En raison du modèle spécifique, le bilan annuel de cette société ne peut être traité. Vous pouvez consulté le bilan original depuis le site internet de la <a href="https://www.nbb.be/fr" rel="noopener noreferrer" target="_blank">Banque Nationale de Belgique</a>. Si vous avez des questions ou avez besoin d’aide, veuillez contacter notre équipe du Service Clients.',
  'pdf.annualReport.natuur10.title': 'Cette société a déposé un type de bilan annuel exceptionnel',
  'pdf.annualReport.noReport.text':
    "Cette société n'a déposé aucun bilan comptable au cours de ces cinq dernières années.",
  'pdf.annualReport.noReport.title': 'Aucune calamités financières pour cette société',
  'pdf.paymentBehaviour.noData.text':
    "Malheureusement, nous n'avons pas suffisamment d'informations disponibles pour une analyse appropriée.",
  'pdf.annualReport.noReportLiability.text':
    "Ni cette société, ni la société assujettie à l'impôt n'ont déposé de bilan comptable au cours de ces cinq dernières années.",
  'pdf.annualReport.noReportLiability.title': 'Aucun bilan annuel disponible',
  'pdf.branches.limit-message':
    '20+ filiales, la liste complète est disponible dans le rapport numérique',
  'pdf.copyright.statement':
    'Copyright {yearIssued} {customerCountry, select, gb {Graydon UK Limited} be {Graydon Belgium N.V.} nl {Graydon Nederland B.V.} other {Graydon UK Limited}}',
  'pdf.footer.text':
    'Ce {productName} est destiné à l’usage exclusif de {companyName} et les utilisateurs approuvés contractuellement – {userName}.',
  'pdf.footer.ubo': "Rapport d'UBO - {companyName} - {requestorName}",
  'pdf.generic-no-data.text':
    'Si vous avez des questions ou avez besoin d’aide, veuillez contacter notre équipe du Service Clients.',
  'pdf.generic-no-data.title': "Cette information n'est pas disponible pour cette société",
  'pdf.legal.contact':
    'Contactez notre service clients au <b>{country, select, gb {+44&nbsp;(0)20&nbsp;8515&nbsp;1400} nl {+31&nbsp;(020)&nbsp;567&nbsp;9501} be {+32&nbsp;(03)&nbsp;280&nbsp;88&nbsp;80} other {+44&nbsp;(0)20&nbsp;8515&nbsp;1400}}</b> or <b><a href="mailto:{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} be {support@graydon.be} other {customerservices@graydon.co.uk}}">{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} be {support@graydon.be} other {customerservices@graydon.co.uk}}</a></b>',
  'pdf.legal.contact.heading': 'Des questions?',
  'pdf.legal.copyright': 'Copyright: {graydonName}',
  'pdf.legal.disclaimer':
    'Les conditions générales de Graydon sont applicables à ce Graydon rapport de crédit . Le contenu de ce Graydon rapport de crédit est confidentiel et ne peut être fourni à des tiers (en tout ou en partie). Vous êtes autorisé à utiliser ce contenu uniquement pour votre propre usage professionnel interne.',
  'pdf.legal.graydon.BE': 'Graydon Belgium N.V.',
  'pdf.legal.graydon.GB': 'Graydon UK Ltd.',
  'pdf.legal.graydon.NL': 'Graydon Nederland B.V.',
  'pdf.legal.report.requested.by': 'Rapport demandé par',
  'pdf.legal.statement':
    'Les conditions générales de {customerCountry, select, gb {Graydon UK Limited} be {Graydon Belgium N.V.} nl {Graydon Nederland B.V.} other {Graydon UK Limited}} sont applicables à ce Graydon {productName}. Le contenu de ce Graydon {productName} est confidentiel et ne peut être fourni à des tiers (en tout ou en partie). Vous êtes uniquement autorisé à utiliser ce contenu pour votre propre usage professionnelle interne.',
  'pdf.section.h1.company-profile': "Profile de l'entreprise",
  'pdf.section.h1.company-structure': "Structure de l'entreprise",
  'pdf.section.h1.exceptional-events': 'Facteurs exceptionnels',
  'pdf.section.h1.financials': 'Finances',
  'pdf.section.h1.payment.behaviour': 'Comportement de paiement',
  'pdf.section.h1.ratings': 'Notations',
  'pdf.section.h1.summary': 'Résumé',
  'pdf.section.h2.balance-sheet': 'Comptes annuels',
  'pdf.section.h2.branches': 'Filiales',
  'pdf.section.h2.company-contacts': "Contacts de l'entreprise",
  'pdf.section.h2.company-details': 'Détails de la société',
  'pdf.section.h2.credit.flag': 'Drapeau de crédit',
  'pdf.section.h2.credit.flag.maximum.advised':
    'La limite maximale de crédit recommandée sur 12 mois',
  'pdf.section.h2.credit.limit': 'Limite de crédit',
  'pdf.section.h2.credit.limit.trend': 'Tendance de la Limite de Crédit',
  'pdf.section.h2.credit.rating': 'Notation de crédit',
  'pdf.section.h2.credit.rating.graydon.percentage': 'Pourcentage Graydon',
  'pdf.section.h2.credit.rating.graydon.rating': 'Notation Graydon',
  'pdf.section.h2.directors': 'Administrateurs',
  'pdf.section.h2.discontinuation.score': 'Probabilité de discontinuité',
  'pdf.section.h2.events': 'Evénements',
  'pdf.section.h2.events.BE': 'Historique',
  'pdf.section.h2.financial-insolvencies': 'Calamités financières',
  'pdf.section.h2.general-information': 'Information général sur les comptes annuels',
  'pdf.section.h2.group-structure': 'Structure du groupe',
  'pdf.section.h2.growth.score': 'Score de croissance',
  'pdf.section.h2.key-figures': 'Chiffres clés',
  'pdf.section.h2.liability': 'Passif',
  'pdf.section.h2.order-data': 'Order data',
  'pdf.section.h2.others': 'Autres',
  'pdf.section.h2.payment.analysis.table': 'Analyse du comportement de paiement',
  'pdf.section.h2.payment.ccj.details': 'Détails des injonctions',
  'pdf.section.h2.payment.mortgages': 'Hypothèques',
  'pdf.section.h2.payment.payment.averages': 'Moyenne des paiements',
  'pdf.section.h2.payment.score': 'Score de paiement',
  'pdf.section.h2.payment.score.trend': 'Evolution du Score de Paiement',
  'pdf.section.h2.payment.summons.and.judgements': 'Assignations',
  'pdf.section.h2.payment.terms': 'Comportement de paiement pour les 4 derniers trimestres',
  'pdf.section.h2.payment.trend': 'Days Beyond Terms Trend',
  'pdf.section.h2.profit-and-loss': 'Pertes et profits',
  'pdf.section.h2.rating.trend': 'Tendance de la Notation Graydon',
  'pdf.section.h2.shareholders': 'Actionnaires',
  'pdf.section.h2.summary.company-data': 'Détails de la société',
  'pdf.section.h2.summary.company-structure': "Structure de l'entreprise",
  'pdf.section.h2.summary.insolvency': 'Calamité financière',
  'pdf.section.h2.summary.key-figures': 'Chiffres clés',
  'pdf.section.h2.summary.payment-score-ccj-summons.BE':
    'Score de paiement, assignations & jugements',
  'pdf.section.h2.summary.payment-score-ccj-summons.GB': 'Comportement de paiement & injonctions',
  'pdf.section.h2.summary.payment-score-ccj-summons.NL': 'Score de paiement',
  'pdf.section.h2.tradenames': 'Dénominations commerciales',
  'pdf.section.h2.xseptions.BE': 'XSeptions',
  'pdf.section.h2.xseptions.GB': 'XSeptions',
  'pdf.section.h2.xseptions.NL': 'XSeptions',
  'pdf.section.h3.order-comment': 'Comments',
  'pdf.section.h3.order-number': 'Order number',
  'pdf.section.h3.order-reference': 'Order reference',
  'pdf.support.statement':
    '**Si vous avez des questions ou avez besoin d’aide, veuillez contacter notre Service Clients**. Contacter notre équipe du Service Clients par téléphone au **{country, select, gb {+44 (0)20 8515 1400} be {+32 (03) 280 88 80} nl {+31 (0)20 567 9501} other {+44 (0)20 8515 1400}}** ou à l\'adresse suivante: <a href="mailto:{country, select, gb {customerservices@graydon.co.uk} be {support@graydon.be} nl {service@graydon.nl} other {customerservices@graydon.co.uk}}">**{country, select, gb {customerservices@graydon.co.uk} be {support@graydon.be} nl {service@graydon.nl} other {customerservices@graydon.co.uk}}**</a>',
  'pdf.tradenames.limit-message':
    '20+ dénominations commerciales, la liste complète est disponible dans le rapport numérique',
  'pdf.tradenames.title': 'Dénominations commerciales',
  'person.birthdate': 'Date de naissance',
  'person.gender.FEMALE.title': 'Mme',
  'person.gender.MALE.title': 'Mr',
  'placeSearch.placeholder': 'Entrer un lieu',
  'position.authorization': 'Autorisation',
  'position.startdate': 'Date de début',
  'position.title': 'Titre',
  radius: 'rayon',
  rangeTo: 'au',
  'relation.company-link.warning':
    'ATTENTION : en cliquant sur le lien de la société associée, vous demandez un nouveau rapport Highlight. Cette action sera alors portée sur votre compte.',
  'search-results.item.address': 'Adresse',
  'search-results.item.Buyers_Number': 'Buyers Number',
  'search-results.item.Chamber_Of_Commerce': 'Chambres de commerce',
  'search-results.item.Company_Registration_Number': "Numéro d'entreprise",
  'search-results.item.contact.email': "Trouvé dans l'adresse e-mail: {value}",
  'search-results.item.contact.telephoneNumber': 'Trouvé dans le numéro de téléphone: {value}',
  'search-results.item.Fiscal_Code': 'Fiscal Code',
  'search-results.item.gbYCompanyId': 'Trouvé dans le numéro Graydon: {value}',
  'search-results.item.id': 'Numéro Graydon',
  'search-results.item.Internal': 'Company number',
  'search-results.item.National_Government_Business_Identification_Number':
    'National Government Business Identification Number',
  'search-results.item.National1': 'National1',
  'search-results.item.National2': 'National2',
  'search-results.item.NIT': 'NIT',
  'search-results.item.previousCompanyName':
    "Trouvé dans le nom précédent de l'entreprise : {value}",
  'search-results.item.previousRegisteredAddress': "Trouvé dans l'adresse précédente : {value}",
  'search-results.item.registrationNumber.be': "Numéro d'entreprise",
  'search-results.item.registrationNumber.gb': "Numéro d'entreprise",
  'search-results.item.registrationNumber.nl': "Numéro d'identification (KvK)",
  'search-results.item.RIF': 'RIF',
  'search-results.item.RUC_Number': 'RUC Number',
  'search-results.item.SIREN_Number': 'SIREN Number',
  'search-results.item.SIRET_Number': 'SIRET Number',
  'search-results.item.tradeName': 'Trouvé dans la dénomination commerciale : {value}',
  'search-results.item.tradeNames': 'Trouvé dans la dénomination commerciale : {value}',
  'search-results.item.tradingAddress': "Trouvé dans l'adresse commerciale : {value}",
  'search-results.item.TSR': 'TSR',
  'search-results.item.type.BRANCH_OFFICE': 'F',
  'search-results.item.type.ROOT_ORG': 'STÉ',
  'search-results.item.type.tooltip.BRANCH_OFFICE': 'Filiale',
  'search-results.item.type.tooltip.ROOT_ORG': 'Société récemment constituée',
  'search-results.item.Unique_Company_Number': 'Unique Company Number',
  'search-results.item.Unknown': 'Inconnu',
  'search-results.item.VAT': "Numéro d'entreprise",
  'search-results.item.vatNumber': 'Trouvé dans le numéro de TVA : {value}',
  'search-results.item.Web_Address': 'Web Address',
  'search.advanced.clear': 'Effacez',
  'search.advanced.close': 'Fermer',
  'search.advanced.heading': 'Recherche avancée',
  'search.advanced.open': 'Recherche avancée',
  'search.advanced.subheading': 'Vous pouvez utiliser plusieurs champs',
  'search.autocomplete.all': 'montrer tous {total} les résultats',
  'search.autocomplete.less': 'Poursuivez la saisie ou…',
  'search.autocomplete.max': 'Afficher les premiers {total} résultats',
  'search.companies.found': '{total} {total, plural, =1 {entreprise} other {entreprises}} trouvées',
  'search.error': 'Oops ! Nous n’avons pas pu effectuer votre recherche. Réessayez.',
  'search.heading': 'Recherchez une entreprise',
  'search.heading.subtext': "Vérifiez si la société répond aux critères d'accceptation",
  'search.in': 'Sélectionner un pays',
  'search.name.obsolete': "Trouvé sous le nom précédent de l'entreprise {name}",
  'search.no.results': 'Aucun résultat trouvé',
  'search.no.results.check-spelling': "Contrôler l'orthographe",
  'search.no.results.check-the-following':
    'Aucun résultat trouvé. Veuillez contrôler les données suivantes, le cas échéant',
  'search.no.results.for': 'Aucun résultat trouvé pour “{query}”',
  'search.no.results.identifier': 'Contrôler chaque identifiant saisi (ex. numéro de TVA)',
  'search.no.results.refine-search': "Affiner votre recherche en ajoutant d'autres informations",
  'search.no.results.refine-search.extended':
    'Affinez votre recherche en ajoutant plus d’informations dans le champs de recherche avancée',
  'search.placeholder':
    "Saisissez le nom de la société, un mot clé, le numéro d'identification ou de TVA (en incluant le préfixe GB et sans espace entre les chiffres).",
  'search.placeholder.be': "Saisissez le nom de la société, le numéro d'entreprise ou de TVA",
  'search.placeholder.gb': "Saisissez le nom de la société, le numéro d'entreprise ou de TVA",
  'search.placeholder.long': "Saisissez le nom de la société, le numéro d'identification ou de TVA",
  'search.placeholder.nl':
    "Saisissez le nom de la société, le numéro d'identification (kvk) ou de TVA",
  'search.results.found': '{total} {total, plural, =1 {result} other {results}} trouvé(s).',
  'search.tooltip.country': 'Rechercher les sociétés {country}',
  'search.tradingName': 'Trouvé comme nom de commerce {name}',
  'session.expired.close': 'OK',
  'session.expired.description': 'Votre session est écoulée',
  'share.check-result': 'Partager ce résultat',
  'share.creditcheck.body':
    'Bonjour, j’ai effectué ce check {url}. Pouvez-vous y jeter un coup d’oeil ?',
  'share.creditcheck.subject': 'Un credit check a été partagé avec vous',
  'shr.archive.subtitle':
    "Vue d'archive pour toutes les demandes effectuées depuis votre compte vers le système Graydon.",
  'shr.archive.table.address': 'Adresse',
  'shr.archive.table.country': 'Pays',
  'shr.archive.table.date': 'Date de la commande',
  'shr.archive.table.download': 'Télécharger le rapport',
  'shr.archive.table.download.en': 'PDF en anglais',
  'shr.archive.table.download.fr': 'PDF en français',
  'shr.archive.table.download.nl': 'PDF en néerlandais',
  'shr.archive.table.person-name': 'Nom de la personne',
  'shr.archive.table.report': 'Rapport',
  'shr.archive.title': 'Archive',
  'shr.pdf.footer':
    "{personName} - Ce rapport Graydon Stakeholder est destiné à l'usage exclusif de: {requestorCustomer} - {requestorName}",
  'shr.report.archive.tooltip':
    "Vous trouverez ici tous les rapports de parties prenantes précédemment consultés, triés par date de consultation. Vous pouvez télécharger à nouveau les rapports dans l'une des trois langues disponibles.",
  'shr.report.company-overview.insolvency.active': 'Insolvabilté financière (en cours)',
  'shr.report.company-overview.insolvency.inactive': "Pas d'objection",
  'shr.report.company-overview.row.city': 'Ville',
  'shr.report.company-overview.row.company': 'Société',
  'shr.report.company-overview.row.company-address': 'Adresse de la société',
  'shr.report.company-overview.row.company-number': 'Numéro de société',
  'shr.report.company-overview.row.date': 'Date de début et de fin',
  'shr.report.company-overview.row.insolvency': 'Insolvabilité (financière)',
  'shr.report.company-overview.row.position': 'Position',
  'shr.report.company-overview.title': "Présentation de l'entreprise ({positions} positions)",
  'shr.report.company-overview.tooltip':
    'Ce rapport présente toutes les positions actives et inactives des parties prenantes. Les positions sont triées par date. Vous pouvez consulter un rapport sur les différentes organisations si cette option est activée dans votre contrat.',
  'shr.search.add-birth-date': 'Ajouter la date de naissance',
  'shr.search.date-invalid': 'Veuillez remplir une date correcte',
  'shr.search.date-month-and-year': 'Veuillez remplir un mois et un an',
  'shr.search.dateOfBirth.day': 'Jour',
  'shr.search.dateOfBirth.month': 'Mois*',
  'shr.search.dateOfBirth.year': 'Année*',
  'shr.search.input.placeholder':
    'Entrez le nom et/ou le prénom de la personne que vous recherchez.',
  'shr.search.result.address': 'Adresse',
  'shr.search.result.birthDate': 'Date de naissance',
  'shr.search.result.personId': 'Identification de la personne',
  'shr.search.result.position': '**{role}** dans la **{company}**',
  'shr.search.result.request-report': 'Demander un rapport',
  'shr.search.result.total': '{total} des résultats trouvés',
  'shr.search.subtitle':
    'Veuillez ajouter le nom de la partie prenante que vous souhaitez trouver. Utilisez la date de naissance si nécessaire.',
  'shr.search.title': 'Recherche d’information sur les parties prenantes',
  'ubo.report.bulkComplianceCheck.complete.body':
    'Voir les informations de vérification de la conformité sur la page',
  'ubo.report.bulkComplianceCheck.complete.heading': 'Le rapport a été mis à jour',
  'ubo.report.bulkComplianceCheck.loading.body':
    "Un nouveau rapport comprenant toutes les données de contrôle de conformité est généré. Vous trouverez le rapport dans [Company Archive](/company-archive/organisation/{enterpriseId}) dès qu'il sont disponible.",
  'ubo.report.bulkComplianceCheck.loading.heading': 'Le rapport est généré',
  'ubo.report.compliance.check.modal.cancel': 'Annuler',
  'ubo.report.compliance.check.modal.description':
    "Ajoutez le prénom habituel (s'il est connu) pour obtenir de meilleurs résultats de vérification de conformité.\n",
  'ubo.report.compliance.check.modal.input-label': 'Prénom habituel (optionnel)',
  'ubo.report.compliance.check.modal.name': 'Nom',
  'ubo.report.compliance.check.modal.submit': 'Vérification de conformité',
  'ubo.report.compliance.check.modal.title': "Vérification de conformité d'une personne",
  'ubo.report.complianceCheck.request.bulk': 'Tous les verifications de conformité',
  'ubo.report.complianceCheck.request.bulk.all': 'Vérifiez tous les UBOs et directeurs',
  'ubo.report.complianceCheck.request.bulk.companies': 'Vérifiez tous les enterprises',
  'ubo.report.complianceCheck.request.bulk.ubo': 'Vérifiez tous les UBOs',
  'ubo.report.complianceCheck.request.info':
    'Vous pouvez effectuer une vérification de la conformité pour toutes les personnes ou toutes les enterprises à la fois ou pour chaque UBO ou administrateur/-trice ou enterprise séparément.',
  'ubo.report.complianceCheck.usage.report.download': 'Utilisation de vérifications (.xlsx)',
  'ubo.report.directors.title': 'Administrateurs (seulement des gens)',
  'ubo.report.downloadPdf': 'Télécharger le rapport',
  'ubo.report.downloadPdf.en-GB': 'PDF en anglais',
  'ubo.report.downloadPdf.fr': 'PDF en français',
  'ubo.report.downloadPdf.nl-NL': 'PDF en néerlandais',
  'ubo.report.heading': 'Rapport UBO',
  'ubo.report.requestedDate': 'Date',
  'ubo.report.requester': 'Demandeur du rapport',
  'ubo.report.summary.explanation':
    "La recherche UBO montre tous les bénéficiaires finaux dans la structure de groupe de l'entreprise demandée. Chaque branche de la structure est un chemin vers l'entité la plus élevée. De plus, tous les administrateurs de la société demandée sont affichés.\n",
  'ubo.report.summary.heading': 'UBO sommaire',
  'ubo.report.summary.hint':
    "Les résultats sur lesquels il faut éviter de tomber sont les suivants :\n* Un __UBO a été trouvé__. Dans ce cas, l'UBO trouvé et le chemin qui mène à cette personne sont affichés.\n* Un __UBO est introuvable__ dans ce lien. Dans ce cas, le plus haut directeur trouvé et le lien qui y mènent sont affichés.\n* Les __données sont insuffisantes__ pour déterminer un UBO dans ce chemin. Dans ce cas, nous n'avons pu trouver personne et seul le chemin est affiché.\n* Le lien est __opaque / complexe__. Si une entité apparaît plusieurs fois sur un même lien, une référence circulaire est alors créée et le lien sera affiché. Une enquête plus approfondie est alors recommandée.\n",
  'ubo.report.summary.numberOf.companies':
    '{numberOfCompanies, plural, =0 {aucune entreprise} =1 {# entreprise unique} other {# entreprises uniques}}',
  'ubo.report.summary.numberOf.directors':
    "{numberOfDirectors, plural, =0 {pas d'administrateur} =1 {# administrateur} other {# administrateurs}}",
  'ubo.report.summary.numberOf.paths':
    '{numberOfSearchPaths, plural, =0 {pas de chemins} =1 {1 chemin} other {{numberOfSearchPaths} chemins}}',
  'ubo.report.summary.numberOf.ubos':
    '{numberOfUbos, plural, =0 {pas d’UBO} =1 {# UBO (possible)} other {# UBOs (possible)}}',
  'ubo.report.summary.results': 'Résultats',
  'ubo.report.ubo.search.result.compliance.hint.company':
    "Un contrôle de conformité pour une entreprise consiste toujours en 3 types de contrôles :\n* __Liste de sanctions__: l'entité figure sur une ou plusieurs des nombreuses listes de sanctions officielles tenues par les gouvernements du monde entier.\n* __Couvertures médiatiques défavorables__: WorldCompliance(TM) surveille les journaux dans plus de 15 langues pour les informations relatives aux activités illicites, telles que le blanchiment d'argent, la fraude, le trafic de drogue, le terrorisme, la corruption pour n'en nommer que quelques-unes.\n* __Entreprise d'État__: entreprise commerciale dans laquelle le gouvernement ou l'État exerce un contrôle important par le biais d'une propriété pleine, majoritaire ou minoritaire importante.\n\nIl y a trois résultats possibles:\n* __NO MATCH__ (pas de correspondance): L'entreprise en question n'est en aucun cas mise en avant dans le cadre du contrôle de conformité.\n* __MATCH EXACT__ (Correspondance exacte): L'entreprise apparaît dans un ou plusieurs composants du contrôle de conformité. Le nom, le pays et l'année de naissance de la personne correspondent aux données de l'une des inscriptions.\n* __MATCH POSSIBLE__ (Correspondance possible): L'entreprise apparaît dans un ou plusieurs composants du contrôle de conformité. Le nom et le pays correspondent, mais l'année de naissance peut ne pas être associée aux données de l'une des inscriptions.\n",
  'ubo.report.ubo.search.result.compliance.hint.person':
    "Un contrôle de conformité pour une personne consiste toujours en 3 types de contrôles :\n* __Personne politiquement exposée (PEP)__: le terme inclut généralement une personnalité politique étrangère actuelle ou passée, sa famille immédiate et ses proches associés.\n* __Liste de sanctions__: l'entité figure sur une ou plusieurs des nombreuses listes de sanctions officielles tenues par les gouvernements du monde entier.\n* __Couvertures médiatiques défavorables__: WorldCompliance(TM) surveille les journaux dans plus de 15 langues pour les informations relatives aux activités illicites, telles que le blanchiment d'argent, la fraude, le trafic de drogue, le terrorisme, la corruption pour n'en nommer que quelques-unes.\n\nIl y a trois résultats possibles:\n* __NO MATCH__ (pas de correspondance): La personne en question n'est en aucun cas mise en avant dans le cadre du contrôle de conformité.\n* __MATCH EXACT__ (Correspondance exacte): la personne apparaît dans un ou plusieurs composants du contrôle de conformité. Le nom, le pays et l'année de naissance de la personne correspondent aux données de l'une des inscriptions.\n* __MATCH POSSIBLE__ (Correspondance possible): la personne apparaît dans un ou plusieurs composants du contrôle de conformité. Le nom et le pays correspondent, mais l'année de naissance peut ne pas être associée aux données de l'une des inscriptions.\n",
  'ubo.report.ubo.search.result.hint':
    "# Niveau\nReprésentation du niveau le plus élevé du lien. Chaque élément de la structure du groupe compte comme un niveau.\n\n# Type de résultat\nLe rôle de la personne trouvée dans l'entité la plus élevée du lien de cheminement est représenté.\n\n# Participation ultime dans la société demandée\nLa participation ultime (% actions) que la personne identifiée détient dans l'entreprise requise.\n\n# Profession et autorisation\nPosition et autorisation de la personne la plus élevée identifiée dans cette structure.",
  'ubo.report.ubo.search.result.path.hint':
    "# Contrôle ETNC/GAFI\nEtats et territoires non coopératifs, en abrégé : ETNC. Une liste publiée par le Groupe d'action financière (GAFI) des pays et territoires ne participant pas à la prévention du blanchiment d'argent\n\n# Participation ultime dans la société demandée\nPourcentage de parts détenues par l'entreprise à ce niveau pour la société demandée",
  'ubo.report.uboSearch.result': 'Chemin de recherche pour les UBOs',
  'ubo.report.uboSearch.result.authorisation': 'Autorisation',
  'ubo.report.uboSearch.result.collapsePath': 'Masquer le chemin',
  'ubo.report.uboSearch.result.compliance.button.text': 'Vérification de conformité',
  'ubo.report.uboSearch.result.compliance.checkType': 'Type de vérification',
  'ubo.report.uboSearch.result.compliance.date': 'Vérification de conformité effectuée le',
  'ubo.report.uboSearch.result.compliance.dateofbirth': 'Date de naissance',
  'ubo.report.uboSearch.result.compliance.details': 'Détails',
  'ubo.report.uboSearch.result.compliance.explanation':
    'Une vérification de conformité pour un individu consiste toujours en 3 vérifications: une vérification si la personne est manifestement active en politique (vérification de personnalité politiquement exposée ou PPE), une vérification si la personne est sur une liste de sanctions et une vérification en cas de signalement négatif autour de la personne (vérification des mentions négatives dans les médias).',
  'ubo.report.uboSearch.result.compliance.explanation.company':
    'Une vérification de conformité pour une enterprise consiste toujours en 3 vérifications: une vérification si l’enterprise est sur une liste de sanctions, une vérification en cas de signalement négatif autour de l’enterprise (vérification des mentions négatives dans les médias) et une vérification si l’enterprise est une enterprise d’etat.',
  'ubo.report.uboSearch.result.compliance.firstname': 'Prénom',
  'ubo.report.uboSearch.result.compliance.gender': 'Sexe',
  'ubo.report.uboSearch.result.compliance.gender.f': 'Femme',
  'ubo.report.uboSearch.result.compliance.gender.m': 'Homme',
  'ubo.report.uboSearch.result.compliance.heading':
    'Vérification de conformité {checkType, select, person {(personne)} company {(enterprise)} other {} }',
  'ubo.report.uboSearch.result.compliance.key': 'Clés de recherche',
  'ubo.report.uboSearch.result.compliance.lastname': 'Nom de famille',
  'ubo.report.uboSearch.result.compliance.links': 'Liens',
  'ubo.report.uboSearch.result.compliance.media': 'Couvertures médiatiques défavorables',
  'ubo.report.uboSearch.result.compliance.noresult': 'Aucune information trouvée',
  'ubo.report.uboSearch.result.compliance.pep': 'Personne politiquement exposée (PEP)',
  'ubo.report.uboSearch.result.compliance.politicallevel': 'Niveau politique',
  'ubo.report.uboSearch.result.compliance.position': 'Position / Rôle',
  'ubo.report.uboSearch.result.compliance.reasonListed': 'Raison énumérée',
  'ubo.report.uboSearch.result.compliance.result':
    '{status, select, NO_MATCH {Aucune information trouvée} other {Informations trouvées}}',
  'ubo.report.uboSearch.result.compliance.sanction': 'Liste de sanctions',
  'ubo.report.uboSearch.result.compliance.soe': "Entreprise d'État",
  'ubo.report.uboSearch.result.compliance.source': "Source de l'entrée",
  'ubo.report.uboSearch.result.compliance.status': 'Statut correspondant',
  'ubo.report.uboSearch.result.compliance.subcategory': 'Sous catégorie',
  'ubo.report.uboSearch.result.compliance.toast.body':
    "Les données pour le contrôle de conformité de cet individu n'ont pas pu être récupérées",
  'ubo.report.uboSearch.result.compliance.toast.title': 'Aucunes données récupérées',
  'ubo.report.uboSearch.result.dateOfBirth': 'Date de naissance',
  'ubo.report.uboSearch.result.expandPath': 'Afficher le chemin',
  'ubo.report.uboSearch.result.jobTitle': 'Profession',
  'ubo.report.uboSearch.result.level': 'à niveau {level}',
  'ubo.report.uboSearch.result.name': 'Nom',
  'ubo.report.uboSearch.result.path': 'Chemin',
  'ubo.report.uboSearch.result.path.companyName': 'Nom de la société',
  'ubo.report.uboSearch.result.path.country': 'Pays',
  'ubo.report.uboSearch.result.path.directShares':
    'Participation directe dans la société sous-jacente',
  'ubo.report.uboSearch.result.path.level': 'Niveau',
  'ubo.report.uboSearch.result.path.NCCT_FATF_CountryCheck': 'Contrôle ETNC/GAFI',
  'ubo.report.uboSearch.result.path.NCCT_FATF_CountryCheck.false':
    'Ce pays ne figure pas actuellement sur la liste des ETNC tenue par le GAFI',
  'ubo.report.uboSearch.result.path.NCCT_FATF_CountryCheck.true':
    'Ce pays figure actuellement sur la liste des ETNC tenue par le GAFI',
  'ubo.report.uboSearch.result.path.ultimateShares':
    'Participation ultime dans la société demandée',
  'ubo.report.uboSearch.result.path.unknownRelationship': 'Relation inconnue',
  'ubo.report.uboSearch.result.placeOfBirth': 'Lieu de naissance',
  'ubo.report.uboSearch.result.status.NO-DATA':
    'Données insuffisantes disponibles pour déterminer un UBO',
  'ubo.report.uboSearch.result.status.NO-UBO': "Pas d'UBO trouvé",
  'ubo.report.uboSearch.result.status.NO-UBO_BANKRUPTCY': 'Faillite',
  'ubo.report.uboSearch.result.status.NO-UBO_COMPLEX': 'Complexe',
  'ubo.report.uboSearch.result.status.NO-UBO_DISSOLUTION': 'Dissolution',
  'ubo.report.uboSearch.result.status.NO-UBO_DO_INQUIRY':
    "Aucun UBO n'a été identifié sur la base des données des actionnaires",
  'ubo.report.uboSearch.result.status.NO-UBO_FOREIGN_COMPANY': 'Société étrangère',
  'ubo.report.uboSearch.result.status.NO-UBO_MORATORIUM': 'Moratoire',
  'ubo.report.uboSearch.result.status.POSSIBLE-UBO': 'UBO possible trouvé',
  'ubo.report.uboSearch.result.status.POSSIBLE-UBO-SEARCH-RESULT':
    'Un UBO potentiel a été trouvé et est disponible dans le résultat de recherche',
  'ubo.report.uboSearch.result.status.UBO': 'UBO trouvé',
  'ubo.report.uboSearch.result.status.UBO-SEARCH-RESULT':
    'Un UBO a été trouvé et est disponible dans le résultat de recherche',
  'ubo.report.uboSearch.result.type': 'Type de résultat',
  'ubo.report.uboSearch.result.type.AUTHORIZED PROXY': 'Procuration autorisée',
  'ubo.report.uboSearch.result.type.PROXY': 'Procuration',
  'ubo.report.uboSearch.result.type.MANAGER': 'Directeur',
  'ubo.report.uboSearch.result.type.SHAREHOLDER': 'Actionnaire',
  'ubo.report.uboSearch.result.type.UBO': 'UBO',
  'ubo.report.uboSearch.result.type.PSEUDO UBO': 'Pseudo UBO',
  'ubo.report.uboSearch.result.type.STATUTORY MANAGER': 'Directeur statutaire',
  'ubo.report.usage.report.download': "Données d'utilisation de UBO (.xlsx)",
  'ubo.request.foreign.company.error.message': 'Please try again later or',
  'ubo.request.foreign.company.error.message.link': 'contact Graydon',
  'ubo.request.foreign.company.error.title': 'Request could not be sent',
  'ubo.request.foreign.company.modal.button.text': 'Demander investigation',
  'ubo.request.foreign.company.modal.description':
    'Veuillez confirmer que vous souhaitez que Graydon vérifie les UBOs et la conformité de cette sociéte (35 EUR). Vous recevrez les résultats dans quelques jours par e-mail.',
  'ubo.request.foreign.company.success.message':
    'You will receive the results by mail in a few days.',
  'ubo.request.foreign.company.success.title': 'Demande a été envoyée.',
  'ubo.request.foreign.company.title': 'Demander investigation de la société',
  'ubo.requestUboReport': 'Rapport UBO',
  'um.authorization-title': 'Unauthorized access',
  'um.cancel': 'Cancel',
  'um.createListPopup.success': 'La liste a été créée',
  'um.createListPopup.success.text':
    "La liste d'entreprises que vous avez créée a été selectionnée",
  'um.no-authorization': 'You are unauthorized to access this page',
  'unit.months': '{value} mois',
  'unknown.address': 'Adresse inconnue',
  'uploading.downloadExample': "Voir un exemple de fichier d'entreprises:",
  'uploading.dropzone.browse': 'poursuivre la navigation',
  'uploading.dropzone.chooseAdifferentFile': 'Ou Choisissez un fichier différent.',
  'uploading.dropzone.disclaimer':
    'Vos données sont en sécurité. Graydon ne les partagera jamais avec des tiers.',
  'uploading.dropzone.dropFile': 'Déposez votre fichier ici pour le téléchargement',
  'uploading.dropzone.selectNew': 'Sélectionner un nouveau fichier',
  'uploading.dropzone.submitButton': 'Télécharger liste',
  'uploading.dropzone.tip':
    "Le format maximum de fichier est de 1 GB. Les fichiers doivent avoir l'extension .xlsx",
  'uploading.dropzone.title': 'Déposer votre ficher ici ou',
  'uploading.dropzone.warning1':
    "Oops ! Nous ne supportons pas ce type de fichier. Utilisez l'extension .xlsx",
  'uploading.dropzone.warning2':
    'Oops ! Le fichier est trop grand. Nous ne supportons que les fichiers de moins de 1 GB',
  'uploading.error.button': 'Réessayez',
  'uploading.error.message':
    "C'est de notre faute. Si cela se reproduit, contactez notre service clients.",
  'uploading.errorPage.heading': "Une erreur s'est produite ...",
  'uploading.header': 'Importe des enterprises de',
  'uploading.loadingPage.heading': 'Vos données sont en préparation.',
  'uploading.point0': 'Le fichier doit être au format .xlsx ou .csv',
  'uploading.point1': 'Les en-têtes sont présents dans la première ligne',
  'uploading.point2':
    'Le pourcentage de correspondance peut être amélioré si (au moins) les données suivantes sont fournies:',
  'uploading.point2a.be': "Numéros d'entreprise",
  'uploading.point2a.gb': 'Numéros d’entreprise',
  'uploading.point2a.nl': 'Numéros des chambres de commerce',
  'uploading.point2b': 'Noms de sociétés et addresses complètes',
  'uploading.point3':
    "Une référence est importante si vous souhaitez à nouveau importer vos données d'entreprise dans votre CRM",
  'uploading.step1': 'Sélectionner un pays',
  'uploading.subHeader': 'Contrôler votre fichier excel/csv pour les points suivants:',
  'user.bar.language': 'Langue',
  'user.greeting': 'Bonjour {user}',
  'user.management': 'Gestion des utilisateurs',
  'user.roles.delivery_managers': 'Administrateur',
  'user.roles.delivery_users': 'Utilisateur',
  'widgets.acceptance.grade.title': 'Degré d’acceptation',
  'widgets.area.acceptance.grade.title': 'Degré d’acceptation',
  'widgets.avg.order.value.title': 'Valeur moyenne de commande',
  'widgets.consumption.title': 'International Business consommation',
  'widgets.total.orders.title': 'Nombre total de contrôles',
  'xs.affiliations.directors.birthDate': 'Date de naissance',
  'xs.affiliations.directors.birthPlace': 'Lieu de naissance',
  'xs.company-details.more-xseptions': '+ {count} plus de XSeption(s)',
  'xs.company-details.title': 'Société demandée',
  'xs.company-relations.description':
    'XSeptions analyse les relations de premier et de second degré (entreprises et/ou dirigeants) pour déceler des situations sortant de l’ordinaire. Cela peut inclure une mention sur des listes de sanctions, des structures d’entreprises opaques ou des faillites récentes du dirigeant.',
  'xs.company-relations.no-xseptions': 'Aucun XSeption trouvée',
  'xs.company-relations.person.address': 'Address',
  'xs.company-relations.person.name': 'Name',
  'xs.company-relations.present': 'Présent',
  'xs.company-relations.relation.dates': 'Date de début et de fin',
  'xs.company-relations.relation.level.1': '1er degré\n',
  'xs.company-relations.relation.level.2': '2e degré',
  'xs.company-relations.title': 'Relations trouvées',
  'xs.company-relations.unknown': 'Inconnu(e)',
  'xs.company-relations.unknown-company': 'Société inconnue',
  'xs.company-relations.unknown-person': 'Personne inconnue\n',
  'xs.filters.title': 'Filtres',
  'xs.filters.toggle.label': 'Afficher vue liste',
  'xs.filters.tooltip':
    'Ce réseau présente la société recherchée ainsi que les sociétés et personnes liées au 1er et 2e degré.',
  'xs.graph.hover.xseptions.title': 'XSeption(s)',
  'xs.graph.loading.display': 'Afficher maintenant',
  'xs.graph.loading.message':
    'Le réseau est en cours de rendu.\n\nVeuillez patienter pour accélérer le rendu',
  'xs.pdf.footer': 'Network incl. XSeptions - {companyName} - {requestorName}',
  'xs.person.details.title': 'Données personnelles',
  'xs.preferences.priority.level.active': 'Activer les niveaux de priorité pour les XSeptions',
  'xs.preferences.priority.level.cancel': 'Annuler',
  'xs.preferences.priority.level.high': 'Élevée',
  'xs.preferences.priority.level.intro':
    'Activer et définir les niveaux de priorité pour les XSeptions par pays ci-dessous. Clicker Sauvegarder pour sauvegarder les préférences.',
  'xs.preferences.priority.level.low': 'Basse',
  'xs.preferences.priority.level.medium': 'Moyen',
  'xs.preferences.priority.level.save': 'Sauvegarder les préférences des XSeptions',
  'xs.preferences.priority.level.title': 'Niveaux de priorité pour les XSeptions',
  'xs.preferences.priority.level.tooltip': 'tooltip ...',
  'xs.preferences.success.link': 'Aller à Recherchez une entreprise',
  'xs.preferences.success.message':
    'Les préférences des niveaux de priorité pour les XSeptions ont été mises à jour. ',
  'xs.relation-type.ESC':
    "{entityA} **a** {entityB} **en tant qu'entité commerciale ou personne morale ayant un contrôle important** {dateRange}",
  'xs.relation-type.ESC.past':
    "{entityA} **avait** {entityB} **en tant qu'entité commerciale ou personne morale ayant un contrôle important** {dateRange}",
  'xs.relation-type.HASSHL':
    '{entityA} **a** {entityB} **pour actionnaire {shareDetails}** {dateRange}',
  'xs.relation-type.HASSHL.past':
    '{entityA} **avait** {entityB} **pour actionnaire {shareDetails}** {dateRange}',
  'xs.relation-type.ISSHL': '{entityA} **est actionnaire {shareDetails} de** {entityB} {dateRange}',
  'xs.relation-type.ISSHL.past':
    '{entityA} **était actionnaire {shareDetails} de** {entityB} {dateRange}',
  'xs.relation-type.LBSBY':
    '{entityA} **a reçu une déclaration de responsabilité par** {entityB} {dateRange}',
  'xs.relation-type.LBSBY.past':
    '{entityA} **a reçu une déclaration de responsabilité par** {entityB} {dateRange}',
  'xs.relation-type.LBSFOR':
    '{entityA} **a déposé une déclaration de responsabilité pour** {entityB} {dateRange}',
  'xs.relation-type.LBSFOR.past':
    '{entityA} **a déposé une déclaration de responsabilité pour** {entityB} {dateRange}',
  'xs.relation-type.MANBY': '{entityA} **a** {entityB} **comme {position}** {dateRange}',
  'xs.relation-type.MANBY.past': '{entityA} **avait** {entityB} **comme {position}** {dateRange}',
  'xs.relation-type.MANFOR': '{entityA} **est {position} de** {entityB} {dateRange}',
  'xs.relation-type.MANFOR.past': '{entityA} **était {position} de** {entityB} {dateRange}',
  'xs.relation-type.PAR': '{entityA} **a** {entityB} **pour société mère** {dateRange}',
  'xs.relation-type.PAR.past': '{entityA} **avait** {entityB} **pour société mère** {dateRange}',
  'xs.relation-type.SUB': '{entityA} **a** {entityB} **pour filiale** {dateRange}',
  'xs.relation-type.SUB.past': '{entityA} **avait** {entityB} **pour filiale** {dateRange}',
  'xs.relation-type.UP': '{entityA} **a** {entityB} **pour société mère ultime** {dateRange}',
  'xs.relation-type.UP.past':
    '{entityA} **avait** {entityB} **pour société mère ultime** {dateRange}',
  'xs.report.download-pdf': 'Télécharger le rapport',
  'xs.report.download-pdf.en-GB': 'PDF en anglais',
  'xs.report.download-pdf.fr': 'PDF en français',
  'xs.report.download-pdf.nl-NL': 'PDF en néerlandais',
  'xs.report.download-xls.en-GB': 'Excel en anglais',
  'xs.report.download-xls.fr': 'Excel en français',
  'xs.report.download-xls.nl-NL': 'Excel en néerlandais',
  'xs.report.download.content.nodeType.organisation': 'Enterprise',
  'xs.report.download.content.nodeType.person': 'Personne',
  'xs.report.download.headers.address': 'Addresse',
  'xs.report.download.headers.dateOfBirth': 'Date de naissance',
  'xs.report.download.headers.dissolvedDate': 'Date de discontinuité',
  'xs.report.download.headers.endDate': 'Date de fin',
  'xs.report.download.headers.graydonNumber': 'Numéro Graydon',
  'xs.report.download.headers.graydonNumOfRelated': 'Numéro Graydon de la relation',
  'xs.report.download.headers.name': 'Nom',
  'xs.report.download.headers.nodeType': 'Entreprise ou personne',
  'xs.report.download.headers.placeOfBirth': 'Lieu de naissance',
  'xs.report.download.headers.registrationId': "Numéro officiel d'entreprise",
  'xs.report.download.headers.relationOf': 'Relation de',
  'xs.report.download.headers.relationType': 'Type de relation',
  'xs.report.download.headers.startDate': 'Date début',
  'xs.report.heading': 'Network incl. XSeptions',
  'xs.report.usage.download': "Données d'utilisation de Network incl. XSeptions (.xlsx)",
  'xs.xseptions.risk-priority': 'Priorité',
  'xs.xseptions.risk-priority.H': 'Élevée',
  'xs.xseptions.risk-priority.L': 'Basse',
  'xs.xseptions.risk-priority.M': 'Moyen',
  'xs.xseptions.risk-priority.NA': 'N/A',
  'general.help-center.title': "Centre d'aide Graydon",
  'general.help-center.desc':
    'Découvrez comment tirer le meilleur parti de Graydon Insights et laissez-vous inspirer par notre plateforme! Nous rassemblons ici tous nos conseils et astuces, nos vidéos tutorielles et plus encore.',
  'general.help-center.link': "Accéder au Centre d'aide",
  'dbm.create-profile.deliver-complete-columns-removal': 'Format des données supprimées\n',
  'dbm.create-profile.deliver-complete-columns-removal.tooltip':
    "Choisissez le format des données supprimées dans le fichier de données. Des colonnes vides seront ajoutées pour les champs redondants afin d'utiliser le même format que pour les autres enregistrements.\n",
  'dbm.create-profile.deliver-complete-columns-removal.label.short':
    'Utiliser le format court pour les suppressions',
  'dbm.create-profile.deliver-complete-columns-removal.label.complete':
    'Utiliser le même format pour les suppressions que pour les autres enregistrements',
  'mi.isIncludeInIndexFlag': 'Seulement des entreprises avec des données complètes et uniques',
  'mi.isIncludeInIndexFlag.heading': 'Nettoyer la sélection des entreprises',
  'mi.isIncludeInIndexFlag.tooltip':
    "Par defaut, les entreprises qui répondent aux critères suivants sont supprimées de la sélection:\n\n1. Le nom de l'entreprise est manquant\n2. La province de l'entreprise est manquante\n3. La filiale a la même adresse que la société récemment constituée\n4. Le numéro officiel d'entreprise est situé en dehors des Pays-Bas\n5. La société est inactive\n\nDécochez ce critère pour sélectionner plus d'entreprises.",
  'dbm.create-profile.data-blocks.DBM_ADDRESS_OLD': 'Addresses old',
  'dbm.create-profile.data-blocks.DBM_ADDRESS_OLD.tooltip': '-',
  'dbm.create-profile.data-blocks.DBM_ORGANIZATION_NAME_OLD': 'Organization names old',
  'dbm.create-profile.data-blocks.DBM_ORGANIZATION_NAME_OLD.tooltip': '-',
  'dbm.documentation.block-description.DBM_ADDRESS_OLD-NL': '-',
  'dbm.documentation.block-description.DBM_ORGANIZATION_NAME_OLD-NL': '-',
};
