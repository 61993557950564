import React from 'react';
import { injectI18n } from 'src/i18n';
import ErrorPage, { ErrorHeading, ErrorMessage } from '../ErrorPage';
import { I18n } from 'src/i18n';

type Props = {
  i18n?: I18n;
};

const PageUnauthorized = ({ i18n }: Props) => (
  <ErrorPage>
    <ErrorHeading>{i18n.text('um.authorization-title')}</ErrorHeading>
    <ErrorMessage>{i18n.text('um.no-authorization')}</ErrorMessage>
  </ErrorPage>
);

export default injectI18n(PageUnauthorized);
