// @ts-nocheck
import { injectI18n } from 'src/i18n';
import React from 'react';
import DropDownSvg from '../../../../media/icons/select-arrow-down.svg';
import { I18nProp } from 'src/i18n';
import { noop } from '../../../../utils/FP-helpers';
import { funcType } from '../../types';
import CountryOption from './CountryOption';
import styles from './styles';
import { MenuIndicator, Select, SelectWrapper, CircleLoader } from './helper-components';
type Props = {
  countriesList: Record<
    string,
    {
      serviceLevel: string;
      available: boolean;
      availablePaygo: boolean;
    }
  >;
  searchCountry: string;
  onChange: funcType;
  showReportTypes: boolean;
  error: boolean;
};

function CountrySelect({
  i18n,
  countriesList,
  showReportTypes = true,
  searchCountry,
  onChange = noop,
  error,
}: Props & I18nProp) {
  function onSelectCountry(data: any) {
    const { value: countryCode } = data;

    if (onChange) {
      onChange(countryCode);
    }
  }

  const options = countriesList
    ? Object.keys(countriesList).map((code) => {
        const attributes = countriesList[code];
        const { available, availablePaygo } = attributes;
        return {
          label: i18n.text(`icr.country.${code}`),
          value: code,
          attributes,
          isDisabled: !available && !availablePaygo,
        };
      })
    : [];
  const value = options.find((option) => option.value === searchCountry);
  return (
    <SelectWrapper className="country-select">
      <Select
        className="country-selector"
        options={options}
        placeholder={i18n.text('icr.country-select.placeholder')}
        onChange={onSelectCountry}
        components={{
          Option: (innerProps: any) => <CountryOption {...{ ...innerProps, showReportTypes }} />,
          DropdownIndicator: () =>
            countriesList || error ? (
              <MenuIndicator src={DropDownSvg} alt="down" />
            ) : (
              <CircleLoader />
            ),
        }}
        styles={styles}
        value={value}
      />
    </SelectWrapper>
  );
}

export default injectI18n(CountrySelect); // as StatelessFunctionalComponent<Props>
