import styled from 'styled-components';
import mapProps from 'recompose/mapProps';
import omit from 'lodash/omit';
import { Link as ReactRouterLink } from 'react-router-dom';
import * as colors from '@graydon/ui-colors';

const Link = styled.a<{
  secondary?: boolean;
  disabled?: boolean;
  beforeContent?: string;
  tabIndex?: any;
}>`
  color: ${({ secondary }) => (secondary ? 'inherit' : colors.blue)};
  text-decoration: underline;
  cursor: pointer;

  &:active {
    color: ${({ secondary }) => (secondary ? 'inherit' : colors.blue)};
  }

  ${({ disabled }) =>
    disabled
      ? `color: ${colors.grayLight1};
         cursor: not-allowed
         &:hover{ color: ${colors.grayLight1}; }`
      : ''}
  ${({ beforeContent }) =>
    beforeContent &&
    `&::before {
        content: '${beforeContent}';
        display: inline-block;
        margin-right: 3px;
      }`};
`;

export const RouterLink = Link.withComponent(
  // make sure the style props don't get passed to the DOM element
  mapProps((unmappedProps: any) => omit(unmappedProps, ['secondary', 'beforeContent']))(
    ReactRouterLink,
  ),
);

export default Link;
