import get from 'lodash/get';
import { extractRegisteredAddress, parseDate } from '../common';
import { extractInsolvencies } from '../extractFinancialInsolvenciesData';
import { ENTITY_TYPE_COMPANY } from '../../constants';
import { getFormattedAddress } from '../../../../../utils/getFormattedAddress';
export const bankAccountsComparator = (a: any, b: any) => {
  // Compare names first
  if (a.name && (b.name === null || typeof b.name === 'undefined')) return -1;
  if (b.name && (a.name === null || typeof a.name === 'undefined')) return 1;
  if (a.name < b.name) return -1;
  if (a.name > b.name) return 1;
  // Compare account number if names are equal
  if (a.accountNumber && (b.accountNumber === null || typeof b.accountNumber === 'undefined'))
    return -1;
  if (b.accountNumber && (a.accountNumber === null || typeof a.accountNumber === 'undefined'))
    return 1;
  if (a.accountNumber < b.accountNumber) return -1;
  if (a.accountNumber > b.accountNumber) return 1;
  return 0;
};
export const liabilitiesComparator = (a: any, b: any) => {
  // Compare start dates
  // Filled start date leads before unknown start date
  if (a.startDate && !b.startDate) return -1;
  if (!a.startDate && b.startDate) return 1;

  if (a.startDate && b.startDate) {
    if (a.startDate.getTime() > b.startDate.getTime()) return -1;
    if (a.startDate.getTime() < b.startDate.getTime()) return 1;
  }

  // Compare names if start dates are equal
  if (a.name && (b.name === null || typeof b.name === 'undefined')) return -1;
  if (b.name && (a.name === null || typeof a.name === 'undefined')) return 1;
  if (a.name < b.name) return -1;
  if (a.name > b.name) return 1;
  return 0;
};
export const extractOthersData = (data: any) => {
  const accountant = get(data, 'affiliationsProfile.accountant.name', null);
  const bankAccounts = get(data, 'affiliationsProfile.bankAccounts', []);
  const liabilityStatements = get(data, 'affiliationsProfile.liabilityStatementFor', []);
  const country = get(data, 'companyIdentification.countryCode', '');
  const liabilities = liabilityStatements
    .filter((liability: any) => liability.entity === ENTITY_TYPE_COMPANY)
    .map((liability: any) => {
      const address = extractRegisteredAddress(get(liability, 'company.companyProfile.addresses'));
      const addressCountry = get(liability, 'company.companyProfile.address.country.value', null);
      const insolvencies = get(liability, 'company.companyProfile.financialCalamities', []);
      const mappedInsolvencies = extractInsolvencies(country, insolvencies);
      const creditFlag = get(
        liability,
        'company.ratingsProfile.graydonGroupCreditScores.creditFlag.value',
        'NA',
      );
      return {
        name: get(liability, 'company.companyProfile.companyName', null),
        isActive: get(liability, 'company.companyProfile.companyStatus.isActive', null),
        enterpriseId: get(liability, 'company.companyIdentification.enterpriseId', null),
        graydonCompanyId: get(liability, 'company.companyIdentification.graydonCompanyId', null),
        registrationNumber: get(liability, 'company.companyIdentification.registrationId', null),
        address: getFormattedAddress(addressCountry, address),
        startDate: parseDate(get(liability, 'startDate', null)),
        endDate: parseDate(get(liability, 'endDate', null)),
        insolvency: mappedInsolvencies[0],
        creditFlag,
        countryCode: get(liability, 'company.companyIdentification.countryCode', null),
      };
    })
    .sort(liabilitiesComparator);
  const banks = bankAccounts
    .map((ba: any) => ({
      name: get(ba, 'name', null),
      accountNumber: get(ba, 'accountNumber', null),
    }))
    .sort(bankAccountsComparator);
  return {
    country,
    data: {
      accountant,
      liabilities,
      banks,
    },
  };
};
