import styled from 'styled-components';
import * as colors from '@graydon/ui-colors';
import { sans } from '../../design-system/constants/fonts';

export const HeaderText = styled.h1<{
  center?: boolean;
}>`
  font: 500 1rem ${sans};
  color: ${colors.greenDark};
  text-transform: uppercase;
  margin-bottom: 16px;
  margin-top: 0;
  ${({ center }) => center && 'text-align: center;'}
`;

export const Title = styled.h1`
  min-height: 50px;
  color: ${colors.blueLight3};
  font: 500 26px ${sans};
  text-align: center;
  margin: 0;
`;

export const Text = styled(HeaderText)`
  font-weight: 400;
  font-size: 0.875rem;
`;

export const MessageText = styled.p`
  text-align: center;
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.5rem;
  text-transform: uppercase;
  color: ${colors.gray};
  padding: 15px 20px 0px 20px;
  margin: 0;

  a {
    color: ${colors.blue};
  }

  span {
    white-space: nowrap;
  }
`;

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 250px;
  min-width: 420px;
  padding: 20px;

  @media (max-width: 768px) {
    margin-top: 130px;
  }
`;
