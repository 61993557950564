// @ts-nocheck
import range from 'lodash/range';
export type AnnualAccountRecord = {
  financialYear: string;
  id: string | null | undefined;
};
export function fillGaps(
  aaList: AnnualAccountRecord[],
  currentYear = `${new Date().getFullYear()}`,
  firstYear = `${Number.parseInt(currentYear, 10) - 7}`,
): AnnualAccountRecord[] {
  let annualAccountList = aaList;

  if (!aaList.length || aaList[aaList.length - 1].financialYear > firstYear) {
    annualAccountList = [
      ...aaList,
      {
        financialYear: `${firstYear}`,
        id: null,
      },
    ];
  }

  return annualAccountList.reduce((list, item, idx) => {
    let lastYear;

    if (idx === 0) {
      lastYear = currentYear;
    } else {
      lastYear = aaList[idx - 1].financialYear;
    }

    const { financialYear: thisYear } = item;
    const gap = range(Number.parseInt(lastYear, 10) - 1, Number.parseInt(thisYear, 10)).map(
      (year) => ({
        financialYear: `${year}`,
        id: null,
      }),
    );
    return [...list, ...gap, item];
  }, []);
}
