const sans = '"Roboto", Helvetica, Arial, sans-serif';
const serif = '"Roboto Slab", "Times New Roman", Times, serif';
const size = {
  h1: '32px',
  h2: '24px',
};

export { sans, serif, size };

export default {
  sans,
  serif,
  size,
};
