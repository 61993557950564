import React from 'react';
import styled, { css } from 'styled-components';
import breakpoints from '../../constants/breakpoints';

type HamburgerButtonProps = {
  isOpen?: boolean;
  type?: string;
  onClick?: () => void;
};

const HamburgerButton = ({ isOpen = false, type, ...props }: HamburgerButtonProps) => (
  <button type="button" {...props}>
    <span />
    <span />
    <span />
    <span />
  </button>
);

export default styled(HamburgerButton)`
  background: transparent;
  border: none;
  cursor: pointer;
  display: none;
  width: 24px;
  height: 24px;
  position: absolute;
  right: 24px;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  padding: 0px;

  & > span {
    display: block;
    cursor: pointer;
    position: absolute;
    height: 3px;
    width: 100%;
    background: white;
    opacity: 1;
    border-radius: 2px;
    left: 0;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
  }

  & > span:nth-child(1) {
    top: 2px;
  }

  & > span:nth-child(2),
  & > span:nth-child(3) {
    top: 10px;
  }

  & > span:nth-child(4) {
    top: 18px;
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      & > span:nth-child(1) {
        top: 10px;
        width: 0%;
        left: 50%;
      }

      & > span:nth-child(2) {
        transform: rotate(45deg);
      }

      & > span:nth-child(3) {
        transform: rotate(-45deg);
      }

      & > span:nth-child(4) {
        top: 10px;
        width: 0%;
        left: 50%;
      }
    `};

  &:focus {
    outline: none;
  }

  @media screen and (max-width: ${breakpoints['portrait-min']}px) {
    display: block;
  }
`;
