import React from 'react';
import { I18nProp } from 'src/i18n';
import styled from 'styled-components';
import breakpoints from '../../constants/breakpoints';
import colors from '../../constants/colors';

const cookieLocations = {
  NL: 'https://www.graydon.nl/cookiewet',
  BE: 'https://graydon.be/cookies',
  GB: 'https://www.graydon.co.uk/cookies',
};

const Wrapper = styled.div`
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  min-height: 7em;
  z-index: 20;
  background-color: ${colors.secondaryLight};
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const Inner = styled.div`
  max-width: 1350px;
  width: 100%;
  margin: 0 auto;
  padding: 1em;
  display: flex;
  justify-content: space-around;
  align-items: center;

  @media screen and (max-width: ${breakpoints['portrait-min']}) {
    flex-direction: column;
    text-align: center;
  }
`;

const BannerText = styled.span`
  font-size: 17px;
  font-weight: 300;

  a {
    color: ${colors.white};
    font-weight: 400;
    margin-left: 0.5em;
    white-space: nowrap;
  }

  @media screen and (max-width: ${breakpoints['portrait-min']}) {
    margin: 0.3em 0;
  }
`;

const AcceptButton = styled.button`
  padding: 0.6em 1em;
  min-height: 0;
  background-color: ${colors.secondary};
  color: #fff;
  border: none;
  border-radius: 4px;
  outline: none;
  transition: all 0.2s ease-out;
  font-weight: 500;
  font-size: 15px;

  &:hover {
    background-color: ${colors.darkblue};
    transition: all 0.2s ease-out;
  }
`;

type CookieBannerProps = {
  onAccept: () => void;
  country: string;
};

export default function CookieBanner(props: CookieBannerProps & I18nProp) {
  const { i18n, country, onAccept } = props;
  return (
    <Wrapper>
      <Inner>
        <BannerText>
          {i18n.text('cookie.message')}
          <a href={cookieLocations[country]} className="more-info">
            {`> ${i18n.text('cookie.more-info')}`}
          </a>
        </BannerText>
        <AcceptButton onClick={onAccept}>{i18n.text('cookie.accept')}</AcceptButton>
      </Inner>
    </Wrapper>
  );
}
