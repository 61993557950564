// @ts-nocheck
import React from 'react';
import { injectI18n } from 'src/i18n';
import InformationPage, {
  InformationHeading,
  InformationMessage,
  InformationLink,
  CustomIcon,
} from '../InformationPage';
import { I18n } from 'src/i18n';
type Props = {
  i18n: I18n;
};

const Page404 = ({ i18n }: Props) => (
  <InformationPage icon={<CustomIcon as="info" />} size="extended">
    <InformationHeading>{i18n.text('info.404.page-not-found')}</InformationHeading>

    <InformationMessage>{i18n.text('info.404.cannot-be-found')}</InformationMessage>

    <InformationLink to="/home">{i18n.text('info.404.go-to-graydon-insights')}</InformationLink>
  </InformationPage>
);

export default injectI18n(Page404);
