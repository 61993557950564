export const maxChars =
  (max = Infinity) =>
  (str: any) =>
    str.length < max ? str : str.substring(0, max);
export const tagWord = (tag: any) => (word: any) => (text: any) =>
  text.replace(new RegExp(`(${word})`, 'ig'), `<${tag}>$1</${tag}>`);
export const stripHtml = (htmlString: any) => {
  const tmp = document.createElement('DIV');
  tmp.innerHTML = htmlString;
  return tmp.textContent || tmp.innerText || '';
};
export default function getCurrencySymbol(countryCode: any) {
  return countryCode.toUpperCase() === 'GB' ? '\u20A4' : '\u20AC';
}
