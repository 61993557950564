import get from 'lodash/get';
export const extractDiscontinuationScoreData = (data: any) => {
  const score = get(data, 'ratingsProfile.graydonInsights.discontinuationScore.value', 'NA');

  if (score === '2' || score === '3') {
    return '2';
  }

  if (score === '4' || score === '5' || score === '6') {
    return '3';
  }

  if (score === '7' || score === '8') {
    return '4';
  }

  if (score === '9') return '5';
  return score;
};
