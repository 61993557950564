import { gray, grayLight2, green } from '@graydon/ui-colors';
import { Menu as OriginalMenu, withStyles } from '@material-ui/core';
import React, { ReactElement, ReactNode } from 'react';
import styled from 'styled-components';

export const Selector = styled.a`
  display: flex;
  align-items: center;
  margin-right: 26px;
`;

export const Label = styled.label`
  color: ${gray};
  margin-right: 6px;
`;

export const Selected = styled.span`
  color: ${green};
`;

export const Item = styled.div<{
  disabled?: boolean;
}>`
  width: 300px;
  padding: 10px;
  color: ${gray};
  ${({ disabled }) => disabled && `color: ${grayLight2};`}
`;

type Menu = {
  open: boolean;
  children: ReactNode;
};

export const Menu = withStyles({
  paper: {
    boxShadow: '0 0 0 1px hsla(0,0%,0%,0.1), 0 4px 11px hsla(0,0%,0%,0.1)',
  },
})(
  ({ open, ...rest }: Menu): ReactElement => (
    <OriginalMenu
      open={open}
      MenuListProps={{
        disablePadding: true,
      }}
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      {...rest}
    />
  ),
);
