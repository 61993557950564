import React, { Component } from 'react';
import styled, { css } from 'styled-components';

import DropdownButton from './DropdownButton';
import UserGreeting from './UserGreeting';
import SubMenu from './SubMenu';
import UserMenu from './UserMenu';
import UserTitle from './UserTitle';
import CompanyName from './CompanyName';
import LogoutButton from './LogoutButton';
import ChangeLanguage from './ChangeLanguage';
import HelpBlock from './HelpBlock';

import colors from '../../constants/colors';
import breakpoints from '../../constants/breakpoints';
import { I18nProp } from 'src/i18n';

const Container = styled.div<{
  isSubMenu: boolean;
  isHome: boolean;
}>`
  ${({ isSubMenu }) =>
    !isSubMenu &&
    css`
      margin-right: 78px;
      display: inline-flex;
      justify-content: center;

      span {
        color: ${colors.white};
        font-size: 14px;
        font-weight: 400;
      }

      span:after {
        border-color: ${colors.white};
      }

      & > nav {
        z-index: 9999;
        top: ${({ isHome }) => (isHome ? '50px' : '70px')};
        right: 26px;
      }

      @media screen and (max-width: ${breakpoints['portrait-min']}px) {
        display: none;
      }
    `} ${({ isSubMenu }) =>
    isSubMenu &&
    css`
      line-height: 25px;
      display: none;

      @media screen and (max-width: ${breakpoints['portrait-min']}px) {
        display: block;
      }

      &:before,
      &:after {
        border: none;
      }
    `};
`;

const MainArrow = styled.div<{
  expanded: boolean;
}>`
  display: inline-block;
  position: relative;
  left: 20px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid white;
  transform: rotate(0deg);
  transition: transform 0.2s linear;

  ${({ expanded }) =>
    expanded &&
    css`
      transform: rotate(180deg);
      transition: transform 0.2s linear;
    `};
`;

const SubArrow = styled.div<{
  expanded: boolean;
}>`
  display: inline-block;
  position: absolute;
  top: 35px;
  right: 34px;
  width: 0;
  height: 0;

  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid white;
  transform: rotate(0deg);
  transition: transform 0.2s linear;

  ${({ expanded }) =>
    expanded &&
    css`
      transform: rotate(180deg);
      transition: transform 0.2s linear;
    `};
`;
export type Locales = {
  locales?: {
    label: string;
    value: string;
  }[];
  language?: string;
};

type UserBarProps = Locales & {
  name: string;
  companyName: string;
  doLogout: () => void;
  onLanguageChange: (item: { label: string; value: string }) => () => void;
  isSubMenu?: boolean;
  isHome?: boolean;
  showLanguageBar: boolean;
  showLogoutButton: boolean;
  showHelpBlock?: boolean;
  onContactsClick?: () => void;
};

type State = {
  expanded: boolean;
  subMenuExpanded: boolean;
};

class UserBar extends Component<UserBarProps & I18nProp, State> {
  userBar: HTMLDivElement | null;
  constructor(props) {
    super(props);
    this.state = { expanded: false, subMenuExpanded: !props.isSubMenu };
    this.onClickOutside = this.onClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('click', this.onClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.onClickOutside);
  }

  onClickOutside(e) {
    if (this.userBar && !this.userBar.contains(e.target)) {
      this.setState(() => ({ expanded: false, subMenuExpanded: false }));
    }
  }

  toggleMenu() {
    this.setState((prevState) => ({ ...prevState, expanded: !prevState.expanded }));
  }

  toggleSubMenu() {
    if (this.props.isSubMenu) {
      this.setState((prevState) => ({ ...prevState, subMenuExpanded: !prevState.subMenuExpanded }));
    }
  }

  render() {
    const { expanded, subMenuExpanded } = this.state;
    const {
      name,
      companyName,
      doLogout,
      i18n,
      locales = [
        { label: 'Nederlands', value: 'nl-NL' },
        { label: 'English', value: 'en-GB' },
        { label: 'Français', value: 'fr' },
      ],
      onLanguageChange,
      language = 'en-GB',
      isSubMenu = false,
      isHome = false,
      showLanguageBar,
      showLogoutButton,
      showHelpBlock = false,
      onContactsClick,
    } = this.props;

    return (
      <Container
        id="user-menu-container"
        isSubMenu={isSubMenu}
        isHome={isHome}
        innerRef={(el) => {
          this.userBar = el;
        }}
      >
        {!isSubMenu && (
          <DropdownButton className="toggle" onClick={() => this.toggleMenu()}>
            <UserGreeting>{i18n.text('user.greeting', { user: name })}!</UserGreeting>
            <MainArrow expanded={expanded} />
          </DropdownButton>
        )}
        <UserMenu expanded={expanded} className="menu">
          <UserTitle onClick={() => this.toggleSubMenu()}>
            {name}
            <CompanyName>{companyName}</CompanyName>
            {isSubMenu && <SubArrow expanded={subMenuExpanded} />}
          </UserTitle>
          <SubMenu expanded={!isSubMenu || subMenuExpanded}>
            {showLanguageBar ? (
              <ChangeLanguage
                i18n={i18n}
                locales={locales}
                onLanguageChange={onLanguageChange}
                language={language}
                useBottomPadding={!showHelpBlock}
              />
            ) : null}
            {showHelpBlock && (
              <HelpBlock
                i18n={i18n}
                onContactsClick={() => {
                  this.toggleMenu();
                  onContactsClick();
                }}
              />
            )}
            {showLogoutButton ? (
              <LogoutButton className="logout" onClick={doLogout}>
                {i18n.text('cta.logout')}
              </LogoutButton>
            ) : null}
          </SubMenu>
        </UserMenu>
      </Container>
    );
  }
}

export default UserBar;
