import IntlPolyfill from 'intl';
import 'intl/locale-data/jsonp/en-GB';
import 'intl/locale-data/jsonp/fr';
import 'intl/locale-data/jsonp/nl';
import IntlMessageFormat from 'intl-messageformat';
import IntlRelativeFormat from 'intl-relativeformat';
import defaultDictionary from '../translations';

function isValidDate(date: any): boolean {
  return date instanceof Date;
}

type DateFormatOptions = {
  format?: 'YYYYMMDD' | 'YYYYMM' | 'YYYY' | 'MM' | 'DD';
};

function getDateFormatOptions(
  options: DateFormatOptions | Intl.DateTimeFormatOptions,
): Intl.DateTimeFormatOptions {
  if (!options) {
    return {};
  }
  if ('format' in options) {
    switch (options.format) {
      case 'YYYYMMDD':
        return { year: 'numeric', month: 'numeric', day: 'numeric' };
      case 'YYYYMM':
        return { year: 'numeric', month: 'numeric' };
      case 'YYYY':
        return { year: 'numeric' };
      case 'MM':
        return { month: 'numeric' };
      case 'DD':
        return { day: 'numeric' };
      default:
        return {};
    }
  } else {
    return options as any;
  }
}

type Dictionary = typeof defaultDictionary;
export type Locale = 'en-GB' | 'nl-NL' | 'fr';

function i18nFactory(initialLocale: Locale, dictionary: Dictionary) {
  let locale = initialLocale;

  return {
    get locale() {
      return locale;
    },
    setLocale(newLocale: Locale) {
      locale = newLocale;
    },
    text(key: string, value: string) {
      const msg = dictionary[locale][key];
      if (!msg) {
        console.warn(`No translation key found for ${key}. Using provided key instead`);
        return key;
      }

      try {
        const formatter = new IntlMessageFormat(msg, locale, undefined, { ignoreTag: true });
        return formatter.format(value as any);
      } catch (e) {
        console.error(`Invalid message format for key '${key}'`, e);
        return msg;
      }
    },
    number(num: number, options: Intl.NumberFormatOptions) {
      const formatter = new IntlPolyfill.NumberFormat(locale, options);
      return formatter.format(num);
    },
    currency(num: number, currencyCode: string) {
      const formatter = new IntlPolyfill.NumberFormat(locale, {
        style: 'currency',
        currency: currencyCode,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
      return locale === 'fr'
        ? formatter.format(num).replace(/([A-Z])\w+/g, '')
        : formatter.format(num);
    },
    date(date: any, options: DateFormatOptions | Intl.DateTimeFormatOptions = {}) {
      if (!isValidDate(date)) {
        throw new Error('Invalid date, please pass only Date objects');
      }

      const allOptions = getDateFormatOptions(options);
      if ('format' in options) {
        delete options.format;
      }
      const formatter = new IntlPolyfill.DateTimeFormat(locale, Object.assign(allOptions, options));
      return formatter.format(date as any);
    },
    formatRelative(date: any, options = {}) {
      if (!isValidDate(date)) {
        throw new Error('Invalid date, please pass only Date objects');
      }
      const formatter = new IntlRelativeFormat(locale, options);
      return formatter.format(date as any);
    },
  };
}

export type I18n = {
  setLocale(newLocale: Locale): void;
  text(key: string, value?: any): string;
  number(num: number, options?: Intl.NumberFormatOptions): string;
  currency(num: number, currencyCode: string): string;
  date(date: any, options?: DateFormatOptions | Intl.DateTimeFormatOptions): string;
  formatRelative(date: any, options?: any): string;
  locale: string;
};

export default function getI18n(locale: string, dictionary = defaultDictionary): I18n {
  const supportedLocales = Object.keys(dictionary);
  if (!supportedLocales.includes(locale)) {
    throw new Error(
      `Unsupported locale ${locale}. Supported locales are ${supportedLocales.join(
        ', ',
      )}. You passed ${locale}.`,
    );
  }
  return i18nFactory(locale as any, dictionary);
}
