import React from 'react';
import styled, { keyframes } from 'styled-components';
import colors from '../../constants/colors';

const Wrapper = styled.div<{
  size?: number;
}>`
  display: inline-block;
  padding-left: ${({ size }) => size * 1.5}px;
  vertical-align: middle;
  margin-left: 8px;
`;

const animation = ({ size, color = colors.link }) => keyframes`
  from {
    box-shadow: 0 ${size}px 0 -${size}px ${color};
  }

  to {
    box-shadow: 0 ${size}px 0 ${color};
  }
`;

type DotLoaderProps = {
  size?: number;
  speed?: string;
  color?: string;
};

const Inner = styled.div<DotLoaderProps>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border-radius: 50%;
  position: relative;
  animation: ${({ size, color }) => animation({ size, color })} ${({ speed = '0.75s' }) => speed}
    ease alternate infinite;
  animation-delay: 0.36s;
  top: 50%;
  margin: -${({ size }) => size * 1.5}px auto 0 auto;

  &::after,
  &::before {
    content: '';
    position: absolute;
    width: ${({ size }) => size}px;
    height: ${({ size }) => size}px;
    border-radius: 50%;
    animation: ${({ size, color }) => animation({ size, color })} ${({ speed = '0.75s' }) => speed}
      ease alternate infinite;
  }

  &::before {
    left: -${({ size }) => size * 1.5}px;
    animation-delay: 0.18s;
  }

  &::after {
    right: -${({ size }) => size * 1.5}px;
    animation-delay: 0.54s;
  }
`;

const DotLoader = ({ size = 18, speed = '0.75s', color = colors.link }, props: DotLoaderProps) => (
  <Wrapper size={size} {...props}>
    <Inner size={size} color={color} speed={speed} {...props} />
  </Wrapper>
);

export default DotLoader;
