import { Set } from 'immutable';
import React, { Component } from 'react';
import { I18nProp } from 'src/i18n';
import styled from 'styled-components';
import Link from '../Link';
import { createCheckIcon } from '../icons/CheckIcon';
import { createCrossIcon } from '../icons/CrossIcon';
import TreeNodeView, { NodeShape } from './TreeNodeView';
import { deselectNode, deselectNodes, expandParentNodes, selectNode, selectNodes } from './utils';

const WhiteCheckIcon = createCheckIcon('#fff');
const WhiteCrossIcon = createCrossIcon('#fff');

const LinkBar = styled.div`
  margin: 10px 0 12px;
`;

const BarLink = styled(Link)`
  margin-right: 20px;
`;

type TreeSelectorProps = {
  nodeMap: NodeShape[];
  rootNodes: NodeShape[];
  selectedNodes?: any;
  visibleNodes?: any;
  onChangeSelection: (selectedNodes: any) => void;
  nodeRenderFunc: (node: NodeShape) => React.ReactNode;
  didSelectAll?: () => void;
  didDeselectAll?: () => void;
  getNodeDescription: (node: NodeShape) => string;
};

type State = {
  expandedNodes: any;
};

class TreeSelector extends Component<TreeSelectorProps & I18nProp, State> {
  constructor(props) {
    super(props);

    this.state = {
      expandedNodes: expandParentNodes(props.rootNodes, Set(), props.selectedNodes, true),
    };
  }
  static defaultProps = {
    selectedNodes: Set(),
    visibleNodes: null,
    didSelectAll: () => null,
    didDeselectAll: () => null,
  };

  render() {
    const {
      rootNodes,
      selectedNodes,
      visibleNodes,
      nodeRenderFunc,
      i18n,
      onChangeSelection,
      didSelectAll,
      didDeselectAll,
    } = this.props;
    const { expandedNodes } = this.state;

    const forcedExpandedNodes = visibleNodes
      ? expandParentNodes(rootNodes, Set(), visibleNodes, false)
      : Set();

    const allExpandedNodes = expandedNodes.union(forcedExpandedNodes);

    const visibleRootNodes = visibleNodes
      ? rootNodes.filter((node) => forcedExpandedNodes.has(node.id) || visibleNodes.has(node.id))
      : rootNodes;

    const onClickNode = (node) => {
      onChangeSelection(
        selectedNodes.has(node.id)
          ? deselectNode(node, selectedNodes)
          : selectNode(node, selectedNodes),
      );
    };

    const onClickCollapse = (node) => {
      this.setState((prevState) => ({
        expandedNodes: allExpandedNodes.has(node.id)
          ? prevState.expandedNodes.delete(node.id)
          : prevState.expandedNodes.add(node.id),
      }));
    };

    return (
      <div className="TreeSelector">
        <LinkBar>
          <BarLink
            onClick={() => {
              onChangeSelection(selectNodes(visibleRootNodes, selectedNodes));
              didSelectAll();
            }}
            secondary
          >
            <WhiteCheckIcon>
              {visibleNodes
                ? i18n.text('core.treeSelector.selectResults')
                : i18n.text('core.treeSelector.selectAll')}
            </WhiteCheckIcon>
          </BarLink>
          <BarLink
            onClick={() => {
              onChangeSelection(deselectNodes(visibleRootNodes, selectedNodes));
              didDeselectAll();
            }}
            secondary
          >
            <WhiteCrossIcon>
              {visibleNodes
                ? i18n.text('core.treeSelector.deselectResults')
                : i18n.text('core.treeSelector.selectNone')}
            </WhiteCrossIcon>
          </BarLink>
        </LinkBar>

        <div className="rootNodes">
          {visibleRootNodes.map((node) => (
            <TreeNodeView
              key={node.id}
              node={node}
              depth={0}
              selectedNodes={selectedNodes}
              expandedNodes={allExpandedNodes}
              onClickNode={onClickNode}
              onClickCollapse={onClickCollapse}
              nodeRenderFunc={nodeRenderFunc}
            />
          ))}
        </div>
      </div>
    );
  }
}

export default TreeSelector;
