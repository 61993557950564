import createReducer from 'create-reducer-map';
import { FETCH_RESULTS, SET_RESULTS, RESET_RESULTS } from './constants';
export const initialState = {
  isFetching: false,
  isError: false,
  total: 0,
  items: null,
};
const reducer = {
  [FETCH_RESULTS]: () => ({ ...initialState, isFetching: true }),
  [SET_RESULTS]: {
    next: (state, { total, items }: any) => ({
      ...initialState,
      total,
      items,
    }),
    throw: () => ({ ...initialState, isError: true }),
  },
  [RESET_RESULTS]: () => initialState,
};
export default createReducer(initialState, reducer);
