export default {
  belowZero: '#c70a15',
  black: '#000000',
  darkblue: '#00545d',
  danger: '#ff0000',
  disabled: '#858587',
  disabledBlue: '#92d2e1',
  disabledLight: '#babfc1',
  highlight: '#ffff00',
  lightBlue: '#f0f9fb',
  link: '#208fa5',
  linkDark: '#1d79b0',
  primary: '#eb6e08',
  primaryDark: '#b55d26',
  primaryLight: '#f29d63',
  secondary: '#168fa7',
  secondaryDark: '#00545d',
  secondaryLight: '#90d1e0',
  secondaryGray: '#577e87',
  lightGrey: '#E1E1E1',
  success: '#2b861a',
  veryLight: '#eff0f0',
  white: '#ffffff',
  divideBefore: '#024C54',
  divideAfter: '#125F67',
  navDivideBefore: '#309AB0',
  navDivideAfter: '#1785A2',
  'dark-grey': '#808080',
  'light-grey': '#ECECEC',
  'text-blue': '#1B6D75',
};
