import React from 'react';
import styled from 'styled-components';
import colors from '../../constants/colors';
import { width, margin, radius } from './constants';
import { slices, labels, pie, getRotationAngle, disabledArc } from './utils';

const data = ['Very high', 'High', 'Average', 'Low', 'Very Low'].map((key) => ({ key, value: 1 }));
const base = pie(data);
const viewBoxWidth = width + margin * 2;
const viewBoxHeight = width / 2;
const arcTranslateX = width / 2 + margin;
const needleTranslateX = width / 2 + margin - 16;

type GaugeProps = {
  value?: number;
};

const Gauge = ({
  value = 8, // Indicates that there's no rating available
  ...props
}: GaugeProps) => {
  if (value >= 1 && value <= 5) {
    return (
      <svg {...props} viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}>
        <g transform={`translate(${arcTranslateX}, ${viewBoxHeight})`}>
          {base.map(slices)}
          {base.map(labels, value)}
        </g>
        <g transform={`translate(${needleTranslateX}, 104)`} className="needle">
          <polygon
            points="16,0 32,128 0,128"
            transform={`rotate(${getRotationAngle(value)}, 16, 128)`}
            style={{ fill: colors.secondaryDark }}
          />
          <circle cx="16" cy="128" r="16" style={{ fill: colors.secondaryDark }} />
        </g>
      </svg>
    );
  }

  return (
    <svg {...props} viewBox={`0 0 ${viewBoxWidth} ${radius}`}>
      <g transform={`translate(${arcTranslateX}, ${radius}) scale(0.85)`}>{disabledArc}</g>
      <g transform={`translate(${needleTranslateX}, 44)`} className="needle">
        <polygon
          points="16,0 32,128 0,128"
          transform="rotate(-90, 16, 128)"
          style={{ fill: colors.disabledBlue }}
        />
        <circle cx="16" cy="128" r="16" style={{ fill: colors.disabledBlue }} />
      </g>
    </svg>
  );
};

const GaugeWrapper = styled(Gauge)`
  background: ${colors.lightBlue};
  max-width: ${viewBoxWidth}px;
  max-height: 55%;
`;

GaugeWrapper.displayName = 'Gauge';

export default GaugeWrapper;
