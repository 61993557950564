import { useState } from 'react';
import { processStates, processFlow } from '../utils/processFlow';
// custom hook to follow the state of an async functions
export function useProcessFlow(processFn?: any) {
  // process sate
  const [process, setProcess] = useState(processStates.init);
  // action follower
  const follow = processFlow(setProcess);

  // reset state to initial state
  const reset = () => setProcess(processStates.init);

  return [process, processFn ? follow(processFn) : follow, reset];
}
