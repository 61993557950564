// status for an async operation
export const processStates = {
  init: 0,
  started: 1,
  successful: 2,
  failed: 3,
};
// a higher order function for care about async operations - function which returns a promise
// in first step the function get a flow callback that is called in start, success and failed situation
// in second step the function get main function - which do the async operation
// and then return a function that handle the process
export const processFlow =
  (flowCB: any) =>
  (fun: any) =>
  (...args: any[]) => {
    flowCB(processStates.started);
    return fun(...args)
      .then((data: any) => {
        flowCB(processStates.successful);
        return data;
      })
      .catch((error: any) => {
        flowCB(processStates.failed);
        return Promise.reject(error);
      });
  };

const isInState = (state: any) => (process: any) => process == state;

export const isInit = isInState(processStates.init);
export const isStarted = isInState(processStates.started);
export const isSuccessful = isInState(processStates.successful);
export const isFailed = isInState(processStates.failed);
// it accepts multiple status and returns one overall status
export const combineStatus = (...list: any[]) =>
  list.reduce((acc, status) => {
    // return failed if one status is failed
    if ([acc, status].includes(processStates.failed)) return processStates.failed;
    // return started if one status is started
    if ([acc, status].includes(processStates.started)) return processStates.started;
    // return successful if one status is successful
    if ([acc, status].includes(processStates.successful)) return processStates.successful;
    return processStates.init;
  }, processStates.init);
