import React from 'react';
import * as colors from '@graydon/ui-colors';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Link from 'src/core/components/Link';
import { I18nProp, injectI18n } from 'src/i18n';
import styled from 'styled-components';

export const Button = styled(Link)`
  display: inline-block;
  margin: 0 16px 16px;
  color: ${colors.gray};
  text-decoration: underline;

  @media screen and (min-width: 768px) {
    margin: 0 0 16px;
  }
`;
export const ChevronIconLeft = styled(ChevronLeft)`
  vertical-align: bottom;
`;

const BackButton = ({ i18n, history }: I18nProp & RouteComponentProps) => (
  <Button onClick={history.goBack}>
    <ChevronIconLeft />
    {i18n.text('info.404.go-back')}
  </Button>
);

export default injectI18n(withRouter(BackButton));
