export const getUserData = (customerData: any) =>
  customerData
    ? {
        id: customerData.id,
        ldapId: customerData.ldapId,
        username: customerData.username,
        email: customerData.email,
        firstName: customerData.firstName,
        lastName: customerData.lastName,
        locale: customerData.locale,
        consumerId: customerData.consumerId,
        customerName: customerData.consumer.customerName,
        consumerName: customerData.consumer.consumerName,
      }
    : {};
