import React, { LinkHTMLAttributes } from 'react';
import styled from 'styled-components';
import mapProps from 'recompose/mapProps';
import omit from 'lodash/omit';
// import * as colors from '@graydon/ui-colors';
import colors from '../../constants/colors';
import { sans } from '../../constants/fonts';

export const fontSizes = {
  small: 12,
  medium: 16,
  big: 20,
};

function createButtonComponent(componentType) {
  return styled<{
    disabled?: boolean;
    primary?: boolean;
    cssFontSize?: string;
    cssWidth?: string;
  }>(componentType)`
    padding: 0.4em 1.5em;
    ${({ cssWidth }) => (cssWidth ? `width: ${cssWidth};` : '')} font-size: ${({ cssFontSize }) =>
      cssFontSize};
    font-family: ${sans};
    line-height: 1.5;
    font-weight: 500;
    border: none;
    outline: none;
    border-radius: 0.25em;
    display: inline-block;
    text-decoration: none;
    text-align: center;
    transition: background-color 0.2s ease-out;
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    ${({ disabled }) => disabled && 'pointer-events: none;'}
    background-color: ${({ disabled, primary }) => {
      if (disabled) return colors.disabled;
      return primary ? colors.primary : colors.secondary;
    }};
    color: ${({ disabled }) => (disabled ? colors.disabledLight : colors.white)};

    &:hover {
      background-color: ${({ disabled, primary }) => {
        if (disabled) return colors.disabled;
        return primary ? colors.primaryLight : colors.secondaryLight;
      }};
    }
  `;
}

const ButtonButton = createButtonComponent('button');
const AButton = createButtonComponent('a');
// LinkButton is instantiated when Link is passed in the props
let LinkButton;

type ButtonProps = {
  link?: any;
  primary?: boolean;
  disabled?: boolean;
  href?: string;
  cssFontSize?: string;
  cssWidth?: string;
  onClick?: (arg?: any) => void;
  children?: React.ReactNode;
  to?: any;
  id?: string;
  type?: string;
};

const Button: React.FC<ButtonProps> = (props) => {
  if (props.link) {
    if (!LinkButton) {
      // we have to filter out all props that can't go on an <a> element, or react will complain
      LinkButton = createButtonComponent(
        mapProps((unmappedProps) =>
          omit(unmappedProps, ['link', 'primary', 'cssFontSize', 'cssWidth']),
        )(props.link),
      );
    }
    return <LinkButton {...props} />;
  }

  return props.href ? <AButton {...props} /> : <ButtonButton {...props} />;
};

Button.defaultProps = {
  link: undefined,
  href: undefined,
  cssFontSize: '16px',
  disabled: false,
  primary: false,
  cssWidth: undefined,
};

type RemoveButtonProps = {
  onClick: (arg?: any) => void;
  textShadow?: boolean;
  children?: React.ReactNode;
};

const StyledRemoveButton = styled.button<{
  textShadow?: boolean;
}>`
  display: inline-block;
  background: none;
  border: none;
  color: inherit;
  margin: 0;
  padding: 0;
  outline: none;
  float: left;
  padding-right: 5px;
  font-size: 18px;
  line-height: 1;
  cursor: pointer;
  ${({ textShadow }) => textShadow && `text-shadow: 0 0 5px ${colors.black};`}
`;

export const RemoveButton = ({ textShadow, children = '×', onClick }: RemoveButtonProps) => (
  <StyledRemoveButton textShadow={textShadow} onClick={onClick} type="button">
    {children}
  </StyledRemoveButton>
);

export default Button;
