import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { reducer } from './reducer';

const composeEnhancers = window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] || compose;

const enhancer = composeEnhancers(applyMiddleware(thunk));
export default function configureStore(initialState: any) {
  const store = createStore(reducer, initialState, enhancer);

  // Hot reload reducers (requires Webpack or Browserify HMR to be enabled)
  if (module.hot) {
    module.hot.accept(
      [
        '../components/SearchResults/reducer',
        '../components/SearchResultsItemButtons/reducer',
        '../Pages/Organisation/reducer',
        '../components/CompanyHeader/reducer',
        '../Pages/Organisation/Archive/reducer',
        '../Pages/Organisation/CompanyBox/reducer',
        '../../CreditReports/shared/stores/monitoringProfiles/reducer',
        '../../CreditReports/shared/stores/organisationTagUuids/reducer',
        '../../CreditReports/shared/stores/modal/reducer',
      ],
      () => store.replaceReducer(reducer),
    );
  }

  return store;
}
