import React from 'react';
import styled from 'styled-components';
import { I18nProp, injectI18n } from 'src/i18n';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import * as colors from '@graydon/ui-colors';
import Popup, { Title, Body } from '../Popup';
import IconChrome from '../../media/icons/browser_chrome.png';
import IconFirefox from '../../media/icons/browser_firefox.jpg';
import IconEdge from '../../media/icons/browser_edge.png';

type Props = {
  onClose: () => void;
};

const Icon = styled.div<{
  icon: string;
  size: string;
}>`
  background: url(${({ icon }) => icon}) center no-repeat;
  height: ${({ size }) => size}
  width: ${({ size }) => size}
  background-size: ${({ size }) => size}
  margin-bottom: 20px;
`;

const BrowserLinksContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
`;

const FlexContainerColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-width: 33.3%;
`;

const FlexContainerRow = styled.div`
  display: flex;
  align-items: center;
`;

const OpenInNewWindowIcon = styled(OpenInNewIcon)`
  color: ${colors.green};
  font-size: 18px !important;
  margin-left: 6px;
`;

const ExternalLink = styled.a`
  color: ${colors.gray};
  text-decoration: none;
  display: flex;
  align-items: center;
`;

const IeBrowserWarningPopup = ({ i18n, onClose }: Props & I18nProp) => (
  <Popup onClose={onClose} width="520px">
    <Title>{i18n.text('banner.phaseout.internetexplorer.title')}</Title>

    <Body>
      {i18n.text('banner.phaseout.internetexplorer.content')}
      <BrowserLinksContainer>
        <FlexContainerColumn>
          <Icon icon={IconFirefox} size="46px" />
          <ExternalLink
            href="https://www.mozilla.org/en-US/firefox/new/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <span>Mozila Firefox</span> <OpenInNewWindowIcon />
          </ExternalLink>
        </FlexContainerColumn>

        <FlexContainerColumn>
          <Icon icon={IconChrome} size="46px" />
          <FlexContainerRow>
            <ExternalLink
              href="https://www.google.com/intl/en/chrome/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <span>Google Chrome</span>
              <OpenInNewWindowIcon />
            </ExternalLink>
          </FlexContainerRow>
        </FlexContainerColumn>

        <FlexContainerColumn>
          <Icon icon={IconEdge} size="46px" />
          <FlexContainerRow>
            <ExternalLink
              href="https://www.microsoft.com/en-us/edge"
              rel="noopener noreferrer"
              target="_blank"
            >
              <span>Microsoft Edge</span>
              <OpenInNewWindowIcon />
            </ExternalLink>
          </FlexContainerRow>
        </FlexContainerColumn>
      </BrowserLinksContainer>
    </Body>
  </Popup>
);

export default injectI18n(IeBrowserWarningPopup);
