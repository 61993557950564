import CircleLoader from 'src/core/components/loaders/CircleLoader';
import React from 'react';
import { getConsumerId, hasPaygo } from '../../../../../keycloak';
import { isFailed, isStarted, isSuccessful } from '../../../../../utils/processFlow';
import SearchErrorMessage from '../../../components/SearchError';
import SearchResults from '../../../components/SearchResults';
import { ErrorWrapper, SpinnerWrapper } from '../helper-components';
import NoPaygo from './NoPaygo';
import OnlyFreshInvestigation from './OnlyFreshInvestigation';
import { CountryCode } from 'src/types/locales';

type Props = {
  country: CountryCode;
  serviceLevel: string;
  loadState: number;
  result: any;
  isFreshInvestigationAvailable: boolean;
};

export default function RenderSearchResults({
  country,
  serviceLevel,
  loadState,
  result,
  isFreshInvestigationAvailable,
}: Props) {
  if (!serviceLevel) {
    return null;
  }

  if (serviceLevel === 'investigation') {
    return hasPaygo() ? <OnlyFreshInvestigation country={country} /> : <NoPaygo />;
  }

  return (
    <>
      {isSuccessful(loadState) && result && (
        <SearchResults
          {...result}
          country={country}
          isFreshInvestigationAvailable={isFreshInvestigationAvailable}
        />
      )}

      {isStarted(loadState) && (
        <SpinnerWrapper>
          <CircleLoader />
        </SpinnerWrapper>
      )}

      {isFailed(loadState) && (
        <ErrorWrapper>
          <SearchErrorMessage consumerId={getConsumerId()} />
        </ErrorWrapper>
      )}
    </>
  );
}
