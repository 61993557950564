import React from 'react';
import * as colors from '@graydon/ui-colors';
import CircleLoader from 'src/core/components/loaders/CircleLoader';
import styled from 'styled-components';
import { sans } from '../../design-system/constants/fonts';
import acceptIcon from '../../media/icons/accept.svg';
import referIcon from '../../media/icons/refer.svg';
import rejectIcon from '../../media/icons/reject.svg';
import ButtonCR from '../ButtonCR';
import OriginalInput from '../Input';
import OriginalSelect from '../Select';

export const Title = styled.h2`
  font: 500 22px ${sans};
  color: ${colors.greenLight};
  margin: 0;
  margin-bottom: 24px;
  padding-right: 32px;
`;

export const Input = styled(OriginalInput)`
  width: 100%;
  padding-left: 24px;
`;

export const Select = styled(OriginalSelect)`
  width: 100%;
`;

export const Label = styled.label`
  display: block;
  margin: 10px 0;
  font-size: 16px;
  font-weight: 400;
  color: ${colors.gray};
`;

export const Description = styled.div`
  margin: 8px 0;
  color: ${colors.gray};
`;

export const CompanyName = styled.h3`
  font: 500 18px ${sans};
  color: ${colors.greenLight};
  margin: 0;
`;

export const Form = styled.form`
  margin: 24px 0;
`;

export const ValueWrapper = styled.div<{
  currency?: string;
}>`
  position: relative;
  ::before {
    content: '${({ currency = '€' }) => currency}';
    position: absolute;
    left: 10px;
    top: 10px;
    color: ${colors.gray};
  }
`;

export const Button = styled(ButtonCR)`
  margin-top: 24px;
  width: auto;
`;

export const Row = styled.li`
  padding: 16px 10px;
  display: flex;
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 10px;
  }
`;

export const Cell = styled.span`
  font-size: 16px;
  font-weight: 300;
  color: ${colors.gray};
  display: flex;
  align-items: center;
  @media (min-width: 769px) {
    padding-right: 16px;
    &:last-child {
      justify-content: flex-end;
    }
  }

  @media (max-width: 768px) {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const DateWrapper = styled(Cell)`
  min-width: 110px;
`;

export const Outcome = styled(Cell)`
  flex: 1;
`;

export const ArchiveHeader = styled(CompanyName)`
  padding-top: 24px;
  border-top: solid 1px ${colors.grayLight4};
  margin-bottom: 16px;
`;

export const StatusImg = styled.img`
  margin-right: 8px;
`;

export const Loader = styled(CircleLoader)`
  display: inline-block;
`;

export const ErrorText = styled.div`
  min-height: 30px;
  color: ${colors.gray};
`;

export type OutcomeType = 'ACCEPT' | 'DENY' | 'REFER';

export function getIconPath(outcome: OutcomeType) {
  switch (outcome) {
    case 'ACCEPT':
      return acceptIcon;

    case 'DENY':
      return rejectIcon;

    case 'REFER':
      return referIcon;

    default:
      return '';
  }
}

export const StatusIcon = ({ className, type }: { className?: string; type: OutcomeType }) => (
  <StatusImg className={className} src={getIconPath(type)} alt="status" />
);
