// @ts-nocheck
import compose from 'lodash/fp/compose';
import curry from 'lodash/fp/curry';
import filter from 'lodash/fp/filter';
import get from 'lodash/fp/get';
import identity from 'lodash/fp/identity';
import includes from 'lodash/fp/includes';
import isEmpty from 'lodash/fp/isEmpty';
import map from 'lodash/fp/map';
import __ from 'lodash/fp/placeholder';
import set from 'lodash/fp/set';
import pickBy from 'lodash/fp/pickBy';
// extended set function that use a setter to set value
export const extSet = curry((path: any, setter: any, object: any) =>
  set(path, setter(object), object),
);
// reduce an array in [object, object, object] format to {key1: value1, key2: value2}
// it uses a mapper to map object to {key: value} format
// if the items have same key then the value of last item effects the output
export const reduceToObject =
  (mapper: (arg0: any) => any) =>
  (items: any = {}, item: any) => ({
    ...items,
    ...mapper(item),
  });
// reduce an array to object [{key1: value1}, {key2: value3}, {}] to {key1: value1, key2: value2}
export const arrayToObject = reduceToObject(identity);
// sorts an array of objects by property
export const sortObjectsByProperty = (
  objectsArray: any[],
  prop: string,
  descending = false,
): any[] => {
  return objectsArray.sort((a, b) => (descending ? b[prop] - a[prop] : a[prop] - b[prop]));
};
// a HOF that keep the resolved result of the function and return it for same inputs;
export const memorizeResult = (fn: (arg0: any) => any) => {
  // generate a unique key for the args
  const getUniqueKey = (...obj: any[]) => JSON.stringify(obj);

  // collection of result promises
  let results = {};

  // reset the memory
  const reset = () => {
    results = {};
  };

  // return a new function that handles memorizing;
  const newFn = (...args: any[]) => {
    const key = getUniqueKey(...args);
    if (results[key]) return results[key];
    // store the promise in the result object
    results[key] = fn(...args).catch((e: any) => {
      delete results[key];
      return Promise.reject(e);
    });
    return results[key];
  };

  return [newFn, reset];
};
// log input to console and return it
export const inspect = (tag: string) => (data: any) => {
  console.log(tag, data);
  return data;
};
// if the is empty returns undefined otherwise input
export const checkEmpty = (input: any) => (isEmpty(input) ? undefined : input);
// mapper to map an object by getting a prop
export const mapOver = compose(map, get);
// compose<A extends any[], R1, R2>(f2: (a: R1) => R2, f1: (...args: A) => R1): (...args: A) => R2
// filter an object by checking a prop
export const filterOver = compose(filter, get);
// update each key of items in the input array
// key setter -> array -> updatedArray
export const setInList = (key: string, setter: (arg0: any) => any) => {
  // call setter with object[key] instead of object
  const keySetter = compose(setter, get(key));
  return map(extSet(key, keySetter));
};
// check an item is in list
export const isInList = (list: any | any[]) => includes(__, list);
export const compactObject = pickBy<any, any>((value) => !isEmpty(value));
// no operation
export const noop = () => undefined;
// replace items with default values in case of null, undefined and NaN
export const defValues =
  (def: any = {}) =>
  (obj: any = {}) => {
    return Object.entries(def).reduce(
      (acc, [key, value]) => {
        acc[key] =
          obj[key] === undefined || obj[key] === null || Number.isNaN(obj[key]) ? value : obj[key];
        return acc;
      },
      { ...obj },
    );
  };
// preventDefault an event
export function preventDefault(e: React.SyntheticEvent): React.SyntheticEvent {
  if (e && e.preventDefault) e.preventDefault();
  return e;
}
export const filterObject = (filterFn: (k: any, v: any) => boolean) => (obj: any) =>
  Object.entries(obj).reduce(
    (acc, [key, value]) => (filterFn(key, value) ? { ...acc, [key]: value } : acc),
    {},
  );
export const isSubsetOf = (array: any[] = [], subArray: any[]) =>
  subArray.every((item) => array.includes(item));
