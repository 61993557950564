import React from 'react';
import styled from 'styled-components';
import MaterialMenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import * as colors from '@graydon/ui-colors';
export const DividerWrapper = styled.div`
  padding: 0 14px;
`;
export const IconWrapper = styled.div`
  display: flex;
  margin-right: 10px;
`;
export const ItemWrapper = styled.div`
  color: ${colors.green};
  display: flex;
  align-items: center;
`;
export const Label = styled.div`
  text-transform: uppercase;
`;
export const SvgIcon = styled.svg`
  width: 20px;
  height: 20px;
`;
export const Icon = styled.img`
  width: 20px;
  height: 20px;
`;
export const MenuItem = styled(MaterialMenuItem)`
  @media (min-width: 600px) {
    min-height: 48px !important;
  }
`;
export const MenuDivider = () => (
  <DividerWrapper>
    <Divider />
  </DividerWrapper>
);
