import * as React from 'react';
import { Wrapper, InputField, ResetButton, SearchButton } from './helper-components';
const emptyStr = '';

const noop = () => {};

type Props = {
  placeholder: string;
  className?: string;
  searchText: string;
  onChange: (arg0: any) => any;
  onReset: (arg0: any) => any;
  autoFocus: boolean | void;
  disabled?: boolean;
};

function SearchBar(props: Props) {
  const {
    placeholder = emptyStr,
    searchText = emptyStr,
    onChange = noop,
    onReset = noop,
    className,
    autoFocus,
    disabled,
  } = props;
  return (
    <Wrapper className={className}>
      <InputField
        className="searchbar-input"
        type="search"
        autoComplete="off"
        autoCorrect="off"
        autoCapitalize="off"
        spellCheck="false"
        placeholder={placeholder}
        value={searchText}
        onChange={onChange}
        // showBackground={searchText.length === 0}
        autoFocus={!!autoFocus}
      />

      <ResetButton role="button" onClick={onReset} isVisible={searchText.length > 0}>
        &times;
      </ResetButton>

      <SearchButton primary type="submit" disabled={searchText.length < 2 || disabled} />
    </Wrapper>
  );
}

export default SearchBar;
