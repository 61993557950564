// @ts-nocheck
import get from 'lodash/get';
import range from 'lodash/range';
import { parseDate } from '../common';
export const extractMortgagesData = (data: any) => {
  const mortgagesSummary = get(data, 'paymentBehaviourProfile.mortgagesSummary');
  const dateOfLastFullSatisfaction = parseDate(get(mortgagesSummary, 'dateOfLastFullSatisfaction'));
  const dateOfLastPartialSatisfaction = parseDate(
    get(mortgagesSummary, 'dateOfLastPartialSatisfaction'),
  );
  const dateOfLastMortgage = parseDate(get(mortgagesSummary, 'dateOfLastMortgage'));
  const numberFullySatisfied = get(mortgagesSummary, 'numberFullySatisfied', '-');
  const numberPartiallySatisfied = get(mortgagesSummary, 'numberPartiallySatisfied', '-');
  const allMortgagesDetails = get(data, 'paymentBehaviourProfile.mortgagesDetails', []);
  const totalNumberOfMortgagesDetails = allMortgagesDetails.length;
  const mortgagesDetails = allMortgagesDetails.slice(0, 20).map((item: any, index: any) => {
    const lenders = range(1, 5)
      .map((n) => item[`lender${n}`])
      .filter((lender) => typeof lender !== 'undefined');
    return {
      key: `${index} ${item.creationDate && parseDate(item.creationDate).toDateString()} ${
        item.propertyDetails
      }`,
      registrationDate: item.registrationDate ? parseDate(item.registrationDate) : null,
      creationDate: item.creationDate ? parseDate(item.creationDate) : null,
      satisfactionDate: item.satisfactionDate ? parseDate(item.satisfactionDate) : null,
      propertyDetails: item.propertyDetails,
      lenders,
      otherLenders: item.otherLenders || null,
    };
  });
  return {
    dateOfLastFullSatisfaction,
    dateOfLastPartialSatisfaction,
    dateOfLastMortgage,
    numberFullySatisfied,
    numberPartiallySatisfied,
    totalNumberOfMortgagesDetails,
    mortgagesDetails,
    noDataAvailable: mortgagesSummary === undefined,
  };
};
