import React from 'react';
import { injectI18n } from 'src/i18n';
import ContactDetails from './ContactDetails';
import InformationPage, {
  InformationHeading,
  InformationMessage,
  CustomIcon,
} from '../../components/InformationPage';
import { I18n } from 'src/i18n';
type Props = {
  i18n: I18n;
};

const ContactPage = ({ i18n }: Props) => (
  <InformationPage icon={<CustomIcon as="hidden" />} size="extended">
    <InformationHeading>{i18n.text('contacts.title')}</InformationHeading>

    <InformationMessage>{i18n.text('contacts.text')}</InformationMessage>

    <ContactDetails i18n={i18n} />
  </InformationPage>
);

export default injectI18n(ContactPage);
