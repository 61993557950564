import createReducer from 'create-reducer-map';
import { REPORT_ID_REQUEST, REPORT_ID_RESPONSE, REPORT_RESET_CREATION_ERROR } from './constants';
export const initialState = {
  isRequesting: false,
  isRequestError: false,
};
const reducer = {
  [REPORT_ID_REQUEST]: () => ({ ...initialState, isRequesting: true, isRequestError: false }),
  [REPORT_ID_RESPONSE]: (state: any, isError: any) =>
    isError
      ? { ...initialState, isRequesting: false, isRequestError: true }
      : { ...initialState, isRequesting: false },
  [REPORT_RESET_CREATION_ERROR]: () => ({
    ...initialState,
    isRequesting: false,
    isRequestError: false,
  }),
};
export default createReducer(initialState, reducer);
