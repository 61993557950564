import range from 'lodash/range';
import get from 'lodash/get';
import { getLast12ActiveMonths } from '../common';
export const setMaxAvgDbtCeiling = (maxAvgDbt: any) => {
  if (maxAvgDbt <= 10) return 10;
  if (maxAvgDbt > 10 && maxAvgDbt <= 30) return 30;
  if (maxAvgDbt > 30 && maxAvgDbt <= 50) return 50;
  if (maxAvgDbt > 50 && maxAvgDbt <= 100) return 100;
  if (maxAvgDbt > 100 && maxAvgDbt <= 160) return 160;
  if (maxAvgDbt > 160 && maxAvgDbt <= 200) return 200;
  return 240;
};

const sortByAvgDbt = (a: any, b: any) => {
  if (a.avgDbt && b.avgDbt) return b.avgDbt - a.avgDbt;
  if (!a.avgDbt && b.avgDbt) return 1;
  if (!b.avgDbt && a.avgDbt) return -1;
  if (a.avgDbt === 0 && !b.avgDbt) return -1;
  if (b.avgDbt === 0 && !a.avgDbt) return 1;
  return 0;
};

export const extractPaymentTrendData = (data: any, date = new Date()) => {
  const latestValidDate = new Date(date.getFullYear(), date.getMonth() - 1, 1);
  const paymentPerformance = get(data, 'paymentBehaviourProfile.paymentPerformance');
  const { dbtLastA, dbtLastB, dbtLastC, dbtLastD, dbtLastE } = paymentPerformance || {};
  const numPaymentsLast12Months = get(paymentPerformance, 'numPaymentsLast12Months');
  const availableData = range(12).map((fmiCounter) => ({
    year: get(paymentPerformance, `fmi${fmiCounter + 1}Year`),
    month: get(paymentPerformance, `fmi${fmiCounter + 1}Month`),
    data: get(paymentPerformance, `fmi${fmiCounter + 1}AvgDbt`),
  }));
  const avgDbtlast12Months = getLast12ActiveMonths(latestValidDate)
    .map((currentActivePoint: any) => {
      const availableDataForCurrentElement = availableData.find(
        (el) => el.year === currentActivePoint.year && el.month === currentActivePoint.month,
      );
      return { ...currentActivePoint, avgDbt: get(availableDataForCurrentElement, 'data', null) };
    })
    .reverse();
  const actualMaxAvgDbt = avgDbtlast12Months.slice().sort(sortByAvgDbt)[0].avgDbt;
  return {
    numPaymentsLast12Months,
    avgDbtlast12Months,
    lastMonthDBT: [dbtLastA, dbtLastB, dbtLastC, dbtLastD, dbtLastE].reduce(
      (old, item) => (typeof old === 'number' ? old : item),
      null,
    ),
    maxAvgDbt: setMaxAvgDbtCeiling(actualMaxAvgDbt),
    noDataAvailable: !avgDbtlast12Months.find((a: any) => a.avgDbt !== null),
  };
};
