import get from 'lodash/get';
import { parseDate, getYearsRange, getLastFilledFinancialYear } from '../common';
export const extractGeneralInformationData = (data: any) => {
  const lastFilledYear = getLastFilledFinancialYear(data);
  const actualYears = getYearsRange(lastFilledYear, 5);
  const headers = get(data, 'annualReportProfile.annualReports', []).map((report: any) =>
    get(report, 'annualReportHeader'),
  );
  return actualYears.map((year) => {
    const header = headers.find((h: any) => get(h, 'accountingPeriod', null) === year);
    return {
      accountingPeriod: year,
      accountingPeriodStartDate: parseDate(get(header, 'accountingPeriodStartDate', null)),
      accountingPeriodEndDate: parseDate(get(header, 'accountingPeriodEndDate', null)),
      filingDate: parseDate(get(header, 'filingDate', null)),
      preliminaryFlag: get(header, 'preliminaryFlag', null),
      consolidatedFlag: get(header, 'consolidatedFlag', null),
      accountingStandard: get(header, 'accountingStandardCode.code', null),
      balancedFlag: get(header, 'balancedFlag', null),
      modelType: get(header, 'typeCode.value', null),
      annualMeetingDate: parseDate(get(header, 'annualMeetingDate', null)),
    };
  });
};
