import { injectGlobal } from 'styled-components';
export const GlobalStyle = injectGlobal`
  *, *:before, *:after { box-sizing: border-box; }

  body {
    margin: 0;
    padding: 0;
    font-family: "Roboto", Helvetica, Arial, sans-serif;
    font-weight: 300;
    line-height: 1.4;
    font-size: 16px;
  }

  html, body, #root {
    height: 100%;
  }

  h1 {
    font-family: "Roboto", Helvetica, Arial, sans-serif;
    font-size: 2rem;
    font-weight: 500;
    line-height: 1.4;
  }

  h2 {
    font-family: "Roboto", Helvetica, Arial, sans-serif;
    font-size: 1.375rem;
    font-weight: 500;
    line-height: 1.4;
  }
  h3 {
    font-family: "Roboto", Helvetica, Arial, sans-serif;
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1.4;
  }
  h4 {
    font-family: "Roboto", Helvetica, Arial, sans-serif;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.4;
  }

  input:focus, select:focus, textarea:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(1, 89, 106, 0.35);
  }

  a:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(1, 89, 106, 0.35);
    border-radius: 1px;
  }

  @media print{
    .noprint{
      display:none!important;
    }
    .noprint-bg{
      background-color:unset!important;
    }
    .noprint-color{
      color:unset!important;
    }
    ._hj_feedback_container{
      display: none;
    }
  }
`;
