import styled from 'styled-components';
import { sans } from 'src/core/constants/fonts';
export const Heading1 = styled.h1`
  font-family: ${sans};
  font-size: 36px;
  font-weight: 500;
  color: #1b6d75;
  margin: 0;
  padding: 0;
`;
export const ResponsiveHeading1 = styled(Heading1)`
  @media (max-width: 768px) {
    font-size: 18px;
  }
`;
export const Heading2 = styled.h2`
  font-family: ${sans};
  font-size: 32px;
  font-weight: 500;
  color: #1b6d75;
  margin: 0;
  padding: 0;
`;
export const ResponsiveHeading2 = styled(Heading2)`
  @media (max-width: 768px) {
    font-size: 26px;
  }
`;
export const Heading3 = styled.h3`
  font-family: ${sans};
  font-size: 24px;
  font-weight: 500;
  color: #1b6d75;
  margin: 0;
  padding: 0;
`;
export const ResponsiveHeading3 = styled(Heading3)`
  @media (max-width: 768px) {
    font-size: 18px;
  }
`;
