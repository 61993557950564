// @ts-nocheck
import get from 'lodash/get';
import { parseDate, extractRegisteredAddress } from '../common';
import { extractInsolvencies } from '../extractFinancialInsolvenciesData';
import { ENTITY_TYPE_COMPANY, ENTITY_TYPE_PERSON } from '../../constants';
import { keyGenerator } from '../../../../../utils/keyGenerator';
import { getFormattedAddress } from '../../../../../utils/getFormattedAddress';
const getKey = keyGenerator();
export const remapPositionsInOtherCompanies = (list: any, countryCode: any) =>
  list.reduce((acc: any, entity: any) => {
    const {
      company: { companyIdentification, companyProfile, ratingsProfile } = {},
      positions = [],
    } = entity;
    const enterpriseId = get(companyIdentification, 'enterpriseId', null);
    const graydonCompanyId = get(companyIdentification, 'graydonCompanyId', null);
    const isCompanyActive = get(companyProfile, 'companyStatus.isActive', null);
    const insolvencies = get(companyProfile, 'financialCalamities', []);
    const mappedInsolvencies = extractInsolvencies(countryCode.toUpperCase(), insolvencies);
    const creditFlag = get(ratingsProfile, 'graydonGroupCreditScores.creditFlag.value', 'NA');
    const companyInformation = {
      name: get(companyProfile, 'companyName', null),
      enterpriseId,
      graydonCompanyId,
      isCompanyActive,
      activeCalamity: mappedInsolvencies[0],
      creditFlag,
    };
    return positions
      .map((position: any) => {
        const positionInformation = {
          title: get(position, 'type.descriptions'),
          titleAdditionalDescription: get(position, 'title'),
          startDate: parseDate(get(position, 'startDate', null)),
          endDate: parseDate(get(position, 'endDate', null)),
        };

        if (countryCode === 'NL') {
          positionInformation.occupation = get(position, 'mandate.type.descriptions');
          positionInformation.occupationAdditionalDescription = get(position, 'mandate.text.0');
        }

        return {
          key: getKey(),
          ...companyInformation,
          ...positionInformation,
        };
      })
      .concat(acc);
  }, []);
export const getPersonOrCompanyName = (entity: any) => {
  if (entity.entity === ENTITY_TYPE_PERSON) return entity.person.surName;
  else if (entity.entity === ENTITY_TYPE_COMPANY) return entity.company.companyProfile.companyName;
  return entity.companyProfile.companyName;
};
export const sortAffiliationsEntitiesOrPositionsByDate = (el1: any, el2: any) => {
  const end1 = el1.endDate || 0;
  const start1 = el1.startDate || 0;
  const end2 = el2.endDate || 0;
  const start2 = el2.startDate || 0;
  const name1 = el1.name;
  const name2 = el2.name;

  if (!start1 && !start2 && !end1 && !end2) {
    if (name1 < name2) return -1;
    if (name1 > name2) return 1;
  } else if (start1 && start2 && end1 && end2) {
    if (start1.getTime() === start2.getTime() && end1.getTime() === end2.getTime()) {
      if (name1 < name2) return -1;
      if (name1 > name2) return 1;
    } else {
      if (end1.getTime() === end2.getTime()) {
        if (name1 < name2) return -1;
        if (name1 > name2) return 1;
      }

      return end2 - end1;
    }
  } else if (end1 && !end2) {
    return 1;
  } else if (!end1 && end2) {
    return -1;
  } else if (start1 && start2 && !end1 && !end2) {
    if (start1.getTime() === start2.getTime()) {
      if (name1 < name2) return -1;
      if (name1 > name2) return 1;
    }

    return start2 - start1;
  } else if (start1 && !end1 && !start2 && !end2) {
    return -1;
  } else if (!start1 && !end1 && start2 && !end2) {
    return 1;
  }

  return 0;
};

const getName = ({ fullName, initials, firstName, surNamePrefix, surName }: any) =>
  fullName || [initials, firstName, surNamePrefix, surName].filter((it) => it).join(' ');

const getRepresentedBy = (representedByData: any) => {
  if (get(representedByData, 'entity') === 'PERSON') {
    return {
      id: get(representedByData, 'person.personId'),
      initials: get(representedByData, 'person.initials'),
      name: getName(get(representedByData, 'person')),
      gender: get(representedByData, 'person.gender'),
    };
  }

  return undefined;
};

export const extractDirectorsData = (data: any) => {
  const { countryCode } = data.companyIdentification;
  const management = get(data, 'affiliationsProfile.management', []);
  const managementSorted = management
    .reduce((acc: any, m: any) => {
      const entity = get(m, 'entity', null);
      const person = get(m, 'person', {});
      const company = get(m, 'company', {});
      const positions = get(m, 'positions', []);
      const positionsInOtherCompanies = get(m, 'positionsInOtherCompanies', []);
      let entityInformation = {};

      if (entity === ENTITY_TYPE_PERSON) {
        const { gender, initials, personId, dateOfBirth, unformattedDateOfBirth } = person;
        const name = getName(person);
        entityInformation = {
          id: personId,
          initials,
          name,
          gender,
        };

        if (countryCode === 'NL') {
          entityInformation.countryOfBirth = get(person, 'countryOfBirth.descriptions');
          entityInformation.birthDate = parseDate(dateOfBirth);
          entityInformation.placeOfBirth = get(person, 'placeOfBirth', '');
        }

        if (countryCode === 'GB') {
          const { address: serviceAddress } = person;
          entityInformation.birthDate = unformattedDateOfBirth;
          entityInformation.serviceAddress = getFormattedAddress(countryCode, serviceAddress);
        }

        if (countryCode === 'BE') {
          const { address } = person;
          entityInformation.birthDate = parseDate(dateOfBirth);
          entityInformation.address = getFormattedAddress(countryCode, address);
        }
      }

      if (entity === ENTITY_TYPE_COMPANY) {
        const { companyProfile, ratingsProfile, companyIdentification } = company;
        const registrationNumber = get(companyIdentification, 'registrationId', null);
        const enterpriseId = get(companyIdentification, 'enterpriseId', null);
        const graydonCompanyId = get(companyIdentification, 'graydonCompanyId', null);
        const isCompanyActive = get(companyProfile, 'companyStatus.isActive', null);
        const companyAddress = extractRegisteredAddress(get(companyProfile, 'addresses'));
        const insolvencies = get(companyProfile, 'financialCalamities', []);
        const creditFlag = get(ratingsProfile, 'graydonGroupCreditScores.creditFlag.value', 'NA');
        const directingCompanyCountryCode = get(companyIdentification, 'countryCode', null);
        const mappedInsolvencies = extractInsolvencies(countryCode.toUpperCase(), insolvencies);
        entityInformation = {
          id: registrationNumber,
          graydonCompanyId,
          name: get(company, 'companyProfile.companyName'),
          enterpriseId,
          registrationNumber,
          isCompanyActive,
          address:
            companyAddress !== undefined &&
            getFormattedAddress(companyAddress.country, companyAddress),
          activeCalamity: mappedInsolvencies[0],
          creditFlag,
          directingCompanyCountryCode,
        };
      }

      return positions
        .map((position: any) => {
          const positionInformation = {
            title: get(position, 'type.descriptions'),
            titleAdditionalDescription: get(position, 'title'),
            startDate: parseDate(get(position, 'startDate', null)),
            endDate: parseDate(get(position, 'endDate', null)),
          };

          if (countryCode === 'NL') {
            positionInformation.occupation = get(position, 'mandate.type.descriptions');
            positionInformation.occupationAdditionalDescription = get(position, 'mandate.text.0');
          }

          if (countryCode === 'GB') {
            const { address: serviceAddress } = position;
            entityInformation.serviceAddress = getFormattedAddress(countryCode, serviceAddress);
          }

          const positionsInOtherCompaniesRemapped = remapPositionsInOtherCompanies(
            positionsInOtherCompanies,
            countryCode,
          ).sort(sortAffiliationsEntitiesOrPositionsByDate);
          return {
            key: getKey(),
            type: entity,
            ...entityInformation,
            ...positionInformation,
            representedBy: getRepresentedBy(get(position, 'representedBy')),
            positionsInOtherCompanies: positionsInOtherCompaniesRemapped,
            positionsInOtherCompaniesCount: positionsInOtherCompaniesRemapped.length,
          };
        })
        .concat(acc);
    }, [])
    .sort(sortAffiliationsEntitiesOrPositionsByDate);
  return {
    countryCode,
    management: managementSorted,
  };
};
