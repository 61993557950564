import { post } from '../../../../utils/api';
import { companyMapper, getSearchOptions } from './utils';
export const search = (
  searchTerm: any,
  countryCode: any,
  city: any,
  searchMethod: any,
  state: any,
) => {
  const searchOptions = getSearchOptions({
    searchTerm,
    countryCode,
    city,
    state,
    searchMethod,
  });
  return post(`/international-business/search`, searchOptions).then(
    ({ totalMatches: total, companyMatches }) => {
      return {
        total,
        items: companyMatches.map(companyMapper),
      };
    },
  );
};
