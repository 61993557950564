import styled from 'styled-components';
import colors from '../../constants/colors';

const LogoutButton = styled.button`
  border: none;
  border-radius: 0;
  display: block;
  width: 100%;
  padding: 15px 16px;
  color: #fff;
  background-color: ${colors.secondaryLight};
  text-align: center;
  font-size: 12px;
  cursor: pointer;
`;

export default LogoutButton;
