import React from 'react';
import FormFieldHeader from '../components/FormFieldHeader';
import TextInput from '../components/TextInput';
import ValidationMessage from '../components/ValidationMessage';
import { Container, FlexContainer } from '../helper-components';
type Props = {
  value?: string;
  type?: string;
  isRequired?: boolean;
  label?: string;
  placeholder?: string;
  tooltipText?: string;
  isValid?: boolean;
  validationMessage?: string;
  // onBlur?: ((arg0: any) => any) | null | undefined;
  onBlur?: () => void;
  onEdit?: ((arg0: any) => any) | null | undefined;
  autoFocus?: boolean;
  onEnterKey?: ((arg0: any) => any) | null | undefined;
  className?: string;
};

export const FormFieldText = ({
  value,
  isRequired = false,
  label,
  placeholder,
  tooltipText,
  isValid = true,
  validationMessage,
  onBlur,
  onEdit,
  type = 'text',
  autoFocus,
  onEnterKey,
  className,
}: Props) => (
  <Container className={className}>
    <FormFieldHeader isRequired={isRequired} label={label} tooltipText={tooltipText} />

    <FlexContainer>
      <TextInput
        type={type}
        value={value}
        placeholder={placeholder}
        onBlur={() => onBlur && onBlur()}
        onEdit={(val: any) => onEdit && onEdit(val)}
        autoFocus={autoFocus}
        onEnterKey={onEnterKey}
      />
    </FlexContainer>

    {!isValid && <ValidationMessage>{validationMessage}</ValidationMessage>}
  </Container>
);
export default FormFieldText;
