// @ts-nocheck
// @ts-ignore

import set from 'lodash/set';
import get from 'lodash/get';
import { EXTRA_HIGHLIGHTED_FIELDS } from './constants';
import { stripHtml } from '../../../../utils/string';
export function flattenMatchedFields(fields: any) {
  return fields.reduce((acc: any, { field, values }: any) => {
    [acc[field]] = values;
    return acc;
  }, {});
}
export const populateHighlightedFields = (commonFields: any, highlightedFields: any) => {
  Object.keys(highlightedFields).forEach((key) => set(commonFields, key, highlightedFields[key]));
  return commonFields;
};
export const getRegistrationNumber = (item: any) =>
  item.nlKvkNumber || item.beEnterpriseNumber || item.gbCroRegistrationNumber;
export const getAddress = (country: any, item = {}) => {
  if (country === 'gb') {
    const ukAddress = new Set();

    if (item.addressLine1) {
      ukAddress.add(item.addressLine1);
    }

    if (item.addressLine2) {
      ukAddress.add(item.addressLine2);
    }

    if (item.addressLine3) {
      ukAddress.add(item.addressLine3);
    }

    if (item.addressLine4) {
      ukAddress.add(item.addressLine4);
    }

    if (item.addressLine5) {
      ukAddress.add(item.addressLine5);
    }

    if (item.postcode) {
      ukAddress.add(item.postcode);
    }

    // @ts-ignore
    return [...ukAddress].join(', ');
  }

  const nlbeAddress = [];

  if (item.street) {
    if (item.houseNumber) {
      nlbeAddress.push(`${item.street} ${item.houseNumber}`);
    } else {
      nlbeAddress.push(item.street);
    }
  }

  if (item.city) {
    if (item.postcode) {
      nlbeAddress.push(`${item.postcode} ${item.city}`);
    } else {
      nlbeAddress.push(item.city);
    }
  }

  return nlbeAddress.join(', ');
};
export function getExtraHighlightedFields({ highlightFields, countryCode, organization }: any) {
  return Object.keys(highlightFields).reduce((acc, key) => {
    const highlightedField = EXTRA_HIGHLIGHTED_FIELDS.find((field) => key.startsWith(field));

    if (highlightedField != null && acc[highlightedField] == null) {
      // `highlightedField` is a path within the `organization` object
      let props = get(organization, highlightedField);

      if (highlightedField.match(/address/i)) {
        props = getAddress(countryCode, props);
      }

      return { ...acc, [highlightedField]: props };
    }

    return acc;
  }, {});
}
export const mapper = (country: any, { matchedFields, organization }: any) => {
  const {
    id: cleanId,
    enterpriseId: cleanEnterpriseId,
    nlKvkNumber,
    organizationType,
    nlYCompanyId,
    gbCroRegistrationNumber,
    beEnterpriseNumber,
    companyName: originalCompanyName,
    vatNumber,
  } = organization;
  const highlightFields = flattenMatchedFields(matchedFields);
  const commonFields = populateHighlightedFields(organization, highlightFields);
  const { id, companyName, registeredAddress, isActive } = commonFields;
  return {
    id: cleanId,
    companyName,
    originalCompanyName,
    organizationType,
    isActive,
    address: getAddress(country, registeredAddress),
    enterpriseId: cleanEnterpriseId,
    graydonCompanyId: nlYCompanyId || beEnterpriseNumber || gbCroRegistrationNumber || null,
    cocNumber: nlKvkNumber,
    highlightFields: getExtraHighlightedFields({
      highlightFields,
      organization,
      countryCode: country.toLowerCase(),
    }),
    registrationNumber: stripHtml(getRegistrationNumber(organization)),
    commonFields: {
      registrationNumber: stripHtml(getRegistrationNumber(commonFields)),
      id,
    },
    vatNumber,
  };
};
export const dataCleanup = (country: any, data: any) => {
  const { total, organizations_hits: organizations } = data;
  const result = {
    total,
    items: organizations.map(mapper.bind(null, country)),
  };
  return result;
};
