import styled from 'styled-components';
import Button from 'src/core/components/Button';
import * as colors from '@graydon/ui-colors';
export const ErrorContainer = styled.div`
  background-color: ${colors.white};
  text-align: center;
  width: 800px;
  margin-top: 130px;
  padding: 30px 0;
  border: 1px solid ${colors.grayLight4};
  border-radius: 4px;

  @media (max-width: 768px) {
    margin-top: 0px;
    border-radius: 0px;
  }
`;
export const Title = styled.div`
  margin-bottom: 30px;
  color: ${colors.blueLight3};
  font-size: 26px;
  font-weight: 500;
  text-align: center;
`;
export const ExpiredMessageBody = styled.div`
  font-size: 16px;
  font-weight: 300;
  padding: 0 120px;
  color: #545454;

  a {
    color: ${colors.blue};
  }

  @media (max-width: 768px) {
    padding: 0 20px;
    text-align: left;
  }
`;
export const MessageBody = styled(ExpiredMessageBody)`
  text-align: left;
`;
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 34px 0px 0px 0px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
export const ActionButton = styled(Button)<{ primary: boolean }>`
  padding: 15px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 300;
  border-radius: 4px;

  &:hover {
    background-color: ${({ primary }) => (primary ? colors.orangeDark : colors.blueDark)};
  }
`;
export const ActionMarginButton = styled(ActionButton)`
  margin-right: 20px;

  @media (max-width: 768px) {
    &:first-child {
      margin-right: 0px;
      margin-bottom: 20px;
    }
  }
`;
export const ListContainer = styled.div`
  margin-left: 60px;

  @media (max-width: 768px) {
    margin-left: 30px;
  }
`;
export const ListTitle = styled.span`
  font-weight: 500;
  margin-left: 19px;
`;
export const List = styled.ul`
  padding: 0;
  margin: 0;
  line-height: 1.5rem;
  display: table;
`;
export const ListItem = styled.li`
  list-style: none;
  padding-left: 10px;
  display: table-row;

  &::before {
    content: '•';
    color: #00545d;
    font-size: 1.5em;
    line-height: 0.5em;
    padding-right: 10px;
    position: relative;
    top: 0.125em;
    display: table-cell;
  }
`;
