import React from 'react';
import Link from 'src/core/components/Link';
import Popup from 'src/core/components/Popup';
import { I18nProp, injectI18n } from 'src/i18n';
import styled from 'styled-components';
import { getCountryOfResidence } from '../../../keycloak';
import { HeaderText } from '../helper-components';
import { DATA } from './constants';

const Text = styled.div`
  font-weight: 300;
  font-size: 0.875rem;
  line-height: 1.5rem;
`;

const InnerContainer = styled.div`
  width: 100%;
`;

export const EmailLink = styled(Link)<{
  href?: string;
}>`
  font-size: 0.875rem;
  line-height: 1.5rem;
`;

const Title = styled(HeaderText)<{
  withClose?: boolean;
}>`
  ${({ withClose }) => withClose && 'padding-right: 18px'}
`;

type ReportErrorModalProps = {
  consumerId?: string;
  onClose?: () => void;
};

const ReportErrorModal: React.FC<ReportErrorModalProps & I18nProp> = ({
  i18n,
  consumerId,
  onClose,
}) => {
  const { title, email, phoneNumber } = DATA[getCountryOfResidence(consumerId)];
  return (
    <Popup onClose={onClose}>
      <InnerContainer>
        <Title withClose={Boolean(onClose)}>{i18n.text('cr.modal.report-error.title')}</Title>

        <Text>{i18n.text('cr.modal.report-error.text')}</Text>

        <Text>{i18n.text(title)}</Text>

        <EmailLink href={`mailto:${email}`}>{email}</EmailLink>

        <Text>{phoneNumber}</Text>
      </InnerContainer>
    </Popup>
  );
};

export default injectI18n(ReportErrorModal);
