import get from 'lodash/get';
const naList = ['NA', '0', 'N', 'NIL', 'P', 'S', 'SN', 'X'];
export const extractCreditRatingData = (data: any) => {
  const ukCreditRating = get(
    data,
    'ratingsProfile.graydonGroupCreditScores.ukCreditRating.value',
    naList[0], // default value
  );
  const isDisabled = naList.includes(ukCreditRating);
  const ratingValue = ukCreditRating;
  const ratingCategory = isDisabled
    ? ukCreditRating
    : ukCreditRating.slice(ukCreditRating.length - 1);
  return {
    isDisabled,
    ratingValue,
    ratingCategory,
  };
};
