import React from 'react';
import styled, { css } from 'styled-components';
import cssColors from '../../constants/colors';
import Button from '../Button';
import { Heading2 as Heading } from '../Heading';

const icon = css`
  background: url(hideIcon) no-repeat 50% 0;
  background-size: 175px;
  padding-top: 190px;
`;

const DialogBody = styled.div<{
  hideIcon: boolean;
}>`
  display: block;
  margin: 20px auto;
  text-align: center;
  width: 75%;
  ${({ hideIcon }) => !hideIcon && icon};
  a,
  a:link,
  a:visited {
    color: ${cssColors.link};
    transition: color 0.3s;
  }

  a:hover,
  a:active {
    color: ${cssColors.linkDark};
  }
`;

type ErrorDialogProps = {
  heading?: string;
  errorMessage: string;
  buttonText?: string;
  actionHandler?: () => void;
  hideIcon?: boolean;
};

/* eslint-disable react/no-danger */
// Usage of `dangerouslySetInnerHTML` is to allow for (mailto) links in error
// messages (usually supplied through `i18n`).
const ErrorDialog = ({
  heading,
  errorMessage,
  actionHandler,
  buttonText,
  hideIcon = false,
  ...props
}: ErrorDialogProps) => (
  <DialogBody hideIcon={hideIcon} {...props}>
    {heading && <Heading>{heading}</Heading>}
    <p dangerouslySetInnerHTML={{ __html: errorMessage }} />
    {actionHandler && buttonText && <Button onClick={actionHandler}>{buttonText}</Button>}
  </DialogBody>
);

export default ErrorDialog;
