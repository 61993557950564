import styled, { css } from 'styled-components';
import breakpoints from '../../constants/breakpoints';

const SubMenu = styled.div<{
  expanded: boolean;
}>`
  @media screen and (max-width: ${breakpoints['portrait-min']}px) {
    max-height: 0;
    transition: max-height 0.20s ease-out;
    overflow: hidden;

    ${({ expanded }) =>
      expanded &&
      css`
        transition: max-height 0.2s ease-in;
        max-height: 999px;
      `}
`;

export default SubMenu;
