import React from 'react';
import Link from 'src/core/components/Link';
import { ContactsDetails, ContactsWrapper, Email, Subtitle, Phone } from './helper-components';
import { I18n } from 'src/i18n';
type Props = {
  i18n: I18n;
};

const ContactDetails = ({ i18n }: Props) => (
  <ContactsWrapper>
    <ContactsDetails>
      <Subtitle>{i18n.text('country.nl')}</Subtitle>

      <Email>
        <Link href="mailto:service@graydon.nl">service@graydon.nl</Link>
      </Email>

      <Phone>
        <div>+31 (020) 567 9501</div>

        <Link href="tel:+310205679501">+31 (020) 567 9501</Link>
      </Phone>
    </ContactsDetails>

    <ContactsDetails>
      <Subtitle>{i18n.text('country.be')}</Subtitle>

      <Email>
        <Link href="mailto:support@graydon.be">support@graydon.be</Link>
      </Email>

      <Phone>
        <div>+32 (03) 280 88 80</div>

        <Link href="tel:+32032808880">+32 (03) 280 88 80</Link>
      </Phone>
    </ContactsDetails>
  </ContactsWrapper>
);

export default ContactDetails;
