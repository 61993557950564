import { grayLight2, grayLight5, greenDark, greenLight, white } from '@graydon/ui-colors';
import styled from 'styled-components';
import { sans } from '../../../../constants/fonts';

export const Button = styled.button<{
  selected?: boolean;
  disabled?: boolean;
  uppercase?: boolean;
  width?: string;
  isFirst?: boolean;
  isLast?: boolean;
}>`
  font-family: ${sans};
  font-size: 14px;
  height: 44px;
  width: ${({ width = '66px' }) => width};
  border: none;
  outline: none;
  color: ${({ selected }) => (selected ? white : greenLight)};
  background: ${({ selected }) => (selected ? greenLight : white)};
  border: solid 1px ${grayLight2};
  border-color: ${({ selected }) => (selected ? greenLight : grayLight2)};
  ${({ uppercase }) => uppercase && 'text-transform: uppercase;'}
  ${({ isFirst }) => !isFirst && 'border-left: none;'}
    ${({ isFirst }) => isFirst && 'border-radius: 4px 0 0 4px;'}
    ${({ isLast }) => isLast && 'border-radius: 0 4px 4px 0;'}
    ${({ isLast, isFirst }) => isLast && isFirst && 'border-radius: 4px'}
  @media (max-width: 768px) {
    width: 50%;
    margin-bottom: 8px;
  }
  cursor: pointer;
  ${({ disabled, selected }) =>
    !disabled &&
    `
  &:hover, &:focus {
    background: ${selected ? greenDark : grayLight5};
  }`}
`;
