import { combineReducers } from 'redux';
import searchResults from '../components/SearchResults/reducer';
import reportId from '../components/SearchResultsItemButtons/reducer';
import organisation from '../Pages/Organisation/reducer';
import organisationCompanyHeader from '../components/CompanyHeader/reducer';
import archivedReports from '../Pages/Organisation/Archive/reducer';
import companyBox from '../Pages/Organisation/CompanyBox/reducer';
import monitoringProfiles from '../../CreditReports/shared/stores/monitoringProfiles/reducer';
import organisationTagUuids from '../../CreditReports/shared/stores/organisationTagUuids/reducer';
import modal from '../../CreditReports/shared/stores/modal/reducer';
import creditReportCreation from '../../../store/credit-reports';
import internationalCreditReports from '../../InternationalCreditReports/store/internationalCreditReports';
export const reducer = combineReducers({
  searchResults,
  reportId,
  organisation,
  organisationCompanyHeader,
  archivedReports,
  companyBox,
  monitoringProfiles,
  organisationTagUuids,
  modal,
  creditReportCreation,
  internationalCreditReports,
});
