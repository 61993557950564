import * as colors from '@graydon/ui-colors';
import React from 'react';
import { I18nProp, injectI18n } from 'src/i18n';
import styled from 'styled-components';
import TextIcon from '../../../../../components/TextIcon';
import { getCountryOfResidence } from '../../../../../keycloak';
import { Title } from '../helper-components';
const Container = styled.div`
  max-width: 720px;
  width: 100%;
  font-size: 16px;
  font-weight: 300;
  text-align: center;
  padding: 30px 0px;
  color: #545454;
  align-self: center;

  a {
    color: ${colors.blue};
  }

  @media (max-width: 768px) {
    padding: 10px 20px;
  }
`;

type SearchErrorProps = {
  consumerId: number | string;
};

export const SearchError: React.FC<SearchErrorProps & I18nProp> = ({ consumerId, i18n }) => (
  <Container>
    <TextIcon />

    <Title>{i18n.text('cr.error.search')}</Title>

    <div>
      <div>{i18n.text('cr.error.search.text')}</div>

      <div
        dangerouslySetInnerHTML={{
          __html: i18n.text('cr.error.search.contacts', {
            country: getCountryOfResidence(consumerId.toString()),
          }),
        }}
      />
    </div>
  </Container>
);
export default injectI18n(SearchError);
