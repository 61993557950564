import get from 'lodash/get';
import { parseDate, extractRegisteredAddress } from '../common';
import { getFormattedAddress } from '../../../../../utils/getFormattedAddress';
import { keyGenerator } from '../../../../../utils/keyGenerator';
import { extractInsolvencies } from '../extractFinancialInsolvenciesData';

const isExist = (value: any) => value !== null && typeof value !== 'undefined';

const getKey = keyGenerator();
export const comparator = (a: any, b: any) => {
  // Compare percentage first
  if (isExist(a.percentageOfShares) && !isExist(b.percentageOfShares)) return -1;
  if (!isExist(a.percentageOfShares) && isExist(b.percentageOfShares)) return 1;
  if (a.percentageOfShares > b.percentageOfShares) return -1;
  if (a.percentageOfShares < b.percentageOfShares) return 1;
  // Compare start dates
  // Filled start date leads before unknown start date
  if (a.startDate && !b.startDate) return -1;
  if (!a.startDate && b.startDate) return 1;

  if (a.startDate && b.startDate) {
    if (a.startDate.getTime() > b.startDate.getTime()) return -1;
    if (a.startDate.getTime() < b.startDate.getTime()) return 1;
  }

  // Compare company names
  if (isExist(a.companyName) && !isExist(b.companyName)) return -1;
  if (!isExist(a.companyName) && isExist(b.companyName)) return 1;
  if (a.companyName < b.companyName) return -1;
  if (a.companyName > b.companyName) return 1;
  return 0;
};
export const getActiveShare = (sharesList: any) =>
  sharesList.find((it: any) => it.endDate === null || typeof it.endDate === 'undefined');
export const extractCompanyData = (type: any, companyData: any) => {
  const countryCode = get(companyData, 'company.companyIdentification.countryCode', '');
  const enterpriseId = get(companyData, 'company.companyIdentification.enterpriseId', null);
  const addresses = get(companyData, 'company.companyProfile.addresses');
  // If enterpriseId exists (that means it is a domestic company), there must be a registered address available,
  // otherwise we pick up the first available address item
  const address = enterpriseId ? extractRegisteredAddress(addresses) : addresses[0] || {};
  const share = getActiveShare(get(companyData, 'shares', []));
  const insolvencies = get(companyData, 'company.companyProfile.financialCalamities', []);
  const mappedInsolvencies = extractInsolvencies(countryCode, insolvencies);
  const creditFlag = get(
    companyData,
    'company.ratingsProfile.graydonGroupCreditScores.creditFlag.value',
    'NA',
  );
  return {
    key: getKey(),
    type,
    companyName: get(companyData, 'company.companyProfile.companyName', null),
    isActive: get(companyData, 'company.companyProfile.companyStatus.isActive', null),
    enterpriseId,
    graydonCompanyId: get(companyData, 'company.companyIdentification.graydonCompanyId', null),
    registrationNumber: get(companyData, 'company.companyIdentification.registrationId', null),
    percentageOfShares: get(share, 'percentage'),
    startDate:
      (share && parseDate(get(share, 'startDate', null))) ||
      parseDate(get(companyData, 'startDate', null)),
    endDate: parseDate(get(companyData, 'endDate', null)),
    address: getFormattedAddress(address.country, address),
    activeCalamity: mappedInsolvencies[0],
    creditFlag,
    countryCode: get(companyData, 'company.companyIdentification.countryCode', null),
  };
};

const getParticipantsWithAnActiveShare = (participations: any) =>
  participations.reduce((acc: any, participation: any) => {
    const activeShare = getActiveShare(get(participation, 'shares', []));

    if (activeShare) {
      participation.activeShare = activeShare;
      acc.push({ ...participation, activeShare });
    }

    return acc;
  }, []);

const getParticipations = (participantsWithAnActiveShare: any) =>
  participantsWithAnActiveShare.filter(
    (p: any) => p.activeShare.percentage <= 50 || typeof p.activeShare.percentage === 'undefined',
  );

const getDaughters = (participantsWithAnActiveShare: any) =>
  participantsWithAnActiveShare.filter(
    (d: any) => d.activeShare.percentage && d.activeShare.percentage > 50,
  );

export const extractGroupStructureData = (data: any) => {
  const country = get(data, 'companyIdentification.countryCode', '');
  let ultimateParent = null;
  const ultimateParentData = get(data, 'affiliationsProfile.ultimateParent', null);

  if (ultimateParentData) {
    ultimateParent = extractCompanyData('ULTIMATE_MOTHER', ultimateParentData);
  }

  let parent = null;
  const parentData = get(data, 'affiliationsProfile.parent', null);

  if (parentData) {
    parent = extractCompanyData('MOTHER', parentData);
  }

  const participationsWithAnActiveShare = getParticipantsWithAnActiveShare(
    get(data, 'affiliationsProfile.participations', []),
  );
  const sistersWithAnActiveShare = getParticipantsWithAnActiveShare(
    get(data, 'affiliationsProfile.sisters', []),
  );
  const participations = getParticipations(participationsWithAnActiveShare)
    .map((p: any) => extractCompanyData('PARTICIPANT', p))
    .sort(comparator);
  const daughters = getDaughters(participationsWithAnActiveShare)
    .map((d: any) => extractCompanyData('DAUGHTER', d))
    .sort(comparator);
  const sisters = sistersWithAnActiveShare
    .map((s: any) => extractCompanyData('SISTER', s))
    .sort(comparator);
  const reportCompany = {
    key: getKey(),
    type: 'REPORT_COMPANY',
    companyName: get(data, 'companyProfile.companyName', null),
  };
  return {
    country,
    ultimateParent,
    parent,
    reportCompany,
    daughters,
    participations,
    sisters,
  };
};
