import createReducer from 'create-reducer-map';
import { SET_ORGANISATION_COMPANY_BOX_DATA } from './constants';
export const initialState = {
  countryCode: 'NL',
  companyName: null,
  kvkNumber: null,
  vatNumber: null,
  graydonNumber: null,
  previousCompanyName: '',
  branches: [],
  tradeNames: [],
};
export default createReducer(initialState, {
  [SET_ORGANISATION_COMPANY_BOX_DATA]: (state: any, payload: any) => payload,
});
