import React from 'react';
import { ReactNode } from 'react';
import CircleLoader from 'src/core/components/loaders/CircleLoader';
import ErrorPage, { ErrorMessage, ErrorHeading } from '../../components/ErrorPage';
import { I18n } from 'src/i18n';

type Props = {
  i18n: I18n;
  requestState: any;
  renderResponse: (response: any) => any;
  renderError?: (error: Error) => ReactNode;
  renderLoading?: () => ReactNode;
};
export function renderRequestError(
  i18n: I18n,
  error?: Error | null | undefined,
  customMessage?: string | null | undefined,
) {
  if (error) {
    if (error.message === '403' || error.message === '401') {
      return (
        <ErrorPage>
          <ErrorHeading>{i18n.text('um.authorization-title')}</ErrorHeading>
          <ErrorMessage>{i18n.text('um.no-authorization')}</ErrorMessage>
        </ErrorPage>
      );
    }

    if (error.message === '404') {
      return (
        <ErrorPage>
          <ErrorHeading>{i18n.text('info.404.oops')}</ErrorHeading>
          <ErrorMessage>{i18n.text('info.404.does-not-exist')}</ErrorMessage>
        </ErrorPage>
      );
    }
  }

  return (
    <ErrorPage>
      <ErrorHeading>{i18n.text('uploading.errorPage.heading')}</ErrorHeading>

      <ErrorMessage>{customMessage || i18n.text('uploading.error.message')}</ErrorMessage>
    </ErrorPage>
  );
}

const RequestWrapper = ({
  i18n,
  requestState,
  renderResponse,
  renderError,
  renderLoading,
}: Props) => {
  if (requestState instanceof Error) {
    if (renderError) return renderError(requestState);
    return renderRequestError(i18n, requestState);
  }

  if (!requestState || requestState === 'loading') {
    if (renderLoading) return renderLoading();
    return <CircleLoader />;
  }

  return renderResponse(requestState);
};

export default RequestWrapper;
