import React from 'react';
import styled from 'styled-components';

type HomeButtonProps = {
  homePath: string;
  isHomePathRouterLink?: boolean;
  Link?: React.ComponentType<any>;
};

const HomeButton = ({ homePath, isHomePathRouterLink, Link, ...props }: HomeButtonProps) =>
  isHomePathRouterLink && Link ? (
    <Link to={homePath} {...props}>
      <span>Home</span>
    </Link>
  ) : (
    <a href={homePath} {...props}>
      <span>Home</span>
    </a>
  );

export default styled(HomeButton)`
  text-decoration: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='60 70 320 320'%3E%3Cpath fill='%23FFF' d='M168.68,204.08c0,0,13.29-92.49,92.49-93h92.08c0,0-18.19,93-93.69,93H168.68z'/%3E%3Cpath fill='%23FFF' d='M72,314.13c0,0,13.29-92.51,92.48-93h92.09c0,0-18.2,93-93.69,93H72z'/%3E%3C/svg%3E%0A");
  background-position: right center;
  background-repeat: no-repeat;
  width: 2.8em;
  height: 2.2em;
  display: inline-block;
  transition: all 0.2s ease-out;

  &:hover {
    transform: scale(1.1) rotate(-2deg);
  }

  > span {
    position: absolute;
    left: -99em;
  }
`;
