// @ts-nocheck
const event = 'GAevent';

/**
 * Send a Google Analytics event
 * @param {string} eventCategory Typically the object that was interacted with (e.g. 'Video')
 * @param {string} eventAction The type of interaction (e.g. 'play')
 * @param {string} [eventLabel] Useful for categorizing events (e.g. 'Fall Campaign')
 * @param {number} [eventValue] A numeric value associated with the event (e.g. 42)
 */
export function sendEvent(
  eventCategory: string,
  eventAction: string,
  eventLabel?: string,
  eventValue?: number,
): void {
  if (!eventAction) throw new Error('eventAction is required');

  if (window.dataLayer && window.dataLayer.push) {
    const obj: any = {
      event,
      eventCategory,
      eventAction,
    };
    if (eventLabel) obj.eventLabel = eventLabel;
    if (eventValue) obj.eventValue = eventValue;
    window.dataLayer.push(obj);
    if (process.env.NODE_ENV === 'development') console.log(obj);
  }
}

/**
 * CR Google Analytics util for sending analytics requests on Tile Tooltip click
 */
export const sendEventOnTileTooltipShow =
  (eventCategory: string, eventAction: string) => (isShown: boolean) => {
    if (isShown) sendEvent(eventCategory, eventAction);
  };
