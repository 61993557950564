import React, { lazy, useEffect } from 'react';
import { Provider } from 'react-redux';
import styled from 'styled-components';
import { Route, Switch } from 'react-router-dom';
import configureStore from './store/createStore';
import SearchPage from './Pages/Search';
import AppModals from './components/AppModals';
import Page404 from '../../components/Page404';
import PageUnauthorized from '../../components/PageUnauthorized';
import getRouteProps, {
  COMPANY_SEARCH_ROUTE,
  ORGANISATION_ROUTE,
  RAW_DATA_ROUTE,
  PREFERENCES_ROUTE,
} from './routes-config';
import { isDeliveryManager } from '../../keycloak';

const JsonViewer = lazy(
  () =>
    import(
      /* webpackChunkName: "json-viewer" */
      './components/JsonViewer'
    ),
);
const OrganisationPage = lazy(
  () =>
    import(
      /* webpackChunkName: "organisation-page" */
      './Pages/Organisation'
    ),
);
const PreferencesPage = lazy(
  () =>
    import(
      /* webpackChunkName: "preferences-page" */
      './Pages/Preferences'
    ),
);
const AppContainer = styled.div`
  height: 100%;
`;

const CompanyData: React.FC = () => {
  let store = configureStore({});

  useEffect(() => {
    return () => {
      store = null;
    };
  }, []);

  return (
    <AppContainer>
      <Provider store={store}>
        <Switch>
          <Route {...getRouteProps(COMPANY_SEARCH_ROUTE)} component={SearchPage} />
          <Route {...getRouteProps(RAW_DATA_ROUTE)} component={JsonViewer} />
          <Route {...getRouteProps(ORGANISATION_ROUTE)} component={OrganisationPage} />
          <Route
            {...getRouteProps(PREFERENCES_ROUTE)}
            render={() => (isDeliveryManager() ? <PreferencesPage /> : <PageUnauthorized />)}
          />
          <Route component={Page404} />
        </Switch>
        <AppModals />
      </Provider>
    </AppContainer>
  );
};

export default CompanyData;
