import get from 'lodash/get';
import { getPrimaryActivity } from '../common';
export const extractGdiTranslations = (data: any) => {
  const events = get(data, 'operationsProfile.events', []);
  const xseptions = get(data, 'xseptionsProfile.xseptions', []);
  const companyProfileTranlationKeys = {
    'companyProfile.legalForm': get(data, 'companyProfile.legalForm.code.descriptions'),
    'companyProfile.industry': get(data, 'companyProfile.industry.0.descriptions'),
    'companyProfile.activities': get(getPrimaryActivity(data), 'code.descriptions'),
    'companyProfile.employees': get(data, 'companyProfile.employees.category.descriptions'),
  };
  const eventsTranslationKeys = events.reduce((acc: any, event: any) => {
    const category = get(event, 'category', {});
    const source = get(event, 'source', {});
    const description = get(event, 'type', {});
    acc[`exceptionalEvents.events.description.typeCode-${get(description, 'value', 'default')}`] =
      get(description, 'descriptions', []);
    acc[`exceptionalEvents.events.category.typeCode-${get(category, 'value', 'default')}`] = get(
      category,
      'descriptions',
      [],
    );
    acc[`exceptionslEvents.events.source.typeCode-${get(source, 'value', 'default')}`] = get(
      source,
      'descriptions',
      [],
    );
    return acc;
  }, {});
  const xseptionsTranslationKeys = xseptions.reduce((acc: any, xseption: any) => {
    const category = get(xseption, 'category', {});
    const description = get(xseption, 'type', {});
    acc[`exceptionalEvents.xseptions.category.typeCode-${get(category, 'value', 'default')}`] = get(
      category,
      'descriptions',
      [],
    );
    acc[
      `exceptionalEvents.xseptions.description.typeCode-${get(description, 'value', 'default')}`
    ] = get(description, 'descriptions', []);
    return acc;
  }, {});
  return { ...companyProfileTranlationKeys, ...eventsTranslationKeys, ...xseptionsTranslationKeys };
};
