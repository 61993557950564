import { createAction } from 'redux-actions';
import { isDeliveryAdminUser } from '../../../../keycloak';

function setClientInSessionStorage(client: any) {
  if (client) {
    sessionStorage.setItem('client-session', JSON.stringify(client));
  } else {
    sessionStorage.removeItem('client-session');
  }
}

export function getClientFormSessionStorage() {
  const data = sessionStorage.getItem('client-session');

  if (data && isDeliveryAdminUser()) {
    try {
      return JSON.parse(data);
    } catch (e) {
      return null;
    }
  }

  return null;
}
export const setClientSession = createAction('SET_CLIENT_SESSION');
export const setClientView = createAction('SET_CLIENT_VIEW');
export const setClientSessionAction = (dispatch: any) => (clientSession: any) => {
  setClientInSessionStorage(clientSession);
  dispatch(setClientSession(clientSession));
};
export const initClientSessionAction = (dispatch: any) => {
  const clientSession = getClientFormSessionStorage();
  dispatch(setClientSession(clientSession));
};
