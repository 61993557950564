import { injectI18n } from 'src/i18n';
import compose from 'lodash/fp/compose';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { useProcessFlow } from '../../hooks/useProcessFlow';
import { I18n } from 'src/i18n';
import Menu from './menu';
import Modals from './modals';
import { ReportType } from './reportOptions';
import useModals from './useModals';

export type CompanyData = {
  enterpriseId: number;
  countryCode: string;
  cocNumber?: string;
  companyName?: string;
  registrationNumber?: string;
  graydonNumber?: string;
  graydonCompanyId?: number;
};

export type ReportProps = {
  i18n: I18n;
  history: {
    push: (path: string) => any;
  };
  context: string;
  app: string;
  exclude: ReportType[];
  company: CompanyData;
  className?: string;
  openFromLeft?: boolean;
  openInside?: boolean;
  clicked?: any;
};

export const ReportMenu: React.FC<ReportProps> = ({
  i18n,
  history,
  context,
  app,
  company,
  exclude = [],
  clicked,
  className,
  openFromLeft,
  openInside,
}) => {
  const [
    { monitoringModal, cocModal, annualAccountModal, gdmModal },
    { setMonitoringModal, setCocModal, setAnnualAccountModal, setGdmModal },
  ] = useModals();

  const [process, loadProcess, reset] = useProcessFlow();
  return (
    <>
      {/* @ts-ignore */}
      <Modals
        {...{
          process,
          monitoringModal,
          setMonitoringModal,
          cocModal,
          setCocModal,
          gdmModal,
          setGdmModal,
          company,
          app,
          context,
          reset,
          annualAccountModal,
          setAnnualAccountModal,
        }}
      />

      <Menu
        {...{
          i18n,
          history,
          context,
          app,
          company,
          exclude,
          clicked,
          className,
          loadProcess,
          setMonitoringModal,
          setCocModal,
          setGdmModal,
          openFromLeft,
          openInside,
          setAnnualAccountModal,
        }}
      />
    </>
  );
};
export default compose(injectI18n, withRouter)(ReportMenu);
