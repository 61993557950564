import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { post } from '../../utils/api';
import ReportFetchingModal from '../CRModals/ReportFetchingModal';
import ReportErrorModal from '../CRModals/ReportErrorModal/component';

type Props = {
  onClose: () => void;
  enterpriseId: number;
  registrationId: string;
  countryCode: string;
};

type State = {
  requestStatus: null | 'loading' | Error;
};

class ExternalUboReportModal extends React.Component<Props & RouteComponentProps, State> {
  state = {
    requestStatus: null,
  };

  componentDidMount() {
    this.createReportAndRedirect();
  }

  createReportAndRedirect() {
    const { enterpriseId, registrationId, countryCode } = this.props;
    this.setState({
      requestStatus: 'loading',
    });
    post('/credit-reports/ubo/report', {
      enterpriseId,
      registrationId,
      countryCode,
    })
      .then(({ reportId }) => {
        this.setState({
          requestStatus: null,
        });
        this.props.history.push(`/ubo-reports/report/${reportId}`);
      })
      .catch((error) => {
        this.setState({
          requestStatus: error,
        });
      });
  }

  render() {
    const { onClose } = this.props;
    const { requestStatus } = this.state;
    if (requestStatus === 'loading') return <ReportFetchingModal />;
    if (requestStatus instanceof Error) return <ReportErrorModal onClose={onClose} />;
    return null;
  }
}

export default withRouter(ExternalUboReportModal);
