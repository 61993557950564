import compose from 'lodash/fp/compose';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { I18nProp, injectI18n } from 'src/i18n';
import Link from '../../../../../components/Link';
import {
  getConsumerId,
  getCountryOfResidence,
  hasFreshInvestigationOnDomesticCountries,
} from '../../../../../keycloak';
import { sendEvent } from '../../../../../utils/analytics';
import { InvestigationMessage, Message } from '../helper-components';
import { CountryCode } from 'src/types/locales';

type OnlyFreshInvestigationProps = {
  country?: CountryCode;
};

const OnlyFreshInvestigation: React.FC<
  OnlyFreshInvestigationProps & I18nProp & RouteComponentProps
> = ({ i18n, history, country }) => (
  <InvestigationMessage>
    {!hasFreshInvestigationOnDomesticCountries(
      country.toUpperCase() as CountryCode,
      getConsumerId(),
    ) ? (
      <Message
        dangerouslySetInnerHTML={{
          __html: i18n.text('icr.search-page.service-level-investigation.domestic.message', {
            country: getCountryOfResidence(getConsumerId()),
          }),
        }}
      />
    ) : (
      <>
        <Message>
          {i18n.text('icr.search-page.service-level-investigation.message', {
            country: i18n.text(`icr.country.${country}`),
          })}
        </Message>
        <Link
          onClick={() => {
            sendEvent(
              'ICR',
              'Search page: Service level = investigation: Click Fresh Investigation link',
              country,
            );
            history.push(`/international-credit-reports/order-investigated-report/${country}`);
          }}
        >
          {i18n.text('icr.search-page.service-level-investigation.link')}
        </Link>
      </>
    )}
  </InvestigationMessage>
);

export default compose(injectI18n, withRouter)(OnlyFreshInvestigation);
