import styled from 'styled-components';
import * as colors from '@graydon/ui-colors';
import { sans } from '../../constants/fonts';
export const ContactsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 768px) {
    flex-direction: row;
    justify-content: space-around;
  }
`;
export const ContactsDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  a {
    text-decoration: underline;
  }
`;
export const Subtitle = styled.h2`
  font-family: ${sans};
  font-size: 1.25rem;
  color: ${colors.greenLight};
  margin: 24px 0 8px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @media screen and (min-width: 768px) {
    margin: 48px 0 8px;
  }
`;
export const Email = styled.div`
  margin-bottom: 10px;
`;
export const Phone = styled.div`
  div {
    display: none;
  }
  a {
    display: block;
  }

  @media screen and (min-width: 768px) {
    div {
      display: block;
    }
    a {
      display: none;
    }
  }
`;
