import React from 'react';
import { useDispatch } from 'react-redux';
import CircleLoader from 'src/core/components/loaders/CircleLoader';
import SearchResultsList from '../../../../components/SearchResultsList';
import SearchResultsListItem from '../../../../components/SearchResultsListItem';
import {
  getConsumerId,
  hasAccessToICRPaygo,
  hasFreshInvestigationOnDomesticCountries,
} from '../../../../keycloak';
import SearchErrorMessage from '../Errors/SearchError';
import SearchResultsItemButtons from '../SearchResultsItemButtons/component';
import { ErrorWrapper, Wrapper } from './helper-components';
import AvailabilityPopup from '../../../../components/AvailabilityPopup';
import { popupCompanySet } from '../../../InternationalCreditReports/store/internationalCreditReports';
import {
  COMMON_FIELDS,
  TRANSLATION_KEY_PREFIX,
} from '../../../../components/SearchResultsListItem/utils';

type Props = {
  isFetching;
  isError;
  countryCode;
  items;
  total;
  serviceLevel;
};

const SearchResults: React.FC<Props> = ({
  isFetching,
  isError,
  countryCode = '',
  items = null,
  total,
  serviceLevel,
}) => {
  const dispatch = useDispatch();

  const prepareAvailability = ({
    id,
    registrationNumber,
    vatNumber,
    originalCompanyName,
    address,
  }: any) => {
    dispatch(
      popupCompanySet({
        companyName: originalCompanyName,
        address: {
          entireAddress: address,
        },
        companyIdentifier: {
          vatNumber: vatNumber || registrationNumber,
          agencyCompanyId: id,
        },
        isDatabaseReportAvailable: true,
        // icr report for domestic country always there
        countryCode,
      }),
    );
  };

  if (isFetching) {
    return (
      <Wrapper>
        <CircleLoader />
      </Wrapper>
    );
  }

  if (isError) {
    return (
      <ErrorWrapper>
        <SearchErrorMessage consumerId={getConsumerId()} />
      </ErrorWrapper>
    );
  }

  const showFreshInvestigationLinkOnNoResults =
    hasAccessToICRPaygo() && hasFreshInvestigationOnDomesticCountries(countryCode.toUpperCase());
  const TARGET_FIELD = 'registrationNumber';

  const getItemTranslationCD =
    (country: any) => (i18n: any, field: any, fieldType: any, options: any) => {
      return field === TARGET_FIELD && fieldType === COMMON_FIELDS
        ? i18n.text(`${TRANSLATION_KEY_PREFIX}.${TARGET_FIELD}.${country}`)
        : i18n.text(`${TRANSLATION_KEY_PREFIX}.${field}`, options);
    };

  return (
    items && (
      <>
        <SearchResultsList
          total={total}
          showFreshInvestigationLinkOnNoResults={showFreshInvestigationLinkOnNoResults}
          countryCode={countryCode}
          onlyFreshInvestigationAllowed={serviceLevel === 'investigation'}
        >
          {items.map(
            ({
              id,
              registrationNumber,
              companyName,
              originalCompanyName,
              organizationType,
              isActive,
              address,
              enterpriseId,
              graydonCompanyId,
              cocNumber,
              commonFields,
              highlightFields,
              vatNumber,
            }: any) => (
              <SearchResultsListItem
                key={id}
                companyName={companyName}
                organizationType={organizationType}
                isActive={isActive}
                isCompanyData
                address={address}
                commonFields={commonFields}
                highlightFields={highlightFields}
                buttons={
                  <SearchResultsItemButtons
                    clicked={(type: any) =>
                      type === 'international-credit-report' &&
                      prepareAvailability({
                        id,
                        vatNumber,
                        registrationNumber,
                        originalCompanyName,
                        address,
                      })
                    }
                    graydonNumber={id}
                    enterpriseId={enterpriseId}
                    graydonCompanyId={graydonCompanyId}
                    selectedCountry={countryCode.toUpperCase()}
                    cocNumber={cocNumber}
                    registrationNumber={registrationNumber}
                    companyName={originalCompanyName}
                  />
                }
                getItemTranslationFunc={getItemTranslationCD(countryCode)}
                showExtraFields
                showActivity
              />
            ),
          )}
        </SearchResultsList>

        <AvailabilityPopup isFreshInvestigationAvailable />
      </>
    )
  );
};

export default SearchResults;
