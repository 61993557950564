import Tooltip from 'src/core/components/Tooltip';
import React from 'react';
import * as colors from '@graydon/ui-colors';
import { FlexContainer } from '../helper-components';
import FormFieldLabel from './FormFieldLabel';
import { I18nProp } from 'src/i18n';

type FormFieldHeaderProps = {
  isRequired?: boolean;
  label?: string;
  tooltipText?: string;
  className?: string;
};

export const FormFieldHeader: React.FC<FormFieldHeaderProps & I18nProp> = ({
  i18n,
  isRequired = false,
  label,
  tooltipText,
  className,
}) => {
  return (
    <FlexContainer className={className}>
      <FormFieldLabel isRequired={isRequired} text={label} i18n={i18n} />

      {tooltipText && (
        <Tooltip iconColor={colors.greenDark} bgColor={colors.white}>
          {tooltipText}
        </Tooltip>
      )}
    </FlexContainer>
  );
};

export default FormFieldHeader;
