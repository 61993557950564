import { getFilenameFromHeader } from 'src/core/utils/http';

function bytesToMegabytes(b: any) {
  return (b / 1000000).toFixed(2);
}

export default {
  bytesToMegabytes,
  getFilenameFromHeader,
};
export { bytesToMegabytes, getFilenameFromHeader };
