export default {
  'cr.ratings.credit.flag.tooltip.NL\n  \n \n\n\n':
    'The credit flag acts as a traffic light, and in short is a simple translation of the credit advice and the Graydon Rating. Green = lending does not cause any problems. Orange = granting credit is associated with an increased risk. Red = Graydon advises against pre-financing. The credit limit indicates the amount up to which you can do business with a company. The credit limit forms a value judgment in the short term.',
  'aa.not-available.message':
    'Unfortunately the Advanced Analytics application is not available anymore.',
  'aa.not-available.title': 'Application not available',
  'aa.not-available.contact-service': 'Please contact our Customer Service team.',
  'aa.modal.annual-account': 'Annual account',
  'aa.modal.download': 'Buy',
  'aa.modal.download-archive': 'Download',
  'aa.modal.download.error.message': 'Please try again later or contact Graydon.',
  'aa.modal.download.error.title': 'Request could not be sent',
  'aa.modal.download.not-available': 'Not available',
  'aa.modal.download.not-found.message':
    'The annual account cannot be retrieved. Please contact Graydon.',
  'aa.modal.download.not-found.title': 'Annual account not found',
  'aa.modal.download.success.message':
    'The annual account that you requested is available from the download bar and from Company Archive.',
  'aa.modal.download.success.title': 'Report has been downloaded',
  'aa.modal.explanation': 'Choose for which year you want to buy and download the annual accounts',
  'aa.modal.fetch-list.error.message':
    'We couldn’t load the Annual accounts at this moment. Please try again later.',
  'aa.modal.fetch-list.error.title': 'Something went wrong…',
  'aa.modal.fetch-list.not-found.message':
    'There are no original Annual Accounts available for this company.',
  'aa.modal.fetch-list.not-found.title': 'No Annual Accounts available',
  'aa.modal.title': 'Download Annual Accounts',
  'aa.report.errorFetching': 'Error fetching available report!',
  'aa.report.noReport':
    'Unfortunately, this information is not available online.<br /><br />However, for assistance, please contact our Customer Service Team at <a href="mailto:{country, select, gb {customerservices@graydon.co.uk} be {support@graydon.be} other {service@graydon.nl}}">{country, select, gb {customerservices@graydon.co.uk} be {support@graydon.be} other {service@graydon.nl}}</a> or on {country, select, gb {+44 (020) 8515 1400} be {+32 (03) 280 88 80} other {+31 (020) 567 9501}}.',
  'address.city': 'Town/City',
  'address.house.number': 'House nbr.',
  'address.street.or.po.box': 'Street or PO Box',
  'address.zip.code': 'Postcode',
  'addToLists.link': 'Add to…',
  'addToMonitoring.companyAdded': 'Company <b>added</b> to list',
  'addToMonitoring.companyRemoved': 'Company <b>removed</b> from list',
  'addToMonitoring.database-management': 'Database Management ',
  'addToMonitoring.database-management.desc':
    'In order to receive data from a company, it should be added to a Database Management list. A company can be added to or removed from the lists below.',
  'addToMonitoring.done': 'Done',
  'addToMonitoring.goToMonitoring': 'Go to Monitoring',
  'addToMonitoring.heading': 'Add company to list(s)',
  'addToMonitoring.link': 'Add to Monitoring',
  'addToMonitoring.lists.goToListManagement': 'Go to List Management',
  'addToMonitoring.lists.noneFound': 'No list(s) found',
  'addToMonitoring.lists.paragraph': 'Choose one or several lists',
  'addToMonitoring.lists.subheading': 'Other lists',
  'addToMonitoring.monitoring': 'Monitoring',
  'addToMonitoring.monitoring.desc':
    'To monitor a company, it should be added to a monitored list. A company can be added to or removed from the lists below.',
  'addToMonitoring.noMonitoredLists': 'No monitored list found',
  'addToMonitoring.noMonitoredLists.paragraph':
    'To start monitoring a company, you should create a monitoring profile first and relate a company list to the profile.',
  'addToMonitoring.relatedProfiles': 'related monitoring profile(s):',
  'addToMonitoring.subheading': 'Monitoring lists',
  'app-home.app.name.advanced-analytics': 'Advanced Analytics',
  'app-home.app.name.app-home.app.name.decision-model': 'Decision Model',
  'app-home.app.name.archive': 'Archive',
  'app-home.app.name.data-enrichment': 'Data Enrichment',
  'app-home.app.name.list-management': 'List Management',
  'app-home.app.name.management-dashboard': 'Management Dashboard',
  'app-home.app.name.marketing-information': 'Market Data',
  'app-home.app.name.reports': 'International Business',
  'app-home.app.name.uploads': 'Uploads',
  'app-home.apps.heading': 'My other products',
  'app-home.main.title': 'What would you like to do today?',
  'apps.advanced-analytics': 'Advanced Analytics',
  'apps.advanced-analytics.description': 'Check tailored analytics data',
  'apps.business-risk': 'Business Risk',
  'apps.business-risk.description': 'The power to stop fraud and financial crime',
  'apps.business-radar': 'Business Radar',
  'apps.business-radar.description': 'Check news updates',
  'apps.company-data': 'Company Data',
  'apps.consumer-credit-check': 'Consumer Credit Check',
  'apps.consumer-credit-check.description': "Check a person's creditworthiness",
  'apps.contact': 'Customer Care',
  'apps.credit-reports': 'Company Data',
  'apps.data-enrichment': 'Data Enrichment',
  'apps.data-enrichment.description': 'Enrich your company data set',
  'apps.database-management': 'Database Management ',
  'apps.database-management.description': 'Keep your company data up-to-date',
  'apps.decision-model': 'Decision Model',
  'apps.decision-model.description': 'Manage decision models and consult archive',
  'apps.dm-v2': 'Decision Model',
  'apps.international-business-archive': 'International Business Archive',
  'apps.international-business-archive.description': 'Database reports and Fresh Investigations',
  'apps.international-credit-reports': 'International Business',
  'apps.ledger-analyser': 'Ledger Management',
  'apps.ledger-analyser.description': 'Find risks in your ledger',
  'apps.list-management': 'List Management',
  'apps.list-management.description': 'Manage your companies of interest',
  'apps.management-dashboard': 'Management Dashboard',
  'apps.management-dashboard.description': 'View usage of Graydon apps',
  'apps.marketing-information': 'Market Data',
  'apps.marketing-information.description': 'Find the right prospects',
  'apps.monitoring': 'Monitoring Tool',
  'apps.monitoring.description': 'Check company data updates',
  'apps.stakeholder-report': 'Stakeholder Search',
  'apps.stakeholder-report.description': 'Find positions of stakeholders',
  'apps.ubo-reports': 'UBO Report',
  'apps.xseption-report': 'Network incl. XSeptions',
  'archive.backtoarchive': 'Back to archive',
  'archive.backToCreditcheck': 'Back to credit check',
  'archive.meta.title': 'Archived result',
  'archive.pdf.checked.model': 'Checked with model',
  'archive.pdf.note.title': 'Note',
  'archive.pdf.order.value': 'Order value',
  'archive.pdf.reference.title': 'Reference',
  'archive.pdf.result.checkpoint.be.annualAccountAvailable': 'Annual account available?',
  'archive.pdf.result.checkpoint.be.annualAccountAvailable.threshold.label': 'Threshold value',
  'archive.pdf.result.checkpoint.be.multiscore': 'Multiscore',
  'archive.pdf.result.checkpoint.be.multiscore.threshold.label': 'Threshold value',
  'archive.pdf.result.checkpoint.be.paymentsScore': 'Payment score',
  'archive.pdf.result.checkpoint.be.paymentsScore.threshold.label': 'Threshold value',
  'archive.pdf.result.checkpoint.companyActive': 'Active company',
  'archive.pdf.result.checkpoint.companyActive.threshold.label': 'Threshold value',
  'archive.pdf.result.checkpoint.creditLimit': 'Credit limit',
  'archive.pdf.result.checkpoint.creditLimit.threshold.label': 'Order value',
  'archive.pdf.result.checkpoint.gb.augurScore': 'Augur score',
  'archive.pdf.result.checkpoint.gb.augurScore.threshold.label': 'Threshold value',
  'archive.pdf.result.checkpoint.gb.ccjCount': 'CCJ count',
  'archive.pdf.result.checkpoint.gb.ccjCount.threshold.label': 'Threshold value',
  'archive.pdf.result.checkpoint.gb.companyAge': 'Company age',
  'archive.pdf.result.checkpoint.gb.companyAge.threshold.label': 'Threshold value',
  'archive.pdf.result.checkpoint.gb.companyIncorporated': 'Company incorporated?',
  'archive.pdf.result.checkpoint.gb.companyIncorporated.threshold.label': 'Threshold value',
  'archive.pdf.result.checkpoint.gb.creditRating': 'Credit rating',
  'archive.pdf.result.checkpoint.gb.creditRating.threshold.label': 'Threshold value',
  'archive.pdf.result.checkpoint.gb.orderValue': 'Order value',
  'archive.pdf.result.checkpoint.gb.orderValue.threshold.label': 'Threshold value',
  'archive.pdf.result.checkpoint.nl.paymentsScore': 'Payment score',
  'archive.pdf.result.checkpoint.nl.paymentsScore.threshold.label': 'Threshold value',
  'archive.pdf.result.checkpoint.nl.probabilityOfDefault': 'Probability of default',
  'archive.pdf.result.checkpoint.nl.probabilityOfDefault.threshold.label': 'Threshold value',
  'archive.pdf.result.checkpoint.payments': 'Payment behaviour',
  'archive.pdf.result.checkpoint.payments.threshold.label': 'Threshold value',
  'archive.search.placeholder': 'Please enter a valid registration number',
  'archive.title': 'Archive',
  back: 'Back',
  'back.to.dashboard': 'Back to dashboard',
  'banner.phaseout.internetexplorer.content':
    'For an optimal experience, please update your browser to Mozilla Firefox, Google Chrome or Microsoft Edge.',
  'banner.phaseout.internetexplorer.title':
    'You are using an out-of-date web browser: Internet Explorer',
  beforeAfterSelection: 'Before/after',
  'branding.app': '{app}',
  'branding.subtitle': 'For {company}',
  'breadcrumb.company-search': 'Company Data',
  'breadcrumb.create-profile': 'Create profile',
  'breadcrumb.credit-report': 'Credit Report',
  'breadcrumb.database-management': 'Database Management ',
  'breadcrumb.database-management.create-profile': 'Create profile',
  'breadcrumb.database-management.documentation': 'Documentation',
  'breadcrumb.database-management.update-profile': 'Edit profile',
  'breadcrumb.database-management.view-profile': 'View profile',
  'breadcrumb.decision-model': 'Decision Model Report',
  'breadcrumb.edit-profile': 'Edit profile',
  'breadcrumb.export-profile': 'Export',
  'breadcrumb.home': 'Home',
  'breadcrumb.international-credit-reports': 'International Business',
  'breadcrumb.international-credit-reports.archive': 'Archive',
  'breadcrumb.international-credit-reports.investigative-report': 'Order investigation',
  'breadcrumb.ledger-management': 'Ledger management',
  'breadcrumb.list-management': 'List management',
  'breadcrumb.list-profile': 'List profile',
  'breadcrumb.monitoring-updates': 'Monitoring updates',
  'breadcrumb.my-portfolio': 'My portfolios',
  'breadcrumb.organisation': 'Company Archive',
  'breadcrumb.preferences': 'Preferences',
  'breadcrumb.stakeholder-archive': 'Archive',
  'breadcrumb.stakeholder-report': 'Stakeholder Search',
  'breadcrumb.stakeholder-search': 'Stakeholder Search',
  'breadcrumb.ubo-report': 'UBO Report',
  'breadcrumb.unmatched-debtors': 'Unmatched debtors',
  'cd.modal.report-download-error.title': 'Something went wrong...',
  'chart.amount.orders': 'orders',
  'chart.average.order.value': 'Average order value',
  'coc.extracts.usage.download': 'Trade register extract usage (.xlsx)',
  'coc.modal.buy': 'Buy extract',
  'coc.modal.cached': 'Graydon archive',
  'coc.modal.date': 'Date',
  'coc.modal.download.error.message': 'Please try again later or contact Graydon.',
  'coc.modal.download.error.title': 'Request could not be sent',
  'coc.modal.download.not-found.message':
    'The extract cannot be retrieved. Please contact Graydon.',
  'coc.modal.download.not-found.title': 'Extract not found',
  'coc.modal.download.success.message':
    'The trade register extract that you requested is available from the download bar and from Company Archive.',
  'coc.modal.download.success.title': 'Extract has been downloaded',
  'coc.modal.explanation':
    'Request a new extract or request a (cheaper) cached version from the Graydon archive.',
  'coc.modal.fetch-cached.error.message':
    'The list of cached extracts could not be retrieved. Please try again later or contact Graydon.',
  'coc.modal.fetch-cached.error.title': 'The data could not be retrieved',
  'coc.modal.link': 'Link',
  'coc.modal.new': 'New',
  'coc.modal.title': 'Request trade register extract',
  'coc.modal.today': 'Today',
  'coc.modal.type': 'Type',
  'common.report-modal.open-in-new-tab': 'Always open in a new tab',
  companies: 'Companies',
  company: 'Company',
  'company.contact.details': 'Contact details',
  'company.email': 'Email address',
  'company.entireAddress': 'Place (city or street name etc.)',
  'company.graydon.id': 'Graydon ID',
  'company.incorporation.date': 'Incorporation date',
  'company.legal.form': 'Legal form',
  'company.legal.forms': 'Legal forms',
  'company.legal.forms.all': 'All legal forms',
  'company.mobile.number': 'Mobile number',
  'company.name': 'Company name',
  'company.phone.number': 'Telephone number',
  'company.register.number': 'Company registration No',
  'company.relationship.founder': 'Has as founder',
  'company.relationship.groupMember': 'Has as group member',
  'company.relationship.other': 'Has other relationship with',
  'company.relationship.parent': 'Parent company',
  'company.relationship.participation':
    'Has a {isMajorityStake, select, true{majority} other{}} participation in',
  'company.relationship.shareholder':
    'Has as {isMajorityStake, select, true{majority} other{}} shareholder',
  'company.relationship.ultimateParent': 'Ultimate parent company',
  'company.sectorCode': 'Sector code',
  'company.status': 'Company status',
  'company.status.BANKRUPTCY': 'Bankruptcy',
  'company.status.DEBT_RESTRUCTURING': 'Debt restructuring',
  'company.status.INACTIVE': 'Inactive',
  'company.status.MERGER': 'Merger',
  'company.status.OWNER_DECEASED': 'Owner deceased',
  'company.status.SPLIT_UP': 'Split-up',
  'company.telefax.number': 'Fax No',
  'company.trade-names': 'Trade names',
  'company.vat.number': 'VAT number',
  'company.website': 'Website',
  'contact.customerService':
    '<a href="mailto:{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}">{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}</a> or on {country, select, gb {+44&nbsp;(0)20&nbsp;8515&nbsp;1400} nl {+31&nbsp;(020)&nbsp;567&nbsp;9501} other {+32&nbsp;(03)&nbsp;280&nbsp;88&nbsp;80}}',
  'contacts.text':
    'If you have issues with the platform, if you have any questions or suggestions, or if you want to find out more about our products, please contact our dedicated customer care team.',
  'contacts.title': 'Any questions?',
  'cookie.accept': 'I accept the placements and use of cookies',
  'cookie.message':
    'Graydon uses cookies to ensure that we give you the best experience on our website and to analyse the use of this website.',
  'cookie.more-info': 'More information',
  'core.filterPlaceholder': 'Search',
  'core.tagging.add': 'Create new list',
  'core.tagging.placeholder': 'Give the list a name',
  'core.treeSelector.collapseAll': 'Collapse all',
  'core.treeSelector.deselectResults': 'Deselect results',
  'core.treeSelector.expandAll': 'Expand all',
  'core.treeSelector.expandSelected': 'Expand selected',
  'core.treeSelector.selectAll': 'Select all',
  'core.treeSelector.selectNone': 'Deselect all',
  'core.treeSelector.selectResults': 'Select results',
  'country.be': 'Belgium',
  'country.fr': 'France',
  'country.gb': 'United Kingdom',
  'country.nl': 'The Netherlands',
  'country.uk': 'United Kingdom',
  'cr.action.button.addToList': 'Add to list',
  'cr.action.button.addToMonitoring': 'Monitor',
  'cr.action.button.addToMonitoring.back': 'Back to Credit Report',
  'cr.action.button.download': 'Download report',
  'cr.action.button.download.en-pdf': 'English PDF',
  'cr.action.button.download.fr-pdf': 'French PDF',
  'cr.action.button.download.nl-pdf': 'Dutch PDF',
  'cr.action.button.request-new-report': 'Credit Report',
  'cr.action.button.share': 'Share full report',
  'cr.action.buttons.title': 'Share & Download',
  'cr.add.company.to.default.list.error.body':
    'There seems to be a unexpected error, please refresh or try again later.',
  'cr.add.company.to.default.list.error.title': 'Something went wrong',
  'cr.add.company.to.default.list.success.body':
    'We have added this company to "{listName}" list. ',
  'cr.add.company.to.default.list.success.title': 'Company added to a default list',
  'cr.affiliations.calamities': 'Insolvencies',
  'cr.affiliations.creditFlag.G': 'No objection',
  'cr.affiliations.creditFlag.NA': 'Not available',
  'cr.affiliations.creditFlag.O': 'Monitoring advised',
  'cr.affiliations.creditFlag.R': 'Not advised',
  'cr.affiliations.directors.active': 'Current Directors',
  'cr.affiliations.directors.active-calamity': 'Insolvency',
  'cr.affiliations.directors.address': 'Address',
  'cr.affiliations.directors.birthDate': 'Date of birth',
  'cr.affiliations.directors.birthPlace': 'Place of birth',
  'cr.affiliations.directors.calamities': 'Insolvency',
  'cr.affiliations.directors.calamity': 'Insolvency',
  'cr.affiliations.directors.collapse-calamity': 'Collapse insolvency',
  'cr.affiliations.directors.companyName': 'Company name',
  'cr.affiliations.directors.entity.COMPANY': 'Management company',
  'cr.affiliations.directors.entity.PERSON': 'Managerial person',
  'cr.affiliations.directors.error.no-data.text':
    'There is no information currently available about the directors of this company.<br /><br />If you have questions or need help, please contact our Customer Service team at <a href="mailto:{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}">{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}</a> or on {country, select, gb {+44 (0)20 8515 1400} nl {+31 (020) 567 9501} other {+32 (03) 280 88 80}}.',
  'cr.affiliations.directors.function': 'Job title',
  'cr.affiliations.directors.inactive': 'Previous directors',
  'cr.affiliations.directors.jurisdiction': 'Authorisation',
  'cr.affiliations.directors.name': 'Name',
  'cr.affiliations.directors.otherFunctions': 'Other appointment(s)',
  'cr.affiliations.directors.otherFunctions.collapse': 'Collapse other appointment(s)',
  'cr.affiliations.directors.period': 'Start and end date',
  'cr.affiliations.directors.presentDay': 'Present',
  'cr.affiliations.directors.registration-number-be': 'Business number',
  'cr.affiliations.directors.registration-number-gb': 'Company registration number ',
  'cr.affiliations.directors.registration-number-nl': 'Chamber of Commerce number (KvK)',
  'cr.affiliations.directors.registrationNumber': 'Registration number',
  'cr.affiliations.directors.representedBy': 'Permanent representative legal person',
  'cr.affiliations.directors.serviceAddress': 'Service Address',
  'cr.affiliations.directors.tooltip':
    "You will find here an overview of the business' managers with their links to other companies and their status.",
  'cr.affiliations.directors.tooltip.BE':
    'Here you will find an overview of the active managers, with their position, their term of appointment and possibly their mandates in other companies. You will also get an overview of inactive, former managers and the date of dismissal.\nThis information gives you insight into the management of a company, so that you know who is actually pulling the strings. Important, because there is an unmistakable link between the management and the performance of a company. Certainly in an SME country such as Belgium, where small to medium-sized companies usually only have 1 to 3 managers on their management boards. ',
  'cr.affiliations.directors.tooltip.GB':
    'Here, you will find an overview of the active and inactive directors, their associated authority and the details of any other directorships they hold.',
  'cr.affiliations.directors.tooltip.NL':
    'Here you will find an overview of the active and possibly inactive directors, associated authority and other functions of these directors.',
  'cr.affiliations.directors.unknown': 'Unknown',
  'cr.affiliations.gender.F': 'Mrs.',
  'cr.affiliations.gender.M': 'Mr.',
  'cr.affiliations.group-structure.address': 'Address',
  'cr.affiliations.group-structure.company-name': 'Company name',
  'cr.affiliations.group-structure.error.no-data.text':
    'There is currently no information available about the group structure of this company.<br /><br />If you have any questions or need help, please contact our Customer Service team at <a href="mailto:{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}">{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}</a> or on {country, select, gb {+44&nbsp;(0)20&nbsp;8515&nbsp;1400} nl {+31&nbsp;(020)&nbsp;567&nbsp;9501} other {+32&nbsp;(03)&nbsp;280&nbsp;88&nbsp;80}}.',
  'cr.affiliations.group-structure.percentage': 'Percentage shares',
  'cr.affiliations.group-structure.period': 'Start and end date',
  'cr.affiliations.group-structure.registration-number-be': 'Business number',
  'cr.affiliations.group-structure.registration-number-gb': 'Company registration number ',
  'cr.affiliations.group-structure.registration-number-nl': 'Chamber of Commerce number (KvK)',
  'cr.affiliations.group-structure.tooltip.BE':
    'Here you will find an overview of the active participating interests/subsidiaries, with the percentage of the shares that this company holds (as far as is known). You will also receive an overview of the subsidiaries in which this company has previously held an interest.',
  'cr.affiliations.group-structure.tooltip.GB':
    'Here, you will find an overview of the group company structure, if applicable.',
  'cr.affiliations.group-structure.tooltip.NL':
    'Here you will find an overview of the group in which the company is located.',
  'cr.affiliations.group-structure.type.DAUGHTER': 'Subsidiary',
  'cr.affiliations.group-structure.type.MOTHER': 'Parent company',
  'cr.affiliations.group-structure.type.PARTICIPANT': 'Share participation',
  'cr.affiliations.group-structure.type.SISTER': 'Sister company',
  'cr.affiliations.group-structure.type.ULTIMATE_MOTHER': 'Ultimate parent company',
  'cr.affiliations.indicator': 'Indicator',
  'cr.affiliations.indicators': 'Indicators',
  'cr.affiliations.no-insolvency': 'No insolvency case',
  'cr.affiliations.no.data.error.title':
    'This type of information is not available for this company',
  'cr.affiliations.others.account-number': 'Account number',
  'cr.affiliations.others.accountant': 'Accountants',
  'cr.affiliations.others.active-calamity': 'Insolvency',
  'cr.affiliations.others.address': 'Address',
  'cr.affiliations.others.bank': 'Banks',
  'cr.affiliations.others.bank-name': 'Name',
  'cr.affiliations.others.bankers': 'Banks',
  'cr.affiliations.others.collapse-calamity': 'Collapse insolvency',
  'cr.affiliations.others.company-name': 'Company name',
  'cr.affiliations.others.error.no-data.text':
    'No other parties are listed for this company or no data is currently available. <br /><br />If you have questions or need help, please contact our Customer Service team at <a href="mailto:{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}">{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}</a> or on {country, select, gb {+44&nbsp;(0)20&nbsp;8515&nbsp;1400} nl {+31&nbsp;(020)&nbsp;567&nbsp;9501} other {+32&nbsp;(03)&nbsp;280&nbsp;88&nbsp;80}}.',
  'cr.affiliations.others.liability': 'Liability',
  'cr.affiliations.others.liable-for': 'Liable for this company',
  'cr.affiliations.others.name': 'Name',
  'cr.affiliations.others.period': 'Start and end date',
  'cr.affiliations.others.registration-number-nl': 'Chamber of Commerce number (KvK)',
  'cr.affiliations.others.tooltip.BE':
    'Here you will find an overview of the bank account numbers that are known to us.',
  'cr.affiliations.others.tooltip.GB':
    'Here, you will find other information about the company structure.',
  'cr.affiliations.others.tooltip.NL':
    'Here you will find other information about the company structure of the company.',
  'cr.affiliations.psc.error.no-data.text':
    'This company does not have any persons of significant control or the information is currently not available.<br /><br />If you have any questions or need help, please contact our Customer Service team at <a href="mailto:{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}">{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}</a> or on {country, select, gb {+44&nbsp;(0)20&nbsp;8515&nbsp;1400} nl {+31&nbsp;(020)&nbsp;567&nbsp;9501} other {+32&nbsp;(03)&nbsp;280&nbsp;88&nbsp;80}}.',
  'cr.affiliations.psc.error.no-data.title': 'No person(s) of siginficant control',
  'cr.affiliations.psc.natureOfControl': 'Nature of control',
  'cr.affiliations.psc.protected': 'Protected person(s) with significant control',
  'cr.affiliations.psc.title': 'Active person(s) of significant control',
  'cr.affiliations.psc.tooltip.GB':
    "Here, you will find a list all active persons of significant control for this company. A person of significant control is someone that holds more than 25% of shares or voting rights in a company, has the right to appoint or remove the majority of the board of directors or otherwise exercises significant influence or control. Since 30 June 2016, UK companies (except listed companies) and limited liability partnerships (LLPs) need to declare this information when issuing their annual confirmation statement to Companies House. Persons of significant control can include corporate entities, legal persons and private persons. Please note, in case of an exemption or super secure person, details are not shown due to restrictions on using or disclosing any of the individual's particulars under section 790ZG of the regulation in relation to this company.",
  'cr.affiliations.shareholders.active': 'Current shareholders',
  'cr.affiliations.shareholders.active-calamity': 'Insolvency',
  'cr.affiliations.shareholders.address': 'Address',
  'cr.affiliations.shareholders.collapse-calamity': 'Collapse insolvency',
  'cr.affiliations.shareholders.company': 'Shareholder company',
  'cr.affiliations.shareholders.company-name': 'Company name',
  'cr.affiliations.shareholders.date-of-birth': 'Date of birth',
  'cr.affiliations.shareholders.error.no-data.text':
    'This company has a legal form without shareholders or there is presently no information available.<br /><br />If you have any questions or need help, please contact our Customer Service team at <a href="mailto:{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}">{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}</a> or on {country, select, gb {+44&nbsp;(0)20&nbsp;8515&nbsp;1400} nl {+31&nbsp;(020)&nbsp;567&nbsp;9501} other {+32&nbsp;(03)&nbsp;280&nbsp;88&nbsp;80}}.',
  'cr.affiliations.shareholders.error.no-data.title':
    'This type of information is not available for this company',
  'cr.affiliations.shareholders.inactive': 'Previous shareholders',
  'cr.affiliations.shareholders.percentage': 'Percentage of shares',
  'cr.affiliations.shareholders.period': 'Start & end date',
  'cr.affiliations.shareholders.period.present': 'Present',
  'cr.affiliations.shareholders.period.unknown': 'Unknown',
  'cr.affiliations.shareholders.person': 'Shareholder',
  'cr.affiliations.shareholders.person-name': 'Name',
  'cr.affiliations.shareholders.place-of-birth': 'Place of birth',
  'cr.affiliations.shareholders.registration-number-be': 'Business number',
  'cr.affiliations.shareholders.registration-number-gb': 'Company registration number ',
  'cr.affiliations.shareholders.registration-number-nl': 'Chamber of Commerce number (KvK)',
  'cr.affiliations.shareholders.share': '{share, plural, =1 {Share} other {Shares}}',
  'cr.affiliations.shareholders.shares': 'Shares',
  'cr.affiliations.shareholders.tooltip.BE':
    'Here you will find an overview of the active shareholders, with the percentage of the shares they hold (if known). You will also receive an overview of inactive, former shareholders.',
  'cr.affiliations.shareholders.tooltip.GB':
    'Here, you will find an overview of current and previous shareholders.',
  'cr.affiliations.shareholders.tooltip.NL':
    'Here you will find an overview of the active and possibly inactive shareholders.',
  'cr.affiliations.tab.directors': 'Directors',
  'cr.affiliations.tab.group-structure': 'Group structure',
  'cr.affiliations.tab.other': 'Other',
  'cr.affiliations.tab.psc': 'Person(s) of significant control',
  'cr.affiliations.tab.shareholders': 'Shareholders',
  'cr.annual-report.general-information.accountingPeriod': 'Accounting period',
  'cr.annual-report.general-information.accountingPeriodEndDate': 'Accounts date',
  'cr.annual-report.general-information.accountingStandard': 'Accounting standard',
  'cr.annual-report.general-information.annualMeetingDate': 'Annual general meeting date',
  'cr.annual-report.general-information.balancedFlag': 'Balanced annual statement',
  'cr.annual-report.general-information.balancedFlag.false': 'No',
  'cr.annual-report.general-information.balancedFlag.true': 'Yes',
  'cr.annual-report.general-information.consolidatedFlag': 'Type',
  'cr.annual-report.general-information.consolidatedFlag.false': 'Company financial statements',
  'cr.annual-report.general-information.consolidatedFlag.true': 'Consolidated',
  'cr.annual-report.general-information.description':
    'Overview of the annual reports listed by the financial year',
  'cr.annual-report.general-information.description.BE':
    'Here you will find information about the financial year to which the data from the financial statements relate.',
  'cr.annual-report.general-information.description.GB':
    'Here, you will find an overview of the annual reports, displayed by financial year.',
  'cr.annual-report.general-information.description.NL':
    'Here you will find information about the financial year to which the data from the financial statements relate.',
  'cr.annual-report.general-information.dormant': 'Dormant',
  'cr.annual-report.general-information.dormant.no': 'No',
  'cr.annual-report.general-information.dormant.yes': 'Yes',
  'cr.annual-report.general-information.filingDate': 'Filing date',
  'cr.annual-report.general-information.model.BE_1': 'Abbreviated model for companies (20)',
  'cr.annual-report.general-information.model.BE_1_19': 'Abbreviated model for companies (20)',
  'cr.annual-report.general-information.model.BE_1_20': 'Abbreviated model for companies (20)',
  'cr.annual-report.general-information.model.BE_10': 'Abbreviated model for companies (23)',
  'cr.annual-report.general-information.model.BE_10_20': 'Abbreviated model for companies (23)',
  'cr.annual-report.general-information.model.BE_11': 'Micro model for companies (73)',
  'cr.annual-report.general-information.model.BE_11_20': 'Micro model for companies (73)',
  'cr.annual-report.general-information.model.BE_12_21':
    'Micro model for micro associations and micro foundations (701)',
  'cr.annual-report.general-information.model.BE_2': 'Full model for companies (40)',
  'cr.annual-report.general-information.model.BE_2_19': 'Full model for companies (40)',
  'cr.annual-report.general-information.model.BE_2_20': 'Full model for companies (40)',
  'cr.annual-report.general-information.model.BE_4': 'Micro model for companies (70)',
  'cr.annual-report.general-information.model.BE_4_19': 'Micro model for companies (70)',
  'cr.annual-report.general-information.model.BE_4_20': 'Micro model for companies (70)',
  'cr.annual-report.general-information.model.BE_5': 'Abbreviated model for associations (201)',
  'cr.annual-report.general-information.model.BE_5_13': 'Abbreviated model for associations (201)',
  'cr.annual-report.general-information.model.BE_6': 'Full model for associations (401)',
  'cr.annual-report.general-information.model.BE_6_13': 'Full model for associations (401)',
  'cr.annual-report.general-information.model.BE_8': 'Specific model scheme B (Nature 10)',
  'cr.annual-report.general-information.model.BE_9': 'Full model for companies (43)',
  'cr.annual-report.general-information.model.BE_9_20': 'Full model for companies (43)',
  'cr.annual-report.general-information.modelType': 'Model type',
  'cr.annual-report.general-information.preliminaryFlag': 'Preliminary or definitive',
  'cr.annual-report.general-information.preliminaryFlag.false': 'Definitive',
  'cr.annual-report.general-information.preliminaryFlag.true': 'Preliminary',
  'cr.annual-report.general-information.title': 'Overview annual reports',
  'cr.annual.report.annual-accounts.collapse-all': 'Collapse all',
  'cr.annual.report.annual-accounts.expand-all': 'Expand all',
  'cr.annual.report.balance-sheet.BE_ACTIVA': 'ASSETS',
  'cr.annual.report.balance-sheet.BE_B_167':
    'Provisions for reimbursable subsidies and bequests and for donations with a right of return',
  'cr.annual.report.balance-sheet.BE_B_AST_20': 'INCORPORATION COSTS (20)',
  'cr.annual.report.balance-sheet.BE_B_AST_2028': 'FIXED ASSETS (2028)',
  'cr.annual.report.balance-sheet.BE_B_AST_2058': 'TOTAL ASSETS (2058)',
  'cr.annual.report.balance-sheet.BE_B_AST_2128': 'FIXED ASSETS (2128)',
  'cr.annual.report.balance-sheet.BE_B_AST_2958': 'CURRENT ASSETS (2958)',
  'cr.annual.report.balance-sheet.BE_B_AST_C_29': 'Receivables after over a year (29)',
  'cr.annual.report.balance-sheet.BE_B_AST_C_3': 'Stocks and orders in progress (3)',
  'cr.annual.report.balance-sheet.BE_B_AST_C_4041': 'Receivables within one year (4041)',
  'cr.annual.report.balance-sheet.BE_B_AST_C_4901': 'Accrued charges (4901)',
  'cr.annual.report.balance-sheet.BE_B_AST_C_5053': 'Cash investments (5053)',
  'cr.annual.report.balance-sheet.BE_B_AST_C_5458': 'Cash resources (5458)',
  'cr.annual.report.balance-sheet.BE_B_AST_CI_50': 'Own shares (50)',
  'cr.annual.report.balance-sheet.BE_B_AST_CI_5153': 'Other investments (5153)',
  'cr.annual.report.balance-sheet.BE_B_AST_COS_3031': 'Raw material and additives (3031)',
  'cr.annual.report.balance-sheet.BE_B_AST_COS_32': 'Production in progress (32)',
  'cr.annual.report.balance-sheet.BE_B_AST_COS_33': 'Finished product (33)',
  'cr.annual.report.balance-sheet.BE_B_AST_COS_34': 'Commercial goods (34)',
  'cr.annual.report.balance-sheet.BE_B_AST_COS_35': 'Property intended for sale (35)',
  'cr.annual.report.balance-sheet.BE_B_AST_COS_36': 'Advance payments (36)',
  'cr.annual.report.balance-sheet.BE_B_AST_CRL_290': 'Trading receivables (290)',
  'cr.annual.report.balance-sheet.BE_B_AST_CRL_291': 'Other receivables (291)',
  'cr.annual.report.balance-sheet.BE_B_AST_CRLO_2915':
    'Including non-interest-bearing receivables or linked to abnormally low interest rates (2915)',
  'cr.annual.report.balance-sheet.BE_B_AST_CRS_40': 'Trading receivables (40)',
  'cr.annual.report.balance-sheet.BE_B_AST_CRS_41': 'Other receivables (41)',
  'cr.annual.report.balance-sheet.BE_B_AST_CRSO_415':
    'Including non-interest-bearing receivables or linked to abnormally low interest rates (415)',
  'cr.annual.report.balance-sheet.BE_B_AST_CS_3036': 'Stocks (3036)',
  'cr.annual.report.balance-sheet.BE_B_AST_CS_37': 'Orders in progress (37)',
  'cr.annual.report.balance-sheet.BE_B_AST_F_21': 'Intangible fixed assets (21)',
  'cr.annual.report.balance-sheet.BE_B_AST_F_2227': 'Tangible fixed assets (2227)',
  'cr.annual.report.balance-sheet.BE_B_AST_F_28': 'Financial fixed assets (28)',
  'cr.annual.report.balance-sheet.BE_B_AST_FF_2801': 'Associated companies (2801)',
  'cr.annual.report.balance-sheet.BE_B_AST_FF_2823':
    'Companies with which there is a link of participation (2823)',
  'cr.annual.report.balance-sheet.BE_B_AST_FF_2848': 'Other financial fixed assets (2848)',
  'cr.annual.report.balance-sheet.BE_B_AST_FFC_280': 'Participating interests (280)',
  'cr.annual.report.balance-sheet.BE_B_AST_FFC_281': 'Receivables (281)',
  'cr.annual.report.balance-sheet.BE_B_AST_FFO_284': 'Shares (284)',
  'cr.annual.report.balance-sheet.BE_B_AST_FFO_2858': 'Receivables and guarantees in cash (2858) ',
  'cr.annual.report.balance-sheet.BE_B_AST_FFP_282': 'Participating interests (282)',
  'cr.annual.report.balance-sheet.BE_B_AST_FFP_283': 'Receivables (283)',
  'cr.annual.report.balance-sheet.BE_B_AST_FT_22': 'Land and buildings (22)',
  'cr.annual.report.balance-sheet.BE_B_AST_FT_23': 'Installations, machines and equipment (23) ',
  'cr.annual.report.balance-sheet.BE_B_AST_FT_24': 'Furniture, fixture and rolling stock (24) ',
  'cr.annual.report.balance-sheet.BE_B_AST_FT_25': 'Leasing and similar rights (25)',
  'cr.annual.report.balance-sheet.BE_B_AST_FT_26': 'Other tangible fixed assets (26) ',
  'cr.annual.report.balance-sheet.BE_B_AST_FT_27':
    'Assets under construction and advance payments (27)',
  'cr.annual.report.balance-sheet.BE_B_AST_FTI_231':
    'In full ownership of the association or foundation (231)',
  'cr.annual.report.balance-sheet.BE_B_AST_FTI_232': 'Others (232)',
  'cr.annual.report.balance-sheet.BE_B_AST_FTI_241':
    'In full ownership of the association or foundation (241)',
  'cr.annual.report.balance-sheet.BE_B_AST_FTI_242': 'Other (242)',
  'cr.annual.report.balance-sheet.BE_B_AST_FTO_261':
    'In full ownership of the association or foundation (261)',
  'cr.annual.report.balance-sheet.BE_B_AST_FTO_262': 'Others (262)',
  'cr.annual.report.balance-sheet.BE_B_AST_FTT_2291':
    'In full ownership of the association or foundation (2291)',
  'cr.annual.report.balance-sheet.BE_B_AST_FTT_2292': 'Others (2292)',
  'cr.annual.report.balance-sheet.BE_B_LBL_1011': 'Contributions (1011)',
  'cr.annual.report.balance-sheet.BE_B_LBL_1015': 'EQUITY (1015) ',
  'cr.annual.report.balance-sheet.BE_B_LBL_1049': 'TOTAL LIABILITIES (1049)',
  'cr.annual.report.balance-sheet.BE_B_LBL_110': 'Available (110)',
  'cr.annual.report.balance-sheet.BE_B_LBL_111': 'Not available (111)',
  'cr.annual.report.balance-sheet.BE_B_LBL_16': 'PROVISIONS AND DEFERRED TAXES (16) ',
  'cr.annual.report.balance-sheet.BE_B_LBL_1749': 'DEBTS (1749) ',
  'cr.annual.report.balance-sheet.BE_B_LBL_D_17': 'Debts after over a year (17)',
  'cr.annual.report.balance-sheet.BE_B_LBL_D_1789': 'Other debts (1789)',
  'cr.annual.report.balance-sheet.BE_B_LBL_D_4248': 'Debts payable within one year (4248) ',
  'cr.annual.report.balance-sheet.BE_B_LBL_D_4923': 'Accrued charges (4923)',
  'cr.annual.report.balance-sheet.BE_B_LBL_DL_1704': 'Financial debts (1704)',
  'cr.annual.report.balance-sheet.BE_B_LBL_DL_175': 'Trading debts (175)',
  'cr.annual.report.balance-sheet.BE_B_LBL_DL_176': 'Advances received on orders (176)',
  'cr.annual.report.balance-sheet.BE_B_LBL_DL_179': 'Other debts (179)',
  'cr.annual.report.balance-sheet.BE_B_LBL_DLF_170': 'Deferred loans (170)',
  'cr.annual.report.balance-sheet.BE_B_LBL_DLF_171': 'Non-deferred bond loans (171)',
  'cr.annual.report.balance-sheet.BE_B_LBL_DLF_172': 'Leasing debts and similar debts (172)',
  'cr.annual.report.balance-sheet.BE_B_LBL_DLF_1723':
    'Credit institutions, leasing debts and similar debts (1723)',
  'cr.annual.report.balance-sheet.BE_B_LBL_DLF_173': 'Credit institutions (173)',
  'cr.annual.report.balance-sheet.BE_B_LBL_DLF_174': 'Other loans (174)',
  'cr.annual.report.balance-sheet.BE_B_LBL_DLF_1740': 'Other loans (1740)',
  'cr.annual.report.balance-sheet.BE_B_LBL_DLO_1790': 'Interest-bearing (1790)',
  'cr.annual.report.balance-sheet.BE_B_LBL_DLO_1791':
    'Non-interest bearing or linked to abnormally low interest rates (1791)',
  'cr.annual.report.balance-sheet.BE_B_LBL_DLO_1792': 'Guarantees received in cash (1792)',
  'cr.annual.report.balance-sheet.BE_B_LBL_DLTR_1750': 'Suppliers (1750)',
  'cr.annual.report.balance-sheet.BE_B_LBL_DLTR_1751': 'Bills due (1751)',
  'cr.annual.report.balance-sheet.BE_B_LBL_DS_42':
    'Debts of over a year that fall due within the year (42)',
  'cr.annual.report.balance-sheet.BE_B_LBL_DS_43': 'Financial debts (43) ',
  'cr.annual.report.balance-sheet.BE_B_LBL_DS_44': 'Trading debts (44)',
  'cr.annual.report.balance-sheet.BE_B_LBL_DS_45':
    'Debts relating to taxes, wages and social charges (45)',
  'cr.annual.report.balance-sheet.BE_B_LBL_DS_46': 'Advances received on orders (46)',
  'cr.annual.report.balance-sheet.BE_B_LBL_DS_4748': 'Other debts (4748)',
  'cr.annual.report.balance-sheet.BE_B_LBL_DS_48': 'Various debts (48)',
  'cr.annual.report.balance-sheet.BE_B_LBL_DSF_4308': 'Credit institutions (4308)',
  'cr.annual.report.balance-sheet.BE_B_LBL_DSF_439': 'Other loans (439)',
  'cr.annual.report.balance-sheet.BE_B_LBL_DST_4503': 'Taxes (4503)',
  'cr.annual.report.balance-sheet.BE_B_LBL_DST_4549': 'Wages and social charges (4549)',
  'cr.annual.report.balance-sheet.BE_B_LBL_DSTR_4404': 'Suppliers (4404)',
  'cr.annual.report.balance-sheet.BE_B_LBL_DSTR_441': 'Bills due (441)',
  'cr.annual.report.balance-sheet.BE_B_LBL_DSV_4808':
    'Bonds, debt securities and coupon bonds expire, repayable subsidies and guarantees received in cash (4808)',
  'cr.annual.report.balance-sheet.BE_B_LBL_DSV_4890': 'Other interest-bearing debts (4890)',
  'cr.annual.report.balance-sheet.BE_B_LBL_DSV_4891':
    'Other payables, non-interest bearing or linked to abnormally low interest rates (4891)',
  'cr.annual.report.balance-sheet.BE_B_LBL_E_10': 'Capital (10) ',
  'cr.annual.report.balance-sheet.BE_B_LBL_E_11': 'Premium issued (11)',
  'cr.annual.report.balance-sheet.BE_B_LBL_E_12': 'Revaluation gains (12)',
  'cr.annual.report.balance-sheet.BE_B_LBL_E_13': 'Reserves (13) ',
  'cr.annual.report.balance-sheet.BE_B_LBL_E_14': 'Profit (loss) carried forward (14)',
  'cr.annual.report.balance-sheet.BE_B_LBL_E_15': 'Investment grants (15)',
  'cr.annual.report.balance-sheet.BE_B_LBL_E_19':
    'Advance to the partners on the distribution of net assets (19)',
  'cr.annual.report.balance-sheet.BE_B_LBL_EC_100': 'Subscribed capital (100) ',
  'cr.annual.report.balance-sheet.BE_B_LBL_EC_101': 'Uncalled capital \r\n (101)',
  'cr.annual.report.balance-sheet.BE_B_LBL_ER_130': 'Legal reserve (130)',
  'cr.annual.report.balance-sheet.BE_B_LBL_ER_1301': 'Reserves not available (1301)',
  'cr.annual.report.balance-sheet.BE_B_LBL_ER_131': 'Unavailable reserves (131)',
  'cr.annual.report.balance-sheet.BE_B_LBL_ER_132': 'Tax-free reserves (132) ',
  'cr.annual.report.balance-sheet.BE_B_LBL_ER_133': 'Available reserves (133) ',
  'cr.annual.report.balance-sheet.BE_B_LBL_ERU_1310': 'For own shares (1310)',
  'cr.annual.report.balance-sheet.BE_B_LBL_ERU_1311': 'Other (1311)',
  'cr.annual.report.balance-sheet.BE_B_LBL_ERU_1312': 'Purchase of own shares (1312)',
  'cr.annual.report.balance-sheet.BE_B_LBL_ERU_1313': 'Financial support (1313)',
  'cr.annual.report.balance-sheet.BE_B_LBL_ERU_1319': 'Other (1319)',
  'cr.annual.report.balance-sheet.BE_B_LBL_P_1605': 'Provisions for risks and costs (1605) ',
  'cr.annual.report.balance-sheet.BE_B_LBL_P_168':
    'Provisions for reimbursable subsidies, bequests and donations (168)',
  'cr.annual.report.balance-sheet.BE_B_LBL_PR_160': 'Pensions and similar obligations (160)',
  'cr.annual.report.balance-sheet.BE_B_LBL_PR_161': 'Fiscal charges (161)',
  'cr.annual.report.balance-sheet.BE_B_LBL_PR_162': 'Major repair and maintenance works (162)',
  'cr.annual.report.balance-sheet.BE_B_LBL_PR_163': 'Environmental obligations (163)',
  'cr.annual.report.balance-sheet.BE_B_LBL_PR_1635': 'Other risks and costs (1635)',
  'cr.annual.report.balance-sheet.BE_B_LBL_PR_1645': 'Other risks and costs (1645)',
  'cr.annual.report.balance-sheet.BE_PASSIVA': 'LIABILITIES',
  'cr.annual.report.balance-sheet.current-assets': 'Current assets',
  'cr.annual.report.balance-sheet.current-liabilites': 'Current liabilites',
  'cr.annual.report.balance-sheet.fixed-assets': 'Fixed Assets',
  'cr.annual.report.balance-sheet.GB_AST_00_00_001': 'Working capital',
  'cr.annual.report.balance-sheet.GB_AST_00_00_002': 'Employed capital',
  'cr.annual.report.balance-sheet.GB_AST_00_00_003': 'Total net assets',
  'cr.annual.report.balance-sheet.GB_AST_00_00_004': 'Net assets',
  'cr.annual.report.balance-sheet.GB_AST_00_00_005': 'Net worth',
  'cr.annual.report.balance-sheet.GB_AST_01': 'Total fixed assets',
  'cr.annual.report.balance-sheet.GB_AST_02': 'Total intangible assets',
  'cr.annual.report.balance-sheet.GB_AST_03': 'Total tangible assets',
  'cr.annual.report.balance-sheet.GB_AST_03_01': 'Land and Building',
  'cr.annual.report.balance-sheet.GB_AST_03_01_01': 'Freehold',
  'cr.annual.report.balance-sheet.GB_AST_03_01_02': 'Leasehold',
  'cr.annual.report.balance-sheet.GB_AST_03_02': 'Fixtures and Fittings',
  'cr.annual.report.balance-sheet.GB_AST_03_03': 'Plant and machinery',
  'cr.annual.report.balance-sheet.GB_AST_03_04': 'Assets under construction',
  'cr.annual.report.balance-sheet.GB_AST_03_05': 'Other tangible assets',
  'cr.annual.report.balance-sheet.GB_AST_04': 'Total financial assets',
  'cr.annual.report.balance-sheet.GB_AST_04_01': 'Finance leases / Hire purchase',
  'cr.annual.report.balance-sheet.GB_AST_04_02': 'Group loans',
  'cr.annual.report.balance-sheet.GB_AST_04_03': "Directors' loans",
  'cr.annual.report.balance-sheet.GB_AST_04_04': 'Other loans',
  'cr.annual.report.balance-sheet.GB_AST_04_05': 'Other financial assets',
  'cr.annual.report.balance-sheet.GB_AST_05': 'Other fixed assets / Non current',
  'cr.annual.report.balance-sheet.GB_AST_05_01': 'Long-term trade',
  'cr.annual.report.balance-sheet.GB_AST_05_02': 'Affiliate / Associate / Subsidiary',
  'cr.annual.report.balance-sheet.GB_AST_05_03': 'Assets held for sale',
  'cr.annual.report.balance-sheet.GB_AST_05_04': 'Other',
  'cr.annual.report.balance-sheet.GB_AST_06': 'Total current assets',
  'cr.annual.report.balance-sheet.GB_AST_07': 'Total inventories',
  'cr.annual.report.balance-sheet.GB_AST_07_01': 'Stock / Raw material',
  'cr.annual.report.balance-sheet.GB_AST_07_02': 'Work in progress',
  'cr.annual.report.balance-sheet.GB_AST_07_03': 'Finished goods',
  'cr.annual.report.balance-sheet.GB_AST_08': 'Total financial assets',
  'cr.annual.report.balance-sheet.GB_AST_08_01': 'Finance leases / Hire purchase',
  'cr.annual.report.balance-sheet.GB_AST_08_02': 'Group loans',
  'cr.annual.report.balance-sheet.GB_AST_08_03': "Directors' loans",
  'cr.annual.report.balance-sheet.GB_AST_08_04': 'Other loans',
  'cr.annual.report.balance-sheet.GB_AST_08_05': 'Other financial assets',
  'cr.annual.report.balance-sheet.GB_AST_10': 'Total cash',
  'cr.annual.report.balance-sheet.GB_AST_10_01': 'Cash in bank / In-hand',
  'cr.annual.report.balance-sheet.GB_AST_10_02': 'Cash equivalents',
  'cr.annual.report.balance-sheet.GB_AST_11': 'Total debtors',
  'cr.annual.report.balance-sheet.GB_AST_11_01': 'Accounts receivable / Trade debtors',
  'cr.annual.report.balance-sheet.GB_AST_11_02': 'Affiliate / Associate / Subsidiary',
  'cr.annual.report.balance-sheet.GB_AST_11_03': 'Group loans',
  'cr.annual.report.balance-sheet.GB_AST_11_04': "Directors' loans",
  'cr.annual.report.balance-sheet.GB_AST_11_05': 'Prepayment / Accrued charges',
  'cr.annual.report.balance-sheet.GB_AST_11_06': 'Other debtors',
  'cr.annual.report.balance-sheet.GB_AST_13': 'Other current assets',
  'cr.annual.report.balance-sheet.GB_AST_13_01': 'Assets held for sale',
  'cr.annual.report.balance-sheet.GB_AST_13_02': 'Other',
  'cr.annual.report.balance-sheet.GB_AST_14': 'Total investment assets',
  'cr.annual.report.balance-sheet.GB_AST_14_01': 'Property',
  'cr.annual.report.balance-sheet.GB_AST_14_02': 'Affiliate / Associate / Subsidiary',
  'cr.annual.report.balance-sheet.GB_AST_14_03': 'Other investments',
  'cr.annual.report.balance-sheet.GB_AST_15': 'Total assets',
  'cr.annual.report.balance-sheet.GB_LBL_01': "Total shareholders' funds",
  'cr.annual.report.balance-sheet.GB_LBL_01_01': 'Issued capital',
  'cr.annual.report.balance-sheet.GB_LBL_01_01_01': 'Ordinary',
  'cr.annual.report.balance-sheet.GB_LBL_01_01_02': 'Preferred',
  'cr.annual.report.balance-sheet.GB_LBL_01_01_03': 'Other',
  'cr.annual.report.balance-sheet.GB_LBL_01_02': 'Premium share accounts',
  'cr.annual.report.balance-sheet.GB_LBL_01_03': 'Interest in own shares',
  'cr.annual.report.balance-sheet.GB_LBL_01_04': 'Retained earnings',
  'cr.annual.report.balance-sheet.GB_LBL_01_05': 'Revaluation reserve',
  'cr.annual.report.balance-sheet.GB_LBL_01_06': 'Currency conversion reserve',
  'cr.annual.report.balance-sheet.GB_LBL_01_07': 'Other reserves',
  'cr.annual.report.balance-sheet.GB_LBL_01.NET-ASSET': 'Net assets',
  'cr.annual.report.balance-sheet.GB_LBL_12': 'Total provisions',
  'cr.annual.report.balance-sheet.GB_LBL_12_01': 'Deferred taxation',
  'cr.annual.report.balance-sheet.GB_LBL_12_02': 'Pension',
  'cr.annual.report.balance-sheet.GB_LBL_12_03': 'Other provision',
  'cr.annual.report.balance-sheet.GB_LBL_13': 'Total long-term liabilities',
  'cr.annual.report.balance-sheet.GB_LBL_13_01': 'Total financial liabilities',
  'cr.annual.report.balance-sheet.GB_LBL_13_01_01': 'Finance leases / Hire purchase',
  'cr.annual.report.balance-sheet.GB_LBL_13_01_01_01': 'Finance leases',
  'cr.annual.report.balance-sheet.GB_LBL_13_01_01_02': 'Hire purchase',
  'cr.annual.report.balance-sheet.GB_LBL_13_01_02': 'Group loans',
  'cr.annual.report.balance-sheet.GB_LBL_13_01_03': "Directors' loans",
  'cr.annual.report.balance-sheet.GB_LBL_13_01_04': 'Subsidies',
  'cr.annual.report.balance-sheet.GB_LBL_13_01_05': 'Other long-term loans',
  'cr.annual.report.balance-sheet.GB_LBL_13_01_06': 'Other financial liabilities',
  'cr.annual.report.balance-sheet.GB_LBL_13_02': 'Other long-term liabilities',
  'cr.annual.report.balance-sheet.GB_LBL_13_02_01': 'Long-term trade',
  'cr.annual.report.balance-sheet.GB_LBL_13_02_02': 'Affiliate / Associate / Subsidiary',
  'cr.annual.report.balance-sheet.GB_LBL_13_02_03': 'Group loans',
  'cr.annual.report.balance-sheet.GB_LBL_13_02_04': "Directors' loans",
  'cr.annual.report.balance-sheet.GB_LBL_13_02_05': 'Liabilities Held For sale',
  'cr.annual.report.balance-sheet.GB_LBL_13_02_06': 'Accrued charges & Deferred Income',
  'cr.annual.report.balance-sheet.GB_LBL_13_02_07': 'Preferred stock',
  'cr.annual.report.balance-sheet.GB_LBL_13_02_08': 'Other',
  'cr.annual.report.balance-sheet.GB_LBL_15': 'Total current liabilities',
  'cr.annual.report.balance-sheet.GB_LBL_15_01': 'Total creditors',
  'cr.annual.report.balance-sheet.GB_LBL_15_01_01': 'Accounts payable / Trade creditors',
  'cr.annual.report.balance-sheet.GB_LBL_15_01_02': 'Affiliate / Associate / Subsidiary',
  'cr.annual.report.balance-sheet.GB_LBL_15_01_03': 'Group loans',
  'cr.annual.report.balance-sheet.GB_LBL_15_01_04': "Directors' loans",
  'cr.annual.report.balance-sheet.GB_LBL_15_01_05': 'Accrued charges & Deferred Income',
  'cr.annual.report.balance-sheet.GB_LBL_15_01_06': 'Social security / VAT',
  'cr.annual.report.balance-sheet.GB_LBL_15_01_07': 'Taxation payable',
  'cr.annual.report.balance-sheet.GB_LBL_15_01_08': 'Other creditors',
  'cr.annual.report.balance-sheet.GB_LBL_15_02': 'Total financial liabilities',
  'cr.annual.report.balance-sheet.GB_LBL_15_02_01': 'Bank overdraft',
  'cr.annual.report.balance-sheet.GB_LBL_15_02_02': 'Finance leases / Hire purchase',
  'cr.annual.report.balance-sheet.GB_LBL_15_02_02_01': 'Finance leases / Hire purchase',
  'cr.annual.report.balance-sheet.GB_LBL_15_02_02_02': 'Hire purchase',
  'cr.annual.report.balance-sheet.GB_LBL_15_02_03': 'Group loans',
  'cr.annual.report.balance-sheet.GB_LBL_15_02_04': "Directors' loans",
  'cr.annual.report.balance-sheet.GB_LBL_15_02_05': 'Subsidies',
  'cr.annual.report.balance-sheet.GB_LBL_15_02_06': 'Other short-term loans',
  'cr.annual.report.balance-sheet.GB_LBL_15_02_07': 'Other financial liabilities',
  'cr.annual.report.balance-sheet.GB_LBL_15_03': 'Other current liabilities',
  'cr.annual.report.balance-sheet.GB_LBL_15_03_01': 'Liabilities held for sale',
  'cr.annual.report.balance-sheet.GB_LBL_15_03_02': 'Other',
  'cr.annual.report.balance-sheet.GB_LBL_15_04': 'Dividends',
  'cr.annual.report.balance-sheet.GB_LBL_20': 'Minority interests',
  'cr.annual.report.balance-sheet.long-term-liabilities': 'Long term liabilities',
  'cr.annual.report.balance-sheet.NL_ACC_01': 'Balance sheet total',
  'cr.annual.report.balance-sheet.NL_AST_10_00_001': 'Total fixed assets',
  'cr.annual.report.balance-sheet.NL_AST_10_00_002': 'Other fixed assets',
  'cr.annual.report.balance-sheet.NL_AST_10_10_001': 'Intangible fixed assets',
  'cr.annual.report.balance-sheet.NL_AST_10_10_002': 'Costs of incorporation and issuing shares',
  'cr.annual.report.balance-sheet.NL_AST_10_10_003': 'Costs of research and development',
  'cr.annual.report.balance-sheet.NL_AST_10_10_004':
    'Acquisition costs, concessions, permits and intellectual property rights',
  'cr.annual.report.balance-sheet.NL_AST_10_10_005': 'Goodwill',
  'cr.annual.report.balance-sheet.NL_AST_10_10_006': 'Prepaid on intangible fixed assets',
  'cr.annual.report.balance-sheet.NL_AST_10_20_001': 'Tangible fixed assets',
  'cr.annual.report.balance-sheet.NL_AST_10_20_002': 'Buildings and land',
  'cr.annual.report.balance-sheet.NL_AST_10_20_003': 'Plant and machinery',
  'cr.annual.report.balance-sheet.NL_AST_10_20_004': 'Other fixed operating assets',
  'cr.annual.report.balance-sheet.NL_AST_10_20_005':
    'Fixed operating assets in progress and prepaid on tangible fixed assets',
  'cr.annual.report.balance-sheet.NL_AST_10_20_006': 'Not instrumental in operations',
  'cr.annual.report.balance-sheet.NL_AST_10_30_001': 'Financial fixed assets',
  'cr.annual.report.balance-sheet.NL_AST_10_30_002': 'Investment in group companies',
  'cr.annual.report.balance-sheet.NL_AST_10_30_003': 'Receivables from group companies',
  'cr.annual.report.balance-sheet.NL_AST_10_30_004': 'Other participations',
  'cr.annual.report.balance-sheet.NL_AST_10_30_005':
    'Receivables from participants and participating interests',
  'cr.annual.report.balance-sheet.NL_AST_10_30_006': 'Other investments',
  'cr.annual.report.balance-sheet.NL_AST_20_00_001': 'Total current assets',
  'cr.annual.report.balance-sheet.NL_AST_20_00_002': 'Other current assets',
  'cr.annual.report.balance-sheet.NL_AST_20_10_001': 'Stock',
  'cr.annual.report.balance-sheet.NL_AST_20_10_002': 'Raw material and consumables',
  'cr.annual.report.balance-sheet.NL_AST_20_10_003': 'Work in progress',
  'cr.annual.report.balance-sheet.NL_AST_20_10_004': 'Finished product and trading goods',
  'cr.annual.report.balance-sheet.NL_AST_20_10_005': 'Prepaid on stock',
  'cr.annual.report.balance-sheet.NL_AST_20_30_001': 'Receivables',
  'cr.annual.report.balance-sheet.NL_AST_20_30_002': 'Trade debtors',
  'cr.annual.report.balance-sheet.NL_AST_20_30_003': 'Group companies',
  'cr.annual.report.balance-sheet.NL_AST_20_30_004':
    'Participants and other participating interests',
  'cr.annual.report.balance-sheet.NL_AST_20_30_005': 'Other receivables',
  'cr.annual.report.balance-sheet.NL_AST_20_30_006': 'Called up share capital not yet paid in',
  'cr.annual.report.balance-sheet.NL_AST_20_30_007': 'Accrued assets',
  'cr.annual.report.balance-sheet.NL_AST_20_30_008': 'Receivables and accrued assets',
  'cr.annual.report.balance-sheet.NL_AST_20_40_001': 'Securities',
  'cr.annual.report.balance-sheet.NL_AST_20_40_002': 'Companies affiliated with the group',
  'cr.annual.report.balance-sheet.NL_AST_20_40_003': 'Other securities',
  'cr.annual.report.balance-sheet.NL_AST_20_50_001': 'Cash',
  'cr.annual.report.balance-sheet.NL_AST_20_50_002': 'Other cash resources',
  'cr.annual.report.balance-sheet.NL_LBL_30_00_000': 'Total liabilities',
  'cr.annual.report.balance-sheet.NL_LBL_30_10_001': 'Equity capital',
  'cr.annual.report.balance-sheet.NL_LBL_30_10_002': 'Paid and called-up capital',
  'cr.annual.report.balance-sheet.NL_LBL_30_10_003': 'Premium',
  'cr.annual.report.balance-sheet.NL_LBL_30_10_004': 'Revaluation reserves',
  'cr.annual.report.balance-sheet.NL_LBL_30_10_005': 'Legal reserves',
  'cr.annual.report.balance-sheet.NL_LBL_30_10_006': 'Statutory reserves',
  'cr.annual.report.balance-sheet.NL_LBL_30_10_007': 'Legal statutory reserves',
  'cr.annual.report.balance-sheet.NL_LBL_30_10_008': 'Other reserves',
  'cr.annual.report.balance-sheet.NL_LBL_30_10_009': 'Undistributed profit',
  'cr.annual.report.balance-sheet.NL_LBL_30_10_010': 'Result after tax',
  'cr.annual.report.balance-sheet.NL_LBL_30_10_012': 'Loss balance',
  'cr.annual.report.balance-sheet.NL_LBL_30_20_001': 'Third-party shares',
  'cr.annual.report.balance-sheet.NL_LBL_30_20_002': 'Other third-party shares',
  'cr.annual.report.balance-sheet.NL_LBL_30_25_001': 'Equalisation account',
  'cr.annual.report.balance-sheet.NL_LBL_30_25_002': 'Other equalisation account',
  'cr.annual.report.balance-sheet.NL_LBL_30_30_001': 'Provisions',
  'cr.annual.report.balance-sheet.NL_LBL_30_30_002': 'For pensions',
  'cr.annual.report.balance-sheet.NL_LBL_30_30_003': 'For tax',
  'cr.annual.report.balance-sheet.NL_LBL_30_30_004': 'For restructuring/reorganisation',
  'cr.annual.report.balance-sheet.NL_LBL_30_30_005': 'For debtors',
  'cr.annual.report.balance-sheet.NL_LBL_30_30_006': 'For deferred tax liabilities',
  'cr.annual.report.balance-sheet.NL_LBL_30_30_007': 'Other',
  'cr.annual.report.balance-sheet.NL_LBL_30_40_001': 'Long-term debts',
  'cr.annual.report.balance-sheet.NL_LBL_30_40_002': 'Convertible loans',
  'cr.annual.report.balance-sheet.NL_LBL_30_40_003': 'Other bonds and private loans',
  'cr.annual.report.balance-sheet.NL_LBL_30_40_004': 'Debts to credit institutions',
  'cr.annual.report.balance-sheet.NL_LBL_30_40_005': 'Advances on orders',
  'cr.annual.report.balance-sheet.NL_LBL_30_40_006': 'Debts to suppliers and trade credits',
  'cr.annual.report.balance-sheet.NL_LBL_30_40_007': 'Bills of exchange and cheques to pay',
  'cr.annual.report.balance-sheet.NL_LBL_30_40_008': 'Debts to group companies',
  'cr.annual.report.balance-sheet.NL_LBL_30_40_009': 'Debts to participants and associates',
  'cr.annual.report.balance-sheet.NL_LBL_30_40_010': 'Tax and social insurance premiums',
  'cr.annual.report.balance-sheet.NL_LBL_30_40_011': 'Pension-related debts',
  'cr.annual.report.balance-sheet.NL_LBL_30_40_012': 'Other debts',
  'cr.annual.report.balance-sheet.NL_LBL_30_40_013': 'Accrued liabilities',
  'cr.annual.report.balance-sheet.NL_LBL_30_50_001': 'Short-term debts',
  'cr.annual.report.balance-sheet.NL_LBL_30_50_002': 'Convertible bonds and other loans',
  'cr.annual.report.balance-sheet.NL_LBL_30_50_003': 'Other bonds and private loans',
  'cr.annual.report.balance-sheet.NL_LBL_30_50_004': 'Debts to credit institutions',
  'cr.annual.report.balance-sheet.NL_LBL_30_50_005': 'Advances on orders',
  'cr.annual.report.balance-sheet.NL_LBL_30_50_006': 'Debts to suppliers and trade credits',
  'cr.annual.report.balance-sheet.NL_LBL_30_50_007': 'Bills of exchange and cheques to pay',
  'cr.annual.report.balance-sheet.NL_LBL_30_50_008': 'Debts to group companies',
  'cr.annual.report.balance-sheet.NL_LBL_30_50_009': 'Debts to participants and associates',
  'cr.annual.report.balance-sheet.NL_LBL_30_50_010': 'Tax and social insurance premiums',
  'cr.annual.report.balance-sheet.NL_LBL_30_50_011': 'Pension-related debts',
  'cr.annual.report.balance-sheet.NL_LBL_30_50_012': 'Other debts',
  'cr.annual.report.balance-sheet.NL_LBL_30_50_013': 'Accrued liabilities',
  'cr.annual.report.balance-sheet.NL_LBL_30_50_014': 'Short-term debts and accrued liabilities',
  'cr.annual.report.balance-sheet.passiva': 'Liabilities',
  'cr.annual.report.balance-sheet.provisions': 'Provisions',
  'cr.annual.report.balance-sheet.shareholders-funds': "Shareholders' funds",
  'cr.annual.report.balance-sheet.sub-header': 'Base currency and unit',
  'cr.annual.report.balance-sheet.tooltip.BE':
    'Here you will find the balance sheets of the last 5 financial years with all the details. Click on the blue triangles to view and close underlying items. The balance sheet is a snapshot of the assets and liabilities on the last day of the financial year.',
  'cr.annual.report.balance-sheet.tooltip.GB':
    'You can find the balance here. The balance sheet is a snapshot of the assets and liabilities on the last day of the financial year.',
  'cr.annual.report.balance-sheet.tooltip.NL':
    'You can find the balance here. The balance sheet is a snapshot of the assets and liabilities on the last day of the financial year.',
  'cr.annual.report.balance-sheet.vaste-activa': 'Fixed Assets',
  'cr.annual.report.balance-sheet.vlottende-activa': 'Current assets',
  'cr.annual.report.base-currency': 'Base currency and unit',
  'cr.annual.report.download-excel': 'Download Annual Accounts',
  'cr.annual.report.explanatory-notes-BE_1.BE_T_635':
    'Provision for pensions and similar obligations (635)',
  'cr.annual.report.explanatory-notes-BE_1.BE_T_6358':
    'Provisions for risks and charges: additions (6358)',
  'cr.annual.report.explanatory-notes-BE_1.BE_T_6408': 'Other operating charges (6408)',
  'cr.annual.report.explanatory-notes-BE_1.BE_T_9072':
    'Debts fallen due towards tax administration (9072)',
  'cr.annual.report.explanatory-notes-BE_1.BE_T_9076':
    'Debts fallen due towards National Social Security Office (9076)',
  'cr.annual.report.explanatory-notes-BE_1.BE_T_9150':
    'Bills of exchange in circulation endorsed by the enterprise (9150)',
  'cr.annual.report.explanatory-notes-BE_1.BE_T_9161':
    'Commitments for the enterprise - Mortgages - Book value of the pledged assets (9161)',
  'cr.annual.report.explanatory-notes-BE_1.BE_T_91611':
    'Commitments for the enterprise - Mortgages - Book value of the pledged assets (91611)',
  'cr.annual.report.explanatory-notes-BE_1.BE_T_91612':
    'Commitments for third parties - Mortgages - Book value of the pledged assets (91612)',
  'cr.annual.report.explanatory-notes-BE_1.BE_T_9162':
    'Commitments for third parties - Mortgages - Book value of the pledged assets (9162)',
  'cr.annual.report.explanatory-notes-BE_1.BE_T_91621':
    'Commitments for the enterprise - Mortgages - Registration amount (91621)',
  'cr.annual.report.explanatory-notes-BE_1.BE_T_91622':
    'Commitments for third parties - Mortgages - Registration amount (91622)',
  'cr.annual.report.explanatory-notes-BE_1.BE_T_91631':
    'Commitments for the enterprise - Mortgages - Mandated registration amount (91631)',
  'cr.annual.report.explanatory-notes-BE_1.BE_T_91632':
    'Commitments for third parties - Mortgages - Mandated registration amount (91632)',
  'cr.annual.report.explanatory-notes-BE_1.BE_T_9171':
    'Commitments for the enterprise - Mortgages - Registration amount (9171)',
  'cr.annual.report.explanatory-notes-BE_1.BE_T_91711':
    'Commitments for the enterprise - Pledge on the business - Registration amount (91711)',
  'cr.annual.report.explanatory-notes-BE_1.BE_T_91712':
    'Commitments for third parties - Pledge on the business - Registration amount (91712)',
  'cr.annual.report.explanatory-notes-BE_1.BE_T_9172':
    'Commitments for third parties - Mortgages - Registration amount (9172)',
  'cr.annual.report.explanatory-notes-BE_1.BE_T_91721':
    'Commitments for the enterprise - Pledge on the business - Mandated Registration amount (91721)',
  'cr.annual.report.explanatory-notes-BE_1.BE_T_91722':
    'Commitments for third parties - Pledge on the business - Mandated Registration amount (91722)',
  'cr.annual.report.explanatory-notes-BE_1.BE_T_9181':
    'Commitments for the enterprise - Pledge on the business - Registration amount (9181)',
  'cr.annual.report.explanatory-notes-BE_1.BE_T_91811':
    'Commitments for the enterprise - Pledge on other assets - Registration amount (91811)',
  'cr.annual.report.explanatory-notes-BE_1.BE_T_91812':
    'Commitments for third parties - Pledge on other assets - Registration amount (91812)',
  'cr.annual.report.explanatory-notes-BE_1.BE_T_9182':
    'Commitments for third parties - Pledge on the business - Registration amount (9182)',
  'cr.annual.report.explanatory-notes-BE_1.BE_T_91821':
    'Commitments for the enterprise - Pledge on other assets - Maximum amount (91821)',
  'cr.annual.report.explanatory-notes-BE_1.BE_T_91822':
    'Commitments for third parties - Pledge on other assets - Maximum amount (91822)',
  'cr.annual.report.explanatory-notes-BE_1.BE_T_9191':
    'Commitments for the enterprise - Pledge on other assets - Registration amount (9191)',
  'cr.annual.report.explanatory-notes-BE_1.BE_T_91911':
    'Commitments for the enterrise - Pledge on future assets - Registration amount (91911)',
  'cr.annual.report.explanatory-notes-BE_1.BE_T_91912':
    'Commitments for third parties - Pledge on future assets - Registration amount (91912)',
  'cr.annual.report.explanatory-notes-BE_1.BE_T_9192':
    'Commitments for third parties - Pledge on other assets - Registration amount (9192)',
  'cr.annual.report.explanatory-notes-BE_1.BE_T_91921':
    'Commitments for the enterrise - Pledge on future assets - Maximum amount (91921)',
  'cr.annual.report.explanatory-notes-BE_1.BE_T_91922':
    'Commitments for third parties - Pledge on future assets - Maximum amount (91922)',
  'cr.annual.report.explanatory-notes-BE_1.BE_T_9201':
    'Commitments for the enterprise - Pledge on future assets - Registration amount (9201)',
  'cr.annual.report.explanatory-notes-BE_1.BE_T_92011':
    'Commitments for the enterprise - Privilage for the seller - Book value of the soled goods (92011)',
  'cr.annual.report.explanatory-notes-BE_1.BE_T_92012':
    'Commitments for third parties - Privilage for the seller - Book value of the soled goods (92012)',
  'cr.annual.report.explanatory-notes-BE_1.BE_T_9202':
    'Commitments for third parties - Pledge on future assets - Registration amount (9202)',
  'cr.annual.report.explanatory-notes-BE_1.BE_T_92021':
    'Commitments for the enterprise - Privilage for the seller - Amount of the non-paid price (92021)',
  'cr.annual.report.explanatory-notes-BE_1.BE_T_92022':
    'Commitments for third parties - Privilage for the seller - Amount of the non-paid price (92022)',
  'cr.annual.report.explanatory-notes-BE_1.BE_T_9500': 'Amounts receivable from directors (9500)',
  'cr.annual.report.explanatory-notes-BE_1.BE_T_9501': 'Warranties in their favor (9501)',
  'cr.annual.report.explanatory-notes-BE_1.BE_T_9502':
    'Other significant commitments in their favor (9502)',
  'cr.annual.report.explanatory-notes-BE_1.business': 'Collateral securities',
  'cr.annual.report.explanatory-notes-BE_1.general': 'General',
  'cr.annual.report.explanatory-notes-BE_10.BE_T_6358':
    'Provisions for risks and charges: additions (6358)',
  'cr.annual.report.explanatory-notes-BE_10.BE_T_6408': 'Other operating charges (6408)',
  'cr.annual.report.explanatory-notes-BE_10.BE_T_9072':
    'Debts fallen due towards tax administration (9072)',
  'cr.annual.report.explanatory-notes-BE_10.BE_T_9076':
    'Debts fallen due towards National Social Security Office (9076)',
  'cr.annual.report.explanatory-notes-BE_10.BE_T_9150':
    'Bills of exchange in circulation endorsed by the enterprise (9150)',
  'cr.annual.report.explanatory-notes-BE_10.BE_T_91611':
    'Commitments for the enterprise - Mortgages - Book value of the pledged assets (91611)',
  'cr.annual.report.explanatory-notes-BE_10.BE_T_91612':
    'Commitments for third parties - Mortgages - Book value of the pledged assets (91612)',
  'cr.annual.report.explanatory-notes-BE_10.BE_T_91621':
    'Commitments for the enterprise - Mortgages - Registration amount (91621)',
  'cr.annual.report.explanatory-notes-BE_10.BE_T_91622':
    'Commitments for third parties - Mortgages - Registration amount (91622)',
  'cr.annual.report.explanatory-notes-BE_10.BE_T_91631':
    'Commitments for the enterprise - Mortgages - Mandated registration amount (91631)',
  'cr.annual.report.explanatory-notes-BE_10.BE_T_91632':
    'Commitments for third parties - Mortgages - Mandated registration amount (91632)',
  'cr.annual.report.explanatory-notes-BE_10.BE_T_91711':
    'Commitments for the enterprise - Pledge on the business - Registration amount (91711)',
  'cr.annual.report.explanatory-notes-BE_10.BE_T_91712':
    'Commitments for third parties - Pledge on the business - Registration amount (91712)',
  'cr.annual.report.explanatory-notes-BE_10.BE_T_91721':
    'Commitments for the enterprise - Pledge on the business - Mandated Registration amount (91721)',
  'cr.annual.report.explanatory-notes-BE_10.BE_T_91722':
    'Commitments for third parties - Pledge on the business - Mandated Registration amount (91722)',
  'cr.annual.report.explanatory-notes-BE_10.BE_T_91811':
    'Commitments for the enterprise - Pledge on other assets - Registration amount (91811)',
  'cr.annual.report.explanatory-notes-BE_10.BE_T_91812':
    'Commitments for third parties - Pledge on other assets - Registration amount (91812)',
  'cr.annual.report.explanatory-notes-BE_10.BE_T_91821':
    'Commitments for the enterprise - Pledge on other assets - Maximum amount (91821)',
  'cr.annual.report.explanatory-notes-BE_10.BE_T_91822':
    'Commitments for third parties - Pledge on other assets - Maximum amount (91822)',
  'cr.annual.report.explanatory-notes-BE_10.BE_T_91911':
    'Commitments for the enterrise - Pledge on future assets - Registration amount (91911)',
  'cr.annual.report.explanatory-notes-BE_10.BE_T_91912':
    'Commitments for third parties - Pledge on future assets - Registration amount (91912)',
  'cr.annual.report.explanatory-notes-BE_10.BE_T_91921':
    'Commitments for the enterrise - Pledge on future assets - Maximum amount (91921)',
  'cr.annual.report.explanatory-notes-BE_10.BE_T_91922':
    'Commitments for third parties - Pledge on future assets - Maximum amount (91922)',
  'cr.annual.report.explanatory-notes-BE_10.BE_T_92011':
    'Commitments for the enterprise - Privilage for the seller - Book value of the soled goods (92011)',
  'cr.annual.report.explanatory-notes-BE_10.BE_T_92012':
    'Commitments for third parties - Privilage for the seller - Book value of the soled goods (92012)',
  'cr.annual.report.explanatory-notes-BE_10.BE_T_92021':
    'Commitments for the enterprise - Privilage for the seller - Amount of the non-paid price (92021)',
  'cr.annual.report.explanatory-notes-BE_10.BE_T_92022':
    'Commitments for third parties - Privilage for the seller - Amount of the non-paid price (92022)',
  'cr.annual.report.explanatory-notes-BE_10.BE_T_9500': 'Amounts receivable from directors (9500)',
  'cr.annual.report.explanatory-notes-BE_10.BE_T_9501': 'Warranties in their favor (9501)',
  'cr.annual.report.explanatory-notes-BE_10.BE_T_9502':
    'Other significant commitments in their favor (9502)',
  'cr.annual.report.explanatory-notes-BE_10.business': 'Collateral securities',
  'cr.annual.report.explanatory-notes-BE_10.general': 'General',
  'cr.annual.report.explanatory-notes-BE_11.BE_T_6358':
    'Provisions for risks and charges: additions (6358)',
  'cr.annual.report.explanatory-notes-BE_11.BE_T_6408': 'Other operating charges (6408)',
  'cr.annual.report.explanatory-notes-BE_11.BE_T_9072':
    'Debts fallen due towards tax administration (9072)',
  'cr.annual.report.explanatory-notes-BE_11.BE_T_9076':
    'Debts fallen due towards National Social Security Office (9076)',
  'cr.annual.report.explanatory-notes-BE_11.BE_T_9150':
    'Bills of exchange in circulation endorsed by the company (9150)',
  'cr.annual.report.explanatory-notes-BE_11.BE_T_91611':
    'Commitments for the enterprise - Mortgages - Book value of the pledged assets (91611)',
  'cr.annual.report.explanatory-notes-BE_11.BE_T_91612':
    'Commitments for third parties - Mortgages - Book value of the pledged assets (91612)',
  'cr.annual.report.explanatory-notes-BE_11.BE_T_91621':
    'Commitments for the enterprise - Mortgages - Registration amount (91621)',
  'cr.annual.report.explanatory-notes-BE_11.BE_T_91622':
    'Commitments for third parties - Mortgages - Registration amount (91622)',
  'cr.annual.report.explanatory-notes-BE_11.BE_T_91631':
    'Commitments for the enterprise - Mortgages - Mandated registration amount (91631)',
  'cr.annual.report.explanatory-notes-BE_11.BE_T_91632':
    'Commitments for third parties - Mortgages - Mandated registration amount (91632)',
  'cr.annual.report.explanatory-notes-BE_11.BE_T_91711':
    'Commitments for the enterprise - Pledge on the business - Registration amount (91711)',
  'cr.annual.report.explanatory-notes-BE_11.BE_T_91712':
    'Commitments for third parties - Pledge on the business - Registration amount (91712)',
  'cr.annual.report.explanatory-notes-BE_11.BE_T_91721':
    'Commitments for the enterprise - Pledge on the business - Mandated Registration amount (91721)',
  'cr.annual.report.explanatory-notes-BE_11.BE_T_91722':
    'Commitments for third parties - Pledge on the business - Mandated Registration amount (91722)',
  'cr.annual.report.explanatory-notes-BE_11.BE_T_91811':
    'Commitments for the enterprise - Pledge on other assets - Registration amount (91811)',
  'cr.annual.report.explanatory-notes-BE_11.BE_T_91812':
    'Commitments for third parties - Pledge on other assets - Registration amount (91812)',
  'cr.annual.report.explanatory-notes-BE_11.BE_T_91821':
    'Commitments for the enterprise - Pledge on other assets - Maximum amount (91821)',
  'cr.annual.report.explanatory-notes-BE_11.BE_T_91822':
    'Commitments for third parties - Pledge on other assets - Maximum amount (91822)',
  'cr.annual.report.explanatory-notes-BE_11.BE_T_91911':
    'Commitments for the enterrise - Pledge on future assets - Registration amount (91911)',
  'cr.annual.report.explanatory-notes-BE_11.BE_T_91912':
    'Commitments for third parties - Pledge on future assets - Registration amount (91912)',
  'cr.annual.report.explanatory-notes-BE_11.BE_T_91921':
    'Commitments for the enterrise - Pledge on future assets - Maximum amount (91921)',
  'cr.annual.report.explanatory-notes-BE_11.BE_T_91922':
    'Commitments for third parties - Pledge on future assets - Maximum amount (91922)',
  'cr.annual.report.explanatory-notes-BE_11.BE_T_92011':
    'Commitments for the enterprise - Privilage for the seller - Book value of the soled goods (92011)',
  'cr.annual.report.explanatory-notes-BE_11.BE_T_92012':
    'Commitments for third parties - Privilage for the seller - Book value of the soled goods (92012)',
  'cr.annual.report.explanatory-notes-BE_11.BE_T_92021':
    'Commitments for the enterprise - Privilage for the seller - Amount of the non-paid price (92021)',
  'cr.annual.report.explanatory-notes-BE_11.BE_T_92022':
    'Commitments for third parties - Privilage for the seller - Amount of the non-paid price (92022)',
  'cr.annual.report.explanatory-notes-BE_11.BE_T_9500': 'Amounts receivable from directors (9500)',
  'cr.annual.report.explanatory-notes-BE_11.BE_T_9501': 'Warranties in their favor (9501)',
  'cr.annual.report.explanatory-notes-BE_11.BE_T_9502':
    'Other significant commitments in their favor (9502)',
  'cr.annual.report.explanatory-notes-BE_11.business': 'Collateral securities',
  'cr.annual.report.explanatory-notes-BE_11.general': 'General',
  'cr.annual.report.explanatory-notes-BE_12_21.BE_T_9150':
    'Bills of exchange in circulation endorsed by the association or foundation (9150)',
  'cr.annual.report.explanatory-notes-BE_12_21.BE_T_91611':
    'Book value of the immovable properties mortgaged (91611)',
  'cr.annual.report.explanatory-notes-BE_12_21.BE_T_91621': 'Amount of registration (91621)',
  'cr.annual.report.explanatory-notes-BE_12_21.BE_T_91711':
    'Maximum amount up to which the debt is secured and which is the subject of registration\n  (91711)',
  'cr.annual.report.explanatory-notes-BE_12_21.BE_T_91721':
    'For irrevocable mandates to pledge goodwill, the amount for which the agent can take\n the inscription (91721)',
  'cr.annual.report.explanatory-notes-BE_12_21.BE_T_91811':
    'Book value of the immovable properties mortgaged (91811)',
  'cr.annual.report.explanatory-notes-BE_12_21.BE_T_91821':
    'Maximum amount up to which the debt is secured (91821)',
  'cr.annual.report.explanatory-notes-BE_12_21.BE_T_91911': 'Amount of assets in question (91911)',
  'cr.annual.report.explanatory-notes-BE_12_21.BE_T_91921':
    'Maximum amount up to which the debt is secured (91921)',
  'cr.annual.report.explanatory-notes-BE_12_21.BE_T_92011': 'Book value of sold goods (92011)',
  'cr.annual.report.explanatory-notes-BE_12_21.BE_T_92021': 'Amount of the unpaid price (92021)',
  'cr.annual.report.explanatory-notes-BE_12_21.BE_T_9500':
    'Amounts receivable from these persons (9500)',
  'cr.annual.report.explanatory-notes-BE_12_21.BE_T_9501':
    'Guarantees provided in their favour (9501)',
  'cr.annual.report.explanatory-notes-BE_12_21.BE_T_9502':
    'Other significant commitments undertaken in their favour (9502)',
  'cr.annual.report.explanatory-notes-BE_12_21.business': 'Collateral securities',
  'cr.annual.report.explanatory-notes-BE_12_21.general': 'General',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_635':
    'Provision for pensions and similar obligations (635)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_6358':
    'Provisions for risks and charges: additions (6358)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_640':
    'Other operating charges: taxes related to operations (640)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_6418': 'Other charges (6418)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_653':
    'Other financial charges: amount of the discount borne by the enterprise (653)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_9072':
    'Debts fallen due towards tax administration (9072)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_9076':
    'Debts fallen due towards National Social Security Office (9076)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_9145': 'VAT taxes charged to the enterprise (9145)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_9146': 'VAT taxes charged by the enterprise (9146)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_9150':
    'Bills of exchange in circulation endorsed by the enterprise (9150)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_9161':
    'Commitments for the enterprise - Mortgages - Book value of the pledged assets (9161)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_91611':
    'Commitments for the enterprise - Mortgages - Book value of the pledged assets (91611)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_91612':
    'Commitments for third parties - Mortgages - Book value of the pledged assets (91612)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_9162':
    'Commitments for third parties - Mortgages - Book value of the pledged assets (9162)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_91621':
    'Commitments for the enterprise - Mortgages - Registration amount (91621)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_91622':
    'Commitments for third parties - Mortgages - Registration amount (91622)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_91631':
    'Commitments for the enterprise - Mortgages - Mandated registration amount (91631)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_91632':
    'Commitments for third parties - Mortgages - Mandated registration amount (91632)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_9171':
    'Commitments for the enterprise - Mortgages - Registration amount (9171)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_91711':
    'Commitments for the enterprise - Pledge on the business - Registration amount (91711)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_91712':
    'Commitments for third parties - Pledge on the business - Registration amount (91712)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_9172':
    'Commitments for third parties - Mortgages - Registration amount (9172)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_91721':
    'Commitments for the enterprise - Pledge on the business - Mandated Registration amount (91721)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_91722':
    'Commitments for third parties - Pledge on the business - Mandated Registration amount (91722)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_9181':
    'Commitments for the enterprise - Pledge on the business - Registration amount (9181)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_91811':
    'Commitments for the enterprise - Pledge on other assets - Registration amount (91811)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_91812':
    'Commitments for third parties - Pledge on other assets - Registration amount (91812)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_9182':
    'Commitments for third parties - Pledge on the business - Registration amount (9182)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_91821':
    'Commitments for the enterprise - Pledge on other assets - Maximum amount (91821)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_91822':
    'Commitments for third parties - Pledge on other assets - Maximum amount (91822)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_9191':
    'Commitments for the enterprise - Pledge on other assets - Registration amount (9191)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_91911':
    'Commitments for the enterrise - Pledge on future assets - Registration amount (91911)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_91912':
    'Commitments for third parties - Pledge on future assets - Registration amount (91912)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_9192':
    'Commitments for third parties - Pledge on other assets - Registration amount (9192)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_91921':
    'Commitments for the enterrise - Pledge on future assets - Maximum amount (91921)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_91922':
    'Commitments for third parties - Pledge on future assets - Maximum amount (91922)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_9201':
    'Commitments for the enterprise - Pledge on future assets - Registration amount (9201)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_92011':
    'Commitments for the enterprise - Privilage for the seller - Book value of the soled goods (92011)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_92012':
    'Commitments for third parties - Privilage for the seller - Book value of the soled goods (92012)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_9202':
    'Commitments for third parties - Pledge on future assets - Registration amount (9202)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_92021':
    'Commitments for the enterprise - Privilage for the seller - Amount of the non-paid price (92021)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_92022':
    'Commitments for third parties - Privilage for the seller - Amount of the non-paid price (92022)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_9500': 'Amounts receivable from directors (9500)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_9501': 'Warranties in their favor (9501)',
  'cr.annual.report.explanatory-notes-BE_2.BE_T_9502':
    'Other significant commitments in their favor (9502)',
  'cr.annual.report.explanatory-notes-BE_2.business': 'Collateral securities',
  'cr.annual.report.explanatory-notes-BE_2.general': 'General',
  'cr.annual.report.explanatory-notes-BE_4.BE_T_6358':
    'Provisions for risks and charges: additions (6358)',
  'cr.annual.report.explanatory-notes-BE_4.BE_T_6408': 'Other operating charges (6408)',
  'cr.annual.report.explanatory-notes-BE_4.BE_T_9072':
    'Debts fallen due towards tax administration (9072)',
  'cr.annual.report.explanatory-notes-BE_4.BE_T_9076':
    'Debts fallen due towards National Social Security Office (9076)',
  'cr.annual.report.explanatory-notes-BE_4.BE_T_9150':
    'Bills of exchange in circulation endorsed by the enterprise (9150)',
  'cr.annual.report.explanatory-notes-BE_4.BE_T_9161':
    'Commitments for the enterprise - Mortgages - Book value of the pledged assets (9161)',
  'cr.annual.report.explanatory-notes-BE_4.BE_T_91611':
    'Commitments for the enterprise - Mortgages - Book value of the pledged assets (91611)',
  'cr.annual.report.explanatory-notes-BE_4.BE_T_91612':
    'Commitments for third parties - Mortgages - Book value of the pledged assets (91612)',
  'cr.annual.report.explanatory-notes-BE_4.BE_T_9162':
    'Commitments for third parties - Mortgages - Book value of the pledged assets (9162)',
  'cr.annual.report.explanatory-notes-BE_4.BE_T_91621':
    'Commitments for the enterprise - Mortgages - Registration amount (91621)',
  'cr.annual.report.explanatory-notes-BE_4.BE_T_91622':
    'Commitments for third parties - Mortgages - Registration amount (91622)',
  'cr.annual.report.explanatory-notes-BE_4.BE_T_91631':
    'Commitments for the enterprise - Mortgages - Mandated registration amount (91631)',
  'cr.annual.report.explanatory-notes-BE_4.BE_T_91632':
    'Commitments for third parties - Mortgages - Mandated registration amount (91632)',
  'cr.annual.report.explanatory-notes-BE_4.BE_T_9171':
    'Commitments for the enterprise - Mortgages - Registration amount (9171)',
  'cr.annual.report.explanatory-notes-BE_4.BE_T_91711':
    'Commitments for the enterprise - Pledge on the business - Registration amount (91711)',
  'cr.annual.report.explanatory-notes-BE_4.BE_T_91712':
    'Commitments for third parties - Pledge on the business - Registration amount (91712)',
  'cr.annual.report.explanatory-notes-BE_4.BE_T_9172':
    'Commitments for third parties - Mortgages - Registration amount (9172)',
  'cr.annual.report.explanatory-notes-BE_4.BE_T_91721':
    'Commitments for the enterprise - Pledge on the business - Mandated Registration amount (91721)',
  'cr.annual.report.explanatory-notes-BE_4.BE_T_91722':
    'Commitments for third parties - Pledge on the business - Mandated Registration amount (91722)',
  'cr.annual.report.explanatory-notes-BE_4.BE_T_9181':
    'Commitments for the enterprise - Pledge on the business - Registration amount (9181)',
  'cr.annual.report.explanatory-notes-BE_4.BE_T_91811':
    'Commitments for the enterprise - Pledge on other assets - Registration amount (91811)',
  'cr.annual.report.explanatory-notes-BE_4.BE_T_91812':
    'Commitments for third parties - Pledge on other assets - Registration amount (91812)',
  'cr.annual.report.explanatory-notes-BE_4.BE_T_9182':
    'Commitments for third parties - Pledge on the business - Registration amount (9182)',
  'cr.annual.report.explanatory-notes-BE_4.BE_T_91821':
    'Commitments for the enterprise - Pledge on other assets - Maximum amount (91821)',
  'cr.annual.report.explanatory-notes-BE_4.BE_T_91822':
    'Commitments for third parties - Pledge on other assets - Maximum amount (91822)',
  'cr.annual.report.explanatory-notes-BE_4.BE_T_9191':
    'Commitments for the enterprise - Pledge on other assets - Registration amount (9191)',
  'cr.annual.report.explanatory-notes-BE_4.BE_T_91911':
    'Commitments for the enterrise - Pledge on future assets - Registration amount (91911)',
  'cr.annual.report.explanatory-notes-BE_4.BE_T_91912':
    'Commitments for third parties - Pledge on future assets - Registration amount (91912)',
  'cr.annual.report.explanatory-notes-BE_4.BE_T_9192':
    'Commitments for third parties - Pledge on other assets - Registration amount (9192)',
  'cr.annual.report.explanatory-notes-BE_4.BE_T_91921':
    'Commitments for the enterrise - Pledge on future assets - Maximum amount (91921)',
  'cr.annual.report.explanatory-notes-BE_4.BE_T_91922':
    'Commitments for third parties - Pledge on future assets - Maximum amount (91922)',
  'cr.annual.report.explanatory-notes-BE_4.BE_T_9201':
    'Commitments for the enterprise - Pledge on future assets - Registration amount (9201)',
  'cr.annual.report.explanatory-notes-BE_4.BE_T_92011':
    'Commitments for the enterprise - Privilage for the seller - Book value of the soled goods (92011)',
  'cr.annual.report.explanatory-notes-BE_4.BE_T_92012':
    'Commitments for third parties - Privilage for the seller - Book value of the soled goods (92012)',
  'cr.annual.report.explanatory-notes-BE_4.BE_T_9202':
    'Commitments for third parties - Pledge on future assets - Registration amount (9202)',
  'cr.annual.report.explanatory-notes-BE_4.BE_T_92021':
    'Commitments for the enterprise - Privilage for the seller - Amount of the non-paid price (92021)',
  'cr.annual.report.explanatory-notes-BE_4.BE_T_92022':
    'Commitments for third parties - Privilage for the seller - Amount of the non-paid price (92022)',
  'cr.annual.report.explanatory-notes-BE_4.BE_T_9500': 'Amounts receivable from directors (9500)',
  'cr.annual.report.explanatory-notes-BE_4.BE_T_9501': 'Warranties in their favor (9501)',
  'cr.annual.report.explanatory-notes-BE_4.BE_T_9502':
    'Other significant commitments in their favor (9502)',
  'cr.annual.report.explanatory-notes-BE_4.business': 'Collateral securities',
  'cr.annual.report.explanatory-notes-BE_4.general': 'General',
  'cr.annual.report.explanatory-notes-BE_5.BE_T_6358':
    'Provisions for risks and charges: additions (6358)',
  'cr.annual.report.explanatory-notes-BE_5.BE_T_6408': 'Other operating charges (6408)',
  'cr.annual.report.explanatory-notes-BE_5.BE_T_653':
    'Other financial charges: amount of the discount borne by the organisation (653)',
  'cr.annual.report.explanatory-notes-BE_5.BE_T_73':
    'Contributions, gifts, legacies and subsidies (73)',
  'cr.annual.report.explanatory-notes-BE_5.BE_T_9072':
    'Debts fallen due towards tax administration (9072)',
  'cr.annual.report.explanatory-notes-BE_5.BE_T_9076':
    'Debts fallen due towards National Social Security Office (9076)',
  'cr.annual.report.explanatory-notes-BE_5.BE_T_9150':
    'Bills of exchange in circulation endorsed by the organisation (9150)',
  'cr.annual.report.explanatory-notes-BE_5.BE_T_9161':
    'Commitments for the enterprise - Mortgages - Book value of the pledged assets (9161)',
  'cr.annual.report.explanatory-notes-BE_5.BE_T_91611':
    'Book value of the immovable properties mortgaged (91611)',
  'cr.annual.report.explanatory-notes-BE_5.BE_T_9162':
    'Commitments for third parties - Mortgages - Book value of the pledged assets (9162)',
  'cr.annual.report.explanatory-notes-BE_5.BE_T_91621': 'Amount of registration (91621)',
  'cr.annual.report.explanatory-notes-BE_5.BE_T_9171':
    'Commitments for the enterprise - Mortgages - Registration amount (9171)',
  'cr.annual.report.explanatory-notes-BE_5.BE_T_91711':
    'Maximum amount up to which the debt is secured and which is the subject of registration\n  (91711)',
  'cr.annual.report.explanatory-notes-BE_5.BE_T_9172':
    'Commitments for third parties - Mortgages - Registration amount (9172)',
  'cr.annual.report.explanatory-notes-BE_5.BE_T_91721':
    'For irrevocable mandates to pledge goodwill, the amount for which the agent can take\n the inscription (91721)',
  'cr.annual.report.explanatory-notes-BE_5.BE_T_9181':
    'Commitments for the enterprise - Pledge on the business - Registration amount (9181)',
  'cr.annual.report.explanatory-notes-BE_5.BE_T_91811':
    'Book value of the immovable properties mortgaged (91811)',
  'cr.annual.report.explanatory-notes-BE_5.BE_T_9182':
    'Commitments for third parties - Pledge on the business - Registration amount (9182)',
  'cr.annual.report.explanatory-notes-BE_5.BE_T_91821':
    'Maximum amount up to which the debt is secured (91821)',
  'cr.annual.report.explanatory-notes-BE_5.BE_T_9191':
    'Commitments for the enterprise - Pledge on other assets - Registration amount (9191)',
  'cr.annual.report.explanatory-notes-BE_5.BE_T_91911': 'Amount of assets in question (91911)',
  'cr.annual.report.explanatory-notes-BE_5.BE_T_9192':
    'Commitments for third parties - Pledge on other assets - Registration amount (9192)',
  'cr.annual.report.explanatory-notes-BE_5.BE_T_91921':
    'Maximum amount up to which the debt is secured (91921)',
  'cr.annual.report.explanatory-notes-BE_5.BE_T_9201':
    'Commitments for the enterprise - Pledge on future assets - Registration amount (9201)',
  'cr.annual.report.explanatory-notes-BE_5.BE_T_92011': 'Book value of sold goods (92011)',
  'cr.annual.report.explanatory-notes-BE_5.BE_T_9202':
    'Commitments for third parties - Pledge on future assets - Registration amount (9202)',
  'cr.annual.report.explanatory-notes-BE_5.BE_T_92021': 'Amount of the unpaid price (92021)',
  'cr.annual.report.explanatory-notes-BE_5.BE_T_9500': 'Amounts receivable from directors (9500)',
  'cr.annual.report.explanatory-notes-BE_5.BE_T_9501': 'Warranties in their favor (9501)',
  'cr.annual.report.explanatory-notes-BE_5.BE_T_9502':
    'Other significant commitments in their favor (9502)',
  'cr.annual.report.explanatory-notes-BE_5.business': 'Collateral securities',
  'cr.annual.report.explanatory-notes-BE_5.general': 'General',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_635':
    'Provision for pension and similar obligations - appropriations (635)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_6358':
    'Provisions for risks and charges: additions (6358)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_640': 'Taxes related to operation (640)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_6408': 'Other operating charges (6408)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_6418': 'Other charges (6418)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_653':
    'Other financial charges: amount of the discount borne by the organisation (653)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_730': 'Membership fees (730)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_7301':
    'Contributions, gifts, legacies and subsidies: contribution (7301)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_731': 'Donations (731)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_732': 'Bequests (732)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_7323':
    'Contributions, gifts, legacies and subsidies: gifts (7323)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_733': 'Subsidies (733)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_7345':
    'Contributions, gifts, legacies and subsidies: legacies (7345)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_736':
    'Capital and interest subsidies, operating subsidies and compensatory amounts intended to reduce the wage cost (736)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_7368':
    'Capital and interest subsidies, operating subsidies and compensatory amounts intended to reduce the wage cost (7368)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_9072':
    'Debts fallen due towards tax administration (9072)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_9076':
    'Debts fallen due towards National Social Security Office (9076)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_9145':
    'VAT taxes charged to the organisation (9145)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_9146':
    'VAT taxes charged by the organisation (9146)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_9150':
    'Bills of exchange in circulation endorsed by the organisation (9150)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_9161':
    'Commitments for the enterprise - Mortgages - Book value of the pledged assets (9161)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_91611':
    'Book value of the immovable properties mortgaged (91611)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_9162':
    'Commitments for third parties - Mortgages - Book value of the pledged assets (9162)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_91621': 'Amount of registration (91621)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_9171':
    'Commitments for the enterprise - Mortgages - Registration amount (9171)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_91711':
    'Maximum amount up to which the debt is secured and which is the subject of registration\n  (91711)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_9172':
    'Commitments for third parties - Mortgages - Registration amount (9172)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_91721':
    'For irrevocable mandates to pledge goodwill, the amount for which the agent can take\n the inscription (91721)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_9181':
    'Commitments for the enterprise - Pledge on the business - Registration amount (9181)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_91811':
    'Book value of the immovable properties mortgaged (91811)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_9182':
    'Commitments for third parties - Pledge on the business - Registration amount (9182)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_91821':
    'Maximum amount up to which the debt is secured (91821)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_9191':
    'Commitments for the enterprise - Pledge on other assets - Registration amount (9191)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_91911': 'Amount of assets in question (91911)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_9192':
    'Commitments for third parties - Pledge on other assets - Registration amount (9192)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_91921':
    'Maximum amount up to which the debt is secured (91921)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_9201':
    'Commitments for the enterprise - Pledge on future assets - Registration amount (9201)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_92011': 'Book value of sold goods (92011)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_9202':
    'Commitments for third parties - Pledge on future assets - Registration amount (9202)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_92021': 'Amount of the unpaid price (92021)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_9500': 'Amounts receivable from directors (9500)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_9501': 'Warranties in their favor (9501)',
  'cr.annual.report.explanatory-notes-BE_6.BE_T_9502':
    'Other significant commitments in their favor (9502)',
  'cr.annual.report.explanatory-notes-BE_6.business': 'Collateral securities',
  'cr.annual.report.explanatory-notes-BE_6.general': 'General',
  'cr.annual.report.explanatory-notes-BE_9.BE_T_635':
    'Provision for pensions and similar obligations (635)',
  'cr.annual.report.explanatory-notes-BE_9.BE_T_6358':
    'Provisions for risks and charges: additions (6358)',
  'cr.annual.report.explanatory-notes-BE_9.BE_T_640':
    'Other operating charges: taxes related to operations (640)',
  'cr.annual.report.explanatory-notes-BE_9.BE_T_6408': 'Other operating charges (6408)',
  'cr.annual.report.explanatory-notes-BE_9.BE_T_6418': 'Other charges (6418)',
  'cr.annual.report.explanatory-notes-BE_9.BE_T_653':
    'Other financial charges: amount of the discount borne by the enterprise (653)',
  'cr.annual.report.explanatory-notes-BE_9.BE_T_9072':
    'Debts fallen due towards tax administration (9072)',
  'cr.annual.report.explanatory-notes-BE_9.BE_T_9076':
    'Debts fallen due towards National Social Security Office (9076)',
  'cr.annual.report.explanatory-notes-BE_9.BE_T_9145': 'VAT taxes charged to the enterprise (9145)',
  'cr.annual.report.explanatory-notes-BE_9.BE_T_9146': 'VAT taxes charged by the enterprise (9146)',
  'cr.annual.report.explanatory-notes-BE_9.BE_T_9150':
    'Bills of exchange in circulation endorsed by the enterprise (9150)',
  'cr.annual.report.explanatory-notes-BE_9.BE_T_91611':
    'Commitments for the enterprise - Mortgages - Book value of the pledged assets (91611)',
  'cr.annual.report.explanatory-notes-BE_9.BE_T_91612':
    'Commitments for third parties - Mortgages - Book value of the pledged assets (91612)',
  'cr.annual.report.explanatory-notes-BE_9.BE_T_91621':
    'Commitments for the enterprise - Mortgages - Registration amount (91621)',
  'cr.annual.report.explanatory-notes-BE_9.BE_T_91622':
    'Commitments for third parties - Mortgages - Registration amount (91622)',
  'cr.annual.report.explanatory-notes-BE_9.BE_T_91631':
    'Commitments for the enterprise - Mortgages - Mandated registration amount (91631)',
  'cr.annual.report.explanatory-notes-BE_9.BE_T_91632':
    'Commitments for third parties - Mortgages - Mandated registration amount (91632)',
  'cr.annual.report.explanatory-notes-BE_9.BE_T_91711':
    'Commitments for the enterprise - Pledge on the business - Registration amount (91711)',
  'cr.annual.report.explanatory-notes-BE_9.BE_T_91712':
    'Commitments for third parties - Pledge on the business - Registration amount (91712)',
  'cr.annual.report.explanatory-notes-BE_9.BE_T_91721':
    'Commitments for the enterprise - Pledge on the business - Mandated Registration amount (91721)',
  'cr.annual.report.explanatory-notes-BE_9.BE_T_91722':
    'Commitments for third parties - Pledge on the business - Mandated Registration amount (91722)',
  'cr.annual.report.explanatory-notes-BE_9.BE_T_91811':
    'Commitments for the enterprise - Pledge on other assets - Registration amount (91811)',
  'cr.annual.report.explanatory-notes-BE_9.BE_T_91812':
    'Commitments for third parties - Pledge on other assets - Registration amount (91812)',
  'cr.annual.report.explanatory-notes-BE_9.BE_T_91821':
    'Commitments for the enterprise - Pledge on other assets - Maximum amount (91821)',
  'cr.annual.report.explanatory-notes-BE_9.BE_T_91822':
    'Commitments for third parties - Pledge on other assets - Maximum amount (91822)',
  'cr.annual.report.explanatory-notes-BE_9.BE_T_91911':
    'Commitments for the enterrise - Pledge on future assets - Registration amount (91911)',
  'cr.annual.report.explanatory-notes-BE_9.BE_T_91912':
    'Commitments for third parties - Pledge on future assets - Registration amount (91912)',
  'cr.annual.report.explanatory-notes-BE_9.BE_T_91921':
    'Commitments for the enterrise - Pledge on future assets - Maximum amount (91921)',
  'cr.annual.report.explanatory-notes-BE_9.BE_T_91922':
    'Commitments for third parties - Pledge on future assets - Maximum amount (91922)',
  'cr.annual.report.explanatory-notes-BE_9.BE_T_92011':
    'Commitments for the enterprise - Privilage for the seller - Book value of the soled goods (92011)',
  'cr.annual.report.explanatory-notes-BE_9.BE_T_92012':
    'Commitments for third parties - Privilage for the seller - Book value of the soled goods (92012)',
  'cr.annual.report.explanatory-notes-BE_9.BE_T_92021':
    'Commitments for the enterprise - Privilage for the seller - Amount of the non-paid price (92021)',
  'cr.annual.report.explanatory-notes-BE_9.BE_T_92022':
    'Commitments for third parties - Privilage for the seller - Amount of the non-paid price (92022)',
  'cr.annual.report.explanatory-notes-BE_9.BE_T_9500': 'Amounts receivable from directors (9500)',
  'cr.annual.report.explanatory-notes-BE_9.BE_T_9501': 'Warranties in their favor (9501)',
  'cr.annual.report.explanatory-notes-BE_9.BE_T_9502':
    'Other significant commitments in their favor (9502)',
  'cr.annual.report.explanatory-notes-BE_9.business': 'Collateral securities',
  'cr.annual.report.explanatory-notes-BE_9.general': 'General',
  'cr.annual.report.explanatory-notes.after-2020':
    "Collateral securities or business garantuees given or irrevocably promised by the company on its own assets as a guarantee for the company's debts and obligations – Model from 2020",
  'cr.annual.report.explanatory-notes.before-2020':
    "Collateral securities or business garantuees given or irrevocably promised by the company on its own assets as a guarantee for the company's debts and obligations - Mortgages - Model before 2020",
  'cr.annual.report.explanatory-notes.tooltip.BE':
    'In the annex or explanatory notes, the company explains which valuation rules it used to obtain certain figures from the balance sheet and profit and loss account. These valuation rules contain important information. For example, it is important to determine how a company manages depreciation and stock valuations. Each form of evaluation gives a completely different picture and has financial implications.',
  'cr.annual.report.key-figures.accountsTotal': 'Total assets',
  'cr.annual.report.key-figures.accountsTotal-BE': 'Total Account',
  'cr.annual.report.key-figures.acidTestRatio': 'Liquidity ratio',
  'cr.annual.report.key-figures.averageDaysClientsCredit-BE':
    'Average number of days of customer credit',
  'cr.annual.report.key-figures.averageDaysSuppliersCredit-BE':
    'Average number of days of supplier credit',
  'cr.annual.report.key-figures.balans': 'Balance sheet',
  'cr.annual.report.key-figures.borrowingRatio': 'Borrowing ratio',
  'cr.annual.report.key-figures.capital': 'Capital',
  'cr.annual.report.key-figures.cash': 'Cash',
  'cr.annual.report.key-figures.cashFlow': 'Cash flow',
  'cr.annual.report.key-figures.cashFlowRatio': 'Cash flow',
  'cr.annual.report.key-figures.creditorDays': 'Creditors days',
  'cr.annual.report.key-figures.currentRatio': 'Current ratio',
  'cr.annual.report.key-figures.debtsFallenDuetowardsRSZ-BE': 'Debts due to the NSSO',
  'cr.annual.report.key-figures.debtsFallenDuetowardsTax-BE': 'Outstanding tax liabilities',
  'cr.annual.report.key-figures.equity': 'Total equity',
  'cr.annual.report.key-figures.equityCapital': 'Equity capital',
  'cr.annual.report.key-figures.financial-ratios': 'Financial ratios',
  'cr.annual.report.key-figures.generalDebtRate-BE': 'General debt ratio in %',
  'cr.annual.report.key-figures.grantsAndContributionsAutonomy-BE':
    'Autonomy subsidies and contributions in %',
  'cr.annual.report.key-figures.grossSalesMargin-BE': 'Gross sales margin in %',
  'cr.annual.report.key-figures.income-statement': 'Income statement',
  'cr.annual.report.key-figures.investments-BE': 'Investments',
  'cr.annual.report.key-figures.netSalesMargin-BE': 'Net sales margin in %',
  'cr.annual.report.key-figures.operationResult-BE': 'Operating result',
  'cr.annual.report.key-figures.preTaxProfitAndLoss': 'Pre - Tax Profit / Loss',
  'cr.annual.report.key-figures.preTaxProfitMargin': 'Pre Tax Profit Margin %',
  'cr.annual.report.key-figures.quickRatio': 'Liquidity ratio',
  'cr.annual.report.key-figures.quickRatio-BE': 'Quick ratio',
  'cr.annual.report.key-figures.ratios.description': 'The following figures are shown as ratios',
  'cr.annual.report.key-figures.result': 'Net result',
  'cr.annual.report.key-figures.result-BE': 'Result for the period',
  'cr.annual.report.key-figures.returnOfCapital': 'Return of Capital %',
  'cr.annual.report.key-figures.shareholderFunds': 'Shareholder funds',
  'cr.annual.report.key-figures.solvencyRatio': 'Solvency ratio',
  'cr.annual.report.key-figures.sub-header': 'Base currency and unit',
  'cr.annual.report.key-figures.tangibleNetWorth': 'Tangible Net Worth',
  'cr.annual.report.key-figures.tooltip':
    'Here you will find a summary of the key figures of these annual accounts',
  'cr.annual.report.key-figures.tooltip.BE':
    'Here you will find an overview of the most important figures from the 5 most recent annual accounts and a number of financial ratios.',
  'cr.annual.report.key-figures.tooltip.GB':
    'Here, you will find a summary of the key figures of these financial statements.',
  'cr.annual.report.key-figures.tooltip.NL':
    'Here you will find a summary of the most important key figures of these financial statements.',
  'cr.annual.report.key-figures.totalAssets': 'Total assets',
  'cr.annual.report.key-figures.turnover': 'Turnover',
  'cr.annual.report.key-figures.workingCapital': 'Working capital',
  'cr.annual.report.key-figures.workingCapital-BE': 'Net working capital',
  'cr.annual.report.key-figures.workingCapitalRatio': 'Working capital Ratio',
  'cr.annual.report.profit-and-loss.appropriation': 'Appropriation',
  'cr.annual.report.profit-and-loss.BE_B_LBL_E_14': 'Profit (loss) to be carried forward (14)',
  'cr.annual.report.profit-and-loss.BE_P_RES_6064': 'Operating expenses (6064)',
  'cr.annual.report.profit-and-loss.BE_P_RES_6066A': 'Operating expenses (6066A)',
  'cr.annual.report.profit-and-loss.BE_P_RES_6566B': 'Financial expenses (6566B)',
  'cr.annual.report.profit-and-loss.BE_P_RES_66': 'Exceptional costs (66)',
  'cr.annual.report.profit-and-loss.BE_P_RES_6777': 'Income tax on the profit or loss (6777)',
  'cr.annual.report.profit-and-loss.BE_P_RES_680': 'Transfer to deferred taxes (680)',
  'cr.annual.report.profit-and-loss.BE_P_RES_689': 'Transfer to untaxed reserves (689)',
  'cr.annual.report.profit-and-loss.BE_P_RES_7074': 'Operating income (7074)',
  'cr.annual.report.profit-and-loss.BE_P_RES_7076A': 'Operating income (7076A)',
  'cr.annual.report.profit-and-loss.BE_P_RES_7576B': 'Financial income (7576B)',
  'cr.annual.report.profit-and-loss.BE_P_RES_76': 'Extraordinary income (76)',
  'cr.annual.report.profit-and-loss.BE_P_RES_780': 'Transfer from deferred taxes (780)',
  'cr.annual.report.profit-and-loss.BE_P_RES_789': 'Transfer from untaxed reserves (789)',
  'cr.annual.report.profit-and-loss.BE_P_RES_9901': 'Operating profit (loss) (9901)',
  'cr.annual.report.profit-and-loss.BE_P_RES_9902':
    'Postitive (negative) results on ordinary operations (9902)',
  'cr.annual.report.profit-and-loss.BE_P_RES_9903':
    'Profit (loss) for the financial year before tax (9903)',
  'cr.annual.report.profit-and-loss.BE_P_RES_9904': 'Profit (loss) for the financial year (9904)',
  'cr.annual.report.profit-and-loss.BE_P_RES_9905':
    'Profit (Loss) of the period available for appropriation (+)/(-) (9905)',
  'cr.annual.report.profit-and-loss.BE_P_RES_C_60':
    'Trade goods, raw materials and consumables (60)',
  'cr.annual.report.profit-and-loss.BE_P_RES_C_61': 'Services and other goods (61)',
  'cr.annual.report.profit-and-loss.BE_P_RES_C_62':
    'Remuneration, social security costs and pensions (62)',
  'cr.annual.report.profit-and-loss.BE_P_RES_C_630':
    'Depreciation and write-downs on formation expenses, intangible and tangible fixed assets (630)',
  'cr.annual.report.profit-and-loss.BE_P_RES_C_6314':
    'Write-downs of inventories, contracts in progress and trade receivables: additions (companies) (6314)',
  'cr.annual.report.profit-and-loss.BE_P_RES_C_6358':
    'Provisions for risks and costs: additions (uses and withdrawals) (6358)',
  'cr.annual.report.profit-and-loss.BE_P_RES_C_6408': 'Other operating expenses (6408)',
  'cr.annual.report.profit-and-loss.BE_P_RES_C_649':
    'Restructuring costs showing as operating costs (649)',
  'cr.annual.report.profit-and-loss.BE_P_RES_C_66A': 'Non-recurring operating costs (66A)',
  'cr.annual.report.profit-and-loss.BE_P_RES_CTR_6008': 'Acquisition (6008)',
  'cr.annual.report.profit-and-loss.BE_P_RES_CTR_609': 'Inventory: decrease (increase) (609)',
  'cr.annual.report.profit-and-loss.BE_P_RES_E_660':
    'Exceptional depreciation and write-downs of formation expenses, intangible and tangible fixed assets (660)',
  'cr.annual.report.profit-and-loss.BE_P_RES_E_661': 'Write-downs on financial fixed assets (661)',
  'cr.annual.report.profit-and-loss.BE_P_RES_E_662':
    'Provisions for exceptional risks and costs: additions (expenditures) (662)',
  'cr.annual.report.profit-and-loss.BE_P_RES_E_663':
    'Capital gains on the realisation of fixed assets (663)',
  'cr.annual.report.profit-and-loss.BE_P_RES_E_6648': 'Other exceptional costs (6648)',
  'cr.annual.report.profit-and-loss.BE_P_RES_E_669':
    'Exceptional restructuring costs reported as assets (669)',
  'cr.annual.report.profit-and-loss.BE_P_RES_E_760':
    'Reversal of depreciation, amortisation and impairment of intangible assets and property, plant and equipments (760)',
  'cr.annual.report.profit-and-loss.BE_P_RES_E_761':
    'Reversal of write-downs on financial fixed assets (761)',
  'cr.annual.report.profit-and-loss.BE_P_RES_E_762':
    'Reversal of provisions for exceptional risks and costs (762)',
  'cr.annual.report.profit-and-loss.BE_P_RES_E_763':
    'Capital gains on the realisation of fixed assets (763)',
  'cr.annual.report.profit-and-loss.BE_P_RES_E_7649': 'Other exceptional returns (7649)',
  'cr.annual.report.profit-and-loss.BE_P_RES_FC_65': 'Recurring financial charges (65)',
  'cr.annual.report.profit-and-loss.BE_P_RES_FC_65.COMPANY': 'Recurring financial expenses (65)',
  'cr.annual.report.profit-and-loss.BE_P_RES_FC_65.NON-PROFIT': 'Financial expenses (65)',
  'cr.annual.report.profit-and-loss.BE_P_RES_FC_66B': 'Non-recurring financial costs (66B)',
  'cr.annual.report.profit-and-loss.BE_P_RES_FCR_650': 'Cost of debt (650)',
  'cr.annual.report.profit-and-loss.BE_P_RES_FCR_651':
    'Impairment of assets other than inventories, contracts in progress and trade receivables: additions (companies) (651)',
  'cr.annual.report.profit-and-loss.BE_P_RES_FCR_6529': 'Other financial costs (6529)',
  'cr.annual.report.profit-and-loss.BE_P_RES_FR_75': 'Recurring financial income (75)',
  'cr.annual.report.profit-and-loss.BE_P_RES_FR_75.COMPANY': 'Recurring financial income (75)',
  'cr.annual.report.profit-and-loss.BE_P_RES_FR_75.NON-PROFIT': 'Financial income (75)',
  'cr.annual.report.profit-and-loss.BE_P_RES_FR_76B': 'Non-recurring financial income (76B)',
  'cr.annual.report.profit-and-loss.BE_P_RES_FRR_750': 'Income from financial fixed assets (750)',
  'cr.annual.report.profit-and-loss.BE_P_RES_FRR_751': 'Income from current assets (751)',
  'cr.annual.report.profit-and-loss.BE_P_RES_FRR_7529': 'Other financial profits (7529)',
  'cr.annual.report.profit-and-loss.BE_P_RES_FRR_753':
    'Of which: capital and interest subsidies (753)',
  'cr.annual.report.profit-and-loss.BE_P_RES_R_70': 'Turnover (70)',
  'cr.annual.report.profit-and-loss.BE_P_RES_R_71':
    'Stock, work in progress, finished products and contracts in progress: increase (decrease) (71)',
  'cr.annual.report.profit-and-loss.BE_P_RES_R_72': 'Fixed production (72)',
  'cr.annual.report.profit-and-loss.BE_P_RES_R_74': 'Other operating income (74)',
  'cr.annual.report.profit-and-loss.BE_P_RES_R_76A':
    'Of which: non-recurring operating income (76A)',
  'cr.annual.report.profit-and-loss.BE_P_RES_R_9900': 'Gross margin (9900)',
  'cr.annual.report.profit-and-loss.BE_P_RES_RG_6061':
    'Trade goods, raw materials, consumables, services and other goods (6061)',
  'cr.annual.report.profit-and-loss.BE_P_RES_RGR_73':
    'Membership fees, donations, legacies and grants (73)',
  'cr.annual.report.profit-and-loss.BE_P_RES_T_6703': 'Taxes (6703)',
  'cr.annual.report.profit-and-loss.BE_P_RES_T_77':
    'Regularisation of taxes and reversal of provision for taxation (77)',
  'cr.annual.report.profit-and-loss.BE_R_6359':
    'Provisions for liabilities and charges: appropriations\n (uses and write-backs) (6359)',
  'cr.annual.report.profit-and-loss.BE_RV_14': 'Profit(losses) carried forward (14)',
  'cr.annual.report.profit-and-loss.BE_RV_14P':
    'Profit(losses) carried forward from the previous financial year (14P)',
  'cr.annual.report.profit-and-loss.BE_RV_691': 'to contributions  (691)',
  'cr.annual.report.profit-and-loss.BE_RV_6912': 'Appropriation to capital & reserves (691/2)',
  'cr.annual.report.profit-and-loss.BE_RV_692': 'Appropriation to designated funds (692)',
  'cr.annual.report.profit-and-loss.BE_RV_6920': 'to legal reserves (6920)',
  'cr.annual.report.profit-and-loss.BE_RV_6921': 'to other reserves (6921)',
  'cr.annual.report.profit-and-loss.BE_RV_694': 'Dividends (694)',
  'cr.annual.report.profit-and-loss.BE_RV_6947': 'Distribution of profit (6947)',
  'cr.annual.report.profit-and-loss.BE_RV_695': "Directors' emoluments (695)",
  'cr.annual.report.profit-and-loss.BE_RV_696': 'Employees (696)',
  'cr.annual.report.profit-and-loss.BE_RV_697': 'Other allocations (697)',
  'cr.annual.report.profit-and-loss.BE_RV_791':
    'Transfers from equity: funds, allocated funds and other reserves (791)',
  'cr.annual.report.profit-and-loss.BE_RV_791.COMPANY': 'to capital & share premium account (791)',
  'cr.annual.report.profit-and-loss.BE_RV_791.NON-PROFIT':
    'to funds of the association or foundation (791)',
  'cr.annual.report.profit-and-loss.BE_RV_7912': 'Transfer from capital and reserves (791/2)',
  'cr.annual.report.profit-and-loss.BE_RV_792.COMPANY': 'to reserves (792)',
  'cr.annual.report.profit-and-loss.BE_RV_792.NON-PROFIT': 'to designated funds (792)',
  'cr.annual.report.profit-and-loss.BE_RV_794': "Sharehold.'contrib.in resp.of loss (794)",
  'cr.annual.report.profit-and-loss.BE_RV_9905':
    'Profit (Loss) of the of the financial year to be appropriated (9905)',
  'cr.annual.report.profit-and-loss.BE_RV_9906': 'Profit (Loss) to be appropriated (+)/(-) (9906)',
  'cr.annual.report.profit-and-loss.bedrijfsopbrengsten-en-bedrijfskosten':
    'Operating income and costs',
  'cr.annual.report.profit-and-loss.GB_RES_21': 'Operating profit/loss',
  'cr.annual.report.profit-and-loss.GB_RES_21_01': 'Operating expenses',
  'cr.annual.report.profit-and-loss.GB_RES_21_02': 'Operating income',
  'cr.annual.report.profit-and-loss.GB_RES_33': 'Depreciation charges',
  'cr.annual.report.profit-and-loss.GB_RES_21_03': 'Exceptional items',
  'cr.annual.report.profit-and-loss.GB_RES_21_04': 'Profit/Loss on disposal',
  'cr.annual.report.profit-and-loss.GB_RES_26': 'Pre-tax profit/loss',
  'cr.annual.report.profit-and-loss.GB_RES_26_03': 'Interest receivable',
  'cr.annual.report.profit-and-loss.GB_RES_26_04': 'Interest payable',
  'cr.annual.report.profit-and-loss.GB_RES_26_10': 'Exceptional items',
  'cr.annual.report.profit-and-loss.GB_RES_34': 'Net profit/loss',
  'cr.annual.report.profit-and-loss.GB_RES_34_01': 'Taxation',
  'cr.annual.report.profit-and-loss.GB_RES_34_02': 'Minority interests',
  'cr.annual.report.profit-and-loss.GB_RES_34_03': 'Extraordinary items',
  'cr.annual.report.profit-and-loss.GB_RES_34_04': 'Dividends',
  'cr.annual.report.profit-and-loss.GB_RES_35': 'Gross profit',
  'cr.annual.report.profit-and-loss.GB_RES_35_01': 'Turnover',
  'cr.annual.report.profit-and-loss.GB_RES_35_01_01': 'Turnover (UK)',
  'cr.annual.report.profit-and-loss.GB_RES_35_01_02': 'Turnover (Europe)',
  'cr.annual.report.profit-and-loss.GB_RES_35_01_03': 'Turnover (rest of the world)',
  'cr.annual.report.profit-and-loss.GB_RES_35_01_04': 'Turnover (exports)',
  'cr.annual.report.profit-and-loss.GB_RES_35_02': 'Cost of sales',
  'cr.annual.report.profit-and-loss.GB_RES_35_03': 'Total expenses',
  'cr.annual.report.profit-and-loss.GB_RES_35_03_01': 'Exceptional items',
  'cr.annual.report.profit-and-loss.GB_RES_35_03_02': 'Other direct items',
  'cr.annual.report.profit-and-loss.NL_ACC_02': 'Net result',
  'cr.annual.report.profit-and-loss.NL_RES_50_10_001': 'Total operating income',
  'cr.annual.report.profit-and-loss.NL_RES_50_10_002': 'Net turnover',
  'cr.annual.report.profit-and-loss.NL_RES_50_10_003':
    'Changes in inventories of finished goods and work in progress',
  'cr.annual.report.profit-and-loss.NL_RES_50_10_004': 'Activated products own company',
  'cr.annual.report.profit-and-loss.NL_RES_50_10_005': 'Other operating income',
  'cr.annual.report.profit-and-loss.NL_RES_50_10_006': 'Total income',
  'cr.annual.report.profit-and-loss.NL_RES_50_15_001': 'Gross margin',
  'cr.annual.report.profit-and-loss.NL_RES_50_15_002': 'Other gross margin',
  'cr.annual.report.profit-and-loss.NL_RES_50_20_001': 'Total operating charges',
  'cr.annual.report.profit-and-loss.NL_RES_50_20_002': 'Cost of raw materials and consumables',
  'cr.annual.report.profit-and-loss.NL_RES_50_20_003':
    'Cost of outsourced work and other external charges',
  'cr.annual.report.profit-and-loss.NL_RES_50_20_004': 'Wages and salaries',
  'cr.annual.report.profit-and-loss.NL_RES_50_20_005': 'Social security contributions',
  'cr.annual.report.profit-and-loss.NL_RES_50_20_006':
    'Depreciation of intangible and tangible fixed assets',
  'cr.annual.report.profit-and-loss.NL_RES_50_20_007':
    'Other changes in the value of intangible and tangible fixed assets',
  'cr.annual.report.profit-and-loss.NL_RES_50_20_008':
    'Exceptional decrease in value of current assets',
  'cr.annual.report.profit-and-loss.NL_RES_50_20_009': 'Other operating charges',
  'cr.annual.report.profit-and-loss.NL_RES_50_20_010': 'General management costs',
  'cr.annual.report.profit-and-loss.NL_RES_50_25_001': 'Operating charges',
  'cr.annual.report.profit-and-loss.NL_RES_50_25_002': 'Cost of raw materials and consumables',
  'cr.annual.report.profit-and-loss.NL_RES_50_25_003':
    'Cost of outsourced work and other external charges',
  'cr.annual.report.profit-and-loss.NL_RES_50_25_004': 'Wages and salaries',
  'cr.annual.report.profit-and-loss.NL_RES_50_25_005': 'Social security contributions',
  'cr.annual.report.profit-and-loss.NL_RES_50_25_006':
    'Depreciation of intangible and tangible fixed assets',
  'cr.annual.report.profit-and-loss.NL_RES_50_25_007':
    'Other changes in the value of intangible and tangible fixed assets',
  'cr.annual.report.profit-and-loss.NL_RES_50_25_008': 'Special value reduction of current assets',
  'cr.annual.report.profit-and-loss.NL_RES_50_25_009': 'Other operating charges',
  'cr.annual.report.profit-and-loss.NL_RES_50_30_001': 'Operating result',
  'cr.annual.report.profit-and-loss.NL_RES_50_30_002': 'Other operating result',
  'cr.annual.report.profit-and-loss.NL_RES_50_40_001': 'Financial result',
  'cr.annual.report.profit-and-loss.NL_RES_50_40_002': 'Other financial result',
  'cr.annual.report.profit-and-loss.NL_RES_50_42_001': 'Total financial income',
  'cr.annual.report.profit-and-loss.NL_RES_50_42_002': 'Distribution from participating interests',
  'cr.annual.report.profit-and-loss.NL_RES_50_42_003':
    'Return from receivables that belong to the fixed assets and other interests',
  'cr.annual.report.profit-and-loss.NL_RES_50_42_004': 'Other interests and similar income',
  'cr.annual.report.profit-and-loss.NL_RES_50_42_005':
    'Value changes from receivables relating to fixed assets and other assets',
  'cr.annual.report.profit-and-loss.NL_RES_50_44_001': 'Total financial charges',
  'cr.annual.report.profit-and-loss.NL_RES_50_44_002': 'Distribution from participating interests',
  'cr.annual.report.profit-and-loss.NL_RES_50_44_003':
    'Value changes from receivables relating to fixed assets and other assets',
  'cr.annual.report.profit-and-loss.NL_RES_50_44_004': 'Interest charges and similar costs',
  'cr.annual.report.profit-and-loss.NL_RES_50_50_001': 'Result from ordinary operations before tax',
  'cr.annual.report.profit-and-loss.NL_RES_50_50_002':
    'Other result from ordinary operations before tax',
  'cr.annual.report.profit-and-loss.NL_RES_50_52_001': 'Share in profit/loss participation',
  'cr.annual.report.profit-and-loss.NL_RES_50_52_002': 'Other share in profit/loss participation',
  'cr.annual.report.profit-and-loss.NL_RES_50_52_003': 'Result from participation after tax',
  'cr.annual.report.profit-and-loss.NL_RES_50_54_001': 'Tax on results from ordinary operations',
  'cr.annual.report.profit-and-loss.NL_RES_50_54_002':
    'Other tax on results from ordinary operations',
  'cr.annual.report.profit-and-loss.NL_RES_50_60_001': 'Results from ordinary operations after tax',
  'cr.annual.report.profit-and-loss.NL_RES_50_60_002':
    'Other result from ordinary operations after tax',
  'cr.annual.report.profit-and-loss.NL_RES_50_62_001': 'Total extraordinary income',
  'cr.annual.report.profit-and-loss.NL_RES_50_62_002': 'Total of other extraordinary income',
  'cr.annual.report.profit-and-loss.NL_RES_50_64_001': 'Total extraordinary charges',
  'cr.annual.report.profit-and-loss.NL_RES_50_64_002': 'Total of other extraordinary charges',
  'cr.annual.report.profit-and-loss.NL_RES_50_66_001': 'Tax on extraordinary results',
  'cr.annual.report.profit-and-loss.NL_RES_50_66_002': 'Other extraordinary results',
  'cr.annual.report.profit-and-loss.NL_RES_50_70_001': 'Extraordinary results after tax',
  'cr.annual.report.profit-and-loss.NL_RES_50_70_002': 'Other extraordinary results after tax',
  'cr.annual.report.profit-and-loss.NL_RES_50_70_003':
    'Balance of extraordinary results before tax',
  'cr.annual.report.profit-and-loss.NL_RES_50_72_001': 'Third-party shares',
  'cr.annual.report.profit-and-loss.NL_RES_50_72_002': 'Other third-party shares',
  'cr.annual.report.profit-and-loss.NL_RES_50_80_001': 'Results after tax',
  'cr.annual.report.profit-and-loss.NL_RES_50_80_002': 'Other results after tax',
  'cr.annual.report.profit-and-loss.RESULTATENREKENING': 'INCOME STATEMENT',
  'cr.annual.report.profit-and-loss.subheader': 'Base currency and unit',
  'cr.annual.report.profit.loss.subheader': 'Base currency and unit',
  'cr.annual.report.profit.loss.tooltip.BE':
    'The income statement gives an overview of the income and expenditure of the last 5 financial years.',
  'cr.annual.report.profit.loss.tooltip.GB':
    'The profit and loss account provides an overview of the income and expenditure during the financial year.',
  'cr.annual.report.profit.loss.tooltip.NL':
    'The profit and loss account provides an overview of the income and expenditure during the financial year.',
  'cr.annual.report.social-balance.BE_SB_1003':
    'Average number of employees during the financial year (FTE) (1003)',
  'cr.annual.report.social-balance.BE_SB_1013':
    'Actual number of hours performed during the financial year (1013)',
  'cr.annual.report.social-balance.BE_SB_1023': 'Personnel costs during the financial year (1023)',
  'cr.annual.report.social-balance.BE_SB_1203':
    'Male(FTE) on the closing date of the financial year (1203)',
  'cr.annual.report.social-balance.BE_SB_1213':
    'Female (FTE) on the closing date of the financial year (1213)',
  'cr.annual.report.social-balance.BE_SB_1303':
    'Management (FTE) on the closing date of the financial year (1303)',
  'cr.annual.report.social-balance.BE_SB_1323':
    'Workers (FTE) on the closing date of the financial year (1323)',
  'cr.annual.report.social-balance.BE_SB_1333':
    'Other (FTE) on the closing date of the financial year (1333)',
  'cr.annual.report.social-balance.BE_SB_1343':
    'Office staff (FTE) on the closing date of the financial year (1343)',
  'cr.annual.report.social-balance.BE_SB_1501':
    'Average number of temporary employees during the financial year (1501)',
  'cr.annual.report.social-balance.BE_SB_1511':
    'Actual number of hours performed by temporary employees during the financial year (1511)',
  'cr.annual.report.social-balance.BE_SB_1521':
    'Temporary agency costs during the financial year (1521)',
  'cr.annual.report.social-balance.header-title': 'Social balance',
  'cr.annual.report.social-balance.tooltip.BE':
    'Here you will find the breakdown of employee types and employee costs',
  'cr.annual.report.tab.balance-sheet': 'Balance sheet',
  'cr.annual.report.tab.explanatory-notes': 'Explanatory notes',
  'cr.annual.report.tab.key-figures': 'Key figures',
  'cr.annual.report.tab.profit-and-loss': 'Profit and loss',
  'cr.annual.report.tab.social-balance': 'Social balance sheet',
  'cr.archive.action.button.addToMonitoring.back': 'Back to Company Archive',
  'cr.archive.no.archived.reports.body':
    'You haven’t requested any reports yet or if you did, they are not available at the moment.<br /><br />\nIf you have questions or need help, please contact our Customer Service team at <a href="mailto:{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}">{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}</a> or on {country, select, gb {+44 (0)20 8515 1400} nl {+31 (020) 567 9501} other {+32 (03) 280 88 80}}.',
  'cr.archive.no.archived.reports.title': 'No recent actions available',
  'cr.branches-table.branch-address': 'Branch address',
  'cr.branches-table.branch-name': 'Branch name',
  'cr.branches-table.branch-number': 'Graydon branch number',
  'cr.branches-table.branch-start-date': 'Start & end date',
  'cr.branches.branch-address': 'Branch address',
  'cr.branches.branch-id': 'Branch id',
  'cr.branches.branch-name': 'Branch name',
  'cr.branches.branch-number': 'Branch number',
  'cr.branches.period.present': 'Present',
  'cr.branches.period.unknown': 'Unknown',
  'cr.branches.start-date': 'Start date',
  'cr.branches.total': 'Total',
  'cr.company-archive.reference.add': 'Add a reference',
  'cr.company-archive.reference.edit': 'Edit',
  'cr.company-archive.reference.error.message':
    'We couldn’t update your reference, please try again.',
  'cr.company-archive.reference.error.title': 'Please try again',
  'cr.company-archive.reference.form.add-new-reference': '+ Add another reference',
  'cr.company-archive.reference.form.add.cancel': 'Cancel',
  'cr.company-archive.reference.form.add.label': 'Your reference',
  'cr.company-archive.reference.form.add.placeholder': 'Enter your company reference here',
  'cr.company-archive.reference.form.add.save': 'Save',
  'cr.company-archive.reference.form.add.title': 'Add your reference',
  'cr.company-archive.reference.form.add.tooltip':
    'Adding your company reference may help you to identify this company in the future. Your company reference will be visible in any new reports you will request for this company in the future.',
  'cr.company-archive.reference.form.description': 'Specify 1 or multiple references.',
  'cr.company-archive.reference.form.edit.cancel': 'Cancel',
  'cr.company-archive.reference.form.edit.label': 'Your reference',
  'cr.company-archive.reference.form.edit.placeholder': 'Edit your company reference here',
  'cr.company-archive.reference.form.edit.save': 'Save',
  'cr.company-archive.reference.form.edit.title': 'Edit your reference(s)',
  'cr.company-archive.reference.form.edit.tooltip':
    'Your updated company reference will be visible in any new reports you will request for this company in the future.',
  'cr.company-archive.reference.form.references': 'Your reference(s)',
  'cr.company-archive.reference.label': 'Reference',
  'cr.company-archive.reference.modal.add.title': 'Add your company reference',
  'cr.company-archive.reference.placeHolder': 'Add a reference to identify the company',
  'cr.company-archive.reference.success.add.title': 'Your reference has been added',
  'cr.company-archive.reference.success.remove.title': 'Your reference has been removed',
  'cr.company-archive.reference.tooltip.add.description':
    'Adding your company reference may help you to identify this company in the future. Your company reference(s) will be visible in any new reports you will request for this company in the future.',
  'cr.company-archive.reference.tooltip.add.title': 'Add your reference(s)',
  'cr.company-archive.reference.tooltip.edit.description':
    'Your updated company reference will be visible in any new reports you will request for this company in the future.',
  'cr.company-archive.reference.tooltip.edit.title': 'Edit your reference(s)',
  'cr.company-box.branch-address': 'Address',
  'cr.company-box.branches': 'Branches',
  'cr.company-box.branches.link': 'View branches',
  'cr.company-box.graydon-number': 'Graydon number',
  'cr.company-box.registration-number-be': 'Business number',
  'cr.company-box.registration-number-gb': 'Company registration number ',
  'cr.company-box.registration-number-nl': 'Chamber of Commerce number (KvK)',
  'cr.company-box.trade-names': 'Trade names',
  'cr.company-box.trade-names.link': 'View all trade names',
  'cr.company-box.vat-number': 'VAT number',
  'cr.company.active': 'Active',
  'cr.company.branches.title': 'Branches',
  'cr.company.branches.tooltip': 'Here you will find everything about the branches of the company.',
  'cr.company.branches.tooltip.BE':
    'In this section you will find all information about the branches of the company. Each branch has a unique number that, like the enterprise number, is assigned by the CBE.',
  'cr.company.branches.tooltip.GB':
    'Here, you will find details of any company branches, if applicable.',
  'cr.company.branches.tooltip.NL':
    'In this section you will find all information about the branches of the company.',
  'cr.company.contacts.email': 'Email address',
  'cr.company.contacts.fax': 'Fax number',
  'cr.company.contacts.location': 'Location on map',
  'cr.company.contacts.mobile-number': 'Mobile number',
  'cr.company.contacts.old-address': 'Old Address',
  'cr.company.contacts.phone': 'Phone number',
  'cr.company.contacts.region': 'Region',
  'cr.company.contacts.registered-address': 'Registered Address',
  'cr.company.contacts.title': 'Company contact details',
  'cr.company.contacts.title.BE':
    'Here you will find all the contact details and trade names that are known to us. The registered address or registered office is the official legal address of a company (incorporated) or association. There can be several locations, but there is only one registered address. Usually that location is also considered as the head office.\nFor sole traderships, our official sources no longer supply registered addresses and Graydon has to rely on the provided branch addresses.\nThere is a difference between the trade name and the corporate name of a company. The trade name is the name under which a company carries out its trading activities. Sole traders ususally work with a trade name. The social name is the official legal name of a company and serves to identify the company. It has no publicity purpose, as is the case for the trade name. For legal persons, however, the trade name and the social name are often the same.',
  'cr.company.contacts.title.GB':
    'Here, you will find contact details and trade names for the company.',
  'cr.company.contacts.title.NL':
    'Here you will find all contact details and trade names that are known to us.',
  'cr.company.contacts.tooltip':
    'Here you will find all the known contact details and trade names.',
  'cr.company.contacts.tooltip.BE':
    'Here you will find all the contact details and trade names that are known to us. The registered address or registered office is the official legal address of a company (incorporated) or association. There can be several locations, but there is only one registered address. Usually that location is also considered as the head office.\nFor sole traderships, our official sources no longer supply registered addresses and Graydon has to rely on the provided branch addresses.\nThere is a difference between the trade name and the corporate name of a company. The trade name is the name under which a company carries out its trading activities. Sole traders usually work with a trade name. The social name is the official legal name of a company and serves to identify the company. It has no publicity purpose, as is the case for the trade name. For legal persons, however, the trade name and the social name are often the same.',
  'cr.company.contacts.tooltip.GB':
    'Here, you will find contact details and trade names for the company.',
  'cr.company.contacts.tooltip.NL':
    'Here you will find all contact details and trade names that are known to us.',
  'cr.company.contacts.trade.names': 'Trade names',
  'cr.company.contacts.trade.names.link': 'View all trade names',
  'cr.company.contacts.trade.names.mobile': '{value} of trade names to view in full PDF download',
  'cr.company.contacts.trading-address': 'Trading Address',
  'cr.company.contacts.website': 'Website',
  'cr.company.details.activities-description': 'Activities ({codeType} code)',
  'cr.company.details.activity-group': 'Activity group',
  'cr.company.details.activity-text': 'Activity details',
  'cr.company.details.activity-text.showAll': 'Read more details',
  'cr.company.details.actual-amount-liability': 'Actual amount liability',
  'cr.company.details.capital': 'Capital',
  'cr.company.details.capital.associationFunds': 'Association funds',
  'cr.company.details.capital.authorisedCapital': 'Social capital',
  'cr.company.details.capital.availableContribution': 'Available contribution',
  'cr.company.details.capital.contribution': 'Contribution',
  'cr.company.details.capital.foundingCapital': 'Founding capital',
  'cr.company.details.capital.issuedCapital': 'Issued capital',
  'cr.company.details.capital.paidUpCapital': 'Paid-up capital',
  'cr.company.details.capital.paidUpContribution': 'Paid up contribution',
  'cr.company.details.capital.paidUpFoundingCapital': 'Paid-up founding capital',
  'cr.company.details.capital.partnershipCapital': 'Partnership capital',
  'cr.company.details.capital.unavailableContribution': 'Unavailable contribution',
  'cr.company.details.coc-documents': 'Official documents',
  'cr.company.details.companies-house': 'Companies House',
  'cr.company.details.companies-house.view-company': 'View filing history',
  'cr.company.details.company-name': 'Company name',
  'cr.company.details.consumer-credit-check': 'Consumer credit check',
  'cr.company.details.end-date': 'End date',
  'cr.company.details.estimated-amount-liability': 'Estimated amount liability',
  'cr.company.details.export-indicator': 'Export indicator',
  'cr.company.details.founding-capital': 'Founding capital',
  'cr.company.details.governance-form': 'Governance form',
  'cr.company.details.graydon-number': 'Graydon number',
  'cr.company.details.import-indicator': 'Import indicator',
  'cr.company.details.incorporation-date': 'Incorporation date',
  'cr.company.details.known-since': 'Known since',
  'cr.company.details.legal-form': 'Legal form',
  'cr.company.details.legal-form-since': 'Legal form since',
  'cr.company.details.lei-code': 'LEI Code (Legal Entity Identifier)',
  'cr.company.details.no': 'No',
  'cr.company.details.number-of-employees': 'Number of employees',
  'cr.company.details.number-of-fte': 'Number of full time employees',
  'cr.company.details.old-graydon-number': 'Old Graydon number',
  'cr.company.details.paritair-comite': 'Joint industrial committees',
  'cr.company.details.previous-company-name': 'Previous company name',
  'cr.company.details.recognition': 'Recognition',
  'cr.company.details.recognition.agriculturalSocietyRecognition': 'Agricultural company',
  'cr.company.details.recognition.landAgencyRecognition': 'Forest ownership',
  'cr.company.details.recognition.socialRecognition': 'Social recognition',
  'cr.company.details.registration-id.BE': 'Business number',
  'cr.company.details.registration-id.GB': 'Company registration number ',
  'cr.company.details.registration-id.NL': 'Chamber of Commerce number (KvK)',
  'cr.company.details.rsz-number': 'NSSO number',
  'cr.company.details.sector': 'Sector',
  'cr.company.details.size-description': 'Company Size',
  'cr.company.details.title': 'Company details',
  'cr.company.details.tooltip':
    'In this section you will find general information about the company.',
  'cr.company.details.tooltip.BE':
    "The elements in this section will give you an overall picture of the company's profile. In Belgium, the company number is the unique number used to identify a company. It is assigned by the Crossroads Bank for Enterprises and must be listed on all official documents (e.g. invoices). The NSSO number is a number assigned by the National Social Security Office to identify employers. Each employer must submit an electronic declaration to the NSSO every three months, containing the details of all employees and the calculation of the amounts owed. Companies that do not employ personnel will not have an NSSO number.\r\nThe activity code shown here is the result of a cascade. First the activity code assigned by the VAT administration is examined, followed by the Appendices to the Belgian Official Gazette, the Crossroads Bank for Enterprises, the National Office for Social Security or by means of a survey.\r\nThe employer category, the joint committee, as well as the personnel category (a value from 0 to 9), are all provided by the National Social Security Office. The personnel category is related to the number of employees employed by a company, where personnel category 9 corresponds to more than 1,000 employees.",
  'cr.company.details.tooltip.GB':
    'In this section, you will find general information about the company.',
  'cr.company.details.tooltip.NL':
    'Here you will find all general information about the company. The Graydon number is a new and uniform number for a company that is used for the Netherlands, Belgium and the United Kingdom. The legacy Graydon number is a local Graydon number that was used previously.',
  'cr.company.details.transunion-application': 'TransUnion application',
  'cr.company.details.vat-number': 'VAT number',
  'cr.company.details.vat-status': 'VAT liable',
  'cr.company.details.vat-status.no': 'No',
  'cr.company.details.view-documents': 'View documents',
  'cr.company.details.werkgeverscategorie': "Employers' category",
  'cr.company.details.yes': 'Yes',
  'cr.company.inactive': 'Inactive',
  'cr.company.unavailable': 'Status unavailable',
  'cr.error.annualReport.natuur10.contact':
    'Due to the specific model, the annual accounts for this company cannot be processed. The original accounts can be consulted via the website of the <a href="https://www.nbb.be/en" rel="noopener noreferrer" target="_blank">National Bank of Belgium</a>. If you have questions or need help, please contact our Customer Service team at {contactInfo}.',
  'cr.error.annualReport.natuur10.tileHeading': 'Annual accounts',
  'cr.error.annualReport.natuur10.title':
    'This company has filed an exceptional type of annual accounts',
  'cr.error.contacts':
    'If this still doesn\'t work, please contact our Customer Service team at <a href="mailto:{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}">{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}</a> or on {country, select, gb {+44 (020) 8515 1400} nl {+31 (020) 567 9501} other {+32 (03) 280 88 80}}.',
  'cr.error.goback': 'Go back to search',
  'cr.error.insolvencies.no-data.body':
    'There are no insolvency cases for this company.<br /><br />If you have any questions or need help, please contact our Customer Service team at <a href="mailto:{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}">{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}</a> or on {country, select, gb {+44&nbsp;(0)20&nbsp;8515&nbsp;1400} nl {+31&nbsp;(020)&nbsp;567&nbsp;9501} other {+32&nbsp;(03)&nbsp;280&nbsp;88&nbsp;80}}.',
  'cr.error.insolvencies.no-data.title': 'No insolvency case for this company',
  'cr.error.no.annual.report.contact':
    'This company has not submitted any annual accounts in the last 5 years yet.<br /><br />If you have questions or need help, please contact our Customer Service team at <a href="mailto:{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}">{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}</a> or on {country, select, gb {+44 (020) 8515 1400} nl {+31 (020) 567 9501} other {+32 (03) 280 88 80}} for amendments.',
  'cr.error.no.annual.report.no-data-from-liability':
    'This company nor its liable company has submitted any annual accounts in the last 5 years.<br /><br />If you have questions or need help, please contact our Customer Service team at <a href="mailto:{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}">{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}</a> or on {country, select, gb {+44 (020) 8515 1400} nl {+31 (020) 567 9501} other {+32 (03) 280 88 80}} for amendments.',
  'cr.error.no.annual.report.title': 'No annual accounts available for this company',
  'cr.error.no.annual.report.title-no-data-from-liability': 'No annual accounts available',
  'cr.error.no.archived.reports.body':
    'You have not conducted any searches for this company yet or the searches are currently unavailable.<br /><br />If you have questions or need help, please contact our Customer Service team at <a href="mailto:{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}">{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}</a> or on {country, select, gb {+44 (0)20 8515 1400} nl {+31 (020) 567 9501} other {+32 (03) 280 88 80}}.',
  'cr.error.no.archived.reports.title': 'No recent actions are available for this company',
  'cr.error.no.trends.body':
    'If you have questions or need help, please contact our Customer Service team at <a href="mailto:{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}">{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}</a> or on {country, select, gb {+44 (0)20 8515 1400} nl {+31 (020) 567 9501} other {+32 (03) 280 88 80}}.',
  'cr.error.no.trends.body.condensed':
    'If you have questions or need help, please contact our Customer Service team for more information.',
  'cr.error.no.trends.title': 'No trends are available for this company',
  'cr.error.report.expired.text':
    'Sorry, the report page you’ve tried to reach is expired. Please request a new report or search for the company and download the archived report.',
  'cr.error.report.expired.title': 'This report is expired...',
  'cr.error.report.fetch.list-header': 'Please try the following',
  'cr.error.report.fetch.list-item1': "Make sure you've got the right URL",
  'cr.error.report.fetch.list-item2': 'Refresh the page',
  'cr.error.report.fetch.list-item3': 'Get back to the home and try to search for your page again',
  'cr.error.report.fetch.text':
    "Sorry, unfortunately the page you've tried to reach doesn't exist or doesn't work.",
  'cr.error.report.fetch.title': 'Something went wrong...',
  'cr.error.search': 'Something went wrong…',
  'cr.error.search.contacts':
    'Please try again later or contact our Customer Service team at <a href="mailto:{country, select, gb {customerservices@graydon.co.uk} be {support@graydon.be} other {service@graydon.nl}}">{country, select, gb {customerservices@graydon.co.uk} be {support@graydon.be} other {service@graydon.nl}}</a> or on {country, select, gb {+44&nbsp;(0)20&nbsp;8515&nbsp;1400} be {+32&nbsp;(03)&nbsp;280&nbsp;8880} other {+31&nbsp;(020)&nbsp;567&nbsp;9501}}.',
  'cr.error.search.text':
    'Unfortunately the service is unavailable and the search can’t be performed.',
  'cr.error.tryagain': 'Try again',
  'cr.financial-calamities.curators': 'With the appointment of',
  'cr.financial-calamities.description': 'Description',
  'cr.financial-calamities.preliminary': 'Preliminary',
  'cr.financial-calamities.preliminary.no': 'No',
  'cr.financial-calamities.preliminary.yes': 'Yes',
  'cr.financial-calamities.start-date': 'Date',
  'cr.financial-calamities.title': 'Insolvencies',
  'cr.financial-calamities.tooltip':
    'In this document you will find an overview of the financial incidents for this company and all related details.',
  'cr.financial-calamities.tooltip.BE':
    'In this part you will find an overview of the financial incidents for this company and all related details.',
  'cr.financial-calamities.tooltip.GB':
    'In this part you will find an overview of the financial incidents for this company and all related details.',
  'cr.financial-calamities.tooltip.NL':
    'Here you get more details about possible financial calamities from this company.',
  'cr.financials.liabilities.companyName': 'Company name',
  'cr.financials.liabilities.data-from-liability':
    'The financial information displayed below belongs to the company with limited liability which has filed a 403 declaration.',
  'cr.financials.liabilities.main':
    '{liableCompany} has assumed joint and several liability for any debts arising from legally binding transactions entered into by this company.',
  'cr.financials.liabilities.startDate': 'Start date',
  'cr.financials.liabilities.title': 'Liability',
  'cr.financials.liabilities.tooltip':
    'Here you will find general information on the liabilities of the company listed.',
  'cr.financials.liabilities.tooltip.NL':
    'Here you will find general information about the liabilities of the company. Liability is the obligation to bear the adverse consequences of an event.',
  'cr.hearder.reference': 'Reference',
  'cr.insolvency.curators': 'With the appointment of',
  'cr.insolvency.description': 'Description',
  'cr.insolvency.descriptionDetails': 'Insolvency description and details',
  'cr.insolvency.endReason': 'End insolvency',
  'cr.insolvency.eventDate': 'Date',
  'cr.insolvency.eventDetails': 'Event details',
  'cr.insolvency.forms': 'Date',
  'cr.insolvency.forms-count': 'Event details',
  'cr.insolvency.forms-hide': 'Hide form(s)',
  'cr.insolvency.no-description': 'Unknown',
  'cr.insolvency.no-details': 'No details',
  'cr.insolvency.period': 'Start and end date',
  'cr.insolvency.preliminary': 'Preliminary',
  'cr.insolvency.preliminary.no': 'No',
  'cr.insolvency.preliminary.yes': 'Yes',
  'cr.insolvency.present': 'Present',
  'cr.insolvency.startDate': 'Date',
  'cr.insolvency.table.date': 'Date',
  'cr.insolvency.table.form': 'Form',
  'cr.modal.report-fetching.header': 'Please wait while we collect the data',
  'cr.modal.report-fetching.text': 'collecting data...',
  'cr.modal.report-download-error.text.paragraph1':
    'Please wait while we download your report. This may take a short moment.\n\nIt may take a bit longer to generate your report if there is a lot of traffic on the site. Once generated, the PDF will be accessible from your company archive.',
  'cr.modal.report-download-error.text.paragraph2':
    'If the report does not show in your company archive, please contact our Customer Service Team at <a href="mailto:{country, select, gb {customerservices@graydon.co.uk} be {support@graydon.be} other {service@graydon.nl}}">{country, select, gb {customerservices@graydon.co.uk} be {support@graydon.be} other {service@graydon.nl}}</a> or on {country, select, gb {+44&nbsp;(0)20&nbsp;8515&nbsp;1400} be {+32&nbsp;(03)&nbsp;280&nbsp;8880} other {+31&nbsp;(020)&nbsp;567&nbsp;9501}}.',
  'cr.modal.report-download-error.title': 'Your report in a PDF format is being generated.',
  'cr.modal.report-error.graydon.be': 'Graydon Belgium',
  'cr.modal.report-error.graydon.gb': 'Graydon United Kingdom',
  'cr.modal.report-error.graydon.nl': 'Graydon Netherlands',
  'cr.modal.report-error.text': 'Please contact Graydon for assistance',
  'cr.modal.report-error.title': 'The data could not be retrieved',
  'cr.modal.report-creating.header': 'PLEASE WAIT WHILE WE GENERATE THE REPORT',
  'cr.modal.report-creating.text': 'Report generated...',
  'cr.modal.request-new-report.company-archive-link': 'View company',
  'cr.modal.request-new-report.open-in-new-tab': 'Open in new tab',
  'cr.modal.request-new-report.request-report-button': 'Request report',
  'cr.modal.request-new-report.text':
    "View main company details and history of actions on the company page of <a>{companyName}</a> or choose 'More' to request a new report or add the company to Monitoring.",
  'cr.modal.request-new-report.title': 'Request a report',
  'cr.nav.item.1': 'Summary',
  'cr.nav.item.2': 'Company Profile',
  'cr.nav.item.3': 'Ratings',
  'cr.nav.item.4': 'Financials',
  'cr.nav.item.5': 'Payment Behaviour',
  'cr.nav.item.6': 'Company structure',
  'cr.nav.item.7': 'Exceptional events',
  'cr.payment-behavior.no-data-error.contacts':
    'If data should be available here, please contact our Customer Service team at <a href="mailto:{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}">{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}</a> or on {country, select, gb {+44 (020) 8515 1400} nl {+31 (020) 567 9501} other {+32 (03) 280 88 80}}.',
  'cr.payment-behavior.no-data-error.message':
    'Unfortunately, there is no payment information available for this company yet. Are you doing business with this company? Share your payment experiences with us to receive a discount on Graydon products. Please contact us for more information.',
  'cr.payment-behavior.no-data-error.title': 'No payment information available',
  'cr.payment-behaviour.analysis-table.amountCategory': 'Total amount of payments',
  'cr.payment-behaviour.analysis-table.legal.text':
    'Incidental deviations in payment behaviour may be the result of disputes or specific payment agreements between supplier and the company.',
  'cr.payment-behaviour.analysis-table.numPaymentExperiences': 'Payment experiences',
  'cr.payment-behaviour.analysis-table.pctOver90DBT': 'more than 90 days',
  'cr.payment-behaviour.analysis-table.pctTo30DBT': 'between 1 and 30 days',
  'cr.payment-behaviour.analysis-table.pctTo60DBT': 'between 31 and 60 days',
  'cr.payment-behaviour.analysis-table.pctTo90DBT': 'between 61 and 90 days',
  'cr.payment-behaviour.analysis-table.pctWithinTerms': 'Within terms',
  'cr.payment-behaviour.analysis-table.quarter': 'Quarter',
  'cr.payment-behaviour.analysis-table.suppliers': 'Amount of suppliers',
  'cr.payment-behaviour.analysis-table.suppliers.text':
    'The payment information is based on the experiences of {num} {num, plural, =1 {supplier} other {suppliers}}.',
  'cr.payment-behaviour.analysis-table.title': 'Payment behaviour analysis',
  'cr.payment-behaviour.analysis-table.tooltip':
    'This overview shows in detail the number of processed payment experiences and to what extent the agreed terms\n of payment are met or exceeded. This is only an indication for the current, incomplete quarter.',
  'cr.payment-behaviour.analysis-table.tooltip.BE':
    'This table goes back to two years and shows the amount of processed payment experiences in the relevant period. In the table you will also find a breakdown of the payments that were made within the agreed period and what percentage was made after the due date. Please note, payment experiences can relate to overlooked accounts, disputes, ... The current quarter is not yet closed. The data is therefore only a first indication.',
  'cr.payment-behaviour.analysis-table.tooltip.NL':
    'This overview shows the number of processed payment experiences and to what extent the agreed payment terms are respected or exceeded.',
  'cr.payment-behaviour.ccj-details-table.amount': 'Amount (&pound;)',
  'cr.payment-behaviour.ccj-details-table.case-number': 'Case number',
  'cr.payment-behaviour.ccj-details-table.court': 'Court',
  'cr.payment-behaviour.ccj-details-table.date': 'Date',
  'cr.payment-behaviour.ccj-details-table.header': 'County Court Judgment overview',
  'cr.payment-behaviour.ccj-details-table.info':
    'Showing a maximum of 20 of the most recent County Court Judgments out of {totalCount}',
  'cr.payment-behaviour.ccj-details-table.no-data.text':
    'This company doesn’t have any County Court Judgments or the information is currently not available.<br /><br />If you have any questions or need help, please contact our Customer Service team at <a href="mailto:{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}">{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}</a> or on {country, select, gb {+44&nbsp;(0)20&nbsp;8515&nbsp;1400} nl {+31&nbsp;(020)&nbsp;567&nbsp;9501} other {+32&nbsp;(03)&nbsp;280&nbsp;88&nbsp;80}}.',
  'cr.payment-behaviour.ccj-details-table.no-data.title':
    'No County Court Judgment available for this company',
  'cr.payment-behaviour.ccj-details-table.satisfied': 'Satisfied',
  'cr.payment-behaviour.ccj-details-table.title': 'County Court Judgment details',
  'cr.payment-behaviour.ccj-details-table.tooltip':
    'You will find here the details of County Courts Judements (CCJs) made against this company.',
  'cr.payment-behaviour.ccj-details-table.tooltip.GB':
    'You will find here a summary of the County Courts Judgments (CCJs) made against this company, with the total amount and the amount that remains to be settled. Below the summary, you will find the details of the 20 most recent CCJs.',
  'cr.payment-behaviour.ccj-details-table.type': 'Type',
  'cr.payment-behaviour.ccj-summaries-table.col.last18month': 'Last 18 months',
  'cr.payment-behaviour.ccj-summaries-table.col.last18monthMonthlyAverage': 'Monthly average',
  'cr.payment-behaviour.ccj-summaries-table.col.last36month': 'Last 36 months',
  'cr.payment-behaviour.ccj-summaries-table.col.last36monthMonthlyAverage': 'Monthly average',
  'cr.payment-behaviour.ccj-summaries-table.col.last72month': 'Last 72 months',
  'cr.payment-behaviour.ccj-summaries-table.col.last72monthMonthlyAverage': 'Monthly average',
  'cr.payment-behaviour.ccj-summaries-table.header': 'County Court Judgment Summaries',
  'cr.payment-behaviour.ccj-summaries-table.row.count': 'Count',
  'cr.payment-behaviour.ccj-summaries-table.row.highestAmount': 'Highest amount',
  'cr.payment-behaviour.ccj-summaries-table.row.totalAmount': 'Total amount',
  'cr.payment-behaviour.ccj-summaries-table.row.unsettledAmount': 'Unsettled amount',
  'cr.payment-behaviour.ccj-summaries-table.sub-header': 'Summaries',
  'cr.payment-behaviour.summons-and-judgments.no-data.text':
    'This company doesn’t have any summons or the information is currently not available.<br /><br />If you have any questions or need help, please contact our Customer Service team at <a href="mailto:{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}">{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}</a> or on {country, select, gb {+44&nbsp;(0)20&nbsp;8515&nbsp;1400} nl {+31&nbsp;(020)&nbsp;567&nbsp;9501} other {+32&nbsp;(03)&nbsp;280&nbsp;88&nbsp;80}}.',
  'cr.payment-behaviour.summons-and-judgments.no-data.title':
    'No summons and judgements available for this company',
  'cr.payment.behaviour.averages.disclaimer': 'All figures refer to days beyond terms (DBT)',
  'cr.payment.behaviour.averages.th.1': 'This company',
  'cr.payment.behaviour.averages.th.2': 'Industry',
  'cr.payment.behaviour.averages.title': 'Average payment amount',
  'cr.payment.behaviour.averages.tooltip':
    'This section provides you with details about the average payment amounts made by this company againt outstanding registered charges in comparison to its sector industry.',
  'cr.payment.behaviour.averages.tooltip.GB':
    'This section provides you with details about the average payment amounts made by this company againt outstanding registered charges in comparison to its sector industry.',
  'cr.payment.behaviour.averages.tr.1': 'Last month',
  'cr.payment.behaviour.averages.tr.2': 'Last 3 months',
  'cr.payment.behaviour.averages.tr.3': 'Last 6 months',
  'cr.payment.behaviour.averages.tr.4': 'Last 12 months',
  'cr.payment.behaviour.graph.noData': 'unavailable',
  'cr.payment.behaviour.graph.noData-short': 'na',
  'cr.payment.behaviour.graph.pctOver90DBT': 'more than 90 days',
  'cr.payment.behaviour.graph.pctOver90DBT-short': '> 90',
  'cr.payment.behaviour.graph.pctTo30DBT': 'between 1 and 30 days',
  'cr.payment.behaviour.graph.pctTo30DBT-short': '1 - 30',
  'cr.payment.behaviour.graph.pctTo60DBT': 'between 31 and 60 days',
  'cr.payment.behaviour.graph.pctTo60DBT-short': '31 - 60',
  'cr.payment.behaviour.graph.pctTo90DBT': 'between 61 and 90 days',
  'cr.payment.behaviour.graph.pctTo90DBT-short': '61 - 90',
  'cr.payment.behaviour.graph.pctWithinTerms': 'within terms',
  'cr.payment.behaviour.graph.pctWithinTerms-short': 'Within terms',
  'cr.payment.behaviour.graph.quarter': 'Quarter',
  'cr.payment.behaviour.graph.title': 'Payment behaviour for the last 4 quarters',
  'cr.payment.behaviour.graph.tooltip':
    "This graph indicates whether a company pays within terms. Please note that this is NOT an indication of creditworthiness, but of the company's payment behaviour.",
  'cr.payment.behaviour.graph.tooltip.BE':
    'From the graphical representation of the payment behavior over the last 4 quarters - divided into a period within the period and different segments outside the period - you can quickly deduce whether payment behavior is evolving in a positive or negative sense.',
  'cr.payment.behaviour.graph.tooltip.NL':
    'Here you can see the payment behavior of the past four quarters and the extent to which the agreed payment terms are respected or exceeded.',
  'cr.payment.behaviour.mortgages.dateOfLastFullSatisfaction':
    'Date of last registered charge fully satisfied',
  'cr.payment.behaviour.mortgages.dateOfLastMortgage': 'Date of last registered charge created',
  'cr.payment.behaviour.mortgages.dateOfLastPartialSatisfaction':
    'Date of partially satisfied charges',
  'cr.payment.behaviour.mortgages.numberFullySatisfied': 'Number of charges satisfied',
  'cr.payment.behaviour.mortgages.numberPartiallySatisfied':
    'Number of charges partially satisfied',
  'cr.payment.behaviour.mortgages.title': 'Registered charges',
  'cr.payment.behaviour.mortgages.tooltip':
    'This section details the most recent registered charges and satisfactions. Please note that there is no legal requirement to submit a statement of satisfaction at Companies House.',
  'cr.payment.behaviour.mortgages.tooltip.GB':
    'This section details the most recent registered charges and satisfactions. Please note that there is no legal requirement to file satisfaction details of registered charges at Companies House.',
  'cr.payment.behaviour.mortgages.totalNumberOfMortgages':
    'Total of mortgages / registered charges',
  'cr.payment.behaviour.payment.score.trend.disclaimer':
    'This section gives you an indication of the trend followed by the Payment Score for this company over the past 12 months.',
  'cr.payment.behaviour.payment.score.trend.title': 'Payment score’s trend',
  'cr.payment.behaviour.payment.score.trend.tooltip.BE':
    'This section gives you an indication of the trend followed by the Payment Score for this company over the past 12 months.',
  'cr.payment.behaviour.payment.score.trend.tooltip.GB':
    'This section gives you an indication of the trend followed by the Payment Score for this company over the past 12 months.',
  'cr.payment.behaviour.payment.score.trend.tooltip.NL':
    'This section gives you an indication of the trend followed by the Payment Score for this company over the past 12 months.',
  'cr.payment.behaviour.payment.trend.disclaimer':
    'All figures refer to days beyond terms from {value} experiences (DBT).',
  'cr.payment.behaviour.payment.trend.title': 'Days Beyond Terms Trend',
  'cr.payment.behaviour.payment.trend.tooltip':
    'This section indicates the payment trends of this company, refering to their payments made with days beyond terms (DBT).',
  'cr.payment.behaviour.payment.trend.tooltip.BE':
    'From the graphical representation of the payment behavior over the last 4 quarters - divided into a period within the period and different segments outside the period - you can quickly deduce whether payment behavior is evolving in a positive or negative sense.',
  'cr.payment.behaviour.payment.trend.tooltip.GB':
    'This section indicates the payment trends of this company, refering to their payments made with days beyond terms (DBT).',
  'cr.payment.behaviour.payment.trend.tooltip.NL':
    'Here you can see the payment behavior of the past four quarters and the extent to which the agreed payment terms are respected or exceeded.',
  'cr.payment.behaviour.registeredCharges.andOthers': 'and others.',
  'cr.payment.behaviour.registeredCharges.dateCreated': 'Date created',
  'cr.payment.behaviour.registeredCharges.dateRegistered': 'Date registered',
  'cr.payment.behaviour.registeredCharges.dateSatisfied': 'Date fully satisfied',
  'cr.payment.behaviour.registeredCharges.description': 'Charge description',
  'cr.payment.behaviour.registeredCharges.disclaimer':
    'Showing a maximum of the 20 most recent registered charges out of {totalCount}',
  'cr.payment.behaviour.registeredCharges.lender': 'Lender',
  'cr.payment.behaviour.registeredCharges.no-data-text':
    'This company does not have any registered charges or there is no information available.<br/><br/>If you have any questions or need help, please contact our Customer Service team at <a href="mailto:{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}">{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}</a> or on {country, select, gb {+44&nbsp;(0)20&nbsp;8515&nbsp;1400} nl {+31&nbsp;(020)&nbsp;567&nbsp;9501} other {+32&nbsp;(03)&nbsp;280&nbsp;88&nbsp;80}}.',
  'cr.payment.behaviour.registeredCharges.no-data-title':
    'This company does not have any registered charges.',
  'cr.payment.behaviour.registeredCharges.summary.current': 'Current value',
  'cr.payment.behaviour.registeredCharges.summary.title': 'Registered charges summary',
  'cr.payment.behaviour.registeredCharges.summary.totals': 'Totals',
  'cr.payment.behaviour.registeredCharges.title': 'Registered charges',
  'cr.payment.behaviour.summons-and-judgments.date': 'Date',
  'cr.payment.behaviour.summons-and-judgments.description': 'Description',
  'cr.payment.behaviour.summons-and-judgments.info':
    'Showing a maximum of the most recent 20 summons and judgements out of {totalCount}',
  'cr.payment.behaviour.summons-and-judgments.InsuranceAndSelfEmployed':
    'Summons of the social insurance fund for self-employed persons',
  'cr.payment.behaviour.summons-and-judgments.RSZ': 'NSSO',
  'cr.payment.behaviour.summons-and-judgments.SocialSecurityFund':
    'Summons of the social security fund',
  'cr.payment.behaviour.summons-and-judgments.title': 'Summons',
  'cr.payment.behaviour.summons-and-judgments.tooltip':
    "The Court's Order can be the results of overlooked accounts, ligations, etc.",
  'cr.payment.behaviour.summons-and-judgments.tooltip.BE':
    'In this section you will find an overview of the summons issued by the National Social Security Office, the Social Insurance Fund for the Self-Employed, or the Social Security Fund, including the hearing date. Please note that the summons issued may be the result of overlooked bills, disputes, etc. That is why each company will receive a letter requesting more information about the summons in question.',
  'cr.payment.behaviour.summons-and-judgments.tooltip.NL':
    'Please note that the summons issued may be the result of overlooked bills, disputes, etc. ',
  'cr.payment.behaviour.unsecuredCreditors.amount': 'Amount',
  'cr.payment.behaviour.unsecuredCreditors.companyName': 'Company name',
  'cr.payment.behaviour.unsecuredCreditors.disclaimer':
    'Showing a maximum of the 20 highest claims out of {totalCount}',
  'cr.payment.behaviour.unsecuredCreditors.no-data-text':
    'This company has not listed any unsecured creditors  or there is no information filed.<br/><br/>If you have any questions or need help, please contact our Customer Service team at <a href="mailto:{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}">{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}</a> or on {country, select, gb {+44&nbsp;(0)20&nbsp;8515&nbsp;1400} nl {+31&nbsp;(020)&nbsp;567&nbsp;9501} other {+32&nbsp;(03)&nbsp;280&nbsp;88&nbsp;80}}.',
  'cr.payment.behaviour.unsecuredCreditors.no-data-title':
    'No unsecured creditors listed for this company.',
  'cr.payment.behaviour.unsecuredCreditors.statementDate': 'Statement date',
  'cr.payment.behaviour.unsecuredCreditors.summary': 'Unsecured creditors summary',
  'cr.payment.behaviour.unsecuredCreditors.tabTitle': 'Unsecured creditors',
  'cr.payment.behaviour.unsecuredCreditors.tooltip':
    'This list includes the unsecured creditors as reported on statement date',
  'cr.payment.behaviour.unsecuredCreditors.totalNumber': 'Total number of unsecured creditors',
  'cr.payment.behaviour.unsecuredCreditors.totalValue': 'Total value of unsecured debt',
  'cr.payment.behaviour.unsecuredCreditors.unknown': 'Unknown',
  'cr.pdf-archive.available.actions': 'Perform any of these actions for this company',
  'cr.pdf-archive.download.button.title': 'Download PDF',
  'cr.pdf-archive.download.button.title.en': 'English PDF',
  'cr.pdf-archive.download.button.title.fr': 'French PDF',
  'cr.pdf-archive.download.button.title.nl': 'Dutch PDF',
  'cr.pdf-archive.item.title': 'Credit report',
  'cr.pdf-archive.item.button.no-avail': 'Download is not available anymore',
  'cr.pdf-archive.max.reports.message':
    'Only the 50 most recent actions are displayed.<br /><br />If you have any questions, please contact our Customer Service team at <a href="mailto:{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}">{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}</a> or on {country, select, gb {+44 (020) 8515 1400} nl {+31 (020) 567 9501} other {+32 (03) 280 88 80}}.',
  'cr.pdf-archive.previous.actions': 'Previous requests',
  'cr.pdf-archive.title': 'Actions for this company',
  'cr.preferences': 'Preferences',
  'cr.preferences.cancel-button': 'Cancel',
  'cr.preferences.default-list.no-list-message.part.one':
    'To be able to use this option you would need to have at least one list created. ',
  'cr.preferences.default-list.no-list-message.part.two':
    'You can setup lists in the List Management App.',
  'cr.preferences.default.list-preference.description':
    'By activating this option, every credit report you request for a company will be automatically added to your default list. You can use a list to monitor companies, or for data enrichment or for exports.\n\nIf you prefer to choose each time to which list to add the company to monitor, simply leave this option deactivated.',
  'cr.preferences.default.list-select.list.title': 'Select a list',
  'cr.preferences.default.list-title': 'View report and add company to list',
  'cr.preferences.default.list-toggle.label': 'Add company to default list',
  'cr.preferences.default.list-toggle.title': 'Activate default list option',
  'cr.preferences.default.list-tooltip':
    'Via these preference settings, each time you request a credit report, the company will be automatically added to your list by default.',
  'cr.preferences.default.list.select.list-monitoring.info':
    'This list has <b>{numberOfProfilesAttachedToSelectedList}</b> monitoring profiles.',
  'cr.preferences.default.list.select.list-monitoring.info.tooltip.body':
    'If a list is connected to a monitoring profile, you will receive updates when data changes in the company. You can connect a list to a monitoring profile in the Monitoring App.',
  'cr.preferences.default.list.select.list-monitoring.info.tooltip.title': 'Monitoring profiles',
  'cr.preferences.save-button': 'Save view report preferences',
  'cr.preferences.title': 'Preferences',
  'cr.preferences.tosti.error.body':
    'There seems to be a unexpected error, please refresh or try again later.',
  'cr.preferences.tosti.error.title': 'Something went wrong',
  'cr.preferences.tosti.success.body':
    'Every new request of a credit report for a company will add this company to the selected list.',
  'cr.preferences.tosti.success.title': 'Your preferences have been updated',
  'cr.ratings.credit-rating.0': 'Financial Strength is less than £1',
  'cr.ratings.credit-rating.A': 'Excellent overall risk level',
  'cr.ratings.credit-rating.B': 'Good overall risk level',
  'cr.ratings.credit-rating.C': 'Fair overall risk level',
  'cr.ratings.credit-rating.D': 'Poor overall risk level',
  'cr.ratings.credit-rating.description':
    'This rating determines the creditworthiness based on financial health as well as specific sector information. ',
  'cr.ratings.credit-rating.N': 'Newly incorporated company',
  'cr.ratings.credit-rating.na': 'Not available',
  'cr.ratings.credit-rating.NA': 'Not available',
  'cr.ratings.credit-rating.NIL': 'No filed accounts or negative information',
  'cr.ratings.credit-rating.P': 'Parental link',
  'cr.ratings.credit-rating.S': 'Special rating',
  'cr.ratings.credit-rating.SN': 'Special rating',
  'cr.ratings.credit-rating.title': 'Credit Rating',
  'cr.ratings.credit-rating.tooltip':
    'The Credit Rating is an alpha numeric rating read in conjunction with a credit guide figure. It is based on all the information and data on our database, and it summarises our overall opinion on your subject of enquiry.\n',
  'cr.ratings.credit-rating.tooltip.BE':
    'The Graydon Rating shows the degree of credit risk. The AAA rating is the highest rating and indicates the lowest possible risk. The C rating is the weakest rating and points to a very high risk. Companies with a rating D are in a state of bankruptcy. NR means that a rating could not be established because the company is passive. The chance of bankruptcy indicates how great the risk is that the company encounters serious financial problems and goes bankrupt. The lower the percentage, the lower the risk.',
  'cr.ratings.credit-rating.tooltip.GB':
    'Please note: To benefit from the latest risk model please refer to the Graydon Rating or Augur Score. The Credit Rating will be discontinued at the end of September 2021. Please contact your Account Manager for more information.\n\nGraydon’s alphanumeric credit rating is based on all the information and data Graydon has in its databases. It summarises Graydon’s overall opinion and credit guide figure based on the consideration of the behavioural and recent financial performance of the company you are enquiring about (A – Excellent; B – Good; C – Fair; D – Poor).',
  'cr.ratings.credit-rating.tooltip.NL':
    'The Graydon Rating shows the degree of credit risk, with AAA rating being the highest possible rating and C being the lowest. D indicates that the company is in a state of bankruptcy. NR indicates that no rating could be set because the company is passive. The chance of bankruptcy provides insight into the risk that the company will experience serious financial problems. The higher the percentage, the greater the risk.',
  'cr.ratings.credit-rating.X': 'X-rated',
  'cr.ratings.credit.flag.description': 'The recommended credit limit for this company.',
  'cr.ratings.credit.flag.information.G': 'Creditworthy',
  'cr.ratings.credit.flag.information.O': 'Credit facility maybe given but should be monitored',
  'cr.ratings.credit.flag.information.R': 'Credit facility is not advised',
  'cr.ratings.credit.flag.information.text':
    'This assessment is based on the Graydon Credit Limit and the Graydon Rating.',
  'cr.ratings.credit.flag.limit.tooltip':
    '**Credit flag**\n\nThe credit flag acts as a traffic light, and in short is a simple translation of the credit advice and the Graydon Rating. **[Green](#green)** = lending does not cause any problems. **[Orange](#orange)** = granting credit is associated with an increased risk. **[Red](#red)** = Graydon advises against pre-financing.\n\n\n**Credit limit**\n\nThe credit limit indicates the amount up to which you can do business with a company. The credit limit forms a value judgment in the short term.\n',
  'cr.ratings.credit.flag.maximum-limit': 'Maximum limit',
  'cr.ratings.credit.flag.monthly-credit-guide': 'Monthly credit guide',
  'cr.ratings.credit.flag.title': 'Credit flag & credit limit',
  'cr.ratings.credit.flag.tooltip':
    'The credit limit indicates how much cash a company can pay in the short term to pay its outstanding debts.',
  'cr.ratings.credit.flag.tooltip.BE':
    'The credit flag shows in a simple way to what extent it is safe to grant credit to a company. Green = granting credit does not cause any problems. Orange = granting credit is associated with an increased risk. Red = Graydon discourages credit to provide.\nThe credit limit specifies the maximum amount of cash that a company can release in the short term to pay off outstanding debts. ',
  'cr.ratings.credit.flag.tooltip.GB':
    'The credit flag acts as a traffic light. Green = granting credit is unlikely to cause problems. Orange = granting credit is associated with an increased risk. Red = Graydon advises against providing credit. The credit limit indicates the amount up to which you can do business with a company. The credit limit is a value judgment in the short term.',
  'cr.ratings.credit.limit.trend.title': "Credit limit's trend",
  'cr.ratings.credit.limit.trend.tooltip':
    'This section presents the trend of the company’s recommended credit limit over the past 12 months.',
  'cr.ratings.delphi.index.1': 'Delphi index 1, increased risk',
  'cr.ratings.delphi.index.2': 'Delphi index 2, increased risk',
  'cr.ratings.delphi.index.3': 'Delphi index 3, increased risk',
  'cr.ratings.delphi.index.4': 'Delphi index 4, average risk',
  'cr.ratings.delphi.index.5': 'Delphi index 5, average risk',
  'cr.ratings.delphi.index.6': 'Delphi index 6, average risk',
  'cr.ratings.delphi.index.7': 'Delphi index 7, low risk',
  'cr.ratings.delphi.index.8': 'Delphi index 8, low risk',
  'cr.ratings.delphi.index.9': 'Delphi index 9, low risk',
  'cr.ratings.delphi.index.personal.credit.information': 'Personal credit indication',
  'cr.ratings.delphi.index.tooltip':
    '**Credit flag**\n\nThe credit flag acts as a traffic light, and in short is a simple translation of the credit advice and the Graydon Rating. **[Green](#green)** = lending does not cause any problems. **[Orange](#orange)** = granting credit is associated with an increased risk. **[Red](#red)** = Graydon advises against pre-financing.\n\n\n**Credit limit**\n\nThe credit limit indicates the amount up to which you can do business with a company. The credit limit forms a value judgment in the short term.\n\n\n**Personal credit indication**\n\nThe Delphi index is a personal credit indication for a natural person, taking account of negative registrations with for instance bailiffs, debt collection agencies etc.\nThe higher the Delphi Index, the lower the risk level is. The Delphi index is drawn up by Experian.',
  'cr.ratings.discontinuation-score.1': 'Very high',
  'cr.ratings.discontinuation-score.2': 'High',
  'cr.ratings.discontinuation-score.3': 'Neutral',
  'cr.ratings.discontinuation-score.4': 'Low',
  'cr.ratings.discontinuation-score.5': 'Very low',
  'cr.ratings.discontinuation-score.description':
    'The likelihood this company is going to stop operating within the next 12 months.',
  'cr.ratings.discontinuation-score.NA': 'Not available ',
  'cr.ratings.discontinuation-score.NR': 'Not rated',
  'cr.ratings.discontinuation-score.title': 'Probability of discontinuation',
  'cr.ratings.discontinuation-score.tooltip':
    'The probability of discontinuation score predicts the likelihood a company is to stop operating within the next 12 months (also takes into consideration the probability of bankruptcy and liquidation, take-over and mergers).',
  'cr.ratings.discontinuation-score.tooltip.BE':
    'The chance of cancellation predicts how likely it is that a company will cease to exist in the coming 12 months. The score does not look at the chance of bankruptcy or LCE (Law on the continuity of enterprises), but at the chance of dissolution, merger, takeover and termination.',
  'cr.ratings.discontinuation-score.tooltip.NL':
    'The chance of cancellation predicts how likely it is that a company will cease to exist in the coming 12 months.',
  'cr.ratings.graydon.rating.trend.title': "Graydon Rating's trend",
  'cr.ratings.graydon.rating.trend.tooltip':
    'This section shows the pattern followed by the Graydon Rating for this company, over the past 12 months.',
  'cr.ratings.growth-score.1': 'Rapid decline',
  'cr.ratings.growth-score.2': 'Decline',
  'cr.ratings.growth-score.3': 'Neutral',
  'cr.ratings.growth-score.4': 'Growth',
  'cr.ratings.growth-score.5': 'Strong growth',
  'cr.ratings.growth-score.99': 'Not predictable',
  'cr.ratings.growth-score.description':
    "The company's likelihood to grow their total assets within the next 12 months.",
  'cr.ratings.growth-score.NA': 'Not available ',
  'cr.ratings.growth-score.NR': 'Not rated',
  'cr.ratings.growth-score.title': 'Expected growth',
  'cr.ratings.growth-score.tooltip':
    'The Growth Score offers an indication about the organic growth potential of a company under normal economic conditions. It looks at the extent to which a company can finance its own growth, without having to immediately call on external capital. \nPlease note, during the Coronavirus pandemic, we are no longer operating in normal economic conditions, so please be advised that the growth forecast could deviate from reality.\n',
  'cr.ratings.growth-score.tooltip.BE':
    'The expected growth gives an indication of the potential growth opportunities of a company for the coming 12 months under normal economic conditions. It looks at the extent to which a company can finance its own growth, without having to immediately call on external capital. The expected growth is only available for companies that publish financial statements. The indication ‘not predictable’ means the expected growth is volatile of strong variable.\r\nPlease note, during the Coronavirus pandemic, we are no longer operating in normal economic conditions, so please be advised that the growth forecast could deviate from reality.',
  'cr.ratings.growth-score.tooltip.GB':
    'This is a targeted forecast of the expected growth of the total assets of the company in the next 12 months under normal economic conditions. The indication ‘not predictable’ means the expected growth is volatile of strong variable.\nPlease note, during the Coronavirus pandemic, we are no longer operating in normal economic conditions, so please be advised that the growth forecast could deviate from reality.',
  'cr.ratings.growth-score.tooltip.NL':
    'The expected growth makes a targeted forecast of the growth of the total assets of companies and branches in the coming twelve months under normal economic conditions. The indication ‘not predictable’ means the expected growth is volatile of strong variable.\nPlease note, during the Coronavirus pandemic, we are no longer operating in normal economic conditions, so please be advised that the growth forecast could deviate from reality.',
  'cr.ratings.no.delphi.index': 'No Delphi index retrieved',
  'cr.ratings.payment-score.description': 'Probability of paying invoices within terms.',
  'cr.ratings.payment-score.lower-bound': 'Payment terms are not being met',
  'cr.ratings.payment-score.NR': 'Not rated',
  'cr.ratings.payment-score.range-sector-1': 'Payment terms are not being met',
  'cr.ratings.payment-score.range-sector-2': 'Below average',
  'cr.ratings.payment-score.range-sector-3': 'Average',
  'cr.ratings.payment-score.range-sector-4': 'Good payment behaviour',
  'cr.ratings.payment-score.title': 'Payment score',
  'cr.ratings.payment-score.tooltip':
    'The payment score and the payment behaviour indicate the likelihood of payment AND within terms from the company you are looking up.',
  'cr.ratings.payment-score.tooltip.BE':
    'The payment score tells you how correctly your customer respects the agreed payment term.\nNote the difference with the credit limit, which indicates whether your customer has the capacity to pay his suppliers in the short term. The credit limit tells whether your customer can pay. The payment score indicates whether the customer will actually do that. The higher the payment score (on a scale of 0 to 10), the better the payment behavior.\nThe payment score is the result of an exchange project in which thousands of suppliers share millions of payment experiences. Discreet and anonymous. All participants identify weak payers in this way, but also reward well-paying customers. Participating in the Graydon Data Exchange Pooling exchange project costs you nothing. On the contrary, you show your collegiality and you receive a commercial allowance in exchange for your delivery. Contact us for more information. ',
  'cr.ratings.payment-score.tooltip.GB':
    'The payment score indicates the extent to which an organisation pays its invoices on time. The following rule applies here: the higher the payment score, the better the company pays its invoices on time.',
  'cr.ratings.payment-score.tooltip.NL':
    'The payment score indicates to what extent an organisation pays its invoices on time. The following applies here: the higher the payment score, the better the company pays its invoices on time.',
  'cr.ratings.payment-score.upper-bound': 'Good payment behaviour',
  'cr.ratings.rating.augur-rating': '(Augur score: { augurRating })',
  'cr.ratings.rating.description': 'Percentage likelihood of failure',
  'cr.ratings.rating.information.A': 'Probability of default is low',
  'cr.ratings.rating.information.AA': 'Probability of default is low',
  'cr.ratings.rating.information.AAA': 'Probability of default is very low',
  'cr.ratings.rating.information.B': 'Probability of default is neutral',
  'cr.ratings.rating.information.BB': 'Probability of default is neutral',
  'cr.ratings.rating.information.BBB': 'Probability of default is neutral',
  'cr.ratings.rating.information.C': 'Probability of default is very high',
  'cr.ratings.rating.information.CC': 'Probability of default is higher than average',
  'cr.ratings.rating.information.CCC': 'Probability of default is higher than average',
  'cr.ratings.rating.information.D': 'The company is insolvent',
  'cr.ratings.rating.information.NA': 'The Probability of default cannot be determined.',
  'cr.ratings.rating.information.NR': 'The company is not rated',
  'cr.ratings.rating.information.text':
    'This rating is based on sector specific information; available annual reports and payment behaviour.',
  'cr.ratings.rating.multiscore': '(Multiscore: { multiscore })',
  'cr.ratings.rating.placeholder':
    "Graydon's calculation of the likelihood of business failure within the next 12 months",
  'cr.ratings.rating.title': 'multiscore & percentage likelihood of failure',
  'cr.ratings.rating.tooltip':
    'Learn more about the probability of default and the Graydon Rating here',
  'cr.ratings.rating.tooltip.BE':
    'The Graydon Rating shows the degree of credit risk. The AAA rating is the highest rating and indicates the lowest possible risk. The C rating is the weakest rating and points to a very high risk. Companies with a rating D are in a state of bankruptcy. NR means that a rating could not be established because the company is passive. The chance of bankruptcy indicates how great the risk is that the company encounters serious financial problems and goes bankrupt. The lower the percentage, the lower the risk.',
  'cr.ratings.rating.tooltip.GB':
    'The Graydon Rating shows the degree of credit risk, with AAA rating being the highest possible rating and C being the lowest. D indicates that the customer is in a state of bankruptcy. NR indicates that no rating could be set because the company is passive. The chance of bankruptcy provides insight into the risk that the company will experience serious financial problems. The higher the percentage, the greater the risk. The label NAN – for Not A Number – means that no Augur score is available.',
  'cr.ratings.rating.tooltip.NL':
    'The Graydon Rating shows the degree of credit risk, with AAA rating being the highest possible rating and C being the lowest. D indicates that the company is in a state of bankruptcy. NR indicates that no rating could be set because the company is passive. The chance of bankruptcy provides insight into the risk that the company will experience serious financial problems. The higher the percentage, the greater the risk.',
  'cr.ratings.trends.tile.title': 'Trends ',
  'cr.search-gb.fresh.investigation.disclaimer':
    'Investigations on incorporated and unincorporated companies can be requested through the Fresh Investigation button at the top of this page.',
  'cr.search.annual-account': 'Annual accounts',
  'cr.search.coc-extract': 'Trade register extract',
  'cr.search.credit-report': 'Credit Report',
  'cr.search.gdm-check': 'Decision Model check',
  'cr.search.international-credit-report': 'International Credit Report',
  'cr.search.more': 'more',
  'cr.search.results.no.data.link-text': 'please request a Fresh Investigation.',
  'cr.search.results.no.data.main-text': 'If you are looking for an unincorporated company, please',
  'cr.search.view-company-archive': 'View company',
  'cr.search.view-raw-data': 'View data',
  'cr.search.viewReport': 'View report',
  'cr.search.xseptions.report': 'Network incl. XSeptions',
  'cr.specifics.events.category': 'Category',
  'cr.specifics.events.date': 'Date',
  'cr.specifics.events.description':
    'Here is the list and description of events that occurred for this company.',
  'cr.specifics.events.description.BE':
    'Here you will find an overview of all deeds that are published in the Annexes of the Belgian Official Gazette, including a brief description of the content.',
  'cr.specifics.events.description.GB':
    'Here is the list and description of events that have occurred involving this company.',
  'cr.specifics.events.description.history.BE':
    'Here you will find an overview of all deeds, containing updates of amounts, company names, addresses etc. that are published in the Annexes of the Belgian Official Gazette, including a brief description of the content.',
  'cr.specifics.events.disclaimer': 'Showing a maximum of 50 of the most recent events',
  'cr.specifics.events.disclaimer.BE':
    'Showing a maximum of 50 of the most recent history and other events together',
  'cr.specifics.events.event-description': 'Event description - Details',
  'cr.specifics.events.event-description.BE': 'Event description',
  'cr.specifics.events.event-description.hideDetails': 'Hide event details',
  'cr.specifics.events.event-description.showAll': 'Read more',
  'cr.specifics.events.eventReference': 'Statute number BBS/AMB',
  'cr.specifics.events.no-data.text':
    'This company doesn’t have any events or there is no information available.<br /><br />If you have questions or need help, please contact our Customer Service team at <a href="mailto:{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}">{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}</a> or on {country, select, gb {+44&nbsp;(0)20&nbsp;8515&nbsp;1400} nl {+31&nbsp;(020)&nbsp;567&nbsp;9501} other {+32&nbsp;(03)&nbsp;280&nbsp;88&nbsp;80}}.',
  'cr.specifics.events.no-data.title': 'No events are available for this company',
  'cr.specifics.events.source-description': 'Source',
  'cr.specifics.financial-insolvencies.title': 'Insolvencies {extra}',
  'cr.specifics.financial-insolvencies.tooltip':
    'Find here more details related to the insolvencies.',
  'cr.specifics.financial-insolvencies.tooltip.BE':
    'In this document you will find an overview of the financial incidents for this company and all related details.',
  'cr.specifics.financial-insolvencies.tooltip.GB':
    'Here, you will find details of any insolvency action involving the company sourced from Companies House or the London, Edinburgh or Belfast Gazette.',
  'cr.specifics.financial-insolvencies.tooltip.NL':
    'Here you get more details about possible financial calamities from this company.',
  'cr.specifics.tab.events': 'Events {extra}',
  'cr.specifics.tab.events.BE': 'History {extra}',
  'cr.specifics.tab.xseptions.title': 'XSeptions {extra}',
  'cr.specifics.tab.xseptions.title.BE': 'XSeptions {extra}',
  'cr.specifics.tab.xseptions.title.GB': 'XSeptions {extra}',
  'cr.specifics.tab.xseptions.title.NL': 'XSeptions {extra}',
  'cr.specifics.xseptions.category': 'Category',
  'cr.specifics.xseptions.date': 'Date',
  'cr.specifics.xseptions.description': 'Description',
  'cr.specifics.xseptions.description.BE':
    'XSeptions screens first and second-degree relationships (companies and/or directors) for remarkable situations. This includes mention on sanction lists, opaque company structures or recent bankruptcies of the director.',
  'cr.specifics.xseptions.description.GB':
    'Here are the details of XSeptions for this company. Please note that an XSeption is only recorded when unusual patterns of behaviour or financial results have been identified.',
  'cr.specifics.xseptions.description.NL':
    'XSeptions screens first and second-degree relationships (companies and/ or directors) for remarkable situations. This includes opaque company structures or recent bankruptcies of the director.',
  'cr.specifics.xseptions.no-data.text':
    'There are currently no XSeptions for this business. An XSeption is recorded when unusual patterns of behaviour or financial results have been identified.<br /><br />If you have any questions or need help, please contact our Customer Service team at <a href="mailto:{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}">{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}</a> or on {country, select, gb {+44&nbsp;(0)20&nbsp;8515&nbsp;1400} nl {+31&nbsp;(020)&nbsp;567&nbsp;9501} other {+32&nbsp;(03)&nbsp;280&nbsp;88&nbsp;80}}.',
  'cr.specifics.xseptions.no-data.text.condensed':
    'An XSeption is recorded when unusual patterns of behaviour or financial results have been identified. There are presently no XSeptions for this business.',
  'cr.specifics.xseptions.no-data.title': 'No XSeptions available for this company',
  'cr.specifics.xseptions.start-date': 'Start date',
  'cr.specifics.xseptions.uk-description.GB_1':
    'The Subject has changed its registered address {value1} times in the period {date1} to {date2}.',
  'cr.specifics.xseptions.uk-description.GB_2':
    'The subject filed two sets of accounts {value1} days apart. Accounts were filed on {date1} and then on {date2}.',
  'cr.specifics.xseptions.uk-description.GB_3':
    'The subjects {date1} accounts appear to be identical to those filed by company registration number {text} for {date2}.',
  'cr.specifics.xseptions.uk-description.GB_4':
    'The subject has filed their first set of accounts on {date2} after being incorporated on {date1}',
  'cr.specifics.xseptions.uk-description.GB_5':
    '{value1} directors resigned from the subject in the same day {date1}.',
  'cr.specifics.xseptions.uk-description.GB_6':
    'Industry Intelligence of potential critical importance has been gathered on the subject.',
  'cr.specifics.xseptions.uk-description.GB_7':
    'The subject filed its latest accounts very soon after year end date. Latest financial year end {date2} were filed on {date1}.',
  'cr.specifics.xseptions.uk-description.GB_8':
    'The subject appointed a director on {date1}. who was associated with {value1} company/companies that recently experienced insolvency action.',
  'cr.specifics.xseptions.uk-description.GB_9': 'Two years no balance sheet',
  'cr.summary.company.details.activitiesDescription': 'Activities ({codeType} code)',
  'cr.summary.company.details.email': 'Email address',
  'cr.summary.company.details.graydonNumber': 'Graydon number',
  'cr.summary.company.details.incorporationDate': 'Incorporation date',
  'cr.summary.company.details.legalForm': 'Legal form',
  'cr.summary.company.details.numberOfBranches': 'Number of branches',
  'cr.summary.company.details.phoneNumber': 'Telephone number',
  'cr.summary.company.details.registrationId': 'Registration ID',
  'cr.summary.company.details.registrationId.BE': 'Business number',
  'cr.summary.company.details.registrationId.GB': 'Company registration number ',
  'cr.summary.company.details.registrationId.NL': 'Chamber of Commerce number (KvK)',
  'cr.summary.company.details.title': 'Company details',
  'cr.summary.company.details.tooltip.BE':
    'Here you will find the most important basic information about the company. Open the overview for more details.',
  'cr.summary.company.details.tooltip.GB':
    'Here, you will find an overview of the company you requested.',
  'cr.summary.company.details.tooltip.NL':
    'Here you will find all basic information about the company you requested.',
  'cr.summary.company.details.tradingAddress': 'Trading Address',
  'cr.summary.company.details.vatNumber': 'VAT number',
  'cr.summary.company.details.website': 'Website',
  'cr.summary.company.structure.title': 'Company structure',
  'cr.summary.company.structure.tooltip.BE':
    'In this section you will find more information about the company structure, including the (ultimate) parent company. Open the overview to consult the managers, the shareholders or the participations.',
  'cr.summary.company.structure.tooltip.GB':
    'Here, you will find more information about the company structure. For example, any parent companies.',
  'cr.summary.company.structure.tooltip.NL':
    'Here you will find more information about the company structure of the company you requested. For example, you can see the parent company here.',
  'cr.summary.key.figures.title': 'Key figures',
  'cr.summary.key.figures.tooltip': 'Some key figures related information ...',
  'cr.summary.key.figures.tooltip.BE':
    'Here you get a brief overview of the most important figures from the most recent annual accounts and a number of financial ratios. Open the overview for more key figures. Or to consult the balance sheet and income statement for the last 5 financial years.',
  'cr.summary.key.figures.tooltip.GB':
    'Here, you will find key figures for the company, such as working capital and equity capital, as well as financial ratios.',
  'cr.summary.key.figures.tooltip.NL':
    'Here you will find key figures for the company, such as working capital and equity capital. Financial ratios, such as the current ratio.',
  'cr.summary.link.company.profile': 'Company profile overview >',
  'cr.summary.link.company.structure': 'Company structure overview >',
  'cr.summary.link.financials': 'Financials overview >',
  'cr.summary.link.payment-behaviour': 'Payment behaviour overview >',
  'cr.summary.link.ratings': 'Ratings overview >',
  'cr.summary.link.xseptions': 'Exceptional events overview >',
  'cr.summary.link.xseptions.date-unknown': 'Unknown date',
  'cr.summary.news': 'News',
  'cr.summary.news.no-data.text':
    'There is currently no news available for this company.<br /><br />If you have any questions or need help, please contact our Customer Service team at <a href="mailto:{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}">{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}</a> or on {country, select, gb {+44 (0)20 8515 1400} nl {+31 (020) 567 9501} other {+32 (03) 280 88 80}}.',
  'cr.summary.news.no-data.title': 'No news available for this company',
  'cr.summary.news.tooltip':
    'Here you will find the 3 most recent news articles about the company, including sentiment (positive > 0 < negative) and category. Click on an article to open it in a new tab in your browser. This information comes from Business Radar.',
  'cr.summary.payment-score-ccj-summons.information.ccjs':
    'Total amount of County Court Judgments for this company',
  'cr.summary.payment-score-ccj-summons.information.dbts': 'DBT average during the last month',
  'cr.summary.payment-score-ccj-summons.information.summons':
    'Total amount of summons and judgements for this company.',
  'cr.summary.payment-score-ccj-summons.title.BE': 'Payment score, summons & judgments',
  'cr.summary.payment-score-ccj-summons.title.GB': 'Payment Behaviour, DBT & CCJs',
  'cr.summary.payment-score-ccj-summons.title.NL': 'Payment score',
  'cr.summary.payment-score-ccj-summons.tooltip.BE':
    'The payment score tells you how great the chance is that your customer will respect the agreed payment term. The higher the payment score (on a scale of 0 to 10), the better the payment behavior. The number of subpoenas indicates how often a company has been summoned by the National Social Security Office, the Social Insurance Fund for the Self-employed or the Social Security Fund. Open the overview for more details.',
  'cr.summary.payment-score-ccj-summons.tooltip.GB':
    'The payment score indicates the extent to which an organisation pays its invoices on time. The following rule applies here: the higher the payment score, the better the company pays its invoices on time.',
  'cr.summary.payment-score-ccj-summons.tooltip.NL':
    'The payment score indicates to what extent an organisation pays its invoices on time. The following applies here: the higher the payment score, the better the company pays its invoices on time.',
  'cr.summary.xseptions.title': 'Exceptional events',
  'cr.summary.xseptions.tooltip.BE':
    'Under Xseptions you will find a number of striking events that took place (eg a fire, social unrest, financial problems, ...) or will take place (eg announcement of investments, merger proposal, announcement of location of registered office, ...) within the company . Open the overview for more details.',
  'cr.summary.xseptions.tooltip.GB':
    'XSeptions screens first and second-degree relationships (companies and/or directors) for critical events. This includes membership of sanction lists, opaque company structures or recent bankruptcies or resignations of the directors.',
  'cr.summary.xseptions.tooltip.NL':
    'XSeptions screens first and second-degree relationships (companies and / or directors) for remarkable situations. This includes mention on sanction lists, opaque company structures or recent bankruptcies of the director.',
  'cr.test': 'test',
  'cr.toast.message.edit-preferences': 'Edit preferences',
  'cr.unsecuredClaims.amount': 'Amount',
  'cr.unsecuredClaims.companyName': 'Company name',
  'cr.unsecuredClaims.disclaimer':
    'Showing a maximum of {maximum} claims out of {totalCount}, with the most recent claims displayed first.',
  'cr.unsecuredClaims.error.no-data.text':
    'This company does not have any unsecured claims or the information is not available.<br /><br />If you have any questions or need help, please contact our Customer Service team at <a href="mailto:{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}">{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}</a> or on {country, select, gb {+44&nbsp;(0)20&nbsp;8515&nbsp;1400} nl {+31&nbsp;(020)&nbsp;567&nbsp;9501} other {+32&nbsp;(03)&nbsp;280&nbsp;88&nbsp;80}}.',
  'cr.unsecuredClaims.error.no-data.title': 'No unsecured claims',
  'cr.unsecuredClaims.statementDate': 'Statement date',
  'cr.unsecuredClaims.summary': 'Total value of unsecured claims',
  'cr.unsecuredClaims.title': 'Unsecured claims',
  'cr.unsecuredClaims.tooltip':
    'This list includes all unsecured claims as reported on the statement date',
  'cr.unsecuredClaims.unknown': 'Unknown',
  'cr.value.not.available': 'N.A.',
  'credit.check.accountsDate': 'Accounts date',
  'credit.check.accountsFileDate': 'Accounts filed',
  'credit.check.accountsPeriod': 'Accounting period',
  'credit.check.accumulatedDepreciation': 'Accumulated depreciation',
  'credit.check.advancedPayments': 'Advanced payments',
  'credit.check.advancedPaymentsOnStock': 'Advanced payments on stock',
  'credit.check.assets': 'Assets',
  'credit.check.cashBankSecurities': 'Cash & Bank securities',
  'credit.check.commonRating': 'Common Rating',
  'credit.check.commonRiskClass': 'Common Rating',
  'credit.check.company.previous.title': 'Previous check(s) for this company',
  'credit.check.companyIncorporated': 'Company Incorporated',
  'credit.check.companyIncorporated.no': 'No',
  'credit.check.companyIncorporated.yes': 'Yes',
  'credit.check.creditorDays': 'Creditor',
  'credit.check.creditRecommendation': 'Credit limit',
  'credit.check.currentAssets': 'Current assets',
  'credit.check.currentLiabilities': 'Current liabilities',
  'credit.check.debtorDays': 'Payment terms',
  'credit.check.details.showMore': '{num} more…',
  'credit.check.download.pdf': 'Download PDF',
  'credit.check.dueToBanks': 'Due to banks',
  'credit.check.equityCapital': 'Equity capital',
  'credit.check.erc': 'Common Rating',
  'credit.check.finishedGoods': 'Finished goods',
  'credit.check.fixedAssets': 'Fixed assets',
  'credit.check.graydonUkAugurScore': 'Augur score',
  'credit.check.graydonUkCreditRating': 'Credit rating',
  'credit.check.graydonUkOddsOfFinancialStress': 'Odds of financial stress',
  'credit.check.inventories': 'Inventories',
  'credit.check.liabilities': 'Liabilities',
  'credit.check.longTermDeferredTaxes': 'Long term deferred taxes',
  'credit.check.longTermLiabilities': 'Long term liabilities',
  'credit.check.longTermLiabilitiesToAssociatedCompanies':
    'Long term liabilities to associated companies',
  'credit.check.longTermLoans': 'Long-term loans',
  'credit.check.multiscore': 'Multiscore',
  'credit.check.note.notValid':
    'This field requires a minimum of 1 chararter and a maximum of 140 characters',
  'credit.check.note.placeholder': 'Add note (optional)',
  'credit.check.numberOfEmployees': 'Number of employees',
  'credit.check.other': 'Other',
  'credit.check.otherCurrentAssets': 'Other current assets',
  'credit.check.otherLongTermLiabilities': 'Other long term liabilities',
  'credit.check.otherReserves': 'Other reserves',
  'credit.check.otherShortTermLiabilities': 'Other short-term liabilities',
  'credit.check.paymentscore': 'Payment score',
  'credit.check.paymentsRating': 'Payment Behaviour',
  'credit.check.pdPercentage': 'Percentage likelihood of failure',
  'credit.check.pdRating': 'Graydon Rating',
  'credit.check.profitAndLossAccount': 'Profit and loss account',
  'credit.check.provisions': 'Provisions',
  'credit.check.rawMaterialsAndSupplies': 'Raw materials & supplies',
  'credit.check.reference.label': 'Reference number',
  'credit.check.reference.placeholder': 'Add reference (optional)',
  'credit.check.refId.notValid': 'Only 30 characters allowed',
  'credit.check.reserveForPensions': 'Reserved for pensions',
  'credit.check.reserves': 'Reserves',
  'credit.check.revaluationReserves': 'Revaluation reserves',
  'credit.check.save.and.close': 'Done',
  'credit.check.saved': 'Check result saved',
  'credit.check.shareholdersFunds': 'Shareholder funds',
  'credit.check.shortTermLiabilities': 'Short-term liabilities',
  'credit.check.startupReservesExpenses': 'Start-up reserves expenses',
  'credit.check.surplusEquity': 'Surplus equity',
  'credit.check.totalAssets': 'Total assets',
  'credit.check.totalCurrentAssets': 'Total current assets',
  'credit.check.totalEquity': 'Total equity',
  'credit.check.totalFinancialAssets': 'Total financial assets',
  'credit.check.totalFixedAssets': 'Total fixed assets',
  'credit.check.totalIntangibleAssets': 'Total intangible assets',
  'credit.check.totalLiabilities': 'Total liabilities',
  'credit.check.totalLongTermLiabilities': 'Total long term liabilities',
  'credit.check.totalReceivables': 'Total receivables',
  'credit.check.totalShortTermLiabilities': 'Total short-term liabilities',
  'credit.check.totalTangibleAssets': 'Total tangible assets',
  'credit.check.tradeCreditors': 'Trade creditors',
  'credit.check.unknown.date': 'Unknown',
  'credit.check.workingCapital': 'Working capital',
  'credit.check.workInProgress': 'Work in progress',
  'creditcheck.no.active.models': 'No active models',
  'cta.choose.default': 'Select our advised model',
  'cta.choose.this': 'Personalize your decision model',
  'cta.logout': 'Log out',
  'cta.next.step': 'Next step',
  'cta.save': 'Save',
  'cta.save.and.close': 'Save and close',
  'cta.save.and.test': 'Save and test',
  'cta.search': 'Search',
  'cta.select': 'Select',
  'dashboard.clear.filters': 'Clear filters',
  'dashboard.company-list.tile.common-rating': 'Common rating',
  'dashboard.company-list.tile.report-requested': 'Report requested',
  'dashboard.filter.common-rating.title': 'Common Rating',
  'dashboard.filter.common-rating.tooltip':
    "This is a European standard for determining the credit risk of a company and is expressed as a value on a scale of 1 to 5. The higher the value, the lower the risk. Value '9' gives a financial calamity. With a value of '8' it is not possible to determine a risk.",
  'dashboard.filter.common-rating.value-1': 'Very high',
  'dashboard.filter.common-rating.value-2': 'High',
  'dashboard.filter.common-rating.value-3': 'Average',
  'dashboard.filter.common-rating.value-4': 'Low',
  'dashboard.filter.common-rating.value-5': 'Very low',
  'dashboard.filter.common-rating.value-8': 'Default',
  'dashboard.filter.common-rating.value-9': 'Not available',
  'dashboard.filter.group.companysizes.title': 'Company size',
  'dashboard.filter.group.countries_per_package.deluxe.title': "International Deluxe' package",
  'dashboard.filter.group.countries_per_package.essential.title':
    "International Essential' package",
  'dashboard.filter.group.countries_per_package.premium.title': "International Premium' package",
  'dashboard.filter.group.countries_per_package.title': 'Countries per package',
  'dashboard.filter.group.countries.title': 'Countries',
  'dashboard.filter.group.decisionmodels.title': 'Decision Models',
  'dashboard.filter.group.industry.title': 'Industry segments',
  'dashboard.filter.group.sectors.title': 'Sectors',
  'dashboard.filter.time-range.last-month': 'Current month',
  'dashboard.filter.time-range.last-six-months': 'Last 6 months',
  'dashboard.filter.time-range.last-three-months': 'Last 3 months',
  'dashboard.filter.time-range.last-twelve-months': 'Last 12 months',
  'dashboard.filter.time-range.title': 'Date range',
  'dashboard.heatmap.companies-list.header':
    'All checks for {timerange} on {country}. Showing {numOfItems} of {totalNumOfItems} {totalNumOfItems, plural, one{item} other{items}}.',
  'dashboard.heatmap.legend.addon-package': 'Add-on package',
  'dashboard.heatmap.legend.least-reports': 'Least reports',
  'dashboard.heatmap.legend.most-reports': 'Most reports',
  'dashboard.heatmap.legend.unavailable': 'Not available',
  'dashboard.multiselect.companysizes.0': '0 employees',
  'dashboard.multiselect.companysizes.1': '1 - 4 employees',
  'dashboard.multiselect.companysizes.2': '5 - 9 employees',
  'dashboard.multiselect.companysizes.3': '10 - 19 employees',
  'dashboard.multiselect.companysizes.4': '20 - 49 employees',
  'dashboard.multiselect.companysizes.5': '50 - 99 employees',
  'dashboard.multiselect.companysizes.6': '100 - 199 employees',
  'dashboard.multiselect.companysizes.7': '200 - 499 employees',
  'dashboard.multiselect.companysizes.8': '500 - 999 employees',
  'dashboard.multiselect.companysizes.9': '>1000 employees',
  'dashboard.multiselect.companysizes.all': 'All company sizes',
  'dashboard.multiselect.companysizes.n-sizes': '{n} company sizes selected',
  'dashboard.multiselect.companysizes.X': 'Unknown',
  'dashboard.tile.list.footer': 'Want to see all?',
  'dashboard.tile.list.header':
    '{outcome, select, all{All} accept{Accepted} refer{Referred} deny{Denied}} orders for {month}.',
  'dashboard.tile.list.header.close': 'Close',
  'dashboard.tile.list.numOfItems':
    'Showing {numOfItems} of {totalNumOfItems} {totalNumOfItems, plural, one{item} other{items}}.',
  'dashboard.title': 'Management Dashboard',
  'dashboard.widget.label.accept': 'Accept',
  'dashboard.widget.label.be': 'Belgium',
  'dashboard.widget.label.deluxe': 'Deluxe',
  'dashboard.widget.label.deny': 'Refer',
  'dashboard.widget.label.essential': 'Essential',
  'dashboard.widget.label.gb': 'United kingdom',
  'dashboard.widget.label.nl': 'Netherlands',
  'dashboard.widget.label.premium': 'Premium',
  'dashboard.widget.label.refer': 'Reject',
  'dashboard.widget.title.acceptance-grade': 'Acceptance grade',
  'dashboard.widget.title.average-order-value': 'Average order value',
  'dashboard.widget.title.consumption': 'International Business - Requested reports per country',
  'dashboard.widget.title.consumption-heatmap':
    'International Business - Requested reports per country',
  'dashboard.widget.title.credit-checks': 'Credit checks',
  'dashboard.widget.title.total-orders': 'Total amount of checks',
  'dashboard.with.filters': 'Filtered selection',
  Datablocks: 'The changes have been saved',
  'dbm.alreadyScheduled': 'already scheduled',
  'dbm.backHome': 'Return to the homepage',
  'dbm.bigDownload.button': "Let's do something else",
  'dbm.bigDownload.emailTo':
    "When your enriched file is ready to download, we'll send an email to:",
  'dbm.bigDownload.heading': 'Almost time to hit your targets...',
  'dbm.bigDownload.smallText': "Haven't received an email within 2 hours? Contact customer service",
  'dbm.chooseAList': 'Choose a list',
  'dbm.create-profile.companies': 'Companies',
  'dbm.create-profile.company-options.description':
    "Note: You can only choose for List, Selection or All from a country at profile creation. This can't be changed at this point",
  'dbm.create-profile.company-options.description-no-merketdata':
    "Note: You can only choose for List or All from a country at profile creation. This can't be changed at this point",
  'dbm.create-profile.company-options.event': 'All from country',
  'dbm.create-profile.company-options.event-description':
    'Get companies based on events, f.e. insolvencies',
  'dbm.create-profile.company-options.from': 'Companies from',
  'dbm.create-profile.company-options.list': 'List',
  'dbm.create-profile.company-options.list-description': 'Add companies yourself',
  'dbm.create-profile.company-options.selection': 'Market Data Selection',
  'dbm.create-profile.company-options.selection-description': 'Find companies based on criteria',
  'dbm.create-profile.company-options.tooltip':
    'You can receive data either from a list of companies you choose yourself (List option), from companies that match a set of criteria (Selection option) or from all companies from a country where a particular event occurs, like an insolvency (Country option).',
  'dbm.create-profile.company-options.tooltip-no-marketdata':
    'You can receive data either from a list of companies you choose yourself (List option) or from all companies from a country where a particular event occurs, like an insolvency (Country option).',
  'dbm.create-profile.country': 'Country',
  'dbm.create-profile.country.tooltip':
    'Beware: country selection is not editable after the initial save. Do you want to receive data from multiple countries? Then please create a profile per country.',
  'dbm.create-profile.data-block-group.code_descriptions': 'Code descriptions',
  'dbm.create-profile.data-block-group.company_profile': 'Company profile',
  'dbm.create-profile.data-block-group.events': 'Exceptional events',
  'dbm.create-profile.data-block-group.finance': 'Financials',
  'dbm.create-profile.data-block-group.network': 'Company structure',
  'dbm.create-profile.data-block-group.ratings': 'Ratings',
  'dbm.create-profile.data-block-group.untitled': 'Untitled',
  'dbm.create-profile.data-blocks': 'Choose data blocks',
  'dbm.create-profile.data-blocks-version': 'Version',
  'dbm.create-profile.data-blocks.DBM_ACTIVITY': 'Activity',
  'dbm.create-profile.data-blocks.DBM_ACTIVITY.tooltip':
    'This data block contains all activity information about a company. Please check the documentation for a complete overview. ',
  'dbm.create-profile.data-blocks.DBM_ADDRESS': 'Addresses',
  'dbm.create-profile.data-blocks.DBM_ADDRESS.tooltip':
    'This data block contains all address information about a company. Please check the documentation for a complete overview. ',
  'dbm.create-profile.data-blocks.DBM_ANNUAL_ACCOUNT': 'Annual accounts',
  'dbm.create-profile.data-blocks.DBM_ANNUAL_ACCOUNT.tooltip': '-',
  'dbm.create-profile.data-blocks.DBM_ANNUAL_REPORT': 'Annual Report',
  'dbm.create-profile.data-blocks.DBM_ANNUAL_REPORT.tooltip': '-',
  'dbm.create-profile.data-blocks.DBM_BAG': 'BAG',
  'dbm.create-profile.data-blocks.DBM_BAG.tooltip':
    'BAG (Basic Registration Addresses and Buildings) contains municipal basic data of all addresses and buildings in a municipality.',
  'dbm.create-profile.data-blocks.DBM_BANKER': 'Bank',
  'dbm.create-profile.data-blocks.DBM_BANKER.tooltip': 'Bank',
  'dbm.create-profile.data-blocks.DBM_BASIC': 'Basic',
  'dbm.create-profile.data-blocks.DBM_BASIC.tooltip':
    'This data block contains all basic information about a company. Please check the documentation for a complete overview. ',
  'dbm.create-profile.data-blocks.DBM_BRANCH': 'Branches',
  'dbm.create-profile.data-blocks.DBM_BRANCH.tooltip':
    'This data block contains information about branches of a company. Please check the documentation for a complete overview. ',
  'dbm.create-profile.data-blocks.DBM_CCJ_SUMMARY': 'CCJ Summary',
  'dbm.create-profile.data-blocks.DBM_CCJ_SUMMARY.tooltip': '-',
  'dbm.create-profile.data-blocks.DBM_CODE_DESCRIPTIONS': 'Code descriptions',
  'dbm.create-profile.data-blocks.DBM_CODE_DESCRIPTIONS.tooltip':
    'Special data block that provides codes, values and descriptions (EN, FR, NL) for tables used in the other data blocks. (e.g. Country code: NL -> Nederland, SIZE_CLASS: 4 -> 5 tot 9)',
  'dbm.create-profile.data-blocks.DBM_CONTACT_ITEM': 'Contact information',
  'dbm.create-profile.data-blocks.DBM_CONTACT_ITEM.tooltip':
    'This data block contains all contact information of a company. Please check the documentation for a complete overview. ',
  'dbm.create-profile.data-blocks.DBM_CONTINUATION': 'Continuation',
  'dbm.create-profile.data-blocks.DBM_CONTINUATION.tooltip': '-',
  'dbm.create-profile.data-blocks.DBM_CREDIT_RATING': 'Credit Rating',
  'dbm.create-profile.data-blocks.DBM_CREDIT_RATING.tooltip':
    'This data block contains all scores and ratings of a company. Please check the documentation for a complete overview. ',
  'dbm.create-profile.data-blocks.DBM_CREDIT_SCORES': 'Credit scores',
  'dbm.create-profile.data-blocks.DBM_CREDIT_SCORES.tooltip':
    'This data block contains all information of scores and ratings from a company. Please check the documentation for a complete overview. ',
  'dbm.create-profile.data-blocks.DBM_EMPLOYEE': 'Employee',
  'dbm.create-profile.data-blocks.DBM_EMPLOYEE.tooltip':
    'This data block contains all employee related information about a company. Please check the documentation for a complete overview. ',
  'dbm.create-profile.data-blocks.DBM_EVENTS': 'Events and publications {extra}',
  'dbm.create-profile.data-blocks.DBM_EVENTS.tooltip': '-',
  'dbm.create-profile.data-blocks.DBM_FINANCE_SUMMARY': 'Financial summary',
  'dbm.create-profile.data-blocks.DBM_FINANCE_SUMMARY.tooltip':
    'This data block contains a financial summary from a company. Please check the documentation for a complete overview. ',
  'dbm.create-profile.data-blocks.DBM_INSOLVENCY': 'Insolvency',
  'dbm.create-profile.data-blocks.DBM_INSOLVENCY_POSITION': 'Persons related to insolvency',
  'dbm.documentation.block-description.DBM_INSOLVENCY_POSITION-NL': '-',
  'dbm.documentation.block-description.DBM_INSOLVENCY_POSITION-BE': '-',
  'dbm.documentation.block-description.DBM_INSOLVENCY_POSITION-GB': '-',
  'dbm.create-profile.data-blocks.DBM_INSOLVENCY.tooltip': '-',
  'dbm.create-profile.data-blocks.DBM_KEY_FIGURE': 'Key figures',
  'dbm.create-profile.data-blocks.DBM_KEY_FIGURE.tooltip': 'Key figures',
  'dbm.create-profile.data-blocks.DBM_LIABILITY_STATEMENT': 'Liability statement',
  'dbm.create-profile.data-blocks.DBM_LIABILITY_STATEMENT.tooltip': '-',
  'dbm.create-profile.data-blocks.DBM_OPPORTUNITY': 'Opportunities',
  'dbm.create-profile.data-blocks.DBM_OPPORTUNITY.tooltip': 'Opportunities',
  'dbm.create-profile.data-blocks.DBM_ORGANIZATION_NAME': 'Organization names',
  'dbm.create-profile.data-blocks.DBM_ORGANIZATION_NAME.tooltip':
    'This data block contains all organisation names of a company. Please check the documentation for a complete overview. ',
  'dbm.create-profile.data-blocks.DBM_PARTICIPATION': 'Participations',
  'dbm.create-profile.data-blocks.DBM_PARTICIPATION.tooltip':
    'This data block contains all information of shares from a company. Please check the documentation for a complete overview. ',
  'dbm.create-profile.data-blocks.DBM_PAYMENT_ANALYSIS': 'Payment analysis',
  'dbm.create-profile.data-blocks.DBM_PAYMENT_ANALYSIS.tooltip': '-',
  'dbm.create-profile.data-blocks.DBM_POSITION': 'Management positions',
  'dbm.create-profile.data-blocks.DBM_POSITION_CIS': 'Positions CIS',
  'dbm.create-profile.data-blocks.DBM_POSITION_CIS.tooltip':
    'Information about positions from the database of Foundation Centraal Informatie Systeem',
  'dbm.create-profile.data-blocks.DBM_POSITION.tooltip':
    'This data block contains all director and management information of a company. Please check the documentation for a complete overview. ',
  'dbm.create-profile.data-blocks.DBM_SHAREHOLDER': 'Shareholders',
  'dbm.create-profile.data-blocks.DBM_SHAREHOLDER.tooltip':
    'This data block contains all shareholder information about a company. Please check the documentation for a complete overview. ',
  'dbm.create-profile.data-blocks.DBM_SOCIAL_BALANCE': 'Social balance',
  'dbm.create-profile.data-blocks.DBM_SOCIAL_BALANCE.tooltip': '-',
  'dbm.create-profile.data-blocks.DBM_STRUCTURE': 'Structure',
  'dbm.create-profile.data-blocks.DBM_STRUCTURE.tooltip':
    'This data block contains all related company information. Please check the documentation for a complete overview. ',
  'dbm.create-profile.data-blocks.DBM_SUMMONS': 'Summons',
  'dbm.create-profile.data-blocks.DBM_SUMMONS.tooltip': '-',
  'dbm.create-profile.data-blocks.DBM_UBO': 'UBO',
  'dbm.create-profile.data-blocks.DBM_UBO_CIS': 'UBO CIS',
  'dbm.create-profile.data-blocks.DBM_UBO_CIS.tooltip':
    'Information about Utimate Beneficial Owners in the group structure of a company, from the database of Foundation Centraal Informatie Systeem',
  'dbm.create-profile.data-blocks.DBM_UBO.tooltip':
    'Ultimate Beneficial Owners in the group structure of a company',
  'dbm.create-profile.data-blocks.DBM_XSEPTION': 'XSeption',
  'dbm.create-profile.data-blocks.DBM_XSEPTION.tooltip': '-',
  'dbm.create-profile.delimiter': 'Delimiter for CSV file',
  'dbm.create-profile.description': 'Description',
  'dbm.create-profile.duplicate.message': 'You have already used this name for another profile',
  'dbm.create-profile.duplicate.title': 'Duplicated name',
  'dbm.create-profile.error.message': 'There was an error in creating profile',
  'dbm.create-profile.error.title': 'Error in creating profile',
  'dbm.create-profile.layout': 'File record layout',
  'dbm.create-profile.layout.columnNames': 'Include column names',
  'dbm.create-profile.layout.headerAndFooter': 'Include header and footer',
  'dbm.create-profile.layout.tooltip':
    'The data file header contains metadata like profile name, date and time, requester id. The footer contains the number of organisations in the file. Column names declare what data comes in which data file column. See documentation for an example.',
  'dbm.create-profile.list.countryNote':
    'Please note: only {organizationCountOfCountry} {organizationCountOfCountry, plural, =1 {company} other {companies}} out of {organizationCount} are from {countryCode} and will be used for this profile',
  'dbm.create-profile.list.countryNote.empty':
    'None of the {organizationCount} companies in the list are from {countryCode}, so none will be used for this profile',
  'dbm.create-profile.name': 'Profile name',
  'dbm.create-profile.no-data-block':
    'No datablocks are enabled for this country. Please [contact Graydon](/contact).',
  'dbm.create-profile.receiving-updates': 'File delivery',
  'dbm.create-profile.save-profile': 'Save profile',
  'dbm.create-profile.select-company': 'General',
  'dbm.create-profile.start-date': 'From',
  'dbm.create-profile.success.message': 'The profile was created successfully.',
  'dbm.create-profile.success.title': 'Profile created successfully',
  'dbm.create-profile.title': 'Create profile',
  'dbm.create-profile.type': 'Full dataset or changes only',
  'dbm.create-profile.type.delta': 'Only receive companies with changes',
  'dbm.create-profile.type.full': 'Receive full dataset every time',
  'dbm.create-profile.type.full_then_delta': 'Full dataset first, then only changed',
  'dbm.dataBlock.fields.activity': 'Activity',
  'dbm.dataBlock.fields.companyName': 'Company name & Trading Name',
  'dbm.dataBlock.fields.contactDetails': 'Contact details',
  'dbm.dataBlock.fields.DMU': 'Contact person (DMU)',
  'dbm.dataBlock.fields.employeeCount': 'Number of Employees',
  'dbm.dataBlock.fields.establishmentType': 'Type of Establishment',
  'dbm.dataBlock.fields.graydonId': 'Graydon Identification number',
  'dbm.dataBlock.fields.incorporationDate': 'Date of incorporation',
  'dbm.dataBlock.fields.legalForm': 'Legal form',
  'dbm.dataBlock.fields.sector': 'Sector',
  'dbm.dataBlock.marketinginformation': 'Marketing Information',
  'dbm.documentation.block-description.DBM_ACTIVITY-BE': 'Activities',
  'dbm.documentation.block-description.DBM_ACTIVITY-GB': '-',
  'dbm.documentation.block-description.DBM_ACTIVITY-NL': '-',
  'dbm.documentation.block-description.DBM_ADDRESS-BE': 'Addresses',
  'dbm.documentation.block-description.DBM_ADDRESS-GB': '-',
  'dbm.documentation.block-description.DBM_ADDRESS-NL': '-',
  'dbm.documentation.block-description.DBM_ANNUAL_ACCOUNT-BE': 'Annual Accounts',
  'dbm.documentation.block-description.DBM_ANNUAL_ACCOUNT-GB': '-',
  'dbm.documentation.block-description.DBM_ANNUAL_ACCOUNT-NL': '-',
  'dbm.documentation.block-description.DBM_ANNUAL_REPORT-BE': 'Annual Accounts Publications Index',
  'dbm.documentation.block-description.DBM_ANNUAL_REPORT-GB': '-',
  'dbm.documentation.block-description.DBM_ANNUAL_REPORT-NL': '-',
  'dbm.documentation.block-description.DBM_BAG-NL':
    'BAG (Basic Registration Addresses and Buildings) contains municipal basic data of all addresses and buildings in a municipality.',
  'dbm.documentation.block-description.DBM_BANKER-BE': 'Bankers',
  'dbm.documentation.block-description.DBM_BASIC-BE': 'Basic information',
  'dbm.documentation.block-description.DBM_BASIC-GB': '-',
  'dbm.documentation.block-description.DBM_BASIC-NL': '-',
  'dbm.documentation.block-description.DBM_BRANCH-BE': 'Branches',
  'dbm.documentation.block-description.DBM_BRANCH-NL': '-',
  'dbm.documentation.block-description.DBM_CCJ_SUMMARY-BE': '-',
  'dbm.documentation.block-description.DBM_CCJ_SUMMARY-GB': '-',
  'dbm.documentation.block-description.DBM_CCJ_SUMMARY-NL': '-',
  'dbm.documentation.block-description.DBM_CODE_DESCRIPTIONS-BE':
    'Special data block that provides human readable descriptions (in English, Dutch and French) for codes in other data blocks',
  'dbm.documentation.block-description.DBM_CODE_DESCRIPTIONS-GB':
    'Special data block that provides human readable descriptions (in English, Dutch and French) for codes in other data blocks',
  'dbm.documentation.block-description.DBM_CODE_DESCRIPTIONS-NL':
    'Special data block that provides human readable descriptions (in English, Dutch and French) for codes in other data blocks',
  'dbm.documentation.block-description.DBM_CONTACT_ITEM-BE': 'Contact information',
  'dbm.documentation.block-description.DBM_CONTACT_ITEM-GB': '-',
  'dbm.documentation.block-description.DBM_CONTACT_ITEM-NL': '-',
  'dbm.documentation.block-description.DBM_CONTINUATION-BE': '-',
  'dbm.documentation.block-description.DBM_CONTINUATION-GB': '-',
  'dbm.documentation.block-description.DBM_CONTINUATION-NL': '-',
  'dbm.documentation.block-description.DBM_CREDIT_SCORES-BE': 'Credit scores',
  'dbm.documentation.block-description.DBM_CREDIT_SCORES-GB': '-',
  'dbm.documentation.block-description.DBM_CREDIT_SCORES-NL': '-',
  'dbm.documentation.block-description.DBM_EMPLOYEE-GB': '-',
  'dbm.documentation.block-description.DBM_EMPLOYEE-NL': '-',
  'dbm.documentation.block-description.DBM_EVENTS-BE': 'Events and publications',
  'dbm.documentation.block-description.DBM_EVENTS-GB': 'Events',
  'dbm.documentation.block-description.DBM_EVENTS-NL': '-',
  'dbm.documentation.block-description.DBM_FINANCE_SUMMARY-BE': 'Financial summary',
  'dbm.documentation.block-description.DBM_FINANCE_SUMMARY-GB': '-',
  'dbm.documentation.block-description.DBM_FINANCE_SUMMARY-NL': '-',
  'dbm.documentation.block-description.DBM_INSOLVENCY-BE':
    'Insolvencies - bankruptcies & judicial reorganizations',
  'dbm.documentation.block-description.DBM_INSOLVENCY-GB':
    'Insolvencies - bankruptcies & judicial reorganizations',
  'dbm.documentation.block-description.DBM_INSOLVENCY-NL':
    'Insolvencies - bankruptcies & judicial reorganizations',
  'dbm.documentation.block-description.DBM_KEY_FIGURE-BE': 'Key figures',
  'dbm.documentation.block-description.DBM_KEY_FIGURE-GB': 'Key figures',
  'dbm.documentation.block-description.DBM_KEY_FIGURE-NL': 'Key figures',
  'dbm.documentation.block-description.DBM_LIABILITY_STATEMENT-NL': '-',
  'dbm.documentation.block-description.DBM_OPPORTUNITY-BE': 'Opportunity scores',
  'dbm.documentation.block-description.DBM_OPPORTUNITY-GB': 'Opportunity scores',
  'dbm.documentation.block-description.DBM_OPPORTUNITY-NL': 'Opportunity scores',
  'dbm.documentation.block-description.DBM_ORGANIZATION_NAME-BE': 'Names',
  'dbm.documentation.block-description.DBM_ORGANIZATION_NAME-GB': '-',
  'dbm.documentation.block-description.DBM_ORGANIZATION_NAME-NL': '-',
  'dbm.documentation.block-description.DBM_PARTICIPATION-BE': 'Participations',
  'dbm.documentation.block-description.DBM_PARTICIPATION-GB': 'Participations',
  'dbm.documentation.block-description.DBM_PARTICIPATION-NL': 'Participations',
  'dbm.documentation.block-description.DBM_PAYMENT_ANALYSIS-BE': 'Payment analysis',
  'dbm.documentation.block-description.DBM_PAYMENT_ANALYSIS-NL': 'Payment analysis',
  'dbm.documentation.block-description.DBM_POSITION_CIS-BE': '-',
  'dbm.documentation.block-description.DBM_POSITION_CIS-GB': '-',
  'dbm.documentation.block-description.DBM_POSITION_CIS-NL': '-',
  'dbm.documentation.block-description.DBM_POSITION-BE': 'Management positions',
  'dbm.documentation.block-description.DBM_POSITION-NL': '-',
  'dbm.documentation.block-description.DBM_POSITION-GB': '-',
  'dbm.documentation.block-description.DBM_SHAREHOLDER-BE': 'Shareholders',
  'dbm.documentation.block-description.DBM_SHAREHOLDER-GB': 'Shareholders',
  'dbm.documentation.block-description.DBM_SHAREHOLDER-NL': 'Shareholders',
  'dbm.documentation.block-description.DBM_SOCIAL_BALANCE-BE': 'Social balance',
  'dbm.documentation.block-description.DBM_SOCIAL_BALANCE-GB': '-',
  'dbm.documentation.block-description.DBM_SOCIAL_BALANCE-NL': '-',
  'dbm.documentation.block-description.DBM_STRUCTURE-NL': '-',
  'dbm.documentation.block-description.DBM_STRUCTURE-GB': '-',
  'dbm.documentation.block-description.DBM_SUMMONS-BE': 'Summons',
  'dbm.documentation.block-description.DBM_SUMMONS-GB': '-',
  'dbm.documentation.block-description.DBM_SUMMONS-NL': '-',
  'dbm.documentation.block-description.DBM_UBO_CIS-BE': '-',
  'dbm.documentation.block-description.DBM_UBO_CIS-GB': '-',
  'dbm.documentation.block-description.DBM_UBO_CIS-NL': '-',
  'dbm.documentation.block-description.DBM_UBO-BE': '-',
  'dbm.documentation.block-description.DBM_UBO-NL': '-',
  'dbm.documentation.block-description.DBM_XSEPTION-BE': 'XSeptions',
  'dbm.documentation.block-description.DBM_XSEPTION-GB': 'XSeptions',
  'dbm.documentation.block-description.DBM_XSEPTION-NL': 'XSeptions',
  'dbm.documentation.collapse-all': 'Collapse all',
  'dbm.documentation.delete-profile': 'Delete Profile',
  'dbm.documentation.delete-profile.error':
    'The profile cannot be deleted because data collection is in progress at the moment. Please try deleting again later.',
  'dbm.documentation.delete-profile.success': 'Profile has been deleted',
  'dbm.documentation.description':
    'Below the metadata of available datablocks is described. If you would like to download or print parts of the documentation, please expand the data blocks of your preference.\n General documentation about the usage of the API can be found at [graydon.io](https://graydon.io) and in the [supplied Technical documentation sheet](https://connect.graydon-global.com/rs/934-AQC-614/images/Database-Management-Technical-documentation.pdf). ',
  'dbm.documentation.description.ABBREVIATIONS_LEGAL_FORM': 'Legal form abriviation',
  'dbm.documentation.description.ACC_PERIOD_END_DATE': 'Accounting period end date',
  'dbm.documentation.description.ACC_PERIOD_START_DATE': 'Start date annual account period',
  'dbm.documentation.description.ACCOUNTANT_NAME': 'Accountants',
  'dbm.documentation.description.ACCOUNTING_PERIOD': 'Annual account period start-end date',
  'dbm.documentation.description.ACTIVITY_CLASSIFICATION_CODE': 'Activity classification code',
  'dbm.documentation.description.ACTIVITY_CODE':
    'A standard code that identifies the activities of the company (primary)',
  'dbm.documentation.description.ACTIVITY_CODE_1':
    'A standard code that identifies the activities of the company (primary)',
  'dbm.documentation.description.ACTIVITY_CODE_2':
    'A standard code that identifies the activities of the company (primary)',
  'dbm.documentation.description.ACTIVITY_CODE_3':
    'A standard code that identifies the activities of the company (primary)',
  'dbm.documentation.description.ACTIVITY_CODE_4':
    'A standard code that identifies the activities of the company (primary)',
  'dbm.documentation.description.ACTIVITY_CODE_5':
    'A standard code that identifies the activities of the company (primary)',
  'dbm.documentation.description.ACTIVITY_DESCRIPTION': 'Economic activity description.',
  'dbm.documentation.description.ACTIVITY_GROUP_CODE':
    'Source of the activity: NSSO, VAT, CBE or Graydon',
  'dbm.documentation.description.ACTIVITY_SEQUENCE_NUMBER': 'Activity sequence number',
  'dbm.documentation.description.ACTIVITY_START_DATE': 'Date',
  'dbm.documentation.description.ACTIVITY_TYPE_CODE':
    'Code defines the type of the activity: primary, secondary (side activity)',
  'dbm.documentation.description.ADDRESS_END_DATE':
    'The date this company stopped using this address',
  'dbm.documentation.description.ADDRESS_LINE_1': 'Free format address line 1',
  'dbm.documentation.description.ADDRESS_LINE_1_RECEIVER_1': '-',
  'dbm.documentation.description.ADDRESS_LINE_1_RECEIVER_10': '-',
  'dbm.documentation.description.ADDRESS_LINE_1_RECEIVER_2': '-',
  'dbm.documentation.description.ADDRESS_LINE_1_RECEIVER_3': '-',
  'dbm.documentation.description.ADDRESS_LINE_1_RECEIVER_4': '-',
  'dbm.documentation.description.ADDRESS_LINE_1_RECEIVER_5': '-',
  'dbm.documentation.description.ADDRESS_LINE_1_RECEIVER_6': '-',
  'dbm.documentation.description.ADDRESS_LINE_1_RECEIVER_7': '-',
  'dbm.documentation.description.ADDRESS_LINE_1_RECEIVER_8': '-',
  'dbm.documentation.description.ADDRESS_LINE_1_RECEIVER_9': '-',
  'dbm.documentation.description.ADDRESS_LINE_2': 'Free format address line 2',
  'dbm.documentation.description.ADDRESS_LINE_2_RECEIVER_1': '-',
  'dbm.documentation.description.ADDRESS_LINE_2_RECEIVER_10': '-',
  'dbm.documentation.description.ADDRESS_LINE_2_RECEIVER_2': '-',
  'dbm.documentation.description.ADDRESS_LINE_2_RECEIVER_3': '-',
  'dbm.documentation.description.ADDRESS_LINE_2_RECEIVER_4': '-',
  'dbm.documentation.description.ADDRESS_LINE_2_RECEIVER_5': '-',
  'dbm.documentation.description.ADDRESS_LINE_2_RECEIVER_6': '-',
  'dbm.documentation.description.ADDRESS_LINE_2_RECEIVER_7': '-',
  'dbm.documentation.description.ADDRESS_LINE_2_RECEIVER_8': '-',
  'dbm.documentation.description.ADDRESS_LINE_2_RECEIVER_9': '-',
  'dbm.documentation.description.ADDRESS_LINE_3': 'Free format address line 3',
  'dbm.documentation.description.ADDRESS_LINE_3_RECEIVER_1': '-',
  'dbm.documentation.description.ADDRESS_LINE_3_RECEIVER_10': '-',
  'dbm.documentation.description.ADDRESS_LINE_3_RECEIVER_2': '-',
  'dbm.documentation.description.ADDRESS_LINE_3_RECEIVER_3': '-',
  'dbm.documentation.description.ADDRESS_LINE_3_RECEIVER_4': '-',
  'dbm.documentation.description.ADDRESS_LINE_3_RECEIVER_5': '-',
  'dbm.documentation.description.ADDRESS_LINE_3_RECEIVER_6': '-',
  'dbm.documentation.description.ADDRESS_LINE_3_RECEIVER_7': '-',
  'dbm.documentation.description.ADDRESS_LINE_3_RECEIVER_8': '-',
  'dbm.documentation.description.ADDRESS_LINE_3_RECEIVER_9': '-',
  'dbm.documentation.description.ADDRESS_LINE_4': 'Free format address line 4',
  'dbm.documentation.description.ADDRESS_LINE_4_RECEIVER_1': '-',
  'dbm.documentation.description.ADDRESS_LINE_4_RECEIVER_10': '-',
  'dbm.documentation.description.ADDRESS_LINE_4_RECEIVER_2': '-',
  'dbm.documentation.description.ADDRESS_LINE_4_RECEIVER_3': '-',
  'dbm.documentation.description.ADDRESS_LINE_4_RECEIVER_4': '-',
  'dbm.documentation.description.ADDRESS_LINE_4_RECEIVER_5': '-',
  'dbm.documentation.description.ADDRESS_LINE_4_RECEIVER_6': '-',
  'dbm.documentation.description.ADDRESS_LINE_4_RECEIVER_7': '-',
  'dbm.documentation.description.ADDRESS_LINE_4_RECEIVER_8': '-',
  'dbm.documentation.description.ADDRESS_LINE_4_RECEIVER_9': '-',
  'dbm.documentation.description.ADDRESS_LINE_5': 'Free format address line 5',
  'dbm.documentation.description.ADDRESS_LINE_5_RECEIVER_1': '-',
  'dbm.documentation.description.ADDRESS_LINE_5_RECEIVER_10': '-',
  'dbm.documentation.description.ADDRESS_LINE_5_RECEIVER_2': '-',
  'dbm.documentation.description.ADDRESS_LINE_5_RECEIVER_3': '-',
  'dbm.documentation.description.ADDRESS_LINE_5_RECEIVER_4': '-',
  'dbm.documentation.description.ADDRESS_LINE_5_RECEIVER_5': '-',
  'dbm.documentation.description.ADDRESS_LINE_5_RECEIVER_6': '-',
  'dbm.documentation.description.ADDRESS_LINE_5_RECEIVER_7': '-',
  'dbm.documentation.description.ADDRESS_LINE_5_RECEIVER_8': '-',
  'dbm.documentation.description.ADDRESS_LINE_5_RECEIVER_9': '-',
  'dbm.documentation.description.ADDRESS_LINES':
    'Indicator that the address lines are filled in this address block',
  'dbm.documentation.description.ADDRESS_ROLE_CODE':
    'function code of the address, Registered office address, trading address,`Postal address, Service address ,etc.',
  'dbm.documentation.description.ADDRESS_START_DATE':
    'The date this company started using this address',
  'dbm.documentation.description.ADDRESS_TYPE_CODE': 'PO box or physical address',
  'dbm.documentation.description.AMOUNT_CATEGORY_CODE': 'Amount category code.',
  'dbm.documentation.description.AMOUNT_CCJ_LAST_12_MONTHS': '-',
  'dbm.documentation.description.AMOUNT_CCJ_LAST_24_MONTHS': '-',
  'dbm.documentation.description.AMOUNT_CCJ_LAST_36_MONTHS': '-',
  'dbm.documentation.description.AMOUNT_CCJ_LAST_48_MONTHS': '-',
  'dbm.documentation.description.AMOUNT_CCJ_LAST_60_MONTHS': '-',
  'dbm.documentation.description.AMOUNT_CCJ_LAST_72_MONTHS': '-',
  'dbm.documentation.description.AMOUNT_UNIT_CODE': 'Amount units',
  'dbm.documentation.description.ANNUAL_ACCOUNT_ITEM_CODE': 'Graydon annual account item code',
  'dbm.documentation.description.ANNUAL_ACCOUNT_ITEM_VALUE': 'Value of the account item',
  'dbm.documentation.description.ANNUAL_ACCOUNT_TYPE_CODE': 'Type of annual accounts model',
  'dbm.documentation.description.ANNUAL_REPORT_FILING_DATE': 'Annual report filing date',
  'dbm.documentation.description.ANNUAL_REPORT_TYPE_CODE': 'Type of annual accounts model',
  'dbm.documentation.description.AREA': 'Area in square meters',
  'dbm.documentation.description.ASSOCIATION_FUNDS': 'Funds of the association / foundation',
  'dbm.documentation.description.AUTHORISED_CAPITAL': 'Authorised capital',
  'dbm.documentation.description.AVAILABLE_CONTRIBUTION': 'Available contribution',
  'dbm.documentation.description.BAG_STATUS_CODE': 'Object status code',
  'dbm.documentation.description.BAG_TYPE_CODE': 'BAG type code',
  'dbm.documentation.description.BALANCE_SHEET_TOTAL': 'The balance sheet total for the company',
  'dbm.documentation.description.BANK_CODE': 'bank code',
  'dbm.documentation.description.BANK_NAME': 'Bank name',
  'dbm.documentation.description.BE_BUSINESS_UNIT_NUMBER':
    'The first trade name in GDI of the branch office, Each branch unit is assigned a unique identification number, which differs from the enterprise number. The establishment unit number consists of 10 digits. The first digit is a digit from 2 to 8. That number is transferable from one entity to another',
  'dbm.documentation.description.BE_ENTERPRISE_NUMBER': 'Enterprise number',
  'dbm.documentation.description.BE_LEGAL_FORM_CODE': 'Graydon assigned legal form code',
  'dbm.documentation.description.BE_MULTISCORE':
    'The multiscore bundles all relevant information available to Graydon in one score to assess a company in the medium term in terms of financial health, prompt payment and chances of survival.',
  'dbm.documentation.description.BOARD_TYPE_CODE': 'Company management or board type',
  'dbm.documentation.description.BUILDING': '-',
  'dbm.documentation.description.CALAMITY': '-',
  'dbm.documentation.description.CALAMITY_START_DATE': '-',
  'dbm.documentation.description.CASH': 'Company cash in hand',
  'dbm.documentation.description.CCJ_FLAG': '-',
  'dbm.documentation.description.CITY': 'City name',
  'dbm.documentation.description.CODE_VALUE': 'Code value',
  'dbm.documentation.description.CONSOLIDATED_FLAG': '-',
  'dbm.documentation.description.CONSTRUCTION_YEAR': 'Construction year',
  'dbm.documentation.description.CONTACT_ITEM_ROLE_CODE': 'Contact item role code',
  'dbm.documentation.description.CONTACT_ITEM_TYPE_CODE':
    'Contact information item type code (such as url, email, telephone, etc.)',
  'dbm.documentation.description.CONTACT_ITEM_VALUE':
    'contact information for the differents types , sample www.rikaint.com or info@graydon.nl or 0205679999',
  'dbm.documentation.description.CONTINUATION_START_DATE': '-',
  'dbm.documentation.description.CONTINUATION_TYPE_CODE': '-',
  'dbm.documentation.description.CONTRIBUTION': 'Contribution',
  'dbm.documentation.description.COUNTRY_CODE': 'ISO alpha-2 country code',
  'dbm.documentation.description.COURT': 'Court',
  'dbm.documentation.description.CREDIT_ADVICE':
    'The maximum credit amount advised by Graydon for each supplier of the company in Euro.',
  'dbm.documentation.description.CREDIT_FLAG':
    'Risk indication of doing business with requested company',
  'dbm.documentation.description.CURRENCY_CODE': 'Currency_code, ISO 4217 code',
  'dbm.documentation.description.CURRENT_RATIO': 'Current ratio',
  'dbm.documentation.description.DATE_LAST_CCJ': '-',
  'dbm.documentation.description.DIRECT_PERCENTAGE': 'Direct percentage',
  'dbm.documentation.description.DISCONTINUATION_PROBABILITY':
    'This is the predicted discontinuation rate of the company in percentage. Range: [0.0, 100.0]. The larger the percentage, the larger the risk of discontinuation.',
  'dbm.documentation.description.DISCONTINUATION_SCORE':
    'This is the predicted discontinuation score of the company. It ranges from 1 to 9. The discontinuation risk decreases from 1 to 9.',
  'dbm.documentation.description.DISSOLUTION_DATE': 'The date of the dissolution of a branch',
  'dbm.documentation.description.DO_NOT_MAIL_FLAG':
    "Indicator that a company doesn't want to receive direct mail",
  'dbm.documentation.description.DOMAIN_CODE': 'Domain code',
  'dbm.documentation.description.EMAIL': 'E-mail',
  'dbm.documentation.description.EMPLOYEE_COUNT_ANNUAL_ACCOUNT': 'Annual accounts employee count',
  'dbm.documentation.description.EMPLOYEE_COUNT_FULL_TIME':
    'The number of full-time employees in the company',
  'dbm.documentation.description.EMPLOYEE_COUNT_TOTAL':
    'The actual number of employed staff in company',
  'dbm.documentation.description.EN_DESCRIPTION': 'English description',
  'dbm.documentation.description.END_DATE': 'End date',
  'dbm.documentation.description.END_REASON_CODE': 'End reason code',
  'dbm.documentation.description.ENTERPRISE_ID': 'Unique Graydon Identification Number',
  'dbm.documentation.description.ERC':
    'Standardised score for european providers (European Risk Classification ERC)',
  'dbm.documentation.description.EVENT_REFERENCE': 'Reference',
  'dbm.documentation.description.EVENTS_TYPE_CODE': 'Events type code',
  'dbm.documentation.description.EXCUSABILITY_FLAG': 'Excusability',
  'dbm.documentation.description.EXPORT_FLAG':
    'Indicator for export activity, export Yes 34469 for NL and 627650 No',
  'dbm.documentation.description.FIGURE_VALUE': 'Key figure value',
  'dbm.documentation.description.FILED_FOR_ENTERPRISE_ID': '-',
  'dbm.documentation.description.FILED_FOR_NAME': 'Filed for name',
  'dbm.documentation.description.FILED_FOR_NL_KVK_NUMBER':
    'Unique identification - official 8 figure registered id from the Chamber of Commerce',
  'dbm.documentation.description.FILING_DATE': 'Filing date',
  'dbm.documentation.description.FIXED_CAPITAL': 'Fixed capital',
  'dbm.documentation.description.FOUND_AT_CALAMITY': '-',
  'dbm.documentation.description.FOUND_AT_CALAMITY_START_DATE': '-',
  'dbm.documentation.description.FOUND_AT_ENTERPRISE_ID': '-',
  'dbm.documentation.description.FOUND_AT_ORG_LEVEL': '-',
  'dbm.documentation.description.FOUNDATION_DATE': 'The starting date of a company, ISO 8601',
  'dbm.documentation.description.FOUNDER_FLAG':
    'When this flag is Y the shareholder and capital are from the establishment.',
  'dbm.documentation.description.FR_DESCRIPTION': 'French description',
  'dbm.documentation.description.FREE_TEXT': 'Free text',
  'dbm.documentation.description.GAZETTE_NUMBER': 'ABG number',
  'dbm.documentation.description.GB_AUGUR_SCORE': 'Augur score',
  'dbm.documentation.description.GB_CREDIT_RATING': '-',
  'dbm.documentation.description.GB_CRO_REGISTRATION_NUMBER': '-',
  'dbm.documentation.description.GB_LEGAL_FORM_CODE': '-',
  'dbm.documentation.description.GB_MAXIMUM_CREDIT_GUIDE': '-',
  'dbm.documentation.description.GB_MONTHLY_CREDIT_GUIDE': '-',
  'dbm.documentation.description.GDB_ACCOUNTING_PERIOD': '-',
  'dbm.documentation.description.GENDER_CODE': 'Gender (code) of a person',
  'dbm.documentation.description.GENDER_CODE_RECEIVER_1': 'M , F or NULL',
  'dbm.documentation.description.GENDER_CODE_RECEIVER_2': 'M , F or NULL',
  'dbm.documentation.description.GENDER_CODE_RECEIVER_3': 'M , F or NULL',
  'dbm.documentation.description.GOV_GAZETTE_DATE': 'ABG date',
  'dbm.documentation.description.GOV_GAZETTE_NUMBER': 'ABG number',
  'dbm.documentation.description.GOVERNMENT_GAZETTE_CODE': 'ABG code',
  'dbm.documentation.description.GRAYDON_RATING':
    'The Graydon rating indicates the probability of default of a company',
  'dbm.documentation.description.GROSS_MARGIN': 'The Gross margin',
  'dbm.documentation.description.GROSS_SALES_MARGIN': 'The Gross sales margin',
  'dbm.documentation.description.GROUP_EMPLOYEE_COUNT_FULL_TIME':
    'Group full time employee total count',
  'dbm.documentation.description.GROUP_EMPLOYEE_COUNT_TOTAL': 'Group employee total count',
  'dbm.documentation.description.GROWTH_SCORE':
    'This is the predicted growth score of the company.\nThere are 7 categories as follows:\n \n 1: strong decline\n 2: decline\n 3: neutral\n 4: growth\n 5: strong growth\n 99: volatile\n NR: Not Rated',
  'dbm.documentation.description.GROWTH_SEGMENT':
    'This segment indicates the level of available input data for modeling growth score.\n Companies are grouped based on the availability of input data as follows:\n \n A: the highest level of available data.\n B: the second highest level of available data.\n C: the third highest level of available data.\n D: the lowest level of available data.',
  'dbm.documentation.description.HELD_BY_ORG_ENTERPRISE_ID': 'deprecated translation key',
  'dbm.documentation.description.HIGHEST_AMOUNT_CCJ_LAST_12_MONTHS': '-',
  'dbm.documentation.description.HIGHEST_AMOUNT_CCJ_LAST_24_MONTHS': '-',
  'dbm.documentation.description.HIGHEST_AMOUNT_CCJ_LAST_36_MONTHS': '-',
  'dbm.documentation.description.HIGHEST_AMOUNT_CCJ_LAST_48_MONTHS': '-',
  'dbm.documentation.description.HIGHEST_AMOUNT_CCJ_LAST_60_MONTHS': '-',
  'dbm.documentation.description.HIGHEST_AMOUNT_CCJ_LAST_72_MONTHS': '-',
  'dbm.documentation.description.HOUSE_NUMBER': 'House number',
  'dbm.documentation.description.HOUSE_NUMBER_SUFFIX':
    'House number suffix belonging to an address',
  'dbm.documentation.description.IBAN': 'BE70645108445025',
  'dbm.documentation.description.IMPORT_FLAG':
    'Indicator for import activity, import yes = 50696 for NL and no = 611699',
  'dbm.documentation.description.INFO_CODE': 'Info code',
  'dbm.documentation.description.INSOLVENCY_DETAIL_CODE': '-',
  'dbm.documentation.description.INSOLVENCY_FORM_NAME': 'Form Name',
  'dbm.documentation.description.INSOLVENCY_FREE_TEXT': 'Free text',
  'dbm.documentation.description.INSOLVENCY_JUDGMENT_TYPE_CODE': 'Type of judgment',
  'dbm.documentation.description.INSOLVENCY_TYPE_CODE': 'Insolvency type code',
  'dbm.documentation.description.INTEREST_SIZE_DESCRIPTION': '-',
  'dbm.documentation.description.INTEREST_TYPE_DESCRIPTION': '-',
  'dbm.documentation.description.IS_ACTIVE_STATUS_CODE':
    'The status code of a company - active or not active',
  'dbm.documentation.description.IS_DISSOLVED_STATUS_CODE': 'The dissolved status of a company',
  'dbm.documentation.description.IS_ECONOMICALLY_ACTIVE_STATUS_CODE':
    'Is economically active according to the KvK',
  'dbm.documentation.description.IS_INSOLVENT_STATUS_CODE': 'The insolvency status of a company',
  'dbm.documentation.description.IS_SOHO': 'Small office / home office ',
  'dbm.documentation.description.IS_ZZP': 'Self-employed indication',
  'dbm.documentation.description.ISIC_ACTIVITY_CODE': 'ISIC 2 digits activity from SBI',
  'dbm.documentation.description.ISSUE_DATE': 'Issue date',
  'dbm.documentation.description.ISSUED_CAPITAL': 'Issued Capital',
  'dbm.documentation.description.JUDGMENT_DATE': 'Judgment date',
  'dbm.documentation.description.KEY_FIGURE_YEAR': 'Year of the most recent key figures',
  'dbm.documentation.description.LAST_NL_KVK_SOURCE_UPDATE_DATE': '-',
  'dbm.documentation.description.LATITUDE': 'GPS latitude in decimal degrees',
  'dbm.documentation.description.LEGAL_FORM_START_DATE': 'Start date of a legal form',
  'dbm.documentation.description.LEI_NUMBER':
    'The Legal Entity Identifier (LEI) is a 20-character, alpha-numeric code based on the ISO 17442 standard developed by the International Organisation for Standardisation (ISO). It connects to key reference information that enables clear and unique identification of legal entities participating in financial transactions.',
  'dbm.documentation.description.SEARCH_STATUS_DESCRIPTION': '-',
  'dbm.documentation.description.POSITION_MANDATE_AUTHORITY_DESCRIPTION': '-',
  'dbm.documentation.description.SEARCH_ROLE_DESCRIPTION': '-',
  'dbm.documentation.description.POSITION_TYPE_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_2_SEARCH_STATUS_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_2_POSITION_MANDATE_AUTHORITY_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_2_SEARCH_ROLE_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_2_POSITION_TYPE_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_2_POSITION_START_DATE': '-',
  'dbm.documentation.description.LEVEL_2_POSITION_END_DATE': '-',
  'dbm.documentation.description.LEVEL_3_SEARCH_STATUS_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_3_POSITION_MANDATE_AUTHORITY_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_3_SEARCH_ROLE_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_3_POSITION_TYPE_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_3_POSITION_START_DATE': '-',
  'dbm.documentation.description.LEVEL_3_POSITION_END_DATE': '-',
  'dbm.documentation.description.LEVEL_4_SEARCH_STATUS_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_4_POSITION_MANDATE_AUTHORITY_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_4_SEARCH_ROLE_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_4_POSITION_TYPE_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_4_POSITION_START_DATE': '-',
  'dbm.documentation.description.LEVEL_4_POSITION_END_DATE': '-',
  'dbm.documentation.description.LEVEL_5_SEARCH_STATUS_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_5_POSITION_MANDATE_AUTHORITY_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_5_SEARCH_ROLE_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_5_POSITION_TYPE_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_5_POSITION_START_DATE': '-',
  'dbm.documentation.description.LEVEL_5_POSITION_END_DATE': '-',
  'dbm.documentation.description.LEVEL_6_SEARCH_STATUS_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_6_POSITION_MANDATE_AUTHORITY_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_6_SEARCH_ROLE_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_6_POSITION_TYPE_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_6_POSITION_START_DATE': '-',
  'dbm.documentation.description.LEVEL_6_POSITION_END_DATE': '-',
  'dbm.documentation.description.LEVEL_7_SEARCH_STATUS_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_7_POSITION_MANDATE_AUTHORITY_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_7_SEARCH_ROLE_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_7_POSITION_TYPE_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_7_POSITION_START_DATE': '-',
  'dbm.documentation.description.LEVEL_7_POSITION_END_DATE': '-',
  'dbm.documentation.description.LEVEL_8_SEARCH_STATUS_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_8_POSITION_MANDATE_AUTHORITY_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_8_SEARCH_ROLE_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_8_POSITION_TYPE_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_8_POSITION_START_DATE': '-',
  'dbm.documentation.description.LEVEL_8_POSITION_END_DATE': '-',
  'dbm.documentation.description.LEVEL_9_SEARCH_STATUS_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_9_POSITION_MANDATE_AUTHORITY_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_9_SEARCH_ROLE_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_9_POSITION_TYPE_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_9_POSITION_START_DATE': '-',
  'dbm.documentation.description.LEVEL_9_POSITION_END_DATE': '-',
  'dbm.documentation.description.LEVEL_10_SEARCH_STATUS_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_10_POSITION_MANDATE_AUTHORITY_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_10_SEARCH_ROLE_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_10_POSITION_TYPE_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_10_POSITION_START_DATE': '-',
  'dbm.documentation.description.LEVEL_10_POSITION_END_DATE': '-',
  'dbm.documentation.description.LEVEL_11_SEARCH_STATUS_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_11_POSITION_MANDATE_AUTHORITY_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_11_SEARCH_ROLE_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_11_POSITION_TYPE_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_11_POSITION_START_DATE': '-',
  'dbm.documentation.description.LEVEL_11_POSITION_END_DATE': '-',
  'dbm.documentation.description.LEVEL_12_SEARCH_STATUS_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_12_POSITION_MANDATE_AUTHORITY_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_12_SEARCH_ROLE_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_12_POSITION_TYPE_DESCRIPTION': '-',
  'dbm.documentation.description.LEVEL_12_POSITION_START_DATE': '-',
  'dbm.documentation.description.LEVEL_12_POSITION_END_DATE': '-',
  'dbm.documentation.description.LEVEL_1_ORGANIZATION_NAME': '-',
  'dbm.documentation.description.LEVEL_1_COUNTRY_CODE': '-',
  'dbm.documentation.description.LEVEL_1_COUNTRY': '-',
  'dbm.documentation.description.LEVEL_2_ORGANIZATION_NAME': '-',
  'dbm.documentation.description.LEVEL_2_COUNTRY_CODE': '-',
  'dbm.documentation.description.LEVEL_2_COUNTRY': '-',
  'dbm.documentation.description.LEVEL_3_ORGANIZATION_NAME': '-',
  'dbm.documentation.description.LEVEL_3_COUNTRY_CODE': '-',
  'dbm.documentation.description.LEVEL_3_COUNTRY': '-',
  'dbm.documentation.description.LEVEL_4_ORGANIZATION_NAME': '-',
  'dbm.documentation.description.LEVEL_4_COUNTRY_CODE': '-',
  'dbm.documentation.description.LEVEL_4_COUNTRY': '-',
  'dbm.documentation.description.LEVEL_5_ORGANIZATION_NAME': '-',
  'dbm.documentation.description.LEVEL_5_COUNTRY_CODE': '-',
  'dbm.documentation.description.LEVEL_5_COUNTRY': '-',
  'dbm.documentation.description.LEVEL_6_ORGANIZATION_NAME': '-',
  'dbm.documentation.description.LEVEL_6_COUNTRY_CODE': '-',
  'dbm.documentation.description.LEVEL_6_COUNTRY': '-',
  'dbm.documentation.description.LEVEL_7_ORGANIZATION_NAME': '-',
  'dbm.documentation.description.LEVEL_7_COUNTRY_CODE': '-',
  'dbm.documentation.description.LEVEL_7_COUNTRY': '-',
  'dbm.documentation.description.LEVEL_8_ORGANIZATION_NAME': '-',
  'dbm.documentation.description.LEVEL_8_COUNTRY_CODE': '-',
  'dbm.documentation.description.LEVEL_8_COUNTRY': '-',
  'dbm.documentation.description.LEVEL_9_ORGANIZATION_NAME': '-',
  'dbm.documentation.description.LEVEL_9_COUNTRY_CODE': '-',
  'dbm.documentation.description.LEVEL_9_COUNTRY': '-',
  'dbm.documentation.description.LEVEL_10_ORGANIZATION_NAME': '-',
  'dbm.documentation.description.LEVEL_10_COUNTRY_CODE': '-',
  'dbm.documentation.description.LEVEL_10_COUNTRY': '-',
  'dbm.documentation.description.LEVEL_11_ORGANIZATION_NAME': '-',
  'dbm.documentation.description.LEVEL_11_COUNTRY_CODE': '-',
  'dbm.documentation.description.LEVEL_11_COUNTRY': '-',
  'dbm.documentation.description.LEVEL_12_ORGANIZATION_NAME': '-',
  'dbm.documentation.description.LEVEL_12_COUNTRY_CODE': '-',
  'dbm.documentation.description.LEVEL_12_COUNTRY': '-',
  'dbm.documentation.description.LEVEL_1_CALAMITY': '-',
  'dbm.documentation.description.LEVEL_1_CALAMITY_START_DATE': '-',
  'dbm.documentation.description.LEVEL_2_CALAMITY': '-',
  'dbm.documentation.description.LEVEL_2_CALAMITY_START_DATE': '-',
  'dbm.documentation.description.LEVEL_3_CALAMITY': '-',
  'dbm.documentation.description.LEVEL_3_CALAMITY_START_DATE': '-',
  'dbm.documentation.description.LEVEL_4_CALAMITY': '-',
  'dbm.documentation.description.LEVEL_4_CALAMITY_START_DATE': '-',
  'dbm.documentation.description.LEVEL_5_CALAMITY': '-',
  'dbm.documentation.description.LEVEL_5_CALAMITY_START_DATE': '-',
  'dbm.documentation.description.LEVEL_6_CALAMITY': '-',
  'dbm.documentation.description.LEVEL_6_CALAMITY_START_DATE': '-',
  'dbm.documentation.description.LEVEL_7_CALAMITY': '-',
  'dbm.documentation.description.LEVEL_7_CALAMITY_START_DATE': '-',
  'dbm.documentation.description.LEVEL_8_CALAMITY': '-',
  'dbm.documentation.description.LEVEL_8_CALAMITY_START_DATE': '-',
  'dbm.documentation.description.LEVEL_9_CALAMITY': '-',
  'dbm.documentation.description.LEVEL_9_CALAMITY_START_DATE': '-',
  'dbm.documentation.description.LEVEL_10_CALAMITY': '-',
  'dbm.documentation.description.LEVEL_10_CALAMITY_START_DATE': '-',
  'dbm.documentation.description.LEVEL_11_CALAMITY': '-',
  'dbm.documentation.description.LEVEL_11_CALAMITY_START_DATE': '-',
  'dbm.documentation.description.LEVEL_12_CALAMITY': '-',
  'dbm.documentation.description.LEVEL_12_CALAMITY_START_DATE': '-',
  'dbm.documentation.description.LEVEL_1_ENTERPRISE_ID': '-',
  'dbm.documentation.description.LEVEL_1_NL_KVK_NUMBER':
    'Unique identification - official 8 figure registered id from the Chamber of Commerce',
  'dbm.documentation.description.LEVEL_1_SEARCH_ROLE_CODE': '-',
  'dbm.documentation.description.LEVEL_1_SEARCH_STATUS_CODE': '-',
  'dbm.documentation.description.LEVEL_10_DIRECT_PERCENTAGE': '-',
  'dbm.documentation.description.LEVEL_10_ENTERPRISE_ID': '-',
  'dbm.documentation.description.LEVEL_10_INDIRECT_PERCENTAGE_SUBJECT': '-',
  'dbm.documentation.description.LEVEL_10_MANDATE_AUTHORITY_CODE': '-',
  'dbm.documentation.description.LEVEL_10_NL_KVK_NUMBER':
    'Unique identification - official 8 figure registered id from the Chamber of Commerce',
  'dbm.documentation.description.LEVEL_10_ORG_ADDRESS_LINE_1': '-',
  'dbm.documentation.description.LEVEL_10_ORG_ADDRESS_LINE_2': '-',
  'dbm.documentation.description.LEVEL_10_ORG_ADDRESS_LINE_3': '-',
  'dbm.documentation.description.LEVEL_10_ORG_ADDRESS_LINE_4': '-',
  'dbm.documentation.description.LEVEL_10_ORG_ADDRESS_LINE_5': '-',
  'dbm.documentation.description.LEVEL_10_ORG_NAME': '-',
  'dbm.documentation.description.LEVEL_10_POSITION_TITLE_CODE': '-',
  'dbm.documentation.description.LEVEL_10_POSITION_TYPE_CODE': '-',
  'dbm.documentation.description.LEVEL_10_ROLE_TYPE_CODE': '-',
  'dbm.documentation.description.LEVEL_10_SEARCH_ROLE_CODE': '-',
  'dbm.documentation.description.LEVEL_10_SEARCH_STATUS_CODE': '-',
  'dbm.documentation.description.LEVEL_10_START_DATE': '-',
  'dbm.documentation.description.LEVEL_11_DIRECT_PERCENTAGE': 'Direct percentage',
  'dbm.documentation.description.LEVEL_11_ENTERPRISE_ID': '-',
  'dbm.documentation.description.LEVEL_11_INDIRECT_PERCENTAGE_SUBJECT': '-',
  'dbm.documentation.description.LEVEL_11_MANDATE_AUTHORITY_CODE': '-',
  'dbm.documentation.description.LEVEL_11_NL_KVK_NUMBER':
    'Unique identification - official 8 figure registered id from the Chamber of Commerce',
  'dbm.documentation.description.LEVEL_11_ORG_ADDRESS_LINE_1': '-',
  'dbm.documentation.description.LEVEL_11_ORG_ADDRESS_LINE_2': '-',
  'dbm.documentation.description.LEVEL_11_ORG_ADDRESS_LINE_3': '-',
  'dbm.documentation.description.LEVEL_11_ORG_ADDRESS_LINE_4': '-',
  'dbm.documentation.description.LEVEL_11_ORG_ADDRESS_LINE_5': '-',
  'dbm.documentation.description.LEVEL_11_ORG_NAME': '-',
  'dbm.documentation.description.LEVEL_11_POSITION_TITLE_CODE': '-',
  'dbm.documentation.description.LEVEL_11_POSITION_TYPE_CODE': '-',
  'dbm.documentation.description.LEVEL_11_ROLE_TYPE_CODE': '-',
  'dbm.documentation.description.LEVEL_11_SEARCH_ROLE_CODE': '-',
  'dbm.documentation.description.LEVEL_11_SEARCH_STATUS_CODE': '-',
  'dbm.documentation.description.LEVEL_11_START_DATE': '-',
  'dbm.documentation.description.LEVEL_12_DIRECT_PERCENTAGE': 'Direct percentage',
  'dbm.documentation.description.LEVEL_12_ENTERPRISE_ID': '-',
  'dbm.documentation.description.LEVEL_12_INDIRECT_PERCENTAGE_SUBJECT': '-',
  'dbm.documentation.description.LEVEL_12_MANDATE_AUTHORITY_CODE': '-',
  'dbm.documentation.description.LEVEL_12_NL_KVK_NUMBER':
    'Unique identification - official 8 figure registered id from the Chamber of Commerce',
  'dbm.documentation.description.LEVEL_12_ORG_ADDRESS_LINE_1': '-',
  'dbm.documentation.description.LEVEL_12_ORG_ADDRESS_LINE_2': '-',
  'dbm.documentation.description.LEVEL_12_ORG_ADDRESS_LINE_3': '-',
  'dbm.documentation.description.LEVEL_12_ORG_ADDRESS_LINE_4': '-',
  'dbm.documentation.description.LEVEL_12_ORG_ADDRESS_LINE_5': '-',
  'dbm.documentation.description.LEVEL_12_ORG_NAME': '-',
  'dbm.documentation.description.LEVEL_12_POSITION_TITLE_CODE': '-',
  'dbm.documentation.description.LEVEL_12_POSITION_TYPE_CODE': '-',
  'dbm.documentation.description.LEVEL_12_ROLE_TYPE_CODE': '-',
  'dbm.documentation.description.LEVEL_12_SEARCH_ROLE_CODE': '-',
  'dbm.documentation.description.LEVEL_12_SEARCH_STATUS_CODE': '-',
  'dbm.documentation.description.LEVEL_12_START_DATE': '-',
  'dbm.documentation.description.LEVEL_2_DIRECT_PERCENTAGE': 'Direct percentage',
  'dbm.documentation.description.LEVEL_2_ENTERPRISE_ID': '-',
  'dbm.documentation.description.LEVEL_2_INDIRECT_PERCENTAGE_SUBJECT': '-',
  'dbm.documentation.description.LEVEL_2_MANDATE_AUTHORITY_CODE': '-',
  'dbm.documentation.description.LEVEL_2_NL_KVK_NUMBER':
    'Unique identification - official 8 figure registered id from the Chamber of Commerce',
  'dbm.documentation.description.LEVEL_2_ORG_ADDRESS_LINE_1': '-',
  'dbm.documentation.description.LEVEL_2_ORG_ADDRESS_LINE_2': '-',
  'dbm.documentation.description.LEVEL_2_ORG_ADDRESS_LINE_3': '-',
  'dbm.documentation.description.LEVEL_2_ORG_ADDRESS_LINE_4': '-',
  'dbm.documentation.description.LEVEL_2_ORG_ADDRESS_LINE_5': '-',
  'dbm.documentation.description.LEVEL_2_ORG_NAME': '-',
  'dbm.documentation.description.LEVEL_2_POSITION_TITLE_CODE': '-',
  'dbm.documentation.description.LEVEL_2_POSITION_TYPE_CODE': '-',
  'dbm.documentation.description.LEVEL_2_ROLE_TYPE_CODE': '-',
  'dbm.documentation.description.LEVEL_2_SEARCH_ROLE_CODE': '-',
  'dbm.documentation.description.LEVEL_2_SEARCH_STATUS_CODE': '-',
  'dbm.documentation.description.LEVEL_2_START_DATE': '-',
  'dbm.documentation.description.LEVEL_3_DIRECT_PERCENTAGE': 'Direct percentage',
  'dbm.documentation.description.LEVEL_3_ENTERPRISE_ID': '-',
  'dbm.documentation.description.LEVEL_3_INDIRECT_PERCENTAGE_SUBJECT': '-',
  'dbm.documentation.description.LEVEL_3_MANDATE_AUTHORITY_CODE': '-',
  'dbm.documentation.description.LEVEL_3_NL_KVK_NUMBER':
    'Unique identification - official 8 figure registered id from the Chamber of Commerce',
  'dbm.documentation.description.LEVEL_3_ORG_ADDRESS_LINE_1': '-',
  'dbm.documentation.description.LEVEL_3_ORG_ADDRESS_LINE_2': '-',
  'dbm.documentation.description.LEVEL_3_ORG_ADDRESS_LINE_3': '-',
  'dbm.documentation.description.LEVEL_3_ORG_ADDRESS_LINE_4': '-',
  'dbm.documentation.description.LEVEL_3_ORG_ADDRESS_LINE_5': '-',
  'dbm.documentation.description.LEVEL_3_ORG_NAME': '-',
  'dbm.documentation.description.LEVEL_3_POSITION_TITLE_CODE': '-',
  'dbm.documentation.description.LEVEL_3_POSITION_TYPE_CODE': '-',
  'dbm.documentation.description.LEVEL_3_ROLE_TYPE_CODE': '-',
  'dbm.documentation.description.LEVEL_3_SEARCH_ROLE_CODE': '-',
  'dbm.documentation.description.LEVEL_3_SEARCH_STATUS_CODE': '-',
  'dbm.documentation.description.LEVEL_3_START_DATE': '-',
  'dbm.documentation.description.LEVEL_4_DIRECT_PERCENTAGE': '-',
  'dbm.documentation.description.LEVEL_4_ENTERPRISE_ID': '-',
  'dbm.documentation.description.LEVEL_4_INDIRECT_PERCENTAGE_SUBJECT': '-',
  'dbm.documentation.description.LEVEL_4_MANDATE_AUTHORITY_CODE': '-',
  'dbm.documentation.description.LEVEL_4_NL_KVK_NUMBER':
    'Unique identification - official 8 figure registered id from the Chamber of Commerce',
  'dbm.documentation.description.LEVEL_4_ORG_ADDRESS_LINE_1': '-',
  'dbm.documentation.description.LEVEL_4_ORG_ADDRESS_LINE_2': '-',
  'dbm.documentation.description.LEVEL_4_ORG_ADDRESS_LINE_3': '-',
  'dbm.documentation.description.LEVEL_4_ORG_ADDRESS_LINE_4': '-',
  'dbm.documentation.description.LEVEL_4_ORG_ADDRESS_LINE_5': '-',
  'dbm.documentation.description.LEVEL_4_ORG_NAME': '-',
  'dbm.documentation.description.LEVEL_4_POSITION_TITLE_CODE': '-',
  'dbm.documentation.description.LEVEL_4_POSITION_TYPE_CODE': '-',
  'dbm.documentation.description.LEVEL_4_ROLE_TYPE_CODE': '-',
  'dbm.documentation.description.LEVEL_4_SEARCH_ROLE_CODE': '-',
  'dbm.documentation.description.LEVEL_4_SEARCH_STATUS_CODE': '-',
  'dbm.documentation.description.LEVEL_4_START_DATE': '-',
  'dbm.documentation.description.LEVEL_5_DIRECT_PERCENTAGE': '-',
  'dbm.documentation.description.LEVEL_5_ENTERPRISE_ID': '-',
  'dbm.documentation.description.LEVEL_5_INDIRECT_PERCENTAGE_SUBJECT': '-',
  'dbm.documentation.description.LEVEL_5_MANDATE_AUTHORITY_CODE': '-',
  'dbm.documentation.description.LEVEL_5_NL_KVK_NUMBER':
    'Unique identification - official 8 figure registered id from the Chamber of Commerce',
  'dbm.documentation.description.LEVEL_5_ORG_ADDRESS_LINE_1': '-',
  'dbm.documentation.description.LEVEL_5_ORG_ADDRESS_LINE_2': '-',
  'dbm.documentation.description.LEVEL_5_ORG_ADDRESS_LINE_3': '-',
  'dbm.documentation.description.LEVEL_5_ORG_ADDRESS_LINE_4': '-',
  'dbm.documentation.description.LEVEL_5_ORG_ADDRESS_LINE_5': '-',
  'dbm.documentation.description.LEVEL_5_ORG_NAME': '-',
  'dbm.documentation.description.LEVEL_5_POSITION_TITLE_CODE': '-',
  'dbm.documentation.description.LEVEL_5_POSITION_TYPE_CODE': '-',
  'dbm.documentation.description.LEVEL_5_ROLE_TYPE_CODE': '-',
  'dbm.documentation.description.LEVEL_5_SEARCH_ROLE_CODE': '-',
  'dbm.documentation.description.LEVEL_5_SEARCH_STATUS_CODE': '-',
  'dbm.documentation.description.LEVEL_5_START_DATE': '-',
  'dbm.documentation.description.LEVEL_6_DIRECT_PERCENTAGE': '-',
  'dbm.documentation.description.LEVEL_6_ENTERPRISE_ID': '-',
  'dbm.documentation.description.LEVEL_6_INDIRECT_PERCENTAGE_SUBJECT': '-',
  'dbm.documentation.description.LEVEL_6_MANDATE_AUTHORITY_CODE': '-',
  'dbm.documentation.description.LEVEL_6_NL_KVK_NUMBER':
    'Unique identification - official 8 figure registered id from the Chamber of Commerce',
  'dbm.documentation.description.LEVEL_6_ORG_ADDRESS_LINE_1': '-',
  'dbm.documentation.description.LEVEL_6_ORG_ADDRESS_LINE_2': '-',
  'dbm.documentation.description.LEVEL_6_ORG_ADDRESS_LINE_3': '-',
  'dbm.documentation.description.LEVEL_6_ORG_ADDRESS_LINE_4': '-',
  'dbm.documentation.description.LEVEL_6_ORG_ADDRESS_LINE_5': '-',
  'dbm.documentation.description.LEVEL_6_ORG_NAME': '-',
  'dbm.documentation.description.LEVEL_6_POSITION_TITLE_CODE': '-',
  'dbm.documentation.description.LEVEL_6_POSITION_TYPE_CODE': '-',
  'dbm.documentation.description.LEVEL_6_ROLE_TYPE_CODE': '-',
  'dbm.documentation.description.LEVEL_6_SEARCH_ROLE_CODE': '-',
  'dbm.documentation.description.LEVEL_6_SEARCH_STATUS_CODE': '-',
  'dbm.documentation.description.LEVEL_6_START_DATE': '-',
  'dbm.documentation.description.LEVEL_7_DIRECT_PERCENTAGE': 'Direct percentage',
  'dbm.documentation.description.LEVEL_7_ENTERPRISE_ID': '-',
  'dbm.documentation.description.LEVEL_7_INDIRECT_PERCENTAGE_SUBJECT': '-',
  'dbm.documentation.description.LEVEL_7_MANDATE_AUTHORITY_CODE': '-',
  'dbm.documentation.description.LEVEL_7_NL_KVK_NUMBER':
    'Unique identification - official 8 figure registered id from the Chamber of Commerce',
  'dbm.documentation.description.LEVEL_7_ORG_ADDRESS_LINE_1': '-',
  'dbm.documentation.description.LEVEL_7_ORG_ADDRESS_LINE_2': '-',
  'dbm.documentation.description.LEVEL_7_ORG_ADDRESS_LINE_3': '-',
  'dbm.documentation.description.LEVEL_7_ORG_ADDRESS_LINE_4': '-',
  'dbm.documentation.description.LEVEL_7_ORG_ADDRESS_LINE_5': '-',
  'dbm.documentation.description.LEVEL_7_ORG_NAME': '-',
  'dbm.documentation.description.LEVEL_7_POSITION_TITLE_CODE': '-',
  'dbm.documentation.description.LEVEL_7_POSITION_TYPE_CODE': '-',
  'dbm.documentation.description.LEVEL_7_ROLE_TYPE_CODE': '-',
  'dbm.documentation.description.LEVEL_7_SEARCH_ROLE_CODE': '-',
  'dbm.documentation.description.LEVEL_7_SEARCH_STATUS_CODE': '-',
  'dbm.documentation.description.LEVEL_7_START_DATE': '-',
  'dbm.documentation.description.LEVEL_8_DIRECT_PERCENTAGE': 'Direct percentage',
  'dbm.documentation.description.LEVEL_8_ENTERPRISE_ID': '-',
  'dbm.documentation.description.LEVEL_8_INDIRECT_PERCENTAGE_SUBJECT': '-',
  'dbm.documentation.description.LEVEL_8_MANDATE_AUTHORITY_CODE': '-',
  'dbm.documentation.description.LEVEL_8_NL_KVK_NUMBER':
    'Unique identification - official 8 figure registered id from the Chamber of Commerce',
  'dbm.documentation.description.LEVEL_8_ORG_ADDRESS_LINE_1': '-',
  'dbm.documentation.description.LEVEL_8_ORG_ADDRESS_LINE_2': '-',
  'dbm.documentation.description.LEVEL_8_ORG_ADDRESS_LINE_3': '-',
  'dbm.documentation.description.LEVEL_8_ORG_ADDRESS_LINE_4': '-',
  'dbm.documentation.description.LEVEL_8_ORG_ADDRESS_LINE_5': '-',
  'dbm.documentation.description.LEVEL_8_ORG_NAME': '-',
  'dbm.documentation.description.LEVEL_8_POSITION_TITLE_CODE': '-',
  'dbm.documentation.description.LEVEL_8_POSITION_TYPE_CODE': '-',
  'dbm.documentation.description.LEVEL_8_ROLE_TYPE_CODE': '-',
  'dbm.documentation.description.LEVEL_8_SEARCH_ROLE_CODE': '-',
  'dbm.documentation.description.LEVEL_8_SEARCH_STATUS_CODE': '-',
  'dbm.documentation.description.LEVEL_8_START_DATE': '-',
  'dbm.documentation.description.LEVEL_9_DIRECT_PERCENTAGE': 'Direct percentage',
  'dbm.documentation.description.LEVEL_9_ENTERPRISE_ID': '-',
  'dbm.documentation.description.LEVEL_9_INDIRECT_PERCENTAGE_SUBJECT': '-',
  'dbm.documentation.description.LEVEL_9_MANDATE_AUTHORITY_CODE': '-',
  'dbm.documentation.description.LEVEL_9_NL_KVK_NUMBER':
    'Unique identification - official 8 figure registered id from the Chamber of Commerce',
  'dbm.documentation.description.LEVEL_9_ORG_ADDRESS_LINE_1': '-',
  'dbm.documentation.description.LEVEL_9_ORG_ADDRESS_LINE_2': '-',
  'dbm.documentation.description.LEVEL_9_ORG_ADDRESS_LINE_3': '-',
  'dbm.documentation.description.LEVEL_9_ORG_ADDRESS_LINE_4': '-',
  'dbm.documentation.description.LEVEL_9_ORG_ADDRESS_LINE_5': '-',
  'dbm.documentation.description.LEVEL_9_ORG_NAME': '-',
  'dbm.documentation.description.LEVEL_9_POSITION_TITLE_CODE': '-',
  'dbm.documentation.description.LEVEL_9_POSITION_TYPE_CODE': '-',
  'dbm.documentation.description.LEVEL_9_ROLE_TYPE_CODE': '-',
  'dbm.documentation.description.LEVEL_9_SEARCH_ROLE_CODE': '-',
  'dbm.documentation.description.LEVEL_9_SEARCH_STATUS_CODE': '-',
  'dbm.documentation.description.LEVEL_9_START_DATE': '-',
  'dbm.documentation.description.LIST_MGT_ORG_ID': '-',
  'dbm.documentation.description.LONGITUDE': 'GPS longitude in decimal degrees.',
  'dbm.documentation.description.MEETING_DATE': 'General meeting date',
  'dbm.documentation.description.MOBILE': 'Mobile number',
  'dbm.documentation.description.NACE_ACTIVITY_CODE': 'Nace v2 activity from sbi',
  'dbm.documentation.description.NAME': 'Organisation name',
  'dbm.documentation.description.NET_RESULT': 'The net result',
  'dbm.documentation.description.NL_DESCRIPTION': 'Dutch description',
  'dbm.documentation.description.NL_KVK_BRANCH_NUMBER':
    'Each branch_office of a company or legal entity in the Trade Register has a unique branch number of 12 digits.',
  'dbm.documentation.description.NL_KVK_NUMBER':
    'Unique identification - official 8 figure registered id from the Chamber of Commerce',
  'dbm.documentation.description.NL_KVK_NUMBER_12':
    'Official 12 figure registered id from the Chamber of Commerce',
  'dbm.documentation.description.NL_LEGAL_FORM_CODE':
    'The legal form of a company. Type code as available in Graydon database',
  'dbm.documentation.description.NL_RSIN':
    'Upon registration with the Chamber of Commerce, all legal entities and associations, such as private limited companies, associations, foundations, general partnerships and partnerships (but not sole proprietorships, i.e. one man companies), are given a Chamber of Commerce number and also an identification number for legal entities and associations (RSIN: legal persons and partnerships infomation number). This RSIN is used to exchange data with other government organisations, such as the Tax and Customs Administration.',
  'dbm.documentation.description.NOMINAL_VALUE': 'Nominal value',
  'dbm.documentation.description.NOTICE_EVENT_DATE': 'Event date',
  'dbm.documentation.description.NUM_BRANCH_OFFICES': 'Number of branch offices of the enterprise',
  'dbm.documentation.description.NUM_CCJ_LAST_12_MONTHS': '-',
  'dbm.documentation.description.NUM_CCJ_LAST_24_MONTHS': '-',
  'dbm.documentation.description.NUM_CCJ_LAST_36_MONTHS': '-',
  'dbm.documentation.description.NUM_CCJ_LAST_48_MONTHS': '-',
  'dbm.documentation.description.NUM_CCJ_LAST_60_MONTHS': '-',
  'dbm.documentation.description.NUM_CCJ_LAST_72_MONTHS': '-',
  'dbm.documentation.description.NUM_EMPLOYEES_SIZE_CLASS_CODE': '-',
  'dbm.documentation.description.NUM_OF_SUPPLIERS': 'Number of suppliers',
  'dbm.documentation.description.NUM_PAYMENT_EXPERIENCES': 'Number of payment experiences',
  'dbm.documentation.description.NUM_SHARES':
    'The number of shares a shareholder holds in the requested company',
  'dbm.documentation.description.ORGANIZATION_ID': 'Unique Graydon Identification Number',
  'dbm.documentation.description.ORGANIZATION_NAME': 'Name',
  'dbm.documentation.description.ORGANIZATION_NAME_TYPE_CODE': 'Type of organization name',
  'dbm.documentation.description.ORGANIZATION_TYPE_CODE':
    'type of organization , ROOT_ORG or BRANCH_OFFICE',
  'dbm.documentation.description.ORIGIN_CODE': 'Source code',
  'dbm.documentation.description.OTHER_ADDR_LINE_1': '-',
  'dbm.documentation.description.OTHER_ADDR_LINE_2': '-',
  'dbm.documentation.description.OTHER_ADDR_LINE_3': '-',
  'dbm.documentation.description.OTHER_ADDR_LINE_4': '-',
  'dbm.documentation.description.OTHER_ADDR_LINE_5': '-',
  'dbm.documentation.description.OTHER_BE_ENTERPRISE_NUMBER': 'Other enterprise number',
  'dbm.documentation.description.OTHER_ENTERPRISE_ID':
    'GDB organisation YUID of other organisation in external relationship.',
  'dbm.documentation.description.OTHER_GB_CRO_REGISTRATION_NUMBER': '-',
  'dbm.documentation.description.OTHER_NAME': '-',
  'dbm.documentation.description.OTHER_NL_KVK_NUMBER':
    'Unique identification - official 8 figure registered id from the Chamber of Commerce',
  'dbm.documentation.description.OTHER_ORG_ADDRESS_LINE_1':
    'Address line 1 for other organisation in external relationship.',
  'dbm.documentation.description.OTHER_ORG_ADDRESS_LINE_2':
    'Address line 2 for other organisation in external relationship.',
  'dbm.documentation.description.OTHER_ORG_ADDRESS_LINE_3':
    'Address line 3 for other organisation in external relationship.',
  'dbm.documentation.description.OTHER_ORG_ADDRESS_LINE_4':
    'Address line 4 for other organisation in external relationship.',
  'dbm.documentation.description.OTHER_ORG_ADDRESS_LINE_5':
    'Address line 5 for other organisation in external relationship.',
  'dbm.documentation.description.OTHER_ORG_NAME':
    'Name of other organisation in external relationship.',
  'dbm.documentation.description.PAID_UP_CAPITAL': 'Paid up capital',
  'dbm.documentation.description.PAID_UP_CONTRIBUTION': 'Paid up contribution',
  'dbm.documentation.description.PAID_UP_FIXED_CAPITAL': 'Paid up fixed capital',
  'dbm.documentation.description.PAID_UP_VARIABLE_CAPITAL': 'Paid up variable capital',
  'dbm.documentation.description.PART_ADDR_LINE_1': 'Foreign Address line 1',
  'dbm.documentation.description.PART_ADDR_LINE_2': 'Foreign Address line 2',
  'dbm.documentation.description.PART_ADDR_LINE_3': 'Foreign Address line 3',
  'dbm.documentation.description.PART_ADDR_LINE_4': 'Foreign Address line 4',
  'dbm.documentation.description.PART_ADDR_LINE_5': 'Foreign Address line 5',
  'dbm.documentation.description.PART_BE_ENTERPRISE_NUMBER':
    'CBE Crossroads Bank for Enterprises number',
  'dbm.documentation.description.PART_ENTERPRISE_ID':
    'Unique identification within the Graydon Database',
  'dbm.documentation.description.PART_NAME': 'Shareholder name',
  'dbm.documentation.description.PART_NL_KVK_NUMBER':
    'Unique identification - official 8 figure registered id from the Chamber of Commerce',
  'dbm.documentation.description.PARTNERSHIP_CAPITAL': 'Partnership capital',
  'dbm.documentation.description.PAYMENT_SCORE':
    'The payment score indicates the payment behaviour of the company',
  'dbm.documentation.description.PCT_OVER_90_DBT': 'Percentage over 90',
  'dbm.documentation.description.PCT_TO_30_DBT': 'Percentage 1 to 30',
  'dbm.documentation.description.PCT_TO_60_DBT': 'Percentage 31 to 60',
  'dbm.documentation.description.PCT_TO_90_DBT': 'Percentage 61 to 90',
  'dbm.documentation.description.PCT_WITHIN_TERMS': 'Payments within terms',
  'dbm.documentation.description.PD_PERCENTAGE': 'The probability of default as a percentage',
  'dbm.documentation.description.PERSON_BIRTH_CITY': 'Place of birth',
  'dbm.documentation.description.PERSON_BIRTH_COUNTRY_CODE':
    'The country ISO-code a person is born in AVG/GDPR',
  'dbm.documentation.description.PERSON_BIRTH_DATE': 'The birth date of a person .',
  'dbm.documentation.description.PERSON_BIRTH_NDATE':
    'Unformatted date of birth of the person in a management position. This date is not checked and directly from source. UK only',
  'dbm.documentation.description.PERSON_CITY': 'City name',
  'dbm.documentation.description.PERSON_COUNTRY_CODE':
    'ISO alpha-2 country code of origin (origin of official source)',
  'dbm.documentation.description.PERSON_DEATH_DATE': 'Date of death',
  'dbm.documentation.description.PERSON_DEATH_NDATE':
    'The date of death of a person (unformatted date)',
  'dbm.documentation.description.PERSON_DETAILS_PROTECTED': '-',
  'dbm.documentation.description.PERSON_FIRST_NAMES': 'First name',
  'dbm.documentation.description.PERSON_FULL_NAME': 'Full name',
  'dbm.documentation.description.PERSON_GENDER_CODE': 'Gender (code) of a person (M, F, X)',
  'dbm.documentation.description.PERSON_HOUSE_NUMBER': 'House number',
  'dbm.documentation.description.PERSON_HOUSE_NUMBER_SUFFIX':
    'House number suffix belonging to an address',
  'dbm.documentation.description.PERSON_ID': 'External person id created by Graydon',
  'dbm.documentation.description.PERSON_INITIALS': 'Initials',
  'dbm.documentation.description.PERSON_POSTCODE': 'Postal Code',
  'dbm.documentation.description.PERSON_STREET': 'Street name',
  'dbm.documentation.description.PERSON_STREET_CODE': 'Belgian street code',
  'dbm.documentation.description.PERSON_SURNAME': 'Last name',
  'dbm.documentation.description.PERSON_SURNAME_PREFIX':
    'The prefix that comes between the first name and surname',
  'dbm.documentation.description.PERSON_TITLES': 'Title',
  'dbm.documentation.description.PO_BOX_CITY': 'City name',
  'dbm.documentation.description.PO_BOX_END_DATE':
    'Date specifies when this company stopped using this address',
  'dbm.documentation.description.PO_BOX_NUMBER': 'PO box number of the Organisation',
  'dbm.documentation.description.PO_BOX_POSTCODE': 'Postal Code',
  'dbm.documentation.description.PO_BOX_START_DATE':
    'Date specifies when this company started using this address',
  'dbm.documentation.description.POSITION_END_DATE': 'position mandate end date',
  'dbm.documentation.description.POSITION_HOLDER_ADDR_LINE_1': 'Address line 1',
  'dbm.documentation.description.POSITION_HOLDER_ADDR_LINE_2': 'Address line 2',
  'dbm.documentation.description.POSITION_HOLDER_ADDR_LINE_3': 'Address line 3',
  'dbm.documentation.description.POSITION_HOLDER_ADDR_LINE_4': 'Address line 4',
  'dbm.documentation.description.POSITION_HOLDER_ADDR_LINE_5': 'Address line 5',
  'dbm.documentation.description.POSITION_HOLDER_BE_ENTERPRISE_NUMBER': 'Enterprises number',
  'dbm.documentation.description.POSITION_HOLDER_ENTERPRISE_ID':
    'Unique identification within the Graydon Database',
  'dbm.documentation.description.POSITION_HOLDER_NAME': 'Name',
  'dbm.documentation.description.POSITION_HOLDER_NL_KVK_NUMBER':
    'Unique identification - official 8 figure registered id from the Chamber of Commerce',
  'dbm.documentation.description.POSITION_MANDATE_AUTHORITY_CODE': 'Authority code',
  'dbm.documentation.description.POSITION_MANDATE_TEXT': 'Authority text',
  'dbm.documentation.description.POSITION_START_DATE': 'Start date of position mandate',
  'dbm.documentation.description.POSITION_TITLE':
    'The free text description of a management position',
  'dbm.documentation.description.POSITION_TITLE_CODE': 'Position title code',
  'dbm.documentation.description.POSITION_TYPE_CODE': 'Type of position',
  'dbm.documentation.description.POSTCODE': 'Postal Code',
  'dbm.documentation.description.PREFERRED_LANGUAGE_CODE':
    'Preferred language, Belgium only, ISO 639-1 Code',
  'dbm.documentation.description.PRELIMINARY_FLAG': 'Preliminary flag',
  'dbm.documentation.description.PRIMARY_ACTIVITY_CODE_1':
    'A standard code that identifies the activities of the company (primary)',
  'dbm.documentation.description.QUARTER': 'Quarter 1, 2 , 3 or 4',
  'dbm.documentation.description.QUICK_RATIO': 'The quick ratio of the company',
  'dbm.documentation.description.REASON_CODE': 'Reason code',
  'dbm.documentation.description.RECEIVER_1': 'Receiver / Agent of justice',
  'dbm.documentation.description.RECEIVER_1_END_DATE': 'end date curator',
  'dbm.documentation.description.RECEIVER_1_TYPE_CODE': '-',
  'dbm.documentation.description.RECEIVER_10': 'Receiver / Agent of justice',
  'dbm.documentation.description.RECEIVER_2': 'Receiver / Agent of justice',
  'dbm.documentation.description.RECEIVER_2_END_DATE': 'end date curator',
  'dbm.documentation.description.RECEIVER_2_TYPE_CODE': '-',
  'dbm.documentation.description.RECEIVER_3': 'Receiver / Agent of justice',
  'dbm.documentation.description.RECEIVER_3_END_DATE': 'end date curator',
  'dbm.documentation.description.RECEIVER_3_TYPE_CODE': '-',
  'dbm.documentation.description.RECEIVER_4': 'Receiver / Agent of justice',
  'dbm.documentation.description.RECEIVER_4_TYPE_CODE': '-',
  'dbm.documentation.description.RECEIVER_5': 'Receiver / Agent of justice',
  'dbm.documentation.description.RECEIVER_5_TYPE_CODE': '-',
  'dbm.documentation.description.RECEIVER_6': 'Receiver / Agent of justice',
  'dbm.documentation.description.RECEIVER_7': 'Receiver / Agent of justice',
  'dbm.documentation.description.RECEIVER_8': 'Receiver / Agent of justice',
  'dbm.documentation.description.RECEIVER_9': 'Receiver / Agent of justice',
  'dbm.documentation.description.RECIEVER': 'Receiver / Agent of justice',
  'dbm.documentation.description.RECIEVER_TYPE_CODE': 'Position code in insolvency procedure',
  'dbm.documentation.description.REFERENCE': 'Your reference',
  'dbm.documentation.description.REGION':
    'Company registration region code (Flanders, Brussels, Walloon)',
  'dbm.documentation.description.RELATION_TYPE_CODE': 'Relation type code',
  'dbm.documentation.description.REPRESENTATIVE_PERSON_BIRTH_CITY': 'Place of birth',
  'dbm.documentation.description.REPRESENTATIVE_PERSON_BIRTH_COUNTRY_CODE':
    'The country ISO-code a person is born in AVG/GDPR',
  'dbm.documentation.description.REPRESENTATIVE_PERSON_BIRTH_DATE': 'Birth date of the person',
  'dbm.documentation.description.REPRESENTATIVE_PERSON_BIRTH_NDATE':
    'Unformatted date of birth of the person in a management position. This date is not checked and directly from source. UK only',
  'dbm.documentation.description.REPRESENTATIVE_PERSON_CITY': 'City name',
  'dbm.documentation.description.REPRESENTATIVE_PERSON_COUNTRY_CODE':
    'ISO alpha-2 country code of origin (origin of official source)',
  'dbm.documentation.description.REPRESENTATIVE_PERSON_DEATH_DATE': 'The date of death',
  'dbm.documentation.description.REPRESENTATIVE_PERSON_DEATH_NDATE':
    'The date of death - unformatted',
  'dbm.documentation.description.REPRESENTATIVE_PERSON_FIRST_NAMES': 'Given name of representative',
  'dbm.documentation.description.REPRESENTATIVE_PERSON_FULL_NAME': 'The complete name of a person',
  'dbm.documentation.description.REPRESENTATIVE_PERSON_GENDER_CODE': 'Gender code (M, F, X)',
  'dbm.documentation.description.REPRESENTATIVE_PERSON_HOUSE_NUMBER': 'House number',
  'dbm.documentation.description.REPRESENTATIVE_PERSON_HOUSE_NUMBER_SUFFIX':
    'House number suffix belonging to an address',
  'dbm.documentation.description.REPRESENTATIVE_PERSON_ID': 'Graydon representative person id',
  'dbm.documentation.description.REPRESENTATIVE_PERSON_INITIALS': 'Initials',
  'dbm.documentation.description.REPRESENTATIVE_PERSON_POSTCODE': 'Postal Code',
  'dbm.documentation.description.REPRESENTATIVE_PERSON_STREET': 'Street name',
  'dbm.documentation.description.REPRESENTATIVE_PERSON_STREET_CODE': 'Belgian street code',
  'dbm.documentation.description.REPRESENTATIVE_PERSON_SURNAME': 'Representative surname',
  'dbm.documentation.description.REPRESENTATIVE_PERSON_SURNAME_PREFIX':
    'The prefix that comes between the first name and surname',
  'dbm.documentation.description.REPRESENTATIVE_PERSON_TITLES': 'Title',
  'dbm.documentation.description.RESULT_SEARCH_STATUS_CODE': '-',
  'dbm.documentation.description.ROW_TYPE':
    'Special field indicating the type of data in the row. See technical documentation.',
  'dbm.documentation.description.RSZ_NUMBER': 'NSSO number',
  'dbm.documentation.description.SBI_ACTIVITY_CODE':
    'A standard code that identifies the activities of the company based on sbi2008',
  'dbm.documentation.description.SEARCH_ROLE_CODE': '-',
  'dbm.documentation.description.SECONDARY_ACTIVITY_CODE_1':
    'A standard code that identifies the activities of the company (primary)',
  'dbm.documentation.description.SECONDARY_ACTIVITY_CODE_2':
    'A standard code that identifies the activities of the company (primary)',
  'dbm.documentation.description.SECONDARY_ACTIVITY_CODE_3':
    'A standard code that identifies the activities of the company (primary)',
  'dbm.documentation.description.SECTOR_CODE': 'Code identifying an industry / sector',
  'dbm.documentation.description.SEQUENCE_NUMBER': '-',
  'dbm.documentation.description.SHARE_PERCENTAGE': 'Percentage of shares held',
  'dbm.documentation.description.SHARE_TYPE_CODE': 'type of share',
  'dbm.documentation.description.SHAREHOLDER_ADDR_LINE_1':
    'Address line 1 of address of shareholder. Foreign address',
  'dbm.documentation.description.SHAREHOLDER_ADDR_LINE_2':
    'Address line 2 of address of shareholder. Foreign address',
  'dbm.documentation.description.SHAREHOLDER_ADDR_LINE_3':
    'Address line 3 of address of shareholder. Foreign address',
  'dbm.documentation.description.SHAREHOLDER_ADDR_LINE_4':
    'Address line 4 of address of shareholder. Foreign address',
  'dbm.documentation.description.SHAREHOLDER_ADDR_LINE_5':
    'Address line 5 of address of shareholder. Foreign address',
  'dbm.documentation.description.SHAREHOLDER_FUNDS': 'The shareholder funds',
  'dbm.documentation.description.SHAREHOLDER_NAME': 'Name of the shareholder',
  'dbm.documentation.description.SHL_BE_ENTERPRISE_NUMBER':
    'CBE Crossroads Bank for Enterprises number',
  'dbm.documentation.description.SHL_ENTERPRISE_ID': 'Enterprise number',
  'dbm.documentation.description.SHL_NL_KVK_NUMBER':
    'Unique identification - official 8 figure registered id from the Chamber of Commerce',
  'dbm.documentation.description.SHL_PERSON_ID': 'Graydon person id',
  'dbm.documentation.description.SIZE_CLASS_BE_RSZ_NUM_EMPLOYEES': 'NSSO employees count class',
  'dbm.documentation.description.SIZE_CLASS_EMPLOYEE_COUNT_FULL_TIME': 'Employers category',
  'dbm.documentation.description.SIZE_CLASS_EMPLOYEE_COUNT_TOTAL': 'Total employees class',
  'dbm.documentation.description.SIZE_CLASS_GROUP_EMPLOYEE_COUNT_FULL_TIME':
    'Full time employees class',
  'dbm.documentation.description.SIZE_CLASS_GROUP_EMPLOYEE_COUNT_TOTAL': 'Total employees class',
  'dbm.documentation.description.SIZE_CLASS_NUM_EMPLOYEES': 'Employees count class',
  'dbm.documentation.description.SOURCE': 'source can be NBB or Bijlage Belgisch Staatsblad',
  'dbm.documentation.description.SOURCE_REF': 'Source reference code',
  'dbm.documentation.description.START_DATE': 'Start date',
  'dbm.documentation.description.STREET': 'Street name',
  'dbm.documentation.description.STREET_CODE': 'Belgian street code',
  'dbm.documentation.description.SUM_TOTAL_PERCENTAGE_SUBJECT_ALL': '-',
  'dbm.documentation.description.SUMMONS': 'Summons',
  'dbm.documentation.description.TEL_AREA_CODE': 'Telephone number area code',
  'dbm.documentation.description.TEL_COUNTRY_CODE': 'Telephone country number',
  'dbm.documentation.description.TEL_SUBSCRIBER_NUMBER': 'Company phone number subscriber number',
  'dbm.documentation.description.TELEPHONE': 'Telephone number',
  'dbm.documentation.description.TELEPHONE_RECEIVER_1': '-',
  'dbm.documentation.description.TELEPHONE_RECEIVER_2': '-',
  'dbm.documentation.description.TELEPHONE_RECEIVER_3': '-',
  'dbm.documentation.description.TITLES': 'Title',
  'dbm.documentation.description.TITLES_RECEIVER_1': 'title of the receiver',
  'dbm.documentation.description.TITLES_RECEIVER_2': 'title of the receiver',
  'dbm.documentation.description.TITLES_RECEIVER_3': 'title of the receiver',
  'dbm.documentation.description.TOTAL_EQUITY': 'The equity of the company',
  'dbm.documentation.description.TOTAL_PERCENTAGE_SUBJECT': '-',
  'dbm.documentation.description.TRADE_NAME_1': 'first trade name',
  'dbm.documentation.description.TRADE_NAME_2': 'second trade name',
  'dbm.documentation.description.TRADE_NAME_3': '3th trade name',
  'dbm.documentation.description.TURNOVER': 'Company turnover',
  'dbm.documentation.description.TYPE_CODE': 'Code type',
  'dbm.documentation.description.UBO_SOURCE': '-',
  'dbm.documentation.description.UBO_SOURCE_KVK_DATE': '-',
  'dbm.documentation.description.UNAVAILABLE_CONTRIBUTION': 'Unavailable contribution',
  'dbm.documentation.description.UPDATED_ON': 'Updated on',
  'dbm.documentation.description.URL': 'URL',
  'dbm.documentation.description.USE_CLASS_CODE_1': 'Use class code 1',
  'dbm.documentation.description.USE_CLASS_CODE_2': 'Use class code 2',
  'dbm.documentation.description.USE_CLASS_CODE_3': 'Use class code 3',
  'dbm.documentation.description.USE_CLASS_CODE_4': 'Use class code 4',
  'dbm.documentation.description.USE_CLASS_CODE_5': 'Use class code 5',
  'dbm.documentation.description.VARIABLE_CAPITAL': 'Variable capital',
  'dbm.documentation.description.VAT_LIABILITY':
    'Is a company liable for VAT or not? Not NL and GB',
  'dbm.documentation.description.VAT_NUMBER': 'Unique VAT NUMBER',
  'dbm.documentation.description.VOTING_DATE': 'Voting date',
  'dbm.documentation.description.WORKING_CAPITAL': 'The working capital of the company',
  'dbm.documentation.description.XSEPTION_CATEGORY_CODE': 'XSeption category code.',
  'dbm.documentation.description.XSEPTION_NDATE_1': 'Numeric date 1.',
  'dbm.documentation.description.XSEPTION_NDATE_2': 'Numeric date 2.',
  'dbm.documentation.description.XSEPTION_TEXT': 'XSeptions',
  'dbm.documentation.description.XSEPTION_TYPE_CODE': 'XSeption type code.',
  'dbm.documentation.description.XSEPTION_VALUE_1': 'Xseption value field 1',
  'dbm.documentation.description.XSEPTION_VALUE_2': 'Xseption value field 2',
  'dbm.documentation.description.YEAR': 'Numeric year',
  'dbm.documentation.description.NL_KVK_NUMBER_CONTINUATION': '-',
  'dbm.documentation.description.NL_LEGAL_FORM_DESCRIPTION': '-',
  'dbm.documentation.description.COUNTRY': '-',
  'dbm.documentation.description.FOUND_AT_NL_KVK_NUMBER': '-',
  'dbm.documentation.description.FOUND_AT_NL_LEGAL_FORM_DESCRIPTION': '-',
  'dbm.documentation.description.PERSON_BIRTH_COUNTRY': '-',
  'dbm.documentation.description.RESULT_SEARCH_STATUS_DESCRIPTION': '-',
  'dbm.documentation.description.INTEREST_TYPE_CODE': '-',
  'dbm.documentation.description.INTEREST_SIZE_CODE': '-',
  'dbm.documentation.description.REGISTRATION_DATE': '-',
  'dbm.documentation.domain-code.description':
    'Some fields contain a code instead of a human readable value. The possible codes and their descriptions (in English, Dutch and French) can be found inside the DBM_CODE_DESCRIPTIONS data block, see above. Download this data block to see the most recent versions of descriptions of each code.',
  'dbm.documentation.domain-code.description.title': 'Domain Code in code description:',
  'dbm.documentation.domain-code.link': 'Info about code descriptions',
  'dbm.documentation.domain-code.title': '{country} Code descriptions',
  'dbm.documentation.download-example': 'Download/View example of this datablock version (.csv)',
  'dbm.documentation.example.ABBREVIATIONS_LEGAL_FORM': 'NV',
  'dbm.documentation.example.ACC_PERIOD_END_DATE': '2021-01-01',
  'dbm.documentation.example.ACC_PERIOD_START_DATE': '2012-01-01',
  'dbm.documentation.example.ACCOUNTANT_NAME': 'KPMG',
  'dbm.documentation.example.ACCOUNTING_PERIOD': '20120101-20121231',
  'dbm.documentation.example.ACTIVITY_CLASSIFICATION_CODE':
    'NL_SBI2008, BE_NACEBEL2008, GB_SIC2007',
  'dbm.documentation.example.ACTIVITY_CODE': '8513',
  'dbm.documentation.example.ACTIVITY_CODE_1': '8513',
  'dbm.documentation.example.ACTIVITY_CODE_2': '8513',
  'dbm.documentation.example.ACTIVITY_CODE_3': '8513',
  'dbm.documentation.example.ACTIVITY_CODE_4': '8513',
  'dbm.documentation.example.ACTIVITY_CODE_5': '8513',
  'dbm.documentation.example.ACTIVITY_DESCRIPTION': '-',
  'dbm.documentation.example.ACTIVITY_GROUP_CODE': 'CODE: BE_4',
  'dbm.documentation.example.ACTIVITY_SEQUENCE_NUMBER': '1',
  'dbm.documentation.example.ACTIVITY_START_DATE': '2021-01-01',
  'dbm.documentation.example.ACTIVITY_TYPE_CODE': '1',
  'dbm.documentation.example.ADDRESS_END_DATE': '2021-01-10',
  'dbm.documentation.example.ADDRESS_LINE_1': 'SOUTH RANGE 6 HIGH CARLBURY',
  'dbm.documentation.example.ADDRESS_LINE_1_RECEIVER_1': '-',
  'dbm.documentation.example.ADDRESS_LINE_1_RECEIVER_10': '-',
  'dbm.documentation.example.ADDRESS_LINE_1_RECEIVER_2': '-',
  'dbm.documentation.example.ADDRESS_LINE_1_RECEIVER_3': '-',
  'dbm.documentation.example.ADDRESS_LINE_1_RECEIVER_4': '-',
  'dbm.documentation.example.ADDRESS_LINE_1_RECEIVER_5': '-',
  'dbm.documentation.example.ADDRESS_LINE_1_RECEIVER_6': '-',
  'dbm.documentation.example.ADDRESS_LINE_1_RECEIVER_7': '-',
  'dbm.documentation.example.ADDRESS_LINE_1_RECEIVER_8': '-',
  'dbm.documentation.example.ADDRESS_LINE_1_RECEIVER_9': '-',
  'dbm.documentation.example.ADDRESS_LINE_2': 'PIERCEBRIDGE',
  'dbm.documentation.example.ADDRESS_LINE_2_RECEIVER_1': '3600KJ',
  'dbm.documentation.example.ADDRESS_LINE_2_RECEIVER_10': '-',
  'dbm.documentation.example.ADDRESS_LINE_2_RECEIVER_2': '3600KJ',
  'dbm.documentation.example.ADDRESS_LINE_2_RECEIVER_3': '3600KJ',
  'dbm.documentation.example.ADDRESS_LINE_2_RECEIVER_4': '3600KJ',
  'dbm.documentation.example.ADDRESS_LINE_2_RECEIVER_5': '3600KJ',
  'dbm.documentation.example.ADDRESS_LINE_2_RECEIVER_6': '-',
  'dbm.documentation.example.ADDRESS_LINE_2_RECEIVER_7': '-',
  'dbm.documentation.example.ADDRESS_LINE_2_RECEIVER_8': '-',
  'dbm.documentation.example.ADDRESS_LINE_2_RECEIVER_9': '-',
  'dbm.documentation.example.ADDRESS_LINE_3': 'DARLINGTON',
  'dbm.documentation.example.ADDRESS_LINE_3_RECEIVER_1': 'AMSTERDAM',
  'dbm.documentation.example.ADDRESS_LINE_3_RECEIVER_10': '-',
  'dbm.documentation.example.ADDRESS_LINE_3_RECEIVER_2': 'AMSTERDAM',
  'dbm.documentation.example.ADDRESS_LINE_3_RECEIVER_3': 'AMSTERDAM',
  'dbm.documentation.example.ADDRESS_LINE_3_RECEIVER_4': '-',
  'dbm.documentation.example.ADDRESS_LINE_3_RECEIVER_5': '-',
  'dbm.documentation.example.ADDRESS_LINE_3_RECEIVER_6': '-',
  'dbm.documentation.example.ADDRESS_LINE_3_RECEIVER_7': '-',
  'dbm.documentation.example.ADDRESS_LINE_3_RECEIVER_8': '-',
  'dbm.documentation.example.ADDRESS_LINE_3_RECEIVER_9': '-',
  'dbm.documentation.example.ADDRESS_LINE_4': 'CO DURHAM',
  'dbm.documentation.example.ADDRESS_LINE_4_RECEIVER_1': '-',
  'dbm.documentation.example.ADDRESS_LINE_4_RECEIVER_10': '-',
  'dbm.documentation.example.ADDRESS_LINE_4_RECEIVER_2': '-',
  'dbm.documentation.example.ADDRESS_LINE_4_RECEIVER_3': '-',
  'dbm.documentation.example.ADDRESS_LINE_4_RECEIVER_4': '-',
  'dbm.documentation.example.ADDRESS_LINE_4_RECEIVER_5': '-',
  'dbm.documentation.example.ADDRESS_LINE_4_RECEIVER_6': '-',
  'dbm.documentation.example.ADDRESS_LINE_4_RECEIVER_7': '-',
  'dbm.documentation.example.ADDRESS_LINE_4_RECEIVER_8': '-',
  'dbm.documentation.example.ADDRESS_LINE_4_RECEIVER_9': '-',
  'dbm.documentation.example.ADDRESS_LINE_5': 'UNITED KINGDOM',
  'dbm.documentation.example.ADDRESS_LINE_5_RECEIVER_1': '-',
  'dbm.documentation.example.ADDRESS_LINE_5_RECEIVER_10': '-',
  'dbm.documentation.example.ADDRESS_LINE_5_RECEIVER_2': '-',
  'dbm.documentation.example.ADDRESS_LINE_5_RECEIVER_3': '-',
  'dbm.documentation.example.ADDRESS_LINE_5_RECEIVER_4': '-',
  'dbm.documentation.example.ADDRESS_LINE_5_RECEIVER_5': '-',
  'dbm.documentation.example.ADDRESS_LINE_5_RECEIVER_6': '-',
  'dbm.documentation.example.ADDRESS_LINE_5_RECEIVER_7': '-',
  'dbm.documentation.example.ADDRESS_LINE_5_RECEIVER_8': '-',
  'dbm.documentation.example.ADDRESS_LINE_5_RECEIVER_9': '-',
  'dbm.documentation.example.ADDRESS_LINES': 'TRUE',
  'dbm.documentation.example.ADDRESS_ROLE_CODE': '1',
  'dbm.documentation.example.ADDRESS_START_DATE': '2021-01-10',
  'dbm.documentation.example.ADDRESS_TYPE_CODE': 'PHYSICAL',
  'dbm.documentation.example.AMOUNT_CATEGORY_CODE': 'BE_A',
  'dbm.documentation.example.AMOUNT_CCJ_LAST_12_MONTHS': '-',
  'dbm.documentation.example.AMOUNT_CCJ_LAST_24_MONTHS': '-',
  'dbm.documentation.example.AMOUNT_CCJ_LAST_36_MONTHS': '-',
  'dbm.documentation.example.AMOUNT_CCJ_LAST_48_MONTHS': '-',
  'dbm.documentation.example.AMOUNT_CCJ_LAST_60_MONTHS': '-',
  'dbm.documentation.example.AMOUNT_CCJ_LAST_72_MONTHS': '-',
  'dbm.documentation.example.AMOUNT_UNIT_CODE': '1',
  'dbm.documentation.example.ANNUAL_ACCOUNT_ITEM_CODE': 'NL_AST_20_00_002',
  'dbm.documentation.example.ANNUAL_ACCOUNT_ITEM_VALUE': '53786',
  'dbm.documentation.example.ANNUAL_ACCOUNT_TYPE_CODE': '40',
  'dbm.documentation.example.ANNUAL_REPORT_FILING_DATE': '2021-01-01',
  'dbm.documentation.example.ANNUAL_REPORT_TYPE_CODE': 'NL',
  'dbm.documentation.example.AREA': '5972',
  'dbm.documentation.example.ASSOCIATION_FUNDS': '125000',
  'dbm.documentation.example.AUTHORISED_CAPITAL': '500000',
  'dbm.documentation.example.AVAILABLE_CONTRIBUTION': '125000',
  'dbm.documentation.example.BAG_STATUS_CODE': '2',
  'dbm.documentation.example.BAG_TYPE_CODE': '1',
  'dbm.documentation.example.BALANCE_SHEET_TOTAL': '87000',
  'dbm.documentation.example.BANK_CODE': 'JVBA',
  'dbm.documentation.example.BANK_NAME': 'BANK J VAN BREDA',
  'dbm.documentation.example.BE_BUSINESS_UNIT_NUMBER': '2019802096',
  'dbm.documentation.example.BE_ENTERPRISE_NUMBER': '8123456',
  'dbm.documentation.example.BE_LEGAL_FORM_CODE': '12',
  'dbm.documentation.example.BE_MULTISCORE': '59',
  'dbm.documentation.example.BOARD_TYPE_CODE': '-',
  'dbm.documentation.example.BUILDING': '-',
  'dbm.documentation.example.CALAMITY': '-',
  'dbm.documentation.example.CALAMITY_START_DATE': '-',
  'dbm.documentation.example.CASH': '1',
  'dbm.documentation.example.CCJ_FLAG': '-',
  'dbm.documentation.example.CITY': 'Amsterdam',
  'dbm.documentation.example.CODE_VALUE': 'Y (=Yes), NL (=Netherlands)',
  'dbm.documentation.example.CONSOLIDATED_FLAG': 'Y',
  'dbm.documentation.example.CONSTRUCTION_YEAR': '1997',
  'dbm.documentation.example.CONTACT_ITEM_ROLE_CODE': '-',
  'dbm.documentation.example.CONTACT_ITEM_TYPE_CODE': 'Email,URL,Telephone, Mobile',
  'dbm.documentation.example.CONTACT_ITEM_VALUE': 'info@graydon.nl',
  'dbm.documentation.example.CONTINUATION_START_DATE': '2021-01-01',
  'dbm.documentation.example.CONTINUATION_TYPE_CODE': '-',
  'dbm.documentation.example.CONTRIBUTION': '125000',
  'dbm.documentation.example.COUNTRY_CODE': 'NL',
  'dbm.documentation.example.COURT': 'Antwerpen',
  'dbm.documentation.example.CREDIT_ADVICE': '2000',
  'dbm.documentation.example.CREDIT_FLAG': 'G',
  'dbm.documentation.example.CURRENCY_CODE': 'EUR',
  'dbm.documentation.example.CURRENT_RATIO': '-',
  'dbm.documentation.example.DATE_LAST_CCJ': '-',
  'dbm.documentation.example.DIRECT_PERCENTAGE': '-',
  'dbm.documentation.example.DISCONTINUATION_PROBABILITY': '9.87',
  'dbm.documentation.example.DISCONTINUATION_SCORE': '3',
  'dbm.documentation.example.DISSOLUTION_DATE': '44197',
  'dbm.documentation.example.DO_NOT_MAIL_FLAG': 'Y',
  'dbm.documentation.example.DOMAIN_CODE': 'ORGANIZATION_STATUS_CODE, COUNTRY_CODE',
  'dbm.documentation.example.EMAIL': '-',
  'dbm.documentation.example.EMPLOYEE_COUNT_ANNUAL_ACCOUNT': '150',
  'dbm.documentation.example.EMPLOYEE_COUNT_FULL_TIME': '6',
  'dbm.documentation.example.EMPLOYEE_COUNT_TOTAL': '10',
  'dbm.documentation.example.EN_DESCRIPTION': 'Yes (for code Y)',
  'dbm.documentation.example.END_DATE': '2021-01-01',
  'dbm.documentation.example.END_REASON_CODE': 'NL_FAL_7',
  'dbm.documentation.example.ENTERPRISE_ID': '1234567',
  'dbm.documentation.example.ERC': '3',
  'dbm.documentation.example.EVENT_REFERENCE': '-',
  'dbm.documentation.example.EVENTS_TYPE_CODE': '-',
  'dbm.documentation.example.EXCUSABILITY_FLAG': '-',
  'dbm.documentation.example.EXPORT_FLAG': 'N',
  'dbm.documentation.example.FOUND_AT_CALAMITY': '-',
  'dbm.documentation.example.FOUND_AT_CALAMITY_START_DATE': '-',
  'dbm.documentation.example.FIGURE_VALUE': '88762',
  'dbm.documentation.example.FILED_FOR_ENTERPRISE_ID': '1234567',
  'dbm.documentation.example.FILED_FOR_NAME': 'Graydon Nederland B.V.',
  'dbm.documentation.example.FILED_FOR_NL_KVK_NUMBER': '81234560',
  'dbm.documentation.example.FILING_DATE': '2021-01-01',
  'dbm.documentation.example.FIXED_CAPITAL': '125000',
  'dbm.documentation.example.FOUND_AT_ENTERPRISE_ID': '-',
  'dbm.documentation.example.FOUND_AT_ORG_LEVEL': '-',
  'dbm.documentation.example.FOUNDATION_DATE': '2021-01-01',
  'dbm.documentation.example.FOUNDER_FLAG': 'Y',
  'dbm.documentation.example.FR_DESCRIPTION': 'Oui (for code Y)',
  'dbm.documentation.example.FREE_TEXT': '-',
  'dbm.documentation.example.GAZETTE_NUMBER': '-',
  'dbm.documentation.example.GOV_GAZETTE_DATE': '-',
  'dbm.documentation.example.GB_AUGUR_SCORE': '-',
  'dbm.documentation.example.GB_CREDIT_RATING': '-',
  'dbm.documentation.example.GB_CRO_REGISTRATION_NUMBER': '-',
  'dbm.documentation.example.GB_LEGAL_FORM_CODE': '-',
  'dbm.documentation.example.GB_MAXIMUM_CREDIT_GUIDE': '-',
  'dbm.documentation.example.GB_MONTHLY_CREDIT_GUIDE': '-',
  'dbm.documentation.example.GDB_ACCOUNTING_PERIOD': '-',
  'dbm.documentation.example.GENDER_CODE': 'M, F, X',
  'dbm.documentation.example.GENDER_CODE_RECEIVER_1': 'M',
  'dbm.documentation.example.GENDER_CODE_RECEIVER_2': 'M',
  'dbm.documentation.example.GENDER_CODE_RECEIVER_3': 'M',
  'dbm.documentation.example.GOV_GAZETTE_NUMBER': '-',
  'dbm.documentation.example.GOVERNMENT_GAZETTE_CODE': '-',
  'dbm.documentation.example.GRAYDON_RATING': 'AA',
  'dbm.documentation.example.GROSS_MARGIN': '1',
  'dbm.documentation.example.GROSS_SALES_MARGIN': '1',
  'dbm.documentation.example.GROUP_EMPLOYEE_COUNT_FULL_TIME': '9000',
  'dbm.documentation.example.GROUP_EMPLOYEE_COUNT_TOTAL': '10000',
  'dbm.documentation.example.GROWTH_SCORE': '2',
  'dbm.documentation.example.GROWTH_SEGMENT': 'A',
  'dbm.documentation.example.HELD_BY_ORG_ENTERPRISE_ID': 'deprecated translation key',
  'dbm.documentation.example.HIGHEST_AMOUNT_CCJ_LAST_12_MONTHS': '-',
  'dbm.documentation.example.HIGHEST_AMOUNT_CCJ_LAST_24_MONTHS': '-',
  'dbm.documentation.example.HIGHEST_AMOUNT_CCJ_LAST_36_MONTHS': '-',
  'dbm.documentation.example.HIGHEST_AMOUNT_CCJ_LAST_48_MONTHS': '-',
  'dbm.documentation.example.HIGHEST_AMOUNT_CCJ_LAST_60_MONTHS': '-',
  'dbm.documentation.example.HIGHEST_AMOUNT_CCJ_LAST_72_MONTHS': '-',
  'dbm.documentation.example.HOUSE_NUMBER': '250',
  'dbm.documentation.example.HOUSE_NUMBER_SUFFIX': '-A',
  'dbm.documentation.example.IBAN': 'could be an bank account or Iban',
  'dbm.documentation.example.IMPORT_FLAG': 'Y',
  'dbm.documentation.example.INFO_CODE': '-',
  'dbm.documentation.example.INSOLVENCY_DETAIL_CODE': '-',
  'dbm.documentation.example.INSOLVENCY_FORM_NAME': '-',
  'dbm.documentation.example.INSOLVENCY_FREE_TEXT': '-',
  'dbm.documentation.example.INSOLVENCY_JUDGMENT_TYPE_CODE': '-',
  'dbm.documentation.example.INSOLVENCY_TYPE_CODE': 'NL_1',
  'dbm.documentation.example.INTEREST_SIZE_DESCRIPTION': '-',
  'dbm.documentation.example.INTEREST_TYPE_DESCRIPTION': '-',
  'dbm.documentation.example.IS_ACTIVE_STATUS_CODE': 'Y',
  'dbm.documentation.example.IS_DISSOLVED_STATUS_CODE': 'N',
  'dbm.documentation.example.IS_ECONOMICALLY_ACTIVE_STATUS_CODE': 'N',
  'dbm.documentation.example.IS_INSOLVENT_STATUS_CODE': 'Y',
  'dbm.documentation.example.IS_SOHO': 'Y',
  'dbm.documentation.example.IS_ZZP': 'Y',
  'dbm.documentation.example.ISIC_ACTIVITY_CODE': '15',
  'dbm.documentation.example.ISSUE_DATE': '-',
  'dbm.documentation.example.ISSUED_CAPITAL': '50000',
  'dbm.documentation.example.JUDGMENT_DATE': '2021-01-01',
  'dbm.documentation.example.KEY_FIGURE_YEAR': '2018',
  'dbm.documentation.example.LAST_NL_KVK_SOURCE_UPDATE_DATE': '-',
  'dbm.documentation.example.LATITUDE': '51.30338',
  'dbm.documentation.example.LEGAL_FORM_START_DATE': '2021-01-01',
  'dbm.documentation.example.LEI_NUMBER': '72450056A9WLXUPSI574',
  'dbm.documentation.example.SEARCH_STATUS_DESCRIPTION': '-',
  'dbm.documentation.example.POSITION_MANDATE_AUTHORITY_DESCRIPTION': '-',
  'dbm.documentation.example.SEARCH_ROLE_DESCRIPTION': '-',
  'dbm.documentation.example.POSITION_TYPE_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_2_SEARCH_STATUS_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_2_POSITION_MANDATE_AUTHORITY_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_2_SEARCH_ROLE_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_2_POSITION_TYPE_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_2_POSITION_START_DATE': '-',
  'dbm.documentation.example.LEVEL_2_POSITION_END_DATE': '-',
  'dbm.documentation.example.LEVEL_3_SEARCH_STATUS_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_3_POSITION_MANDATE_AUTHORITY_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_3_SEARCH_ROLE_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_3_POSITION_TYPE_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_3_POSITION_START_DATE': '-',
  'dbm.documentation.example.LEVEL_3_POSITION_END_DATE': '-',
  'dbm.documentation.example.LEVEL_4_SEARCH_STATUS_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_4_POSITION_MANDATE_AUTHORITY_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_4_SEARCH_ROLE_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_4_POSITION_TYPE_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_4_POSITION_START_DATE': '-',
  'dbm.documentation.example.LEVEL_4_POSITION_END_DATE': '-',
  'dbm.documentation.example.LEVEL_5_SEARCH_STATUS_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_5_POSITION_MANDATE_AUTHORITY_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_5_SEARCH_ROLE_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_5_POSITION_TYPE_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_5_POSITION_START_DATE': '-',
  'dbm.documentation.example.LEVEL_5_POSITION_END_DATE': '-',
  'dbm.documentation.example.LEVEL_6_SEARCH_STATUS_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_6_POSITION_MANDATE_AUTHORITY_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_6_SEARCH_ROLE_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_6_POSITION_TYPE_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_6_POSITION_START_DATE': '-',
  'dbm.documentation.example.LEVEL_6_POSITION_END_DATE': '-',
  'dbm.documentation.example.LEVEL_7_SEARCH_STATUS_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_7_POSITION_MANDATE_AUTHORITY_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_7_SEARCH_ROLE_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_7_POSITION_TYPE_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_7_POSITION_START_DATE': '-',
  'dbm.documentation.example.LEVEL_7_POSITION_END_DATE': '-',
  'dbm.documentation.example.LEVEL_8_SEARCH_STATUS_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_8_POSITION_MANDATE_AUTHORITY_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_8_SEARCH_ROLE_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_8_POSITION_TYPE_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_8_POSITION_START_DATE': '-',
  'dbm.documentation.example.LEVEL_8_POSITION_END_DATE': '-',
  'dbm.documentation.example.LEVEL_9_SEARCH_STATUS_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_9_POSITION_MANDATE_AUTHORITY_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_9_SEARCH_ROLE_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_9_POSITION_TYPE_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_9_POSITION_START_DATE': '-',
  'dbm.documentation.example.LEVEL_9_POSITION_END_DATE': '-',
  'dbm.documentation.example.LEVEL_10_SEARCH_STATUS_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_10_POSITION_MANDATE_AUTHORITY_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_10_SEARCH_ROLE_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_10_POSITION_TYPE_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_10_POSITION_START_DATE': '-',
  'dbm.documentation.example.LEVEL_10_POSITION_END_DATE': '-',
  'dbm.documentation.example.LEVEL_11_SEARCH_STATUS_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_11_POSITION_MANDATE_AUTHORITY_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_11_SEARCH_ROLE_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_11_POSITION_TYPE_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_11_POSITION_START_DATE': '-',
  'dbm.documentation.example.LEVEL_11_POSITION_END_DATE': '-',
  'dbm.documentation.example.LEVEL_12_SEARCH_STATUS_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_12_POSITION_MANDATE_AUTHORITY_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_12_SEARCH_ROLE_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_12_POSITION_TYPE_DESCRIPTION': '-',
  'dbm.documentation.example.LEVEL_12_POSITION_START_DATE': '-',
  'dbm.documentation.example.LEVEL_12_POSITION_END_DATE': '-',
  'dbm.documentation.example.LEVEL_1_ORGANIZATION_NAME': '-',
  'dbm.documentation.example.LEVEL_1_COUNTRY_CODE': '-',
  'dbm.documentation.example.LEVEL_1_COUNTRY': '-',
  'dbm.documentation.example.LEVEL_2_ORGANIZATION_NAME': '-',
  'dbm.documentation.example.LEVEL_2_COUNTRY_CODE': '-',
  'dbm.documentation.example.LEVEL_2_COUNTRY': '-',
  'dbm.documentation.example.LEVEL_3_ORGANIZATION_NAME': '-',
  'dbm.documentation.example.LEVEL_3_COUNTRY_CODE': '-',
  'dbm.documentation.example.LEVEL_3_COUNTRY': '-',
  'dbm.documentation.example.LEVEL_4_ORGANIZATION_NAME': '-',
  'dbm.documentation.example.LEVEL_4_COUNTRY_CODE': '-',
  'dbm.documentation.example.LEVEL_4_COUNTRY': '-',
  'dbm.documentation.example.LEVEL_5_ORGANIZATION_NAME': '-',
  'dbm.documentation.example.LEVEL_5_COUNTRY_CODE': '-',
  'dbm.documentation.example.LEVEL_5_COUNTRY': '-',
  'dbm.documentation.example.LEVEL_6_ORGANIZATION_NAME': '-',
  'dbm.documentation.example.LEVEL_6_COUNTRY_CODE': '-',
  'dbm.documentation.example.LEVEL_6_COUNTRY': '-',
  'dbm.documentation.example.LEVEL_7_ORGANIZATION_NAME': '-',
  'dbm.documentation.example.LEVEL_7_COUNTRY_CODE': '-',
  'dbm.documentation.example.LEVEL_7_COUNTRY': '-',
  'dbm.documentation.example.LEVEL_8_ORGANIZATION_NAME': '-',
  'dbm.documentation.example.LEVEL_8_COUNTRY_CODE': '-',
  'dbm.documentation.example.LEVEL_8_COUNTRY': '-',
  'dbm.documentation.example.LEVEL_9_ORGANIZATION_NAME': '-',
  'dbm.documentation.example.LEVEL_9_COUNTRY_CODE': '-',
  'dbm.documentation.example.LEVEL_9_COUNTRY': '-',
  'dbm.documentation.example.LEVEL_10_ORGANIZATION_NAME': '-',
  'dbm.documentation.example.LEVEL_10_COUNTRY_CODE': '-',
  'dbm.documentation.example.LEVEL_10_COUNTRY': '-',
  'dbm.documentation.example.LEVEL_11_ORGANIZATION_NAME': '-',
  'dbm.documentation.example.LEVEL_11_COUNTRY_CODE': '-',
  'dbm.documentation.example.LEVEL_11_COUNTRY': '-',
  'dbm.documentation.example.LEVEL_12_ORGANIZATION_NAME': '-',
  'dbm.documentation.example.LEVEL_12_COUNTRY_CODE': '-',
  'dbm.documentation.example.LEVEL_12_COUNTRY': '-',
  'dbm.documentation.example.LEVEL_1_CALAMITY': '-',
  'dbm.documentation.example.LEVEL_1_CALAMITY_START_DATE': '-',
  'dbm.documentation.example.LEVEL_2_CALAMITY': '-',
  'dbm.documentation.example.LEVEL_2_CALAMITY_START_DATE': '-',
  'dbm.documentation.example.LEVEL_3_CALAMITY': '-',
  'dbm.documentation.example.LEVEL_3_CALAMITY_START_DATE': '-',
  'dbm.documentation.example.LEVEL_4_CALAMITY': '-',
  'dbm.documentation.example.LEVEL_4_CALAMITY_START_DATE': '-',
  'dbm.documentation.example.LEVEL_5_CALAMITY': '-',
  'dbm.documentation.example.LEVEL_5_CALAMITY_START_DATE': '-',
  'dbm.documentation.example.LEVEL_6_CALAMITY': '-',
  'dbm.documentation.example.LEVEL_6_CALAMITY_START_DATE': '-',
  'dbm.documentation.example.LEVEL_7_CALAMITY': '-',
  'dbm.documentation.example.LEVEL_7_CALAMITY_START_DATE': '-',
  'dbm.documentation.example.LEVEL_8_CALAMITY': '-',
  'dbm.documentation.example.LEVEL_8_CALAMITY_START_DATE': '-',
  'dbm.documentation.example.LEVEL_9_CALAMITY': '-',
  'dbm.documentation.example.LEVEL_9_CALAMITY_START_DATE': '-',
  'dbm.documentation.example.LEVEL_10_CALAMITY': '-',
  'dbm.documentation.example.LEVEL_10_CALAMITY_START_DATE': '-',
  'dbm.documentation.example.LEVEL_11_CALAMITY': '-',
  'dbm.documentation.example.LEVEL_11_CALAMITY_START_DATE': '-',
  'dbm.documentation.example.LEVEL_12_CALAMITY': '-',
  'dbm.documentation.example.LEVEL_12_CALAMITY_START_DATE': '-',
  'dbm.documentation.example.LEVEL_1_ENTERPRISE_ID': '-',
  'dbm.documentation.example.LEVEL_1_NL_KVK_NUMBER': '81234560',
  'dbm.documentation.example.LEVEL_1_SEARCH_ROLE_CODE': '-',
  'dbm.documentation.example.LEVEL_1_SEARCH_STATUS_CODE': '-',
  'dbm.documentation.example.LEVEL_10_DIRECT_PERCENTAGE': '-',
  'dbm.documentation.example.LEVEL_10_ENTERPRISE_ID': '-',
  'dbm.documentation.example.LEVEL_10_INDIRECT_PERCENTAGE_SUBJECT': '-',
  'dbm.documentation.example.LEVEL_10_MANDATE_AUTHORITY_CODE': '-',
  'dbm.documentation.example.LEVEL_10_NL_KVK_NUMBER': '81234560',
  'dbm.documentation.example.LEVEL_10_ORG_ADDRESS_LINE_1': '-',
  'dbm.documentation.example.LEVEL_10_ORG_ADDRESS_LINE_2': '-',
  'dbm.documentation.example.LEVEL_10_ORG_ADDRESS_LINE_3': '-',
  'dbm.documentation.example.LEVEL_10_ORG_ADDRESS_LINE_4': '-',
  'dbm.documentation.example.LEVEL_10_ORG_ADDRESS_LINE_5': '-',
  'dbm.documentation.example.LEVEL_10_ORG_NAME': '-',
  'dbm.documentation.example.LEVEL_10_POSITION_TITLE_CODE': '-',
  'dbm.documentation.example.LEVEL_10_POSITION_TYPE_CODE': '-',
  'dbm.documentation.example.LEVEL_10_ROLE_TYPE_CODE': '-',
  'dbm.documentation.example.LEVEL_10_SEARCH_ROLE_CODE': '-',
  'dbm.documentation.example.LEVEL_10_SEARCH_STATUS_CODE': '-',
  'dbm.documentation.example.LEVEL_10_START_DATE': '2021-01-01',
  'dbm.documentation.example.LEVEL_11_DIRECT_PERCENTAGE': '-',
  'dbm.documentation.example.LEVEL_11_ENTERPRISE_ID': '-',
  'dbm.documentation.example.LEVEL_11_INDIRECT_PERCENTAGE_SUBJECT': '-',
  'dbm.documentation.example.LEVEL_11_MANDATE_AUTHORITY_CODE': '-',
  'dbm.documentation.example.LEVEL_11_NL_KVK_NUMBER': '81234560',
  'dbm.documentation.example.LEVEL_11_ORG_ADDRESS_LINE_1': '-',
  'dbm.documentation.example.LEVEL_11_ORG_ADDRESS_LINE_2': '-',
  'dbm.documentation.example.LEVEL_11_ORG_ADDRESS_LINE_3': '-',
  'dbm.documentation.example.LEVEL_11_ORG_ADDRESS_LINE_4': '-',
  'dbm.documentation.example.LEVEL_11_ORG_ADDRESS_LINE_5': '-',
  'dbm.documentation.example.LEVEL_11_ORG_NAME': '-',
  'dbm.documentation.example.LEVEL_11_POSITION_TITLE_CODE': '-',
  'dbm.documentation.example.LEVEL_11_POSITION_TYPE_CODE': '-',
  'dbm.documentation.example.LEVEL_11_ROLE_TYPE_CODE': '-',
  'dbm.documentation.example.LEVEL_11_SEARCH_ROLE_CODE': '-',
  'dbm.documentation.example.LEVEL_11_SEARCH_STATUS_CODE': '-',
  'dbm.documentation.example.LEVEL_11_START_DATE': '2021-01-01',
  'dbm.documentation.example.LEVEL_12_DIRECT_PERCENTAGE': '-',
  'dbm.documentation.example.LEVEL_12_ENTERPRISE_ID': '-',
  'dbm.documentation.example.LEVEL_12_INDIRECT_PERCENTAGE_SUBJECT': '-',
  'dbm.documentation.example.LEVEL_12_MANDATE_AUTHORITY_CODE': '-',
  'dbm.documentation.example.LEVEL_12_NL_KVK_NUMBER': '81234560',
  'dbm.documentation.example.LEVEL_12_ORG_ADDRESS_LINE_1': '-',
  'dbm.documentation.example.LEVEL_12_ORG_ADDRESS_LINE_2': '-',
  'dbm.documentation.example.LEVEL_12_ORG_ADDRESS_LINE_3': '-',
  'dbm.documentation.example.LEVEL_12_ORG_ADDRESS_LINE_4': '-',
  'dbm.documentation.example.LEVEL_12_ORG_ADDRESS_LINE_5': '-',
  'dbm.documentation.example.LEVEL_12_ORG_NAME': '-',
  'dbm.documentation.example.LEVEL_12_POSITION_TITLE_CODE': '-',
  'dbm.documentation.example.LEVEL_12_POSITION_TYPE_CODE': '-',
  'dbm.documentation.example.LEVEL_12_ROLE_TYPE_CODE': '-',
  'dbm.documentation.example.LEVEL_12_SEARCH_ROLE_CODE': '-',
  'dbm.documentation.example.LEVEL_12_SEARCH_STATUS_CODE': '-',
  'dbm.documentation.example.LEVEL_12_START_DATE': '2021-01-01',
  'dbm.documentation.example.LEVEL_2_DIRECT_PERCENTAGE': '-',
  'dbm.documentation.example.LEVEL_2_ENTERPRISE_ID': '-',
  'dbm.documentation.example.LEVEL_2_INDIRECT_PERCENTAGE_SUBJECT': '-',
  'dbm.documentation.example.LEVEL_2_MANDATE_AUTHORITY_CODE': '-',
  'dbm.documentation.example.LEVEL_2_NL_KVK_NUMBER': '81234560',
  'dbm.documentation.example.LEVEL_2_ORG_ADDRESS_LINE_1': '-',
  'dbm.documentation.example.LEVEL_2_ORG_ADDRESS_LINE_2': '-',
  'dbm.documentation.example.LEVEL_2_ORG_ADDRESS_LINE_3': '-',
  'dbm.documentation.example.LEVEL_2_ORG_ADDRESS_LINE_4': '-',
  'dbm.documentation.example.LEVEL_2_ORG_ADDRESS_LINE_5': '-',
  'dbm.documentation.example.LEVEL_2_ORG_NAME': '-',
  'dbm.documentation.example.LEVEL_2_POSITION_TITLE_CODE': '-',
  'dbm.documentation.example.LEVEL_2_POSITION_TYPE_CODE': '-',
  'dbm.documentation.example.LEVEL_2_ROLE_TYPE_CODE': '-',
  'dbm.documentation.example.LEVEL_2_SEARCH_ROLE_CODE': '-',
  'dbm.documentation.example.LEVEL_2_SEARCH_STATUS_CODE': '-',
  'dbm.documentation.example.LEVEL_2_START_DATE': '2021-01-01',
  'dbm.documentation.example.LEVEL_3_DIRECT_PERCENTAGE': '-',
  'dbm.documentation.example.LEVEL_3_ENTERPRISE_ID': '-',
  'dbm.documentation.example.LEVEL_3_INDIRECT_PERCENTAGE_SUBJECT': '-',
  'dbm.documentation.example.LEVEL_3_MANDATE_AUTHORITY_CODE': '-',
  'dbm.documentation.example.LEVEL_3_NL_KVK_NUMBER': '81234560',
  'dbm.documentation.example.LEVEL_3_ORG_ADDRESS_LINE_1': '-',
  'dbm.documentation.example.LEVEL_3_ORG_ADDRESS_LINE_2': '-',
  'dbm.documentation.example.LEVEL_3_ORG_ADDRESS_LINE_3': '-',
  'dbm.documentation.example.LEVEL_3_ORG_ADDRESS_LINE_4': '-',
  'dbm.documentation.example.LEVEL_3_ORG_ADDRESS_LINE_5': '-',
  'dbm.documentation.example.LEVEL_3_ORG_NAME': '-',
  'dbm.documentation.example.LEVEL_3_POSITION_TITLE_CODE': '-',
  'dbm.documentation.example.LEVEL_3_POSITION_TYPE_CODE': '-',
  'dbm.documentation.example.LEVEL_3_ROLE_TYPE_CODE': '-',
  'dbm.documentation.example.LEVEL_3_SEARCH_ROLE_CODE': '-',
  'dbm.documentation.example.LEVEL_3_SEARCH_STATUS_CODE': '-',
  'dbm.documentation.example.LEVEL_3_START_DATE': '2021-01-01',
  'dbm.documentation.example.LEVEL_4_DIRECT_PERCENTAGE': 'Direct percentage',
  'dbm.documentation.example.LEVEL_4_ENTERPRISE_ID': '-',
  'dbm.documentation.example.LEVEL_4_INDIRECT_PERCENTAGE_SUBJECT': '-',
  'dbm.documentation.example.LEVEL_4_MANDATE_AUTHORITY_CODE': '-',
  'dbm.documentation.example.LEVEL_4_NL_KVK_NUMBER': '81234560',
  'dbm.documentation.example.LEVEL_4_ORG_ADDRESS_LINE_1': '-',
  'dbm.documentation.example.LEVEL_4_ORG_ADDRESS_LINE_2': '-',
  'dbm.documentation.example.LEVEL_4_ORG_ADDRESS_LINE_3': '-',
  'dbm.documentation.example.LEVEL_4_ORG_ADDRESS_LINE_4': '-',
  'dbm.documentation.example.LEVEL_4_ORG_ADDRESS_LINE_5': '-',
  'dbm.documentation.example.LEVEL_4_ORG_NAME': '-',
  'dbm.documentation.example.LEVEL_4_POSITION_TITLE_CODE': '-',
  'dbm.documentation.example.LEVEL_4_POSITION_TYPE_CODE': '-',
  'dbm.documentation.example.LEVEL_4_ROLE_TYPE_CODE': '-',
  'dbm.documentation.example.LEVEL_4_SEARCH_ROLE_CODE': '-',
  'dbm.documentation.example.LEVEL_4_SEARCH_STATUS_CODE': '-',
  'dbm.documentation.example.LEVEL_4_START_DATE': '2021-01-01',
  'dbm.documentation.example.LEVEL_5_DIRECT_PERCENTAGE': '-',
  'dbm.documentation.example.LEVEL_5_ENTERPRISE_ID': '-',
  'dbm.documentation.example.LEVEL_5_INDIRECT_PERCENTAGE_SUBJECT': '-',
  'dbm.documentation.example.LEVEL_5_MANDATE_AUTHORITY_CODE': '-',
  'dbm.documentation.example.LEVEL_5_NL_KVK_NUMBER': '81234560',
  'dbm.documentation.example.LEVEL_5_ORG_ADDRESS_LINE_1': '-',
  'dbm.documentation.example.LEVEL_5_ORG_ADDRESS_LINE_2': '-',
  'dbm.documentation.example.LEVEL_5_ORG_ADDRESS_LINE_3': '-',
  'dbm.documentation.example.LEVEL_5_ORG_ADDRESS_LINE_4': '-',
  'dbm.documentation.example.LEVEL_5_ORG_ADDRESS_LINE_5': '-',
  'dbm.documentation.example.LEVEL_5_ORG_NAME': '-',
  'dbm.documentation.example.LEVEL_5_POSITION_TITLE_CODE': '-',
  'dbm.documentation.example.LEVEL_5_POSITION_TYPE_CODE': '-',
  'dbm.documentation.example.LEVEL_5_ROLE_TYPE_CODE': '-',
  'dbm.documentation.example.LEVEL_5_SEARCH_ROLE_CODE': '-',
  'dbm.documentation.example.LEVEL_5_SEARCH_STATUS_CODE': '-',
  'dbm.documentation.example.LEVEL_5_START_DATE': '2021-01-01',
  'dbm.documentation.example.LEVEL_6_DIRECT_PERCENTAGE': '-',
  'dbm.documentation.example.LEVEL_6_ENTERPRISE_ID': '-',
  'dbm.documentation.example.LEVEL_6_INDIRECT_PERCENTAGE_SUBJECT': '-',
  'dbm.documentation.example.LEVEL_6_MANDATE_AUTHORITY_CODE': '-',
  'dbm.documentation.example.LEVEL_6_NL_KVK_NUMBER': '81234560',
  'dbm.documentation.example.LEVEL_6_ORG_ADDRESS_LINE_1': '-',
  'dbm.documentation.example.LEVEL_6_ORG_ADDRESS_LINE_2': '-',
  'dbm.documentation.example.LEVEL_6_ORG_ADDRESS_LINE_3': '-',
  'dbm.documentation.example.LEVEL_6_ORG_ADDRESS_LINE_4': '-',
  'dbm.documentation.example.LEVEL_6_ORG_ADDRESS_LINE_5': '-',
  'dbm.documentation.example.LEVEL_6_ORG_NAME': '-',
  'dbm.documentation.example.LEVEL_6_POSITION_TITLE_CODE': '-',
  'dbm.documentation.example.LEVEL_6_POSITION_TYPE_CODE': '-',
  'dbm.documentation.example.LEVEL_6_ROLE_TYPE_CODE': '-',
  'dbm.documentation.example.LEVEL_6_SEARCH_ROLE_CODE': '-',
  'dbm.documentation.example.LEVEL_6_SEARCH_STATUS_CODE': '-',
  'dbm.documentation.example.LEVEL_6_START_DATE': '2021-01-01',
  'dbm.documentation.example.LEVEL_7_DIRECT_PERCENTAGE': '-',
  'dbm.documentation.example.LEVEL_7_ENTERPRISE_ID': '-',
  'dbm.documentation.example.LEVEL_7_INDIRECT_PERCENTAGE_SUBJECT': '-',
  'dbm.documentation.example.LEVEL_7_MANDATE_AUTHORITY_CODE': '-',
  'dbm.documentation.example.LEVEL_7_NL_KVK_NUMBER': '81234560',
  'dbm.documentation.example.LEVEL_7_ORG_ADDRESS_LINE_1': '-',
  'dbm.documentation.example.LEVEL_7_ORG_ADDRESS_LINE_2': '-',
  'dbm.documentation.example.LEVEL_7_ORG_ADDRESS_LINE_3': '-',
  'dbm.documentation.example.LEVEL_7_ORG_ADDRESS_LINE_4': '-',
  'dbm.documentation.example.LEVEL_7_ORG_ADDRESS_LINE_5': '-',
  'dbm.documentation.example.LEVEL_7_ORG_NAME': '-',
  'dbm.documentation.example.LEVEL_7_POSITION_TITLE_CODE': '-',
  'dbm.documentation.example.LEVEL_7_POSITION_TYPE_CODE': '-',
  'dbm.documentation.example.LEVEL_7_ROLE_TYPE_CODE': '-',
  'dbm.documentation.example.LEVEL_7_SEARCH_ROLE_CODE': '-',
  'dbm.documentation.example.LEVEL_7_SEARCH_STATUS_CODE': '-',
  'dbm.documentation.example.LEVEL_7_START_DATE': '2021-01-01',
  'dbm.documentation.example.LEVEL_8_DIRECT_PERCENTAGE': '-',
  'dbm.documentation.example.LEVEL_8_ENTERPRISE_ID': '-',
  'dbm.documentation.example.LEVEL_8_INDIRECT_PERCENTAGE_SUBJECT': '-',
  'dbm.documentation.example.LEVEL_8_MANDATE_AUTHORITY_CODE': '-',
  'dbm.documentation.example.LEVEL_8_NL_KVK_NUMBER': '81234560',
  'dbm.documentation.example.LEVEL_8_ORG_ADDRESS_LINE_1': '-',
  'dbm.documentation.example.LEVEL_8_ORG_ADDRESS_LINE_2': '-',
  'dbm.documentation.example.LEVEL_8_ORG_ADDRESS_LINE_3': '-',
  'dbm.documentation.example.LEVEL_8_ORG_ADDRESS_LINE_4': '-',
  'dbm.documentation.example.LEVEL_8_ORG_ADDRESS_LINE_5': '-',
  'dbm.documentation.example.LEVEL_8_ORG_NAME': '-',
  'dbm.documentation.example.LEVEL_8_POSITION_TITLE_CODE': '-',
  'dbm.documentation.example.LEVEL_8_POSITION_TYPE_CODE': '-',
  'dbm.documentation.example.LEVEL_8_ROLE_TYPE_CODE': '-',
  'dbm.documentation.example.LEVEL_8_SEARCH_ROLE_CODE': '-',
  'dbm.documentation.example.LEVEL_8_SEARCH_STATUS_CODE': '-',
  'dbm.documentation.example.LEVEL_8_START_DATE': '2021-01-01',
  'dbm.documentation.example.LEVEL_9_DIRECT_PERCENTAGE': '-',
  'dbm.documentation.example.LEVEL_9_ENTERPRISE_ID': '-',
  'dbm.documentation.example.LEVEL_9_INDIRECT_PERCENTAGE_SUBJECT': '-',
  'dbm.documentation.example.LEVEL_9_MANDATE_AUTHORITY_CODE': '-',
  'dbm.documentation.example.LEVEL_9_NL_KVK_NUMBER': '81234560',
  'dbm.documentation.example.LEVEL_9_ORG_ADDRESS_LINE_1': '-',
  'dbm.documentation.example.LEVEL_9_ORG_ADDRESS_LINE_2': '-',
  'dbm.documentation.example.LEVEL_9_ORG_ADDRESS_LINE_3': '-',
  'dbm.documentation.example.LEVEL_9_ORG_ADDRESS_LINE_4': '-',
  'dbm.documentation.example.LEVEL_9_ORG_ADDRESS_LINE_5': '-',
  'dbm.documentation.example.LEVEL_9_ORG_NAME': '-',
  'dbm.documentation.example.LEVEL_9_POSITION_TITLE_CODE': '-',
  'dbm.documentation.example.LEVEL_9_POSITION_TYPE_CODE': '-',
  'dbm.documentation.example.LEVEL_9_ROLE_TYPE_CODE': '-',
  'dbm.documentation.example.LEVEL_9_SEARCH_ROLE_CODE': '-',
  'dbm.documentation.example.LEVEL_9_SEARCH_STATUS_CODE': '-',
  'dbm.documentation.example.LEVEL_9_START_DATE': '2021-01-01',
  'dbm.documentation.example.LIST_MGT_ORG_ID': '-',
  'dbm.documentation.example.LONGITUDE': '5.37337',
  'dbm.documentation.example.MEETING_DATE': '-',
  'dbm.documentation.example.MOBILE': '-',
  'dbm.documentation.example.NACE_ACTIVITY_CODE': '1511',
  'dbm.documentation.example.NAME': 'GRAYDON',
  'dbm.documentation.example.NET_RESULT': '15000',
  'dbm.documentation.example.NL_DESCRIPTION': 'Ja (for code Y)',
  'dbm.documentation.example.NL_KVK_BRANCH_NUMBER': '987123456789',
  'dbm.documentation.example.NL_KVK_NUMBER': '8123456',
  'dbm.documentation.example.NL_KVK_NUMBER_12': '81234560000',
  'dbm.documentation.example.NL_LEGAL_FORM_CODE': '4',
  'dbm.documentation.example.NL_RSIN': '009031315',
  'dbm.documentation.example.NOMINAL_VALUE': '-',
  'dbm.documentation.example.NOTICE_EVENT_DATE': '-',
  'dbm.documentation.example.NUM_BRANCH_OFFICES': '2',
  'dbm.documentation.example.NUM_CCJ_LAST_12_MONTHS': '-',
  'dbm.documentation.example.NUM_CCJ_LAST_24_MONTHS': '-',
  'dbm.documentation.example.NUM_CCJ_LAST_36_MONTHS': '-',
  'dbm.documentation.example.NUM_CCJ_LAST_48_MONTHS': '-',
  'dbm.documentation.example.NUM_CCJ_LAST_60_MONTHS': '-',
  'dbm.documentation.example.NUM_CCJ_LAST_72_MONTHS': '-',
  'dbm.documentation.example.NUM_EMPLOYEES_SIZE_CLASS_CODE': '-',
  'dbm.documentation.example.NUM_OF_SUPPLIERS': '8',
  'dbm.documentation.example.NUM_PAYMENT_EXPERIENCES': '47',
  'dbm.documentation.example.NUM_SHARES': '-',
  'dbm.documentation.example.ORGANIZATION_ID': '18268053',
  'dbm.documentation.example.ORGANIZATION_NAME': 'Bouwbedrijf Bos',
  'dbm.documentation.example.ORGANIZATION_NAME_TYPE_CODE': 'PRINCIPAL_NAME, SHORT_NAME, etc',
  'dbm.documentation.example.ORGANIZATION_TYPE_CODE': 'ROOT_ORG',
  'dbm.documentation.example.ORIGIN_CODE': '-',
  'dbm.documentation.example.OTHER_ADDR_LINE_1': '-',
  'dbm.documentation.example.OTHER_ADDR_LINE_2': '-',
  'dbm.documentation.example.OTHER_ADDR_LINE_3': '-',
  'dbm.documentation.example.OTHER_ADDR_LINE_4': '-',
  'dbm.documentation.example.OTHER_ADDR_LINE_5': '-',
  'dbm.documentation.example.OTHER_BE_ENTERPRISE_NUMBER': '406952018',
  'dbm.documentation.example.OTHER_ENTERPRISE_ID': '1234567',
  'dbm.documentation.example.OTHER_GB_CRO_REGISTRATION_NUMBER': '-',
  'dbm.documentation.example.OTHER_NAME': '-',
  'dbm.documentation.example.OTHER_NL_KVK_NUMBER': '33189409',
  'dbm.documentation.example.OTHER_ORG_ADDRESS_LINE_1': 'Berg Arrarat 1',
  'dbm.documentation.example.OTHER_ORG_ADDRESS_LINE_2': '-',
  'dbm.documentation.example.OTHER_ORG_ADDRESS_LINE_3': '-',
  'dbm.documentation.example.OTHER_ORG_ADDRESS_LINE_4': 'Willemstad',
  'dbm.documentation.example.OTHER_ORG_ADDRESS_LINE_5': 'Curaçao',
  'dbm.documentation.example.OTHER_ORG_NAME': 'HRO Curacao N.V.',
  'dbm.documentation.example.PAID_UP_CAPITAL': '50000',
  'dbm.documentation.example.PAID_UP_CONTRIBUTION': '125000',
  'dbm.documentation.example.PAID_UP_FIXED_CAPITAL': '125000',
  'dbm.documentation.example.PAID_UP_VARIABLE_CAPITAL': '125000',
  'dbm.documentation.example.PART_ADDR_LINE_1': 'Lopez de Hoyos 15',
  'dbm.documentation.example.PART_ADDR_LINE_2': '3 dcha',
  'dbm.documentation.example.PART_ADDR_LINE_3': '28006',
  'dbm.documentation.example.PART_ADDR_LINE_4': 'Madrid',
  'dbm.documentation.example.PART_ADDR_LINE_5': 'Spain',
  'dbm.documentation.example.PART_BE_ENTERPRISE_NUMBER': '8123456',
  'dbm.documentation.example.PART_ENTERPRISE_ID': '1234567',
  'dbm.documentation.example.PART_NAME': 'Graydon Nederland B.V.',
  'dbm.documentation.example.PART_NL_KVK_NUMBER': '33080348',
  'dbm.documentation.example.PARTNERSHIP_CAPITAL': '1000',
  'dbm.documentation.example.PAYMENT_SCORE': '6.8',
  'dbm.documentation.example.PCT_OVER_90_DBT': '10.2',
  'dbm.documentation.example.PCT_TO_30_DBT': '36.9',
  'dbm.documentation.example.PCT_TO_60_DBT': '38.6',
  'dbm.documentation.example.PCT_TO_90_DBT': '2.4',
  'dbm.documentation.example.PCT_WITHIN_TERMS': '12.1',
  'dbm.documentation.example.PD_PERCENTAGE': '0.22',
  'dbm.documentation.example.PERSON_BIRTH_CITY': 'Amsterdam',
  'dbm.documentation.example.PERSON_BIRTH_COUNTRY_CODE': 'BE',
  'dbm.documentation.example.PERSON_BIRTH_DATE': '1980-01-01',
  'dbm.documentation.example.PERSON_BIRTH_NDATE': '2018-01-01',
  'dbm.documentation.example.PERSON_CITY': 'ANTWERPEN',
  'dbm.documentation.example.PERSON_COUNTRY_CODE': 'BE',
  'dbm.documentation.example.PERSON_DEATH_DATE': 'date from Generic types',
  'dbm.documentation.example.PERSON_DEATH_NDATE': '20180101',
  'dbm.documentation.example.PERSON_DETAILS_PROTECTED': '-',
  'dbm.documentation.example.PERSON_FIRST_NAMES': 'Fred Johannes',
  'dbm.documentation.example.PERSON_FULL_NAME': 'Fred van Amerongen',
  'dbm.documentation.example.PERSON_GENDER_CODE': 'F',
  'dbm.documentation.example.PERSON_HOUSE_NUMBER': '84',
  'dbm.documentation.example.PERSON_HOUSE_NUMBER_SUFFIX': '-A',
  'dbm.documentation.example.PERSON_ID': '1000008993',
  'dbm.documentation.example.PERSON_INITIALS': 'AAJ',
  'dbm.documentation.example.PERSON_POSTCODE': '2600',
  'dbm.documentation.example.PERSON_STREET': 'UITBREIDINGSTRAAT',
  'dbm.documentation.example.PERSON_STREET_CODE': '3180',
  'dbm.documentation.example.PERSON_SURNAME': 'Amerongen',
  'dbm.documentation.example.PERSON_SURNAME_PREFIX': 'van',
  'dbm.documentation.example.PERSON_TITLES': 'Dr.',
  'dbm.documentation.example.PO_BOX_CITY': 'Amsterdam',
  'dbm.documentation.example.PO_BOX_END_DATE': '2021-01-01',
  'dbm.documentation.example.PO_BOX_NUMBER': '461',
  'dbm.documentation.example.PO_BOX_POSTCODE': '1101BV',
  'dbm.documentation.example.PO_BOX_START_DATE': '2021-01-01',
  'dbm.documentation.example.POSITION_END_DATE': '2021-01-01',
  'dbm.documentation.example.POSITION_HOLDER_ADDR_LINE_1': 'Plaza real 11',
  'dbm.documentation.example.POSITION_HOLDER_ADDR_LINE_2': '28000',
  'dbm.documentation.example.POSITION_HOLDER_ADDR_LINE_3': 'Madrid',
  'dbm.documentation.example.POSITION_HOLDER_ADDR_LINE_4': 'Spain',
  'dbm.documentation.example.POSITION_HOLDER_ADDR_LINE_5': '-',
  'dbm.documentation.example.POSITION_HOLDER_BE_ENTERPRISE_NUMBER': '730124',
  'dbm.documentation.example.POSITION_HOLDER_ENTERPRISE_ID': '1234567',
  'dbm.documentation.example.POSITION_HOLDER_NAME': 'Graydon Nederland B.V.',
  'dbm.documentation.example.POSITION_HOLDER_NL_KVK_NUMBER': '81234560',
  'dbm.documentation.example.POSITION_MANDATE_AUTHORITY_CODE': '2',
  'dbm.documentation.example.POSITION_MANDATE_TEXT': '-',
  'dbm.documentation.example.POSITION_START_DATE': '2021-01-01',
  'dbm.documentation.example.POSITION_TITLE': '-',
  'dbm.documentation.example.POSITION_TITLE_CODE': '-',
  'dbm.documentation.example.POSITION_TYPE_CODE': 'NL_4',
  'dbm.documentation.example.POSTCODE': '1101BV, 3000, EN3 5BT',
  'dbm.documentation.example.PREFERRED_LANGUAGE_CODE': 'FR, NL, DE',
  'dbm.documentation.example.PRELIMINARY_FLAG': 'N',
  'dbm.documentation.example.PRIMARY_ACTIVITY_CODE_1': '4722',
  'dbm.documentation.example.QUARTER': '2',
  'dbm.documentation.example.QUICK_RATIO': '1',
  'dbm.documentation.example.REASON_CODE': '-',
  'dbm.documentation.example.RECEIVER_1': 'AAJ van Amerongen',
  'dbm.documentation.example.RECEIVER_1_END_DATE': '2021-01-01',
  'dbm.documentation.example.RECEIVER_1_TYPE_CODE': 'NL_1',
  'dbm.documentation.example.RECEIVER_10': '-',
  'dbm.documentation.example.RECEIVER_2': 'AAJ van Amerongen',
  'dbm.documentation.example.RECEIVER_2_END_DATE': '2021-01-01',
  'dbm.documentation.example.RECEIVER_2_TYPE_CODE': 'NL_1',
  'dbm.documentation.example.RECEIVER_3': '-',
  'dbm.documentation.example.RECEIVER_3_END_DATE': '2021-01-01',
  'dbm.documentation.example.RECEIVER_3_TYPE_CODE': 'NL_1',
  'dbm.documentation.example.RECEIVER_4': '-',
  'dbm.documentation.example.RECEIVER_4_TYPE_CODE': 'NL_1',
  'dbm.documentation.example.RECEIVER_5': '-',
  'dbm.documentation.example.RECEIVER_5_TYPE_CODE': 'NL_1',
  'dbm.documentation.example.RECEIVER_6': '-',
  'dbm.documentation.example.RECEIVER_7': '-',
  'dbm.documentation.example.RECEIVER_8': '-',
  'dbm.documentation.example.RECEIVER_9': '-',
  'dbm.documentation.example.RECIEVER': '-',
  'dbm.documentation.example.RECIEVER_TYPE_CODE': 'NL_1',
  'dbm.documentation.example.REFERENCE': 'XYZ',
  'dbm.documentation.example.REGION': 'BE_5_1, BE_5_2, BE_5_3',
  'dbm.documentation.example.RELATION_TYPE_CODE': '1',
  'dbm.documentation.example.REPRESENTATIVE_PERSON_BIRTH_CITY': 'Amsterdam',
  'dbm.documentation.example.REPRESENTATIVE_PERSON_BIRTH_COUNTRY_CODE': 'BE',
  'dbm.documentation.example.REPRESENTATIVE_PERSON_BIRTH_DATE': 'date from Generic types',
  'dbm.documentation.example.REPRESENTATIVE_PERSON_BIRTH_NDATE': '20180101',
  'dbm.documentation.example.REPRESENTATIVE_PERSON_CITY': 'ANTWERPEN',
  'dbm.documentation.example.REPRESENTATIVE_PERSON_COUNTRY_CODE': 'BE',
  'dbm.documentation.example.REPRESENTATIVE_PERSON_DEATH_DATE': 'date from Generic types',
  'dbm.documentation.example.REPRESENTATIVE_PERSON_DEATH_NDATE': '2021-01-01',
  'dbm.documentation.example.REPRESENTATIVE_PERSON_FIRST_NAMES': 'Fred Johannes',
  'dbm.documentation.example.REPRESENTATIVE_PERSON_FULL_NAME': 'van Amerongen, Fred Johannes',
  'dbm.documentation.example.REPRESENTATIVE_PERSON_GENDER_CODE': 'F',
  'dbm.documentation.example.REPRESENTATIVE_PERSON_HOUSE_NUMBER': '84',
  'dbm.documentation.example.REPRESENTATIVE_PERSON_HOUSE_NUMBER_SUFFIX': '-A',
  'dbm.documentation.example.REPRESENTATIVE_PERSON_ID': '1000008993',
  'dbm.documentation.example.REPRESENTATIVE_PERSON_INITIALS': 'AAJ',
  'dbm.documentation.example.REPRESENTATIVE_PERSON_POSTCODE': '2600',
  'dbm.documentation.example.REPRESENTATIVE_PERSON_STREET': 'UITBREIDINGSTRAAT',
  'dbm.documentation.example.REPRESENTATIVE_PERSON_STREET_CODE': '3180',
  'dbm.documentation.example.REPRESENTATIVE_PERSON_SURNAME': 'Amerongen',
  'dbm.documentation.example.REPRESENTATIVE_PERSON_SURNAME_PREFIX': 'van',
  'dbm.documentation.example.REPRESENTATIVE_PERSON_TITLES': 'Dr.',
  'dbm.documentation.example.RESULT_SEARCH_STATUS_CODE': '-',
  'dbm.documentation.example.ROW_TYPE': 'D (=Data), R (=Removal)',
  'dbm.documentation.example.RSZ_NUMBER': '88995656',
  'dbm.documentation.example.SBI_ACTIVITY_CODE': '151101',
  'dbm.documentation.example.SEARCH_ROLE_CODE': '-',
  'dbm.documentation.example.SECONDARY_ACTIVITY_CODE_1': '8513',
  'dbm.documentation.example.SECONDARY_ACTIVITY_CODE_2': '8514',
  'dbm.documentation.example.SECONDARY_ACTIVITY_CODE_3': '8515',
  'dbm.documentation.example.SECTOR_CODE': 'A',
  'dbm.documentation.example.SEQUENCE_NUMBER': '-',
  'dbm.documentation.example.SHARE_PERCENTAGE': '100',
  'dbm.documentation.example.SHARE_TYPE_CODE': 'BE_30',
  'dbm.documentation.example.SHAREHOLDER_ADDR_LINE_1': 'Lopez de Hoyos 15',
  'dbm.documentation.example.SHAREHOLDER_ADDR_LINE_2': '3 dcha',
  'dbm.documentation.example.SHAREHOLDER_ADDR_LINE_3': '28006 Madrid',
  'dbm.documentation.example.SHAREHOLDER_ADDR_LINE_4': 'Spain',
  'dbm.documentation.example.SHAREHOLDER_ADDR_LINE_5': '-',
  'dbm.documentation.example.SHAREHOLDER_FUNDS': '123456',
  'dbm.documentation.example.SHAREHOLDER_NAME': 'Graydon Holding N.V.',
  'dbm.documentation.example.SHL_BE_ENTERPRISE_NUMBER': '8123456',
  'dbm.documentation.example.SHL_ENTERPRISE_ID': '302459',
  'dbm.documentation.example.SHL_NL_KVK_NUMBER': '33189409',
  'dbm.documentation.example.SHL_PERSON_ID': '1000008993',
  'dbm.documentation.example.SIZE_CLASS_BE_RSZ_NUM_EMPLOYEES': '2',
  'dbm.documentation.example.SIZE_CLASS_EMPLOYEE_COUNT_FULL_TIME': '3',
  'dbm.documentation.example.SIZE_CLASS_EMPLOYEE_COUNT_TOTAL': '2',
  'dbm.documentation.example.SIZE_CLASS_GROUP_EMPLOYEE_COUNT_FULL_TIME': '11',
  'dbm.documentation.example.SIZE_CLASS_GROUP_EMPLOYEE_COUNT_TOTAL': '12',
  'dbm.documentation.example.SIZE_CLASS_NUM_EMPLOYEES': '1',
  'dbm.documentation.example.SOURCE': 'BE_01',
  'dbm.documentation.example.SOURCE_REF': '20090123/12271',
  'dbm.documentation.example.START_DATE': '2021-01-01',
  'dbm.documentation.example.STREET': 'Hullenbergweg',
  'dbm.documentation.example.STREET_CODE': '3180',
  'dbm.documentation.example.SUM_TOTAL_PERCENTAGE_SUBJECT_ALL': '-',
  'dbm.documentation.example.SUMMONS': '-',
  'dbm.documentation.example.TEL_AREA_CODE': '020',
  'dbm.documentation.example.TEL_COUNTRY_CODE': '0031',
  'dbm.documentation.example.TEL_SUBSCRIBER_NUMBER': '5679999',
  'dbm.documentation.example.TELEPHONE': '-',
  'dbm.documentation.example.TELEPHONE_RECEIVER_1': '010-4400500',
  'dbm.documentation.example.TELEPHONE_RECEIVER_2': '010-4400500',
  'dbm.documentation.example.TELEPHONE_RECEIVER_3': '010-4400500',
  'dbm.documentation.example.TITLES': 'Dr.',
  'dbm.documentation.example.TITLES_RECEIVER_1': 'DRS.',
  'dbm.documentation.example.TITLES_RECEIVER_2': 'DRS.',
  'dbm.documentation.example.TITLES_RECEIVER_3': 'DRS.',
  'dbm.documentation.example.TOTAL_EQUITY': '1',
  'dbm.documentation.example.TOTAL_PERCENTAGE_SUBJECT': 'Total percentage',
  'dbm.documentation.example.TRADE_NAME_1': 'aero',
  'dbm.documentation.example.TRADE_NAME_2': 'aero plus',
  'dbm.documentation.example.TRADE_NAME_3': 'aero float',
  'dbm.documentation.example.TURNOVER': '1000000000',
  'dbm.documentation.example.TYPE_CODE': '1',
  'dbm.documentation.example.UBO_SOURCE': '-',
  'dbm.documentation.example.UBO_SOURCE_KVK_DATE': '-',
  'dbm.documentation.example.UNAVAILABLE_CONTRIBUTION': '125000',
  'dbm.documentation.example.UPDATED_ON': '2021-01-01',
  'dbm.documentation.example.URL': '-',
  'dbm.documentation.example.USE_CLASS_CODE_1': '6',
  'dbm.documentation.example.USE_CLASS_CODE_2': '10',
  'dbm.documentation.example.USE_CLASS_CODE_3': '11',
  'dbm.documentation.example.USE_CLASS_CODE_4': '6',
  'dbm.documentation.example.USE_CLASS_CODE_5': '9',
  'dbm.documentation.example.VARIABLE_CAPITAL': '125000',
  'dbm.documentation.example.VAT_LIABILITY': 'Y, N, null',
  'dbm.documentation.example.VAT_NUMBER': 'NL123456789B01',
  'dbm.documentation.example.VOTING_DATE': '2021-01-01',
  'dbm.documentation.example.WORKING_CAPITAL': '25000',
  'dbm.documentation.example.XSEPTION_CATEGORY_CODE': 'A',
  'dbm.documentation.example.XSEPTION_NDATE_1': '2018',
  'dbm.documentation.example.XSEPTION_NDATE_2': '2019',
  'dbm.documentation.example.XSEPTION_TEXT': '-',
  'dbm.documentation.example.XSEPTION_TYPE_CODE': 'NL_84',
  'dbm.documentation.example.XSEPTION_VALUE_1': '-',
  'dbm.documentation.example.XSEPTION_VALUE_2': '-',
  'dbm.documentation.example.YEAR': '2018',
  'dbm.documentation.example.NL_KVK_NUMBER_CONTINUATION': '-',
  'dbm.documentation.example.NL_LEGAL_FORM_DESCRIPTION': '-',
  'dbm.documentation.example.COUNTRY': '-',
  'dbm.documentation.example.FOUND_AT_NL_KVK_NUMBER': '-',
  'dbm.documentation.example.FOUND_AT_NL_LEGAL_FORM_DESCRIPTION': '-',
  'dbm.documentation.example.PERSON_BIRTH_COUNTRY': '-',
  'dbm.documentation.example.RESULT_SEARCH_STATUS_DESCRIPTION': '-',
  'dbm.documentation.example.INTEREST_TYPE_CODE': '-',
  'dbm.documentation.example.INTEREST_SIZE_CODE': '-',
  'dbm.documentation.example.REGISTRATION_DATE': '-',
  'dbm.documentation.expand-all': 'Expand all',
  'dbm.documentation.field.description': 'description',
  'dbm.documentation.field.example': 'example',
  'dbm.documentation.field.length': 'length',
  'dbm.documentation.field.level': 'level',
  'dbm.documentation.field.name': 'name',
  'dbm.documentation.field.nullable': 'null',
  'dbm.documentation.field.ROW_TYPE': 'ROW_TYPE',
  'dbm.documentation.field.type': 'type',
  'dbm.documentation.footer': '-',
  'dbm.documentation.go-back': 'Go back',
  'dbm.documentation.print': 'Print documentation',
  'dbm.documentation.show-documentation': 'View documentation',
  'dbm.documentation.title': 'Datablocks documentation',
  'dbm.enrichedScheduleSet.paragraph': 'The first download will be sent to {email} shortly',
  'dbm.enrichedScheduleSet.paragraph.later': 'The first download will be sent to {email} on {date}',
  'dbm.executions.download-file': 'Download file',
  'dbm.executions.downloading-file': 'Download',
  'dbm.executions.endDate': 'End Date',
  'dbm.executions.error': 'Error in fetching deliveries',
  'dbm.executions.error.retry': 'Retry',
  'dbm.executions.file-size': 'File size',
  'dbm.executions.report': 'File',
  'dbm.executions.sequentialNumber': 'Sequential number',
  'dbm.executions.startDate': 'Start date',
  'dbm.executions.status': 'Status',
  'dbm.executions.status.done': 'Done',
  'dbm.executions.status.failed': 'Failed',
  'dbm.executions.status.scheduled': 'Scheduled',
  'dbm.executions.status.updating_data': 'Updating data',
  'dbm.executions.status.updating_list': 'Updating list',
  'dbm.executions.title': 'Previous deliveries',
  'dbm.executions.type': 'Type',
  'dbm.executions.type.delta': 'Changes',
  'dbm.executions.type.full': 'Full dataset',
  'dbm.homepage.disabled': 'Disabled, no deliveries scheduled',
  'dbm.homepage.noListsAvailable': "You don't have a list of companies yet.",
  'dbm.homepage.scheduleButton': "Let's start",
  'dbm.homepage.scheduled.empty.heading': 'You are not receiving continuous updates yet!',
  'dbm.homepage.scheduled.empty.text': 'This means that your database is not up-to-date.',
  'dbm.homepage.scheduled.heading': 'Continuous enrichments on your database',
  'dbm.homepage.schedules.addAnother': 'Add another list for continuous updates',
  'dbm.homepage.schedules.interval': 'Every {num, plural, =1 {day} other {{num} days}}',
  'dbm.homepage.single.file': 'Enrich your file',
  'dbm.homepage.single.heading': 'One time enrichment',
  'dbm.homepage.single.list': 'Enrich and download a list',
  'dbm.homepage.single.text': 'Need the data now? Enrich your file or a list once.',
  'dbm.homepage.startEnrichmentButton': 'Start enrichment',
  'dbm.homepage.title': 'Data Enrichment',
  'dbm.important.stuff': 'great',
  'dbm.importCompaniesLink': 'Import companies',
  'dbm.importCompaniesLink.right': '(exits Database management)',
  'dbm.profile-overview.add-new-profile': 'Add new profile',
  'dbm.profile-overview.no-creation-access':
    'You do not have the user permission to create a profile. Please contact the database manager and ask them to create a profile.',
  'dbm.profile-overview.no-profile': 'You have not set up a database management profile yet.',
  'dbm.profile-overview.no-profile.restricted':
    'You have not set up a database management profile.',
  'dbm.profile-overview.note': 'Note',
  'dbm.profile-overview.title': 'Profile overview',
  'dbm.profileOverview.prevDeliveries.hide': 'Hide previous deliveries',
  'dbm.profileOverview.prevDeliveries.view': 'View previous deliveries',
  'dbm.scheduleEnrichmentForm.heading': 'Keep your database up-to-date',
  'dbm.scheduleEnrichmentForm.schedule.daysStarting': 'days, starting',
  'dbm.scheduleEnrichmentForm.schedule.every': 'Every',
  'dbm.scheduleEnrichmentForm.schedule.heading': 'You will receive an email with fresh data',
  'dbm.scheduleEnrichmentForm.schedule.info': 'You will receive the updates at {email}',
  'dbm.scheduleEnrichmentForm.selectData.explanation':
    'The more data you choose, the more valuable your database becomes.',
  'dbm.scheduleEnrichmentForm.selectData.heading': 'Which data should we add to these companies?',
  'dbm.scheduleEnrichmentForm.selectList.heading': 'Which companies are in your database?',
  'dbm.scheduleEnrichmentForm.start': 'Start',
  'dbm.scheduleList.deleteSchedule': 'Remove continuous enrichment',
  'dbm.scheduleList.deleteSchedule.message':
    'Are you sure you want to remove the continuous enrichment from "{tagName}"?',
  'dbm.scheduleList.deleteSchedule.message2': 'You can always add it again later.',
  'dbm.update-profile.dbm-disabled-for-this-country':
    'Note: Database Management is disabled for this country. Datafiles will **not** be delivered.',
  'dbm.update-profile.error.message': 'There was an error updating the profile',
  'dbm.update-profile.error.title': 'Error updating profile',
  'dbm.update-profile.success.message': 'The profile was updated successfully.',
  'dbm.update-profile.success.message.new-blocks-added':
    '**Note:** You added a new datablock. Next delivery will contain all data for the newly added datablock(s).',
  'dbm.update-profile.success.title': 'Profile updated successfully',
  'dbm.update-profile.title': 'Edit profile',
  'dbm.view-profile.profile-id': 'ProfileID (for API usage)',
  'dbm.view-profile.profile-id.tooltip':
    'Use this ID to find data from the right database management profile',
  'dbm.view-profile.send-single-delivery': 'Prepare a single delivery now',
  'dbm.view-profile.send-single-delivery.desc': 'based on this profile',
  'dbm.view-profile.single-delivery': 'Single delivery',
  'dbm.view-profile.single-delivery.failed': 'Extra delivery cannot be prepared',
  'dbm.view-profile.single-delivery.failed.desc':
    'Either no company list has been selected in this profile or the data cannot be retrieved. Please select a company list or try again later.',
  'dbm.view-profile.single-delivery.success': 'Extra delivery is ready',
  'dbm.view-profile.single-delivery.success.desc':
    "When the data is retrieved the data file will be delivered. Check the 'Previous deliveries' overview in this profile to download the data file.",
  'dbm.view-profile.single-delivery.tooltip':
    'Click this link to manually start an extra data retrieval based on the saved settings of this profile. When data is retrieved, the data file will be available in the list of previous deliveries.',
  'dbm.view-profile.title': 'View profile',
  'de.dataOverview.continue': 'Continue',
  'de.dataOverview.heading': "Let's start by choosing which data you want",
  'de.downloadUpdatedFile': 'Download enriched file',
  'de.homePage.continue': 'Start enrichment',
  'de.homePage.heading': 'Data Enrichment',
  'de.homePage.step1.heading': '1. Choose the data you want',
  'de.homePage.step1.text': 'This will be added to your file as new columns',
  'de.homePage.step2.heading': '2. Import your company file',
  'de.homePage.step2.li1': 'Upload your file',
  'de.homePage.step2.li2': 'Map your fields',
  'de.homePage.step2.li3': 'Match with Graydon data',
  'de.homePage.step3.heading': '3. Download enriched file',
  'de.homePage.step3.text': 'We never change your original data',
  'de.listEnrichment.downloadButton': 'Download enriched list',
  'dm.acceptance.label': ' ',
  'dm.acceptance.legend': ' ',
  'dm.accordion.contact': 'General company information',
  'dm.accordion.financials': 'Annual accounts',
  'dm.accordion.ginsights': 'Graydon Insights',
  'dm.accordion.management': 'Management',
  'dm.accordion.relation': 'Company structure',
  'dm.balanced.lede':
    'With a slightly more flexible acceptance policy, you can still achieve growth and increase profits without large risks on bad debt write-offs.',
  'dm.balanced.title': 'Balanced',
  'dm.createModel.header': "Let's get started",
  'dm.createModel.instruction':
    "Welcome to the setup. Before you can start accepting clients you'll need to choose your first decision model and fine-tune it or save a default version. No worries, it's a hassle free affair. Select a country, select a model and finish by personalising the model or save it as default.",
  'dm.createModel.subHeader': "Let's start by selecting a country",
  'dm.credit.check.error':
    'Unfortunately, this information is not available online.<br /><br />However, for assistance, please contact our Customer Service team at <a href="mailto:{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}">{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}</a> or on {country, select, gb {+44 (020) 8515 1400} nl {+31 (020) 567 9501} other {+32 (03) 280 88 80}}.',
  'dm.defensive.lede':
    'This model is suitable if you want to be at low risk and restrict bad debt write-offs to a minimum.',
  'dm.defensive.title': 'Defensive',
  'dm.delete.confirm.desc': 'You are about to delete an decision model. This is unreversable.',
  'dm.delete.confirm.no': 'No',
  'dm.delete.confirm.question': 'Are you sure?',
  'dm.delete.confirm.title': 'Hold up!',
  'dm.delete.confirm.yes': 'Yes',
  'dm.editor.accept': 'Accept',
  'dm.editor.annualAccountAvailable': 'Annual account?',
  'dm.editor.annualAccountAvailable.desc':
    "Here you can set the conditions for the Checkpoint Availability Annual Account. You can read this as 'If, Then' statements. So, 'If' that condition is met,'Then' the selected follow-up action is taken.",
  'dm.editor.annualAccountAvailable.title': 'Checkpoint: Is the annual account available?',
  'dm.editor.augurScore': 'Augur score',
  'dm.editor.augurScore-1': 'augur 1',
  'dm.editor.augurScore-2': 'augur 2',
  'dm.editor.augurScore-3': 'augur 3',
  'dm.editor.augurScore.desc':
    "Here you can set the conditions for the Augur Score Checkpoint. You can read this as 'If, Then' statements. So, 'If' that condition is met,'Then' the selected follow-up action is taken.",
  'dm.editor.augurScore.title': 'Checkpoint: Augur score',
  'dm.editor.be.creditLimit.desc':
    "Here you can set the conditions for the Credit Limit Checkpoint. You can read this as 'If, Then' statements. So, 'If' that condition is met, 'Then' the selected follow-up action is taken. The 'Order value' can be provided when you perform a check.",
  'dm.editor.be.multiscore.threshold.invalid': 'The value needs to be between 0 and 100',
  'dm.editor.ccj.description': ' ',
  'dm.editor.ccjCount': 'Number of CCJs',
  'dm.editor.ccjCount.desc':
    "Here you can set the conditions for the CCJ Count Checkpoint. You can read this as 'If, Then' statements. So, 'If' that condition is met, 'Then' the selected follow-up action is taken.",
  'dm.editor.ccjCount.title': 'Checkpoint: Number of CCJs',
  'dm.editor.companyActive': 'Company active?',
  'dm.editor.companyActive.desc':
    "Here you can set the conditions for the Company Active Checkpoint. You can read this as 'If, Then' statements. So, 'If'that condition is met,'Then'the selected follow-up action is taken.",
  'dm.editor.companyActive.title': 'Checkpoint: is the company active?',
  'dm.editor.companyAge': 'Company Age',
  'dm.editor.companyAge.desc':
    "Here you can set the conditions for the Company Age Checkpoint. You can read this as 'If, Then' statements. So, 'If' that condition is met, 'Then' the selected follow-up action is taken.",
  'dm.editor.companyAge.months': 'months',
  'dm.editor.companyAge.title': 'Checkpoint: Company Age',
  'dm.editor.companyIncorporated': 'Company Incorporated',
  'dm.editor.companyIncorporated.desc':
    "Here you can set the conditions for the Company Incorporated Checkpoint. You can read this as 'If, Then' statements. So, 'If' that condition is met, 'Then' the selected follow-up action is taken.",
  'dm.editor.companyIncorporated.title': 'Checkpoint: Company Incorporated?',
  'dm.editor.companyName': 'Company Name',
  'dm.editor.companyRegisterNumber': 'Company Register Number',
  'dm.editor.credit.limit': 'Order Value',
  'dm.editor.credit.limit.zero': 'Credit limit = {currency} 0',
  'dm.editor.creditLimit': 'Credit limit',
  'dm.editor.creditLimit.desc':
    "Here you can set the conditions for the Credit Limit Checkpoint. You can read this as  'If, Then' statements. So, 'If' that condition is met, 'Then' the selected follow-up action is taken. The 'Order value' can be provided when you perform a check.",
  'dm.editor.creditLimit.threshold.invalid': 'The value needs to be between 0 and 12',
  'dm.editor.creditLimit.title': 'Checkpoint: Graydon credit limit',
  'dm.editor.creditRating': 'Credit rating',
  'dm.editor.creditRating.desc':
    "Here you can set the conditions for the Credit Rating Checkpoint. You can read this as 'If, Then' statements. So, 'If' that condition is met, 'Then' the selected follow-up action is taken.",
  'dm.editor.creditRating.outcome.above': 'Credit rating',
  'dm.editor.creditRating.outcome.below': 'Credit rating',
  'dm.editor.creditRating.title': 'Checkpoint: Credit Rating',
  'dm.editor.deny': 'Reject',
  'dm.editor.error': 'The value has to be between {minValue} and {maxValue}',
  'dm.editor.esc.to.close': 'Close without saving',
  'dm.editor.help.annualAccountAvailable.condition':
    'The availability of the annual account has an influence on the Multiscore and the Credit limit.',
  'dm.editor.help.annualAccountAvailable.decision':
    'Select the follow-up action. Provide a description to personalise the outcome. This desctiption is shown in check results.',
  'dm.editor.help.augurScore.condition':
    'The Augur score is an indicator of financial health. Higher scores mean less risk. Make sure the values in the conditions are coherent.',
  'dm.editor.help.augurScore.decision':
    'Select the follow-up action. Provide a description to personalise the outcome. This desctiption is shown in check results.',
  'dm.editor.help.ccjCount.condition':
    'CCJs are legal decisions handed down by the County Court. Set the maximum allowed amount. Make sure the values in both conditions match.',
  'dm.editor.help.ccjCount.decision':
    'Select the follow-up action. Provide a description to personalise the outcome. This desctiption is shown in check results.',
  'dm.editor.help.companyActive.condition':
    "The start of every model is to check if the company is active. Companies that are dissolved, or bankrupt or listed as 'Inactive'.",
  'dm.editor.help.companyActive.decision':
    'Select the follow-up action. Provide a description to personalise the outcome. This desctiption is shown in check results.',
  'dm.editor.help.companyAge.condition':
    "Set the company's age in months. Make sure the values in both conditions match.",
  'dm.editor.help.companyAge.decision':
    'Select the follow-up action. Provide a description to personalise the outcome. This desctiption is shown in check results.',
  'dm.editor.help.companyIncorporated.condition':
    "A company is Incorporated (Y) if it concerns a legal entity; Unincorporated (N) if it's a natural person.",
  'dm.editor.help.companyIncorporated.decision':
    'Select the follow-up action. Provide a description to personalise the outcome. This desctiption is shown in check results.',
  'dm.editor.help.creditLimit.condition':
    'The order value is compared to the Graydon Credit Limit. You can use a factor to multiply or denominate the Graydon credit limit.',
  'dm.editor.help.creditLimit.decision':
    'Select the follow-up action. Provide a description to personalise the outcome. This desctiption is shown in check results.',
  'dm.editor.help.creditRating': 'Lorem ipsum dolor sit amet',
  'dm.editor.help.creditRating.condition':
    "The Credit Rating is 'X' if there's high risk with a company. If the rating is not 'X', we advice to move on to the Augur score.",
  'dm.editor.help.creditRating.decision':
    'Select the follow-up action. Provide a description to personalise the outcome. This desctiption is shown in check results.',
  'dm.editor.help.multiscore.condition':
    'The Multiscore is an indicator of financial health. Higher scores mean less risk. Make sure the values in both conditions match.',
  'dm.editor.help.multiscore.decision':
    'Select the follow-up action. Provide a description to personalise the outcome. This desctiption is shown in check results.',
  'dm.editor.help.orderValue.condition':
    'If an order value exceeds the level you set here, you may want to refer. Make sure the order values in both conditions match.',
  'dm.editor.help.orderValue.decision':
    'Select the follow-up action. Provide a description to personalise the outcome. This desctiption is shown in check results.',
  'dm.editor.help.payments.condition':
    "The payment qualification is condensed to 'Good' and 'Bad'. 'Good': we have positive experiences; 'Bad': we have negative experiences.",
  'dm.editor.help.payments.decision':
    'Select the follow-up action. Provide a description to personalise the outcome. This desctiption is shown in check results.',
  'dm.editor.help.paymentsScore.condition':
    "The payment qualification is condensed to 'Good' and 'Bad'. 'Good': we have positive experiences; 'Bad': we have negative experiences.",
  'dm.editor.help.paymentsScore.decision':
    'Select the follow-up action. Provide a description to personalise the outcome. This desctiption is shown in check results.',
  'dm.editor.help.probabilityOfDefault.condition':
    'The probability of default is a percentage value. Higher PD% means higher risk. Not Rated applies to missing values.',
  'dm.editor.help.probabilityOfDefault.decision':
    'Select the follow-up action. Provide a description to personalise the outcome. This desctiption is shown in check results.',
  'dm.editor.help.tree.view':
    "This page displays your decision model in a tree-like structure. When a check is performed on a company, that company comes in from the left. The blue boxes are checkpoints, and in each of these the company data is checked against specified conditions, and a follow-up action is executed. Boxes in white specify the conditions for that checkpoint. The company can either move on to a next checkpoint, or end up in an outcome. The outcomes are displayed as green boxes and have 3 categories: Accept, Refer, and Deny. By clicking on a checkpoint, you can change the conditions and the outcomes of that check. When you're done editing the model, click on Save and close on the top right and start using the model.",
  'dm.editor.isCompanyIncorporated': 'Company Incorporated?',
  'dm.editor.multiscore': 'Multiscore',
  'dm.editor.multiscore.desc':
    "Here you can set the conditions for the Multiscore Checkpoint. You can read this as 'If, Then' statements. So, 'If' that condition is met, 'Then' the selected follow-up action is taken. ",
  'dm.editor.multiscore.title': 'Checkpoint: Multiscore',
  'dm.editor.nameEditor.err': 'This is not a valid name',
  'dm.editor.nameEditor.placeholder': 'Give your model a name',
  'dm.editor.next.checkpoint': 'Go to next checkpoint',
  'dm.editor.nl.creditLimit.desc':
    "Here you can set the conditions for the Payments Checkpoint. You can read this as 'If, Then' statements. So, 'If' that condition is met, 'Then' the selected follow-up action is taken. ",
  'dm.editor.nl.paymentsScore.desc':
    "Here you can set the conditions for the Payments Checkpoint. You can read this as 'If, Then' statements. So, 'If' that condition is met, 'Then' the selected follow-up action is taken. ",
  'dm.editor.nl.probabilityOfDefault.threshold.invalid': 'The value needs to be between 0.1 and 20',
  'dm.editor.operator.nr': 'Not rated',
  'dm.editor.orderValue': 'order value',
  'dm.editor.orderValue.desc':
    "Here you can set the conditions for the Order Value Checkpoint. You can read this as 'If, Then' statements. So, 'If'that condition is met,'Then'the selected follow-up action is taken.",
  'dm.editor.orderValue.k': ' ',
  'dm.editor.orderValue.title': 'Order Value',
  'dm.editor.outcome.description': 'Result description',
  'dm.editor.outcomes': 'Go to outcome',
  'dm.editor.payments': 'Payment behaviour',
  'dm.editor.payments.desc':
    "Here you can set the conditions for the Payments Checkpoint. You can read this as 'If, Then' statements. So, 'If' that condition is met, 'Then' the selected follow-up action is taken. ",
  'dm.editor.payments.title': 'Checkpoint: Payment behaviour',
  'dm.editor.paymentsScore': 'Payment behaviour',
  'dm.editor.paymentsScore.desc': 'Adjust the threshold for payment score.',
  'dm.editor.paymentsScore.title': 'Payment score',
  'dm.editor.pd.nr': 'Probability of default = “Not Rated”',
  'dm.editor.probabilityOfDefault': 'Probability of default',
  'dm.editor.probabilityOfDefault.desc':
    "Here you can set the conditions for the Probability of Default Checkpoint. You can read this as 'If, Then' statements. So, 'If' that condition is met,'Then' the selected follow-up action is taken.",
  'dm.editor.probabilityOfDefault.desc.nl':
    "Do you need help with setting up a PD percentage? View in the <a href='https://connect.graydon-global.com/rs/934-AQC-614/images/EN PD Sheet.pdf' target='_blank' rel='noopener noreferrer'>sector data table (pdf)</a> how many companies you can accept per sector, based on the PD percentage. Please note: this is a snapshot and therefore, only a guideline.",
  'dm.editor.probabilityOfDefault.title': 'Checkpoint: Probability of default',
  'dm.editor.refer': 'Refer',
  'dm.editor.setup': 'Decision model set up',
  'dm.editor.totalStaffEmployed': 'Total staff employed',
  'dm.firstrun.cta': 'Create a decision model',
  'dm.firstrun.lede':
    'Welcome to the set-up. Before you can start accepting clients  you’ll need to create your first decision model. No worries, it’s a hassle-free process.',
  'dm.firstrun.next.steps': ' ',
  'dm.firstrun.step1': 'Step 1. Select a country;',
  'dm.firstrun.step2': 'Step 2. Select a model;',
  'dm.firstrun.step3': 'Step 3. Personalise the model.',
  'dm.firstrun.title': 'Get started',
  'dm.label.client.acceptance': 'Customer acceptance',
  'dm.label.debt.prevention': 'Bad debt prevention',
  'dm.management.action.activate': 'activate',
  'dm.management.action.deactivate': 'deactivate',
  'dm.management.action.delete': 'delete',
  'dm.management.action.edit': 'edit',
  'dm.management.create': 'Create new model',
  'dm.management.title': 'Model management',
  'dm.multiselect.all-models': 'All decision models',
  'dm.multiselect.n-models': '{n} decision models selected',
  'dm.offensive.lede':
    'If you want high growth and take a chance on running up bad debt, this is your way to go.',
  'dm.offensive.title': 'Offensive',
  'dm.pod.tolerance.accept': 'we <b>accept</b>',
  'dm.pod.tolerance.deny': 'we <b>deny</b>',
  'dm.pod.tolerance.if': 'If <b>probability of default</b>',
  'dm.pod.tolerance.label': "Annually, I don't want to write off on bad debts more than…",
  'dm.run.backtosearch': 'Back to search results',
  'dm.run.choosemodel': 'Select existing decision model',
  'dm.run.ordervalue': 'Enter the order value here',
  'dm.run.result.bucket-accept': 'Accept',
  'dm.run.result.bucket-deny': 'Reject',
  'dm.run.result.bucket-refer': 'Refer',
  'dm.run.result.bucket-undefined': 'Undefined',
  'dm.run.result.checkpoint.be.annualAccountAvailable': 'Annual account available?',
  'dm.run.result.checkpoint.be.annualAccountAvailable.threshold.label': 'Threshold Value',
  'dm.run.result.checkpoint.be.multiscore': 'Multiscore',
  'dm.run.result.checkpoint.be.multiscore.threshold.label': 'Threshold Value',
  'dm.run.result.checkpoint.be.paymentsScore': 'Payment Score',
  'dm.run.result.checkpoint.be.paymentsScore.threshold.label': 'Threshold Value',
  'dm.run.result.checkpoint.companyActive': 'Active company?',
  'dm.run.result.checkpoint.companyActive.threshold.label': 'Threshold Value',
  'dm.run.result.checkpoint.creditLimit': 'Credit Limit',
  'dm.run.result.checkpoint.creditLimit.threshold.label': 'Order Value',
  'dm.run.result.checkpoint.failed.description': 'The result could not be determined',
  'dm.run.result.checkpoint.failed.label': 'Result',
  'dm.run.result.checkpoint.gb.augurScore': 'Augur Score',
  'dm.run.result.checkpoint.gb.augurScore.threshold.label': 'Threshold Value',
  'dm.run.result.checkpoint.gb.ccjCount': 'CCJ Count',
  'dm.run.result.checkpoint.gb.ccjCount.threshold.label': 'Threshold Value',
  'dm.run.result.checkpoint.gb.companyAge': 'Company Age',
  'dm.run.result.checkpoint.gb.companyAge.threshold.label': 'Threshold Value',
  'dm.run.result.checkpoint.gb.companyIncorporated': 'Incorporated company?',
  'dm.run.result.checkpoint.gb.companyIncorporated.threshold.label': 'Threshold Value',
  'dm.run.result.checkpoint.gb.creditRating': 'Credit Rating',
  'dm.run.result.checkpoint.gb.creditRating.threshold.label': 'Threshold Value',
  'dm.run.result.checkpoint.gb.orderValue': 'Order Value',
  'dm.run.result.checkpoint.gb.orderValue.threshold.label': 'Threshold Value',
  'dm.run.result.checkpoint.nl.paymentsScore': 'Payment Score',
  'dm.run.result.checkpoint.nl.paymentsScore.threshold.label': 'Threshold Value',
  'dm.run.result.checkpoint.nl.probabilityOfDefault': 'Percentage likelihood of failure',
  'dm.run.result.checkpoint.nl.probabilityOfDefault.threshold.label': 'Threshold Value',
  'dm.run.result.checkpoint.payments': 'Payment Behaviour',
  'dm.run.result.checkpoint.payments.threshold.label': 'Threshold Value',
  'dm.run.result.checkpoint.value.bad': 'Bad',
  'dm.run.result.checkpoint.value.good': 'Good',
  'dm.run.result.checkpoint.value.n': 'No',
  'dm.run.result.checkpoint.value.na': 'Not available',
  'dm.run.result.checkpoint.value.no': 'No',
  'dm.run.result.checkpoint.value.notX': 'Not X',
  'dm.run.result.checkpoint.value.X': 'X',
  'dm.run.result.checkpoint.value.y': 'Yes',
  'dm.run.result.checkpoint.value.yes': 'Yes',
  'dm.run.result.failed': 'The result could not be retrieved',
  'dm.run.runcheck': 'Run check',
  'dm.setup.step1': 'Select a country',
  'dm.setup.step2': 'What is your risk appetite?',
  'dm.setup.step3': 'Personalise your model',
  'dm.setup.step4': 'Done!',
  'dm.templates.lede':
    "We've selected these pre-configured decision models for you. You can choose one and fine-tune in the next step.",
  'dm.templates.title': 'What is your risk appetite?',
  'dm.tooltip.acceptance.legend': ' ',
  'dm.tooltip.acceptance.value': ' ',
  'dm.tooltip.client.acceptance':
    'The more blacks are filled in this model, the more focus is put on customer acceptance and growth.',
  'dm.tooltip.debt.prevention':
    'The more blocks are filled in this model, the more focus is put on bad debt prevention.',
  'dm.tooltip.tolerance.accept':
    'If the probability of default is lower than the threshold value, the customer can be accepted.',
  'dm.tooltip.tolerance.deny':
    'If the probability of default is higher than the threshold value, the customer cannot be accepted.',
  'dm.tooltip.tolerance.threshold':
    'Define your maximum annual tolerance for bad debt write-offs. This value will be compared to the probability of default when performing checks.',
  'dm.userBar.cancel': 'Cancel and close',
  'download.as.csv': 'Download as CSV',
  'error.contact-support': 'Contact customer service',
  'error.inactive.description':
    "Give us a call at the number below, and we'll help you out quickly.",
  'error.inactive.title': 'Your account is currently inactive',
  'error.login': 'Login',
  'error.retry-login': 'Please try logging in again',
  'error.something-wrong': 'Something went wrong',
  'error.unknown.description':
    "Sadly, login failed. It could be that your account is (still) inactive. It could also be that your 'Consumer ID' has been registered incorrectly. This may directly ring a bell, but if you pass that information on to our Service Desk, they'll be able to help you out quickly.",
  'error.unknown.title': "Something's not right",
  'filter.pane.button.tooltip': 'Click to toggle the filters',
  'filter.pane.header': 'Filters',
  'filters.countries.be': 'BE',
  'filters.countries.gb': 'UK',
  'filters.countries.nl': 'NL',
  'filters.countries.xx': 'Unknown',
  'filters.sectors.a': 'Agriculture forestry and fishing',
  'filters.sectors.a.short': 'Agriculture forestry and fishing',
  'filters.sectors.b': 'Mining and quarrying',
  'filters.sectors.b.short': 'Mining and quarrying',
  'filters.sectors.c': 'Manufacturing',
  'filters.sectors.c.short': 'Manufacturing',
  'filters.sectors.d': 'Electricity gas steam and air conditioning supply',
  'filters.sectors.d.short': 'Electricity gas steam and air conditioning supply',
  'filters.sectors.e': 'Water supply; sewerage; waste managment and remediation activities',
  'filters.sectors.e.short': 'Water supply; sewerage; waste managment and remediation activities',
  'filters.sectors.f': 'Construction',
  'filters.sectors.f.short': 'Construction',
  'filters.sectors.g': 'Wholesale and retail trade; repair of motor vehicles and motorcycles',
  'filters.sectors.g.short': 'Wholesale and retail trade; repair of motor vehicles and motorcycles',
  'filters.sectors.h': 'Transporting and storage',
  'filters.sectors.h.short': 'Transporting and storage',
  'filters.sectors.i': 'Accommodation and food service activities',
  'filters.sectors.i.short': 'Accommodation and food service activities',
  'filters.sectors.j': 'Information and communication',
  'filters.sectors.j.short': 'Information and communication',
  'filters.sectors.k': 'Financial and insurance activities',
  'filters.sectors.k.short': 'Financial and insurance activities',
  'filters.sectors.l': 'Real estate activities',
  'filters.sectors.l.short': 'Real estate activities',
  'filters.sectors.m': 'Professional scientific and technical activities',
  'filters.sectors.m.short': 'Professional scientific and technical activities',
  'filters.sectors.n': 'Administrative and support service activities',
  'filters.sectors.n.short': 'Administrative and support service activities',
  'filters.sectors.o': 'Public administration and defence; compulsory social security',
  'filters.sectors.o.short': 'Public administration and defence; compulsory social security',
  'filters.sectors.p': 'Education',
  'filters.sectors.p.short': 'Education',
  'filters.sectors.q': 'Human health and social work activities',
  'filters.sectors.q.short': 'Human health and social work activities',
  'filters.sectors.r': 'Arts entertainment and recreation',
  'filters.sectors.r.short': 'Arts entertainment and recreation',
  'filters.sectors.s': 'Other services activities',
  'filters.sectors.s.short': 'Other services activities',
  'filters.sectors.t':
    'Activities of households as employers; undifferentiated goods - and services - producing activities of households for own use',
  'filters.sectors.t.short':
    'Activities of households as employers; undifferentiated goods - and services - producing activities of households for own use',
  'filters.sectors.u': 'Activities of extraterritorial organisations and bodies',
  'filters.sectors.u.short': 'Activities of extraterritorial organisations and bodies',
  'filters.sectors.x': 'Other',
  'filters.sectors.x.short': 'Other',
  'footer.cookies': 'Cookies',
  'footer.copyright': '© Graydon. All rights reserved',
  'footer.gdpr': 'GDPR',
  'footer.terms.and.conditions': 'Terms and Conditions',
  'form.validation.max-length': 'This field can contain a maximum of {max} characters',
  'form.validation.required': 'The field is required',
  'gdm.check-modal.archive-description': 'Only the latest 10 checks are displayed',
  'gdm.check-modal.archive-error': 'Error loading previous check(s)',
  'gdm.check-modal.archive-header': 'Previous check(s) for this company',
  'gdm.check-modal.archive-no-result': 'No previous checks',
  'gdm.check-modal.select': 'Select a decision model',
  'gdm.check-modal.title': 'Check with decision model',
  'gdm.check-modal.view-check': 'View check',
  'gdm.check-result.checkpoint.current-value': 'Current value',
  'gdm.check-result.checkpoint.indicator': 'Indicator',
  'gdm.check-result.checkpoint.order-value': 'Threshold value',
  'gdm.check-result.checkpoint.title.accept': 'Accept',
  'gdm.check-result.checkpoint.title.deny': 'Reject',
  'gdm.check-result.checkpoint.title.refer': 'Refer',
  'gdm.check-result.checkpoint.tooltip':
    'Here you will find the most important details according to the decision model check',
  'gdm.check-result.company-details.tooltip':
    'Here you will find all the basic information about the company you requested.',
  'gdm.check-result.company-relations.tooltip':
    'Here you will find the list of relations about the company you requested.',
  'gdm.check-result.contact-details.tooltip':
    'Here you will find the contact details about the company you requested.',
  'gdm.check-result.directors.tooltip':
    'Here you will find an overview of the active and possibly inactive directors, associated authority and other functions of these directors.',
  'gdm.check-result.graydon-insights.tooltip':
    'Here you will find some important details about the company you requested.',
  'gdm.check-result.summary.date': 'Date',
  'gdm.check-result.summary.decision-model': 'Decision Model',
  'gdm.check-result.summary.order-value': 'Order Value',
  'gdm.report.orderValue.customized': 'Customized',
  'gdm.report.orderValue.recommended': 'Recommended',
  'general.contactGraydon': 'Contact Graydon',
  'general.customerCare': 'Customer Care',
  'general.fetch.error': 'Error fetching data, try again later',
  'general.na': 'N/A',
  'general.noRating': 'NR',
  'general.not.available': 'Not available',
  'general.sort': 'Sort',
  'general.sort.ascending': 'Ascending',
  'general.sort.descending': 'Descending',
  'general.specify-search':
    "Can't find what you are looking for? Please make your search more specific.",
  'general.to.the.attention.of': 'attn.',
  'get.support': ' ',
  heading: 'UBO Report',
  'home.heading': 'Welcome',
  'home.heading.subtext': 'Get started on a decision model, or search for a company.',
  'icr-form-field-required': 'Required',
  'icr.archive-page.description':
    'Archive view for all requests made from your account to the Graydon system.',
  'icr.archive-page.header': 'Archive',
  'icr.archive-page.tab.pending': 'Pending',
  'icr.archive-page.tab.received': 'Received',
  'icr.archive.load.more.reports': 'Load {pageSize} more reports',
  'icr.archive.orders.client-note': 'Your reference',
  'icr.archive.orders.company-name': 'Company name',
  'icr.archive.orders.completed.tooltip':
    'A copy of all the reports you have previously requested and downloaded is saved in this section.',
  'icr.archive.orders.country': 'Country',
  'icr.archive.orders.dateCreated': 'Date created',
  'icr.archive.orders.documentName': 'Report name',
  'icr.archive.orders.due-date': 'Due date',
  'icr.archive.orders.genericError.description':
    'The requested report could not be delivered. Please request a [Fresh Investigation](/apps/international-credit-reports/order-investigated-report).',
  'icr.archive.orders.genericError.title': 'Order failed',
  'icr.archive.orders.hideReports': 'Hide {count} reports',
  'icr.archive.orders.no-result': 'No results found',
  'icr.archive.orders.order-reference': 'Order #',
  'icr.archive.orders.ordered-date': 'Date ordered',
  'icr.archive.orders.pending.tooltip':
    'Find here all the reports you have previously ordered and whose delivery is presently pending.',
  'icr.archive.orders.report': 'Report',
  'icr.archive.orders.search.placeholder':
    'Search by company name, country, order number or your reference.',
  'icr.archive.orders.showReports': 'View {count} reports',
  'icr.archive.orders.type': 'Type',
  'icr.archive.report.download.toast.body.error':
    'Report cannot be downloaded. Please try again later',
  'icr.archive.report.download.toast.body.link-to-archive': 'Go to the Archive section',
  'icr.archive.report.download.toast.body.not-available': 'Please try downloading the report again',
  'icr.archive.report.download.toast.body.success':
    'Your report is also stored in the International Business Archive you can access it anytime there.',
  'icr.archive.report.download.toast.title.error': 'Cannot be downloaded',
  'icr.archive.report.download.toast.title.not-available': 'Report currently unavailable',
  'icr.archive.report.download.toast.title.success': 'Report downloaded',
  'icr.archive.request-report.toast.link': 'Go to the archive',
  'icr.archive.request-report.toast.text':
    'The report will be available soon. Track the progress in the International Business Archive section.',
  'icr.archive.request-report.toast.text.error':
    'Please try again later or [contact](/apps/contact) Graydon.',
  'icr.archive.request-report.toast.title': 'Your report is being generated',
  'icr.archive.request-report.toast.title.error': 'Request could not be sent',
  'icr.country-select.disabled.hover':
    'This country is not included your package. Please contact us to upgrade.',
  'icr.country-select.domestic': 'Domestic',
  'icr.country-select.placeholder': 'Select or type country',
  'icr.country-select.report-type.database': 'Database report',
  'icr.country-select.report-type.domestic-database': 'Domestic database report',
  'icr.country-select.report-type.investigation': 'Fresh Investigation',
  'icr.country.AD': 'Andorra',
  'icr.country.AE': 'United Arab Emirates',
  'icr.country.AF': 'Afghanistan',
  'icr.country.AG': 'Antigua and Barbuda',
  'icr.country.AI': 'Anguilla',
  'icr.country.AL': 'Albania',
  'icr.country.AM': 'Armenia',
  'icr.country.AN': 'Netherlands Antilles',
  'icr.country.AO': 'Angola',
  'icr.country.AQ': 'Antarctica',
  'icr.country.AR': 'Argentina',
  'icr.country.AS': 'American Samoa',
  'icr.country.AT': 'Austria',
  'icr.country.AU': 'Australia',
  'icr.country.AW': 'Aruba',
  'icr.country.AX': 'Aland Islands',
  'icr.country.AZ': 'Azerbaijan',
  'icr.country.BA': 'Bosnia and Herzegovina',
  'icr.country.BB': 'Barbados',
  'icr.country.BD': 'Bangladesh',
  'icr.country.BE': 'Belgium',
  'icr.country.BF': 'Burkina Faso',
  'icr.country.BG': 'Bulgaria',
  'icr.country.BH': 'Bahrain',
  'icr.country.BI': 'Burundi',
  'icr.country.BJ': 'Benin',
  'icr.country.BL': 'Saint Bartholomew',
  'icr.country.BM': 'Bermuda',
  'icr.country.BN': 'Brunei Darussalam',
  'icr.country.BO': 'Bolivia',
  'icr.country.BQ': 'Bonaire, Saint-Eustache et Saba',
  'icr.country.BR': 'Brazil',
  'icr.country.BS': 'Bahamas',
  'icr.country.BT': 'Bhutan',
  'icr.country.BV': 'Bouvet Island',
  'icr.country.BW': 'Botswana',
  'icr.country.BY': 'Belarus',
  'icr.country.BZ': 'Belize',
  'icr.country.CA': 'Canada',
  'icr.country.CC': 'Cocos (Keeling) Islands',
  'icr.country.CD': 'Congo Kinshasa',
  'icr.country.CF': 'République centrafricaine',
  'icr.country.CG': 'Congo',
  'icr.country.CH': 'Switzerland',
  'icr.country.CI': 'Cote d’Ivoire',
  'icr.country.CK': 'Cook Islands',
  'icr.country.CL': 'Chile',
  'icr.country.CM': 'Cameroon',
  'icr.country.CN': 'China',
  'icr.country.CO': 'Colombia',
  'icr.country.CR': 'Costa Rica',
  'icr.country.CU': 'Cuba',
  'icr.country.CV': 'Cape Verde',
  'icr.country.CW': 'Curaçao',
  'icr.country.CX': 'Christmas Island',
  'icr.country.CY': 'Cyprus',
  'icr.country.CZ': 'Czech Republic',
  'icr.country.DE': 'Germany',
  'icr.country.DJ': 'Djibouti',
  'icr.country.DK': 'Denmark',
  'icr.country.DM': 'Dominica',
  'icr.country.DO': 'Dominican Republic',
  'icr.country.DZ': 'Algeria',
  'icr.country.EC': 'Ecuador',
  'icr.country.EE': 'Estonia',
  'icr.country.EG': 'Egypt',
  'icr.country.EH': 'Western Sahara',
  'icr.country.ER': 'Eritrea',
  'icr.country.ES': 'Spain',
  'icr.country.ET': 'Ethiopia',
  'icr.country.FI': 'Finland',
  'icr.country.FJ': 'Fiji',
  'icr.country.FK': 'Falkland Islands',
  'icr.country.FM': 'Micronesia, Federated States of',
  'icr.country.FO': 'Faroe Islands',
  'icr.country.FR': 'France',
  'icr.country.GA': 'Gabon',
  'icr.country.GB': 'United Kingdom',
  'icr.country.GD': 'Grenada',
  'icr.country.GE': 'Georgia',
  'icr.country.GF': 'French Guiana',
  'icr.country.GG': 'Guernsey',
  'icr.country.GH': 'Ghana',
  'icr.country.GI': 'Gibraltar',
  'icr.country.GL': 'Greenland',
  'icr.country.GM': 'Gambia',
  'icr.country.GN': 'Guinea',
  'icr.country.GP': 'Guadeloupe',
  'icr.country.GQ': 'Equatorial Guinea',
  'icr.country.GR': 'Greece',
  'icr.country.GS': 'South Georgia and the South Sandwich Islands',
  'icr.country.GT': 'Guatemala',
  'icr.country.GU': 'Guam',
  'icr.country.GW': 'Guinea-Bissau',
  'icr.country.GY': 'Guyana',
  'icr.country.HK': 'Hong Kong SAR',
  'icr.country.HM': 'Heard and Mcdonald Islands',
  'icr.country.HN': 'Honduras',
  'icr.country.HR': 'Croatia',
  'icr.country.HT': 'Haiti',
  'icr.country.HU': 'Hungary',
  'icr.country.ID': 'Indonesia',
  'icr.country.IE': 'Ireland',
  'icr.country.IL': 'Israel',
  'icr.country.IM': 'Isle of Man',
  'icr.country.IN': 'India',
  'icr.country.IO': 'British Indian Ocean Territory',
  'icr.country.IQ': 'Iraq',
  'icr.country.IR': 'Iran',
  'icr.country.IS': 'Iceland',
  'icr.country.IT': 'Italy',
  'icr.country.JE': 'Jersey',
  'icr.country.JM': 'Jamaica',
  'icr.country.JO': 'Jordan',
  'icr.country.JP': 'Japan',
  'icr.country.KE': 'Kenya',
  'icr.country.KG': 'Kyrgyzstan',
  'icr.country.KH': 'Cambodia',
  'icr.country.KI': 'Kiribati',
  'icr.country.KM': 'Comoros',
  'icr.country.KN': 'Saint Kitts and Nevis',
  'icr.country.KP': 'Korea (North)',
  'icr.country.KR': 'Korea (South)',
  'icr.country.KW': 'Kuwait',
  'icr.country.KY': 'Cayman Islands',
  'icr.country.KZ': 'Kazakhstan',
  'icr.country.LA': "Lao People's Democratic Republic",
  'icr.country.LB': 'Lebanon',
  'icr.country.LC': 'Saint Lucia',
  'icr.country.LI': 'Liechtenstein',
  'icr.country.LK': 'Sri Lanka',
  'icr.country.LR': 'Liberia',
  'icr.country.LS': 'Lesotho',
  'icr.country.LT': 'Lithuania',
  'icr.country.LU': 'Luxembourg',
  'icr.country.LV': 'Latvia',
  'icr.country.LY': 'Libya',
  'icr.country.MA': 'Morocco',
  'icr.country.MC': 'Monaco',
  'icr.country.MD': 'Moldova',
  'icr.country.ME': 'Montenegro',
  'icr.country.MF': 'Saint-Martin (French part)',
  'icr.country.MG': 'Madagascar',
  'icr.country.MH': 'Marshall Islands',
  'icr.country.MK': 'Macedonia',
  'icr.country.ML': 'Mali',
  'icr.country.MM': 'Myanmar',
  'icr.country.MN': 'Mongolia',
  'icr.country.MO': 'Macau',
  'icr.country.MP': 'Northern Mariana Islands',
  'icr.country.MQ': 'Martinique',
  'icr.country.MR': 'Mauritania',
  'icr.country.MS': 'Montserrat',
  'icr.country.MT': 'Malta',
  'icr.country.MU': 'Mauritius',
  'icr.country.MV': 'Maldives',
  'icr.country.MW': 'Malawi',
  'icr.country.MX': 'Mexico',
  'icr.country.MY': 'Malaysia',
  'icr.country.MZ': 'Mozambique',
  'icr.country.NA': 'Namibia',
  'icr.country.NC': 'New Caledonia',
  'icr.country.NE': 'Niger',
  'icr.country.NF': 'Norfolk Island',
  'icr.country.NG': 'Nigeria',
  'icr.country.NI': 'Nicaragua',
  'icr.country.NL': 'The Netherlands',
  'icr.country.NO': 'Norway',
  'icr.country.NP': 'Nepal',
  'icr.country.NR': 'Nauru',
  'icr.country.NU': 'Niue',
  'icr.country.NZ': 'New Zealand',
  'icr.country.OM': 'Oman',
  'icr.country.PA': 'Panama',
  'icr.country.PE': 'Peru',
  'icr.country.PF': 'French Polynesia',
  'icr.country.PG': 'Papua New Guinea',
  'icr.country.PH': 'Philippines',
  'icr.country.PK': 'Pakistan',
  'icr.country.PL': 'Poland',
  'icr.country.PM': 'Saint Pierre and Miquelon',
  'icr.country.PN': 'Pitcairn',
  'icr.country.PR': 'Puerto Rico',
  'icr.country.PS': 'Palestine, State of',
  'icr.country.PT': 'Portugal',
  'icr.country.PW': 'Palau',
  'icr.country.PY': 'Paraguay',
  'icr.country.QA': 'Qatar',
  'icr.country.RE': 'Réunion',
  'icr.country.RO': 'Romania',
  'icr.country.RS': 'Serbia',
  'icr.country.RU': 'Russian Federation',
  'icr.country.RW': 'Rwanda',
  'icr.country.SA': 'Saudi Arabia',
  'icr.country.SB': 'Solomon Islands',
  'icr.country.SC': 'Seychelles',
  'icr.country.SD': 'Sudan',
  'icr.country.SE': 'Sweden',
  'icr.country.SG': 'Singapore',
  'icr.country.SH': 'Saint Helena',
  'icr.country.SI': 'Slovenia',
  'icr.country.SJ': 'Svalbard and Jan Mayen Islands',
  'icr.country.SK': 'Slovakia',
  'icr.country.SL': 'Sierra Leone',
  'icr.country.SM': 'San Marino',
  'icr.country.SN': 'Senegal',
  'icr.country.SO': 'Somalia',
  'icr.country.SR': 'Suriname',
  'icr.country.SS': 'South Sudan',
  'icr.country.ST': 'Sao Tome and Principe',
  'icr.country.SV': 'El Salvador',
  'icr.country.SY': 'Syria',
  'icr.country.SZ': 'Swaziland',
  'icr.country.TC': 'Turks and Caicos Islands',
  'icr.country.TD': 'Chad',
  'icr.country.TF': 'French Southern Territories',
  'icr.country.TG': 'Togo',
  'icr.country.TH': 'Thailand',
  'icr.country.TJ': 'Tajikistan',
  'icr.country.TK': 'Tokelau',
  'icr.country.TL': 'Timor-Leste',
  'icr.country.TM': 'Turkmenistan',
  'icr.country.TN': 'Tunisia',
  'icr.country.TO': 'Tonga',
  'icr.country.TR': 'Turkey',
  'icr.country.TT': 'Trinidad and Tobago',
  'icr.country.TV': 'Tuvalu',
  'icr.country.TW': 'Taiwan',
  'icr.country.TZ': 'Tanzania, United Republic ',
  'icr.country.UA': 'Ukraine',
  'icr.country.UG': 'Uganda',
  'icr.country.UM': 'United States Minor Outlying Islands',
  'icr.country.US': 'United States of America',
  'icr.country.UY': 'Uruguay',
  'icr.country.UZ': 'Uzbekistan',
  'icr.country.VA': 'Holy See (Vatican City State)',
  'icr.country.VC': 'Saint Vincent and the Grenadines',
  'icr.country.VE': 'Venezuela',
  'icr.country.VG': 'British Virgin Islands',
  'icr.country.VI': 'Virgin Islands (U.S.)',
  'icr.country.VN': 'Vietnam',
  'icr.country.VU': 'Vanuatu',
  'icr.country.WF': 'Wallis and Futuna Islands',
  'icr.country.WS': 'Samoa',
  'icr.country.YE': 'Yemen',
  'icr.country.YT': 'Mayotte',
  'icr.country.ZA': 'South Africa',
  'icr.country.ZM': 'Zambia',
  'icr.country.ZW': 'Zimbabwe',
  'icr.coutnry.GQ': 'Equatorial Guinea',
  'icr.error.search': 'Something went wrong',
  'icr.error.search.contacts':
    'Please try again later or contact our Customer Service team at <a href="mailto:{country, select, gb {customerservices@graydon.co.uk} be {support@graydon.be} other {service@graydon.nl}}">{country, select, gb {customerservices@graydon.co.uk} be {support@graydon.be} other {service@graydon.nl}}</a> or on {country, select, gb {+44&nbsp;(0)20&nbsp;8515&nbsp;1400} be {+32&nbsp;(03)&nbsp;280&nbsp;8880} other {+31&nbsp;(020)&nbsp;567&nbsp;9501}}.',
  'icr.error.search.text':
    'Unfortunately, the service is unavailable and the search can’t be performed.',
  'icr.investgated.report.additionalEmails.label': 'Send extra notification to',
  'icr.investgated.report.additionalEmails.placeholder': 'email@your-domain.com',
  'icr.investgated.report.additionalEmails.recipients.none':
    'A notification will be sent to the account email address only',
  'icr.investgated.report.additionalEmails.tooltip':
    'A notification will be sent to the account email address anyway as soon as the requested report is available. Provide additional email addresses to send extra notifications.',
  'icr.investgated.report.address.label': 'Address',
  'icr.investgated.report.address.placeholder':
    'Enter complete address including city, postcode, street number, street name, building, etc.',
  'icr.investgated.report.address.tooltip':
    'Enter complete address including, city, street number, street name, building, etc.',
  'icr.investgated.report.address.validation.message': 'Please enter a valid and detailed address',
  'icr.investgated.report.building.label': 'Building',
  'icr.investgated.report.building.placeholder': 'Enter building number etc.',
  'icr.investgated.report.building.tooltip': 'Enter building number etc.',
  'icr.investgated.report.building.validation.message': 'Please enter a valid building number',
  'icr.investgated.report.city.label': 'City',
  'icr.investgated.report.city.placeholder': 'Enter city name',
  'icr.investgated.report.city.validation.message': 'Please enter a valid city name',
  'icr.investgated.report.clientEmail.label': 'Email',
  'icr.investgated.report.clientEmail.placeholder': 'Enter email address',
  'icr.investgated.report.clientEmail.tooltip': 'Enter email address',
  'icr.investgated.report.clintIdentification.sap-id': 'SAP ID',
  'icr.investgated.report.clintIdentification.validate': 'Validate',
  'icr.investgated.report.comments.label': 'Comments',
  'icr.investgated.report.comments.maxLengthMessage': 'Remaining characters',
  'icr.investgated.report.comments.tooltip':
    'Please indicate here any additional information and/or details that could help our teams or any comments/questions about the company report you are requesting.',
  'icr.investgated.report.companyName.label': 'Company name',
  'icr.investgated.report.companyName.placeholder': 'Enter company name',
  'icr.investgated.report.companyName.tooltip':
    'Enter here the company name for which you would like a report.',
  'icr.investgated.report.companyNameLocal.label': 'Company name in local language',
  'icr.investgated.report.companyNameLocal.placeholder':
    'Enter the name in the local language (if different from above)',
  'icr.investgated.report.companyNameLocal.tooltip':
    'Enter the name of the company in the local language (if different from the name indicated above or in another alphabet).',
  'icr.investgated.report.companyRegNumber.label': 'Company registration number',
  'icr.investgated.report.companyRegNumber.placeholder': 'Enter the company registration number',
  'icr.investgated.report.companyRegNumber.tooltip':
    'Enter the registration number for the company.',
  'icr.investgated.report.companyRegNumber.validation.message':
    'Please enter a valid registration number',
  'icr.investgated.report.consumer-name.label': 'Customer’s company name',
  'icr.investgated.report.country.label': 'Country',
  'icr.investgated.report.country.validation.message': 'You must select a country',
  'icr.investgated.report.creditRecommendation.label': 'Credit recommendation',
  'icr.investgated.report.creditRecommendation.placeholder':
    'Enter the credit recommendation you are seeking to offer (if applicable)',
  'icr.investgated.report.currency.label': 'Currency',
  'icr.investgated.report.currency.tooltip':
    'Please select the currency for the requested credit recommendation from the dropdown menu',
  'icr.investgated.report.discloseEnquirerName.label':
    'Yes, I allow Graydon to disclose the name of the enquirer as part of this investigation',
  'icr.investgated.report.discloseEnquirerName.tooltip':
    'Please tick this box if you are happy for us to disclose your name as part of the investigation.',
  'icr.investgated.report.email.label': 'Contact email address',
  'icr.investgated.report.enquirerName.label': 'Enquirer Name',
  'icr.investgated.report.form.info.2':
    'Enter any other information which can help us identify the correct company.',
  'icr.investgated.report.form.section.header.0': 'Client identification',
  'icr.investgated.report.form.section.header.1': 'Required company information',
  'icr.investgated.report.form.section.header.2': 'Additional company information',
  'icr.investgated.report.form.section.header.3': 'Report information',
  'icr.investgated.report.includeEnquirerName.false': 'No',
  'icr.investgated.report.includeEnquirerName.label': 'Include Enquirer Name',
  'icr.investgated.report.includeEnquirerName.true': 'Yes',
  'icr.investgated.report.max-length.validation':
    'This field can contain a maximum of {max} characters',
  'icr.investgated.report.name.label': 'Contact full name',
  'icr.investgated.report.otherIdentifiers.addAnother': 'Add another identifying number',
  'icr.investgated.report.otherIdentifiers.tooltip':
    'If you have any additional unique identification numbers, please add them below. \nPlease note you need to describe in the box on the right what type of identification number you are adding.',
  'icr.investgated.report.otherIdentifiers.validation':
    'Please enter both a valid number and a description or delete the additional fields',
  'icr.investgated.report.otherIdentifyingDescription.label':
    'Description of identification number',
  'icr.investgated.report.otherIdentifyingDescription.label-short': 'Description',
  'icr.investgated.report.otherIdentifyingNumber.label': 'Additional unique identification number',
  'icr.investgated.report.otherIdentifyingNumber.label-short':
    'If available, please enter an additional Identification Number',
  'icr.investgated.report.otherInfoOrComments.label': 'Please add any other comments in this box',
  'icr.investgated.report.postalCode.label': 'Postcode',
  'icr.investgated.report.postalCode.placeholder': 'Enter Postal Code',
  'icr.investgated.report.postalCode.validation.message': 'Please enter a valid Postal Code',
  'icr.investgated.report.range.validation':
    'This field requires a minimum of {min} chararter and a maximum of {max} characters',
  'icr.investgated.report.reference.label': 'Reference',
  'icr.investgated.report.reference.placeholder':
    'Enter your reference for this report (eg. order number or customer number)',
  'icr.investgated.report.reference.tooltip':
    'Enter here your personal reference for this request  (customer name, file name, etc.).',
  'icr.investgated.report.reference.validation.message':
    'Please enter a valid reference number (max 50 characters)',
  'icr.investgated.report.sap-id.label': 'SAP ID',
  'icr.investgated.report.speedOfService.label': 'Speed of service',
  'icr.investgated.report.speedOfService.tooltip':
    'Select the speed of service from the options below.\nPlease note that the speed of service listed will vary according to the country you have selected. For details on the price of each delivery speed, please check your Graydon contract.',
  'icr.investgated.report.speedOfService.validation.message':
    'Something went wrong. Please try again.',
  'icr.investgated.report.speedOfService.validationMessage':
    'Please select the speed of service that you require.',
  'icr.investgated.report.street.label': 'Street, house number, suffix',
  'icr.investgated.report.street.placeholder': 'Enter street name',
  'icr.investgated.report.street.tooltip': 'Enter street name',
  'icr.investgated.report.street.validation.message': 'Please enter a valid street name',
  'icr.investgated.report.submitting.message-bottom': 'Sending request',
  'icr.investgated.report.submitting.message-top':
    'Please wait while we send your request to our investigation team',
  'icr.investgated.report.telephone.addNumber': 'Add another telephone number',
  'icr.investgated.report.telephone.label': 'Enter a telephone number',
  'icr.investgated.report.telephone.placeholder': 'Enter a telephone number',
  'icr.investgated.report.vatNumber.label': 'VAT number',
  'icr.investgated.report.vatNumber.placeholder': 'Enter the company VAT number',
  'icr.investgated.report.vatNumber.tooltip': 'Enter here the VAT number for this company.',
  'icr.investigated.report.button.next-step': 'Next step',
  'icr.investigated.report.button.send-request': 'Submit order',
  'icr.investigated.report.clientEmail.empty.message': 'Enter the email address here to verify it',
  'icr.investigated.report.clientEmail.validation.message': 'invalid or unknown email address',
  'icr.investigated.report.companyName.validation.message': 'Please enter a valid company name',
  'icr.investigated.report.form.deliveryTime': 'Delivery in {daysFrom} to {daysTo} working days',
  'icr.investigated.report.form.deliveryTime-short': 'From ({daysFrom} to {daysTo} working days)',
  'icr.investigated.report.form.info.1':
    'Please fill in the following details about the company you would like us to investigate. This information will help us to identify the company and retrieve the correct information for you.',
  'icr.investigated.report.form.previousStep': 'Previous step',
  'icr.investigated.report.page.title': 'Order Fresh Investigation',
  'icr.investigated.report.step.0.name': 'Client identification',
  'icr.investigated.report.step.0.title': 'Step 0',
  'icr.investigated.report.step.1.name': 'Company Information',
  'icr.investigated.report.step.1.title': 'Step 1',
  'icr.investigated.report.step.2.name': 'Order details',
  'icr.investigated.report.step.2.title': 'Step 2',
  'icr.investigated.report.step.3.name': 'Order investigation',
  'icr.investigated.report.step.3.title': 'Step 3',
  'icr.investigated.report.step.title': 'Step {step}',
  'icr.link.download-old-report': 'Download old report',
  'icr.order.investigated.report.priceList':
    'Check [here](https://graydon.showpad.com/share/azJC056WCJf2MneQTcCA7) the rates for our international reports',
  'icr.order.investigated.report.toast.body.error':
    'Unfortunately, an error has occurred and we cannot complete the order. Please try resending the request.',
  'icr.order.investigated.report.toast.body.success':
    'Your request has been sent. You can review your order status in your archive.',
  'icr.order.investigated.report.toast.title.error': 'Something went wrong',
  'icr.order.investigated.report.toast.title.success': 'Request sent!',
  'icr.report.database.immediate': 'Database Report - Immediate',
  'icr.report.database.immediate-with-update': 'Database Report - Immediate with update',
  'icr.report.investigated': 'Fresh Investigation',
  'icr.search-page.archive-btn': 'Archive',
  'icr.search-page.choose-country': 'Choose a country',
  'icr.search-page.choose-country.tooltip':
    'Select which country you would like to search. Via the dropdown menu, you will see if a report is immediately available in the database. If not available, you can request a Fresh Investigation  (usually delivered within 3-5 working days) via the button in the top right corner.',
  'icr.search-page.choose-state': 'Select a state',
  'icr.search-page.choose-state.tooltip': 'Select or type a state',
  'icr.search-page.client-session.description':
    'Currently you are performing actions in the name of “**{firstName} {lastName}**” and SAP ID “**{consumerId}**”',
  'icr.search-page.client-session.modal.description':
    'You are about to terminate the session of "**{firstName} {lastName}**" with SAP ID "**{consumerId}**". ',
  'icr.search-page.client-session.modal.title': 'Stop session',
  'icr.search-page.client-session.start': 'Start a client session',
  'icr.search-page.client-session.stop': 'Stop a client session',
  'icr.search-page.client-session.stop-session': 'Stop session',
  'icr.search-page.client-session.title': 'Client session',
  'icr.search-page.client-session.tooltip':
    "An admin user can perform actions on behalf of a customer, like requesting a fresh investigation, requesting a report or checking the archive. Requested reports will be available in the customer's archive.",
  'icr.search-page.client-validation.description':
    'Enter the email of the client for validating in order to perform action from his account.',
  'icr.search-page.client-validation.email-empty.message':
    'Enter the email address here to verify it',
  'icr.search-page.client-validation.email-validation.message': 'invalid or unknown email address',
  'icr.search-page.client-validation.email.label': 'Email',
  'icr.search-page.client-validation.email.text': 'Enter email address',
  'icr.search-page.client-validation.sap-id': 'SAP ID',
  'icr.search-page.client-validation.sapid-empty.message': 'Enter the SAP ID here to verify it',
  'icr.search-page.client-validation.sapid-validation.message': 'invalid or unknown SAP ID',
  'icr.search-page.client-validation.sapid.label': 'SAP ID',
  'icr.search-page.client-validation.sapid.text': 'Enter SAP ID',
  'icr.search-page.client-validation.start-session': 'Start session',
  'icr.search-page.client-validation.title': 'Client identification',
  'icr.search-page.client-validation.validate': 'Validate',
  'icr.search-page.description':
    'Countries have recommended search methods. For a better experience, we have pre-selected the preferred method for each country. However you can switch to whichever method depending on the information you already have and what is available.',
  'icr.search-page.domestic-country.link': 'Go to Company Data',
  'icr.search-page.domestic-country.message':
    '{country} is part of your domestic subscription. Go to the Company Data and search for the company.',
  'icr.search-page.fresh-investigation-btn': 'Fresh Investigation',
  'icr.search-page.fresh-investigation-button.no-paygo':
    'Fresh Investigations are not yet part of your package. Please contact your account manager for more details or to upgrade your subscription.',
  'icr.search-page.header': 'Search for a company',
  'icr.search-page.non-domestic-country.message.BE':
    'The Belgian reports are available via Company Data.',
  'icr.search-page.non-domestic-country.message.GB':
    'The UK reports are available via Company Data.',
  'icr.search-page.non-domestic-country.message.NL':
    'The Dutch reports are available via Company Data.',
  'icr.search-page.service-level-investigation.domestic.message':
    'This country is currently not available in our database.<br><br>\nIf you have questions or need help, please contact our Customer Service team at <a href="mailto:{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}">{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} other {support@graydon.be}}</a> or on {country, select, gb {+44 (0)20 8515 1400} nl {+31 (020) 567 9501} other {+32 (03) 280 88 80}}.',
  'icr.search-page.service-level-investigation.link': 'Order a Fresh Investigation',
  'icr.search-page.service-level-investigation.message':
    'This country is currently not in our database. However to request information about a specific company in this country, simply fill in the Fresh Investigation form.',
  'icr.search-page.service-level-investigation.no-paygo':
    'Reports for this country are only available as Fresh Investigations. Please contact your account manager for more details or to upgrade your subscription.',
  'icr.search-page.switch-to-client': 'Switch to client account',
  'icr.search-page.switch-to-personal': 'Switch to personal account',
  'icr.search-results.button.check-availability': 'Request report',
  'icr.search-results.button.view-report': 'View report',
  'icr.search-results.latest-report-date': 'Date of latest report',
  'icr.search-results.modal.add-client-note': 'Add a reference',
  'icr.search-results.modal.address': 'Address',
  'icr.search-results.modal.availability-error':
    'This report is not available online. Please contact your account manager to upgrade your package to be able to request a Fresh Investigation.',
  'icr.search-results.modal.availability-error-fresh':
    'This report is not available online. You can request a fresh investigation report, by clicking on ‘FRESH INVESTIGATION’.',
  'icr.search-results.modal.availability-immediate':
    "You can request an up-to-date online report by clicking on the button ‘REQUEST REPORT'.",
  'icr.search-results.modal.availability-immediate-fresh':
    'For many companies a report is available immediately. You can also request a Fresh Investigation and we will research and deliver a report for a company.',
  'icr.search-results.modal.availability-immediate-update':
    'The supplier has indicated this report is not up-to-date. You can still request it now, and we will send you an updated version when available.',
  'icr.search-results.modal.availability-immediate-update-fresh':
    'The supplier has indicated this report is not up-to-date. You can still request it now, and we will send you an updated version when available.\nHowever, depending on the urgency, you can request a fresh investigation report, by clicking on ‘FRESH INVESTIGATION’.',
  'icr.search-results.modal.availability.outdated-report-body':
    'The updated version of this report will be available soon. Track the progress in the Archive section.',
  'icr.search-results.modal.availability.outdated-report-title':
    'Your updated report is being generated',
  'icr.search-results.modal.clientNote.label': 'Add a report reference',
  'icr.search-results.modal.clientNote.placeholder': 'Add a reference to your report',
  'icr.search-results.modal.clientNote.tooltip':
    'Here, you can enter a note as a reference to your report. This reference will show in your archive.',
  'icr.search-results.modal.downloading.heading': 'Please wait while we prepare your download',
  'icr.search-results.modal.downloading.step0': 'send the request',
  'icr.search-results.modal.downloading.step1': 'gather the data',
  'icr.search-results.modal.downloading.step2': 'group the information',
  'icr.search-results.modal.downloading.step3': 'build the report',
  'icr.search-results.modal.downloading.step4': 'store the report',
  'icr.search-results.modal.fresh-investigation-button': 'Fresh Investigation',
  'icr.search-results.modal.identifier': 'Identifier',
  'icr.search-results.modal.not-available':
    'For this company a report is not available immediately. Please request a Fresh Investigation and we will research and deliver a report for the company.',
  'icr.search-results.modal.request-report-button': 'Request report',
  'icr.search-results.modal.tooltip':
    'For many companies a report is available immediately. You can also request a Fresh Investigation and we will research and deliver a report for a company.',
  'icr.search.advanced.city.placeholder': 'City',
  'icr.search.advanced.post-code.placeholder': 'Postcode',
  'icr.search.advanced.toggle-link': 'Advanced search',
  'icr.search.company.searchButton': 'Search company',
  'icr.search.search-bar.placeholder': 'Enter company information',
  'icr.search.search-method.all': 'Combined',
  'icr.search.search-method.identifier': 'Identifier',
  'icr.search.search-method.KEYWORD': 'Keyword',
  'icr.search.search-method.keyword': 'Keyword',
  'icr.search.search-method.NAME': 'Name',
  'icr.search.search-method.name': 'Name',
  'icr.search.search-method.NAME_AND_CITY': 'Combined',
  'icr.search.search-method.REGISTRATION_ID': 'Identifier',
  'icr.search.search-methods-label': 'Search method',
  'icr.state-select.placeholder': 'Select a state',
  importCompanies: 'Import companies',
  'importCompanies.steps.addToList': 'Step 4/4: Add companies to list(s)',
  'importCompanies.steps.downloadEnrichedFile': 'Step 4/4: Download enriched file',
  'importCompanies.steps.mapping': "Step 2/4: Map your columns to Graydon's fields",
  'importCompanies.steps.matchingInProgress': 'Step 3/4: Matching is in progress',
  'importCompanies.steps.upload': 'Step 1/4: Upload',
  'info.404.cannot-be-found':
    'The page you are looking for cannot be found. Please check if you typed the correct URL.',
  'info.404.does-not-exist':
    "This is not the page you're looking for. Or, it is, but it doesn't exist. Then again, here you are...",
  'info.404.go-back': 'Back',
  'info.404.go-home': 'Take me back home',
  'info.404.go-to-graydon-insights': 'Please go to the Graydon Insights homepage.',
  'info.404.oops': 'Oops',
  'info.404.page-not-found': 'Page not found',
  'info.associated.no-data': 'No data',
  'info.no-access.title': 'No Access',
  'info.no-data': 'Unfortunately there is no data available',
  'int-reports.country-search-in': 'Search In',
  'int-reports.country.al': 'Albania',
  'int-reports.country.at': 'Austria',
  'int-reports.country.ba': 'Bosnia and Herzegovina',
  'int-reports.country.be': 'Belgium',
  'int-reports.country.bg': 'Bulgaria',
  'int-reports.country.ch': 'Switzerland',
  'int-reports.country.cz': 'Czech Republic',
  'int-reports.country.de': 'Germany',
  'int-reports.country.ee': 'Estonia',
  'int-reports.country.es': 'Spain',
  'int-reports.country.fr': 'France',
  'int-reports.country.gb': 'United Kingdom',
  'int-reports.country.hr': 'Croatia',
  'int-reports.country.hu': 'Hungary',
  'int-reports.country.ie': 'Ireland',
  'int-reports.country.it': 'Italy',
  'int-reports.country.kv': 'Kosovo',
  'int-reports.country.lt': 'Lithuania',
  'int-reports.country.lu': 'Luxembourg',
  'int-reports.country.lv': 'Latvia',
  'int-reports.country.md': 'Moldavia',
  'int-reports.country.me': 'Montenegro',
  'int-reports.country.mk': 'Macedonia',
  'int-reports.country.nl': 'Netherlands',
  'int-reports.country.pl': 'Poland',
  'int-reports.country.pt': 'Portugal',
  'int-reports.country.ro': 'Romania',
  'int-reports.country.rs': 'Serbia',
  'int-reports.country.ru': 'Russia',
  'int-reports.country.se': 'Sweden',
  'int-reports.country.si': 'Slovenia',
  'int-reports.country.sk': 'Slovakia',
  'int-reports.country.ua': 'Ukraine',
  'int-reports.country.us': 'United States of America',
  'intl-report.autocomplete.no-results': 'No matches for "{keyword}"',
  'intl-report.category.otherFields': 'Other fields',
  'intl-report.category.recommendedFields': 'Recommended fields',
  'intl-report.category.requiredFields': 'Required fields',
  'intl-report.check.for.archived.download': 'Download this report',
  'intl-report.check.for.archived.no-reports': 'No archived reports found',
  'intl-report.company.active': 'Active',
  'intl-report.company.al.amountAdvisedCaption':
    'The amount advised is per 60 days all suppliers assuming that the debtor has 5 creditors. The credit ceiling in this country is €2,500,000.',
  'intl-report.company.amountAdvisedCaption': 'The maximum credit limit for this company',
  'intl-report.company.at.amountAdvisedCaption':
    'The amount advised is a monthly credit limit per supplier. The credit ceiling in this country is €1,000,000.',
  'intl-report.company.ba.amountAdvisedCaption':
    'The amount advised is per 60 days all suppliers assuming that the debtor has 5 creditors. The credit ceiling in this country is €2,500,000.',
  'intl-report.company.be.amountAdvisedCaption':
    'The amount advised is a maximum credit exposure over 12 months, all suppliers. There is no credit ceiling in this country.',
  'intl-report.company.bg.amountAdvisedCaption':
    'The amount advised is per 60 days all suppliers assuming that the debtor has 5 creditors. The credit ceiling in this country is €2,500,000.',
  'intl-report.company.ch.amountAdvisedCaption':
    'The amount advised is a maximum credit exposure per supplier at any one time. The credit ceiling in this country is CHF 1,000,000.',
  'intl-report.company.check': 'View report for this company',
  'intl-report.company.commonRating': 'Common Rating',
  'intl-report.company.commonRiskClassCaption': 'The risk of non-payment for this company',
  'intl-report.company.commonRiskClassDescription':
    "This is a European standard for determining the credit risk of a company and is expressed as a value on a scale of 1 to 5. The higher the value, the lower the risk. Value '9' gives a financial calamity. With a value of '8' it is not possible to determine a risk.",
  'intl-report.company.creditRatings': 'Credit Ratings',
  'intl-report.company.creditRatings.title': 'Credit rating',
  'intl-report.company.cz.amountAdvisedCaption':
    'The amount advised is a maximum credit exposure per supplier over 12 months. If no financials are available the credit ceiling in this country is CZK 39 mil. If  accounts are available, there is no ceiling.',
  'intl-report.company.de.amountAdvisedCaption':
    'The amount advised is a credit recommendation per transaction. There is no credit ceiling in this country.',
  'intl-report.company.ee.amountAdvisedCaption':
    'The amount advised is per 60 days all suppliers assuming that the debtor has 5 creditors. The credit ceiling in this country is €2,500,000.',
  'intl-report.company.error': 'Error fetching data, try again later',
  'intl-report.company.error.extended':
    'Unfortunately, this information is not available online.<br /><br />However, for assistance, please contact our Customer Service Team at <a href="mailto:{country, select, gb {customerservices@graydon.co.uk} be {support@graydon.be} other {service@graydon.nl}}">{country, select, gb {customerservices@graydon.co.uk} be {support@graydon.be} other {service@graydon.nl}}</a> or on {country, select, gb {+44 (020) 8515 1400} be {+32 (03) 280 88 80} other {+31 (020) 567 9501}}.',
  'intl-report.company.es.amountAdvisedCaption':
    'The amount advised is a maximum credit exposure at any one time. The credit ceiling in this country is €6,000,000.',
  'intl-report.company.fr.amountAdvisedCaption':
    'The amount advised is a credit recommendation per transaction. The credit ceiling in this country is €300,000.',
  'intl-report.company.gb.amountAdvisedCaption':
    'The amount advised is a monthly credit limit per supplier. The credit ceiling in this country is £1.125.000.',
  'intl-report.company.hr.amountAdvisedCaption':
    'The amount advised is per 60 days all suppliers assuming that the debtor has 5 creditors. The credit ceiling in this country is €2,500,000.',
  'intl-report.company.hu.amountAdvisedCaption':
    'The amount advised is per 60 days all suppliers assuming that the debtor has 5 creditors. The credit ceiling in this country is €2,500,000.',
  'intl-report.company.ie.amountAdvisedCaption':
    'The amount advised is a maximum credit exposure per supplier at any one time. The credit ceiling in this country is €7,500,000.',
  'intl-report.company.inDefault': 'Default',
  'intl-report.company.inDefaultOrInsolvent': 'In default or insolvent',
  'intl-report.company.insolvent': 'Insolvent',
  'intl-report.company.it.amountAdvisedCaption':
    'The amount advised is a credit recommendation per transaction. The credit ceiling in this country is €1,000,000.',
  'intl-report.company.localRating': 'Local Rating',
  'intl-report.company.localRatingCaption': 'The probability of default for this company',
  'intl-report.company.lt.amountAdvisedCaption':
    'The amount advised is per 60 days all suppliers assuming that the debtor has 5 creditors. The credit ceiling in this country is €2,500,000.',
  'intl-report.company.lu.amountAdvisedCaption':
    'The amount advised is a credit recommendation per transaction. There is no credit ceiling in this country.',
  'intl-report.company.lv.amountAdvisedCaption':
    'The amount advised is per 60 days all suppliers assuming that the debtor has 5 creditors. The credit ceiling in this country is €2,500,000.',
  'intl-report.company.maxCreditLimit': 'Maximum Credit Limit',
  'intl-report.company.maxCreditLimitDescription':
    'This is the maximum recommended amount which may be granted in accordance with Graydon short-term credit. This amount may vary by region of 500 euros to 6 million euros.',
  'intl-report.company.maxCreditLimitNotAvailable': 'Not available',
  'intl-report.company.md,amountAdvisedCaption':
    'The amount advised is per 60 days all suppliers assuming that the debtor has 5 creditors. The credit ceiling in this country is €2,500,000.',
  'intl-report.company.me.amountAdvisedCaption':
    'The amount advised is per 60 days all suppliers assuming that the debtor has 5 creditors. The credit ceiling in this country is €2,500,000.',
  'intl-report.company.mk.amountAdvisedCaption':
    'The amount advised is per 60 days all suppliers assuming that the debtor has 5 creditors. The credit ceiling in this country is €2,500,000.',
  'intl-report.company.nl.amountAdvisedCaption':
    'The amount advised is a maximum credit exposure over 12 months per supplier. The credit ceiling in this country is €5,000,000.',
  'intl-report.company.noRating': 'No rating available',
  'intl-report.company.paymentBehaviour': 'Payment behaviour',
  'intl-report.company.paymentBehaviourCaption':
    'Occurrences of Protests, Non-Payments and other qualifications of payment behaviour',
  'intl-report.company.paymentBehaviourDescription':
    'Payment behaviour information tells you how a company is paying right now. Occurrences of Protests, Non-Payments and other qualifications of payment behaviour provides an insight into the payment evolution, the cash position and the payment intentions.',
  'intl-report.company.paymentBehaviourUnknown': 'Not available',
  'intl-report.company.pl.amountAdvisedCaption':
    'The amount advised is a maximum credit exposure over 12 months, all suppliers. There is no credit ceiling in this country.',
  'intl-report.company.protestsAndNonPayments':
    '{protestsAndNonPayments} Protests and/or Non-Payments have been registered',
  'intl-report.company.providerRiskClassDescription':
    'This standard differs and is used for determining the credit risk of a company per country. The risk is expressed on different scales. Please contact Graydon if you like to know more about this rating.',
  'intl-report.company.pt.amountAdvisedCaption':
    'The amount advised is a maximum credit exposure at any one time. The credit ceiling in this country is €6,000,000.',
  'intl-report.company.rating.no-description': 'No Description',
  'intl-report.company.riskClasses': 'Risk Classes',
  'intl-report.company.ro.amountAdvisedCaption':
    'The amount advised is per 60 days all suppliers assuming that the debtor has 5 creditors. The credit ceiling in this country is €2,500,000.',
  'intl-report.company.rs.amountAdvisedCaption':
    'The amount advised is per 60 days all suppliers assuming that the debtor has 5 creditors. The credit ceiling in this country is €2,500,000.',
  'intl-report.company.ru.amountAdvisedCaption':
    'The amount advised is per 60 days all suppliers assuming that the debtor has 5 creditors. The credit ceiling in this country is €2,500,000.',
  'intl-report.company.se.amountAdvisedCaption':
    'The amount advised is a monthly credit limit all suppliers. The credit ceiling in this country is SEK 10 mil.',
  'intl-report.company.sectorCode': 'Sector code',
  'intl-report.company.si.amountAdvisedCaption':
    'The amount advised is per 60 days all suppliers assuming that the debtor has 5 creditors. The credit ceiling in this country is €2,500,000.',
  'intl-report.company.sk.amountAdvisedCaption':
    'The amount advised is per 60 days all suppliers assuming that the debtor has 5 creditors. The credit ceiling in this country is €2,500,000.',
  'intl-report.company.ua.amountAdvisedCaption':
    'The amount advised is per 60 days all suppliers assuming that the debtor has 5 creditors. The credit ceiling in this country is €2,500,000.',
  'intl-report.company.us.amountAdvisedCaption':
    'The amount advised is a maximum credit exposure per supplier at any one time. The credit ceiling in this country is $1,000,000.',
  'intl-report.date': 'Date',
  'intl-report.download.pdf.full-report': 'Download full report',
  'intl-report.download.pdf.summary': 'Download summary',
  'intl-report.financialSummary.profitLoss': 'Profit Loss',
  'intl-report.financialSummary.revenue': 'Revenue',
  'intl-report.financialSummary.summaryDate': 'Financials from {data}',
  'intl-report.financialSummary.totalEquity': 'Total equity',
  'intl-report.financialSummary.workingCapital': 'Working Capital',
  'intl-report.managementPositions.authorization': 'Authorisation',
  'intl-report.managementPositions.birthDate': 'Date of birth',
  'intl-report.managementPositions.companyName': 'Company name',
  'intl-report.managementPositions.firstName': 'First name',
  'intl-report.managementPositions.fullName': 'Full name',
  'intl-report.managementPositions.initials': 'Initials',
  'intl-report.managementPositions.lastName': 'Last name',
  'intl-report.managementPositions.management': 'Management',
  'intl-report.managementPositions.middleName': 'Middle name',
  'intl-report.managementPositions.prefix': 'Prefix',
  'intl-report.managementPositions.startingDate': 'Starting date',
  'intl-report.managementPositions.title': 'Title',
  'intl-report.pdf.balance': 'Balance',
  'intl-report.pdf.employee-data': 'Employee data',
  'intl-report.pdf.liabilityDeclarations': 'Liability declarations',
  'intl-report.pdf.unknown.value': 'Unknown',
  'intl-report.protestsAndNonPayments.amount': 'Amount',
  'intl-report.protestsAndNonPayments.creditor': 'Creditor',
  'intl-report.protestsAndNonPayments.date': 'Date',
  'intl-report.protestsAndNonPayments.description': 'Description',
  'intl-report.protestsAndNonPayments.noEventsRegisterdForThisType':
    'No events registered for this type',
  'intl-report.protestsAndNonPayments.noEventsRegistered':
    'No protests and/or non-payments have been registered',
  'intl-report.protestsAndNonPayments.title': 'Payments: Protests & Non-payments',
  'intl-report.protestsAndNonPayments.type': 'Type',
  'intl-report.search-hint.adress': 'Use only streetname',
  'intl-report.search-hint.builder': 'Numbers only',
  'intl-report.search-hint.city': 'Use full name',
  'intl-report.search-hint.companyId': 'The unique number within the Graydon database',
  'intl-report.search-hint.companyRegisterNumber':
    'In most cases this would be the number registered at the Chamber of Commerce',
  'intl-report.search-hint.name': 'Use full name',
  'intl-report.search-hint.postCode': 'Use the full post code',
  'intl-report.search-hint.registeredName': 'Use full name',
  'intl-report.search-hint.state': 'Use the full state name',
  'intl-report.search-hint.street': 'Use only streetname',
  'intl-report.search-hint.tradingName': 'The name a company is trading under',
  'intl-report.search-hint.vat': 'Leave out FR and first 2 digits',
  'intl-report.search-hint.vatNumber': 'Leave out FR and first 2 digits',
  'intl-report.search-key.adress': 'Address',
  'intl-report.search-key.builder': 'Builder',
  'intl-report.search-key.city': 'City',
  'intl-report.search-key.companyId': 'Company ID',
  'intl-report.search-key.companyRegisterNumber': 'Company Register Number',
  'intl-report.search-key.postCode': 'Post code',
  'intl-report.search-key.registeredName': 'Name',
  'intl-report.search-key.state': 'State',
  'intl-report.search-key.street': 'Street',
  'intl-report.search-key.tradingName': 'Trading Name',
  'intl-report.search-key.vat': 'VAT',
  'intl-report.search-key.vatNumber': 'VAT',
  'intl-report.search.hide-other-fields': 'Hide other fields',
  'intl-report.search.number.results':
    '{num, plural, =1 {1 item} =50 {50+ items} other {{num} items}} found',
  'intl-report.search.recommended': 'Search on one or more recommended tags',
  'intl-report.search.show-other-fields': 'Show other fields',
  'intl-report.search.state.ak': 'Alaska',
  'intl-report.search.state.al': 'Alabama',
  'intl-report.search.state.ar': 'Arkansas',
  'intl-report.search.state.az': 'Arizona',
  'intl-report.search.state.ca': 'California',
  'intl-report.search.state.co': 'Colorado',
  'intl-report.search.state.ct': 'Connecticut',
  'intl-report.search.state.de': 'Delaware',
  'intl-report.search.state.fl': 'Florida',
  'intl-report.search.state.ga': 'Georgia',
  'intl-report.search.state.hi': 'Hawaii',
  'intl-report.search.state.ia': 'Iowa',
  'intl-report.search.state.id': 'Idaho',
  'intl-report.search.state.il': 'Illinois',
  'intl-report.search.state.in': 'Indiana',
  'intl-report.search.state.ks': 'Kansas',
  'intl-report.search.state.ky': 'Kentucky',
  'intl-report.search.state.la': 'Louisiana',
  'intl-report.search.state.ma': 'Massachusetts',
  'intl-report.search.state.md': 'Maryland',
  'intl-report.search.state.me': 'Maine',
  'intl-report.search.state.mi': 'Michigan',
  'intl-report.search.state.mn': 'Minnesota',
  'intl-report.search.state.mo': 'Missouri',
  'intl-report.search.state.ms': 'Mississippi',
  'intl-report.search.state.mt': 'Montana',
  'intl-report.search.state.nc': 'North Carolina',
  'intl-report.search.state.nd': 'North Dakota',
  'intl-report.search.state.ne': 'Nebraska',
  'intl-report.search.state.nh': 'New Hampshire',
  'intl-report.search.state.nj': 'New Jersey',
  'intl-report.search.state.nm': 'New Mexico',
  'intl-report.search.state.nv': 'Nevada',
  'intl-report.search.state.ny': 'New York',
  'intl-report.search.state.oh': 'Ohio',
  'intl-report.search.state.ok': 'Oklahoma',
  'intl-report.search.state.or': 'Oregon',
  'intl-report.search.state.pa': 'Pennsylvania',
  'intl-report.search.state.ri': 'Rhode Island',
  'intl-report.search.state.sc': 'South Carolina',
  'intl-report.search.state.sd': 'South Dakota',
  'intl-report.search.state.tn': 'Tennessee',
  'intl-report.search.state.tx': 'Texas',
  'intl-report.search.state.ut': 'Utah',
  'intl-report.search.state.va': 'Virginia',
  'intl-report.search.state.vt': 'Vermont',
  'intl-report.search.state.wa': 'Washington',
  'intl-report.search.state.wi': 'Wisconsin',
  'intl-report.search.state.wv': 'West Virginia',
  'intl-report.search.state.wy': 'Wyoming',
  'intl-report.search.title': 'Search',
  'intl-reports.consumption.downloads':
    '{downloads, plural, =0 {# reports} one {# report} other {# reports}}',
  'intl-reports.consumption.heatmap': 'Reports per country',
  'intl-reports.consumption.heatmap.month': 'for the current month',
  'intl-reports.pdf.from': 'from',
  'intl-reports.pdf.liabilityDeclaration.providedBy': 'Provided by',
  'intl-reports.pdf.liabilityDeclaration.providedFor': 'Provided for',
  'intl-reports.pdf.number-of-employees': 'Number of employees',
  'intl-reports.pdf.untill': 'until',
  'la.agedDebt': 'Aged debt distribution',
  'la.agedDebt.DAYS_0_30': '0-30 days',
  'la.agedDebt.DAYS_31_60': '31-60 days',
  'la.agedDebt.DAYS_61_90': '61-90 days',
  'la.agedDebt.DAYS_90_PLUS': '90+ days',
  'la.agedDebt.WITHIN_TERMS': 'Within terms',
  'la.agedDebtDistribution': 'Aged debt distribution',
  'la.agedDebtDistribution.hint':
    'Here the outstanding amount appears per aged debt bucket. Click on a segment in the chart or in the legend to filter debtors.',
  'la.amountMatched': 'Showing {percentage} of all debtors with amount outstanding',
  'la.augurScore': 'Augur score',
  'la.backHome': 'Back to Ledger Management',
  'la.balanceOutstanding': 'Balance outstanding',
  'la.companiesInLedger': 'Company overview',
  'la.companiesInLedger.agedDebt': 'Aged debt distribution',
  'la.companiesInLedger.agedDebt.hint':
    'This overview shows your customers with their outstanding debts, divided across several periods of time. Use the arrows under each period to rank your customers or choose one or more segments in the risk matrix above to fine-tune your selection.',
  'la.companiesInLedger.difference': 'Difference',
  'la.companiesInLedger.download': 'Download',
  'la.companiesInLedger.graydonData': 'Graydon data',
  'la.companiesInLedger.hint':
    '<b>Graydon Rating</b><br/>\nThe probability that a company may default on their payments within the next 12 months.<br/>\n<br/>\n<b>Probability of discontinuation</b><br/>\nThe probability that a company discontinues their business activities within the next 12 months.<br/>\n<br/>\n<b>Expected growth</b><br/>\nThis analysis shows how much a company can expect to grow in the next 12 months.',
  'la.companiesInLedger.id': 'Graydon ID',
  'la.companiesInLedger.name': 'Name',
  'la.companySearch.hint':
    'In case of unexpected results, keyword might match with debtor details. Open company details to see debtor details.',
  'la.companySizeFilter.from': 'From',
  'la.companySizeFilter.heading': 'Company size',
  'la.companySizeFilter.includeUnknown': 'Include companies of unknown size',
  'la.companySizeFilter.to': 'To',
  'la.companyTable.limitNote':
    'Note: Your (filtered) ledger contains {totalAmount} customers. We only show the first {shownAmount}.',
  'la.creditFlag': 'Credit flag',
  'la.creditFlag.G': 'Green: No objection to credit facility',
  'la.creditFlag.NA': 'Information not available',
  'la.creditFlag.O': 'Orange: Credit facility maybe given but should be monitored',
  'la.creditFlag.R': 'Red: Credit facility is not advised',
  'la.creditLimit': 'Credit limit',
  'la.debtor.customerID': 'Debtor reference',
  'la.debtor.customerID.multiple': 'Multiple',
  'la.debtor.customerName': 'Debtor name',
  'la.debtor.freeFields': 'Additional debtor data',
  'la.discontinuation': 'Probablity of discontinuation',
  'la.discontinuationScore.1': 'Very high',
  'la.discontinuationScore.2': 'High',
  'la.discontinuationScore.3': 'High',
  'la.discontinuationScore.4': 'Neutral',
  'la.discontinuationScore.5': 'Neutral',
  'la.discontinuationScore.6': 'Neutral',
  'la.discontinuationScore.7': 'Low',
  'la.discontinuationScore.8': 'Low',
  'la.discontinuationScore.9': 'Very low',
  'la.discontinuationScore.NR': 'Not rated',
  'la.filterBar.showCompanies': 'Show companies',
  'la.filteredSelection': 'Filtered selection',
  'la.filteredSelection.removeAll': 'Clear all filters',
  'la.filters': 'Filters',
  'la.freeField': 'Free field {n}',
  'la.freeField.placeholder': 'Choose filter {n}',
  'la.graphHasNegativeValues': 'Due to negative amounts this graph can not be shown',
  'la.graydonRating': 'Graydon Rating',
  'la.graydonRating.HIGH': 'CCC/CC/C',
  'la.graydonRating.hint':
    'Here the Graydon Rating appears. Click on a segment in the chart or in the legend to filter debtors on rating',
  'la.graydonRating.INSOLVENT': 'D - Bankrupt',
  'la.graydonRating.LOW': 'AAA/AA/A',
  'la.graydonRating.MEDIUM': 'BBB/BB/B',
  'la.graydonRating.NR': 'Not rated',
  'la.growthScore': 'Expected growth',
  'la.growthScore.1': 'Rapid decline',
  'la.growthScore.2': 'Decline',
  'la.growthScore.3': 'Neutral',
  'la.growthScore.4': 'Growth',
  'la.growthScore.5': 'Strong growth',
  'la.growthScore.99': 'Not predictable',
  'la.growthScore.NR': 'Not rated',
  'la.hideFilters': 'Hide filters',
  'la.multiscore': 'Multiscore',
  'la.myCustomers': 'My customers',
  'la.nrInvoices': 'Number of invoices',
  'la.paymentScore': 'Payment score',
  'la.paymentScore.1': 'Payment terms are not being met',
  'la.paymentScore.2': 'Below average',
  'la.paymentScore.3': 'Average',
  'la.paymentScore.4': 'Good payment behaviour',
  'la.paymentScore.null': 'Unknown',
  'la.pdRating.A': 'Chance of default is low',
  'la.pdRating.AA': 'Chance of default is low',
  'la.pdRating.AAA': 'Chance of default is very low',
  'la.pdRating.B': 'Chance of default is neutral',
  'la.pdRating.BB': 'Chance of default is neutral',
  'la.pdRating.BBB': 'Chance of default is neutral',
  'la.pdRating.C': 'Chance of default is very high',
  'la.pdRating.CC': 'Chance of default is higher than average',
  'la.pdRating.CCC': 'Chance of default is higher than average',
  'la.pdRating.D': 'The company is default',
  'la.pdRating.NR': 'The company is not rated',
  'la.preferences': 'Preferences',
  'la.preferences.cancel': 'Cancel',
  'la.preferences.companyTable.description': 'Change visibility of (some) company table columns.',
  'la.preferences.save': 'Save preferences',
  'la.riskDistribution': 'Risk distribution',
  'la.riskDistribution.high': 'High',
  'la.riskDistribution.hint':
    'Risk distribution is a combination of Graydon Rating and the number of days beyond terms.<br><br>Hint: Click on a segment in the chart or in the legend to filter debtors on risk level.',
  'la.riskDistribution.low': 'Low',
  'la.riskDistribution.medium': 'Medium',
  'la.riskDistribution.nr': 'Not rated',
  'la.riskMatrix': 'Risk matrix',
  'la.riskMatrix.hint':
    'The risk matrix shows outstanding amounts and number of companies with outstanding amounts. The amounts are broken down in aged debt buckets and Graydon Rating of the company.<br/><br/>The risk level is a combination of aged debt term and Graydon Rating. This level is indicated by a colour (green=low, orange=medium, red=high). Click the Settings icon above the matrix to edit these levels.',
  'la.riskMatrixPreferences.explanation':
    'Click the matrix cells to define the risk level for each combination of Graydon Rating and payment',
  'la.riskMatrixPreferences.heading': 'Define risk levels',
  'la.sector': 'Sector',
  'la.sector.placeholder': 'Choose a sector',
  'la.showFilters': 'Show filters',
  'la.showUnmatched': 'Show unmatched debtors',
  'la.totals.averageDaysOverdue': 'average days overdue',
  'la.totals.lastUpdated': 'Last imported',
  'la.totals.matchCount': 'debtors with amount outstanding',
  'la.totals.totalOpen': 'total outstanding',
  'la.totals.totalOverdue': 'overdue',
  'la.totals.totalWithinTerms': 'within terms',
  'la.unmatch.button': 'Unmatch debtor(s) from customer',
  'la.unmatch.heading': 'Unmatch debtor(s) from customer',
  'la.unmatch.text':
    'If you do not recognize <b>‘{organizationName}’</b> as a customer then we might have matched the debtor(s) incorrectly to this customer. Please unmatch all debtors from <b>‘{organizationName}’</b>',
  'la.unmatchDone.heading': 'Debtor(s) unmatched from customer',
  'la.unmatchDone.text':
    'In order to match debtors properly please<br/>\n• Check and update the debtor data (name, id, address, etc.) in your ledger system.<br> \n• Preferably, add Company Registration number (KvK, CRO, Ondernemingsnummer) to your debtor data in your ledger system.<br> \n• Wait until after the next import (usually next day).',
  'la.unmatchedDebtors': 'Unmatched debtors',
  'la.unmatchedDebtors.limitNote':
    '<b>Note:</b> We only show the <b>top 200 unmatched debtors</b> with the biggest amount outstanding.',
  'la.unmatchedDebtors.text':
    '{unmatchedCount} debtors in your imported ledger could not be matched with Graydon data. In order to match debtors properly, please:<br/>\n<br/>\n• Check and update the debtor data (name, id, address, etc.) in your ledger system<br/> \n• Preferably, add Company Registration number (KvK, CRO, Ondernemingsnummer) to your debtor data in your ledger system.<br/> \n• Wait until after the next import (usually next day).',
  'la.unmatchLink': 'I do not recognize this customer',
  'la.updatePreferencesPopup.error': 'Preferences could not be saved',
  'la.updatePreferencesPopup.success': 'Preferences have been saved',
  lists: 'Lists',
  'lm.company.has-dbm': 'Database management',
  'lm.company.monitored': 'Monitored',
  'lm.deletePopup.cancel': 'No, cancel',
  'lm.deletePopup.confirm': 'Yes, delete',
  'lm.listCompanies.actions': 'Actions',
  'lm.listCompanies.back': 'Back to list overview',
  'lm.listCompanies.consumerReferenceId': 'Your reference',
  'lm.listCompanies.country': 'Country',
  'lm.listCompanies.dateAdded': 'Date added',
  'lm.listCompanies.name': 'Name',
  'lm.listCompanies.nrCompaniesSelected': '{amount} companies selected',
  'lm.listCompanies.organizationId': 'Graydon ID',
  'lm.listCompanies.registrationNumber': 'Company registration number ',
  'lm.listCompanies.removeCompanies': 'Remove companies',
  'lm.listCompanies.removeCompanies.message':
    'Are you sure you want to remove {amount} companies from {listName}?',
  'lm.listCompanies.removeCompany': 'Remove company',
  'lm.listCompanies.removeCompany.message':
    'Are you sure you want to remove "{companyName}" from {listName}?',
  'lm.listCompanies.removeSuccess': 'Successfully removed',
  'lm.listCompanies.search.placeholder': 'Search for a company in the list',
  'lm.listOverview.createList': 'Create new list',
  'lm.listOverview.createPopup.heading': 'Create a new list',
  'lm.listOverview.createPopup.label': 'List name',
  'lm.listOverview.createPopup.submit': 'Save',
  'lm.listOverview.deleteList': 'Delete list',
  'lm.listOverview.deletePopup.message': 'Are you sure you want to delete "{name}"?',
  'lm.listOverview.import.button': 'Import companies',
  'lm.listOverview.noCompaniesMessage':
    'There are no companies in this list yet. Please import companies to use this list or search a company and add this via Credit Reports.',
  'lm.listOverview.noListsMessage': 'No lists to show, please import companies first.',
  'lm.listOverview.renameList': 'Rename list',
  'lm.listOverview.renamePopup.label': 'List name',
  'lm.listOverview.renamePopup.submit': 'Save',
  'lm.listOverview.clearReferences': 'Clear all references',
  'lm.listOverview.clearReferences.confirm':
    'Are you sure you want to clear all references for "{Listname}"?',
  'lm.listOverview.clearReferences.yes': 'Yes, clear',
  'lm.listOverview.clearReferences.no': 'No, cancel',
  'lm.listOverview.clearReferences.success': 'References were successfully cleared',
  'lm.listOverview.title': 'List overview',
  'lm.numCompanies': '{num} {num, plural, =1 {company} other {companies}}',
  'lm.showMoreLists': 'Show more lists',
  'lm.tags.createError.nameAlreadyExists': 'List name already exists',
  'lm.tags.createSuccess': 'List has been created',
  'lm.tags.deleteSuccess': 'Successfully deleted',
  'lm.tags.renameSuccess': 'Successfully renamed',
  'lm.upload-csv.delimiter': 'Delimiter',
  'loading.content': 'Loading content',
  'matching.organizationId': 'Graydon number',
  'matching.addressCity': 'City',
  'matching.addressHouseNumber': 'House number',
  'matching.addressHouseNumberSuffix': 'House number suffix',
  'matching.addressLine1': 'Address line 1',
  'matching.addressLine2': 'Address line 2',
  'matching.addressLine3': 'Address line 3',
  'matching.addressLine4': 'Address line 4',
  'matching.addressLine5': 'Address line 5',
  'matching.addressPostalCode': 'Postcode',
  'matching.addressStreet': 'Street',
  'matching.addressStreetHouseNumberSuffix': 'House number + suffix',
  'matching.addressStreetHouseNumberSuffixPostcalCodeCity': 'Full address',
  'matching.async.backButton': 'Go to homepage',
  'matching.async.disclaimer':
    'Depending on your file size, this could take up to one hour. In the meantime, you can leave this page and do something else.',
  'matching.async.matchingDone': "Once matching is done, we'll send the matching results to:",
  'matching.async.nextSteps': 'Next steps',
  'matching.async.nextSteps.step1': 'Once matching is done, we’ll send the matching results to',
  'matching.async.nextSteps.step1.note':
    'Note: This email might end up in your spambox, so please check this box as well.',
  'matching.async.nextSteps.step2': 'Click the link in the email',
  'matching.async.nextSteps.step3': 'Add matched companies to one or multiple lists',
  'matching.async.title': 'Matching in progress...',
  'matching.be': 'Belgium',
  'matching.beBusinessUnitNumber': 'Business unit number',
  'matching.beEnterpriseNumber': 'Enterprise number',
  'matching.connectingWithGraydonDatabase': "Map your file columns to Graydon's data",
  'matching.croRegistrationNumberOrGbYCompanyId': 'Company registration number ',
  'matching.example': 'Example from your file',
  'matching.gb': 'The United Kingdom',
  'matching.graydonFieldsFor': 'Graydon data',
  'matching.graydonOrganisationId': 'Graydon number',
  'matching.introText.be':
    "Define which data columns in your file should be mapped to Graydon's data. Match rate can be improved if (at least) business unit number or company name and full address is mapped. The more items you can map, the better.",
  'matching.introText.gb':
    "Define which data columns in your file should be mapped to Graydon's data. Match rate can be improved if (at least) company registration number or company name and full address is mapped. The more items you can map, the better.",
  'matching.introText.nl':
    "Define which data columns in your file should be mapped to Graydon's data. Match rate can be improved if (at least) Chamber of Commerce number or company name and full address is mapped. The more items you can map, the better.",
  'matching.kvkBranchNumber': 'Chamber of Commerce branch number',
  'matching.kvkNumber': 'Chamber of Commerce number',
  'matching.labelFromYourList': 'Your file columns',
  'matching.nl': 'The Netherlands',
  'matching.nlYCompanyId': 'Old Graydon number (NL)',
  'matching.orgName': 'Company name',
  'matching.reference': 'Reference (customer ID)',
  'matching.results.accessViaPortfolio': 'You can access the companies via your Portfolio.',
  'matching.results.addCompaniesToList': 'Add your companies to a list',
  'matching.results.download': 'Download',
  'matching.results.download.point1': 'matched companies',
  'matching.results.download.point2': 'companies labeled ‘Unmatched’',
  'matching.results.download.text': 'Download the match results including:',
  'matching.results.downloadButton': 'Download match results',
  'matching.results.heading': 'Match result',
  'matching.results.listItemOne': "Rows labeled 'Not matched'",
  'matching.results.listItemThree': 'Update wrong data',
  'matching.results.listItemTwo': 'Add missing data',
  'matching.results.matchResults': 'Match result',
  'matching.results.more': 'more',
  'matching.results.noMatchesMessage': 'Please update your file or go back and change the mapping',
  'matching.results.pleaseChooseGroups': 'Add companies to list(s)',
  'matching.results.pleaseChooseGroups.text': 'Choose one or multiple list(s) or',
  'matching.results.pleaseChooseGroups.text.link': 'create a new list',
  'matching.results.successfullyMatched': 'companies were successfully matched',
  'matching.results.update': 'Update',
  'matching.results.update.point1': 'Add missing data',
  'matching.results.update.point2': 'Update wrong data',
  'matching.results.update.text':
    'Please update the file and re-import it to match more companies:',
  'matching.results.updateYourFile': 'See unmatched companies and match more',
  'matching.results.uploadAgain': 'Upload again',
  'matching.results.uploadAgainButton': 'Upload updated list',
  'matching.results.wereNotMatched': 'companies were not matched',
  'matching.review.addMissingData': 'Please add missing data yourself',
  'matching.review.companies': 'companies were matched and added to your portfolio.',
  'matching.review.companyMoreData': 'companies need more data.',
  'matching.review.continue': 'Continue without non-matches',
  'matching.review.hideTips': 'hide tips',
  'matching.review.reviewImport': 'Review import',
  'matching.review.showTips': 'Show tips',
  'matching.review.submitButton': 'Download non-matches',
  'matching.review.theWordHow': 'How?',
  'matching.review.theWordOf': 'of',
  'matching.review.tip.downloadNonMatched': 'Download non-matched companies.',
  'matching.review.tip.review':
    'Review the file. Add as much missing data as you can. What we need are:',
  'matching.review.tip.uploadAndMatch': 'Upload the updated file and match again.',
  'matching.review.tip1': 'tip',
  'matching.review.tip2': 'tip',
  'matching.review.tip3': 'tip',
  'matching.selectColumn': 'No label selected',
  'matching.overwriteReference':
    'Overwrite existing company references with references from this import',
  'matching.overwriteReference.warning':
    'Overwriting references cannot be undone. It will also affects other users.',
  'matching.submitButton': 'Match data',
  'matching.vatNumber': 'VAT number',
  'matching.yMsKey': 'MSkey',
  'matchng.results.continueButton': 'Save and continue',
  'mdb.cm.download-invoice-data': 'Credit Management invoice data (.xlsx)',
  'mdb.cm.download-usage-data': 'Credit Management usage data (.xlsx)',
  'mdb.creditManagement.amountRequested.advanced': 'Organisations checked',
  'mdb.creditManagement.amountRequested.essential': 'Credit reports requested',
  'mdb.creditManagement.amountRequested.premium': 'Organisations checked',
  'mdb.creditManagement.country.be': 'Belgium',
  'mdb.creditManagement.country.nl': 'The Netherlands',
  'mdb.creditManagement.country.uk': 'United Kingdom',
  'mdb.creditManagement.country.gb': 'United Kingdom',
  'mdb.creditManagement.description.advanced':
    'The number of companies that have been checked vs. the total number of companies included in your package for the duration of your contract: **{dateRange}**. This includes the Monitoring Tool, Credit Reports and the Ledger Management.',
  'mdb.creditManagement.description.essential':
    'The number of credit reports requested vs. the total amount of credit reports available in your package for the duration of your contract: **{dateRange}**.',
  'mdb.creditManagement.description.premium':
    'The number of companies that have been checked vs. the total number of companies included in your package for the duration of your contract: **{dateRange}**. This includes the Monitoring Tool, Credit Reports and the Ledger Management.',
  'mdb.creditManagement.lastUpdate': 'Last update:',
  'mdb.creditManagement.tab': 'Credit Management',
  'mdb.creditManagement.tileHeading.advanced': 'Number of organisations checked',
  'mdb.creditManagement.tileHeading.essential': 'Number of credit reports requested',
  'mdb.creditManagement.tileHeading.premium': 'Number of organisations checked',
  'mdb.creditManagement.tooltip': 'The Credit Management counters will be updated once each day.',
  'mdb.download-data-enrichment': 'Marketing Information Enrichments (.xlsx)',
  'mdb.download-market-data': 'Market Data usage data (.xlsx)',
  'mdb.icr.download-old-usage-data':
    'Old International Business usage data (before 9-11-2021) (.xlsx)',
  'mdb.icr.download-usage-data': 'International Business usage data (.xlsx)',
  'mdb.icr.tab': 'International Credit Reports',
  'mdb.market-data.tab': 'Market Data',
  'mdb.marketData.amountRequested': 'Organisations downloaded or enriched',
  'mdb.marketData.description':
    'The number of organisations that have been downloaded via Market Data or enriched via Data Enrichment vs. the total number of organisations included in your package for duration of your contract: **{dateRange}**.',
  'mdb.marketData.tileHeading': 'Number of organisations downloaded or enriched',
  'mdb.marketData.tooltip':
    'The Market Data counters will be updated once each day. The counter indicates how many records you can still download.',
  'mdb.no.reference': 'No reference',
  'mdb.usage.extractCached.amountRequested': 'Cached trade register extracts requested',
  'mdb.usage.extractCached.description':
    'The number of cached trade register extracts that have been requested vs. the total number of company extracts included in your package for the duration of your contract: **{dateRange}**.',
  'mdb.usage.extractFresh.amountRequested': 'Fresh trade register extracts requested',
  'mdb.usage.extractFresh.description':
    'The number of fresh trade register extracts that have been requested vs. the total number of extracts included in your package for the duration of your contract: **{dateRange}**.',
  'mdn.usage.companyExtract.tooltip':
    'The trade register extract counters will be updated once each day.',
  'mdn.usage.extractCached.heading':
    'Number of cached trade register extracts {country, select, gb {United Kingdom} be {Belgium} other {The Netherlands}}',
  'mdn.usage.extractFresh.heading':
    'Number of fresh trade register extracts {country, select, gb {United Kingdom} be {Belgium} other {The Netherlands}}',
  'menu.hide': 'Hide navigation',
  'menu.show': 'Show navigation',
  'mi.activities.confirm': 'Confirm activities',
  'mi.activities.group-code.1': 'VAT',
  'mi.activities.group-code.BE_1': 'CBE-EDRL',
  'mi.activities.group-code.BE_1_2_5': 'CBE',
  'mi.activities.group-code.BE_2': 'CBE-POR',
  'mi.activities.group-code.BE_3': 'NSSOPLA',
  'mi.activities.group-code.BE_3_4': 'NSSO',
  'mi.activities.group-code.BE_4': 'NSSO',
  'mi.activities.group-code.BE_5': 'CBE-SCHOOLS',
  'mi.activities.group-code.BE_6': 'Madison',
  'mi.activities.group-code.EMPTY': 'AOG and other',
  'mi.activities.source': 'Source',
  'mi.activities.source.all-sources': 'All sources',
  'mi.activitySearchPlaceholder': 'Search on keyword or activity code',
  'mi.bigDownload.button': "Let's do something else",
  'mi.bigDownload.emailTo': "When your campaign is ready to download, we'll email you on:",
  'mi.bigDownload.heading': 'Almost time to hit your targets...',
  'mi.bigDownload.smallText': "Haven't received an email within 2 hours? Contact customer service",
  'mi.companies': 'companies',
  'mi.configurator.activities': 'Activities',
  'mi.configurator.addArea': 'Add area',
  'mi.configurator.addCity': 'Add city',
  'mi.configurator.addCity.belgium': 'Add city',
  'mi.configurator.addFoundingYear': 'Add year',
  'mi.configurator.addFoundingYearRange': 'Add year range',
  'mi.configurator.addZipCode': 'Add postcode',
  'mi.configurator.addZipCodeRange': 'Add postcode range',
  'mi.configurator.amountSelected': '{num} selected',
  'mi.configurator.annualAccount.equity_capital': 'Equity capital',
  'mi.configurator.annualAccount.net_result': 'Net result',
  'mi.configurator.annualAccount.tooltip':
    'Choose one or more key figures and define threshold value(s)',
  'mi.configurator.annualAccount.total_assets': 'Total assets',
  'mi.configurator.annualAccount.turnover': 'Turnover',
  'mi.configurator.annualAccount.working_capital': 'Working capital',
  'mi.configurator.annualAccountHeading': 'Annual accounts',
  'mi.configurator.area.enterToSearch': 'to search',
  'mi.configurator.area.heading': 'Area',
  'mi.configurator.area.locationNotFound': 'Location not found',
  'mi.configurator.area.tooltip':
    "Enter a location, press 'Enter' to search, and change the radius of the area by dragging the circle on the map",
  'mi.configurator.chooseActivities': 'Choose activities',
  'mi.configurator.cityFilterHeading': 'City',
  'mi.configurator.cityFilterHeading.belgium': 'City',
  'mi.configurator.clearAllFilters': 'Reset selection',
  'mi.configurator.clearSelection': 'Clear filter',
  'mi.configurator.companySizesHeading': 'Number of employees',
  'mi.configurator.companyTypesHeading': 'Type of establishment',
  'mi.configurator.edited': 'Edited',
  'mi.configurator.filters': 'Filters',
  'mi.configurator.foundingYearHeading': 'Incorporation date',
  'mi.configurator.foundingYearInputPlaceholder': 'Year',
  'mi.configurator.header.be': 'Your selection in Belgium',
  'mi.configurator.header.gb': 'Your selection in The United Kingdom',
  'mi.configurator.header.nl': 'Your selection in The Netherlands',
  'mi.configurator.heading': 'My campaign',
  'mi.configurator.legalFormsHeading': 'Legal form',
  'mi.configurator.noProspectsActivities':
    'No prospects in selection. Change one or more filters to view activities',
  'mi.configurator.provincesHeading': 'Provinces',
  'mi.configurator.provincesHeading.uk': 'Regions',
  'mi.configurator.rangeButton': 'Range',
  'mi.configurator.rangeSelector.and': 'and',
  'mi.configurator.rangeSelector.between': 'Between',
  'mi.configurator.rangeSelector.higher-than': 'Higher than',
  'mi.configurator.rangeSelector.lower-than': 'Lower than',
  'mi.configurator.removeFoundingYear': 'Remove',
  'mi.configurator.removeZipCode': 'Remove',
  'mi.configurator.resetFiltering': 'Reset filtering',
  'mi.configurator.save': 'Save',
  'mi.configurator.saveActivities': 'Continue selection',
  'mi.configurator.saveAs': 'Save as',
  'mi.configurator.savedFiltersHeading': 'My campaigns',
  'mi.configurator.saveFilters': 'Save campaign',
  'mi.configurator.saveFilters.inputPlaceholder':
    'e.g. Bakeries in London, large companies in Manchester',
  'mi.configurator.saveFilters.text':
    'To continue later, or create a template set to base new campaigns on',
  'mi.configurator.search-key.name': 'Name',
  'mi.configurator.selectCountryHeading': 'Start your campaign',
  'mi.configurator.selectCountryParagraph':
    'To start your campaign, you have to choose a country first. After that, you can make a prospect selection in this country.',
  'mi.configurator.sessionExpired.button': 'Log in again',
  'mi.configurator.sessionExpired.text':
    "Don't worry, your work is not lost. Please log in again to continue.",
  'mi.configurator.sessionExpired.title': 'For security reasons, your session has expired',
  'mi.configurator.showLess': 'Less',
  'mi.configurator.showMore': 'Show all',
  'mi.configurator.socialBalance.heading': 'Social balance',
  'mi.configurator.socialBalance.label':
    '{label, select, full_time_employees_size {Number of full time employees} board_members_size {Number of board members} office_staff_size {Number of office staff} other {Unknown category}}',
  'mi.configurator.socialBalance.tooltip':
    'Choose one or multiple employee type numbers and define threshold value(s).',
  'mi.configurator.textSearch.invalidQuery': 'Invalid search',
  'mi.configurator.textSearch.placeholder': 'For instance: Webshop AND (Basket OR Cart)',
  'mi.configurator.textSearchHeading': 'Search in company websites and company data',
  'mi.configurator.unknown': 'Unknown',
  'mi.configurator.zipCode': 'Postcode',
  'mi.configurator.zipCodeHeading': 'Postcode',
  'mi.configurator.zipCodeInputPlaceholder': 'Postcode',
  'mi.dateRangeSelection': 'Period',
  'mi.discontinuationScore.1': 'Low',
  'mi.discontinuationScore.2': 'Neutral',
  'mi.discontinuationScore.3': 'High',
  'mi.discontinuationScore.heading': 'Probability of discontinuation',
  'mi.discontinuationScore.NR': 'Not rated',
  'mi.download': 'Download',
  'mi.download.noResults.heading': 'There are no prospects left',
  'mi.download.noResults.message':
    'You probably have excluded too many companies. You have to exclude less companies, or change your filters.',
  'mi.downloadAsCsv': 'Download as CSV',
  'mi.downloadAsExcel': 'Download as Excel',
  'mi.downloadForm.amount.right': 'Total: {maxAmount}',
  'mi.downloadForm.amountScheduled.right': 'companies per batch',
  'mi.downloadForm.back': 'Back to campaign',
  'mi.downloadForm.batchSize': 'Maximum batch size',
  'mi.downloadForm.batchSize.nCompanies': 'companies',
  'mi.downloadForm.batchSize.xFirst': 'first',
  'mi.downloadForm.campaignName': 'Campaign name',
  'mi.downloadForm.cleanUp.heading': 'Clean up your download',
  'mi.downloadForm.cleanUp.paragraph':
    'Remove duplicate companies and exclude companies, such as your previous downloads.',
  'mi.downloadForm.deduplicate': 'Deduplicate',
  'mi.downloadForm.deduplicate.heading': 'Deduplicate your selection',
  'mi.downloadForm.deduplicate.onAddress': 'On address',
  'mi.downloadForm.deduplicate.onEmail': 'On Email address',
  'mi.downloadForm.deduplicate.onPhone': 'On phone number',
  'mi.downloadForm.downloadLocale': 'File language',
  'mi.downloadForm.downloadSettings.heading': 'Download settings',
  'mi.downloadForm.downloadType.once': 'Single download',
  'mi.downloadForm.downloadType.once.paragraph': 'Useful for smaller one-off campaigns.',
  'mi.downloadForm.downloadType.scheduled': 'Scheduled repeated download',
  'mi.downloadForm.downloadType.scheduled.paragraph':
    'Spread large amount of prospects over time. Will guarantee fresh data each time.',
  'mi.downloadForm.excelTooBig\n':
    'Unfortunately, Excel doesn’t support 1 million records or more. Please change to CSV or make your selection smaller.',
  'mi.downloadForm.exclude': 'Exclude',
  'mi.downloadForm.excludeDownloads': 'Exclude previous downloads',
  'mi.downloadForm.excludeDownloads.selectAll': 'All previous downloads',
  'mi.downloadForm.excludeDownloads.title': 'Choose previous downloads',
  'mi.downloadForm.excludeGroups.continue': 'Continue',
  'mi.downloadForm.excludeGroups.heading': 'Exclude list(s) from your campaign',
  'mi.downloadForm.excludeGroups.showMore': 'Choose one or more lists',
  'mi.downloadForm.fileType': 'File type',
  'mi.downloadForm.fileType.csv': 'Comma Separated Values (.csv)',
  'mi.downloadForm.fileType.excel': 'Excel (.xlsx)',
  'mi.downloadForm.fileType.heading': 'File type',
  'mi.downloadForm.first.batch': 'First batch',
  'mi.downloadForm.firstBatch': 'You will download the first batch of',
  'mi.downloadForm.gdpr.conditionsText':
    'The General Data Protection Regulation and the Telecommunications Act apply to the personal details and the scores in the download. For more information, please refer to the General Terms and Conditions of Graydon: <a href="https://www.graydon.co.uk/terms-and-conditions">https://www.graydon.co.uk/terms-and-conditions</a>',
  'mi.downloadForm.gdpr.dmus': 'Personal details of contact persons (if available)',
  'mi.downloadForm.gdpr.dmus.tooltip':
    'It is not permitted to approach companies/individuals with a non-mailing indicator and who are not part of your relations by mail or door-to-door sales.',
  'mi.downloadForm.gdpr.heading': 'Would you like to receive additional data?',
  'mi.downloadForm.gdpr.scores': 'Scores (if available)',
  'mi.downloadForm.gdpr.scores.tooltip':
    'Probability of default or financial risk, probability of discontinuation & expected growth.',
  'mi.downloadForm.heading': 'Downloading your campaign',
  'mi.downloadForm.maxNumberOfProspects': 'Number',
  'mi.downloadForm.name': 'Campaign name',
  'mi.downloadForm.nextBatch': 'You will receive your prospects on',
  'mi.downloadForm.on': 'on',
  'mi.downloadForm.order.oldest': 'oldest',
  'mi.downloadForm.order.withHighestProbOfDefault': 'with highest probability of Default',
  'mi.downloadForm.order.withLeastEmployees': 'with least employees',
  'mi.downloadForm.order.withLowestProbOfDefault': 'with lowest probability of Default',
  'mi.downloadForm.order.withMostEmployees': 'with most employees',
  'mi.downloadForm.order.youngest': 'youngest',
  'mi.downloadForm.recipient': 'Recipient',
  'mi.downloadForm.repeat': 'Repeat every',
  'mi.downloadForm.repeat.left': 'every',
  'mi.downloadForm.repeat.right': 'days',
  'mi.downloadForm.repeat.subtext': 'Next batch on',
  'mi.downloadForm.scheduledInfoLine2':
    'Please note: Scheduled downloads automatically deduplicate on previously delivered downloads. You never receive organisations more than once.',
  'mi.downloadForm.tags.heading': 'Choose a list to add the companies to',
  'mi.downloadForm.tags.paragraph': 'Lists can be found in List Management',
  'mi.downloadForm.today': 'today',
  'mi.downloadForm.validation.filterName': 'Please enter a name for the campaign',
  'mi.downloadForm.validation.nrCompanies': 'Please enter a number larger than 0',
  'mi.downloadForm.validation.prospectLimitExceeded':
    "You can't download more than {value} prospects at a time",
  'mi.downloadForm.validation.repeatInterval': 'Please enter a number larger than 0',
  'mi.downloadForm.validation.selectList': 'Please select one or more lists',
  'mi.downloadStarted.heading': 'Download started',
  'mi.downloadStarted.homeButton': 'Go to homepage',
  'mi.errorPage.fetchErrorDescription': 'Error fetching data, try again later',
  'mi.errorPage.heading': 'Error',
  'mi.errorPage.invalidDownload': 'Link has expired. Please download again.',
  'mi.errorPage.retry': 'Retry',
  'mi.foundingYear.after': 'After',
  'mi.foundingYear.before': 'Before',
  'mi.foundingYear.lastNMonths': 'Last {n} months',
  'mi.growthScore.1': 'Growth',
  'mi.growthScore.2': 'Neutral',
  'mi.growthScore.3': 'Decline',
  'mi.growthScore.heading': 'Expected growth',
  'mi.growthScore.NR': 'Not rated',
  'mi.hasTurnover.false': 'No',
  'mi.hasTurnover.heading': 'Has a turnover',
  'mi.hasTurnover.true': 'Yes',
  'mi.howToContact.has_email': 'Email available',
  'mi.howToContact.has_telephone_number': 'Telephone number available',
  'mi.howToContact.heading': 'Contact details',
  'mi.isActiveStatusCode': '{key, select, Y {Yes} N {No} other {Unknown}}',
  'mi.isActiveStatusCode.heading': 'Active companies',
  'mi.isActiveStatusCode.tooltip':
    'Active companies are companies registered as active at the Chamber of Commerce. These companies are not bankrupt and have not been dissolved.',
  'mi.isEconomicallyActive.heading': 'Economically active companies',
  'mi.isEconomicallyActive.N': 'No',
  'mi.isEconomicallyActive.N/A': 'Unknown',
  'mi.isEconomicallyActive.tooltip':
    'Economically active companies are companies of which Graydon has determined that they most likely take an active part in economic life. Graydon has determined this for example on the basis of filed annual account, collected payment information or information from other sources.',
  'mi.isEconomicallyActive.Y': 'Yes',
  'mi.noActivitiesFound':
    'No activities found. Please check your spelling, or try searching on another keyword.',
  'mi.nonMailIndicator.heading': 'KVK Non-mailing-indicator (NMI)',
  'mi.nonMailIndicator.noNMI': 'Only companies without NMI (accepts mail or door-to-door visit)',
  'mi.nrProspects': '{num} prospects',
  'mi.paymentScore.average': 'Average',
  'mi.paymentScore.heading': 'Payment score',
  'mi.paymentScore.high': 'High',
  'mi.paymentScore.low': 'Low',
  'mi.postcodeRange': 'Postcode range',
  'mi.preferredLanguages.FR': 'French',
  'mi.preferredLanguages.heading': 'Working language',
  'mi.preferredLanguages.NL': 'Dutch',
  'mi.preparingDownload.heading': 'Preparing download, please wait...',
  'mi.preparingDownloadCsv': 'Preparing CSV download',
  'mi.preparingDownloadExcel': 'Preparing Excel download',
  'mi.prospectPreview.activityUnknown': 'Activity unknown',
  'mi.prospectPreview.backButton': 'Change campaign',
  'mi.prospectPreview.heading': 'Example from your campaign',
  'mi.prospectPreview.legalFormUnknown': 'Legal form unknown',
  'mi.prospectPreview.unknownCompanyName': 'Name unknown',
  'mi.prospectPreviewButton': 'Example',
  'mi.ratingCode.1': 'High',
  'mi.ratingCode.2': 'Average',
  'mi.ratingCode.3': 'Low',
  'mi.ratingCode.heading': 'Financial risk',
  'mi.ratingCode.heading.nl': 'Probability of default',
  'mi.savedFilters.delete': 'Delete',
  'mi.savedFilters.deletePopup.cancel': 'No, cancel',
  'mi.savedFilters.deletePopup.confirm': 'Yes, delete',
  'mi.savedFilters.deletePopup.message': 'Are you sure you want to delete "{name}"?',
  'mi.savedFilters.deleteSuccess': 'Successfully deleted',
  'mi.savedFilters.editSchedule': 'edit',
  'mi.savedFilters.noSavedFilters': 'You currently do not have any saved campaigns.',
  'mi.savedFilters.saveSuccess': 'Campaign saved',
  'mi.savedFilters.scheduled': 'scheduled',
  'mi.savedFilters.scheduledText': 'Scheduled download every {nrDays} days, next batch {nextDate}',
  'mi.savedFilters.show.all': 'All',
  'mi.savedFilters.show.saved': 'Saved',
  'mi.savedFilters.show.scheduled': 'Scheduled',
  'mi.saveFilter.nameAlreadyExists': 'Campaign with the same name already exists',
  'mi.scheduleSet.heading': 'Scheduled download has been set',
  'mi.scheduleSet.paragraph': 'The first batch of prospects will be sent to {email} shortly',
  'mi.scheduleSet.paragraph.later':
    'The first batch of prospects will be sent to {email} on {date}',
  'mi.scheduleUpdated.heading': 'Scheduled download has been updated',
  'mi.sectors.heading': 'Sector',
  'mi.singlePostcode': 'Single postcode',
  'mi.singleYear': 'Single year',
  'mi.textSearchFilter.tooltip_1':
    '- Use <b>AND</b> between two keywords when both keywords should be present.',
  'mi.textSearchFilter.tooltip_2':
    '- Use <b>OR</b> between two keywords when either one of the keywords should be present.',
  'mi.textSearchFilter.tooltip_3':
    '- Use <b>NOT</b> in front of a keyword to exclude organisations with this keyword on their website.',
  'mi.textSearchFilter.tooltip_4':
    '- Place keywords <b>&quot;between quotes&quot;</b> to search for an exact match of those words.',
  'mi.textSearchFilter.tooltip_5':
    '- Place keywords <b>between parentheses</b> to group these words. For instance: (bakery OR baker) AND shop',
  'mi.totalSelectedProspects': 'Prospects in selection',
  'mi.unknownArea': 'Unknown location',
  'mi.whoToContact.has_financial_director': 'Financial matters',
  'mi.whoToContact.has_fleet_management_director': 'Fleet ',
  'mi.whoToContact.has_hrm_director': 'Human Resources',
  'mi.whoToContact.has_it_director': 'IT/Automation',
  'mi.whoToContact.has_managing_director': 'Management',
  'mi.whoToContact.has_managing_director.be':
    'Management: Cascade: managing director - director - manager - business manager – president',
  'mi.whoToContact.has_marketing_director': 'Marketing',
  'mi.whoToContact.has_operations_director': 'Operations',
  'mi.whoToContact.has_purchase_director': 'Purchasing',
  'mi.whoToContact.has_sales_director': 'Sales',
  'mi.whoToContact.has_technical_director': 'Technical service',
  'mi.whoToContact.heading': 'Contact person available',
  'mi.yearRange': 'Year range',
  'mi.yearSelection': 'Year',
  'mon.backToUpdateOverview': 'Back to update overview',
  'mon.ccjFlag.false': 'None registered',
  'mon.ccjFlag.true': 'CCJ registered',
  'mon.configure.address': 'Company address',
  'mon.configure.address_1': 'Company address',
  'mon.configure.address_1.hint':
    'Monitor the changes to company addresses. You will be notified when there is an update. You can find the updated information in the Credit Report.',
  'mon.configure.address.changed': 'New address registered',
  'mon.configure.address.hint':
    'Monitor the changes to company addresses. You will be notified when there is an update. You can find the updated information in the Credit Report.',
  'mon.configure.annualReport': 'Annual account',
  'mon.configure.annualReport.hint': 'Monitor when there are new annual accounts available.',
  'mon.configure.annualReport.newReport': 'New annual account registered',
  'mon.configure.annualReportGB': 'Accounts available (at Graydon)',
  'mon.configure.annualReportGB.hint':
    "Annual accounts: Monitor when there are new annual accounts available in Graydon's database and reports",
  'mon.configure.annualReports': 'Latest account filed at Companies House at',
  'mon.configure.annualReports.title': 'Annual accounts',
  'mon.configure.beMultiscore': 'Multiscore',
  'mon.configure.beMultiscore.hint':
    'Monitor any changes to the Multiscore. This metric indicates the level of credit risk associated with the company, expressed as a score with range 1 - 100.',
  'mon.configure.ccjFlag': 'CCJ flag',
  'mon.configure.ccjFlag.hint':
    'Monitor for all CCJ’s , you will be notified each time a CCJ is recorded against a company which includes a CCJ recorded, CCJ satisfied and cancelled CCJ’s.',
  'mon.configure.companyName': 'Company name',
  'mon.configure.companyName.hint':
    'Monitor the changes to a company name. You will be notified when there is an update. You will be able to view the old and the new name.',
  'mon.configure.country': 'Country',
  'mon.configure.country.hint':
    'Choose a country. If a company list that you monitor consists of companies from multiple countries, you will only monitor companies from chosen country.<br><br>If you want to monitor companies from multiple countries you should set up a monitoring profile for each country.',
  'mon.configure.courtJudgmentDetails': 'CCJs',
  'mon.configure.courtJudgmentDetails.hint':
    'Monitor for all CCJ’s , you will be notified each time a CCJ is recorded against a company which includes a CCJ recorded, CCJ satisfied and cancelled CCJ’s.',
  'mon.configure.creditAmountAdvised': 'Credit limit',
  'mon.configure.creditAmountAdvised.changeMoreThanPercent': '{n}% change',
  'mon.configure.creditAmountAdvised.hint':
    'Monitor any changes to the advised maximum credit limit.',
  'mon.configure.dataType': 'Data type',
  'mon.configure.events': 'Events',
  'mon.configure.eventsPosition': 'Directors',
  'mon.configure.eventsPosition.hint':
    'Monitor the changes to company directors. You will be notified when there is an update. You can find the updated information in the Credit Report.',
  'mon.configure.financialCalamities': 'Insolvency',
  'mon.configure.financialCalamities.hint':
    'Monitor insolvency-related events. We recommend that you always have this alert activated.',
  'mon.configure.financialCalamitiesDetails': 'Insolvency Details',
  'mon.configure.financialCalamitiesDetails.hint':
    'Monitor detailed insolvency-related events. We recommend that you always have this alert activated.',
  'mon.configure.general': 'General',
  'mon.configure.graydonRating': 'Graydon Rating',
  'mon.configure.graydonRating.changeMoreThan':
    '{n} {n, plural, =1 {rating category} other {rating categories}}',
  'mon.configure.graydonRating.hint':
    'Monitor any changes to the Graydon Rating. This is expressed on a scale from AAA (very low risk) to C (high risk). D indicates a bankruptcy. NR indicates no rating is available.',
  'mon.configure.graydonRatingPercentage': 'Percentage likelihood of failure',
  'mon.configure.graydonRatingPercentage.hint':
    'Monitor any changes to the percentage likelihood of failure. This is the likelihood of the company experiencing severe financial distress (i.e. insolvency events), expressed as a percentage.',
  'mon.configure.heading': 'Monitoring profile ‘{profileName}’',
  'mon.configure.latestAnnualReportFilingDate': 'Latest accounts filed',
  'mon.configure.latestAnnualReportFilingDate.hint':
    'Monitor when new accounts have been filed at Companies House',
  'mon.configure.legalForm': 'Legal form',
  'mon.configure.legalForm_1': 'Legal form',
  'mon.configure.legalForm_1.hint':
    "Monitor any changes to a company's legal form. You will be notified when there is an update. You can find the updated information in the Credit Report.",
  'mon.configure.legalForm.changed': 'New legal form registered',
  'mon.configure.legalForm.hint':
    "Monitor any changes to a company's legal form. You will be notified when there is an update. You can find the updated information in the Credit Report.",
  'mon.configure.liabilityStatement': 'Liability statement',
  'mon.configure.liabilityStatement.hint':
    'Monitor the changes to a liability statement. You will be notified when \nthere is an update. You will be able to view the old and the new liable \ncompany.',
  'mon.configure.liabilityStatement.updateLabel': 'Liable company',
  'mon.configure.list.countryNote':
    'Note: only {organizationCountOfCountry} {organizationCountOfCountry, plural, =1 {company} other {companies}} of {organizationCount} in the list are from {countryCode} and will be monitored.',
  'mon.configure.list.countryNote.empty':
    'Note: none of the {organizationCount} companies in the list are from {countryCode}, so none will be monitored by this profile.',
  'mon.configure.list.createEmptyList': 'Create new list',
  'mon.configure.list.custom.value': 'Custom value: {value}',
  'mon.configure.list.custom.value.error': 'Please enter a value between 0 and 600.',
  'mon.configure.list.custom.value.placeholder': 'Choose or enter a value',
  'mon.configure.list.download': 'Download list',
  'mon.configure.list.goToListManagement': 'Go to List Management',
  'mon.configure.list.heading': 'Companies',
  'mon.configure.list.headingText': 'Choose a list or',
  'mon.configure.list.label': 'Monitor companies in list',
  'mon.configure.list.link': 'View in List Management',
  'mon.configure.list.link.newTab': '(opens in a new tab)',
  'mon.configure.list.none': 'None',
  'mon.configure.management': 'Directors',
  'mon.configure.management.changed': 'Change in directors registered',
  'mon.configure.management.hint':
    'Monitor the changes to company directors. You will be notified when there is an update. You can find the updated information in the Credit Report.',
  'mon.configure.notification.email.label': 'Send alerts to',
  'mon.configure.notification.frequency': 'Frequency',
  'mon.configure.notification.frequency.DAILY': 'Daily',
  'mon.configure.notification.frequency.EVERY_FOUR_WEEKS': 'Every four weeks',
  'mon.configure.notification.frequency.EVERY_MONTH': 'Every month',
  'mon.configure.notification.frequency.EVERY_SIX_MONTHS': 'Every six months',
  'mon.configure.notification.frequency.EVERY_THREE_MONTHS': 'Every three months',
  'mon.configure.notification.frequency.EVERY_TWO_MONTHS': 'Every two months',
  'mon.configure.notification.frequency.EVERY_TWO_WEEKS': 'Every two weeks',
  'mon.configure.notification.frequency.EVERY_YEAR': 'Yearly',
  'mon.configure.notification.frequency.note':
    "Note: You can only set up the frequency at creation. This can't be changed.",
  'mon.configure.notification.frequency.WEEKLY': 'Weekly',
  'mon.configure.notification.heading': 'Notification',
  'mon.configure.operator.ANY_CHANGE': 'Any change',
  'mon.configure.operator.CHANGE_MORE_THAN': 'Any change more than',
  'mon.configure.operator.CHANGE_MORE_THAN_PERCENT': 'Any change more than',
  'mon.configure.operator.HIGHER_OR_LOWER_THAN': 'Becomes higher or lower than',
  'mon.configure.operator.HIGHER_THAN': 'Becomes higher than',
  'mon.configure.operator.LOWER_THAN': 'Becomes lower than',
  'mon.configure.operator.xseptions.ANY_CHANGE': 'All XSeptions',
  'mon.configure.operator.xseptions.ONLY_HIGH_AND_MEDIUM_PRIORITY_XSEPTIONS':
    'Only high & medium priority XSeptions',
  'mon.configure.operator.xseptions.ONLY_HIGH_PRIORITY_XSEPTIONS': 'Only high priority XSeptions',
  'mon.configure.paymentScore': 'Payment score',
  'mon.configure.paymentScore.hint':
    'Monitor any changes to the payment score. This metric indicates the extent to which the company honours its payment obligations, expressed in a score with range 1 – 10',
  'mon.configure.phoneNumber': 'Phone number',
  'mon.configure.phoneNumber.changed': 'New phone number registered',
  'mon.configure.phoneNumber.hint':
    'Monitor the changes to company phone numbers. You will be notified when there is an update. You can find the updated information in the Credit Report.',
  'mon.configure.profileName': 'Profile name',
  'mon.configure.recipients': 'Send alerts to',
  'mon.configure.recipients.add': 'Add another',
  'mon.configure.recipients.none': 'Updates will not be sent by email',
  'mon.configure.save': 'Save and start monitoring',
  'mon.configure.selection.goToMarketData': 'Go to Market Data',
  'mon.configure.selection.link': 'View selection in Market data',
  'mon.configure.sendAlerts': 'Send alerts',
  'mon.configure.sendAlerts.ALWAYS': 'Always',
  'mon.configure.sendAlerts.NEVER': 'No',
  'mon.configure.sendAlerts.ONLY_ON_UPDATE': 'Only when there are updates',
  'mon.configure.shareHolders': 'Shareholders',
  'mon.configure.shareHolders.changed': 'Change in shareholder(s) registered',
  'mon.configure.shareHolders.hint':
    'Monitor the changes to company shareholders. You will be notified when there is an update. You can find the updated information in the Credit Report.',
  'mon.configure.shareholdersDetails': 'Shareholders',
  'mon.configure.shareholdersDetails.changed': 'Change in shareholder(s) registered',
  'mon.configure.summons': 'Summons',
  'mon.configure.summons.hint':
    'Monitor detailed summons events. You will be notified when there is an update. You will be able to view summon details.',
  'mon.configure.threshold': 'Threshold',
  'mon.configure.totalSummons': 'Number of summons',
  'mon.configure.totalSummons.hint':
    'Monitor the number of summons. You will be notified when there is an update. You will be able to view the previous number and the new total number of summons.',
  'mon.configure.ukAugurRating': 'Augur score',
  'mon.configure.ukAugurRating.hint':
    'Monitor any changes to the Augur Score. This metric is based on statistical analysis of a credit file, to represent the risk or creditworthiness of a company. Empirically derived score, ranges from 0 – 600.',
  'mon.configure.ultimateParent': 'Ultimate parent company',
  'mon.configure.ultimateParent.hint':
    'Monitor the changes to the ultimate parent company. You will be notified when there is an update. You can find the updated information in the Credit Report.',
  'mon.configure.validation.empty': "This field can't be empty",
  'mon.configure.validation.maximum': 'Value can not be higher than {max}',
  'mon.configure.validation.minimum': 'Value can not be lower than {min}',
  'mon.configure.validation.nameAlreadyExists': 'Name already exists',
  'mon.configure.xseptions': 'XSeptions',
  'mon.configure.xseptions.hint':
    'Monitor any remarkable situations within a company (XSeptions). You will be notified when there is an update. You can choose to only be notified about XSeptions with a specific priority as defined in your preferences.',
  'mon.configure.xseptions.link.prio-preferences': 'View XSeptions priority preferences',
  'mon.configure.ubo': 'UBO',
  'mon.configure.ubo.hint':
    'Monitor any changes to a company’s UBO (Ultimate Beneficial Owner). You will be notified when there is an update. You can find the updated information in the UBO report.',
  'mon.createListPopup.success': 'List has been created',
  'mon.createListPopup.success.text':
    'The company list that you have created is attached to the monitoring profile.',
  'mon.createProfile.back': 'Back to profile',
  'mon.createProfile.backToEvents': 'Show update overview',
  'mon.createProfile.backToProfile': 'Show profile settings',
  'mon.createProfile.heading': 'Create monitoring profile',
  'mon.createProfileLink': 'New profile',
  'mon.createProfilePopup.error': 'Profile can not be saved',
  'mon.createProfilePopup.error.nameAlreadyExists': 'Profile name already exists',
  'mon.createProfilePopup.note':
    'Note: Updates that match the new monitoring settings will be visible as from tomorrow or next week, depending on the chosen notification frequency.',
  'mon.createProfilePopup.success': 'Profile has been saved',
  'mon.exportProfiles.button': 'Export Profiles',
  'mon.exportProfiles.popup.heading': 'Export multiple monitoring profiles',
  'mon.exportProfiles.popup.body.parargraph.1':
    'Graydon employees can use this automated tool to export multiple monitoring profiles of a customer in order to import these into Creditsafe’s Monitoring application. Upload a CSV file in the specified format and choose Export.',
  'mon.exportProfiles.popup.body.parargraph.2':
    'For more information contact Creditsafe’s IT department via the servicedesk:',
  'mon.exportProfiles.popup.body.email': 'service@graydon.nl',
  'mon.exportProfiles.popup.link.upload': 'Upload CSV file',
  'mon.exportProfiles.popup.link.reset': 'Reset',
  'mon.exportProfiles.popup.files.selected': 'Selected file',
  'mon.exportProfiles.popup.files.rejected': 'File rejected',
  'mon.exportProfiles.popup.button.download': 'Export',
  'mon.exportProfiles.popup.error.400': 'Problem with the request',
  'mon.exportProfiles.popup.error.401': 'You are not authorised',
  'mon.exportProfiles.popup.error.403': 'You are not authorised',
  'mon.exportProfiles.popup.error.404': 'Uploading file failed',
  'mon.exportProfiles.popup.error.500': 'Processing of file failed',
  'mon.exportProfiles.popup.error.unknown': 'Unknown error',
  'mon.exportProfiles.popup.processing': 'Your file is being processed...',
  'mon.exportProfiles.popup.success': 'Export file downloaded',
  'mon.customDownload.button': 'Download history',
  'mon.customDownload.button.mobile': 'History',
  'mon.customDownload.heading': 'Custom period',
  'mon.customDownload.placeholder': 'Choose week',
  'mon.customDownload.text': 'Select a custom period in order to download a collection of updates',
  'mon.customDownload.title': 'Download history',
  'mon.defaultProfileName': 'My profile',
  'mon.deleteProfileLink': 'Delete profile',
  'mon.deleteProfilePopup.cancel': 'Cancel',
  'mon.deleteProfilePopup.confirm': 'Delete',
  'mon.deleteProfilePopup.paragraph':
    'Are you sure you want to delete this profile? When you delete a profile, all stored monitoring events for that profile will not be accessible anymore.',
  'mon.deleteProfilePopup.success': 'Profile has been deleted',
  'mon.deleteProfilePopup.toUpdateOverview': 'Back to update overview',
  'mon.emailInvalid': 'Please enter a valid email address',
  'mon.eventsPage.courtJudgmentDetails.amount': 'For £{amount}',
  'mon.eventsPage.courtJudgmentDetails.cancellation': 'Cancellation',
  'mon.eventsPage.courtJudgmentDetails.caseNumber': 'Case no.: {caseNumber}',
  'mon.eventsPage.courtJudgmentDetails.judgement': 'Judgment',
  'mon.eventsPage.courtJudgmentDetails.location': 'Registered in {courtName}',
  'mon.eventsPage.courtJudgmentDetails.satisfaction': 'Satisfaction',
  'mon.eventsPage.download': 'Download',
  'mon.eventsPage.editProfile': 'Edit profile',
  'mon.eventsPage.governmentGazette': 'see appendix Belgian official journal',
  'mon.eventsPage.groupByCompany': 'Group by company',
  'mon.eventsPage.heading': 'Updates in ‘{profileName}’',
  'mon.eventsPage.list': 'List',
  'mon.eventsPage.noUpdates.emptyList':
    'There are no updates, because the company list you are monitoring does not contain any companies yet.',
  'mon.eventsPage.noUpdates.general': 'There are no updates for chosen period.',
  'mon.eventsPage.noUpdates.noList':
    'There are no updates, because there is no company list related to this monitoring profile.',
  'mon.eventsPage.noUpdates.noList.link': 'Edit the profile',
  'mon.eventsPage.noUpdates.noList.link.after': 'and relate a list to the profile.',
  'mon.eventsPage.numUpdates': '{numUpdates} {numUpdates, plural, =1 {update} other {updates}}',
  'mon.eventsPage.numUpdates.in': 'in',
  'mon.eventsPage.oldValue': '(was: {oldValue})',
  'mon.eventsPage.profileTile.hint':
    'You can set up multiple monitoring profiles. For each profile you can choose a list of companies that you want to monitor and you can define what event types you want to monitor.',
  'mon.eventsPage.since': 'Since',
  'mon.eventsPage.updates': 'Updates',
  'mon.eventsPage.updates.hint':
    'You will see updates that match you monitoring profile settings and that have taken place in the chosen period. If you do not see any updates you could edit your profile settings.',
  'mon.export.title.graydonInsights': 'Graydon Insights',
  'mon.export.title.creditSafe': 'Creditsafe',
  'mon.export.migrate.button': 'How to migrate to Creditsafe?',
  'mon.export.download.button': 'Download profile settings',
  'mon.export.operator.ANY_CHANGE': 'Any change',
  'mon.export.operator.CHANGE_MORE_THAN': 'Reduce by',
  'mon.export.operator.CHANGE_MORE_THAN_PERCENT': 'Reduce by',
  'mon.export.operator.HIGHER_OR_LOWER_THAN': 'Less then',
  'mon.export.operator.ONLY_HIGH_AND_MEDIUM_PRIORITY_XSEPTIONS': 'Any change',
  'mon.export.operator.ONLY_HIGH_PRIORITY_XSEPTIONS': 'Any change',
  'mon.export.configure.general': 'Portfolios / Edit portfolio',
  'mon.export.configure.events': 'Notification rules',
  'mon.export.configure.notification.heading': 'Portfolios / Edit portfolio',
  'mon.export.configure.profileName': 'Portfolio name',
  'mon.export.configure.list.label': 'Selected companies',
  'mon.export.configure.list.value': '[Import companies from file]',
  'mon.export.configure.recipients': 'Emails',
  'mon.export.configure.financialCalamitiesDetails': 'Bankruptcy data',
  'mon.export.configure.creditAmountAdvised': 'Limit',
  'mon.export.configure.creditAmountAdvised.extra':
    'Credit Limit drops below internal (customer) credit limit',
  'mon.export.configure.graydonRating': 'Creditscore',
  'mon.export.configure.graydonRating.higherLower': 'International Score & Return Band',
  'mon.export.configure.xseptions': 'Xseptions Addition',
  'mon.export.configure.xseptions.extra': 'Xseptions Removal',
  'mon.export.configure.annualReport': 'New accounts filed',
  'mon.export.configure.beMultiscore': 'Creditscore',
  'mon.export.configure.beMultiscore.higherLower': 'International Score & Return Band',
  'mon.export.configure.paymentScore': 'Payment experience left',
  'mon.export.configure.ultimateParent': 'Ultimate Holding Company',
  'mon.export.configure.eventsPosition': 'Director(s)',
  'mon.export.configure.management': 'Director(s)',
  'mon.export.configure.shareholdersDetails': 'Share capital change',
  'mon.export.configure.liabilityStatement': 'Declaration of Liability (403)',
  'mon.export.configure.companyName': 'Company name',
  'mon.export.configure.address_1': 'Address',
  'mon.export.configure.phoneNumber': 'Telephone Number',
  'mon.financialCalamities.yes': 'Yes',
  'mon.onboarding.noupdates': 'There are no updates yet.',
  'mon.onboarding.start': 'Start monitoring',
  'mon.onboarding.step1': 'Create a monitoring profile',
  'mon.onboarding.step2': 'Create a new list',
  'mon.onboarding.step3': 'Add companies to the list as follows:',
  'mon.onboarding.step3-import-link': 'import a list of companies',
  'mon.onboarding.step3-import-then': 'via List Management.',
  'mon.onboarding.step3-search-link': 'search a company',
  'mon.onboarding.step3-search-then': 'and add this via Credit Reports or',
  'mon.onboarding.to-start-take-steps': 'To start monitoring companies, take the following steps:',
  'mon.onboarding.unauthorized':
    'You do not have permission to create a monitoring profile. Ask someone with administrator permissions to create a profile first.',
  'mon.period': 'Period',
  'mon.period.lastWeek': 'Last week ({date})',
  'mon.period.yesterday': 'Yesterday ({date})',
  'mon.portfolioAnalyser': 'My portfolio',
  'mon.portfolioAnalyser.company.reference': 'Your reference',
  'mon.portfolioAnalyser.company.registrationId': 'Official Registration Id',
  'mon.portfolioAnalyser.company.registrationId.be': 'Business number',
  'mon.portfolioAnalyser.company.registrationId.gb': 'Company registration number',
  'mon.portfolioAnalyser.company.registrationId.nl': 'Chamber of Commerce number (KvK)',
  'mon.portfolioAnalyser.companyList': 'Company list',
  'mon.portfolioAnalyser.companyList.hint':
    'Choose a list and view the data from the companies in that list.',
  'mon.portfolioAnalyser.companyOverview': 'Company data',
  'mon.portfolioAnalyser.companyOverview.hint':
    '<b>Graydon Rating</b><br/>\nThe probability that a company may default on their payments within the next 12 months.<br/>\n<br/>\n<b>Multiscore</b><br/>\nMetric for the level of credit risk associated with the company, expressed as a score with range 1 - 100.<br/>\n<br/>\n<b>Augur score</b><br/>\nEmpirically derived score, (ranging from 0 – 600) based on key information of a business and assessing credit risks.<br/>',
  'mon.portfolioAnalyser.download': 'Download',
  'mon.collapseReferences': 'Collapse references to one row',
  'mon.portfolioAnalyser.graydonRatingOverview': 'Graydon Rating',
  'mon.portfolioAnalyser.graydonRatingOverview.AAA_AA_A': 'AAA/AA/A',
  'mon.portfolioAnalyser.graydonRatingOverview.BBB_BB_B': 'BBB/BB/B',
  'mon.portfolioAnalyser.graydonRatingOverview.CCC_CC_C': 'CCC/CC/C',
  'mon.portfolioAnalyser.graydonRatingOverview.D': 'D - Bankrupt',
  'mon.portfolioAnalyser.graydonRatingOverview.hint':
    'Here is a breakdown of Graydon Rating for the companies in this list.',
  'mon.portfolioAnalyser.graydonRatingOverview.NON_MONITORED': 'Not monitored',
  'mon.portfolioAnalyser.graydonRatingOverview.NR': 'Not rated',
  'mon.portfolioAnalyser.monitoringMessage':
    'Graydon data is only available for companies that are in a monitored company list.',
  'mon.portfolioAnalyser.monitoringMessage.link': 'Configure monitoring',
  'mon.portfolioAnalyser.table.limitNote':
    'Note: Your (filtered) company overview contains {totalAmount} companies. We only show the first {shownAmount}.',
  'mon.profile.noList': 'No list',
  'mon.profileLabel': 'Profile',
  'mon.showPortfolioAnalyser': 'Portfolio status',
  'mon.updateProfilePopup.error': 'Profile changes could not be saved',
  'mon.updateProfilePopup.note':
    'Note: Updates that match the new monitoring settings will be visible as from tomorrow or next week, depending on the chosen notification frequency.',
  'mon.updateProfilePopup.showUpdateOverview': 'Show update overview',
  'mon.updateProfilePopup.success': 'Profile changes have been saved',
  'nav.dm.archive': 'Archive',
  'nav.dm.create': 'Create a decision model',
  'nav.dm.modelmanagement': 'Model management',
  'nav.dm.view': ' ',
  'nav.home': 'Homepage',
  'nav.mdb.dashboard': 'Management Dashboard',
  'nav.search': 'Search for a company',
  'notifications.title': 'Notifications',
  'one-search.company': 'Company',
  'one-search.company.form.description':
    'Select a country and enter company name or other distinctive data.',
  'one-search.company.form.error': 'Error in fetching country list. Please try again later',
  'one-search.company.form.title': 'Search for company data',
  'one-search.company.search-bar.placeholder': 'Enter company information',
  'one-search.person': 'Person',
  'pdf.annualAccount.accountStatus': 'Account status',
  'pdf.annualAccount.accountStatus.filed': 'Filed',
  'pdf.annualAccount.accountStatus.unknown': 'Unknown',
  'pdf.annualAccount.baseCurrency': 'Base currency',
  'pdf.annualAccount.baseUnit': 'Base unit',
  'pdf.annualAccount.creditRatios': 'Key credit ratios',
  'pdf.annualAccount.dateOfClosure': 'Date of closure',
  'pdf.annualReport.natuur10.text':
    'Due to the specific model, the annual accounts for this company cannot be processed. You can consult the original accounts through the website of the <a href="https://www.nbb.be/en" rel="noopener noreferrer" target="_blank">National Bank of Belgium</a>. If this is a mistake or shouldn’t be the case, please contact our Customer Service team.',
  'pdf.annualReport.natuur10.title':
    'This company has filed an exceptional type of annual accounts',
  'pdf.annualReport.noReport.text':
    'This company has not submitted any annual accounts in the last 5 years yet.',
  'pdf.annualReport.noReport.title': 'No insolvency case for this company',
  'pdf.paymentBehaviour.noData.text':
    "Unfortunately, we don't have enough payment information available for a proper analysis.",
  'pdf.annualReport.noReportLiability.text':
    'This company nor its liable company has submitted any annual accounts in the last 5 years.',
  'pdf.annualReport.noReportLiability.title': 'No annual accounts available',
  'pdf.branches.limit-message': '20+ branches, the complete list is viewable in the digital report',
  'pdf.copyright.statement':
    'Copyright {yearIssued} {customerCountry, select, gb {Graydon UK Limited} be {Graydon Belgium N.V.} nl {Graydon Nederland B.V.} other {Graydon UK Limited}}',
  'pdf.footer.text':
    'The {productName} is intended for the exclusive use of {companyName} and the contractually approved users – {userName}.',
  'pdf.footer.ubo': 'UBO Report - {companyName} - {requestorName}',
  'pdf.generic-no-data.text':
    'If you have questions or need help, please contact our Customer Service team for more information.',
  'pdf.generic-no-data.title': 'This type of information is not available for this company',
  'pdf.legal.contact':
    'Please contact Graydon Customer Support <b>{country, select, gb {+44&nbsp;(0)20&nbsp;8515&nbsp;1400} nl {+31&nbsp;(020)&nbsp;567&nbsp;9501} be {+32&nbsp;(03)&nbsp;280&nbsp;88&nbsp;80} other {+44&nbsp;(0)20&nbsp;8515&nbsp;1400}}</b> or <b><a href="mailto:{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} be {support@graydon.be} other {customerservices@graydon.co.uk}}">{country, select, gb {customerservices@graydon.co.uk} nl {service@graydon.nl} be {support@graydon.be} other {customerservices@graydon.co.uk}}</a></b>',
  'pdf.legal.contact.heading': 'Any questions?',
  'pdf.legal.copyright': 'Copyrights: {graydonName}',
  'pdf.legal.disclaimer':
    'The Graydon terms and conditions are applicable to this Graydon Credit Report. The content of this Graydon Credit Report is confidential and must not be provided to third parties (in whole or in part). You are solely allowed to use this content solely for the purpose of your own internal business use.',
  'pdf.legal.graydon.BE': 'Graydon Belgium N.V.',
  'pdf.legal.graydon.GB': 'Graydon UK Ltd.',
  'pdf.legal.graydon.NL': 'Graydon Nederland B.V.',
  'pdf.legal.report.requested.by': 'Report requested by',
  'pdf.legal.statement':
    'The {customerCountry, select, gb {Graydon UK Limited} be {Graydon Belgium N.V.} nl {Graydon Nederland B.V.} other {Graydon UK Limited}} terms and conditions are applicable to this Graydon {productName}. The content of this Graydon {productName} is confidential and may not be provided to third parties (in whole or in part). You are solely allowed to use this content for the purpose of your own internal business use.',
  'pdf.section.h1.company-profile': 'Company Profile',
  'pdf.section.h1.company-structure': 'Company structure',
  'pdf.section.h1.exceptional-events': 'Exceptional events',
  'pdf.section.h1.financials': 'Financials',
  'pdf.section.h1.payment.behaviour': 'Payment behaviour',
  'pdf.section.h1.ratings': 'Ratings',
  'pdf.section.h1.summary': 'Summary',
  'pdf.section.h2.balance-sheet': 'Balance sheet',
  'pdf.section.h2.branches': 'Branches',
  'pdf.section.h2.company-contacts': 'Company Contacts',
  'pdf.section.h2.company-details': 'Company details',
  'pdf.section.h2.credit.flag': 'Credit Flag',
  'pdf.section.h2.credit.flag.maximum.advised': 'Maximum advised credit limit over 12 months',
  'pdf.section.h2.credit.limit': 'Credit Limit',
  'pdf.section.h2.credit.limit.trend': 'Credit limit trend',
  'pdf.section.h2.credit.rating': 'Credit rating',
  'pdf.section.h2.credit.rating.graydon.percentage': 'Graydon Percentage',
  'pdf.section.h2.credit.rating.graydon.rating': 'Graydon Rating',
  'pdf.section.h2.directors': 'Directors',
  'pdf.section.h2.discontinuation.score': 'Probability of Discontinuation',
  'pdf.section.h2.events': 'Events',
  'pdf.section.h2.events.BE': 'History',
  'pdf.section.h2.financial-insolvencies': 'Insolvencies',
  'pdf.section.h2.general-information': 'General Information Annual Reports',
  'pdf.section.h2.group-structure': 'Group structure',
  'pdf.section.h2.growth.score': 'Growth score',
  'pdf.section.h2.key-figures': 'Key figures',
  'pdf.section.h2.liability': 'Liability',
  'pdf.section.h2.order-data': 'Order data',
  'pdf.section.h2.others': 'Other',
  'pdf.section.h2.payment.analysis.table': 'Payment behaviour analysis',
  'pdf.section.h2.payment.ccj.details': 'County Court Judgment details',
  'pdf.section.h2.payment.mortgages': 'Registered charges',
  'pdf.section.h2.payment.payment.averages': 'Payment averages',
  'pdf.section.h2.payment.score': 'Payment score',
  'pdf.section.h2.payment.score.trend': 'Payment score’s trend',
  'pdf.section.h2.payment.summons.and.judgements': 'Summons',
  'pdf.section.h2.payment.terms': 'Payment behaviour for the last 4 quarters',
  'pdf.section.h2.payment.trend': 'Days Beyond Terms Trend',
  'pdf.section.h2.profit-and-loss': 'Profit and loss',
  'pdf.section.h2.rating.trend': 'Graydon Rating trend',
  'pdf.section.h2.shareholders': 'Shareholders',
  'pdf.section.h2.summary.company-data': 'Company details',
  'pdf.section.h2.summary.company-structure': 'Company structure',
  'pdf.section.h2.summary.insolvency': 'Insolvency',
  'pdf.section.h2.summary.key-figures': 'Key figures',
  'pdf.section.h2.summary.payment-score-ccj-summons.BE': 'Payment score, summons & judgments',
  'pdf.section.h2.summary.payment-score-ccj-summons.GB':
    'Payment Behavior & County Court Judgments',
  'pdf.section.h2.summary.payment-score-ccj-summons.NL': 'Payment score',
  'pdf.section.h2.tradenames': 'Trade names',
  'pdf.section.h2.xseptions.BE': 'XSeptions',
  'pdf.section.h2.xseptions.GB': 'XSeptions',
  'pdf.section.h2.xseptions.NL': 'XSeptions',
  'pdf.section.h3.order-comment': 'Comments',
  'pdf.section.h3.order-number': 'Order number',
  'pdf.section.h3.order-reference': 'Order reference',
  'pdf.support.statement':
    '**If you have any questions or need help, please contact our Customer Service.**<br />Contact our Customer Service team on **{country, select, gb {+44 (0)20 8515 1400} be {+32 (03) 280 88 80} nl {+31 (0)20 567 9501} other {+44 (0)20 8515 1400}}** or at <a href="mailto:{country, select, gb {customerservices@graydon.co.uk} be {support@graydon.be} nl {service@graydon.nl} other {customerservices@graydon.co.uk}}">**{country, select, gb {customerservices@graydon.co.uk} be {support@graydon.be} nl {service@graydon.nl} other {customerservices@graydon.co.uk}}**</a>.',
  'pdf.tradenames.limit-message':
    '20+ tradenames, the complete list is viewable in the digital report',
  'pdf.tradenames.title': 'Trade names',
  'person.birthdate': 'Date of birth',
  'person.gender.FEMALE.title': 'Mrs.',
  'person.gender.MALE.title': 'Mr.',
  'placeSearch.placeholder': 'Enter a location',
  'position.authorization': 'Authorisation',
  'position.startdate': 'Start date',
  'position.title': 'Title',
  radius: 'radius',
  rangeTo: 'to',
  'relation.company-link.warning':
    'ATTENTION: Clicking on the link of the related company brings you to a new Highlight report. This action will be charged to your account.',
  'search-results.item.address': 'Address',
  'search-results.item.Buyers_Number': 'Buyers Number',
  'search-results.item.Chamber_Of_Commerce': 'Chamber of Commerce',
  'search-results.item.Company_Registration_Number': 'Company registration number ',
  'search-results.item.contact.email': 'Found in e-mail address: {value}',
  'search-results.item.contact.telephoneNumber': 'Found in telephone number: {value}',
  'search-results.item.Fiscal_Code': 'Fiscal Code',
  'search-results.item.gbYCompanyId': 'Found in Graydon number: {value}',
  'search-results.item.id': 'Graydon number',
  'search-results.item.Internal': 'Company number',
  'search-results.item.National_Government_Business_Identification_Number':
    'National Government Business Identification Number',
  'search-results.item.National1': 'National1',
  'search-results.item.National2': 'National2',
  'search-results.item.NIT': 'NIT',
  'search-results.item.previousCompanyName': 'Found in previous company name: {value}',
  'search-results.item.previousRegisteredAddress': 'Found in previous registered address: {value}',
  'search-results.item.registrationNumber.be': 'Business number',
  'search-results.item.registrationNumber.gb': 'Company registration number',
  'search-results.item.registrationNumber.nl': 'Chamber of Commerce number (KvK)',
  'search-results.item.RIF': 'RIF',
  'search-results.item.RUC_Number': 'RUC Number',
  'search-results.item.SIREN_Number': 'SIREN Number',
  'search-results.item.SIRET_Number': 'SIRET Number',
  'search-results.item.tradeName': 'Found in trade name: {value}',
  'search-results.item.tradeNames': 'Found in trade name: {value}',
  'search-results.item.tradingAddress': 'Found in trading address: {value}',
  'search-results.item.TSR': 'TSR',
  'search-results.item.type.BRANCH_OFFICE': 'B',
  'search-results.item.type.ROOT_ORG': 'HQ',
  'search-results.item.type.tooltip.BRANCH_OFFICE': 'Branch',
  'search-results.item.type.tooltip.ROOT_ORG': 'Headquarter',
  'search-results.item.Unique_Company_Number': 'Unique Company Number',
  'search-results.item.Unknown': 'Unknown',
  'search-results.item.VAT': 'VAT number',
  'search-results.item.vatNumber': 'Found in VAT number: {value}',
  'search-results.item.Web_Address': 'Web Address',
  'search.advanced.clear': 'Clear',
  'search.advanced.close': 'Close',
  'search.advanced.heading': 'Advanced search',
  'search.advanced.open': 'Advanced search',
  'search.advanced.subheading': 'You can use multiple fields',
  'search.autocomplete.all': 'show all {total} results',
  'search.autocomplete.less': 'Keep typing or…',
  'search.autocomplete.max': 'show me the first {total} results',
  'search.companies.found': '{total} {total, plural, =1 {company} other {companies}} found',
  'search.error': "Oops! We couldn't perform your search. Please try again.",
  'search.heading': 'Search for a company',
  'search.heading.subtext': 'Check if the company meets your acceptance criteria',
  'search.in': 'Choose country',
  'search.name.obsolete': 'Found in previous company name {name}',
  'search.no.results': 'No results found',
  'search.no.results.check-spelling': 'Check the spelling',
  'search.no.results.check-the-following':
    'No results have been found. Please check on the following if applicable',
  'search.no.results.for': 'No results found for "{query}"',
  'search.no.results.identifier': 'Check any entered identifier (e.g. VAT number)',
  'search.no.results.refine-search': 'Refine your search by adding further information',
  'search.no.results.refine-search.extended':
    'Refine your search by adding further information using the advanced search',
  'search.placeholder':
    'Enter company name, keyword, Companies House number or VAT number (including the prefix GB and without space between the digits).',
  'search.placeholder.be': 'Enter company name, keyword, business number or VAT number',
  'search.placeholder.gb': 'Enter company name, keyword, Companies House number or VAT number',
  'search.placeholder.long': 'Enter company name, keyword, Companies House number or VAT number',
  'search.placeholder.nl':
    'Enter company name, keyword, Chamber of Commerce number (kvk) or VAT number',
  'search.results.found': '{total} {total, plural, =1 {result} other {results}} found.',
  'search.tooltip.country': 'Search for companies in {country}',
  'search.tradingName': 'Found in trading name {name}',
  'session.expired.close': 'OK',
  'session.expired.description': 'Your session has grown invalid',
  'share.check-result': 'Share this result',
  'share.creditcheck.body':
    "Hi, I've run this Credit Report {url}. Please can you take a look at it?",
  'share.creditcheck.subject': 'A Credit Report needs your approval',
  'shr.archive.subtitle':
    'Archive view for all requests made from your account to the Graydon system.',
  'shr.archive.table.address': 'Address',
  'shr.archive.table.country': 'Country',
  'shr.archive.table.date': 'Date ordered',
  'shr.archive.table.download': 'Download Report',
  'shr.archive.table.download.en': 'English PDF',
  'shr.archive.table.download.fr': 'French PDF',
  'shr.archive.table.download.nl': 'Dutch PDF',
  'shr.archive.table.person-name': 'Person name',
  'shr.archive.table.report': 'Report',
  'shr.archive.title': 'Archive',
  'shr.pdf.footer':
    '{personName} - This Graydon Stakeholder Report is intended for the exclusive use of: {requestorCustomer} - {requestorName}',
  'shr.report.archive.tooltip':
    'Here you will find all previously consulted stakeholder reports, sorted by date of application. You can download the reports again in one of the three available languages.',
  'shr.report.company-overview.insolvency.active': 'Active insolvency',
  'shr.report.company-overview.insolvency.inactive': 'No objection',
  'shr.report.company-overview.row.city': 'City',
  'shr.report.company-overview.row.company': 'Company',
  'shr.report.company-overview.row.company-address': 'Company address',
  'shr.report.company-overview.row.company-number': 'Company number',
  'shr.report.company-overview.row.date': 'Start and End date',
  'shr.report.company-overview.row.insolvency': 'Insolvency',
  'shr.report.company-overview.row.position': 'Position',
  'shr.report.company-overview.title': 'Company overview ({positions} positions)',
  'shr.report.company-overview.tooltip':
    'This report shows all active and inactive positions of the stakeholders. The positions are sorted by date. You can consult a report on the different organisations if this is activated within your Graydon contract.',
  'shr.search.add-birth-date': 'Add date of birth',
  'shr.search.date-invalid': 'Please fill in a correct date',
  'shr.search.date-month-and-year': 'Please fill in a month and a year',
  'shr.search.dateOfBirth.day': 'Day',
  'shr.search.dateOfBirth.month': 'Month*',
  'shr.search.dateOfBirth.year': 'Year*',
  'shr.search.input.placeholder':
    'Enter the first and/or last name of the person you are looking for',
  'shr.search.result.address': 'Address',
  'shr.search.result.birthDate': 'Date of birth',
  'shr.search.result.personId': 'Person id',
  'shr.search.result.position': '**{role}** at **{company}**',
  'shr.search.result.request-report': 'Request a report',
  'shr.search.result.total': '{total} results found',
  'shr.search.subtitle':
    'Please add the name of the stakeholder you are searching for. You can use the birth date if needed.',
  'shr.search.title': 'Search for stakeholder information',
  'ubo.report.bulkComplianceCheck.complete.body': 'See compliance checks details below.',
  'ubo.report.bulkComplianceCheck.complete.heading': 'Report has been updated',
  'ubo.report.bulkComplianceCheck.loading.body':
    'A new report including all compliance check data is being generated. You will find in the [Company Archive](/company-archive/organisation/{enterpriseId}) once it is ready to download.',
  'ubo.report.bulkComplianceCheck.loading.heading': 'Up-to-date report is being generated',
  'ubo.report.compliance.check.modal.cancel': 'Cancel',
  'ubo.report.compliance.check.modal.description':
    'Add usual first name (if known) for better compliance check results.',
  'ubo.report.compliance.check.modal.input-label': 'Usual first name (optional)',
  'ubo.report.compliance.check.modal.name': 'Name',
  'ubo.report.compliance.check.modal.submit': 'Compliance check',
  'ubo.report.compliance.check.modal.title': 'Person compliance check',
  'ubo.report.complianceCheck.request.bulk': 'Perform all compliance checks',
  'ubo.report.complianceCheck.request.bulk.all': 'Check all UBOs and directors',
  'ubo.report.complianceCheck.request.bulk.companies': 'Check all companies',
  'ubo.report.complianceCheck.request.bulk.ubo': 'Check all UBOs',
  'ubo.report.complianceCheck.request.info':
    "You can perform a compliance check for all persons or all companies at once or for each UBO, director or company separately.'",
  'ubo.report.complianceCheck.usage.report.download': 'Compliance check usage data (.xlsx)',
  'ubo.report.directors.title': 'Directors (persons only)',
  'ubo.report.downloadPdf': 'Download report',
  'ubo.report.downloadPdf.en-GB': 'English PDF',
  'ubo.report.downloadPdf.fr': 'French PDF',
  'ubo.report.downloadPdf.nl-NL': 'Dutch PDF',
  'ubo.report.heading': 'UBO Report',
  'ubo.report.requestedDate': 'Date',
  'ubo.report.requester': 'Requester of report',
  'ubo.report.summary.explanation':
    'The UBO search maps all Ultimate Beneficial Owners in the group structure of the requested company. Each branch in the structure is a path to the highest entity. In addition, all directors of the requested company are shown.',
  'ubo.report.summary.heading': 'UBO summary',
  'ubo.report.summary.hint':
    'The results that one can come about in a path are as follows:\n* An __UBO has been found__ in this path. In this case the found UBO and the path that lead to this person is shown.\n* An __UBO can not be found__ in this path. In this case the highest found director and the path leading to it is shown.\n* There is __insufficient data to determine an UBO__ in this path. In this case we were unable to find a person and only the path to the result is shown.\n* The path is __opaque / complex__. If an entity appears in the same path more than once, a circular reference is created. The path will be shown. Further investigation is recommended.\n',
  'ubo.report.summary.numberOf.companies':
    '{numberOfCompanies, plural, =0 {no companies} one {# unique company} other {# unique companies}}',
  'ubo.report.summary.numberOf.directors':
    '{numberOfDirectors, plural, =0 {no directors} one {# director} other {# directors}}',
  'ubo.report.summary.numberOf.paths':
    '{numberOfSearchPaths, plural, =0 {no paths} one {1 path} other {{numberOfSearchPaths} paths}}',
  'ubo.report.summary.numberOf.ubos':
    '{numberOfUbos, plural, =0 {no UBOs} one {# (possible) UBO} other {# (possible) UBOs}}',
  'ubo.report.summary.results': 'Results',
  'ubo.report.ubo.search.result.compliance.hint.company':
    'A compliance check for a company always consists of 3 checks:\n* __Sanction list__: The entity is included on one or more of many official sanction lists maintained by governments world-wide.\n* __Adverse Media__: WorldCompliance(TM) monitors newspapers in more than 15 languages for information relating to illicit activities, such as money laundering, fraud, drug trafficking, terrorism, corruption to name but a few. \n* __State-Owned Enterprise__: A business enterprise where the government or state has significant control through full, majority, or significant minority ownership.\n\nThere are three possible results:\n* __NO MATCH__: The company in question is not put forward in any way in performing the compliance check. \n* __EXACT MATCH__: The company emerges in one or more components of the Compliance Check. The name, the country and year of birth of the person correspond with the data on one of the registrations.\n* __POSSIBLE MATCH__: The company emerges in one or more components of the Compliance Check. The name and country match but the year of birth can not be matched with the data on one of the registrations. ',
  'ubo.report.ubo.search.result.compliance.hint.person':
    'A compliance check for a person always consists of 3 checks: \n* __Politically Exposed Person (PEP)__: the term generally includes a current or former senior foreign political figure, their immediate family, and their close associates.\n* __Sanction list__: The entity is included on one or more of many official sanction lists maintained by governments world-wide.\n* __Adverse Media__: WorldCompliance(TM) monitors newspapers in more than 15 languages for information relating to illicit activities, such as money laundering, fraud, drug trafficking, terrorism, corruption to name but a few. \n\nThere are three possible results:\n* __NO MATCH__: The person in question is not put forward in any way in performing the compliance check. \n* __EXACT MATCH__: The person emerges in one or more components of the Compliance Check. The name, the country and year of birth of the person correspond with the data on one of the registrations.\n* __POSSIBLE MATCH__: The person emerges in one or more components of the Compliance Check. The name and country match but the year of birth can not be matched with the data on one of the registrations. ',
  'ubo.report.ubo.search.result.hint':
    '# Level\nThe highest level in the depicted link. Every element in the group structure counts as a level.\n\n# Result type\nThe role of the person found in the highest entity in the depicted linkage.\n\n# Ultimate Share in requested company        \nThe ultimate share (% shares) the identified person holds in the requested company.\n\n# Job title and authorisation        \nPosition and authorisation of person at the highest entity identified in this structure.',
  'ubo.report.ubo.search.result.path.hint':
    '# NCCT/FATF check\nNon-Cooperative Countries and Territories, abbreviated: NCCT. A list published by the Financial Action Task Force (FATF) of countries and territories not participating in the prevention of money laundering\n\n# Ultimate share in requested company\nThe share percentage held by the company at that level for the requested company',
  'ubo.report.uboSearch.result': 'UBO search path',
  'ubo.report.uboSearch.result.authorisation': 'Authorisation',
  'ubo.report.uboSearch.result.collapsePath': 'Hide path',
  'ubo.report.uboSearch.result.compliance.button.text': 'Compliance check',
  'ubo.report.uboSearch.result.compliance.checkType': 'Check type',
  'ubo.report.uboSearch.result.compliance.date': 'Date of compliance check',
  'ubo.report.uboSearch.result.compliance.dateofbirth': 'Date of birth',
  'ubo.report.uboSearch.result.compliance.details': 'Details',
  'ubo.report.uboSearch.result.compliance.explanation':
    'A compliance check for a person always consists of 3 checks: a check if the person is prominently active in politics (Politically Exposed Person or PEP Check), a check if the person is on a sanction list and a check if there is negative media reporting around the person (Adverse Media Check)',
  'ubo.report.uboSearch.result.compliance.explanation.company':
    'A compliance check for a company always consists of 3 checks: a check if the company is on a sanction list, a check if there is negative media reporting around the company (Adverse Media Check) and a check if the company is state-owned (State-Owned Enterprise).',
  'ubo.report.uboSearch.result.compliance.firstname': 'First name',
  'ubo.report.uboSearch.result.compliance.gender': 'Gender',
  'ubo.report.uboSearch.result.compliance.gender.f': 'Female',
  'ubo.report.uboSearch.result.compliance.gender.m': 'Male',
  'ubo.report.uboSearch.result.compliance.heading':
    'Compliance check {checkType, select, person {(person)} company {(company)} other {} }',
  'ubo.report.uboSearch.result.compliance.key': 'Search key',
  'ubo.report.uboSearch.result.compliance.lastname': 'Last name',
  'ubo.report.uboSearch.result.compliance.links': 'Links',
  'ubo.report.uboSearch.result.compliance.media': 'Adverse Media check',
  'ubo.report.uboSearch.result.compliance.noresult': 'No information found',
  'ubo.report.uboSearch.result.compliance.pep': 'PEP check',
  'ubo.report.uboSearch.result.compliance.politicallevel': 'Political level',
  'ubo.report.uboSearch.result.compliance.position': 'Position',
  'ubo.report.uboSearch.result.compliance.reasonListed': 'Reason listed',
  'ubo.report.uboSearch.result.compliance.result':
    '{status, select, NO_MATCH {No information found} other {Information found}}',
  'ubo.report.uboSearch.result.compliance.sanction': 'Sanction list check',
  'ubo.report.uboSearch.result.compliance.soe': 'State-Owned Enterprise check',
  'ubo.report.uboSearch.result.compliance.source': 'Source of the entry',
  'ubo.report.uboSearch.result.compliance.status': 'Matching status',
  'ubo.report.uboSearch.result.compliance.subcategory': 'Subcategory',
  'ubo.report.uboSearch.result.compliance.toast.body':
    'No data could be retrieved for the compliance check about this person',
  'ubo.report.uboSearch.result.compliance.toast.title': 'No data retrieved',
  'ubo.report.uboSearch.result.dateOfBirth': 'Date of birth',
  'ubo.report.uboSearch.result.expandPath': 'Show path',
  'ubo.report.uboSearch.result.jobTitle': 'Job title',
  'ubo.report.uboSearch.result.level': 'at level {level}',
  'ubo.report.uboSearch.result.name': 'Name',
  'ubo.report.uboSearch.result.path': 'Path',
  'ubo.report.uboSearch.result.path.companyName': 'Company name',
  'ubo.report.uboSearch.result.path.country': 'Country',
  'ubo.report.uboSearch.result.path.directShares': 'Direct share in an underlying company',
  'ubo.report.uboSearch.result.path.level': 'Level',
  'ubo.report.uboSearch.result.path.NCCT_FATF_CountryCheck': 'NCCT/FATF Check',
  'ubo.report.uboSearch.result.path.NCCT_FATF_CountryCheck.false':
    'This country is not on the current NCCT list maintained by the FATF',
  'ubo.report.uboSearch.result.path.NCCT_FATF_CountryCheck.true':
    'This country shows on the current NCCT list maintained by the FATF',
  'ubo.report.uboSearch.result.path.ultimateShares': 'Ultimate share in company applied for',
  'ubo.report.uboSearch.result.path.unknownRelationship': 'Unknown relationship',
  'ubo.report.uboSearch.result.placeOfBirth': 'Place of birth',
  'ubo.report.uboSearch.result.status.NO-DATA': 'Unsufficient data available to determine UBO ',
  'ubo.report.uboSearch.result.status.NO-UBO': 'No UBO found',
  'ubo.report.uboSearch.result.status.NO-UBO_BANKRUPTCY':
    'No UBO has been identified due to bankruptcy of the company',
  'ubo.report.uboSearch.result.status.NO-UBO_COMPLEX':
    'No UBO has been identified due to complexity of the company structure and/or the UBO search resulted in multiple different search results ',
  'ubo.report.uboSearch.result.status.NO-UBO_DISSOLUTION':
    'No UBO has been identified, requested company has been dissolved',
  'ubo.report.uboSearch.result.status.NO-UBO_DO_INQUIRY':
    'No UBO has been identified based on shareholder data',
  'ubo.report.uboSearch.result.status.NO-UBO_FOREIGN_COMPANY':
    'No UBO has been identified, the search path ended in a foreign company',
  'ubo.report.uboSearch.result.status.NO-UBO_MORATORIUM':
    'Payment suspension is applicable for requested company',
  'ubo.report.uboSearch.result.status.POSSIBLE-UBO': 'Possible UBO found',
  'ubo.report.uboSearch.result.status.POSSIBLE-UBO-SEARCH-RESULT':
    'A possible UBO has been found and is returned in the search result',
  'ubo.report.uboSearch.result.status.UBO': 'UBO found',
  'ubo.report.uboSearch.result.status.UBO-SEARCH-RESULT':
    'A definitive UBO has been found and is returned in the search result',
  'ubo.report.uboSearch.result.type': 'Result type',
  'ubo.report.uboSearch.result.type.AUTHORIZED PROXY': 'Authorized proxy',
  'ubo.report.uboSearch.result.type.PROXY': 'Proxy',
  'ubo.report.uboSearch.result.type.MANAGER': 'Manager',
  'ubo.report.uboSearch.result.type.SHAREHOLDER': 'Shareholder',
  'ubo.report.uboSearch.result.type.UBO': 'UBO',
  'ubo.report.uboSearch.result.type.PSEUDO UBO': 'Pseudo UBO',
  'ubo.report.uboSearch.result.type.STATUTORY MANAGER': 'Statutory manager',
  'ubo.report.usage.report.download': 'UBO usage data (.xlsx)',
  'ubo.request.foreign.company.error.message': 'Please try again later or',
  'ubo.request.foreign.company.error.message.link': 'contact Graydon',
  'ubo.request.foreign.company.error.title': 'Request could not be sent',
  'ubo.request.foreign.company.modal.button.text': 'Request investigation',
  'ubo.request.foreign.company.modal.description':
    'Please confirm that you want Graydon to investigate this company for UBOs and compliance (EUR 35). You will receive the results by email within a few days.',
  'ubo.request.foreign.company.success.message':
    'You will receive the results by mail in a few days.',
  'ubo.request.foreign.company.success.title': 'Request has been sent',
  'ubo.request.foreign.company.title': 'Request company investigation',
  'ubo.requestUboReport': 'UBO Report',
  'um.authorization-title': 'Unauthorized access',
  'um.cancel': 'Cancel',
  'um.createListPopup.success': 'List has been created',
  'um.createListPopup.success.text': 'The company list that you have created has been selected',
  'um.no-authorization': 'You are unauthorized to access this page',

  'unit.months': '{value} month(s)',
  'unknown.address': 'Unknown address',
  'uploading.downloadExample': 'See example company file:',
  'uploading.dropzone.browse': 'browse',
  'uploading.dropzone.chooseAdifferentFile': 'Or choose a different file',
  'uploading.dropzone.disclaimer':
    'Your data is safe. Graydon will never share it with third parties.',
  'uploading.dropzone.dropFile': 'Drop your file here to upload',
  'uploading.dropzone.selectNew': 'Select a new file',
  'uploading.dropzone.submitButton': 'Upload list',
  'uploading.dropzone.tip': 'Maximum file size is 1 GB. Files should be .xlsx',
  'uploading.dropzone.title': 'Drop file to attach or ',
  'uploading.dropzone.warning1':
    'Oops! We do not support that file type. Use .xlsx or .csv instead.',
  'uploading.dropzone.warning2': 'Oops! The file is too big. Supported are only sizes below 1 GB.',
  'uploading.error.button': 'Try again',
  'uploading.error.message': 'This is our fault. If this keeps occuring, contact customer service.',
  'uploading.errorPage.heading': 'Something went wrong...',
  'uploading.header': 'Import companies from ',
  'uploading.loadingPage.heading': 'Your data is being prepared.',
  'uploading.point0': 'The file must be in .xlsx or .csv format',
  'uploading.point1': 'Headers are present in the first row or line',
  'uploading.point2': 'Match rate can be improved if (at least) the following data are provided:',
  'uploading.point2a.be': 'Business unit numbers',
  'uploading.point2a.gb': 'Company registration numbers',
  'uploading.point2a.nl': 'Chamber of Commerce numbers',
  'uploading.point2b': 'Company names and full addresses',
  'uploading.point3':
    'If you want to import the company data in your CRM again, a reference is important',
  'uploading.step1': 'Choose country to import companies from',
  'uploading.subHeader': 'Check the file you upload on the following points:',
  'user.bar.language': 'Language',
  'user.greeting': 'Hi {user}',
  'user.management': 'User Management',
  'user.roles.delivery_managers': 'Administrator',
  'user.roles.delivery_users': 'User',
  'widgets.acceptance.grade.title': 'Acceptance grade',
  'widgets.area.acceptance.grade.title': 'Acceptance grade',
  'widgets.avg.order.value.title': 'Average order value',
  'widgets.consumption.title': 'International Business consumption',
  'widgets.total.orders.title': 'Total amount of checks',
  'xs.affiliations.directors.birthDate': 'Date of birth',
  'xs.affiliations.directors.birthPlace': 'Place of birth',
  'xs.company-details.more-xseptions': '+ {count} more XSeption(s)',
  'xs.company-details.title': 'Requested company',
  'xs.company-relations.description':
    'XSeptions screens first and second-degree relationships (companies and/or persons) for remarkable situations. This includes mention on sanction lists, opaque company structures or recent bankruptcies of the director.',
  'xs.company-relations.no-xseptions': 'No XSeptions found',
  'xs.company-relations.person.address': 'Address',
  'xs.company-relations.person.name': 'Name',
  'xs.company-relations.present': 'Present',
  'xs.company-relations.relation.dates': 'Start and end date',
  'xs.company-relations.relation.level.1': '1st degree',
  'xs.company-relations.relation.level.2': '2nd degree',
  'xs.company-relations.title': 'Found relations',
  'xs.company-relations.unknown': 'Unknown',
  'xs.company-relations.unknown-company': 'Unknown company',
  'xs.company-relations.unknown-person': 'Unknown person',
  'xs.filters.title': 'Filters',
  'xs.filters.toggle.label': 'Show list view',
  'xs.filters.tooltip':
    'This network shows the requested company and the 1st and 2nd degree related companies (active or inactive) and persons. ',
  'xs.graph.hover.xseptions.title': 'XSeption(s)',
  'xs.graph.loading.display': 'Display now',
  'xs.graph.loading.message': 'Network is displayed.\n\nPlease wait to speed up the rendering.',
  'xs.pdf.footer': 'Network incl. XSeptions - {companyName} - {requestorName}',
  'xs.person.details.title': 'Personal data',
  'xs.preferences.priority.level.active': 'Activate Xseption priority levels',
  'xs.preferences.priority.level.cancel': 'Cancel',
  'xs.preferences.priority.level.high': 'High',
  'xs.preferences.priority.level.intro':
    'Activate and specify XSeptions priority levels per country below. Click Save to store your preferences.',
  'xs.preferences.priority.level.low': 'Low',
  'xs.preferences.priority.level.medium': 'Medium',
  'xs.preferences.priority.level.save': 'Save Xseptions priority preferences',
  'xs.preferences.priority.level.title': 'XSeptions priority levels',
  'xs.preferences.priority.level.tooltip':
    'Some XSeption types might be more or less relevant for your situation. If you activate XSeption priority levels the priority per XSeption type will be indicated in the XSeption report network so you can focus on relevant exceptional events.',
  'xs.preferences.success.link': 'Go to Company search',
  'xs.preferences.success.message':
    'The preferences for XSeptions priority levels have been updated.',
  'xs.relation-type.ESC':
    '{entityA} **has** {entityB} **as a person with significant control** {dateRange}',
  'xs.relation-type.ESC.past':
    '{entityA} **had** {entityB} **as a person with significant control** {dateRange}',
  'xs.relation-type.HASSHL':
    '{entityA} **has** {entityB} **as shareholder {shareDetails}** {dateRange}',
  'xs.relation-type.HASSHL.past':
    '{entityA} **had** {entityB} **as shareholder {shareDetails}** {dateRange}',
  'xs.relation-type.ISSHL':
    '{entityA} **is a shareholder {shareDetails} of** {entityB} {dateRange}',
  'xs.relation-type.ISSHL.past':
    '{entityA} **was a shareholder {shareDetails} of** {entityB} {dateRange}',
  'xs.relation-type.LBSBY':
    '{entityA} **received a declaration of liability by** {entityB} {dateRange}',
  'xs.relation-type.LBSBY.past':
    '{entityA} **received a declaration of liability by** {entityB} {dateRange}',
  'xs.relation-type.LBSFOR':
    '{entityA} **filed a declaration of liability for** {entityB} {dateRange}',
  'xs.relation-type.LBSFOR.past':
    '{entityA} **filed a declaration of liability for** {entityB} {dateRange}',
  'xs.relation-type.MANBY': '{entityA} **has** {entityB} **as {position}** {dateRange}',
  'xs.relation-type.MANBY.past': '{entityA} **had** {entityB} **as {position}** {dateRange}',
  'xs.relation-type.MANFOR': '{entityA} **is {position} of** {entityB} {dateRange}',
  'xs.relation-type.MANFOR.past': '{entityA} **was {position} of** {entityB} {dateRange}',
  'xs.relation-type.PAR': '{entityA} **has** {entityB} **as parent company** {dateRange}',
  'xs.relation-type.PAR.past': '{entityA} **had** {entityB} **as parent company** {dateRange}',
  'xs.relation-type.SUB': '{entityA} **has** {entityB} **as a subsidiary** {dateRange}',
  'xs.relation-type.SUB.past': '{entityA} **had** {entityB} **as a subsidiary** {dateRange}',
  'xs.relation-type.UP': '{entityA} **has** {entityB} **as ultimate parent company** {dateRange}',
  'xs.relation-type.UP.past':
    '{entityA} **had** {entityB} **as ultimate parent company** {dateRange}',
  'xs.report.download-pdf': 'Download report',
  'xs.report.download-pdf.en-GB': 'English PDF',
  'xs.report.download-pdf.fr': 'French PDF',
  'xs.report.download-pdf.nl-NL': 'Dutch PDF',
  'xs.report.download-xls.en-GB': 'English Excel',
  'xs.report.download-xls.fr': 'French Excel',
  'xs.report.download-xls.nl-NL': 'Dutch Excel',
  'xs.report.download.content.nodeType.organisation': 'Organisation',
  'xs.report.download.content.nodeType.person': 'Person',
  'xs.report.download.headers.address': 'Address',
  'xs.report.download.headers.dateOfBirth': 'Date of Birth',
  'xs.report.download.headers.dissolvedDate': 'Dissolved Date',
  'xs.report.download.headers.endDate': 'End Date',
  'xs.report.download.headers.graydonNumber': 'Graydon Number',
  'xs.report.download.headers.graydonNumOfRelated': 'Graydon Number Of Related',
  'xs.report.download.headers.name': 'Name',
  'xs.report.download.headers.nodeType': 'Company Or Person',
  'xs.report.download.headers.placeOfBirth': 'Place of Birth',
  'xs.report.download.headers.registrationId': 'Official Registration Id',
  'xs.report.download.headers.relationOf': 'Relation Of',
  'xs.report.download.headers.relationType': 'Relation Type',
  'xs.report.download.headers.startDate': 'Start Date',
  'xs.report.heading': 'Network incl. XSeptions',
  'xs.report.usage.download': 'Network incl. XSeptions usage data (.xlsx)',
  'xs.xseptions.risk-priority': 'Priority',
  'xs.xseptions.risk-priority.H': 'High',
  'xs.xseptions.risk-priority.L': 'Low',
  'xs.xseptions.risk-priority.M': 'Medium',
  'xs.xseptions.risk-priority.NA': 'N/A',
  'general.help-center.title': 'Graydon Help Centre',
  'general.help-center.desc':
    'Learn how to get more out of Graydon Insights and get inspired! We bundled our tips & tricks, tutorial videos and more.',
  'general.help-center.link': 'Visit the Help Centre',
  'dbm.create-profile.deliver-complete-columns-removal': 'Removed record format',
  'dbm.create-profile.deliver-complete-columns-removal.tooltip':
    'Choose the format of removed data in the data file. Empty columns will be added for redundant fields to use the same format as for other records.',
  'dbm.create-profile.deliver-complete-columns-removal.label.short':
    'Use short format for removals',
  'dbm.create-profile.deliver-complete-columns-removal.label.complete':
    'Use same format for removals as for other records',
  'mi.isIncludeInIndexFlag': 'Only companies with complete and unique data',
  'mi.isIncludeInIndexFlag.heading': 'Clean up company selection ',
  'mi.isIncludeInIndexFlag.tooltip':
    "By default companies that meet the following criteria are removed from the selection:\n\n1. The company's name is missing\n2. The company's province is missing\n3. Branch companies that have the same address as the headquarter\n4. The official registration number is located outside The Netherlands\n5. Inactive companies\n\nUncheck this criteria to select more companies.",
  'dbm.create-profile.data-blocks.DBM_ADDRESS_OLD': 'Addresses old',
  'dbm.create-profile.data-blocks.DBM_ADDRESS_OLD.tooltip': '-',
  'dbm.create-profile.data-blocks.DBM_ORGANIZATION_NAME_OLD': 'Organization names old',
  'dbm.create-profile.data-blocks.DBM_ORGANIZATION_NAME_OLD.tooltip': '-',
  'dbm.documentation.block-description.DBM_ADDRESS_OLD-NL': '-',
  'dbm.documentation.block-description.DBM_ORGANIZATION_NAME_OLD-NL': '-',
};
