import { combineReducers } from 'redux';
import { searchPageReducer } from './searchPage';
import searchCountry from '../components/CountrySelect/reducer';
import searchInput from '../components/SearchInput/reducer';
import searchResults from '../components/SearchResults/reducer';
import searchMethod from '../components/SearchMethod/reducer';
import archive from '../pages/ArchivePage/Orders/reducer';
import internationalCreditReports from './internationalCreditReports';
import { clientSession, clientView } from '../shared/reducers/clientSession';
import selectedCountryState from '../components/StateSelect/reducer';
import { investigatedReportReducer } from './investigatedReport';

export default combineReducers({
  countriesList: searchPageReducer,
  searchCountry,
  searchInput,
  searchMethod,
  searchResults,
  archive,
  internationalCreditReports,
  selectedCountryState,
  consumerCountriesList: investigatedReportReducer,
  clientSession,
  clientView,
});
