// @ts-nocheck
import { injectI18n } from 'src/i18n';
import * as React from 'react';
import { ContextRouter } from 'react-router-dom';
import Breadcrumb from '../Breadcrumb';
import { funcType } from '../OneSearch/types';
import PreferencesLink from '../PreferencesLink';
import {
  hasAccessToCreditReports,
  hasAccessToXseptionReport,
  isDeliveryAdminUser,
  isDeliveryManager,
} from '../../keycloak';
import { I18nProp } from 'src/i18n';
import {
  ResultWrapper,
  InnerWrapper,
  PageWrapper,
  HeaderWrapper,
  SearchForm,
  SearchFormWrapper,
} from './helper-components';
import SwitchClientView from '../../apps/InternationalCreditReports/components/SwitchClientView';
import { useSessionStorage } from '../../hooks/useLocalStorage';
import ClientSession from '../../components/ClientSession';
import ClientValidation from '../../apps/InternationalCreditReports/pages/SearchPage/Components/ClientValidation';
const CLIENT_SESSION__NO = 0;
const CLIENT_SESSION__VALIDATING = 1;
const CLIENT_SESSION__STARTED = 2;
type Props = {
  actions?: any;
  countryList: any;
  children: any;
  submit: funcType;
  page: 'CR' | 'ICR';
  hideArchiveLink?: boolean;
  error?: boolean;
};
export function BasePage({
  i18n,
  actions,
  countryList,
  children,
  submit,
  page,
  hideArchiveLink,
  error,
}: Props & I18nProp & ContextRouter) {
  const [clientSession, setClientSession] = useSessionStorage('client-session');

  const getClientStatus = (session: any) => {
    if (!session) return CLIENT_SESSION__NO;
    if (session && !session.consumerId) return CLIENT_SESSION__VALIDATING;
    return CLIENT_SESSION__STARTED;
  };

  const clientStatus = getClientStatus(clientSession);

  const stopSession = () => {
    setClientSession(null);
    window.history.go(0);
  };

  const startSession = (client: any) => {
    setClientSession(client);
  };

  return (
    <PageWrapper>
      <InnerWrapper>
        <HeaderWrapper>
          <Breadcrumb
            links={[
              {
                url: '/',
                text: i18n.text('breadcrumb.home'),
              },
              {
                url: null,
                text: i18n.text('breadcrumb.company-search'),
              },
            ]}
          />
          {isDeliveryAdminUser() && clientStatus !== CLIENT_SESSION__STARTED && (
            <SwitchClientView
              stop={stopSession}
              start={startSession}
              isEnabled={clientStatus !== CLIENT_SESSION__NO}
            />
          )}
          {actions}
          {isDeliveryManager() && (hasAccessToCreditReports() || hasAccessToXseptionReport()) && (
            <PreferencesLink
              i18n={i18n}
              to="/company-data/preferences"
              title={i18n.text('cr.preferences')}
            />
          )}
        </HeaderWrapper>
      </InnerWrapper>
      <SearchFormWrapper>
        {clientStatus === CLIENT_SESSION__VALIDATING && (
          <ClientValidation startSession={startSession} />
        )}

        {clientStatus === CLIENT_SESSION__STARTED && (
          <ClientSession clientSession={clientSession} stopSession={stopSession} />
        )}

        {clientStatus !== CLIENT_SESSION__VALIDATING && (
          <SearchForm
            error={error}
            countryList={countryList}
            submit={submit}
            consumerId={clientSession && clientSession.consumerId}
            page={page}
            hideArchiveLink={hideArchiveLink}
          />
        )}
      </SearchFormWrapper>

      {clientStatus !== CLIENT_SESSION__VALIDATING && (
        <ResultWrapper>
          <InnerWrapper>{children}</InnerWrapper>
        </ResultWrapper>
      )}
    </PageWrapper>
  );
}
export default injectI18n(BasePage); // as React.StatelessFunctionalComponent<Props>
