import get from 'lodash/get';
export const extractPaymentAveragesData = (data: any) => {
  const paymentPerformance = get(data, 'paymentBehaviourProfile.paymentPerformance');
  const availableData = {
    mtdAvgDbt: get(paymentPerformance, 'mtdAvgDbt'),
    avgDbtLast3Months: get(paymentPerformance, 'avgDbtLast3Months'),
    avgDbtLast6Months: get(paymentPerformance, 'avgDbtLast6Months'),
    avgDbtLast12Months: get(paymentPerformance, 'avgDbtLast12Months'),
    industryAvgDbtLast3Months: get(paymentPerformance, 'industryAvgDbtLast3Months'),
    industryAvgDbtLast6Months: get(paymentPerformance, 'industryAvgDbtLast6Months'),
    industryAvgDbtLastMonths12: get(paymentPerformance, 'industryAvgDbtLastMonths12'),
  };
  const noDataAvailable = [availableData].find(
    (d) =>
      d.mtdAvgDbt === undefined &&
      d.avgDbtLast3Months === undefined &&
      d.avgDbtLast6Months === undefined &&
      d.avgDbtLast12Months === undefined &&
      d.industryAvgDbtLast3Months === undefined &&
      d.industryAvgDbtLast6Months === undefined &&
      d.industryAvgDbtLastMonths12 === undefined,
  );
  return { ...availableData, noDataAvailable: !!noDataAvailable };
};
