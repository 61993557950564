// General purpose constants
export const ENTER_KEY_CODE = 13;
export const COUNTRY_CODES = ['be', 'nl'];
export const DEFAULT_SEARCH_COUNTRY_KEY = 'preferredSearchCountry';
export const EXTRA_HIGHLIGHTED_FIELDS = [
  'tradeNames',
  'vatNumber',
  'contact.email',
  'contact.telephoneNumber',
  'gbYCompanyId',
  'previousCompanyName',
  'previousRegisteredAddress',
  'tradingAddress',
];
