import styled from 'styled-components';
import * as colors from '@graydon/ui-colors';
import { sans } from '../../design-system/constants/fonts';
import { NoPrint } from '../BasePage/helper-components';

export const Footer = styled.footer<{
  bgColor?: string;
}>`
  display: flex;
  width: 100%;
  color: ${colors.gray};
  background-color: ${({ bgColor }) => bgColor || colors.grayLight5};

  ${NoPrint};
`;

export const FooterWrapperMain = styled.div`
  display: flex;
  margin: 0 auto;
  width: 1400px;
  border-top: 1px solid ${colors.grayLight4};
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  height: 60px;

  @media (max-width: 768px) {
    flex-wrap: wrap;
    height: 82px;
    }
  }
`;

export const FooterList = styled.ul`
  list-style: none;
  font: 300 1rem ${sans};
  color: ${colors.gray};
  margin: 0;
  padding: 0 8px 0 0;
`;

export const ListItem = styled.li`
  display: inline;
  margin: 0 8px 0 0;
  text-decoration: underline;
`;

export const FooterText = styled.div`
  @media (max-width: 768px) {
    padding: 8px 0 0 0;
    width: 100%;
  }
`;
