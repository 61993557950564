import React from 'react';
import styled from 'styled-components';

const ImgBtn = styled.img<{
  size: number;
  margin: string;
}>`
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  margin: ${({ margin }) => margin};
  cursor: pointer;
`;

type IconProps = {
  icon?: string;
  margin?: string;
  size?: number;
  onClick?: () => void;
};

const Icon = ({ icon, size = 24, margin = '0', onClick }: IconProps) => (
  <ImgBtn src={icon} size={size} margin={margin} onClick={onClick} />
);

export default Icon;
