// @ts-nocheck
import React from 'react';
import { sendEvent } from '../../utils/analytics';
import { processStates } from '../../utils/processFlow';
import CompanyMonitoringLists from '../CompanyMonitoringLists';
import ReportFetchingModal from '../CRModals/ReportFetchingModal';
import ReportErrorModalComponent from '../CRModals/ReportErrorModal/component';
import { CompanyData } from './index';
import CocModal from '../CocModal';
import AnnualAccountModal from '../AnnualAccountModal';
import DecisionModel from '../DecisionModel';
type Props = {
  process: number;
  monitoringModal: any;
  setMonitoringModal: any;
  cocModal: any;
  setCocModal: any;
  gdmModal: any;
  annualAccountModal: any;
  setAnnualAccountModal: any;
  setGdmModal: any;
  context: string;
  app: string;
  company: CompanyData;
  reset: any;
};
export const ReportModals = ({
  process,
  monitoringModal,
  setMonitoringModal,
  cocModal,
  setCocModal,
  gdmModal,
  setGdmModal,
  company,
  app,
  context,
  reset,
  annualAccountModal,
  setAnnualAccountModal,
}: Props) => {
  const { enterpriseId, countryCode, cocNumber, graydonNumber } = company;
  return (
    <>
      {process === processStates.started && <ReportFetchingModal />}

      {process === processStates.failed && <ReportErrorModalComponent onClose={reset} />}

      {monitoringModal && (
        <CompanyMonitoringLists
          // @ts-ignore
          enterpriseId={graydonNumber || enterpriseId}
          countryCode={countryCode}
          onClose={(from: any) => {
            setMonitoringModal(null);
            sendEvent(app, `report-menu-${context}: Close "Add to list" (${from})`);
          }}
          onChangeSelectedTagUuids={() => {
            sendEvent(app, `report-menu-${context}: Add to list popup: click list checkbox`);
          }}
        />
      )}

      {cocModal && (
        <CocModal
          enterpriseId={enterpriseId}
          cocNumber={cocNumber}
          onClose={() => setCocModal(null)}
        />
      )}

      {annualAccountModal && (
        <AnnualAccountModal
          enterpriseId={enterpriseId}
          cocNumber={cocNumber}
          onClose={() => setAnnualAccountModal(null)}
        />
      )}
      {/* @ts-ignore */}
      {gdmModal && <DecisionModel company={company} onClose={() => setGdmModal(null)} />}
    </>
  );
};
export default ReportModals;
