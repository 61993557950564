import get from 'lodash/get';
import { getLast12ActiveMonths } from '../common';
export const extractRatingTrendData = (data: any, date = new Date()) => {
  const latestValidDate = new Date(date.getFullYear(), date.getMonth() - 1, 1);
  const ratingTrendData = get(
    data,
    'ratingsProfile.graydonGroupCreditScores.historicGraydonGroupCreditScores.graydonRating',
    [],
  );
  return getLast12ActiveMonths(latestValidDate).reduce((acc: any, period: any) => {
    const ratingForGivenPeriod = ratingTrendData.find(
      (graydonData: any) => graydonData.month === period.month && graydonData.year === period.year,
    );
    period.rating = get(ratingForGivenPeriod, 'value', null);
    return [period, ...acc];
  }, []);
};
