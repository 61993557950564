import styled from 'styled-components';
import icon from './icon.svg';

export const Component = styled.div`
  display: inline-block;
  padding: 4px 10px;
  position: relative;
`;

export const Icon = styled.div`
  cursor: pointer;
`;

export const Modal = styled.div`
  position: absolute;
  width: 300px;
  z-index: 1000000000;
  text-align: left;
  right: 10px;
  padding: 12px 16px 16px 16px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  background-color: #fff;

  @media (max-width: 400px) {
    max-width: 250px;
  }

  h2 {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.6;
    color: #3a7177;
    margin: 0;
    white-space: normal;
  }

  p {
    white-space: normal;
    color: #545454;
    margin 10px 0;
  }
`;

export const Link = styled.a`
  color: #168fa7;
  &::before {
    content: '' !important;
    background-image: url(icon);
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
    margin-right: 4px;
    width: 18px;
    height: 18px;
    display: inline-block;
    position: relative;
    top: 4px;
  }
`;

export const TooltipIcon = styled.div<{
  size: number;
  color: string;
  bgColor: string;
  fontSize: number;
  invertColors: boolean;
}>`
  text-align: center;
  cursor: pointer;
  font-weight: 400;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  margin: 5px 0;
  color: ${({ invertColors, color, bgColor }) => (invertColors ? bgColor : color)};
  border: 2px solid ${({ color }) => color};
  border-radius: ${({ size }) => size}px;
  font-size: ${({ fontSize }) => fontSize}px;
  background-color: ${({ invertColors, color, bgColor }) => (invertColors ? color : bgColor)};

  &:hover {
    color: ${({ bgColor }) => bgColor};
    background-color: ${({ color }) => color};
  }
`;
