import React from 'react';
import { RouteComponentProps, matchPath, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { I18nProp, injectI18n } from 'src/i18n';
import styled from 'styled-components';
import Breadcrumb from '../../../../components/Breadcrumb';

export const BreadcrumbWrapper = styled.div`
  display: flex;
  align-items: baseline;
  margin: 0 auto;
  width: 100%;
  max-width: 1400px;
  flex: 1;
`;

const breadcrumbRoutes = {
  HOME: '/international-credit-reports',
  INVESTIGATED_REPORT: '/international-credit-reports/order-investigated-report/:countryCode?',
  ARCHIVE: '/international-credit-reports/archive',
};

const breadCrumbs = {
  HOME: [
    {
      getText: (i18n: any) => i18n.text('breadcrumb.home'),
      getUrl: () => '/',
    },
    {
      getText: (i18n: any) => i18n.text('breadcrumb.international-credit-reports'),
      getUrl: () => null,
    },
  ],
  INVESTIGATED_REPORT: [
    {
      getText: (i18n: any) => i18n.text('breadcrumb.home'),
      getUrl: () => '/',
    },
    {
      getText: (i18n: any) => i18n.text('breadcrumb.international-credit-reports'),
      getUrl: () => '/international-credit-reports',
    },
    {
      getText: (i18n: any) =>
        i18n.text('breadcrumb.international-credit-reports.investigative-report'),
      getUrl: () => null,
    },
  ],
  ARCHIVE: [
    {
      getText: (i18n: any) => i18n.text('breadcrumb.home'),
      getUrl: () => '/',
    },
    {
      getText: (i18n: any) => i18n.text('breadcrumb.international-credit-reports'),
      getUrl: () => '/international-credit-reports',
    },
    {
      getText: (i18n: any) => i18n.text('breadcrumb.international-credit-reports.archive'),
      getUrl: () => null,
    },
  ],
};

const getLinks = (i18n: any, url: any) => {
  const routeNames = Object.keys(breadcrumbRoutes);
  const routeName = routeNames.find((name) =>
    matchPath(url, {
      path: breadcrumbRoutes[name],
      exact: true,
    }),
  );
  return routeName
    ? breadCrumbs[routeName].map(({ getText, getUrl }: any) => ({
        text: getText(i18n),
        url: getUrl(),
      }))
    : [];
};

const Container = styled.div`
  flex: 1;
`;

const BreadcrumbContainer: React.FC<I18nProp & RouteComponentProps> = ({
  i18n,
  match: { url },
}) => (
  <Container>
    <Breadcrumb links={getLinks(i18n, url)} />
  </Container>
);

export default compose(withRouter, injectI18n)(BreadcrumbContainer);
