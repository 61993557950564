import { MenuItem } from '@material-ui/core';
import { injectI18n } from 'src/i18n';
import React, { useState } from 'react';
import DropDownSvg from '../../../../media/icons/select-arrow-down.svg';
import { I18nProp } from 'src/i18n';
import { noop } from '../../../../utils/FP-helpers';
import { MenuDivider } from '../../../ReportMenu/helper-components';
import { MenuIndicator } from '../CountrySelect/helper-components';
import { Item, Label, Menu, Selected, Selector } from './helper-components';

type Props = {
  className?: string;
  selectedMethod: string;
  onChange: (code: string) => any;
  activeOptions: string[] | null | undefined;
};

const MethodSelect: React.FC<Props & I18nProp> = ({
  i18n,
  onChange = noop,
  selectedMethod,
  activeOptions,
  className,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const closeMenu = () => setAnchorEl(null);

  const isOptionActive = (name: any) => activeOptions && activeOptions.includes(name);

  const RenderMenuItem = (name: string, i: number, list: any[]) => {
    const isActive = isOptionActive(name);
    return [
      <MenuItem
        key={i}
        onClick={() => {
          if (isActive) {
            closeMenu();
            onChange(name);
          }
        }}
      >
        {/* eslint-disable-next-line react/prop-types */}
        <Item disabled={!isActive}>{i18n.text(`icr.search.search-method.${name}`)}</Item>
      </MenuItem>,
      i < list.length - 1 && <MenuDivider />,
    ];
  };

  const menuItems = ['NAME_AND_CITY', 'NAME', 'REGISTRATION_ID', 'KEYWORD'].map(RenderMenuItem);

  return (
    <>
      <Selector onClick={(ev) => setAnchorEl(ev.currentTarget)} tabIndex={0} className={className}>
        <Label>{i18n.text('icr.search.search-methods-label')}:</Label>
        <Selected> {i18n.text(`icr.search.search-method.${selectedMethod}`)}</Selected>
        <MenuIndicator src={DropDownSvg} alt="down" />
      </Selector>
      <Menu
        // @ts-ignore
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        getContentAnchorEl={null}
      >
        {menuItems}
      </Menu>
    </>
  );
};

export default injectI18n(MethodSelect);
