// Copy from graydon-web-apps / src / utils / analytics.js
// @ts-nocheck
const event = 'GAevent';

export function sendEvent(
  eventCategory: string,
  eventAction: string,
  eventLabel?: string,
  eventValue?: string,
) {
  if (!eventAction) throw new Error('eventAction is required');

  if (window.dataLayer && window.dataLayer.push) {
    const obj = { event, eventCategory, eventAction };
    if (eventLabel) obj.eventLabel = eventLabel;
    if (eventValue) obj.eventValue = eventValue;
    window.dataLayer.push(obj);
  }
}

export const sendEventOnTileTooltipShow = (eventCategory, eventAction) => (isShown) => {
  if (isShown) sendEvent(eventCategory, eventAction);
};
