import React from 'react';
import CircleLoader from 'src/core/components/loaders/CircleLoader';
import { I18nProp, injectI18n } from 'src/i18n';
import { Layout, HeaderText, Text } from '../helper-components';
import Popup from 'src/core/components/Popup';

const ReportFetchingModal = ({ i18n }: I18nProp) => (
  <Popup displayCloseButton={false} padding="0">
    <Layout>
      <HeaderText center>{i18n.text('cr.modal.report-fetching.header')}</HeaderText>
      <CircleLoader size={80} borderSize={8} />
      <Text>{i18n.text('cr.modal.report-fetching.text')}</Text>
    </Layout>
  </Popup>
);

export default injectI18n(ReportFetchingModal);
