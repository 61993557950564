import React from 'react';
import { I18nProp, injectI18n } from 'src/i18n';
import { getCountryOfResidence } from '../../../../keycloak';
import { Container, HeaderIcon, IconContainer, Title } from '../helper-components';

type SearchErrorProps = {
  consumerId: string;
};

export const SearchError: React.FC<SearchErrorProps & I18nProp> = ({ consumerId, i18n }) => (
  <Container>
    <IconContainer>
      <HeaderIcon>!</HeaderIcon>
    </IconContainer>

    <Title>{i18n.text('icr.error.search')}</Title>

    <div>
      <div>{i18n.text('icr.error.search.text')}</div>

      <div
        dangerouslySetInnerHTML={{
          __html: i18n.text('icr.error.search.contacts', {
            country: getCountryOfResidence(consumerId),
          }),
        }}
      />
    </div>
  </Container>
);

export default injectI18n(SearchError);
