import React, { Component } from 'react';
import styled from 'styled-components';
import { Link as RouterLink, LinkProps, withRouter, RouteComponentProps } from 'react-router-dom';
import { getI18n, I18nProvider } from './i18n';
import { withCookies } from 'react-cookie';
import { setLocale } from 'src/core/utils/analytics';
import Header from 'src/core/components/Header';
import Navigation from 'src/core/components/Chrome/Navigation';
import NotificationSystem from 'react-notification-system';
import DocumentTitle from 'react-document-title';
import * as colors from '@graydon/ui-colors';
import AppRoutes from './AppRoutes';
import keycloak from './keycloak';
import { put } from './utils/api';
import { setNotificationSystem } from './utils/Notification';
import notificationSystemCustomStyle from './utils/Notification/style';
import apps from './apps';
import { App } from './apps';
import PageUnauthorized from './components/PageUnauthorized';
import ErrorBoundary from './components/ErrorBoundary';
import locales from './constants/locales';
import FooterWrap from './components/Footer';
import IeBrowserWarningPopup from './components/IeBrowserWarningPopup';
import graydonSVG from './media/icons/graydon.svg';
import { initializeNotifications } from './utils/appcues';
// import { Locale } from './i18n/i18n';

const isIE = () =>
  /* @cc_on!@ */
  // @ts-ignore
  false || !!document.documentMode;

// `react-router-dom` doesn't support `forwardRef` yet and this is a cheap workaround for the issue. See
// https://github.com/ReactTraining/react-router/issues/6056 for more info.
const Link = (
  props: JSX.IntrinsicAttributes & LinkProps<unknown> & React.RefAttributes<HTMLAnchorElement>,
) => <RouterLink {...props} />;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
`;

const StandardHeader = styled(Header)`
  flex-shrink: 0;
  border-bottom: none;
  #user-menu-container {
    margin-right: 29px;
    nav {
      &::after {
        content: none;
      }
      right: 18px;
    }
  }

  @media print {
    display: none;
  }
`;

const HomeHeader = styled(StandardHeader)`
  a {
    &::before,
    &::after {
      content: none;
    }
  }
`;

const AppFlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  // This line was added for IE11 bug with flex layout;
  min-height: calc(100vh - 120px);
`;
const GraydonIcon = styled(({ className }) => (
  <img className={className} src={graydonSVG} alt="Graydon" />
))`
  @media (min-width: 768px) {
    height: 22px;
  }

  @media (max-width: 768px) {
    width: 174px;
  }
`;

const toggleMenu = (prevState: { isMenuOpen }) => ({
  isMenuOpen: !prevState.isMenuOpen,
});

const defaultDocumentTitle = 'Graydon Insights';

type Props = RouteComponentProps & {
  settings: {
    environment: {
      id: string;
    };
  };
  cookies: any;
};

type State = {
  // locale: Locale;
  locale: any;
  isMenuOpen: boolean;
};

const setIe11CompatibilityCookie = (cookies: {
  get: (arg0: string) => any;
  set: (arg0: string, arg1: boolean, arg2: { expires: Date }) => void;
}) => {
  const isBrowserIe = isIE();

  if (isBrowserIe) {
    const browserCompatibilityCookie = cookies.get('ie11-compatibility-warning');

    if (!browserCompatibilityCookie) {
      const tomorrow = new Date();
      tomorrow.setDate(new Date().getDate() + 1);
      cookies.set('ie11-compatibility-warning', true, {
        expires: tomorrow,
      });
      return true;
    }
  }

  return false;
};

class AppWrapper extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const user = keycloak.tokenParsed;
    this.state = {
      locale: user.locale || 'en-GB',
      isMenuOpen: false,
    };
  }

  componentDidUpdate(prevProps: { location: { pathname: any } }) {
    if (prevProps.location.pathname !== this.props.location.pathname && window['Appcues']) {
      window['Appcues'].start();
    }
  }

  showIe11CompatibilityPopup = false;

  changeLocale(locale: { split: (arg0: string) => [any, any] }) {
    this.setState({
      locale,
    });
    const user = keycloak.tokenParsed;
    const [language, country] = locale.split('-');
    put(`/user/users/${user.sub}/locale`, {
      language,
      country,
    }).catch((err) => console.log(err));
    initializeNotifications(locale);
    setLocale(locale, window, this.props.settings.environment.id);
  }

  render() {
    const { location, history, cookies } = this.props;
    const { locale, isMenuOpen } = this.state;
    const user = keycloak.tokenParsed;
    const i18n = getI18n(locale);
    this.showIe11CompatibilityPopup = setIe11CompatibilityCookie(cookies);
    const appKey: string = location.pathname.split('/')[1];
    const app: App | null | undefined = apps.find((it: any) => it.key === appKey);
    const isHome = appKey === 'home';
    const chromeProps = {
      i18n,
      name: `${user.given_name} ${user.family_name}`,
      showLogoutButton: !user.direct_access,
      companyName: user.consumer.name,
      language: locale,
      doLogout: () => {
        global.window.location = keycloak.createLogoutUrl({
          redirectUri: window.location.href,
        });
      },
      onLanguageChange: (item: { value: any }) => () => this.changeLocale(item.value),
      isHome: false,
      onToggleMenu: () => this.setState(toggleMenu),
      isOpen: isMenuOpen,
      className: 'graydon-web-apps',
      appName: app && !isHome ? i18n.text(`apps.${app.key}`) : <GraydonIcon />,
      appPath: `/${appKey}`,
      homePath: '/home',
      isHomePathRouterLink: true,
      showLanguageBar: true,
      showHelpBlock: true,
      consumerId: String(user.consumer.id),
      onContactsClick: () => history.push('/contact'),
      Link,
      locales,
    };

    const getFooterColor = () =>
      location.pathname === '/company-data' ||
      location.pathname === '/international-credit-reports' ||
      location.pathname === '/stakeholder-report'
        ? colors.white
        : colors.grayLight5;

    const isGraphPage = () => {
      return location.pathname.match(/\/xseption-report\/[^/]+\/network-graph/);
    };

    return (
      <I18nProvider locale={locale}>
        {/* @ts-ignore */}
        <DocumentTitle
          title={
            app && !isHome
              ? `${defaultDocumentTitle} | ${i18n.text(`apps.${app.key}`)}`
              : defaultDocumentTitle
          }
        >
          <Wrapper>
            {/* @ts-ignore */}
            {isHome ? <HomeHeader {...chromeProps} /> : <StandardHeader {...chromeProps} />}
            {isMenuOpen && (
              <div>
                {/* @ts-ignore */}
                <Navigation showSubMenuOnly {...chromeProps} />
              </div>
            )}
            {app && !app.hasAccess() ? (
              <PageUnauthorized />
            ) : (
              <ErrorBoundary>
                <AppFlexWrapper>
                  {this.showIe11CompatibilityPopup && (
                    <IeBrowserWarningPopup
                      onClose={() => {
                        this.showIe11CompatibilityPopup = false;
                        this.forceUpdate();
                      }}
                    />
                  )}
                  <AppRoutes />
                </AppFlexWrapper>
              </ErrorBoundary>
            )}
            {/* @ts-ignore */}
            <NotificationSystem ref={setNotificationSystem} style={notificationSystemCustomStyle} />
            {!isHome && !isGraphPage() && <FooterWrap bgColor={getFooterColor()} />}
          </Wrapper>
        </DocumentTitle>
      </I18nProvider>
    );
  }
}

// @ts-ignore
export default withCookies(withRouter(AppWrapper));
