export const TERMS_AND_CONDITIONS = 'termsAndConditions';
export const GDPR = 'gdpr';
export const COOKIES = 'cookies';
const links = {
  [TERMS_AND_CONDITIONS]: {
    be: {
      'nl-NL': 'http://public.graydon.be/algemene-voorwaarden',
      fr: 'http://public.graydon.be/fr/conditions-generales',
      'en-GB': 'http://public.graydon.be/en/terms-and-conditions-1',
    },
    nl: {
      'nl-NL': 'https://www.graydon.nl/algemene-voorwaarden',
      fr: 'https://www.graydon.nl/algemene-voorwaarden',
      'en-GB': 'https://www.graydon.nl/en/terms-conditions',
    },
    gb: 'https://www.graydon.co.uk/terms-and-conditions',
  },
  [GDPR]: {
    be: {
      'nl-NL': 'http://public.graydon.be/gdpr',
      fr: 'http://public.graydon.be/fr/rgpd',
      'en-GB': 'http://public.graydon.be/en/wiki/general-data-protection-regulation-gdpr-0',
    },
    nl: {
      'nl-NL': 'https://www.graydon.nl/avg',
      fr: 'https://www.graydon.nl/avg',
      'en-GB': 'https://www.graydon.nl/en/avg',
    },
    gb: 'https://www.graydon.co.uk/wiki/general-data-protection-regulation-gdpr',
  },
  [COOKIES]: {
    be: {
      'nl-NL': 'http://public.graydon.be/cookies',
      fr: 'http://public.graydon.be/fr/cookies',
      'en-GB': 'http://public.graydon.be/en/cookies-0',
    },
    nl: {
      'nl-NL': 'https://www.graydon.nl/cookies',
      fr: 'https://www.graydon.nl/en/cookies',
      'en-GB': 'https://www.graydon.nl/en/cookies',
    },
    gb: 'https://www.graydon.co.uk/cookies',
  },
};
export const getLink = (type: any) => (customerLocale: any) => {
  if (customerLocale === 'gb') return () => links[type][customerLocale];
  return (userLocale: any) => links[type][customerLocale][userLocale];
};
