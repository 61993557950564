import { map } from 'lodash/fp';
import compose from 'lodash/fp/compose';
import prop from 'lodash/fp/getOr';
import orderBy from 'lodash/fp/orderBy';
import property from 'lodash/fp/property';
import uniqBy from 'lodash/fp/uniqBy';
import { stringify } from 'query-string';
import { compactObject } from '../../utils/FP-helpers';
import { downloadFile, get } from '../../utils/api';
import { AnnualAccountRecord, fillGaps } from './util';

const getQueryString = compose(stringify, compactObject);

export function downloadReport(enterpriseId: number, documentId: number, year: number) {
  return downloadFile(
    `/coc-extracts/annual-accounts/enterprises/${enterpriseId}/documents/${documentId}?year=${year}`,
    'application/zip',
  );
}

export function downloadArchiveReport(reportId: number) {
  return downloadFile(
    `/coc-extracts/annual-accounts/archive/download/${reportId} `,
    'application/zip',
  );
}

export function getFreshList(
  enterpriseId: number,
  cocNumber?: string,
): Promise<AnnualAccountRecord[]> {
  const queryParams = getQueryString({
    // @ts-ignore
    cocNumber,
  });
  return get(
    `/coc-extracts/annual-accounts/enterprises/${enterpriseId}${
      queryParams ? `?${queryParams}` : ''
    }`,
  )
    .then(prop([], 'documents'))
    .then((list) => (list.length ? list : Promise.reject(new Error('404'))))
    .then(orderBy<AnnualAccountRecord>('financialYear', 'desc'))
    .then(fillGaps)
    .then((items) => items.slice(0, 7));
}
const uniqByProperty = compose(uniqBy, property);
const getUniqueReports = compose(
  map(({ id, metadata }) => ({
    archiveId: id,
    financialYear: prop(null, 'financialYear', metadata),
  })),
  uniqByProperty(['metadata', 'financialYear']),
  prop([], ['reportsList', 'reports']),
);

export function fetchLisArchivedReports(enterpriseId: number): Promise<any[]> {
  return get(`/credit-reports/enterprises/${enterpriseId}/reports/annual-account`).then((data) => {
    return getUniqueReports(data);
  });
}

export function getList(enterpriseId: number, cocNumber?: string): Promise<any> {
  return Promise.all([
    getFreshList(enterpriseId, cocNumber),
    fetchLisArchivedReports(enterpriseId),
  ]).then(([list, archive]) => {
    return list.map((item) => {
      const archiveVersion = archive.find(
        ({ financialYear }) => financialYear && `${financialYear}` === `${item.financialYear}`,
      );
      return { ...archiveVersion, ...item };
    });
  });
}
