import styled, { css } from 'styled-components';
import breakpoints from '../../constants/breakpoints';
import cssColors from '../../constants/colors';
import { serif } from '../../constants/fonts';
import { HELP_RADIUS, POINTERSIZE, WIDTH, RDM_HEIGHT } from './constants';

const willOverflowBottom = ({ top }) => top + RDM_HEIGHT < document.documentElement.clientHeight;
const willOverflowRight = ({ left }) => left + WIDTH < document.documentElement.clientWidth;

type Rect = { rect: { top: number; left: number } };

const boxPositioning = css<Rect>`
  ${({ rect }) => `${willOverflowBottom(rect) ? 'top' : 'bottom'}: ${HELP_RADIUS + POINTERSIZE}px`};
  ${({ rect }) => `${willOverflowRight(rect) ? 'left' : 'right'}: ${HELP_RADIUS / 2}px`};
`;

const pointerPositioning = css<Rect>`
  ${({ rect }) => `${willOverflowBottom(rect) ? 'top' : 'bottom'}: -${POINTERSIZE}px`};
  ${({ rect }) => `${willOverflowRight(rect) ? 'left' : 'right'}: 0`};
`;

const pointer = css<Rect>`
  border-style: solid;
  border-width: ${POINTERSIZE}px;
  border-top-color: transparent;
  border-right-color: ${({ rect }) =>
    willOverflowRight(rect) ? 'transparent' : cssColors.secondary};
  border-bottom-color: transparent;
  border-left-color: ${({ rect }) =>
    willOverflowRight(rect) ? cssColors.secondary : 'transparent'};
`;

export default styled.div`
  width: ${WIDTH}px;
  padding: 10px;
  color: #fff;
  background: ${cssColors.secondary};
  font: 14px ${serif};
  position: absolute;
  ${boxPositioning} z-index: 9999;
  display: block;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);

  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    ${pointer} ${pointerPositioning};
  }

  @media screen and (max-width: ${breakpoints['portrait-min']}px) {
    width: ${WIDTH / 1.5}px;
  }
`;
