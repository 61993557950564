import React from 'react';
import { createSlice } from '@reduxjs/toolkit';
import download from 'downloadjs';
import { getFilenameFromHeader } from '../../../components/UploadingAndMatching/utils';
import { post, get, doFetch } from '../../../utils/api';
import { sendNotification } from '../../../utils/Notification';
import NotificationMessage from '../../../utils/Notification/Message';
import { RouterLink } from '../../../components/Link';
import { getUserData } from '../shared/selectors/userData';
import { getClientSession } from '../../../utils';
import { Markdown } from '../../../components/Markdown';

type IcrRequestStatus =
  | null
  | 'REQUEST_FAILED'
  | 'ERROR'
  | 'DELETED'
  | 'ORDERED'
  | 'COMPLETED'
  | 'ORDERED_NO_READY';
const CHECK_REPORTS_COUNT = 5;
const CHECK_REPORTS_INTERVAL = 2000;

const slice = createSlice({
  name: 'internationalCreditReports',
  initialState: {
    isDownloading: false,
    isDownloadError: false,
    requestReportStatus: null as IcrRequestStatus,
    popupCompany: null,
  },
  reducers: {
    downloadReportStarted: (state) => {
      state.isDownloading = true;
      state.isDownloadError = false;
    },
    popupCompanySet: (state, action) => {
      state.popupCompany = action.payload;
    },
    reportDownloaded: (state, action) => {
      state.isDownloading = false;
      state.isDownloadError = action.payload !== null;
    },
    reportRequestStatusUpdated: (
      state,
      action: {
        payload: IcrRequestStatus;
      },
    ) => {
      state.requestReportStatus = action.payload;
    },
  },
});

export const {
  downloadReportStarted,
  reportDownloaded,
  reportRequestStatusUpdated,
  popupCompanySet,
} = slice.actions;

export default slice.reducer;

export const downloadPdfReport = (
  orderId: string,
  documentUuid: string,
  i18n: any,
  availability: any,
  consumerId: string,
) => {
  return (dispatch: any) => {
    dispatch(downloadReportStarted());
    return doFetch(
      'get',
      `/international-business/orders/${orderId}/documents/${documentUuid}${
        consumerId ? `?consumerId=${consumerId}` : ''
      }`,
      null,
      {
        Accept: 'application/pdf, application/html',
      },
    )
      .then((res) => {
        res.blob().then((blob: any) => {
          const filenameHeader = res.headers.get('content-disposition');

          if (!filenameHeader) {
            dispatch(reportDownloaded(new Error('Doc download error')));
            sendNotification({
              message: (
                <NotificationMessage
                  title={i18n.text('icr.archive.report.download.toast.title.error')}
                  body={i18n.text('icr.archive.report.download.toast.body.error')}
                />
              ),
              level: 'error',
              autoDismiss: 0,
            });
            return;
          }

          const fileName = getFilenameFromHeader(filenameHeader);
          download(blob, fileName);

          if (availability === 'IMMEDIATE_WITH_UPDATE') {
            sendNotification({
              message: (
                <NotificationMessage
                  title={i18n.text('icr.search-results.modal.availability.outdated-report-title')}
                  body={
                    <div>
                      <div>
                        {i18n.text('icr.search-results.modal.availability.outdated-report-body')}
                      </div>
                      <RouterLink to="/international-credit-reports/archive">
                        {i18n.text('icr.archive.report.download.toast.body.link-to-archive')}
                      </RouterLink>
                    </div>
                  }
                />
              ),
              level: 'success',
              autoDismiss: 0,
            });
          } else if (availability !== 'NO_NOTIFICATION') {
            sendNotification({
              message: (
                <NotificationMessage
                  title={i18n.text('icr.archive.report.download.toast.title.success')}
                  body={
                    <div>
                      <div>{i18n.text('icr.archive.report.download.toast.body.success')}</div>
                      <RouterLink to="/international-credit-reports/archive">
                        {i18n.text('icr.archive.report.download.toast.body.link-to-archive')}
                      </RouterLink>
                    </div>
                  }
                />
              ),
              level: 'success',
              autoDismiss: 5,
            });
          }

          dispatch(reportDownloaded(null));
        });
      })
      .catch((e) => {
        dispatch(reportDownloaded(e));
        sendNotification({
          message: (
            <NotificationMessage
              title={i18n.text('icr.archive.report.download.toast.title.error')}
              body={i18n.text('icr.archive.report.download.toast.body.error')}
            />
          ),
          level: 'error',
          autoDismiss: 0,
        });
      });
  };
};

const checkAvailabilityRegular = (orderId: any, i18n: any) => (dispatch: any) => {
  let counter = CHECK_REPORTS_COUNT;
  const timer = setInterval(() => {
    get(`/international-business/orders/${orderId}`)
      .then(({ orderStatus }) => {
        if (orderStatus !== 'ORDERED' || counter === 0) {
          clearInterval(timer);
          dispatch(
            reportRequestStatusUpdated(
              orderStatus === 'ORDERED' ? 'ORDERED_NO_READY' : orderStatus,
            ),
          );

          if (orderStatus !== 'ERROR') {
            sendNotification({
              message: (
                <NotificationMessage
                  title={i18n.text('icr.archive.request-report.toast.title')}
                  body={
                    <div>
                      <div>{i18n.text('icr.archive.request-report.toast.text')}</div>
                      <RouterLink to="/international-credit-reports/archive">
                        {i18n.text('icr.archive.request-report.toast.link')}
                      </RouterLink>
                    </div>
                  }
                />
              ),
              level: 'info',
              autoDismiss: 0,
            });
          }
        }
      })
      .catch((error) => {
        console.error(error);
      });

    if (counter <= 0) {
      clearInterval(timer);
    } else counter -= 1;
  }, CHECK_REPORTS_INTERVAL);
};

export const requestICR =
  (
    agencyCompanyId: string,
    countryCode: string,
    i18n: any,
    orderReference: string,
    companyName: string,
  ) =>
  (dispatch: any, getState: () => any) => {
    const {
      internationalCreditReports: { requestReportStatus },
    } = getState();
    if (requestReportStatus === 'ORDERED') return;
    dispatch(reportRequestStatusUpdated('ORDERED'));
    const clientSession = getClientSession();
    const request = {
      countryCode,
      companyName,
      orderReference,
      agencyCompanyId,
      language: 'en',
      investigationRequest: null,
      ...(clientSession
        ? {
            userData: getUserData(clientSession),
          }
        : {}),
    };
    post('/international-business/order', request)
      .then(
        ({
          order: {
            details: { orderId },
          },
        }) => {
          dispatch(checkAvailabilityRegular(orderId, i18n));
        },
      )
      .catch(() => {
        dispatch(reportRequestStatusUpdated('REQUEST_FAILED'));
        dispatch(popupCompanySet(null));
        sendNotification({
          message: (
            <NotificationMessage
              title={i18n.text('icr.archive.request-report.toast.title.error')}
              body={<Markdown>{i18n.text('icr.archive.request-report.toast.text.error')}</Markdown>}
            />
          ),
          level: 'error',
          autoDismiss: 0,
        });
      });
  };
