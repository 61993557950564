import styled, { css } from 'styled-components';
import { sans } from 'src/core/constants/fonts';
import mapProps from 'recompose/mapProps';
import omit from 'lodash/omit';
import { Link as ReactRouterLink } from 'react-router-dom';
import * as colors from '@graydon/ui-colors';
const collapsableCss = css`
  @media (max-width: 768px) {
    overflow: hidden;
    width: 48px;
    display: inline-block;

    & > svg {
      margin-right: 10em;
    }
  }
`;
const Button = styled.button<{
  padding?: string;
  primary?: boolean;
  secondary?: boolean;
  iconMargin?: string;
  collapsable?: boolean;
  icon?: React.ReactElement;
}>`
  position: relative;
  padding: ${({ padding }) => padding || '13.5px 15px'};
  font: 14px ${sans};
  font-weight: 300;
  border: none;
  outline: none;
  border-radius: 3px;
  display: inline-block;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  transition: background-color 0.2s ease-out;
  cursor: pointer;

  ${({ disabled }) => disabled && 'pointer-events: none;'}
  background-color: ${({ primary, disabled }) => {
    if (disabled) return colors.grayLight1;
    return primary ? '#eb6e08' : colors.primaryLight;
  }};
  color: ${({ disabled }) => (disabled ? colors.grayLight2 : colors.white)};
  white-space: nowrap;

  &:hover {
    background-color: ${({ primary, disabled }) => {
      if (disabled) return colors.grayLight1;
      return primary ? '#b55d26' : '#0e687a';
    }};
  }
  & > svg {
    display: inline-block;
    margin: ${({ iconMargin }) => iconMargin || '-4px 10px -4px 0'};
  }
  ${({ icon }) =>
    icon &&
    `
    padding-left: 54px;

    &::before {
      content: '';
      position: absolute;
      left: 15px;
      top: 0;
      bottom: 0;
      width: 24px;
      background: no-repeat url(${icon});
      background-position-y: center;
    }
  `}

  ${({ collapsable }) => collapsable && collapsableCss};
`;
export const RouterButton = Button.withComponent(
  // make sure the style props don't get passed to the DOM element
  mapProps((unmappedProps: any) => omit(unmappedProps, ['primary', 'disabled']))(ReactRouterLink),
);
export default Button;
