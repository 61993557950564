export const getHorizontalDirection = (
  windowWidth,
  defaultOrientation,
  containerLeft,
  tipWidth,
  tipOffset,
) => {
  if (tipWidth > windowWidth) {
    return defaultOrientation;
  }
  if (defaultOrientation === 'right') {
    if (containerLeft + tipWidth + tipOffset > windowWidth) {
      return 'left';
    }
    return 'right';
  }
  if (defaultOrientation === 'left') {
    if (containerLeft - tipWidth + tipOffset >= 0) {
      return 'left';
    }
    return 'right';
  }
  return defaultOrientation;
};
