import { createActions } from 'redux-actions';
import download from 'downloadjs';
import { getFilenameFromHeader } from 'src/core/utils/http';
import { REPORT_DOWNLOAD_URL } from '../../../components/CompanyHeader/constants';
import { get } from '../../../../../utils/api';
import { getConsumerId } from '../../../../../keycloak';
export const {
  downloadArchivedReportStart,
  downloadArchivedReportComplete,
  downloadArchivedReportReset,
} = createActions(
  'DOWNLOAD_ARCHIVED_REPORT_START',
  'DOWNLOAD_ARCHIVED_REPORT_COMPLETE',
  'DOWNLOAD_ARCHIVED_REPORT_RESET',
);
export const downloadArchivedReport =
  (reportType: any, id: any, language = 'en') =>
  async (dispatch: any) => {
    try {
      let path;
      let mimeType = 'application/pdf';

      switch (reportType) {
        case 'ubo-report':
          path = `/credit-reports/ubo/report`;
          break;

        case 'xseption-report':
          path = `/xseption/consumers/${getConsumerId()}/download`;
          break;

        case 'coc-extract':
          path = `/coc-extracts/archive/download`;
          break;

        case 'annual-account':
          path = `/coc-extracts/annual-accounts/archive/download`;
          mimeType = 'application/zip';
          break;

        default:
          path = REPORT_DOWNLOAD_URL;
      }

      let url = `${path}/${id}`;

      if (reportType !== 'coc-extract') {
        url += `?language=${language}`;
      }

      dispatch(downloadArchivedReportStart());
      const response = await get(url, null, false, {
        Accept: mimeType,
      });
      const blob = await response.blob();
      download(
        blob,
        getFilenameFromHeader(response.headers.get('content-disposition')),
        'application/pdf',
      );
      dispatch(downloadArchivedReportComplete());
    } catch (err) {
      dispatch(downloadArchivedReportComplete(err));
    }
  };
export default {
  downloadArchivedReport,
};
