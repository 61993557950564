import React from 'react';
import { isEmpty } from 'lodash';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { I18nProp, injectI18n } from 'src/i18n';
import { useProcessFlow } from '../../hooks/useProcessFlow';
import useResult from '../../hooks/useResult';
import { inspect } from '../../utils/FP-helpers';
import { isFailed, isInit, isStarted } from '../../utils/processFlow';
import ReportFetchingModal from '../CRModals/ReportFetchingModal';
import ReportErrorModalComponent from '../CRModals/ReportErrorModal/component';
import Popup from '../Popup';
import { CompanyData } from '../ReportMenu';
import Archive from './Archive';
import { getArchive, getDecisionModels, requestCheck } from './api';
import {
  Button,
  CompanyName,
  Description,
  Form,
  Input,
  Label,
  Select,
  Title,
  ValueWrapper,
} from './helper-components';
import { parseCompanyCode } from './util';

type Props = {
  onClose: () => void;
  company: CompanyData;
};

function DecisionModel({
  i18n,
  onClose,
  history,
  company: {
    enterpriseId,
    countryCode,
    graydonNumber,
    registrationNumber,
    companyName,
    graydonCompanyId,
  },
}: I18nProp & Props & RouteComponentProps) {
  const [models] = useResult(() => getDecisionModels(countryCode), []);
  const [requestStatus, request, reset] = useProcessFlow(requestCheck);
  const [archive, archiveStatus] = useResult(
    () => getArchive(parseCompanyCode(graydonCompanyId, countryCode), enterpriseId),
    [],
  );

  const openCheck = (id: any) => history.push(`/dm-v2/${id}`);

  function checkRequest(e: any) {
    e.preventDefault();
    const {
      target: {
        model: { value: model },
        value: { value },
      },
    } = e;
    //@ts-ignore
    request(model, value, `${enterpriseId}`, countryCode)
      .then(openCheck)
      .catch(inspect('fetch report error'));
  }

  return (
    <>
      {isInit(requestStatus) && (
        <Popup onClose={onClose}>
          <Title>{i18n.text('gdm.check-modal.title')}</Title>
          <CompanyName>{companyName}</CompanyName>
          {registrationNumber && (
            <Description>
              {i18n.text(
                `cr.affiliations.directors.registration-number-${countryCode.toLowerCase()}`,
              )}
              : {registrationNumber}
            </Description>
          )}
          {graydonNumber && (
            <Description>
              {i18n.text('cr.company.details.graydon-number')}: {graydonNumber}
            </Description>
          )}
          <Form onSubmit={checkRequest}>
            <Label>{i18n.text('gdm.check-modal.select')}</Label>
            {isEmpty(models) ? (
              <Description>{i18n.text('creditcheck.no.active.models')}</Description>
            ) : (
              <Select name="model" disabled={isEmpty(models)}>
                {/* @ts-ignore */}
                {models.map(({ id, name }) => (
                  <option key={id} value={id}>
                    {name}
                  </option>
                ))}
              </Select>
            )}
            <Label>{i18n.text('dm.editor.credit.limit')}</Label>
            <ValueWrapper currency={countryCode === 'GB' ? '₤' : '€'}>
              <Input type="number" name="value" />
            </ValueWrapper>
            <Button primary type="submit" disabled={isEmpty(models)}>
              {i18n.text('dm.run.runcheck')}
            </Button>
          </Form>
          <Archive status={archiveStatus} list={archive} />
        </Popup>
      )}
      {isStarted(requestStatus) && <ReportFetchingModal />}
      {/* @ts-ignore */}
      {isFailed(requestStatus) && <ReportErrorModalComponent onClose={reset} />}
    </>
  );
}

export default compose(injectI18n, withRouter)(DecisionModel);
