import * as colors from '@graydon/ui-colors';
export default {
  Containers: {
    DefaultStyle: {
      fontFamily: 'inherit',
      position: 'fixed',
      width: '100%',
      maxWidth: '415px',
      padding: '0 10px 10px 10px',
      zIndex: 9998,
      WebkitBoxSizing: 'border-box',
      MozBoxSizing: 'border-box',
      boxSizing: 'border-box',
    },
    tr: {
      top: '0px',
      bottom: 'auto',
      left: 'auto',
      right: '0px',
    },
  },
  NotificationItem: {
    DefaultStyle: {
      // Applied to every notification, regardless of the notification level
      backgroundColor: '#F8F8F8',
      width: '100%',
      fontSize: '16px',
      fontWeight: '300',
      padding: '16px 32px 16px 16px',
      color: '#545454',
      boxShadow: '0 2px 8px rgba(0,0,0,0.2)',
      borderRadius: '4px',
    },
    success: {
      borderTop: '0px',
      borderLeft: `8px solid ${colors.success}`, // success
    },
    error: {
      borderTop: '0px',
      borderLeft: `8px solid ${colors.danger}`, // danger
    },
    warning: {
      borderTop: '0px',
      borderLeft: `8px solid ${colors.danger}`, // danger
    },
    info: {
      borderTop: '0px',
      borderLeft: `8px solid ${colors.yellow}`, // info
    },
  },
  Title: {
    DefaultStyle: {
      fontSize: '18px',
      fontWeight: '500',
      color: '#1B6D75',
      margin: '0 0 4px 0',
    },
    success: {},
    error: {},
    warning: {},
    info: {},
  },
  Action: {
    DefaultStyle: {
      padding: '0px',
      margin: '4px 0px 0px 0px',
      textDecoration: 'underline',
      fontSize: '16px',
      fontWeight: '300',
      color: '#1B6D75',
      backgroundColor: '#F8F8F8',
    },
    success: {},
    error: {},
    warning: {},
    info: {},
  },
  Dismiss: {
    DefaultStyle: {
      cursor: 'pointer',
      fontFamily: 'Roboto',
      fontSize: '38px',
      position: 'absolute',
      top: '18px',
      right: '16px',
      lineHeight: '15px',
      color: '#777',
      textAlign: 'center',
      backgroundColor: 'transparent',
      fontWeight: 100,
    },
  },
};
