import get from 'lodash/get';
import { parseDate } from '../common';
export const extractUnsecuredClaimsData = (data: any) => {
  const unsecuredClaims = get(data, 'operationsProfile.unsecuredClaims');
  const claimsList = get(unsecuredClaims, 'debtors', []);
  const totalUnsecuredAmount = get(unsecuredClaims, 'totalAmount.value', 0); // Non-Nullable

  const totalUnsecuredAmountCurrency = get(unsecuredClaims, 'totalAmount.currencyCode', 'GBP'); // Non-Nullable

  const totalAmountOfDebtors = get(unsecuredClaims, 'totalDebtors'); // Non-Nullable

  const claims = claimsList.map((item: any) => {
    const {
      amount: {
        value: unsecuredAmountValue,
        // Non-Nullable
        currencyCode: unsecuredAmountCurrency, // Non-Nullable
      },
    } = item;
    const name = get(item, 'companyProfile.companyName', '-');
    const enterpriseId = get(item, 'companyIdentification.enterpriseId', null);
    const countryCode = get(item, 'companyIdentification.countryCode', null);
    const statementOfAffairsDate = parseDate(get(item, 'statementOfAffairsDate', null));
    return {
      key: `${name} ${unsecuredAmountValue}`,
      name,
      enterpriseId,
      statementOfAffairsDate,
      unsecuredAmountCurrency,
      unsecuredAmountValue,
      countryCode,
    };
  });
  return {
    claims,
    totalUnsecuredAmount,
    totalUnsecuredAmountCurrency,
    totalAmountOfDebtors,
  };
};
