export const DATA = {
  nl: {
    title: 'cr.modal.report-error.graydon.nl',
    email: 'service@graydon.nl',
    phoneNumber: '+31 (020) 567 9501',
  },
  be: {
    title: 'cr.modal.report-error.graydon.be',
    email: 'support@graydon.be',
    phoneNumber: '+32 (03) 280 8880',
  },
  gb: {
    title: 'cr.modal.report-error.graydon.gb',
    email: 'customerservices@graydon.co.uk',
    phoneNumber: '+44 (020) 8515 1420',
  },
};
export default {
  DATA,
};
