// @ts-nocheck
import React, { useState } from 'react';
import { useProcessFlow } from '../../../../hooks/useProcessFlow';
import { inspect } from '../../../../utils/FP-helpers';
import { isFailed, isStarted } from '../../../../utils/processFlow';
import BaseResultPage from '../../../../components/BaseResultPage';
import SearchResults from '../../components/SearchResults/component';
import { search } from './api';
import { useCountryList } from '../../../../components/OneSearch/useContryList';

const SearchPage = () => {
  const [searchState, doSearch] = useProcessFlow(search);
  const [result, setResult] = useState({});
  const [countryList, countryError] = useCountryList();

  const submit = (searchParams: any) => {
    const { serviceLevel } = countryList[searchParams.country];

    if (serviceLevel === 'investigation') {
      setResult({
        total: 0,
        items: [],
        countryCode: searchParams.country,
        serviceLevel,
      });
    } else {
      doSearch(searchParams).then(setResult).catch(inspect('error'));
    }
  };

  return (
    <BaseResultPage
      page="CR"
      submit={submit}
      countryList={countryList}
      error={Boolean(countryError)}
    >
      <SearchResults
        {...result}
        countryCode={result.countryCode && result.countryCode.toLowerCase()}
        isFetching={isStarted(searchState)}
        isError={isFailed(searchState)}
      />
    </BaseResultPage>
  );
};

export default SearchPage;
