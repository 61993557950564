import createReducer from 'create-reducer-map';
import {
  FETCH_ORGANISATION_TAG_UUIDS_REQUEST,
  FETCH_ORGANISATION_TAG_UUIDS_RESPONSE,
  SET_ORGANISATION_TAG_UUIDS,
} from './constants';
export const initialState = {
  response: null,
  status: null,
};
export default createReducer(initialState, {
  [FETCH_ORGANISATION_TAG_UUIDS_REQUEST]: (state: any) => ({
    ...state,
    status: 'loading',
  }),
  [FETCH_ORGANISATION_TAG_UUIDS_RESPONSE]: {
    next: (state, response) => ({
      response,
      status: null,
    }),
    throw: (state: any) => ({
      ...state,
      status: 'error',
    }),
  },
  [SET_ORGANISATION_TAG_UUIDS]: (state, organisationTagUuids) => ({
    ...state,
    response: organisationTagUuids,
  }),
});
