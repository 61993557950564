import React from 'react';
import * as colors from '@graydon/ui-colors';
import { Set } from 'immutable';
import groupBy from 'lodash/groupBy';
import { useEffect, useState } from 'react';
import CircleLoader from 'src/core/components/loaders/CircleLoader';
import { I18nProp, injectI18n } from 'src/i18n';
import styled from 'styled-components';
import { sans } from '../../constants/fonts';
import { hasAccessToDatabaseManagement, hasAccessToMonitoring } from '../../keycloak';
import { get, getConsumerPath, getMonitoringPath } from '../../utils/api';
import Button from '../Button';
import { RouterLink } from '../Link';
import Popup, { PopupHeading } from '../Popup';
import { renderRequestError } from '../RequestWrapper';
import TagItem from './TagItem';

const Layout = styled.div`
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  height: 90vh;
  color: #545454;

  @media (max-width: 768px) {
    height: 100vh;
  }
`;

const Content = styled.div`
  flex-grow: 1;
  padding: 0 15px 15px;
`;

const Bottom = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  background-color: white;
  border-top: 2px solid #e6eaee;

  & > * {
    margin: 15px;
  }
`;

export const SubHeading = styled.h4`
  font-family: ${sans};
  font-size: 16px;
  font-weight: 500;
  color: ${colors.gray};
  margin: 16px 0 0 0;
  padding: 0;
`;

type Props = I18nProp & {
  onClose: (from: string) => unknown;
  enterpriseId: number;
  countryCode: string;
  onChangeSelectedTagUuids?: (value: Set<string>) => unknown;
  selectedTagUuids?: Set<string>;
};

function CompanyMonitoringListsContent(props: Props) {
  const hasMonitoring = hasAccessToMonitoring();
  const hasDbmAccess = hasAccessToDatabaseManagement();
  const { i18n, enterpriseId, onChangeSelectedTagUuids, countryCode } = props;
  const [tagsList, setTagsList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [apiError, setApiError] = useState(null);
  const [selectedTagUuidsResponse, setSelectedTagUuidsResponse] = useState(null);

  async function getMonitoringProfiles() {
    try {
      const { monitoringProfiles } = await get(`${getMonitoringPath()}/profiles`);
      return monitoringProfiles;
    } catch (error) {
      // @ts-ignore
      if (error.message === '403' || error.message === '404') return [];
      throw error;
    }
  }

  async function getDbmProfiles() {
    try {
      const { profiles } = await get(`/database-management/profiles`);
      return profiles;
    } catch (error) {
      // @ts-ignore
      if (error.message === '403') return [];
      throw error;
    }
  }

  useEffect(() => {
    async function fetchAPIs() {
      const { tags } = await get(`${getConsumerPath()}/tags?from=0&size=1000`);
      const dbmProfiles = await getDbmProfiles();
      const monitoringProfiles = await getMonitoringProfiles();
      const uuidsResponse = await get(
        `${getConsumerPath()}/organization-tag-uuids/${enterpriseId}`,
      );
      const dbmProfilesByTagId = groupBy(
        [].concat(...dbmProfiles.map((profile: any) => profile.tags)),
        'tagId',
      );
      const monitoringProfilesByTagId = groupBy(monitoringProfiles, 'tagId');
      const sortedTags = tags
        .map((tag: any) => ({
          ...tag,
          hasDBM: tag.uuid in dbmProfilesByTagId,
          isMonitored: tag.uuid in monitoringProfilesByTagId,
        }))
        .slice()
        .sort((a: any, b: any) => (a.name || '').localeCompare(b.name || ''));
      setTagsList(sortedTags);
      setSelectedTagUuidsResponse(Set(uuidsResponse));
      setLoading(false);
    }

    fetchAPIs().catch((error) => {
      setApiError(error);
    });
  }, [enterpriseId]);

  function changeSelectedTagUuids(tagUuids: any) {
    setSelectedTagUuidsResponse(tagUuids);

    if (onChangeSelectedTagUuids) {
      onChangeSelectedTagUuids(selectedTagUuidsResponse);
    }
  }

  if (apiError) return renderRequestError(i18n);

  if (loading) return <CircleLoader />;

  const monitoredOrDbmTagItems: any = [];
  const otherTagItems: any = [];

  tagsList.forEach((tag) => {
    const tagItem = selectedTagUuidsResponse && (
      // @ts-ignore
      <TagItem
        // @ts-ignore
        key={tag.uuid}
        tag={tag}
        // @ts-ignore
        checked={selectedTagUuidsResponse.has(tag.uuid)}
        onChange={(checked: any) =>
          changeSelectedTagUuids(
            checked
              ? // @ts-ignore
                selectedTagUuidsResponse.add(tag.uuid)
              : // @ts-ignore
                selectedTagUuidsResponse.delete(tag.uuid),
          )
        }
        enterpriseId={enterpriseId}
        countryCode={countryCode}
      />
    );

    if (tag.isMonitored || tag.hasDBM) {
      monitoredOrDbmTagItems.push(tagItem);
    } else {
      otherTagItems.push(tagItem);
    }
  });

  const tags = [...monitoredOrDbmTagItems, ...otherTagItems];

  return (
    <>
      {hasMonitoring && (
        <>
          <SubHeading>{i18n.text('addToMonitoring.monitoring')}</SubHeading>
          <div>{i18n.text('addToMonitoring.monitoring.desc')}</div>
        </>
      )}

      {hasDbmAccess && (
        <>
          <SubHeading>{i18n.text('addToMonitoring.database-management')}</SubHeading>
          <div>{i18n.text('addToMonitoring.database-management.desc')}</div>
        </>
      )}

      {tags.length > 0 ? (
        <>
          <SubHeading>{`${i18n.text('lists')} (${tags.length})`}</SubHeading>
          <div>{tags}</div>
        </>
      ) : (
        <div>
          <p>{i18n.text('addToMonitoring.noMonitoredLists')}</p>
          <p>{i18n.text('addToMonitoring.noMonitoredLists.paragraph')}</p>
          <p>
            <RouterLink to="/list-management">
              {i18n.text('addToMonitoring.lists.goToListManagement')}
            </RouterLink>
          </p>
        </div>
      )}
    </>
  );
}

function CompanyMonitoringLists(props: Props) {
  const { i18n, onClose } = props;
  return (
    // @ts-ignore
    <Popup onClose={onClose} padding="0">
      <Layout>
        <Content>
          <PopupHeading>{i18n.text('addToMonitoring.heading')}</PopupHeading>
          <CompanyMonitoringListsContent {...props} />
        </Content>

        <Bottom>
          <Button primary onClick={() => onClose('clickDone')}>
            {i18n.text('addToMonitoring.done')}
          </Button>
        </Bottom>
      </Layout>
    </Popup>
  );
}

export default injectI18n(CompanyMonitoringLists);
