import { number, string } from 'prop-types';
import styled, { keyframes } from 'styled-components';
import colors from '../../constants/colors';

const loaderScale = keyframes`
  0% {
    transform: scale(0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
`;

type CircleLoaderProps = {
  size?: number;
  borderSize?: number;
  speed?: string;
  color?: string;
};

const CircleLoader = styled.div<CircleLoaderProps>`
  width: ${({ size }) => size || 56}px;
  height: ${({ size }) => size || 56}px;
  border: ${({ borderSize }) => borderSize || 4}px solid ${({ color }) => color || colors.link};
  border-radius: 50%;
  position: relative;
  animation: ${loaderScale} ${({ speed }) => speed || '1s'} ease-out infinite;
  margin: 0 auto 0 auto;
`;

export default CircleLoader;
