import React from 'react';
import styled, { keyframes } from 'styled-components';
import colors from '../../constants/colors';

const Wrapper = styled.div`
  display: inline-block;
`;

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

type SpinnerProps = {
  size?: number;
  borderSize?: number;
  speed?: string;
  color?: string;
};

const Loader = styled.div<SpinnerProps>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border: ${({ borderSize }) => borderSize}px solid ${({ color }) => color};
  border-right-color: transparent;
  border-radius: 50%;
  position: relative;
  animation: ${rotate360} ${({ speed }) => speed} linear infinite;
  top: 50%;
  margin: -${({ size }) => size / 2}px auto 0 auto;
`;

const Spinner = (
  { size = 18, borderSize = 3, speed = '0.75s', color = colors.link },
  props: SpinnerProps,
) => (
  <Wrapper>
    <Loader size={size} borderSize={borderSize} speed={speed} color={color} {...props} />
  </Wrapper>
);

export default Spinner;
