import get from 'lodash/get';
import {
  extractTradingAddress,
  extractRegisteredAddress,
  extractOldAddress,
  parsePhoneNumber,
} from '../common';
import { getFormattedAddress } from '../../../../../utils/getFormattedAddress';
export const parseLocation = ({ latitude, longitude }: any) => ({
  lat: latitude || 0,
  lon: longitude || 0,
});
export const parseWebsite = (website: any) =>
  website && !website.match(/^https?:\/\//i) ? `http://${website}` : website;
export const extractCompanyContactsData = (data: any) => {
  const { contactInformation } = get(data, 'companyProfile', {});
  const { countryCode } = get(data, 'companyIdentification', {});
  const addresses = get(data, 'companyProfile.addresses');
  const tradingAddress = extractTradingAddress(addresses);
  const registeredAddress = extractRegisteredAddress(addresses);
  const oldAddress = extractOldAddress(addresses);
  return {
    tradingAddress: getFormattedAddress(countryCode, tradingAddress),
    registeredAddress: getFormattedAddress(countryCode, registeredAddress),
    oldAddress: getFormattedAddress(countryCode, oldAddress),
    companyLocation: parseLocation(registeredAddress),
    phoneNumber: parsePhoneNumber(get(contactInformation, 'phoneNumber', {})),
    mobileNumber: parsePhoneNumber(get(contactInformation, 'mobileNumber', {})),
    faxNumber: parsePhoneNumber(get(contactInformation, 'faxNumber', {})),
    email: get(contactInformation, 'email'),
    website: parseWebsite(get(contactInformation, 'website')),
    tradeNames: get(data, 'companyProfile.tradeNames', []).sort(),
    region: get(data, 'companyProfile.miscellaneous.regionCode.descriptions'),
  };
};
