import { connect } from 'react-redux';
import { setModal } from '../../../CreditReports/shared/stores/modal/actions';
import AppModals from './component';

const mapStateToProps = ({ modal, monitoringProfiles }: any) => ({
  modal,
  monitoringProfiles: monitoringProfiles.response,
});

const mapDispatchToProps = {
  setModal,
};
export default connect(mapStateToProps, mapDispatchToProps)(AppModals);
