import styled from 'styled-components';
import { grayLight5 } from '@graydon/ui-colors';
import OriginalSearchForm from '../OneSearch/CompanySearchForm';

export const ResultWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background: white;
  flex: 1;
`;

export const InnerWrapper = styled.div<{
  flex?: number;
}>`
  width: 100%;
  max-width: 1400px;
  padding: 0 16px;
  ${({ flex }) => flex && `flex: ${flex};`}
`;

export const PageWrapper = styled.div`
  width: 100%;
  min-height: calc(100vh - 120px);
  background-color: ${grayLight5};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`;

export const SearchForm = styled(OriginalSearchForm)`
  margin-bottom: 48px;
  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
`;

export const SearchFormWrapper = styled(InnerWrapper)`
  @media (max-width: 768px) {
    padding: 0;
  }
`;
