import React, { ReactNode } from 'react';
import styled from 'styled-components';
import * as colors from '@graydon/ui-colors';

type StyleProps = {
  size?: number;
  marginTop?: number;
  marginBottom?: number;
};

type Props = StyleProps & {
  className?: string;
  children?: ReactNode;
};

const IconContainer = styled.div<StyleProps>`
  width: 100%;
  margin: ${({ marginTop, marginBottom }) => `${marginTop}px 0px ${marginBottom}px 0px;`};
  display: flex;
  justify-content: center;
  align-items: center;
`;
const IconContent = styled.div<StyleProps>`
  height: ${({ size }) => size}px;
  width: ${({ size }) => size}px;
  border: solid ${({ size }) => size / 10}px ${colors.blue};
  border-radius: 50%;
  font-size: ${({ size }) => size - 8}px;
  font-weight: bold;
  color: ${colors.blue};
  display: inline-block;
  text-align: center;
  line-height: ${({ size }) => `${size - 5}px;`};
`;

const Icon = ({
  className,
  marginTop = 13,
  marginBottom = 19,
  size = 48,
  children = '!',
}: Props) => {
  return (
    <IconContainer className={className} marginTop={marginTop} marginBottom={marginBottom}>
      <IconContent size={size}>{children}</IconContent>
    </IconContainer>
  );
};

export default Icon;
