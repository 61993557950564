export function parseCompanyCode(code: any, countryCode: any) {
  switch (countryCode) {
    case 'UK':
    case 'GB':
      return code;

    default:
      return (+code).toString();
  }
}
