import identity from 'lodash/fp/identity';
import get from 'lodash/get';

const getCountryString = (country: any) =>
  typeof country === 'string' ? country : get(country, 'value');

export const getFormattedAddress = (graydonCountry: string, address: any) => {
  if (!address) return null;
  const {
    streetName,
    street,
    houseNumber,
    houseNumberSuffix,
    postalCode,
    city,
    country,
    building,
    poBox,
    line1,
    line2,
    line3,
    line4,
    line5,
  } = address;
  const countryName = getCountryString(country);
  const graydonCountryCode = getCountryString(graydonCountry) || countryName;

  if (
    graydonCountryCode === 'GB' ||
    graydonCountryCode === 'gb' ||
    (!graydonCountryCode && address.linesFilled)
  ) {
    return (
      [poBox, line1, line2, line3, line4, line5, postalCode, countryName]
        .filter(identity)
        .join(', ') || '-'
    );
  }

  const addrLine1 = [streetName || street, houseNumber, houseNumberSuffix]
    .filter((it) => it)
    .join(' ');
  const addrLine2 = [postalCode, city].filter((it) => it).join(' ');
  return [addrLine1, building, addrLine2, countryName].filter((it) => it).join(', ') || '-';
};
