import * as colors from '@graydon/ui-colors';
import styled from 'styled-components';
import { fonts } from '../../constants/fonts';

export const Wrapper = styled.li`
  font-size: 1rem;
  width: 100%;
  padding: 1em;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  &:nth-child(2n + 1) {
    background-color: ${colors.grayLight5};
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const Info = styled.div`
  flex-grow: 1;
  overflow: hidden;

  em {
    background-color: rgba(144, 209, 224, 0.5);
    font-style: normal;
  }
`;

export const ButtonContainer = styled.div`
  flex-shrink: 0;
  min-width: 120px;
  padding: 0;
  display: flex;
  align-items: stretch;
  flex-direction: column;

  @media (max-width: 768px) {
    justify-content: flex-start;
    align-items: stretch;
    flex-direction: row;
    width: 100%;
  }
`;

export const CompanyNameWrapper = styled.div`
  margin-bottom: 0.25rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

export const CompanyName = styled.div`
  display: inline;
  color: ${colors.greenLight};
  font: ${fonts.h2};
  word-wrap: break-word;
`;

export const OrganizationType = styled.span<{
  type?: string;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 18px;
  padding: 0 6px;
  margin: 0 0 2px 0;
  border: 1px solid ${colors.blue};
  border-radius: 4px;
  font: ${fonts.small};
  cursor: default;

  ${({ type }) =>
    type === 'ROOT_ORG' &&
    `
      background-color: ${colors.blue};
      color: ${colors.white};
    `};

  ${({ type }) =>
    type === 'BRANCH_OFFICE' &&
    `
      background-color: transparent;
      color: ${colors.blue};
    `};

  ${({ type }) => !type && 'display: none'};
`;

export const Address = styled.div<{
  uppercase?: boolean;
}>`
  line-height: 1.15em;
  text-overflow: ellipsis;
  margin-top: 0;
  color: #545454;
  ${({ uppercase }) => uppercase && 'text-transform: uppercase'};
`;

export const AddressWrapper = styled.div`
  font: ${fonts.body};
  display: flex;
  margin-top: 4px;
`;

export const Label = styled(Address)`
  margin-right: 4px;
  margin-top: 0;
`;

export const CommonField = styled.div`
  white-space: nowrap;
  @media (max-width: 768px) {
    margin-right: 0;
    &:not(:first-child) {
      margin-top: 4px;
    }
    white-space: normal;
  }

  &::after {
    content: '|';
    display: inline-block;
    margin: 0 5px;

    @media (max-width: 768px) {
      content: none;
    }
  }

  &:last-child::after {
    content: none;
  }
`;

export const ExtraFields = styled.div`
  font: ${fonts.body};
  line-height: 1.2em;
  margin-bottom: 0.57em;
  color: ${colors.grayLight};
`;

export const CommonFields = styled.div`
  font: ${fonts.body};
  color: ${colors.gray};
  text-overflow: ellipsis;
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const DataWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const OrganizationTypeWrapper = styled.div`
  display: inline-block;
  margin-right: 4px;
`;

export const StatusWrapper = styled.div`
  display: inline-block;
  margin-right: 8px;
`;

export const TypeWrapper = styled.div`
  margin-bottom: 4px;
`;
