import { injectI18n, I18nProp } from 'src/i18n';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/fp/get';
import React, { useEffect, useState } from 'react';
import {
  HeaderWrapper,
  InnerWrapper,
  PageWrapper,
  ResultWrapper,
  SearchForm,
  SearchFormWrapper,
} from '../../../../components/BaseResultPage/helper-components';
import { getCountryList } from '../../../../components/OneSearch/api';
import PreferencesLink from '../../../../components/PreferencesLink';
import { useSessionStorage } from '../../../../hooks/useLocalStorage';
import { useProcessFlow } from '../../../../hooks/useProcessFlow';
import {
  hasAccessToCreditReports,
  hasAccessToXseptionReport,
  isDeliveryAdminUser,
  isDeliveryManager,
} from '../../../../keycloak';
import { inspect } from '../../../../utils/FP-helpers';
import BreadcrumbContainer, { BreadcrumbWrapper } from '../../components/BreadcrumbContainer';
import SwitchClientView from '../../components/SwitchClientView';
import { search } from './api';
import ClientSession from '../../../../components/ClientSession';
import ClientValidation from './Components/ClientValidation';
import RenderSearchResults from './Components/RenderResult';

const SearchPage: React.FC<I18nProp> = ({ i18n }) => {
  const [searchOptions, setSearchOptions] = useState({});
  const [countryList, setCountryList] = useState();
  const [countryError, setCountryError] = useState(null);
  const [result, setResult] = useState();
  const [searchState, doSearch, reset] = useProcessFlow(search);
  const [clientSession, setClientSession, setClient] = useSessionStorage('client-session');

  function resetSearch() {
    // @ts-ignore
    reset();

    setCountryList(null);

    setResult(null);
  }

  const getClientStatus = (session: any) => {
    if (!session) return 0;
    if (session && !session.consumerId) return 1;
    return 2;
  };

  function fetchCountry(session?: any) {
    setCountryList(null);
    (getClientStatus(session) === 2 ? getCountryList(session.consumerId) : getCountryList())
      .then(setCountryList)
      .catch(setCountryError);
  }

  const clientView = getClientStatus(clientSession);

  const startValidations = () => {
    setClient({});
    resetSearch();
  };

  const startSession = (client: any) => {
    setClientSession(client);
    fetchCountry(client);
  };

  const stopSession = () => {
    setClientSession(null);

    fetchCountry();
  };

  useEffect(() => {
    fetchCountry(clientSession);
  }, []);

  function submit({ searchQuery, country, city, method, state }: any) {
    setSearchOptions({
      searchQuery,
      country,
      city,
      method,
      state,
    });

    if (
      countryList &&
      // @ts-expect-error
      countryList[country].name &&
      // @ts-expect-error
      countryList[country].serviceLevel !== 'investigation'
    ) {
      // @ts-ignore
      doSearch(searchQuery, country, city, method, state)
        .then(setResult)
        .catch(inspect('ICR search error'));
    }
  }

  return (
    <PageWrapper>
      <InnerWrapper>
        <HeaderWrapper>
          <BreadcrumbWrapper>
            <BreadcrumbContainer />
          </BreadcrumbWrapper>
          {isDeliveryAdminUser() && clientView !== 2 && (
            // @ts-expect-error
            <SwitchClientView stop={stopSession} start={startValidations} isEnabled={clientView} />
          )}
          {isDeliveryManager() && (hasAccessToCreditReports() || hasAccessToXseptionReport()) && (
            <PreferencesLink
              i18n={i18n}
              to="/company-data/preferences"
              title={i18n.text('cr.preferences')}
            />
          )}
        </HeaderWrapper>
      </InnerWrapper>

      <SearchFormWrapper>
        {clientView === 1 && <ClientValidation startSession={startSession} />}

        {clientView === 2 && (
          <ClientSession clientSession={clientSession} stopSession={stopSession} />
        )}

        {(clientView === 0 || clientView === 2) && (
          <SearchForm
            countryList={countryList}
            submit={submit}
            page="ICR"
            error={Boolean(countryError)}
          />
        )}
      </SearchFormWrapper>

      <ResultWrapper>
        <InnerWrapper>
          {/* @ts-expect-error */}
          {!isEmpty(countryList) && searchOptions.country && (
            <RenderSearchResults
              // @ts-expect-error
              country={searchOptions.country}
              result={result}
              // @ts-expect-error
              loadState={searchState}
              // @ts-expect-error
              serviceLevel={get(`${searchOptions.country}.serviceLevel`, countryList)}
              isFreshInvestigationAvailable={get(
                // @ts-expect-error
                `${searchOptions.country}.availablePaygo`,
                countryList,
              )}
            />
          )}
        </InnerWrapper>
      </ResultWrapper>
    </PageWrapper>
  );
};

export default injectI18n(SearchPage);
