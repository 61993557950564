import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { serif } from '../../constants/fonts';
import colors from '../../constants/colors';

const SectionContainer = styled.div`
  background-color: ${colors.white};
  padding: 0.5em;
  margin-bottom: 1em;
`;

const SectionHeading = styled.h3`
  font-family: ${serif};
  font-size: 18px;
  font-weight: bold;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${colors.secondaryDark};
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  padding-right: 15px;
  cursor: pointer;
`;

const SectionSubHeading = styled.p`
  font-size: 14px;
  font-weight: 200;
  text-overflow: ellipsis;
  margin-top: -10px;
`;

const ExpandArrow = styled.div<{ expanded }>`
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 11px solid ${colors.navDivideBefore};
  cursor: pointer;
  transform: rotate(0deg);
  transition: transform 0.2s linear;
  ${({ expanded }) => expanded && 'transform: rotate(180deg);'};
`;

const LeftFlexColumn = styled.div`
  flex-grow: 1;
`;

type Props = {
  isExpanded;
  heading;
  subHeading;
  children;
  didUpdate;
};

const Accordion: React.FC<Props> = ({
  heading,
  subHeading = null,
  children = null,
  isExpanded = null,
  didUpdate = () => {},
}) => {
  const [expanded, setExpanded] = useState(isExpanded);

  useEffect(() => {
    if (isExpanded) setExpanded(isExpanded);
  }, []);

  useEffect(() => {
    didUpdate(expanded);
  }, [expanded]);

  return (
    <SectionContainer>
      <Header onClick={() => setExpanded((expanded) => !expanded)}>
        <LeftFlexColumn>
          <SectionHeading>{heading}</SectionHeading>
          {subHeading && <SectionSubHeading>{subHeading}</SectionSubHeading>}
        </LeftFlexColumn>
        <ExpandArrow expanded={expanded} />
      </Header>
      {children && expanded && <div>{children}</div>}
    </SectionContainer>
  );
};

export default Accordion;
