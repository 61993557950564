// @ts-nocheck
import compose from 'lodash/fp/compose';
import { parse as qsParse, stringify } from 'query-string';
import { compactObject } from '../../../utils/FP-helpers';

export const parseQueryString = (queryString = '') => qsParse(queryString);

const generateQs = compose(stringify, compactObject);

export const getUrl = ({ searchQuery, country, state, method = 'all', consumerId }: any) =>
  ['NL', 'BE'].includes(country)
    ? `/company-data?${generateQs({
        country,
        searchQuery,
        consumerId,
      })}`
    : `/international-credit-reports?${generateQs({
        country,
        state,
        method,
        searchQuery,
      })}`;
