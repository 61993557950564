import get from 'lodash/get';
export const extractFlagAndLimitData = (data: any) => {
  const groupCreditScores = get(data, 'ratingsProfile.graydonGroupCreditScores');
  return {
    creditFlag: get(groupCreditScores, 'creditFlag.value'),
    creditAmount: get(groupCreditScores, 'creditAmountAdvised.value'),
    creditAmountCurrency: get(groupCreditScores, 'creditAmountAdvised.currencyCode'),
    monthlyCreditGuide: get(groupCreditScores, 'ukMonthlyCreditGuide.value'),
    monthlyCreditGuideCurrency: get(groupCreditScores, 'ukMonthlyCreditGuide.currencyCode'),
  };
};
