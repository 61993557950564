// @ts-nocheck
import React from 'react';
import styled, { css } from 'styled-components';
import * as colors from '@graydon/ui-colors';
import fonts from 'src/core/constants/fonts';
type Props = {
  as?: string;
};
const Symbol = styled.span`
  font-size: 30px;
  font-family: ${fonts.sans};
  color: ${colors.blue};
  font-weight: 900;
`;

const displayIcon = (symbol: any) => {
  return css`
    ${Symbol}::after {
      content: '${symbol}';
    }
  `;
};

const hideIcon = () => {
  return css`
    display: none;
  `;
};

const IconContainer = styled.div`
  width: 48px;
  height: 48px;
  margin-bottom: 24px;
  border-radius: 50%;
  border: 4px solid ${colors.blue};
  text-align: center;

  @media screen and (min-width: 768px) {
    align-self: center;
  }

  ${(props: any) => {
    switch (props.as) {
      case 'info':
        return displayIcon('i');

      case 'warning':
        return displayIcon('!');

      case 'hidden':
        return hideIcon;

      default:
        return displayIcon('i');
    }
  }}
`;

const Icon = ({ as = 'info' }: Props) => {
  return (
    // @ts-ignore
    <IconContainer as={as}>
      <Symbol />
    </IconContainer>
  );
};

export default Icon;
