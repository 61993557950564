import { Divider } from '@material-ui/core';
import Spinner from 'src/core/components/loaders/Spinner';
import { I18nProp, injectI18n } from 'src/i18n';
import React, { useState } from 'react';
import styled from 'styled-components';
import sortBy from 'lodash/sortBy';
import { Button as ButtonCR } from '../../../../../../components/ButtonCR';
import FormFieldText from '../../../../../../components/FormComponents/FormFields/FormFieldText';
import Link from '../../../../../../components/Link';
import checkmark from '../../../../../../media/icons/checkmark-fff.svg';
import { get } from '../../../../../../utils/api';
import {
  ClientIdentificationResult,
  RadioButton,
  RadioButtonList,
  EmailFieldWrapper,
  FlexContainer,
  LinkWrapper,
  SpinWrapper,
  ConsumerUserWrapper,
  Wrapper,
} from './helper-components';
import ZebraList from '../../../../../../components/ZebraList';
import { PageHeader, PageDescription } from '../../../helper-components';
import { isValidEmail, onEnter } from './utils';
import { processStates } from '../../../../../../utils/processFlow';

const Checkmark = styled.div`
  background-image: url(${checkmark});
  background-repeat: no-repeat;
  background-position: center;
  height: 14px;
  width: 20px;
  cursor: pointer;
  margin-bottom: 5px;
  margin-right: 3px;

  @media (max-width: 768px) {
    display: none;
  }
`;

const Button = styled(ButtonCR)`
  width: auto;
  margin-top: 10px;
`;

const ActionWrapper = styled.div`
  margin-top: 10px;
`;

const SPINNER_SIZE = 26;

function checkEmail(email: any) {
  return get(`/user/users/email/${email}`).then((data) => {
    return get(`/user/consumers/${data.consumerId}`).then(
      ({ customer_name: customerName, consumer_name: consumerName, ...restProps }) => ({
        ...data,
        consumer: { ...restProps, customerName, consumerName },
      }),
    );
  });
}

const checkSapId = (sapId: any) =>
  get(`/user/users/consumer/${sapId}`).then((users) => sortBy(users, (user) => user.lastName));

type Props = {
  startSession: (customer: any) => any;
};

const processFlow =
  (flowCB: any) =>
  (fun: any) =>
  (...args: any[]): any => {
    flowCB(processStates.started);
    return fun(...args)
      .then((data: any) => {
        if (Array.isArray(data) && data.length === 0) flowCB(processStates.failed);
        else flowCB(processStates.successful);
        return data;
      })
      .catch((error: any) => {
        flowCB(processStates.failed);
        return Promise.reject(error);
      });
  };

function ClientIdentification({ i18n, startSession }: Props & I18nProp) {
  const [isSearchValueValid, setIsSearchValueValid] = useState(true);
  const [searchType, setSearchType] = useState('email');
  const [clientEmail, setClientEmail] = useState('');
  const [clientSapId, setClientSapId] = useState('');
  const [client, setClient] = useState(null);
  const [sapUsers, setSapUsers] = useState([]);
  const [processState, setProcessState] = useState(processStates.init);
  const sendCustomerValidationRequest = processFlow(setProcessState);

  const checkAndSendRequest = () => {
    if (searchType === 'email' && clientEmail && isSearchValueValid) {
      sendCustomerValidationRequest(checkEmail)(clientEmail)
        .then(setClient)
        .catch(() => {});
    } else if (searchType === 'sapid' && clientSapId && isSearchValueValid) {
      sendCustomerValidationRequest(checkSapId)(clientSapId)
        .then(setSapUsers)
        .catch(() => {});
    }
  };

  const selectSapId = () => {
    setSearchType('sapid');
    setClient(null);
  };

  const selectEmail = () => {
    setSearchType('email');
    setSapUsers([]);
  };

  const startSessionByUser = (user: any) => {
    checkEmail(user.email).then((data) => startSession(data));
  };

  return (
    <Wrapper>
      <PageHeader>{i18n.text('icr.search-page.client-validation.title')}</PageHeader>

      <PageDescription>
        <p>{i18n.text('icr.search-page.client-validation.description')}</p>
      </PageDescription>

      <RadioButtonList>
        <RadioButton
          label={i18n.text('icr.search-page.client-validation.email.label')}
          key="email"
          // @ts-expect-error
          checked={searchType === 'email'}
          onChange={selectEmail}
        />

        <RadioButton
          label={i18n.text('icr.search-page.client-validation.sapid.label')}
          // @ts-expect-error
          checked={searchType === 'sapid'}
          onChange={selectSapId}
        />
      </RadioButtonList>

      <FlexContainer align="flex-start" changeDirectionOnMobile justify="flex-start">
        <EmailFieldWrapper>
          {searchType === 'email' ? (
            <FormFieldText
              isRequired
              placeholder={i18n.text('icr.search-page.client-validation.email.text')}
              tooltipText={i18n.text('icr.search-page.client-validation.email.text')}
              label={i18n.text('icr.search-page.client-validation.email.label')}
              validationMessage={
                clientEmail
                  ? i18n.text('icr.search-page.client-validation.email-validation.message')
                  : i18n.text('icr.search-page.client-validation.email-empty.message')
              }
              value={clientEmail}
              isValid={processStates.failed !== processState}
              onEnterKey={(e: any) => {
                e.preventDefault();
                checkAndSendRequest();
              }}
              onBlur={() => {
                setIsSearchValueValid(isValidEmail(clientEmail));
              }}
              onEdit={(val: any) => {
                setIsSearchValueValid(isValidEmail(clientEmail));
                setClientEmail(val);
              }}
            />
          ) : (
            <FormFieldText
              isRequired
              placeholder={i18n.text('icr.search-page.client-validation.sapid.text')}
              tooltipText={i18n.text('icr.search-page.client-validation.sapid.text')}
              label={i18n.text('icr.search-page.client-validation.sapid.label')}
              validationMessage={
                clientSapId
                  ? i18n.text('icr.search-page.client-validation.sapid-validation.message')
                  : i18n.text('icr.search-page.client-validation.sapid-empty.message')
              }
              value={clientSapId}
              isValid={processStates.failed !== processState}
              onEnterKey={(e: any) => {
                e.preventDefault();
                checkAndSendRequest();
              }}
              onBlur={() => {
                setIsSearchValueValid(/^\d+$/.test(clientSapId));
              }}
              onEdit={(val: any) => {
                setIsSearchValueValid(/^\d+$/.test(clientSapId));
                setClientSapId(val);
              }}
            />
          )}
        </EmailFieldWrapper>

        {processState === processStates.started ? (
          <SpinWrapper>
            <Spinner size={SPINNER_SIZE} />
          </SpinWrapper>
        ) : (
          <LinkWrapper>
            <Link
              tabIndex="0"
              disabled={!isSearchValueValid}
              onKeyPress={onEnter(checkAndSendRequest)}
              onClick={checkAndSendRequest}
            >
              {i18n.text('icr.search-page.client-validation.validate')}
            </Link>
          </LinkWrapper>
        )}
      </FlexContainer>

      {processState === processStates.successful && client && (
        <ClientIdentificationResult>
          <div>
            <Checkmark />
          </div>
          <div>
            <h4>
              {client.consumer.customerName} - {client.firstName} {client.lastName}
            </h4>
            <div>
              {i18n.text('icr.search-page.client-validation.sap-id')}: {client.consumerId}
            </div>
          </div>
        </ClientIdentificationResult>
      )}

      {client && (
        <ActionWrapper>
          <Divider />
          <Button onClick={() => startSession(client)} primary>
            {i18n.text('icr.search-page.client-validation.start-session')}
          </Button>
        </ActionWrapper>
      )}

      {sapUsers.length > 0 && (
        <>
          <br />
          <ZebraList reverseColors>
            {sapUsers.map((user, i) => (
              <ConsumerUserWrapper key={i}>
                <div>{user.firstName}</div>
                <div>{user.lastName}</div>
                <div>{user.email}</div>
                <div>
                  <Link
                    tabIndex="0"
                    onKeyPress={onEnter(checkAndSendRequest)}
                    onClick={() => startSessionByUser(user)}
                  >
                    {i18n.text('icr.search-page.client-validation.start-session')}
                  </Link>
                </div>
              </ConsumerUserWrapper>
            ))}
          </ZebraList>
        </>
      )}
    </Wrapper>
  );
}

export default injectI18n(ClientIdentification);
