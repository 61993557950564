import * as colors from '@graydon/ui-colors';
import { sans } from 'src/core/constants/fonts';
export const styles = {
  option: (innerStyles: any, { isFocused, isSelected }: any) => {
    let backgroundColor = null;

    if (isSelected) {
      backgroundColor = colors.grayLight4;
    } else if (isFocused) {
      backgroundColor = colors.grayLight5;
    }

    return { ...innerStyles, padding: 0, paddingLeft: 5, backgroundColor };
  },
  control: (innerStyles: any, { isFocused }: any) => {
    const focusStyle = isFocused
      ? {
          outline: 'none',
          boxShadow: '0 0 0 3px rgb(1, 89, 106, 0.35)',
          borderColor: '#babfc1',
          transition: 'box-shadow 0s',
        }
      : {};
    return {
      ...innerStyles,
      boxShadow: 'none',
      ...focusStyle,
      lineHeight: 'normal',
      height: 44,
      '&:hover': {
        borderColor: '#babfc1',
      },
      '& input': {
        boxShadow: 'none',
      },
      borderColor: '#babfc1',
      paddingLeft: 8,
      // color: colors.gray,
      fontFamily: sans,
      fontWeight: 300,
    };
  },
  input: (innerStyles: any) => ({
    ...innerStyles,
    fontWeight: 300,
    fontFamily: sans,
  }),
  placeholder: (innerStyles: any) => ({
    ...innerStyles,
    color: colors.grayLight2,
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
};
export default styles;
