// @ts-nocheck
import { injectI18n } from 'src/i18n';
import React, { useEffect, useState } from 'react';
import { useProcessFlow } from '../../hooks/useProcessFlow';
import { hasAccessToCachedCoCExtract } from '../../keycloak';
import { I18nProp } from 'src/i18n';
import { sendEvent } from '../../utils/analytics';
import { sendNotification } from '../../utils/Notification';
import { isStarted } from '../../utils/processFlow';
import ReportFetchingModal from '../CRModals/ReportFetchingModal';
import Popup from '../Popup';
import { downloadReport, getCached } from './api';
import { Explanation, Link, Row, RowData, RowHeader, Title } from './helper-components';
type Props = {
  enterpriseId: number;
  onClose: () => any;
  onFinished?: () => any;
  cocNumber?: string;
};

const CocModal = ({ i18n, onClose, enterpriseId, onFinished, cocNumber }: Props & I18nProp) => {
  const [fetchCachedState, fetchCached] = useProcessFlow(getCached);
  const [downloadState, download] = useProcessFlow(downloadReport);
  const [cached, setCached] = useState([]);

  const isToday = (date: string) => i18n.date(new Date(date)) === i18n.date(new Date());

  useEffect(() => {
    if (hasAccessToCachedCoCExtract()) {
      fetchCached(enterpriseId, cocNumber)
        .then(setCached)
        .catch(() => {
          sendNotification({
            level: 'error',
            title: i18n.text(`coc.modal.fetch-cached.error.title`),
            message: i18n.text(`coc.modal.fetch-cached.error.message`),
          });
        });
    }
  }, [enterpriseId, cocNumber]);

  const doDownload = (date: any) => {
    download(enterpriseId, date, cocNumber)
      .then(() => {
        sendNotification({
          level: 'success',
          title: i18n.text(`coc.modal.download.success.title`),
          message: i18n.text('coc.modal.download.success.message'),
        });

        if (typeof onFinished === 'function') {
          onFinished();
        }
      })
      .catch((status: any) => {
        const code = (status && status.message) || '500';
        sendNotification({
          level: 'error',
          title: i18n.text(`coc.modal.download.${code === '404' ? 'not-found' : 'error'}.title`),
          message: i18n.text(
            `coc.modal.download.${code === '404' ? 'not-found' : 'error'}.message`,
          ),
        });
      });
  };

  const onClickFresh = (e: any) => {
    e.preventDefault();
    sendEvent('KVK', 'fresh report');

    doDownload();
  };

  const onClickCache = (e: any, date: any) => {
    e.preventDefault();
    sendEvent('KVK', 'cached report');
    doDownload(date);
  };

  const renderRow = (onClick: any, type: any, date?: any) => (
    <Row key={date || 'today'}>
      <RowHeader hideInDesktop>{i18n.text('coc.modal.date')}</RowHeader>
      <RowData width="30%">
        {/* @ts-ignore */}
        {!date || isToday(date) ? i18n.text('coc.modal.today') : i18n.date(new Date(date))}
      </RowData>

      <RowHeader hideInDesktop>{i18n.text('coc.modal.type')}</RowHeader>
      <RowData width="40%">{i18n.text(`coc.modal.${type}`)}</RowData>

      <RowHeader hideInDesktop>{i18n.text('coc.modal.link')}</RowHeader>
      <RowData width="30%">
        <Link onClick={onClick}>{i18n.text('coc.modal.buy')}</Link>
      </RowData>
    </Row>
  );

  if (isStarted(fetchCachedState) || isStarted(downloadState)) {
    return <ReportFetchingModal />;
  }

  return (
    <Popup onClose={onClose}>
      <Title>{i18n.text('coc.modal.title')}</Title>
      <Explanation>{i18n.text('coc.modal.explanation')}</Explanation>
      <Row hideInMobile>
        <RowHeader width="30%">{i18n.text('coc.modal.date')}</RowHeader>
        <RowHeader width="40%">{i18n.text('coc.modal.type')}</RowHeader>
        <RowHeader width="30%">{i18n.text('coc.modal.link')}</RowHeader>
      </Row>
      {renderRow(onClickFresh, 'new')}
      {cached.map(({ date }) => renderRow((e: any) => onClickCache(e, date), 'cached', date))}
    </Popup>
  );
};

export default injectI18n(CocModal); // as StatelessFunctionalComponent<Props>
