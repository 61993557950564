import React from 'react';
import styled from 'styled-components';
import Normal from './Normal';
import Unknown from './Unknown';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 20px auto;
`;

Wrapper.displayName = 'PaymentBehaviour';

type PaymentBehaviourProps = {
  value?: number;
};

const PaymentBehaviour = ({ value, ...props }: PaymentBehaviourProps) => {
  const Image = value ? Normal : Unknown;
  return (
    <Wrapper>
      <Image {...props} />
    </Wrapper>
  );
};

export default PaymentBehaviour;
