import styled from 'styled-components';
import * as colors from '@graydon/ui-colors';

const ZebraList = styled.ul<{
  reverseColors?: boolean;
  bgColor?: string;
}>`
  padding: 0;
  margin: 0;
  list-style: none;
  width: 100%;

  li {
    &:nth-child(even) {
      background-color: ${({ reverseColors, bgColor }) => {
        let baseBgColor = colors.white;
        if (bgColor) baseBgColor = bgColor;
        return reverseColors ? baseBgColor : colors.grayLight5;
      }};
    }
    &:nth-child(odd) {
      background-color: ${({ reverseColors, bgColor }) => {
        let baseBgColor = colors.white;
        if (bgColor) baseBgColor = bgColor;
        return reverseColors ? colors.grayLight5 : baseBgColor;
      }};
    }
  }
`;

export default ZebraList;
