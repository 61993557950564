import React from 'react';
import { useDispatch } from 'react-redux';
import { I18nProp, injectI18n } from 'src/i18n';
import { CountryCode } from 'src/types/locales';
import AvailabilityPopup from '../../../../components/AvailabilityPopup';
import SearchResultsList from '../../../../components/SearchResultsList';
import SearchResultsListItem from '../../../../components/SearchResultsListItem';
import { sendEvent } from '../../../../utils/analytics';
import { popupCompanySet } from '../../store/internationalCreditReports';
import { Button } from './helper-components';

type SearchResultsProps = {
  total?: number;
  items?: {
    id: string | number;
    companyName: string;
    address: string;
    commonFields: string[];
  }[];
  isFreshInvestigationAvailable?: boolean;
  downloadStep?: number;
  country?: CountryCode;
};

function SearchResults({
  i18n,
  total = 0,
  items,
  isFreshInvestigationAvailable = false,
  downloadStep,
  country,
}: SearchResultsProps & I18nProp) {
  const dispatch = useDispatch();

  const openPopup = (
    companyName: any,
    address: any,
    companyIdentifier: any,
    isDatabaseReportAvailable: any,
  ) => {
    dispatch(
      popupCompanySet({
        companyName,
        address,
        companyIdentifier,
        isDatabaseReportAvailable,
        countryCode: country,
      }),
    );
  };

  return (
    items && (
      <>
        <SearchResultsList
          total={total}
          countryCode={country}
          showFreshInvestigationLinkOnNoResults={isFreshInvestigationAvailable}
        >
          {items.map(
            ({ id, companyName, address, companyIdentifier, isDatabaseReportAvailable }: any) => (
              <SearchResultsListItem
                key={id}
                appType="ICR"
                companyName={companyName}
                commonFields={companyIdentifier}
                address={address}
                buttons={
                  <>
                    <Button
                      onClick={() => {
                        openPopup(
                          companyName,
                          address,
                          companyIdentifier,
                          isDatabaseReportAvailable,
                        );
                        sendEvent('ICR', 'Search results: Check availability btnclick');
                      }}
                      primary
                    >
                      {i18n.text('icr.search-results.button.check-availability')}
                    </Button>
                  </>
                }
              />
            ),
          )}
        </SearchResultsList>

        <AvailabilityPopup
          isFreshInvestigationAvailable={isFreshInvestigationAvailable}
          downloadStep={downloadStep}
        />
      </>
    )
  );
}

export default injectI18n(SearchResults);
