// @ts-nocheck
import { createSlice } from '@reduxjs/toolkit';
import { post } from '../utils/api';
import { getClientSession } from '../utils';
import { getUserData } from '../apps/InternationalCreditReports/shared/selectors/userData';
const slice = createSlice({
  name: 'credit-reports',
  initialState: {
    creditReportFetching: false,
    creditReportFetchError: false,
  },
  reducers: {
    creditReportIdRequested: (state) => {
      state.creditReportFetching = true;
    },
    creditReportIdRespond: (state, action) => {
      state.creditReportFetching = false;
      state.creditReportFetchError = action.payload != null;
    },
    creditReportResetCreationError: (state) => {
      state.creditReportFetching = false;
      state.creditReportFetchError = false;
    },
  },
});
export const { creditReportIdRequested, creditReportIdRespond, creditReportResetCreationError } =
  slice.actions;
export default slice.reducer;
export function createCreditReport(enterpriseId: any) {
  const clientSession = getClientSession();
  return post('/credit-reports/reports/', {
    enterpriseId,
    ...(clientSession
      ? {
          userData: getUserData(clientSession),
        }
      : {}),
  });
}
export const requestReportId = (enterpriseId: any) => (dispatch: any) => {
  dispatch(creditReportIdRequested());
  return createCreditReport(enterpriseId)
    .then((report) => {
      const { id } = report;

      if (!id) {
        throw new Error('Response does not contain expected id');
      }

      dispatch(creditReportIdRespond());
      return id;
    })
    .catch((err) => {
      dispatch(creditReportIdRespond(err));
    });
};
