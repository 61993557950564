import Menu from '@material-ui/core/Menu';
import MoreVert from '@material-ui/icons/MoreVert';
import compose from 'lodash/fp/compose';
import getOr from 'lodash/fp/getOr';
import React, { forwardRef, useState } from 'react';
import { sendEvent } from '../../utils/analytics';
import { inspect, isInList } from '../../utils/FP-helpers';
import ButtonCR from '../ButtonCR';
import { IconWrapper, ItemWrapper, Label, MenuDivider, MenuItem } from './helper-components';
import { ReportProps } from './index';
import { reportOptions } from './reportOptions';
import { OptionType } from './reportOptions';

type MenuProps = ReportProps & {
  loadProcess: any;
  setMonitoringModal: any;
  setCocModal: any;
  setGdmModal: any;
  container?: any;
  clicked?: any;
  setAnnualAccountModal;
};

export const ReportMenu: React.FC<MenuProps> = ({
  i18n,
  history,
  context,
  app,
  company,
  exclude = [],
  className,
  loadProcess,
  setMonitoringModal,
  setCocModal,
  setAnnualAccountModal,
  setGdmModal,
  openFromLeft,
  openInside,
  container,
  clicked,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const closeMenu = () => setAnchorEl(null);

  const onClickMenu = compose(
    () => sendEvent(app, `report-menu-${context}: click the menu button`),
    setAnchorEl,
    getOr(null, 'currentTarget'),
  );

  const onItemClick = (type: any, action: any) => {
    switch (type) {
      case 'international-credit-report':
        return action().then(() => {
          clicked(type, action);
        });

      case 'add-to-monitoring':
        return action().then(setMonitoringModal);

      case 'coc-extract':
        return action().then(setCocModal);

      case 'annual-account':
        return action().then(setAnnualAccountModal);

      case 'gdm-check':
        return action().then(setGdmModal);

      default:
        return loadProcess(action)().then(history.push);
    }
  };

  const RenderMenuItem = forwardRef(({ label, icon, action, type }: OptionType, ref: any) => (
    <MenuItem
      ref={ref}
      onClick={() => {
        closeMenu();
        onItemClick(type, action).catch(inspect('menu-item-error'));
      }}
    >
      <ItemWrapper>
        <IconWrapper>{icon}</IconWrapper>

        <Label>{label}</Label>
      </ItemWrapper>
    </MenuItem>
  ));

  const excluded = isInList(exclude);
  const menuItems = reportOptions(company, i18n, context, app)
    .filter(({ access, type }: OptionType) => access && !excluded(type))
    .map((option, i) => [i ? <MenuDivider /> : null, <RenderMenuItem key={i} {...option} />]);
  return (
    <>
      <ButtonCR
        className={className}
        width="auto"
        withText
        minWidth="82px"
        iconButton
        onClick={onClickMenu}
        data-testid="report-menu-button"
      >
        {i18n.text('cr.search.more')}

        <MoreVert />
      </ButtonCR>

      <Menu
        id="company-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: openFromLeft ? 'left' : 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: openFromLeft ? 'left' : 'right',
        }}
        getContentAnchorEl={null}
        // @ts-ignore
        container={container || (openInside && (() => anchorEl && anchorEl.parentNode))}
      >
        {menuItems}
      </Menu>
    </>
  );
};
export default ReportMenu;
