import * as colors from '@graydon/ui-colors';
import breakpoints from 'src/core/constants/breakpoints';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { Tabs as MaterialTabs, Tab as MaterialTab, withStyles } from '@material-ui/core';

export const Wrapper = styled.div`
  background-image: linear-gradient(to bottom, #00545d 0%, #ebf7f7);
  background-size: 100%;
  flex-grow: 1;
  flex-shrink: 0;
  padding-top: 0;
`;

export const AppsHeading = styled.h1`
  font-size: 22px;
  font-weight: 500;
  color: white;
  margin: 24px 16px 16px 16px;
`;

export const HeaderWrapper = styled.div`
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 56px;
  @media (max-width: 768px) {
    padding-left: 0;
  }
`;

export const Content = styled.div`
  max-width: 1400px;
  margin: 0 auto;
`;

export const Title = styled.h1`
  text-align: center;
  margin: 1em 0;
  color: #fff;

  @media screen and (max-width: ${breakpoints['portrait-min']}px) {
    font-size: 24px;
  }
`;

export const GridContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 12px;
  padding-top: 0;
  margin-bottom: 24px;
`;

const tileStyle = css`
  min-width: 440px;
  margin: 4px;
  padding: 24px;
  flex: 1;
  @media screen and (max-width: ${breakpoints['portrait-min']}px) {
    padding: 16px;
    margin: 4px 0;
    min-width: calc(100% - 8px);
  }
`;

export const Tile = styled(Link)`
  ${tileStyle}
  position: relative;
  background-color: #fff;
  border-radius: 4px;
  text-decoration: none;
  text-align: center;
  color: #00545d;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
`;

export const SpaceReserve = styled.div`
  ${tileStyle}
  margin: 0 4px;
  padding: 0 24px;
  @media screen and (max-width: ${breakpoints['portrait-min']}px) {
    padding: 0 16px;
  }
`;

export const HrefTile = Tile.withComponent('a');

export const TileName = styled.h3`
  max-width: 100%;
  font-size: 18px;
  font-weight: 500;
  color: ${colors.greenLight};
  margin: 0;

  @media screen and (max-width: ${breakpoints['portrait-min']}px) {
    text-align: left;
    flex-grow: 1;
  }
`;

export const TileSvg = styled.svg`
  fill: #00545d;
  height: 40px;
  width: 40px;
  min-width: 40px;
  margin: 0 1em 0 0;

  @media screen and (max-width: ${breakpoints['portrait-min']}px) {
  }
`;

export const ExternalIcon = styled.img`
  filter: brightness(0) saturate(100%) invert(22%) sepia(15%) saturate(7499%) hue-rotate(159deg)
    brightness(91%) contrast(101%); // #00545D
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;
  height: 20px;
`;

export const HomeSearchFormWrapper = styled.div<{
  hasTabs?: boolean;
}>`
  background: ${colors.white};
  border-radius: 4px;
  margin-left: 16px;
  margin-right: 16px;
  @media (max-width: ${breakpoints['portrait-min']}px) {
    margin-left: 0;
    margin-right: 0;
  }
  ${({ hasTabs = true }) => hasTabs && 'border-top-left-radius: 0;'}
`;

export const AppInfo = styled.div`
  text-align: left;
  @media screen and (min-width: ${breakpoints['portrait-min'] + 1}px) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const Description = styled.div`
  font-size: 16px;
  color: ${colors.gray};
  margin-top: 3px;
  @media screen and (min-width: ${breakpoints['portrait-min'] + 1}px) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const Tabs = withStyles({
  root: {
    background: colors.white,
    padding: '0 8px',
    borderRadius: '4px 4px 0 0',
    alignSelf: 'flex-end',
  },
  indicator: {
    backgroundColor: colors.greenLight,
    height: '3px',
    transform: 'scale(0.8, 1)',
  },
})(MaterialTabs);

export const Tab = withStyles({
  root: {
    opacity: 1,
    minWidth: 136,
  },
  wrapper: {
    fontSize: '14px',
    flexDirection: 'row',
    textTransform: 'none',
    color: colors.greenLight,
    opacity: 1,
    '& svg': {
      marginRight: 8,
    },
  },
  labelIcon: {
    minHeight: 48,
  },
})(MaterialTab);
