import compose from 'lodash/fp/compose';
import prop from 'lodash/fp/getOr';
import pick from 'lodash/fp/pick';
import { stringify } from 'query-string';
import { getConsumerId } from '../../keycloak';
import { get, post } from '../../utils/api';
import { wait } from '../../utils/mock';

type DecisionModelType = {
  id: string;
  name: string;
};

export function getDecisionModels(countryCode: string): Promise<DecisionModelType[]> {
  return get(`/decisionmodel/models?country=${countryCode}&active=true`)
    .then(prop({}, 'data'))
    .then((result) => result.map(compose(pick(['id', 'name']), prop({}, 'tree'))));
}

// the service doesn't return the response in the check request.
// instead it returns a redirect request and we have to check the URL
// multiple times till it returns the data. (I found it in the old code)
function checkStatus(url: any, tryCount = 30) {
  return get(url)
    .then(prop('invalid-id', 'id'))
    .catch((e) =>
      tryCount && e.message === '304'
        ? wait(1000).then(() => checkStatus(url, tryCount - 1))
        : Promise.reject(e),
    );
}

export function requestCheck(
  model: string,
  orderValue: string,
  companyId: string,
  countryCode: string,
) {
  return (
    post(
      `/decisionmodel/models/${model}/evaluations`,
      {
        orderValue,
        company: {
          companyId,
          countryCode,
        },
      },
      false,
    )
      .then((response) => response.headers.get('location'))
      .then(checkStatus) // It seems the service sometimes needs a delay to create the archive version
      // actually it returns the data in this step but we don't use them.
      // We use tha archive version in the result page to reduce the complexity
      .then((result) => wait(500).then(() => result))
  );
}

export function getArchive(
  companyId: number,
  enterpriseId: number,
  consumerId: string = getConsumerId(),
) {
  const qs = stringify({
    companyId: companyId || 0,
    enterpriseId,
    count: 11,
  });
  return get(`/decisionmodel/consumers/${consumerId}/archive?${qs}`).then((result = []) =>
    // @ts-ignore
    result.map(({ product = {}, ...data }) => ({ ...data, outcome: product.outcome })),
  );
}
