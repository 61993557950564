import React from 'react';
import { injectI18n, I18nProp } from 'src/i18n';
import { Footer, FooterWrapperMain, FooterList, ListItem, FooterText } from './helper-components';
import Link from '../Link';
import { getCountryOfResidence, getConsumerId } from '../../keycloak';
import { getLink, TERMS_AND_CONDITIONS, GDPR, COOKIES } from './utils';

type Props = {
  bgColor: string;
};

const FooterWrap = ({ i18n, bgColor }: Props & I18nProp) => {
  const consumerCountry = getCountryOfResidence(getConsumerId());
  const language = i18n.locale;
  return (
    <Footer bgColor={bgColor}>
      <FooterWrapperMain>
        <FooterList>
          <ListItem>
            <Link
              secondary
              href={getLink(TERMS_AND_CONDITIONS)(consumerCountry)(language)}
              target="_blank"
            >
              {i18n.text('footer.terms.and.conditions')}
            </Link>
          </ListItem>
          <ListItem>
            <Link secondary href={getLink(GDPR)(consumerCountry)(language)} target="_blank">
              {i18n.text('footer.gdpr')}
            </Link>
          </ListItem>
          <ListItem>
            <Link secondary href={getLink(COOKIES)(consumerCountry)(language)} target="_blank">
              {i18n.text('footer.cookies')}
            </Link>
          </ListItem>
        </FooterList>

        <FooterText>© Graydon. All rights reserved.</FooterText>
      </FooterWrapperMain>
    </Footer>
  );
};

export default injectI18n(FooterWrap);
