import React from 'react';
import useI18n from './useI18n';

function getDisplayName<P>(component: React.ComponentType<P>) {
  return component.displayName || component.name || 'Component';
}

export default function injectI18n<P>(Component: React.ComponentType<P>): React.ComponentType<P> {
  function WrapperComponent(props: P) {
    const i18n = useI18n();
    return <Component {...props} i18n={i18n} />;
  }

  WrapperComponent.displayName = `injectI18n(${getDisplayName(Component)})`;
  return WrapperComponent;
}
