// @ts-nocheck
import { injectI18n } from 'src/i18n';
import React, { useEffect, useState } from 'react';
import { useProcessFlow } from '../../hooks/useProcessFlow';
import { I18nProp } from 'src/i18n';
import { sendEvent } from '../../utils/analytics';
import { sendNotification } from '../../utils/Notification';
import { isFailed, isStarted } from '../../utils/processFlow';
import ReportFetchingModal from '../CRModals/ReportFetchingModal';
import Popup from '../Popup';
import { downloadArchiveReport, downloadReport, getList } from './api';
import { Explanation, Link, NotAvailable, Row, RowData, Title } from './helper-components';
type Props = {
  enterpriseId: number;
  onClose: () => any;
  onFinished?: () => any;
  cocNumber?: string;
};

const AnnualAccountModal = ({
  i18n,
  onClose,
  enterpriseId,
  onFinished,
  cocNumber,
}: Props & I18nProp) => {
  const [fetchListState, fetchList] = useProcessFlow(getList);
  const [fetchError, setFetchError] = useState();

  const [downloadState, downloadProcess] = useProcessFlow();
  const [annualAccounts, setAnnualAccounts] = useState([]);
  useEffect(() => {
    fetchList(enterpriseId, cocNumber).then(setAnnualAccounts).catch(setFetchError);
  }, [enterpriseId, cocNumber]);

  const downloadAnnualReport = (documentId: any, year: any, archiveId: any) => {
    const download = downloadProcess(() =>
      archiveId ? downloadArchiveReport(archiveId) : downloadReport(enterpriseId, documentId, year),
    );
    download()
      .then(() => {
        sendNotification({
          level: 'success',
          title: i18n.text(`aa.modal.download.success.title`),
          message: i18n.text('aa.modal.download.success.message'),
        });

        if (typeof onFinished === 'function') {
          onFinished();
        }
      })
      .catch((status: any) => {
        sendNotification({
          level: 'error',
          title: i18n.text(`aa.modal.download.${status === '404' ? 'not-found' : 'error'}.title`),
          message: i18n.text(
            `aa.modal.download.${status === '404' ? 'not-found' : 'error'}.message`,
          ),
        });
      });
  };

  const renderRow = (id: any, year: any, archiveId: any) => (
    <Row key={year}>
      <RowData width="25%">{year}</RowData>
      <RowData width="45%">{i18n.text(`aa.modal.annual-account`)}</RowData>
      <RowData width="30%">
        {id ? (
          <Link
            onClick={(e) => {
              e.preventDefault();

              if (archiveId) {
                sendEvent('AA', 'download archive report');
              } else {
                sendEvent('AA', 'buy report');
              }

              downloadAnnualReport(id, year, archiveId);
            }}
          >
            {archiveId ? i18n.text('aa.modal.download-archive') : i18n.text('aa.modal.download')}
          </Link>
        ) : (
          <NotAvailable>{i18n.text('aa.modal.download.not-available')}</NotAvailable>
        )}
      </RowData>
    </Row>
  );

  if (isStarted(fetchListState) || isStarted(downloadState)) {
    return <ReportFetchingModal />;
  }

  if (isFailed(fetchListState)) {
    return (
      <Popup onClose={onClose}>
        {/* @ts-ignore */}
        {fetchError && fetchError.message === '404' ? (
          <>
            <Title>{i18n.text('aa.modal.fetch-list.not-found.title')}</Title>
            <Explanation>{i18n.text('aa.modal.fetch-list.not-found.message')}</Explanation>
          </>
        ) : (
          <>
            <Title>{i18n.text('aa.modal.fetch-list.error.title')}</Title>
            <Explanation>{i18n.text('aa.modal.fetch-list.error.message')}</Explanation>
          </>
        )}
      </Popup>
    );
  }

  return (
    <Popup onClose={onClose}>
      <Title>{i18n.text('aa.modal.title')}</Title>
      <Explanation>{i18n.text('aa.modal.explanation')}</Explanation>
      {annualAccounts.map(({ financialYear, id, archiveId }) =>
        renderRow(id, financialYear, archiveId),
      )}
    </Popup>
  );
};

export default injectI18n(AnnualAccountModal);
