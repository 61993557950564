// @ts-nocheck
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import { injectI18n } from 'src/i18n';
import compose from 'lodash/fp/compose';
import prop from 'lodash/fp/get';
import getOr from 'lodash/fp/getOr';
import isEmpty from 'lodash/fp/isEmpty';
import get from 'lodash/get';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import {
  getCountryOfResidence,
  hasAccessToICRPaygo,
  hasDomestic,
  onlyInternationalSearch,
} from '../../../keycloak';
import { I18nProp } from 'src/i18n';
import SearchBar from '../../SearchBar';
import {
  Description,
  ErrorWrapper,
  Form,
  Link,
  LinkWrapper,
  SearchBoxWrapper,
  Title,
} from '../helper-components';
import CountrySelect from './CountrySelect';
import StateSelect from './CountrySelect/StateSelect';
import MethodSelect from './MethodSelect';
import { getUrl, parseQueryString } from './utils';

const getFullUrl = ({ pathname, search }: any) => pathname + search;

type Props = {
  countryList: any;
  submit?: () => any;
  page?: 'Home' | 'CR' | 'ICR';
  className?: string;
  error?: boolean;
  consumerId?: number;
};

export function SearchForm({
  countryList,
  i18n,
  history,
  location,
  consumerId,
  submit = () => {
    //
  },
  page = 'Home',
  className,
  error,
}: Props & I18nProp & RouteComponentProps) {
  const [text, setText] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [selectedMethod, setSelectedMethod] = useState('');
  const [selectedCountry, setSelectedCountry] = useState(getCountryOfResidence().toUpperCase());
  const [lastSubmittedUrl, setLastSubmittedUrl] = useState('');

  const setFullState = ({ searchQuery, country, state, method }: any) => {
    setSelectedCountry(country);
    setSelectedMethod(method);
    setSelectedState(state);
    setText(searchQuery);
  };

  function checkValidCountry({ available, availablePaygo }: any = {}) {
    return available || availablePaygo;
  }

  function submitForm({ searchQuery, country, state, method }: any) {
    if (country && searchQuery) {
      const newUrl = getUrl({
        searchQuery,
        country,
        state,
        method,
        consumerId,
      });

      if ((page === 'CR' && hasDomestic(country)) || page === 'ICR') {
        submit({
          searchQuery,
          country,
          state,
          method,
        });
        setLastSubmittedUrl(newUrl);
      }

      if (lastSubmittedUrl !== newUrl) {
        // update url if needed
        history.push(
          getUrl({
            searchQuery,
            country,
            state,
            method,
            consumerId,
          }),
        );
      }
    }

    return false;
  }

  // submit the form in case of url update
  useEffect(() => {
    if (getFullUrl(location) !== lastSubmittedUrl && page !== 'Home') {
      const {
        searchQuery,
        country = selectedCountry,
        state,
        method,
      } = parseQueryString(location.search);
      setFullState({
        searchQuery,
        country,
        state,
        method,
      });

      if (!isEmpty(countryList)) {
        if (checkValidCountry(countryList[country])) {
          submitForm({
            searchQuery,
            country,
            state,
            method,
          });
        } else {
          setSelectedState('');
          setSelectedCountry('');
        }
      }
    }
  }, [getFullUrl(location), countryList]);

  const getActiveOptions = (code: any) => {
    const { searchMethods = [] } = countryList[code] || {};
    return searchMethods ? searchMethods.map(prop('method')) : null;
  };

  const getPreferredSearchMethod = (code: any) => getOr('', `${code}.preferredMethod`, countryList);

  useEffect(() => {
    if (countryList) {
      setSelectedMethod(getPreferredSearchMethod(selectedCountry) || '');
    }
  }, [selectedCountry, countryList]);
  const states = get(countryList, `${selectedCountry}.states`);

  function onSubmit(e: any) {
    e.preventDefault();
    submitForm({
      searchQuery: text,
      country: selectedCountry,
      state: selectedState,
      method: selectedMethod,
    });
  }

  const showMethodSelect = () =>
    selectedCountry &&
    countryList &&
    (onlyInternationalSearch(selectedCountry) || !hasDomestic(selectedCountry)) &&
    selectedMethod;

  return (
    <SearchBoxWrapper className={className}>
      <Title>{i18n.text('one-search.company.form.title')}</Title>
      <Description>{i18n.text('one-search.company.form.description')}</Description>
      {error && <ErrorWrapper>{i18n.text('one-search.company.form.error')}</ErrorWrapper>}
      <Form onSubmit={onSubmit}>
        <CountrySelect
          error={error}
          countriesList={countryList}
          onChange={setSelectedCountry}
          searchCountry={selectedCountry}
          showReportTypes
        />
        {!isEmpty(states) && (
          <StateSelect
            statesList={states}
            onChange={setSelectedState}
            selectedCountryState={selectedState}
            showReportTypes
          />
        )}
        <SearchBar
          className="search-bar"
          placeholder={i18n.text('one-search.company.search-bar.placeholder')}
          searchText={text}
          onChange={({ target: { value } }: any) => setText(value)}
          onReset={() => setText('')}
          autoFocus={true}
          disabled={!selectedCountry}
        />
      </Form>
      <LinkWrapper>
        {showMethodSelect() && (
          <MethodSelect
            className="method-selector"
            activeOptions={getActiveOptions(selectedCountry)}
            onChange={setSelectedMethod}
            selectedMethod={selectedMethod}
          />
        )}
        {hasAccessToICRPaygo() && (
          <Link
            disabled={!get(countryList, [selectedCountry, 'availablePaygo'], false)}
            to={`/international-credit-reports/order-investigated-report${
              selectedCountry ? `/${selectedCountry}` : ''
            }`}
          >
            <MailOutlineIcon />
            <span>{i18n.text('icr.search-page.fresh-investigation-btn')}</span>
          </Link>
        )}
      </LinkWrapper>
    </SearchBoxWrapper>
  );
}

export default compose(injectI18n, withRouter)(SearchForm);
