import React from 'react';
import { I18nProp, injectI18n } from 'src/i18n';
import keycloak from '../../keycloak';
import ErrorPage, {
  ErrorHeading,
  ErrorButton,
  ErrorMessage,
  ContactCustomerService,
} from './index';

type Props = {
  error: Error;
};

const AuthError = ({ i18n, error }: Props & I18nProp) => {
  if (error.message === 'CONSUMER_INACTIVE') {
    return (
      <ErrorPage>
        <ErrorHeading>{i18n.text('error.inactive.title')}</ErrorHeading>

        <ErrorMessage>{i18n.text('error.inactive.description')}</ErrorMessage>

        <br />

        <ContactCustomerService i18n={i18n} />

        <br />

        <ErrorButton onClick={() => keycloak.logout()}>{i18n.text('cta.logout')}</ErrorButton>
      </ErrorPage>
    );
  }

  return (
    <ErrorPage>
      <ErrorHeading>{i18n.text('error.unknown.title')}</ErrorHeading>

      <ErrorMessage>{i18n.text('error.unknown.description')}</ErrorMessage>

      <br />

      <ContactCustomerService i18n={i18n} />

      <br />

      <ErrorButton onClick={() => keycloak.logout()}>{i18n.text('cta.logout')}</ErrorButton>
    </ErrorPage>
  );
};

export default injectI18n(AuthError);
