import get from 'lodash/get';
import { extractBranchesData } from '../extractBranchesData';
export const extractCompanyBoxData = (data: any) => {
  const { companyIdentification, companyProfile } = data;
  const countryCode = get(companyIdentification, 'countryCode');
  return {
    countryCode,
    companyName: get(companyProfile, 'companyName', null),
    kvkNumber: get(companyIdentification, 'registrationId', null),
    vatNumber: get(companyIdentification, 'vatNumber', null),
    graydonNumber: get(companyIdentification, 'enterpriseId', null),
    previousCompanyName: get(companyProfile, 'previousCompanyName', ''),
    branches: extractBranchesData(data),
    tradeNames: get(companyProfile, 'tradeNames', []).map((name, i) => ({
      key: i,
      value: name,
    })),
  };
};
