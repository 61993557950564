import React, { LinkHTMLAttributes } from 'react';
import { bool, func, string } from 'prop-types';
import styled from 'styled-components';
import mapProps from 'recompose/mapProps';
import omit from 'lodash/omit';
import colors from '../../constants/colors';

function createLinkComponent(componentType) {
  return styled<{
    secondary?: boolean;
    beforeContent?: string;
  }>(componentType)`
    color: ${({ secondary }) => (secondary ? 'inherit' : colors.link)};
    text-decoration: none;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }

    &:active {
      color: ${({ secondary }) => (secondary ? 'inherit' : colors.linkDark)};
    }

    ${({ beforeContent }) =>
      beforeContent &&
      `&::before {
        content: '${beforeContent}';
        display: inline-block;
        margin-right: 3px;
      }`};
  `;
}

const ALink = createLinkComponent('a');
// LinkLink is instantiated when Link is passed in the props
let LinkLink;

type LinkProps = {
  link?: any;
  secondary?: boolean;
  beforeContent?: string;
  children?: React.ReactNode;
  onClick?: () => void;
  to?: string;
  href?: string;
};

const Link: React.FC<LinkProps> = (props) => {
  if (props.link) {
    if (!LinkLink) {
      // we have to filter out all props that can't go on an <a> element, or react will complain
      LinkLink = createLinkComponent(
        mapProps((unmappedProps) => omit(unmappedProps, ['link', 'secondary', 'beforeContent']))(
          props.link,
        ),
      );
    }

    return <LinkLink {...props} />;
  }

  return <ALink {...props} />;
};

Link.defaultProps = {
  link: undefined,
  secondary: false,
  beforeContent: undefined,
};

export default Link;
