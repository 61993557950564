import React from 'react';
import { injectI18n } from 'src/i18n';
import { I18n } from 'src/i18n';
import { Label } from '../helper-components';

type Props = {
  i18n: I18n;
  isRequired?: boolean;
  text?: string;
};

export const FormFieldLabel = ({ i18n, isRequired = false, text }: Props) =>
  isRequired ? (
    <Label isRequired={isRequired} title={i18n.text('icr-form-field-required')}>
      <span
        style={{
          height: '16px',
        }}
      >
        {text}
      </span>
    </Label>
  ) : (
    <Label>{text}</Label>
  );

export default injectI18n(FormFieldLabel);
