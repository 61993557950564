import createReducer from 'create-reducer-map';
import { FETCH_PROFILES_REQUEST, FETCH_PROFILES_RESPONSE } from './constants';
export const initialState = {
  response: null,
  status: null,
};
export default createReducer(initialState, {
  [FETCH_PROFILES_REQUEST]: (state: any) => ({
    ...state,
    status: 'loading',
  }),
  [FETCH_PROFILES_RESPONSE]: {
    next: (state, response) => ({
      response,
      status: null,
    }),
    throw: (state: any) => ({
      ...state,
      status: 'error',
    }),
  },
});
