import styled from 'styled-components';
import breakpoints from '../../constants/breakpoints';

const DropdownButton = styled.button`
  padding: 10px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  overflow: visible;

  @media screen and (max-width: ${breakpoints['portrait-min']}px) {
    display: none;
  }
`;

export default DropdownButton;
