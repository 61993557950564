// @ts-nocheck
import get from 'lodash/get';
import range from 'lodash/range';
import omit from 'lodash/omit';
export const getCurrentQuarter = (date: any) => Math.floor(date.getMonth() / 3 + 1);

const decreaseQuarter = (point: any) => {
  point.quarter -= 1;

  if (point.quarter === 0) {
    point.year -= 1;
    point.quarter = 4;
  }

  return point;
};

export const getEightLastQuarters = (date: any, countryCode: any) => {
  const current = {
    year: date.getFullYear(),
    quarter: getCurrentQuarter(date),
  };
  const isFirstMonthOfQuarter = date.getMonth() === (current.quarter - 1) * 3;

  if (countryCode === 'BE' && isFirstMonthOfQuarter) {
    decreaseQuarter(current);
  }

  const list = [{ ...current }];
  return range(7).reduce((acc) => {
    decreaseQuarter(current);
    return acc.concat({ ...current });
  }, list);
};
export const extractPaymentTermsData = (data: any, date = new Date()) => {
  const countryCode = get(data, 'companyIdentification.countryCode');
  const matrix = get(data, 'paymentBehaviourProfile.paymentTermsMatrix', []);
  const terms = getEightLastQuarters(date, countryCode).map((point) => {
    const rowData = omit(
      matrix.find((row: any) => row.year === point.year && row.quarter === point.quarter) || {},
      ['amountWithinTerms', 'currencyCode'],
    );
    const { amountCategory: { description } = {}, ...other } = rowData;
    return {
      amountCategory: description,
      ...other,
      ...point,
    };
  });
  const numberOfSuppliers = get(
    data,
    'paymentBehaviourProfile.paymentData.numberOfSuppliers',
    null,
  );
  return {
    id: Math.random(),
    terms,
    noDataAvailable: matrix.length === 0,
    numberOfSuppliers,
  };
};
