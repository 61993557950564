import React from 'react';
import { I18nProp } from 'src/i18n';
import styled from 'styled-components';
import Link from '../Link';

const SectionHeader = styled.h3`
  margin: 10px 10px 10px 16px;
  font-weight: 400;
  font-size: 12px;
  text-transform: uppercase;
`;

const LinkWrapper = styled.div`
  padding: 0px 10px 10px 16px;
  font-size: 12px;
`;

type HelpBlockProps = {
  onContactsClick: () => void;
};

function HelpBlock({ i18n, onContactsClick }: HelpBlockProps & I18nProp) {
  return (
    <div>
      <SectionHeader>{i18n.text('general.customerCare')}</SectionHeader>
      <LinkWrapper>
        <Link onClick={onContactsClick}>{i18n.text('general.contactGraydon')}</Link>
      </LinkWrapper>
    </div>
  );
}

export default HelpBlock;
