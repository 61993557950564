import { injectI18n } from 'src/i18n';
import compose from 'lodash/fp/compose';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import Link from '../../../../../components/Link';
import { I18nProp } from 'src/i18n';
import { InvestigationMessage, Message } from '../helper-components';

const NoPaygo = ({ i18n, history }: I18nProp & RouteComponentProps) => (
  <InvestigationMessage>
    <Message>{i18n.text('icr.search-page.service-level-investigation.no-paygo')}</Message>
    <Link
      onClick={() => {
        history.push('/contact');
      }}
    >
      {i18n.text('general.contactGraydon')}
    </Link>
  </InvestigationMessage>
);

export default compose(injectI18n, withRouter)(NoPaygo);
