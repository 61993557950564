import React from 'react';
type Props = {
  color: string;
  width: number;
  height: number;
};
export const SuccessIcon = ({ color, width, height }: Props) => (
  <svg
    viewBox="0 0 24 24"
    style={{
      width,
      height,
    }}
  >
    <path fill="none" d="M0 0h24v24H0z" />

    <path fill={color} d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z" />
  </svg>
);
export const InformationIcon = ({ color, width, height }: Props) => (
  <svg
    viewBox="0 0 24 24"
    style={{
      width,
      height,
    }}
  >
    <path fill="none" d="M0 0h24v24H0V0z" />

    <path
      fill={color}
      d="M11 15h2v2h-2zm0-8h2v6h-2zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99
       2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"
    />
  </svg>
);
export const DangerIcon = ({ color, width, height }: Props) => (
  <svg
    viewBox="0 0 24 24"
    style={{
      width,
      height,
    }}
  >
    <path d="M0 0h24v24H0z" fill="none" />

    <path d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z" fill={color} />
  </svg>
);
