import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { Action } from 'redux-actions';
import { extractCountries } from './searchPage';
import { get } from '../../../utils/api';

const slice = createSlice({
  name: 'investigatedReport',
  initialState: { initial: true },

  reducers: {
    consumerCountriesListActionSet: (state, action: Action<unknown>) => {
      Object.keys(state).forEach((key) => delete state[key]);
      Object.assign(state, action.payload);
    },
  },
});

export const { consumerCountriesListActionSet } = slice.actions;

export const investigatedReportReducer = slice.reducer;

export const fetchConsumerCountries = (dispatch: Dispatch) => (consumerId: any) => {
  const url = `/international-business/country-list${
    consumerId ? `?consumerId=${consumerId}` : ''
  }`;
  return get(url).then((response) => {
    const { countries = [] } = response;
    dispatch(consumerCountriesListActionSet(extractCountries(countries)));
  });
};
