import styled from 'styled-components';
export const Container = styled.div`
  width: 720px;
  font-size: 16px;
  font-weight: 300;
  text-align: center;
  padding: 30px 0px;
  color: #545454;

  a {
    color: #168fa7;
  }

  @media (max-width: 768px) {
    padding: 10px 20px;
  }
`;
export const IconContainer = styled.div`
  width: 100%;
  text-align: center;
  margin: 13px 0 19px 0;
`;
export const HeaderIcon = styled.div`
  height: 52px;
  width: 52px;
  display: block;
  border: solid 4px #168fa7;
  border-radius: 25px;
  margin: 0 auto;
  font-size: 40px;
  font-weight: bold;
  color: #168fa7;
  line-height: 45px;
`;
export const Title = styled.div`
  margin-bottom: 30px;
  color: #1b6d75;
  font-size: 26px;
  font-weight: 500;
  text-align: center;
`;
