import * as React from 'react';
import styled from 'styled-components';
import * as colors from '@graydon/ui-colors';
import fonts from 'src/core/constants/fonts';
import { Heading1, Heading2 } from 'src/core/components/Heading';
import Button from 'src/core/components/Button';
import { RouterLink } from '../Link';
import Icon from './Icon';
import ContactDetails from '../../apps/Contact/ContactDetails';
import BackButton from '../BackButton';
import { I18n } from 'src/i18n';

const ContentWrapper = styled.div`
  flex: 1;
  background-color: ${colors.grayLight5};
`;

const ContentWrapperInner = styled.div<{
  size: string;
}>`
  width: 100%;
  margin: 16px auto 0;
  max-width: ${({ size }) => (size === 'extended' ? '1368px' : '816px')};

  @media screen and (min-width: 768px) {
    padding: 0 16px;
  }
`;

const InformationContainer = styled.div`
  min-height: 100%;
  background-color: ${colors.white};
  border: 1px solid ${colors.grayLight4};
  border-radius: 4px;
`;

const InformationContainerInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 24px 16px;
  margin: 0 auto;
  max-width: 816px;

  @media screen and (min-width: 768px) {
    padding: 2em;
  }
`;

export const InformationHeading = styled(Heading1)`
  margin-bottom: 15px;
  font-weight: 500;
  font-size: 26px;
  font-family: ${fonts.sans};
  color: ${colors.greenLight};
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @media screen and (min-width: 768px) {
    font-size: 32px;
    text-align: center;
  }
`;

export const InformationMessage = styled.p`
  font-size: 16px;
  font-weight: 300;
  color: ${colors.gray};
  max-width: 752px;
  margin-top: -8px;

  @media screen and (min-width: 768px) {
    text-align: center;
    margin: -8px auto 0;
  }
`;

export const InformationButton = styled(Button)`
  margin-top: 15px;
`;

export const InformationLink = styled(RouterLink)`
  margin-top: 20px;
  color: ${colors.blue};
  text-decoration: underline;

  @media screen and (min-width: 768px) {
    text-align: center;
  }
`;

export const CustomIcon = Icon;
export const ContactCustomerService = ({ i18n }: { i18n: I18n }) => (
  <div>
    <Heading2>{i18n.text('error.contact-support')}</Heading2>

    <ContactDetails i18n={i18n} />
  </div>
);

type Props = {
  children: React.ReactNode;
  icon?: React.ReactNode;
  size: string;
  hideBackButton?: boolean;
};

export default function InformationPage({
  children,
  icon = <Icon />,
  size = 'normal',
  hideBackButton,
}: Props) {
  return (
    <ContentWrapper>
      <ContentWrapperInner size={size}>
        {hideBackButton ? null : <BackButton />}

        <InformationContainer>
          <InformationContainerInner>
            {icon}
            {children}
          </InformationContainerInner>
        </InformationContainer>
      </ContentWrapperInner>
    </ContentWrapper>
  );
}
