import * as colors from '@graydon/ui-colors';
import OriginalSelect from 'react-select';
import OriginalCircleLoader from 'src/core/components/loaders/CircleLoader';
import styled from 'styled-components';
import searchIcon from '../../../../media/icons/search.svg';
import arrowDown from '../../../../media/icons/select-arrow-down.svg';

export const Wrapper = styled.div`
  position: relative;
  font-size: 1rem;
`;

export const SearchWrapper = styled.div<{
  isExpanded?: boolean;
}>`
  display: flex;
  flex-wrap: nowrap;
  border-radius: 4px;
  border: 1px solid ${colors.grayLight2};
  ${({ isExpanded }) =>
    isExpanded &&
    `
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
  `};
`;

export const Input = styled.input`
  flex-grow: 1;
  height: 40px;
  margin: 0 0 0 10px;
  padding: 0;
  border: none;
  outline: none;
  font-size: 1rem;
  color: black;
  border-radius: 4px;
  min-width: 0;
  cursor: pointer;

  &::-ms-clear {
    display: none;
  }

  + div {
    ::after {
      position: relative;
      display: inline-block;
      top: 8px;
      left: 13px;
      width: 12px;
      height: 7px;
      content: url(${arrowDown});
    }
  }

  :focus + div {
    ::after {
      display: inline-block;
      position: relative;
      top: 5px;
      left: 5px;
      width: 30px;
      height: 30px;
      transform: scale(0.8);
      content: url(${searchIcon});
    }
  }
`;

export const Icon = styled.div`
  height: 40px;
  width: 40px;
`;

export const CompaniesList = styled.div`
  position: absolute;
  width: 100%;
  margin: 0;
  padding: 0;
  border: 1px solid ${colors.grayLight2};
  border-top: none;
  max-height: 340px;
  overflow: scroll;
  background-color: ${colors.white};
  z-index: 9999;

  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
`;

export const ListItem = styled.div<{
  disabled?: boolean;
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  box-shadow: 0 16px 0px -15px ${colors.grayLight4};
  cursor: pointer;
  color: ${colors.gray};
  :active {
    background-color: ${colors.grayLight4};
  }

  ${({ disabled }) => disabled && `cursor: not-allowed; color: ${colors.grayLight2};`}
`;

export const CountryName = styled.div`
  font-size: 1rem;
  font-weight: 500;
`;

export const ReportType = styled.div`
  font-size: 0.75rem;
  font-weight: 300;
`;

export const Badge = styled.div`
  color: ${colors.blue};
  font-size: 14px;
  font-weight: 400;
  border: 1px solid ${colors.blue};
  border-radius: 4px;
  padding: 0 6px;
`;

export const MenuIndicator = styled.img`
  display: inline-block;
  padding: 0 10px;
`;

export const Select = styled(OriginalSelect)`
  input {
    font: inherit;
  }
`;

export const SelectWrapper = styled.div`
  position: relative;
`;

export const CircleLoader = styled(OriginalCircleLoader)`
  width: 30px;
  height: 30px;
  margin-right: 4px;
`;
