export default [
  {
    label: 'English',
    value: 'en-GB',
    javaEnumKey: 'en_GB',
  },
  {
    label: 'Nederlands',
    value: 'nl-NL',
    javaEnumKey: 'nl_NL',
  },
  {
    label: 'Français',
    value: 'fr',
    javaEnumKey: 'fr',
  },
];
