import breakpoints from 'src/core/constants/breakpoints';
import styled from 'styled-components';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import InboxOutlineIcon from '@material-ui/icons/InboxOutlined';
import * as colors from '@graydon/ui-colors';
import { Button as OriginalButton } from '../../../../components/ButtonCR';
export const FlexContainer = styled.div`
  display: flex;
  max-width: ${breakpoints['portrait-min']}px;

  @media screen and (max-width: ${breakpoints['portrait-min']}px) {
    flex-direction: column;
  }
`;
export const SearchPageWrapper = styled.div`
  @media (max-width: 768px) {
    padding: 0 16px;
  }
`;
export const SpinnerWrapper = styled.div`
  font-size: 1rem;
  width: 100%;
  padding-top: 1.2em;
`;
export const ErrorWrapper = styled(SpinnerWrapper)`
  display: flex;
  justify-content: center;
`;
export const Button = styled(OriginalButton)`
  display: flex;
  align-items: center;
  width: auto;
`;
export const ButtonsBlock = styled.div`
  flex-grow: 1;
  order: 2;

  display: flex;
  justify-content: flex-end;

  @media (max-width: 768px) {
    order: 3;
    justify-content: flex-start;
  }
`;
export const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 440px;
  margin-bottom: 0.625rem;

  @media (max-width: 768px) {
    margin-top: 1rem;
  }
`;
export const CountrySelectWrapper = styled.div`
  flex: 2;
  max-width: 440px;
  margin-right: 10px;

  @media (max-width: 768px) {
    margin-right: 0;
  }
`;
export const StateSelectWrapper = styled.div`
  flex: 1;
  max-width: 200px;

  @media (max-width: 768px) {
    margin-top: 10px;
    max-width: 440px;
  }
`;
export const Message = styled.div`
  margin-bottom: 5px;
`;
export const MailIcon = styled(MailOutlineIcon)`
  margin-right: 10px;
`;
export const InboxIcon = styled(InboxOutlineIcon)`
  margin-right: 10px;
`;
export const InvestigationMessage = styled.div`
  margin-top: 32px;
  color: ${colors.gray};
  width: 50%;

  @media (max-width: 768px) {
    width: 100%;
  }
`;
