import MaterialMenuItem from '@material-ui/core/MenuItem';
import Link from 'src/core/components/Link';
import styled from 'styled-components';
import * as colors from '@graydon/ui-colors';
export const RawDataLink = styled(Link)`
  display: block;
  margin: 15px 0px 0px 0px;
`;
export const OuterContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    flex: 1;
    flex-wrap: wrap;
    margin-top: 16px;
  }
`;
export const InnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  min-width: 224px;

  @media (max-width: 768px) {
    width: 50%;
    min-width: 254px;
    flex-direction: row;
    flex-wrap: no-wrap;
  }

  & > button {
    @media (min-width: 769px) {
      &:first-child {
        margin-top: 0px;
      }
    }

    @media (max-width: 768px) {
      white-space: pre-line;
      // width: calc(50% - 10px);
    }
  }
`;
export const IconWrapper = styled.div`
  display: flex;
  margin-right: 10px;
`;
export const ItemWrapper = styled.div`
  color: ${colors.green};
  display: flex;
  align-items: center;
`;
export const Label = styled.div`
  text-transform: uppercase;
`;
export const DividerWrapper = styled.div`
  padding: 0 14px;
`;
export const SvgIcon = styled.svg`
  width: 20px;
  height: 20px;
`;
export const MenuItem = styled(MaterialMenuItem)`
  @media (min-width: 600px) {
    min-height: 48px !important;
  }
`;
