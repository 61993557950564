// Fields types
export const HIGHLIGHT_FIELDS = 'highlightFields';
export const MAIN_FIELDS = 'mainFields';
export const COMMON_FIELDS = 'commonFields';
export const TRANSLATION_KEY_PREFIX = 'search-results.item';

const isNotDefined = (value: any) => value === null || typeof value === 'undefined';

export const getItemTranslation = (i18n: any, field: any, fieldType: any, options: any) => {
  if (isNotDefined(i18n)) {
    throw new Error('ArgumentException: "i18n" is required argument.');
  }

  if (isNotDefined(field)) {
    throw new Error('ArgumentException: "field" is required argument.');
  }

  if (isNotDefined(fieldType)) {
    throw new Error('ArgumentException: "fieldType" is required argument.');
  }

  return i18n.text(`${TRANSLATION_KEY_PREFIX}.${field}`, options);
};
