import styled, { css } from 'styled-components';
import breakpoints from '../../constants/breakpoints';
import colors from '../../constants/colors';
import { AppContainerProps } from '../Chrome';
import { HelpCenterModal } from '../HelpCenterModal';
import UserBar from '../UserBar';
import AppName from './AppName';
import Branding from './Branding';
import HamburgerButton from './HamburgerButton';
import HomeButton from './HomeButton';

/* eslint-disable max-len */
const graydon =
  'data:image/svg+xml,%3Csvg%20width%3D%2235%22%20height%3D%2224%22%20viewBox%3D%220%200%2035%2024%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cg%20fill%3D%22%23FFF%22%20fill-rule%3D%22nonzero%22%3E%0A%20%20%20%20%20%20%20%20%3Cpath%20d%3D%22M12%2011S13.656.06%2023.526%200H35s-2.267%2011-11.675%2011H12zM0%2024s1.656-10.942%2011.524-11H23s-2.268%2011-11.675%2011H0z%22%2F%3E%0A%20%20%20%20%3C%2Fg%3E%0A%3C%2Fsvg%3E%0A';
/* eslint-enable max-len */

const Header = styled.header<{
  isHome: boolean;
}>`
  position: relative;
  display: flex;
  height: 60px;
  justify-content: space-between;
  box-sizing: content-box;
  align-items: center;

  ${({ isHome }) =>
    !isHome &&
    css`
      background-color: ${colors.secondaryDark};
      border-bottom: 1px solid ${colors.lightGrey};
    `};

  @media screen and (max-width: ${breakpoints['portrait-min']}px) {
    border: none;
  }
`;

const HeaderExtras = styled.div`
  flex-grow: 1;
  text-align: end;
  white-space: nowrap;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 768px) {
    margin-right: 52px;
  }
`;

type ApplicationHeaderProps = AppContainerProps & {
  isSubMenu?: boolean;
  onToggleMenu: () => void;
  isOpen?: boolean;
  hasHamburger?: boolean;
  isHomePathRouterLink?: boolean;
  showLogoutButton?: boolean;
  consumerId?: string;
};

export default function ApplicationHeader(props: ApplicationHeaderProps) {
  const {
    i18n,
    name,
    companyName,
    locales = [
      { label: 'Nederlands', value: 'nl-NL' },
      { label: 'English', value: 'en-GB' },
      { label: 'Français', value: 'fr' },
    ],
    language = 'en-GB',
    doLogout,
    onLanguageChange,
    isSubMenu = false,
    isHome = false,
    onToggleMenu,
    isOpen = false,
    altUserBar = false,
    homePath = '/apps/home',
    className,
    appName,
    appPath = '/',
    hasHamburger = true,
    showLanguageBar,
    showHelpBlock = false,
    onContactsClick,
    Link,
    isHomePathRouterLink,
    showLogoutButton,
    consumerId,
  } = props;

  return (
    <Header isHome={isHome} className={className}>
      <Branding data-test="branding">
        {isHome ? (
          <img src={graydon} alt="" />
        ) : (
          <>
            <HomeButton
              data-test="home-button"
              homePath={homePath}
              isHomePathRouterLink={isHomePathRouterLink}
              Link={Link}
            />
            <AppName
              className={typeof appName !== 'string' ? 'app-home' : ''}
              i18n={i18n}
              appName={appName}
              appPath={appPath}
              isHome={isHome}
              Link={Link}
            />
          </>
        )}
        <HeaderExtras data-test="header-popups">
          <HelpCenterModal i18n={i18n} consumerId={consumerId || ''} language={language} />
          <div id="notifications-icon" />
        </HeaderExtras>
        {!altUserBar && hasHamburger && (
          <HamburgerButton type="button" onClick={onToggleMenu} isOpen={isOpen} />
        )}
      </Branding>
      {altUserBar || (
        <UserBar
          i18n={i18n}
          name={name}
          companyName={companyName}
          locales={locales}
          language={language}
          doLogout={doLogout}
          onLanguageChange={onLanguageChange}
          isSubMenu={isSubMenu}
          isHome={isHome}
          showLanguageBar={showLanguageBar}
          showLogoutButton={showLogoutButton}
          showHelpBlock={showHelpBlock}
          onContactsClick={onContactsClick}
        />
      )}
    </Header>
  );
}
