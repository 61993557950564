import styled from 'styled-components';
import * as colors from '@graydon/ui-colors';

export const Label = styled.label<{
  isRequired?: boolean;
}>`
  font-size: 16px;
  font-weight: 500;
  color: ${colors.gray} !important;
  margin-bottom: 8px;
  overflow-wrap: break-word;

  ${({ isRequired }) =>
    isRequired &&
    `
    &::after {
      content: "*";
      padding-left: 5px;
    };
  `}
`;

export const FlexContainer = styled.div<{
  align?: string;
  justify?: string;
  column?: boolean;
  changeDirectionOnMobile?: boolean;
}>`
  display: flex;
  width: 100%;
  justify-content: space-between;
  ${({ justify }) => `justify-content: ${justify || 'space-between'}`}
  ${({ align }) => `align-items: ${align || 'center'}`}
    ${({ column }) => column && 'flex-direction: column;'}

    ${({ changeDirectionOnMobile }) =>
    changeDirectionOnMobile && `@media(max-width: 768px) { flex-direction: column; }`}
`;

export const Container = styled.div<{
  marginBottom?: number;
}>`
  width: 100%;
  margin-bottom: ${({ marginBottom }) => (marginBottom ? `${marginBottom}px` : '16px')};

  @media (max-width: 768px) {
    margin-bottom: 8px;
  }
`;
