import styled from 'styled-components';
import * as colors from '@graydon/ui-colors';
import ButtonCR from '../ButtonCR';
import { fonts } from '../../constants/fonts';

export const Button = styled(ButtonCR)`
  margin-top: 15px;

  &:last-child {
    margin-bottom: 15px;

    @media (max-width: 768px) {
      margin-bottom: 0;
    }
  }

  @media (max-width: 768px) and (min-width: 600px) {
    width: 50%;
    white-space: pre-line;
  }
`;

export const PopupButton = styled(ButtonCR)`
  display: flex;
  align-items: center;
  width: auto;

  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
`;

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 8px;
`;

export const Title = styled.h2`
  margin: 8px 0;
  padding: 0;
  font: ${fonts.h2};
  color: ${colors.greenLight};

  @media (max-width: 768px) {
    font-size: 1.5em;
  }
`;

export const Tools = styled.div`
  display: flex;
  align-items: center;
  margin-left: 1em;
  height: 28px;

  img {
    margin-left: 1em;
  }
`;

export const Message = styled.div`
  margin: 4px 0 0 0;
  /* @ts-ignore */
  font: ${fonts.body};
  color: ${colors.gray};
`;

export const Buttons = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 1em;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;

    ${Button} {
      min-width: 210px;
      margin-bottom: 1em;
    }
  }
`;

export const ClientNoteWrapper = styled.div`
  margin-top: 16px;
`;

export const Hr = styled.hr<{
  color?: string;
  margin?: string;
}>`
  border: 0;
  height: 1px;
  background-color: ${({ color }) => color || colors.grayLight2};
  margin: ${({ margin }) => margin || '1.5rem 0 1rem 0'};
`;

export const LoaderContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: top;
  align-items: center;
  pointer-events: none;
  z-index: 9997;
`;
