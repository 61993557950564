import React from 'react';
import defaultDictionary from '../translations';
import getI18n from '../i18n';
import I18nContext from './context';

type I18nProviderProps = {
  locale?: string;
  dictionary?: typeof defaultDictionary;
  children?: React.ReactNode;
};

export default function I18nProvider({ locale, dictionary, children }: I18nProviderProps) {
  return (
    <I18nContext.Provider value={getI18n(locale || 'en-GB', dictionary || defaultDictionary)}>
      {children}
    </I18nContext.Provider>
  );
}
