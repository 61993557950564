import get from 'lodash/get';
import { extractRegisteredAddress } from '../common';
import { getFormattedAddress } from '../../../../../utils/getFormattedAddress';
export const extractCompanyHeaderData = ({
  companyIdentification,
  companyProfile,
  insightsEnterpriseReference,
  insightsEnterpriseReferences,
}: any) => {
  const address = extractRegisteredAddress(get(companyProfile, 'addresses'));
  const countryCode = get(companyIdentification, 'countryCode');
  return {
    companyName: get(companyProfile, 'companyName', ''),
    companyAddress: getFormattedAddress(countryCode, address),
    isCompanyActive: get(companyProfile, 'companyStatus.isActive'),
    countryCode,
    graydonCompanyId: get(companyIdentification, 'graydonCompanyId'),
    enterpriseId: get(companyIdentification, 'enterpriseId'),
    reference: insightsEnterpriseReference,
    referenceList: insightsEnterpriseReferences || [],
  };
};
