/* eslint-disable no-console,max-len */
import * as colors from '@graydon/ui-colors';

// Log a deprecation warning
if (process.env.NODE_ENV !== 'production') {
  console.warn(
    'Importing colors from `src/design-system/constants/colors.js` is deprecated. Please import the colors from `@graydon/ui-colors`.',
  );
}

export default colors;
