// @ts-nocheck
import { createActions } from 'redux-actions';
import { get } from '../../../../utils/api';
import utils from '../../../CreditReports/shared/utils';
import { getClientSession } from '../../../../utils';
import { getUserData } from '../../../../apps/InternationalCreditReports/shared/selectors/userData';
export const {
  fetchOrganisationRequest,
  fetchOrganisationResponse,
  setOrganisationHeaderData,
  setOrganisationCompanyBoxData,
  setArchivedReportsData,
} = createActions(
  'FETCH_ORGANISATION_REQUEST',
  'FETCH_ORGANISATION_RESPONSE',
  'SET_ORGANISATION_HEADER_DATA',
  'SET_ORGANISATION_COMPANY_BOX_DATA',
  'SET_ARCHIVED_REPORTS_DATA',
);

const getArchiveReports = (enterpriseId: any) => {
  const clientSession = getClientSession();
  const { consumerId } = getUserData(clientSession);
  return get(
    `/credit-reports/enterprises/${enterpriseId}/reports${
      consumerId ? `?consumerId=${consumerId}` : ''
    }`,
  );
};

export const fetchOrganisation = (enterpriseId: any) => async (dispatch: any) => {
  dispatch(fetchOrganisationRequest());

  try {
    const { enterpriseData, reportsList } = await getArchiveReports(enterpriseId);
    dispatch(setOrganisationHeaderData(utils.extractCompanyHeaderData(enterpriseData)));
    dispatch(setOrganisationCompanyBoxData(utils.extractCompanyBoxData(enterpriseData)));
    dispatch(
      setArchivedReportsData({
        reportsList,
      }),
    );
    dispatch(fetchOrganisationResponse());
  } catch (err) {
    dispatch(fetchOrganisationResponse(err));
  }
};
export const refreshOrganisation = (enterpriseId: any) => async (dispatch: any) => {
  try {
    const { reportsList } = await getArchiveReports(enterpriseId);
    dispatch(
      setArchivedReportsData({
        reportsList,
      }),
    );
    dispatch(fetchOrganisationResponse());
  } catch (err) {
    dispatch(fetchOrganisationResponse(err));
  }
};
