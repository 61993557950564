import React from 'react';
import styled from 'styled-components';
import colors from '../../constants/colors';
import { sans } from '../../constants/fonts';
import Button from '../Button/index';

const Form = styled.form<{
  height: string;
}>`
  border: solid 1px ${colors.disabled};
  border-radius: 4px;
  display: flex;
  height: ${({ height }) => height};
  padding: 5px;
`;

const TextField = styled.input`
  border: none;
  flex-grow: 1;
  font: 16px ${sans};
  padding-left: 10px;
  width: 100%;

  &:focus {
    outline: none;
  }
`;

const SearchBarButton = styled(Button)<{
  disabled?: boolean;
  children?: React.ReactNode;
  onClick?: (arg?: any) => void;
}>`
  flex-grow: 1;
`;

type SearchBarProps = {
  height?: string;
  minCharsNeeded?: number;
  onSearch: () => void;
  onSearchTermChanged: (searchTerm: string) => void;
  placeHolder?: string;
  searchButtonTitle?: string;
  searchTerm: string;
};

class SearchBar extends React.Component<SearchBarProps> {
  constructor(props) {
    super(props);

    this.handleSearchTermChange = this.handleSearchTermChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSearchTermChange(e) {
    e.preventDefault();
    this.props.onSearchTermChanged(e.target.value);
  }

  handleSubmit(e) {
    e.preventDefault();
    this.props.onSearch();
  }

  render() {
    const {
      height = '70px',
      searchTerm,
      minCharsNeeded = 2,
      placeHolder = '',
      searchButtonTitle = 'Search',
    } = this.props;
    const searchEnabled = searchTerm.length >= minCharsNeeded;
    return (
      <Form height={height} onSubmit={this.handleSubmit}>
        <TextField
          onChange={this.handleSearchTermChange}
          placeholder={placeHolder}
          type="text"
          value={searchTerm}
        />
        <SearchBarButton disabled={!searchEnabled} onClick={this.handleSubmit} primary>
          {searchButtonTitle}
        </SearchBarButton>
      </Form>
    );
  }
}

export default SearchBar;
