import React from 'react';
import * as colors from '@graydon/ui-colors';
import Input from '../../../components/Input';

type Props = {
  type?: string;
  value?: string;
  placeholder?: string;
  onEdit?: (arg0: any) => any;
  onBlur?: (arg0: any) => any;
  onFocus?: (arg0: any) => any;
  autoFocus?: boolean;
  onEnterKey?: ((arg0: any) => any) | null | undefined;
};

type Event = React.KeyboardEvent<HTMLInputElement>;

// just check the keyPress is for Enter key
export const onEnter = (cb: (arg0: Event) => any) => (e: Event) => e.charCode === 13 && cb(e);

export const TextInput = ({
  type,
  value,
  placeholder,
  onEdit,
  onBlur,
  onFocus,
  autoFocus,
  onEnterKey,
}: Props) => (
  <Input
    width="100%"
    borderColor={colors.grayLight2}
    type={type}
    onChange={(e) => onEdit && onEdit(e.target.value)}
    onBlur={onBlur}
    onFocus={onFocus}
    placeholder={placeholder}
    value={value}
    autoFocus={autoFocus}
    onKeyPress={onEnterKey && onEnter(onEnterKey)}
  />
);
export default TextInput;
