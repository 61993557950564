import { injectI18n, I18nProp } from 'src/i18n';
import React, { useState } from 'react';
import styled from 'styled-components';
import * as colors from '@graydon/ui-colors';
import Button from '../ButtonCR';
import { InformationTile } from '../NotificationTile';
import Popup from '../Popup';
import { Markdown } from '../Markdown';

const Wrapper = styled.div`
  margin-bottom: 16px;
`;
const Title = styled.h3`
  color: ${colors.greenLight};
  font-size: 24px;
  font-weight: 500;
  margin: 0;
  padding: 0;
  margin-bottom: 7px;
`;
type Props = {
  clientSession: any;
  stopSession: () => any;
};
const MarkdownWrapper = styled.div`
  color: ${colors.gray};
  margin-bottom: 16px;
  p {
    margin: 0;
  }
  strong {
    font-weight: 500;
  }
`;

const ClientSession: React.FC<Props & I18nProp> = ({ i18n, clientSession, stopSession }) => {
  const [showModal, setShowModal] = useState(false);
  return (
    <Wrapper>
      <InformationTile
        title={i18n.text('icr.search-page.client-session.title')}
        description={i18n.text('icr.search-page.client-session.tooltip')}
      >
        <MarkdownWrapper>
          <Markdown>
            {i18n.text('icr.search-page.client-session.description', clientSession)}
          </Markdown>
        </MarkdownWrapper>

        <Button width="auto" onClick={() => setShowModal(true)} primary>
          {i18n.text('icr.search-page.client-session.stop-session')}
        </Button>
      </InformationTile>

      {showModal && (
        <Popup onClose={() => setShowModal(false)}>
          <Title>{i18n.text('icr.search-page.client-session.modal.title')}</Title>
          <MarkdownWrapper>
            <Markdown>
              {i18n.text('icr.search-page.client-session.modal.description', clientSession)}
            </Markdown>
          </MarkdownWrapper>
          <Button width="auto" onClick={stopSession} primary>
            {i18n.text('icr.search-page.client-session.stop-session')}
          </Button>
        </Popup>
      )}
    </Wrapper>
  );
};

export default injectI18n(ClientSession);
