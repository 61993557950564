import get from 'lodash/get';
import range from 'lodash/range';
import curry from 'lodash/fp/curry';
import {
  ADDRESS_TYPE_TRADING_ADDRESS,
  ADDRESS_TYPE_REGISTERED_ADDRESS,
  ADDRESS_TYPE_OLD_ADDRESS,
  ACTIVITY_TYPE_PRIMARY,
} from '../../constants';

type DateFormats =
  | string
  | {
      year: number;
      month: number;
      day: number;
    };

type Addresses = {
  type: {
    value: string;
  };
};

type PhoneNumber = {
  countryCode?: string;
  areaCode?: string;
  subscriberNumber?: string;
};

export const parseDate = (date?: DateFormats) => {
  if (!date) return null;

  if (typeof date === 'object') {
    const { year, month, day } = date;

    if (month && year) {
      return new Date(year, month - 1, day || 1);
    }

    return null;
  }

  try {
    if (typeof date !== 'string' || date === null) {
      return null;
    }

    const dateParts = date.split('-');

    if (dateParts.length !== 3) {
      return null;
    }

    return new Date(
      parseInt(dateParts[0], 10),
      parseInt(dateParts[1], 10) - 1,
      parseInt(dateParts[2], 10),
    );
  } catch (e) {
    return null;
  }
};

export const getCuratorAddress = ({
  address,
  addressLine1,
  addressLine2,
  addressLine3,
  addressLine4,
  addressLine5,
}: any) => {
  if (address) {
    return address;
  }

  const line2and3 = [addressLine2, addressLine3].filter(Boolean).join(' ');
  const line4and5 = [addressLine4, addressLine5].filter(Boolean).join(' ');
  return [addressLine1, line2and3, line4and5].filter(Boolean).join(', ');
};

export const getYearsRange = (startFrom: number, years: number) =>
  range(startFrom, startFrom - years);

export const extractTradingAddress = (addresses: Array<Addresses> = []) =>
  addresses.find((addr) => get(addr, 'type.value') === ADDRESS_TYPE_TRADING_ADDRESS) || {};

export const extractRegisteredAddress = (addresses: Array<Addresses> = []) =>
  addresses.find((addr) => get(addr, 'type.value') === ADDRESS_TYPE_REGISTERED_ADDRESS) || {};

export const extractOldAddress = (addresses: Array<Addresses> = []) =>
  addresses.find((addr) => get(addr, 'type.value') === ADDRESS_TYPE_OLD_ADDRESS) || {};

export const parsePhoneNumber = ({ countryCode, areaCode, subscriberNumber }: PhoneNumber = {}) =>
  subscriberNumber ? `${countryCode || ''} ${areaCode || ''} ${subscriberNumber}`.trim() : null;

export const getPrimaryActivity = (data: any) =>
  get(data, 'companyProfile.activities', []).find(
    (activity: any) => activity.typeCode.value === ACTIVITY_TYPE_PRIMARY,
  );

export const getLastFilledFinancialYear = (data: any) => {
  const annualAccountsYears = get(data, 'annualReportProfile.annualReports', [])
    .map((annualAccount: any) => get(annualAccount, 'annualReportHeader.accountingPeriod', null))
    .filter((x: any) => x);
  const keyFiguresYears = get(data, 'financialProfile.keyFigures', [])
    .map((keyFigure: any) => get(keyFigure, 'year', null))
    .filter((x: any) => x);
  return Math.max.apply(null, [...annualAccountsYears, ...keyFiguresYears]);
};

export const getLast12ActiveMonths = (date: Date) => {
  let current = {
    year: date.getFullYear(),
    month: date.getMonth() + 1,
  };
  const list = [{ ...current }];

  const decreaseMonth = (point: any) => {
    point.month -= 1;

    if (point.month === 0) {
      point.year -= 1;
      point.month = 12;
    }

    return point;
  };

  return range(11).reduce((acc: any) => {
    current = decreaseMonth(current);
    return acc.concat({ ...current });
  }, list);
};

export const parseUnFormatedDate = curry<string, string, string>(
  (separator: string, dateStr: string) =>
    (dateStr.match(/^(\d{4})(\d{2})?(\d{2})?$/) || [])
      .splice(1)
      .reverse()
      .filter(Boolean)
      .join(separator),
);
