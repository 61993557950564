import React from 'react';
import styled from 'styled-components';
import colors from '../../constants/colors';
import { sans, serif } from '../../constants/fonts';

type HeadingPropStyles = {
  center?: boolean;
  inverted?: boolean;
  primary?: boolean;
};

export const Heading1 = styled.h1<HeadingPropStyles>`
  font-size: 32px;
  font-family: ${serif};
  font-weight: 700;
  text-transform: ${({ primary }) => (primary ? 'uppercase' : 'none')};
  ${({ center }) => center && 'text-align: center;'} color: ${({ inverted }) =>
    inverted ? colors.white : colors.secondaryDark};
  padding: 0;
  margin: 0 0 0.5em;
`;

export const Heading2 = styled.h2<HeadingPropStyles>`
  font-size: 24px;
  font-family: ${serif};
  font-weight: 700;
  text-transform: ${({ primary }) => (primary ? 'uppercase' : 'none')};
  ${({ center }) => center && 'text-align: center;'} color: ${({ inverted, primary }) =>
    inverted ? colors.white : primary ? colors.link : colors.secondaryDark};
  padding: 0;
  margin: 0 0 0.5em;
`;

export const Heading3 = styled.h3<HeadingPropStyles>`
  font-size: 18px;
  font-family: ${serif};
  font-weight: 700;
  text-transform: ${({ inverted, primary }) => (inverted || primary ? 'uppercase' : 'none')};
  ${({ center }) => center && 'text-align: center;'} color: ${({ inverted, primary }) =>
    inverted ? colors.white : primary ? colors.secondaryDark : colors.secondaryDark};
  padding: 0;
  margin: 0 0 0.5em;
`;

export const Heading4 = styled.h4<HeadingPropStyles>`
  font-size: 14px;
  font-family: ${sans};
  font-weight: 700;
  text-transform: ${({ inverted, primary }) => (inverted || primary ? 'uppercase' : 'none')};
  ${({ center }) => center && 'text-align: center;'} color: ${({ inverted }) =>
    inverted ? colors.white : colors.black};
  padding: 0;
  margin: 0 0 0.5em;
`;

export const headingSizes = {
  h1: Heading1,
  h2: Heading2,
  h3: Heading3,
  h4: Heading4,
};

type HeadingProps = {
  children: React.ReactNode;
  size?: keyof typeof headingSizes;
  inverted?: boolean;
  primary?: boolean;
  center?: boolean;
};

function Heading({
  children,
  size = 'h1',
  inverted = false,
  primary = false,
  center = false,
  ...props
}: HeadingProps) {
  const Element = headingSizes[size] || Heading1;

  return <Element {...props}>{children}</Element>;
}

export default Heading;
