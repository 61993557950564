import { injectI18n } from 'src/i18n';
import compose from 'lodash/fp/compose';
import getOr from 'lodash/fp/getOr';
import React from 'react';
import { I18n } from 'src/i18n';
import { isMobileScreen } from '../../../../utils/screen';
import { SearchBar, Form } from './helper-components';
type Props = {
  i18n: I18n;
  onSubmit: (val: string) => any;
  text: string;
  setText: (str: string) => any;
};

function SearchForm({ i18n, onSubmit, text, setText }: Props) {
  const onChange = compose(setText, getOr('', 'target.value'));

  const onFormSubmit = (e: any) => {
    e.preventDefault();
    onSubmit(text);

    // focus out from the input and focus to the search button;
    if (e.target && isMobileScreen()) {
      e.target.querySelector('button[type="submit"]').focus();
    }
  };

  const reset = () => setText('');

  return (
    <Form onSubmit={onFormSubmit}>
      <SearchBar
        placeholder={i18n.text('shr.search.input.placeholder') || ''}
        searchText={text}
        onChange={onChange}
        onReset={reset}
        autoFocus
      />
    </Form>
  );
}

export default injectI18n(SearchForm);
