import React from 'react';
import styled from 'styled-components';
import * as colors from '@graydon/ui-colors';
const Container = styled.div`
  display: flex;
  align-items: baseline;
  margin-top: 4px;
`;
const Icon = styled.div`
  display: inline-block;
  color: #fff;
  background-color: ${colors.danger};
  height: 16px;
  width: 16px;
  border-radius: 16px;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  margin-top: -3px;
  min-width: 16px;
`;
const Message = styled.div`
  display: inline-block
  color: ${colors.danger};
  margin-left: 4px;
`;
type Props = {
  children?: string;
};
export const ValidationMessage = ({ children }: Props) => {
  return (
    <Container>
      <Icon>!</Icon>

      <Message>{children}</Message>
    </Container>
  );
};
export default ValidationMessage;
