import React from 'react';
import styled from 'styled-components';
import { injectI18n, I18nProp } from 'src/i18n';
import * as colors from '@graydon/ui-colors';
import CompanyStatusFlag, {
  getCompanyStatus,
  getCompanyStatusFlagColor,
} from '../CompanyStatusFlag';
import { sans } from '../../constants/fonts';

export const Divider = styled.span`
  color: ${colors.gray};
  border-left: 1px solid;
  margin: 0 1em;
  height: 2.5em;

  @media (max-width: 768px) {
    display: none;
  }
`;

const Status = styled.span<{
  small?: boolean;
}>`
  font: ${({ small }) => (small ? `400 14px ${sans}` : `300 1em ${sans}`)};
  color: ${colors.gray};
  text-transform: uppercase;

  @media (max-width: 768px) {
    line-height: 1em;
  }
`;

const Container = styled.div`
  display: inline-block;
`;

type Props = {
  status?: boolean;
  small?: boolean;
  className?: string;
};

const CompanyStatus: React.FC<Props & I18nProp> = ({ status, i18n, small, className }) => (
  <Container className={className} title={getCompanyStatus(status, i18n)}>
    <CompanyStatusFlag
      small={small}
      color={getCompanyStatusFlagColor(status)}
      title={i18n.text(getCompanyStatus(status, i18n))}
    />
    <Status small={small}>{getCompanyStatus(status, i18n)}</Status>
  </Container>
);

export default injectI18n(CompanyStatus);
