import { post } from '../../../../utils/api';
import { dataCleanup } from '../../components/SearchBar/utils';

type SearchOptions = {
  country: string;
  searchQuery: string;
};

type SearchResults = {
  total: number;
  items: Array<any>;
  countryCode: string;
};

export async function search({
  country,
  searchQuery,
}: SearchOptions): Promise<SearchResults | void> {
  const response = await post(
    `/api/organizations/search?countryCode=${country}&size=50`,
    {
      query: searchQuery,
    },
    true,
    {
      'api-version': '3',
    },
  );
  return { ...dataCleanup(country, response), countryCode: country };
}
