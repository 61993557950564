const checkSupport = () => {
  if (typeof matchMedia !== 'function') {
    throw new Error('Browser do not support `matchMedia`.');
  }
};

export const isDesktopScreen = () => {
  checkSupport();
  return matchMedia('(min-width: 1025px)').matches;
};
export const isTabletScreen = () => {
  checkSupport();
  return matchMedia('(max-width: 1024px)').matches;
};
export const isMobileScreen = () => {
  checkSupport();
  return matchMedia('(max-width: 768px)').matches;
};
export const supportFullScreen = () =>
  document.fullscreenEnabled ||
  document['webkitFullscreenEnabled'] ||
  document['msFullscreenEnabled'] ||
  document['mozFullScreenEnabled'];
export const requestFullscreen = (element: any) => {
  if (element.requestFullscreen) element.requestFullscreen();
  else if (element.webkitRequestFullscreen) element.webkitRequestFullscreen();
  else if (element.msRequestFullscreen) element.msRequestFullscreen();
  else if (element.mozRequestFullScreen) element.mozRequestFullScreen();
};
export const exitFullscreen = (element: any) => {
  if (element.exitFullscreen) element.exitFullscreen();
  else if (element.webkitExitFullscreen) element.webkitExitFullscreen();
  else if (element.msExitFullscreen) element.msExitFullscreen();
  else if (element.mozExitFullScreen) element.mozExitFullScreen();
};
export const onFullscreenChange = (element: any, cb: any) => {
  element.onfullscreenchange = cb;
  element.onwebkitfullscreenchange = cb;
  element.onmsfullscreenchange = cb;
  element.onmozfullscreenchange = cb;
};
export const isFullscreen = () =>
  Boolean(
    document.fullscreenElement ||
      document['webkitFullscreenElement'] ||
      document['msFullscreenElement'] ||
      document['mozFullScreenElement'],
  );
export function scrollIntoView(element: any) {
  if (element && element.scrollIntoView) {
    try {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    } catch (e) {
      console.warn('scroll error', e);
    }
  }
}
