import React from 'react';
import { colors } from './constants';

type SliceProps = {
  idx: number;
  d?: string;
};

const Slice = ({ idx, ...props }: SliceProps) => <path {...props} style={{ fill: colors[idx] }} />;

export default Slice;
