import * as colors from '@graydon/ui-colors';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Popup from 'src/core/components/Popup';
import Tooltip from 'src/core/components/Tooltip';
import CircleLoader from 'src/core/components/loaders/CircleLoader';
import { I18nProp, injectI18n } from 'src/i18n';
import ReportDownloadModal from '../../apps/InternationalCreditReports/components/SearchResults/ReportDownloadModal';
import {
  popupCompanySet,
  requestICR,
} from '../../apps/InternationalCreditReports/store/internationalCreditReports';
import closeIcon from '../../media/icons/close-24px.svg';
import downloadIcon from '../../media/icons/download.svg';
import mailOutlineIcon from '../../media/icons/mail-outline.svg';
import { sendEvent } from '../../utils/analytics';
import { FormFieldText } from '../FormComponents/FormFields/FormFieldText';
import Icon from '../Icon';
import Link from '../Link';
import { COMMON_FIELDS, TRANSLATION_KEY_PREFIX } from '../SearchResultsListItem/utils';
import {
  Buttons,
  ClientNoteWrapper,
  Header,
  Hr,
  LoaderContainer,
  Message,
  PopupButton,
  Title,
  Tools,
} from './helper-components';

type Props = {
  isFreshInvestigationAvailable?: boolean;
  downloadStep?: number;
};

const AvailabilityPopup: React.FC<Props & I18nProp & RouteComponentProps> = ({
  i18n,
  history,
  isFreshInvestigationAvailable = false,
  downloadStep = null,
}) => {
  const [showClientNote, setShowClientNote] = useState(false);
  const [clientNote, setClientNote] = useState();
  const dispatch = useDispatch();
  const { requestReportStatus, popupCompany } = useSelector(
    (state: any) => state.internationalCreditReports,
  );
  if (!popupCompany) return <></>;
  const {
    companyName,
    address,
    companyIdentifier,
    latestReportDate,
    isDatabaseReportAvailable,
    countryCode,
  } = popupCompany;

  function onFreshInvestigation() {
    history.push(`/international-credit-reports/order-investigated-report`);
    sendEvent('ICR', 'Check availability popup: Fresh investigation btnclick');
  }

  function onRequestReport() {
    dispatch(
      requestICR(companyIdentifier.agencyCompanyId, countryCode, i18n, clientNote, companyName),
    );
    sendEvent('ICR', 'Check availability popup: Request report btnclick');
  }

  function onClose() {
    dispatch(popupCompanySet(null));
  }

  if (typeof downloadStep === 'number') return <ReportDownloadModal />;

  function VatRow() {
    const label =
      countryCode && ['nl', 'be', 'gb'].includes(countryCode.toLowerCase())
        ? i18n.text(`${TRANSLATION_KEY_PREFIX}.registrationNumber.${countryCode}`, COMMON_FIELDS)
        : i18n.text(`${TRANSLATION_KEY_PREFIX}.VAT`, COMMON_FIELDS);
    return `${label}: ${companyIdentifier.vatNumber || '-'}`;
  }

  function CompanyIdRow() {
    const label =
      countryCode && ['nl', 'be', 'gb'].includes(countryCode.toLowerCase())
        ? i18n.text(`${TRANSLATION_KEY_PREFIX}.id`, COMMON_FIELDS)
        : i18n.text(`${TRANSLATION_KEY_PREFIX}.Internal`, COMMON_FIELDS);
    return `${label}: ${companyIdentifier.agencyCompanyId || '-'}`;
  }

  function AddressRow() {
    return `${i18n.text('icr.search-results.modal.address')}: ${address.entireAddress || '-'}`;
  }

  const databaseReportIsPossible = isDatabaseReportAvailable && requestReportStatus !== 'ERROR';
  return (
    <Popup displayCloseButton={false} onClose={onClose} padding="30px" width="auto">
      <Header>
        <Title>{companyName}</Title>

        <Tools>
          <Tooltip iconColor={colors.greenDark} bgColor={colors.white}>
            {i18n.text('icr.search-results.modal.availability-immediate-fresh')}
          </Tooltip>
          <Icon size={20} icon={closeIcon} onClick={onClose} />
        </Tools>
      </Header>

      <Message
        dangerouslySetInnerHTML={{
          __html: VatRow(),
        }}
      />

      <Message
        dangerouslySetInnerHTML={{
          __html: `${CompanyIdRow()}`,
        }}
      />

      <Message>{AddressRow()}</Message>

      <Hr margin="1em 0" />

      {databaseReportIsPossible && (
        <Message
          style={{
            marginBottom: '1em',
          }}
        >
          {`${i18n.text(`icr.search-results.latest-report-date`)}: `}
          <span
            style={{
              fontWeight: '500',
            }}
          >
            {latestReportDate
              ? i18n.date(new Date(latestReportDate), {
                  day: 'numeric',
                  month: 'numeric',
                  year: 'numeric',
                })
              : '-'}
          </span>
        </Message>
      )}

      <Message>
        {i18n.text(
          databaseReportIsPossible
            ? `icr.search-results.modal.availability-immediate-fresh`
            : `icr.search-results.modal.not-available`,
        )}
      </Message>

      {databaseReportIsPossible && (
        <ClientNoteWrapper>
          {showClientNote ? (
            <FormFieldText
              placeholder={i18n.text('icr.search-results.modal.clientNote.placeholder')}
              tooltipText={i18n.text('icr.search-results.modal.clientNote.tooltip')}
              label={i18n.text('icr.search-results.modal.clientNote.label')}
              value={clientNote}
              onEdit={(val: any) => setClientNote(val.length > 30 ? clientNote : val)}
            />
          ) : (
            <Link onClick={() => setShowClientNote(true)} beforeContent="+">
              {i18n.text('icr.search-results.modal.add-client-note')}
            </Link>
          )}
        </ClientNoteWrapper>
      )}

      <Buttons>
        {databaseReportIsPossible && (
          <PopupButton onClick={onRequestReport} primary>
            <Icon icon={downloadIcon} size={20} margin="0px 10px 0px 0px" />
            {i18n.text('icr.search-results.modal.request-report-button')}
          </PopupButton>
        )}

        {isFreshInvestigationAvailable && (
          <PopupButton onClick={onFreshInvestigation}>
            <Icon icon={mailOutlineIcon} size={20} margin="0px 10px 0px 0px" />
            {i18n.text('icr.search-results.modal.fresh-investigation-button')}
          </PopupButton>
        )}
      </Buttons>
      {requestReportStatus === 'ORDERED' && (
        <LoaderContainer>
          <CircleLoader size={100} color={colors.green} borderSize={5} />
        </LoaderContainer>
      )}
    </Popup>
  );
};

export default withRouter(injectI18n(AvailabilityPopup));
