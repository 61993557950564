import styled from 'styled-components';

// adapted from https://codepen.io/soulrider911/pen/qhEzf/

type BgStyling = {
  uncheckedColor?: string;
  uncheckedColorDark?: string;
  checkedColor?: string;
  border?: string | null;
  borderSelected?: string | null;
};

export const lightBgStyling = {
  labelColor: '#000',
  uncheckedColor: '#fff',
  uncheckedColorDark: '#bfbfbf',
  checkedColor: '#187b8e',
  border: '1px solid #bfbfbf',
  borderSelected: '1px solid #187b8e',
};

export const darkBgStyling = {
  labelColor: '#fafafa',
  uncheckedColor: '#fafafa',
  uncheckedColorDark: '#bfbfbf',
  checkedColor: '#187b8e',
  border: null,
  borderSelected: null,
};

type BaseInputProps = {
  labelColor?: string;
};

const BaseInput = styled.input<BaseInputProps & BgStyling>`
  display: none;
  cursor: pointer;
  &:focus,
  &:active {
    outline: none;
  }
  & + .label {
    cursor: pointer;
    display: inline-block;
    position: relative;
    padding-left: 25px;
    margin-right: 10px;
    line-height: 16px;
    color: ${({ labelColor }) => labelColor};
    &:before,
    &:after {
      content: '';
      font-family: helvetica;
      display: inline-block;
      width: 18px;
      height: 18px;
      left: 0;
      top: 0;
      text-align: center;
      position: absolute;
    }
    &:before {
      background-color: ${({ uncheckedColor }) => uncheckedColor};
      box-shadow: inset 0 0 0 1px ${({ uncheckedColor }) => uncheckedColor};
      transition: all 0.3s ease-in-out;
      top: -1px;
      ${({ border }) => border && `border: ${border};`};
    }
    &:after {
      color: #fff;
    }
  }
  &:checked + .label:before {
    box-shadow: inset 0 0 0 10px ${({ checkedColor }) => checkedColor};
    ${({ borderSelected }) => borderSelected && `border: ${borderSelected};`};
  }
`;

export const Checkbox = styled(BaseInput)`
  & + .label:before {
    border-radius: 2px;
  }

  & + .label:hover:after,
  &:checked + .label:after {
    content: '\\2713';
    font-size: 14px;
    line-height: 25px;
    top: -3px;
  }
  & + .label:hover:after {
    color: ${({ uncheckedColorDark }) => uncheckedColorDark};
  }
  &:checked + .label:after,
  &:checked + .label:hover:after {
    color: #fff;
  }
`;

Checkbox.defaultProps = {
  type: 'checkbox',
  ...lightBgStyling,
};

export const RadioButton = styled(BaseInput)`
  & + .label:before {
    border-radius: 50%;
  }
  & + .label:hover:after,
  &:checked + .label:after {
    content: '\\2022';
    font-size: 20px;
    line-height: 26px;
    top: -3px;
  }
  & + .label:hover:after {
    color: ${({ uncheckedColorDark }) => uncheckedColorDark};
  }
  &:checked + .label:after,
  &:checked + .label:hover:after {
    color: #fff;
  }
`;

RadioButton.defaultProps = {
  type: 'radio',
  ...lightBgStyling,
};
