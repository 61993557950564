// @ts-nocheck
import { injectI18n, I18nProp } from 'src/i18n';
import InboxIcon from '@material-ui/icons/Inbox';
import DateRangeIcon from '@material-ui/icons/DateRange';
import ErrorIcon from '@material-ui/icons/Error';
import { parse as qsParse, stringify } from 'query-string';
import React, { useEffect, useReducer } from 'react';
import { RouteChildrenProps, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { usePartialState } from '../../../hooks/usePartialState';
import { sendEvent } from '../../../utils/analytics';
import DateSelector from './DateSelector';
import RadioButtonGroup from './RadioButtonGroup';
import { Button } from './RadioButtonGroup/helper-components';
import SearchForm from './SearchForm';
import {
  HeaderWrapper,
  SearchWrapper,
  Description,
  Title,
  Link,
  LinkWrapper,
  BirthDateWrapper,
  SearchBoxWrapper,
  ValidationError,
  HiddenSubmitButton,
} from '../helper-components';
import { hasAccessToStakeholderReport } from '../../../keycloak';
import { initialState, validateDate } from './utils';
type Prop = {
  onSubmit?: () => any;
  clear?: () => any;
  page?: 'Home' | 'SHS';
};

function SearchBox({
  i18n,
  onSubmit = () => undefined,
  clear = () => undefined,
  location = {},
  history,
  page = 'Home',
}: Prop & I18nProp & RouteChildrenProps) {
  const countries = ['be', 'gb'].filter(hasAccessToStakeholderReport);
  const [{ selectedCountry, text, date }, setState, setItem] = usePartialState({
    selectedCountry: countries[0],
    date: null,
    text: '',
  });
  const setSelectedCountry = setItem('selectedCountry');
  const setDate = setItem('date');
  const setText = setItem('text');
  const [{ hasValidDate, hasRequiredFields }, setValidations] = useReducer(
    (oldState: any, newState: any) => (newState == null ? oldState : newState),
    initialState,
  );

  const resetDatePicker = () => {
    setValidations(initialState);
  };

  const setCountry = (code: any) => {
    if (code !== selectedCountry) {
      setSelectedCountry(code);

      if (page !== 'Home') {
        clear();
      }
    }
  };

  const updateSearchQuery = ({ searchText, country, selectedDate }: any) => {
    const validate = validateDate(date);

    if (Object.values(validate).every(Boolean)) {
      history.push(
        `/stakeholder-report?${stringify({
          searchText,
          country,
          ...(selectedDate
            ? {
                selectedDate: JSON.stringify(selectedDate),
              }
            : {}),
        })}`,
      );
    }

    setValidations(validate);
  };

  useEffect(() => {
    if (location.search) {
      const { country, selectedDate, searchText } = qsParse(location.search);
      const dateOfBirth = selectedDate ? JSON.parse(selectedDate.toString()) : null;
      const countryCode = country ? country.toString() : countries[0];
      setState({
        selectedCountry: countryCode,
        date: dateOfBirth,
        text: searchText,
      });
      onSubmit(searchText, countryCode, dateOfBirth);
    }
  }, [location.search]);
  return (
    <SearchBoxWrapper>
      <HeaderWrapper>
        <Title>{i18n.text('shr.search.title')}</Title>
        <Description>{i18n.text('shr.search.subtitle')}</Description>
      </HeaderWrapper>
      <SearchWrapper>
        {countries.length > 1 ? (
          <RadioButtonGroup items={countries} selected={selectedCountry} onChange={setCountry} />
        ) : (
          <Button isFirst isLast disabled width="132px">
            {i18n.text(`country.${selectedCountry}`)}
          </Button>
        )}
        <SearchForm
          text={text}
          setText={setText}
          onSubmit={(searchText: any) => {
            updateSearchQuery({
              searchText,
              country: selectedCountry,
              selectedDate: date,
            });
            sendEvent('SHR', 'Search btnclick');
          }}
        />
      </SearchWrapper>
      <LinkWrapper data-testid="date-inputs">
        {/* @ts-ignore */}
        <BirthDateWrapper
          noValidate
          marginTop={date && '10px'}
          onSubmit={(e: any) => {
            e.preventDefault();

            if (text) {
              updateSearchQuery({
                searchText: text,
                country: selectedCountry,
                selectedDate: date,
              });
              sendEvent('SHR', 'Search btnclick');
            }
          }}
        >
          {date ? (
            <DateSelector
              date={date}
              onChange={setDate}
              onClose={() => {
                setDate(null);
                resetDatePicker();
                sendEvent('SHR', 'Search: close birth of date option');
              }}
            />
          ) : (
            <Link
              to="#"
              onClick={() => {
                setDate({});
                sendEvent('SHR', 'Search: show birth of date option');
              }}
            >
              <DateRangeIcon />
              <span>{i18n.text('shr.search.add-birth-date')}</span>
            </Link>
          )}
          {/* added this line to submit the form by pressing enter key */}
          <HiddenSubmitButton />
        </BirthDateWrapper>
        <Link to="/stakeholder-report/archive">
          <InboxIcon />
          <span>{i18n.text('shr.archive.title')}</span>
        </Link>
      </LinkWrapper>
      {!hasValidDate && hasRequiredFields && (
        <ValidationError>
          <ErrorIcon fontSize="small" />
          &nbsp;{i18n.text('shr.search.date-invalid')}
        </ValidationError>
      )}
      {!hasRequiredFields && (
        <ValidationError>
          <ErrorIcon fontSize="small" />
          &nbsp;{i18n.text('shr.search.date-month-and-year')}
        </ValidationError>
      )}
    </SearchBoxWrapper>
  );
}

export default compose(injectI18n, withRouter)(SearchBox); //  as ComponentType<Prop & _I18nProp>
