// @ts-nocheck
import React from 'react';
import { Node } from 'react';
import styled from 'styled-components';
import { Heading1, Heading2 } from 'src/core/components/Heading';
import Button from 'src/core/components/Button';
import errorIcon from '../../media/icons/something-went-wrong.svg';
import ContactDetails from '../../apps/Contact/ContactDetails';
import { I18n } from 'src/i18n';
const IconContainer = styled.div`
  display: inline-block;
  background: url(${errorIcon}) no-repeat;
  background-size: 200px;
  width: 175px;
  height: 175px;
  margin-bottom: 20px;
`;
export const ErrorHeading = Heading1;
export const ErrorMessage = styled.p`
  font-size: 16px;
  font-weight: normal;
  max-width: 650px;
  margin: 15px auto 0 auto;
`;
const Container = styled.div`
  padding: 1em;
  text-align: center;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const ErrorButton = styled(Button)<{
  onClick?: () => void;
  children?: React.ReactNode;
  to?: string;
  type?: string;
}>`
  margin-top: 15px;
`;
export const ContactCustomerService = ({ i18n }: { i18n: I18n }) => (
  <div>
    <Heading2>{i18n.text('error.contact-support')}</Heading2>

    <ContactDetails i18n={i18n} />
  </div>
);

const ErrorPage = ({ children }: { children?: Node }) => (
  <Container>
    <IconContainer />
    {children}
  </Container>
);

export default ErrorPage;
