import React from 'react';
import styled from 'styled-components';
import UserBar from '../UserBar';
import colors from '../../constants/colors';
import breakpoints from '../../constants/breakpoints';
import { I18nProp } from 'src/i18n';
import { AppContainerProps } from '.';

const Nav = styled.nav<{
  isOpen: boolean;
  isHome: boolean;
  showSubMenuOnly: boolean;
}>`
  background: ${colors.secondary};
  display: ${({ isHome, showSubMenuOnly }) => (isHome || showSubMenuOnly ? 'none' : 'block')};
  flex-basis: 300px;
  flex-direction: row;
  min-width: 300px;
  box-sizing: border-box;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.5);
  min-height: 100vh;
  transition: height 0.5s ease-out;

  @media (max-width: ${breakpoints['portrait-min']}px) {
    display: block;
    flex-basis: 100%;
    min-height: 0px;
    transition: max-height 0.2s ease-in;
    max-height: ${({ isOpen }) => (isOpen ? 999 : 0)}px;
    overflow: hidden;
  }
`;

const NavList = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0;
  margin: 0;
  list-style: none;

  @media screen and (max-width: ${breakpoints['portrait-min']}px) {
    border-top: 1px solid ${colors.navDivideBefore};
  }
`;

const ListItem = styled.li`
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  height: 60px;
  position: relative;
  &:before,
  &:after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    border-bottom: 1px solid ${colors.navDivideBefore};
  }
  &:after {
    bottom: 1px;
    border-bottom-color: ${colors.navDivideAfter};
  }
  > a,
  a:link,
  a:visited {
    border-left: 10px solid ${colors.secondary};
    color: ${colors.white};
    display: block;
    height: 100%;
    padding: 15px;
    text-decoration: none;
  }
  ${
    '' /* `react-router`'s `<IndexLink />` will add `selected` to the classname of the rendered link */
  } > a:hover,
    a:active,
    a.selected {
    border-color: ${colors.secondaryDark};
  }
`;

const UserBarListItem = styled(ListItem)`
  display: none;
  height: auto;

  @media screen and (max-width: ${breakpoints['portrait-min']}px) {
    display: block;
  }

  &:before,
  &:after {
    border: none;
  }
`;

type NavigationProps = AppContainerProps & {
  isOpen: boolean;
  showLogoutButton?: boolean;
  isSubMenu?: boolean;
};

const Navigation = ({
  i18n,
  name,
  companyName,
  locales,
  language,
  doLogout,
  onLanguageChange,
  isOpen,
  showLanguageBar,
  showLogoutButton,
  isSubMenu = true,
  routes = [],
  showNav = true,
  showSubMenuOnly = false,
  isHome = true,
  showHelpBlock = false,
  onContactsClick,
}: NavigationProps & I18nProp) => 
  showNav ? (
    <Nav isOpen={isOpen} isHome={isHome} showSubMenuOnly={showSubMenuOnly}>
      <NavList>
        {routes.map((route) => (
          <ListItem key={route.props.to || route.props.href}>{route}</ListItem>
        ))}
        <UserBarListItem key={routes.length}>
          <UserBar
            i18n={i18n}
            name={name}
            companyName={companyName}
            locales={locales}
            showLanguageBar={showLanguageBar}
            showLogoutButton={showLogoutButton}
            language={language}
            doLogout={doLogout}
            onLanguageChange={onLanguageChange}
            isSubMenu={isSubMenu}
            isHome={isHome}
            showHelpBlock={showHelpBlock}
            onContactsClick={onContactsClick}
          />
        </UserBarListItem>
      </NavList>
    </Nav>
  ) : null;


export default Navigation;
