import { useReducer } from 'react';

const merge = (state, update) => ({
  ...state,
  ...update,
});

export default function useModals() {
  const [state, update] = useReducer(merge, {
    monitoringModal: null,
    cocModal: null,
    annualAccountModal: null,
    gdmModal: null,
  });

  const setState = (key: any) => (value: any) =>
    update({
      [key]: value,
    });

  return [
    state,
    {
      setMonitoringModal: setState('monitoringModal'),
      setCocModal: setState('cocModal'),
      setAnnualAccountModal: setState('annualAccountModal'),
      setGdmModal: setState('gdmModal'),
    },
    Boolean(state.monitoringModal || state.cocModal || state.annualAccountModal || state.gdmModal),
  ];
}
