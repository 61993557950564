import styled from 'styled-components';
import { Link } from 'react-router-dom';
import * as colors from '@graydon/ui-colors';
import { sans } from '../../constants/fonts';
import breadcrumbArrowRight from '../../media/icons/breadcrumb_arrow_right.svg';
import breadcrumbArrowLeft from '../../media/icons/breadcrumb_arrow_left.svg';

export const DesktopList = styled.ul<{
  isPopup?: boolean;
}>`
  list-style: none;
  font: 300 1rem ${sans};
  color: ${colors.gray};
  margin: 0px;
  padding: 16px 0px;
  flex: 1;

  ${({ isPopup }) => isPopup && 'display: none'};

  @media (max-width: 768px) {
    display: none;
  }
`;

export const MobileList = styled(DesktopList)`
  display: none;

  ${({ isPopup }) => isPopup && 'display: block'};

  @media (max-width: 768px) {
    display: block;
  }
`;

const liMobileMixin = `
  &::after {
    display: none;
  }
  &::before {
    margin: 0px 10px 0px 0px;
    content: url(${breadcrumbArrowLeft});
  }
`;

export const ListItem = styled.li<{
  isPopup?: boolean;
}>`
  display: inline;

  &::after {
    margin: 0 8px;
    content: url(${breadcrumbArrowRight});
  }

  &:last-child {
    &::after {
      display: none;
    }
  }

  ${({ isPopup }) => isPopup && liMobileMixin};

  @media (max-width: 768px) {
    ${liMobileMixin};
  }
`;

export const StyledLink = styled(Link)`
  color: ${colors.gray};

  :link,
  :hover,
  :active,
  :visited {
    color: ${colors.gray};
  }
`;
