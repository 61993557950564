import styled from 'styled-components';
import * as colors from '@graydon/ui-colors';
import { sans } from '../../design-system/constants/fonts';

export const Title = styled.h2`
  font: 500 22px ${sans};
  color: ${colors.greenLight};
  margin: 0;
  margin-bottom: 24px;
  padding-right: 32px;
`;

export const Explanation = styled.p`
  font-size: 16px;
  margin: 0;
  color: ${colors.gray};
  margin-bottom: 24px;
`;

export const Row = styled.div<{
  hideInMobile?: boolean;
}>`
  padding: 10px;
  background: ${colors.white};
  &:nth-child(odd) {
    background: ${colors.grayLight5};
  }
  display: flex;
  flex-direction: row;
  @media (max-width: 768px) {
    ${({ hideInMobile }) => hideInMobile && 'display: none;'}
    flex-direction: column;
  }
`;

export const RowData = styled.div<{
  width?: string;
  hideInDesktop?: boolean;
}>`
  width: ${({ width = '33%' }) => width};
  display: inline-block;
  color: ${colors.gray};
  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
`;

export const RowHeader = styled(RowData)`
  color: ${colors.greenLight};
  font-weight: 500;
  text-transform: uppercase;
  font-size: 14px;
  ${({ hideInDesktop }) => hideInDesktop && 'display: none;'}
  @media (max-width: 768px) {
    ${({ hideInDesktop }) => hideInDesktop && 'display: block;'}
    margin-bottom: 0px;
  }
`;

export const Link = styled.a`
  color: ${colors.blue};
  text-decoration: underline;
  cursor: pointer;
`;

export const NotAvailable = styled.span`
  color: ${colors.grayLight1};
`;
