import React, { useState } from 'react';
import { Component, TooltipIcon, Modal, Link } from './styles';
import { sendEvent } from './analytics';
import { useGlobalEvent } from './globalHandler';
import { I18nProp } from 'src/i18n';

const KEY_ESCAPE = 'Escape';

type HelpCenterModalProps = {
  consumerId?: string;
  language?: string;
};

export const HelpCenterModal = ({
  i18n,
  language,
  consumerId,
}: HelpCenterModalProps & I18nProp) => {
  const [showModal, setShowModal] = useState(false);

  useGlobalEvent('keydown', ({ key }) => {
    if (key === KEY_ESCAPE) setShowModal(false);
  });

  useGlobalEvent('mouseup', () => {
    setShowModal(false);
  });

  function onClickIcon() {
    sendEvent('help-center', `click help center icon to ${!showModal ? 'open' : 'close'}`);
    setShowModal((value) => !value);
  }

  function getCountry() {
    if (consumerId.indexOf('1301') === 0) return 'uk';
    else if (consumerId.indexOf('1201') === 0) return 'be';
    return 'nl';
  }

  function getLinkPrefix() {
    const lang = language.toLowerCase().substr(0, 2);
    const country = getCountry();
    const prefix =
      lang === 'en' || (lang === 'fr' && country !== 'be') || (lang === 'nl' && country === 'uk')
        ? 'en_GB'
        : lang;
    return `https://${getCountry()}-graydon.trengohelp.com/${prefix}`;
  }

  return (
    <Component>
      <TooltipIcon
        size={20}
        onClick={onClickIcon}
        onMouseUp={(ev) => ev.stopPropagation()}
        color="#EFF0F0"
        bgColor="#01596A"
        fontSize={13}
        invertColors={showModal}
      >
        ?
      </TooltipIcon>
      {showModal && (
        <Modal onMouseUp={(ev) => ev.stopPropagation()}>
          <h2>{i18n.text('general.help-center.title')}</h2>
          <p>{i18n.text('general.help-center.desc')}</p>
          <Link
            target="_blank"
            onClick={() => {
              sendEvent('help-center', `click help center link`);
              setShowModal(false);
            }}
            href={getLinkPrefix()}
          >
            {i18n.text('general.help-center.link')}
          </Link>
        </Modal>
      )}
    </Component>
  );
};
