import get from 'lodash/get';
import { getLast12ActiveMonths } from '../common';
export const extractPaymentScoreTrend = (data: any, date = new Date()) => {
  const latestValidDate = new Date(date.getFullYear(), date.getMonth(), 1);
  const paymentScoreTrend = get(
    data,
    'ratingsProfile.graydonGroupCreditScores.historicGraydonGroupCreditScores.paymentScore',
    [],
  );
  return getLast12ActiveMonths(latestValidDate).reduce((acc: any, period: any) => {
    const scoreForGivenPeriod = paymentScoreTrend.find(
      (paymentScore: any) =>
        paymentScore.month === period.month && paymentScore.year === period.year,
    );
    const score = get(scoreForGivenPeriod, 'value', null);
    period.score = score && score > 10 ? 10 : score;
    return [period, ...acc];
  }, []);
};
