import get from 'lodash/get';
import { parseDate } from '../common';
export const extractUnsecuredCreditorsData = (data: any) => {
  const unsecuredCreditors = get(data, 'paymentBehaviourProfile.unsecuredCreditors');
  const creditorsList = get(unsecuredCreditors, 'creditors', []);
  const statementOfAffairsDate = parseDate(get(unsecuredCreditors, 'statementOfAffairsDate'));
  const totalUnsecuredAmount = get(unsecuredCreditors, 'totalUnsecuredAmount.value');
  const totalUnsecuredAmountCurrency = get(unsecuredCreditors, 'totalUnsecuredAmount.currencyCode');
  const totalAmountOfCreditors = get(unsecuredCreditors, 'totalAmountOfCreditors', 0);
  const creditors = creditorsList.map((item: any) => {
    const {
      name,
      details,
      unsecuredAmount: { value: unsecuredAmountValue, currencyCode: unsecuredAmountCurrency },
    } = item;
    return {
      key: `${name} ${unsecuredAmountValue}`,
      name,
      details,
      unsecuredAmountCurrency,
      unsecuredAmountValue,
    };
  });
  return {
    creditors,
    statementOfAffairsDate,
    totalUnsecuredAmount,
    totalUnsecuredAmountCurrency,
    totalAmountOfCreditors,
    noDataAvailable: creditors === undefined,
  };
};
