export function getFilenameFromHeader(contentDispositionHeader) {
  const utf8Prefix = "filename*=UTF-8''";
  const utf8PrefixIndex = contentDispositionHeader.indexOf(utf8Prefix);
  if (utf8PrefixIndex !== -1) {
    return decodeURIComponent(
      contentDispositionHeader.substring(utf8PrefixIndex + utf8Prefix.length),
    );
  }

  const prefix = 'attachment; filename=';
  if (contentDispositionHeader.substring(0, prefix.length) === prefix) {
    const hasQuotes =
      contentDispositionHeader.charAt(prefix.length) === '"' &&
      contentDispositionHeader.charAt(contentDispositionHeader.length - 1) === '"';
    return hasQuotes
      ? contentDispositionHeader.substring(prefix.length + 1, contentDispositionHeader.length - 1)
      : contentDispositionHeader.substring(prefix.length);
  }
  return null;
}
