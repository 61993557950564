import React, { Component, ComponentType, ReactNode } from 'react';
import { I18nProp } from 'src/i18n';
import styled from 'styled-components';
import breakpoints from '../../constants/breakpoints';
import Header from '../Header';
import { Locales } from '../UserBar';
import CookieBanner from './CookieBanner';
import Navigation from './Navigation';

const MainWrapper = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: ${breakpoints['portrait-min']}px) {
    display: block;
  }
`;

// TODO: move types to separate file
export type AppContainerProps = Locales &
  I18nProp & {
    name: string;
    companyName: string;
    doLogout: () => void;
    onLanguageChange?: (item: { label: string; value: string }) => () => void;
    isHome?: boolean;
    showSubMenuOnly?: boolean;
    altUserBar?: boolean;
    homePath?: string;
    className?: string;
    appName?: string;
    appPath?: string;
    cookiesAccepted?: boolean;
    children?: ReactNode;
    onCookieAccept?: () => void;
    country?: string;
    showNav?: boolean;
    routes?: any[];
    showLanguageBar?: boolean;
    showHelpBlock?: boolean;
    onContactsClick?: () => void;
    Link?: ComponentType<any>;
  };

type State = {
  isOpen: boolean;
};

class AppContainer extends Component<AppContainerProps, State> {
  constructor(props: AppContainerProps) {
    super(props);
    this.state = { isOpen: false };
    this.onToggleMenu = this.onToggleMenu.bind(this);
  }

  onToggleMenu() {
    this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
  }

  render() {
    const {
      i18n,
      name,
      companyName,
      locales = [
        { label: 'Nederlands', value: 'nl-NL' },
        { label: 'English', value: 'en-GB' },
        { label: 'Français', value: 'fr' },
      ],
      language = 'en-GB',
      doLogout,
      onLanguageChange,
      isHome = false,
      showSubMenuOnly = false,
      altUserBar = false,
      homePath = '/apps/home',
      className,
      appName,
      appPath,
      children,
      onCookieAccept,
      country = 'NL',
      cookiesAccepted = false,
      showNav = true,
      routes,
      showLanguageBar = true,
      showHelpBlock = false,
      onContactsClick,
      Link,
    } = this.props;

    return (
      <div className={className}>
        <Header
          i18n={i18n}
          name={name}
          companyName={companyName}
          locales={locales}
          language={language}
          doLogout={doLogout}
          onLanguageChange={onLanguageChange}
          isHome={isHome}
          altUserBar={altUserBar}
          onToggleMenu={this.onToggleMenu}
          isOpen={this.state.isOpen}
          homePath={homePath}
          className={className}
          appName={appName}
          appPath={appPath}
          showLanguageBar={showLanguageBar}
          showHelpBlock={showHelpBlock}
          onContactsClick={onContactsClick}
          Link={Link}
        />
        <MainWrapper>
          <Navigation
            i18n={i18n}
            name={name}
            companyName={companyName}
            showLanguageBar={showLanguageBar}
            locales={locales}
            language={language}
            doLogout={doLogout}
            onLanguageChange={onLanguageChange}
            isHome={isHome}
            showSubMenuOnly={showSubMenuOnly}
            isOpen={this.state.isOpen}
            showNav={showNav}
            routes={routes}
            showHelpBlock={showHelpBlock}
            onContactsClick={onContactsClick}
          />
          {children}
          {!cookiesAccepted && (
            <CookieBanner i18n={i18n} onAccept={onCookieAccept} country={country} />
          )}
        </MainWrapper>
      </div>
    );
  }
}

export default AppContainer;
