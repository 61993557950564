export const sans = '"Roboto", Helvetica, Arial, sans-serif';
export const serif = '"Roboto Slab", "Times New Roman", Times, serif';
export default {
  sans,
  serif,
};
export const fonts = {
  h2: `500 22px ${sans}`,
  body: `300 16px ${sans}`,
  small: `400 14px ${sans}`,
};
