import React from 'react';
import styled from 'styled-components';
import breakpoints from '../../constants/breakpoints';
import colors from '../../constants/colors';
import { Locales } from '.';

const Button = styled.button<{
  selected: boolean;
}>`
  padding: 0px;
  min-width: 80px;
  box-sizing: border-box;
  border-width: 6px 6px;
  border-color: transparent;
  border-style: solid;
  border-radius: 5px;
  color: ${colors.white};

  cursor: ${({ selected }) => (selected ? 'initial' : 'pointer')};
  background: ${({ selected }) => (selected ? colors.secondaryDark : colors.secondary)};
  &:hover {
    background: ${({ selected }) => (selected ? colors.secondaryDark : colors.secondaryLight)};
  }

  &:active,
  &:focus {
    outline: none;
  }

  @media screen and (max-width: ${breakpoints['portrait-min']}px) {
    width: 30%;
    border-width: 10px 10px;
  }
`;

const ButtonsContainer = styled.nav<{
  useBottomPadding: boolean;
}>`
  ${({ useBottomPadding }) =>
    useBottomPadding ? 'padding: 0px 16px 10px 16px;' : 'padding: 0px 16px;'} font-size: 12px;
  font-family: inherit;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: ${breakpoints['portrait-min']}px) {
    ${({ useBottomPadding }) =>
      useBottomPadding ? 'padding: 0px 16px 16px 16px;' : 'padding: 0px 16px;'};
  }
`;

type ButtonGroupProps = Locales & {
  onClick: (item: { label: string; value: string }) => () => void;
  useBottomPadding?: boolean;
  className?: string;
};

export default function ButtonGroup(props: ButtonGroupProps) {
  const { locales, onClick, language, useBottomPadding = true, ...otherProps } = props;
  return (
    <ButtonsContainer useBottomPadding={useBottomPadding} {...otherProps}>
      {locales.map((item) => (
        <Button
          key={`lang-${item.label}-${item.value}`}
          type="button"
          value={item.value}
          onClick={onClick(item)}
          selected={item.value === language}
          className={item.value === language ? 'selected' : ''}
        >
          {item.label}
        </Button>
      ))}
    </ButtonsContainer>
  );
}
