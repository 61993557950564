import createReducer from 'create-reducer-map';
import {
  SET_COMPLETED_ORDERS,
  SET_PENDING_ORDERS,
  FETCHING_ORDERS,
  TURN_PAGE_COMPLETED_ORDERS,
  TURN_PAGE_PENDING_ORDERS,
  INVALIDATE_COMPLETED_ORDERS,
  INVALIDATE_PENDING_ORDERS,
} from './constants';

type State = {
  isFetching: boolean;
  isError: boolean;
  pending: {
    totalPages: null;
    page: number;
    orders: null;
  };
  completed: {
    totalPages: null;
    page: number;
    orders: null;
  };
};

export const initialState = {
  isFetching: false,
  isError: false,
  pending: {
    totalPages: null,
    page: 0,
    orders: null,
  },
  completed: {
    totalPages: null,
    page: 0,
    orders: null,
  },
};
const reducer = {
  [FETCHING_ORDERS]: (state: State) => ({
    ...state,
    isFetching: true,
  }),
  [SET_COMPLETED_ORDERS]: (state: State, { totalPages, orders, textSearch, language }: any) => ({
    ...state,

    completed: {
      ...state.completed,
      orders:
        state.completed.orders === null ? orders : (state.completed.orders ?? []).concat(orders),
      totalPages,
      textSearch,
      language,
    },

    isFetching: false,
  }),
  [SET_PENDING_ORDERS]: (state: State, { orders, totalPages, textSearch, language }: any) => ({
    ...state,

    pending: {
      ...state.pending,
      orders: state.pending.orders === null ? orders : (state.pending.orders ?? []).concat(orders),
      totalPages,
      textSearch,
      language,
    },

    isFetching: false,
  }),
  [TURN_PAGE_COMPLETED_ORDERS]: (state: State) => ({
    ...state,
    completed: { ...state.completed, page: state.completed.page + 1 },
  }),
  [TURN_PAGE_PENDING_ORDERS]: (state: State) => ({
    ...state,
    pending: { ...state.pending, page: state.pending.page + 1 },
  }),
  [INVALIDATE_PENDING_ORDERS]: (state: State) => ({
    ...state,
    pending: { ...initialState.pending },
  }),
  [INVALIDATE_COMPLETED_ORDERS]: (state: State) => ({
    ...state,
    completed: { ...initialState.completed },
  }),
};
export default createReducer(initialState, reducer);
