// @ts-nocheck
import React from 'react';
import { white, danger, gray, green, greenLight, calamityRed, grayLight } from '@graydon/ui-colors';
import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';
export const HeaderWrapper = styled.div`
  width: 100%;
  margin-bottom: 24px;
`;
export const Title = styled.h2`
  font-size: 22px;
  color: ${greenLight};
  margin: 0;
  font-weight: 500;
  margin-bottom: 8px;
`;
export const Description = styled.p`
  margin: 0;
  color: ${gray};
  font-size: 16px;
`;
export const ErrorWrapper = styled.p`
  margin: 0;
  color: ${danger};
  font-size: 16px;
  margin-top: 8px;
`;
export const SearchWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
export const BirthDateWrapper = styled.form`
  margin-right: 16px;

  @media (max-width: 768px) {
    margin-right: 0;
    a {
      margin-right: 16px;
    }
  }
`;
export const SearchBoxWrapper = styled.div`
  padding: 32px;
  background-color: ${white};
  border-radius: 4px;
  position: relative;
  @media (max-width: 768px) {
    padding: 32px 16px 24px 16px;
    border-radius: 0;
  }
`;
export const Form = styled.form`
  display: flex;
  width: 100%;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 16px;
  .country-select {
    width: 240px;
    margin-right: 8px;
  }
  .state-select {
    width: 180px;
    margin-right: 8px;
  }
  .search-bar {
    flex: 1;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    .country-select,
    .state-select,
    .location-selector,
    .search-bar {
      width: 100%;
      margin-right: 0;
      margin-bottom: 16px;
    }
    .search-bar {
      margin-bottom: 0;
    }
  }
`;
export const Link = styled(({ disabled, ...props }) =>
  disabled ? <a {...props} /> : <RouterLink {...props} />,
)`
  display: flex;
  align-items: center;
  color: ${({ disabled }) => (disabled ? grayLight : green)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  text-decoration: underline;
  > span {
    margin-left: 6px;
  }
`;
export const LinkWrapper = styled.div`
  display: flex;
  margin-top: 24px;
  @media (max-width: 768px) {
    flex-wrap: wrap;
    .method-selector {
      width: 100%;
      margin-bottom: 16px;
    }
  }
`;
export const ValidationError = styled.div`
  display: flex;
  position: absolute;
  left: 32px;
  bottom: 6px;
  color: ${calamityRed};
  @media (max-width: 768px) {
    position: initial;
    margin-top: 4px;
  }
`;
// the submit event does not fire with display:none in Safari
export const HiddenSubmitButton = styled(({ className }) => (
  <button className={className} type="submit" />
))`
  max-width: 0;
  padding: 0;
  border: 0;
  margin: 0;
  visibility: hidden;
  position: absolute;
`;
