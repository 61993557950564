// @ts-nocheck
import { Node } from 'react';
let notificationSystem: any;
export function setNotificationSystem(newNotificationSystem: any) {
  notificationSystem = newNotificationSystem;
}
// https://github.com/igorprado/react-notification-system#creating-a-notification
const defaultOptions = {
  position: 'tr',
};
export function sendNotification(options: {
  title?: string;
  message?: string | Node;
  level: 'success' | 'error' | 'warning' | 'info';
  position?: 'tr' | 'tl' | 'tc' | 'br' | 'bl' | 'bc';
  autoDismiss?: number;
  dismissible?: boolean;
  action?: {
    label: string;
    callback: () => unknown;
  };
  children?: any;
  onAdd?: () => unknown;
  onRemove?: () => unknown;
  uid?: string;
}) {
  if (notificationSystem) {
    notificationSystem.addNotification({ ...defaultOptions, ...options });
  }
}
