import React from 'react';
import styled from 'styled-components';
import ButtonGroup from './ButtonGroup';
import { I18nProp } from 'src/i18n';
import { Locales } from '.';

const LanguageHeader = styled.h3`
  margin: 10px 10px 10px 16px;
  font-weight: 400;
  font-size: 12px;
  text-transform: uppercase;
`;

type ChangeLanguageProps = Locales & {
  onLanguageChange: (item: { label: string; value: string }) => () => void;
  useBottomPadding?: boolean;
};

function ChangeLanguage({
  i18n,
  locales,
  language,
  onLanguageChange,
  useBottomPadding,
}: ChangeLanguageProps & I18nProp) {
  const sortedLocales = locales.slice().sort((a, b) => a.label.localeCompare(b.label));

  return (
    <div>
      <LanguageHeader>{i18n.text('user.bar.language', 'Language')}</LanguageHeader>
      <ButtonGroup
        className="change-language"
        locales={sortedLocales}
        onClick={onLanguageChange}
        language={language}
        useBottomPadding={useBottomPadding}
      />
    </div>
  );
}

ChangeLanguage.defaultProps = {
  useBottomPadding: false,
};

export default ChangeLanguage;
