import { useCallback, useEffect, useReducer } from 'react';
import capture from '../utils/capture';
import { arrayToObject } from '../utils/FP-helpers';
import { processFlow, processStates } from '../utils/processFlow';
// this hook handles most aspect of running an async (promised based) function
// it returns result, processState (init, started, failed, successful), error, run
// run is a function that you can use it to rerun the task
// you can disable autoRun then the fn will not call in component on mount

export default function useResult<T>(
  task: () => Promise<T>,
  initial?: T,
  autoRun = true,
): [T | null | undefined, number, string | null | undefined, () => Promise<T>] {
  const [{ result, error, processState }, dispatch] = useReducer(
    (state: any, payload: any) => {
      return { ...state, ...payload };
    },
    {
      result: initial,
      error: null,
      processState: processStates.init,
    },
  );
  const overallDispatch = useCallback(
    capture(0)((buffer) => dispatch(buffer.reduce(arrayToObject, {}))),
    [],
  );

  const setState = (key: string) => (value: any) =>
    overallDispatch({
      [key]: value,
    });

  const doTask = processFlow(setState('processState'))(task);

  const run = () => doTask().then(setState('result')).catch(setState('error'));

  useEffect(() => {
    if (autoRun) {
      run();
    }
  }, []);
  return [result, processState, error, run];
}
