import React from 'react';
import { Button } from './helper-components';
type Props = {
  items: string[];
  selected: string;
  onChange: (item: string) => any;
};
export default function RadioButtonGroup({ items, selected, onChange }: Props) {
  const isFirst = (i: any) => i === 0;

  const isLast = (i: any) => i === items.length - 1;

  const isSelected = (name: any) => name === selected;

  const renderButton = (name: any, i: any) => (
    <Button
      isFirst={isFirst(i)}
      isLast={isLast(i)}
      selected={isSelected(name)}
      onClick={() => onChange(name)}
      uppercase
      key={name}
    >
      {name === 'gb' ? 'uk' : name}
    </Button>
  );

  return <div>{items.map(renderButton)}</div>;
}
