import get from 'lodash/get';
import { parseDate, getPrimaryActivity } from '../common';
export const extractCompanyDetailsData = (data: any) => {
  const primaryActivity = getPrimaryActivity(data);
  const endDate = parseDate(get(data, 'companyProfile.dissolvedDate'));
  const socialRecognition = get(data, 'companyProfile.miscellaneous.socialRecognition', false);
  const agriculturalSocietyRecognition = get(
    data,
    'companyProfile.miscellaneous.agriculturalSocietyRecognition',
    false,
  );
  const landAgencyRecognition = get(
    data,
    'companyProfile.miscellaneous.landAgencyRecognition',
    false,
  );
  const { currency, amountUnitCode, ...items } = get(data, 'companyProfile.capital', {});
  return {
    activitiesCode: get(primaryActivity, 'code.value'),
    activityGroup: get(primaryActivity, 'groupCodeDescription'),
    activityText: get(data, 'companyProfile.activityText'),
    companyName: get(data, 'companyProfile.companyName', ''),
    previousCompanyName: get(data, 'companyProfile.previousCompanyName', ''),
    countryCode: get(data, 'companyIdentification.countryCode'),
    endDate: endDate && endDate < new Date() ? endDate : null,
    // End date cannot be greater then current date.
    exportIndicator: get(data, 'companyProfile.importExport.exportIndicator'),
    graydonNumber: get(data, 'companyIdentification.enterpriseId'),
    importIndicator: get(data, 'companyProfile.importExport.importIndicator'),
    incorporationDate: parseDate(get(data, 'companyProfile.foundation.date')),
    numberOfEmployees: get(data, 'companyProfile.employees.numberOfEmployees'),
    numberOfFte: get(data, 'companyProfile.employees.numberOfFte'),
    oldGraydonNumber: get(data, 'companyIdentification.graydonCompanyId'),
    registrationId: get(data, 'companyIdentification.registrationId'),
    rszNumber: get(data, 'companyIdentification.rszNumber'),
    vatNumber: get(data, 'companyIdentification.vatNumber'),
    leiCode: get(data, 'companyIdentification.legalEntityId'),
    vatStatus: get(data, 'companyProfile.vatLiable'),
    employersCategory: get(data, 'companyProfile.miscellaneous.employersCategory'),
    jointCommittee: get(data, 'companyProfile.miscellaneous.paritairComitee'),
    governanceForm: get(data, 'companyProfile.miscellaneous.governanceFormCode.descriptions'),
    recognition: [
      socialRecognition && 'socialRecognition',
      agriculturalSocietyRecognition && 'agriculturalSocietyRecognition',
      landAgencyRecognition && 'landAgencyRecognition',
    ].filter((x) => x),
    amountLiability: get(data, 'companyProfile.miscellaneous.liabilityIndication', {}),
    foundingCapital: {
      amount: get(data, 'financialProfile.capital.foundingCapital'),
      currencyCode: get(data, 'financialProfile.capital.currency'),
      multiplier: get(data, 'financialProfile.capital.amountUnitCode.value'),
    },
    legalFormSince: parseDate(get(data, 'companyProfile.legalForm.startDate')),
    soleProprietor: get(data, 'companyProfile.soleProprietor'),
    capital: {
      currencyCode: currency || 'EUR',
      multiplier: get(amountUnitCode, 'value'),
      items: Object.keys(items || {}).map((key) => ({
        name: key,
        amount: items[key],
      })),
    },
  };
};
