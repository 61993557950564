// @ts-nocheck
import download from 'downloadjs';
import { getFilenameFromHeader } from 'src/core/utils/http';
import compose from 'lodash/fp/compose';
import prop from 'lodash/fp/get';
import orderBy from 'lodash/fp/orderBy';
import { stringify } from 'query-string';
import { get, http } from '../../utils/api';
import { compactObject } from '../../utils/FP-helpers';
const getQueryString = compose(stringify, compactObject);
export function downloadReport(enterpriseId: number, date?: string, cocNumber?: string) {
  const queryParams = getQueryString({
    cocNumber,
    date,
  });
  return http(
    'get',
    `/coc-extracts/download/${enterpriseId}${queryParams ? `?${queryParams}` : ''}`,
    null,
    false,
    {
      Accept: 'application/pdf',
    },
  )
    .then((res) =>
      res.blob().then((blob: any) => ({
        blob,
        name: getFilenameFromHeader(res.headers.get('content-disposition')),
      })),
    )
    .then(({ blob, name }) => {
      if (!download(blob, name)) {
        return Promise.reject(Error('invalid file format'));
      }

      return true;
    });
}
export function getCached(enterpriseId: number, cocNumber?: string) {
  const queryParams = getQueryString({
    cocNumber,
  });
  return get(`/coc-extracts/cached/${enterpriseId}${queryParams ? `?${queryParams}` : ''}`)
    .then(prop('documents'))
    .then(
      orderBy<{
        date: string;
      }>('date', 'desc'),
    );
}
