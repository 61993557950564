import React from 'react';
import { sans } from '../../constants/fonts';

type LabelProps = {
  selected?: boolean;
  children?: string;
  x?: number;
  y?: number;
  textAnchor?: string;
};

const Label = ({ selected = false, children = '', ...props }: LabelProps) => (
  <text
    {...props}
    style={{ font: `normal normal ${selected ? '700 18px' : '400 16px'}/1 ${sans}` }}
  >
    {children}
  </text>
);

export default Label;
