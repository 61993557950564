import * as colors from '@graydon/ui-colors';
import React from 'react';
import CircleLoader from 'src/core/components/loaders/CircleLoader';
import { I18nProp, injectI18n } from 'src/i18n';
import styled from 'styled-components';
import Popup from '../../../../components/Popup';

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  & > * {
    margin: 8px;
  }
`;
const Message = styled.div`
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
  color: ${colors.blueLight3};
`;

type State = {
  step: number;
};

class ReportDownloadModal extends React.Component<I18nProp, State> {
  stepInterval: any;
  state = {
    step: 0,
  };

  componentDidMount() {
    this.stepInterval = setInterval(() => {
      this.setState(({ step }) => ({
        step: (step + 1) % 5,
      }));
    }, 2000);
  }

  componentWillUnmount() {
    clearInterval(this.stepInterval);
  }

  render() {
    const { i18n } = this.props;
    const { step } = this.state;
    return (
      <Popup displayCloseButton={false} padding="22px">
        <Layout>
          <Message>{i18n.text('icr.search-results.modal.downloading.heading')}</Message>
          <CircleLoader color={colors.green} />
          <Message>{i18n.text(`icr.search-results.modal.downloading.step${step}`)}</Message>
        </Layout>
      </Popup>
    );
  }
}

export default injectI18n(ReportDownloadModal);
