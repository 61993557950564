import styled from 'styled-components';
import * as colors from '@graydon/ui-colors';
export const HorizontalLine = styled.div`
  width: 100%;
  height: 0;
  margin: 15px 0;
  border-top: 1px solid ${colors.grayLight2};
`;
export const ResultsList = styled.ul`
  padding: 0;
  list-style: none;
`;
export const SearchMessage = styled.p`
  color: ${colors.black};
  font-size: 1em;
  font-weight: 300;
  margin: 20px 0;
`;
export const NoResultsText = styled.div`
  padding-top: 1.2em;
  padding-left: 1.2em;
  color: ${colors.gray};

  ul {
    padding-left: 1em;
  }
`;
export const TotalWrapper = styled.div`
  padding-top: 32px;
  color: ${colors.gray};
  @media (max-width: 768px) {
    padding-top: 24px;
  }
`;
