import get from 'lodash/get';
import filter from 'lodash/filter';
import { parseDate } from '../common';
export const byDateComparator = (a: any, b: any) => {
  if (!a.date && !b.date) return 0;
  // Entites with date leading
  if (!a.date && b.date) return -1;
  if (a.date && !b.date) return 1;
  // Latest end date is leading
  if (a.date.getTime() > b.date.getTime()) return -1;
  if (a.date.getTime() < b.date.getTime()) return 1;
  // If everything equals, do not sort.
  return 0;
};
export const extractSummonsAndJudgmentsData = (data: any) => {
  const items = get(data, 'paymentBehaviourProfile.summons', []);
  const availableItems = items
    .map((summon, i) => ({
      key: i,
      date: parseDate(get(summon, 'date')),
      type: get(summon, 'sourceCode.value'),
      descriptions: get(summon, 'additionalInformationCode.descriptions'),
    }))
    .sort(byDateComparator);
  return {
    totalCount: items.length,
    availableItems: {
      RSZ: filter(availableItems, ['type', 'BE_250']),
      InsuranceAndSelfEmployed: filter(availableItems, ['type', 'BE_251']),
      SocialSecurityFund: filter(availableItems, ['type', 'BE_252']),
    },
  };
};
