import styled from 'styled-components';
import colors from '../../constants/colors';
import breakpoints from '../../constants/breakpoints';

const UserTitle = styled.h2`
  margin: 0;
  padding: 12px 10px 8px 16px;
  color: #fff;
  background: ${colors.secondaryDark};
  text-align: left;
  font-weight: normal;
  font-size: 17px;

  @media screen and (max-width: ${breakpoints['portrait-min']}px) {
    cursor: pointer;
  }
`;

export default UserTitle;
