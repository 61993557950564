// TODO: Remove file
import { shape, func, string } from 'prop-types';

export const i18nShape = shape({
  locale: string.isRequired,
  text: func.isRequired,
  number: func.isRequired,
  date: func.isRequired,
  currency: func.isRequired,
  formatRelative: func.isRequired,
  setLocale: func.isRequired,
});

export default i18nShape;
