import * as colors from '@graydon/ui-colors';
import { sans } from 'src/core/constants/fonts';
import styled from 'styled-components';
import searchIcon from '../../media/icons/icon_search.svg';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  position: relative;
`;

export const InputField = styled.input`
  font-family: ${sans};
  font-weight: 300;
  font-size: 1rem;
  height: 44px;
  padding: 0 80px 0 18px;
  border: 1px solid ${colors.grayLight2};
  background-color: ${colors.white};
  color: ${colors.gray};
  outline: none;
  width: 100%;
  flex-grow: 1;
  border-radius: 4px;

  &[placeholder] {
    text-overflow: ellipsis;
  }
  &::placeholder {
    color: ${colors.grayLight2};
  }

  /* Need for Safari to display the input with type="search" properly */
  -webkit-appearance: none;

  &::-ms-clear {
    display: none;
    width: 0;
    height: 0;
  }
  &::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }
  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }
`;

export const ResetButton = styled.div<{
  isVisible?: boolean;
}>`
  color: ${colors.grayLight1};
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
  font-weight: 400;
  font-size: 2.5em;
  line-height: 100%;
  position: absolute;
  top: 50%;
  right: 50px;
  transform: translate(0, -50%);
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
`;

export const SearchButton = styled.button<{
  disabled?: boolean;
  primary?: boolean;
}>`
  position: absolute;
  right: 0;
  background-image: url(${searchIcon});
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 50%;
  border: 0;
  padding: 1.2em;
  width: 44px;
  height: 44px;
  border-left: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  outline: none;
  display: inline-block;
  transition: background-color 0.2s ease-out;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  ${({ disabled }) => disabled && 'pointer-events: none;'}
  background-color:${({ disabled }) => (disabled ? colors.grayLight : colors.orange)};

  &:hover {
    background-color: ${({ disabled }) => (disabled ? colors.grayLight : colors.orangeDark)};
  }
`;
