import { Set } from 'immutable';

export function selectNodes(nodes, previousSelectedNodes) {
  return previousSelectedNodes.withMutations((selectedNodes) => {
    const recursiveSelect = (currentNode) => {
      selectedNodes.add(currentNode.id);

      if (currentNode.children) {
        currentNode.children.forEach(recursiveSelect);
      }
    };
    nodes.forEach(recursiveSelect);
  });
}

export function selectNode(node, previousSelectedNodes) {
  return selectNodes([node], previousSelectedNodes);
}

export function deselectNodes(nodes, previousSelectedNodes, deselectParents = false) {
  return previousSelectedNodes.withMutations((selectedNodes) => {
    const recursiveDeselect = (currentNode) => {
      selectedNodes.delete(currentNode.id);

      if (currentNode.children) {
        currentNode.children.forEach(recursiveDeselect);
      }
    };
    nodes.forEach((node) => {
      recursiveDeselect(node);

      if (deselectParents) {
        let currentParent = node.parent;
        while (currentParent) {
          selectedNodes.delete(currentParent.id);
          currentParent = currentParent.parent;
        }
      }
    });
  });
}

export function deselectNode(node, previousSelectedNodes) {
  return deselectNodes([node], previousSelectedNodes);
}

export function getVisibleNodes(nodeMap, shouldNodeBeVisibleFunc) {
  return Set().withMutations((visibleNodes) => {
    // use for..in for performance
    for (const id in nodeMap) {
      if (shouldNodeBeVisibleFunc(nodeMap[id])) {
        visibleNodes.add(id);
      }
    }
  });
}

export function expandParentNodes(rootNodes, previousExpandedNodes, nodesToShow, shallow) {
  if (nodesToShow.isEmpty()) return previousExpandedNodes;

  return previousExpandedNodes.withMutations((expandedNodes) => {
    const shouldShow = (node) => {
      if (shallow && nodesToShow.has(node.id)) {
        return true;
      }

      if (node.children) {
        let anyChildShouldShow = false;
        node.children.forEach((childNode) => {
          if (shouldShow(childNode)) {
            anyChildShouldShow = true;
          }
        });
        if (anyChildShouldShow) {
          expandedNodes.add(node.id);
          return true;
        }
      }

      return nodesToShow.has(node.id);
    };

    rootNodes.forEach(shouldShow);
  });
}

export function getGroupedSelectedNodeIds(rootNodes, selectedNodes) {
  if (selectedNodes.isEmpty()) return Set();

  const groupedSelectedNodeIds = [];

  const recursiveFn = (node) => {
    if (selectedNodes.has(node.id)) {
      groupedSelectedNodeIds.push(node.id);
      return;
    }

    if (node.children) {
      node.children.forEach(recursiveFn);
    }
  };

  rootNodes.forEach(recursiveFn);

  return Set(groupedSelectedNodeIds);
}
