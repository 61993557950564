import download from 'downloadjs';
import { getFilenameFromHeader } from 'src/core/utils/http';
import keycloak, { updateTokenPromise } from '../keycloak';

export const getConsumerPath = () => `/service/consumers/${keycloak.tokenParsed.consumer.id}`;

export const getCustomerPath = () => `/user/customers/${keycloak.tokenParsed.customer.id}`;

export const getUploadAndMatchingPath = (country: string) =>
  `/uploadsandmatching/consumers/${keycloak.tokenParsed.consumer.id}/countries/${country}`;

export const getLedgerAnalyserPath = () =>
  `/ledger-analyser/consumers/${keycloak.tokenParsed.consumer.id}`;

export const getMonitoringPath = () => `/monitoring/consumers/${keycloak.tokenParsed.consumer.id}`;

export const getXseptionPath = () => `/xseption/consumers/${keycloak.tokenParsed.consumer.id}`;

export function doFetch(
  method: string,
  uri: string,
  requestBody: any,
  headers: any,
  minValiditySeconds = 30,
): Promise<any> {
  // updateTokenPromise(30) will refresh the token if it will expire within 30 seconds,
  // because we don't want it to expire during the request.
  // For big requests that might take over 30 seconds (like uploads), use a higher `minValiditySeconds`.
  return updateTokenPromise(minValiditySeconds).then(() => {
    const options: any = {
      credentials: 'same-origin',
      method,
      headers: Object.assign(
        {
          Accept: 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
          Authorization: `Bearer ${keycloak.token}`,
        },
        headers,
      ),
    };

    if (requestBody instanceof FormData) {
      options.body = requestBody;
    } else if (requestBody) {
      options.headers['Content-Type'] = 'application/json';
      options.body = typeof requestBody === 'string' ? requestBody : JSON.stringify(requestBody);
    }

    return window.fetch(uri, options);
  });
}

export function http(
  method: string,
  uri: string,
  requestBody: any = null,
  isJsonResponse = true,
  headers = {},
  minValiditySeconds?: number,
): Promise<any> {
  const fetchPromise = doFetch(method, uri, requestBody, headers, minValiditySeconds).then(
    (response) =>
      !response || !response.ok
        ? Promise.reject(new Error(response ? response.status : `Unknown error on '${uri}'`))
        : response,
  );
  return isJsonResponse
    ? fetchPromise
        .then((response) => response.text())
        .then((responseText) => (responseText ? JSON.parse(responseText) : null))
    : fetchPromise;
}

export function get(
  uri: string,
  body?: any,
  isJsonResponse = true,
  headers = {},
  minValiditySeconds?: number,
): Promise<any> {
  return http('get', uri, body, isJsonResponse, headers, minValiditySeconds);
}

export function put(
  uri: string,
  body: any,
  isJsonResponse = true,
  headers = {},
  minValiditySeconds?: number,
): Promise<any> {
  return http('put', uri, body, isJsonResponse, headers, minValiditySeconds);
}

export function post(
  uri: string,
  body: any,
  isJsonResponse = true,
  headers = {},
  minValiditySeconds?: number,
): Promise<any> {
  return http('post', uri, body, isJsonResponse, headers, minValiditySeconds);
}

export function del(
  uri: string,
  isJsonResponse = true,
  headers = {},
  minValiditySeconds?: number,
): Promise<any> {
  return http('delete', uri, null, isJsonResponse, headers, minValiditySeconds);
}

function getDefaultName(type: string | undefined) {
  switch (type) {
    case 'application/pdf':
      return 'untitled.pdf';

    case 'application/zip':
      return 'untitled.zip';

    case 'text/csv':
      return 'untitled.csv';

    default:
      return 'untitled';
  }
}

function getNameFromHeader(res: any) {
  try {
    const ContentDisposition = res.headers.get('content-disposition');

    if (!ContentDisposition) {
      return null;
    }

    return getFilenameFromHeader(ContentDisposition);
  } catch {
    return null;
  }
}

const getFileName = (res: any, type?: string) => getNameFromHeader(res) || getDefaultName(type);

const downloadWithProgress = (progress: any, getName: any) => {
  progress({
    size: 0,
    total: Infinity,
    done: false,
  });
  return (response: any) => {
    const reader = response.body.getReader();
    const total = response.headers.get('content-length');
    let size = 0;
    const buffer: any[] = [];

    function read() {
      return reader.read().then(({ value, done }: { value: any; done: any }) => {
        if (done) {
          progress({
            size: total || size,
            total,
            done: true,
          });
          return {
            blob: new Blob(buffer),
            name: getName(response),
          };
        }

        buffer.push(value);
        size += value.length;
        progress({
          size,
          total,
          done: false,
        });
        return read();
      });
    }

    return read();
  };
};

export type Progress = {
  size: number;
  total: number;
  done: boolean;
};

export function downloadFile(
  url: string,
  type = 'application/pdf',
  getName: (res: any, type?: string) => string = getFileName,
  progress?: (info: Progress) => void,
) {
  function normalDownload(res: any) {
    return res.blob().then((blob: any) => ({
      blob,
      name: getName(res, type),
    }));
  }

  const parser = progress
    ? downloadWithProgress(progress, (res: any) => getFileName(res, type))
    : normalDownload;
  return http('get', url, null, false, {
    Accept: type,
  })
    .then(parser)
    .then(({ blob, name }) => {
      if (!download(blob, name, type)) {
        return Promise.reject(Error('invalid file'));
      }

      return true;
    });
}
