// @ts-ignore
// TODO: remove this file after merging with src/core/components/Popup/index.tsx
import React, { Component, ReactNode } from 'react';
import styled from 'styled-components';
import CloseIcon from '@material-ui/icons/Clear';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import * as colors from '@graydon/ui-colors';
import errorIcon from '../../media/icons/something-went-wrong.svg';
import successIcon from '../../media/icons/ok.svg';
import { Heading3 } from '../Heading';

export const Title = styled.div`
  width: 80%;
  font-size: 22px;
  color: ${colors.greenLight};
  font-weight: 500;
  margin-bottom: 24px;
`;

export const Body = styled.div`
  color: ${colors.gray};
`;

export const PopupContent = styled.div`
  text-align: center;
`;

export const PopupHeading = styled(Heading3)`
  margin-bottom: 7px;
`;

export const SuccessIconContainer = styled.div`
  display: inline-block;
  background: url(${successIcon}) no-repeat;
  background-size: 100%;
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
`;

export const ErrorIconContainer = styled.div`
  display: inline-block;
  background: url(${errorIcon}) no-repeat;
  background-size: 112%;
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
`;

export const PopupButtonsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: baseline;
  margin-top: 8px;
  & > * {
    margin: 8px;
  }
`;

const Dark = styled.div<{
  background?: string;
}>`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background-color: ${({ background = 'rgba(0, 0, 0, 0.5)' }) => background};
  text-align: center;
`;

const WrapperContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1024px;
  height: 100%;
`;

const Wrapper = styled.div<{
  minHeight?: number;
  padding?: string;
  width?: string;
  overFlowY?: string;
}>`
  background-color: white;
  position: relative;
  margin: 10px;
  padding: ${({ padding }) => padding || '32px'};
  overflow-y: ${({ overFlowY = 'auto' }) => overFlowY};
  overscroll-behavior: contain;
  -webkit-overflow-scrolling: touch;
  text-align: left;
  border-radius: 3px;
  width: ${({ width }) => width || '450px'};
  ${({ minHeight }) => minHeight && `min-height: ${minHeight}px;`};

  @media (max-width: 768px) {
    margin: 0;
    border-radius: 0;
    width: 100vw;
    height: 100vh;
  }
`;

export const CloseButton = styled(CloseIcon)`
  color: #777777;
  position: absolute;
  font-size: 27px !important;
  top: 27px;
  right: 27px;
`;

type PopupProps = {
  onClose?: (reason: string) => void;
  minHeight?: number;
  displayCloseButton?: boolean;
  padding?: string;
  width?: string;
  overFlowY?: string;
  background?: string;
  title?: string;
  labelledBy?: string;
  children: ReactNode;
};

class Popup extends Component<PopupProps> {
  wrapperRef: any;
  constructor(props: PopupProps) {
    super(props);
    this.onKeydown = this.onKeydown.bind(this);
    this.onClick = this.onClick.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.onKeydown);
    document.addEventListener('click', this.onClick, {
      capture: true,
    });
    disableBodyScroll(this.wrapperRef); // also works on iOS Safari!
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeydown);
    document.removeEventListener('click', this.onClick, {
      capture: true,
    });
    enableBodyScroll(this.wrapperRef);
  }

  onKeydown(e: any) {
    const escapeKeyCode = 27;

    if (e.keyCode === escapeKeyCode) {
      this.props.onClose('escapeKey');
    }
  }

  onClick(e: any) {
    const clickedInsideWrapper = this.wrapperRef && this.wrapperRef.contains(e.target);

    if (!clickedInsideWrapper) {
      this.props.onClose('clickOutside');
    }
  }

  render() {
    const {
      children,
      onClose,
      minHeight,
      displayCloseButton = true,
      padding = '30px 40px',
      width,
      overFlowY,
      background,
      title,
      labelledBy,
    } = this.props;
    return (
      <Dark background={background}>
        <WrapperContainer>
          <Wrapper
            // @ts-ignore
            innerRef={(el) => {
              this.wrapperRef = el;
            }}
            minHeight={minHeight}
            padding={padding}
            width={width}
            overFlowY={overFlowY}
            role="dialog"
            aria-modal="true"
            aria-labelledby={labelledBy}
          >
            {displayCloseButton && (
              <CloseButton fontSize="large" onClick={() => onClose('clickCross')} />
            )}
            {title && <Title>{title}</Title>}
            <div data-testid="popup-content-wrapper">{children}</div>
          </Wrapper>
        </WrapperContainer>
      </Dark>
    );
  }
}

export default Popup;
