import styled from 'styled-components';
import breakpoints from '../../constants/breakpoints';

const UserGreeting = styled.span`
  text-decoration: underline;

  @media screen and (max-width: ${breakpoints['portrait-min']}px) {
    display: none;
  }
`;

export default UserGreeting;
