// @ts-nocheck
import get from 'lodash/get';
import { parseDate } from '../common';
export const byDateComparator = (a: any, b: any) => {
  const dateA = get(a, 'judgmentDate', null);
  const dateB = get(b, 'judgmentDate', null);

  if (dateA < dateB) {
    return 1;
  }

  if (dateA > dateB) {
    return -1;
  }

  return 0;
};
export const extractCcjDetailsData = (data: any) => {
  const items = get(data, 'paymentBehaviourProfile.courtJudgmentDetails', []);
  const availableItems = items
    .map(
      ({
        amount,
        caseNumber,
        courtName,
        dateSatisfied,
        judgmentDate,
        typeCode: { description } = {},
      }: any) => ({
        amount,
        caseNumber,
        courtName,
        dateSatisfied: parseDate(dateSatisfied),
        judgmentDate: parseDate(judgmentDate),
        type: description,
      }),
    )
    .sort(byDateComparator)
    .slice(0, 20);
  return {
    totalCount: items.length,
    availableItems,
  };
};
