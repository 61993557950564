import React from 'react';
import styled from 'styled-components';
import { RadioButton as Radio } from 'src/core/components/AnimatedToggles';
import * as colors from '@graydon/ui-colors';
import Button from '../../../../../../components/ButtonCR';
import arrowRight from '../../../../../../media/icons/arrow-right-fff.svg';
import iconCreate from '../../../../../../media/icons/baseline-create-24px.svg';

export const Label = styled.label<{
  isRequired?: boolean;
}>`
  font-size: 16px;
  font-weight: 500;
  color: ${colors.gray} !important;
  margin-bottom: 8px;
  overflow-wrap: break-word;

  ${({ isRequired }) =>
    isRequired &&
    `
    &::after {
      content: "*";
      padding-left: 5px;
    };
  `}
`;

export const FlexContainer = styled.div<{
  justify?: string;
  align?: string;
  column?: boolean;
  changeDirectionOnMobile?: boolean;
}>`
  display: flex;
  width: 100%;
  justify-content: space-between;
  ${({ justify }) => `justify-content: ${justify || 'space-between'}`}
  ${({ align }) => `align-items: ${align || 'center'}`}
  ${({ column }) => column && 'flex-direction: column;'}

  ${({ changeDirectionOnMobile }) =>
    changeDirectionOnMobile && `@media(max-width: 768px) { flex-direction: column; }`}
`;

export const Container = styled.div<{
  marginBottom?: number;
}>`
  width: 100%;
  margin-bottom: ${({ marginBottom }) => (marginBottom ? `${marginBottom}px` : '16px')};

  @media (max-width: 768px) {
    margin-bottom: 8px;
  }
`;

export const Text = styled.div<{
  marginBottom?: number;
}>`
  color: ${colors.gray};
  ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom};`}
  display: block;
`;

export const Title = styled.div`
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  color: ${colors.blueLight3};
`;

export const ModularWrapper = styled.div<{
  width?: string;
  flex?: number;
  display?: string;
  marginTop?: string;
  marginBottom?: string;
  marginRight?: string;
  marginLeft?: string;
}>`
  ${({ width }) => width && `width: ${width};`}
  ${({ flex }) => flex && `flex: ${flex};`}
  ${({ display }) => display && `display: ${display};`}
  ${({ marginTop }) => marginTop && `margin-top: ${marginTop};`}
  ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom};`}
  ${({ marginRight }) => marginRight && `margin-right: ${marginRight};`}
  ${({ marginLeft }) => marginLeft && `margin-left: ${marginLeft};`}

  @media(max-width: 768px) {
    width: 100%;
    ${({ marginLeft }) => marginLeft && `margin-left : 0`}
    ${({ marginBottom }) => marginBottom && `margin-bottom: 8px;`}
  }
`;

export const PageContainer = styled.div`
  margin: 0;
  padding: 0 0 32px 0;
  background-color: ${colors.grayLight5};
  height: auto;
`;

export const FormWrapper = styled.div`
  max-width: 800px;

  @media (max-width: 768px) {
    margin: 0;
  }
`;

export const FormHeaderSection = styled.div`
  @media (max-width: 768px) {
    padding: 0 16px;
  }
`;

export const FormContainer = styled.form`
  background-color: #fff;
  max-width: 800px;
  padding: 16px;
  margin-top: 16px;
  border-radius: 3px;
`;

export const SectionHeader = styled.h1<{
  marginBottom?: string;
}>`
  color: #1b6d75;
  font-weight: 500;
  margin: 0;
  overflow-wrap: break-word;

  ${({ marginBottom }) => marginBottom && `margin-bottom: ${marginBottom}`}
`;

export const HorizontalDivider = styled.div`
  height: 0px;
  border-bottom: solid 1px #babfc1;
  margin-top: 16px;
`;

export const ButtonContainer = styled.div<{
  justifyContentSpaceBetween?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: ${({ justifyContentSpaceBetween }) =>
    justifyContentSpaceBetween ? 'space-between' : 'flex-end'};
`;

export const NextStepButton = styled(Button)`
  width: auto;
  display: flex;
  align-items: center;
`;

export const ArrowRight = styled.div`
  background-image: url(${arrowRight});
  background-repeat: no-repeat;
  background-position: center;
  height: 20px;
  width: 20px;
  margin-right: -5px;
  margin-left: 5px;
`;

export const IconCreate = styled.div`
  background-image: url(${iconCreate});
  background-repeat: no-repeat;
  background-position: center;
  height: 20px;
  width: 20px;
  background-size: 20px 20px;
  cursor: pointer;
`;

export const LinkWrapper = styled.div`
  margin: 10px;
  padding-top: 29px;
  @media (max-width: 768px) {
    padding-top: 0;
    margin-top: 0;
    margin-left: 0;
    min-width: 100%;
  }
`;

export const SpinWrapper = styled.div`
  margin: 10px;
  padding-top: 38px;
  @media (max-width: 768px) {
    padding-top: 0;
    width: 100%;
  }
`;

export const EmailFieldWrapper = styled.div`
  max-width: 440px;
  flex: 1;
  @media (max-width: 768px) {
    max-width: 100%;
    width: 100%;
  }
`;

export const ClientIdentificationResult = styled.div`
  display: flex;
  max-width: 440px;
  color: white;
  background: ${colors.greenLight};
  padding: 10px;
  border-radius: 4px;
  h4 {
    margin: 0;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const PageMarginLayout = styled.div`
  max-width: 1400px;
  padding-left: 16px;
  margin: 0 auto;
  @media (max-width: 768px) {
    padding-left: 0;
  }
`;

export const GreyPageWrapper = styled.div`
  flex: 1;
  background: ${colors.grayLight5};
`;

export const Wrapper = styled.div`
  background: ${colors.white};
  padding: 32px;
  border-radius: 4px;
  @media (max-width: 768px) {
    padding: 32px 16px 24px 16px;
    border-radius: 0;
  }
  margin-bottom: 48px;
`;

export const RadioButtonList = styled.div`
  display: flex;
  padding-bottom: 20px;
  justify-content: flex-start;
  > label:last-child {
    margin-left: 40px;
  }
`;

export const ConsumerUserWrapper = styled.li`
  display: flex;
  align-items: center;
  padding: 15px;
  color: ${colors.gray};

  div {
    :first-child {
      width: 20%;
    }

    :nth-child(2) {
      width: 20%;
    }

    :nth-child(3) {
      width: 45%;
    }

    :nth-child(4) {
      width: 15%;
      white-space: nowrap;
    }
  }
`;
const RLabel = styled.span`
  color: ${colors.gray} !important;
`;

export const RadioButton = ({ label, ...props }: { label: string }) => (
  <label>
    <Radio {...props} />

    <RLabel className="label">{label}</RLabel>
  </label>
);
