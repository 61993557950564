// @ts-nocheck
import { handleAction } from 'redux-actions';
import { SET_ORGANISATION_HEADER_DATA } from './constants';
export const initialState = {
  companyAddress: '',
  companyName: '',
  isCompanyActive: true,
};
export default handleAction(
  SET_ORGANISATION_HEADER_DATA,
  (state, { payload }) => ({ ...state, ...payload }),
  initialState,
);
