import React from 'react';
import styled from 'styled-components';

const svg =
  '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 193.916 106.523"><path fill="#92d2e1" d="M96.958 14.344c-21.493 0-38.917 17.424-38.917 38.917s17.43 38.92 38.92 38.92 38.92-17.42 38.92-38.91-17.422-38.92-38.92-38.92zm2.138 64.38c-.67.71-1.653 1.068-2.946 1.068-1.294 0-2.27-.357-2.928-1.07-.66-.713-.988-1.614-.988-2.702s.33-2 .988-2.738c.66-.737 1.634-1.106 2.928-1.106 1.293 0 2.275.37 2.946 1.106.67.738 1.006 1.65 1.006 2.738s-.336 1.99-1.006 2.703zm6.61-26.35l-3.916 3.332c-1.747 1.938-2.623 3.817-2.623 7.817H92.52c.048-3 .372-4.553.97-6.203.6-1.65 1.82-3.207 3.665-5.217l4.706-4.708c2.02-2.27 3.02-4.64 3.02-7.246 0-2.51-.65-4.45-1.97-5.87-1.32-1.42-3.23-2.12-5.75-2.12-2.44 0-4.403-.253-5.89 1.04-1.484 1.294-2.226 2.312-2.226 4.312h-6.65c.048-3 1.43-6.106 4.15-8.49 2.718-2.38 6.258-3.12 10.616-3.12 4.527 0 8.053 1.44 10.58 3.867 2.527 2.43 3.79 5.872 3.79 10.104 0 4.186-1.938 8.424-5.82 12.49z"/></svg>';

const Wrapper = styled.div`
  display: block;
  max-width: 70%;
  margin: 0 auto;
`;

export default () => <Wrapper dangerouslySetInnerHTML={{ __html: svg }} />;
