import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import { I18nProvider } from 'src/i18n';
import populateAnalyticsData from 'src/core/utils/analytics';
import injectGTM from 'src/core/utils/inject-gtm';
import { loadAppcues } from 'src/core/utils/appcues';
import './media/fonts/fonts.css';
import { unregister } from './utils/registerServiceWorker';
import keycloak, { initAuth } from './keycloak';
import AppWrapper from './AppWrapper';
import LoadingPage from './components/LoadingPage';
import AuthError from './components/ErrorPage/AuthError';
import './globalStyles';

const container = document.getElementById('root');
if (!container) {
  throw new Error('Root element not found');
}
const root = createRoot(container);
root.render(<LoadingPage />);

const initAuthPromise = initAuth();
const settingsPromise = fetch('/resource/settings.json').then((res) => res.json());
// create react app replaces process.env.PUBLIC_URL with the value in build time
// but for some reasons, dose it only for main chunk so I have to assign it to window["PUBLIC_URL"]
window['PUBLIC_URL'] = process.env.NODE_ENV === 'development' ? '/apps' : process.env.PUBLIC_URL;
Promise.all([initAuthPromise, settingsPromise]).then(
  ([, settings]) => {
    root.render(
      <Router basename={window['PUBLIC_URL']}>
        <CookiesProvider>
          {/* @ts-ignore */}
          <AppWrapper settings={settings} />
        </CookiesProvider>
      </Router>,
    );
    loadAppcues(keycloak.tokenParsed, '23078');
    populateAnalyticsData(keycloak, window, settings.environment.id);
    injectGTM(settings.statistics.gtm_id);
  },
  (err) => {
    root.render(
      <I18nProvider locale="en-GB">
        <AuthError error={err} />
      </I18nProvider>,
    );
    console.error(err);
  },
);
// disabling service worker for now, because it causes problems with legacy apps having overlapping urls
unregister();
