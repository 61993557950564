import React, { Component } from 'react';
import {
  TabsWrapper,
  DesktopTabs,
  DesktopTabButton,
  MobileTabs,
  MobileTabButton,
  ExpandArrow,
  MobileTabsContainer,
  TabContent,
} from './helper-components';

type TabsProps = {
  tabs: {
    name: string;
    text: string;
    renderContent: () => React.ReactNode;
    onClick?: () => void;
  }[];
  selected?: string;
  tabButtonWidth?: string;
  width?: string;
  tabButtonMinWidth?: string;
  onClick?: () => void;
  borderColor?: string;
};

type State = {
  activeTab: string;
  isExpanded: boolean;
};

class Tabs extends Component<TabsProps, State> {
  constructor(props) {
    super(props);

    const { tabs, selected } = this.props;
    if (tabs.length === 0) {
      throw new Error('[Tabs component]: At least one tab should be provided in props.');
    }

    this.state = {
      activeTab: selected || tabs[0].name,
      isExpanded: false,
    };

    this.onTabClick = this.onTabClick.bind(this);
    this.onToggleMenu = this.onToggleMenu.bind(this);
  }

  onTabClick(name) {
    this.setState(() => ({ activeTab: name }));
    const { onClick } = this.props;
    if (onClick) onClick();
  }

  onToggleMenu() {
    this.setState(({ isExpanded }) => ({ isExpanded: !isExpanded }));
  }

  render() {
    const { tabs, tabButtonWidth, width = '100%', tabButtonMinWidth, borderColor } = this.props;
    const { activeTab, isExpanded } = this.state;
    const tab = tabs.find((x) => x.name === activeTab);

    return (
      <TabsWrapper width={width}>
        <DesktopTabs>
          {tabs.map(({ name, text, onClick }) => (
            <DesktopTabButton
              key={name}
              onClick={() => {
                this.onTabClick(name);
                if (onClick) onClick();
              }}
              selected={name === activeTab}
              width={tabButtonWidth}
              minWidth={tabButtonMinWidth}
              borderColor={borderColor}
            >
              {text}
            </DesktopTabButton>
          ))}
        </DesktopTabs>
        <MobileTabs onClick={this.onToggleMenu}>
          <MobileTabButton key={activeTab} expanded={isExpanded} selected>
            {tab.text}
          </MobileTabButton>
          <ExpandArrow expanded={isExpanded} />
          <MobileTabsContainer>
            {isExpanded &&
              tabs.map(({ name, text }) => (
                <MobileTabButton key={name} onClick={() => this.onTabClick(name)}>
                  {text}
                </MobileTabButton>
              ))}
          </MobileTabsContainer>
        </MobileTabs>
        {tab && tab.renderContent && (
          <TabContent borderColor={borderColor}>{tab.renderContent()}</TabContent>
        )}
      </TabsWrapper>
    );
  }
}

export default Tabs;
