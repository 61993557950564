import React from 'react';
import styled from 'styled-components';
import { Checkbox, darkBgStyling } from '../AnimatedToggles';

const LabelContainer = styled.div`
  padding: 4px;
  display: flex;
  flex-direction: row;
  font-size: 14px;
`;

const Label = styled.label`
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  input {
    margin-right: 5px;
  }
`;

const CollapseButtonContainer = styled.div`
  width: 14px;
  display: inline-block;
  text-align: center;
  margin-right: 5px;
  flex-shrink: 0;
`;

const CollapseButton = styled.span<{
  expanded: boolean;
}>`
  display: inline-block;
  cursor: pointer;
  transition: all 0.1s ease-out;
  user-select: none;
  vertical-align: middle;

  ${({ expanded }) => expanded && 'transform: rotate(90deg);'};
`;

// use this instead of unicode, because unicode characters look different per browser
const ArrowRight = styled.div`
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-left: 8px solid white;
  border-bottom: 6px solid transparent;
`;

const ChildWrapper = styled.div`
  margin-left: 20px;
`;

const LineContainer = styled.div<{
  collapsible: boolean;
}>`
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: -9px;
    border-left: 1px solid #90d1e0;
  }

  &:last-child::before {
    bottom: auto;
    height: 15px;
  }

  .labelContainer {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 14px;
      left: -9px;
      width: ${({ collapsible }) => (collapsible ? 11 : 22)}px;
      border-bottom: 1px solid #90d1e0;
    }
  }
`;

export type NodeShape = {
  id: string;
  children: NodeShape[];
};

type TreeNodeViewProps = {
  node: NodeShape;
  depth: number;
  selectedNodes: any; //set;
  expandedNodes: any; //set;
  onClickNode: (node: NodeShape) => void;
  onClickCollapse: (node: NodeShape) => void;
  nodeRenderFunc: (node: NodeShape) => React.ReactNode;
};

const TreeNodeView = (props: TreeNodeViewProps) => {
  const {
    node,
    depth,
    selectedNodes,
    expandedNodes,
    onClickNode,
    onClickCollapse,
    nodeRenderFunc,
  } = props;
  return (
    <div className={`TreeNode depth-${depth}`}>
      <LabelContainer className="labelContainer">
        <CollapseButtonContainer>
          {node.children && node.children.length > 0 && (
            <CollapseButton
              onClick={() => onClickCollapse(node)}
              expanded={expandedNodes.has(node.id)}
            >
              <ArrowRight />
            </CollapseButton>
          )}
        </CollapseButtonContainer>
        <Label>
          <Checkbox
            readOnly
            checked={selectedNodes.has(node.id)}
            onChange={() => onClickNode(node)}
            {...darkBgStyling}
          />
          <span className="label">{nodeRenderFunc(node)}</span>
        </Label>
      </LabelContainer>
      {node.children && node.children.length > 0 && expandedNodes.has(node.id) && (
        <ChildWrapper>
          {node.children.map((childNode) => (
            <LineContainer
              key={childNode.id}
              collapsible={childNode.children && childNode.children.length > 0}
            >
              <TreeNodeView {...props} node={childNode} depth={depth + 1} />
            </LineContainer>
          ))}
        </ChildWrapper>
      )}
    </div>
  );
};

export default TreeNodeView;
