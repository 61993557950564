import React from 'react';
import { ReactNode } from 'react';
import { I18nProp, injectI18n } from 'src/i18n';
import ErrorPage, { ErrorHeading } from '../ErrorPage';

type Props = {
  children: ReactNode;
};
type State = {
  hasError: boolean;
};

class ErrorBoundary extends React.Component<Props & I18nProp, State> {
  state = {
    hasError: false,
  };

  componentDidCatch() {
    this.setState({
      hasError: true,
    });
  }

  render() {
    const { i18n } = this.props;

    if (this.state.hasError) {
      return (
        <ErrorPage>
          <ErrorHeading>{i18n.text('error.something-wrong')}</ErrorHeading>
        </ErrorPage>
      );
    }

    return this.props.children;
  }
}

export default injectI18n(ErrorBoundary);
