import styled from 'styled-components';
export const Wrapper = styled.div`
  font-size: 1rem;
  width: 100%;
  padding-top: 1.2em;
`;
export const ErrorWrapper = styled(Wrapper)`
  display: flex;
  justify-content: center;
`;
