import { blueLight3, gray, grayLight5 } from '@graydon/ui-colors';
import styled, { css } from 'styled-components';

export const NoPrint = css`
  @media print {
    display: none !important;
  }
`;

export const NoPrintBg = css`
  @media print {
    background-color: unset !important;
  }
`;

export const PageWrapper = styled.div`
  width: 100%;
  flex: 1;
  background-color: ${grayLight5};
  display: flex;
  flex-direction: column;
  align-items: center;

  ${NoPrintBg};
`;

export const PageContent = styled.div`
  width: 100%;
  max-width: 1400px;
  padding: 8px;

  @media (max-width: 768px) {
    width: 100%;
    padding: 0;
  }
`;

export const InnerPageWrapper = styled.div`
  width: 100%;
  max-width: 1400px;
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  margin: 0 auto;

  @media (max-width: 768px) {
    padding: 0;
  }
`;

export const BreadcrumbWrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 1400px;
  padding: 0 16px;

  ${NoPrint};
`;

export const PageTitle = styled.h1`
  font-size: 32px;
  font-weight: 500;
  color: ${blueLight3};
  margin: 0;
  @media (max-width: 768px) {
    font-size: 26px;
  }
`;

export const PageSubTitle = styled.div`
  margin: 4px 0 16px 0;
  color: ${gray};
  font-size: 16px;
`;

export const Header = styled.header`
  display: flex;
  padding: 0 15px;
  width: 100%;
  max-width: 1400px;
  @media (max-width: 768px) {
    flex-direction: column;
  }

  @media print {
    padding: 0;
  }
`;

export const Flex = styled.div<{
  flex?: number;
}>`
  flex: ${({ flex = 1 }) => flex};
`;

export const CenteredItemsFlex = styled.div`
  display: flex;
  align-items: center;
`;
