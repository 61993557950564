import styled from 'styled-components';
import * as colors from '@graydon/ui-colors';
import { I18n } from 'src/i18n';

export const getCompanyStatusFlagColor = (status: boolean | null | undefined) => {
  if (status == null) {
    return 'disabled';
  }

  return status === true ? 'success' : 'calamityRed';
};

export const getCompanyStatus = (status: boolean | null | undefined, i18n: I18n) => {
  let label = 'cr.company.inactive';

  if (status == null) {
    label = 'cr.company.unavailable';
  }

  if (status === true) {
    label = 'cr.company.active';
  }

  return i18n.text(label);
};

const CompanyStatusFlag = styled.span<{
  size?: string;
  color?: string;
  small?: boolean;
}>`
  font-size: ${({ size = '10' }) => size}px;
  border-radius: 50%;
  width: 1em;
  min-width: 1em;
  height: 1em;
  display: inline-block;
  margin: 0 0.5em 0 0;
  ${({ small }) => (small ? 'margin-right: 0.25em;' : '')}
  background-color: ${({ color }) => (color === 'disabled' ? 'white' : colors[color])};
  ${({ color }) => color === 'disabled' && `border: 1px solid ${colors[color]}`};
`;

export default CompanyStatusFlag;
