import * as colors from '@graydon/ui-colors';
import styled from 'styled-components';
import { sans } from '../../constants/fonts';

const Input = styled.input<{
  fontSize?: string;
  width?: string;
  highPadding?: boolean;
  light?: boolean;
  borderColor?: string;
}>`
  margin: 0;
  ${({ width }) => (width ? `width: ${width};` : '')}
  padding: ${({ highPadding = false }) => (highPadding ? '0.8em' : '0.6em')} 0.9em;
  font-size: ${({ fontSize = '16px' }) => fontSize};
  font-family: ${sans};
  font-weight: 300;
  border: ${({ light = false, borderColor = '#e3e3e3' }) =>
    light ? 'none' : `1px solid ${borderColor}`};
  border-radius: 4px;
  background: #fff;
  color: ${colors.gray};
`;

export default Input;
