import React, { ReactNode, useEffect, useRef } from 'react';
import styled from 'styled-components';

type WrapperProps = {
  minHeight?: number;
  minWidth?: number;
  padding?: string;
  width?: string;
  overFlowY?: string;
  background?: string;
  title?: string;
  labelledBy?: string;
};

type PopupProps = WrapperProps & {
  children: ReactNode;
  onClose?: () => void;
  displayCloseButton?: boolean;
};

const Dark = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.5);
  text-align: center;
`;

const WrapperContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1024px;
  height: 100%;
`;

const Wrapper = styled.div<WrapperProps>`
  position: relative;
  margin: 10px;
  padding: ${({ padding }) => padding || '32px'};
  background-color: #fff;
  overflow-y: ${({ overFlowY = 'auto' }) => overFlowY};
  overscroll-behavior: contain;
  -webkit-overflow-scrolling: touch;
  border-radius: 3px;
  width: ${({ width }) => width};
  text-align: left;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  ${({ minWidth }) => minWidth && `min-width: ${minWidth}px;`}
  ${({ minHeight }) => minHeight && `min-height: ${minHeight}px;`};
`;

const StyledCloseButton = styled.button`
  position: absolute;
  top: 15px;
  right: 15px;
  display: inline-block;
  background: none;
  border: none;
  color: #777;
  margin: 0;
  padding: 0 8px;
  outline: none;
  font-size: 40px;
  line-height: 1;
  cursor: pointer;
`;

export const CloseButton = ({ onClick }) => (
  <StyledCloseButton type="button" onClick={onClick}>
    ×
  </StyledCloseButton>
);

const handleClosePopup = (callback) => {
  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    };
    const handleKeydown = (e) => {
      const escapeKeyCode = 27;
      if (e.keyCode === escapeKeyCode) {
        callback();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keydown', handleKeydown);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.addEventListener('keydown', handleKeydown);
    };
  }, [callback]);

  return ref;
};

function Popup({
  children,
  onClose,
  minHeight,
  minWidth,
  displayCloseButton = true,
  width,
  overFlowY,
  labelledBy,
  padding,
}: PopupProps) {
  const wrapperRef = onClose && handleClosePopup(() => onClose());

  return (
    <Dark>
      <WrapperContainer>
        <Wrapper
          innerRef={wrapperRef}
          minHeight={minHeight}
          padding={padding}
          width={width}
          minWidth={minWidth}
          overFlowY={overFlowY}
          role="dialog"
          aria-modal="true"
          aria-labelledby={labelledBy}
        >
          {displayCloseButton && <CloseButton onClick={onClose} />}
          <div>{children}</div>
        </Wrapper>
      </WrapperContainer>
    </Dark>
  );
}

export default Popup;
