import get from 'lodash/get';
import { CountryCode } from 'src/types/locales';
import { getCuratorAddress, parseDate, parsePhoneNumber } from '../common';

export const getDetailsOrForms = (countryCode: CountryCode, financialCalamitiy: any) => {
  const descriptions = get(financialCalamitiy, 'detailTypeCode.descriptions', null);

  if (countryCode === 'GB') {
    const date = get(financialCalamitiy, 'formRegisteredDate', null);
    return {
      date: parseDate(date),
      descriptions,
    };
  }

  if (countryCode === 'BE') {
    return {
      descriptions,
    };
  }

  return null;
};

export const byDateComparator = (a: any, b: any) => {
  const startDateA = a.startDate;
  const startDateB = b.startDate;

  if (!a.endDate && !b.endDate) {
    if (startDateA < startDateB) {
      return 1;
    }

    if (startDateA > startDateB) {
      return -1;
    }

    return 0;
  }

  if (!a.endDate) return -1;
  if (!b.endDate) return 1;

  if (startDateA < startDateB) {
    return 1;
  }

  if (startDateA > startDateB) {
    return -1;
  }

  return 0;
};

export const extractInsolvencies = (countryCode: CountryCode, financialCalamities: any) =>
  financialCalamities
    .map((financialCalamity: any, i: any) => {
      const startDate = get(financialCalamity, 'startDate', null);
      const endDate = get(financialCalamity, 'endDate', null);
      const descriptions = get(financialCalamity, 'typeCode.descriptions', {});
      const detailsOrForms = getDetailsOrForms(countryCode, financialCalamity);
      const endReason = get(financialCalamity, 'endReasonCode.descriptions', null);
      const preliminary = get(financialCalamity, 'preliminaryFlag', false);
      const preliminaryPresent = get(financialCalamity, 'preliminaryFlag', '-1');
      const curators = get(financialCalamity, 'curators', []);
      const eventDetails = get(financialCalamity, 'detailTypeCode.descriptions');
      const eventDate = get(financialCalamity, 'formRegisteredDate');
      const mappedCurators = curators.map((curator: any) => {
        const mappedCurator = {
          name: get(curator, 'name', null),
          role: get(curator, 'curatorRoleCode.descriptions', {}),
          address: getCuratorAddress(curator),
        };
        return countryCode === 'NL'
          ? { ...mappedCurator, phoneNumber: parsePhoneNumber(get(curator, 'phoneNumber', {})) }
          : mappedCurator;
      });
      return {
        key: i,
        startDate: parseDate(startDate),
        endDate: parseDate(endDate),
        detailsOrForms,
        endReason,
        preliminary,
        preliminaryPresent,
        descriptions,
        curators: mappedCurators,
        eventDetails,
        eventDate: parseDate(eventDate),
      };
    })
    .sort(byDateComparator);

export const extractFinancialInsolvenciesData = (data: any) => {
  const countryCode = get(data, 'companyIdentification.countryCode', null);
  const financialCalamities = get(data, 'companyProfile.financialCalamities', []);
  return extractInsolvencies(countryCode, financialCalamities);
};
