import { extractAnnualAccountsData } from './extractAnnualAccountsData';
import { extractBranchesData } from './extractBranchesData';
import { extractCcjDetailsData } from './extractCcjDetailsData';
import { extractCcjSummariesData } from './extractCcjSummariesData';
import { extractCompanyBoxData } from './extractCompanyBoxData';
import { extractCompanyContactsData } from './extractCompanyContactsData';
import { extractCompanyDetailsData } from './extractCompanyDetailsData';
import { extractCompanyHeaderData } from './extractCompanyHeaderData';
import { extractCreditRatingData } from './extractCreditRatingData';
import { extractDirectorsData } from './extractDirectorsData';
import { extractDiscontinuationScoreData } from './extractDiscontinuationScoreData';
import { extractEventsData } from './extractEventsData';
import { extractFinancialInsolvenciesData } from './extractFinancialInsolvenciesData';
import { extractFlagAndLimitData } from './extractFlagAndLimitData';
import { extractGeneralInformationData } from './extractGeneralInformationData';
import { extractGroupStructureData } from './extractGroupStructureData';
import { extractPscData } from './extractPscData';
import { extractGrowthScoreData } from './extractGrowthScoreData';
import { extractKeyFiguresData } from './extractKeyFiguresData';
import { extractLiabilitiesData } from './extractLiabilitiesData';
import { extractMortgagesData } from './extractMortgagesData';
import { extractUnsecuredCreditorsData } from './extractUnsecuredCreditorsData';
import { extractUnsecuredClaimsData } from './extractUnsecuredClaimsData';
import { extractOthersData } from './extractOthersData';
import { extractPaymentAveragesData } from './extractPaymentAveragesData';
import { extractPaymentScoreData } from './extractPaymentScoreData';
import { extractPaymentTermsData } from './extractPaymentTermsData';
import { extractPaymentTrendData } from './extractPaymentTrendData';
import { extractRatingAndPercentageData } from './extractRatingAndPercentageData';
import { extractShareholdersData } from './extractShareholdersData';
import { extractSummonsAndJudgmentsData } from './extractSummonsAndJudgmentsData';
import { extractXSeptionsData } from './extractXSeptionsData';
import { extractRatingTrendData } from './extractRatingTrendData';
import { extractCreditLimitTrendData } from './extractCreditLimitTrendData';
import { extractPaymentScoreTrend } from './extractPaymentScoreTrend';
import { extractDelphiIndexData } from './extractDelphiIndexData';
import { extractGdiTranslations } from './extractGdiTranslations';
export default {
  extractAnnualAccountsData,
  extractBranchesData,
  extractCcjDetailsData,
  extractCcjSummariesData,
  extractCompanyBoxData,
  extractCompanyContactsData,
  extractCompanyDetailsData,
  extractCompanyHeaderData,
  extractCreditRatingData,
  extractDirectorsData,
  extractDiscontinuationScoreData,
  extractEventsData,
  extractFinancialInsolvenciesData,
  extractFlagAndLimitData,
  extractGdiTranslations,
  extractGeneralInformationData,
  extractGroupStructureData,
  extractPscData,
  extractGrowthScoreData,
  extractKeyFiguresData,
  extractLiabilitiesData,
  extractMortgagesData,
  extractUnsecuredCreditorsData,
  extractUnsecuredClaimsData,
  extractOthersData,
  extractPaymentAveragesData,
  extractPaymentScoreData,
  extractPaymentTermsData,
  extractPaymentTrendData,
  extractRatingAndPercentageData,
  extractShareholdersData,
  extractSummonsAndJudgmentsData,
  extractXSeptionsData,
  extractRatingTrendData,
  extractCreditLimitTrendData,
  extractPaymentScoreTrend,
  extractDelphiIndexData,
};
