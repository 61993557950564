import React from 'react';
import { components } from 'react-select';
import styled from 'styled-components';
import colors from '../../constants/colors';

export const ListItem = styled.div<{
  disabled?: boolean;
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  box-shadow: 0px 16px 0px -15px ${colors.grayLight4};
  cursor: pointer;
  color: ${colors.gray};
  :active {
    background-color: ${colors.grayLight4};
  }

  ${({ disabled }) => disabled && `cursor: not-allowed; color: ${colors.grayLight2};`}
`;
export const OptionName = styled.div`
  font-size: 1rem;
  font-weight: 500;
`;

type CustomOptionProps = {
  data: {
    value?: string;
    label?: string;
    disabled?: boolean;
  };
};

export default function CustomOption(props: CustomOptionProps) {
  const { value, label, disabled } = props.data;
  return (
    <components.Option {...props}>
      <ListItem disabled={disabled} key={value}>
        <div>
          <OptionName>{label}</OptionName>
        </div>
      </ListItem>
    </components.Option>
  );
}
