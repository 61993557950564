import styled from 'styled-components';
import breakpoints from '../../constants/breakpoints';
import colors from '../../constants/colors';
import { sans, serif } from '../../constants/fonts';

export const PaperPageContainer = styled.div`
  width: 100%;
  min-height: 100vh;
  float: left;
  background-color: ${colors['light-grey']};
  display: flex;
  flex-direction: column;
  align-items: center;

  header {
    width: 100%;
  }

  @media (max-width: ${breakpoints['portrait-min']}px) {
    float: none;
    padding: 0 1em 1em 1em;
  }
`;

export const PaperPage = styled.div`
  font-family: ${sans};
  font-size: 16px;
  color: ${colors['dark-grey']};
  width: 90%;
  margin-top: 30px;
  position: relative;
  padding: 40px 80px 120px 80px;
  background-color: ${colors.white};
  box-shadow: 0 2px 5px ${colors['dark-grey']};

  &a,
  a:hover,
  a:active,
  a:link,
  a:visited {
    color: ${colors.secondary};
  }

  @media screen and (max-width: 1359px) {
    padding: 40px 80px;
  }

  @media screen and (max-width: 767px) {
    padding: 20px;
    margin-top: 20px;
  }
`;

export const Title = styled.h1`
  font-family: ${serif};
  font-size: 2.25rem;
  font-weight: bold;
  color: ${colors.darkblue};
  margin-bottom: 40px;
  text-align: center;
`;

export const Text = styled.div`
  text-align: center;
`;

export const Navigation = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
`;

export const ContactsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;

  @media screen and (max-width: 991px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
`;

export const ContactsDetails = styled.div`
  margin-top: 40px;
  min-width: 260px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 767px) {
    margin-top: 24px;
  }
`;

export const Subtitle = styled.h2`
  font-family: ${serif};
  font-size: 1.25rem;
  font-weight: bold;
  color: ${colors.darkblue};
  margin-bottom: 24px;

  @media screen and (max-width: 767px) {
    margin-bottom: 16px;
  }
`;

export const Email = styled.div`
  margin-bottom: 10px;
`;

export const Phone = styled.div`
  @media screen and (min-width: 768px) {
    div {
      display: block;
    }
    a {
      display: none;
    }
  }
  @media screen and (max-width: 767px) {
    div {
      display: none;
    }
    a {
      display: block;
    }
  }
`;

export default {
  PaperPageContainer,
  PaperPage,
  Title,
  Text,
  Navigation,
  ContactsWrapper,
  ContactsDetails,
  Subtitle,
  Email,
  Phone,
};
