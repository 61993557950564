import React, { Fragment, ReactNode } from 'react';
import styled from 'styled-components';
import * as colors from '@graydon/ui-colors';
const MessageTitle = styled.div`
  color: #1b6d75;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 5px;
  word-wrap: break-word;
  padding-right: 20px;
`;
const MessageBody = styled.div`
  color: #545454;
  font-size: 16px;
  line-height: 22px;

  a {
    color: ${colors.green};
  }
`;

type NotificationMessage = {
  title: string;
  body: ReactNode;
};

const NotificationMessage = ({ title, body }: NotificationMessage) => (
  <Fragment>
    <MessageTitle>{title}</MessageTitle>

    <MessageBody>{body}</MessageBody>
  </Fragment>
);

export default NotificationMessage;
