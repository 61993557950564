import React from 'react';
import { I18nProp } from 'src/i18n';
import styled from 'styled-components';

const Title = styled.h1`
  color: #fff;
  font-size: 1.5rem;
  margin-left: 8px;
  text-align: left;
  white-space: nowrap;

  @media (max-width: 768px) {
    font-size: 1.1rem;
  }
`;

type AppNameProps = {
  className: string;
  appName: string;
  appPath: string;
  isHome?: boolean;
  Link?: React.ComponentType<any>;
};

const AppName = ({ appPath, appName, i18n, isHome, className, Link }: AppNameProps & I18nProp) => {
  const isText = typeof appName === 'string';
  const title = isText ? i18n.text('branding.app', { app: appName }) : appName;
  return (
    <Link className={className} to={appPath}>
      {isText ? <Title>{title}</Title> : title}
    </Link>
  );
};

const StyledAppName = styled(AppName)`
  margin: 0 0 0 0.2em;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: inherit;
  text-decoration: inherit;

  &.app-home {
    margin: -6px 0 0 10px;
  }
`;

export default StyledAppName;
