import React, { Component } from 'react';
import styled from 'styled-components';
import isDescendant from '../../utils/isDescendant';
import cssColors from '../../constants/colors';
import { HELP_RADIUS } from './constants';
import Content from './Content';

type Direction = {
  top?: any;
  right?: any;
  bottom?: any;
  left?: any;
};

// export const topPosition = ({ top }) => (top !== null ? `${top}px` : null);
// export const rightPosition = ({ right }) => (right !== null ? `${right}px` : null);
// export const bottomPosition = ({ bottom }) => (bottom !== null ? `${bottom}px` : null);
// export const leftPosition = ({ left }) => (left !== null ? `${left}px` : null);

export const topPosition = ({ top }) => (top !== null ? `top: ${top}px;` : null);
export const rightPosition = ({ right }) => (right !== null ? `right: ${right}px;` : null);
export const bottomPosition = ({ bottom }) => (bottom !== null ? `bottom: ${bottom}px;` : null);
export const leftPosition = ({ left }) => (left !== null ? `left: ${left}px;` : null);

export const Wrapper = styled.div<Direction>`
  white-space: normal;
  position: absolute;
  ${({ top }) => topPosition(top)};
  ${({ right }) => rightPosition(right)};
  ${({ bottom }) => bottomPosition(bottom)};
  ${({ left }) => leftPosition(left)};
`;

export const Button = styled.button`
  box-sizing: border-box;
  width: ${HELP_RADIUS}px;
  height: ${HELP_RADIUS}px;
  min-height: 0;
  border: 2px solid ${({ color }) => color || cssColors.darkblue};
  border-radius: ${HELP_RADIUS}px;
  padding: 0;
  color: ${({ color }) => color || cssColors.darkblue};
  background: transparent;
  text-align: center;
  font-weight: bold;
  font-size: 12px;
  position: relative;
  outline: none;
`;

export const toggleExpanded = ({ isExpanded }) => ({ isExpanded: !isExpanded });

export const collapse = ({ isExpanded }) => ({ isExpanded: false });

type HelpBalloonProps = Direction & {
  text: string;
  color?: string;
};

type State = {
  isExpanded: boolean;
};

export default class HelpBalloon extends Component<HelpBalloonProps, State> {
  wrapper;
  constructor(props) {
    super(props);
    this.state = { isExpanded: false };
    this.onClickOutside = this.onClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('click', this.onClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.onClickOutside);
  }

  onClickOutside(e) {
    if (!isDescendant(this.wrapper, e.target)) {
      this.setState(collapse);
    }
  }

  getRect() {
    return this.wrapper ? this.wrapper.getBoundingClientRect() : {};
  }

  toggle(e) {
    if (e) {
      e.stopPropagation();
    }
    this.setState(toggleExpanded);
  }

  render() {
    const { text, color, ...otherProps } = this.props;
    const { isExpanded } = this.state;

    return (
      <Wrapper
        innerRef={(el) => {
          this.wrapper = el;
        }}
        {...otherProps}
      >
        <Button color={color} type="button" onClick={(e) => this.toggle(e)}>
          ?
        </Button>
        {isExpanded && <Content rect={this.getRect()}>{text}</Content>}
      </Wrapper>
    );
  }
}
