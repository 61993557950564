// @ts-nocheck
export const companyMapper = (
  { name: companyName, addresses, companyIdentifier, isDatabaseReportAvailable }: any,
  id: any,
) => {
  const address =
    addresses.find(({ type }: any) => !type) ||
    addresses.find(({ type }: any) => type && /registered/i.test(type.description)) ||
    addresses[0] ||
    {};
  return {
    id,
    companyName,
    address,
    companyIdentifier,
    isDatabaseReportAvailable,
  };
};
export function getSearchOptions({ searchTerm, countryCode, city, state, searchMethod }: any) {
  const method = searchMethod === 'all' ? 'combined' : searchMethod;
  let searchOptions = {
    countryCode,
    searchTerm,
    searchMethod: method,
  };
  if (city) searchOptions = { ...searchOptions, city };
  if (state) searchOptions = { ...searchOptions, state };
  return searchOptions;
}
