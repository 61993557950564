import StakeholderIcon from '@material-ui/icons/AccountCircle';
import BusinessIcon from '@material-ui/icons/Business';
import React, { useState } from 'react';
import { I18nProp, injectI18n } from 'src/i18n';
import svg4everybody from 'svg4everybody';
import apps from '../../apps';
import CompanySearchForm from '../../components/OneSearch/CompanySearchForm';
import StakeholderSearchForm from '../../components/OneSearch/StakeholderSearchForm';
import { useCountryList } from '../../components/OneSearch/useContryList';
import PreferencesLink from '../../components/PreferencesLink';
import {
  hasAccessToCreditReports,
  hasAccessToStakeholderReport,
  hasAccessToXseptionReport,
  isDeliveryManager,
} from '../../keycloak';
import appIcons from '../../media/icons/appIcons.svg';
import openInNewIcon from '../../media/icons/open_in_new.svg';
import { sendEvent } from '../../utils/analytics';
import {
  AppInfo,
  AppsHeading,
  Content,
  Description,
  ExternalIcon,
  GridContainer,
  HeaderWrapper,
  HomeSearchFormWrapper,
  HrefTile,
  SpaceReserve,
  Tab,
  Tabs,
  Tile,
  TileName,
  TileSvg,
  Wrapper,
} from './helper-components';

// polyfill to make sure spritemaps work in ie11
svg4everybody();

const Home = ({ i18n }: I18nProp) => {
  const [countryList, countryError] = useCountryList();
  const [activeTab, setActiveTab] = useState(0);
  // @ts-ignore
  const showTabs = hasAccessToStakeholderReport();
  return (
    <Wrapper>
      <Content>
        <HeaderWrapper>
          {showTabs && (
            <Tabs
              value={activeTab}
              onChange={(e, selectedTab) => {
                setActiveTab(selectedTab);
              }}
              aria-label="search tab"
            >
              <Tab icon={<BusinessIcon />} label={i18n.text('one-search.company')} />
              <Tab icon={<StakeholderIcon />} label={i18n.text('one-search.person')} />
            </Tabs>
          )}
          {
            !showTabs && <div />
            /* Since tabs and / or the preferences link may be hidden,
            this empty div is to maintain the layout composition */
          }
          {isDeliveryManager() && (hasAccessToCreditReports() || hasAccessToXseptionReport()) && (
            <PreferencesLink
              // color={white}
              i18n={i18n}
              to="/company-data/preferences"
              title={i18n.text('cr.preferences')}
            />
          )}
        </HeaderWrapper>

        <HomeSearchFormWrapper hasTabs={showTabs}>
          {activeTab === 0 && (
            <CompanySearchForm countryList={countryList} error={Boolean(countryError)} />
          )}
          {activeTab === 1 && <StakeholderSearchForm />}
        </HomeSearchFormWrapper>

        <AppsHeading>{i18n.text('app-home.apps.heading')}</AppsHeading>

        <GridContainer>
          {apps
            .filter((app: any) => app.hasHomeTile && app.hasAccess())
            .map(({ isExternal, url, path, key }: any) =>
              isExternal || url ? (
                // @ts-expect-error
                <HrefTile
                  key={key}
                  onClick={() => {
                    sendEvent('HOME', `Click home tile: ${key}`);
                  }}
                  href={url || `/apps/${key}`}
                  target={url && '_blank'}
                  rel={url && 'noopener noreferrer'}
                  id={`home-application-link-${key}`}
                >
                  <TileSvg>
                    <use xlinkHref={`${appIcons}#${key}`} />
                  </TileSvg>
                  <AppInfo>
                    <TileName>{i18n.text(`apps.${key}`)}</TileName>
                    <Description>{i18n.text(`apps.${key}.description`)}</Description>
                    {url && <ExternalIcon src={openInNewIcon} />}
                  </AppInfo>
                </HrefTile>
              ) : (
                <Tile
                  key={key}
                  to={path || `/${key}`}
                  id={`home-application-link-${key}`}
                  data-test={`link-${key}`}
                  onClick={() => {
                    sendEvent('HOME', `Click home tile: ${key}`);
                  }}
                >
                  <TileSvg>
                    <use xlinkHref={`${appIcons}#${key}`} />
                  </TileSvg>
                  <AppInfo>
                    <TileName>{i18n.text(`apps.${key}`)}</TileName>
                    <Description>{i18n.text(`apps.${key}.description`)}</Description>
                  </AppInfo>
                </Tile>
              ),
            )}
          <SpaceReserve />
          <SpaceReserve />
          <SpaceReserve />
        </GridContainer>
      </Content>
    </Wrapper>
  );
};

export default injectI18n(Home);
