import React, { Component, lazy } from 'react';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import Page404 from '../../components/Page404';
import PageUnauthorized from '../../components/PageUnauthorized';
import { hasAccessToICRPaygo } from '../../keycloak';
import configureStore from './store/configureStore';
import SearchPage from './pages/SearchPage';
import { fetchCountries } from './store/searchPage';
import { initClientSessionAction } from './shared/actions/clientSession';
const ArchivePage = lazy(
  () =>
    import(
      /* webpackChunkName: "ArchivePage" */
      './pages/ArchivePage'
    ),
);
const InvestigatedReport = lazy(
  () =>
    import(
      /* webpackChunkName: "InvestigatedReport" */
      './pages/InvestigatedReport'
    ),
);

class InternationalCreditReports extends Component {
  store: any;
  constructor(props: any) {
    super(props);
    this.store = configureStore();
  }

  componentDidMount() {
    this.store.dispatch(fetchCountries);
    this.store.dispatch(initClientSessionAction);
  }

  componentWillUnmount() {
    this.store = null;
  }

  render() {
    return (
      <Provider store={this.store}>
        <Switch>
          <Route path="/international-credit-reports" exact>
            <SearchPage />
          </Route>
          <Route
            path="/international-credit-reports/archive"
            exact
            render={({ location }) => {
              return (
                <ArchivePage activeTab={location.hash === '#received' ? 'READY' : 'PENDING'} />
              );
            }}
          />
          <Route
            path="/international-credit-reports/order-investigated-report/:countryCode?"
            render={() => (hasAccessToICRPaygo() ? <InvestigatedReport /> : <PageUnauthorized />)}
          />
          <Route component={Page404} />
        </Switch>
      </Provider>
    );
  }
}

export default InternationalCreditReports;
