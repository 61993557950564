export const GDI_DATA_FETCH_URL = '/credit-reports/organizations/';
// Common actions
export const REPORT_FETCH_REQUEST = 'REPORT_FETCH_REQUEST';
export const REPORT_FETCH_RESPONSE = 'REPORT_FETCH_RESPONSE';
export const REPORT_CREATE_REQUEST = 'REPORT_CREATE_REQUEST';
export const REPORT_CREATE_RESPONSE = 'REPORT_CREATE_RESPONSE';
export const REPORT_RESET_CREATION_ERROR = 'REPORT_RESET_CREATION_ERROR';
export const SET_GDI_TRANSLATIONS = 'SET_GDI_TRANSLATIONS';
// Commpany header actions
export const SET_COMPANY_HEADER_DATA = 'SET_COMPANY_HEADER_DATA';
// Company profile actions
export const SET_COMPANY_CONTACTS_DATA = 'SET_COMPANY_CONTACTS_DATA';
export const SET_COMPANY_DETAILS_DATA = 'SET_COMPANY_DETAILS_DATA';
export const SET_BRANCHES_DATA = 'SET_BRANCHES_DATA';
// Company ratings actions
export const SET_RATING_PERCENTAGE_DATA = 'SET_RATING_PERCENTAGE_DATA';
export const SET_FLAG_LIMIT_DATA = 'SET_FLAG_LIMIT_DATA';
export const SET_PAYMENT_SCORE_DATA = 'SET_PAYMENT_SCORE_DATA';
export const SET_DISCONTINUATION_SCORE_DATA = 'SET_DISCONTINUATION_SCORE_DATA';
export const SET_GROWTH_SCORE_DATA = 'SET_GROWTH_SCORE_DATA';
export const SET_CREDIT_RATING_DATA = 'SET_CREDIT_RATING_DATA';
export const SET_RATING_TREND_DATA = 'SET_RATING_TREND_DATA';
// Financial calamities
export const SET_FINANCIAL_INSOLVENCIES_DATA = 'SET_FINANCIAL_INSOLVENCIES_DATA';
// Financials
export const SET_GENERAL_INFORMATION = 'SET_GENERAL_INFORMATION';
export const SET_PROFIT_AND_LOSS_DATA = 'SET_PROFIT_AND_LOSS_DATA';
export const SET_APPROPRIATION_ACCOUNT_DATA = 'SET_APPROPRIATION_ACCOUNT_DATA';
export const SET_SOCIAL_BALANCE_DATA = 'SET_SOCIAL_BALANCE_DATA';
export const SET_BALANCE_SHEET = 'SET_BALANCE_SHEET';
export const SET_EXPLANATORY_NOTES = 'SET_EXPLANATORY_NOTES';
export const SET_KEY_FIGURES = 'SET_KEY_FIGURES';
export const SET_LIABILITIES_DATA = 'SET_LIABILITIES_DATA';
// Affiliations
export const SET_AFFILIATIONS_DIRECTORS_DATA = 'SET_AFFILIATIONS_DIRECTORS_DATA';
export const SET_GROUP_STRUCTURE_DATA = 'SET_GROUP_STRUCTURE_DATA';
export const SET_OTHERS_DATA = 'SET_OTHERS_DATA';
export const SET_SHAREHOLDERS_DATA = 'SET_SHAREHOLDERS_DATA';
// Error codes
export const REPORT_EXPIRED_ERROR = 'REPORT_EXPIRED_ERROR';
export const FETCH_REPORT_ERROR = 'FETCH_REPORT_ERROR';
// Organisation
export const SET_ORGANISATION_HEADER_DATA = 'SET_ORGANISATION_HEADER_DATA';
export const SET_ORGANISATION_COMPANY_BOX_DATA = 'SET_ORGANISATION_COMPANY_BOX_DATA';
export const SET_ARCHIVED_REPORTS_DATA = 'SET_ARCHIVED_REPORTS_DATA';
// Payment behaviour
export const SET_CCJ_DETAILS_DATA = 'SET_CCJ_DETAILS_DATA';
export const SET_CCJ_SUMMARIES_DATA = 'SET_CCJ_SUMMARIES_DATA';
export const SET_MORTGAGES_DATA = 'SET_MORTGAGES_DATA';
export const SET_UNSECURED_CREDITORS_DATA = 'SET_UNSECURED_CREDITORS_DATA';
export const SET_PAYMENT_AVERAGES_DATA = 'SET_PAYMENT_AVERAGES_DATA';
export const SET_PAYMENT_TERMS_DATA = 'SET_PAYMENT_TERMS_DATA';
export const SET_PAYMENT_TREND_DATA = 'SET_PAYMENT_TREND_DATA';
export const SET_SUMMONS_AND_JUDGMENTS = 'SET_SUMMONS_AND_JUDGMENTS';
// Specifics
export const SET_EVENTS = 'SET_EVENTS';
export const SET_XSEPTIONS = 'SET_XSEPTIONS';
export const SET_UNSECURED_CLAIMS_DATA = 'SET_UNSECURED_CLAIMS_DATA';
// Misc
export const PROFIT_AND_LOSS = 'PROFIT_AND_LOSS';
export const SOCIAL_BALANCE = 'SOCIAL_BALANCE';
export const BALANCE_SHEET = 'BALANCE_SHEET';
export const EXPLANATORY_NOTES = 'EXPLANATORY_NOTES';
export const APPROPRIATION_ACCOUNT = 'APPROPRIATION_ACCOUNT';
export const ACTIVITY_TYPE_PRIMARY = '1';
export const ADDRESS_TYPE_TRADING_ADDRESS = '1';
export const ADDRESS_TYPE_REGISTERED_ADDRESS = '2';
export const ADDRESS_TYPE_OLD_ADDRESS = 'NEXT_TO_LAST_2';
export const ENTITY_TYPE_PERSON = 'PERSON';
export const ENTITY_TYPE_COMPANY = 'COMPANY';
