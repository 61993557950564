import styled from 'styled-components';
import colors from '../../constants/colors';
import breakpoints from '../../constants/breakpoints';

const UserMenu = styled.nav<{
  expanded: boolean;
}>`
  position: absolute;
  width: 300px;
  display: none;

  margin-top: 0px;
  color: ${colors.secondary};
  background: #fff;

  box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);

  ${({ expanded }) =>
    expanded &&
    `
    display: block;`} &:after {
    bottom: 100%;
    right: 40px;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-bottom-color: ${colors.secondaryDark};
    border-width: 10px;
    margin-left: -10px;
  }

  @media screen and (max-width: ${breakpoints['portrait-min']}px) {
    display: block;
    position: relative;
    width: 100%;

    &:after {
      display: none;
    }
  }
`;

export default UserMenu;
