import React from 'react';
import { injectI18n, I18nProp } from 'src/i18n';
import {
  Address,
  ButtonContainer,
  CommonField,
  CommonFields,
  CompanyName,
  CompanyNameWrapper,
  ExtraFields,
  Info,
  OrganizationType,
  Wrapper,
  AddressWrapper,
  Label,
  DataWrapper,
  OrganizationTypeWrapper,
  StatusWrapper,
  TypeWrapper,
} from './helper-components';
import { HIGHLIGHT_FIELDS, MAIN_FIELDS, COMMON_FIELDS, getItemTranslation } from './utils';
import CompanyStatus from '../../components/CompanyStatus';

type Props = {
  appType?;
  highlightFields?;
  companyName?;
  isCompanyData?;
  organizationType?;
  isActive?;
  commonFields?;
  address?;
  buttons?;
  showExtraFields?;
  showActivity?;
  getItemTranslationFunc?;
};

export const SearchResultsListItem: React.FC<Props & I18nProp> = ({
  i18n,
  companyName,
  isCompanyData,
  isActive,
  commonFields,
  address,
  buttons,
  showExtraFields,
  showActivity,
  appType = 'CR',
  highlightFields = null,
  organizationType = null,
  getItemTranslationFunc = getItemTranslation,
}) => (
  <Wrapper>
    <Info>
      {/* Extra fields */}

      {showExtraFields && highlightFields && Object.keys(highlightFields).length > 0 && (
        <ExtraFields>
          {Object.keys(highlightFields).map((field) => (
            <div
              key={field}
              dangerouslySetInnerHTML={{
                __html: getItemTranslationFunc(i18n, field, HIGHLIGHT_FIELDS, {
                  value: highlightFields[field],
                }),
              }}
            />
          ))}
        </ExtraFields>
      )}
      {/* Main fields */}

      <CompanyNameWrapper>
        <CompanyName
          dangerouslySetInnerHTML={{
            __html: companyName || '-',
          }}
        />
      </CompanyNameWrapper>
      {/* Company's activity */}

      <TypeWrapper>
        {showActivity && (
          <StatusWrapper>
            <CompanyStatus small status={isActive} />
          </StatusWrapper>
        )}

        {appType === 'CR' && (
          <OrganizationTypeWrapper>
            <OrganizationType
              type={organizationType}
              title={getItemTranslationFunc(i18n, `type.tooltip.${organizationType}`, MAIN_FIELDS)}
            >
              {getItemTranslationFunc(i18n, `type.${organizationType}`, MAIN_FIELDS)}
            </OrganizationType>
          </OrganizationTypeWrapper>
        )}
      </TypeWrapper>

      <DataWrapper>
        {/* Common fields */}

        {isCompanyData ? (
          <CommonFields>
            {commonFields &&
              Object.keys(commonFields).map((field) => (
                <CommonField
                  key={field}
                  dangerouslySetInnerHTML={{
                    __html: `${getItemTranslationFunc(i18n, field, COMMON_FIELDS)}: ${
                      commonFields[field] || '-'
                    }`,
                  }}
                />
              ))}
          </CommonFields>
        ) : (
          <CommonFields>
            <CommonField
              dangerouslySetInnerHTML={{
                __html: `${getItemTranslationFunc(i18n, 'VAT', COMMON_FIELDS)}: 
              ${commonFields.vatNumber || '-'}`,
              }}
            />
            <CommonField
              dangerouslySetInnerHTML={{
                __html: `${getItemTranslationFunc(i18n, 'Internal', COMMON_FIELDS)}: 
              ${commonFields.agencyCompanyId || '-'}`,
              }}
            />
          </CommonFields>
        )}
      </DataWrapper>

      {appType === 'CR' && (
        <AddressWrapper>
          <Label>{getItemTranslationFunc(i18n, 'address', MAIN_FIELDS)}: </Label>
          <Address
            dangerouslySetInnerHTML={{
              __html: (address && (isCompanyData ? address : address.entireAddress)) || '-',
            }}
            uppercase
          />
        </AddressWrapper>
      )}

      {appType === 'ICR' && (
        <AddressWrapper>
          <Address>
            {`${getItemTranslationFunc(i18n, 'address', MAIN_FIELDS)}: ${
              (address && (isCompanyData ? address : address.entireAddress)) || '-'
            }`}
          </Address>
        </AddressWrapper>
      )}
    </Info>
    {/* Buttons */}

    {buttons && <ButtonContainer>{buttons}</ButtonContainer>}
  </Wrapper>
);

export default injectI18n(SearchResultsListItem);
