import { useState } from 'react';

const safeJsonParse = (str: any) => {
  try {
    return JSON.parse(str);
  } catch {
    return str;
  }
};

const safeStringify = (json: any) => {
  try {
    return JSON.stringify(json);
  } catch {
    return json;
  }
};

export function getSessionStorage(key: any) {
  return safeJsonParse(sessionStorage.getItem(key));
}
export function setSessionStorage(key: any, value: any) {
  if (!value) sessionStorage.removeItem(key);
  else sessionStorage.setItem(key, safeStringify(value));
}
export function useStorage(key: any, storage: any) {
  const [state, setState] = useState(safeJsonParse(storage.getItem(key)));

  const updateStore = (value: any) => {
    if (!value && value !== 0) {
      setState(value);
      storage.removeItem(key);
    } else {
      setState(value);
      storage.setItem(key, safeStringify(value));
    }
  };

  return [state, updateStore, setState];
}
export function useLocalStorage(key: any) {
  return useStorage(key, localStorage);
}
export function useSessionStorage(key: any) {
  return useStorage(key, sessionStorage);
}
