import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { Action } from 'redux-actions';
import _get from 'lodash/get';
import { get } from '../../../utils/api';

const slice = createSlice({
  name: 'searchPage',
  initialState: {},

  reducers: {
    countriesListSet: (state, action: Action<unknown>) => {
      Object.keys(state).forEach((key) => delete state[key]);
      Object.assign(state, action.payload);
    },
  },
});

export const { countriesListSet } = slice.actions;

export const searchPageReducer = slice.reducer;

export const extractCountries = (countries: any) =>
  countries.reduce(
    (
      acc,
      {
        name,
        code,
        preferredSearchMethod,
        searchMethods,
        serviceLevel,
        available,
        availablePaygo,
        states,
      }: any,
    ) => ({
      ...acc,

      [code]: {
        name,
        preferredMethod: preferredSearchMethod || _get(searchMethods, '0.method', null),
        searchMethods,
        serviceLevel,
        available,
        availablePaygo,
        states,
      },
    }),
    {},
  );

export const fetchCountries = (dispatch: Dispatch) => {
  get('/international-business/country-list').then((response) => {
    const { countries = [] } = response;
    dispatch(countriesListSet(extractCountries(countries)));
  });
};
