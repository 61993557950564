export const COMPANY_SEARCH_ROUTE = 'COMPANY_SEARCH_ROUTE';
export const RAW_DATA_ROUTE = 'RAW_DATA_ROUTE';
export const ORGANISATION_ROUTE = 'ORGANISATION_ROUTE';
export const PREFERENCES_ROUTE = 'PREFERENCES_ROUTE';
export const routesConfig = {
  [COMPANY_SEARCH_ROUTE]: {
    path: '/company-data',
    exact: true,
  },
  [RAW_DATA_ROUTE]: {
    path: '/company-data/validate/:enterpriseId',
    exact: true,
  },
  [ORGANISATION_ROUTE]: {
    path: '/company-data/organisation/:enterpriseId',
    exact: true,
  },
  [PREFERENCES_ROUTE]: {
    path: '/company-data/preferences',
    exact: true,
  },
};

const getRouteProps = (routeName: any) => routesConfig[routeName];

export default getRouteProps;
