import React from 'react';
import styled from 'styled-components';
import range from 'lodash/range';
import colors from '../../constants/colors';
import { sans } from '../../constants/fonts';

const CHAR_PREV = '\u25C0';
const CHAR_NEXT = '\u25B6';
const CHAR_GAP = '\u2026';

const PageButton = styled.button<{
  active?: boolean;
  disabled?: boolean;
}>`
  min-width: 30px;
  height: 30px;
  padding: 0 5px;
  margin: 0 5px;
  border: 1px solid ${({ active }) => (active ? colors.secondaryDark : colors.disabledLight)};
  border-radius: 3px;
  background-color: ${({ active }) => (active ? colors.secondaryDark : colors.white)};
  color: ${({ active }) => (active ? colors.white : colors.secondary)};
  font-family: ${sans};
  font-size: 13px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  ${({ disabled, active }) => disabled && !active && 'visibility: hidden;'} &:hover:not(:disabled) {
    background-color: ${colors.secondary};
    border-color: ${colors.secondary};
    color: ${colors.white};
  }
`;

const Gap = styled.div`
  width: 30px;
  height: 30px;
  display: inline-block;
  text-align: center;
  padding: 0 5px;
  margin: 0 5px;
  font-size: 13px;
  font-weight: bold;
  cursor: default;
`;

type PaginationProps = {
  pageCount: number;
  activePage: number;
  onClickPage?: (page: number) => void;
  radius?: number;
};

const Pagination = ({ pageCount, activePage = 1, onClickPage, radius = 4 }: PaginationProps) => {
  if (activePage < 0 || activePage > pageCount - 1) {
    throw new Error(`activePage must be between 0 and ${pageCount - 1}, given was ${activePage}`);
  }

  const rangeLeft = activePage - radius;
  const rangeRight = activePage + radius;

  const rangeMin = 0;
  const rangeMax = pageCount - 1;

  const overflowLeft = Math.max(rangeMin - rangeLeft, 0);
  const overflowRight = Math.max(rangeRight - rangeMax, 0);

  let improvedRangeLeft = Math.max(rangeLeft - overflowRight, rangeMin);
  let improvedRangeRight = Math.min(rangeRight + overflowLeft, rangeMax);

  const showFirstPageWithGap = radius >= 2 && improvedRangeLeft > rangeMin;
  if (showFirstPageWithGap) {
    improvedRangeLeft += 2;
  }
  const showLastPageWithGap = radius >= 2 && improvedRangeRight < rangeMax;
  if (showLastPageWithGap) {
    improvedRangeRight -= 2;
  }

  return (
    <div>
      <PageButton
        type="button"
        onClick={() => onClickPage && onClickPage(activePage - 1)}
        disabled={activePage <= rangeMin}
      >
        {CHAR_PREV}
      </PageButton>

      {showFirstPageWithGap && (
        <PageButton type="button" onClick={() => onClickPage && onClickPage(rangeMin)}>
          {rangeMin + 1}
        </PageButton>
      )}
      {showFirstPageWithGap && <Gap>{CHAR_GAP}</Gap>}

      {range(improvedRangeLeft, improvedRangeRight + 1).map((i) => (
        <PageButton
          key={i}
          type="button"
          onClick={() => onClickPage && onClickPage(i)}
          active={activePage === i}
          disabled={activePage === i}
        >
          {i + 1}
        </PageButton>
      ))}

      {showLastPageWithGap && <Gap>{CHAR_GAP}</Gap>}
      {showLastPageWithGap && (
        <PageButton type="button" onClick={() => onClickPage && onClickPage(rangeMax)}>
          {rangeMax + 1}
        </PageButton>
      )}

      <PageButton
        type="button"
        onClick={() => onClickPage && onClickPage(activePage + 1)}
        disabled={activePage >= rangeMax}
      >
        {CHAR_NEXT}
      </PageButton>
    </div>
  );
};

export default Pagination;
