import get from 'lodash/get';
export const extractCcjSummariesData = (data: any) => {
  const courtJudgmentSummary = get(data, 'paymentBehaviourProfile.courtJudgmentSummary', {});
  const hasData = Object.keys(courtJudgmentSummary).reduce(
    (acc, key) => acc || typeof courtJudgmentSummary[key] === 'number',
    false,
  );
  return {
    hasData,
    data: {
      count: {
        last18month: get(courtJudgmentSummary, 'totalNumberLast18Months'),
        last18monthMonthlyAverage: get(courtJudgmentSummary, 'averageNumberLast18Months'),
        last36month: get(courtJudgmentSummary, 'totalNumberLast36Months'),
        last36monthMonthlyAverage: get(courtJudgmentSummary, 'averageNumberLast36Months'),
        last72month: get(courtJudgmentSummary, 'totalNumberLast72Months'),
        last72monthMonthlyAverage: get(courtJudgmentSummary, 'averageNumberLast72Months'),
      },
      totalAmount: {
        last18month: get(courtJudgmentSummary, 'totalAmountLast18Months'),
        last18monthMonthlyAverage: get(courtJudgmentSummary, 'averageAmountLast18Months'),
        last36month: get(courtJudgmentSummary, 'totalAmountLast36Months'),
        last36monthMonthlyAverage: get(courtJudgmentSummary, 'averageAmountLast36Months'),
        last72month: get(courtJudgmentSummary, 'totalAmountLast72Months'),
        last72monthMonthlyAverage: get(courtJudgmentSummary, 'averageAmountLast72Months'),
      },
      unsettledAmount: {
        last18month: get(courtJudgmentSummary, 'totalAmountNotSatisfiedLast18Months'),
        last18monthMonthlyAverage: get(
          courtJudgmentSummary,
          'averageAmountNotSatisfiedLast18Months',
        ),
        last36month: get(courtJudgmentSummary, 'totalAmountNotSatisfiedLast36Months'),
        last36monthMonthlyAverage: get(
          courtJudgmentSummary,
          'averageAmountNotSatisfiedLast36Months',
        ),
        last72month: get(courtJudgmentSummary, 'totalAmountNotSatisfiedLast72Months'),
        last72monthMonthlyAverage: get(
          courtJudgmentSummary,
          'averageAmountNotSatisfiedLast72Months',
        ),
      },
      highestAmount: {
        last18month: get(courtJudgmentSummary, 'highestAmountLast18Months'),
        last36month: get(courtJudgmentSummary, 'highestAmountLast36Months'),
        last72month: get(courtJudgmentSummary, 'highestAmountLast72Months'),
      },
    },
  };
};
