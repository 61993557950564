// the function capture function call argument in an array for "duration"ms
// then it flush them as an array to the flush function
const capture = (duration: number) => (flush: (buffer: any[]) => any) => {
  let buffer: any = [];
  let timer: any;
  return (value: any) => {
    buffer.push(value);
    clearTimeout(timer);
    timer = setTimeout(() => {
      flush(buffer);
      buffer = [];
    }, duration);
  };
};

export default capture;
