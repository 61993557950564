import styled from 'styled-components';

const arrowWidth = 24;
const arrowHeight = 12;
const tooltipDistance = 4;

export const Container = styled.div`
  position: relative;
`;

export const TooltipIcon = styled.div<{
  size: number;
  fontSize: number;
  color: string;
  bgColor: string;
  invertColors: boolean;
}>`
  text-align: center;
  cursor: pointer;
  font-weight: 400;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  color: ${({ invertColors, color, bgColor }) => (invertColors ? bgColor : color)};
  border: 2px solid ${({ color }) => color};
  border-radius: ${({ size }) => size}px;
  font-size: ${({ fontSize }) => fontSize}px;
  background-color: ${({ invertColors, color, bgColor }) => (invertColors ? color : bgColor)};
  line-height: ${({ size, fontSize }) => size - 0.1 * fontSize}px;

  &:hover {
    color: ${({ bgColor }) => bgColor};
    background-color: ${({ color }) => color};
  }
`;

export const Tip = styled.div<{
  showBelow: boolean;
  left: number;
  leftMobile: number;
  width: number;
  widthMobile: number;
  buttonSize: number;
}>`
  position: absolute;
  ${({ showBelow, buttonSize }) =>
    showBelow
      ? `top: ${buttonSize + arrowHeight + tooltipDistance}px;`
      : `bottom: ${buttonSize + arrowHeight + tooltipDistance}px;`};
  left: ${({ left }) => left}px;
  width: ${({ width }) => width}px;
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #e3e3e3;
  padding: 16px;
  background-color: white;
  color: black;
  z-index: 9999;

  @media (max-width: 768px) {
    left: ${({ leftMobile }) => leftMobile}px;
    width: ${({ widthMobile }) => widthMobile}px;
  }
`;

export const Arrow = styled.div<{
  showBelow: boolean;
  buttonSize: number;
  isBorderArrow?: boolean;
}>`
  position: absolute;
  width: 0;
  height: 0;
  left: ${({ buttonSize }) => buttonSize / 2 - arrowWidth / 2 - 1}px;
  ${({ showBelow }) => (showBelow ? 'top' : 'bottom')}:
    ${({ buttonSize, isBorderArrow }) => buttonSize + tooltipDistance + (isBorderArrow ? -1 : 0)}px;

  border-left: ${arrowWidth / 2 + 1}px solid transparent;
  border-right: ${arrowWidth / 2 + 1}px solid transparent;
  border-${({ showBelow }) => (showBelow ? 'bottom' : 'top')}:
    ${arrowHeight + 1}px solid ${({ isBorderArrow }) => (isBorderArrow ? '#e3e3e3' : 'white')};

  z-index: 9999;
`;
