import styled from 'styled-components';
import OriginalSearchBar from '../../../../components/SearchBar';
export const SearchBar = styled(OriginalSearchBar)`
  flex: 1;
  margin: 0;
  margin-left: 8px;
  @media (max-width: 768px) {
    margin-left: 0;
  }
`;
export const Form = styled.form`
  flex: 1;
  display: flex;
`;
