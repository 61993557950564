function parseDateTime(dateTime: string) {
  const keycloakDateTimeRegex = /(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2}).(.*)Z/g;
  const result = keycloakDateTimeRegex.exec(dateTime);

  if (result && result[7] === '0') {
    // @ts-ignore
    return Date.UTC(result[1], result[2] - 1, result[3], result[4], result[5], result[6]) / 1000;
  }

  return null;
}

type Token = {
  ldap_id: string;
  name: string;
  locale: string;
  roles: string[];
  email: string;
  family_name: string;
  given_name: string;
  customer: {
    id: string;
    name: string;
    active: boolean;
    products: string[];
  };
  consumer: {
    id: string;
    name: string;
    active: boolean;
  };
  create_timestamp: string;
};

/* eslint-disable camelcase */
function createUserProperties(token: Token) {
  const {
    ldap_id,
    name,
    locale,
    roles,
    email,
    family_name,
    given_name,
    customer,
    consumer,
    create_timestamp,
  } = token;
  const { products } = customer;

  return {
    userId: ldap_id,
    create_timestamp: parseDateTime(create_timestamp),
    name,
    family_name,
    given_name,
    customerId: customer.id,
    customerName: customer.name,
    customerActive: customer.active,
    consumerId: consumer.id,
    consumerName: consumer.name,
    consumerActive: consumer.active,
    email,
    locale,
    roles,
    products,
  };
}

let isScriptAdded = false;

function loadAppcues(token: Token, appcuesAccountId: string) {
  if (isScriptAdded) return;
  isScriptAdded = true;

  const script = document.createElement('script');
  script.src = `//fast.appcues.com/${appcuesAccountId}.js`;
  script.async = true;
  script.onload = () => {
    if (window['Appcues']) {
      window['Appcues'].identify(createUserProperties(token));
    }
  };

  document.head.appendChild(script);
}

export { createUserProperties, loadAppcues };

export default { createUserProperties, loadAppcues };
