import get from 'lodash/get';
import { parseDate } from '../common';
import { getFormattedAddress } from '../../../../../utils/getFormattedAddress';
import { ADDRESS_TYPE_TRADING_ADDRESS, ADDRESS_TYPE_REGISTERED_ADDRESS } from '../../constants';
export const comparator = (a: any, b: any) => {
  if (a.isActive !== b.isActive) {
    return a.isActive ? -1 : 1;
  }

  const dateA = a.endDate || a.startDate;
  const dateB = b.endDate || b.startDate;
  if (!dateA) return 1;
  if (!dateB) return -1;

  if (dateA < dateB) {
    return 1;
  }

  if (dateA > dateB) {
    return -1;
  }

  return 0;
};
export const getBranchAddress = (countryCode: any, branch: any) => {
  const ADDRESS_TYPE =
    countryCode === 'GB' ? ADDRESS_TYPE_TRADING_ADDRESS : ADDRESS_TYPE_REGISTERED_ADDRESS;
  const address =
    get(branch, 'address', []).find(({ type }: any) => type.value === ADDRESS_TYPE) || {};
  return getFormattedAddress(countryCode, address);
};
export const extractBranchesData = (data: any) => {
  const countryCode = get(data, 'companyIdentification.countryCode');
  const branches = get(data, 'companyProfile.branches.branches', []);
  const sortedBranches = branches
    .map((branch, i) => ({
      key: i,
      name: countryCode === 'GB' ? get(data, 'companyProfile.companyName') : get(branch, 'name'),
      id: get(branch, 'organizationId'),
      isActive: get(branch, 'isActive'),
      startDate: parseDate(get(branch, 'startDate', '')),
      endDate: parseDate(get(branch, 'endDate', '')),
      address: getBranchAddress(countryCode, branch),
    }))
    .sort(comparator);
  return countryCode === 'GB' ? sortedBranches.slice(0, 19) : sortedBranches;
};
