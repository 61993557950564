import { appcues } from 'src/core';
import { getI18n } from 'src/i18n';
import { injectGlobal } from 'styled-components';
import keycloak from '../keycloak';
import bellSVG from '../media/icons/bell.svg';
import { sendEvent } from './analytics';

export const NotificationsStyles = injectGlobal`
  .appcues {
    .appcues-icon img{
      width: 22px;
      height: 22px;
    }

    .appcues-widget-header {
      font-family: Roboto;
      font-size: 18px;
      font-weight: 500;
      color: #3a7177;
    }

    time {
      display: none;
    }

    .appcues-widget-icon{
      &:after{
        background: #eb6e08;
        font-size: 11px;
        min-width: 20px;
        height: 20px;
        line-height: 20px;
      }

      &[data-appcues-count="0"]:after{
        display: none;
      }
    }

    .appcues-nothing-new{
      display: none;
    }

    .appcues-widget-list li.appcues-unread{
      font-family: Roboto;
      font-size: 16px;
      font-weight: bold;
      line-height: 1.4;
      color: #168fa7;
      text-decoration: underline;
      padding-left: 10px;
    }

    .appcues-widget-dropdown {
      border-radius: 4px;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
      margin-top: -10px;

      &:before, &:after {
        border: none!important;
      }
    }


    li.appcues-unread a:before {
      background-color: #eb6e08;
      border-radius: 50%;
      content: "";
      height: 10px;
      width: 10px;
      margin: 5px 0 0 -15px;
      position: absolute;
    }

    li.appcues-read{
      font-family: Roboto;
      font-size: 16px;
      line-height: 1.4;
      color: #168fa7;
      text-decoration: underline;
      border-bottom: none!important;
      border-top: 1px solid #eee!important;
    }

    li.appcues-unread:not(:first-child){
      border-bottom: none!important;
      border-top: 1px solid #eee!important;
    }

    .appcues-widget-list{
      border-bottom: none;
    }

    .appcues-widget-header {
      // border-bottom: none;
    }

    .appcues-widget-list li{
      border-bottom: none;
    }
  }
`;
export function initializeNotifications(locale?: any) {
  try {
    if (!window['Appcues']) return;
    const user = keycloak.tokenParsed;
    const i18n = getI18n(locale || (user && user.locale) || 'en-GB');
    window['Appcues'].identify(appcues.createUserProperties(keycloak.tokenParsed));
    window['Appcues'].loadLaunchpad('#notifications-icon', {
      position: 'left',
      header: i18n.text('notifications.title'),
      icon: bellSVG,
    });
    const notificationsElem = document.getElementById('notifications-icon');
    notificationsElem?.addEventListener('click', () => {
      sendEvent('appcues', `click notifications icon`);
    });
  } catch (error) {
    console.error('Error on loading the Appcues launchpad', error);
  }
}
