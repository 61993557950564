// @ts-nocheck
import { handleActions } from 'redux-actions';
import { fetchOrganisationRequest, fetchOrganisationResponse } from './actions';
export const initialState = {
  isFetching: false,
  isError: false,
  hasResponse: false,
};
export default handleActions(
  {
    [fetchOrganisationRequest]: (state) => ({ ...state, isFetching: true }),
    [fetchOrganisationResponse]: {
      next: () => ({
        isFetching: false,
        isError: false,
        hasResponse: true,
      }),
      throw: () => ({
        isFetching: false,
        isError: true,
        hasResponse: false,
      }),
    },
  },
  initialState,
);
