import { useEffect } from 'react';

function globalHandler(type, handler) {
  window.addEventListener(type, handler);
  return () => {
    window.removeEventListener(type, handler);
  };
}

export const useGlobalEvent = (type, handler) => useEffect(() => globalHandler(type, handler), []);
