import get from 'lodash/get';

export function setLocale(locale, scope, environment) {
  if (scope.hj) {
    scope.hj('trigger', `${environment}-locale-${locale}`);
  }

  if (scope.Appcues) {
    scope.Appcues.identify({ locale });
  }
}

// Function populate marketo important data to the global 'google_tag_manager' object.
export function populateMarketoData(tokenSource, scope) {
  const email = get(tokenSource, 'tokenParsed.email');
  const token = get(tokenSource, 'tokenParsed.marketo.token');

  if (email && token) {
    scope.google_tag_manager = Object.assign({}, scope.google_tag_manager, {
      marketo: { email, token },
    });
  }
}

export function populateHotjarData(tokenSource, scope, environment) {
  scope.hj =
    scope.hj ||
    function hj(...args) {
      (scope.hj.q = scope.hj.q || []).push(args);
    };

  const locale = get(tokenSource, 'tokenParsed.locale');
  setLocale(locale, scope, environment);
}

// keycloak service and real `window` objects should be passed to population functions
export default function populateAnalyticsData(tokenSource, scope, environment) {
  populateMarketoData(tokenSource, scope);
  populateHotjarData(tokenSource, scope, environment);
}
