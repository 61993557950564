// @ts-nocheck
import { handleActions } from 'redux-actions';
import { setArchivedReportsData } from '../actions';
import { downloadArchivedReportComplete, downloadArchivedReportReset } from './actions';
export const initialState = {
  reportsList: {
    totalCount: 0,
    reports: [],
  },
  isDownloadReady: true,
};
const reducer = {
  [downloadArchivedReportComplete]: {
    next: (state: any) => ({
      ...state,
      isDownloadReady: true,
    }),
    throw: (state: any) => ({
      ...state,
      isDownloadReady: false,
    }),
  },
  [downloadArchivedReportReset]: (state: any) => ({
    ...state,
    isDownloadReady: true,
  }),
  [setArchivedReportsData]: (state, { payload }: any) => ({
    ...state,
    ...payload,
  }),
};
export default handleActions(reducer, initialState);
