import React from 'react';
import { arc as createArc, pie as createPie } from 'd3-shape';
import colors from '../../constants/colors';
import Slice from './Slice';
import Label from './Label';
import { radius } from './constants';

const pie = createPie()
  .value((d) => d.value)
  .startAngle(Math.PI / -2)
  .endAngle(Math.PI / 2);
const arc = createArc()
  .outerRadius(radius)
  .innerRadius(radius * 0.6);
const textArc = createArc()
  .outerRadius(radius + 150)
  .innerRadius(radius * 0.6);
const disabledArc = (
  <path
    style={{ fill: colors.disabledBlue }}
    d={createArc()({
      outerRadius: radius,
      innerRadius: radius * 0.6,
      startAngle: Math.PI / -2,
      endAngle: Math.PI / 2,
    })}
  />
);

function slices(slice, idx) {
  return <Slice key={idx} idx={idx} d={arc(slice)} />;
}

function labels(slice, idx) {
  const { key } = slice.data;
  const [x, y] = textArc.centroid(slice);
  return (
    <Label key={idx} x={x} y={y} selected={this - idx === 1} textAnchor="middle">
      {key}
    </Label>
  );
}

function getRotationAngle(value) {
  const val = parseInt(value, 10);
  return val > 0 && val <= 5 ? (value - 3) * 41 : -90;
}

export { slices, labels, pie, getRotationAngle, disabledArc };
