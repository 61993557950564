// @ts-nocheck
import React from 'react';
import { Node } from 'react';
import styled from 'styled-components';
import { Heading2 } from 'src/core/components/Heading';
import CircleLoader from 'src/core/components/loaders/CircleLoader';
export const LoadingHeading = Heading2;
const Container = styled.div`
  padding: 1em;
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const LoadingPage = ({ children }: { children?: Node }) => (
  <Container>
    <CircleLoader />
    {children}
  </Container>
);

export default LoadingPage;
