import * as colors from '@graydon/ui-colors';
import get from 'lodash/fp/get';
import React, { ReactNode } from 'react';
import Tooltip from 'src/core/components/Tooltip';
import styled from 'styled-components';
import { sans } from '../../constants/fonts';
import { DangerIcon, InformationIcon, SuccessIcon } from './icons';

const getSecondaryColor = get('secondaryColor');

const getPrimaryColor = get('primaryColor');

const getFrontColor = get('frontColor');

const IconWrapper = styled.div`
  display: inline-block;
  margin-right: 5px;
  line-height: 0;
`;

const Container = styled.div`
  font-size: 1rem;
  border-radius: 4px 4px 0 0;
  background-color: ${getPrimaryColor};

  @media (max-width: 768px) {
    border-radius: 0;
  }

  height: auto;
`;

const Header = styled.div`
  display: flex;
  color: ${getFrontColor};
  padding: 10px;
  @media (max-width: 768px) {
    border-radius: 0;
  }
`;

const Title = styled.h3`
  margin: 0;
  flex: 1;
  color: ${getFrontColor};
  text-transform: uppercase;
  font: 500 18px ${sans};
`;

const TooltipWrapper = styled.div`
  margin-left: auto;
  padding-left: 16px;
`;

const Content = styled.div<{
  padding?: string;
}>`
  padding: ${({ padding = '16px' }) => padding};

  background-color: ${getSecondaryColor};
  border-left: 4px solid ${getPrimaryColor};

  @media (max-width: 768px) {
    border-radius: 0;
  }
`;

type Theme = {
  primaryColor: string;
  secondaryColor: string;
  frontColor: string;
  padding?: string;
};

type Props = {
  title: string | ReactNode;
  description: string | ReactNode;
  icon?: ReactNode;
  theme?: Theme;
  children: string | ReactNode;
  onTooltipClick?: () => any;
  className?: string;
};

const NotificationTile = ({
  title,
  description,
  icon,
  theme = {
    primaryColor: colors.gray,
    secondaryColor: colors.white,
    frontColor: colors.grayLight,
  },
  children,
  onTooltipClick,
  className,
}: Props) => (
  <Container {...theme} className={className}>
    <Header {...theme}>
      {icon && <IconWrapper>{icon}</IconWrapper>}

      <Title {...theme}>{title}</Title>

      {description && (
        <TooltipWrapper>
          <Tooltip
            iconColor={theme.frontColor}
            bgColor={theme.primaryColor}
            onChange={onTooltipClick}
          >
            {description}
          </Tooltip>
        </TooltipWrapper>
      )}
    </Header>

    <Content {...theme}>{children}</Content>
  </Container>
);

export default NotificationTile;

const getTileComponent =
  ({
    icon,
    ...theme
  }: Theme & {
    icon: ReactNode;
  }) =>
  (props: Props) => (
    <NotificationTile {...{ ...props, theme: { ...theme, ...props.theme }, icon }} />
  );

export const SuccessTile = getTileComponent({
  primaryColor: colors.success,
  secondaryColor: `${colors.white}e6`,
  frontColor: colors.white,
  icon: <SuccessIcon color={colors.white} width={20} height={20} />,
});

export const InformationTile = getTileComponent({
  primaryColor: colors.yellow,
  secondaryColor: `${colors.white}e6`,
  frontColor: colors.gray,
  icon: <InformationIcon color={colors.gray} width={22} height={22} />,
});

export const DangerTile = getTileComponent({
  primaryColor: colors.red,
  secondaryColor: `${colors.white}e6`,
  frontColor: colors.white,
  icon: <DangerIcon color={colors.white} width={20} height={20} />,
});

export const DefaultTile = getTileComponent({
  primaryColor: colors.grayLight,
  secondaryColor: `${colors.white}e6`,
  frontColor: colors.grayLight5,
  icon: <InformationIcon color={colors.grayLight5} width={20} height={20} />,
});

export const Tile = ({
  type,
  ...props
}: {
  type: 'info' | 'success' | 'danger' | 'default';
} & Props) => {
  switch (type) {
    case 'info':
      return <InformationTile {...props} />;

    case 'success':
      return <SuccessTile {...props} />;

    case 'danger':
      return <DangerTile {...props} />;

    default:
      return <DefaultTile {...props} />;
  }
};
