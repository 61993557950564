import styled from 'styled-components';
import breakpoints from '../../constants/breakpoints';

export default styled.div`
  display: flex;
  flex-basis: 400px;
  padding: 0.5em;
  position: relative;
  align-items: center;
  flex-grow: 1;

  @media (max-width: ${breakpoints['portrait-min']}px) {
    flex-basis: 100%;
  }
`;
