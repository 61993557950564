//@ts-nocheck
import { get, getCustomerPath } from '../utils/api';
import { getCountryList } from '../components/OneSearch/api';
import { SERVICE_LEVEL__INVESTIGATION } from '../constants';
import { CountryCode } from 'src/types/locales';

const GRAYDON_MASTER_CONSUMER_ID = 1101000002;
const GRAYDON_CUSTOMER_ID = 1101000002;
const CREDITSAFE_CUSTOMER_ID = 1101017384;
const consumerIdsWithAccessToBeGraydonRating = [
  1101003056, 1101000300, 1101018449, 1101005691, 1101017489, 1101000002,
];
const keycloak: any = window.Keycloak('/resource/oidc.json');

keycloak.onTokenExpired = () => keycloak.updateToken();

keycloak.onAuthRefreshError = () => keycloak.clearToken();

export function updateTokenPromise(minValiditySeconds?: number): Promise<void> {
  return new Promise((resolve, reject) => {
    keycloak
      .updateToken(minValiditySeconds)
      .success(resolve)
      .error(() => {
        reject(new Error('failed to update token'));
      });
  });
}
export function loadFeatures(): Promise<void> {
  return get(`${getCustomerPath()}/features`).then((response) => {
    global.features = response;
  });
}
export function loadCountryList(): Promise<void> {
  global.countries = [];
  return getCountryList().then((response: any) => {
    global.countries = response;
  });
}
export function initAuth(): Promise<void> {
  return new Promise((resolve, reject) => {
    keycloak
      .init({
        onLoad: 'login-required',
        checkLoginIframe: false,
      })
      .success(resolve)
      .error(reject);
  })
    .then(() => {
      if (!keycloak.tokenParsed.consumer) {
        throw new Error('CONSUMER_MISSING');
      }

      if (!keycloak.tokenParsed.consumer.active) {
        throw new Error('CONSUMER_INACTIVE');
      }
    })
    .then(loadFeatures)
    .then(loadCountryList);
}
export function getFeatures(): Array<string> {
  return global.features || [];
}
export function hasFeature(feature: string): boolean {
  return getFeatures().includes(feature);
}
export function hasFeatureStartingWith(feature: string): boolean {
  return !!getFeatures().find((it) => it.startsWith(feature));
}
export function hasRole(role: string): boolean {
  return (
    keycloak.realmAccess && keycloak.realmAccess.roles && keycloak.realmAccess.roles.includes(role)
  );
}

export const isGBConsumer = (): boolean =>
  String(keycloak.tokenParsed.consumer.id).startsWith('1301');

// This is a temporary solution to force international search for specific countries
export const onlyInternationalSearch = (country: string): boolean =>
  ['GB'].some((c) => c.toUpperCase() === country);

const domesticFeatures = [
  'COC_EXTRACTS_CACHED',
  'COC_EXTRACTS_FRESH',
  'COMPLIANCE_CHECK',
  'ENRICHMENT_MARKETING_INFORMATION',
  'LIST_MANAGEMENT',
  'DECISION_MODEL',
];
const domesticNlFeatures = ['ANNUAL_ACCOUNTS', 'BUSINESS_RADAR'].concat(domesticFeatures);
const considerDomestic = (feature: string, country: string): boolean =>
  (country === 'NL' && domesticNlFeatures.includes(feature)) ||
  (country === 'BE' && domesticFeatures.includes(feature));

const isCountryFeature = (feature: string, country: string) => feature.endsWith(`_${country}`);

export const hasDomestic = (country: string): boolean =>
  isDeliveryUser() &&
  country.toUpperCase() !== 'GB' &&
  (hasAccessToICR() ||
    getFeatures().some(
      (feature) =>
        isCountryFeature(feature, country.toUpperCase()) ||
        considerDomestic(feature, country.toUpperCase()),
    ));

const hasIcrEuropeOrGlobalFeature = (): boolean =>
  getFeatures().some(
    (it) =>
      it.startsWith('ICR') &&
      (it.endsWith('EUROPE') || it.endsWith('GLOBAL') || it.endsWith('WORLD')) &&
      !it.includes('_REST_OF_'),
  );

const excludedFeaturesForInternationalSearch = [
  'DATABASE_MANAGEMENT_GB',
  'MARKETING_INFORMATION_GB',
  'STAKEHOLDER_REPORT_GB',
  'TRANSUNION_GB',
];

export const hasAccessToGbInternationalSearch = (): boolean =>
  getFeatures().some(
    (feature) =>
      isCountryFeature(feature, 'GB') && !excludedFeaturesForInternationalSearch.includes(feature),
  ) || hasIcrEuropeOrGlobalFeature();

export const hasAccessToInternationalSearch = (country: string): boolean =>
  isDeliveryUser() &&
  ((country !== 'GB' && hasICR()) || (country === 'GB' && hasAccessToGbInternationalSearch()));

export const hasAccessToSomeDomestic = (): boolean =>
  ['NL', 'BE', 'GB'].some((country) => hasDomestic(country));

const isUser = (role: any) => (): boolean => hasRole(role);

export const isDeliveryUser = isUser('delivery_users');
export const isDeliveryManager = isUser('delivery_managers');
export const isDeliveryManagerPlus = isUser('delivery_manager_plus');
export const isDeliveryAdminUser = isUser('delivery_admin_users');
export function getConsumerId() {
  return keycloak.tokenParsed.consumer.id;
}

function getCustomerId() {
  return keycloak.tokenParsed.customer.id;
}

export function isConsumerWithAccessToBeGraydonRating(
  consumerId: number = getConsumerId(),
): boolean {
  return consumerIdsWithAccessToBeGraydonRating.includes(consumerId);
}

export function isGraydonMasterConsumer(consumerId: number = getConsumerId()): boolean {
  return consumerId === GRAYDON_MASTER_CONSUMER_ID;
}

export function isGraydonCustomer(customerId: string = getCustomerId()): boolean {
  return customerId === GRAYDON_CUSTOMER_ID;
}
export function isCreditSafeCustomer(customerId: string = getCustomerId()): boolean {
  return customerId === CREDITSAFE_CUSTOMER_ID;
}
export function getCountryOfResidence(consumerId: string = getConsumerId()): string {
  if (/^1301/.test(consumerId)) return 'gb';
  if (/^1201/.test(consumerId)) return 'be';
  return 'nl';
}
const icrFeaturesWithAllDomesticCountries = {
  ICR_ESSENTIAL_EUROPE: true,
  ICR_PAYGO_ESSENTIAL_EUROPE: true,
  ICR_TOP_TEN_EUROPE: true,
  ICR_PAYGO_TOP_TEN_EUROPE: true,
  ICR_EUROPE: true,
  ICR_PAYGO_EUROPE: true,
  ICR_GLOBAL: true,
  ICR_PAYGO_GLOBAL: true,
};
const icrFreshInvestigationOnDomesticCountries = {
  ICR_PAYGO_ESSENTIAL_EUROPE: true,
  ICR_PAYGO_TOP_TEN_EUROPE: true,
  ICR_PAYGO_EUROPE: true,
  ICR_PAYGO_GLOBAL: true,
};
export function hasFreshInvestigationOnDomesticCountries(
  code: CountryCode,
  customerId?: string,
): boolean {
  // NL/BE users cant request FI for NL/BE companies
  const contractCode = getCountryOfResidence(customerId).toUpperCase();

  if (['NL', 'BE'].includes(code) && contractCode !== 'GB') {
    return false;
  }

  return !!getFeatures().find((feature) => feature in icrFreshInvestigationOnDomesticCountries);
}
export function hasAllDomesticCountriesThroughICR(): boolean {
  return !!getFeatures().find((feature) => feature in icrFeaturesWithAllDomesticCountries);
}
export function hasAccessToCreditReports(): boolean {
  return (
    isDeliveryUser() &&
    (hasFeatureStartingWith('CREDIT_REPORTS') || hasAllDomesticCountriesThroughICR())
  );
}
export function hasCreditReportCountry(countryCode: string): boolean {
  if (
    global.countries &&
    global.countries[countryCode] &&
    global.countries[countryCode].serviceLevel === SERVICE_LEVEL__INVESTIGATION
  )
    return false;
  return hasFeature(`CREDIT_REPORTS_${countryCode.toUpperCase()}`);
}
export function hasInternationalCreditReportCountryOnDomestic(countryCode: string): boolean {
  const domestic = ['nl', 'be', 'gb'];
  if (!domestic.includes(countryCode.toLowerCase())) return false;
  return hasAllDomesticCountriesThroughICR();
}
export const hasAccessToInternationalCreditReportOnDomestic = () =>
  hasAllDomesticCountriesThroughICR();
export function hasAccessToMonitoring(countryCode?: string): boolean {
  return (
    isDeliveryUser() &&
    (countryCode ? hasFeature(`MONITORING_${countryCode}`) : hasFeatureStartingWith('MONITORING'))
  );
}
export function hasAccessToListManagement(): boolean {
  return isDeliveryUser() && hasFeature('LIST_MANAGEMENT');
}
export function hasAccessToUboReports(countryCode: string): boolean {
  if (!countryCode) return false;
  return isDeliveryUser() && hasFeature(`UBO_REPORT_${countryCode}`);
}
export function hasAccessToComplianceCheck(): boolean {
  return isDeliveryUser() && hasFeature('COMPLIANCE_CHECK');
}
export const hasAccessToXseptionReport = (countryCode?: string | null | undefined): boolean =>
  countryCode ? hasFeature(`XSEPTION_${countryCode}`) : hasFeatureStartingWith('XSEPTION');
export const hasAccessToStakeholderReport = (countryCode: string | null | undefined): boolean =>
  countryCode
    ? hasFeature(`STAKEHOLDER_REPORT_${countryCode.toUpperCase()}`)
    : hasFeatureStartingWith('STAKEHOLDER_REPORT');
export const hasAccessToCoCExtract = (countryCode = ''): boolean =>
  countryCode.toLowerCase() === 'nl' && hasFeatureStartingWith('COC_EXTRACTS');
export const hasAccessToCachedCoCExtract = (): boolean => hasFeature('COC_EXTRACTS_CACHED');
export const hasPaygo = () => hasFeatureStartingWith('ICR_PAYGO');
export const hasICR = () => hasFeatureStartingWith('ICR');
export const hasDBMFeature = () => hasFeatureStartingWith('DATABASE_MANAGEMENT');
export const hasAccessToDatabaseManagement = (): boolean =>
  hasFeatureStartingWith('DATABASE_MANAGEMENT');
export const hasAccessToBusinessRisk = (): boolean => hasFeature('BUSINESS_RISK');
export const hasAccessToBusinessRadar = (): boolean => hasFeature('BUSINESS_RADAR');
// temporary feature flag for showing GDM v2
// TODO: remove this after release the feature
export const hasAccessToGDM2 = () => true;
export const HasAccessToDecisionModel = () =>
  hasAccessToGDM2() && isDeliveryUser() && hasFeature('DECISION_MODEL');
export const hasAccessToICR = () => isDeliveryUser() && hasFeatureStartingWith('ICR');
export const hasAccessToICRPaygo = () => isDeliveryUser() && hasFeatureStartingWith('ICR_PAYGO');
export const hasAccessToAnnualAccount = (countryCode = ''): boolean =>
  countryCode.toLowerCase() === 'nl' && hasFeature('ANNUAL_ACCOUNTS');
export default keycloak;
