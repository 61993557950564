// @ts-nocheck
import { injectI18n } from 'src/i18n';
import React from 'react';
import { components } from 'react-select';
import { I18nProp } from 'src/i18n';
import { CountryName, ListItem, ReportType } from './helper-components';
type Props = {
  showReportTypes?: boolean;
  isDisabled?: boolean;
  data: {
    value: string;
    attributes: {
      serviceLevel: string;
    };
  };
};
export function CountryOption({ i18n, showReportTypes = true, ...props }: Props & I18nProp) {
  const {
    isDisabled,
    data: {
      value: code,
      attributes: { serviceLevel },
    },
  } = props;
  return (
    <components.Option {...props}>
      <ListItem
        disabled={isDisabled}
        key={code}
        title={isDisabled ? i18n.text('icr.country-select.disabled.hover') : undefined}
      >
        <div>
          <CountryName>{i18n.text(`icr.country.${code}`)}</CountryName>
          {showReportTypes && (
            <ReportType>
              {i18n.text(`icr.country-select.report-type.${serviceLevel}`.toLowerCase())}
            </ReportType>
          )}
        </div>
      </ListItem>
    </components.Option>
  );
}
export default injectI18n(CountryOption);
