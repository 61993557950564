import React, { Component } from 'react';
import { Container, TooltipIcon, Tip, Arrow } from './helper-components';
import { getHorizontalDirection } from './utils';
import isDescendant from '../../utils/isDescendant';

type Props = {
  children: React.ReactNode;
  buttonSize?: number;
  fontSize?: number;
  offset?: number;
  width?: number;
  widthMobile?: number;
  onChange?: (showTip?: boolean) => void;
  iconColor?: string;
  bgColor?: string;
  defaultOrientation?: 'left' | 'right';
};

type State = {
  showTip: boolean;
  showBelow: boolean;
  orientation: 'left' | 'right';
  orientationMobile: 'left' | 'right';
};

class Tooltip extends Component<Props, State> {
  containerRef: any;
  constructor(props: Props) {
    super(props);
    this.state = {
      showTip: false,
      showBelow: false,
      orientation: null,
      orientationMobile: null,
    };

    this.onDocumentClick = this.onDocumentClick.bind(this);
  }

  UNSAFE_componentWillMount() {
    document.addEventListener('click', this.onDocumentClick);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.onDocumentClick);
  }

  onDocumentClick(e) {
    if (this.state.showTip && !isDescendant(this.containerRef, e.target)) {
      this.hideTooltip();
    }
  }

  showTooltip() {
    const containerRect = this.containerRef.getBoundingClientRect();
    const { defaultOrientation, width, widthMobile, offset } = this.props;

    this.setState(() => ({
      showTip: true,
      showBelow: containerRect.top + containerRect.height / 2 < window.innerHeight / 2,
      orientation: getHorizontalDirection(
        window.innerWidth,
        defaultOrientation,
        containerRect.left,
        width,
        offset,
      ),
      orientationMobile: getHorizontalDirection(
        window.innerWidth,
        defaultOrientation,
        containerRect.left,
        widthMobile,
        offset,
      ),
    }));

    const { onChange } = this.props;
    if (onChange) onChange(true);
  }

  hideTooltip() {
    this.setState(() => ({ showTip: false }));

    const { onChange } = this.props;
    if (onChange) onChange(false);
  }

  render() {
    const {
      children,
      buttonSize = 20,
      fontSize = 13,
      offset = 16,
      width = 400,
      widthMobile = 300,
      iconColor = '#90d1e0',
      bgColor = '#FFFFFF',
    } = this.props;
    const { showTip, showBelow, orientation, orientationMobile } = this.state;

    return (
      <Container
        innerRef={(el) => {
          this.containerRef = el;
        }}
      >
        <TooltipIcon
          size={buttonSize}
          onClick={() => (showTip ? this.hideTooltip() : this.showTooltip())}
          color={iconColor}
          bgColor={bgColor}
          fontSize={fontSize}
          invertColors={showTip}
        >
          ?
        </TooltipIcon>
        {showTip && (
          <React.Fragment>
            <Tip
              buttonSize={buttonSize}
              showBelow={showBelow}
              left={orientation === 'right' ? -offset : offset + buttonSize - width}
              leftMobile={
                orientationMobile === 'right' ? -offset : offset + buttonSize - widthMobile
              }
              width={width}
              widthMobile={widthMobile}
            >
              {children}
            </Tip>
            <Arrow isBorderArrow buttonSize={buttonSize} showBelow={showBelow} />
            <Arrow buttonSize={buttonSize} showBelow={showBelow} />
          </React.Fragment>
        )}
      </Container>
    );
  }
}

export default Tooltip;
