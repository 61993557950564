import { injectI18n, I18nProp } from 'src/i18n';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ButtonCR from '../../../../components/ButtonCR';
import { isGraydonCustomer, isCreditSafeCustomer } from '../../../../keycloak';
import { sendEvent } from '../../../../utils/analytics';
import { InnerContainer, OuterContainer, RawDataLink } from './helper-components';
import ReportMenu from '../../../../components/ReportMenu';

type Props = {
  enterpriseId: number;
  graydonCompanyId?: string;
  selectedCountry: string;
  cocNumber?: string;
  companyName?: string;
  registrationNumber?: string;
  graydonNumber?: string;
  clicked: any;
};

export const SearchResultsItemButtons: React.FC<Props & I18nProp & RouteComponentProps> = ({
  i18n,
  history,
  enterpriseId,
  graydonCompanyId,
  selectedCountry,
  companyName,
  clicked,
  cocNumber,
  registrationNumber,
  graydonNumber,
}) => {
  return (
    <OuterContainer>
      <InnerContainer>
        <ButtonCR
          width="auto"
          onClick={() => {
            history.push(`/company-data/organisation/${enterpriseId}`);
            sendEvent('CD', 'Search Results: Company archive');
          }}
          primary
        >
          {i18n.text('cr.search.view-company-archive')}
        </ButtonCR>

        <ReportMenu
          company={{
            enterpriseId,
            countryCode: selectedCountry,
            graydonCompanyId,
            cocNumber,
            companyName,
            registrationNumber,
            graydonNumber,
          }}
          clicked={clicked}
          app="CD"
          context="search-items"
        />
      </InnerContainer>

      {(isGraydonCustomer() || isCreditSafeCustomer()) && (
        <RawDataLink onClick={() => history.push(`/company-data/validate/${enterpriseId}`)}>
          {i18n.text('cr.search.view-raw-data')}
        </RawDataLink>
      )}
    </OuterContainer>
  );
};
export default injectI18n(withRouter(SearchResultsItemButtons));
