import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { I18nProp, injectI18n } from 'src/i18n';
import { hasPaygo } from '../../keycloak';
import { sendEvent } from '../../utils/analytics';
import Link from '../Link';
import { NoResultsText, ResultsList, SearchMessage, TotalWrapper } from './helper-components';
import NoPaygo from '../../apps/InternationalCreditReports/pages/SearchPage/Components/NoPaygo';
// eslint-disable-next-line max-len
import OnlyFreshInvestigation from '../../apps/InternationalCreditReports/pages/SearchPage/Components/OnlyFreshInvestigation';
import { CountryCode } from 'src/types/locales';

type SearchResultsListProps = {
  total: number;
  showFreshInvestigationLinkOnNoResults?: boolean;
  onlyFreshInvestigationAllowed?: boolean;
  countryCode?: CountryCode;
  children?: React.ReactNode;
};

const SearchResultsList: React.FC<SearchResultsListProps & I18nProp & RouteComponentProps> = ({
  i18n,
  total,
  children,
  history,
  showFreshInvestigationLinkOnNoResults = false,
  countryCode = '',
  onlyFreshInvestigationAllowed,
}) => {
  if (onlyFreshInvestigationAllowed) {
    return hasPaygo() ? <OnlyFreshInvestigation country={countryCode} /> : <NoPaygo />;
  }

  return (
    <>
      {total === 0 && (
        <NoResultsText>
          <span>{`${i18n.text('search.no.results.check-the-following')}:`}</span>
          <ul>
            <li>{i18n.text('search.no.results.check-spelling')}</li>
            <li>{i18n.text('search.no.results.identifier')}</li>
            <li>{i18n.text('search.no.results.refine-search')}</li>
            {showFreshInvestigationLinkOnNoResults && (
              <li>
                <span>{i18n.text('cr.search.results.no.data.main-text')} </span>
                <Link
                  onClick={() => {
                    sendEvent(
                      'CD',
                      'Search results: No results message - Make a Fresh Investigation Link',
                      countryCode,
                    );
                    history.push(
                      `/international-credit-reports/order-investigated-report/${countryCode.toUpperCase()}`,
                    );
                  }}
                >
                  {i18n.text('cr.search.results.no.data.link-text')}
                </Link>
              </li>
            )}
          </ul>
        </NoResultsText>
      )}

      {total > 0 && (
        <>
          <TotalWrapper>
            {i18n.text('search.companies.found', {
              total: total > 50 ? '50+' : total,
            })}
          </TotalWrapper>
          <ResultsList>{children}</ResultsList>
        </>
      )}

      {total > 50 && <SearchMessage>{i18n.text('general.specify-search')}</SearchMessage>}
    </>
  );
};

export default withRouter(injectI18n(SearchResultsList));
