import get from 'lodash/get';
import { getFormattedAddress } from '../../../../../utils/getFormattedAddress';
import { parseDate } from '../common';
export const xSeptionComparator = (a: any, b: any) => {
  const { startDate: startDateA, category: categoryA } = a;
  const { startDate: startDateB, category: categoryB } = b;
  if (!startDateA && startDateB) return 1;
  if (startDateA && !startDateB) return -1;

  if (!startDateA && !startDateB) {
    if (categoryA < categoryB) return -1;
    if (categoryA > categoryB) return 1;
  }

  if (startDateA && startDateB) {
    if (a.startDate.getTime() > b.startDate.getTime()) return -1;
    if (a.startDate.getTime() < b.startDate.getTime()) return 1;

    if (startDateA.getTime() === startDateB.getTime()) {
      if (categoryA < categoryB) return -1;
      if (categoryA > categoryB) return 1;
    }
  }

  return 0;
};

function mapCompanyInfo({
  companyIdentification = {},
  companyProfile: { addresses, ...company },
}: any) {
  return {
    ...company,
    isActive: company.companyStatus.isActive,
    countryCode: companyIdentification.countryCode,
    address:
      addresses && addresses.length
        ? getFormattedAddress(companyIdentification.countryCode, addresses[0])
        : null,
    companyIdentification,
  };
}

export const extractXSeptionsData = (data: any) => {
  const country = get(data, 'companyIdentification.countryCode', '');
  const xseptions = get(data, 'xseptionsProfile.xseptions', []).map((xSeption: any, i: any) => {
    const descriptionTypeCode = get(xSeption, 'type.value', null);
    const categoryTypeCode = get(xSeption, 'category.value', null);
    const relatedCompany = get(xSeption, 'relatedCompany');
    const gdiTranslationKeyCategory = categoryTypeCode
      ? `exceptionalEvents.xseptions.category.typeCode-${categoryTypeCode}`
      : null;
    const gdiTranslationKeyDescription = descriptionTypeCode
      ? `exceptionalEvents.xseptions.description.typeCode-${descriptionTypeCode}`
      : null;
    return {
      key: i,
      type: get(xSeption, 'type.value', null),
      startDate: parseDate(get(xSeption, 'startDate', null)),
      // TODO: remove
      gdiTranslationKeyCategory,
      gdiTranslationKeyDescription,
      value1: get(xSeption, 'value1', null),
      value2: get(xSeption, 'value2', null),
      date1: parseDate(get(xSeption, 'date1', null)),
      date2: parseDate(get(xSeption, 'date2', null)),
      text: get(xSeption, 'text', null),
      relatedCompany: relatedCompany ? mapCompanyInfo(relatedCompany) : null,
    };
  });
  return country === 'GB' ? xseptions : xseptions.sort(xSeptionComparator);
};
