const GTM_URL = '//www.googletagmanager.com/gtm.js?id=';
const tagRegEx = /^GTM-[A-Z0-9]{6}$/;
let injected = false;

export default (id) => {
  if (!injected && tagRegEx.test(id)) {
    const script = document.createElement('script');
    script.src = `${GTM_URL}${id}`;
    script.async = true;
    script.onload = () => {
      // @ts-ignore
      window.dataLayer = window.dataLayer || [];
      // @ts-ignore
      window.dataLayer.push({
        'gtm.start': Date.now(),
        event: 'gtm.js',
      });
    };

    document.head.appendChild(script);
    injected = true;
  }
};
