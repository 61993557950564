import createReducer from 'create-reducer-map';
import { SET_SEARCH_QUERY } from './constants';
export const initialState = {
  text: '',
};
const reducer = {
  [SET_SEARCH_QUERY]: (state, payload) => ({
    ...state,
    text: payload,
  }),
};
export default createReducer(initialState, reducer);
