import * as colors from '@graydon/ui-colors';
import React from 'react';
import { Checkbox } from 'src/core/components/AnimatedToggles';
import Spinner from 'src/core/components/loaders/Spinner';
import { I18nProp, injectI18n } from 'src/i18n';
import styled from 'styled-components';
import grayEyeIcon from '../../media/icons/gray-eye-icon.svg';
import iconDatabase from '../../media/icons/icon-database.svg';
import successIcon from '../../media/icons/ok.svg';
import errorIcon from '../../media/icons/something-went-wrong.svg';
import { getConsumerPath, post } from '../../utils/api';
import { CenteredItemsFlex } from '../BasePage/helper-components';

const Wrapper = styled.div`
  padding: 15px 0;
  &:not(:last-child) {
    border-bottom: 1px solid ${colors.grayLight4};
  }

  img {
    margin-right: 8px;
  }
`;

const Item = styled.div`
  display: flex;
  align-items: flex-start;
`;

const TagName = styled.div`
  word-break: break-all;
  word-wrap: break-word;
`;

const CheckboxContainer = styled.label`
  width: 33px;
  overflow: hidden;
  flex-shrink: 0;
`;

const SpinnerContainer = styled.div`
  width: 33px;
  padding-top: 4px;
  flex-shrink: 0;
`;
export const IconWrapper = styled(CenteredItemsFlex)`
  padding-top: 8px;
`;

const StatusDiv = styled.div<{
  isError?: boolean;
}>`
  display: flex;
  align-items: center;
  background-color: ${({ isError }) => (isError ? 'red' : 'rgba(78, 167, 62, 0.4)')};
  padding: 10px;
  margin-top: 15px;

  & > * {
    margin: 5px;
  }
`;

type Props = I18nProp & {
  tag: any;
  enterpriseId: number;
  countryCode: string;
  checked: boolean;
  onChange: (checked: boolean) => unknown;
};

type State = {
  requestStatus: null | 'loading' | Error | 'successfullyAdded' | 'successfullyRemoved';
};

class TagItem extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      requestStatus: null,
    };
  }

  toggleChecked() {
    const { tag, enterpriseId, countryCode, checked, onChange } = this.props;
    this.setState({
      requestStatus: 'loading',
    });
    const request = checked
      ? post(`${getConsumerPath()}/tags/${tag.uuid}/organizations/deletebyorgyuid`, {
          orgYuids: [enterpriseId],
        })
      : post(`${getConsumerPath()}/tags/tagorganizations`, {
          tagUuids: [tag.uuid],
          gdbOrgYuids: [enterpriseId],
          countryCode: [countryCode],
        });
    request.then(
      () => {
        if (checked) {
          this.setState({
            requestStatus: 'successfullyRemoved',
          });
          onChange(false);
        } else {
          this.setState({
            requestStatus: 'successfullyAdded',
          });
          onChange(true);
        }
      },
      (error) => {
        this.setState({
          requestStatus: error,
        });
      },
    );
  }

  renderRequestStatus() {
    const { i18n } = this.props;
    const { requestStatus } = this.state;

    if (requestStatus === 'successfullyAdded') {
      return (
        <StatusDiv>
          <img src={successIcon} width={20} height={20} alt="OK" />
          <span
            dangerouslySetInnerHTML={{
              __html: i18n.text('addToMonitoring.companyAdded'),
            }}
          />
        </StatusDiv>
      );
    }

    if (requestStatus === 'successfullyRemoved') {
      return (
        <StatusDiv>
          <img src={successIcon} width={20} height={20} alt="OK" />
          <span
            dangerouslySetInnerHTML={{
              __html: i18n.text('addToMonitoring.companyRemoved'),
            }}
          />
        </StatusDiv>
      );
    }

    if (requestStatus instanceof Error) {
      return (
        <StatusDiv isError>
          <img src={errorIcon} width={20} height={20} alt="Error" />
          <span>{i18n.text('error.something-wrong')}</span>
        </StatusDiv>
      );
    }

    return null;
  }

  render() {
    const { i18n, tag, checked } = this.props;
    const { requestStatus } = this.state;
    return (
      <Wrapper data-testid={tag.uuid}>
        <Item>
          {requestStatus === 'loading' ? (
            <SpinnerContainer>
              <Spinner />
            </SpinnerContainer>
          ) : (
            <CheckboxContainer>
              <Checkbox readOnly checked={checked} onChange={() => this.toggleChecked()} />
              <span className="label">&nbsp;</span>
            </CheckboxContainer>
          )}
          <div>
            <TagName>{tag.name}</TagName>
            {tag.isMonitored && (
              <IconWrapper>
                <img width={20} height={20} src={grayEyeIcon} alt="" />
                {i18n.text('lm.company.monitored')}
              </IconWrapper>
            )}
            {tag.hasDBM && (
              <IconWrapper>
                <img width={20} height={20} src={iconDatabase} alt="" />
                {i18n.text('lm.company.has-dbm')}
              </IconWrapper>
            )}
          </div>
        </Item>
        {this.renderRequestStatus()}
      </Wrapper>
    );
  }
}

export default injectI18n(TagItem);
