import { I18nProp, injectI18n } from 'src/i18n';
import React from 'react';
import Link from '../../../../components/Link';

type Prop = {
  isEnabled: boolean;
  start: (e: any) => any;
  stop: (e: any) => any;
};

const SwitchClientView = ({ i18n, isEnabled, stop, start }: Prop & I18nProp) => (
  <Link onClick={isEnabled ? stop : start}>
    {i18n.text(
      isEnabled ? 'icr.search-page.client-session.stop' : 'icr.search-page.client-session.start',
    )}
  </Link>
);

export default injectI18n(SwitchClientView);
