import get from 'lodash/get';
import { parseDate } from '../common';

const getAnnualReportFiledBy = (report: any) =>
  get(report, 'annualReportHeader.annualreportFiledBy', null) ||
  get(report, 'annualReportHeader.annualReportFiledBy', null);

export const extractLiabilitiesData = (data: any) => {
  const liabilities = get(data, 'financialProfile.liabilityStatement', null);
  const annualReports = get(data, 'annualReportProfile.annualReports', []);
  const keyFigures = get(data, 'financialProfile.keyFigures', []);
  if (!liabilities) return null;
  const companyName = get(liabilities, 'liableCompany.companyProfile.companyName');
  const enterpriseId = get(liabilities, 'liableCompany.companyIdentification.enterpriseId');
  const graydonCompanyId = get(liabilities, 'liableCompany.companyIdentification.graydonCompanyId');
  const filingDate = parseDate(get(liabilities, 'filingDate', null));
  const annualReportFiledBy = annualReports.find((report: any) => {
    const annualReportFiled = getAnnualReportFiledBy(report);
    const liableCompanyId = get(annualReportFiled, 'companyIdentification.enterpriseId', null);
    return liableCompanyId === enterpriseId ? annualReportFiled : null;
  });
  const originatesFrom = keyFigures.find((report: any) => {
    const originates = get(report, 'originatesFrom', null);
    const liableCompanyId = get(originates, 'companyIdentification.enterpriseId', null);
    return liableCompanyId === enterpriseId ? originates : null;
  });
  return {
    companyName,
    filingDate,
    enterpriseId,
    graydonCompanyId,
    annualReportFiledBy: getAnnualReportFiledBy(annualReportFiledBy),
    originatesFrom: get(originatesFrom, 'originatesFrom', null),
  };
};
