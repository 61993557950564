import styled from 'styled-components';
import { sans } from '../../constants/fonts';
import arrowSvg from '../../media/icons/select-arrow-down.svg';

export const Select = styled.select<{
  fontSize?: string;
  width?: string;
  maxWidth?: string;
  highPadding?: boolean;
  borderless?: boolean;
  light?: boolean;
  borderColor?: string;
}>`
  appearance: none;
  margin: 0;
  ${({ width }) => (width ? `width: ${width};` : '')}
  ${({ maxWidth }) => (maxWidth ? `max-width: ${maxWidth};` : '')}
    padding: ${({ highPadding }) => (highPadding ? '0.8em' : '0.6em')} 0.9em;
  padding-right: 26px;
  font-size: ${({ fontSize = '16px' }) => fontSize};
  font-family: ${sans};
  font-weight: 300;
  ${({ borderless = false, light = false, borderColor = '#e3e3e3' }) =>
    borderless
      ? `border: none;
  background: none;
  outline: none;
  color: inherit;`
      : `border: ${light ? 'none' : `1px solid ${borderColor}`};
  border-radius: 4px;
  background: #fff;
  color: #545454;`}

  background-image: url(${arrowSvg});
  background-repeat: no-repeat;
  background-position: top 50% right 7px;

  &::-ms-expand {
    display: none;
  }
`;

export default Select;
