import { type ComponentType } from 'react';
import React, { lazy } from 'react';
import CompanyData from './apps/CompanyData';
import Contact from './apps/Contact';
import Home from './apps/Home';
import InternationalCreditReports from './apps/InternationalCreditReports';
import ErrorPage, { ErrorButton, ErrorHeading } from './components/ErrorPage';
import { transunionUrl, truNarrativeUrl, businessRadarUrl } from './constants/externals';
import {
  hasAccessToBusinessRisk,
  hasAccessToBusinessRadar,
  hasAccessToCreditReports,
  hasAccessToDatabaseManagement,
  hasAccessToGDM2,
  hasAccessToICR,
  hasAccessToMonitoring,
  hasAccessToStakeholderReport,
  hasAccessToXseptionReport,
  hasFeature,
  hasFeatureStartingWith,
  isDeliveryManager,
  isDeliveryUser,
  isGBConsumer,
  hasAccessToSomeDomestic,
  hasAccessToGbInternationalSearch,
} from './keycloak';

const AsyncListManagement = lazy(
  () =>
    import(
      /* webpackChunkName: "list-management" */
      './apps/ListManagement'
    ),
);
const AsyncDataEnrichment = lazy(
  () =>
    import(
      /* webpackChunkName: "data-enrichment" */
      './apps/DataEnrichment'
    ),
);
const AsyncMarketingInformation = lazy(
  () =>
    import(
      /* webpackChunkName: "marketing-information" */
      './apps/MarketingInformation'
    ),
);
const AsyncLedgerAnalyser = lazy(
  () =>
    import(
      /* webpackChunkName: "ledger-analyser" */
      './apps/LedgerAnalyser'
    ),
);
const AsyncCreditReports = lazy(
  () =>
    import(
      /* webpackChunkName: "credit-reports" */
      './apps/CreditReports'
    ),
);
const AsyncUboReports = lazy(
  () =>
    import(
      /* webpackChunkName: "ubo-reports" */
      './apps/UboReports'
    ),
);
const AsyncMonitoring = lazy(
  () =>
    import(
      /* webpackChunkName: "monitoring" */
      './apps/Monitoring'
    ),
);
const AsyncAdvancedAnalytics = lazy(
  () =>
    import(
      /* webpackChunkName: "advanced-analytics" */
      './apps/AdvancedAnalytics'
    ),
);
const AsyncXSeptions = lazy(
  () =>
    import(
      /* webpackChunkName: "xseption-report" */
      './apps/XSeptionReports/'
    ),
);
const AsyncStakeholder = lazy(
  () =>
    import(
      /* webpackChunkName: "stakeholder-report" */
      './apps/StakeholderReports/'
    ),
);
const AsyncDatabaseManagement = lazy(
  () =>
    import(
      /* webpackChunkName: "database-management" */
      './apps/DatabaseManagement/'
    ),
);
const AsyncDecisionModel = lazy(
  () =>
    import(
      /* webpackChunkName: "decision-model" */
      './apps/DecisionModel/'
    ),
);

const RedirectErrorPage = () => (
  <ErrorPage>
    <ErrorHeading>Redirect failed</ErrorHeading>

    <ErrorButton
      onClick={() => {
        window.location.reload();
      }}
    >
      Refresh
    </ErrorButton>
  </ErrorPage>
);

export type App = {
  key: string;
  component: ComponentType<any>;
  hasHomeTile?: boolean;
  hasAccess: () => boolean;
  isExternal?: boolean;
  url?: string;
  path?: string;
};

const apps: Array<App> = [
  {
    key: 'home',
    component: Home,
    hasAccess: () => true,
  },
  {
    key: 'contact',
    component: Contact,
    hasAccess: () => true,
  },
  {
    key: 'company-data',
    component: CompanyData,
    hasAccess: () => true,
  },
  {
    key: 'credit-reports',
    component: AsyncCreditReports,
    hasHomeTile: false,
    hasAccess: hasAccessToCreditReports,
  },
  {
    key: 'ubo-reports',
    component: AsyncUboReports,
    hasHomeTile: false,
    hasAccess: () => hasFeatureStartingWith('UBO_REPORT'),
  },
  {
    key: 'monitoring',
    component: AsyncMonitoring,
    hasHomeTile: true,
    hasAccess: hasAccessToMonitoring,
  },
  {
    key: 'list-management',
    component: AsyncListManagement,
    hasHomeTile: true,
    hasAccess: () => hasFeature('LIST_MANAGEMENT'),
  },
  {
    key: 'data-enrichment',
    component: AsyncDataEnrichment,
    hasHomeTile: true,
    hasAccess: () => isDeliveryUser() && hasFeatureStartingWith('ENRICHMENT'),
  },
  {
    key: 'decision-model',
    component: RedirectErrorPage,
    isExternal: true,
    hasHomeTile: true,
    hasAccess: () => isDeliveryUser() && hasFeature('DECISION_MODEL'),
  },
  {
    key: 'marketing-information',
    component: AsyncMarketingInformation,
    hasHomeTile: true,
    hasAccess: () => isDeliveryUser() && hasFeatureStartingWith('MARKETING_INFORMATION'),
  },
  {
    key: 'ledger-analyser',
    component: AsyncLedgerAnalyser,
    hasHomeTile: true,
    hasAccess: () => isDeliveryUser() && hasFeatureStartingWith('LEDGER_ANALYSER'),
  },
  {
    key: 'advanced-analytics',
    component: AsyncAdvancedAnalytics,
    hasHomeTile: false,
    hasAccess: () => isDeliveryManager() && hasFeature('ADVANCED_ANALYTICS'),
  },
  {
    key: 'management-dashboard',
    component: RedirectErrorPage,
    isExternal: true,
    hasHomeTile: true,
    hasAccess: () => isDeliveryManager() && !isGBConsumer(),
  },
  {
    key: 'international-credit-reports',
    component: InternationalCreditReports,
    hasAccess: () =>
      hasAccessToICR() || hasAccessToSomeDomestic() || hasAccessToGbInternationalSearch(),
  },
  {
    key: 'international-business-archive',
    path: '/international-credit-reports/archive',
    component: InternationalCreditReports,
    hasHomeTile: true,
    hasAccess: () =>
      hasAccessToICR() || hasAccessToSomeDomestic() || hasAccessToGbInternationalSearch(),
  },
  {
    key: 'consumer-credit-check',
    component: RedirectErrorPage,
    url: transunionUrl,
    hasHomeTile: true,
    hasAccess: () => hasFeature('TRANSUNION_GB'),
  },
  {
    key: 'xseption-report',
    component: AsyncXSeptions,
    hasHomeTile: false,

    hasAccess: () => hasAccessToXseptionReport(),
  },
  {
    key: 'stakeholder-report',
    component: AsyncStakeholder,
    hasHomeTile: false,

    hasAccess: () => hasAccessToStakeholderReport(null),
  },
  {
    key: 'database-management',
    component: AsyncDatabaseManagement,
    hasHomeTile: true,
    hasAccess: () => hasAccessToDatabaseManagement(),
  },
  {
    key: 'business-risk',
    component: RedirectErrorPage,
    url: truNarrativeUrl,
    hasHomeTile: true,
    hasAccess: () => hasAccessToBusinessRisk(),
  },
  {
    key: 'business-radar',
    component: RedirectErrorPage,
    url: businessRadarUrl,
    hasHomeTile: true,
    hasAccess: () => hasAccessToBusinessRadar(),
  },
  {
    key: 'dm-v2',
    component: AsyncDecisionModel,
    hasHomeTile: false,
    hasAccess: () => hasAccessToGDM2() && isDeliveryUser() && hasFeature('DECISION_MODEL'),
  },
];

export default apps;
