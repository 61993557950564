import { useState } from 'react';
// manage state as an object (react old fashion way)
export function usePartialState(initial = {}) {
  const [state, setInternalState] = useState(initial);

  // set some keys in state;
  const setState = (changes: any) => setInternalState({ ...state, ...changes });

  // set a key in state
  const setItem = (key: any) => (value: any) =>
    setState({
      [`${key}`]: value,
    });

  return [state, setState, setItem];
}
