import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import { extractRegisteredAddress } from '../common';
import { ENTITY_TYPE_COMPANY, ENTITY_TYPE_PERSON } from '../../constants';
import { extractInsolvencies } from '../extractFinancialInsolvenciesData';
import { getFormattedAddress } from '../../../../../utils/getFormattedAddress';

const getAdditionalInfo = (item: any) => {
  if (item.entity === ENTITY_TYPE_PERSON) {
    const { person = {} } = item;
    const { titles, firstName, surName } = person;
    return {
      fullName: get(person, 'fullName') || [titles, firstName, surName].filter((x) => x).join(' '),
      unFormattedDateOfBirth: get(person, 'unformattedDateOfBirth'),
      address: getFormattedAddress('GB', get(item, 'roles.[0].address')),
    };
  }

  if (item.entity === ENTITY_TYPE_COMPANY) {
    const companyProfile = get(item, 'company.companyProfile');
    const companyIdentification = get(item, 'company.companyIdentification');
    const country = get(companyIdentification, 'countryCode', '');
    const creditFlag = get(
      item,
      'company.ratingsProfile.graydonGroupCreditScores.creditFlag.value',
      'NA',
    );
    const address = extractRegisteredAddress(get(companyProfile, 'addresses'));
    const addressCountry = get(address, 'country.value');
    const mappedInsolvencies = extractInsolvencies(
      country,
      get(companyProfile, 'financialCalamities', []),
    );
    return {
      name: get(companyProfile, 'companyName'),
      enterpriseId: get(companyIdentification, 'enterpriseId'),
      registrationNumber: get(companyIdentification, 'registrationId'),
      address: getFormattedAddress(addressCountry, address),
      isActive: get(companyProfile, 'companyStatus.isActive'),
      insolvency: mappedInsolvencies[0],
      creditFlag,
      countryCode: get(companyIdentification, 'countryCode'),
    };
  }

  return {};
};

export const extractPscData = (data: any) => {
  const pscRawData = get(data, 'affiliationsProfile.entitiesWithSignificantControl.entities', []);
  const reasonNoEntity = get(
    data,
    'affiliationsProfile.entitiesWithSignificantControl.reasonNoEntity',
    '',
  );
  const country = get(data, 'companyIdentification.countryCode', '');
  const pscList = pscRawData.map((item: any) => ({
    entity: get(item, 'entity'),
    mandate: get(item, 'roles.[0].mandateText'),
    additionalInfo: getAdditionalInfo(item),
  }));
  const pscPersons = pscList.filter((psc: any) => psc.entity === ENTITY_TYPE_PERSON);
  const pscCompanies = pscList.filter((psc: any) => psc.entity === ENTITY_TYPE_COMPANY);
  const sortedPscList = [
    ...sortBy(pscPersons, (psc) => psc.additionalInfo.fullName),
    ...sortBy(pscCompanies, (psc) => psc.additionalInfo.name),
  ];
  return {
    country,
    pscList: sortedPscList,
    reasonNoEntity,
  };
};
