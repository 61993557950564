import styled from 'styled-components';
import ButtonCR from '../../../../components/ButtonCR';
export const Button = styled(ButtonCR)`
  margin-top: 15px;

  &:last-child {
    margin-bottom: 15px;

    @media (max-width: 768px) {
      margin-bottom: 0;
    }
  }

  @media (max-width: 768px) and (min-width: 600px) {
    width: 50%;
    white-space: pre-line;
  }
`;
