import React, { Suspense, useEffect } from 'react';
import { withRouter, Route, Switch, Redirect } from 'react-router-dom';
import { compose } from 'redux';
import apps from './apps';
import Page404 from './components/Page404';
import LoadingPage from './components/LoadingPage';
import { initializeNotifications } from './utils/appcues';

const AppRoutes: React.FC = () => {
  useEffect(initializeNotifications, []);

  return (
    <Suspense fallback={<LoadingPage />}>
      <Switch>
        <Redirect from="/" exact to="/home" />
        {apps.map((app) => (
          <Route key={app.key} path={app.path || `/${app.key}`} component={app.component} />
        ))}
        <Route component={Page404} />
      </Switch>
    </Suspense>
  );
};

export default compose(withRouter)(AppRoutes);
