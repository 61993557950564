import { injectI18n, I18nProp } from 'src/i18n';
import { isEmpty } from 'lodash';
import React from 'react';
import { isFailed, isStarted, isSuccessful } from '../../utils/processFlow';
import { RouterLink } from '../Link';
import ZebraList from '../ZebraList';
import {
  ArchiveHeader,
  Cell,
  Description,
  ErrorText,
  Loader,
  Row,
  StatusIcon,
  Outcome,
  DateWrapper,
} from './helper-components';
import { OutcomeType } from './helper-components';
type ArchiveItem = {
  id: string;
  date: string;
  outcome: OutcomeType;
};
type Props = {
  status: number;
  list: ArchiveItem[] | null | undefined;
};

function formatDate(i18n: any, dateString: any) {
  return i18n.date(new Date(dateString));
}

function Archive({ status, list, i18n }: Props & I18nProp) {
  return (
    <div>
      <ArchiveHeader>{i18n.text('gdm.check-modal.archive-header')}</ArchiveHeader>

      {isStarted(status) && <Loader size={24} borderSize={2} />}

      {isFailed(status) && <ErrorText>{i18n.text('gdm.check-modal.archive-error')}</ErrorText>}

      {isSuccessful(status) && (
        <>
          {!isEmpty(list) ? (
            <>
              {/* @ts-ignore */}
              {list.length > 10 && (
                <Description>{i18n.text('gdm.check-modal.archive-description')}</Description>
              )}
              <ZebraList reverseColors>
                {/* @ts-ignore */}
                {list.slice(0, 10).map(({ date, outcome, id }) => (
                  <Row key={id}>
                    <DateWrapper>{formatDate(i18n, date)}</DateWrapper>
                    <Outcome>
                      <StatusIcon type={outcome} />
                      {i18n.text(`dm.editor.${outcome.toLocaleLowerCase()}`)}
                    </Outcome>
                    <Cell>
                      <RouterLink to={`/dm-v2/${id}`}>
                        {i18n.text('gdm.check-modal.view-check')}
                      </RouterLink>
                    </Cell>
                  </Row>
                ))}
              </ZebraList>
            </>
          ) : (
            <ErrorText>{i18n.text('gdm.check-modal.archive-no-result')}</ErrorText>
          )}
        </>
      )}
    </div>
  );
}

export default injectI18n(Archive); // as ComponentType<Props & _I18nProp>
