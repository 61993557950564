import React from 'react';
import { marked } from 'marked';

type Props = {
  children: string;
  colorLink?: boolean;
};

export const Markdown: React.FC<Props> = ({ children, colorLink = false }) => {
  if (children == '-') return <p>-</p>;

  const renderer = new marked.Renderer();
  renderer.link = function (href: string, title: string, text: string) {
    if (colorLink && ['#green', '#red', '#orange'].includes(href)) {
      switch (href) {
        case '#green':
          return `<span style="color:#4ea73e">${text}</span>`;

        case '#red':
          return `<span style="color:#c71d18">${text}</span>`;

        case '#orange':
          return `<span style="color:#f57100">${text}</span>`;
      }
    } else
      return `<a target="_blank" rel="noopener noreferrer" href=${href} title=${title}>${text}</a>`;
  };

  let html = marked(children, { renderer: renderer });
  html = html.replace(/^\s*\<p\>(.+)\<\/p\>\s*$/, '$1');
  return <span dangerouslySetInnerHTML={{ __html: html }} />;
};
